define("discourse/widgets/quick-access-profile", ["exports", "I18n", "discourse/widgets/quick-access-item", "discourse/widgets/quick-access-panel", "discourse/widgets/widget"], function (_exports, _I18n, _quickAccessItem, _quickAccessPanel, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addQuickAccessProfileItem = addQuickAccessProfileItem;
  var _extraItems = [];

  function addQuickAccessProfileItem(item) {
    _extraItems.push(item);
  }

  (0, _widget.createWidgetFrom)(_quickAccessItem.default, "logout-item", {
    tagName: "li.logout",
    html: function html() {
      return this.attach("flat-button", {
        action: "logout",
        content: _I18n.default.t("user.log_out"),
        icon: "sign-out-alt",
        label: "user.log_out"
      });
    }
  });
  (0, _widget.createWidgetFrom)(_quickAccessPanel.default, "quick-access-profile", {
    tagName: "div.quick-access-panel.quick-access-profile",
    buildKey: function buildKey() {
      return "quick-access-profile";
    },
    hideBottomItems: function hideBottomItems() {
      // Never show the button to the full profile page.
      return true;
    },
    findNewItems: function findNewItems() {
      return Ember.RSVP.Promise.resolve(this._getItems());
    },
    itemHtml: function itemHtml(item) {
      var widgetType = item.widget || "quick-access-item";
      return this.attach(widgetType, item);
    },
    _getItems: function _getItems() {
      var items = this._getDefaultItems();

      if (this._showToggleAnonymousButton()) {
        items.push(this._toggleAnonymousButton());
      }

      items = items.concat(_extraItems);

      if (this.attrs.showLogoutButton) {
        items.push({
          widget: "logout-item"
        });
      }

      return items;
    },
    _getDefaultItems: function _getDefaultItems() {
      var defaultItems = [{
        icon: "user",
        href: "".concat(this.attrs.path, "/summary"),
        content: _I18n.default.t("user.summary.title"),
        className: "summary"
      }, {
        icon: "stream",
        href: "".concat(this.attrs.path, "/activity"),
        content: _I18n.default.t("user.activity_stream"),
        className: "activity"
      }];

      if (this.currentUser.can_invite_to_forum) {
        defaultItems.push({
          icon: "user-plus",
          href: "".concat(this.attrs.path, "/invited"),
          content: _I18n.default.t("user.invited.title"),
          className: "invites"
        });
      }

      defaultItems.push({
        icon: "pencil-alt",
        href: "".concat(this.attrs.path, "/activity/drafts"),
        content: this.currentUser.draft_count > 0 ? _I18n.default.t("drafts.label_with_count", {
          count: this.currentUser.draft_count
        }) : _I18n.default.t("drafts.label"),
        className: "drafts"
      }, {
        icon: "cog",
        href: "".concat(this.attrs.path, "/preferences"),
        content: _I18n.default.t("user.preferences"),
        className: "preferences"
      });
      defaultItems.push({
        widget: "do-not-disturb"
      });
      return defaultItems;
    },
    _toggleAnonymousButton: function _toggleAnonymousButton() {
      if (this.currentUser.is_anonymous) {
        return {
          action: "toggleAnonymous",
          className: "disable-anonymous",
          content: _I18n.default.t("switch_from_anon"),
          icon: "ban"
        };
      } else {
        return {
          action: "toggleAnonymous",
          className: "enable-anonymous",
          content: _I18n.default.t("switch_to_anon"),
          icon: "user-secret"
        };
      }
    },
    _showToggleAnonymousButton: function _showToggleAnonymousButton() {
      return this.siteSettings.allow_anonymous_posting && this.currentUser.trust_level >= this.siteSettings.anonymous_posting_min_trust_level || this.currentUser.is_anonymous;
    }
  });
});