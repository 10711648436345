define("discourse/templates/modal/activation-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "78xUdgy0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,null,{\"statements\":[[0,\"  \"],[1,[28,\"activation-email-form\",null,[[\"email\"],[[24,[\"newEmail\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"label\",\"disabled\",\"class\"],[[28,\"action\",[[23,0,[]],\"changeEmail\"],null],\"login.submit_new_email\",[24,[\"submitDisabled\"]],\"btn-primary\"]]],false],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"label\"],[[28,\"route-action\",[\"closeModal\"],null],\"close\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/activation-edit.hbs"
    }
  });

  _exports.default = _default;
});