define("discourse/controllers/user-badges", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("favoriteBadges.length"), _dec2 = Ember._action, (_obj = {
    user: Ember.inject.controller(),
    username: Ember.computed.alias("user.model.username_lower"),
    sortedBadges: Ember.computed.sort("model", "badgeSortOrder"),
    favoriteBadges: Ember.computed.filterBy("model", "is_favorite", true),
    canFavoriteMoreBadges: function canFavoriteMoreBadges(favoriteBadgesCount) {
      return favoriteBadgesCount < this.siteSettings.max_favorite_badges;
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.badgeSortOrder = ["badge.badge_type.sort_order:desc", "badge.name"];
    },
    favorite: function favorite(badge) {
      return badge.favorite();
    }
  }, (_applyDecoratedDescriptor(_obj, "canFavoriteMoreBadges", [_dec], Object.getOwnPropertyDescriptor(_obj, "canFavoriteMoreBadges"), _obj), _applyDecoratedDescriptor(_obj, "favorite", [_dec2], Object.getOwnPropertyDescriptor(_obj, "favorite"), _obj)), _obj)));

  _exports.default = _default;
});