define("discourse/routes/review-index", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _discourse.default.extend((_dec = Ember._action, (_obj = {
    model: function model(params) {
      if (params.sort_order === null) {
        if (params.status === "reviewed" || params.status === "all") {
          params.sort_order = "created_at";
        } else {
          params.sort_order = "score";
        }
      }

      return this.store.findAll("reviewable", params);
    },
    setupController: function setupController(controller, model) {
      var meta = model.resultSetMeta; // "fast track" to update the current user's reviewable count before the message bus finds out.

      if (meta.reviewable_count !== undefined) {
        this.currentUser.set("reviewable_count", meta.reviewable_count);
      }

      controller.setProperties({
        reviewables: model,
        type: meta.type,
        filterType: meta.type,
        filterStatus: meta.status,
        filterTopic: meta.topic_id,
        filterCategoryId: meta.category_id,
        filterPriority: meta.priority,
        reviewableTypes: meta.reviewable_types,
        filterUsername: meta.username,
        filterReviewedBy: meta.reviewed_by,
        filterFromDate: Ember.isPresent(meta.from_date) ? moment(meta.from_date) : null,
        filterToDate: Ember.isPresent(meta.to_date) ? moment(meta.to_date) : null,
        filterSortOrder: meta.sort_order,
        sort_order: meta.sort_order,
        additionalFilters: meta.additional_filters || {}
      });
      controller.reviewables.setEach("last_performing_username", null);
    },
    activate: function activate() {
      var _this = this;

      this.messageBus.subscribe("/reviewable_claimed", function (data) {
        var reviewables = _this.controller.reviewables;

        if (reviewables) {
          var user = data.user ? _this.store.createRecord("user", data.user) : null;
          reviewables.forEach(function (reviewable) {
            if (data.topic_id === reviewable.topic.id) {
              reviewable.set("claimed_by", user);
            }
          });
        }
      });
      this.messageBus.subscribe("/reviewable_counts", function (data) {
        if (data.updates) {
          _this.controller.reviewables.forEach(function (reviewable) {
            var updates = data.updates[reviewable.id];

            if (updates) {
              reviewable.setProperties(updates);
            }
          });
        }
      });
    },
    deactivate: function deactivate() {
      this.messageBus.unsubscribe("/reviewable_claimed");
    },
    refreshRoute: function refreshRoute() {
      this.refresh();
    }
  }, (_applyDecoratedDescriptor(_obj, "refreshRoute", [_dec], Object.getOwnPropertyDescriptor(_obj, "refreshRoute"), _obj)), _obj)));

  _exports.default = _default;
});