define("discourse/widgets/bookmark-reminder-notification-item", ["discourse/widgets/default-notification-item", "I18n", "discourse/widgets/widget", "discourse/lib/utilities"], function (_defaultNotificationItem, _I18n, _widget, _utilities) {
  "use strict";

  (0, _widget.createWidgetFrom)(_defaultNotificationItem.DefaultNotificationItem, "bookmark-reminder-notification-item", {
    text: function text(notificationName, data) {
      var username = (0, _utilities.formatUsername)(data.display_username);
      var description = this.description(data);
      return _I18n.default.t("notifications.bookmark_reminder", {
        description: description,
        username: username
      });
    },
    notificationTitle: function notificationTitle(notificationName, data) {
      if (notificationName) {
        if (data.bookmark_name) {
          return _I18n.default.t("notifications.titles.".concat(notificationName, "_with_name"), {
            name: data.bookmark_name
          });
        } else {
          return _I18n.default.t("notifications.titles.".concat(notificationName));
        }
      } else {
        return "";
      }
    }
  });
});