define("discourse/templates/modal/post-enqueued", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0NlxELYA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[\"review.approval.description\"],null],false],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[8],[1,[28,\"html-safe\",[[28,\"i18n\",[\"review.approval.pending_posts\"],[[\"count\"],[[24,[\"model\",\"pending_count\"]]]]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"class\",\"label\"],[[28,\"route-action\",[\"closeModal\"],null],\"btn-primary\",\"review.approval.ok\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/post-enqueued.hbs"
    }
  });

  _exports.default = _default;
});