define("discourse/helpers/format-date", ["discourse/lib/formatter", "discourse-common/lib/helpers"], function (_formatter, _helpers) {
  "use strict";

  /**
    Display logic for dates. It is unbound in Ember but will use jQuery to
    update the dates on a regular interval.
  **/
  (0, _helpers.registerUnbound)("format-date", function (val, params) {
    var leaveAgo,
        format = "medium",
        title = true;

    if (params.leaveAgo) {
      leaveAgo = params.leaveAgo === "true";
    }

    if (params.format) {
      format = params.format;
    }

    if (params.noTitle) {
      title = false;
    }

    if (val) {
      var date = new Date(val);
      return Ember.String.htmlSafe((0, _formatter.autoUpdatingRelativeAge)(date, {
        format: format,
        title: title,
        leaveAgo: leaveAgo
      }));
    }
  });
});