define("discourse/routes/user-private-messages-tags", ["exports", "discourse/routes/discourse", "I18n", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _discourse, _I18n, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    model: function model() {
      var username = this.modelFor("user").get("username_lower");
      return (0, _ajax.ajax)("/tags/personal_messages/".concat(username)).then(function (result) {
        return result.tags.map(function (tag) {
          return Ember.Object.create(tag);
        });
      }).catch(_ajaxError.popupAjaxError);
    },
    titleToken: function titleToken() {
      return [_I18n.default.t("tagging.tags"), _I18n.default.t("user.private_messages")];
    },
    setupController: function setupController(controller, model) {
      this.controllerFor("user-private-messages-tags").setProperties({
        model: model,
        sortProperties: this.siteSettings.tags_sort_alphabetically ? ["id"] : ["count:desc", "id"],
        tagsForUser: this.modelFor("user").get("username_lower")
      });
      this.controllerFor("user-topics-list").setProperties({
        showToggleBulkSelect: false,
        selected: []
      });
      this.controllerFor("user-private-messages").setProperties({
        pmView: "tags"
      });
    }
  });

  _exports.default = _default;
});