define("discourse/components/hide-modal-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      $(".d-modal.fixed-modal").modal("hide").addClass("hidden");
    }
  });

  _exports.default = _default;
});