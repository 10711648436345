define("discourse/templates/navigation/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "03SqAU7B",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-section\",null,[[\"bodyClass\",\"class\",\"scrollTop\"],[\"navigation-topics\",\"navigation-container\",false]],{\"statements\":[[0,\"  \"],[1,[28,\"d-navigation\",null,[[\"filterMode\",\"canCreateTopic\",\"hasDraft\",\"createTopic\"],[[24,[\"filterMode\"]],[24,[\"canCreateTopic\"]],[24,[\"currentUser\",\"has_topic_draft\"]],[28,\"route-action\",[\"createTopic\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/navigation/default.hbs"
    }
  });

  _exports.default = _default;
});