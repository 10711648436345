define("discourse/lib/show-modal", ["exports", "I18n", "discourse-common/lib/get-owner"], function (_exports, _I18n, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(name, opts) {
    opts = opts || {};
    var container = (0, _getOwner.getOwner)(this); // We use the container here because modals are like singletons
    // in Discourse. Only one can be shown with a particular state.

    var route = container.lookup("route:application");
    var modalController = route.controllerFor("modal");
    modalController.set("modalClass", opts.modalClass || "".concat(Ember.String.dasherize(name).toLowerCase(), "-modal"));
    var controllerName = opts.admin ? "modals/".concat(name) : name;
    modalController.set("name", controllerName);
    var controller = container.lookup("controller:" + controllerName);
    var templateName = opts.templateName || Ember.String.dasherize(name);
    var renderArgs = {
      into: "modal",
      outlet: "modalBody"
    };

    if (controller) {
      renderArgs.controller = controllerName;
    } else {
      // use a basic controller
      renderArgs.controller = "basic-modal-body";
      controller = container.lookup("controller:".concat(renderArgs.controller));
    }

    if (opts.addModalBodyView) {
      renderArgs.view = "modal-body";
    }

    var modalName = "modal/".concat(templateName);
    var fullName = opts.admin ? "admin/templates/".concat(modalName) : modalName;
    route.render(fullName, renderArgs);

    if (opts.title) {
      modalController.set("title", _I18n.default.t(opts.title));
    } else if (opts.titleTranslated) {
      modalController.set("title", opts.titleTranslated);
    } else {
      modalController.set("title", null);
    }

    if (opts.titleAriaElementId) {
      modalController.set("titleAriaElementId", opts.titleAriaElementId);
    }

    if (opts.panels) {
      modalController.setProperties({
        panels: opts.panels,
        selectedPanel: opts.panels[0]
      });

      if (controller.actions.onSelectPanel) {
        modalController.set("onSelectPanel", controller.actions.onSelectPanel.bind(controller));
      }

      modalController.set("modalClass", "".concat(modalController.get("modalClass"), " has-tabs"));
    } else {
      modalController.setProperties({
        panels: [],
        selectedPanel: null
      });
    }

    controller.set("modal", modalController);
    var model = opts.model;

    if (model) {
      controller.set("model", model);
    }

    if (controller.onShow) {
      controller.onShow();
    }

    controller.set("flashMessage", null);
    return controller;
  }
});