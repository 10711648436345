define("discourse/templates/components/reviewable-created-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hmlGDXmO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"created-by\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"user\"]]],null,{\"statements\":[[0,\"    \"],[4,\"user-link\",null,[[\"user\"],[[24,[\"user\"]]]],{\"statements\":[[1,[28,\"avatar\",[[24,[\"user\"]]],[[\"imageSize\"],[\"large\"]]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"d-icon\",[\"far-trash-alt\"],[[\"class\"],[\"deleted-user-avatar\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-created-by.hbs"
    }
  });

  _exports.default = _default;
});