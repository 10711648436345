define("discourse/components/groups-form-interaction-fields", ["exports", "I18n", "discourse/lib/notification-levels", "discourse-common/utils/decorators"], function (_exports, _I18n, _notificationLevels, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("model.default_notification_level", "watchingNotificationLevel"), _dec2 = (0, _decorators.default)("siteSettings.email_in", "model.automatic", "currentUser.admin"), _dec3 = (0, _decorators.default)("model.isCreated", "model.can_admin_group", "currentUser.can_create_group"), _dec4 = (0, _decorators.default)("membersVisibilityLevel"), (_obj = {
    init: function init() {
      this._super.apply(this, arguments);

      this.visibilityLevelOptions = [{
        name: _I18n.default.t("admin.groups.manage.interaction.visibility_levels.public"),
        value: 0
      }, {
        name: _I18n.default.t("admin.groups.manage.interaction.visibility_levels.logged_on_users"),
        value: 1
      }, {
        name: _I18n.default.t("admin.groups.manage.interaction.visibility_levels.members"),
        value: 2
      }, {
        name: _I18n.default.t("admin.groups.manage.interaction.visibility_levels.staff"),
        value: 3
      }, {
        name: _I18n.default.t("admin.groups.manage.interaction.visibility_levels.owners"),
        value: 4
      }];
      this.aliasLevelOptions = [{
        name: _I18n.default.t("groups.alias_levels.nobody"),
        value: 0
      }, {
        name: _I18n.default.t("groups.alias_levels.only_admins"),
        value: 1
      }, {
        name: _I18n.default.t("groups.alias_levels.mods_and_admins"),
        value: 2
      }, {
        name: _I18n.default.t("groups.alias_levels.members_mods_and_admins"),
        value: 3
      }, {
        name: _I18n.default.t("groups.alias_levels.owners_mods_and_admins"),
        value: 4
      }, {
        name: _I18n.default.t("groups.alias_levels.everyone"),
        value: 99
      }];
      this.watchingNotificationLevel = _notificationLevels.NotificationLevels.WATCHING;
    },
    membersVisibilityLevel: Ember.computed.or("model.members_visibility_level", "visibilityLevelOptions.firstObject.value"),
    messageableLevel: Ember.computed.or("model.messageable_level", "aliasLevelOptions.firstObject.value"),
    mentionableLevel: Ember.computed.or("model.mentionable_level", "aliasLevelOptions.firstObject.value"),
    defaultNotificationLevel: function defaultNotificationLevel(_defaultNotificationLevel, watchingNotificationLevel) {
      if (Object.values(_notificationLevels.NotificationLevels).includes(_defaultNotificationLevel)) {
        return _defaultNotificationLevel;
      }

      return watchingNotificationLevel;
    },
    showEmailSettings: function showEmailSettings(emailIn, automatic, isAdmin) {
      return emailIn && isAdmin && !automatic;
    },
    canAdminGroup: function canAdminGroup(isCreated, canAdmin, canCreate) {
      return !isCreated && canCreate || isCreated && canAdmin;
    },
    membersVisibilityPrivate: function membersVisibilityPrivate(membersVisibilityLevel) {
      return membersVisibilityLevel !== this.visibilityLevelOptions.firstObject.value;
    }
  }, (_applyDecoratedDescriptor(_obj, "defaultNotificationLevel", [_dec], Object.getOwnPropertyDescriptor(_obj, "defaultNotificationLevel"), _obj), _applyDecoratedDescriptor(_obj, "showEmailSettings", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showEmailSettings"), _obj), _applyDecoratedDescriptor(_obj, "canAdminGroup", [_dec3], Object.getOwnPropertyDescriptor(_obj, "canAdminGroup"), _obj), _applyDecoratedDescriptor(_obj, "membersVisibilityPrivate", [_dec4], Object.getOwnPropertyDescriptor(_obj, "membersVisibilityPrivate"), _obj)), _obj)));

  _exports.default = _default;
});