define("discourse/components/quote-button", ["exports", "discourse/lib/after-transition", "discourse/lib/computed", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/utilities", "I18n", "discourse-common/config/environment", "discourse/mixins/key-enter-escape", "discourse/lib/sharing", "discourse-common/utils/decorators", "discourse-common/lib/debounce", "discourse-common/lib/get-url", "discourse/lib/to-markdown"], function (_exports, _afterTransition, _computed, _ajax, _ajaxError, _utilities, _I18n, _environment, _keyEnterEscape, _sharing, _decorators, _debounce, _getUrl, _toMarkdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function getQuoteTitle(element) {
    var titleEl = element.querySelector(".title");

    if (!titleEl) {
      return;
    }

    var titleLink = titleEl.querySelector("a:not(.back)");

    if (titleLink) {
      return titleLink.textContent.trim();
    }

    return titleEl.textContent.trim().replace(/:$/, "");
  }

  function fixQuotes(str) {
    // u+201c “
    // u+201d ”
    return str.replace(/[\u201C\u201D]/g, '"');
  }

  function regexSafeStr(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  var _default = Ember.Component.extend(_keyEnterEscape.default, (_dec = (0, _decorators.default)("topic.{isPrivateMessage,invisible,category}"), _dec2 = (0, _decorators.default)("topic.isPrivateMessage"), _dec3 = (0, _decorators.default)("topic.{isPrivateMessage,invisible,category}"), _dec4 = (0, _decorators.default)("topic.{id,slug}", "quoteState"), _dec5 = (0, _decorators.default)("topic.details.can_create_post", "composerVisible"), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_obj = {
    classNames: ["quote-button"],
    classNameBindings: ["visible", "_displayFastEditInput:fast-editing", "animated"],
    visible: false,
    animated: false,
    privateCategory: Ember.computed.alias("topic.category.read_restricted"),
    editPost: null,
    _isFastEditable: false,
    _displayFastEditInput: false,
    _fastEditInitalSelection: null,
    _fastEditNewSelection: null,
    _isSavingFastEdit: false,
    _canEditPost: false,
    _saveEditButtonTitle: _I18n.default.t("composer.title", {
      modifier: (0, _utilities.translateModKey)("Meta+")
    }),
    _isMouseDown: false,
    _reselected: false,
    _hideButton: function _hideButton() {
      this.quoteState.clear();
      this.set("visible", false);
      this.set("animated", false);
      this.set("_isFastEditable", false);
      this.set("_displayFastEditInput", false);
      this.set("_fastEditInitalSelection", null);
      this.set("_fastEditNewSelection", null);
    },
    _getRangeBoundaryRect: function _getRangeBoundaryRect(range, atEnd) {
      // Don't mess with the original range as it results in weird behaviours
      // where certain browsers will deselect the selection
      var clone = range.cloneRange(range); // create a marker element containing a single invisible character

      var markerElement = document.createElement("span");
      markerElement.appendChild(document.createTextNode("\uFEFF")); // on mobile, collapse the range at the end of the selection

      if (atEnd) {
        clone.collapse();
      } // insert the marker


      clone.insertNode(markerElement); // retrieve the position of the marker

      var boundaryRect = markerElement.getBoundingClientRect();
      boundaryRect.x += document.documentElement.scrollLeft;
      boundaryRect.y += document.documentElement.scrollTop; // remove the marker

      var parent = markerElement.parentNode;
      parent.removeChild(markerElement); // merge back all text nodes so they don't get messed up

      parent.normalize(); // work around Safari that would sometimes lose the selection

      if (this.capabilities.isSafari) {
        this._reselected = true;
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }

      return boundaryRect;
    },
    _selectionChanged: function _selectionChanged() {
      var _this = this;

      if (this._displayFastEditInput) {
        return;
      }

      var quoteState = this.quoteState;
      var selection = window.getSelection();

      if (selection.isCollapsed) {
        if (this.visible) {
          this._hideButton();
        }

        return;
      } // ensure we selected content inside 1 post *only*


      var firstRange, postId;

      for (var r = 0; r < selection.rangeCount; r++) {
        var range = selection.getRangeAt(r);
        var $selectionStart = $(range.startContainer);
        var $ancestor = $(range.commonAncestorContainer);

        if ($selectionStart.closest(".cooked").length === 0) {
          return;
        }

        firstRange = firstRange || range;
        postId = postId || $ancestor.closest(".boxed, .reply").data("post-id");

        if ($ancestor.closest(".contents").length === 0 || !postId) {
          if (this.visible) {
            this._hideButton();
          }

          return;
        }
      }

      var _selectedElement = (0, _utilities.selectedElement)();

      var _selectedText = (0, _utilities.selectedText)();

      var $selectedElement = $(_selectedElement);
      var cooked = $selectedElement.find(".cooked")[0] || $selectedElement.closest(".cooked")[0]; // computing markdown takes a lot of time on long posts
      // this code attempts to compute it only when we can't fast track

      var opts = {
        full: (0, _utilities.selectedRange)().startOffset > 0 ? false : _selectedText === (0, _toMarkdown.default)(cooked.innerHTML)
      };

      for (var element = _selectedElement; element && element.tagName !== "ARTICLE"; element = element.parentElement) {
        if (element.tagName === "ASIDE" && element.classList.contains("quote")) {
          opts.username = element.dataset.username || getQuoteTitle(element);
          opts.post = element.dataset.post;
          opts.topic = element.dataset.topic;
          break;
        }
      }

      quoteState.selected(postId, _selectedText, opts);
      this.set("visible", quoteState.buffer.length > 0);

      if (this.siteSettings.enable_fast_edit) {
        var _this$topic$postStrea;

        this.set("_canEditPost", (_this$topic$postStrea = this.topic.postStream.findLoadedPost(postId)) === null || _this$topic$postStrea === void 0 ? void 0 : _this$topic$postStrea.can_edit);

        if (this._canEditPost) {
          var regexp = new RegExp(regexSafeStr(quoteState.buffer), "gi");
          var matches = cooked.innerHTML.match(regexp);

          if (quoteState.buffer.length < 1 || quoteState.buffer.includes("|") || // tables are too complex
          quoteState.buffer.match(/\n/g) || // linebreaks are too complex
          (matches === null || matches === void 0 ? void 0 : matches.length) > 1 // duplicates are too complex
          ) {
              this.set("_isFastEditable", false);
              this.set("_fastEditInitalSelection", null);
              this.set("_fastEditNewSelection", null);
            } else if ((matches === null || matches === void 0 ? void 0 : matches.length) === 1) {
            this.set("_isFastEditable", true);
            this.set("_fastEditInitalSelection", quoteState.buffer);
            this.set("_fastEditNewSelection", quoteState.buffer);
          }
        }
      } // avoid hard loops in quote selection unconditionally
      // this can happen if you triple click text in firefox


      if (this._prevSelection === _selectedText) {
        return;
      }

      this._prevSelection = _selectedText; // on Desktop, shows the button at the beginning of the selection
      // on Mobile, shows the button at the end of the selection

      var isMobileDevice = this.site.isMobileDevice;
      var _this$capabilities = this.capabilities,
          isIOS = _this$capabilities.isIOS,
          isAndroid = _this$capabilities.isAndroid,
          isOpera = _this$capabilities.isOpera;
      var showAtEnd = isMobileDevice || isIOS || isAndroid || isOpera;

      var boundaryPosition = this._getRangeBoundaryRect(firstRange, showAtEnd); // change the position of the button


      Ember.run.schedule("afterRender", function () {
        if (!_this.element || _this.isDestroying || _this.isDestroyed) {
          return;
        }

        var top = 0;
        var left = 0;
        var pxFromSelection = 5;

        if (showAtEnd) {
          // The selection-handles on iOS have a hit area of ~50px radius
          // so we need to make sure our buttons are outside that radius
          // Apply the same logic on all mobile devices for consistency
          top = boundaryPosition.bottom + pxFromSelection;
          left = boundaryPosition.left;
          var safeRadius = 50;
          var topicArea = document.querySelector(".topic-area").getBoundingClientRect();
          topicArea.x += document.documentElement.scrollLeft;
          topicArea.y += document.documentElement.scrollTop;
          var endHandlePosition = boundaryPosition;
          var width = _this.element.clientWidth;
          var possiblePositions = [{
            // move to left
            top: top,
            left: left - width - safeRadius
          }, {
            // move to right
            top: top,
            left: left + safeRadius
          }, {
            // centered below end handle
            top: top + safeRadius,
            left: left - width / 2
          }];

          for (var _i = 0, _possiblePositions = possiblePositions; _i < _possiblePositions.length; _i++) {
            var pos = _possiblePositions[_i];
            // Ensure buttons are entirely within the .topic-area
            pos.left = Math.max(topicArea.left, pos.left);
            pos.left = Math.min(topicArea.right - width, pos.left);
            var clearOfStartHandle = true;

            if (isAndroid) {
              // On android, the start-selection handle extends below the line, so we need to avoid it as well:
              var startHandlePosition = _this._getRangeBoundaryRect(firstRange, false);

              clearOfStartHandle = pos.top - startHandlePosition.bottom >= safeRadius || pos.left + width <= startHandlePosition.left - safeRadius || pos.left >= startHandlePosition.left + safeRadius;
            }

            var clearOfEndHandle = pos.top - endHandlePosition.top >= safeRadius || pos.left + width <= endHandlePosition.left - safeRadius || pos.left >= endHandlePosition.left + safeRadius;

            if (clearOfStartHandle && clearOfEndHandle) {
              left = pos.left;
              top = pos.top;
              break;
            }
          }
        } else {
          // Desktop
          top = boundaryPosition.top - _this.element.clientHeight - pxFromSelection;
          left = boundaryPosition.left;
        }

        Object.assign(_this.element.style, {
          top: "".concat(top, "px"),
          left: "".concat(left, "px")
        });

        if (!_this.animated) {
          // We only enable CSS transitions after the initial positioning
          // otherwise the button can appear to fly in from off-screen
          Ember.run.next(function () {
            return _this.set("animated", true);
          });
        }
      });
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var _this$capabilities2 = this.capabilities,
          isWinphone = _this$capabilities2.isWinphone,
          isAndroid = _this$capabilities2.isAndroid;
      var wait = isWinphone || isAndroid ? _environment.INPUT_DELAY : 25;

      var onSelectionChanged = function onSelectionChanged() {
        (0, _debounce.default)(_this2, _this2._selectionChanged, wait);
      };

      $(document).on("mousedown.quote-button", function (e) {
        _this2._prevSelection = null;
        _this2._isMouseDown = true;
        _this2._reselected = false; // prevents fast-edit input event to trigger mousedown

        if (e.target.classList.contains("fast-edit-input")) {
          return;
        }

        if ($(e.target).closest(".quote-button, .create, .share, .reply-new").length === 0) {
          _this2._hideButton();
        }
      }).on("mouseup.quote-button", function (e) {
        // prevents fast-edit input event to trigger mouseup
        if (e.target.classList.contains("fast-edit-input")) {
          return;
        }

        _this2._prevSelection = null;
        _this2._isMouseDown = false;
        onSelectionChanged();
      }).on("selectionchange.quote-button", function () {
        if (!_this2._isMouseDown && !_this2._reselected) {
          onSelectionChanged();
        }
      });
      this.appEvents.on("quote-button:quote", this, "insertQuote");
      this.appEvents.on("quote-button:edit", this, "_toggleFastEditForm");
    },
    willDestroyElement: function willDestroyElement() {
      $(document).off("mousedown.quote-button").off("mouseup.quote-button").off("selectionchange.quote-button");
      this.appEvents.off("quote-button:quote", this, "insertQuote");
      this.appEvents.off("quote-button:edit", this, "_toggleFastEditForm");
    },
    quoteSharingEnabled: function quoteSharingEnabled(topic) {
      if (this.site.mobileView || this.siteSettings.share_quote_visibility === "none" || this.currentUser && this.siteSettings.share_quote_visibility === "anonymous" || this.quoteSharingSources.length === 0 || this.privateCategory || this.currentUser && topic.invisible) {
        return false;
      }

      return true;
    },
    quoteSharingSources: function quoteSharingSources(isPM) {
      return _sharing.default.activeSources(this.siteSettings.share_quote_buttons, this.siteSettings.login_required || isPM);
    },
    quoteSharingShowLabel: function quoteSharingShowLabel() {
      return this.quoteSharingSources.length > 1;
    },
    shareUrl: function shareUrl(topic, quoteState) {
      var postId = quoteState.postId;
      var postNumber = topic.postStream.findLoadedPost(postId).post_number;
      return (0, _getUrl.getAbsoluteURL)((0, _utilities.postUrl)(topic.slug, topic.id, postNumber));
    },
    embedQuoteButton: function embedQuoteButton(canCreatePost, composerOpened) {
      return (canCreatePost || composerOpened) && this.currentUser && this.currentUser.get("enable_quoting");
    },
    _saveFastEditDisabled: (0, _computed.propertyEqual)("_fastEditInitalSelection", "_fastEditNewSelection"),
    insertQuote: function insertQuote() {
      var _this3 = this;

      this.attrs.selectText().then(function () {
        return _this3._hideButton();
      });
    },
    _toggleFastEditForm: function _toggleFastEditForm() {
      var _this4 = this;

      if (this._isFastEditable) {
        this.toggleProperty("_displayFastEditInput");
        Ember.run.schedule("afterRender", function () {
          var _document$querySelect;

          if (_this4.site.mobileView) {
            _this4.element.style.left = "".concat((window.innerWidth - _this4.element.clientWidth) / 2, "px");
          }

          (_document$querySelect = document.querySelector("#fast-edit-input")) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.focus();
        });
      } else {
        var postId = this.quoteState.postId;
        var postModel = this.topic.postStream.findLoadedPost(postId);
        return (0, _ajax.ajax)("/posts/".concat(postModel.id), {
          type: "GET",
          cache: false
        }).then(function (result) {
          var bestIndex = 0;
          var rows = result.raw.split("\n"); // selecting even a part of the text of a list item will include
          // "* " at the beginning of the buffer, we remove it to be able
          // to find it in row

          var buffer = fixQuotes(_this4.quoteState.buffer.split("\n")[0].replace(/^\* /, ""));
          rows.some(function (row, index) {
            if (row.length && row.includes(buffer)) {
              bestIndex = index;
              return true;
            }
          });
          _this4 === null || _this4 === void 0 ? void 0 : _this4.editPost(postModel);
          (0, _afterTransition.default)(document.querySelector("#reply-control"), function () {
            var textarea = document.querySelector(".d-editor-input");

            if (!textarea || _this4.isDestroyed || _this4.isDestroying) {
              return;
            } // best index brings us to one row before as slice start from 1
            // we add 1 to be at the beginning of next line, unless we start from top


            (0, _utilities.setCaretPosition)(textarea, rows.slice(0, bestIndex).join("\n").length + (bestIndex > 0 ? 1 : 0)); // ensures we correctly scroll to caret and reloads composer
            // if we do another selection/edit

            textarea.blur();
            textarea.focus();
          });
        });
      }
    },
    _saveFastEdit: function _saveFastEdit() {
      var _this$quoteState,
          _this5 = this;

      var postId = (_this$quoteState = this.quoteState) === null || _this$quoteState === void 0 ? void 0 : _this$quoteState.postId;
      var postModel = this.topic.postStream.findLoadedPost(postId);
      this.set("_isSavingFastEdit", true);
      return (0, _ajax.ajax)("/posts/".concat(postModel.id), {
        type: "GET",
        cache: false
      }).then(function (result) {
        var newRaw = result.raw.replace(fixQuotes(_this5._fastEditInitalSelection), fixQuotes(_this5._fastEditNewSelection));
        postModel.save({
          raw: newRaw
        }).catch(_ajaxError.popupAjaxError).finally(function () {
          _this5.set("_isSavingFastEdit", false);

          _this5._hideButton();
        });
      }).catch(_ajaxError.popupAjaxError);
    },
    save: function save() {
      if (this._displayFastEditInput && !this._saveFastEditDisabled) {
        this._saveFastEdit();
      }
    },
    cancelled: function cancelled() {
      this._hideButton();
    },
    share: function share(source) {
      _sharing.default.shareSource(source, {
        url: this.shareUrl,
        title: this.topic.title,
        quote: window.getSelection().toString()
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "quoteSharingEnabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "quoteSharingEnabled"), _obj), _applyDecoratedDescriptor(_obj, "quoteSharingSources", [_dec2], Object.getOwnPropertyDescriptor(_obj, "quoteSharingSources"), _obj), _applyDecoratedDescriptor(_obj, "quoteSharingShowLabel", [_dec3], Object.getOwnPropertyDescriptor(_obj, "quoteSharingShowLabel"), _obj), _applyDecoratedDescriptor(_obj, "shareUrl", [_dec4], Object.getOwnPropertyDescriptor(_obj, "shareUrl"), _obj), _applyDecoratedDescriptor(_obj, "embedQuoteButton", [_dec5], Object.getOwnPropertyDescriptor(_obj, "embedQuoteButton"), _obj), _applyDecoratedDescriptor(_obj, "insertQuote", [_dec6], Object.getOwnPropertyDescriptor(_obj, "insertQuote"), _obj), _applyDecoratedDescriptor(_obj, "_toggleFastEditForm", [_dec7], Object.getOwnPropertyDescriptor(_obj, "_toggleFastEditForm"), _obj), _applyDecoratedDescriptor(_obj, "_saveFastEdit", [_dec8], Object.getOwnPropertyDescriptor(_obj, "_saveFastEdit"), _obj), _applyDecoratedDescriptor(_obj, "save", [_dec9], Object.getOwnPropertyDescriptor(_obj, "save"), _obj), _applyDecoratedDescriptor(_obj, "cancelled", [_dec10], Object.getOwnPropertyDescriptor(_obj, "cancelled"), _obj), _applyDecoratedDescriptor(_obj, "share", [_dec11], Object.getOwnPropertyDescriptor(_obj, "share"), _obj)), _obj)));

  _exports.default = _default;
});