define("discourse/controllers/badges/index", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function badgeKey(badge) {
    var pos = badge.get("badge_grouping.position");
    var type = badge.get("badge_type_id");
    var name = badge.get("name");
    return ("000" + pos).slice(-4) + (10 - type) + name;
  }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("model"), (_obj = {
    badgeGroups: function badgeGroups(model) {
      var sorted = model.sort(function (a, b) {
        return badgeKey(a).localeCompare(badgeKey(b));
      });
      var grouped = [];
      var group = [];
      var groupId;
      sorted.forEach(function (badge) {
        if (groupId !== badge.badge_grouping_id) {
          if (group && group.length > 0) {
            grouped.push({
              badges: group,
              badgeGrouping: group[0].badge_grouping
            });
          }

          group = [];
          groupId = badge.badge_grouping_id;
        }

        group.push(badge);
      });

      if (group && group.length > 0) {
        grouped.push({
          badges: group,
          badgeGrouping: group[0].badge_grouping
        });
      }

      return grouped;
    }
  }, (_applyDecoratedDescriptor(_obj, "badgeGroups", [_dec], Object.getOwnPropertyDescriptor(_obj, "badgeGroups"), _obj)), _obj)));

  _exports.default = _default;
});