define("discourse/components/generated-invite-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      $(this.element.querySelector(".invite-link-input")).select().focus();
    }
  });

  _exports.default = _default;
});