define("discourse/routes/topic-by-slug-or-id", ["exports", "discourse/models/topic", "discourse/routes/discourse"], function (_exports, _topic, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    router: Ember.inject.service(),
    model: function model(params) {
      if (params.slugOrId.match(_topic.ID_CONSTRAINT)) {
        return {
          url: "/t/topic/".concat(params.slugOrId)
        };
      } else {
        return _topic.default.idForSlug(params.slugOrId).then(function (data) {
          return {
            url: "/t/".concat(data.slug, "/").concat(data.topic_id)
          };
        });
      }
    },
    afterModel: function afterModel(result) {
      this.router.transitionTo(result.url);
    }
  });

  _exports.default = _default;
});