define("discourse/lib/discourse-location", ["exports", "discourse/lib/utilities", "discourse-common/lib/get-url"], function (_exports, _utilities, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var popstateFired = false;
  var supportsHistoryState = window.history && "state" in window.history;
  var popstateCallbacks = [];
  /**
    `Ember.DiscourseLocation` implements the location API using the browser's
    `history.pushState` API.
  
    @class DiscourseLocation
    @namespace Discourse
    @extends @ember/object
  */

  var DiscourseLocation = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set("location", this.location || window.location);
      this.initState();
    },

    /**
      @private
       Used to set state on first call to setURL
       @method initState
    */
    initState: function initState() {
      var history = this.history || window.history;

      if (history && history.scrollRestoration) {
        history.scrollRestoration = "manual";
      }

      this.set("history", history);
      var url = this.formatURL(this.getURL());

      if (this.location && this.location.hash) {
        url += this.location.hash;
      }

      this.replaceState(url);
    },

    /**
      Will be pre-pended to path upon state change
       @property rootURL
      @default '/'
    */
    rootURL: "/",

    /**
      @private
       Returns the current `location.pathname` without rootURL
       @method getURL
    */
    getURL: function getURL() {
      var url = (0, _getUrl.withoutPrefix)(this.location.pathname);
      var search = this.location.search || "";
      url += search;
      url = url.replace(/\/\//g, "/"); // remove extra slashes

      return url;
    },

    /**
      @private
       Uses `history.pushState` to update the url without a page reload.
       @method setURL
      @param path {String}
    */
    setURL: function setURL(path) {
      var state = this.getState();
      path = this.formatURL(path);

      if (state && state.path !== path) {
        var paths = [path, state.path];

        if (!(paths.includes("/") && paths.includes("/".concat((0, _utilities.defaultHomepage)())))) {
          this.pushState(path);
        }
      }
    },

    /**
      @private
       Uses `history.replaceState` to update the url without a page reload
      or history modification.
       @method replaceURL
      @param path {String}
    */
    replaceURL: function replaceURL(path) {
      var state = this.getState();
      path = this.formatURL(path);

      if (!state || state.path !== path) {
        this.replaceState(path);
      }
    },

    /**
     @private
      Get the current `history.state`
     Polyfill checks for native browser support and falls back to retrieving
     from a private _historyState constiable
      @method getState
    */
    getState: function getState() {
      return supportsHistoryState ? this.history.state : this._historyState;
    },

    /**
     @private
      Pushes a new state
      @method pushState
     @param path {String}
    */
    pushState: function pushState(path) {
      var state = {
        path: path
      }; // store state if browser doesn't support `history.state`

      if (!supportsHistoryState) {
        this._historyState = state;
      } else {
        this.history.pushState(state, null, path);
      } // used for webkit workaround


      this._previousURL = this.getURL();
    },

    /**
     @private
      Replaces the current state
      @method replaceState
     @param path {String}
    */
    replaceState: function replaceState(path) {
      var state = {
        path: path
      }; // store state if browser doesn't support `history.state`

      if (!supportsHistoryState) {
        this._historyState = state;
      } else {
        this.history.replaceState(state, null, path);
      } // used for webkit workaround


      this._previousURL = this.getURL();
    },

    /**
      @private
       Register a callback to be invoked whenever the browser
      history changes, including using forward and back buttons.
       @method onUpdateURL
      @param callback {Function}
    */
    onUpdateURL: function onUpdateURL(callback) {
      var _this = this;

      var guid = Ember.guidFor(this);
      $(window).on("popstate.ember-location-".concat(guid), function () {
        var url = _this.getURL(); // Ignore initial page load popstate event in Chrome


        if (!popstateFired) {
          popstateFired = true;

          if (url === _this._previousURL) {
            return;
          }
        }

        popstateCallbacks.forEach(function (cb) {
          return cb(url);
        });
        callback(url);
      });
    },

    /**
      @private
       Used when using `{{action}}` helper.  The url is always appended to the rootURL.
       @method formatURL
      @param url {String}
    */
    formatURL: function formatURL(url) {
      var rootURL = this.rootURL;

      if (url !== "") {
        rootURL = rootURL.replace(/\/$/, "");

        if (rootURL.length > 0 && url.indexOf(rootURL + "/") === 0) {
          rootURL = "";
        }
      }

      return rootURL + url;
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var guid = Ember.guidFor(this);
      $(window).off("popstate.ember-location-".concat(guid));
    }
  });
  var _default = DiscourseLocation;
  _exports.default = _default;
});