define("discourse/components/user-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "a",
    attributeBindings: ["href", "data-user-card"],
    href: Ember.computed.alias("user.path"),
    "data-user-card": Ember.computed.alias("user.username")
  });

  _exports.default = _default;
});