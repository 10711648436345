define("discourse/lib/optional-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(name) {
    return Ember.computed(function (defaultName) {
      return Ember.getOwner(this).lookup("service:".concat(name || Ember.String.dasherize(defaultName)));
    });
  }
});