define("discourse/controllers/groups-index", ["exports", "I18n", "discourse-common/config/environment", "discourse-common/utils/decorators", "discourse-common/lib/debounce"], function (_exports, _I18n, _environment, _decorators, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("groups.extras.type_filters"), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    application: Ember.inject.controller(),
    queryParams: ["order", "asc", "filter", "type"],
    order: null,
    asc: null,
    filter: "",
    type: null,
    groups: null,
    isLoading: false,
    types: function types(typeFilters) {
      var types = [];

      if (typeFilters) {
        typeFilters.forEach(function (type) {
          return types.push({
            id: type,
            name: _I18n.default.t("groups.index.".concat(type, "_groups"))
          });
        });
      }

      return types;
    },
    loadGroups: function loadGroups(params) {
      var _this = this;

      this.set("isLoading", true);
      this.store.findAll("group", params).then(function (groups) {
        _this.set("groups", groups);

        if (groups.canLoadMore) {
          _this.set("application.showFooter", !groups.canLoadMore);
        }
      }).finally(function () {
        return _this.set("isLoading", false);
      });
    },
    onFilterChanged: function onFilterChanged(filter) {
      (0, _debounce.default)(this, this._debouncedFilter, filter, _environment.INPUT_DELAY);
    },
    loadMore: function loadMore() {
      this.groups && this.groups.loadMore();
    },
    new: function _new() {
      this.transitionToRoute("groups.new");
    },
    _debouncedFilter: function _debouncedFilter(filter) {
      this.set("filter", filter);
    }
  }, (_applyDecoratedDescriptor(_obj, "types", [_dec], Object.getOwnPropertyDescriptor(_obj, "types"), _obj), _applyDecoratedDescriptor(_obj, "onFilterChanged", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onFilterChanged"), _obj), _applyDecoratedDescriptor(_obj, "loadMore", [_dec3], Object.getOwnPropertyDescriptor(_obj, "loadMore"), _obj), _applyDecoratedDescriptor(_obj, "new", [_dec4], Object.getOwnPropertyDescriptor(_obj, "new"), _obj)), _obj)));

  _exports.default = _default;
});