define("discourse/controllers/group", ["exports", "I18n", "bootbox", "discourse-common/lib/deprecated", "discourse-common/utils/decorators"], function (_exports, _I18n, _bootbox, _deprecated, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var Tab = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        route: this.route || "group.".concat(this.name),
        message: _I18n.default.t("groups.".concat(this.i18nKey || this.name))
      });
    }
  });

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("showMessages", "model.user_count", "model.request_count", "canManageGroup", "model.allow_membership_requests"), _dec2 = (0, _decorators.default)("model.has_messages", "model.is_group_user"), _dec3 = (0, _decorators.default)("model.displayName", "model.full_name"), _dec4 = (0, _decorators.default)("model.messageable"), _dec5 = (0, _decorators.default)("model", "model.automatic"), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_obj = {
    application: Ember.inject.controller(),
    counts: null,
    showing: "members",
    destroying: null,
    showTooltip: false,
    tabs: function tabs(showMessages, userCount, requestCount, canManageGroup, allowMembershipRequests) {
      var membersTab = Tab.create({
        name: "members",
        route: "group.index",
        icon: "users",
        i18nKey: "members.title",
        count: userCount
      });
      var defaultTabs = [membersTab, Tab.create({
        name: "activity"
      })];

      if (canManageGroup && allowMembershipRequests) {
        defaultTabs.push(Tab.create({
          name: "requests",
          i18nKey: "requests.title",
          icon: "user-plus",
          count: requestCount
        }));
      }

      if (showMessages) {
        defaultTabs.push(Tab.create({
          name: "messages",
          i18nKey: "messages"
        }));
      }

      if (canManageGroup) {
        defaultTabs.push(Tab.create({
          name: "manage",
          i18nKey: "manage.title",
          icon: "wrench"
        }));
      }

      defaultTabs.push(Tab.create({
        name: "permissions",
        i18nKey: "permissions.title"
      }));
      return defaultTabs;
    },
    showMessages: function showMessages(hasMessages, isGroupUser) {
      if (!this.siteSettings.enable_personal_messages) {
        return false;
      }

      if (!hasMessages) {
        return false;
      }

      return isGroupUser || this.currentUser && this.currentUser.admin;
    },
    groupName: function groupName(displayName, fullName) {
      return (fullName || displayName).capitalize();
    },
    displayGroupMessageButton: function displayGroupMessageButton(messageable) {
      return this.currentUser && messageable;
    },
    canManageGroup: function canManageGroup(model, automatic) {
      return this.currentUser && (this.currentUser.canManageGroup(model) || model.can_admin_group && automatic);
    },
    messageGroup: function messageGroup() {
      this.send("createNewMessageViaParams", {
        recipients: this.get("model.name"),
        hasGroups: true
      });
    },
    destroyGroup: function destroyGroup() {
      var _this = this;

      this.set("destroying", true);
      var model = this.model;

      var message = _I18n.default.t("admin.groups.delete_confirm");

      if (model.has_messages && model.message_count > 0) {
        message = _I18n.default.t("admin.groups.delete_with_messages_confirm", {
          count: model.message_count
        });
      }

      _bootbox.default.confirm(message, _I18n.default.t("no_value"), _I18n.default.t("yes_value"), function (confirmed) {
        if (confirmed) {
          model.destroy().then(function () {
            return _this.transitionToRoute("groups.index");
          }).catch(function (error) {
            // eslint-disable-next-line no-console
            console.error(error);

            _bootbox.default.alert(_I18n.default.t("admin.groups.delete_failed"));
          }).finally(function () {
            return _this.set("destroying", false);
          });
        } else {
          _this.set("destroying", false);
        }
      });
    },
    toggleDeleteTooltip: function toggleDeleteTooltip() {
      this.toggleProperty("showTooltip");
    },
    actions: {
      destroy: function destroy() {
        (0, _deprecated.default)("Use `destroyGroup` action instead of `destroy`.", {
          since: "2.5.0",
          dropFrom: "2.6.0"
        });
        this.destroyGroup();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "tabs", [_dec], Object.getOwnPropertyDescriptor(_obj, "tabs"), _obj), _applyDecoratedDescriptor(_obj, "showMessages", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showMessages"), _obj), _applyDecoratedDescriptor(_obj, "groupName", [_dec3], Object.getOwnPropertyDescriptor(_obj, "groupName"), _obj), _applyDecoratedDescriptor(_obj, "displayGroupMessageButton", [_dec4], Object.getOwnPropertyDescriptor(_obj, "displayGroupMessageButton"), _obj), _applyDecoratedDescriptor(_obj, "canManageGroup", [_dec5], Object.getOwnPropertyDescriptor(_obj, "canManageGroup"), _obj), _applyDecoratedDescriptor(_obj, "messageGroup", [_dec6], Object.getOwnPropertyDescriptor(_obj, "messageGroup"), _obj), _applyDecoratedDescriptor(_obj, "destroyGroup", [_dec7], Object.getOwnPropertyDescriptor(_obj, "destroyGroup"), _obj), _applyDecoratedDescriptor(_obj, "toggleDeleteTooltip", [_dec8], Object.getOwnPropertyDescriptor(_obj, "toggleDeleteTooltip"), _obj)), _obj)));

  _exports.default = _default;
});