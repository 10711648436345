define("discourse/templates/mobile/components/sub-category-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yzgNMR8K",
    "block": "{\"symbols\":[\"subcategory\"],\"statements\":[[4,\"unless\",[[24,[\"isHidden\"]]],null,{\"statements\":[[0,\"  \"],[7,\"tr\",true],[11,\"data-category-id\",[24,[\"category\",\"id\"]]],[11,\"style\",[28,\"border-color\",[[24,[\"category\",\"color\"]]],null]],[11,\"class\",[29,[\"subcategory-list-item category \",[28,\"if\",[[24,[\"isMuted\"]],\"muted\"],null]]]],[8],[0,\"\\n    \"],[7,\"td\",true],[8],[0,\"\\n      \"],[1,[28,\"category-title-link\",null,[[\"tagName\",\"category\"],[\"h4\",[24,[\"category\"]]]]],false],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"subcategories-list\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"category\",\"subcategories\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"subcategories\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"category\",\"subcategories\"]]],null,{\"statements\":[[0,\"              \"],[1,[28,\"sub-category-item\",null,[[\"category\",\"listType\"],[[23,1,[]],[24,[\"listType\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/mobile/components/sub-category-row.hbs"
    }
  });

  _exports.default = _default;
});