define("discourse/lib/computed", ["exports", "I18n", "discourse-common/lib/get-url"], function (_exports, _I18n, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.propertyEqual = propertyEqual;
  _exports.propertyNotEqual = propertyNotEqual;
  _exports.propertyGreaterThan = propertyGreaterThan;
  _exports.propertyLessThan = propertyLessThan;
  _exports.i18n = i18n;
  _exports.htmlSafe = htmlSafe;
  _exports.fmt = fmt;
  _exports.url = url;
  _exports.endWith = endWith;
  _exports.setting = setting;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function addonFmt(str, formats) {
    var cachedFormats = formats;

    if (!Array.isArray(cachedFormats) || arguments.length > 2) {
      cachedFormats = new Array(arguments.length - 1);

      for (var i = 1, l = arguments.length; i < l; i++) {
        cachedFormats[i - 1] = arguments[i];
      }
    } // first, replace any ORDERED replacements.


    var idx = 0; // the current index for non-numerical replacements

    return str.replace(/%@([0-9]+)?/g, function (s, argIndex) {
      argIndex = argIndex ? parseInt(argIndex, 10) - 1 : idx++;
      s = cachedFormats[argIndex];
      return typeof s === "string" ? s : s === null ? "(null)" : s === undefined ? "" : "" + s;
    });
  }
  /**
    Returns whether two properties are equal to each other.
  
    @method propertyEqual
    @params {String} p1 the first property
    @params {String} p2 the second property
    @return {Function} discourseComputedProperty function
  **/


  function propertyEqual(p1, p2) {
    return Ember.computed(p1, p2, function () {
      return this.get(p1) === this.get(p2);
    });
  }
  /**
    Returns whether two properties are not equal to each other.
  
    @method propertyNotEqual
    @params {String} p1 the first property
    @params {String} p2 the second property
    @return {Function} discourseComputedProperty function
  **/


  function propertyNotEqual(p1, p2) {
    return Ember.computed(p1, p2, function () {
      return this.get(p1) !== this.get(p2);
    });
  }

  function propertyGreaterThan(p1, p2) {
    return Ember.computed(p1, p2, function () {
      return this.get(p1) > this.get(p2);
    });
  }

  function propertyLessThan(p1, p2) {
    return Ember.computed(p1, p2, function () {
      return this.get(p1) < this.get(p2);
    });
  }
  /**
    Returns i18n version of a string based on a property.
  
    @method i18n
    @params {String} properties* to format
    @params {String} format the i18n format string
    @return {Function} discourseComputedProperty function
  **/


  function i18n() {
    var _Ember;

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var format = args.pop();
    return (_Ember = Ember).computed.apply(_Ember, args.concat([function () {
      var _this = this;

      return _I18n.default.t(addonFmt.apply(void 0, [format].concat(_toConsumableArray(args.map(function (a) {
        return _this.get(a);
      })))));
    }]));
  }
  /**
    Returns htmlSafe version of a string.
  
    @method htmlSafe
    @params {String} properties* to htmlify
    @return {Function} discourseComputedProperty function
  **/


  function htmlSafe() {
    var _Ember2;

    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return (_Ember2 = Ember).computed.apply(_Ember2, args.concat([{
      get: function get() {
        var path = args.pop();
        return Ember.String.htmlSafe(this.get(path));
      }
    }]));
  }
  /**
    Uses an Ember String `fmt` call to format a string. See:
    http://emberjs.com/api/classes/Ember.String.html#method_fmt
  
    @method fmt
    @params {String} properties* to format
    @params {String} format the format string
    @return {Function} discourseComputedProperty function
  **/


  function fmt() {
    var _Ember3;

    for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    var format = args.pop();
    return (_Ember3 = Ember).computed.apply(_Ember3, args.concat([function () {
      var _this2 = this;

      return addonFmt.apply(void 0, [format].concat(_toConsumableArray(args.map(function (a) {
        return _this2.get(a);
      }))));
    }]));
  }
  /**
    Creates a URL using getURL. It takes a fmt string just like
    fmt does.
  
    @method url
    @params {String} properties* to format
    @params {String} format the format string for the URL
    @return {Function} discourseComputedProperty function returning a URL
  **/


  function url() {
    var _Ember4;

    for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      args[_key4] = arguments[_key4];
    }

    var format = args.pop();
    return (_Ember4 = Ember).computed.apply(_Ember4, args.concat([function () {
      var _this3 = this;

      return (0, _getUrl.default)(addonFmt.apply(void 0, [format].concat(_toConsumableArray(args.map(function (a) {
        return _this3.get(a);
      })))));
    }]));
  }
  /**
    Returns whether properties end with a string
  
    @method endWith
    @params {String} properties* to check
    @params {String} substring the substring
    @return {Function} discourseComputedProperty function
  **/


  function endWith() {
    var _Ember5;

    var args = Array.prototype.slice.call(arguments, 0);
    var substring = args.pop();
    return (_Ember5 = Ember).computed.apply(_Ember5, _toConsumableArray(args).concat([function () {
      var _this4 = this;

      return args.map(function (a) {
        return _this4.get(a);
      }).every(function (s) {
        var position = s.length - substring.length,
            lastIndex = s.lastIndexOf(substring);
        return lastIndex !== -1 && lastIndex === position;
      });
    }]));
  }
  /**
    Creates a property from a SiteSetting. In the future the plan is for them to
    be able to update when changed.
  
    @method setting
    @param {String} name of site setting
  **/


  function setting(name) {
    return Ember.computed(function () {
      return this.siteSettings[name];
    });
  }
});