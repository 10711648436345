define("discourse/initializers/moment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "moment",
    after: "message-bus",
    initialize: function initialize() {
      delete moment.tz._links["us_pacific-new"];
    }
  };
  _exports.default = _default;
});