define("discourse/controllers/badges/show", ["exports", "discourse-common/utils/decorators", "discourse/models/badge", "I18n", "discourse/models/user-badge"], function (_exports, _decorators, _badge, _I18n, _userBadge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _obj;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("userBadgesAll"), _dec2 = (0, _decorators.default)("filteredList"), _dec3 = (0, _decorators.default)("username"), _dec4 = (0, _decorators.default)("username", "model.grant_count", "userBadges.grant_count"), _dec5 = (0, _decorators.default)("model.grant_count", "userBadges.grant_count"), _dec6 = (0, _decorators.default)("model.allow_title", "model.has_badge", "model"), _dec7 = (0, _decorators.default)("noMoreBadges", "grantCount", "userBadges.length"), _dec8 = (0, _decorators.default)("user", "model.grant_count"), _dec9 = (0, _decorators.observes)("canLoadMore"), _dec10 = Ember._action, _dec11 = Ember._action, (_obj = {
    application: Ember.inject.controller(),
    queryParams: ["username"],
    noMoreBadges: false,
    userBadges: null,
    hiddenSetTitle: true,
    filteredList: function filteredList(userBadgesAll) {
      return userBadgesAll.filterBy("badge.allow_title", true);
    },
    selectableUserBadges: function selectableUserBadges(filteredList) {
      return [Ember.Object.create({
        id: 0,
        badge: _badge.default.create({
          name: _I18n.default.t("badges.none")
        })
      })].concat(_toConsumableArray(filteredList.uniqBy("badge.name")));
    },
    user: function user(username) {
      if (username) {
        return this.userBadges[0].get("user");
      }
    },
    grantCount: function grantCount(username, modelCount, userCount) {
      return username ? userCount : modelCount;
    },
    othersCount: function othersCount(modelCount, userCount) {
      return modelCount - userCount;
    },
    canSelectTitle: function canSelectTitle(hasTitleBadges, hasBadge) {
      return this.siteSettings.enable_badges && hasTitleBadges && hasBadge;
    },
    canLoadMore: function canLoadMore(noMoreBadges, grantCount, userBadgeLength) {
      if (noMoreBadges) {
        return false;
      }

      return grantCount > (userBadgeLength || 0);
    },
    canShowOthers: function canShowOthers(user, grantCount) {
      return !!user && grantCount > 1;
    },
    _showFooter: function _showFooter() {
      this.set("application.showFooter", !this.canLoadMore);
    },
    loadMore: function loadMore() {
      var _this = this;

      if (!this.canLoadMore) {
        return;
      }

      if (this.loadingMore) {
        return;
      }

      this.set("loadingMore", true);
      var userBadges = this.userBadges;

      _userBadge.default.findByBadgeId(this.get("model.id"), {
        offset: userBadges.length,
        username: this.username
      }).then(function (result) {
        userBadges.pushObjects(result);

        if (userBadges.length === 0) {
          _this.set("noMoreBadges", true);
        }
      }).finally(function () {
        _this.set("loadingMore", false);
      });
    },
    toggleSetUserTitle: function toggleSetUserTitle() {
      return this.toggleProperty("hiddenSetTitle");
    }
  }, (_applyDecoratedDescriptor(_obj, "filteredList", [_dec], Object.getOwnPropertyDescriptor(_obj, "filteredList"), _obj), _applyDecoratedDescriptor(_obj, "selectableUserBadges", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectableUserBadges"), _obj), _applyDecoratedDescriptor(_obj, "user", [_dec3], Object.getOwnPropertyDescriptor(_obj, "user"), _obj), _applyDecoratedDescriptor(_obj, "grantCount", [_dec4], Object.getOwnPropertyDescriptor(_obj, "grantCount"), _obj), _applyDecoratedDescriptor(_obj, "othersCount", [_dec5], Object.getOwnPropertyDescriptor(_obj, "othersCount"), _obj), _applyDecoratedDescriptor(_obj, "canSelectTitle", [_dec6], Object.getOwnPropertyDescriptor(_obj, "canSelectTitle"), _obj), _applyDecoratedDescriptor(_obj, "canLoadMore", [_dec7], Object.getOwnPropertyDescriptor(_obj, "canLoadMore"), _obj), _applyDecoratedDescriptor(_obj, "canShowOthers", [_dec8], Object.getOwnPropertyDescriptor(_obj, "canShowOthers"), _obj), _applyDecoratedDescriptor(_obj, "_showFooter", [_dec9], Object.getOwnPropertyDescriptor(_obj, "_showFooter"), _obj), _applyDecoratedDescriptor(_obj, "loadMore", [_dec10], Object.getOwnPropertyDescriptor(_obj, "loadMore"), _obj), _applyDecoratedDescriptor(_obj, "toggleSetUserTitle", [_dec11], Object.getOwnPropertyDescriptor(_obj, "toggleSetUserTitle"), _obj)), _obj)));

  _exports.default = _default;
});