define("discourse/templates/components/edit-category-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dcF2W+fa",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[[24,[\"customComponent\"]]],[[\"tab\",\"selectedTab\",\"category\"],[[24,[\"tab\"]],[24,[\"selectedTab\"]],[24,[\"category\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/edit-category-panel.hbs"
    }
  });

  _exports.default = _default;
});