define("discourse/controllers/account-created-edit-email", ["exports", "discourse/lib/user-activation", "discourse-common/utils/decorators", "discourse/lib/ajax-error"], function (_exports, _userActivation, _decorators, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("newEmail", "accountCreated.email"), (_obj = {
    accountCreated: null,
    newEmail: null,
    submitDisabled: function submitDisabled(newEmail, currentEmail) {
      return newEmail === currentEmail;
    },
    actions: {
      changeEmail: function changeEmail() {
        var _this = this;

        var email = this.newEmail;
        (0, _userActivation.changeEmail)({
          email: email
        }).then(function () {
          _this.set("accountCreated.email", email);

          _this.transitionToRoute("account-created.resent");
        }).catch(_ajaxError.popupAjaxError);
      },
      cancel: function cancel() {
        this.transitionToRoute("account-created.index");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "submitDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "submitDisabled"), _obj)), _obj)));

  _exports.default = _default;
});