define("discourse/models/session", ["exports", "discourse/models/rest", "discourse/mixins/singleton"], function (_exports, _rest, _singleton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // A data model representing current session data. You can put transient
  // data here you might want later. It is not stored or serialized anywhere.
  var Session = _rest.default.extend({
    hasFocus: null,
    init: function init() {
      this.set("highestSeenByTopic", {});
    }
  });

  Session.reopenClass(_singleton.default);
  var _default = Session;
  _exports.default = _default;
});