define("discourse/models/user-action", ["exports", "discourse-common/utils/decorators", "discourse-common/utils/category-macro", "discourse/models/rest", "discourse/models/user", "discourse/models/user-action-group", "discourse/lib/utilities", "discourse/lib/url"], function (_exports, _decorators, _categoryMacro, _rest, _user, _userActionGroup, _utilities, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var UserActionTypes = {
    likes_given: 1,
    likes_received: 2,
    bookmarks: 3,
    topics: 4,
    posts: 5,
    replies: 6,
    mentions: 7,
    quotes: 9,
    edits: 11,
    messages_sent: 12,
    messages_received: 13
  };
  var InvertedActionTypes = {};
  Object.keys(UserActionTypes).forEach(function (k) {
    return InvertedActionTypes[k] = UserActionTypes[k];
  });

  var UserAction = _rest.default.extend((_dec = (0, _decorators.default)("action_type"), _dec2 = (0, _decorators.default)("username"), _dec3 = (0, _decorators.default)("target_username"), _dec4 = (0, _decorators.default)("target_username"), _dec5 = (0, _decorators.default)("username"), _dec6 = (0, _decorators.default)("usernameLower"), _dec7 = (0, _decorators.default)(), _dec8 = (0, _decorators.default)(), _dec9 = (0, _decorators.default)("childGroups", "childGroups.likes.items", "childGroups.likes.items.[]", "childGroups.stars.items", "childGroups.stars.items.[]", "childGroups.edits.items", "childGroups.edits.items.[]", "childGroups.bookmarks.items", "childGroups.bookmarks.items.[]"), (_obj = {
    category: (0, _categoryMacro.default)("category_id"),
    descriptionKey: function descriptionKey(action) {
      if (action === null || UserAction.TO_SHOW.indexOf(action) >= 0) {
        if (this.isPM) {
          return this.sameUser ? "sent_by_you" : "sent_by_user";
        } else {
          return this.sameUser ? "posted_by_you" : "posted_by_user";
        }
      }

      if (this.topicType) {
        return this.sameUser ? "you_posted_topic" : "user_posted_topic";
      }

      if (this.postReplyType) {
        if (this.reply_to_post_number) {
          return this.sameUser ? "you_replied_to_post" : "user_replied_to_post";
        } else {
          return this.sameUser ? "you_replied_to_topic" : "user_replied_to_topic";
        }
      }

      if (this.mentionType) {
        if (this.sameUser) {
          return "you_mentioned_user";
        } else {
          return this.targetUser ? "user_mentioned_you" : "user_mentioned_user";
        }
      }
    },
    sameUser: function sameUser(username) {
      return username === _user.default.currentProp("username");
    },
    targetUser: function targetUser(targetUsername) {
      return targetUsername === _user.default.currentProp("username");
    },
    presentName: Ember.computed.or("name", "username"),
    targetDisplayName: Ember.computed.or("target_name", "target_username"),
    actingDisplayName: Ember.computed.or("acting_name", "acting_username"),
    targetUserUrl: function targetUserUrl(username) {
      return (0, _url.userPath)(username);
    },
    usernameLower: function usernameLower(username) {
      return username.toLowerCase();
    },
    userUrl: function userUrl(usernameLower) {
      return (0, _url.userPath)(usernameLower);
    },
    postUrl: function postUrl() {
      return (0, _utilities.postUrl)(this.slug, this.topic_id, this.post_number);
    },
    replyUrl: function replyUrl() {
      return (0, _utilities.postUrl)(this.slug, this.topic_id, this.reply_to_post_number);
    },
    replyType: Ember.computed.equal("action_type", UserActionTypes.replies),
    postType: Ember.computed.equal("action_type", UserActionTypes.posts),
    topicType: Ember.computed.equal("action_type", UserActionTypes.topics),
    bookmarkType: Ember.computed.equal("action_type", UserActionTypes.bookmarks),
    messageSentType: Ember.computed.equal("action_type", UserActionTypes.messages_sent),
    messageReceivedType: Ember.computed.equal("action_type", UserActionTypes.messages_received),
    mentionType: Ember.computed.equal("action_type", UserActionTypes.mentions),
    isPM: Ember.computed.or("messageSentType", "messageReceivedType"),
    postReplyType: Ember.computed.or("postType", "replyType"),
    removableBookmark: Ember.computed.and("bookmarkType", "sameUser"),
    addChild: function addChild(action) {
      var groups = this.childGroups;

      if (!groups) {
        groups = {
          likes: _userActionGroup.default.create({
            icon: "heart"
          }),
          stars: _userActionGroup.default.create({
            icon: "star"
          }),
          edits: _userActionGroup.default.create({
            icon: "pencil-alt"
          }),
          bookmarks: _userActionGroup.default.create({
            icon: "bookmark"
          })
        };
      }

      this.set("childGroups", groups);

      var bucket = function () {
        switch (action.action_type) {
          case UserActionTypes.likes_given:
          case UserActionTypes.likes_received:
            return "likes";

          case UserActionTypes.edits:
            return "edits";

          case UserActionTypes.bookmarks:
            return "bookmarks";
        }
      }();

      var current = groups[bucket];

      if (current) {
        current.push(action);
      }
    },
    children: function children() {
      var g = this.childGroups;
      var rval = [];

      if (g) {
        rval = [g.likes, g.stars, g.edits, g.bookmarks].filter(function (i) {
          return i.get("items") && i.get("items").length > 0;
        });
      }

      return rval;
    },
    switchToActing: function switchToActing() {
      this.setProperties({
        username: this.acting_username,
        name: this.actingDisplayName
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "descriptionKey", [_dec], Object.getOwnPropertyDescriptor(_obj, "descriptionKey"), _obj), _applyDecoratedDescriptor(_obj, "sameUser", [_dec2], Object.getOwnPropertyDescriptor(_obj, "sameUser"), _obj), _applyDecoratedDescriptor(_obj, "targetUser", [_dec3], Object.getOwnPropertyDescriptor(_obj, "targetUser"), _obj), _applyDecoratedDescriptor(_obj, "targetUserUrl", [_dec4], Object.getOwnPropertyDescriptor(_obj, "targetUserUrl"), _obj), _applyDecoratedDescriptor(_obj, "usernameLower", [_dec5], Object.getOwnPropertyDescriptor(_obj, "usernameLower"), _obj), _applyDecoratedDescriptor(_obj, "userUrl", [_dec6], Object.getOwnPropertyDescriptor(_obj, "userUrl"), _obj), _applyDecoratedDescriptor(_obj, "postUrl", [_dec7], Object.getOwnPropertyDescriptor(_obj, "postUrl"), _obj), _applyDecoratedDescriptor(_obj, "replyUrl", [_dec8], Object.getOwnPropertyDescriptor(_obj, "replyUrl"), _obj), _applyDecoratedDescriptor(_obj, "children", [_dec9], Object.getOwnPropertyDescriptor(_obj, "children"), _obj)), _obj)));

  UserAction.reopenClass({
    collapseStream: function collapseStream(stream) {
      var uniq = {};
      var collapsed = [];
      var pos = 0;
      stream.forEach(function (item) {
        var key = "" + item.topic_id + "-" + item.post_number;
        var found = uniq[key];

        if (found === void 0) {
          var current;

          if (UserAction.TO_COLLAPSE.indexOf(item.action_type) >= 0) {
            current = UserAction.create(item);
            item.switchToActing();
            current.addChild(item);
          } else {
            current = item;
          }

          uniq[key] = pos;
          collapsed[pos] = current;
          pos += 1;
        } else {
          if (UserAction.TO_COLLAPSE.indexOf(item.action_type) >= 0) {
            item.switchToActing();
            collapsed[found].addChild(item);
          } else {
            collapsed[found].setProperties(item.getProperties("action_type", "description"));
          }
        }
      });
      return collapsed;
    },
    TYPES: UserActionTypes,
    TYPES_INVERTED: InvertedActionTypes,
    TO_COLLAPSE: [UserActionTypes.likes_given, UserActionTypes.likes_received, UserActionTypes.edits, UserActionTypes.bookmarks],
    TO_SHOW: [UserActionTypes.likes_given, UserActionTypes.likes_received, UserActionTypes.edits, UserActionTypes.bookmarks, UserActionTypes.messages_sent, UserActionTypes.messages_received]
  });
  var _default = UserAction;
  _exports.default = _default;
});