define("discourse/models/topic-details", ["exports", "discourse/models/rest", "discourse/models/user", "discourse/lib/ajax"], function (_exports, _rest, _user, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    A model representing a Topic's details that aren't always present, such as a list of participants.
    When showing topics in lists and such this information should not be required.
  **/
  var TopicDetails = _rest.default.extend({
    loaded: false,
    updateFromJson: function updateFromJson(details) {
      var topic = this.topic;

      if (details.allowed_users) {
        details.allowed_users = details.allowed_users.map(function (u) {
          return _user.default.create(u);
        });
      }

      if (details.participants) {
        details.participants = details.participants.map(function (p) {
          p.topic = topic;
          return Ember.Object.create(p);
        });
      }

      this.setProperties(details);
      this.set("loaded", true);
    },
    updateNotifications: function updateNotifications(level) {
      var _this = this;

      return (0, _ajax.ajax)("/t/".concat(this.get("topic.id"), "/notifications"), {
        type: "POST",
        data: {
          notification_level: level
        }
      }).then(function () {
        _this.setProperties({
          notification_level: level,
          notifications_reason_id: null
        });
      });
    },
    removeAllowedGroup: function removeAllowedGroup(group) {
      var groups = this.allowed_groups;
      var name = group.name;
      return (0, _ajax.ajax)("/t/" + this.get("topic.id") + "/remove-allowed-group", {
        type: "PUT",
        data: {
          name: name
        }
      }).then(function () {
        groups.removeObject(groups.findBy("name", name));
      });
    },
    removeAllowedUser: function removeAllowedUser(user) {
      var users = this.allowed_users;
      var username = user.get("username");
      return (0, _ajax.ajax)("/t/" + this.get("topic.id") + "/remove-allowed-user", {
        type: "PUT",
        data: {
          username: username
        }
      }).then(function () {
        users.removeObject(users.findBy("username", username));
      });
    }
  });

  var _default = TopicDetails;
  _exports.default = _default;
});