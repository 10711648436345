define("discourse/pre-initializers/inject-discourse-objects", ["exports", "discourse/models/topic-tracking-state", "discourse/models/private-message-topic-tracking-state", "discourse/lib/discourse-location", "discourse/lib/key-value-store", "message-bus-client", "discourse/models/session", "discourse/models/site", "discourse/models/user", "discourse-common/lib/deprecated"], function (_exports, _topicTrackingState, _privateMessageTopicTrackingState, _discourseLocation, _keyValueStore, _messageBusClient, _session, _site, _user, _deprecated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerObjects = registerObjects;
  _exports.default = void 0;
  var ALL_TARGETS = ["controller", "component", "route", "model", "adapter"];

  function registerObjects(app) {
    if (app.__registeredObjects__) {
      // don't run registrations twice.
      return;
    }

    app.__registeredObjects__ = true; // TODO: This should be included properly

    app.register("message-bus:main", _messageBusClient.default, {
      instantiate: false
    });
    var siteSettings = app.SiteSettings;
    app.register("site-settings:main", siteSettings, {
      instantiate: false
    });
  }

  var _default = {
    name: "inject-discourse-objects",
    after: "discourse-bootstrap",
    initialize: function initialize(container, app) {
      registerObjects(app);
      app.register("store:main", {
        create: function create() {
          (0, _deprecated.default)("\"store:main\" is deprecated, use \"service:store\" instead", {
            since: "2.8.0.beta8",
            dropFrom: "2.9.0.beta1"
          });
          return container.lookup("service:store");
        }
      });
      var siteSettings = container.lookup("site-settings:main");

      var currentUser = _user.default.current();

      app.register("current-user:main", currentUser, {
        instantiate: false
      });
      app.currentUser = currentUser;

      var topicTrackingState = _topicTrackingState.default.create({
        messageBus: _messageBusClient.default,
        siteSettings: siteSettings,
        currentUser: currentUser
      });

      app.register("topic-tracking-state:main", topicTrackingState, {
        instantiate: false
      });

      var pmTopicTrackingState = _privateMessageTopicTrackingState.default.create({
        messageBus: _messageBusClient.default,
        currentUser: currentUser
      });

      app.register("pm-topic-tracking-state:main", pmTopicTrackingState, {
        instantiate: false
      });

      var site = _site.default.current();

      app.register("site:main", site, {
        instantiate: false
      });

      var session = _session.default.current();

      app.register("session:main", session, {
        instantiate: false
      });
      app.register("location:discourse-location", _discourseLocation.default);
      var keyValueStore = new _keyValueStore.default("discourse_");
      app.register("key-value-store:main", keyValueStore, {
        instantiate: false
      });
      app.register("search-service:main", {
        create: function create() {
          (0, _deprecated.default)("\"search-service:main\" is deprecated, use \"service:search\" instead", {
            since: "2.8.0.beta8",
            dropFrom: "2.9.0.beta1"
          });
          return container.lookup("service:search");
        }
      });
      ALL_TARGETS.forEach(function (t) {
        app.inject(t, "appEvents", "service:app-events");
        app.inject(t, "pmTopicTrackingState", "pm-topic-tracking-state:main");
        app.inject(t, "store", "service:store");
        app.inject(t, "site", "site:main");
        app.inject(t, "searchService", "service:search");
      });
      ALL_TARGETS.concat("service").forEach(function (t) {
        app.inject(t, "session", "session:main");
        app.inject(t, "messageBus", "message-bus:main");
        app.inject(t, "siteSettings", "site-settings:main");
        app.inject(t, "topicTrackingState", "topic-tracking-state:main");
        app.inject(t, "keyValueStore", "key-value-store:main");
      });

      if (currentUser) {
        ["controller", "component", "route", "service"].forEach(function (t) {
          app.inject(t, "currentUser", "current-user:main");
        });
      }

      (0, _topicTrackingState.startTracking)(topicTrackingState);
    }
  };
  _exports.default = _default;
});