define("discourse/components/expanding-text-area", ["exports", "discourse-common/utils/decorators", "discourse/lib/autosize"], function (_exports, _decorators, _autosize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.TextArea.extend((_dec = (0, _decorators.on)("didInsertElement"), _dec2 = (0, _decorators.observes)("value"), _dec3 = (0, _decorators.on)("willDestroyElement"), (_obj = {
    _startWatching: function _startWatching() {
      var _this = this;

      Ember.run.schedule("afterRender", function () {
        $(_this.element).focus();
        (0, _autosize.default)(_this.element);
      });
    },
    _updateAutosize: function _updateAutosize() {
      this.element.value = this.value;
      var evt = document.createEvent("Event");
      evt.initEvent("autosize:update", true, false);
      this.element.dispatchEvent(evt);
    },
    _disableAutosize: function _disableAutosize() {
      _autosize.default.destroy($(this.element));
    }
  }, (_applyDecoratedDescriptor(_obj, "_startWatching", [_dec], Object.getOwnPropertyDescriptor(_obj, "_startWatching"), _obj), _applyDecoratedDescriptor(_obj, "_updateAutosize", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_updateAutosize"), _obj), _applyDecoratedDescriptor(_obj, "_disableAutosize", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_disableAutosize"), _obj)), _obj)));

  _exports.default = _default;
});