define("discourse/lib/export-csv", ["exports", "I18n", "discourse/lib/ajax", "bootbox", "discourse/lib/ajax-error"], function (_exports, _I18n, _ajax, _bootbox, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.exportUserArchive = exportUserArchive;
  _exports.exportEntity = exportEntity;

  function exportEntityByType(type, entity, args) {
    return (0, _ajax.ajax)("/export_csv/export_entity.json", {
      type: "POST",
      data: {
        entity: entity,
        args: args
      }
    });
  }

  function exportUserArchive() {
    return exportEntityByType("user", "user_archive").then(function () {
      _bootbox.default.alert(_I18n.default.t("user.download_archive.success"));
    }).catch(_ajaxError.popupAjaxError);
  }

  function exportEntity(entity, args) {
    return exportEntityByType("admin", entity, args);
  }
});