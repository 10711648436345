define("discourse/mixins/scroll-top", ["exports", "discourse/lib/url", "discourse-common/config/environment"], function (_exports, _url, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.scrollTop = scrollTop;
  var context = {
    _scrollTop: function _scrollTop() {
      if ((0, _environment.isTesting)()) {
        return;
      }

      $(document).scrollTop(0);
    }
  };

  function scrollTop() {
    if (_url.default.isJumpScheduled()) {
      return;
    }

    Ember.run.scheduleOnce("afterRender", context, context._scrollTop);
  }
});