define("discourse/templates/components/topic-timer-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "weABCGjp",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showTopicTimer\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h3\",true],[10,\"class\",\"topic-timer-heading\"],[8],[0,\"\\n    \"],[7,\"span\",true],[11,\"title\",[22,\"title\"]],[8],[0,\"\\n      \"],[1,[22,\"clockIcon\"],false],[0,\" \"],[1,[22,\"notice\"],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"topic-timer-modify\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showEdit\"]]],null,{\"statements\":[[1,[28,\"d-button\",null,[[\"title\",\"icon\",\"class\",\"action\"],[\"post.controls.edit_timer\",\"pencil-alt\",\"btn topic-timer-edit no-text\",[24,[\"showTopicTimerModal\"]]]]],false]],\"parameters\":[]},null],[4,\"if\",[[24,[\"showTrashCan\"]]],null,{\"statements\":[[1,[28,\"d-button\",null,[[\"title\",\"icon\",\"class\",\"action\"],[\"post.controls.remove_timer\",\"trash-alt\",\"btn topic-timer-remove no-text\",[24,[\"removeTopicTimer\"]]]]],false]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/topic-timer-info.hbs"
    }
  });

  _exports.default = _default;
});