define("discourse/lib/page-tracker", ["exports", "discourse-common/lib/get-url"], function (_exports, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setTransient = setTransient;
  _exports.getTransient = getTransient;
  _exports.resetPageTracking = resetPageTracking;
  _exports.startPageTracking = startPageTracking;
  _exports.addGTMPageChangedCallback = addGTMPageChangedCallback;
  _exports.googleTagManagerPageChanged = googleTagManagerPageChanged;
  var _started = false;
  var cache = {};
  var transitionCount = 0;

  function setTransient(key, data, count) {
    cache[key] = {
      data: data,
      target: transitionCount + count
    };
  }

  function getTransient(key) {
    return cache[key];
  }

  function resetPageTracking() {
    _started = false;
    transitionCount = 0;
    cache = {};
  }

  function startPageTracking(router, appEvents, documentTitle) {
    if (_started) {
      return;
    }

    router.on("routeDidChange", function (transition) {
      if (transition.isAborted) {
        return;
      } // we occasionally prevent tracking of replaced pages when only query params changed
      // eg: google analytics


      var replacedOnlyQueryParams = transition.urlMethod === "replace" && transition.queryParamsOnly;
      router.send("refreshTitle");
      var url = (0, _getUrl.default)(router.get("url")); // Refreshing the title is debounced, so we need to trigger this in the
      // next runloop to have the correct title.

      Ember.run.next(function () {
        appEvents.trigger("page:changed", {
          url: url,
          title: documentTitle.getTitle(),
          currentRouteName: router.currentRouteName,
          replacedOnlyQueryParams: replacedOnlyQueryParams
        });
      });
      transitionCount++;
      Object.keys(cache).forEach(function (k) {
        var v = cache[k];

        if (v && v.target && v.target < transitionCount) {
          delete cache[k];
        }
      });
    });
    _started = true;
  }

  var _gtmPageChangedCallbacks = [];

  function addGTMPageChangedCallback(callback) {
    _gtmPageChangedCallbacks.push(callback);
  }

  function googleTagManagerPageChanged(data) {
    var gtmData = {
      event: "virtualPageView",
      page: {
        title: data.title,
        url: data.url
      }
    };

    _gtmPageChangedCallbacks.forEach(function (callback) {
      return callback(gtmData);
    });

    window.dataLayer.push(gtmData);
  }
});