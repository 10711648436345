define("discourse/components/disabled-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    classNameBindings: [":fa-stack"]
  });

  _exports.default = _default;
});