define("discourse/components/nav-item", ["exports", "I18n", "discourse-common/utils/decorators", "discourse-common/lib/icon-library"], function (_exports, _I18n, _decorators, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("label", "i18nLabel", "icon"), _dec2 = (0, _decorators.default)("route", "router.currentRoute"), (_obj = {
    tagName: "li",
    classNameBindings: ["active"],
    router: Ember.inject.service(),
    contents: function contents(label, i18nLabel, icon) {
      var text = i18nLabel || _I18n.default.t(label);

      if (icon) {
        return "".concat((0, _iconLibrary.iconHTML)(icon), " ").concat(text).htmlSafe();
      }

      return text;
    },
    active: function active(route, currentRoute) {
      if (!route) {
        return;
      }

      var routeParam = this.routeParam;

      if (routeParam && currentRoute) {
        return currentRoute.params["filter"] === routeParam;
      }

      return this.router.isActive(route);
    }
  }, (_applyDecoratedDescriptor(_obj, "contents", [_dec], Object.getOwnPropertyDescriptor(_obj, "contents"), _obj), _applyDecoratedDescriptor(_obj, "active", [_dec2], Object.getOwnPropertyDescriptor(_obj, "active"), _obj)), _obj)));

  _exports.default = _default;
});