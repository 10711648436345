define("discourse/helpers/application", ["discourse/lib/formatter", "I18n", "discourse/lib/utilities", "discourse-common/lib/helpers"], function (_formatter, _I18n, _utilities, _helpers) {
  "use strict";

  (0, _helpers.registerUnbound)("raw-date", function (dt) {
    return Ember.String.htmlSafe((0, _formatter.longDate)(new Date(dt)));
  });
  (0, _helpers.registerUnbound)("age-with-tooltip", function (dt) {
    return Ember.String.htmlSafe((0, _formatter.autoUpdatingRelativeAge)(new Date(dt), {
      title: true
    }));
  });
  (0, _helpers.registerUnbound)("number", function (orig, params) {
    orig = Math.round(parseFloat(orig));

    if (isNaN(orig)) {
      orig = 0;
    }

    var title = _I18n.default.toNumber(orig, {
      precision: 0
    });

    if (params.numberKey) {
      title = _I18n.default.t(params.numberKey, {
        number: title,
        count: parseInt(orig, 10)
      });
    }

    var classNames = "number";

    if (params["class"]) {
      classNames += " " + params["class"];
    }

    var result = "<span class='" + classNames + "'";
    var addTitle = params.noTitle ? false : true; // Round off the thousands to one decimal place

    var n = (0, _formatter.number)(orig);

    if (n.toString() !== title.toString() && addTitle) {
      result += " title='" + (0, _utilities.escapeExpression)(title) + "'";
    }

    if (params.ariaLabel) {
      var ariaLabel = (0, _utilities.escapeExpression)(params.ariaLabel);
      result += " aria-label='".concat(ariaLabel, "'");
    }

    result += ">" + n + "</span>";
    return Ember.String.htmlSafe(result);
  });
});