define("discourse/routes/associate-account", ["exports", "discourse/routes/discourse", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/show-modal", "discourse/lib/cookie"], function (_exports, _discourse, _ajax, _ajaxError, _showModal, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    beforeModel: function beforeModel(transition) {
      if (!this.currentUser) {
        (0, _cookie.default)("destination_url", transition.intent.url);
        return this.replaceWith("login");
      }

      var params = this.paramsFor("associate-account");
      this.replaceWith("preferences.account", this.currentUser).then(function () {
        return Ember.run.next(function () {
          return (0, _ajax.ajax)("/associate/".concat(encodeURIComponent(params.token), ".json")).then(function (model) {
            return (0, _showModal.default)("associate-account-confirm", {
              model: model
            });
          }).catch(_ajaxError.popupAjaxError);
        });
      });
    }
  });

  _exports.default = _default;
});