define("discourse/components/categories-boxes", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("categories.[].uploaded_logo.url"), _dec2 = (0, _decorators.default)("categories.[].subcategories"), (_obj = {
    tagName: "section",
    classNameBindings: [":category-boxes", "anyLogos:with-logos:no-logos", "hasSubcategories:with-subcategories"],
    noCategoryStyle: Ember.computed.equal("siteSettings.category_style", "none"),
    lockIcon: "lock",
    anyLogos: function anyLogos() {
      return this.categories.any(function (c) {
        return !Ember.isEmpty(c.get("uploaded_logo.url"));
      });
    },
    hasSubcategories: function hasSubcategories() {
      return this.categories.any(function (c) {
        return !Ember.isEmpty(c.get("subcategories"));
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "anyLogos", [_dec], Object.getOwnPropertyDescriptor(_obj, "anyLogos"), _obj), _applyDecoratedDescriptor(_obj, "hasSubcategories", [_dec2], Object.getOwnPropertyDescriptor(_obj, "hasSubcategories"), _obj)), _obj)));

  _exports.default = _default;
});