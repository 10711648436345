define("discourse/controllers/preferences/categories", ["exports", "discourse-common/utils/decorators", "discourse/lib/ajax-error"], function (_exports, _decorators, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("model.watchedCategories", "model.watchedFirstPostCategories", "model.trackedCategories", "model.regularCategories", "model.mutedCategories"), _dec2 = (0, _decorators.default)("siteSettings.remove_muted_tags_from_latest"), (_obj = {
    init: function init() {
      this._super.apply(this, arguments);

      this.saveAttrNames = ["muted_category_ids", "regular_category_ids", "watched_category_ids", "tracked_category_ids", "watched_first_post_category_ids"];
    },
    selectedCategories: function selectedCategories(watched, watchedFirst, tracked, regular, muted) {
      return [].concat(watched, watchedFirst, tracked, regular, muted).filter(function (t) {
        return t;
      });
    },
    canSee: function canSee() {
      return this.get("currentUser.id") === this.get("model.id");
    },
    hideMutedTags: function hideMutedTags() {
      return this.siteSettings.remove_muted_tags_from_latest !== "never";
    },
    canSave: Ember.computed.or("canSee", "currentUser.admin"),
    actions: {
      save: function save() {
        var _this = this;

        this.set("saved", false);
        return this.model.save(this.saveAttrNames).then(function () {
          _this.set("saved", true);
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "selectedCategories", [_dec], Object.getOwnPropertyDescriptor(_obj, "selectedCategories"), _obj), _applyDecoratedDescriptor(_obj, "canSee", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "canSee"), _obj), _applyDecoratedDescriptor(_obj, "hideMutedTags", [_dec2], Object.getOwnPropertyDescriptor(_obj, "hideMutedTags"), _obj)), _obj)));

  _exports.default = _default;
});