define("discourse/templates/components/composer-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gn7ZYi7b",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"d-editor\",null,[[\"value\",\"placeholder\",\"previewUpdated\",\"markdownOptions\",\"extraButtons\",\"importQuote\",\"showUploadModal\",\"togglePreview\",\"processPreview\",\"validation\",\"loading\",\"forcePreview\",\"showLink\",\"composerEvents\",\"onExpandPopupMenuOptions\",\"onPopupMenuAction\",\"popupMenuOptions\",\"disabled\",\"outletArgs\"],[[24,[\"composer\",\"reply\"]],[24,[\"replyPlaceholder\"]],[28,\"action\",[[23,0,[]],\"previewUpdated\"],null],[24,[\"markdownOptions\"]],[28,\"action\",[[23,0,[]],\"extraButtons\"],null],[28,\"action\",[[23,0,[]],\"importQuote\"],null],[24,[\"showUploadModal\"]],[28,\"action\",[[23,0,[]],\"togglePreview\"],null],[24,[\"processPreview\"]],[24,[\"validation\"]],[24,[\"composer\",\"loading\"]],[24,[\"forcePreview\"]],[24,[\"showLink\"]],true,[28,\"action\",[[23,0,[]],\"onExpandPopupMenuOptions\"],null],[24,[\"onPopupMenuAction\"]],[24,[\"popupMenuOptions\"]],[24,[\"disableTextarea\"]],[28,\"hash\",null,[[\"composer\",\"editorType\"],[[24,[\"composer\"]],\"composer\"]]]]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"allowUpload\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"acceptsAllFormats\"]]],null,{\"statements\":[[0,\"    \"],[7,\"input\",true],[10,\"id\",\"file-uploader\"],[10,\"multiple\",\"\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"input\",true],[10,\"id\",\"file-uploader\"],[11,\"accept\",[22,\"acceptedFormats\"]],[10,\"multiple\",\"\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/composer-editor.hbs"
    }
  });

  _exports.default = _default;
});