define("discourse/components/badge-title", ["exports", "I18n", "discourse/lib/ajax", "bootbox"], function (_exports, _I18n, _ajax, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    tagName: "",
    selectableUserBadges: null,
    _selectedUserBadgeId: null,
    _isSaved: false,
    _isSaving: false,
    init: function init() {
      this._super.apply(this, arguments);

      var badge = this._findBadgeByTitle(this.selectableUserBadges, this.currentUser.title);

      this.set("_selectedUserBadgeId", (badge === null || badge === void 0 ? void 0 : badge.id) || 0);
    },
    saveBadgeTitle: function saveBadgeTitle() {
      var _this = this;

      this.setProperties({
        _isSaved: false,
        _isSaving: true
      });

      var selectedUserBadge = this._findBadgeById(this.selectableUserBadges, this._selectedUserBadgeId);

      return (0, _ajax.ajax)("".concat(this.currentUser.path, "/preferences/badge_title"), {
        type: "PUT",
        data: {
          user_badge_id: (selectedUserBadge === null || selectedUserBadge === void 0 ? void 0 : selectedUserBadge.id) || 0
        }
      }).then(function () {
        var _selectedUserBadge$ba;

        _this.set("_isSaved", true);

        _this.currentUser.set("title", (selectedUserBadge === null || selectedUserBadge === void 0 ? void 0 : (_selectedUserBadge$ba = selectedUserBadge.badge) === null || _selectedUserBadge$ba === void 0 ? void 0 : _selectedUserBadge$ba.name) || "");
      }, function () {
        _bootbox.default.alert(_I18n.default.t("generic_error"));
      }).finally(function () {
        return _this.set("_isSaving", false);
      });
    },
    _findBadgeById: function _findBadgeById(badges, id) {
      return (badges || []).findBy("id", id);
    },
    _findBadgeByTitle: function _findBadgeByTitle(badges, title) {
      return (badges || []).findBy("badge.name", title);
    }
  }, (_applyDecoratedDescriptor(_obj, "saveBadgeTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "saveBadgeTitle"), _obj)), _obj)));

  _exports.default = _default;
});