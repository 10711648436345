define("discourse/controllers/preferences/users", ["exports", "discourse-common/utils/decorators", "discourse-common/lib/helpers", "discourse/lib/ajax-error"], function (_exports, _decorators, _helpers, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("model.trust_level"), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = (0, _decorators.default)("model.user_option.allow_private_messages"), _dec5 = Ember._action, (_obj = {
    ignoredUsernames: Ember.computed.alias("model.ignored_usernames"),
    userCanIgnore: function userCanIgnore(trustLevel) {
      return trustLevel >= this.siteSettings.min_trust_level_to_allow_ignore;
    },
    ignoredEnabled: Ember.computed.or("userCanIgnore", "model.staff"),
    allowPmUsersEnabled: Ember.computed.and("model.user_option.enable_allowed_pm_users", "model.user_option.allow_private_messages"),
    mutedUsernames: Ember.computed("model.muted_usernames", {
      get: function get() {
        var usernames = this.model.muted_usernames;

        if (typeof usernames === "string") {
          usernames = usernames.split(",").filter(Boolean);
        }

        return (0, _helpers.makeArray)(usernames).uniq();
      }
    }),
    allowedPmUsernames: Ember.computed("model.allowed_pm_usernames", {
      get: function get() {
        var usernames = this.model.allowed_pm_usernames;

        if (typeof usernames === "string") {
          usernames = usernames.split(",").filter(Boolean);
        }

        return (0, _helpers.makeArray)(usernames).uniq();
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.saveAttrNames = ["muted_usernames", "allowed_pm_usernames", "enable_allowed_pm_users"];
    },
    onChangeMutedUsernames: function onChangeMutedUsernames(usernames) {
      this.model.set("muted_usernames", usernames.uniq().join(","));
    },
    onChangeAllowedPmUsernames: function onChangeAllowedPmUsernames(usernames) {
      this.model.set("allowed_pm_usernames", usernames.uniq().join(","));
    },
    disableAllowPmUsersSetting: function disableAllowPmUsersSetting(allowPrivateMessages) {
      return !allowPrivateMessages;
    },
    save: function save() {
      var _this = this;

      this.set("saved", false);
      return this.model.save(this.saveAttrNames).then(function () {
        return _this.set("saved", true);
      }).catch(_ajaxError.popupAjaxError);
    }
  }, (_applyDecoratedDescriptor(_obj, "userCanIgnore", [_dec], Object.getOwnPropertyDescriptor(_obj, "userCanIgnore"), _obj), _applyDecoratedDescriptor(_obj, "onChangeMutedUsernames", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onChangeMutedUsernames"), _obj), _applyDecoratedDescriptor(_obj, "onChangeAllowedPmUsernames", [_dec3], Object.getOwnPropertyDescriptor(_obj, "onChangeAllowedPmUsernames"), _obj), _applyDecoratedDescriptor(_obj, "disableAllowPmUsersSetting", [_dec4], Object.getOwnPropertyDescriptor(_obj, "disableAllowPmUsersSetting"), _obj), _applyDecoratedDescriptor(_obj, "save", [_dec5], Object.getOwnPropertyDescriptor(_obj, "save"), _obj)), _obj)));

  _exports.default = _default;
});