define("discourse/helpers/border-color", ["exports", "discourse-common/lib/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helpers.htmlHelper)(function (color) {
    return "border-color: #".concat(color, "; ");
  });

  _exports.default = _default;
});