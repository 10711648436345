define("discourse/routes/user-badges", ["exports", "discourse/routes/discourse", "discourse/models/user-badge", "discourse/mixins/viewing-action-type"], function (_exports, _discourse, _userBadge, _viewingActionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _discourse.default.extend(_viewingActionType.default, (_dec = Ember._action, (_obj = {
    model: function model() {
      return _userBadge.default.findByUsername(this.modelFor("user").get("username_lower"), {
        grouped: true
      });
    },
    setupController: function setupController(controller, model) {
      this.viewingActionType(-1);
      controller.set("model", model);
    },
    renderTemplate: function renderTemplate() {
      this.render("user/badges", {
        into: "user"
      });
    },
    didTransition: function didTransition() {
      this.controllerFor("application").set("showFooter", true);
      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, "didTransition", [_dec], Object.getOwnPropertyDescriptor(_obj, "didTransition"), _obj)), _obj)));

  _exports.default = _default;
});