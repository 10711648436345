define("discourse/components/scroll-tracker", ["exports", "discourse/mixins/scrolling"], function (_exports, _scrolling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_scrolling.default, {
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set("trackerName", "scroll-tracker-".concat(this.name));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.bindScrolling();
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var data = this.session.get(this.trackerName);

      if (data && data.position >= 0 && data.tag === this.tag) {
        Ember.run.next(function () {
          return $(window).scrollTop(data.position + 1);
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.unbindScrolling();
    },
    scrolled: function scrolled() {
      this._super.apply(this, arguments);

      this.session.set(this.trackerName, {
        position: $(window).scrollTop(),
        tag: this.tag
      });
    }
  });

  _exports.default = _default;
});