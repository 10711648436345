define("discourse/templates/components/directory-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WcQ92IKp",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[7,\"td\",true],[8],[1,[28,\"user-info\",null,[[\"user\"],[[24,[\"item\",\"user\"]]]]],false],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"columns\"]]],null,{\"statements\":[[0,\"  \"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"directory-column-is-user-field\",null,[[\"column\"],[[23,1,[]]]]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"directory-item-user-field-value\",null,[[\"item\",\"column\"],[[24,[\"item\"]],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"directory-item-value\",null,[[\"item\",\"column\"],[[24,[\"item\"]],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showTimeRead\"]]],null,{\"statements\":[[0,\"  \"],[7,\"td\",true],[8],[7,\"span\",true],[10,\"class\",\"time-read\"],[8],[1,[28,\"format-duration\",[[24,[\"item\",\"time_read\"]]],null],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/directory-item.hbs"
    }
  });

  _exports.default = _default;
});