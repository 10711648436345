define("discourse/templates/modal/change-timestamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HhCUp7vI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"class\"],[\"change-timestamp\"]],{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[1,[28,\"i18n\",[\"topic.change_timestamp.instructions\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[11,\"class\",[29,[\"alert alert-error \",[28,\"unless\",[[24,[\"validTimestamp\"]],\"hidden\"],null]]]],[8],[0,\"\\n    \"],[1,[28,\"i18n\",[\"topic.change_timestamp.invalid_timestamp\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"form\",true],[8],[0,\"\\n    \"],[1,[28,\"date-picker-past\",null,[[\"value\",\"containerId\",\"onSelect\"],[[28,\"readonly\",[[24,[\"date\"]]],null],\"date-container\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"date\"]]],null]],null]]]],false],[0,\"\\n\\n    \"],[1,[28,\"input\",null,[[\"type\",\"value\"],[\"time\",[24,[\"time\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"id\",\"date-container\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer change-timestamp-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"class\",\"disabled\",\"action\",\"translatedLabel\"],[\"btn-primary\",[24,[\"buttonDisabled\"]],[28,\"action\",[[23,0,[]],\"changeTimestamp\"],null],[24,[\"buttonTitle\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/change-timestamp.hbs"
    }
  });

  _exports.default = _default;
});