define("discourse/controllers/create-account", ["exports", "discourse/lib/cookie", "discourse-common/utils/decorators", "I18n", "discourse/mixins/modal-functionality", "discourse/mixins/name-validation", "discourse/mixins/password-validation", "discourse/models/user", "discourse/mixins/user-fields-validation", "discourse/mixins/username-validation", "discourse/lib/ajax", "discourse/lib/utilities", "discourse/models/login-method", "discourse-common/lib/debounce", "discourse-common/lib/get-url", "discourse/lib/computed", "discourse/lib/url", "discourse/lib/waving-hand-url"], function (_exports, _cookie, _decorators, _I18n, _modalFunctionality, _nameValidation, _passwordValidation, _user, _userFieldsValidation, _usernameValidation, _ajax, _utilities, _loginMethod, _debounce, _getUrl, _computed, _url, _wavingHandUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _obj, _init;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, _passwordValidation.default, _usernameValidation.default, _nameValidation.default, _userFieldsValidation.default, (_dec = (0, _decorators.default)("hasAuthOptions", "canCreateLocal", "skipConfirmation"), _dec2 = (0, _decorators.default)("formSubmitted"), _dec3 = (0, _decorators.default)(), _dec4 = (0, _decorators.default)("userFields", "hasAtLeastOneLoginButton", "hasAuthOptions"), _dec5 = (0, _decorators.default)("authOptions", "authOptions.can_edit_username"), _dec6 = (0, _decorators.default)("authOptions", "authOptions.can_edit_name"), _dec7 = (0, _decorators.default)("authOptions.auth_provider"), _dec8 = (0, _decorators.default)("serverAccountEmail", "serverEmailValidation", "accountEmail", "rejectedEmails.[]", "forceValidationReason"), _dec9 = Ember._action, _dec10 = (0, _decorators.default)("accountEmail", "authOptions.email", "authOptions.email_valid"), _dec11 = (0, _decorators.observes)("emailValidation", "accountEmail"), _dec12 = (0, _decorators.on)("init"), _dec13 = (0, _decorators.default)("authOptions.associate_url", "authOptions.auth_provider"), (_obj = {
    login: Ember.inject.controller(),
    complete: false,
    accountChallenge: 0,
    accountHoneypot: 0,
    formSubmitted: false,
    rejectedEmails: Ember.A(),
    prefilledUsername: null,
    userFields: null,
    isDeveloper: false,
    hasAuthOptions: Ember.computed.notEmpty("authOptions"),
    canCreateLocal: (0, _computed.setting)("enable_local_logins"),
    requireInviteCode: (0, _computed.setting)("require_invite_code"),
    showCreateForm: function showCreateForm(hasAuthOptions, canCreateLocal, skipConfirmation) {
      return (hasAuthOptions || canCreateLocal) && !skipConfirmation;
    },
    resetForm: function resetForm() {
      // We wrap the fields in a structure so we can assign a value
      this.setProperties({
        accountName: "",
        accountEmail: "",
        accountUsername: "",
        accountPassword: "",
        serverAccountEmail: null,
        serverEmailValidation: null,
        authOptions: null,
        complete: false,
        formSubmitted: false,
        rejectedEmails: [],
        rejectedPasswords: [],
        prefilledUsername: null,
        isDeveloper: false
      });

      this._createUserFields();
    },
    submitDisabled: function submitDisabled() {
      if (this.formSubmitted) {
        return true;
      }

      return false;
    },
    wavingHandURL: function wavingHandURL() {
      return (0, _wavingHandUrl.wavingHandURL)();
    },
    modalBodyClasses: function modalBodyClasses(userFields, hasAtLeastOneLoginButton, hasAuthOptions) {
      var classes = [];

      if (userFields) {
        classes.push("has-user-fields");
      }

      if (hasAtLeastOneLoginButton && !hasAuthOptions) {
        classes.push("has-alt-auth");
      }

      if (!this.canCreateLocal) {
        classes.push("no-local-logins");
      }

      return classes.join(" ");
    },
    usernameDisabled: function usernameDisabled(authOptions, canEditUsername) {
      return authOptions && !canEditUsername;
    },
    nameDisabled: function nameDisabled(authOptions, canEditName) {
      return authOptions && !canEditName;
    },
    fullnameRequired: function fullnameRequired() {
      return this.get("siteSettings.full_name_required") || this.get("siteSettings.enable_names");
    },
    passwordRequired: function passwordRequired(authProvider) {
      return Ember.isEmpty(authProvider);
    },
    disclaimerHtml: function disclaimerHtml() {
      return _I18n.default.t("create_account.disclaimer", {
        tos_link: this.get("siteSettings.tos_url") || (0, _getUrl.default)("/tos"),
        privacy_link: this.get("siteSettings.privacy_policy_url") || (0, _getUrl.default)("/privacy")
      });
    },
    emailValidation: function emailValidation(serverAccountEmail, serverEmailValidation, email, rejectedEmails, forceValidationReason) {
      var failedAttrs = {
        failed: true,
        ok: false,
        element: document.querySelector("#new-account-email")
      };

      if (serverAccountEmail === email && serverEmailValidation) {
        return serverEmailValidation;
      } // If blank, fail without a reason


      if (Ember.isEmpty(email)) {
        return Ember.Object.create(Object.assign(failedAttrs, {
          message: _I18n.default.t("user.email.required"),
          reason: forceValidationReason ? _I18n.default.t("user.email.required") : null
        }));
      }

      if (rejectedEmails.includes(email) || !(0, _utilities.emailValid)(email)) {
        return Ember.Object.create(Object.assign(failedAttrs, {
          reason: _I18n.default.t("user.email.invalid")
        }));
      }

      if (this.get("authOptions.email") === email && this.get("authOptions.email_valid")) {
        return Ember.Object.create({
          ok: true,
          reason: _I18n.default.t("user.email.authenticated", {
            provider: this.authProviderDisplayName(this.get("authOptions.auth_provider"))
          })
        });
      }

      return Ember.Object.create({
        ok: true,
        reason: _I18n.default.t("user.email.ok")
      });
    },
    checkEmailAvailability: function checkEmailAvailability() {
      var _this = this;

      if (!this.emailValidation.ok || this.serverAccountEmail === this.accountEmail) {
        return;
      }

      return _user.default.checkEmail(this.accountEmail).then(function (result) {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }

        if (result.failed) {
          _this.setProperties({
            serverAccountEmail: _this.accountEmail,
            serverEmailValidation: Ember.Object.create({
              failed: true,
              element: document.querySelector("#new-account-email"),
              reason: result.errors[0]
            })
          });
        } else {
          _this.setProperties({
            serverAccountEmail: _this.accountEmail,
            serverEmailValidation: Ember.Object.create({
              ok: true,
              reason: _I18n.default.t("user.email.ok")
            })
          });
        }
      }).catch(function () {
        _this.setProperties({
          serverAccountEmail: null,
          serverEmailValidation: null
        });
      });
    },
    emailDisabled: function emailDisabled() {
      return this.get("authOptions.email") === this.accountEmail && this.get("authOptions.email_valid");
    },
    authProviderDisplayName: function authProviderDisplayName(providerName) {
      var matchingProvider = (0, _loginMethod.findAll)().find(function (provider) {
        return provider.name === providerName;
      });
      return matchingProvider ? matchingProvider.get("prettyName") : providerName;
    },
    prefillUsername: function prefillUsername() {
      if (this.prefilledUsername) {
        // If username field has been filled automatically, and email field just changed,
        // then remove the username.
        if (this.accountUsername === this.prefilledUsername) {
          this.set("accountUsername", "");
        }

        this.set("prefilledUsername", null);
      }

      if (this.get("emailValidation.ok") && (Ember.isEmpty(this.accountUsername) || this.get("authOptions.email"))) {
        // If email is valid and username has not been entered yet,
        // or email and username were filled automatically by 3rd party auth,
        // then look for a registered username that matches the email.
        (0, _debounce.default)(this, this.fetchExistingUsername, 500);
      }
    },
    hasAtLeastOneLoginButton: function hasAtLeastOneLoginButton() {
      return (0, _loginMethod.findAll)().length > 0;
    },
    fetchConfirmationValue: function fetchConfirmationValue() {
      var _this2 = this;

      if (this._challengeDate === undefined && this._hpPromise) {
        // Request already in progress
        return this._hpPromise;
      }

      this._hpPromise = (0, _ajax.ajax)("/session/hp.json").then(function (json) {
        if (_this2.isDestroying || _this2.isDestroyed) {
          return;
        }

        _this2._challengeDate = new Date(); // remove 30 seconds for jitter, make sure this works for at least
        // 30 seconds so we don't have hard loops

        _this2._challengeExpiry = parseInt(json.expires_in, 10) - 30;

        if (_this2._challengeExpiry < 30) {
          _this2._challengeExpiry = 30;
        }

        _this2.setProperties({
          accountHoneypot: json.value,
          accountChallenge: json.challenge.split("").reverse().join("")
        });
      }).finally(function () {
        return _this2._hpPromise = undefined;
      });
      return this._hpPromise;
    },
    performAccountCreation: function performAccountCreation() {
      var _this3 = this;

      if (!this._challengeDate || new Date() - this._challengeDate > 1000 * this._challengeExpiry) {
        return this.fetchConfirmationValue().then(function () {
          return _this3.performAccountCreation();
        });
      }

      var attrs = this.getProperties("accountName", "accountEmail", "accountPassword", "accountUsername", "accountChallenge", "inviteCode");
      attrs["accountPasswordConfirm"] = this.accountHoneypot;
      var userFields = this.userFields;
      var destinationUrl = this.get("authOptions.destination_url");

      if (!Ember.isEmpty(destinationUrl)) {
        (0, _cookie.default)("destination_url", destinationUrl, {
          path: "/"
        });
      } // Add the userfields to the data


      if (!Ember.isEmpty(userFields)) {
        attrs.userFields = {};
        userFields.forEach(function (f) {
          return attrs.userFields[f.get("field.id")] = f.get("value");
        });
      }

      this.set("formSubmitted", true);
      return _user.default.createAccount(attrs).then(function (result) {
        if (_this3.isDestroying || _this3.isDestroyed) {
          return;
        }

        _this3.set("isDeveloper", false);

        if (result.success) {
          // invalidate honeypot
          _this3._challengeExpiry = 1; // Trigger the browser's password manager using the hidden static login form:

          var $hidden_login_form = $("#hidden-login-form");
          $hidden_login_form.find("input[name=username]").val(attrs.accountUsername);
          $hidden_login_form.find("input[name=password]").val(attrs.accountPassword);
          $hidden_login_form.find("input[name=redirect]").val((0, _url.userPath)("account-created"));
          $hidden_login_form.submit();
          return new Ember.RSVP.Promise(function () {}); // This will never resolve, the page will reload instead
        } else {
          _this3.flash(result.message || _I18n.default.t("create_account.failed"), "error");

          if (result.is_developer) {
            _this3.set("isDeveloper", true);
          }

          if (result.errors && result.errors.email && result.errors.email.length > 0 && result.values) {
            _this3.rejectedEmails.pushObject(result.values.email);
          }

          if (result.errors && result.errors.password && result.errors.password.length > 0) {
            _this3.rejectedPasswords.pushObject(attrs.accountPassword);
          }

          _this3.set("formSubmitted", false);

          (0, _cookie.removeCookie)("destination_url");
        }
      }, function () {
        _this3.set("formSubmitted", false);

        (0, _cookie.removeCookie)("destination_url");
        return _this3.flash(_I18n.default.t("create_account.failed"), "error");
      });
    },
    onShow: function onShow() {
      var _this4 = this;

      if (this.skipConfirmation) {
        this.performAccountCreation().finally(function () {
          return _this4.set("skipConfirmation", false);
        });
      }
    },
    associateHtml: function associateHtml(url, provider) {
      if (!url) {
        return;
      }

      return _I18n.default.t("create_account.associate", {
        associate_link: url,
        provider: _I18n.default.t("login.".concat(provider, ".name"))
      });
    },
    actions: {
      externalLogin: function externalLogin(provider) {
        this.login.send("externalLogin", provider, {
          signup: true
        });
      },
      createAccount: function createAccount() {
        this.clearFlash();
        this.set("forceValidationReason", true);
        var validation = [this.emailValidation, this.usernameValidation, this.nameValidation, this.passwordValidation, this.userFieldsValidation].find(function (v) {
          return v.failed;
        });

        if (validation) {
          var element = validation.element;

          if (element) {
            if (element.tagName === "DIV") {
              if (element.scrollIntoView) {
                element.scrollIntoView();
              }

              element.click();
            } else {
              element.focus();
            }
          }

          return;
        }

        this.set("forceValidationReason", false);
        this.performAccountCreation();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "showCreateForm", [_dec], Object.getOwnPropertyDescriptor(_obj, "showCreateForm"), _obj), _applyDecoratedDescriptor(_obj, "submitDisabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "submitDisabled"), _obj), _applyDecoratedDescriptor(_obj, "wavingHandURL", [_dec3], (_init = Object.getOwnPropertyDescriptor(_obj, "wavingHandURL"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "modalBodyClasses", [_dec4], Object.getOwnPropertyDescriptor(_obj, "modalBodyClasses"), _obj), _applyDecoratedDescriptor(_obj, "usernameDisabled", [_dec5], Object.getOwnPropertyDescriptor(_obj, "usernameDisabled"), _obj), _applyDecoratedDescriptor(_obj, "nameDisabled", [_dec6], Object.getOwnPropertyDescriptor(_obj, "nameDisabled"), _obj), _applyDecoratedDescriptor(_obj, "fullnameRequired", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "fullnameRequired"), _obj), _applyDecoratedDescriptor(_obj, "passwordRequired", [_dec7], Object.getOwnPropertyDescriptor(_obj, "passwordRequired"), _obj), _applyDecoratedDescriptor(_obj, "disclaimerHtml", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "disclaimerHtml"), _obj), _applyDecoratedDescriptor(_obj, "emailValidation", [_dec8], Object.getOwnPropertyDescriptor(_obj, "emailValidation"), _obj), _applyDecoratedDescriptor(_obj, "checkEmailAvailability", [_dec9], Object.getOwnPropertyDescriptor(_obj, "checkEmailAvailability"), _obj), _applyDecoratedDescriptor(_obj, "emailDisabled", [_dec10], Object.getOwnPropertyDescriptor(_obj, "emailDisabled"), _obj), _applyDecoratedDescriptor(_obj, "prefillUsername", [_dec11], Object.getOwnPropertyDescriptor(_obj, "prefillUsername"), _obj), _applyDecoratedDescriptor(_obj, "hasAtLeastOneLoginButton", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "hasAtLeastOneLoginButton"), _obj), _applyDecoratedDescriptor(_obj, "fetchConfirmationValue", [_dec12], Object.getOwnPropertyDescriptor(_obj, "fetchConfirmationValue"), _obj), _applyDecoratedDescriptor(_obj, "associateHtml", [_dec13], Object.getOwnPropertyDescriptor(_obj, "associateHtml"), _obj)), _obj)));

  _exports.default = _default;
});