define("discourse/routes/build-category-route", ["exports", "discourse/controllers/discovery-sortable", "discourse/routes/build-topic-route", "discourse/models/category", "discourse/models/category-list", "discourse/routes/discourse", "I18n", "discourse/models/permission-type", "discourse/models/topic-list"], function (_exports, _discoverySortable, _buildTopicRoute, _category, _categoryList, _discourse, _I18n, _permissionType, _topicList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // A helper function to create a category route with parameters
  var _default = function _default(filterArg, params) {
    var _dec, _dec2, _dec3, _dec4, _obj;

    return _discourse.default.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
      queryParams: _discoverySortable.queryParams,
      model: function model(modelParams) {
        var _this = this;

        var category = _category.default.findBySlugPathWithID(modelParams.category_slug_path_with_id);

        if (!category) {
          var parts = modelParams.category_slug_path_with_id.split("/");

          if (parts.length > 0 && parts[parts.length - 1].match(/^\d+$/)) {
            parts.pop();
          }

          return _category.default.reloadBySlugPath(parts.join("/")).then(function (result) {
            var record = _this.store.createRecord("category", result.category);

            record.setupGroupsAndPermissions();

            _this.site.updateCategory(record);

            return {
              category: record,
              modelParams: modelParams
            };
          });
        }

        if (category) {
          return {
            category: category,
            modelParams: modelParams
          };
        }
      },
      afterModel: function afterModel(model, transition) {
        if (!model) {
          this.replaceWith("/404");
          return;
        }

        var category = model.category,
            modelParams = model.modelParams;

        if ((!params || params.no_subcategories === undefined) && category.default_list_filter === "none" && filterArg === "default" && modelParams) {
          return this.replaceWith("discovery.categoryNone", {
            category: category,
            category_slug_path_with_id: modelParams.category_slug_path_with_id
          });
        }

        this._setupNavigation(category);

        return Ember.RSVP.all([this._createSubcategoryList(category), this._retrieveTopicList(category, transition, modelParams)]);
      },
      filter: function filter(category) {
        return filterArg === "default" ? category.get("default_view") || "latest" : filterArg;
      },
      _setupNavigation: function _setupNavigation(category) {
        var noSubcategories = params && !!params.no_subcategories,
            filterType = this.filter(category).split("/")[0];
        this.controllerFor("navigation/category").setProperties({
          category: category,
          filterType: filterType,
          noSubcategories: noSubcategories
        });
      },
      _createSubcategoryList: function _createSubcategoryList(category) {
        var _this2 = this;

        this._categoryList = null;

        if (category.isParent && category.show_subcategory_list) {
          return _categoryList.default.listForParent(this.store, category).then(function (list) {
            return _this2._categoryList = list;
          });
        } // If we're not loading a subcategory list just resolve


        return Ember.RSVP.Promise.resolve();
      },
      _retrieveTopicList: function _retrieveTopicList(category, transition, modelParams) {
        var _this3 = this;

        var findOpts = (0, _buildTopicRoute.filterQueryParams)(modelParams, params);
        var extras = {
          cached: this.isPoppedState(transition)
        };
        var listFilter = "c/".concat(_category.default.slugFor(category), "/").concat(category.id);

        if (findOpts.no_subcategories) {
          listFilter += "/none";
        }

        listFilter += "/l/".concat(this.filter(category));
        return (0, _buildTopicRoute.findTopicList)(this.store, this.topicTrackingState, listFilter, findOpts, extras).then(function (list) {
          _topicList.default.hideUniformCategory(list, category);

          _this3.set("topics", list);

          return list;
        });
      },
      titleToken: function titleToken() {
        var category = this.currentModel.category;

        var filterText = _I18n.default.t("filters." + this.filter(category).replace("/", ".") + ".title");

        var categoryName = category.name;

        if (category.parent_category_id) {
          var list = _category.default.list();

          var parentCategory = list.findBy("id", category.parent_category_id);
          categoryName = "".concat(parentCategory.name, "/").concat(categoryName);
        }

        return _I18n.default.t("filters.with_category", {
          filter: filterText,
          category: categoryName
        });
      },
      setupController: function setupController(controller, model) {
        var topics = this.topics,
            category = model.category,
            canCreateTopic = topics.get("can_create_topic"),
            canCreateTopicOnCategory = canCreateTopic && category.get("permission") === _permissionType.default.FULL;

        this.controllerFor("navigation/category").setProperties({
          canCreateTopicOnCategory: canCreateTopicOnCategory,
          cannotCreateTopicOnCategory: !canCreateTopicOnCategory,
          canCreateTopic: canCreateTopic
        });
        var topicOpts = {
          model: topics,
          category: category,
          period: topics.get("for_period") || model.modelParams && model.modelParams.period,
          selected: [],
          noSubcategories: params && !!params.no_subcategories,
          expandAllPinned: true,
          canCreateTopic: canCreateTopic,
          canCreateTopicOnCategory: canCreateTopicOnCategory
        };
        var p = category.get("params");

        if (p && Object.keys(p).length) {
          if (p.order !== undefined) {
            topicOpts.order = p.order;
          }

          if (p.ascending !== undefined) {
            topicOpts.ascending = p.ascending;
          }
        }

        this.controllerFor("discovery/topics").setProperties(topicOpts);
        this.searchService.set("searchContext", category.get("searchContext"));
        this.set("topics", null);
      },
      renderTemplate: function renderTemplate() {
        this.render("navigation/category", {
          outlet: "navigation-bar"
        });

        if (this._categoryList) {
          this.render("discovery/categories", {
            outlet: "header-list-container",
            model: this._categoryList
          });
        } else {
          this.disconnectOutlet({
            outlet: "header-list-container"
          });
        }

        this.render("discovery/topics", {
          controller: "discovery/topics",
          outlet: "list-container"
        });
      },
      deactivate: function deactivate() {
        this._super.apply(this, arguments);

        this.controllerFor("composer").set("prioritizedCategoryId", null);
        this.searchService.set("searchContext", null);
      },
      setNotification: function setNotification(notification_level) {
        this.currentModel.setNotification(notification_level);
      },
      triggerRefresh: function triggerRefresh() {
        this.refresh();
      },
      changeSort: function changeSort(sortBy) {
        _discoverySortable.changeSort.call(this, sortBy);
      },
      resetParams: function resetParams() {
        var skipParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        _discoverySortable.resetParams.call(this, skipParams);
      }
    }, (_applyDecoratedDescriptor(_obj, "setNotification", [_dec], Object.getOwnPropertyDescriptor(_obj, "setNotification"), _obj), _applyDecoratedDescriptor(_obj, "triggerRefresh", [_dec2], Object.getOwnPropertyDescriptor(_obj, "triggerRefresh"), _obj), _applyDecoratedDescriptor(_obj, "changeSort", [_dec3], Object.getOwnPropertyDescriptor(_obj, "changeSort"), _obj), _applyDecoratedDescriptor(_obj, "resetParams", [_dec4], Object.getOwnPropertyDescriptor(_obj, "resetParams"), _obj)), _obj)));
  };

  _exports.default = _default;
});