define("discourse/templates/navigation/category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HnFpxZiT",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"add-category-tag-classes\",null,[[\"category\",\"tagName\"],[[24,[\"category\"]],\"\"]]],false],[0,\"\\n\\n\"],[7,\"section\",true],[10,\"class\",\"category-heading\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"category\",\"uploaded_logo\",\"url\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"cdn-img\",null,[[\"src\",\"class\",\"width\",\"height\",\"alt\"],[[24,[\"category\",\"uploaded_logo\",\"url\"]],\"category-logo\",[24,[\"category\",\"uploaded_logo\",\"width\"]],[24,[\"category\",\"uploaded_logo\",\"height\"]],\"\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"category\",\"description\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[1,[28,\"dir-span\",[[24,[\"category\",\"description\"]]],[[\"htmlSafe\"],[\"true\"]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"tagName\",\"connectorTagName\",\"args\"],[\"category-heading\",\"span\",\"div\",[28,\"hash\",null,[[\"category\"],[[24,[\"category\"]]]]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"d-section\",null,[[\"class\"],[\"navigation-container category-navigation\"]],{\"statements\":[[0,\"  \"],[1,[28,\"d-navigation\",null,[[\"category\",\"filterMode\",\"noSubcategories\",\"canCreateTopic\",\"createTopic\",\"createTopicDisabled\",\"hasDraft\",\"editCategory\"],[[24,[\"category\"]],[24,[\"filterMode\"]],[24,[\"noSubcategories\"]],[24,[\"canCreateTopic\"]],[28,\"route-action\",[\"createTopic\"],null],[24,[\"cannotCreateTopicOnCategory\"]],[24,[\"currentUser\",\"has_topic_draft\"]],[28,\"route-action\",[\"editCategory\",[24,[\"category\"]]],null]]]],false],[0,\"\\n\\n  \"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"connectorTagName\",\"args\"],[\"category-navigation\",\"div\",[28,\"hash\",null,[[\"category\"],[[24,[\"category\"]]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/navigation/category.hbs"
    }
  });

  _exports.default = _default;
});