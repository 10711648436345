define("discourse/widgets/header-contents", ["discourse/widgets/widget"], function (_widget) {
  "use strict";

  (0, _widget.createWidget)("header-contents", {
    tagName: "div.contents.clearfix",
    template: function template(attrs, state) {
      var _r = [];

      _r.push("\n    ");

      _r.push(this.attach("home-logo", attrs));

      _r.push("\n");

      if (attrs.topic) {
        _r.push("      ");

        _r.push(this.attach("header-topic-info", attrs));

        _r.push("\n");
      }

      _r.push("    ");

      var _a0 = [];

      _a0.push(this.attrs.contents());

      _r.push(virtualDom.h('div', {
        "className": "panel clearfix",
        "attributes": {
          "role": "navigation"
        }
      }, _a0));

      _r.push("\n  ");

      return _r;
    }
  });
});