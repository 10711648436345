define("discourse/templates/components/related-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+748qtrj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"related-messages\"],[10,\"class\",\"suggested-topics\"],[10,\"role\",\"complementary\"],[10,\"aria-labelledby\",\"related-messages-title\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"suggested-topics-title\"],[10,\"id\",\"related-messages-title\"],[8],[0,\"\\n    \"],[1,[28,\"i18n\",[\"related_messages.title\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"topics\"],[8],[0,\"\\n    \"],[1,[28,\"basic-topic-list\",null,[[\"hideCategory\",\"showPosters\",\"topics\"],[\"true\",\"true\",[24,[\"topic\",\"relatedMessages\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"targetUser\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\",true],[10,\"class\",\"see-all-pms-message\"],[8],[0,\"\\n      \"],[1,[28,\"html-safe\",[[28,\"i18n\",[\"related_messages.see_all\"],[[\"path\",\"username\"],[[24,[\"searchLink\"]],[24,[\"targetUser\",\"username\"]]]]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/related-messages.hbs"
    }
  });

  _exports.default = _default;
});