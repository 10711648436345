define("discourse/components/date-picker", ["exports", "discourse-common/utils/decorators", "I18n", "discourse/lib/load-script"], function (_exports, _decorators, _I18n, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var DATE_FORMAT = "YYYY-MM-DD";

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("site.mobileView"), _dec2 = (0, _decorators.on)("didInsertElement"), _dec3 = (0, _decorators.on)("willDestroyElement"), _dec4 = (0, _decorators.default)(), (_obj = {
    classNames: ["date-picker-wrapper"],
    _picker: null,
    value: null,
    inputType: function inputType(mobileView) {
      return mobileView ? "date" : "text";
    },
    _loadDatePicker: function _loadDatePicker() {
      if (this.site.mobileView) {
        this._loadNativePicker();
      } else {
        var container = document.getElementById(this.containerId);

        this._loadPikadayPicker(container);
      }
    },
    _loadPikadayPicker: function _loadPikadayPicker(container) {
      var _this = this;

      (0, _loadScript.default)("/javascripts/pikaday.js").then(function () {
        Ember.run.schedule("afterRender", function () {
          var options = {
            field: _this.element.querySelector(".date-picker"),
            container: container || null,
            bound: container === null,
            format: DATE_FORMAT,
            firstDay: 1,
            i18n: {
              previousMonth: _I18n.default.t("dates.previous_month"),
              nextMonth: _I18n.default.t("dates.next_month"),
              months: moment.months(),
              weekdays: moment.weekdays(),
              weekdaysShort: moment.weekdaysMin()
            },
            onSelect: function onSelect(date) {
              return _this._handleSelection(date);
            }
          };
          _this._picker = new Pikaday(Object.assign(options, _this._opts()));
        });
      });
    },
    _loadNativePicker: function _loadNativePicker() {
      var _this2 = this;

      var picker = this.element.querySelector("input.date-picker");

      picker.onchange = function () {
        return _this2._handleSelection(picker.value);
      };

      picker.hide = function () {
        /* do nothing for native */
      };

      picker.destroy = function () {
        /* do nothing for native */
      };

      this._picker = picker;
    },
    _handleSelection: function _handleSelection(value) {
      var formattedDate = moment(value).format(DATE_FORMAT);

      if (!this.element || this.isDestroying || this.isDestroyed) {
        return;
      }

      if (this.onSelect) {
        this.onSelect(formattedDate);
      }
    },
    _destroy: function _destroy() {
      if (this._picker) {
        this._picker.destroy();

        this._picker = null;
      }
    },
    placeholder: function placeholder() {
      return _I18n.default.t("dates.placeholder");
    },
    _opts: function _opts() {
      return null;
    }
  }, (_applyDecoratedDescriptor(_obj, "inputType", [_dec], Object.getOwnPropertyDescriptor(_obj, "inputType"), _obj), _applyDecoratedDescriptor(_obj, "_loadDatePicker", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_loadDatePicker"), _obj), _applyDecoratedDescriptor(_obj, "_destroy", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_destroy"), _obj), _applyDecoratedDescriptor(_obj, "placeholder", [_dec4], Object.getOwnPropertyDescriptor(_obj, "placeholder"), _obj)), _obj)));

  _exports.default = _default;
});