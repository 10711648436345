define("discourse/templates/components/navigation-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TNKVkuVe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[11,\"href\",[22,\"hrefLink\"]],[11,\"class\",[22,\"activeClass\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"hasIcon\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[24,[\"content\",\"name\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[24,[\"content\",\"displayName\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/navigation-item.hbs"
    }
  });

  _exports.default = _default;
});