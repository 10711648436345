define("discourse/models/nav-item", ["exports", "discourse/models/category", "I18n", "discourse/models/site", "discourse/models/user", "discourse-common/lib/object", "discourse-common/lib/deprecated", "discourse-common/utils/decorators", "discourse/lib/text", "discourse-common/lib/get-owner", "discourse-common/lib/get-url"], function (_exports, _category, _I18n, _site, _user, _object, _deprecated, _decorators, _text, _getOwner, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.extraNavItemProperties = extraNavItemProperties;
  _exports.customNavItemHref = customNavItemHref;
  _exports.clearNavItems = clearNavItems;
  _exports.addNavItem = addNavItem;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var NavItem = Ember.Object.extend((_dec = (0, _decorators.default)("name"), _dec2 = (0, _decorators.default)("name", "count"), _dec3 = (0, _decorators.default)("filterType", "category", "noSubcategories", "tagId"), _dec4 = (0, _decorators.default)("name", "category", "noSubcategories"), _dec5 = (0, _decorators.default)("name", "category", "tagId", "noSubcategories", "topicTrackingState.messageCount"), (_obj = {
    title: function title(name) {
      return _I18n.default.t("filters." + name.replace("/", ".") + ".help", {});
    },
    displayName: function displayName(name, count) {
      count = count || 0;

      if (name === "latest" && (!_site.default.currentProp("mobileView") || this.tagId !== undefined)) {
        count = 0;
      }

      var extra = {
        count: count
      };
      var titleKey = count === 0 ? ".title" : ".title_with_count";
      return (0, _text.emojiUnescape)(_I18n.default.t("filters.".concat(name.replace("/", ".") + titleKey), extra));
    },
    href: function href(filterType, category, noSubcategories, tagId) {
      var customHref = null;
      NavItem.customNavItemHrefs.forEach(function (cb) {
        customHref = cb.call(this, this);

        if (customHref) {
          return false;
        }
      }, this);

      if (customHref) {
        return (0, _getUrl.default)(customHref);
      }

      var context = {
        category: category,
        noSubcategories: noSubcategories,
        tagId: tagId
      };
      return NavItem.pathFor(filterType, context);
    },
    filterType: Ember.computed.reads("name"),
    filterMode: function filterMode(name, category, noSubcategories) {
      var mode = "";

      if (category) {
        mode += "c/";
        mode += _category.default.slugFor(category);

        if (noSubcategories) {
          mode += "/none";
        }

        mode += "/l/";
      }

      return mode + name.replace(" ", "-");
    },
    count: function count(name, category, tagId, noSubcategories) {
      var state = this.topicTrackingState;

      if (state) {
        return state.lookupCount(name, category, tagId, noSubcategories);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "title", [_dec], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "displayName", [_dec2], Object.getOwnPropertyDescriptor(_obj, "displayName"), _obj), _applyDecoratedDescriptor(_obj, "href", [_dec3], Object.getOwnPropertyDescriptor(_obj, "href"), _obj), _applyDecoratedDescriptor(_obj, "filterMode", [_dec4], Object.getOwnPropertyDescriptor(_obj, "filterMode"), _obj), _applyDecoratedDescriptor(_obj, "count", [_dec5], Object.getOwnPropertyDescriptor(_obj, "count"), _obj)), _obj)));
  var ExtraNavItem = NavItem.extend({
    href: (0, _decorators.default)("href", {
      get: function get() {
        if (this._href) {
          return this._href;
        }

        return this.href;
      },
      set: function set(key, value) {
        return this._href = value;
      }
    }),
    count: 0,
    customFilter: null
  });
  NavItem.reopenClass({
    extraArgsCallbacks: [],
    customNavItemHrefs: [],
    extraNavItemDescriptors: [],
    pathFor: function pathFor(filterType, context) {
      var path = (0, _getUrl.default)("");
      var includesCategoryContext = false;
      var includesTagContext = false;

      if (filterType === "categories") {
        path += "/categories";
        return path;
      }

      if (context.tagId && _site.default.currentProp("filters").includes(filterType)) {
        includesTagContext = true;

        if (context.category) {
          path += "/tags";
        } else {
          path += "/tag";
        }
      }

      if (context.category) {
        includesCategoryContext = true;
        path += "/c/".concat(_category.default.slugFor(context.category), "/").concat(context.category.id);

        if (context.noSubcategories) {
          path += "/none";
        }
      }

      if (includesTagContext) {
        path += "/".concat(context.tagId);
      }

      if (includesTagContext || includesCategoryContext) {
        path += "/l";
      }

      path += "/".concat(filterType); // In the case of top, the nav item doesn't include a period because the
      // period has its own selector just below

      return path;
    },
    // Create a nav item given a filterType. It returns null if there is not
    // valid nav item. The name is a historical artifact.
    fromText: function fromText(filterType, opts) {
      var _this = this;

      var anonymous = !_user.default.current();
      opts = opts || {};

      if (anonymous) {
        var topMenuItems = _site.default.currentProp("anonymous_top_menu_items");

        if (!topMenuItems || !topMenuItems.includes(filterType)) {
          return null;
        }
      }

      if (!_category.default.list() && filterType === "categories") {
        return null;
      }

      if (!_site.default.currentProp("top_menu_items").includes(filterType)) {
        return null;
      }

      var args = {
        name: filterType,
        hasIcon: filterType === "unread"
      };

      if (opts.category) {
        args.category = opts.category;
      }

      if (opts.tagId) {
        args.tagId = opts.tagId;
      }

      if (opts.currentRouteQueryParams) {
        args.currentRouteQueryParams = opts.currentRouteQueryParams;
      }

      if (opts.noSubcategories) {
        args.noSubcategories = true;
      }

      NavItem.extraArgsCallbacks.forEach(function (cb) {
        return (0, _object.deepMerge)(args, cb.call(_this, filterType, opts));
      });
      var store = (0, _getOwner.getOwner)(this).lookup("service:store");
      return store.createRecord("nav-item", args);
    },
    buildList: function buildList(category, args) {
      args = args || {};

      if (category) {
        args.category = category;
      }

      if (!args.siteSettings) {
        (0, _deprecated.default)("You must supply `buildList` with a `siteSettings` object", {
          since: "2.6.0",
          dropFrom: "2.7.0"
        });
        args.siteSettings = (0, _getOwner.getOwner)(this).lookup("site-settings:main");
      }

      var items = args.siteSettings.top_menu.split("|");
      var filterType = (args.filterMode || "").split("/").pop();

      if (!items.some(function (i) {
        return filterType === i;
      })) {
        items.push(filterType);
      }

      items = items.map(function (i) {
        return NavItem.fromText(i, args);
      }).filter(function (i) {
        return i !== null && !(category && i.get("name").indexOf("categor") === 0);
      });
      var context = {
        category: args.category,
        tagId: args.tagId,
        noSubcategories: args.noSubcategories
      };
      var extraItems = NavItem.extraNavItemDescriptors.map(function (descriptor) {
        return ExtraNavItem.create((0, _object.deepMerge)({}, context, descriptor));
      }).filter(function (item) {
        if (!item.customFilter) {
          return true;
        }

        return item.customFilter(category, args);
      });
      var forceActive = false;
      extraItems.forEach(function (item) {
        if (item.init) {
          item.init(item, category, args);
        }

        if (item.href) {
          item.href = (0, _getUrl.default)(item.href);
        }

        var before = item.before;

        if (before) {
          var i = 0;

          for (i = 0; i < items.length; i++) {
            if (items[i].name === before) {
              break;
            }
          }

          items.splice(i, 0, item);
        } else {
          items.push(item);
        }

        if (item.customHref) {
          item.set("href", item.customHref(category, args));
        }

        if (item.forceActive && item.forceActive(category, args)) {
          item.active = true;
          forceActive = true;
        } else {
          item.active = undefined;
        }
      });

      if (forceActive) {
        items.forEach(function (i) {
          if (i.active === undefined) {
            i.active = false;
          }
        });
      }

      return items;
    }
  });
  var _default = NavItem;
  _exports.default = _default;

  function extraNavItemProperties(cb) {
    NavItem.extraArgsCallbacks.push(cb);
  }

  function customNavItemHref(cb) {
    NavItem.customNavItemHrefs.push(cb);
  }

  function clearNavItems() {
    NavItem.customNavItemHrefs.clear();
    NavItem.extraArgsCallbacks.clear();
    NavItem.extraNavItemDescriptors.clear();
  }

  function addNavItem(item) {
    NavItem.extraNavItemDescriptors.push(item);
  }
});