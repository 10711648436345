define("discourse/widgets/post-small-action", ["exports", "I18n", "discourse/widgets/raw-html", "discourse/lib/formatter", "discourse/widgets/post", "discourse/widgets/widget", "virtual-dom", "discourse-common/lib/icon-library", "discourse/lib/url"], function (_exports, _I18n, _rawHtml, _formatter, _post, _widget, _virtualDom, _iconLibrary, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.actionDescriptionHtml = actionDescriptionHtml;
  _exports.actionDescription = actionDescription;
  _exports.addPostSmallActionIcon = addPostSmallActionIcon;
  _exports.addGroupPostSmallActionCode = addGroupPostSmallActionCode;
  _exports.default = void 0;

  function actionDescriptionHtml(actionCode, createdAt, username, path) {
    var dt = new Date(createdAt);
    var when = (0, _formatter.autoUpdatingRelativeAge)(dt, {
      format: "medium-with-ago-and-on"
    });
    var who = "";

    if (username) {
      if (groupActionCodes.includes(actionCode)) {
        who = "<a class=\"mention-group\" href=\"/g/".concat(username, "\">@").concat(username, "</a>");
      } else {
        who = "<a class=\"mention\" href=\"".concat((0, _url.userPath)(username), "\">@").concat(username, "</a>");
      }
    }

    return _I18n.default.t("action_codes.".concat(actionCode), {
      who: who,
      when: when,
      path: path
    }).htmlSafe();
  }

  function actionDescription(actionCode, createdAt, username) {
    return Ember.computed(actionCode, createdAt, function () {
      var ac = this.get(actionCode);

      if (ac) {
        return actionDescriptionHtml(ac, this.get(createdAt), this.get(username));
      }
    });
  }

  var groupActionCodes = ["invited_group", "removed_group"];
  var icons = {
    "closed.enabled": "lock",
    "closed.disabled": "unlock-alt",
    "autoclosed.enabled": "lock",
    "autoclosed.disabled": "unlock-alt",
    "archived.enabled": "folder",
    "archived.disabled": "folder-open",
    "pinned.enabled": "thumbtack",
    "pinned.disabled": "thumbtack unpinned",
    "pinned_globally.enabled": "thumbtack",
    "pinned_globally.disabled": "thumbtack unpinned",
    "banner.enabled": "thumbtack",
    "banner.disabled": "thumbtack unpinned",
    "visible.enabled": "far-eye",
    "visible.disabled": "far-eye-slash",
    split_topic: "sign-out-alt",
    invited_user: "plus-circle",
    invited_group: "plus-circle",
    user_left: "minus-circle",
    removed_user: "minus-circle",
    removed_group: "minus-circle",
    public_topic: "comment",
    private_topic: "envelope",
    autobumped: "hand-point-right"
  };

  function addPostSmallActionIcon(key, icon) {
    icons[key] = icon;
  }

  function addGroupPostSmallActionCode(actionCode) {
    groupActionCodes.push(actionCode);
  }

  var _default = (0, _widget.createWidget)("post-small-action", {
    buildKey: function buildKey(attrs) {
      return "post-small-act-".concat(attrs.id);
    },
    tagName: "div.small-action.onscreen-post",
    buildId: function buildId(attrs) {
      return "post_".concat(attrs.post_number);
    },
    buildClasses: function buildClasses(attrs) {
      if (attrs.deleted) {
        return "deleted";
      }
    },
    html: function html(attrs) {
      var contents = [];

      if (attrs.canRecover) {
        contents.push(this.attach("button", {
          className: "small-action-recover",
          icon: "undo",
          action: "recoverPost",
          title: "post.controls.undelete"
        }));
      }

      if (attrs.canDelete) {
        contents.push(this.attach("button", {
          className: "small-action-delete",
          icon: "trash-alt",
          action: "deletePost",
          title: "post.controls.delete"
        }));
      }

      if (attrs.canEdit && !attrs.canRecover) {
        contents.push(this.attach("button", {
          className: "small-action-edit",
          icon: "pencil-alt",
          action: "editPost",
          title: "post.controls.edit"
        }));
      }

      contents.push(_post.avatarFor.call(this, "small", {
        template: attrs.avatar_template,
        username: attrs.username,
        url: attrs.usernameUrl
      }));

      if (attrs.actionDescriptionWidget) {
        contents.push(this.attach(attrs.actionDescriptionWidget, attrs));
      } else {
        var description = actionDescriptionHtml(attrs.actionCode, new Date(attrs.created_at), attrs.actionCodeWho, attrs.actionCodePath);
        contents.push(new _rawHtml.default({
          html: "<p>".concat(description, "</p>")
        }));

        if (attrs.cooked) {
          contents.push(new _rawHtml.default({
            html: "<div class='custom-message'>".concat(attrs.cooked, "</div>")
          }));
        }
      }

      return [(0, _virtualDom.h)("div.topic-avatar", (0, _iconLibrary.iconNode)(icons[attrs.actionCode] || "exclamation")), (0, _virtualDom.h)("div.small-action-desc", contents)];
    }
  });

  _exports.default = _default;
});