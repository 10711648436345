define("discourse/templates/components/sub-category-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sHu9jMKQ",
    "block": "{\"symbols\":[\"subsubcategory\"],\"statements\":[[4,\"unless\",[[24,[\"isHidden\"]]],null,{\"statements\":[[0,\"  \"],[7,\"tr\",true],[11,\"data-category-id\",[24,[\"category\",\"id\"]]],[11,\"data-notification-level\",[24,[\"category\",\"notificationLevelString\"]]],[11,\"class\",[29,[[28,\"if\",[[24,[\"category\",\"description_excerpt\"]],\"has-description\",\"no-description\"],null],\" \",[28,\"if\",[[24,[\"category\",\"uploaded_logo\",\"url\"]],\"has-logo\",\"no-logo\"],null]]]],[8],[0,\"\\n    \"],[7,\"td\",true],[11,\"class\",[29,[\"category \",[28,\"if\",[[24,[\"isMuted\"]],\"muted\"],null]]]],[11,\"style\",[28,\"border-color\",[[24,[\"category\",\"color\"]]],null]],[8],[0,\"\\n      \"],[1,[28,\"category-title-link\",null,[[\"tagName\",\"category\"],[\"h4\",[24,[\"category\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"category\",\"description_excerpt\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"category-description subcategory-description\"],[8],[0,\"\\n          \"],[1,[28,\"dir-span\",[[24,[\"category\",\"description_excerpt\"]]],[[\"htmlSafe\"],[\"true\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"category\",\"subcategories\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"subcategories\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"category\",\"subcategories\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"sub-category-item\",null,[[\"category\",\"hideUnread\",\"listType\"],[[23,1,[]],\"true\",[24,[\"listType\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/sub-category-row.hbs"
    }
  });

  _exports.default = _default;
});