define("discourse/controllers/do-not-disturb", ["exports", "discourse/mixins/modal-functionality", "discourse/lib/ajax-error"], function (_exports, _modalFunctionality, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, (_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    duration: null,
    setDuration: function setDuration(duration) {
      this.set("duration", duration);
      this.save();
    },
    save: function save() {
      var _this = this;

      this.currentUser.enterDoNotDisturbFor(this.duration).then(function () {
        _this.send("closeModal");
      }).catch(function (e) {
        _this.flash((0, _ajaxError.extractError)(e), "error");
      });
    },
    navigateToNotificationSchedule: function navigateToNotificationSchedule() {
      this.transitionToRoute("preferences.notifications", this.currentUser);
      this.send("closeModal");
    }
  }, (_applyDecoratedDescriptor(_obj, "setDuration", [_dec], Object.getOwnPropertyDescriptor(_obj, "setDuration"), _obj), _applyDecoratedDescriptor(_obj, "navigateToNotificationSchedule", [_dec2], Object.getOwnPropertyDescriptor(_obj, "navigateToNotificationSchedule"), _obj)), _obj)));

  _exports.default = _default;
});