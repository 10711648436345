define("discourse/controllers/user-card", ["exports", "discourse/lib/url"], function (_exports, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    topic: Ember.inject.controller(),
    actions: {
      filterPosts: function filterPosts(user) {
        var topicController = this.topic;
        topicController.send("filterParticipant", user);
      },
      showUser: function showUser(user) {
        _url.default.routeTo((0, _url.userPath)(user.username_lower));
      },
      showGroup: function showGroup(group) {
        _url.default.routeTo((0, _url.groupPath)(group.name));
      }
    }
  });

  _exports.default = _default;
});