define("discourse/controllers/login", ["exports", "discourse/lib/utilities", "discourse/lib/cookie", "I18n", "discourse/mixins/modal-functionality", "discourse/models/user", "discourse/lib/ajax", "bootbox", "discourse-common/utils/decorators", "pretty-text/sanitizer", "discourse/lib/ajax-error", "discourse/models/login-method", "discourse-common/lib/get-url", "discourse/lib/webauthn", "discourse/lib/computed", "discourse/lib/show-modal", "discourse/lib/waving-hand-url"], function (_exports, _utilities, _cookie, _I18n, _modalFunctionality, _user, _ajax, _bootbox, _decorators, _sanitizer, _ajaxError, _loginMethod, _getUrl, _webauthn, _computed, _showModal, _wavingHandUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _obj, _init;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // This is happening outside of the app via popup
  var AuthErrors = ["requires_invite", "awaiting_approval", "awaiting_activation", "admin_not_allowed_from_ip_address", "not_allowed_from_ip_address"];

  var _default = Ember.Controller.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("showSecondFactor", "showSecurityKey"), _dec2 = (0, _decorators.default)(), _dec3 = (0, _decorators.default)("showSecondFactor", "showSecurityKey"), _dec4 = (0, _decorators.default)("awaitingApproval", "hasAtLeastOneLoginButton", "showSecondFactor", "canLoginLocal", "showSecurityKey"), _dec5 = (0, _decorators.default)("showSecondFactor", "showSecurityKey"), _dec6 = (0, _decorators.default)(), _dec7 = (0, _decorators.default)("loggingIn"), _dec8 = (0, _decorators.default)("loggingIn", "application.canSignUp"), _dec9 = (0, _decorators.default)("canLoginLocalWithEmail"), (_obj = {
    createAccount: Ember.inject.controller(),
    forgotPassword: Ember.inject.controller(),
    application: Ember.inject.controller(),
    loggingIn: false,
    loggedIn: false,
    processingEmailLink: false,
    showLoginButtons: true,
    showSecondFactor: false,
    awaitingApproval: false,
    canLoginLocal: (0, _computed.setting)("enable_local_logins"),
    canLoginLocalWithEmail: (0, _computed.setting)("enable_local_logins_via_email"),
    loginRequired: Ember.computed.alias("application.loginRequired"),
    secondFactorMethod: _user.SECOND_FACTOR_METHODS.TOTP,
    noLoginLocal: Ember.computed.not("canLoginLocal"),
    resetForm: function resetForm() {
      this.setProperties({
        loggingIn: false,
        loggedIn: false,
        secondFactorRequired: false,
        showSecondFactor: false,
        showSecurityKey: false,
        showLoginButtons: true,
        awaitingApproval: false
      });
    },
    credentialsClass: function credentialsClass(showSecondFactor, showSecurityKey) {
      return showSecondFactor || showSecurityKey ? "hidden" : "";
    },
    wavingHandURL: function wavingHandURL() {
      return (0, _wavingHandUrl.wavingHandURL)();
    },
    secondFactorClass: function secondFactorClass(showSecondFactor, showSecurityKey) {
      return showSecondFactor || showSecurityKey ? "" : "hidden";
    },
    modalBodyClasses: function modalBodyClasses(awaitingApproval, hasAtLeastOneLoginButton, showSecondFactor, canLoginLocal, showSecurityKey) {
      var classes = ["login-modal-body"];

      if (awaitingApproval) {
        classes.push("awaiting-approval");
      }

      if (hasAtLeastOneLoginButton && !showSecondFactor && !showSecurityKey) {
        classes.push("has-alt-auth");
      }

      if (!canLoginLocal) {
        classes.push("no-local-login");
      }

      if (showSecondFactor || showSecurityKey) {
        classes.push("second-factor");
      }

      return classes.join(" ");
    },
    disableLoginFields: function disableLoginFields(showSecondFactor, showSecurityKey) {
      return showSecondFactor || showSecurityKey;
    },
    hasAtLeastOneLoginButton: function hasAtLeastOneLoginButton() {
      return (0, _loginMethod.findAll)().length > 0;
    },
    loginButtonLabel: function loginButtonLabel(loggingIn) {
      return loggingIn ? "login.logging_in" : "login.title";
    },
    loginDisabled: Ember.computed.or("loggingIn", "loggedIn"),
    showSignupLink: function showSignupLink(loggingIn, canSignUp) {
      return canSignUp && !loggingIn;
    },
    showSpinner: Ember.computed.readOnly("loggingIn"),
    showLoginWithEmailLink: function showLoginWithEmailLink(canLoginLocalWithEmail) {
      return canLoginLocalWithEmail;
    },
    actions: {
      login: function login() {
        var _this = this;

        if (this.loginDisabled) {
          return;
        }

        if (Ember.isEmpty(this.loginName) || Ember.isEmpty(this.loginPassword)) {
          this.flash(_I18n.default.t("login.blank_username_or_password"), "error");
          return;
        }

        this.set("loggingIn", true);
        (0, _ajax.ajax)("/session", {
          type: "POST",
          data: {
            login: this.loginName,
            password: this.loginPassword,
            second_factor_token: this.securityKeyCredential || this.secondFactorToken,
            second_factor_method: this.secondFactorMethod,
            timezone: moment.tz.guess()
          }
        }).then(function (result) {
          // Successful login
          if (result && result.error) {
            _this.set("loggingIn", false);

            _this.clearFlash();

            if ((result.security_key_enabled || result.totp_enabled) && !_this.secondFactorRequired) {
              _this.setProperties({
                otherMethodAllowed: result.multiple_second_factor_methods,
                secondFactorRequired: true,
                showLoginButtons: false,
                backupEnabled: result.backup_enabled,
                showSecondFactor: result.totp_enabled,
                showSecurityKey: result.security_key_enabled,
                secondFactorMethod: result.security_key_enabled ? _user.SECOND_FACTOR_METHODS.SECURITY_KEY : _user.SECOND_FACTOR_METHODS.TOTP,
                securityKeyChallenge: result.challenge,
                securityKeyAllowedCredentialIds: result.allowed_credential_ids
              }); // only need to focus the 2FA input for TOTP


              if (!_this.showSecurityKey) {
                Ember.run.schedule("afterRender", function () {
                  return document.getElementById("second-factor").querySelector("input").focus();
                });
              }

              return;
            } else if (result.reason === "not_activated") {
              _this.send("showNotActivated", {
                username: _this.loginName,
                sentTo: (0, _sanitizer.escape)(result.sent_to_email),
                currentEmail: (0, _sanitizer.escape)(result.current_email)
              });
            } else if (result.reason === "suspended") {
              _this.send("closeModal");

              _bootbox.default.alert(result.error);
            } else {
              _this.flash(result.error, "error");
            }
          } else {
            _this.set("loggedIn", true); // Trigger the browser's password manager using the hidden static login form:


            var hiddenLoginForm = document.getElementById("hidden-login-form");

            var applyHiddenFormInputValue = function applyHiddenFormInputValue(value, key) {
              if (!hiddenLoginForm) {
                return;
              }

              hiddenLoginForm.querySelector("input[name=".concat(key, "]")).value = value;
            };

            var destinationUrl = (0, _cookie.default)("destination_url");
            var ssoDestinationUrl = (0, _cookie.default)("sso_destination_url");
            applyHiddenFormInputValue(_this.loginName, "username");
            applyHiddenFormInputValue(_this.loginPassword, "password");

            if (ssoDestinationUrl) {
              (0, _cookie.removeCookie)("sso_destination_url");
              window.location.assign(ssoDestinationUrl);
              return;
            } else if (destinationUrl) {
              // redirect client to the original URL
              (0, _cookie.removeCookie)("destination_url");
              applyHiddenFormInputValue(destinationUrl, "redirect");
            } else {
              applyHiddenFormInputValue(window.location.href, "redirect");
            }

            if (hiddenLoginForm) {
              if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g) && navigator.userAgent.match(/Safari/g)) {
                // In case of Safari on iOS do not submit hidden login form
                window.location.href = hiddenLoginForm.querySelector("input[name=redirect]").value;
              } else {
                hiddenLoginForm.submit();
              }
            }

            return;
          }
        }, function (e) {
          // Failed to login
          if (e.jqXHR && e.jqXHR.status === 429) {
            _this.flash(_I18n.default.t("login.rate_limit"), "error");
          } else if (!(0, _utilities.areCookiesEnabled)()) {
            _this.flash(_I18n.default.t("login.cookies_error"), "error");
          } else {
            _this.flash(_I18n.default.t("login.error"), "error");
          }

          _this.set("loggingIn", false);
        });
        return false;
      },
      externalLogin: function externalLogin(loginMethod) {
        var _this2 = this;

        var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
            _ref$signup = _ref.signup,
            signup = _ref$signup === void 0 ? false : _ref$signup;

        if (this.loginDisabled) {
          return;
        }

        this.set("loggingIn", true);
        loginMethod.doLogin({
          signup: signup
        }).catch(function () {
          return _this2.set("loggingIn", false);
        });
      },
      createAccount: function createAccount() {
        var createAccountController = this.createAccount;

        if (createAccountController) {
          createAccountController.resetForm();
          var loginName = this.loginName;

          if (loginName && loginName.indexOf("@") > 0) {
            createAccountController.set("accountEmail", loginName);
          } else {
            createAccountController.set("accountUsername", loginName);
          }
        }

        this.send("showCreateAccount");
      },
      forgotPassword: function forgotPassword() {
        var forgotPasswordController = this.forgotPassword;

        if (forgotPasswordController) {
          forgotPasswordController.set("accountEmailOrUsername", this.loginName);
        }

        this.send("showForgotPassword");
      },
      emailLogin: function emailLogin() {
        var _this3 = this;

        if (this.processingEmailLink) {
          return;
        }

        if (Ember.isEmpty(this.loginName)) {
          this.flash(_I18n.default.t("login.blank_username"), "info");
          return;
        }

        this.set("processingEmailLink", true);
        (0, _ajax.ajax)("/u/email-login", {
          data: {
            login: this.loginName.trim()
          },
          type: "POST"
        }).then(function (data) {
          var loginName = (0, _utilities.escapeExpression)(_this3.loginName);
          var isEmail = loginName.match(/@/);
          var key = "email_login.complete_".concat(isEmail ? "email" : "username");

          if (data.user_found === false) {
            _this3.flash(_I18n.default.t("".concat(key, "_not_found"), {
              email: loginName,
              username: loginName
            }), "error");
          } else {
            var postfix = data.hide_taken ? "" : "_found";

            _this3.flash(_I18n.default.t("".concat(key).concat(postfix), {
              email: loginName,
              username: loginName
            }));
          }
        }).catch(function (e) {
          return _this3.flash((0, _ajaxError.extractError)(e), "error");
        }).finally(function () {
          return _this3.set("processingEmailLink", false);
        });
      },
      authenticateSecurityKey: function authenticateSecurityKey() {
        var _this4 = this;

        (0, _webauthn.getWebauthnCredential)(this.securityKeyChallenge, this.securityKeyAllowedCredentialIds, function (credentialData) {
          _this4.set("securityKeyCredential", credentialData);

          _this4.send("login");
        }, function (errorMessage) {
          _this4.flash(errorMessage, "error");
        });
      }
    },
    authenticationComplete: function authenticationComplete(options) {
      var _this5 = this;

      var loginError = function loginError(errorMsg, className, callback) {
        (0, _showModal.default)("login");
        Ember.run.next(function () {
          if (callback) {
            callback();
          }

          _this5.flash(errorMsg, className || "success");
        });
      };

      if (options.awaiting_approval && !this.canLoginLocal && !this.canLoginLocalWithEmail) {
        this.set("awaitingApproval", true);
      }

      if (options.omniauth_disallow_totp) {
        return loginError(_I18n.default.t("login.omniauth_disallow_totp"), "error", function () {
          _this5.setProperties({
            loginName: options.email,
            showLoginButtons: false
          });

          document.getElementById("login-account-password").focus();
        });
      }

      for (var i = 0; i < AuthErrors.length; i++) {
        var cond = AuthErrors[i];

        if (options[cond]) {
          return loginError(_I18n.default.t("login.".concat(cond)));
        }
      }

      if (options.suspended) {
        return loginError(options.suspended_message, "error");
      } // Reload the page if we're authenticated


      if (options.authenticated) {
        var destinationUrl = (0, _cookie.default)("destination_url") || options.destination_url;

        if (destinationUrl) {
          // redirect client to the original URL
          (0, _cookie.removeCookie)("destination_url");
          window.location.href = destinationUrl;
        } else if (window.location.pathname === (0, _getUrl.default)("/login")) {
          window.location = (0, _getUrl.default)("/");
        } else {
          window.location.reload();
        }

        return;
      }

      var skipConfirmation = options && this.siteSettings.auth_skip_create_confirm;
      var createAccountController = this.createAccount;
      createAccountController.setProperties({
        accountEmail: options.email,
        accountUsername: options.username,
        accountName: options.name,
        authOptions: Ember.Object.create(options),
        skipConfirmation: skipConfirmation
      });
      Ember.run.next(function () {
        (0, _showModal.default)("createAccount", {
          modalClass: "create-account",
          titleAriaElementId: "create-account-title"
        });
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "credentialsClass", [_dec], Object.getOwnPropertyDescriptor(_obj, "credentialsClass"), _obj), _applyDecoratedDescriptor(_obj, "wavingHandURL", [_dec2], (_init = Object.getOwnPropertyDescriptor(_obj, "wavingHandURL"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "secondFactorClass", [_dec3], Object.getOwnPropertyDescriptor(_obj, "secondFactorClass"), _obj), _applyDecoratedDescriptor(_obj, "modalBodyClasses", [_dec4], Object.getOwnPropertyDescriptor(_obj, "modalBodyClasses"), _obj), _applyDecoratedDescriptor(_obj, "disableLoginFields", [_dec5], Object.getOwnPropertyDescriptor(_obj, "disableLoginFields"), _obj), _applyDecoratedDescriptor(_obj, "hasAtLeastOneLoginButton", [_dec6], Object.getOwnPropertyDescriptor(_obj, "hasAtLeastOneLoginButton"), _obj), _applyDecoratedDescriptor(_obj, "loginButtonLabel", [_dec7], Object.getOwnPropertyDescriptor(_obj, "loginButtonLabel"), _obj), _applyDecoratedDescriptor(_obj, "showSignupLink", [_dec8], Object.getOwnPropertyDescriptor(_obj, "showSignupLink"), _obj), _applyDecoratedDescriptor(_obj, "showLoginWithEmailLink", [_dec9], Object.getOwnPropertyDescriptor(_obj, "showLoginWithEmailLink"), _obj)), _obj)));

  _exports.default = _default;
});