define("discourse/mixins/can-check-emails", ["exports", "discourse/lib/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isCurrentUser: (0, _computed.propertyEqual)("model.id", "currentUser.id"),
    showEmailOnProfile: (0, _computed.setting)("moderators_view_emails"),
    canStaffCheckEmails: Ember.computed.and("showEmailOnProfile", "currentUser.staff"),
    canAdminCheckEmails: Ember.computed.alias("currentUser.admin"),
    canCheckEmails: Ember.computed.or("isCurrentUser", "canStaffCheckEmails", "canAdminCheckEmails")
  });

  _exports.default = _default;
});