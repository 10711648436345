define("discourse/components/directory-item", ["exports", "discourse/lib/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "tr",
    classNameBindings: ["me"],
    me: (0, _computed.propertyEqual)("item.user.id", "currentUser.id"),
    columns: null
  });

  _exports.default = _default;
});