define("discourse/components/share-panel", ["exports", "I18n", "discourse/lib/sharing", "discourse-common/utils/decorators", "discourse/lib/utilities"], function (_exports, _I18n, _sharing, _decorators, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("topic.{isPrivateMessage,invisible,category}"), _dec2 = (0, _decorators.default)("type", "topic.title"), _dec3 = (0, _decorators.default)("panel.model.shareUrl", "topic.shareUrl"), (_obj = {
    tagName: null,
    type: Ember.computed.alias("panel.model.type"),
    topic: Ember.computed.alias("panel.model.topic"),
    privateCategory: Ember.computed.alias("panel.model.topic.category.read_restricted"),
    sources: function sources(topic) {
      var privateContext = this.siteSettings.login_required || topic && topic.isPrivateMessage || topic && topic.invisible || this.privateCategory;
      return _sharing.default.activeSources(this.siteSettings.share_links, privateContext);
    },
    shareTitle: function shareTitle(type, topicTitle) {
      topicTitle = (0, _utilities.escapeExpression)(topicTitle);
      return _I18n.default.t("share.topic_html", {
        topicTitle: topicTitle
      });
    },
    shareUrl: function shareUrl(forcedShareUrl, _shareUrl) {
      _shareUrl = forcedShareUrl || _shareUrl;

      if (Ember.isEmpty(_shareUrl)) {
        return;
      } // Relative urls


      if (_shareUrl.indexOf("/") === 0) {
        var location = window.location;
        _shareUrl = "".concat(location.protocol, "//").concat(location.host).concat(_shareUrl);
      }

      return encodeURI(_shareUrl);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.later(function () {
        if (_this.element) {
          var textArea = _this.element.querySelector(".topic-share-url");

          textArea.style.height = textArea.scrollHeight + "px";
          textArea.focus();
          textArea.setSelectionRange(0, _this.shareUrl.length);
        }
      }, 200);
    },
    actions: {
      share: function share(source) {
        _sharing.default.shareSource(source, {
          url: this.shareUrl,
          title: this.get("topic.title")
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "sources", [_dec], Object.getOwnPropertyDescriptor(_obj, "sources"), _obj), _applyDecoratedDescriptor(_obj, "shareTitle", [_dec2], Object.getOwnPropertyDescriptor(_obj, "shareTitle"), _obj), _applyDecoratedDescriptor(_obj, "shareUrl", [_dec3], Object.getOwnPropertyDescriptor(_obj, "shareUrl"), _obj)), _obj)));

  _exports.default = _default;
});