define("discourse/controllers/delete-topic-confirm", ["exports", "I18n", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators"], function (_exports, _I18n, _modalFunctionality, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // Modal that displays confirmation text when user deletes a topic
  // The modal will display only if the topic exceeds a certain amount of views
  var _default = Ember.Controller.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("deletingTopic"), _dec2 = Ember._action, (_obj = {
    topicController: Ember.inject.controller("topic"),
    deletingTopic: false,
    buttonTitle: function buttonTitle(deletingTopic) {
      return deletingTopic ? _I18n.default.t("deleting") : _I18n.default.t("post.controls.delete_topic_confirm_modal_yes");
    },
    onShow: function onShow() {
      this.set("deletingTopic", false);
    },
    deleteTopic: function deleteTopic() {
      var _this = this;

      this.set("deletingTopic", true);
      this.topicController.model.destroy(this.currentUser).then(function () {
        return _this.send("closeModal");
      }).catch(function () {
        _this.flash(_I18n.default.t("post.controls.delete_topic_error"), "error");

        _this.set("deletingTopic", false);
      });
      return false;
    }
  }, (_applyDecoratedDescriptor(_obj, "buttonTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "buttonTitle"), _obj), _applyDecoratedDescriptor(_obj, "deleteTopic", [_dec2], Object.getOwnPropertyDescriptor(_obj, "deleteTopic"), _obj)), _obj)));

  _exports.default = _default;
});