define("discourse/templates/components/navigation-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3JjIRiiF",
    "block": "{\"symbols\":[\"navItem\"],\"statements\":[[4,\"each\",[[24,[\"navItems\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"navigation-item\",null,[[\"content\",\"filterMode\",\"category\"],[[23,1,[]],[24,[\"filterMode\"]],[24,[\"category\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[1,[28,\"custom-html\",null,[[\"name\",\"tagName\"],[\"extraNavItem\",\"li\"]]],false],[0,\"\\n\"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"connectorTagName\",\"args\"],[\"extra-nav-item\",\"li\",[28,\"hash\",null,[[\"category\",\"filterMode\"],[[24,[\"category\"]],[24,[\"filterMode\"]]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/navigation-bar.hbs"
    }
  });

  _exports.default = _default;
});