define("discourse/components/pending-post", ["exports", "discourse-common/utils/decorators", "discourse/lib/load-oneboxes", "discourse/lib/ajax", "pretty-text/upload-short-url"], function (_exports, _decorators, _loadOneboxes2, _ajax, _uploadShortUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_obj = {
    didRender: function didRender() {
      this._loadOneboxes();

      this._resolveUrls();
    },
    _loadOneboxes: function _loadOneboxes() {
      (0, _loadOneboxes2.loadOneboxes)(this.element, _ajax.ajax, this.post.topic_id, this.post.category_id, this.siteSettings.max_oneboxes_per_post, true);
    },
    _resolveUrls: function _resolveUrls() {
      (0, _uploadShortUrl.resolveAllShortUrls)(_ajax.ajax, this.siteSettings, this.element, this.opts);
    }
  }, (_applyDecoratedDescriptor(_obj, "_loadOneboxes", [_decorators.afterRender], Object.getOwnPropertyDescriptor(_obj, "_loadOneboxes"), _obj), _applyDecoratedDescriptor(_obj, "_resolveUrls", [_decorators.afterRender], Object.getOwnPropertyDescriptor(_obj, "_resolveUrls"), _obj)), _obj));

  _exports.default = _default;
});