define("discourse/components/login-modal", ["exports", "discourse/lib/cookie"], function (_exports, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var prefillUsername = $("#hidden-login-form input[name=username]").val();

      if (prefillUsername) {
        this.set("loginName", prefillUsername);
        this.set("loginPassword", $("#hidden-login-form input[name=password]").val());
      } else if ((0, _cookie.default)("email")) {
        this.set("loginName", (0, _cookie.default)("email"));
      }

      Ember.run.schedule("afterRender", function () {
        $("#login-account-password, #login-account-name, #login-second-factor").keydown(function (e) {
          if (e.key === "Enter") {
            _this.action();
          }
        });
      });
    }
  });

  _exports.default = _default;
});