define("discourse/components/group-post", ["exports", "discourse-common/utils/decorators", "discourse-common/lib/get-url", "discourse/lib/settings", "discourse/lib/computed"], function (_exports, _decorators, _getUrl, _settings, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("post.url"), _dec2 = (0, _decorators.default)("post.user"), _dec3 = (0, _decorators.default)("post.user"), (_obj = {
    classNameBindings: [":user-stream-item", ":item", "moderatorAction", "primaryGroup"],
    postUrl: function postUrl(url) {
      return (0, _getUrl.default)(url);
    },
    moderatorAction: (0, _computed.propertyEqual)("post.post_type", "site.post_types.moderator_action"),
    name: function name() {
      if ((0, _settings.prioritizeNameInUx)(this.post.user.name)) {
        return this.post.user.name;
      }

      return this.post.user.username;
    },
    primaryGroup: function primaryGroup() {
      if (this.post.user.primary_group_name) {
        return "group-".concat(this.post.user.primary_group_name);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "postUrl", [_dec], Object.getOwnPropertyDescriptor(_obj, "postUrl"), _obj), _applyDecoratedDescriptor(_obj, "name", [_dec2], Object.getOwnPropertyDescriptor(_obj, "name"), _obj), _applyDecoratedDescriptor(_obj, "primaryGroup", [_dec3], Object.getOwnPropertyDescriptor(_obj, "primaryGroup"), _obj)), _obj)));

  _exports.default = _default;
});