define("discourse/lib/lightbox", ["exports", "discourse/lib/utilities", "I18n", "discourse/models/user", "discourse/lib/load-script", "discourse-common/lib/icon-library", "discourse/helpers/loading-spinner", "discourse-common/lib/helpers", "discourse-common/config/environment"], function (_exports, _utilities, _I18n, _user, _loadScript, _iconLibrary, _loadingSpinner, _helpers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(elem, siteSettings) {
    if (!elem) {
      return;
    }

    var lightboxes = elem.querySelectorAll("*:not(.spoiler):not(.spoiled) a.lightbox");

    if (!lightboxes.length) {
      return;
    }

    var caps = (0, _helpers.helperContext)().capabilities;
    var imageClickNavigation = caps.touch;
    (0, _loadScript.default)("/javascripts/jquery.magnific-popup.min.js").then(function () {
      $(lightboxes).magnificPopup({
        type: "image",
        closeOnContentClick: false,
        removalDelay: (0, _environment.isTesting)() ? 0 : 300,
        mainClass: "mfp-zoom-in",
        tClose: _I18n.default.t("lightbox.close"),
        tLoading: _loadingSpinner.spinnerHTML,
        gallery: {
          enabled: true,
          tPrev: _I18n.default.t("lightbox.previous"),
          tNext: _I18n.default.t("lightbox.next"),
          tCounter: _I18n.default.t("lightbox.counter"),
          navigateByImgClick: imageClickNavigation
        },
        ajax: {
          tError: _I18n.default.t("lightbox.content_load_error")
        },
        callbacks: {
          open: function open() {
            if (!imageClickNavigation) {
              var wrap = this.wrap,
                  img = this.currItem.img,
                  maxHeight = img.css("max-height");
              wrap.on("click.pinhandler", "img", function () {
                wrap.toggleClass("mfp-force-scrollbars");
                img.css("max-height", wrap.hasClass("mfp-force-scrollbars") ? "none" : maxHeight);
              });
            }

            if ((0, _utilities.isAppWebview)()) {
              (0, _utilities.postRNWebviewMessage)("headerBg", $(".mfp-bg").css("background-color"));
            }
          },
          change: function change() {
            this.wrap.removeClass("mfp-force-scrollbars");
          },
          beforeClose: function beforeClose() {
            this.wrap.off("click.pinhandler");
            this.wrap.removeClass("mfp-force-scrollbars");

            if ((0, _utilities.isAppWebview)()) {
              (0, _utilities.postRNWebviewMessage)("headerBg", $(".d-header").css("background-color"));
            }
          }
        },
        image: {
          tError: _I18n.default.t("lightbox.image_load_error"),
          titleSrc: function titleSrc(item) {
            var href = item.el.data("download-href") || item.src;
            var src = [(0, _utilities.escapeExpression)(item.el.attr("title")), $("span.informations", item.el).text()];

            if (!siteSettings.prevent_anons_from_downloading_files || _user.default.current()) {
              src.push('<a class="image-source-link" href="' + href + '">' + (0, _iconLibrary.renderIcon)("string", "download") + _I18n.default.t("lightbox.download") + "</a>");
            }

            return src.join(" &middot; ");
          }
        }
      });
    });
  }
});