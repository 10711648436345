define("discourse/widgets/default-notification-item", ["exports", "discourse/lib/url", "discourse/lib/ajax", "discourse/lib/utilities", "I18n", "discourse/widgets/raw-html", "discourse/widgets/widget", "discourse/lib/text", "discourse-common/lib/get-url", "virtual-dom", "discourse-common/lib/icon-library", "discourse/lib/intercept-click", "discourse/lib/cookie"], function (_exports, _url, _ajax, _utilities, _I18n, _rawHtml, _widget, _text, _getUrl, _virtualDom, _iconLibrary, _interceptClick, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DefaultNotificationItem = void 0;
  var DefaultNotificationItem = (0, _widget.createWidget)("default-notification-item", {
    tagName: "li",
    buildClasses: function buildClasses(attrs) {
      var classNames = [];

      if (attrs.get("read")) {
        classNames.push("read");
      }

      if (attrs.is_warning) {
        classNames.push("is-warning");
      }

      var notificationType = attrs.notification_type;
      var lookup = this.site.get("notificationLookup");
      var notificationName = lookup[notificationType];

      if (notificationName) {
        classNames.push(notificationName.replace(/_/g, "-"));
      }

      return classNames;
    },
    url: function url(data) {
      var attrs = this.attrs;
      var badgeId = data.badge_id;

      if (badgeId) {
        var badgeSlug = data.badge_slug;

        if (!badgeSlug) {
          var badgeName = data.badge_name;
          badgeSlug = badgeName.replace(/[^A-Za-z0-9_]+/g, "-").toLowerCase();
        }

        var username = data.username;
        username = username ? "?username=" + username.toLowerCase() : "";
        return (0, _getUrl.default)("/badges/" + badgeId + "/" + badgeSlug + username);
      }

      var topicId = attrs.topic_id;

      if (topicId) {
        return (0, _utilities.postUrl)(attrs.slug, topicId, attrs.post_number);
      }

      if (data.group_id) {
        return (0, _url.userPath)(data.username + "/messages/group/" + data.group_name);
      }
    },
    description: function description(data) {
      var badgeName = data.badge_name;

      if (badgeName) {
        return (0, _utilities.escapeExpression)(badgeName);
      }

      var groupName = data.group_name;

      if (groupName) {
        if (this.attrs.fancy_title) {
          if (this.attrs.topic_id) {
            return "<span class=\"mention-group notify\">@".concat(groupName, "</span><span data-topic-id=\"").concat(this.attrs.topic_id, "\"> ").concat(this.attrs.fancy_title, "</span>");
          }

          return "<span class=\"mention-group notify\">@".concat(groupName, "</span> ").concat(this.attrs.fancy_title);
        }
      }

      if (this.attrs.fancy_title) {
        if (this.attrs.topic_id) {
          return "<span data-topic-id=\"".concat(this.attrs.topic_id, "\">").concat(this.attrs.fancy_title, "</span>");
        }

        return this.attrs.fancy_title;
      }

      var description = data.topic_title;
      return Ember.isEmpty(description) ? "" : (0, _utilities.escapeExpression)(description);
    },
    text: function text(notificationName, data) {
      var username = (0, _utilities.formatUsername)(data.display_username);
      var description = this.description(data, notificationName);
      return _I18n.default.t("notifications.".concat(notificationName), {
        description: description,
        username: username
      });
    },
    icon: function icon(notificationName) {
      return (0, _iconLibrary.iconNode)("notification.".concat(notificationName));
    },
    _addA11yAttrsTo: function _addA11yAttrsTo(icon, notificationName) {
      icon.properties.attributes["aria-label"] = _I18n.default.t("notifications.titles.".concat(notificationName));
      icon.properties.attributes["aria-hidden"] = false;
      icon.properties.attributes["role"] = "img";
      return icon;
    },
    notificationTitle: function notificationTitle(notificationName) {
      if (notificationName) {
        return _I18n.default.t("notifications.titles.".concat(notificationName));
      } else {
        return "";
      }
    },
    html: function html(attrs) {
      var notificationType = attrs.notification_type;
      var lookup = this.site.get("notificationLookup");
      var notificationName = lookup[notificationType];
      var data = attrs.data;
      var text = (0, _text.emojiUnescape)(this.text(notificationName, data));
      var icon = this.icon(notificationName, data);

      this._addA11yAttrsTo(icon, notificationName);

      var title = this.notificationTitle(notificationName, data); // We can use a `<p>` tag here once other languages have fixed their HTML
      // translations.

      var html = new _rawHtml.default({
        html: "<div>".concat(text, "</div>")
      });
      var contents = [icon, html];
      var href = this.url(data);
      return href ? (0, _virtualDom.h)("a", {
        attributes: {
          href: href,
          title: title,
          "data-auto-route": true
        }
      }, contents) : contents;
    },
    click: function click(e) {
      this.attrs.set("read", true);
      var id = this.attrs.id;
      (0, _ajax.setTransientHeader)("Discourse-Clear-Notifications", id);
      (0, _cookie.default)("cn", id, {
        path: (0, _getUrl.default)("/")
      });

      if ((0, _interceptClick.wantsNewWindow)(e)) {
        return;
      }

      e.preventDefault();
      this.sendWidgetEvent("linkClicked");

      if (this.attrs.data.revision_number) {
        this.appEvents.trigger("edit-notification:clicked", {
          topicId: this.attrs.topic_id,
          postNumber: this.attrs.post_number,
          revisionNumber: this.attrs.data.revision_number
        });
      }

      _url.default.routeTo(this.url(this.attrs.data));
    },
    mouseUp: function mouseUp(event) {
      // dismiss notification on middle click
      if (event.which === 2 && !this.attrs.read) {
        this.attrs.set("read", true);
        (0, _ajax.ajax)("/notifications/mark-read", {
          method: "PUT",
          data: {
            id: this.attrs.id
          }
        });
      }
    }
  });
  _exports.DefaultNotificationItem = DefaultNotificationItem;
});