define("discourse/templates/components/category-read-only-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SpQ+najL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"shouldShow\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"alert alert-info category-read-only-banner\"],[8],[0,\"\\n      \"],[1,[28,\"html-safe\",[[24,[\"category\",\"read_only_banner\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/category-read-only-banner.hbs"
    }
  });

  _exports.default = _default;
});