define("discourse/widgets/hamburger-menu", ["exports", "discourse/lib/url", "discourse/widgets/widget", "I18n", "discourse/lib/notification-levels", "discourse/lib/ajax", "discourse-common/lib/get-url", "virtual-dom", "discourse/lib/intercept-click"], function (_exports, _url, _widget, _I18n, _notificationLevels, _ajax, _getUrl, _virtualDom, _interceptClick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var flatten = function flatten(array) {
    return [].concat.apply([], array);
  };

  (0, _widget.createWidget)("priority-faq-link", {
    tagName: "a.faq-priority.widget-link",
    buildAttributes: function buildAttributes(attrs) {
      return {
        href: attrs.href
      };
    },
    html: function html() {
      return [_I18n.default.t("faq"), " ", (0, _virtualDom.h)("span.badge.badge-notification", _I18n.default.t("new_item"))];
    },
    click: function click(e) {
      var href = this.attrs.href,
          currentUser = this.currentUser,
          siteSettings = this.siteSettings;

      if (siteSettings.faq_url === href) {
        (0, _ajax.ajax)((0, _url.userPath)("read-faq"), {
          type: "POST"
        }).then(function () {
          currentUser.set("read_faq", true);

          if ((0, _interceptClick.wantsNewWindow)(e)) {
            return;
          }

          e.preventDefault();

          _url.default.routeTo(href);
        });
      } else {
        if ((0, _interceptClick.wantsNewWindow)(e)) {
          return;
        }

        e.preventDefault();

        _url.default.routeTo(href);
      }
    }
  });

  var _default = (0, _widget.createWidget)("hamburger-menu", {
    buildKey: function buildKey() {
      return "hamburger-menu";
    },
    tagName: "div.hamburger-panel",
    settings: {
      showCategories: true,
      maxWidth: 320,
      showFAQ: true,
      showAbout: true
    },
    defaultState: function defaultState() {
      return {
        loaded: false,
        loading: false
      };
    },
    adminLinks: function adminLinks() {
      var _this = this;

      var currentUser = this.currentUser;
      var links = [{
        route: "admin",
        className: "admin-link",
        icon: "wrench",
        label: "admin_title"
      }];

      if (currentUser.admin) {
        links.push({
          href: "/admin/site_settings",
          icon: "cog",
          label: "admin.site_settings.title",
          className: "settings-link"
        });
      }

      return links.map(function (l) {
        return _this.attach("link", l);
      });
    },
    lookupCount: function lookupCount(type) {
      var tts = this.register.lookup("topic-tracking-state:main");
      return tts ? tts.lookupCount(type) : 0;
    },
    generalLinks: function generalLinks() {
      var _this2 = this;

      var attrs = this.attrs,
          currentUser = this.currentUser,
          siteSettings = this.siteSettings,
          state = this.state;
      var links = [];
      links.push({
        route: "discovery.latest",
        className: "latest-topics-link",
        label: "filters.latest.title",
        title: "filters.latest.help"
      });

      if (currentUser) {
        links.push({
          route: "discovery.new",
          className: "new-topics-link",
          labelCount: "filters.new.title_with_count",
          label: "filters.new.title",
          title: "filters.new.help",
          count: this.lookupCount("new")
        });
        links.push({
          route: "discovery.unread",
          className: "unread-topics-link",
          labelCount: "filters.unread.title_with_count",
          label: "filters.unread.title",
          title: "filters.unread.help",
          count: this.lookupCount("unread")
        });

        if (currentUser.can_review) {
          links.push({
            route: siteSettings.reviewable_default_topics ? "review.topics" : "review",
            className: "review",
            label: "review.title",
            badgeCount: "reviewable_count",
            badgeClass: "reviewables"
          });
        }
      }

      links.push({
        route: "discovery.top",
        className: "top-topics-link",
        label: "filters.top.title",
        title: "filters.top.help"
      });

      if (siteSettings.enable_badges) {
        links.push({
          route: "badges",
          className: "badge-link",
          label: "badges.title"
        });
      }

      var canSeeUserProfiles = currentUser || !siteSettings.hide_user_profiles_from_public;

      if (siteSettings.enable_user_directory && canSeeUserProfiles) {
        links.push({
          route: "users",
          className: "user-directory-link",
          label: "directory.title"
        });
      }

      if (siteSettings.enable_group_directory) {
        links.push({
          route: "groups",
          className: "groups-link",
          label: "groups.index.title"
        });
      }

      if (siteSettings.tagging_enabled) {
        links.push({
          route: "tags",
          label: "tagging.tags"
        });
      }

      var extraLinks = flatten((0, _widget.applyDecorators)(this, "generalLinks", attrs, state));
      return links.concat(extraLinks).map(function (l) {
        return _this2.attach("link", l);
      });
    },
    listCategories: function listCategories() {
      var currentUser = this.currentUser,
          site = this.site,
          siteSettings = this.siteSettings;
      var maxCategoriesToDisplay = siteSettings.header_dropdown_category_count;
      var categories = [];

      if (currentUser) {
        var allCategories = site.get("categories").filter(function (c) {
          return c.notification_level !== _notificationLevels.NotificationLevels.MUTED;
        });
        categories = allCategories.filter(function (c) {
          return c.get("newTopics") > 0 || c.get("unreadTopics") > 0;
        }).sort(function (a, b) {
          return b.get("newTopics") + b.get("unreadTopics") - (a.get("newTopics") + a.get("unreadTopics"));
        });
        var topCategoryIds = currentUser.get("top_category_ids") || [];
        topCategoryIds.forEach(function (id) {
          var category = allCategories.find(function (c) {
            return c.id === id;
          });

          if (category && !categories.includes(category)) {
            categories.push(category);
          }
        });
        categories = categories.concat(allCategories.filter(function (c) {
          return !categories.includes(c);
        }).sort(function (a, b) {
          return b.topic_count - a.topic_count;
        }));
      } else {
        categories = site.get("categoriesByCount").filter(function (c) {
          return c.notification_level !== _notificationLevels.NotificationLevels.MUTED;
        });
      }

      if (!siteSettings.allow_uncategorized_topics) {
        categories = categories.filter(function (c) {
          return c.id !== site.uncategorized_category_id;
        });
      }

      var moreCount = categories.length - maxCategoriesToDisplay;
      categories = categories.slice(0, maxCategoriesToDisplay);
      return this.attach("hamburger-categories", {
        categories: categories,
        moreCount: moreCount
      });
    },
    footerLinks: function footerLinks(prioritizeFaq, faqUrl) {
      var _this3 = this;

      var attrs = this.attrs,
          capabilities = this.capabilities,
          settings = this.settings,
          site = this.site,
          siteSettings = this.siteSettings,
          state = this.state;
      var links = [];

      if (settings.showAbout) {
        links.push({
          route: "about",
          className: "about-link",
          label: "about.simple_title"
        });
      }

      if (settings.showFAQ && !prioritizeFaq) {
        links.push({
          href: faqUrl,
          className: "faq-link",
          label: "faq"
        });
      }

      if (!site.mobileView && !capabilities.touch) {
        links.push({
          href: "",
          action: "showKeyboard",
          className: "keyboard-shortcuts-link",
          label: "keyboard_shortcuts_help.title"
        });
      }

      var mobileTouch = siteSettings.enable_mobile_theme && capabilities.touch;

      if (site.mobileView || mobileTouch) {
        links.push({
          action: "toggleMobileView",
          className: "mobile-toggle-link",
          label: site.mobileView ? "desktop_view" : "mobile_view"
        });
      }

      var extraLinks = flatten((0, _widget.applyDecorators)(this, "footerLinks", attrs, state));
      return links.concat(extraLinks).map(function (l) {
        return _this3.attach("link", l);
      });
    },
    panelContents: function panelContents() {
      var _this4 = this;

      var attrs = this.attrs,
          currentUser = this.currentUser,
          settings = this.settings,
          siteSettings = this.siteSettings,
          state = this.state;
      var results = [];
      var faqUrl = siteSettings.faq_url || (0, _getUrl.default)("/faq");
      var prioritizeFaq = settings.showFAQ && currentUser && !currentUser.read_faq;

      if (prioritizeFaq) {
        results.push(this.attach("menu-links", {
          name: "faq-link",
          heading: true,
          contents: function contents() {
            return _this4.attach("priority-faq-link", {
              href: faqUrl
            });
          }
        }));
      }

      if (currentUser && currentUser.staff) {
        results.push(this.attach("menu-links", {
          name: "admin-links",
          contents: function contents() {
            var extraLinks = flatten((0, _widget.applyDecorators)(_this4, "admin-links", attrs, state));
            return _this4.adminLinks().concat(extraLinks);
          }
        }));
      }

      results.push(this.attach("menu-links", {
        name: "general-links",
        contents: function contents() {
          return _this4.generalLinks();
        }
      }));

      if (settings.showCategories) {
        results.push(this.listCategories());
        results.push((0, _virtualDom.h)("hr.categories-separator"));
      }

      results.push(this.attach("menu-links", {
        name: "footer-links",
        omitRule: true,
        contents: function contents() {
          return _this4.footerLinks(prioritizeFaq, faqUrl);
        }
      }));
      return results;
    },
    refreshReviewableCount: function refreshReviewableCount(state) {
      var _this5 = this;

      var currentUser = this.currentUser;

      if (state.loading || !currentUser || !currentUser.can_review) {
        return;
      }

      state.loading = true;
      return (0, _ajax.ajax)("/review/count.json").then(function (_ref) {
        var count = _ref.count;
        return currentUser.set("reviewable_count", count);
      }).finally(function () {
        state.loaded = true;
        state.loading = false;

        _this5.scheduleRerender();
      });
    },
    html: function html(attrs, state) {
      var _this6 = this;

      if (!state.loaded) {
        this.refreshReviewableCount(state);
      }

      return this.attach("menu-panel", {
        contents: function contents() {
          return _this6.panelContents();
        },
        maxWidth: this.settings.maxWidth
      });
    },
    clickOutsideMobile: function clickOutsideMobile(e) {
      var _this7 = this;

      var centeredElement = document.elementFromPoint(e.clientX, e.clientY);
      var parents = document.elementsFromPoint(e.clientX, e.clientY).some(function (ele) {
        return ele.classList.contains("panel");
      });

      if (!centeredElement.classList.contains("header-cloak") && parents) {
        this.sendWidgetAction("toggleHamburger");
      } else {
        var windowWidth = document.body.offsetWidth;
        var panel = document.querySelector(".menu-panel");
        panel.classList.add("animate");
        var offsetDirection = this.site.mobileView ? -1 : 1;
        offsetDirection = document.querySelector("html").classList["direction"] === "rtl" ? -offsetDirection : offsetDirection;
        panel.style.setProperty("--offset", "".concat(offsetDirection * windowWidth, "px"));
        var headerCloak = document.querySelector(".header-cloak");
        headerCloak.classList.add("animate");
        headerCloak.style.setProperty("--opacity", 0);
        Ember.run.later(function () {
          return _this7.sendWidgetAction("toggleHamburger");
        }, 200);
      }
    },
    clickOutside: function clickOutside(e) {
      if (this.site.mobileView) {
        this.clickOutsideMobile(e);
      } else {
        this.sendWidgetAction("toggleHamburger");
      }
    }
  });

  _exports.default = _default;
});