define("discourse/controllers/user-private-messages", ["exports", "discourse-common/utils/decorators", "discourse/routes/user-private-messages-warnings", "I18n"], function (_exports, _decorators, _userPrivateMessagesWarnings, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PERSONAL_INBOX = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var PERSONAL_INBOX = "__personal_inbox__";
  _exports.PERSONAL_INBOX = PERSONAL_INBOX;

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("viewingSelf", "pmView", "currentUser.admin"), _dec2 = (0, _decorators.default)("pmTopicTrackingState.newIncoming.[]", "pmTopicTrackingState.statesModificationCounter", "group"), _dec3 = (0, _decorators.default)("pmTopicTrackingState.newIncoming.[]", "pmTopicTrackingState.statesModificationCounter", "group"), _dec4 = Ember._action, (_obj = {
    user: Ember.inject.controller(),
    pmView: false,
    viewingSelf: Ember.computed.alias("user.viewingSelf"),
    isGroup: Ember.computed.equal("pmView", "group"),
    isPersonal: Ember.computed.equal("pmView", "user"),
    group: null,
    groupFilter: Ember.computed.alias("group.name"),
    currentPath: Ember.computed.alias("router._router.currentPath"),
    pmTaggingEnabled: Ember.computed.alias("site.can_tag_pms"),
    tagId: null,
    showNewPM: Ember.computed.and("user.viewingSelf", "currentUser.can_send_private_messages"),
    showWarningsWarning: function showWarningsWarning(viewingSelf, pmView, isAdmin) {
      return pmView === _userPrivateMessagesWarnings.VIEW_NAME_WARNINGS && !viewingSelf && !isAdmin;
    },
    newLinkText: function newLinkText() {
      return this._linkText("new");
    },
    unreadLinkText: function unreadLinkText() {
      return this._linkText("unread");
    },
    _linkText: function _linkText(type) {
      var _this$pmTopicTracking;

      var count = ((_this$pmTopicTracking = this.pmTopicTrackingState) === null || _this$pmTopicTracking === void 0 ? void 0 : _this$pmTopicTracking.lookupCount(type)) || 0;

      if (count === 0) {
        return _I18n.default.t("user.messages.".concat(type));
      } else {
        return _I18n.default.t("user.messages.".concat(type, "_with_count"), {
          count: count
        });
      }
    },
    changeGroupNotificationLevel: function changeGroupNotificationLevel(notificationLevel) {
      this.group.setNotification(notificationLevel, this.get("user.model.id"));
    }
  }, (_applyDecoratedDescriptor(_obj, "showWarningsWarning", [_dec], Object.getOwnPropertyDescriptor(_obj, "showWarningsWarning"), _obj), _applyDecoratedDescriptor(_obj, "newLinkText", [_dec2], Object.getOwnPropertyDescriptor(_obj, "newLinkText"), _obj), _applyDecoratedDescriptor(_obj, "unreadLinkText", [_dec3], Object.getOwnPropertyDescriptor(_obj, "unreadLinkText"), _obj), _applyDecoratedDescriptor(_obj, "changeGroupNotificationLevel", [_dec4], Object.getOwnPropertyDescriptor(_obj, "changeGroupNotificationLevel"), _obj)), _obj)));

  _exports.default = _default;
});