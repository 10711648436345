define("discourse/templates/components/category-title-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0K64gZao",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[10,\"class\",\"category-title-link\"],[11,\"href\",[24,[\"category\",\"url\"]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"category-text-title\"],[8],[0,\"\\n    \"],[1,[28,\"category-title-before\",null,[[\"category\"],[[24,[\"category\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"category\",\"read_restricted\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"d-icon\",[[24,[\"lockIcon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"span\",true],[10,\"class\",\"category-name\"],[8],[1,[28,\"dir-span\",[[24,[\"category\",\"name\"]]],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"category\",\"uploaded_logo\",\"url\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"cdn-img\",null,[[\"src\",\"class\",\"width\",\"height\",\"alt\"],[[24,[\"category\",\"uploaded_logo\",\"url\"]],\"category-logo\",[24,[\"category\",\"uploaded_logo\",\"width\"]],[24,[\"category\",\"uploaded_logo\",\"height\"]],\"\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/category-title-link.hbs"
    }
  });

  _exports.default = _default;
});