define("discourse/lib/safari-hacks", ["exports", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-common/lib/helpers"], function (_exports, _environment, _debounce, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isWorkaroundActive = isWorkaroundActive;
  _exports.default = void 0;
  var workaroundActive = false;

  function isWorkaroundActive() {
    return workaroundActive;
  } // per http://stackoverflow.com/questions/29001977/safari-in-ios8-is-scrolling-screen-when-fixed-elements-get-focus/29064810


  function positioningWorkaround($fixedElement) {
    var caps = (0, _helpers.helperContext)().capabilities;

    if (!caps.isIOS) {
      return;
    }

    document.addEventListener("scroll", function () {
      if (!caps.isIpadOS && workaroundActive) {
        window.scrollTo(0, 0);
      }
    });
    var fixedElement = $fixedElement[0];
    var originalScrollTop = 0;
    var lastTouchedElement = null;

    positioningWorkaround.blur = function (evt) {
      if (workaroundActive) {
        document.body.classList.remove("ios-safari-composer-hacks");
        window.scrollTo(0, originalScrollTop);

        if (evt && evt.target) {
          evt.target.removeEventListener("blur", blurred);
        }

        workaroundActive = false;
      }
    };

    var blurredNow = function blurredNow(evt) {
      // we cannot use evt.relatedTarget to get the last focused element in safari iOS
      // document.activeElement is also unreliable (iOS does not mark buttons as focused)
      // so instead, we store the last touched element and check against it
      // cancel blur event when:
      // - switching to another iOS app
      // - displaying title field
      // - invoking a select-kit dropdown
      // - invoking mentions
      // - invoking emoji dropdown via : and hitting return
      // - invoking a button in the editor toolbar
      // - tapping on emoji in the emoji modal
      if (lastTouchedElement && (document.visibilityState === "hidden" || $fixedElement.hasClass("edit-title") || $(lastTouchedElement).hasClass("select-kit-header") || $(lastTouchedElement).closest(".autocomplete").length || lastTouchedElement.nodeName.toLowerCase() === "textarea" && document.activeElement === lastTouchedElement || $(lastTouchedElement).closest(".d-editor-button-bar").length || $(lastTouchedElement).hasClass("emoji"))) {
        return;
      }

      positioningWorkaround.blur(evt);
    };

    var blurred = function blurred(evt) {
      (0, _debounce.default)(this, blurredNow, evt, _environment.INPUT_DELAY);
    };

    var positioningHack = function positioningHack(evt) {
      var _this = this;

      if (evt === undefined) {
        evt = new CustomEvent("no-op");
      } // we need this, otherwise changing focus means we never clear


      this.addEventListener("blur", blurred); // resets focus out of select-kit elements
      // might become redundant after select-kit refactoring

      $fixedElement.find(".select-kit.is-expanded > button").trigger("click");
      $fixedElement.find(".select-kit > button.is-focused").removeClass("is-focused");

      if (window.pageYOffset > 0) {
        originalScrollTop = window.pageYOffset;
      }

      var delay = caps.isIpadOS ? 350 : 150;
      Ember.run.later(function () {
        if (caps.isIpadOS) {
          // disable hacks when using a hardware keyboard
          // by default, a hardware keyboard will show the keyboard accessory bar
          // whose height is currently 55px (using 75 for a bit of a buffer)
          var heightDiff = window.innerHeight - window.visualViewport.height;

          if (heightDiff < 75) {
            return;
          }
        } // don't trigger keyboard on disabled element (happens when a category is required)


        if (_this.disabled) {
          return;
        }

        document.body.classList.add("ios-safari-composer-hacks");
        window.scrollTo(0, 0);
        evt.preventDefault();

        _this.focus();

        workaroundActive = true;
      }, delay);
    };

    var lastTouched = function lastTouched(evt) {
      if (evt && evt.target) {
        lastTouchedElement = evt.target;
      }
    };

    function attachTouchStart(elem, fn) {
      if (!$(elem).data("listening")) {
        elem.addEventListener("touchstart", fn);
        $(elem).data("listening", true);
      }
    }

    var checkForInputs = function checkForInputs() {
      (0, _debounce.default)(this, function () {
        attachTouchStart(fixedElement, lastTouched);
        $fixedElement.find("input[type=text],textarea").each(function () {
          attachTouchStart(this, positioningHack);
        });
      }, 100);
    };

    positioningWorkaround.touchstartEvent = function (element) {
      var triggerHack = positioningHack.bind(element);
      triggerHack();
    };

    var config = {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false
    };
    var observer = new MutationObserver(checkForInputs);
    observer.observe(fixedElement, config);
  }

  var _default = positioningWorkaround;
  _exports.default = _default;
});