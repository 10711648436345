define("discourse/components/modal-panel", ["exports", "discourse/lib/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    panel: null,
    panelComponent: (0, _computed.fmt)("panel.id", "%@-panel"),
    classNameBindings: ["panel.id"],
    classNames: ["modal-panel"]
  });

  _exports.default = _default;
});