define("discourse/components/featured-topic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [":featured-topic"],
    attributeBindings: ["topic.id:data-topic-id"],
    click: function click(e) {
      var $target = $(e.target);

      if ($target.closest(".last-posted-at").length) {
        this.appEvents.trigger("topic-entrance:show", {
          topic: this.topic,
          position: $target.offset()
        });
        return false;
      }
    }
  });

  _exports.default = _default;
});