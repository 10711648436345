define("discourse/templates/components/topic-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yigJOTYH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"topic\",\"isPrivateMessage\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"bound-category-link\",[[24,[\"topic\",\"category\"]]],[[\"recursive\",\"hideParent\"],[true,true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"topic-header-extra\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"siteSettings\",\"tagging_enabled\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"list-tags\"],[8],[0,\"\\n      \"],[1,[28,\"discourse-tags\",[[24,[\"topic\"]]],[[\"mode\",\"tags\"],[\"list\",[24,[\"topic\",\"tags\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"siteSettings\",\"topic_featured_link_enabled\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"topic-featured-link\",[[24,[\"topic\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"tagName\",\"connectorTagName\",\"args\"],[\"topic-category\",\"span\",\"div\",[28,\"hash\",null,[[\"topic\",\"category\"],[[24,[\"topic\"]],[24,[\"topic\",\"category\"]]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/topic-category.hbs"
    }
  });

  _exports.default = _default;
});