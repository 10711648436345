define("discourse/templates/modal/delete-topic-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "beGg297g",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[\"post.controls.delete_topic_confirm_modal\"],[[\"count\"],[[24,[\"siteSettings\",\"min_topic_views_for_delete_confirm\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"disabled\",\"translatedLabel\",\"class\"],[[28,\"action\",[[23,0,[]],\"deleteTopic\"],null],[24,[\"deletingTopic\"]],[24,[\"buttonTitle\"]],\"btn-danger\"]]],false],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"label\",\"class\"],[[28,\"route-action\",[\"closeModal\"],null],\"post.controls.delete_topic_confirm_modal_no\",\"btn-primary\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/delete-topic-confirm.hbs"
    }
  });

  _exports.default = _default;
});