define("discourse/initializers/signup-cta", ["exports", "discourse/models/session"], function (_exports, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ANON_TOPIC_IDS = 2;
  var ANON_PROMPT_READ_TIME = 2 * 60 * 1000;
  var ONE_DAY = 24 * 60 * 60 * 1000;
  var PROMPT_HIDE_DURATION = ONE_DAY;
  var _default = {
    name: "signup-cta",
    initialize: function initialize(container) {
      var screenTrack = container.lookup("service:screen-track");

      var session = _session.default.current();

      var siteSettings = container.lookup("site-settings:main");
      var keyValueStore = container.lookup("key-value-store:main");
      var user = container.lookup("current-user:main");
      var appEvents = container.lookup("service:app-events");
      screenTrack.keyValueStore = keyValueStore; // Preconditions

      if (user) {
        return;
      } // must not be logged in


      if (keyValueStore.get("anon-cta-never")) {
        return;
      } // "never show again"


      if (!siteSettings.allow_new_registrations) {
        return;
      }

      if (siteSettings.invite_only) {
        return;
      }

      if (siteSettings.login_required) {
        return;
      }

      if (!siteSettings.enable_signup_cta) {
        return;
      }

      function checkSignupCtaRequirements() {
        if (session.get("showSignupCta")) {
          return; // already shown
        }

        if (session.get("hideSignupCta")) {
          return; // hidden for session
        }

        if (keyValueStore.get("anon-cta-never")) {
          return; // hidden forever
        }

        var now = Date.now();
        var hiddenAt = keyValueStore.getInt("anon-cta-hidden", 0);

        if (hiddenAt > now - PROMPT_HIDE_DURATION) {
          return; // hidden in last 24 hours
        }

        var readTime = keyValueStore.getInt("anon-topic-time");

        if (readTime < ANON_PROMPT_READ_TIME) {
          return;
        }

        var topicIdsString = keyValueStore.get("anon-topic-ids");

        if (!topicIdsString) {
          return;
        }

        var topicIdsAry = topicIdsString.split(",");

        if (topicIdsAry.length < ANON_TOPIC_IDS) {
          return;
        } // Requirements met.


        session.set("showSignupCta", true);
        appEvents.trigger("cta:shown");
      }

      screenTrack.registerAnonCallback(checkSignupCtaRequirements);
      checkSignupCtaRequirements();
    }
  };
  _exports.default = _default;
});