define("discourse/controllers/edit-slow-mode", ["exports", "discourse/helpers/slow-mode", "I18n", "discourse/mixins/modal-functionality", "discourse/models/topic", "discourse-common/utils/decorators", "discourse/lib/ajax-error"], function (_exports, _slowMode, _I18n, _modalFunctionality, _topic, _decorators, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("saveDisabled", "durationIsSet", "model.slow_mode_enabled_until"), _dec2 = (0, _decorators.default)("model.slow_mode_seconds"), _dec3 = (0, _decorators.default)("slowModeEnabled"), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_obj = {
    selectedSlowMode: null,
    hours: null,
    minutes: null,
    seconds: null,
    saveDisabled: false,
    showCustomSelect: Ember.computed.equal("selectedSlowMode", "custom"),
    durationIsSet: Ember.computed.or("hours", "minutes", "seconds"),
    init: function init() {
      this._super.apply(this, arguments);

      this.set("slowModes", [{
        id: "600",
        name: _I18n.default.t("topic.slow_mode_update.durations.10_minutes")
      }, {
        id: "900",
        name: _I18n.default.t("topic.slow_mode_update.durations.15_minutes")
      }, {
        id: "1800",
        name: _I18n.default.t("topic.slow_mode_update.durations.30_minutes")
      }, {
        id: "2700",
        name: _I18n.default.t("topic.slow_mode_update.durations.45_minutes")
      }, {
        id: "3600",
        name: _I18n.default.t("topic.slow_mode_update.durations.1_hour")
      }, {
        id: "7200",
        name: _I18n.default.t("topic.slow_mode_update.durations.2_hours")
      }, {
        id: "14400",
        name: _I18n.default.t("topic.slow_mode_update.durations.4_hours")
      }, {
        id: "28800",
        name: _I18n.default.t("topic.slow_mode_update.durations.8_hours")
      }, {
        id: "43200",
        name: _I18n.default.t("topic.slow_mode_update.durations.12_hours")
      }, {
        id: "86400",
        name: _I18n.default.t("topic.slow_mode_update.durations.24_hours")
      }, {
        id: "custom",
        name: _I18n.default.t("topic.slow_mode_update.durations.custom")
      }]);
    },
    onShow: function onShow() {
      var currentDuration = parseInt(this.model.slow_mode_seconds, 10);

      if (currentDuration) {
        var selectedDuration = this.slowModes.find(function (mode) {
          return mode.id === currentDuration.toString();
        });

        if (selectedDuration) {
          this.set("selectedSlowMode", currentDuration.toString());
        } else {
          this.set("selectedSlowMode", "custom");
        }

        this._setFromSeconds(currentDuration);
      }
    },
    submitDisabled: function submitDisabled(saveDisabled, durationIsSet, enabledUntil) {
      return saveDisabled || !durationIsSet || !enabledUntil;
    },
    slowModeEnabled: function slowModeEnabled(slowModeSeconds) {
      return slowModeSeconds && slowModeSeconds !== 0;
    },
    saveButtonLabel: function saveButtonLabel(slowModeEnabled) {
      return slowModeEnabled ? "topic.slow_mode_update.update" : "topic.slow_mode_update.enable";
    },
    _setFromSeconds: function _setFromSeconds(seconds) {
      this.setProperties((0, _slowMode.fromSeconds)(seconds));
    },
    _parseValue: function _parseValue(value) {
      return parseInt(value, 10) || 0;
    },
    setSlowModeDuration: function setSlowModeDuration(duration) {
      if (duration !== "custom") {
        var seconds = parseInt(duration, 10);

        this._setFromSeconds(seconds);
      }

      this.set("selectedSlowMode", duration);
    },
    enableSlowMode: function enableSlowMode() {
      var _this = this;

      this.set("saveDisabled", true);
      var seconds = (0, _slowMode.toSeconds)(this._parseValue(this.hours), this._parseValue(this.minutes), this._parseValue(this.seconds));

      _topic.default.setSlowMode(this.model.id, seconds, this.model.slow_mode_enabled_until).catch(_ajaxError.popupAjaxError).then(function () {
        _this.set("model.slow_mode_seconds", seconds);

        _this.send("closeModal");
      }).finally(function () {
        return _this.set("saveDisabled", false);
      });
    },
    disableSlowMode: function disableSlowMode() {
      var _this2 = this;

      this.set("saveDisabled", true);

      _topic.default.setSlowMode(this.model.id, 0).catch(_ajaxError.popupAjaxError).then(function () {
        _this2.set("model.slow_mode_seconds", 0);

        _this2.send("closeModal");
      }).finally(function () {
        return _this2.set("saveDisabled", false);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "submitDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "submitDisabled"), _obj), _applyDecoratedDescriptor(_obj, "slowModeEnabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "slowModeEnabled"), _obj), _applyDecoratedDescriptor(_obj, "saveButtonLabel", [_dec3], Object.getOwnPropertyDescriptor(_obj, "saveButtonLabel"), _obj), _applyDecoratedDescriptor(_obj, "setSlowModeDuration", [_dec4], Object.getOwnPropertyDescriptor(_obj, "setSlowModeDuration"), _obj), _applyDecoratedDescriptor(_obj, "enableSlowMode", [_dec5], Object.getOwnPropertyDescriptor(_obj, "enableSlowMode"), _obj), _applyDecoratedDescriptor(_obj, "disableSlowMode", [_dec6], Object.getOwnPropertyDescriptor(_obj, "disableSlowMode"), _obj)), _obj)));

  _exports.default = _default;
});