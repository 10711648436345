define("discourse/components/basic-topic-list", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("topicList.loaded"), _dec2 = (0, _decorators.observes)("topicList.[]"), _dec3 = (0, _decorators.default)("topics"), (_obj = {
    loadingMore: Ember.computed.alias("topicList.loadingMore"),
    loading: Ember.computed.not("loaded"),
    loaded: function loaded() {
      var topicList = this.topicList;

      if (topicList) {
        return topicList.get("loaded");
      } else {
        return true;
      }
    },
    _topicListChanged: function _topicListChanged() {
      this._initFromTopicList(this.topicList);
    },
    _initFromTopicList: function _initFromTopicList(topicList) {
      if (topicList !== null) {
        this.set("topics", topicList.get("topics"));
        this.rerender();
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      var topicList = this.topicList;

      if (topicList) {
        this._initFromTopicList(topicList);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.topics.forEach(function (topic) {
        var includeUnreadIndicator = typeof topic.unread_by_group_member !== "undefined";

        if (includeUnreadIndicator) {
          var unreadIndicatorChannel = "/private-messages/unread-indicator/".concat(topic.id);

          _this.messageBus.subscribe(unreadIndicatorChannel, function (data) {
            var nodeClassList = document.querySelector(".indicator-topic-".concat(data.topic_id)).classList;

            if (data.show_indicator) {
              nodeClassList.remove("read");
            } else {
              nodeClassList.add("read");
            }
          });
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.topics.forEach(function (topic) {
        var includeUnreadIndicator = typeof topic.unread_by_group_member !== "undefined";

        if (includeUnreadIndicator) {
          var unreadIndicatorChannel = "/private-messages/unread-indicator/".concat(topic.id);

          _this2.messageBus.unsubscribe(unreadIndicatorChannel);
        }
      });
    },
    showUnreadIndicator: function showUnreadIndicator(topics) {
      return topics.some(function (topic) {
        return typeof topic.unread_by_group_member !== "undefined";
      });
    },
    click: function click(e) {
      // Mobile basic-topic-list doesn't use the `topic-list-item` view so
      // the event for the topic entrance is never wired up.
      if (!this.site.mobileView) {
        return;
      }

      var target = $(e.target);

      if (target.closest(".posts-map").length) {
        var topicId = target.closest("tr").attr("data-topic-id");

        if (topicId) {
          if (target.prop("tagName") !== "A") {
            var targetLinks = target.find("a");

            if (targetLinks.length) {
              target = targetLinks;
            } else {
              targetLinks = target.closest("a");

              if (targetLinks.length) {
                target = targetLinks;
              } else {
                return false;
              }
            }
          }

          var topic = this.topics.findBy("id", parseInt(topicId, 10));
          this.appEvents.trigger("topic-entrance:show", {
            topic: topic,
            position: target.offset()
          });
        }

        return false;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "loaded", [_dec], Object.getOwnPropertyDescriptor(_obj, "loaded"), _obj), _applyDecoratedDescriptor(_obj, "_topicListChanged", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_topicListChanged"), _obj), _applyDecoratedDescriptor(_obj, "showUnreadIndicator", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showUnreadIndicator"), _obj)), _obj)));

  _exports.default = _default;
});