define("discourse/helpers/dir-span", ["exports", "discourse-common/lib/helpers", "discourse/lib/text-direction", "discourse/lib/utilities"], function (_exports, _helpers, _textDirection, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function setDir(text) {
    var content = text ? text : "";
    var siteSettings = (0, _helpers.helperContext)().siteSettings;

    if (content && siteSettings.support_mixed_text_direction) {
      var textDir = (0, _textDirection.isRTL)(content) ? "rtl" : "ltr";
      return "<span dir=\"".concat(textDir, "\">").concat(content, "</span>");
    }

    return content;
  }

  var _default = (0, _helpers.registerUnbound)("dir-span", function (str) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var isHtmlSafe = false;

    if (params.htmlSafe) {
      isHtmlSafe = params.htmlSafe === "true";
    }

    var text = isHtmlSafe ? str : (0, _utilities.escapeExpression)(str);
    return Ember.String.htmlSafe(setDir(text));
  });

  _exports.default = _default;
});