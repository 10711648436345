define("discourse/components/links-redirect", ["exports", "discourse/lib/click-track"], function (_exports, _clickTrack) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    click: function click(event) {
      var _event$target;

      if ((event === null || event === void 0 ? void 0 : (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.tagName) === "A") {
        return _clickTrack.default.trackClick(event, this.siteSettings);
      }
    }
  });

  _exports.default = _default;
});