define("discourse/models/static-page", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var StaticPage = Ember.Object.extend();
  StaticPage.reopenClass({
    find: function find(path) {
      return new Ember.RSVP.Promise(function (resolve) {
        // Models shouldn't really be doing Ajax request, but this is a huge speed boost if we
        // preload content.
        var $preloaded = $('noscript[data-path="/' + path + '"]');

        if ($preloaded.length) {
          var text = $preloaded.text();
          text = text.match(/<!-- preload-content: -->((?:.|[\n\r])*)<!-- :preload-content -->/)[1];
          resolve(StaticPage.create({
            path: path,
            html: text
          }));
        } else {
          (0, _ajax.ajax)("/".concat(path, ".html"), {
            dataType: "html"
          }).then(function (result) {
            return resolve(StaticPage.create({
              path: path,
              html: result
            }));
          });
        }
      });
    }
  });
  var _default = StaticPage;
  _exports.default = _default;
});