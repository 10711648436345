define("discourse/mixins/bulk-topic-selection", ["exports", "discourse-common/utils/decorators", "discourse/lib/notification-levels", "discourse/models/topic"], function (_exports, _decorators, _notificationLevels, _topic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Mixin.create((_dec = (0, _decorators.on)("init"), (_obj = {
    router: Ember.inject.service(),
    bulkSelectEnabled: false,
    autoAddTopicsToBulkSelect: false,
    selected: null,
    lastChecked: null,
    canBulkSelect: Ember.computed.or("currentUser.staff", "showDismissRead", "showResetNew"),
    resetSelected: function resetSelected() {
      this.set("selected", []);
    },
    _isFilterPage: function _isFilterPage(filter, filterType) {
      if (!filter) {
        return false;
      }

      return new RegExp(filterType + "$", "gi").test(filter);
    },
    actions: {
      toggleBulkSelect: function toggleBulkSelect() {
        this.toggleProperty("bulkSelectEnabled");
        this.selected.clear();
      },
      dismissRead: function dismissRead(operationType, options) {
        var _this = this;

        var operation = operationType === "posts" ? {
          type: "dismiss_posts"
        } : {
          type: "change_notification_level",
          notification_level_id: _notificationLevels.NotificationLevels.REGULAR
        };
        var tracked = (this.router.currentRoute.queryParams["f"] || this.router.currentRoute.queryParams["filter"]) === "tracked";
        var promise = this.selected.length ? _topic.default.bulkOperation(this.selected, operation, tracked) : _topic.default.bulkOperationByFilter("unread", operation, options, tracked);
        promise.then(function (result) {
          if (result && result.topic_ids) {
            if (options.private_message_inbox) {
              _this.pmTopicTrackingState.removeTopics(result.topic_ids);
            } else {
              _this.topicTrackingState.removeTopics(result.topic_ids);
            }
          }

          _this.send("closeModal");

          _this.send("refresh", tracked ? {
            skipResettingParams: ["filter", "f"]
          } : {});
        });
      },
      updateAutoAddTopicsToBulkSelect: function updateAutoAddTopicsToBulkSelect(newVal) {
        this.set("autoAddTopicsToBulkSelect", newVal);
      },
      addTopicsToBulkSelect: function addTopicsToBulkSelect(topics) {
        this.selected.pushObjects(topics);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "resetSelected", [_dec], Object.getOwnPropertyDescriptor(_obj, "resetSelected"), _obj)), _obj)));

  _exports.default = _default;
});