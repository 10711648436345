define("discourse/templates/tag-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nWEC+N+c",
    "block": "{\"symbols\":[\"tagGroup\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container tag-groups-container\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[8],[1,[28,\"i18n\",[\"tagging.groups.title\"],null],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"siteSettings\",\"tagging_enabled\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"tag-groups-sidebar content-list\"],[8],[0,\"\\n        \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"            \"],[7,\"li\",true],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"tagGroups.edit\",[23,1,[]]]],{\"statements\":[[0,\"                \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n\\n        \"],[1,[28,\"d-button\",null,[[\"class\",\"action\",\"icon\",\"label\"],[\"btn-default\",[28,\"action\",[[23,0,[]],\"newTagGroup\"],null],\"plus\",\"tagging.groups.new\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"tag-group-content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"alert info\"],[8],[1,[28,\"i18n\",[\"tagging.groups.disabled\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/tag-groups.hbs"
    }
  });

  _exports.default = _default;
});