define("discourse/controllers/static", ["exports", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/url"], function (_exports, _ajax, _decorators, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj, _init;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("model.path"), _dec2 = (0, _decorators.default)("model.path"), (_obj = {
    application: Ember.inject.controller(),
    showLoginButton: Ember.computed.equal("model.path", "login"),
    anyButtons: Ember.computed.or("showLoginButton", "showSignupButton"),
    bodyClass: function bodyClass(path) {
      return "static-".concat(path);
    },
    showSignupButton: function showSignupButton() {
      return this.get("model.path") === "login" && this.get("application.canSignUp");
    },
    actions: {
      markFaqRead: function markFaqRead() {
        var currentUser = this.currentUser;

        if (currentUser) {
          (0, _ajax.ajax)((0, _url.userPath)("read-faq"), {
            type: "POST"
          }).then(function () {
            currentUser.set("read_faq", true);
          });
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "bodyClass", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "bodyClass"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "showSignupButton", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showSignupButton"), _obj)), _obj)));

  _exports.default = _default;
});