define("discourse/templates/components/user-summary-users-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o2uT6fIH",
    "block": "{\"symbols\":[\"user\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"users\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"users\"]]],null,{\"statements\":[[0,\"      \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[[28,\"concat\",[\"user.summary.\",[23,0,[\"none\"]]],null]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/user-summary-users-list.hbs"
    }
  });

  _exports.default = _default;
});