define("discourse/components/user-stream", ["exports", "discourse/lib/click-track", "discourse/lib/url", "discourse/models/draft", "I18n", "discourse/mixins/load-more", "discourse/models/post", "discourse/models/composer", "bootbox", "discourse-common/lib/get-owner", "discourse-common/utils/decorators", "discourse/lib/ajax-error"], function (_exports, _clickTrack, _url, _draft, _I18n, _loadMore, _post, _composer, _bootbox, _getOwner, _decorators, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_loadMore.default, (_dec = (0, _decorators.observes)("stream.user.id"), (_obj = {
    tagName: "ul",
    _lastDecoratedElement: null,
    _initialize: Ember.on("init", function () {
      var filter = this.get("stream.filter");

      if (filter) {
        this.set("classNames", ["user-stream", "filter-" + filter.toString().replace(",", "-")]);
      }
    }),
    loading: false,
    eyelineSelector: ".user-stream .item",
    classNames: ["user-stream"],
    _scrollTopOnModelChange: function _scrollTopOnModelChange() {
      Ember.run.schedule("afterRender", function () {
        return $(document).scrollTop(0);
      });
    },
    _inserted: Ember.on("didInsertElement", function () {
      var _this = this;

      $(window).on("resize.discourse-on-scroll", function () {
        return _this.scrolled();
      });
      $(this.element).on("click.details-disabled", "details.disabled", function () {
        return false;
      });
      $(this.element).on("click.discourse-redirect", ".excerpt a", function (e) {
        return _clickTrack.default.trackClick(e, _this.siteSettings);
      });

      this._updateLastDecoratedElement();
    }),
    // This view is being removed. Shut down operations
    _destroyed: Ember.on("willDestroyElement", function () {
      $(window).unbind("resize.discourse-on-scroll");
      $(this.element).off("click.details-disabled", "details.disabled"); // Unbind link tracking

      $(this.element).off("click.discourse-redirect", ".excerpt a");
    }),
    _updateLastDecoratedElement: function _updateLastDecoratedElement() {
      var nodes = this.element.querySelectorAll(".user-stream-item");

      if (nodes.length === 0) {
        return;
      }

      var lastElement = nodes[nodes.length - 1];

      if (lastElement === this._lastDecoratedElement) {
        return;
      }

      this._lastDecoratedElement = lastElement;
    },
    actions: {
      removeBookmark: function removeBookmark(userAction) {
        var stream = this.stream;

        _post.default.updateBookmark(userAction.get("post_id"), false).then(function () {
          stream.remove(userAction);
        }).catch(_ajaxError.popupAjaxError);
      },
      resumeDraft: function resumeDraft(item) {
        var composer = (0, _getOwner.getOwner)(this).lookup("controller:composer");

        if (composer.get("model.viewOpen")) {
          composer.close();
        }

        if (item.get("postUrl")) {
          _url.default.routeTo(item.get("postUrl"));
        } else {
          _draft.default.get(item.draft_key).then(function (d) {
            var draft = d.draft || item.data;

            if (!draft) {
              return;
            }

            composer.open({
              draft: draft,
              draftKey: item.draft_key,
              draftSequence: d.draft_sequence
            });
          }).catch(function (error) {
            (0, _ajaxError.popupAjaxError)(error);
          });
        }
      },
      removeDraft: function removeDraft(draft) {
        var _this2 = this;

        var stream = this.stream;

        _bootbox.default.confirm(_I18n.default.t("drafts.remove_confirmation"), _I18n.default.t("no_value"), _I18n.default.t("yes_value"), function (confirmed) {
          if (confirmed) {
            _draft.default.clear(draft.draft_key, draft.sequence).then(function () {
              stream.remove(draft);

              if (draft.draft_key === _composer.NEW_TOPIC_KEY) {
                _this2.currentUser.set("has_topic_draft", false);
              }
            }).catch(function (error) {
              (0, _ajaxError.popupAjaxError)(error);
            });
          }
        });
      },
      loadMore: function loadMore() {
        var _this3 = this;

        if (this.loading) {
          return;
        }

        this.set("loading", true);
        var stream = this.stream;
        stream.findItems().then(function () {
          var _this3$_lastDecorated;

          _this3.set("loading", false);

          var element = (_this3$_lastDecorated = _this3._lastDecoratedElement) === null || _this3$_lastDecorated === void 0 ? void 0 : _this3$_lastDecorated.nextElementSibling;

          while (element) {
            _this3.trigger("user-stream:new-item-inserted", element);

            element = element.nextElementSibling;
          }

          _this3._updateLastDecoratedElement();
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "_scrollTopOnModelChange", [_dec], Object.getOwnPropertyDescriptor(_obj, "_scrollTopOnModelChange"), _obj)), _obj)));

  _exports.default = _default;
});