define("discourse/components/second-factor-form", ["exports", "I18n", "discourse/models/user", "discourse-common/utils/decorators"], function (_exports, _I18n, _user, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("secondFactorMethod"), _dec2 = (0, _decorators.default)("secondFactorMethod"), _dec3 = (0, _decorators.default)("secondFactorMethod", "isLogin"), _dec4 = (0, _decorators.default)("backupEnabled", "secondFactorMethod"), (_obj = {
    secondFactorTitle: function secondFactorTitle(secondFactorMethod) {
      switch (secondFactorMethod) {
        case _user.SECOND_FACTOR_METHODS.TOTP:
          return _I18n.default.t("login.second_factor_title");

        case _user.SECOND_FACTOR_METHODS.SECURITY_KEY:
          return _I18n.default.t("login.second_factor_title");

        case _user.SECOND_FACTOR_METHODS.BACKUP_CODE:
          return _I18n.default.t("login.second_factor_backup_title");
      }
    },
    secondFactorDescription: function secondFactorDescription(secondFactorMethod) {
      switch (secondFactorMethod) {
        case _user.SECOND_FACTOR_METHODS.TOTP:
          return _I18n.default.t("login.second_factor_description");

        case _user.SECOND_FACTOR_METHODS.SECURITY_KEY:
          return _I18n.default.t("login.security_key_description");

        case _user.SECOND_FACTOR_METHODS.BACKUP_CODE:
          return _I18n.default.t("login.second_factor_backup_description");
      }
    },
    linkText: function linkText(secondFactorMethod, isLogin) {
      if (isLogin) {
        return secondFactorMethod === _user.SECOND_FACTOR_METHODS.TOTP ? "login.second_factor_backup" : "login.second_factor";
      } else {
        return secondFactorMethod === _user.SECOND_FACTOR_METHODS.TOTP ? "user.second_factor_backup.use" : "user.second_factor.use";
      }
    },
    showToggleMethodLink: function showToggleMethodLink(backupEnabled, secondFactorMethod) {
      return backupEnabled && secondFactorMethod !== _user.SECOND_FACTOR_METHODS.SECURITY_KEY;
    },
    actions: {
      toggleSecondFactorMethod: function toggleSecondFactorMethod() {
        var secondFactorMethod = this.secondFactorMethod;
        this.set("secondFactorToken", "");

        if (secondFactorMethod === _user.SECOND_FACTOR_METHODS.TOTP) {
          this.set("secondFactorMethod", _user.SECOND_FACTOR_METHODS.BACKUP_CODE);
        } else {
          this.set("secondFactorMethod", _user.SECOND_FACTOR_METHODS.TOTP);
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "secondFactorTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "secondFactorTitle"), _obj), _applyDecoratedDescriptor(_obj, "secondFactorDescription", [_dec2], Object.getOwnPropertyDescriptor(_obj, "secondFactorDescription"), _obj), _applyDecoratedDescriptor(_obj, "linkText", [_dec3], Object.getOwnPropertyDescriptor(_obj, "linkText"), _obj), _applyDecoratedDescriptor(_obj, "showToggleMethodLink", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showToggleMethodLink"), _obj)), _obj)));

  _exports.default = _default;
});