define("discourse/components/date-time-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    classNames: ["d-date-time-input"],
    date: null,
    relativeDate: null,
    showTime: true,
    clearable: false,
    hours: Ember.computed("date", "showTime", function () {
      return this.date && this.get("showTime") ? this.date.hours() : null;
    }),
    minutes: Ember.computed("date", "showTime", function () {
      return this.date && this.get("showTime") ? this.date.minutes() : null;
    }),
    onClear: function onClear() {
      this.onChange(null);
    },
    onChangeTime: function onChangeTime(time) {
      if (this.onChange) {
        var date = this.date ? this.date : this.relativeDate ? this.relativeDate : moment();
        this.onChange(moment({
          year: date.year(),
          month: date.month(),
          day: date.date(),
          hours: time.hours,
          minutes: time.minutes
        }));
      }
    },
    onChangeDate: function onChangeDate(date) {
      if (!date) {
        this.onClear();
        return;
      }

      this.onChange && this.onChange(moment({
        year: date.year(),
        month: date.month(),
        day: date.date(),
        hours: this.hours || 0,
        minutes: this.minutes || 0
      }));
    }
  }, (_applyDecoratedDescriptor(_obj, "onClear", [_dec], Object.getOwnPropertyDescriptor(_obj, "onClear"), _obj), _applyDecoratedDescriptor(_obj, "onChangeTime", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onChangeTime"), _obj), _applyDecoratedDescriptor(_obj, "onChangeDate", [_dec3], Object.getOwnPropertyDescriptor(_obj, "onChangeDate"), _obj)), _obj)));

  _exports.default = _default;
});