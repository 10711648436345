define("discourse/routes/user-activity-index", ["exports", "discourse/routes/user-activity-stream", "discourse-common/lib/icon-library", "discourse-common/lib/get-url", "I18n"], function (_exports, _userActivityStream, _iconLibrary, _getUrl, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userActivityStream.default.extend({
    userActionType: null,
    emptyState: function emptyState() {
      var title = _I18n.default.t("user_activity.no_activity_title");

      var body = _I18n.default.t("user_activity.no_activity_body", {
        topUrl: (0, _getUrl.default)("/top"),
        categoriesUrl: (0, _getUrl.default)("/categories"),
        preferencesUrl: (0, _getUrl.default)("/my/preferences"),
        heartIcon: (0, _iconLibrary.iconHTML)("heart")
      }).htmlSafe();

      return {
        title: title,
        body: body
      };
    }
  });

  _exports.default = _default;
});