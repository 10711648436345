define("discourse/widgets/quick-access-notifications", ["discourse/widgets/raw-html", "discourse-common/lib/icon-library", "discourse-common/lib/get-url", "discourse/widgets/quick-access-panel", "discourse/lib/ajax", "discourse/widgets/widget", "virtual-dom", "I18n"], function (_rawHtml, _iconLibrary, _getUrl, _quickAccessPanel, _ajax, _widget, _virtualDom, _I18n) {
  "use strict";

  var ICON = "bell";
  (0, _widget.createWidget)("no-quick-access-notifications", {
    html: function html() {
      return (0, _virtualDom.h)("div.empty-state", [(0, _virtualDom.h)("span.empty-state-title", _I18n.default.t("user.no_notifications_title")), (0, _virtualDom.h)("div.empty-state-body", new _rawHtml.default({
        html: "<p>" + _I18n.default.t("user.no_notifications_body", {
          preferencesUrl: (0, _getUrl.default)("/my/preferences/notifications"),
          icon: (0, _iconLibrary.iconHTML)(ICON)
        }).htmlSafe() + "</p>"
      }))]);
    }
  });
  (0, _widget.createWidgetFrom)(_quickAccessPanel.default, "quick-access-notifications", {
    buildKey: function buildKey() {
      return "quick-access-notifications";
    },
    emptyStateWidget: "no-quick-access-notifications",
    buildAttributes: function buildAttributes() {
      return {
        tabindex: -1
      };
    },
    markReadRequest: function markReadRequest() {
      return (0, _ajax.ajax)("/notifications/mark-read", {
        type: "PUT"
      });
    },
    newItemsLoaded: function newItemsLoaded() {
      if (!this.currentUser.enforcedSecondFactor) {
        this.currentUser.set("unread_notifications", 0);
      }
    },
    itemHtml: function itemHtml(notification) {
      var notificationName = this.site.notificationLookup[notification.notification_type];
      return this.attach("".concat(notificationName.dasherize(), "-notification-item"), notification, {}, {
        fallbackWidgetName: "default-notification-item"
      });
    },
    findNewItems: function findNewItems() {
      return this._findStaleItemsInStore().refresh();
    },
    showAllHref: function showAllHref() {
      return "".concat(this.attrs.path, "/notifications");
    },
    hasUnread: function hasUnread() {
      return this.getItems().filterBy("read", false).length > 0;
    },
    _findStaleItemsInStore: function _findStaleItemsInStore() {
      return this.store.findStale("notification", {
        recent: true,
        silent: this.currentUser.enforcedSecondFactor,
        limit: 30
      }, {
        cacheKey: "recent-notifications"
      });
    }
  });
});