define("discourse/routes/new-category", ["exports", "discourse/routes/discourse", "I18n", "discourse/lib/constants"], function (_exports, _discourse, _I18n, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setNewCategoryDefaultColors = setNewCategoryDefaultColors;
  _exports.default = void 0;
  var _newCategoryColor = "0088CC",
      _newCategoryTextColor = "FFFFFF";

  function setNewCategoryDefaultColors(backgroundColor, textColor) {
    _newCategoryColor = backgroundColor;
    _newCategoryTextColor = textColor;
  }

  var _default = _discourse.default.extend({
    model: function model() {
      var _this = this;

      return Ember.RSVP.Promise.resolve(this.groupPermissions()).then(function (permissions) {
        return _this.newCategoryWithPermissions(permissions);
      }).catch(function () {
        return _this.newCategoryWithPermissions(_this.defaultGroupPermissions());
      });
    },
    newCategoryWithPermissions: function newCategoryWithPermissions(group_permissions) {
      return this.store.createRecord("category", {
        color: _newCategoryColor,
        text_color: _newCategoryTextColor,
        group_permissions: group_permissions,
        available_groups: this.site.groups.map(function (g) {
          return g.name;
        }),
        allow_badges: true,
        topic_featured_link_allowed: true,
        custom_fields: {},
        search_priority: _constants.SEARCH_PRIORITIES.normal
      });
    },
    titleToken: function titleToken() {
      return _I18n.default.t("category.create");
    },
    groupPermissions: function groupPermissions() {
      // Override this function if you want different groupPermissions from a plugin.
      // If your plugin override fails, permissions will fallback to defaultGroupPermissions
      return this.defaultGroupPermissions();
    },
    defaultGroupPermissions: function defaultGroupPermissions() {
      return [{
        group_name: this.site.groups.findBy("id", 0).name,
        permission_type: 1
      }];
    },
    renderTemplate: function renderTemplate() {
      this.render("edit-category-tabs", {
        controller: "edit-category-tabs",
        model: this.currentModel
      });
    }
  });

  _exports.default = _default;
});