define("discourse/templates/modal/change-post-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KPJ16OgU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"title\"],[[28,\"if\",[[24,[\"model\",\"notice\"]],\"post.controls.change_post_notice\",\"post.controls.add_post_notice\"],null]]],{\"statements\":[[0,\"  \"],[7,\"form\",true],[8],[1,[28,\"textarea\",null,[[\"value\"],[[24,[\"notice\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"class\",\"label\",\"action\",\"disabled\"],[\"btn-primary\",[28,\"if\",[[24,[\"saving\"]],\"saving\",\"save\"],null],[28,\"action\",[[23,0,[]],\"setNotice\",[24,[\"notice\"]]],null],[24,[\"disabled\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"notice\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"d-button\",null,[[\"class\",\"label\",\"action\",\"disabled\"],[\"btn-danger\",\"post.controls.delete_post_notice\",[28,\"action\",[[23,0,[]],\"setNotice\"],null],[24,[\"saving\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[28,\"d-modal-cancel\",null,[[\"close\"],[[28,\"route-action\",[\"closeModal\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/change-post-notice.hbs"
    }
  });

  _exports.default = _default;
});