define("discourse/routes/build-private-messages-group-route", ["exports", "discourse/routes/build-private-messages-route", "I18n", "discourse/lib/cached-topic-list"], function (_exports, _buildPrivateMessagesRoute, _I18n, _cachedTopicList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(inboxType, filter) {
    return (0, _buildPrivateMessagesRoute.default)(inboxType, "private-messages-groups", filter).extend({
      groupName: null,
      titleToken: function titleToken() {
        var groupName = this.groupName;

        if (groupName) {
          var title = groupName.capitalize();

          if (filter !== "inbox") {
            title = "".concat(title, " ").concat(_I18n.default.t("user.messages." + filter));
          }

          return [title, _I18n.default.t("user.private_messages")];
        }
      },
      model: function model(params) {
        var _this = this;

        var username = this.modelFor("user").get("username_lower");
        var topicListFilter = "topics/private-messages-group/".concat(username, "/").concat(params.name);

        if (filter !== "inbox") {
          topicListFilter = "".concat(topicListFilter, "/").concat(filter);
        }

        var lastTopicList = (0, _cachedTopicList.findOrResetCachedTopicList)(this.session, topicListFilter);
        return lastTopicList ? lastTopicList : this.store.findFiltered("topicList", {
          filter: topicListFilter
        }).then(function (topicList) {
          // andrei: we agreed that this is an anti pattern,
          // it's better to avoid mutating a rest model like this
          // this place we'll be refactored later
          // see https://github.com/discourse/discourse/pull/14313#discussion_r708784704
          topicList.set("emptyState", _this.emptyState());
          return topicList;
        });
      },
      afterModel: function afterModel(model) {
        var filters = model.get("filter").split("/");
        var groupName;

        if (filter !== "inbox") {
          groupName = filters[filters.length - 2];
        } else {
          groupName = filters.pop();
        }

        var group = this.modelFor("user").get("groups").filterBy("name", groupName)[0];
        this.setProperties({
          groupName: groupName,
          group: group
        });
      },
      setupController: function setupController() {
        this._super.apply(this, arguments);

        var userTopicsListController = this.controllerFor("user-topics-list");
        userTopicsListController.set("group", this.group);
        userTopicsListController.set("pmTopicTrackingState.activeGroup", this.group);
        this.controllerFor("user-private-messages").set("group", this.group);
      },
      emptyState: function emptyState() {
        return {
          title: _I18n.default.t("user.no_messages_title"),
          body: ""
        };
      },
      dismissReadOptions: function dismissReadOptions() {
        return {
          group_name: this.get("groupName")
        };
      }
    });
  };

  _exports.default = _default;
});