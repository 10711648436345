define("discourse/components/text-field", ["exports", "discourse/lib/text-direction", "I18n", "discourse-common/utils/decorators", "discourse-common/lib/debounce"], function (_exports, _textDirection, _I18n, _decorators, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj, _init;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var DEBOUNCE_MS = 500;

  var _default = Ember.TextField.extend((_dec = (0, _decorators.default)("placeholderKey"), (_obj = {
    attributeBindings: ["autocorrect", "autocapitalize", "autofocus", "maxLength", "dir", "aria-label", "aria-controls"],
    init: function init() {
      this._super.apply(this, arguments);

      this._prevValue = null;
      this._timer = null;
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._prevValue = this.value;
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this._prevValue !== this.value) {
        if (this.onChangeImmediate) {
          Ember.run.next(function () {
            return _this.onChangeImmediate(_this.value);
          });
        }

        if (this.onChange) {
          Ember.run.cancel(this._timer);
          this._timer = (0, _debounce.default)(this, this._debouncedChange, DEBOUNCE_MS);
        }
      }
    },
    _debouncedChange: function _debouncedChange() {
      var _this2 = this;

      Ember.run.next(function () {
        return _this2.onChange(_this2.value);
      });
    },
    dir: function dir() {
      if (this.siteSettings.support_mixed_text_direction) {
        var val = this.value;

        if (val) {
          return (0, _textDirection.isRTL)(val) ? "rtl" : "ltr";
        } else {
          return (0, _textDirection.siteDir)();
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.run.cancel(this._timer);
    },
    keyUp: function keyUp(event) {
      this._super(event);

      if (this.siteSettings.support_mixed_text_direction) {
        var val = this.value;

        if ((0, _textDirection.isRTL)(val)) {
          this.set("dir", "rtl");
        } else if ((0, _textDirection.isLTR)(val)) {
          this.set("dir", "ltr");
        } else {
          this.set("dir", (0, _textDirection.siteDir)());
        }
      }
    },
    placeholder: {
      get: function get() {
        if (this._placeholder) {
          return this._placeholder;
        }

        return this.placeholderKey ? _I18n.default.t(this.placeholderKey) : "";
      },
      set: function set(value) {
        return this._placeholder = value;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "dir", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "dir"), _obj), _applyDecoratedDescriptor(_obj, "placeholder", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "placeholder"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj)), _obj)));

  _exports.default = _default;
});