define("discourse/lib/text", ["exports", "pretty-text/pretty-text", "pretty-text/emoji", "pretty-text/allow-lister", "discourse/models/session", "discourse/lib/utilities", "discourse-common/lib/get-url", "discourse-common/lib/helpers", "discourse/lib/load-script", "pretty-text/sanitizer"], function (_exports, _prettyText, _emoji, _allowLister, _session, _utilities, _getUrl, _helpers, _loadScript, _sanitizer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cook = cook;
  _exports.cookAsync = cookAsync;
  _exports.generateCookFunction = generateCookFunction;
  _exports.generateLinkifyFunction = generateLinkifyFunction;
  _exports.sanitize = sanitize;
  _exports.sanitizeAsync = sanitizeAsync;
  _exports.emojiUnescape = emojiUnescape;
  _exports.emojiUrlFor = emojiUrlFor;
  _exports.excerpt = excerpt;

  function getOpts(opts) {
    var context = (0, _helpers.helperContext)();
    opts = Object.assign({
      getURL: _getUrl.getURLWithCDN,
      currentUser: context.currentUser,
      censoredRegexp: context.site.censored_regexp,
      customEmojiTranslation: context.site.custom_emoji_translation,
      siteSettings: context.siteSettings,
      formatUsername: _utilities.formatUsername,
      watchedWordsReplace: context.site.watched_words_replace,
      watchedWordsLink: context.site.watched_words_link,
      additionalOptions: context.site.markdown_additional_options
    }, opts);
    return (0, _prettyText.buildOptions)(opts);
  } // Use this to easily create a pretty text instance with proper options


  function cook(text, options) {
    return Ember.String.htmlSafe(createPrettyText(options).cook(text));
  } // everything should eventually move to async API and this should be renamed
  // cook


  function cookAsync(text, options) {
    return loadMarkdownIt().then(function () {
      return cook(text, options);
    });
  } // Warm up pretty text with a set of options and return a function
  // which can be used to cook without rebuilding prettytext every time


  function generateCookFunction(options) {
    return loadMarkdownIt().then(function () {
      var prettyText = createPrettyText(options);
      return function (text) {
        return prettyText.cook(text);
      };
    });
  }

  function generateLinkifyFunction(options) {
    return loadMarkdownIt().then(function () {
      var prettyText = createPrettyText(options);
      return prettyText.opts.engine.linkify;
    });
  }

  function sanitize(text, options) {
    return (0, _sanitizer.sanitize)(text, new _allowLister.default(options));
  }

  function sanitizeAsync(text, options) {
    return loadMarkdownIt().then(function () {
      return createPrettyText(options).sanitize(text);
    });
  }

  function loadMarkdownIt() {
    return new Ember.RSVP.Promise(function (resolve) {
      var markdownItURL = _session.default.currentProp("markdownItURL");

      if (markdownItURL) {
        (0, _loadScript.default)(markdownItURL).then(function () {
          return resolve();
        }).catch(function (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        });
      } else {
        resolve();
      }
    });
  }

  function createPrettyText(options) {
    return new _prettyText.default(getOpts(options));
  }

  function emojiOptions() {
    var siteSettings = (0, _helpers.helperContext)().siteSettings;

    if (!siteSettings.enable_emoji) {
      return;
    }

    return {
      getURL: function getURL(url) {
        return (0, _getUrl.getURLWithCDN)(url);
      },
      emojiSet: siteSettings.emoji_set,
      enableEmojiShortcuts: siteSettings.enable_emoji_shortcuts,
      inlineEmoji: siteSettings.enable_inline_emoji_translation,
      emojiCDNUrl: siteSettings.external_emoji_url
    };
  }

  function emojiUnescape(string, options) {
    var opts = emojiOptions();

    if (opts) {
      return (0, _emoji.performEmojiUnescape)(string, Object.assign(opts, options || {}));
    } else {
      return string;
    }
  }

  function emojiUrlFor(code) {
    var opts = emojiOptions();

    if (opts) {
      return (0, _emoji.buildEmojiUrl)(code, opts);
    }
  }

  function encode(str) {
    return str.replaceAll("<", "&lt;").replaceAll(">", "&gt;");
  }

  function traverse(element, callback) {
    if (callback(element)) {
      element.childNodes.forEach(function (child) {
        return traverse(child, callback);
      });
    }
  }

  function excerpt(cooked, length) {
    var result = "";
    var resultLength = 0;
    var div = document.createElement("div");
    div.innerHTML = cooked;
    traverse(div, function (element) {
      if (resultLength >= length) {
        return;
      }

      if (element.nodeType === Node.TEXT_NODE) {
        if (resultLength + element.textContent.length > length) {
          var text = element.textContent.substr(0, length - resultLength);
          result += encode(text);
          result += "&hellip;";
          resultLength += text.length;
        } else {
          result += encode(element.textContent);
          resultLength += element.textContent.length;
        }
      } else if (element.tagName === "A") {
        element.innerHTML = element.innerText;
        result += element.outerHTML;
        resultLength += element.innerText.length;
      } else if (element.tagName === "IMG") {
        if (element.classList.contains("emoji")) {
          result += element.outerHTML;
        } else {
          result += "[image]";
          resultLength += "[image]".length;
        }
      } else {
        return true;
      }
    });
    return result;
  }
});