define("discourse/components/topic-list-item", ["exports", "discourse-common/utils/decorators", "discourse/lib/url", "I18n", "discourse-common/lib/raw-handlebars-helpers", "discourse-common/lib/raw-templates", "discourse/components/topic-title", "discourse/lib/intercept-click"], function (_exports, _decorators, _url, _I18n, _rawHandlebarsHelpers, _rawTemplates, _topicTitle, _interceptClick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showEntrance = showEntrance;
  _exports.navigateToTopic = navigateToTopic;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function showEntrance(e) {
    var target = $(e.target);

    if (target.hasClass("posts-map") || target.parents(".posts-map").length > 0) {
      if (target.prop("tagName") !== "A") {
        target = target.find("a");

        if (target.length === 0) {
          target = target.end();
        }
      }

      this.appEvents.trigger("topic-entrance:show", {
        topic: this.topic,
        position: target.offset()
      });
      return false;
    }
  }

  function navigateToTopic(topic, href) {
    this.appEvents.trigger("header:update-topic", topic);

    _url.default.routeTo(href || topic.get("url"));

    return false;
  }

  var _default = Ember.Component.extend((_dec = (0, _decorators.observes)("topic.pinned"), _dec2 = (0, _decorators.default)("topic.id"), _dec3 = (0, _decorators.default)("topic.unread_by_group_member"), _dec4 = (0, _decorators.default)("topic.unread_by_group_member"), _dec5 = (0, _decorators.default)("topic", "lastVisitedTopic"), (_obj = {
    tagName: "tr",
    classNameBindings: [":topic-list-item", "unboundClassNames", "topic.visited"],
    attributeBindings: ["data-topic-id", "role", "ariaLevel:aria-level"],
    "data-topic-id": Ember.computed.alias("topic.id"),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.renderTopicListItem();
    },
    renderTopicListItem: function renderTopicListItem() {
      var _this = this;

      var template = (0, _rawTemplates.findRawTemplate)("list/topic-list-item");

      if (template) {
        this.set("topicListItemContents", template(this, _rawHandlebarsHelpers.RUNTIME_OPTIONS).htmlSafe());
        Ember.run.schedule("afterRender", function () {
          if (_this.selected && _this.selected.includes(_this.topic)) {
            _this.element.querySelector("input.bulk-select").checked = true;
          }
        });
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (this.includeUnreadIndicator) {
        this.messageBus.subscribe(this.unreadIndicatorChannel, function (data) {
          var nodeClassList = document.querySelector(".indicator-topic-".concat(data.topic_id)).classList;

          if (data.show_indicator) {
            nodeClassList.remove("read");
          } else {
            nodeClassList.add("read");
          }
        });
      }

      Ember.run.schedule("afterRender", function () {
        if (_this2.element && !_this2.isDestroying && !_this2.isDestroyed) {
          var rawTopicLink = _this2.element.querySelector(".raw-topic-link");

          rawTopicLink && _topicTitle.topicTitleDecorators && _topicTitle.topicTitleDecorators.forEach(function (cb) {
            return cb(_this2.topic, rawTopicLink, "topic-list-item-title");
          });
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.includeUnreadIndicator) {
        this.messageBus.unsubscribe(this.unreadIndicatorChannel);
      }
    },
    unreadIndicatorChannel: function unreadIndicatorChannel(topicId) {
      return "/private-messages/unread-indicator/".concat(topicId);
    },
    unreadClass: function unreadClass(unreadByGroupMember) {
      return unreadByGroupMember ? "" : "read";
    },
    includeUnreadIndicator: function includeUnreadIndicator(unreadByGroupMember) {
      return typeof unreadByGroupMember !== "undefined";
    },
    newDotText: function newDotText() {
      return this.currentUser && this.currentUser.trust_level > 0 ? "" : _I18n.default.t("filters.new.lower_title");
    },
    unboundClassNames: function unboundClassNames(topic, lastVisitedTopic) {
      var classes = [];

      if (topic.get("category")) {
        classes.push("category-" + topic.get("category.fullSlug"));
      }

      if (topic.get("tags")) {
        topic.get("tags").forEach(function (tagName) {
          return classes.push("tag-" + tagName);
        });
      }

      if (topic.get("hasExcerpt")) {
        classes.push("has-excerpt");
      }

      if (topic.get("unseen")) {
        classes.push("unseen-topic");
      }

      if (topic.unread_posts) {
        classes.push("unread-posts");
      }

      ["liked", "archived", "bookmarked", "pinned", "closed"].forEach(function (name) {
        if (topic.get(name)) {
          classes.push(name);
        }
      });

      if (topic === lastVisitedTopic) {
        classes.push("last-visit");
      }

      return classes.join(" ");
    },
    hasLikes: function hasLikes() {
      return this.get("topic.like_count") > 0;
    },
    hasOpLikes: function hasOpLikes() {
      return this.get("topic.op_like_count") > 0;
    },
    expandPinned: function expandPinned() {
      var pinned = this.get("topic.pinned");

      if (!pinned) {
        return false;
      }

      if (this.site.mobileView) {
        if (!this.siteSettings.show_pinned_excerpt_mobile) {
          return false;
        }
      } else {
        if (!this.siteSettings.show_pinned_excerpt_desktop) {
          return false;
        }
      }

      if (this.expandGloballyPinned && this.get("topic.pinned_globally")) {
        return true;
      }

      if (this.expandAllPinned) {
        return true;
      }

      return false;
    },
    showEntrance: showEntrance,
    click: function click(e) {
      var _this3 = this;

      var result = this.showEntrance(e);

      if (result === false) {
        return result;
      }

      var topic = this.topic;

      if (e.target.classList.contains("bulk-select")) {
        var selected = this.selected;

        if (e.target.checked) {
          selected.addObject(topic);

          if (this.lastChecked && e.shiftKey) {
            var bulkSelects = Array.from(document.querySelectorAll("input.bulk-select")),
                from = bulkSelects.indexOf(e.target),
                to = bulkSelects.findIndex(function (el) {
              return el.id === _this3.lastChecked.id;
            }),
                start = Math.min(from, to),
                end = Math.max(from, to);
            bulkSelects.slice(start, end).filter(function (el) {
              return el.checked !== true;
            }).forEach(function (checkbox) {
              checkbox.click();
            });
          }

          this.set("lastChecked", e.target);
        } else {
          selected.removeObject(topic);
          this.set("lastChecked", null);
        }
      }

      if (e.target.classList.contains("raw-topic-link")) {
        if ((0, _interceptClick.wantsNewWindow)(e)) {
          return true;
        }

        return this.navigateToTopic(topic, e.target.getAttribute("href"));
      }

      if (e.target.closest("a.topic-status")) {
        this.topic.togglePinnedForUser();
        return false;
      }

      return this.unhandledRowClick(e, topic);
    },
    unhandledRowClick: function unhandledRowClick() {},
    navigateToTopic: navigateToTopic,
    highlight: function highlight() {
      var _this4 = this;

      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        isLastViewedTopic: false
      };
      Ember.run.schedule("afterRender", function () {
        if (!_this4.element || _this4.isDestroying || _this4.isDestroyed) {
          return;
        }

        var $topic = $(_this4.element);
        $topic.addClass("highlighted").attr("data-islastviewedtopic", opts.isLastViewedTopic);
        $topic.on("animationend", function () {
          return $topic.removeClass("highlighted");
        });
      });
    },
    _highlightIfNeeded: Ember.on("didInsertElement", function () {
      // highlight the last topic viewed
      if (this.session.get("lastTopicIdViewed") === this.get("topic.id")) {
        this.session.set("lastTopicIdViewed", null);
        this.highlight({
          isLastViewedTopic: true
        });
      } else if (this.get("topic.highlight")) {
        // highlight new topics that have been loaded from the server or the one we just created
        this.set("topic.highlight", false);
        this.highlight();
      }
    })
  }, (_applyDecoratedDescriptor(_obj, "renderTopicListItem", [_dec], Object.getOwnPropertyDescriptor(_obj, "renderTopicListItem"), _obj), _applyDecoratedDescriptor(_obj, "unreadIndicatorChannel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "unreadIndicatorChannel"), _obj), _applyDecoratedDescriptor(_obj, "unreadClass", [_dec3], Object.getOwnPropertyDescriptor(_obj, "unreadClass"), _obj), _applyDecoratedDescriptor(_obj, "includeUnreadIndicator", [_dec4], Object.getOwnPropertyDescriptor(_obj, "includeUnreadIndicator"), _obj), _applyDecoratedDescriptor(_obj, "newDotText", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "newDotText"), _obj), _applyDecoratedDescriptor(_obj, "unboundClassNames", [_dec5], Object.getOwnPropertyDescriptor(_obj, "unboundClassNames"), _obj), _applyDecoratedDescriptor(_obj, "expandPinned", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "expandPinned"), _obj)), _obj)));

  _exports.default = _default;
});