define("discourse/templates/modal/bookmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xLNUAwgG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"id\"],[\"bookmark-reminder-modal\"]],{\"statements\":[[0,\"  \"],[1,[28,\"bookmark\",null,[[\"model\",\"afterSave\",\"afterDelete\",\"onCloseWithoutSaving\",\"registerOnCloseHandler\",\"closeModal\"],[[24,[\"model\"]],[24,[\"afterSave\"]],[24,[\"afterDelete\"]],[24,[\"onCloseWithoutSaving\"]],[28,\"action\",[[23,0,[]],\"registerOnCloseHandler\"],null],[28,\"action\",[[23,0,[]],\"closeModal\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/bookmark.hbs"
    }
  });

  _exports.default = _default;
});