define("discourse/mixins/rerender-on-do-not-disturb-change", ["exports", "discourse-common/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    _listenToDoNotDisturbLoop: null,
    listenForDoNotDisturbChanges: function listenForDoNotDisturbChanges() {
      var _this = this;

      if (this.currentUser && !this.currentUser.isInDoNotDisturb()) {
        this.queueRerender();
      } else {
        Ember.run.cancel(this._listenToDoNotDisturbLoop);
        this._listenToDoNotDisturbLoop = Ember.run.later(this, function () {
          _this.listenForDoNotDisturbChanges();
        }, 10000);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.appEvents.on("do-not-disturb:changed", function () {
        return _this2.queueRerender();
      });

      if (!(0, _environment.isTesting)()) {
        this.listenForDoNotDisturbChanges();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.run.cancel(this._listenToDoNotDisturbLoop);
    }
  });

  _exports.default = _default;
});