define("discourse/routes/account-created-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set("accountCreated", this.controllerFor("account-created").get("accountCreated"));
    }
  });

  _exports.default = _default;
});