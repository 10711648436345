define("discourse/widgets/liked-consolidated-notification-item", ["discourse/widgets/default-notification-item", "I18n", "discourse/widgets/widget", "discourse/lib/utilities", "discourse/lib/url"], function (_defaultNotificationItem, _I18n, _widget, _utilities, _url) {
  "use strict";

  (0, _widget.createWidgetFrom)(_defaultNotificationItem.DefaultNotificationItem, "liked-consolidated-notification-item", {
    url: function url(data) {
      return (0, _url.userPath)("".concat(this.attrs.username || this.currentUser.username, "/notifications/likes-received?acting_username=").concat(data.display_username));
    },
    description: function description(data) {
      var description = _I18n.default.t("notifications.liked_consolidated_description", {
        count: parseInt(data.count, 10)
      });

      return Ember.isEmpty(description) ? "" : (0, _utilities.escapeExpression)(description);
    }
  });
});