define("discourse/controllers/bookmark", ["exports", "discourse/mixins/modal-functionality", "discourse/lib/show-modal"], function (_exports, _modalFunctionality, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.openBookmarkModal = openBookmarkModal;
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function openBookmarkModal(bookmark) {
    var callbacks = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      onCloseWithoutSaving: null,
      onAfterSave: null,
      onAfterDelete: null
    };
    return new Ember.RSVP.Promise(function (resolve) {
      var modalTitle = function modalTitle() {
        if (bookmark.for_topic) {
          return bookmark.id ? "post.bookmarks.edit_for_topic" : "post.bookmarks.create_for_topic";
        }

        return bookmark.id ? "post.bookmarks.edit" : "post.bookmarks.create";
      };

      var modalController = (0, _showModal.default)("bookmark", {
        model: {
          postId: bookmark.post_id,
          topicId: bookmark.topic_id,
          id: bookmark.id,
          reminderAt: bookmark.reminder_at,
          autoDeletePreference: bookmark.auto_delete_preference,
          name: bookmark.name,
          forTopic: bookmark.for_topic
        },
        title: modalTitle(),
        modalClass: "bookmark-with-reminder"
      });
      modalController.setProperties({
        onCloseWithoutSaving: function onCloseWithoutSaving() {
          if (callbacks.onCloseWithoutSaving) {
            callbacks.onCloseWithoutSaving();
          }

          resolve();
        },
        afterSave: function afterSave(savedData) {
          var resolveData;

          if (callbacks.onAfterSave) {
            resolveData = callbacks.onAfterSave(savedData);
          }

          resolve(resolveData);
        },
        afterDelete: function afterDelete(topicBookmarked, bookmarkId) {
          if (callbacks.onAfterDelete) {
            callbacks.onAfterDelete(topicBookmarked, bookmarkId);
          }

          resolve();
        }
      });
    });
  }

  var _default = Ember.Controller.extend(_modalFunctionality.default, (_dec = Ember._action, (_obj = {
    onShow: function onShow() {
      this.setProperties({
        model: this.model || {},
        allowSave: true
      });
    },
    registerOnCloseHandler: function registerOnCloseHandler(handlerFn) {
      this.set("onCloseHandler", handlerFn);
    },

    /**
     * We always want to save the bookmark unless the user specifically
     * clicks the save or cancel button to mimic browser behaviour.
     */
    onClose: function onClose() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (this.onCloseHandler) {
        this.onCloseHandler(opts);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "registerOnCloseHandler", [_dec], Object.getOwnPropertyDescriptor(_obj, "registerOnCloseHandler"), _obj)), _obj)));

  _exports.default = _default;
});