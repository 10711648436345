define("discourse/templates/user-private-messages-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V1aV4U2z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"list-controls\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[1,[28,\"i18n\",[\"tagging.tags\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"tag-sort-options\"],[8],[0,\"\\n  \"],[1,[28,\"i18n\",[\"tagging.sort_by\"],null],false],[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[29,[\"tag-sort-count \",[28,\"if\",[[24,[\"sortedByCount\"]],\"active\"],null]]]],[8],[0,\"\\n    \"],[7,\"a\",false],[12,\"href\",\"\"],[3,\"action\",[[23,0,[]],\"sortByCount\"]],[8],[0,\"\\n      \"],[1,[28,\"i18n\",[\"tagging.sort_by_count\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[29,[\"tag-sort-name \",[28,\"if\",[[24,[\"sortedByName\"]],\"active\"],null]]]],[8],[0,\"\\n    \"],[7,\"a\",false],[12,\"href\",\"\"],[3,\"action\",[[23,0,[]],\"sortById\"]],[8],[0,\"\\n      \"],[1,[28,\"i18n\",[\"tagging.sort_by_name\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"tag-list\",null,[[\"tags\",\"sortProperties\",\"titleKey\",\"isPrivateMessage\",\"tagsForUser\"],[[24,[\"model\"]],[24,[\"sortProperties\"]],\"tagging.all_tags\",true,[24,[\"tagsForUser\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/user-private-messages-tags.hbs"
    }
  });

  _exports.default = _default;
});