define("discourse/controllers/discovery", ["exports", "discourse/models/category", "discourse/lib/url"], function (_exports, _category, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    discoveryTopics: Ember.inject.controller("discovery/topics"),
    navigationCategory: Ember.inject.controller("navigation/category"),
    application: Ember.inject.controller(),
    router: Ember.inject.service(),
    viewingCategoriesList: Ember.computed.equal("router.currentRouteName", "discovery.categories"),
    loading: false,
    category: Ember.computed.alias("navigationCategory.category"),
    noSubcategories: Ember.computed.alias("navigationCategory.noSubcategories"),
    loadedAllItems: Ember.computed.not("discoveryTopics.model.canLoadMore"),
    loadingBegan: function loadingBegan() {
      this.set("loading", true);
      this.set("application.showFooter", false);
    },
    loadingComplete: function loadingComplete() {
      this.set("loading", false);
      this.set("application.showFooter", this.loadedAllItems);
    },
    showMoreUrl: function showMoreUrl(period) {
      var url = "",
          category = this.category;

      if (category) {
        url = "/c/".concat(_category.default.slugFor(category), "/").concat(category.id).concat(this.noSubcategories ? "/none" : "", "/l");
      }

      url += "/top";
      var queryParams = this.router.currentRoute.queryParams;
      queryParams.period = period;

      if (Object.keys(queryParams).length) {
        url = "".concat(url, "?") + Object.keys(queryParams).map(function (key) {
          return "".concat(key, "=").concat(queryParams[key]);
        }).join("&");
      }

      return url;
    },
    actions: {
      changePeriod: function changePeriod(p) {
        _url.default.routeTo(this.showMoreUrl(p));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "loadingBegan", [_dec], Object.getOwnPropertyDescriptor(_obj, "loadingBegan"), _obj), _applyDecoratedDescriptor(_obj, "loadingComplete", [_dec2], Object.getOwnPropertyDescriptor(_obj, "loadingComplete"), _obj)), _obj)));

  _exports.default = _default;
});