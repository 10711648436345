define("discourse/templates/exception", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e1sHNICp",
    "block": "{\"symbols\":[\"buttonData\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,0,[\"errorHtml\"]],[23,0,[\"isForbidden\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"not-found\"],[8],[1,[28,\"html-safe\",[[23,0,[\"errorHtml\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"error-page\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"face\"],[8],[0,\":(\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"reason\"],[8],[1,[22,\"reason\"],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"requestUrl\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"url\"],[8],[0,\"\\n          \"],[1,[28,\"i18n\",[\"errors.prev_page\"],null],false],[0,\" \"],[7,\"a\",true],[11,\"href\",[22,\"requestUrl\"]],[10,\"data-auto-route\",\"true\"],[8],[1,[22,\"requestUrl\"],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"div\",true],[10,\"class\",\"desc\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"networkFixed\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"d-icon\",[\"check-circle\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[1,[22,\"desc\"],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"buttons\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"enabledButtons\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"d-button\",null,[[\"icon\",\"action\",\"label\",\"class\"],[[23,1,[\"icon\"]],[23,1,[\"action\"]],[23,1,[\"key\"]],[23,1,[\"classes\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[1,[28,\"conditional-loading-spinner\",null,[[\"condition\"],[[24,[\"loading\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/exception.hbs"
    }
  });

  _exports.default = _default;
});