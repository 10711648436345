define("discourse/templates/tag-groups-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HJ0Os+6+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"tag-group-content\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"i18n\",[\"tagging.groups.about_heading\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"i18n\",[\"tagging.groups.about_heading_empty\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"section\",true],[10,\"class\",\"tag-groups-about\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[\"tagging.groups.about_description\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"section\",true],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"model\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-primary\",\"tagGroups.new\"]],{\"statements\":[[0,\"        \"],[1,[28,\"d-icon\",[\"plus\"],null],false],[0,\" \"],[1,[28,\"i18n\",[\"tagging.groups.new\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/tag-groups-index.hbs"
    }
  });

  _exports.default = _default;
});