define("discourse/helpers/share-url", ["exports", "discourse-common/lib/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.resolveShareUrl = resolveShareUrl;

  function resolveShareUrl(url, user) {
    var badgesEnabled = (0, _helpers.helperContext)().siteSettings.enable_badges;
    var userSuffix = user && badgesEnabled ? "?u=".concat(user.username_lower) : "";
    return url + userSuffix;
  }
});