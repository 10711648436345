define("discourse/lib/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SEARCH_PHRASE_REGEXP = _exports.SEARCH_PRIORITIES = void 0;
  // DO NOT EDIT THIS FILE!!!
  // Update it by running `rake javascript:update_constants`
  var SEARCH_PRIORITIES = {
    ignore: 1,
    very_low: 2,
    low: 3,
    normal: 0,
    high: 4,
    very_high: 5
  };
  _exports.SEARCH_PRIORITIES = SEARCH_PRIORITIES;
  var SEARCH_PHRASE_REGEXP = '"([^"]+)"';
  _exports.SEARCH_PHRASE_REGEXP = SEARCH_PHRASE_REGEXP;
});