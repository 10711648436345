define("discourse/templates/account-created/edit-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ge/6JU4Y",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ac-message\"],[8],[0,\"\\n  \"],[1,[28,\"activation-email-form\",null,[[\"email\"],[[24,[\"newEmail\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"activation-controls\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"label\",\"disabled\",\"class\"],[[28,\"action\",[[23,0,[]],\"changeEmail\"],null],\"login.submit_new_email\",[24,[\"submitDisabled\"]],\"btn-primary\"]]],false],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"label\",\"class\"],[[28,\"action\",[[23,0,[]],\"cancel\"],null],\"cancel\",\"edit-cancel\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/account-created/edit-email.hbs"
    }
  });

  _exports.default = _default;
});