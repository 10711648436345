define("discourse/models/topic-list", ["exports", "discourse/models/rest", "discourse/models/session", "discourse/models/user", "discourse/lib/ajax", "discourse-common/lib/get-owner", "discourse-common/lib/get-url"], function (_exports, _rest, _session, _user, _ajax, _getOwner, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function extractByKey(collection, klass) {
    var retval = {};

    if (Ember.isEmpty(collection)) {
      return retval;
    }

    collection.forEach(function (item) {
      retval[item.id] = klass.create(item);
    });
    return retval;
  } // Whether to show the category badge in topic lists


  function displayCategoryInList(site, category) {
    if (category) {
      if (category.has_children) {
        return true;
      }

      var draftCategoryId = site.shared_drafts_category_id;

      if (draftCategoryId && category.id === draftCategoryId) {
        return true;
      }

      return false;
    }

    return true;
  }

  var TopicList = _rest.default.extend({
    canLoadMore: Ember.computed.notEmpty("more_topics_url"),
    forEachNew: function forEachNew(topics, callback) {
      var topicIds = new Set();
      this.topics.forEach(function (topic) {
        return topicIds.add(topic.id);
      });
      topics.forEach(function (topic) {
        if (!topicIds.has(topic.id)) {
          callback(topic);
        }
      });
    },
    updateSortParams: function updateSortParams(order, ascending) {
      var params = Object.assign({}, this.params || {});

      if (params.q) {
        // search is unique, nothing else allowed with it
        params = {
          q: params.q
        };
      } else {
        params.order = order || params.order;
        params.ascending = ascending;
      }

      this.set("params", params);
    },
    loadMore: function loadMore() {
      var _this = this;

      if (this.loadingMore) {
        return Ember.RSVP.Promise.resolve();
      }

      var moreUrl = this.more_topics_url;

      if (moreUrl) {
        var _moreUrl$split = moreUrl.split("?"),
            _moreUrl$split2 = _slicedToArray(_moreUrl$split, 2),
            url = _moreUrl$split2[0],
            params = _moreUrl$split2[1]; // ensure we postfix with .json so username paths work
        // correctly


        if (!url.match(/\.json$/)) {
          url += ".json";
        }

        moreUrl = url;

        if (params) {
          moreUrl += "?" + params;
        }

        this.set("loadingMore", true);
        return (0, _ajax.ajax)({
          url: moreUrl
        }).then(function (result) {
          var topicsAdded = 0;

          if (result) {
            // the new topics loaded from the server
            var newTopics = TopicList.topicsFrom(_this.store, result);

            _this.forEachNew(newTopics, function (t) {
              t.set("highlight", topicsAdded++ === 0);

              _this.topics.pushObject(t);
            });

            _this.setProperties({
              loadingMore: false,
              more_topics_url: result.topic_list.more_topics_url
            });

            _session.default.currentProp("topicList", _this);

            return {
              moreTopicsUrl: _this.more_topics_url,
              newTopics: newTopics
            };
          }
        });
      } else {
        // Return a promise indicating no more results
        return Ember.RSVP.Promise.resolve();
      }
    },
    // loads topics with these ids "before" the current topics
    loadBefore: function loadBefore(topic_ids, storeInSession) {
      var _this2 = this;

      // refresh dupes
      this.topics.removeObjects(this.topics.filter(function (topic) {
        return topic_ids.indexOf(topic.id) >= 0;
      }));
      var url = "".concat((0, _getUrl.default)("/")).concat(this.filter, ".json?topic_ids=").concat(topic_ids.join(","));
      return (0, _ajax.ajax)({
        url: url,
        data: this.params
      }).then(function (result) {
        var i = 0;

        _this2.forEachNew(TopicList.topicsFrom(_this2.store, result), function (t) {
          // highlight the first of the new topics so we can get a visual feedback
          t.set("highlight", true);

          _this2.topics.insertAt(i, t);

          i++;
        });

        if (storeInSession) {
          _session.default.currentProp("topicList", _this2);
        }
      });
    }
  });

  TopicList.reopenClass({
    topicsFrom: function topicsFrom(store, result, opts) {
      if (!result) {
        return;
      }

      opts = opts || {};
      var listKey = opts.listKey || "topics"; // Stitch together our side loaded data

      var users = extractByKey(result.users, _user.default);
      var groups = extractByKey(result.primary_groups, Ember.Object);
      return result.topic_list[listKey].map(function (t) {
        t.posters.forEach(function (p) {
          p.user = users[p.user_id];
          p.extraClasses = p.extras;

          if (p.primary_group_id) {
            p.primary_group = groups[p.primary_group_id];

            if (p.primary_group) {
              p.extraClasses = "".concat(p.extraClasses || "", " group-").concat(p.primary_group.name);
            }
          }
        });

        if (t.participants) {
          t.participants.forEach(function (p) {
            return p.user = users[p.user_id];
          });
        }

        return store.createRecord("topic", t);
      });
    },
    munge: function munge(json, store) {
      json.inserted = json.inserted || [];
      json.can_create_topic = json.topic_list.can_create_topic;
      json.more_topics_url = json.topic_list.more_topics_url;
      json.for_period = json.topic_list.for_period;
      json.loaded = true;
      json.per_page = json.topic_list.per_page;
      json.topics = this.topicsFrom(store, json);

      if (json.topic_list.shared_drafts) {
        json.sharedDrafts = this.topicsFrom(store, json, {
          listKey: "shared_drafts"
        });
      }

      return json;
    },
    find: function find(filter, params) {
      var store = (0, _getOwner.getOwner)(this).lookup("service:store");
      return store.findFiltered("topicList", {
        filter: filter,
        params: params
      });
    },
    // hide the category when it has no children
    hideUniformCategory: function hideUniformCategory(list, category) {
      list.set("hideCategory", !displayCategoryInList(list.site, category));
    }
  });
  var _default = TopicList;
  _exports.default = _default;
});