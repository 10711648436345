define("discourse/helpers/discourse-tag", ["exports", "discourse-common/lib/helpers", "discourse/lib/render-tag"], function (_exports, _helpers, _renderTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helpers.registerUnbound)("discourse-tag", function (name, params) {
    return Ember.String.htmlSafe((0, _renderTag.default)(name, params));
  });

  _exports.default = _default;
});