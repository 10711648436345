define("discourse/initializers/banner", ["exports", "discourse/lib/preload-store"], function (_exports, _preloadStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "banner",
    after: "message-bus",
    initialize: function initialize(container) {
      var banner = Ember.Object.create(_preloadStore.default.get("banner") || {}),
          site = container.lookup("site:main");
      site.set("banner", banner);
      var messageBus = container.lookup("message-bus:main");

      if (!messageBus) {
        return;
      }

      messageBus.subscribe("/site/banner", function (ban) {
        site.set("banner", Ember.Object.create(ban || {}));
      });
    }
  };
  _exports.default = _default;
});