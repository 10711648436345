define("discourse/components/edit-category-tags", ["exports", "discourse/components/edit-category-panel"], function (_exports, _editCategoryPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _editCategoryPanel.buildCategoryPanel)("tags", {
    allowedTagsEmpty: Ember.computed.empty("category.allowed_tags"),
    allowedTagGroupsEmpty: Ember.computed.empty("category.allowed_tag_groups"),
    disableAllowGlobalTags: Ember.computed.and("allowedTagsEmpty", "allowedTagGroupsEmpty")
  });

  _exports.default = _default;
});