define("discourse/templates/static", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QiDYO5fh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-section\",null,[[\"bodyClass\",\"class\"],[[24,[\"bodyClass\"]],\"container\"]],{\"statements\":[[4,\"watch-read\",null,[[\"action\",\"path\"],[[28,\"action\",[[23,0,[]],\"markFaqRead\"],null],[24,[\"model\",\"path\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"contents clearfix body-page\"],[8],[0,\"\\n\\n      \"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"tagName\",\"connectorTagName\"],[\"above-static\",\"span\",\"div\"]]],false],[0,\"\\n\\n      \"],[1,[28,\"html-safe\",[[24,[\"model\",\"html\"]]],null],false],[0,\"\\n\\n      \"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"connectorTagName\"],[\"below-static\",\"div\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"anyButtons\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"body-page-button-container\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showSignupButton\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"d-button\",null,[[\"action\",\"class\",\"label\"],[[28,\"route-action\",[\"showCreateAccount\"],null],\"btn-primary sign-up-button\",\"sign_up\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showLoginButton\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"d-button\",null,[[\"action\",\"class\",\"icon\",\"label\"],[[28,\"route-action\",[\"showLogin\"],null],\"btn-primary login-button\",\"user\",\"log_in\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/static.hbs"
    }
  });

  _exports.default = _default;
});