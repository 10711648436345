define("discourse/components/modal-tab", ["exports", "I18n", "discourse-common/utils/decorators", "discourse/lib/computed"], function (_exports, _I18n, _decorators, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("panel.title", "panel.rawTitle"), (_obj = {
    tagName: "li",
    classNames: ["modal-tab"],
    panel: null,
    selectedPanel: null,
    panelsLength: null,
    classNameBindings: ["isActive", "singleTab", "panel.id"],
    singleTab: Ember.computed.equal("panelsLength", 1),
    isActive: (0, _computed.propertyEqual)("panel.id", "selectedPanel.id"),
    title: function title(_title, rawTitle) {
      return _title ? _I18n.default.t(_title) : rawTitle;
    },
    click: function click() {
      this.set("selectedPanel", this.panel);

      if (this.onSelectPanel) {
        this.onSelectPanel(this.panel);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "title", [_dec], Object.getOwnPropertyDescriptor(_obj, "title"), _obj)), _obj)));

  _exports.default = _default;
});