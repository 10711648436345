define("discourse/lib/d-popover", ["exports", "discourse/lib/text-direction"], function (_exports, _textDirection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hidePopover = hidePopover;
  _exports.showPopover = showPopover;
  _exports.POPOVER_SELECTORS = void 0;
  var D_POPOVER_ID = "d-popover";
  var D_POPOVER_TEMPLATE = "\n  <div id=\"".concat(D_POPOVER_ID, "\" class=\"is-under\">\n    <div class=\"d-popover-arrow d-popover-top-arrow\"></div>\n    <div class=\"d-popover-content\">\n      <div class=\"spinner small\"></div>\n    </div>\n    <div class=\"d-popover-arrow d-popover-bottom-arrow\"></div>\n  </div>\n");
  var D_ARROW_HEIGHT = 10;
  var D_HORIZONTAL_MARGIN = 5;
  var POPOVER_SELECTORS = "[data-popover], [data-tooltip]";
  _exports.POPOVER_SELECTORS = POPOVER_SELECTORS;

  function hidePopover() {
    getPopover().fadeOut().remove();
    return getPopover();
  }

  function showPopover(event) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var $enteredElement = $(event.target).closest(POPOVER_SELECTORS).first();

    if (!$enteredElement.length) {
      $enteredElement = $(event.target);
    }

    if (isRetina()) {
      getPopover().addClass("retina");
    }

    if (!getPopover().length) {
      $("body").append($(D_POPOVER_TEMPLATE));
    }

    setPopoverHtmlContent($enteredElement, options.htmlContent);
    setPopoverTextContent($enteredElement, options.textContent);
    getPopover().fadeIn();
    positionPopover($enteredElement);
    return {
      html: function html(content) {
        return replaceHtmlContent($enteredElement, content);
      },
      text: function text(content) {
        return replaceTextContent($enteredElement, content);
      },
      hide: hidePopover
    };
  }

  function setPopoverHtmlContent($enteredElement, content) {
    replaceHtmlContent($enteredElement, content);
  }

  function setPopoverTextContent($enteredElement, content) {
    content = content || $enteredElement.attr("data-popover") || $enteredElement.attr("data-tooltip");
    replaceTextContent($enteredElement, content);
  }

  function replaceTextContent($enteredElement, content) {
    if (content) {
      getPopover().find(".d-popover-content").text(content);
      window.requestAnimationFrame(function () {
        return positionPopover($enteredElement);
      });
    }
  }

  function replaceHtmlContent($enteredElement, content) {
    if (content) {
      getPopover().find(".d-popover-content").html(content);
      window.requestAnimationFrame(function () {
        return positionPopover($enteredElement);
      });
    }
  }

  function positionPopover($element) {
    var $popover = getPopover();
    $popover.removeClass("is-above is-under is-left-aligned is-right-aligned");
    var $dHeader = $(".d-header");
    var windowRect = {
      left: 0,
      top: $dHeader.length ? $dHeader[0].getBoundingClientRect().bottom : 0,
      width: $(window).width(),
      height: $(window).height()
    };
    var popoverRect = {
      width: $popover.width(),
      height: $popover.height(),
      left: null,
      right: null
    };

    if (popoverRect.width > windowRect.width - D_HORIZONTAL_MARGIN * 2) {
      popoverRect.width = windowRect.width - D_HORIZONTAL_MARGIN * 2;
      $popover.width(popoverRect.width);
    }

    var targetRect = $element[0].getBoundingClientRect();
    var underSpace = windowRect.height - targetRect.bottom - D_ARROW_HEIGHT;
    var topSpace = targetRect.top - windowRect.top - D_ARROW_HEIGHT;

    if (underSpace > popoverRect.height + D_HORIZONTAL_MARGIN || underSpace > topSpace) {
      $popover.css("top", targetRect.bottom + window.pageYOffset + D_ARROW_HEIGHT).addClass("is-under");
    } else {
      $popover.css("top", targetRect.top + window.pageYOffset - popoverRect.height - D_ARROW_HEIGHT).addClass("is-above");
    }

    var leftSpace = targetRect.left + targetRect.width / 2;

    if ((0, _textDirection.siteDir)() === "ltr") {
      if (leftSpace > popoverRect.width / 2 + D_HORIZONTAL_MARGIN) {
        popoverRect.left = leftSpace - popoverRect.width / 2;
        $popover.css("left", popoverRect.left);
      } else {
        popoverRect.left = D_HORIZONTAL_MARGIN;
        $popover.css("left", popoverRect.left).addClass("is-left-aligned");
      }
    } else {
      var rightSpace = windowRect.width - targetRect.right;

      if (rightSpace > popoverRect.width / 2 + D_HORIZONTAL_MARGIN) {
        popoverRect.left = leftSpace - popoverRect.width / 2;
        $popover.css("left", popoverRect.left);
      } else {
        popoverRect.left = windowRect.width - popoverRect.width - D_HORIZONTAL_MARGIN * 2;
        $popover.css("left", popoverRect.left).addClass("is-right-aligned");
      }
    }

    var arrowPosition;

    if ((0, _textDirection.siteDir)() === "ltr") {
      arrowPosition = Math.abs(targetRect.left - popoverRect.left);
    } else {
      arrowPosition = targetRect.left - popoverRect.left + targetRect.width / 2;
    }

    $popover.find(".d-popover-arrow").css("left", arrowPosition);
  }

  function isRetina() {
    return window.devicePixelRatio && window.devicePixelRatio > 1;
  }

  function getPopover() {
    return $(document.getElementById(D_POPOVER_ID));
  }
});