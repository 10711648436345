define("discourse/templates/components/basic-topic-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MKKKtP4c",
    "block": "{\"symbols\":[],\"statements\":[[4,\"conditional-loading-spinner\",null,[[\"condition\"],[[24,[\"loading\"]]]],{\"statements\":[[4,\"if\",[[24,[\"topics\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"topic-list\",null,[[\"showPosters\",\"hideCategory\",\"topics\",\"expandExcerpts\",\"bulkSelectEnabled\",\"bulkSelectAction\",\"canBulkSelect\",\"selected\",\"tagsForUser\",\"onScroll\",\"scrollOnLoad\",\"toggleBulkSelect\",\"updateAutoAddTopicsToBulkSelect\"],[[24,[\"showPosters\"]],[24,[\"hideCategory\"]],[24,[\"topics\"]],[24,[\"expandExcerpts\"]],[24,[\"bulkSelectEnabled\"]],[24,[\"bulkSelectAction\"]],[24,[\"canBulkSelect\"]],[24,[\"selected\"]],[24,[\"tagsForUser\"]],[24,[\"onScroll\"]],[24,[\"scrollOnLoad\"]],[24,[\"toggleBulkSelect\"]],[24,[\"updateAutoAddTopicsToBulkSelect\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[24,[\"loadingMore\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"alert alert-info\"],[8],[0,\"\\n        \"],[1,[28,\"i18n\",[\"choose_topic.none_found\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/basic-topic-list.hbs"
    }
  });

  _exports.default = _default;
});