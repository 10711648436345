define("discourse/controllers/discovery/categories", ["exports", "discourse/controllers/discovery", "discourse-common/utils/decorators"], function (_exports, _discovery, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var subcategoryStyleComponentNames = {
    rows: "categories_only",
    rows_with_featured_topics: "categories_with_featured_topics",
    boxes: "categories_boxes",
    boxes_with_featured_topics: "categories_boxes_with_topics"
  };

  var _default = _discovery.default.extend((_dec = (0, _decorators.default)("model.parentCategory"), (_obj = {
    discovery: Ember.inject.controller(),
    // this makes sure the composer isn't scoping to a specific category
    category: null,
    canEdit: Ember.computed.reads("currentUser.staff"),
    categoryPageStyle: function categoryPageStyle(parentCategory) {
      var style = this.site.mobileView ? "categories_with_featured_topics" : this.siteSettings.desktop_category_page_style;

      if (parentCategory) {
        style = subcategoryStyleComponentNames[parentCategory.get("subcategory_list_style")] || style;
      }

      var componentName = parentCategory && style === "categories_and_latest_topics" ? "categories_only" : style;
      return Ember.String.dasherize(componentName);
    },
    actions: {
      refresh: function refresh() {
        this.send("triggerRefresh");
      },
      showInserted: function showInserted() {
        var tracker = this.topicTrackingState; // Move inserted into topics

        this.model.loadBefore(tracker.get("newIncoming"), true);
        tracker.resetTracking();
        return false;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "categoryPageStyle", [_dec], Object.getOwnPropertyDescriptor(_obj, "categoryPageStyle"), _obj)), _obj)));

  _exports.default = _default;
});