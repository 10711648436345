define("discourse/templates/composer/similar-topics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zaoB2ZnO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"close\"],[3,\"action\",[[23,0,[]],[24,[\"closeMessage\"]],[24,[\"message\"]]]],[8],[1,[28,\"d-icon\",[\"times\"],null],false],[9],[0,\"\\n\"],[7,\"h3\",true],[8],[1,[28,\"i18n\",[\"composer.similar_topics\"],null],false],[9],[0,\"\\n\\n\"],[7,\"ul\",true],[10,\"class\",\"topics\"],[8],[0,\"\\n  \"],[1,[28,\"mount-widget\",null,[[\"widget\",\"args\"],[\"search-result-topic\",[28,\"hash\",null,[[\"results\"],[[24,[\"message\",\"similarTopics\"]]]]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/composer/similar-topics.hbs"
    }
  });

  _exports.default = _default;
});