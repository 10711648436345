define("discourse/controllers/feature-topic", ["exports", "I18n", "discourse/mixins/modal-functionality", "discourse/lib/ajax", "bootbox", "discourse/helpers/category-link", "discourse-common/utils/decorators"], function (_exports, _I18n, _modalFunctionality, _ajax, _bootbox, _categoryLink, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("model.category"), _dec2 = (0, _decorators.default)("categoryLink", "model.pinned_globally", "model.pinned_until"), _dec3 = (0, _decorators.default)("model.details.can_pin_unpin_topic"), _dec4 = (0, _decorators.default)("categoryLink"), _dec5 = (0, _decorators.default)("categoryLink", "pinnedInCategoryCount"), _dec6 = (0, _decorators.default)("parsedPinnedInCategoryUntil"), _dec7 = (0, _decorators.default)("parsedPinnedGloballyUntil"), _dec8 = (0, _decorators.default)("model.pinnedInCategoryUntil"), _dec9 = (0, _decorators.default)("model.pinnedGloballyUntil"), _dec10 = (0, _decorators.default)("pinDisabled"), _dec11 = (0, _decorators.default)("pinGloballyDisabled"), (_obj = {
    topicController: Ember.inject.controller("topic"),
    loading: true,
    pinnedInCategoryCount: 0,
    pinnedGloballyCount: 0,
    bannerCount: 0,
    reset: function reset() {
      this.setProperties({
        "model.pinnedInCategoryUntil": null,
        "model.pinnedGloballyUntil": null,
        pinInCategoryTipShownAt: false,
        pinGloballyTipShownAt: false
      });
    },
    categoryLink: function categoryLink(category) {
      return (0, _categoryLink.categoryLinkHTML)(category, {
        allowUncategorized: true
      });
    },
    unPinMessage: function unPinMessage(categoryLink, pinnedGlobally, pinnedUntil) {
      var name = "topic.feature_topic.unpin";

      if (pinnedGlobally) {
        name += "_globally";
      }

      if (moment(pinnedUntil) > moment()) {
        name += "_until";
      }

      var until = moment(pinnedUntil).format("LL");
      return _I18n.default.t(name, {
        categoryLink: categoryLink,
        until: until
      });
    },
    canPinGlobally: function canPinGlobally(canPinUnpinTopic) {
      return this.currentUser.canManageTopic && canPinUnpinTopic;
    },
    pinMessage: function pinMessage(categoryLink) {
      return _I18n.default.t("topic.feature_topic.pin", {
        categoryLink: categoryLink
      });
    },
    alreadyPinnedMessage: function alreadyPinnedMessage(categoryLink, count) {
      var key = count === 0 ? "topic.feature_topic.not_pinned" : "topic.feature_topic.already_pinned";
      return _I18n.default.t(key, {
        categoryLink: categoryLink,
        count: count
      });
    },
    pinDisabled: function pinDisabled(parsedPinnedInCategoryUntil) {
      return !this._isDateValid(parsedPinnedInCategoryUntil);
    },
    pinGloballyDisabled: function pinGloballyDisabled(parsedPinnedGloballyUntil) {
      return !this._isDateValid(parsedPinnedGloballyUntil);
    },
    parsedPinnedInCategoryUntil: function parsedPinnedInCategoryUntil(pinnedInCategoryUntil) {
      return this._parseDate(pinnedInCategoryUntil);
    },
    parsedPinnedGloballyUntil: function parsedPinnedGloballyUntil(pinnedGloballyUntil) {
      return this._parseDate(pinnedGloballyUntil);
    },
    pinInCategoryValidation: function pinInCategoryValidation(pinDisabled) {
      if (pinDisabled) {
        return Ember.Object.create({
          failed: true,
          reason: _I18n.default.t("topic.feature_topic.pin_validation")
        });
      }
    },
    pinGloballyValidation: function pinGloballyValidation(pinGloballyDisabled) {
      if (pinGloballyDisabled) {
        return Ember.Object.create({
          failed: true,
          reason: _I18n.default.t("topic.feature_topic.pin_validation")
        });
      }
    },
    _parseDate: function _parseDate(date) {
      return moment(date, ["YYYY-MM-DD", "YYYY-MM-DD HH:mm"]);
    },
    _isDateValid: function _isDateValid(parsedDate) {
      return parsedDate.isValid() && parsedDate > moment();
    },
    onShow: function onShow() {
      var _this = this;

      this.set("loading", true);
      return (0, _ajax.ajax)("/topics/feature_stats.json", {
        data: {
          category_id: this.get("model.category.id")
        }
      }).then(function (result) {
        if (result) {
          _this.setProperties({
            pinnedInCategoryCount: result.pinned_in_category_count,
            pinnedGloballyCount: result.pinned_globally_count,
            bannerCount: result.banner_count
          });
        }
      }).finally(function () {
        return _this.set("loading", false);
      });
    },
    _forwardAction: function _forwardAction(name) {
      this.topicController.send(name);
      this.send("closeModal");
    },
    _confirmBeforePinningGlobally: function _confirmBeforePinningGlobally() {
      var _this2 = this;

      var count = this.pinnedGloballyCount;

      if (count < 4) {
        this._forwardAction("pinGlobally");
      } else {
        this.send("hideModal");

        _bootbox.default.confirm(_I18n.default.t("topic.feature_topic.confirm_pin_globally", {
          count: count
        }), _I18n.default.t("no_value"), _I18n.default.t("yes_value"), function (confirmed) {
          return confirmed ? _this2._forwardAction("pinGlobally") : _this2.send("reopenModal");
        });
      }
    },
    actions: {
      pin: function pin() {
        if (this.pinDisabled) {
          this.set("pinInCategoryTipShownAt", Date.now());
        } else {
          this._forwardAction("togglePinned");
        }
      },
      pinGlobally: function pinGlobally() {
        if (this.pinGloballyDisabled) {
          this.set("pinGloballyTipShownAt", Date.now());
        } else {
          this._confirmBeforePinningGlobally();
        }
      },
      unpin: function unpin() {
        this._forwardAction("togglePinned");
      },
      makeBanner: function makeBanner() {
        this._forwardAction("makeBanner");
      },
      removeBanner: function removeBanner() {
        this._forwardAction("removeBanner");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "categoryLink", [_dec], Object.getOwnPropertyDescriptor(_obj, "categoryLink"), _obj), _applyDecoratedDescriptor(_obj, "unPinMessage", [_dec2], Object.getOwnPropertyDescriptor(_obj, "unPinMessage"), _obj), _applyDecoratedDescriptor(_obj, "canPinGlobally", [_dec3], Object.getOwnPropertyDescriptor(_obj, "canPinGlobally"), _obj), _applyDecoratedDescriptor(_obj, "pinMessage", [_dec4], Object.getOwnPropertyDescriptor(_obj, "pinMessage"), _obj), _applyDecoratedDescriptor(_obj, "alreadyPinnedMessage", [_dec5], Object.getOwnPropertyDescriptor(_obj, "alreadyPinnedMessage"), _obj), _applyDecoratedDescriptor(_obj, "pinDisabled", [_dec6], Object.getOwnPropertyDescriptor(_obj, "pinDisabled"), _obj), _applyDecoratedDescriptor(_obj, "pinGloballyDisabled", [_dec7], Object.getOwnPropertyDescriptor(_obj, "pinGloballyDisabled"), _obj), _applyDecoratedDescriptor(_obj, "parsedPinnedInCategoryUntil", [_dec8], Object.getOwnPropertyDescriptor(_obj, "parsedPinnedInCategoryUntil"), _obj), _applyDecoratedDescriptor(_obj, "parsedPinnedGloballyUntil", [_dec9], Object.getOwnPropertyDescriptor(_obj, "parsedPinnedGloballyUntil"), _obj), _applyDecoratedDescriptor(_obj, "pinInCategoryValidation", [_dec10], Object.getOwnPropertyDescriptor(_obj, "pinInCategoryValidation"), _obj), _applyDecoratedDescriptor(_obj, "pinGloballyValidation", [_dec11], Object.getOwnPropertyDescriptor(_obj, "pinGloballyValidation"), _obj)), _obj)));

  _exports.default = _default;
});