define("discourse/initializers/badging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Updates the PWA badging if available
  var _default = {
    name: "badging",
    after: "message-bus",
    initialize: function initialize(container) {
      if (!navigator.setAppBadge) {
        return;
      } // must have the Badging API


      var user = container.lookup("current-user:main");

      if (!user) {
        return;
      } // must be logged in


      var appEvents = container.lookup("service:app-events");
      appEvents.on("notifications:changed", function () {
        var notifications = user.unread_notifications + user.unread_high_priority_notifications;
        navigator.setAppBadge(notifications);
      });
    }
  };
  _exports.default = _default;
});