define("discourse/components/user-selector", ["exports", "discourse-common/utils/decorators", "discourse/components/text-field", "discourse-common/lib/raw-templates", "discourse/lib/user-search", "discourse-common/lib/deprecated"], function (_exports, _decorators, _textField, _rawTemplates, _userSearch, _deprecated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _textField.default.extend((_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.on)("willDestroyElement"), _dec3 = (0, _decorators.on)("didInsertElement"), _dec4 = (0, _decorators.observes)("usernames"), (_obj = {
    autocorrect: false,
    autocapitalize: false,
    name: "user-selector",
    canReceiveUpdates: false,
    single: false,
    fullWidthWrap: false,
    deprecateComponent: function deprecateComponent() {
      (0, _deprecated.default)("`{{user-selector}}` is deprecated. Please use `{{email-group-user-chooser}}` instead.", {
        since: "2.7",
        dropFrom: "2.8"
      });
    },
    _paste: function _paste(event) {
      var pastedText = "";

      if (window.clipboardData && window.clipboardData.getData) {
        // IE
        pastedText = window.clipboardData.getData("Text");
      } else if (event.clipboardData && event.clipboardData.getData) {
        pastedText = event.clipboardData.getData("text/plain");
      }

      if (pastedText.length > 0) {
        this.importText(pastedText);
        event.preventDefault();
        return false;
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      if (this.canReceiveUpdates) {
        this._createAutocompleteInstance({
          updateData: true
        });
      }
    },
    _destroyAutocompleteInstance: function _destroyAutocompleteInstance() {
      $(this.element).autocomplete("destroy");
      this.element.removeEventListener("paste", this._paste);
    },
    _createAutocompleteInstance: function _createAutocompleteInstance(opts) {
      var _this = this;

      var bool = function bool(n) {
        var val = _this[n];
        return val === true || val === "true";
      };

      var selected = [],
          groups = [],
          currentUser = this.currentUser,
          includeMentionableGroups = bool("includeMentionableGroups"),
          includeMessageableGroups = bool("includeMessageableGroups"),
          includeGroups = bool("includeGroups"),
          allowedUsers = bool("allowedUsers"),
          excludeCurrentUser = bool("excludeCurrentUser"),
          single = bool("single"),
          allowAny = bool("allowAny"),
          disabled = bool("disabled"),
          allowEmails = bool("allowEmails"),
          fullWidthWrap = bool("fullWidthWrap");

      var allExcludedUsernames = function allExcludedUsernames() {
        // hack works around some issues with allowAny eventing
        var usernames = single ? [] : selected;

        if (currentUser && excludeCurrentUser) {
          usernames = usernames.concat([currentUser.username]);
        }

        return usernames.concat(_this.excludedUsernames || []);
      };

      this.element.addEventListener("paste", this._paste);
      var userSelectorComponent = this;
      $(this.element).val(this.usernames).autocomplete({
        template: (0, _rawTemplates.findRawTemplate)("user-selector-autocomplete"),
        disabled: disabled,
        single: single,
        allowAny: allowAny,
        updateData: opts && opts.updateData ? opts.updateData : false,
        fullWidthWrap: fullWidthWrap,
        dataSource: function dataSource(term) {
          return (0, _userSearch.default)({
            term: term,
            topicId: userSelectorComponent.topicId,
            exclude: allExcludedUsernames(),
            includeGroups: includeGroups,
            allowedUsers: allowedUsers,
            includeMentionableGroups: includeMentionableGroups,
            includeMessageableGroups: includeMessageableGroups,
            groupMembersOf: userSelectorComponent.groupMembersOf,
            allowEmails: allowEmails
          });
        },
        transformComplete: function transformComplete(v) {
          if (v.username || v.name) {
            if (!v.username) {
              groups.push(v.name);
            }

            return v.username || v.name;
          } else {
            var excludes = allExcludedUsernames();
            return v.usernames.filter(function (item) {
              return excludes.indexOf(item) === -1;
            });
          }
        },
        onChangeItems: function onChangeItems(items) {
          var hasGroups = false;
          items = items.map(function (i) {
            if (groups.indexOf(i) > -1) {
              hasGroups = true;
            }

            return i.username ? i.username : i;
          });
          var previouslySelected = [];

          if (Array.isArray(userSelectorComponent.usernames)) {
            previouslySelected = userSelectorComponent.usernames;
          } else {
            if (userSelectorComponent.usernames) {
              previouslySelected = userSelectorComponent.usernames.split(",");
            }
          }

          userSelectorComponent.setProperties({
            usernames: items.join(","),
            hasGroups: hasGroups
          });
          selected = items;

          if (userSelectorComponent.onChangeCallback) {
            userSelectorComponent.onChangeCallback(previouslySelected, selected);
          }
        },
        reverseTransform: function reverseTransform(i) {
          return {
            username: i
          };
        }
      });
    },
    importText: function importText(text) {
      var _this2 = this;

      var usernames = [];

      if ((this.usernames || "").length > 0) {
        usernames = this.usernames.split(",");
      }

      (text || "").split(/[, \n]+/).forEach(function (val) {
        val = val.replace(/^@+/, "").trim();

        if (val.length > 0 && (!_this2.excludedUsernames || !_this2.excludedUsernames.includes(val))) {
          usernames.push(val);
        }
      });
      this.set("usernames", usernames.uniq().join(","));

      if (!this.canReceiveUpdates) {
        this._createAutocompleteInstance({
          updateData: true
        });
      }
    },
    _clearInput: function _clearInput() {
      if (arguments.length > 1 && Ember.isEmpty(this.usernames)) {
        $(this.element).parent().find("a").click();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "deprecateComponent", [_dec], Object.getOwnPropertyDescriptor(_obj, "deprecateComponent"), _obj), _applyDecoratedDescriptor(_obj, "_paste", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_paste"), _obj), _applyDecoratedDescriptor(_obj, "_destroyAutocompleteInstance", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_destroyAutocompleteInstance"), _obj), _applyDecoratedDescriptor(_obj, "_createAutocompleteInstance", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_createAutocompleteInstance"), _obj), _applyDecoratedDescriptor(_obj, "_clearInput", [_dec4], Object.getOwnPropertyDescriptor(_obj, "_clearInput"), _obj)), _obj)));

  _exports.default = _default;
});