define("discourse/templates/components/date-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "85yoXA3f",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"class\",\"placeholder\",\"value\",\"input\",\"id\"],[[24,[\"inputType\"]],\"date-picker\",[24,[\"placeholder\"]],[28,\"readonly\",[[24,[\"value\"]]],null],[28,\"action\",[[23,0,[]],\"onChangeDate\"],null],[24,[\"inputId\"]]]]],false],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"useGlobalPickerContainer\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"picker-container\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/date-input.hbs"
    }
  });

  _exports.default = _default;
});