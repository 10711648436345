define("discourse/helpers/route-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.routeAction = routeAction;
  _exports.default = void 0;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function getCurrentRouteInfos(router) {
    var routerLib = router._routerMicrolib || router.router;
    return routerLib.currentRouteInfos;
  }

  function getRoutes(router) {
    return Ember.A(getCurrentRouteInfos(router)).mapBy("_route").reverse();
  }

  function getRouteWithAction(router, actionName) {
    var action;
    var handler = Ember.A(getRoutes(router)).find(function (route) {
      var actions = route.actions || route._actions;
      action = actions[actionName];
      return typeof action === "function";
    });
    return {
      action: action,
      handler: handler
    };
  }

  function routeAction(actionName, router) {
    for (var _len = arguments.length, params = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      params[_key - 2] = arguments[_key];
    }

    (false && !(router) && Ember.assert("[ember-route-action-helper] Unable to lookup router", router));
    Ember.runInDebug(function () {
      var _getRouteWithAction = getRouteWithAction(router, actionName),
          handler = _getRouteWithAction.handler;

      (false && !(handler) && Ember.assert("[ember-route-action-helper] Unable to find action ".concat(actionName), handler));
    });
    return function () {
      var _Ember$run;

      var _getRouteWithAction2 = getRouteWithAction(router, actionName),
          action = _getRouteWithAction2.action,
          handler = _getRouteWithAction2.handler;

      for (var _len2 = arguments.length, invocationArgs = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        invocationArgs[_key2] = arguments[_key2];
      }

      var args = params.concat(invocationArgs);
      return (_Ember$run = Ember.run).join.apply(_Ember$run, [handler, action].concat(_toConsumableArray(args)));
    };
  }

  var _default = Ember.Helper.extend({
    router: Ember.computed({
      get: function get() {
        return Ember.getOwner(this).lookup("router:main");
      }
    }),
    compute: function compute(_ref) {
      var _ref2 = _toArray(_ref),
          actionName = _ref2[0],
          params = _ref2.slice(1);

      return routeAction.apply(void 0, [actionName, Ember.get(this, "router")].concat(_toConsumableArray(params)));
    }
  });

  _exports.default = _default;
});