define("discourse/components/category-title-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "h3",
    // icon name defined here so it can be easily overridden in theme components
    lockIcon: "lock"
  });

  _exports.default = _default;
});