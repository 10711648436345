define("discourse/templates/components/reviewable-claimed-topic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d699hfN2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"enabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"reviewable-claimed-topic\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"claimedBy\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"claimed-by\"],[8],[0,\"\\n        \"],[1,[28,\"avatar\",[[24,[\"claimedBy\"]]],[[\"imageSize\"],[\"small\"]]],false],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"claimed-username\"],[8],[1,[24,[\"claimedBy\",\"username\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[1,[28,\"d-button\",null,[[\"icon\",\"class\",\"action\",\"disabled\",\"title\"],[\"times\",\"btn-small unclaim\",[28,\"action\",[[23,0,[]],\"unclaim\"],null],[24,[\"unassigning\"]],\"review.unclaim.help\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"d-button\",null,[[\"icon\",\"class\",\"title\",\"action\"],[\"user-plus\",\"btn-small claim\",\"review.claim.title\",[28,\"action\",[[23,0,[]],\"claim\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-claimed-topic.hbs"
    }
  });

  _exports.default = _default;
});