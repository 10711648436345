define("discourse/templates/components/reviewable-histories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MtAo0VBF",
    "block": "{\"symbols\":[\"rh\"],\"statements\":[[4,\"if\",[[24,[\"filteredHistories\"]]],null,{\"statements\":[[0,\"  \"],[7,\"table\",true],[10,\"class\",\"reviewable-histories\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"th\",true],[10,\"colspan\",\"3\"],[8],[1,[28,\"i18n\",[\"review.history.title\"],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"filteredHistories\"]]],null,{\"statements\":[[4,\"unless\",[[23,1,[\"created\"]]],null,{\"statements\":[[0,\"          \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"td\",true],[8],[0,\"\\n              \"],[1,[28,\"reviewable-history-description\",[[23,1,[]]],null],false],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"user-link\",null,[[\"user\"],[[24,[\"rs\",\"user\"]]]],{\"statements\":[[0,\"                \"],[1,[28,\"avatar\",[[23,1,[\"created_by\"]]],[[\"imageSize\"],[\"tiny\"]]],false],[0,\"\\n                \"],[1,[23,1,[\"created_by\",\"username\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[9],[0,\"\\n            \"],[7,\"td\",true],[8],[1,[28,\"format-date\",[[23,1,[\"created_at\"]]],[[\"format\"],[\"medium\"]]],false],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-histories.hbs"
    }
  });

  _exports.default = _default;
});