define("discourse/templates/components/create-topics-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n4ahr5oy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"hidden\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"alert alert-info alert-too-few-topics\"],[8],[0,\"\\n      \"],[1,[22,\"message\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/create-topics-notice.hbs"
    }
  });

  _exports.default = _default;
});