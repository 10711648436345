define("discourse/routes/tag-show", ["exports", "discourse/routes/build-topic-route", "discourse/controllers/discovery-sortable", "discourse/models/category", "discourse/models/composer", "discourse/routes/discourse", "discourse/mixins/filter-mode", "I18n", "discourse/models/permission-type", "discourse/lib/utilities", "discourse-common/lib/helpers", "discourse/lib/topic-list-tracker", "discourse/lib/show-modal"], function (_exports, _buildTopicRoute, _discoverySortable, _category, _composer, _discourse, _filterMode, _I18n, _permissionType, _utilities, _helpers, _topicListTracker, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _discourse.default.extend(_filterMode.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_obj = {
    navMode: "latest",
    queryParams: _discoverySortable.queryParams,
    renderTemplate: function renderTemplate() {
      var controller = this.controllerFor("tag.show");
      this.render("tags.show", {
        controller: controller
      });
    },
    model: function model(params) {
      var _this = this;

      var tag = this.store.createRecord("tag", {
        id: (0, _utilities.escapeExpression)(params.tag_id)
      });

      if (params.additional_tags) {
        this.set("additionalTags", params.additional_tags.split("/").map(function (t) {
          return _this.store.createRecord("tag", {
            id: (0, _utilities.escapeExpression)(t)
          }).id;
        }));
      } else {
        this.set("additionalTags", null);
      }

      this.set("filterType", this.navMode.split("/")[0]);
      this.set("categorySlugPathWithID", params.category_slug_path_with_id);

      if (tag && tag.get("id") !== "none" && this.currentUser) {
        // If logged in, we should get the tag's user settings
        return this.store.find("tagNotification", tag.get("id").toLowerCase()).then(function (tn) {
          _this.set("tagNotification", tn);

          return tag;
        });
      }

      return tag;
    },
    afterModel: function afterModel(tag, transition) {
      var _this2 = this;

      var controller = this.controllerFor("tag.show");
      controller.setProperties({
        loading: true,
        showInfo: false
      });
      var params = (0, _buildTopicRoute.filterQueryParams)(transition.to.queryParams, {});
      var category = this.categorySlugPathWithID ? _category.default.findBySlugPathWithID(this.categorySlugPathWithID) : null;
      var topicFilter = this.navMode;
      var tagId = tag ? tag.id.toLowerCase() : "none";
      var filter;

      if (category) {
        category.setupGroupsAndPermissions();
        this.set("category", category);
        filter = "tags/c/".concat(_category.default.slugFor(category), "/").concat(category.id);

        if (this.noSubcategories) {
          filter += "/none";
        }

        filter += "/".concat(tagId, "/l/").concat(topicFilter);
      } else if (this.additionalTags) {
        this.set("category", null);
        filter = "tags/intersection/".concat(tagId, "/").concat(this.additionalTags.join("/"));
      } else {
        this.set("category", null);
        filter = "tag/".concat(tagId, "/l/").concat(topicFilter);
      }

      return (0, _buildTopicRoute.findTopicList)(this.store, this.topicTrackingState, filter, params, {
        cached: this.isPoppedState(transition)
      }).then(function (list) {
        if (list.topic_list.tags && list.topic_list.tags.length === 1) {
          // Update name of tag (case might be different)
          tag.setProperties({
            id: list.topic_list.tags[0].name,
            staff: list.topic_list.tags[0].staff
          });
        }

        (0, _topicListTracker.setTopicList)(list);
        controller.setProperties({
          list: list,
          canCreateTopic: list.get("can_create_topic"),
          loading: false,
          canCreateTopicOnCategory: _this2.get("category.permission") === _permissionType.default.FULL,
          canCreateTopicOnTag: !tag.get("staff") || _this2.get("currentUser.staff")
        });
      });
    },
    titleToken: function titleToken() {
      var filterText = _I18n.default.t("filters.".concat(this.navMode.replace("/", "."), ".title"));

      var controller = this.controllerFor("tag.show");

      if (controller.get("model.id")) {
        if (this.category) {
          return _I18n.default.t("tagging.filters.with_category", {
            filter: filterText,
            tag: controller.get("model.id"),
            category: this.get("category.name")
          });
        } else {
          return _I18n.default.t("tagging.filters.without_category", {
            filter: filterText,
            tag: controller.get("model.id")
          });
        }
      } else {
        if (this.category) {
          return _I18n.default.t("tagging.filters.untagged_with_category", {
            filter: filterText,
            category: this.get("category.name")
          });
        } else {
          return _I18n.default.t("tagging.filters.untagged_without_category", {
            filter: filterText
          });
        }
      }
    },
    setupController: function setupController(controller, model) {
      this.controllerFor("tag.show").setProperties({
        model: model,
        tag: model,
        additionalTags: this.additionalTags,
        category: this.category,
        filterType: this.filterType,
        navMode: this.navMode,
        tagNotification: this.tagNotification,
        noSubcategories: this.noSubcategories
      });
      this.searchService.set("searchContext", model.get("searchContext"));
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.searchService.set("searchContext", null);
    },
    renameTag: function renameTag(tag) {
      (0, _showModal.default)("rename-tag", {
        model: tag
      });
    },
    createTopic: function createTopic() {
      var _this3 = this;

      if (this.get("currentUser.has_topic_draft")) {
        this.openTopicDraft();
      } else {
        var controller = this.controllerFor("tag.show");
        var composerController = this.controllerFor("composer");
        composerController.open({
          categoryId: controller.get("category.id"),
          action: _composer.default.CREATE_TOPIC,
          draftKey: _composer.default.NEW_TOPIC_KEY
        }).then(function () {
          // Pre-fill the tags input field
          if (composerController.canEditTags && controller.get("model.id")) {
            var composerModel = _this3.controllerFor("composer").get("model");

            composerModel.set("tags", [controller.get("model.id")].concat(_toConsumableArray((0, _helpers.makeArray)(controller.additionalTags))).filter(Boolean));
          }
        });
      }
    },
    dismissReadTopics: function dismissReadTopics(dismissTopics) {
      var operationType = dismissTopics ? "topics" : "posts";
      this.send("dismissRead", operationType);
    },
    dismissRead: function dismissRead(operationType) {
      var controller = this.controllerFor("tags-show");
      var options = {
        tagName: controller.get("tag.id")
      };
      var categoryId = controller.get("category.id");

      if (categoryId) {
        options = Object.assign({}, options, {
          categoryId: categoryId,
          includeSubcategories: !controller.noSubcategories
        });
      }

      controller.send("dismissRead", operationType, options);
    },
    resetParams: function resetParams() {
      var skipParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      _discoverySortable.resetParams.call(this, skipParams);
    },
    didTransition: function didTransition() {
      this.controllerFor("tag.show")._showFooter();

      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, "renameTag", [_dec], Object.getOwnPropertyDescriptor(_obj, "renameTag"), _obj), _applyDecoratedDescriptor(_obj, "createTopic", [_dec2], Object.getOwnPropertyDescriptor(_obj, "createTopic"), _obj), _applyDecoratedDescriptor(_obj, "dismissReadTopics", [_dec3], Object.getOwnPropertyDescriptor(_obj, "dismissReadTopics"), _obj), _applyDecoratedDescriptor(_obj, "dismissRead", [_dec4], Object.getOwnPropertyDescriptor(_obj, "dismissRead"), _obj), _applyDecoratedDescriptor(_obj, "resetParams", [_dec5], Object.getOwnPropertyDescriptor(_obj, "resetParams"), _obj), _applyDecoratedDescriptor(_obj, "didTransition", [_dec6], Object.getOwnPropertyDescriptor(_obj, "didTransition"), _obj)), _obj)));

  _exports.default = _default;
});