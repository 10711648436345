define("discourse/templates/components/reviewable-field-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B22GzyLp",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"category-chooser\",null,[[\"value\",\"onChange\"],[[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-field-category.hbs"
    }
  });

  _exports.default = _default;
});