define("discourse/adapters/pending-post", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    jsonMode: true,
    pathFor: function pathFor(_store, _type, params) {
      return "/posts/".concat(params.username, "/pending.json");
    }
  });

  _exports.default = _default;
});