define("discourse/raw-views/list/post-count-or-badges", ["exports", "I18n", "discourse-common/utils/decorators"], function (_exports, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Object.extend((_obj = {
    showBadges: Ember.computed.and("postBadgesEnabled", "topic.unread_posts"),
    newDotText: function newDotText() {
      return this.currentUser && this.currentUser.trust_level > 0 ? "" : _I18n.default.t("filters.new.lower_title");
    }
  }, (_applyDecoratedDescriptor(_obj, "newDotText", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "newDotText"), _obj)), _obj));

  _exports.default = _default;
});