define("discourse/templates/components/reviewable-field-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oSeetZ3g",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"value\",\"change\",\"class\"],[[24,[\"value\"]],[24,[\"valueChanged\"]],\"reviewable-input-text\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-field-text.hbs"
    }
  });

  _exports.default = _default;
});