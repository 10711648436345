define("discourse/controllers/request-group-membership-form", ["exports", "discourse/lib/url", "I18n", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators", "discourse/lib/ajax-error"], function (_exports, _url, _I18n, _modalFunctionality, _decorators, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("model.name"), _dec2 = (0, _decorators.default)("loading", "reason"), (_obj = {
    loading: false,
    reason: Ember.computed.alias("model.membership_request_template"),
    title: function title(groupName) {
      return _I18n.default.t("groups.membership_request.title", {
        group_name: groupName
      });
    },
    disableSubmit: function disableSubmit(loading, reason) {
      return loading || Ember.isEmpty(reason);
    },
    actions: {
      requestMember: function requestMember() {
        var _this = this;

        if (this.currentUser) {
          this.set("loading", true);
          this.model.requestMembership(this.reason).then(function (result) {
            _url.default.routeTo(result.relative_url);
          }).catch(_ajaxError.popupAjaxError).finally(function () {
            _this.set("loading", false);
          });
        } else {
          this._showLoginModal();
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "title", [_dec], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "disableSubmit", [_dec2], Object.getOwnPropertyDescriptor(_obj, "disableSubmit"), _obj)), _obj)));

  _exports.default = _default;
});