define("discourse/routes/group", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    titleToken: function titleToken() {
      return [this.modelFor("group").get("name")];
    },
    model: function model(params) {
      return this.store.find("group", params.name);
    },
    serialize: function serialize(model) {
      return {
        name: model.get("name").toLowerCase()
      };
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model);
    }
  });

  _exports.default = _default;
});