define("discourse/lib/time-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.now = now;
  _exports.startOfDay = startOfDay;
  _exports.tomorrow = tomorrow;
  _exports.thisWeekend = thisWeekend;
  _exports.laterToday = laterToday;
  _exports.laterThisWeek = laterThisWeek;
  _exports.nextMonth = nextMonth;
  _exports.nextBusinessWeekStart = nextBusinessWeekStart;
  _exports.parseCustomDatetime = parseCustomDatetime;
  _exports.MOMENT_SATURDAY = _exports.MOMENT_FRIDAY = _exports.MOMENT_THURSDAY = _exports.MOMENT_MONDAY = _exports.MOMENT_SUNDAY = _exports.LATER_TODAY_MAX_HOUR = _exports.LATER_TODAY_CUTOFF_HOUR = _exports.START_OF_DAY_HOUR = void 0;
  var START_OF_DAY_HOUR = 8;
  _exports.START_OF_DAY_HOUR = START_OF_DAY_HOUR;
  var LATER_TODAY_CUTOFF_HOUR = 17;
  _exports.LATER_TODAY_CUTOFF_HOUR = LATER_TODAY_CUTOFF_HOUR;
  var LATER_TODAY_MAX_HOUR = 18;
  _exports.LATER_TODAY_MAX_HOUR = LATER_TODAY_MAX_HOUR;
  var MOMENT_SUNDAY = 0;
  _exports.MOMENT_SUNDAY = MOMENT_SUNDAY;
  var MOMENT_MONDAY = 1;
  _exports.MOMENT_MONDAY = MOMENT_MONDAY;
  var MOMENT_THURSDAY = 4;
  _exports.MOMENT_THURSDAY = MOMENT_THURSDAY;
  var MOMENT_FRIDAY = 5;
  _exports.MOMENT_FRIDAY = MOMENT_FRIDAY;
  var MOMENT_SATURDAY = 6;
  _exports.MOMENT_SATURDAY = MOMENT_SATURDAY;

  function now(timezone) {
    return moment.tz(timezone);
  }

  function startOfDay(momentDate) {
    var startOfDayHour = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : START_OF_DAY_HOUR;
    return momentDate.hour(startOfDayHour).startOf("hour");
  }

  function tomorrow(timezone) {
    return startOfDay(now(timezone).add(1, "day"));
  }

  function thisWeekend(timezone) {
    return startOfDay(now(timezone).day(MOMENT_SATURDAY));
  }

  function laterToday(timezone) {
    var later = now(timezone).add(3, "hours");

    if (later.hour() >= LATER_TODAY_MAX_HOUR) {
      return later.hour(LATER_TODAY_MAX_HOUR).startOf("hour");
    }

    return later.minutes() < 30 ? later.startOf("hour") : later.add(30, "minutes").startOf("hour");
  }

  function laterThisWeek(timezone) {
    return startOfDay(now(timezone).add(2, "days"));
  }

  function nextMonth(timezone) {
    return startOfDay(now(timezone).add(1, "month").startOf("month"));
  }

  function nextBusinessWeekStart(timezone) {
    return startOfDay(now(timezone).add(7, "days")).day(MOMENT_MONDAY);
  }

  function parseCustomDatetime(date, time, currentTimezone) {
    var parseTimezone = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    // If we are called without a valid date use today
    date = date || new Date().toISOString().split("T")[0];
    var dateTime = Ember.isPresent(time) ? "".concat(date, " ").concat(time) : date;
    parseTimezone = parseTimezone || currentTimezone;
    var parsed = moment.tz(dateTime, parseTimezone);

    if (parseTimezone !== currentTimezone) {
      parsed = parsed.tz(currentTimezone);
    }

    return parsed;
  }
});