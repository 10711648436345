define("discourse/templates/modal/bulk-change-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q+hJsPJQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[1,[28,\"i18n\",[\"topics.bulk.choose_new_category\"],null],false],[9],[0,\"\\n\\n\"],[7,\"p\",true],[8],[0,\"\\n  \"],[1,[28,\"category-chooser\",null,[[\"value\",\"onChange\"],[[24,[\"newCategoryId\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"newCategoryId\"]]],null]],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"conditional-loading-spinner\",null,[[\"condition\"],[[24,[\"loading\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"d-button\",null,[[\"action\",\"label\"],[[28,\"action\",[[23,0,[]],\"changeCategory\"],null],\"topics.bulk.change_category\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/bulk-change-category.hbs"
    }
  });

  _exports.default = _default;
});