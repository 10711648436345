define("discourse/components/table-header-toggle", ["exports", "discourse-common/lib/icon-library"], function (_exports, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "th",
    classNames: ["sortable"],
    attributeBindings: ["title"],
    labelKey: null,
    chevronIcon: null,
    columnIcon: null,
    translated: false,
    automatic: false,
    onActiveRender: null,
    toggleProperties: function toggleProperties() {
      if (this.order === this.field) {
        this.set("asc", this.asc ? null : true);
      } else {
        this.setProperties({
          order: this.field,
          asc: null
        });
      }
    },
    toggleChevron: function toggleChevron() {
      if (this.order === this.field) {
        var chevron = (0, _iconLibrary.iconHTML)(this.asc ? "chevron-up" : "chevron-down");
        this.set("chevronIcon", "".concat(chevron).htmlSafe());
      } else {
        this.set("chevronIcon", null);
      }
    },
    click: function click() {
      this.toggleProperties();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.automatic && !this.translated) {
        this.set("labelKey", this.field);
      }

      this.set("id", "table-header-toggle-".concat(this.field.replace(/\s/g, "")));
      this.toggleChevron();
    },
    didRender: function didRender() {
      if (this.onActiveRender && this.chevronIcon) {
        this.onActiveRender(this.element);
      }
    }
  });

  _exports.default = _default;
});