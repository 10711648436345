define("discourse/routes/tag-groups-new", ["exports", "discourse/routes/discourse", "I18n"], function (_exports, _discourse, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    showFooter: true,
    beforeModel: function beforeModel() {
      if (!this.siteSettings.tagging_enabled) {
        this.transitionTo("tagGroups");
      }
    },
    model: function model() {
      return this.store.createRecord("tagGroup", {
        name: _I18n.default.t("tagging.groups.new_name")
      });
    }
  });

  _exports.default = _default;
});