define("discourse/routes/review", ["exports", "discourse/routes/discourse", "I18n"], function (_exports, _discourse, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    titleToken: function titleToken() {
      return _I18n.default.t("review.title");
    }
  });

  _exports.default = _default;
});