define("discourse/controllers/review-index", ["exports", "I18n", "discourse-common/utils/decorators"], function (_exports, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("reviewableTypes"), _dec2 = (0, _decorators.default)("filtersExpanded"), (_obj = {
    queryParams: ["priority", "type", "status", "category_id", "topic_id", "username", "reviewed_by", "from_date", "to_date", "sort_order", "additional_filters"],
    type: null,
    status: "pending",
    priority: "low",
    category_id: null,
    reviewables: null,
    topic_id: null,
    filtersExpanded: false,
    username: "",
    reviewed_by: "",
    from_date: null,
    to_date: null,
    sort_order: null,
    additional_filters: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set("priority", this.siteSettings.reviewable_default_visibility);
      this.set("filtersExpanded", !this.site.mobileView);
    },
    allTypes: function allTypes() {
      return (this.reviewableTypes || []).map(function (type) {
        return {
          id: type,
          name: _I18n.default.t("review.types.".concat(type.underscore(), ".title"))
        };
      });
    },
    priorities: function priorities() {
      return ["any", "low", "medium", "high"].map(function (priority) {
        return {
          id: priority,
          name: _I18n.default.t("review.filters.priority.".concat(priority))
        };
      });
    },
    sortOrders: function sortOrders() {
      return ["score", "score_asc", "created_at", "created_at_asc"].map(function (order) {
        return {
          id: order,
          name: _I18n.default.t("review.filters.orders.".concat(order))
        };
      });
    },
    statuses: function statuses() {
      return ["pending", "approved", "rejected", "deleted", "ignored", "reviewed", "all"].map(function (id) {
        return {
          id: id,
          name: _I18n.default.t("review.statuses.".concat(id, ".title"))
        };
      });
    },
    toggleFiltersIcon: function toggleFiltersIcon(filtersExpanded) {
      return filtersExpanded ? "chevron-up" : "chevron-down";
    },
    setRange: function setRange(range) {
      this.setProperties(range);
    },
    refreshModel: function refreshModel() {
      var _this = this;

      Ember.run.next(function () {
        return _this.send("refreshRoute");
      });
    },
    actions: {
      remove: function remove(ids) {
        if (!ids) {
          return;
        }

        var newList = this.reviewables.reject(function (reviewable) {
          return ids.indexOf(reviewable.id) !== -1;
        });

        if (newList.length === 0) {
          this.refreshModel();
        } else {
          this.set("reviewables", newList);
        }
      },
      resetTopic: function resetTopic() {
        this.set("topic_id", null);
        this.refreshModel();
      },
      refresh: function refresh() {
        var currentStatus = this.status;
        var nextStatus = this.filterStatus;
        var currentOrder = this.sort_order;
        var nextOrder = this.filterSortOrder;
        var createdAtStatuses = ["reviewed", "all"];
        var priorityStatuses = ["approved", "rejected", "deleted", "ignored", "pending"];

        if (createdAtStatuses.includes(currentStatus) && currentOrder === "created_at" && priorityStatuses.includes(nextStatus) && nextOrder === "created_at") {
          nextOrder = "score";
        }

        if (priorityStatuses.includes(currentStatus) && currentOrder === "score" && createdAtStatuses.includes(nextStatus) && nextOrder === "score") {
          nextOrder = "created_at";
        }

        this.setProperties({
          type: this.filterType,
          priority: this.filterPriority,
          status: this.filterStatus,
          category_id: this.filterCategoryId,
          username: this.filterUsername,
          reviewed_by: this.filterReviewedBy,
          from_date: Ember.isPresent(this.filterFromDate) ? this.filterFromDate.toISOString(true).split("T")[0] : null,
          to_date: Ember.isPresent(this.filterToDate) ? this.filterToDate.toISOString(true).split("T")[0] : null,
          sort_order: nextOrder,
          additional_filters: JSON.stringify(this.additionalFilters)
        });
        this.refreshModel();
      },
      loadMore: function loadMore() {
        return this.reviewables.loadMore();
      },
      toggleFilters: function toggleFilters() {
        this.toggleProperty("filtersExpanded");
      },
      updateFilterReviewedBy: function updateFilterReviewedBy(selected) {
        this.set("filterReviewedBy", selected.firstObject);
      },
      updateFilterUsername: function updateFilterUsername(selected) {
        this.set("filterUsername", selected.firstObject);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "allTypes", [_dec], Object.getOwnPropertyDescriptor(_obj, "allTypes"), _obj), _applyDecoratedDescriptor(_obj, "priorities", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "priorities"), _obj), _applyDecoratedDescriptor(_obj, "sortOrders", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "sortOrders"), _obj), _applyDecoratedDescriptor(_obj, "statuses", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "statuses"), _obj), _applyDecoratedDescriptor(_obj, "toggleFiltersIcon", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleFiltersIcon"), _obj)), _obj)));

  _exports.default = _default;
});