define("discourse/templates/components/composer-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UJWlA+56",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"text-field\",null,[[\"value\",\"id\",\"maxLength\",\"placeholderKey\",\"aria-label\",\"disabled\",\"autocomplete\"],[[24,[\"composer\",\"title\"]],\"reply-title\",[24,[\"titleMaxLength\"]],[24,[\"composer\",\"titlePlaceholder\"]],[28,\"I18n\",[[24,[\"composer\",\"titlePlaceholder\"]]],null],[24,[\"disabled\"]],\"off\"]]],false],[0,\"\\n\\n\"],[1,[28,\"popup-input-tip\",null,[[\"validation\"],[[24,[\"validation\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/composer-title.hbs"
    }
  });

  _exports.default = _default;
});