define("discourse/templates/components/user-fields/multiselect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iCj4RhG0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"control-label\"],[11,\"for\",[28,\"concat\",[\"user-\",[23,0,[\"elementId\"]]],null]],[8],[0,\"\\n  \"],[1,[28,\"html-safe\",[[23,0,[\"field\",\"name\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[23,0,[\"field\",\"required\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"required\"],[8],[0,\"*\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[0,\"\\n  \"],[1,[28,\"multi-select\",null,[[\"id\",\"content\",\"valueProperty\",\"nameProperty\",\"value\",\"none\",\"onChange\"],[[28,\"concat\",[\"user-\",[23,0,[\"elementId\"]]],null],[23,0,[\"field\",\"options\"]],null,null,[23,0,[\"value\"]],[23,0,[\"noneLabel\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"value\"]]],null]],null]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"instructions\"],[8],[1,[28,\"html-safe\",[[23,0,[\"field\",\"description\"]]],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/user-fields/multiselect.hbs"
    }
  });

  _exports.default = _default;
});