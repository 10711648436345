define("discourse/templates/components/date-time-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fNCVdPI+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"timeFirst\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"date-input\",null,[[\"date\",\"placeholder\",\"relativeDate\",\"onChange\",\"useGlobalPickerContainer\"],[[24,[\"date\"]],[24,[\"placeholder\"]],[24,[\"relativeDate\"]],[28,\"action\",[[23,0,[]],\"onChangeDate\"],null],[24,[\"useGlobalPickerContainer\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showTime\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"time-input\",null,[[\"date\",\"relativeDate\",\"onChange\"],[[24,[\"date\"]],[24,[\"relativeDate\"]],[28,\"action\",[[23,0,[]],\"onChangeTime\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"timeFirst\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"date-input\",null,[[\"date\",\"placeholder\",\"relativeDate\",\"onChange\",\"useGlobalPickerContainer\"],[[24,[\"date\"]],[24,[\"placeholder\"]],[24,[\"relativeDate\"]],[28,\"action\",[[23,0,[]],\"onChangeDate\"],null],[24,[\"useGlobalPickerContainer\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"clearable\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"d-button\",null,[[\"class\",\"icon\",\"action\"],[\"btn-default clear-date-time\",\"times\",[28,\"action\",[[23,0,[]],\"onClear\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/date-time-input.hbs"
    }
  });

  _exports.default = _default;
});