define("discourse/templates/mobile/components/categories-only", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PUcSBrSj",
    "block": "{\"symbols\":[\"c\",\"c\"],\"statements\":[[4,\"if\",[[24,[\"categories\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"filteredCategories\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"category-list \",[28,\"if\",[[24,[\"showTopics\"]],\"with-topics\"],null]]]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"filteredCategories\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"parent-category-row\",null,[[\"category\",\"showTopics\"],[[23,2,[]],[24,[\"showTopics\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"mutedCategories\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"muted-categories\"],[8],[0,\"\\n      \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"muted-categories-link\"],[3,\"action\",[[23,0,[]],\"toggleShowMuted\"]],[8],[0,\"\\n        \"],[7,\"h3\",true],[10,\"class\",\"muted-categories-heading\"],[8],[1,[28,\"i18n\",[\"categories.muted\"],null],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"mutedToggleIcon\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"d-icon\",[[24,[\"mutedToggleIcon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"category-list \",[28,\"if\",[[24,[\"showTopics\"]],\"with-topics\"],null],\" \",[28,\"unless\",[[24,[\"showMutedCategories\"]],\"hidden\"],null]]]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"mutedCategories\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"parent-category-row\",null,[[\"category\",\"showTopics\",\"listType\"],[[23,1,[]],[24,[\"showTopics\"]],\"muted\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/mobile/components/categories-only.hbs"
    }
  });

  _exports.default = _default;
});