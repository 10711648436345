define("discourse/components/create-account", ["exports", "discourse/lib/cookie"], function (_exports, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    classNames: ["create-account-body"],
    userInputFocus: function userInputFocus(event) {
      var label = event.target.parentElement.previousElementSibling;

      if (!label.classList.contains("value-entered")) {
        label.classList.toggle("value-entered");
      }
    },
    userInputFocusOut: function userInputFocusOut(event) {
      var label = event.target.parentElement.previousElementSibling;

      if (event.target.value.length === 0 && label.classList.contains("value-entered")) {
        label.classList.toggle("value-entered");
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if ((0, _cookie.default)("email")) {
        this.set("email", (0, _cookie.default)("email"));
      }

      var userTextFields = document.getElementsByClassName("user-fields")[0];

      if (userTextFields) {
        userTextFields = userTextFields.getElementsByClassName("ember-text-field");
      }

      if (userTextFields) {
        var _iterator = _createForOfIteratorHelper(userTextFields),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var element = _step.value;
            element.addEventListener("focus", this.userInputFocus);
            element.addEventListener("focusout", this.userInputFocusOut);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }

      $(this.element).on("keydown.discourse-create-account", function (e) {
        if (!_this.disabled && e.key === "Enter") {
          e.preventDefault();
          e.stopPropagation();

          _this.action();

          return false;
        }
      });
      $(this.element).on("click.dropdown-user-field-label", "[for]", function (event) {
        var $element = $(event.target);
        var $target = $("#".concat($element.attr("for")));

        if ($target.is(".select-kit")) {
          event.preventDefault();
          $target.find(".select-kit-header").trigger("click");
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      $(this.element).off("keydown.discourse-create-account");
      $(this.element).off("click.dropdown-user-field-label");
      var userTextFields = document.getElementsByClassName("user-fields")[0];

      if (userTextFields) {
        userTextFields = userTextFields.getElementsByClassName("ember-text-field");
      }

      if (userTextFields) {
        var _iterator2 = _createForOfIteratorHelper(userTextFields),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var element = _step2.value;
            element.removeEventListener("focus", this.userInputFocus);
            element.removeEventListener("focusout", this.userInputFocusOut);
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
    }
  });

  _exports.default = _default;
});