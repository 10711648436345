define("discourse/templates/components/avatar-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ju61vOAM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"btn btn-default btn-icon-text\"],[11,\"disabled\",[22,\"uploading\"]],[11,\"title\",[28,\"i18n\",[\"user.change_avatar.upload_title\"],null]],[8],[0,\"\\n  \"],[1,[28,\"d-icon\",[\"far-image\"],null],false],[0,\"\\n\"],[4,\"if\",[[24,[\"uploading\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"i18n\",[\"uploading\"],null],false],[0,\" \"],[1,[22,\"uploadProgress\"],false],[0,\"%\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"i18n\",[\"upload\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"input\",true],[10,\"class\",\"hidden-upload-field\"],[11,\"disabled\",[22,\"uploading\"]],[10,\"accept\",\"image/*\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"imageIsNotASquare\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"warning\"],[8],[1,[28,\"i18n\",[\"user.change_avatar.image_is_not_a_square\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/avatar-uploader.hbs"
    }
  });

  _exports.default = _default;
});