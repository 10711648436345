define("discourse/lib/plugin-connectors", ["exports", "discourse-common/lib/raw-templates", "discourse-common/lib/deprecated"], function (_exports, _rawTemplates, _deprecated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.resetExtraClasses = resetExtraClasses;
  _exports.extraConnectorClass = extraConnectorClass;
  _exports.clearCache = clearCache;
  _exports.connectorsFor = connectorsFor;
  _exports.renderedConnectorsFor = renderedConnectorsFor;
  _exports.rawConnectorsFor = rawConnectorsFor;
  _exports.buildArgsWithDeprecations = buildArgsWithDeprecations;

  var _connectorCache;

  var _rawConnectorCache;

  var _extraConnectorClasses = {};

  var _classPaths;

  function resetExtraClasses() {
    _extraConnectorClasses = {};
    _classPaths = undefined;
  } // Note: In plugins, define a class by path and it will be wired up automatically
  // eg: discourse/connectors/<OUTLET NAME>/<CONNECTOR NAME>


  function extraConnectorClass(name, obj) {
    _extraConnectorClasses[name] = obj;
  }

  var DefaultConnectorClass = {
    actions: {},
    shouldRender: function shouldRender() {
      return true;
    },
    setupComponent: function setupComponent() {},
    teardownComponent: function teardownComponent() {}
  };

  function findOutlets(collection, callback) {
    Object.keys(collection).forEach(function (res) {
      if (res.indexOf("/connectors/") !== -1) {
        var segments = res.split("/");
        var outletName = segments[segments.length - 2];
        var uniqueName = segments[segments.length - 1];
        callback(outletName, res, uniqueName);
      }
    });
  }

  function clearCache() {
    _connectorCache = null;
    _rawConnectorCache = null;
  }

  function findClass(outletName, uniqueName) {
    if (!_classPaths) {
      _classPaths = {};
      findOutlets(require._eak_seen, function (outlet, res, un) {
        _classPaths["".concat(outlet, "/").concat(un)] = requirejs(res).default;
      });
    }

    var id = "".concat(outletName, "/").concat(uniqueName);
    var foundClass = _extraConnectorClasses[id] || _classPaths[id];
    return foundClass ? Object.assign({}, DefaultConnectorClass, foundClass) : DefaultConnectorClass;
  }

  function buildConnectorCache() {
    _connectorCache = {};
    findOutlets(Ember.TEMPLATES, function (outletName, resource, uniqueName) {
      _connectorCache[outletName] = _connectorCache[outletName] || [];

      _connectorCache[outletName].push({
        outletName: outletName,
        templateName: resource.replace("javascripts/", ""),
        template: Ember.TEMPLATES[resource],
        classNames: "".concat(outletName, "-outlet ").concat(uniqueName),
        connectorClass: findClass(outletName, uniqueName)
      });
    });
  }

  function connectorsFor(outletName) {
    if (!_connectorCache) {
      buildConnectorCache();
    }

    return _connectorCache[outletName] || [];
  }

  function renderedConnectorsFor(outletName, args, context) {
    return connectorsFor(outletName).filter(function (con) {
      return con.connectorClass.shouldRender(args, context);
    });
  }

  function rawConnectorsFor(outletName) {
    if (!_rawConnectorCache) {
      _rawConnectorCache = (0, _rawTemplates.buildRawConnectorCache)(findOutlets);
    }

    return _rawConnectorCache[outletName] || [];
  }

  function buildArgsWithDeprecations(args, deprecatedArgs) {
    var output = {};
    Object.keys(args).forEach(function (key) {
      Object.defineProperty(output, key, {
        value: args[key]
      });
    });
    Object.keys(deprecatedArgs).forEach(function (key) {
      Object.defineProperty(output, key, {
        get: function get() {
          (0, _deprecated.default)("".concat(key, " is deprecated"));
          return deprecatedArgs[key];
        }
      });
    });
    return output;
  }
});