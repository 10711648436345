define("discourse/components/invite-link-panel", ["exports", "discourse/models/group", "I18n", "discourse/models/invite", "discourse-common/utils/decorators"], function (_exports, _group, _I18n, _invite, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("isStaff", "inviteModel.saving", "maxRedemptionAllowed"), _dec2 = (0, _decorators.default)("isAdmin", "inviteModel.group_users"), _dec3 = Ember._action, (_obj = {
    inviteModel: Ember.computed.readOnly("panel.model.inviteModel"),
    userInvitedShow: Ember.computed.readOnly("panel.model.userInvitedShow"),
    isStaff: Ember.computed.readOnly("currentUser.staff"),
    isAdmin: Ember.computed.readOnly("currentUser.admin"),
    maxRedemptionAllowed: 5,
    inviteExpiresAt: moment().add(1, "month").format("YYYY-MM-DD"),
    groupIds: null,
    allGroups: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.setDefaultSelectedGroups();
      this.setGroupOptions();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.reset();
    },
    disabled: function disabled(isStaff, saving, canInviteTo, maxRedemptionAllowed) {
      if (saving) {
        return true;
      }

      if (!isStaff) {
        return true;
      }

      if (maxRedemptionAllowed < 2) {
        return true;
      }

      return false;
    },
    errorMessage: _I18n.default.t("user.invited.invite_link.error"),
    showGroups: function showGroups(isAdmin, groupUsers) {
      return isAdmin || groupUsers && groupUsers.some(function (groupUser) {
        return groupUser.owner;
      });
    },
    showApprovalMessage: Ember.computed.and("isStaff", "siteSettings.must_approve_users"),
    reset: function reset() {
      this.setProperties({
        maxRedemptionAllowed: 5,
        groupIds: []
      });
      this.inviteModel.setProperties({
        error: false,
        saving: false,
        finished: false,
        inviteLink: null
      });
    },
    generateMultipleUseInviteLink: function generateMultipleUseInviteLink() {
      var _this = this;

      if (this.disabled) {
        return;
      }

      var groupIds = this.groupIds;
      var maxRedemptionAllowed = this.maxRedemptionAllowed;
      var inviteExpiresAt = this.inviteExpiresAt;
      var userInvitedController = this.userInvitedShow;
      var model = this.inviteModel;
      model.setProperties({
        saving: true,
        error: false
      });
      return model.generateMultipleUseInviteLink(groupIds, maxRedemptionAllowed, inviteExpiresAt).then(function (result) {
        model.setProperties({
          saving: false,
          finished: true,
          inviteLink: result.link
        });

        if (userInvitedController) {
          _invite.default.findInvitedBy(_this.currentUser, userInvitedController.filter).then(function (inviteModel) {
            userInvitedController.setProperties({
              model: inviteModel,
              totalInvites: inviteModel.invites.length
            });
          });
        }
      }).catch(function (e) {
        if (e.jqXHR.responseJSON && e.jqXHR.responseJSON.errors) {
          _this.set("errorMessage", e.jqXHR.responseJSON.errors[0]);
        } else {
          _this.set("errorMessage", _I18n.default.t("user.invited.invite_link.error"));
        }

        model.setProperties({
          saving: false,
          error: true
        });
      });
    },
    setDefaultSelectedGroups: function setDefaultSelectedGroups() {
      this.set("groupIds", []);
    },
    setGroupOptions: function setGroupOptions() {
      var _this2 = this;

      _group.default.findAll().then(function (groups) {
        _this2.set("allGroups", groups.filterBy("automatic", false));
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "disabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "disabled"), _obj), _applyDecoratedDescriptor(_obj, "showGroups", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showGroups"), _obj), _applyDecoratedDescriptor(_obj, "generateMultipleUseInviteLink", [_dec3], Object.getOwnPropertyDescriptor(_obj, "generateMultipleUseInviteLink"), _obj)), _obj)));

  _exports.default = _default;
});