define("discourse/routes/preferences", ["exports", "discourse/routes/restricted-user"], function (_exports, _restrictedUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _restrictedUser.default.extend({
    model: function model() {
      return this.modelFor("user");
    }
  });

  _exports.default = _default;
});