define("discourse/controllers/group-add-members", ["exports", "discourse-common/utils/decorators", "discourse/lib/ajax-error", "discourse/lib/utilities", "discourse/mixins/modal-functionality", "I18n"], function (_exports, _decorators, _ajaxError, _utilities, _modalFunctionality, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("model.name", "model.full_name"), _dec2 = (0, _decorators.default)("usernamesAndEmails.[]"), _dec3 = (0, _decorators.default)("usernamesAndEmails.[]"), _dec4 = Ember._action, _dec5 = Ember._action, (_obj = {
    loading: false,
    usernamesAndEmails: null,
    setOwner: false,
    notifyUsers: false,
    onShow: function onShow() {
      this.setProperties({
        loading: false,
        setOwner: false,
        notifyUsers: false,
        usernamesAndEmails: []
      });
    },
    rawTitle: function rawTitle(name, fullName) {
      return _I18n.default.t("groups.add_members.title", {
        group_name: fullName || name
      });
    },
    usernames: function usernames(usernamesAndEmails) {
      return usernamesAndEmails.reject(_utilities.emailValid).join(",");
    },
    emails: function emails(usernamesAndEmails) {
      return usernamesAndEmails.filter(_utilities.emailValid).join(",");
    },
    setUsernamesAndEmails: function setUsernamesAndEmails(usernamesAndEmails) {
      this.set("usernamesAndEmails", usernamesAndEmails);

      if (this.emails) {
        if (!this.usernames) {
          this.set("notifyUsers", false);
        }

        this.set("setOwner", false);
      }
    },
    addMembers: function addMembers() {
      var _this = this;

      if (Ember.isEmpty(this.usernamesAndEmails)) {
        return;
      }

      this.set("loading", true);
      var promise = this.setOwner ? this.model.addOwners(this.usernames, true, this.notifyUsers) : this.model.addMembers(this.usernames, true, this.notifyUsers, this.emails);
      promise.then(function () {
        _this.transitionToRoute("group.members", _this.get("model.name"), {
          queryParams: _this.usernames ? {
            filter: _this.usernames
          } : {}
        });

        _this.send("closeModal");
      }).catch(function (error) {
        return _this.flash((0, _ajaxError.extractError)(error), "error");
      }).finally(function () {
        return _this.set("loading", false);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "rawTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "rawTitle"), _obj), _applyDecoratedDescriptor(_obj, "usernames", [_dec2], Object.getOwnPropertyDescriptor(_obj, "usernames"), _obj), _applyDecoratedDescriptor(_obj, "emails", [_dec3], Object.getOwnPropertyDescriptor(_obj, "emails"), _obj), _applyDecoratedDescriptor(_obj, "setUsernamesAndEmails", [_dec4], Object.getOwnPropertyDescriptor(_obj, "setUsernamesAndEmails"), _obj), _applyDecoratedDescriptor(_obj, "addMembers", [_dec5], Object.getOwnPropertyDescriptor(_obj, "addMembers"), _obj)), _obj)));

  _exports.default = _default;
});