define("discourse/lib/bookmark", ["exports", "I18n"], function (_exports, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formattedReminderTime = formattedReminderTime;

  function formattedReminderTime(reminderAt, timezone) {
    var reminderAtDate = moment.tz(reminderAt, timezone);
    var formatted = reminderAtDate.format(_I18n.default.t("dates.time"));
    var now = moment.tz(timezone);
    var tomorrow = moment(now).add(1, "day");

    if (reminderAtDate.isSame(tomorrow, "date")) {
      return _I18n.default.t("bookmarks.reminders.tomorrow_with_time", {
        time: formatted
      });
    } else if (reminderAtDate.isSame(now, "date")) {
      return _I18n.default.t("bookmarks.reminders.today_with_time", {
        time: formatted
      });
    }

    return _I18n.default.t("bookmarks.reminders.at_time", {
      date_time: reminderAtDate.format(_I18n.default.t("dates.long_with_year"))
    });
  }
});