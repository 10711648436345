define("discourse/routes/guidelines", ["exports", "discourse/lib/static-route-builder"], function (_exports, _staticRouteBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _staticRouteBuilder.default)("guidelines");

  _exports.default = _default;
});