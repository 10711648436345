define("discourse/controllers/ignore-duration", ["exports", "I18n", "discourse/mixins/modal-functionality", "discourse/lib/ajax-error"], function (_exports, _I18n, _modalFunctionality, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    loading: false,
    ignoredUntil: null,
    actions: {
      ignore: function ignore() {
        var _this = this;

        if (!this.ignoredUntil) {
          this.flash(_I18n.default.t("user.user_notifications.ignore_duration_time_frame_required"), "error");
          return;
        }

        this.set("loading", true);
        this.model.updateNotificationLevel("ignore", this.ignoredUntil).then(function () {
          _this.set("model.ignored", true);

          _this.set("model.muted", false);

          if (_this.onSuccess) {
            _this.onSuccess();
          }

          _this.send("closeModal");
        }).catch(_ajaxError.popupAjaxError).finally(function () {
          return _this.set("loading", false);
        });
      }
    }
  });

  _exports.default = _default;
});