define("discourse/templates/components/user-avatar-flair", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9EKdv/lo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"flair\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"avatar-flair\",null,[[\"flairName\",\"flairUrl\",\"flairBgColor\",\"flairColor\"],[[24,[\"flair\",\"flairName\"]],[24,[\"flair\",\"flairUrl\"]],[24,[\"flair\",\"flairBgColor\"]],[24,[\"flair\",\"flairColor\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/user-avatar-flair.hbs"
    }
  });

  _exports.default = _default;
});