define("discourse/templates/topic-post-badges", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var template = (0, _rawHandlebars.template)({
    "1": function _(container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
          alias2 = container.escapeExpression,
          lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }

        return undefined;
      };

      return "&nbsp;<a href='" + alias2(lookupProperty(helpers, "get").call(alias1, "url", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 19
          },
          "end": {
            "line": 3,
            "column": 26
          }
        }
      })) + "' class='badge badge-notification unread-posts' title='" + alias2((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || container.hooks.helperMissing).call(alias1, "topic.unread_posts", {
        "name": "i18n",
        "hash": {
          "count": "newPosts"
        },
        "hashTypes": {
          "count": "PathExpression"
        },
        "hashContexts": {
          "count": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 81
          },
          "end": {
            "line": 3,
            "column": 125
          }
        }
      })) + "'>" + alias2(lookupProperty(helpers, "get").call(alias1, "newPosts", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 127
          },
          "end": {
            "line": 3,
            "column": 139
          }
        }
      })) + "</a>";
    },
    "3": function _(container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
          alias2 = container.escapeExpression,
          lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }

        return undefined;
      };

      return "&nbsp;<a href='" + alias2(lookupProperty(helpers, "get").call(alias1, "url", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 6,
            "column": 19
          },
          "end": {
            "line": 6,
            "column": 26
          }
        }
      })) + "' class='badge badge-notification unread-posts' title='" + alias2((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || container.hooks.helperMissing).call(alias1, "topic.unread_posts", {
        "name": "i18n",
        "hash": {
          "count": "unreadPosts"
        },
        "hashTypes": {
          "count": "PathExpression"
        },
        "hashContexts": {
          "count": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 6,
            "column": 81
          },
          "end": {
            "line": 6,
            "column": 128
          }
        }
      })) + "'>" + alias2(lookupProperty(helpers, "get").call(alias1, "unreadPosts", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 6,
            "column": 130
          },
          "end": {
            "line": 6,
            "column": 145
          }
        }
      })) + "</a>";
    },
    "5": function _(container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
          alias2 = container.escapeExpression,
          lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }

        return undefined;
      };

      return "&nbsp;<a href='" + alias2(lookupProperty(helpers, "get").call(alias1, "url", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 9,
            "column": 19
          },
          "end": {
            "line": 9,
            "column": 26
          }
        }
      })) + "' class='badge badge-notification new-topic' title='" + alias2((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || container.hooks.helperMissing).call(alias1, "topic.new", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 9,
            "column": 78
          },
          "end": {
            "line": 9,
            "column": 98
          }
        }
      })) + "'>" + alias2(lookupProperty(helpers, "get").call(alias1, "newDotText", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 9,
            "column": 100
          },
          "end": {
            "line": 9,
            "column": 114
          }
        }
      })) + "</a>";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function main(container, depth0, helpers, partials, data) {
      var stack1,
          alias1 = depth0 != null ? depth0 : container.nullContext || {},
          lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }

        return undefined;
      };

      return "<span class='topic-post-badges'>" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "newPosts", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 2,
            "column": 2
          },
          "end": {
            "line": 4,
            "column": 10
          }
        }
      })) != null ? stack1 : "") + ((stack1 = lookupProperty(helpers, "if").call(alias1, "unreadPosts", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(3, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 5,
            "column": 2
          },
          "end": {
            "line": 7,
            "column": 10
          }
        }
      })) != null ? stack1 : "") + ((stack1 = lookupProperty(helpers, "if").call(alias1, "unseen", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(5, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 8,
            "column": 2
          },
          "end": {
            "line": 10,
            "column": 10
          }
        }
      })) != null ? stack1 : "") + "</span>\n";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("topic-post-badges", template, {
    core: true
  });
  var _default = template;
  _exports.default = _default;
});