define("discourse/templates/components/badge-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i1V7ELg2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"icon-or-image\",[[24,[\"badge\"]]],null],false],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"badge-display-name\"],[8],[1,[24,[\"badge\",\"name\"]],false],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/badge-button.hbs"
    }
  });

  _exports.default = _default;
});