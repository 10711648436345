define("discourse/templates/components/reviewable-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VGXSElDo",
    "block": "{\"symbols\":[\"t\"],\"statements\":[[4,\"if\",[[24,[\"tags\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"list-tags\"],[8],[0,\"\\n    \"],[4,\"each\",[[24,[\"tags\"]]],null,{\"statements\":[[0,\" \"],[1,[28,\"discourse-tag\",[[23,1,[]]],null],false],[0,\" \"]],\"parameters\":[1]},null],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-tags.hbs"
    }
  });

  _exports.default = _default;
});