define("discourse/controllers/explain-reviewable", ["exports", "discourse/mixins/modal-functionality"], function (_exports, _modalFunctionality) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    loading: null,
    reviewableExplanation: null,
    onShow: function onShow() {
      var _this = this;

      this.setProperties({
        loading: true,
        reviewableExplanation: null
      });
      this.store.find("reviewable-explanation", this.model.id).then(function (result) {
        return _this.set("reviewableExplanation", result);
      }).finally(function () {
        return _this.set("loading", false);
      });
    }
  });

  _exports.default = _default;
});