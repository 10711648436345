define("discourse/components/copy-button", ["exports", "discourse-common/lib/debounce"], function (_exports, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    tagName: "",
    copyIcon: "copy",
    copyClass: "btn-primary",
    copy: function copy() {
      var _this = this;

      var target = document.querySelector(this.selector);
      target.select();
      target.setSelectionRange(0, target.value.length);

      try {
        document.execCommand("copy");

        if (this.copied) {
          this.copied();
        }

        this.set("copyIcon", "check");
        this.set("copyClass", "btn-primary ok");
        (0, _debounce.default)(function () {
          if (_this.isDestroying || _this.isDestroyed) {
            return;
          }

          _this.set("copyIcon", "copy");

          _this.set("copyClass", "btn-primary");
        }, 3000);
      } catch (err) {}
    }
  }, (_applyDecoratedDescriptor(_obj, "copy", [_dec], Object.getOwnPropertyDescriptor(_obj, "copy"), _obj)), _obj)));

  _exports.default = _default;
});