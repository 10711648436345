define("discourse/templates/group/activity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BQLMb5FI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"user-secondary-navigation\"],[8],[0,\"\\n\"],[4,\"mobile-nav\",null,[[\"class\",\"desktopClass\"],[\"activity-nav\",\"action-list activity-list nav-stacked\"]],{\"statements\":[[4,\"if\",[[24,[\"model\",\"can_see_members\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"group-activity-filter\",null,[[\"filter\",\"categoryId\"],[\"posts\",[24,[\"category_id\"]]]]],false],[0,\"\\n      \"],[1,[28,\"group-activity-filter\",null,[[\"filter\",\"categoryId\"],[\"topics\",[24,[\"category_id\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"siteSettings\",\"enable_mentions\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"group-activity-filter\",null,[[\"filter\",\"categoryId\"],[\"mentions\",[24,[\"category_id\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"connectorTagName\"],[\"group-activity-bottom\",\"li\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"user-content\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/group/activity.hbs"
    }
  });

  _exports.default = _default;
});