define("discourse/lib/export-result", ["exports", "I18n", "bootbox"], function (_exports, _I18n, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.outputExportResult = outputExportResult;

  function outputExportResult(result) {
    if (result.success) {
      _bootbox.default.alert(_I18n.default.t("admin.export_csv.success"));
    } else {
      _bootbox.default.alert(_I18n.default.t("admin.export_csv.failed"));
    }
  }
});