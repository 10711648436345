define("discourse/widgets/liked-notification-item", ["discourse/widgets/default-notification-item", "I18n", "discourse/widgets/widget", "discourse/lib/utilities"], function (_defaultNotificationItem, _I18n, _widget, _utilities) {
  "use strict";

  (0, _widget.createWidgetFrom)(_defaultNotificationItem.DefaultNotificationItem, "liked-notification-item", {
    text: function text(notificationName, data) {
      var username = (0, _utilities.formatUsername)(data.display_username);
      var description = this.description(data);

      if (data.count > 1) {
        var count = data.count - 2;
        var username2 = (0, _utilities.formatUsername)(data.username2);

        if (count === 0) {
          return _I18n.default.t("notifications.liked_2", {
            description: description,
            username: "<span class=\"multi-username\">".concat(username, "</span>"),
            username2: "<span class=\"multi-username\">".concat(username2, "</span>")
          });
        } else {
          return _I18n.default.t("notifications.liked_many", {
            description: description,
            username: "<span class=\"multi-username\">".concat(username, "</span>"),
            username2: "<span class=\"multi-username\">".concat(username2, "</span>"),
            count: count
          });
        }
      }

      return _I18n.default.t("notifications.liked", {
        description: description,
        username: username
      });
    }
  });
});