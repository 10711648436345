define("discourse/components/plugin-connector", ["exports", "discourse-common/utils/decorators", "discourse/lib/plugin-connectors", "discourse-common/lib/deprecated"], function (_exports, _decorators2, _pluginConnectors, _deprecated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addPluginOutletDecorator = addPluginOutletDecorator;
  _exports.resetDecorators = resetDecorators;
  _exports.default = void 0;

  var _obj;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _decorators = {}; // Don't call this directly: use `plugin-api/decoratePluginOutlet`

  function addPluginOutletDecorator(outletName, callback) {
    _decorators[outletName] = _decorators[outletName] || [];

    _decorators[outletName].push(callback);
  }

  function resetDecorators() {
    _decorators = {};
  }

  var _default = Ember.Component.extend((_obj = {
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var connector = this.connector;
      this.set("layoutName", connector.templateName);
      var args = this.args || {};
      Object.keys(args).forEach(function (key) {
        Ember.defineProperty(_this, key, Ember.computed("args", function () {
          return (_this.args || {})[key];
        }));
      });
      var deprecatedArgs = this.deprecatedArgs || {};
      Object.keys(deprecatedArgs).forEach(function (key) {
        Ember.defineProperty(_this, key, Ember.computed("deprecatedArgs", function () {
          (0, _deprecated.default)("The ".concat(key, " property is deprecated, but is being used in ").concat(_this.layoutName));
          return (_this.deprecatedArgs || {})[key];
        }));
      });
      var connectorClass = this.get("connector.connectorClass");
      this.set("actions", connectorClass.actions);

      for (var _i = 0, _Object$entries = Object.entries(this.actions); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            name = _Object$entries$_i[0],
            action = _Object$entries$_i[1];

        this.set(name, action);
      }

      var merged = (0, _pluginConnectors.buildArgsWithDeprecations)(args, deprecatedArgs);
      connectorClass.setupComponent.call(this, merged, this);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._decoratePluginOutlets();
    },
    _decoratePluginOutlets: function _decoratePluginOutlets() {
      var _this2 = this;

      (_decorators[this.connector.outletName] || []).forEach(function (dec) {
        return dec(_this2.element, _this2.args);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var connectorClass = this.get("connector.connectorClass");
      connectorClass.teardownComponent.call(this, this);
    },
    send: function send(name) {
      var connectorClass = this.get("connector.connectorClass");
      var action = connectorClass.actions[name];

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return action ? action.call.apply(action, [this].concat(args)) : this._super.apply(this, [name].concat(args));
    }
  }, (_applyDecoratedDescriptor(_obj, "_decoratePluginOutlets", [_decorators2.afterRender], Object.getOwnPropertyDescriptor(_obj, "_decoratePluginOutlets"), _obj)), _obj));

  _exports.default = _default;
});