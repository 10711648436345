define("discourse/components/user-card-contents", ["exports", "discourse-common/utils/decorators", "discourse/lib/computed", "discourse/mixins/can-check-emails", "discourse/mixins/card-contents-base", "discourse/mixins/cleans-up", "I18n", "discourse/models/user", "discourse/lib/formatter", "discourse-common/lib/get-url", "discourse/lib/settings"], function (_exports, _decorators, _computed, _canCheckEmails, _cardContentsBase, _cleansUp, _I18n, _user, _formatter, _getUrl, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _obj, _init, _init2, _init3, _init4;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_cardContentsBase.default, _canCheckEmails.default, _cleansUp.default, (_dec = (0, _decorators.default)("user"), _dec2 = (0, _decorators.default)("user.staff"), _dec3 = (0, _decorators.default)("user.trust_level"), _dec4 = (0, _decorators.default)("user.name"), _dec5 = (0, _decorators.default)("user"), _dec6 = (0, _decorators.default)("userTimezone"), _dec7 = (0, _decorators.default)("username"), _dec8 = (0, _decorators.default)("username", "topicPostCount"), _dec9 = (0, _decorators.default)("user.user_fields.@each.value"), _dec10 = (0, _decorators.default)("user.trust_level"), _dec11 = (0, _decorators.default)("user.badge_count", "user.featured_user_badges.length"), _dec12 = (0, _decorators.default)("user.time_read", "user.recent_time_read"), _dec13 = (0, _decorators.default)("user.recent_time_read"), _dec14 = (0, _decorators.default)("showRecentTimeRead", "user.time_read", "recentTimeRead"), _dec15 = (0, _decorators.observes)("user.card_background_upload_url"), _dec16 = (0, _decorators.default)("user.primary_group_name"), (_obj = {
    elementId: "user-card",
    classNames: "user-card",
    avatarSelector: "[data-user-card]",
    avatarDataAttrKey: "userCard",
    mentionSelector: "a.mention",
    classNameBindings: ["visible:show", "showBadges", "user.card_background_upload_url::no-bg", "isFixed:fixed", "usernameClass", "primaryGroup"],
    allowBackgrounds: (0, _computed.setting)("allow_profile_backgrounds"),
    showBadges: (0, _computed.setting)("enable_badges"),
    postStream: Ember.computed.alias("topic.postStream"),
    enoughPostsForFiltering: Ember.computed.gte("topicPostCount", 2),
    showFilter: Ember.computed.and("viewingTopic", "postStream.hasNoFilters", "enoughPostsForFiltering"),
    showName: (0, _computed.propertyNotEqual)("user.name", "user.username"),
    hasUserFilters: Ember.computed.gt("postStream.userFilters.length", 0),
    showMoreBadges: Ember.computed.gt("moreBadgesCount", 0),
    showDelete: Ember.computed.and("viewingAdmin", "showName", "user.canBeDeleted"),
    linkWebsite: Ember.computed.not("user.isBasic"),
    hasLocaleOrWebsite: function hasLocaleOrWebsite(user) {
      return user.location || user.website_name || this.userTimezone;
    },
    isSuspendedOrHasBio: Ember.computed.or("user.suspend_reason", "user.bio_excerpt"),
    showCheckEmail: Ember.computed.and("user.staged", "canCheckEmails"),
    user: null,
    // If inside a topic
    topicPostCount: null,
    showFeaturedTopic: Ember.computed.and("user.featured_topic", "siteSettings.allow_featured_topic_on_user_profiles"),
    showUserLocalTime: (0, _computed.setting)("display_local_time_in_user_card"),
    staff: function staff(isStaff) {
      return isStaff ? "staff" : "";
    },
    newUser: function newUser(trustLevel) {
      return trustLevel === 0 ? "new-user" : "";
    },
    nameFirst: function nameFirst(name) {
      return (0, _settings.prioritizeNameInUx)(name);
    },
    userTimezone: function userTimezone(user) {
      if (!this.showUserLocalTime) {
        return;
      }

      return user.resolvedTimezone(this.currentUser);
    },
    formattedUserLocalTime: function formattedUserLocalTime(timezone) {
      return moment.tz(timezone).format(_I18n.default.t("dates.time"));
    },
    usernameClass: function usernameClass(username) {
      return username ? "user-card-".concat(username) : "";
    },
    filterPostsLabel: function filterPostsLabel(username, count) {
      return _I18n.default.t("topic.filter_to", {
        username: username,
        count: count
      });
    },
    publicUserFields: function publicUserFields() {
      var siteUserFields = this.site.get("user_fields");

      if (!Ember.isEmpty(siteUserFields)) {
        var userFields = this.get("user.user_fields");
        return siteUserFields.filterBy("show_on_user_card", true).sortBy("position").map(function (field) {
          Ember.set(field, "dasherized_name", field.get("name").dasherize());
          var value = userFields ? userFields[field.get("id")] : null;
          return Ember.isEmpty(value) ? null : Ember.Object.create({
            value: value,
            field: field
          });
        }).compact();
      }
    },
    removeNoFollow: function removeNoFollow(trustLevel) {
      return trustLevel > 2 && !this.siteSettings.tl3_links_no_follow;
    },
    moreBadgesCount: function moreBadgesCount(badgeCount, badgeLength) {
      return badgeCount - badgeLength;
    },
    showRecentTimeRead: function showRecentTimeRead(timeRead, recentTimeRead) {
      return timeRead !== recentTimeRead && recentTimeRead !== 0;
    },
    recentTimeRead: function recentTimeRead(recentTimeReadSeconds) {
      return (0, _formatter.durationTiny)(recentTimeReadSeconds);
    },
    timeReadTooltip: function timeReadTooltip(showRecent, timeRead, recentTimeRead) {
      if (showRecent) {
        return _I18n.default.t("time_read_recently_tooltip", {
          time_read: (0, _formatter.durationTiny)(timeRead),
          recent_time_read: recentTimeRead
        });
      } else {
        return _I18n.default.t("time_read_tooltip", {
          time_read: (0, _formatter.durationTiny)(timeRead)
        });
      }
    },
    addBackground: function addBackground() {
      if (!this.allowBackgrounds) {
        return;
      }

      var thisElem = this.element;

      if (!thisElem) {
        return;
      }

      var url = this.get("user.card_background_upload_url");
      var bg = Ember.isEmpty(url) ? "" : "url(".concat((0, _getUrl.getURLWithCDN)(url), ")");
      thisElem.style.backgroundImage = bg;
    },
    primaryGroup: function primaryGroup(_primaryGroup) {
      return "group-".concat(_primaryGroup);
    },
    _showCallback: function _showCallback(username, $target) {
      var _this = this;

      this._positionCard($target);

      this.setProperties({
        visible: true,
        loading: true
      });
      var args = {
        forCard: true,
        include_post_count_for: this.get("topic.id")
      };
      return _user.default.findByUsername(username, args).then(function (user) {
        if (user.topic_post_count) {
          _this.set("topicPostCount", user.topic_post_count[args.include_post_count_for]);
        }

        _this.setProperties({
          user: user
        });

        return user;
      }).catch(function () {
        return _this._close();
      }).finally(function () {
        return _this.set("loading", null);
      });
    },
    _close: function _close() {
      this.setProperties({
        user: null,
        topicPostCount: null
      });

      this._super.apply(this, arguments);
    },
    cleanUp: function cleanUp() {
      this._close();
    },
    actions: {
      close: function close() {
        this._close();
      },
      composePM: function composePM(user, post) {
        this._close();

        this.composePrivateMessage(user, post);
      },
      cancelFilter: function cancelFilter() {
        var postStream = this.postStream;
        postStream.cancelFilter();
        postStream.refresh();

        this._close();
      },
      filterPosts: function filterPosts() {
        this.filterPosts(this.user);

        this._close();
      },
      deleteUser: function deleteUser() {
        this.user.delete();

        this._close();
      },
      showUser: function showUser(username) {
        this.showUser(username);

        this._close();
      },
      checkEmail: function checkEmail(user) {
        user.checkEmail();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "hasLocaleOrWebsite", [_dec], Object.getOwnPropertyDescriptor(_obj, "hasLocaleOrWebsite"), _obj), _applyDecoratedDescriptor(_obj, "staff", [_dec2], (_init = Object.getOwnPropertyDescriptor(_obj, "staff"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "newUser", [_dec3], (_init2 = Object.getOwnPropertyDescriptor(_obj, "newUser"), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "nameFirst", [_dec4], Object.getOwnPropertyDescriptor(_obj, "nameFirst"), _obj), _applyDecoratedDescriptor(_obj, "userTimezone", [_dec5], Object.getOwnPropertyDescriptor(_obj, "userTimezone"), _obj), _applyDecoratedDescriptor(_obj, "formattedUserLocalTime", [_dec6], Object.getOwnPropertyDescriptor(_obj, "formattedUserLocalTime"), _obj), _applyDecoratedDescriptor(_obj, "usernameClass", [_dec7], (_init3 = Object.getOwnPropertyDescriptor(_obj, "usernameClass"), _init3 = _init3 ? _init3.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init3;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "filterPostsLabel", [_dec8], Object.getOwnPropertyDescriptor(_obj, "filterPostsLabel"), _obj), _applyDecoratedDescriptor(_obj, "publicUserFields", [_dec9], Object.getOwnPropertyDescriptor(_obj, "publicUserFields"), _obj), _applyDecoratedDescriptor(_obj, "removeNoFollow", [_dec10], Object.getOwnPropertyDescriptor(_obj, "removeNoFollow"), _obj), _applyDecoratedDescriptor(_obj, "moreBadgesCount", [_dec11], (_init4 = Object.getOwnPropertyDescriptor(_obj, "moreBadgesCount"), _init4 = _init4 ? _init4.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init4;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "showRecentTimeRead", [_dec12], Object.getOwnPropertyDescriptor(_obj, "showRecentTimeRead"), _obj), _applyDecoratedDescriptor(_obj, "recentTimeRead", [_dec13], Object.getOwnPropertyDescriptor(_obj, "recentTimeRead"), _obj), _applyDecoratedDescriptor(_obj, "timeReadTooltip", [_dec14], Object.getOwnPropertyDescriptor(_obj, "timeReadTooltip"), _obj), _applyDecoratedDescriptor(_obj, "addBackground", [_dec15], Object.getOwnPropertyDescriptor(_obj, "addBackground"), _obj), _applyDecoratedDescriptor(_obj, "primaryGroup", [_dec16], Object.getOwnPropertyDescriptor(_obj, "primaryGroup"), _obj)), _obj)));

  _exports.default = _default;
});