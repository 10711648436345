define("discourse/components/highlight-text", ["exports", "discourse-common/lib/deprecated", "discourse/components/highlight-search"], function (_exports, _deprecated, _highlightSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _highlightSearch.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      (0, _deprecated.default)("`highlight-text` component is deprecated,  use the `highlight-search` instead.");
    }
  });

  _exports.default = _default;
});