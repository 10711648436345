define("discourse/components/security-key-form", ["exports", "discourse/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      useAnotherMethod: function useAnotherMethod() {
        this.set("showSecurityKey", false);
        this.set("showSecondFactor", true);
        this.set("secondFactorMethod", _user.SECOND_FACTOR_METHODS.TOTP);
      }
    }
  });

  _exports.default = _default;
});