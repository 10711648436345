define("discourse/templates/components/user-summary-topic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lVDQnVf8",
    "block": "{\"symbols\":[\"@likes\",\"@createdAt\",\"@url\",\"@topic\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"topic-info\"],[8],[0,\"\\n  \"],[1,[28,\"format-date\",[[23,2,[]]],[[\"format\",\"noTitle\"],[\"tiny\",\"true\"]]],false],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    ·\\n    \"],[1,[28,\"d-icon\",[\"heart\"],null],false],[0,\" \"],[7,\"span\",true],[10,\"class\",\"like-count\"],[8],[1,[28,\"number\",[[23,1,[]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[7,\"a\",true],[11,\"href\",[23,3,[]]],[8],[1,[28,\"html-safe\",[[23,4,[\"fancyTitle\"]]],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/user-summary-topic.hbs"
    }
  });

  _exports.default = _default;
});