define("discourse/helpers/directory-item-helpers", ["discourse/lib/formatter", "discourse-common/lib/helpers", "I18n"], function (_formatter, _helpers, _I18n) {
  "use strict";

  (0, _helpers.registerUnbound)("mobile-directory-item-label", function (args) {
    // Args should include key/values { item, column }
    var count = args.item.get(args.column.name);
    var translationPrefix = args.column.type === "automatic" ? "directory." : "";
    return Ember.String.htmlSafe(_I18n.default.t("".concat(translationPrefix).concat(args.column.name), {
      count: count
    }));
  });
  (0, _helpers.registerUnbound)("directory-item-value", function (args) {
    // Args should include key/values { item, column }
    return Ember.String.htmlSafe("<span class='number'>".concat((0, _formatter.number)(args.item.get(args.column.name)), "</span>"));
  });
  (0, _helpers.registerUnbound)("directory-item-user-field-value", function (args) {
    // Args should include key/values { item, column }
    var value = args.item.user && args.item.user.user_fields ? args.item.user.user_fields[args.column.user_field_id] : null;
    var content = value || "-";
    return Ember.String.htmlSafe("<span class='user-field-value'>".concat(content, "</span>"));
  });
  (0, _helpers.registerUnbound)("directory-column-is-automatic", function (args) {
    // Args should include key/values { column }
    return args.column.type === "automatic";
  });
  (0, _helpers.registerUnbound)("directory-column-is-user-field", function (args) {
    // Args should include key/values { column }
    return args.column.type === "user_field";
  });
});