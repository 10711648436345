define("discourse/routes/group-activity-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var group = this.modelFor("group");

      if (group.can_see_members) {
        this.transitionTo("group.activity.posts");
      } else {
        this.transitionTo("group.activity.mentions");
      }
    }
  });

  _exports.default = _default;
});