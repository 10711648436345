define("discourse/components/bulk-select-all", ["exports", "discourse/components/d-button"], function (_exports, _dButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dButton.default.extend({
    click: function click() {
      $("input.bulk-select:not(checked)").click();
    }
  });

  _exports.default = _default;
});