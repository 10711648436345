define("discourse/helpers/topic-status-icons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.ArrayProxy.extend({
    render: function render(topic, renderIcon) {
      var renderIconIf = function renderIconIf(conditionProp, name, key) {
        if (!topic.get(conditionProp)) {
          return;
        }

        renderIcon(name, key);
      };

      if (topic.get("closed") && topic.get("archived")) {
        renderIcon("lock", "locked_and_archived");
      } else {
        renderIconIf("closed", "lock", "locked");
        renderIconIf("archived", "lock", "archived");
      }

      this.forEach(function (args) {
        return renderIconIf.apply(void 0, _toConsumableArray(args));
      });
    }
  }).create({
    content: [["is_warning", "envelope", "warning"], ["pinned", "thumbtack", "pinned"], ["unpinned", "thumbtack", "unpinned"], ["invisible", "far-eye-slash", "unlisted"]]
  });

  _exports.default = _default;
});