define("discourse/controllers/second-factor-backup-edit", ["exports", "I18n", "discourse/mixins/modal-functionality", "discourse/models/user"], function (_exports, _I18n, _modalFunctionality, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    loading: false,
    errorMessage: null,
    successMessage: null,
    backupEnabled: Ember.computed.alias("model.second_factor_backup_enabled"),
    remainingCodes: Ember.computed.alias("model.second_factor_remaining_backup_codes"),
    backupCodes: null,
    secondFactorMethod: _user.SECOND_FACTOR_METHODS.TOTP,
    onShow: function onShow() {
      this.setProperties({
        loading: false,
        errorMessage: null,
        successMessage: null,
        backupCodes: null
      });
    },
    actions: {
      copyBackupCode: function copyBackupCode(successful) {
        if (successful) {
          this.set("successMessage", _I18n.default.t("user.second_factor_backup.copied_to_clipboard"));
        } else {
          this.set("errorMessage", _I18n.default.t("user.second_factor_backup.copy_to_clipboard_error"));
        }

        this._hideCopyMessage();
      },
      disableSecondFactorBackup: function disableSecondFactorBackup() {
        var _this = this;

        this.set("backupCodes", []);
        this.set("loading", true);
        this.model.updateSecondFactor(0, "", true, _user.SECOND_FACTOR_METHODS.BACKUP_CODE).then(function (response) {
          if (response.error) {
            _this.set("errorMessage", response.error);

            return;
          }

          _this.set("errorMessage", null);

          _this.model.set("second_factor_backup_enabled", false);

          _this.markDirty();

          _this.send("closeModal");
        }).catch(function (error) {
          _this.send("closeModal");

          _this.onError(error);
        }).finally(function () {
          return _this.set("loading", false);
        });
      },
      generateSecondFactorCodes: function generateSecondFactorCodes() {
        var _this2 = this;

        this.set("loading", true);
        this.model.generateSecondFactorCodes().then(function (response) {
          if (response.error) {
            _this2.set("errorMessage", response.error);

            return;
          }

          _this2.markDirty();

          _this2.setProperties({
            errorMessage: null,
            backupCodes: response.backup_codes,
            backupEnabled: true,
            remainingCodes: response.backup_codes.length
          });
        }).catch(function (error) {
          _this2.send("closeModal");

          _this2.onError(error);
        }).finally(function () {
          _this2.setProperties({
            loading: false
          });
        });
      }
    },
    _hideCopyMessage: function _hideCopyMessage() {
      var _this3 = this;

      Ember.run.later(function () {
        return _this3.setProperties({
          successMessage: null,
          errorMessage: null
        });
      }, 2000);
    }
  });

  _exports.default = _default;
});