define("discourse/controllers/exception", ["exports", "discourse-common/utils/decorators", "discourse/lib/url", "I18n"], function (_exports, _decorators, _url, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var ButtonBackBright = {
    classes: "btn-primary",
    action: "back",
    key: "errors.buttons.back"
  },
      ButtonBackDim = {
    classes: "",
    action: "back",
    key: "errors.buttons.back"
  },
      ButtonTryAgain = {
    classes: "btn-primary",
    action: "tryLoading",
    key: "errors.buttons.again",
    icon: "sync"
  },
      ButtonLoadPage = {
    classes: "btn-primary",
    action: "tryLoading",
    key: "errors.buttons.fixed"
  }; // The controller for the nice error page

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("thrown"), _dec2 = (0, _decorators.on)("init"), _dec3 = (0, _decorators.default)("isNetwork", "thrown.status", "thrown"), _dec4 = (0, _decorators.default)("networkFixed", "isNetwork", "thrown.status", "thrown.statusText", "thrown"), _dec5 = (0, _decorators.default)("networkFixed", "isNetwork", "lastTransition"), (_obj = {
    thrown: null,
    lastTransition: null,
    isNetwork: function isNetwork(thrown) {
      // never made it on the wire
      if (thrown && thrown.readyState === 0) {
        return true;
      } // timed out


      if (thrown && thrown.jqTextStatus === "timeout") {
        return true;
      }

      return false;
    },
    isNotFound: Ember.computed.equal("thrown.status", 404),
    isForbidden: Ember.computed.equal("thrown.status", 403),
    isServer: Ember.computed.gte("thrown.status", 500),
    isUnknown: Ember.computed.none("isNetwork", "isServer"),
    // Handling for the detailed_404 setting (which actually creates 403s)
    errorHtml: Ember.computed.alias("thrown.responseJSON.extras.html"),
    // TODO
    // make ajax requests to /srv/status with exponential backoff
    // if one succeeds, set networkFixed to true, which puts a "Fixed!" message on the page
    networkFixed: false,
    loading: false,
    _init: function _init() {
      this.set("loading", false);
    },
    reason: function reason(isNetwork, thrownStatus, thrown) {
      if (isNetwork) {
        return _I18n.default.t("errors.reasons.network");
      } else if (thrownStatus >= 500) {
        return _I18n.default.t("errors.reasons.server");
      } else if (thrownStatus === 404) {
        return _I18n.default.t("errors.reasons.not_found");
      } else if (thrownStatus === 403) {
        return _I18n.default.t("errors.reasons.forbidden");
      } else if (thrown === null) {
        return _I18n.default.t("errors.reasons.unknown");
      } else {
        // TODO
        return _I18n.default.t("errors.reasons.unknown");
      }
    },
    requestUrl: Ember.computed.alias("thrown.requestedUrl"),
    desc: function desc(networkFixed, isNetwork, thrownStatus, thrownStatusText, thrown) {
      if (networkFixed) {
        return _I18n.default.t("errors.desc.network_fixed");
      } else if (isNetwork) {
        return _I18n.default.t("errors.desc.network");
      } else if (thrownStatus === 404) {
        return _I18n.default.t("errors.desc.not_found");
      } else if (thrownStatus === 403) {
        return _I18n.default.t("errors.desc.forbidden");
      } else if (thrownStatus >= 500) {
        return _I18n.default.t("errors.desc.server", {
          status: thrownStatus + " " + thrownStatusText
        });
      } else if (thrown === null) {
        return _I18n.default.t("errors.desc.unknown");
      } else {
        // TODO
        return _I18n.default.t("errors.desc.unknown");
      }
    },
    enabledButtons: function enabledButtons(networkFixed, isNetwork, lastTransition) {
      if (networkFixed) {
        return [ButtonLoadPage];
      } else if (isNetwork) {
        return [ButtonBackDim, ButtonTryAgain];
      } else if (!lastTransition) {
        return [ButtonBackBright];
      } else {
        return [ButtonBackBright, ButtonTryAgain];
      }
    },
    actions: {
      back: function back() {
        // Strip off subfolder
        var currentURL = _url.default.router.location.getURL();

        if (this.lastTransition && currentURL !== "/exception") {
          this.lastTransition.abort();
          this.setProperties({
            lastTransition: null,
            thrown: null
          }); // Can't use routeTo because it handles navigation to the same page

          _url.default.handleURL(currentURL);
        } else {
          window.history.back();
        }
      },
      tryLoading: function tryLoading() {
        var _this = this;

        this.set("loading", true);
        Ember.run.schedule("afterRender", function () {
          var transition = _this.lastTransition;

          _this.setProperties({
            lastTransition: null,
            thrown: null
          });

          transition.retry();

          _this.set("loading", false);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "isNetwork", [_dec], Object.getOwnPropertyDescriptor(_obj, "isNetwork"), _obj), _applyDecoratedDescriptor(_obj, "_init", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_init"), _obj), _applyDecoratedDescriptor(_obj, "reason", [_dec3], Object.getOwnPropertyDescriptor(_obj, "reason"), _obj), _applyDecoratedDescriptor(_obj, "desc", [_dec4], Object.getOwnPropertyDescriptor(_obj, "desc"), _obj), _applyDecoratedDescriptor(_obj, "enabledButtons", [_dec5], Object.getOwnPropertyDescriptor(_obj, "enabledButtons"), _obj)), _obj)));

  _exports.default = _default;
});