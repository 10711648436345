define("discourse/controllers/feature-topic-on-profile", ["exports", "discourse/mixins/modal-functionality", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _modalFunctionality, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    newFeaturedTopic: null,
    saving: false,
    noTopicSelected: Ember.computed.none("newFeaturedTopic"),
    onClose: function onClose() {
      this.set("newFeaturedTopic", null);
    },
    onShow: function onShow() {
      this.set("modal.modalClass", "choose-topic-modal");
    },
    actions: {
      save: function save() {
        var _this = this;

        return (0, _ajax.ajax)("/u/".concat(this.model.username, "/feature-topic"), {
          type: "PUT",
          data: {
            topic_id: this.newFeaturedTopic.id
          }
        }).then(function () {
          _this.model.set("featured_topic", _this.newFeaturedTopic);

          _this.send("closeModal");
        }).catch(_ajaxError.popupAjaxError);
      },
      newTopicSelected: function newTopicSelected(topic) {
        this.set("newFeaturedTopic", topic);
      }
    }
  });

  _exports.default = _default;
});