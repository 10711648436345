define("discourse/components/shared-draft-controls", ["exports", "I18n", "bootbox", "discourse-common/utils/decorators"], function (_exports, _I18n, _bootbox, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("topic.destination_category_id"), (_obj = {
    tagName: "",
    publishing: false,
    validCategory: function validCategory(destCatId) {
      return destCatId && destCatId !== this.site.shared_drafts_category_id;
    },
    actions: {
      updateDestinationCategory: function updateDestinationCategory(categoryId) {
        return this.topic.updateDestinationCategory(categoryId);
      },
      publish: function publish() {
        var _this = this;

        _bootbox.default.confirm(_I18n.default.t("shared_drafts.confirm_publish"), function (result) {
          if (result) {
            _this.set("publishing", true);

            var destinationCategoryId = _this.topic.destination_category_id;

            _this.topic.publish().then(function () {
              _this.topic.setProperties({
                category_id: destinationCategoryId,
                destination_category_id: undefined,
                is_shared_draft: false
              });
            }).finally(function () {
              _this.set("publishing", false);
            });
          }
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "validCategory", [_dec], Object.getOwnPropertyDescriptor(_obj, "validCategory"), _obj)), _obj)));

  _exports.default = _default;
});