define("discourse/lib/pwa-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nativeShare = nativeShare;
  _exports.getNativeContact = getNativeContact;

  function nativeShare(caps, data) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      if (!(caps.isIOS || caps.isAndroid || caps.isWinphone)) {
        reject();
        return;
      }

      if (window.location.protocol === "https:" && typeof window.navigator.share !== "undefined") {
        window.navigator.share(data).then(resolve).catch(function (e) {
          if (e.name === "AbortError") {// closing share panel do nothing
          } else {
            reject();
          }
        });
      } else {
        reject();
      }
    });
  }

  function getNativeContact(caps, properties, multiple) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      if (!caps.hasContactPicker) {
        return reject();
      }

      navigator.contacts.select(properties, {
        multiple: multiple
      }).then(resolve).catch(reject);
    });
  }
});