define("discourse/routes/badges-show", ["exports", "discourse/models/badge", "discourse/routes/discourse", "discourse/lib/preload-store", "discourse/models/user-badge", "discourse/mixins/scroll-top"], function (_exports, _badge, _discourse, _preloadStore, _userBadge, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _discourse.default.extend((_dec = Ember._action, (_obj = {
    queryParams: {
      username: {
        refreshModel: true
      }
    },
    serialize: function serialize(model) {
      return model.getProperties("id", "slug");
    },
    model: function model(params) {
      if (_preloadStore.default.get("badge")) {
        return _preloadStore.default.getAndRemove("badge").then(function (json) {
          return _badge.default.createFromJson(json);
        });
      } else {
        return _badge.default.findById(params.id);
      }
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      var usernameFromParams = transition.to.queryParams && transition.to.queryParams.username;

      var userBadgesGrant = _userBadge.default.findByBadgeId(model.get("id"), {
        username: usernameFromParams
      }).then(function (userBadges) {
        _this.userBadgesGrant = userBadges;
      });

      var username = this.currentUser && this.currentUser.username_lower;

      var userBadgesAll = _userBadge.default.findByUsername(username).then(function (userBadges) {
        _this.userBadgesAll = userBadges;
      });

      var promises = {
        userBadgesGrant: userBadgesGrant,
        userBadgesAll: userBadgesAll
      };
      return Ember.RSVP.hash(promises);
    },
    titleToken: function titleToken() {
      var model = this.modelFor("badges.show");

      if (model) {
        return model.get("name");
      }
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model);
      controller.set("userBadges", this.userBadgesGrant);
      controller.set("userBadgesAll", this.userBadgesAll);
    },
    didTransition: function didTransition() {
      this.controllerFor("badges/show")._showFooter();

      (0, _scrollTop.scrollTop)();
      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, "didTransition", [_dec], Object.getOwnPropertyDescriptor(_obj, "didTransition"), _obj)), _obj)));

  _exports.default = _default;
});