define("discourse/templates/hide-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D0Ngm9TK",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"hide-modal-trigger\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/hide-modal.hbs"
    }
  });

  _exports.default = _default;
});