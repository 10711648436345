define("discourse/routes/edit-category-tabs", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    model: function model() {
      return this.modelFor("editCategory");
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      var parentParams = this.paramsFor("editCategory");
      controller.setProperties({
        parentParams: parentParams,
        selectedTab: transition.to.params.tab,
        showTooltip: false
      });
    }
  });

  _exports.default = _default;
});