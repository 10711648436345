define("discourse/widgets/header", ["exports", "discourse/lib/url", "I18n", "discourse/helpers/user-avatar", "discourse/lib/ajax", "discourse/widgets/post", "discourse/widgets/widget", "discourse-common/lib/get-url", "virtual-dom", "discourse-common/lib/icon-library", "discourse/mixins/scroll-top", "discourse/lib/intercept-click"], function (_exports, _url, _I18n, _userAvatar, _ajax, _post, _widget, _getUrl, _virtualDom, _iconLibrary, _scrollTop, _interceptClick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addToHeaderIcons = addToHeaderIcons;
  _exports.attachAdditionalPanel = attachAdditionalPanel;
  _exports.default = void 0;
  var _extraHeaderIcons = [];

  function addToHeaderIcons(icon) {
    _extraHeaderIcons.push(icon);
  }

  var dropdown = {
    buildClasses: function buildClasses(attrs) {
      var classes = attrs.classNames || [];

      if (attrs.active) {
        classes.push("active");
      }

      return classes;
    },
    click: function click(e) {
      if ((0, _interceptClick.wantsNewWindow)(e)) {
        return;
      }

      e.preventDefault();

      if (!this.attrs.active) {
        this.sendWidgetAction(this.attrs.action);
      }
    }
  };
  (0, _widget.createWidget)("header-notifications", {
    settings: {
      avatarSize: "medium"
    },
    html: function html(attrs) {
      var user = attrs.user;
      var avatarAttrs = {
        template: user.get("avatar_template"),
        username: user.get("username")
      };

      if (this.siteSettings.enable_names) {
        avatarAttrs.name = user.get("name");
      }

      var contents = [(0, _post.avatarImg)(this.settings.avatarSize, Object.assign({
        alt: "user.avatar.header_title"
      }, (0, _userAvatar.addExtraUserClasses)(user, avatarAttrs)))];

      if (user.isInDoNotDisturb()) {
        contents.push((0, _virtualDom.h)("div.do-not-disturb-background", (0, _iconLibrary.iconNode)("moon")));
      } else {
        var unreadNotifications = user.get("unread_notifications");

        if (!!unreadNotifications) {
          contents.push(this.attach("link", {
            action: attrs.action,
            className: "badge-notification unread-notifications",
            rawLabel: unreadNotifications,
            omitSpan: true,
            title: "notifications.tooltip.regular",
            titleOptions: {
              count: unreadNotifications
            }
          }));
        }

        var unreadHighPriority = user.get("unread_high_priority_notifications");

        if (!!unreadHighPriority) {
          // highlight the avatar if the first ever PM is not read
          if (!user.get("read_first_notification") && !user.get("enforcedSecondFactor")) {
            if (!attrs.active && attrs.ringBackdrop) {
              contents.push((0, _virtualDom.h)("span.ring"));
              contents.push((0, _virtualDom.h)("span.ring-backdrop-spotlight"));
              contents.push((0, _virtualDom.h)("span.ring-backdrop", {}, (0, _virtualDom.h)("h1.ring-first-notification", {}, [(0, _virtualDom.h)("span", {
                className: "first-notification"
              }, _I18n.default.t("user.first_notification")), (0, _virtualDom.h)("span", {
                className: "read-later"
              }, [this.attach("link", {
                action: "readLater",
                className: "read-later-link",
                label: "user.skip_new_user_tips.read_later"
              })]), (0, _virtualDom.h)("span", {}, [_I18n.default.t("user.skip_new_user_tips.not_first_time"), " ", this.attach("link", {
                action: "skipNewUserTips",
                className: "skip-new-user-tips",
                label: "user.skip_new_user_tips.skip_link",
                title: "user.skip_new_user_tips.description"
              })])])));
            }
          } // add the counter for the unread high priority


          contents.push(this.attach("link", {
            action: attrs.action,
            className: "badge-notification unread-high-priority-notifications",
            rawLabel: unreadHighPriority,
            omitSpan: true,
            title: "notifications.tooltip.high_priority",
            titleOptions: {
              count: unreadHighPriority
            }
          }));
        }
      }

      return contents;
    }
  });
  (0, _widget.createWidget)("user-dropdown", Object.assign({
    tagName: "li.header-dropdown-toggle.current-user",
    buildId: function buildId() {
      return "current-user";
    },
    html: function html(attrs) {
      return (0, _virtualDom.h)("a.icon", {
        attributes: {
          "aria-haspopup": true,
          "aria-expanded": attrs.active,
          href: attrs.user.path,
          title: attrs.user.name || attrs.user.username,
          "data-auto-route": true
        }
      }, this.attach("header-notifications", attrs));
    }
  }, dropdown));
  (0, _widget.createWidget)("header-dropdown", Object.assign({
    tagName: "li.header-dropdown-toggle",
    html: function html(attrs) {
      var title = _I18n.default.t(attrs.title);

      var body = [(0, _iconLibrary.iconNode)(attrs.icon)];

      if (attrs.contents) {
        body.push(attrs.contents.call(this));
      }

      return (0, _virtualDom.h)("a.icon.btn-flat", {
        attributes: {
          "aria-expanded": attrs.active,
          "aria-haspopup": true,
          href: attrs.href,
          "data-auto-route": true,
          title: title,
          "aria-label": title,
          id: attrs.iconId
        }
      }, body);
    }
  }, dropdown));
  (0, _widget.createWidget)("header-icons", {
    tagName: "ul.icons.d-header-icons",
    html: function html(attrs) {
      var _this = this;

      if (this.siteSettings.login_required && !this.currentUser) {
        return [];
      }

      var icons = [];

      if (_extraHeaderIcons) {
        _extraHeaderIcons.forEach(function (icon) {
          icons.push(_this.attach(icon));
        });
      }

      var search = this.attach("header-dropdown", {
        title: "search.title",
        icon: "search",
        iconId: "search-button",
        action: "toggleSearchMenu",
        active: attrs.searchVisible,
        href: (0, _getUrl.default)("/search"),
        classNames: ["search-dropdown"]
      });
      icons.push(search);
      var hamburger = this.attach("header-dropdown", {
        title: "hamburger_menu",
        icon: "bars",
        iconId: "toggle-hamburger-menu",
        active: attrs.hamburgerVisible,
        action: "toggleHamburger",
        href: "",
        classNames: ["hamburger-dropdown"],
        contents: function contents() {
          var currentUser = this.currentUser;

          if (currentUser && currentUser.reviewable_count) {
            return (0, _virtualDom.h)("div.badge-notification.reviewables", {
              attributes: {
                title: _I18n.default.t("notifications.reviewable_items")
              }
            }, this.currentUser.reviewable_count);
          }
        }
      });
      icons.push(hamburger);

      if (attrs.user) {
        icons.push(this.attach("user-dropdown", {
          active: attrs.userVisible,
          action: "toggleUserMenu",
          ringBackdrop: attrs.ringBackdrop,
          user: attrs.user
        }));
      }

      return icons;
    }
  });
  (0, _widget.createWidget)("header-buttons", {
    tagName: "span.header-buttons",
    html: function html(attrs) {
      if (this.currentUser) {
        return;
      }

      var buttons = [];

      if (attrs.canSignUp && !attrs.topic) {
        buttons.push(this.attach("button", {
          label: "sign_up",
          className: "btn-primary btn-small sign-up-button",
          action: "showCreateAccount"
        }));
      }

      buttons.push(this.attach("button", {
        label: "log_in",
        className: "btn-primary btn-small login-button",
        action: "showLogin",
        icon: "user"
      }));
      return buttons;
    }
  });
  (0, _widget.createWidget)("header-cloak", {
    tagName: "div.header-cloak",
    html: function html() {
      return "";
    },
    click: function click() {},
    scheduleRerender: function scheduleRerender() {}
  });
  var additionalPanels = [];

  function attachAdditionalPanel(name, toggle, transformAttrs) {
    additionalPanels.push({
      name: name,
      toggle: toggle,
      transformAttrs: transformAttrs
    });
  }

  var _default = (0, _widget.createWidget)("header", {
    tagName: "header.d-header.clearfix",
    buildKey: function buildKey() {
      return "header";
    },
    services: ["router", "search"],
    defaultState: function defaultState() {
      var states = {
        searchVisible: false,
        hamburgerVisible: false,
        userVisible: false,
        ringBackdrop: true,
        inTopicContext: false
      };

      if (this.site.mobileView) {
        states.skipSearchContext = true;
      }

      return states;
    },
    html: function html(attrs, state) {
      var _this2 = this;

      var inTopicRoute = false;

      if (this.state.inTopicContext) {
        inTopicRoute = this.router.currentRouteName.startsWith("topic.");
      }

      var contents = function contents() {
        var headerIcons = _this2.attach("header-icons", {
          hamburgerVisible: state.hamburgerVisible,
          userVisible: state.userVisible,
          searchVisible: state.searchVisible,
          ringBackdrop: state.ringBackdrop,
          flagCount: attrs.flagCount,
          user: _this2.currentUser
        });

        if (attrs.onlyIcons) {
          return headerIcons;
        }

        var panels = [_this2.attach("header-buttons", attrs), headerIcons];

        if (state.searchVisible) {
          panels.push(_this2.attach("search-menu", {
            inTopicContext: state.inTopicContext && inTopicRoute
          }));
        } else if (state.hamburgerVisible) {
          panels.push(_this2.attach("hamburger-menu"));
        } else if (state.userVisible) {
          panels.push(_this2.attach("user-menu"));
        }

        additionalPanels.map(function (panel) {
          if (_this2.state[panel.toggle]) {
            panels.push(_this2.attach(panel.name, panel.transformAttrs.call(_this2, attrs, state)));
          }
        });

        if (_this2.site.mobileView) {
          panels.push(_this2.attach("header-cloak"));
        }

        return panels;
      };

      var contentsAttrs = {
        contents: contents,
        minimized: !!attrs.topic
      };
      return (0, _virtualDom.h)("div.wrap", this.attach("header-contents", Object.assign({}, attrs, contentsAttrs)));
    },
    updateHighlight: function updateHighlight() {
      if (!this.state.searchVisible) {
        this.search.set("highlightTerm", "");
      }
    },
    closeAll: function closeAll() {
      this.state.userVisible = false;
      this.state.hamburgerVisible = false;
      this.state.searchVisible = false;
      this.toggleBodyScrolling(false);
    },
    linkClickedEvent: function linkClickedEvent(attrs) {
      var searchContextEnabled = false;

      if (attrs) {
        searchContextEnabled = attrs.searchContextEnabled;
        var searchLogId = attrs.searchLogId,
            searchResultId = attrs.searchResultId,
            searchResultType = attrs.searchResultType;

        if (searchLogId && searchResultId && searchResultType) {
          (0, _ajax.ajax)("/search/click", {
            type: "POST",
            data: {
              search_log_id: searchLogId,
              search_result_id: searchResultId,
              search_result_type: searchResultType
            }
          });
        }
      }

      if (!searchContextEnabled) {
        this.closeAll();
      }

      this.updateHighlight();
    },
    toggleSearchMenu: function toggleSearchMenu() {
      if (this.site.mobileView) {
        var context = this.search.searchContext;
        var params = "";

        if (context) {
          params = "?context=".concat(context.type, "&context_id=").concat(context.id, "&skip_context=").concat(this.state.skipSearchContext);
        }

        if (this.router.currentRouteName === "full-page-search") {
          (0, _scrollTop.scrollTop)();
          $(".full-page-search").focus();
          return false;
        } else {
          return _url.default.routeTo("/search" + params);
        }
      }

      this.state.searchVisible = !this.state.searchVisible;
      this.updateHighlight();

      if (this.state.searchVisible) {
        this.focusSearchInput();
      } else {
        this.state.inTopicContext = false;
      }
    },
    toggleUserMenu: function toggleUserMenu() {
      if (this.currentUser.get("read_first_notification")) {
        this.state.ringBackdrop = false;
      }

      this.state.userVisible = !this.state.userVisible;
      this.toggleBodyScrolling(this.state.userVisible); // auto focus on first button in dropdown

      Ember.run.schedule("afterRender", function () {
        var _document$querySelect;

        return (_document$querySelect = document.querySelector(".user-menu button")) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.focus();
      });
    },
    toggleHamburger: function toggleHamburger() {
      this.state.hamburgerVisible = !this.state.hamburgerVisible;
      this.toggleBodyScrolling(this.state.hamburgerVisible); // auto focus on first link in dropdown

      Ember.run.schedule("afterRender", function () {
        var _document$querySelect2;

        (_document$querySelect2 = document.querySelector(".hamburger-panel .menu-links a")) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.focus();
      });
    },
    toggleBodyScrolling: function toggleBodyScrolling(bool) {
      if (!this.site.mobileView) {
        return;
      }

      if (bool) {
        document.body.addEventListener("touchmove", this.preventDefault, {
          passive: false
        });
      } else {
        document.body.removeEventListener("touchmove", this.preventDefault, {
          passive: false
        });
      }
    },
    preventDefault: function preventDefault(e) {
      // prevent all scrollin on menu panels, except on overflow
      var height = window.innerHeight ? window.innerHeight : $(window).height();

      if (!$(e.target).parents(".menu-panel").length || $(".menu-panel .panel-body-contents").height() <= height) {
        e.preventDefault();
      }
    },
    togglePageSearch: function togglePageSearch() {
      var state = this.state;
      state.inTopicContext = false;
      var showSearch = this.router.currentRouteName.startsWith("topic."); // If we're viewing a topic, only intercept search if there are cloaked posts

      if (showSearch) {
        var controller = this.register.lookup("controller:topic");
        var total = controller.get("model.postStream.stream.length") || 0;
        var chunkSize = controller.get("model.chunk_size") || 0;
        showSearch = total > chunkSize && $(".topic-post .cooked, .small-action:not(.time-gap)").length < total;
      }

      if (state.searchVisible) {
        this.toggleSearchMenu();
        return showSearch;
      }

      if (showSearch) {
        state.inTopicContext = true;
        this.toggleSearchMenu();
        return false;
      }

      return true;
    },
    domClean: function domClean() {
      var state = this.state;

      if (state.searchVisible || state.hamburgerVisible || state.userVisible) {
        this.closeAll();
      }
    },
    headerDismissFirstNotificationMask: function headerDismissFirstNotificationMask() {
      // Dismiss notifications
      if (document.body.classList.contains("unread-first-notification")) {
        document.body.classList.remove("unread-first-notification");
      }

      this.store.findStale("notification", {
        recent: true,
        silent: this.get("currentUser.enforcedSecondFactor"),
        limit: 5
      }, {
        cacheKey: "recent-notifications"
      }).refresh(); // Update UI

      this.state.ringBackdrop = false;
      this.scheduleRerender();
    },
    readLater: function readLater() {
      this.headerDismissFirstNotificationMask();
    },
    skipNewUserTips: function skipNewUserTips() {
      var _this3 = this;

      this.headerDismissFirstNotificationMask();
      (0, _ajax.ajax)((0, _url.userPath)(this.currentUser.username_lower), {
        type: "PUT",
        data: {
          skip_new_user_tips: true
        }
      }).then(function () {
        _this3.currentUser.set("skip_new_user_tips", true);
      });
    },
    headerKeyboardTrigger: function headerKeyboardTrigger(msg) {
      switch (msg.type) {
        case "search":
          this.toggleSearchMenu();
          break;

        case "user":
          this.toggleUserMenu();
          break;

        case "hamburger":
          this.toggleHamburger();
          break;

        case "page-search":
          if (!this.togglePageSearch()) {
            msg.event.preventDefault();
            msg.event.stopPropagation();
          }

          break;
      }
    },
    focusSearchInput: function focusSearchInput() {
      if (this.state.searchVisible) {
        Ember.run.schedule("afterRender", function () {
          var searchInput = document.querySelector("#search-term");
          searchInput.focus();
          searchInput.select();
        });
      }
    },
    setTopicContext: function setTopicContext() {
      this.state.inTopicContext = true;
      this.focusSearchInput();
    },
    clearContext: function clearContext() {
      this.state.inTopicContext = false;
      this.focusSearchInput();
    }
  });

  _exports.default = _default;
});