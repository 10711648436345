define("discourse/templates/user-activity-pending", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V8n8gcj0",
    "block": "{\"symbols\":[\"pending_post\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"user-stream\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[5,\"pending-post\",[],[[\"@post\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/user-activity-pending.hbs"
    }
  });

  _exports.default = _default;
});