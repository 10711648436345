define("discourse/components/input-tip", ["exports", "discourse-common/lib/icon-library"], function (_exports, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [":tip", "good", "bad"],
    tipIcon: null,
    tipReason: null,
    bad: Ember.computed.alias("validation.failed"),
    good: Ember.computed.not("bad"),
    tipIconHTML: function tipIconHTML() {
      var icon = (0, _iconLibrary.iconHTML)(this.good ? "check" : "times");
      return "".concat(icon).htmlSafe();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var reason = this.get("validation.reason");

      if (reason) {
        this.set("tipIcon", this.tipIconHTML());
        this.set("tipReason", reason);
      } else {
        this.set("tipIcon", null);
        this.set("tipReason", null);
      }
    }
  });

  _exports.default = _default;
});