define("discourse/helpers/theme-helpers", ["discourse-common/lib/helpers", "I18n", "discourse-common/lib/deprecated", "discourse/lib/theme-settings-store"], function (_helpers, _I18n, _deprecated, _themeSettingsStore) {
  "use strict";

  (0, _helpers.registerUnbound)("theme-i18n", function (themeId, key, params) {
    return _I18n.default.t("theme_translations.".concat(themeId, ".").concat(key), params);
  });
  (0, _helpers.registerUnbound)("theme-prefix", function (themeId, key) {
    return "theme_translations.".concat(themeId, ".").concat(key);
  });
  (0, _helpers.registerUnbound)("theme-setting", function (themeId, key, hash) {
    if (hash.deprecated) {
      (0, _deprecated.default)("The `{{themeSetting.setting_name}}` syntax is deprecated. Use `{{theme-setting 'setting_name'}}` instead", {
        since: "v2.2.0.beta8",
        dropFrom: "v2.3.0"
      });
    }

    return (0, _themeSettingsStore.getSetting)(themeId, key);
  });
});