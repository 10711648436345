define("discourse/controllers/user-activity", ["exports", "I18n", "bootbox", "discourse/lib/export-csv", "discourse-common/utils/decorators"], function (_exports, _I18n, _bootbox, _exportCsv, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.observes)("userActionType", "model.stream.itemsLoaded"), _dec2 = (0, _decorators.default)("currentUser.draft_count"), _dec3 = (0, _decorators.default)("model.pending_posts_count"), (_obj = {
    application: Ember.inject.controller(),
    user: Ember.inject.controller(),
    userActionType: null,
    canDownloadPosts: Ember.computed.alias("user.viewingSelf"),
    _showFooter: function _showFooter() {
      var _this = this;

      var showFooter;

      if (this.userActionType) {
        var stat = (this.get("model.stats") || []).find(function (s) {
          return s.action_type === _this.userActionType;
        });
        showFooter = stat && stat.count <= this.get("model.stream.itemsLoaded");
      } else {
        showFooter = this.get("model.statsCountNonPM") <= this.get("model.stream.itemsLoaded");
      }

      this.set("application.showFooter", showFooter);
    },
    draftLabel: function draftLabel(count) {
      return count > 0 ? _I18n.default.t("drafts.label_with_count", {
        count: count
      }) : _I18n.default.t("drafts.label");
    },
    pendingLabel: function pendingLabel(count) {
      return count > 0 ? _I18n.default.t("pending_posts.label_with_count", {
        count: count
      }) : _I18n.default.t("pending_posts.label");
    },
    actions: {
      exportUserArchive: function exportUserArchive() {
        _bootbox.default.confirm(_I18n.default.t("user.download_archive.confirm"), _I18n.default.t("no_value"), _I18n.default.t("yes_value"), function (confirmed) {
          return confirmed ? (0, _exportCsv.exportUserArchive)() : null;
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "_showFooter", [_dec], Object.getOwnPropertyDescriptor(_obj, "_showFooter"), _obj), _applyDecoratedDescriptor(_obj, "draftLabel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "draftLabel"), _obj), _applyDecoratedDescriptor(_obj, "pendingLabel", [_dec3], Object.getOwnPropertyDescriptor(_obj, "pendingLabel"), _obj)), _obj)));

  _exports.default = _default;
});