define("discourse/lib/highlight-syntax", ["exports", "discourse/lib/load-script"], function (_exports, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = highlightSyntax;
  _exports.registerHighlightJSLanguage = registerHighlightJSLanguage;

  /*global hljs:true */
  var _moreLanguages = [];

  function highlightSyntax(elem, siteSettings, session) {
    if (!elem) {
      return;
    }

    var selector = siteSettings.autohighlight_all_code ? "pre code" : "pre code[class]";
    var codeblocks = elem.querySelectorAll(selector);

    if (!codeblocks.length) {
      return;
    }

    var path = session.highlightJsPath;

    if (!path) {
      return;
    }

    return (0, _loadScript.default)(path).then(function () {
      customHighlightJSLanguages();
      codeblocks.forEach(function (e) {
        // Large code blocks can cause crashes or slowdowns
        if (e.innerHTML.length > 30000) {
          return;
        }

        e.classList.remove("lang-auto");
        hljs.highlightElement(e);
      });
    });
  }

  function registerHighlightJSLanguage(name, fn) {
    _moreLanguages.push({
      name: name,
      fn: fn
    });
  }

  function customHighlightJSLanguages() {
    _moreLanguages.forEach(function (l) {
      if (hljs.getLanguage(l.name) === undefined) {
        hljs.registerLanguage(l.name, l.fn);
      }
    });
  }
});