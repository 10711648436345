define("discourse/mixins/filter-mode", ["exports", "discourse/models/category"], function (_exports, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    filterModeInternal: Ember.computed("rawFilterMode", "filterType", "category", "noSubcategories", function () {
      var rawFilterMode = this.rawFilterMode;

      if (rawFilterMode) {
        return rawFilterMode;
      } else {
        var category = this.category;
        var filterType = this.filterType;

        if (category) {
          var noSubcategories = this.noSubcategories;
          return "c/".concat(_category.default.slugFor(category)).concat(noSubcategories ? "/none" : "", "/l/").concat(filterType);
        } else {
          return filterType;
        }
      }
    }),
    filterMode: Ember.computed("filterModeInternal", {
      get: function get() {
        return this.filterModeInternal;
      },
      set: function set(key, value) {
        this.set("rawFilterMode", value);
        this.set("filterType", value.split("/").pop());
        return value;
      }
    })
  });

  _exports.default = _default;
});