define("discourse/adapters/topic-list", ["exports", "discourse/lib/preload-store", "discourse/adapters/rest", "discourse/lib/ajax", "discourse-common/lib/get-url"], function (_exports, _preloadStore, _rest, _ajax, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.finderFor = finderFor;
  _exports.default = void 0;

  function finderFor(filter, params) {
    return function () {
      var url = (0, _getUrl.default)("/") + filter + ".json";

      if (params) {
        var keys = Object.keys(params),
            encoded = [];
        keys.forEach(function (p) {
          var value = encodeURI(params[p]);

          if (typeof value !== "undefined") {
            encoded.push(p + "=" + value);
          }
        });

        if (encoded.length > 0) {
          url += "?" + encoded.join("&");
        }
      }

      return (0, _ajax.ajax)(url);
    };
  }

  var _default = _rest.default.extend({
    find: function find(store, type, findArgs) {
      var filter = findArgs.filter;
      var params = findArgs.params;
      return _preloadStore.default.getAndRemove("topic_list_" + filter, finderFor(filter, params)).then(function (result) {
        result.filter = filter;
        result.params = params;
        return result;
      });
    }
  });

  _exports.default = _default;
});