define("discourse/templates/components/category-name-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4oePOJ6U",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"field\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"category\",\"isUncategorizedCategory\"]]],null,{\"statements\":[[0,\"    \"],[7,\"section\",true],[10,\"class\",\"field-item\"],[8],[0,\"\\n      \"],[7,\"label\",true],[8],[1,[28,\"i18n\",[\"category.name\"],null],false],[9],[0,\"\\n      \"],[1,[28,\"text-field\",null,[[\"value\",\"placeholderKey\",\"maxlength\",\"class\"],[[24,[\"category\",\"name\"]],\"category.name_placeholder\",\"50\",\"category-name\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"section\",true],[10,\"class\",\"field-item\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[1,[28,\"i18n\",[\"category.slug\"],null],false],[9],[0,\"\\n    \"],[1,[28,\"text-field\",null,[[\"value\",\"placeholderKey\",\"maxlength\"],[[24,[\"category\",\"slug\"]],\"category.slug_placeholder\",\"255\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/category-name-fields.hbs"
    }
  });

  _exports.default = _default;
});