define("discourse/templates/group/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l4PCmZS3",
    "block": "{\"symbols\":[\"permission\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"user-content\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"permissions\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",true],[10,\"class\",\"group-category-permissions-desc\"],[8],[0,\"\\n      \"],[1,[28,\"i18n\",[\"groups.permissions.description\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"table\",true],[10,\"class\",\"group-category-permissions\"],[8],[0,\"\\n      \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"permissions\"]]],null,{\"statements\":[[0,\"          \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"td\",true],[8],[1,[28,\"category-link\",[[23,1,[\"category\"]]],null],false],[9],[0,\"\\n            \"],[7,\"td\",true],[8],[1,[23,1,[\"description\"]],false],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"i18n\",[\"groups.permissions.none\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/group/permissions.hbs"
    }
  });

  _exports.default = _default;
});