define("discourse/controllers/password-reset", ["exports", "discourse/lib/url", "I18n", "discourse/mixins/password-validation", "discourse/models/user", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse-common/lib/get-url", "discourse/lib/webauthn"], function (_exports, _url, _I18n, _passwordValidation, _user, _ajax, _decorators, _getUrl, _webauthn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_passwordValidation.default, (_dec = (0, _decorators.default)("model.security_key_required"), _dec2 = (0, _decorators.default)(), _dec3 = (0, _decorators.default)("redirectTo"), (_obj = {
    isDeveloper: Ember.computed.alias("model.is_developer"),
    admin: Ember.computed.alias("model.admin"),
    secondFactorRequired: Ember.computed.alias("model.second_factor_required"),
    securityKeyRequired: Ember.computed.alias("model.security_key_required"),
    backupEnabled: Ember.computed.alias("model.backup_enabled"),
    securityKeyOrSecondFactorRequired: Ember.computed.or("model.second_factor_required", "model.security_key_required"),
    otherMethodAllowed: Ember.computed.readOnly("model.multiple_second_factor_methods"),
    secondFactorMethod: function secondFactorMethod(security_key_required) {
      return security_key_required ? _user.SECOND_FACTOR_METHODS.SECURITY_KEY : _user.SECOND_FACTOR_METHODS.TOTP;
    },
    passwordRequired: true,
    errorMessage: null,
    successMessage: null,
    requiresApproval: false,
    redirected: false,
    continueButtonText: function continueButtonText() {
      return _I18n.default.t("password_reset.continue", {
        site_name: this.siteSettings.title
      });
    },
    redirectHref: function redirectHref(redirectTo) {
      return (0, _getUrl.default)(redirectTo || "/");
    },
    lockImageUrl: (0, _getUrl.default)("/images/lock.svg"),
    actions: {
      submit: function submit() {
        var _this = this;

        (0, _ajax.ajax)({
          url: (0, _url.userPath)("password-reset/".concat(this.get("model.token"), ".json")),
          type: "PUT",
          data: {
            password: this.accountPassword,
            second_factor_token: this.securityKeyCredential || this.secondFactorToken,
            second_factor_method: this.secondFactorMethod,
            timezone: moment.tz.guess()
          }
        }).then(function (result) {
          if (result.success) {
            _this.set("successMessage", result.message);

            _this.set("redirectTo", result.redirect_to);

            if (result.requires_approval) {
              _this.set("requiresApproval", true);
            } else {
              _this.set("redirected", true);

              _url.default.redirectTo(result.redirect_to || "/");
            }
          } else {
            if (result.errors && !result.errors.password) {
              _this.setProperties({
                secondFactorRequired: _this.secondFactorRequired,
                securityKeyRequired: _this.securityKeyRequired,
                password: null,
                errorMessage: result.message
              });
            } else if (_this.secondFactorRequired || _this.securityKeyRequired) {
              _this.setProperties({
                secondFactorRequired: false,
                securityKeyRequired: false,
                errorMessage: null
              });
            } else if (result.errors && result.errors.password && result.errors.password.length > 0) {
              _this.rejectedPasswords.pushObject(_this.accountPassword);

              _this.rejectedPasswordsMessages.set(_this.accountPassword, result.errors.password[0]);
            }

            if (result.message) {
              _this.set("errorMessage", result.message);
            }
          }
        }).catch(function (e) {
          if (e.jqXHR && e.jqXHR.status === 429) {
            _this.set("errorMessage", _I18n.default.t("user.second_factor.rate_limit"));
          } else {
            throw new Error(e);
          }
        });
      },
      authenticateSecurityKey: function authenticateSecurityKey() {
        var _this2 = this;

        (0, _webauthn.getWebauthnCredential)(this.model.challenge, this.model.allowed_credential_ids, function (credentialData) {
          _this2.set("securityKeyCredential", credentialData);

          _this2.send("submit");
        }, function (errorMessage) {
          _this2.setProperties({
            securityKeyRequired: true,
            password: null,
            errorMessage: errorMessage
          });
        });
      },
      done: function done() {
        this.set("redirected", true);

        _url.default.redirectTo(this.redirectTo || "/");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "secondFactorMethod", [_dec], Object.getOwnPropertyDescriptor(_obj, "secondFactorMethod"), _obj), _applyDecoratedDescriptor(_obj, "continueButtonText", [_dec2], Object.getOwnPropertyDescriptor(_obj, "continueButtonText"), _obj), _applyDecoratedDescriptor(_obj, "redirectHref", [_dec3], Object.getOwnPropertyDescriptor(_obj, "redirectHref"), _obj)), _obj)));

  _exports.default = _default;
});