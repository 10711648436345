define("discourse/helpers/bound-category-link", ["exports", "discourse/helpers/category-link", "discourse-common/lib/helpers"], function (_exports, _categoryLink, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helpers.htmlHelper)(_categoryLink.categoryLinkHTML);

  _exports.default = _default;
});