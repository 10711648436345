define("discourse/components/edit-category-general", ["exports", "discourse/models/category", "discourse/components/edit-category-panel", "discourse/helpers/category-link", "discourse-common/utils/decorators", "discourse-common/lib/get-url"], function (_exports, _category, _editCategoryPanel, _categoryLink, _decorators, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = (0, _editCategoryPanel.buildCategoryPanel)("general", (_dec = (0, _decorators.default)("category.id", "category.color"), _dec2 = (0, _decorators.default)("category.parent_category_id", "category.name", "category.color", "category.text_color"), _dec3 = (0, _decorators.default)("category.id"), _dec4 = (0, _decorators.default)("category.isUncategorizedCategory", "category.id", "category.topic_url"), _dec5 = Ember._action, (_obj = {
    init: function init() {
      this._super.apply(this, arguments);

      this.foregroundColors = ["FFFFFF", "000000"];
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._focusCategoryName();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._laterFocus && Ember.run.cancel(this._laterFocus);
    },
    canSelectParentCategory: Ember.computed.not("category.isUncategorizedCategory"),
    uncategorizedSiteSettingLink: (0, _getUrl.default)("/admin/site_settings/category/all_results?filter=allow_uncategorized_topics"),
    customizeTextContentLink: (0, _getUrl.default)("/admin/customize/site_texts?q=uncategorized"),
    backgroundColors: function backgroundColors() {
      var categories = this.site.get("categoriesList");
      return this.siteSettings.category_colors.split("|").map(function (i) {
        return i.toUpperCase();
      }).concat(categories.map(function (c) {
        return c.color.toUpperCase();
      })).uniq();
    },
    noCategoryStyle: function noCategoryStyle() {
      return this.siteSettings.category_style === "none";
    },
    usedBackgroundColors: function usedBackgroundColors(categoryId, categoryColor) {
      var categories = this.site.get("categoriesList"); // If editing a category, don't include its color:

      return categories.map(function (c) {
        return categoryId && categoryColor.toUpperCase() === c.color.toUpperCase() ? null : c.color.toUpperCase();
      }, this).compact();
    },
    parentCategories: function parentCategories() {
      var _this = this;

      return this.site.get("categoriesList").filter(function (c) {
        return c.level + 1 < _this.siteSettings.max_category_nesting;
      });
    },
    categoryBadgePreview: function categoryBadgePreview(parentCategoryId, name, color, textColor) {
      var category = this.category;

      var c = _category.default.create({
        name: name,
        color: color,
        text_color: textColor,
        parent_category_id: parseInt(parentCategoryId, 10),
        read_restricted: category.get("read_restricted")
      });

      return (0, _categoryLink.categoryBadgeHTML)(c, {
        link: false
      });
    },
    subCategories: function subCategories(categoryId) {
      if (Ember.isEmpty(categoryId)) {
        return null;
      }

      return _category.default.list().filterBy("parent_category_id", categoryId);
    },
    showDescription: function showDescription(isUncategorizedCategory, categoryId, topicUrl) {
      return !isUncategorizedCategory && categoryId && topicUrl;
    },
    showCategoryTopic: function showCategoryTopic() {
      window.open(this.get("category.topic_url"), "_blank").focus();
      return false;
    },
    _focusCategoryName: function _focusCategoryName() {
      var _this2 = this;

      this._laterFocus = Ember.run.later(function () {
        var categoryName = _this2.element.querySelector(".category-name");

        categoryName && categoryName.focus();
      }, 25);
    }
  }, (_applyDecoratedDescriptor(_obj, "backgroundColors", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "backgroundColors"), _obj), _applyDecoratedDescriptor(_obj, "noCategoryStyle", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "noCategoryStyle"), _obj), _applyDecoratedDescriptor(_obj, "usedBackgroundColors", [_dec], Object.getOwnPropertyDescriptor(_obj, "usedBackgroundColors"), _obj), _applyDecoratedDescriptor(_obj, "parentCategories", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "parentCategories"), _obj), _applyDecoratedDescriptor(_obj, "categoryBadgePreview", [_dec2], Object.getOwnPropertyDescriptor(_obj, "categoryBadgePreview"), _obj), _applyDecoratedDescriptor(_obj, "subCategories", [_dec3], Object.getOwnPropertyDescriptor(_obj, "subCategories"), _obj), _applyDecoratedDescriptor(_obj, "showDescription", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showDescription"), _obj), _applyDecoratedDescriptor(_obj, "showCategoryTopic", [_dec5], Object.getOwnPropertyDescriptor(_obj, "showCategoryTopic"), _obj)), _obj)));

  _exports.default = _default;
});