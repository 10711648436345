define("discourse/templates/components/ignored-user-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZYtZvEZK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ignored-user-list-item\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"ignored-user-name\"],[8],[1,[22,\"item\"],false],[9],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"class\",\"action\",\"icon\"],[\"remove-ignored-user no-text btn-icon\",[28,\"action\",[[23,0,[]],\"removeIgnoredUser\",[24,[\"item\"]]],null],\"times\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/ignored-user-list-item.hbs"
    }
  });

  _exports.default = _default;
});