define("discourse/mixins/user-fields-validation", ["exports", "discourse-common/utils/decorators", "I18n"], function (_exports, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Mixin.create((_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.default)("userFields.@each.value"), (_obj = {
    _createUserFields: function _createUserFields() {
      if (!this.site) {
        return;
      }

      var userFields = this.site.get("user_fields");

      if (userFields) {
        userFields = userFields.sortBy("position").map(function (f) {
          return Ember.Object.create({
            value: null,
            field: f
          });
        });
      }

      this.set("userFields", userFields);
    },
    userFieldsValidation: function userFieldsValidation() {
      var userFields = this.userFields;

      if (userFields) {
        userFields = userFields.filterBy("field.required");
      }

      if (!Ember.isEmpty(userFields)) {
        var emptyUserField = userFields.find(function (uf) {
          var val = uf.get("value");
          return !val || Ember.isEmpty(val);
        });

        if (emptyUserField) {
          var userField = emptyUserField.field;
          return Ember.Object.create({
            failed: true,
            message: _I18n.default.t("user_fields.required", {
              name: userField.name
            }),
            element: userField.element
          });
        }
      }

      return Ember.Object.create({
        ok: true
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "_createUserFields", [_dec], Object.getOwnPropertyDescriptor(_obj, "_createUserFields"), _obj), _applyDecoratedDescriptor(_obj, "userFieldsValidation", [_dec2], Object.getOwnPropertyDescriptor(_obj, "userFieldsValidation"), _obj)), _obj)));

  _exports.default = _default;
});