define("discourse/initializers/read-only", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Subscribe to "read-only" status change events via the Message Bus
  var _default = {
    name: "read-only",
    after: "message-bus",
    initialize: function initialize(container) {
      var messageBus = container.lookup("message-bus:main");

      if (!messageBus) {
        return;
      }

      var site = container.lookup("site:main");
      messageBus.subscribe("/site/read-only", function (enabled) {
        site.set("isReadOnly", enabled);
      });
    }
  };
  _exports.default = _default;
});