define("discourse/components/bookmark", ["exports", "discourse/lib/time-utils", "discourse/models/bookmark", "I18n", "discourse/lib/keyboard-shortcuts", "@discourse/itsatrap", "discourse/lib/time-shortcut", "discourse/lib/ajax", "bootbox", "discourse-common/utils/decorators", "discourse/lib/bookmark", "discourse/lib/ajax-error"], function (_exports, _timeUtils, _bookmark, _I18n, _keyboardShortcuts, _itsatrap, _timeShortcut, _ajax, _bootbox, _decorators, _bookmark2, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _obj, _init;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var BOOKMARK_BINDINGS = {
    enter: {
      handler: "saveAndClose"
    },
    "d d": {
      handler: "delete"
    }
  };

  var _default = Ember.Component.extend((_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.on)("didInsertElement"), _dec3 = (0, _decorators.default)("postDetectedLocalDate", "postDetectedLocalTime"), _dec4 = (0, _decorators.default)(), _dec5 = (0, _decorators.default)(), _dec6 = (0, _decorators.default)("existingBookmarkHasReminder"), _dec7 = (0, _decorators.default)("editingExistingBookmark", "existingBookmarkHasReminder"), _dec8 = (0, _decorators.default)("model.reminderAt"), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_obj = {
    tagName: "",
    errorMessage: null,
    selectedReminderType: null,
    _closeWithoutSaving: null,
    _savingBookmarkManually: null,
    _saving: null,
    _deleting: null,
    _itsatrap: null,
    postDetectedLocalDate: null,
    postDetectedLocalTime: null,
    postDetectedLocalTimezone: null,
    prefilledDatetime: null,
    userTimezone: null,
    showOptions: null,
    model: null,
    afterSave: null,
    _setup: function _setup() {
      this.setProperties({
        errorMessage: null,
        selectedReminderType: _timeShortcut.TIME_SHORTCUT_TYPES.NONE,
        _closeWithoutSaving: false,
        _savingBookmarkManually: false,
        _saving: false,
        _deleting: false,
        postDetectedLocalDate: null,
        postDetectedLocalTime: null,
        postDetectedLocalTimezone: null,
        prefilledDatetime: null,
        userTimezone: this.currentUser.resolvedTimezone(this.currentUser),
        showOptions: false,
        _itsatrap: new _itsatrap.default()
      });
      this.registerOnCloseHandler(this._onModalClose);

      this._loadBookmarkOptions();

      this._bindKeyboardShortcuts();

      if (this.editingExistingBookmark) {
        this._initializeExistingBookmarkData();
      }

      this._loadPostLocalDates();
    },
    _prepareUI: function _prepareUI() {
      var _this = this;

      Ember.run.later(function () {
        if (_this.site.isMobileDevice) {
          document.getElementById("bookmark-name").blur();
        }
      }); // we want to make sure the options panel opens so the user
      // knows they have set these options previously.

      if (this.autoDeletePreference) {
        this.toggleOptionsPanel();
      }
    },
    _initializeExistingBookmarkData: function _initializeExistingBookmarkData() {
      if (this.existingBookmarkHasReminder) {
        this.set("prefilledDatetime", this.model.reminderAt);
        var parsedDatetime = (0, _timeUtils.parseCustomDatetime)(this.prefilledDatetime, null, this.userTimezone);
        this.set("selectedDatetime", parsedDatetime);
      }
    },
    _loadBookmarkOptions: function _loadBookmarkOptions() {
      this.set("autoDeletePreference", this.model.autoDeletePreference || this._preferredDeleteOption() || 0);
    },
    _preferredDeleteOption: function _preferredDeleteOption() {
      var preferred = localStorage.bookmarkDeleteOption;

      if (preferred && preferred !== "") {
        preferred = parseInt(preferred, 10);
      }

      return preferred;
    },
    _bindKeyboardShortcuts: function _bindKeyboardShortcuts() {
      var _this2 = this;

      _keyboardShortcuts.default.pause();

      Object.keys(BOOKMARK_BINDINGS).forEach(function (shortcut) {
        _this2._itsatrap.bind(shortcut, function () {
          var binding = BOOKMARK_BINDINGS[shortcut];

          _this2.send(binding.handler);

          return false;
        });
      });
    },
    _loadPostLocalDates: function _loadPostLocalDates() {
      var postEl = document.querySelector("[data-post-id=\"".concat(this.model.postId, "\"]"));
      var localDateEl;

      if (postEl) {
        localDateEl = postEl.querySelector(".discourse-local-date");
      }

      if (localDateEl) {
        this.setProperties({
          postDetectedLocalDate: localDateEl.dataset.date,
          postDetectedLocalTime: localDateEl.dataset.time,
          postDetectedLocalTimezone: localDateEl.dataset.timezone
        });
      }
    },
    _saveBookmark: function _saveBookmark() {
      var _this3 = this;

      var reminderAt;

      if (this.selectedReminderType) {
        reminderAt = this.selectedDatetime;
      }

      var reminderAtISO = reminderAt ? reminderAt.toISOString() : null;

      if (this.selectedReminderType === _timeShortcut.TIME_SHORTCUT_TYPES.CUSTOM) {
        if (!reminderAt) {
          return Ember.RSVP.Promise.reject(_I18n.default.t("bookmarks.invalid_custom_datetime"));
        }
      }

      localStorage.bookmarkDeleteOption = this.autoDeletePreference;
      var data = {
        reminder_at: reminderAtISO,
        name: this.model.name,
        post_id: this.model.postId,
        id: this.model.id,
        auto_delete_preference: this.autoDeletePreference,
        for_topic: this.model.forTopic
      };

      if (this.editingExistingBookmark) {
        return (0, _ajax.ajax)("/bookmarks/".concat(this.model.id), {
          type: "PUT",
          data: data
        }).then(function (response) {
          _this3._executeAfterSave(response, reminderAtISO);
        });
      } else {
        return (0, _ajax.ajax)("/bookmarks", {
          type: "POST",
          data: data
        }).then(function (response) {
          _this3._executeAfterSave(response, reminderAtISO);
        });
      }
    },
    _executeAfterSave: function _executeAfterSave(response, reminderAtISO) {
      if (!this.afterSave) {
        return;
      }

      this.afterSave({
        reminder_at: reminderAtISO,
        for_topic: this.model.forTopic,
        auto_delete_preference: this.autoDeletePreference,
        post_id: this.model.postId,
        id: this.model.id || response.id,
        name: this.model.name,
        topic_id: this.model.topicId
      });
    },
    _deleteBookmark: function _deleteBookmark() {
      var _this4 = this;

      return (0, _ajax.ajax)("/bookmarks/" + this.model.id, {
        type: "DELETE"
      }).then(function (response) {
        if (_this4.afterDelete) {
          _this4.afterDelete(response.topic_bookmarked, _this4.model.id);
        }
      });
    },
    _postLocalDate: function _postLocalDate() {
      var parsedPostLocalDate = (0, _timeUtils.parseCustomDatetime)(this.postDetectedLocalDate, this.postDetectedLocalTime, this.userTimezone, this.postDetectedLocalTimezone);

      if (!this.postDetectedLocalTime) {
        return (0, _timeUtils.startOfDay)(parsedPostLocalDate);
      }

      return parsedPostLocalDate;
    },
    _handleSaveError: function _handleSaveError(e) {
      this._savingBookmarkManually = false;

      if (typeof e === "string") {
        _bootbox.default.alert(e);
      } else {
        (0, _ajaxError.popupAjaxError)(e);
      }
    },
    _onModalClose: function _onModalClose(closeOpts) {
      var _this5 = this;

      // we want to close without saving if the user already saved
      // manually or deleted the bookmark, as well as when the modal
      // is just closed with the X button
      this._closeWithoutSaving = this._closeWithoutSaving || closeOpts.initiatedByCloseButton || closeOpts.initiatedByESC;

      if (!this._closeWithoutSaving && !this._savingBookmarkManually) {
        this._saveBookmark().catch(function (e) {
          return _this5._handleSaveError(e);
        });
      }

      if (this.onCloseWithoutSaving && this._closeWithoutSaving) {
        this.onCloseWithoutSaving();
      }
    },
    willDestroyElement: function willDestroyElement() {
      var _this$_itsatrap;

      this._super.apply(this, arguments);

      (_this$_itsatrap = this._itsatrap) === null || _this$_itsatrap === void 0 ? void 0 : _this$_itsatrap.destroy();
      this.set("_itsatrap", null);

      _keyboardShortcuts.default.unpause();
    },
    showExistingReminderAt: Ember.computed.notEmpty("model.reminderAt"),
    showDelete: Ember.computed.notEmpty("model.id"),
    userHasTimezoneSet: Ember.computed.notEmpty("userTimezone"),
    editingExistingBookmark: Ember.computed.and("model", "model.id"),
    existingBookmarkHasReminder: Ember.computed.and("model", "model.id", "model.reminderAt"),
    showPostLocalDate: function showPostLocalDate(postDetectedLocalDate, postDetectedLocalTime) {
      if (!postDetectedLocalTime || !postDetectedLocalDate) {
        return;
      }

      var postLocalDateTime = this._postLocalDate();

      if (postLocalDateTime < (0, _timeUtils.now)(this.userTimezone)) {
        return;
      }

      return true;
    },
    autoDeletePreferences: function autoDeletePreferences() {
      return Object.keys(_bookmark.AUTO_DELETE_PREFERENCES).map(function (key) {
        return {
          id: _bookmark.AUTO_DELETE_PREFERENCES[key],
          name: _I18n.default.t("bookmarks.auto_delete_preference.".concat(key.toLowerCase()))
        };
      });
    },
    customTimeShortcutOptions: function customTimeShortcutOptions() {
      var customOptions = [];

      if (this.showPostLocalDate) {
        customOptions.push({
          icon: "globe-americas",
          id: _timeShortcut.TIME_SHORTCUT_TYPES.POST_LOCAL_DATE,
          label: "time_shortcut.post_local_date",
          time: this._postLocalDate(),
          timeFormatKey: "dates.long_no_year",
          hidden: false
        });
      }

      return customOptions;
    },
    customTimeShortcutLabels: function customTimeShortcutLabels(existingBookmarkHasReminder) {
      var labels = {};

      if (existingBookmarkHasReminder) {
        labels[_timeShortcut.TIME_SHORTCUT_TYPES.NONE] = "bookmarks.remove_reminder_keep_bookmark";
      }

      return labels;
    },
    hiddenTimeShortcutOptions: function hiddenTimeShortcutOptions(editingExistingBookmark, existingBookmarkHasReminder) {
      if (editingExistingBookmark && !existingBookmarkHasReminder) {
        return [_timeShortcut.TIME_SHORTCUT_TYPES.NONE];
      }

      return [];
    },
    existingReminderAtFormatted: function existingReminderAtFormatted(existingReminderAt) {
      return (0, _bookmark2.formattedReminderTime)(existingReminderAt, this.userTimezone);
    },
    saveAndClose: function saveAndClose() {
      var _this6 = this;

      if (this._saving || this._deleting) {
        return;
      }

      this._saving = true;
      this._savingBookmarkManually = true;
      return this._saveBookmark().then(function () {
        return _this6.closeModal();
      }).catch(function (e) {
        return _this6._handleSaveError(e);
      }).finally(function () {
        return _this6._saving = false;
      });
    },
    toggleOptionsPanel: function toggleOptionsPanel() {
      if (this.showOptions) {
        $(".bookmark-options-panel").slideUp("fast");
      } else {
        $(".bookmark-options-panel").slideDown("fast");
      }

      this.toggleProperty("showOptions");
    },
    delete: function _delete() {
      var _this7 = this;

      if (!this.model.id) {
        return;
      }

      this._deleting = true;

      var deleteAction = function deleteAction() {
        _this7._closeWithoutSaving = true;

        _this7._deleteBookmark().then(function () {
          _this7._deleting = false;

          _this7.closeModal();
        }).catch(function (e) {
          return _this7._handleSaveError(e);
        });
      };

      if (this.existingBookmarkHasReminder) {
        _bootbox.default.confirm(_I18n.default.t("bookmarks.confirm_delete"), function (result) {
          if (result) {
            deleteAction();
          }
        });
      } else {
        deleteAction();
      }
    },
    closeWithoutSavingBookmark: function closeWithoutSavingBookmark() {
      this._closeWithoutSaving = true;
      this.closeModal();
    },
    onTimeSelected: function onTimeSelected(type, time) {
      this.setProperties({
        selectedReminderType: type,
        selectedDatetime: time
      }); // if the type is custom, we need to wait for the user to click save, as
      // they could still be adjusting the date and time

      if (![_timeShortcut.TIME_SHORTCUT_TYPES.CUSTOM, _timeShortcut.TIME_SHORTCUT_TYPES.RELATIVE].includes(type)) {
        return this.saveAndClose();
      }
    },
    selectPostLocalDate: function selectPostLocalDate(date) {
      this.setProperties({
        selectedReminderType: this.reminderTypes.POST_LOCAL_DATE,
        postLocalDate: date
      });
      return this.saveAndClose();
    }
  }, (_applyDecoratedDescriptor(_obj, "_setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "_setup"), _obj), _applyDecoratedDescriptor(_obj, "_prepareUI", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_prepareUI"), _obj), _applyDecoratedDescriptor(_obj, "_onModalClose", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_onModalClose"), _obj), _applyDecoratedDescriptor(_obj, "showPostLocalDate", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showPostLocalDate"), _obj), _applyDecoratedDescriptor(_obj, "autoDeletePreferences", [_dec4], (_init = Object.getOwnPropertyDescriptor(_obj, "autoDeletePreferences"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "customTimeShortcutOptions", [_dec5], Object.getOwnPropertyDescriptor(_obj, "customTimeShortcutOptions"), _obj), _applyDecoratedDescriptor(_obj, "customTimeShortcutLabels", [_dec6], Object.getOwnPropertyDescriptor(_obj, "customTimeShortcutLabels"), _obj), _applyDecoratedDescriptor(_obj, "hiddenTimeShortcutOptions", [_dec7], Object.getOwnPropertyDescriptor(_obj, "hiddenTimeShortcutOptions"), _obj), _applyDecoratedDescriptor(_obj, "existingReminderAtFormatted", [_dec8], Object.getOwnPropertyDescriptor(_obj, "existingReminderAtFormatted"), _obj), _applyDecoratedDescriptor(_obj, "saveAndClose", [_dec9], Object.getOwnPropertyDescriptor(_obj, "saveAndClose"), _obj), _applyDecoratedDescriptor(_obj, "toggleOptionsPanel", [_dec10], Object.getOwnPropertyDescriptor(_obj, "toggleOptionsPanel"), _obj), _applyDecoratedDescriptor(_obj, "delete", [_dec11], Object.getOwnPropertyDescriptor(_obj, "delete"), _obj), _applyDecoratedDescriptor(_obj, "closeWithoutSavingBookmark", [_dec12], Object.getOwnPropertyDescriptor(_obj, "closeWithoutSavingBookmark"), _obj), _applyDecoratedDescriptor(_obj, "onTimeSelected", [_dec13], Object.getOwnPropertyDescriptor(_obj, "onTimeSelected"), _obj), _applyDecoratedDescriptor(_obj, "selectPostLocalDate", [_dec14], Object.getOwnPropertyDescriptor(_obj, "selectPostLocalDate"), _obj)), _obj)));

  _exports.default = _default;
});