define("discourse/components/group-selector", ["exports", "discourse-common/utils/decorators", "I18n", "discourse-common/lib/raw-templates"], function (_exports, _decorators, _I18n, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("placeholderKey"), _dec2 = (0, _decorators.observes)("groupNames"), _dec3 = (0, _decorators.on)("didInsertElement"), (_obj = {
    placeholder: function placeholder(placeholderKey) {
      return placeholderKey ? _I18n.default.t(placeholderKey) : "";
    },
    _update: function _update() {
      if (this.canReceiveUpdates === "true") {
        this._initializeAutocomplete({
          updateData: true
        });
      }
    },
    _initializeAutocomplete: function _initializeAutocomplete(opts) {
      var _this = this;

      var selectedGroups;
      var groupNames = this.groupNames;
      $(this.element.querySelector("input")).autocomplete({
        debounced: true,
        allowAny: false,
        items: Array.isArray(groupNames) ? groupNames : Ember.isEmpty(groupNames) ? [] : [groupNames],
        single: this.single,
        fullWidthWrap: this.fullWidthWrap,
        updateData: opts && opts.updateData ? opts.updateData : false,
        onChangeItems: function onChangeItems(items) {
          selectedGroups = items;

          if (_this.onChangeCallback) {
            _this.onChangeCallback(_this.groupNames, selectedGroups);
          } else {
            _this.set("groupNames", items.join(","));
          }
        },
        transformComplete: function transformComplete(g) {
          return g.name;
        },
        dataSource: function dataSource(term) {
          return _this.groupFinder(term).then(function (groups) {
            if (!selectedGroups) {
              return groups;
            }

            return groups.filter(function (group) {
              return !selectedGroups.any(function (s) {
                return s === group.name;
              });
            });
          });
        },
        template: (0, _rawTemplates.findRawTemplate)("group-selector-autocomplete")
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "placeholder", [_dec], Object.getOwnPropertyDescriptor(_obj, "placeholder"), _obj), _applyDecoratedDescriptor(_obj, "_update", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_update"), _obj), _applyDecoratedDescriptor(_obj, "_initializeAutocomplete", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_initializeAutocomplete"), _obj)), _obj)));

  _exports.default = _default;
});