define("discourse/mixins/pan-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MINIMUM_SWIPE_DISTANCE = _exports.SWIPE_VELOCITY_THRESHOLD = _exports.SWIPE_DISTANCE_THRESHOLD = void 0;

  /**
     Pan events is a mixin that allows components to detect and respond to swipe gestures
     It fires callbacks for panStart, panEnd, panMove with the pan state, and the original event.
   **/
  var SWIPE_DISTANCE_THRESHOLD = 50;
  _exports.SWIPE_DISTANCE_THRESHOLD = SWIPE_DISTANCE_THRESHOLD;
  var SWIPE_VELOCITY_THRESHOLD = 0.12;
  _exports.SWIPE_VELOCITY_THRESHOLD = SWIPE_VELOCITY_THRESHOLD;
  var MINIMUM_SWIPE_DISTANCE = 5;
  _exports.MINIMUM_SWIPE_DISTANCE = MINIMUM_SWIPE_DISTANCE;

  var _default = Ember.Mixin.create({
    //velocity is pixels per ms
    _panState: null,
    _animationPending: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.addTouchListeners(this.element);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.removeTouchListeners(this.element);
    },
    addTouchListeners: function addTouchListeners(element) {
      var _this = this;

      if (this.site.mobileView) {
        this.touchStart = function (e) {
          return e.touches && _this._panStart(e.touches[0]);
        };

        this.touchMove = function (e) {
          var touchEvent = e.touches[0];
          touchEvent.type = "pointermove";

          _this._panMove(touchEvent, e);
        };

        this.touchEnd = function (e) {
          return _this._panMove({
            type: "pointerup"
          }, e);
        };

        this.touchCancel = function (e) {
          return _this._panMove({
            type: "pointercancel"
          }, e);
        };

        var opts = {
          passive: false
        };
        element.addEventListener("touchstart", this.touchStart, opts);
        element.addEventListener("touchmove", this.touchMove, opts);
        element.addEventListener("touchend", this.touchEnd, opts);
        element.addEventListener("touchcancel", this.touchCancel, opts);
      }
    },
    removeTouchListeners: function removeTouchListeners(element) {
      if (this.site.mobileView) {
        element.removeEventListener("touchstart", this.touchStart);
        element.removeEventListener("touchmove", this.touchMove);
        element.removeEventListener("touchend", this.touchEnd);
        element.removeEventListener("touchcancel", this.touchCancel);
      }
    },
    _calculateDirection: function _calculateDirection(oldState, deltaX, deltaY) {
      if (oldState.start || !oldState.direction) {
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          return deltaX > 0 ? "right" : "left";
        } else {
          return deltaY > 0 ? "down" : "up";
        }
      }

      return oldState.direction;
    },
    _calculateNewPanState: function _calculateNewPanState(oldState, e) {
      if (e.type === "pointerup" || e.type === "pointercancel") {
        return oldState;
      }

      var newTimestamp = Date.now();
      var timeDiffSeconds = newTimestamp - oldState.timestamp;

      if (timeDiffSeconds === 0) {
        return oldState;
      } //calculate delta x, y, distance from START location


      var deltaX = e.clientX - oldState.startLocation.x;
      var deltaY = e.clientY - oldState.startLocation.y;
      var distance = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2)); //calculate velocity from previous event center location

      var eventDeltaX = e.clientX - oldState.center.x;
      var eventDeltaY = e.clientY - oldState.center.y;
      var velocityX = eventDeltaX / timeDiffSeconds;
      var velocityY = eventDeltaY / timeDiffSeconds;
      var deltaDistance = Math.sqrt(Math.pow(eventDeltaX, 2) + Math.pow(eventDeltaY, 2));
      var velocity = deltaDistance / timeDiffSeconds;
      return {
        startLocation: oldState.startLocation,
        center: {
          x: e.clientX,
          y: e.clientY
        },
        velocity: velocity,
        velocityX: velocityX,
        velocityY: velocityY,
        deltaX: deltaX,
        deltaY: deltaY,
        distance: distance,
        start: false,
        timestamp: newTimestamp,
        direction: this._calculateDirection(oldState, deltaX, deltaY)
      };
    },
    _panStart: function _panStart(e) {
      var newState = {
        center: {
          x: e.clientX,
          y: e.clientY
        },
        startLocation: {
          x: e.clientX,
          y: e.clientY
        },
        velocity: 0,
        velocityX: 0,
        velocityY: 0,
        deltaX: 0,
        deltaY: 0,
        distance: 0,
        start: true,
        timestamp: Date.now(),
        direction: null
      };
      this.set("_panState", newState);
    },
    _panMove: function _panMove(e, originalEvent) {
      var _this2 = this;

      if (!this._panState) {
        this._panStart(e);

        return;
      }

      var previousState = this._panState;

      var newState = this._calculateNewPanState(previousState, e);

      if (previousState.start && newState.distance < MINIMUM_SWIPE_DISTANCE) {
        return;
      }

      this.set("_panState", newState);
      newState.originalEvent = originalEvent;

      if (previousState.start && "panStart" in this) {
        this.panStart(newState);
      } else if ((e.type === "pointerup" || e.type === "pointercancel") && "panEnd" in this) {
        this.panEnd(newState);
      } else if (e.type === "pointermove" && "panMove" in this) {
        if (this._animationPending) {
          return;
        }

        this._animationPending = true;
        window.requestAnimationFrame(function () {
          if (!_this2._animationPending) {
            return;
          }

          _this2.panMove(newState);

          _this2._animationPending = false;
        });
      }
    }
  });

  _exports.default = _default;
});