define("discourse/services/app-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    init: function init() {
      this._super.apply(this, arguments); // A hack because we don't make `current user` properly via container in testing mode


      if (this.currentUser) {
        this.currentUser.appEvents = this;
      }
    }
  });

  _exports.default = _default;
});