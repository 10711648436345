define("discourse/components/topic-entrance", ["exports", "discourse/mixins/cleans-up", "discourse/lib/url", "I18n", "discourse-common/utils/decorators"], function (_exports, _cleansUp, _url, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj, _init, _init2, _init3, _init4;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function entranceDate(dt, showTime) {
    var today = new Date();

    if (dt.toDateString() === today.toDateString()) {
      return moment(dt).format(_I18n.default.t("dates.time"));
    }

    if (dt.getYear() === today.getYear()) {
      // No year
      return moment(dt).format(showTime ? _I18n.default.t("dates.long_date_without_year_with_linebreak") : _I18n.default.t("dates.long_no_year_no_time"));
    }

    return moment(dt).format(showTime ? _I18n.default.t("dates.long_date_with_year_with_linebreak") : _I18n.default.t("dates.long_date_with_year_without_time"));
  }

  var _default = Ember.Component.extend(_cleansUp.default, (_dec = (0, _decorators.default)("topic.created_at"), _dec2 = (0, _decorators.default)("topic.bumped_at"), _dec3 = (0, _decorators.default)("createdDate", "bumpedDate"), _dec4 = (0, _decorators.default)("createdDate", "showTime"), _dec5 = (0, _decorators.default)("bumpedDate", "showTime"), (_obj = {
    elementId: "topic-entrance",
    classNameBindings: ["visible::hidden"],
    _position: null,
    topic: null,
    visible: null,
    createdDate: function createdDate(createdAt) {
      return new Date(createdAt);
    },
    bumpedDate: function bumpedDate(bumpedAt) {
      return new Date(bumpedAt);
    },
    showTime: function showTime(createdDate, bumpedDate) {
      return bumpedDate.getTime() - createdDate.getTime() < 1000 * 60 * 60 * 24 * 2;
    },
    topDate: function topDate(createdDate, showTime) {
      return entranceDate(createdDate, showTime);
    },
    bottomDate: function bottomDate(bumpedDate, showTime) {
      return entranceDate(bumpedDate, showTime);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.appEvents.on("topic-entrance:show", this, "_show");
    },
    _setCSS: function _setCSS() {
      var pos = this._position;
      var $self = $(this.element);
      var width = $self.width();
      var height = $self.height();
      pos.left = parseInt(pos.left, 10) - width / 2;
      pos.top = parseInt(pos.top, 10) - height / 2;
      var windowWidth = $(window).width();

      if (pos.left + width > windowWidth) {
        pos.left = windowWidth - width - 15;
      }

      $self.css(pos);
    },
    _show: function _show(data) {
      var _this = this;

      this._position = data.position;
      this.setProperties({
        topic: data.topic,
        visible: true
      });
      Ember.run.scheduleOnce("afterRender", this, this._setCSS);
      $("html").off("mousedown.topic-entrance").on("mousedown.topic-entrance", function (e) {
        var $target = $(e.target);

        if ($target.prop("id") === "topic-entrance" || $(_this.element).has($target).length !== 0) {
          return;
        }

        _this.cleanUp();
      });
    },
    cleanUp: function cleanUp() {
      this.setProperties({
        topic: null,
        visible: false
      });
      $("html").off("mousedown.topic-entrance");
    },
    willDestroyElement: function willDestroyElement() {
      this.appEvents.off("topic-entrance:show", this, "_show");
    },
    _jumpTo: function _jumpTo(destination) {
      this.cleanUp();

      _url.default.routeTo(destination);
    },
    actions: {
      enterTop: function enterTop() {
        this._jumpTo(this.get("topic.url"));
      },
      enterBottom: function enterBottom() {
        this._jumpTo(this.get("topic.lastPostUrl"));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "createdDate", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "createdDate"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "bumpedDate", [_dec2], (_init2 = Object.getOwnPropertyDescriptor(_obj, "bumpedDate"), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "showTime", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showTime"), _obj), _applyDecoratedDescriptor(_obj, "topDate", [_dec4], (_init3 = Object.getOwnPropertyDescriptor(_obj, "topDate"), _init3 = _init3 ? _init3.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init3;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "bottomDate", [_dec5], (_init4 = Object.getOwnPropertyDescriptor(_obj, "bottomDate"), _init4 = _init4 ? _init4.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init4;
    }
  }), _obj)), _obj)));

  _exports.default = _default;
});