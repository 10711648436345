define("discourse/lib/load-oneboxes", ["exports", "pretty-text/oneboxer", "pretty-text/inline-oneboxer"], function (_exports, _oneboxer, _inlineOneboxer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadOneboxes = loadOneboxes;

  function loadOneboxes(container, ajax, topicId, categoryId, maxOneboxes, refresh, offline) {
    var oneboxes = {};
    var inlineOneboxes = {}; // Oneboxes = `a.onebox` -> `a.onebox-loading` -> `aside.onebox`
    // Inline Oneboxes = `a.inline-onebox-loading` -> `a.inline-onebox`

    var loadedOneboxes = container.querySelectorAll("aside.onebox, a.".concat(_oneboxer.LOADING_ONEBOX_CSS_CLASS, ", a.inline-onebox")).length;
    container.querySelectorAll("a.onebox, a.inline-onebox-loading").forEach(function (link) {
      var isInline = link.classList.contains("inline-onebox-loading"); // maps URLs to their link elements

      var map = isInline ? inlineOneboxes : oneboxes;

      if (loadedOneboxes < maxOneboxes) {
        if (map[link.href] === undefined) {
          map[link.href] = [];
          loadedOneboxes++;
        }

        map[link.href].push(link);
      } else {
        if (map[link.href] !== undefined) {
          map[link.href].push(link);
        } else if (isInline) {
          link.classList.remove("inline-onebox-loading");
        }
      }
    });

    if (Object.keys(oneboxes).length > 0) {
      _loadOneboxes({
        oneboxes: oneboxes,
        ajax: ajax,
        topicId: topicId,
        categoryId: categoryId,
        refresh: refresh,
        offline: offline
      });
    }

    if (Object.keys(inlineOneboxes).length > 0) {
      _loadInlineOneboxes(inlineOneboxes, ajax, topicId, categoryId);
    }

    return Object.keys(oneboxes).length + Object.keys(inlineOneboxes).length;
  }

  function _loadInlineOneboxes(inline, ajax, topicId, categoryId) {
    (0, _inlineOneboxer.applyInlineOneboxes)(inline, ajax, {
      categoryId: topicId,
      topicId: categoryId
    });
  }

  function _loadOneboxes(_ref) {
    var oneboxes = _ref.oneboxes,
        ajax = _ref.ajax,
        topicId = _ref.topicId,
        categoryId = _ref.categoryId,
        refresh = _ref.refresh,
        offline = _ref.offline;
    Object.values(oneboxes).forEach(function (onebox) {
      onebox.forEach(function (elem) {
        (0, _oneboxer.load)({
          elem: elem,
          ajax: ajax,
          categoryId: categoryId,
          topicId: topicId,
          refresh: refresh,
          offline: offline
        });
      });
    });
  }
});