define("discourse/controllers/grant-admin-second-factor", ["exports", "discourse-common/utils/decorators", "discourse/lib/webauthn", "discourse/mixins/modal-functionality", "discourse/models/user", "I18n", "bootbox"], function (_exports, _decorators, _webauthn, _modalFunctionality, _user, _I18n, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("inProgress", "securityKeyCredential", "secondFactorToken"), _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    showSecondFactor: false,
    secondFactorMethod: _user.SECOND_FACTOR_METHODS.TOTP,
    secondFactorToken: null,
    securityKeyCredential: null,
    inProgress: false,
    onShow: function onShow() {
      this.setProperties({
        showSecondFactor: false,
        secondFactorMethod: _user.SECOND_FACTOR_METHODS.TOTP,
        secondFactorToken: null,
        securityKeyCredential: null
      });
    },
    disabled: function disabled(inProgress, securityKeyCredential, secondFactorToken) {
      return inProgress || !securityKeyCredential && !secondFactorToken;
    },
    setResult: function setResult(result) {
      this.setProperties({
        otherMethodAllowed: result.multiple_second_factor_methods,
        secondFactorRequired: true,
        showLoginButtons: false,
        backupEnabled: result.backup_enabled,
        showSecondFactor: result.totp_enabled,
        showSecurityKey: result.security_key_enabled,
        secondFactorMethod: result.security_key_enabled ? _user.SECOND_FACTOR_METHODS.SECURITY_KEY : _user.SECOND_FACTOR_METHODS.TOTP,
        securityKeyChallenge: result.challenge,
        securityKeyAllowedCredentialIds: result.allowed_credential_ids
      });
    },
    authenticateSecurityKey: function authenticateSecurityKey() {
      var _this = this;

      (0, _webauthn.getWebauthnCredential)(this.securityKeyChallenge, this.securityKeyAllowedCredentialIds, function (credentialData) {
        _this.set("securityKeyCredential", credentialData);

        _this.send("authenticate");
      }, function (errorMessage) {
        _this.flash(errorMessage, "error");
      });
    },
    authenticate: function authenticate() {
      var _this2 = this;

      this.set("inProgress", true);
      this.model.grantAdmin({
        second_factor_token: this.securityKeyCredential || this.secondFactorToken,
        second_factor_method: this.secondFactorMethod,
        timezone: moment.tz.guess()
      }).then(function (result) {
        if (result.success) {
          _this2.send("closeModal");

          _bootbox.default.alert(_I18n.default.t("admin.user.grant_admin_success"));
        } else {
          _this2.flash(result.error, "error");

          _this2.setResult(result);
        }
      }).finally(function () {
        return _this2.set("inProgress", false);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "disabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "disabled"), _obj), _applyDecoratedDescriptor(_obj, "authenticateSecurityKey", [_dec2], Object.getOwnPropertyDescriptor(_obj, "authenticateSecurityKey"), _obj), _applyDecoratedDescriptor(_obj, "authenticate", [_dec3], Object.getOwnPropertyDescriptor(_obj, "authenticate"), _obj)), _obj)));

  _exports.default = _default;
});