define("discourse/models/bookmark", ["exports", "discourse-common/utils/category-macro", "I18n", "discourse/models/rest", "discourse/models/user", "discourse/models/topic", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/bookmark", "discourse-common/lib/get-url", "discourse/lib/formatter"], function (_exports, _categoryMacro, _I18n, _rest, _user, _topic, _ajax, _decorators, _bookmark, _getUrl, _formatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AUTO_DELETE_PREFERENCES = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var AUTO_DELETE_PREFERENCES = {
    NEVER: 0,
    WHEN_REMINDER_SENT: 1,
    ON_OWNER_REPLY: 2
  };
  _exports.AUTO_DELETE_PREFERENCES = AUTO_DELETE_PREFERENCES;

  var Bookmark = _rest.default.extend((_dec = Ember.computed, _dec2 = (0, _decorators.default)("highest_post_number", "url"), _dec3 = (0, _decorators.default)("bumped_at", "createdAt"), _dec4 = (0, _decorators.default)("bumpedAt", "createdAt"), _dec5 = (0, _decorators.default)("created_at"), _dec6 = (0, _decorators.default)("tags"), _dec7 = (0, _decorators.default)("reminder_at", "currentUser"), _dec8 = (0, _decorators.default)(), _dec9 = (0, _decorators.default)("post_user_username", "post_user_avatar_template", "post_user_name"), (_obj = {
    newBookmark: Ember.computed.none("id"),

    get url() {
      return (0, _getUrl.default)("/bookmarks/".concat(this.id));
    },

    destroy: function destroy() {
      if (this.newBookmark) {
        return Ember.RSVP.Promise.resolve();
      }

      return (0, _ajax.ajax)(this.url, {
        type: "DELETE"
      });
    },
    attachedTo: function attachedTo() {
      if (this.for_topic) {
        return {
          target: "topic",
          targetId: this.topic_id
        };
      }

      return {
        target: "post",
        targetId: this.post_id
      };
    },
    togglePin: function togglePin() {
      if (this.newBookmark) {
        return Ember.RSVP.Promise.resolve();
      }

      return (0, _ajax.ajax)(this.url + "/toggle_pin", {
        type: "PUT"
      });
    },
    pinAction: function pinAction() {
      return this.pinned ? "unpin" : "pin";
    },
    lastPostUrl: function lastPostUrl(highestPostNumber) {
      return this.urlForPostNumber(highestPostNumber);
    },
    // Helper to build a Url with a post number
    urlForPostNumber: function urlForPostNumber(postNumber) {
      var url = (0, _getUrl.default)("/t/".concat(this.topic_id));

      if (postNumber > 0) {
        url += "/".concat(postNumber);
      }

      return url;
    },
    bumpedAt: function bumpedAt(bumped_at, createdAt) {
      if (bumped_at) {
        return new Date(bumped_at);
      } else {
        return createdAt;
      }
    },
    bumpedAtTitle: function bumpedAtTitle(bumpedAt, createdAt) {
      var firstPost = _I18n.default.t("first_post");

      var lastPost = _I18n.default.t("last_post");

      var createdAtDate = (0, _formatter.longDate)(createdAt);
      var bumpedAtDate = (0, _formatter.longDate)(bumpedAt);
      return _I18n.default.messageFormat("topic.bumped_at_title_MF", {
        FIRST_POST: firstPost,
        CREATED_AT: createdAtDate,
        LAST_POST: lastPost,
        BUMPED_AT: bumpedAtDate
      });
    },
    createdAt: function createdAt(created_at) {
      return new Date(created_at);
    },
    visibleListTags: function visibleListTags(tags) {
      if (!tags || !this.siteSettings.suppress_overlapping_tags_in_list) {
        return tags;
      }

      var title = this.title;
      var newTags = [];
      tags.forEach(function (tag) {
        if (title.toLowerCase().indexOf(tag) === -1) {
          newTags.push(tag);
        }
      });
      return newTags;
    },
    category: (0, _categoryMacro.default)("category_id"),
    formattedReminder: function formattedReminder(bookmarkReminderAt, currentUser) {
      return (0, _bookmark.formattedReminderTime)(bookmarkReminderAt, currentUser.resolvedTimezone(currentUser)).capitalize();
    },
    topicForList: function topicForList() {
      // for topic level bookmarks we want to jump to the last unread post URL,
      // which the topic-link helper does by default if no linked post number is
      // provided
      var linkedPostNumber = this.for_topic ? null : this.linked_post_number;
      return _topic.default.create({
        id: this.topic_id,
        fancy_title: this.fancy_title,
        linked_post_number: linkedPostNumber,
        last_read_post_number: this.last_read_post_number,
        highest_post_number: this.highest_post_number
      });
    },
    postUser: function postUser(post_user_username, avatarTemplate, name) {
      return _user.default.create({
        username: post_user_username,
        avatar_template: avatarTemplate,
        name: name
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "url", [_dec], Object.getOwnPropertyDescriptor(_obj, "url"), _obj), _applyDecoratedDescriptor(_obj, "lastPostUrl", [_dec2], Object.getOwnPropertyDescriptor(_obj, "lastPostUrl"), _obj), _applyDecoratedDescriptor(_obj, "bumpedAt", [_dec3], Object.getOwnPropertyDescriptor(_obj, "bumpedAt"), _obj), _applyDecoratedDescriptor(_obj, "bumpedAtTitle", [_dec4], Object.getOwnPropertyDescriptor(_obj, "bumpedAtTitle"), _obj), _applyDecoratedDescriptor(_obj, "createdAt", [_dec5], Object.getOwnPropertyDescriptor(_obj, "createdAt"), _obj), _applyDecoratedDescriptor(_obj, "visibleListTags", [_dec6], Object.getOwnPropertyDescriptor(_obj, "visibleListTags"), _obj), _applyDecoratedDescriptor(_obj, "formattedReminder", [_dec7], Object.getOwnPropertyDescriptor(_obj, "formattedReminder"), _obj), _applyDecoratedDescriptor(_obj, "topicForList", [_dec8], Object.getOwnPropertyDescriptor(_obj, "topicForList"), _obj), _applyDecoratedDescriptor(_obj, "postUser", [_dec9], Object.getOwnPropertyDescriptor(_obj, "postUser"), _obj)), _obj)));

  Bookmark.reopenClass({
    create: function create(args) {
      args = args || {};
      args.currentUser = args.currentUser || _user.default.current();
      return this._super(args);
    }
  });
  var _default = Bookmark;
  _exports.default = _default;
});