define("discourse/mixins/upload-debugging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    _consoleDebug: function _consoleDebug(msg) {
      if (this.siteSettings.enable_upload_debug_mode) {
        // eslint-disable-next-line no-console
        console.log(msg);
      }
    },
    _consolePerformanceTiming: function _consolePerformanceTiming(timing) {
      var minutes = Math.floor(timing.duration / 60000);
      var seconds = (timing.duration % 60000 / 1000).toFixed(0);
      var duration = minutes + ":" + (seconds < 10 ? "0" : "") + seconds;

      this._consoleDebug("".concat(timing.name, ":\n duration: ").concat(duration, " (").concat(timing.duration, "ms)"));
    },
    _performanceApiSupport: function _performanceApiSupport() {
      performance.mark("testing support 1");
      performance.mark("testing support 2");
      var perfMeasure = performance.measure("performance api support", "testing support 1", "testing support 2");
      return perfMeasure;
    },
    _instrumentUploadTimings: function _instrumentUploadTimings() {
      var _this = this;

      if (!this._performanceApiSupport()) {
        // TODO (martin) (2021-01-23) Check if FireFox fixed this yet.
        (false && Ember.warn("Some browsers do not return a PerformanceMeasure when calling performance.mark, disabling instrumentation. See https://developer.mozilla.org/en-US/docs/Web/API/Performance/measure#return_value and https://bugzilla.mozilla.org/show_bug.cgi?id=1724645", {
          id: "discourse.upload-debugging"
        }));
        return;
      }

      this._uppyInstance.on("upload", function (data) {
        data.fileIDs.forEach(function (fileId) {
          return performance.mark("upload-".concat(fileId, "-start"));
        });
      });

      this._uppyInstance.on("create-multipart", function (fileId) {
        performance.mark("upload-".concat(fileId, "-create-multipart"));
      });

      this._uppyInstance.on("create-multipart-success", function (fileId) {
        performance.mark("upload-".concat(fileId, "-create-multipart-success"));
      });

      this._uppyInstance.on("complete-multipart", function (fileId) {
        performance.mark("upload-".concat(fileId, "-complete-multipart"));

        _this._consolePerformanceTiming(performance.measure("upload-".concat(fileId, "-multipart-all-parts-complete"), "upload-".concat(fileId, "-create-multipart-success"), "upload-".concat(fileId, "-complete-multipart")));
      });

      this._uppyInstance.on("complete-multipart-success", function (fileId) {
        performance.mark("upload-".concat(fileId, "-complete-multipart-success"));

        _this._consolePerformanceTiming(performance.measure("upload-".concat(fileId, "-multipart-total-network-exclusive-complete-multipart"), "upload-".concat(fileId, "-create-multipart"), "upload-".concat(fileId, "-complete-multipart")));

        _this._consolePerformanceTiming(performance.measure("upload-".concat(fileId, "-multipart-total-network-inclusive-complete-multipart"), "upload-".concat(fileId, "-create-multipart"), "upload-".concat(fileId, "-complete-multipart-success")));

        _this._consolePerformanceTiming(performance.measure("upload-".concat(fileId, "-multipart-complete-convert-to-upload"), "upload-".concat(fileId, "-complete-multipart"), "upload-".concat(fileId, "-complete-multipart-success")));
      });

      this._uppyInstance.on("upload-success", function (file) {
        performance.mark("upload-".concat(file.id, "-end"));

        _this._consolePerformanceTiming(performance.measure("upload-".concat(file.id, "-multipart-total-inclusive-preprocessing"), "upload-".concat(file.id, "-start"), "upload-".concat(file.id, "-end")));
      });
    }
  });

  _exports.default = _default;
});