define("discourse/components/groups-form-membership-fields", ["exports", "I18n", "discourse-common/utils/decorators", "discourse/models/associated-group"], function (_exports, _I18n, _decorators, _associatedGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("model.visibility_level", "model.public_admission"), _dec2 = (0, _decorators.default)("model.visibility_level", "model.allow_membership_requests"), (_obj = {
    tokenSeparator: "|",
    showAssociatedGroups: Ember.computed.readOnly("site.can_associate_groups"),
    init: function init() {
      this._super.apply(this, arguments);

      this.trustLevelOptions = [{
        name: _I18n.default.t("admin.groups.manage.membership.trust_levels_none"),
        value: 0
      }, {
        name: 1,
        value: 1
      }, {
        name: 2,
        value: 2
      }, {
        name: 3,
        value: 3
      }, {
        name: 4,
        value: 4
      }];

      if (this.showAssociatedGroups) {
        this.loadAssociatedGroups();
      }
    },
    canEdit: Ember.computed.not("model.automatic"),
    groupTrustLevel: Ember.computed("model.grant_trust_level", "trustLevelOptions", function () {
      return this.model.get("grant_trust_level") || this.trustLevelOptions.firstObject.value;
    }),
    disableMembershipRequestSetting: function disableMembershipRequestSetting(visibility_level, publicAdmission) {
      visibility_level = parseInt(visibility_level, 10);
      return publicAdmission || visibility_level > 1;
    },
    disablePublicSetting: function disablePublicSetting(visibility_level, allowMembershipRequests) {
      visibility_level = parseInt(visibility_level, 10);
      return allowMembershipRequests || visibility_level > 1;
    },
    emailDomains: Ember.computed("model.emailDomains", function () {
      return this.model.emailDomains.split(this.tokenSeparator).filter(Boolean);
    }),
    loadAssociatedGroups: function loadAssociatedGroups() {
      var _this = this;

      _associatedGroup.default.list().then(function (ags) {
        return _this.set("associatedGroups", ags);
      });
    },
    actions: {
      onChangeEmailDomainsSetting: function onChangeEmailDomainsSetting(value) {
        this.set("model.automatic_membership_email_domains", value.join(this.tokenSeparator));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "disableMembershipRequestSetting", [_dec], Object.getOwnPropertyDescriptor(_obj, "disableMembershipRequestSetting"), _obj), _applyDecoratedDescriptor(_obj, "disablePublicSetting", [_dec2], Object.getOwnPropertyDescriptor(_obj, "disablePublicSetting"), _obj)), _obj)));

  _exports.default = _default;
});