define("discourse/routes/user-activity-pending", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    beforeModel: function beforeModel() {
      this.username = this.modelFor("user").username_lower;
    },
    model: function model() {
      return this.store.findAll("pending-post", {
        username: this.username
      });
    },
    activate: function activate() {
      this.appEvents.on("count-updated:".concat(this.username, ":pending_posts_count"), this, "_handleCountChange");
    },
    deactivate: function deactivate() {
      this.appEvents.off("count-updated:".concat(this.username, ":pending_posts_count"), this, "_handleCountChange");
    },
    _handleCountChange: function _handleCountChange(count) {
      this.refresh();

      if (count <= 0) {
        this.transitionTo("userActivity");
      }
    }
  });

  _exports.default = _default;
});