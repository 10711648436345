define("discourse/lib/eyeline", ["exports", "discourse-common/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.configureEyeline = configureEyeline;
  _exports.default = void 0;

  var _skipUpdate;

  var _rootElement;

  function configureEyeline(opts) {
    if (opts) {
      _skipUpdate = opts.skipUpdate;
      _rootElement = opts.rootElement;
    } else {
      _skipUpdate = (0, _environment.isTesting)();
      _rootElement = null;
    }
  }

  configureEyeline(); // Track visible elements on the screen.

  var _default = Ember.Object.extend(Ember.Evented, {
    update: function update() {
      var _this = this;

      if (_skipUpdate) {
        return;
      }

      var docViewTop = _rootElement ? $(_rootElement).scrollTop() : $(window).scrollTop();
      var windowHeight = _rootElement ? $(_rootElement).height() : $(window).height();
      var docViewBottom = docViewTop + windowHeight;
      var $elements = $(this.selector);
      var bottomOffset = _rootElement ? $elements.last().position() : $elements.last().offset();
      var atBottom = bottomOffset && bottomOffset.top <= docViewBottom && bottomOffset.top >= docViewTop;
      return $elements.each(function (i, elem) {
        var $elem = $(elem);
        var elemTop = _rootElement ? $elem.position().top : $elem.offset().top;
        var elemBottom = elemTop + $elem.height();
        var markSeen = false; // Make sure the element is visible

        if (!$elem.is(":visible")) {
          return true;
        } // It's seen if...
        // ...the element is vertically within the top and bottom


        if (elemTop <= docViewBottom && elemTop >= docViewTop) {
          markSeen = true;
        } // ...the element top is above the top and the bottom is below the bottom (large elements)


        if (elemTop <= docViewTop && elemBottom >= docViewBottom) {
          markSeen = true;
        } // ...we're at the bottom and the bottom of the element is visible (large bottom elements)


        if (atBottom && elemBottom >= docViewTop) {
          markSeen = true;
        }

        if (!markSeen) {
          return true;
        } // If you hit the bottom we mark all the elements as seen. Otherwise, just the first one


        if (!atBottom) {
          return false;
        }

        if (i === $elements.length - 1) {
          return _this.trigger("sawBottom", {
            detail: $elem
          });
        }
      });
    }
  });

  _exports.default = _default;
});