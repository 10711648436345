define("discourse/routes/group-activity-posts", ["exports", "discourse/routes/discourse", "I18n"], function (_exports, _discourse, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildGroupPage = buildGroupPage;
  _exports.default = void 0;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function buildGroupPage(type) {
    var _dec, _obj;

    return _discourse.default.extend((_dec = Ember._action, (_obj = {
      type: type,
      titleToken: function titleToken() {
        return _I18n.default.t("groups.".concat(type));
      },
      model: function model(params, transition) {
        var categoryId = Ember.get(transition.to, "queryParams.category_id");
        return this.modelFor("group").findPosts({
          type: type,
          categoryId: categoryId
        });
      },
      setupController: function setupController(controller, model) {
        var loadedAll = model.length < 20;
        this.controllerFor("group-activity-posts").setProperties({
          model: model,
          type: type,
          canLoadMore: !loadedAll
        });
        this.controllerFor("application").set("showFooter", loadedAll);
      },
      renderTemplate: function renderTemplate() {
        this.render("group-activity-posts");
      },
      didTransition: function didTransition() {
        return true;
      }
    }, (_applyDecoratedDescriptor(_obj, "didTransition", [_dec], Object.getOwnPropertyDescriptor(_obj, "didTransition"), _obj)), _obj)));
  }

  var _default = buildGroupPage("posts");

  _exports.default = _default;
});