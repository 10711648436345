define("discourse/components/topic-list", ["exports", "discourse-common/utils/decorators", "discourse/mixins/load-more", "discourse-common/lib/debounce", "discourse/lib/show-modal"], function (_exports, _decorators, _loadMore, _debounce, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_loadMore.default, (_dec = (0, _decorators.default)("bulkSelectEnabled"), _dec2 = (0, _decorators.default)("order"), _dec3 = (0, _decorators.default)("order"), _dec4 = (0, _decorators.observes)("topics.[]"), _dec5 = (0, _decorators.observes)("topics", "order", "ascending", "category", "top"), (_obj = {
    tagName: "table",
    classNames: ["topic-list"],
    classNameBindings: ["bulkSelectEnabled:sticky-header"],
    showTopicPostBadges: true,
    listTitle: "topic.title",
    canDoBulkActions: Ember.computed.and("currentUser.staff", "selected.length"),
    // Overwrite this to perform client side filtering of topics, if desired
    filteredTopics: Ember.computed.alias("topics"),
    _init: Ember.on("init", function () {
      this.addObserver("hideCategory", this.rerender);
      this.addObserver("order", this.rerender);
      this.addObserver("ascending", this.rerender);
      this.refreshLastVisited();
    }),
    toggleInTitle: function toggleInTitle(bulkSelectEnabled) {
      return !bulkSelectEnabled && this.canBulkSelect;
    },
    sortable: function sortable() {
      return !!this.changeSort;
    },
    skipHeader: Ember.computed.reads("site.mobileView"),
    showLikes: function showLikes(order) {
      return order === "likes";
    },
    showOpLikes: function showOpLikes(order) {
      return order === "op_likes";
    },
    topicsAdded: function topicsAdded() {
      // special case so we don't keep scanning huge lists
      if (!this.lastVisitedTopic) {
        this.refreshLastVisited();
      }
    },
    lastVisitedTopicChanged: function lastVisitedTopicChanged() {
      this.refreshLastVisited();
    },
    scrolled: function scrolled() {
      this._super.apply(this, arguments);

      var onScroll = this.onScroll;

      if (!onScroll) {
        return;
      }

      onScroll.call(this);
    },
    scrollToLastPosition: function scrollToLastPosition() {
      var _this = this;

      if (!this.scrollOnLoad) {
        return;
      }

      var scrollTo = this.session.get("topicListScrollPosition");

      if (scrollTo && scrollTo >= 0) {
        Ember.run.schedule("afterRender", function () {
          (0, _debounce.default)(_this, function () {
            if (this.element && !this.isDestroying && !this.isDestroyed) {
              $(window).scrollTop(scrollTo + 1);
            }
          }, 0);
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.scrollToLastPosition();
    },
    _updateLastVisitedTopic: function _updateLastVisitedTopic(topics, order, ascending, top) {
      this.set("lastVisitedTopic", null);

      if (!this.highlightLastVisited) {
        return;
      }

      if (order && order !== "activity") {
        return;
      }

      if (top) {
        return;
      }

      if (!topics || topics.length === 1) {
        return;
      }

      if (ascending) {
        return;
      }

      var user = this.currentUser;

      if (!user || !user.previous_visit_at) {
        return;
      }

      var lastVisitedTopic, topic;
      var prevVisit = user.get("previousVisitAt"); // this is more efficient cause we keep appending to list
      // work backwards

      var start = 0;

      while (topics[start] && topics[start].get("pinned")) {
        start++;
      }

      var i;

      for (i = topics.length - 1; i >= start; i--) {
        if (topics[i].get("bumpedAt") > prevVisit) {
          lastVisitedTopic = topics[i];
          break;
        }

        topic = topics[i];
      }

      if (!lastVisitedTopic || !topic) {
        return;
      } // end of list that was scanned


      if (topic.get("bumpedAt") > prevVisit) {
        return;
      }

      this.set("lastVisitedTopic", lastVisitedTopic);
    },
    refreshLastVisited: function refreshLastVisited() {
      this._updateLastVisitedTopic(this.topics, this.order, this.ascending, this.top);
    },
    updateAutoAddTopicsToBulkSelect: function updateAutoAddTopicsToBulkSelect(newVal) {
      this.set("autoAddTopicsToBulkSelect", newVal);
    },
    click: function click(e) {
      var self = this;

      var onClick = function onClick(sel, callback) {
        var target = $(e.target).closest(sel);

        if (target.length === 1) {
          callback.apply(self, [target]);
        }
      };

      onClick("button.bulk-select", function () {
        this.toggleBulkSelect();
        this.rerender();
      });
      onClick("button.bulk-select-all", function () {
        this.updateAutoAddTopicsToBulkSelect(true);
        $("input.bulk-select:not(:checked)").click();
      });
      onClick("button.bulk-clear-all", function () {
        this.updateAutoAddTopicsToBulkSelect(false);
        $("input.bulk-select:checked").click();
      });
      onClick("th.sortable", function (e2) {
        this.changeSort(e2.data("sort-order"));
        this.rerender();
      });
      onClick("button.bulk-select-actions", function () {
        var controller = (0, _showModal.default)("topic-bulk-actions", {
          model: {
            topics: this.selected,
            category: this.category
          },
          title: "topics.bulk.actions"
        });
        var action = this.bulkSelectAction;

        if (action) {
          controller.set("refreshClosure", function () {
            return action();
          });
        }
      });
    },
    keyDown: function keyDown(e) {
      var _this2 = this;

      if (e.key === "Enter" || e.key === " ") {
        var onKeyDown = function onKeyDown(sel, callback) {
          var target = $(e.target).closest(sel);

          if (target.length === 1) {
            callback.apply(_this2, [target]);
          }
        };

        onKeyDown("th.sortable", function (e2) {
          _this2.changeSort(e2.data("sort-order"));

          _this2.rerender();
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "toggleInTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleInTitle"), _obj), _applyDecoratedDescriptor(_obj, "sortable", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "sortable"), _obj), _applyDecoratedDescriptor(_obj, "showLikes", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showLikes"), _obj), _applyDecoratedDescriptor(_obj, "showOpLikes", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showOpLikes"), _obj), _applyDecoratedDescriptor(_obj, "topicsAdded", [_dec4], Object.getOwnPropertyDescriptor(_obj, "topicsAdded"), _obj), _applyDecoratedDescriptor(_obj, "lastVisitedTopicChanged", [_dec5], Object.getOwnPropertyDescriptor(_obj, "lastVisitedTopicChanged"), _obj)), _obj)));

  _exports.default = _default;
});