define("discourse/templates/components/activation-email-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9GioYLP9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[1,[28,\"i18n\",[\"login.provide_new_email\"],null],false],[9],[0,\"\\n\"],[1,[28,\"input\",null,[[\"value\",\"class\"],[[24,[\"email\"]],\"activate-new-email\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/activation-email-form.hbs"
    }
  });

  _exports.default = _default;
});