define("discourse/templates/components/user-notification-schedule-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GUMgDOv3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"tr\",true],[11,\"class\",[29,[\"day \",[22,\"dayLabel\"]]]],[8],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"day-label\"],[8],[1,[22,\"dayLabel\"],false],[9],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"starts-at\"],[8],[0,\"\\n    \"],[1,[28,\"combo-box\",null,[[\"valueProperty\",\"content\",\"value\",\"onChange\"],[\"value\",[24,[\"startTimeOptions\"]],[24,[\"startTimeValue\"]],[24,[\"onChangeStartTime\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"endTimeOptions\"]]],null,{\"statements\":[[0,\"    \"],[7,\"td\",true],[10,\"class\",\"to\"],[8],[1,[28,\"i18n\",[\"user.notification_schedule.to\"],null],false],[9],[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"ends-at\"],[8],[0,\"\\n      \"],[1,[28,\"combo-box\",null,[[\"valueProperty\",\"content\",\"value\",\"onChange\"],[\"value\",[24,[\"endTimeOptions\"]],[24,[\"endTimeValue\"]],[24,[\"onChangeEndTime\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/user-notification-schedule-day.hbs"
    }
  });

  _exports.default = _default;
});