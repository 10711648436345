define("discourse/components/signup-cta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    action: "showCreateAccount",
    actions: {
      neverShow: function neverShow() {
        this.keyValueStore.setItem("anon-cta-never", "t");
        this.session.set("showSignupCta", false);
      },
      hideForSession: function hideForSession() {
        var _this = this;

        this.session.set("hideSignupCta", true);
        this.keyValueStore.setItem("anon-cta-hidden", Date.now());
        Ember.run.later(function () {
          return _this.session.set("showSignupCta", false);
        }, 20 * 1000);
      }
    },
    _turnOffIfHidden: Ember.on("willDestroyElement", function () {
      if (this.session.get("hideSignupCta")) {
        this.session.set("showSignupCta", false);
      }
    })
  });

  _exports.default = _default;
});