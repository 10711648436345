define("discourse/templates/components/badge-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "InNS9hLd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[11,\"placeholder\",[22,\"placeholder\"]],[10,\"name\",\"badges\"],[10,\"class\",\"ember-text-field badge-names\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/badge-selector.hbs"
    }
  });

  _exports.default = _default;
});