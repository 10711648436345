define("discourse/services/logs-notice", ["exports", "discourse-common/utils/decorators", "I18n", "discourse/lib/formatter", "discourse-common/lib/get-url"], function (_exports, _decorators, _I18n, _formatter, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var LOGS_NOTICE_KEY = "logs-notice-text";

  var _default = Ember.Service.extend((_dec = (0, _decorators.default)("text"), _dec2 = (0, _decorators.default)("text"), _dec3 = (0, _decorators.default)("isEmpty", "isAdmin"), _dec4 = (0, _decorators.observes)("text"), (_obj = {
    text: "",
    isAdmin: Ember.computed.readOnly("currentUser.admin"),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.siteSettings.alert_admins_if_errors_per_hour === 0 && this.siteSettings.alert_admins_if_errors_per_minute === 0) {
        return;
      }

      var text = this.keyValueStore.getItem(LOGS_NOTICE_KEY);

      if (text) {
        this.set("text", text);
      }

      this.messageBus.subscribe("/logs_error_rate_exceeded", function (data) {
        var duration = data.duration;
        var rate = data.rate;
        var siteSettingLimit = 0;

        if (duration === "minute") {
          siteSettingLimit = _this.siteSettings.alert_admins_if_errors_per_minute;
        } else if (duration === "hour") {
          siteSettingLimit = _this.siteSettings.alert_admins_if_errors_per_hour;
        }

        var translationKey = rate === siteSettingLimit ? "reached" : "exceeded";
        translationKey += "_".concat(duration, "_MF");

        _this.set("text", _I18n.default.messageFormat("logs_error_rate_notice.".concat(translationKey), {
          relativeAge: (0, _formatter.autoUpdatingRelativeAge)(new Date(data.publish_at * 1000)),
          rate: rate,
          limit: siteSettingLimit,
          url: (0, _getUrl.default)("/logs")
        }));
      });
    },
    isEmpty: function isEmpty(text) {
      return Ember.isEmpty(text);
    },
    message: function message(text) {
      return Ember.String.htmlSafe(text);
    },
    hidden: function hidden(thisIsEmpty, isAdmin) {
      return !isAdmin || thisIsEmpty;
    },
    _updateKeyValueStore: function _updateKeyValueStore() {
      this.keyValueStore.setItem(LOGS_NOTICE_KEY, this.text);
    }
  }, (_applyDecoratedDescriptor(_obj, "isEmpty", [_dec], Object.getOwnPropertyDescriptor(_obj, "isEmpty"), _obj), _applyDecoratedDescriptor(_obj, "message", [_dec2], Object.getOwnPropertyDescriptor(_obj, "message"), _obj), _applyDecoratedDescriptor(_obj, "hidden", [_dec3], Object.getOwnPropertyDescriptor(_obj, "hidden"), _obj), _applyDecoratedDescriptor(_obj, "_updateKeyValueStore", [_dec4], Object.getOwnPropertyDescriptor(_obj, "_updateKeyValueStore"), _obj)), _obj)));

  _exports.default = _default;
});