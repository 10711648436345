define("discourse/routes/user-summary", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    showFooter: true,
    model: function model() {
      var user = this.modelFor("user");

      if (user.get("profile_hidden")) {
        return this.replaceWith("user.profile-hidden");
      }

      return user.summary();
    }
  });

  _exports.default = _default;
});