define("discourse/templates/components/relative-time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IeNCjAXc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"relative-time-picker\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"class\",\"min\",\"step\",\"type\",\"value\",\"onChange\",\"id\"],[\"relative-time-duration\",[24,[\"durationMin\"]],[24,[\"durationStep\"]],\"number\",[24,[\"duration\"]],[28,\"action\",[[23,0,[]],\"onChangeDuration\"],null],[24,[\"id\"]]]]],false],[0,\"\\n  \"],[1,[28,\"combo-box\",null,[[\"content\",\"value\",\"class\",\"onChange\"],[[24,[\"intervals\"]],[24,[\"selectedInterval\"]],\"relative-time-intervals\",[28,\"action\",[[23,0,[]],\"onChangeInterval\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/relative-time-picker.hbs"
    }
  });

  _exports.default = _default;
});