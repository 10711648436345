define("discourse/mixins/textarea-text-manipulation", ["exports", "discourse-common/utils/decorators", "discourse/lib/text", "discourse/lib/to-markdown", "discourse-common/config/environment", "discourse/lib/utilities"], function (_exports, _decorators, _text, _toMarkdown, _environment, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var INDENT_DIRECTION_LEFT = "left";
  var INDENT_DIRECTION_RIGHT = "right";

  var _default = Ember.Mixin.create((_dec = Ember._action, (_obj = {
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _text.generateLinkifyFunction)(this.markdownOptions || {}).then(function (linkify) {
        // When pasting links, we should use the same rules to match links as we do when creating links for a cooked post.
        _this._cachedLinkify = linkify;
      });
    },
    // ensures textarea scroll position is correct
    _focusTextArea: function _focusTextArea() {
      if (!this.element || this.isDestroying || this.isDestroyed) {
        return;
      }

      if (!this._textarea) {
        return;
      }

      this._textarea.blur();

      this._textarea.focus();
    },
    _insertBlock: function _insertBlock(text) {
      this._addBlock(this._getSelected(), text);
    },
    _insertText: function _insertText(text, options) {
      this._addText(this._getSelected(), text, options);
    },
    _getSelected: function _getSelected(trimLeading, opts) {
      if (!this.ready || !this.element) {
        return;
      }

      var value = this._textarea.value;
      var start = this._textarea.selectionStart;
      var end = this._textarea.selectionEnd; // trim trailing spaces cause **test ** would be invalid

      while (end > start && /\s/.test(value.charAt(end - 1))) {
        end--;
      }

      if (trimLeading) {
        // trim leading spaces cause ** test** would be invalid
        while (end > start && /\s/.test(value.charAt(start))) {
          start++;
        }
      }

      var selVal = value.substring(start, end);
      var pre = value.slice(0, start);
      var post = value.slice(end);

      if (opts && opts.lineVal) {
        var lineVal = value.split("\n")[value.substr(0, this._textarea.selectionStart).split("\n").length - 1];
        return {
          start: start,
          end: end,
          value: selVal,
          pre: pre,
          post: post,
          lineVal: lineVal
        };
      } else {
        return {
          start: start,
          end: end,
          value: selVal,
          pre: pre,
          post: post
        };
      }
    },
    _selectText: function _selectText(from, length) {
      var _this2 = this;

      var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
        scroll: true
      };
      Ember.run.next(function () {
        if (!_this2.element) {
          return;
        }

        _this2._textarea.selectionStart = from;
        _this2._textarea.selectionEnd = from + length;

        _this2._$textarea.trigger("change");

        if (opts.scroll) {
          var oldScrollPos = _this2._$textarea.scrollTop();

          if (!_this2.capabilities.isIOS) {
            _this2._$textarea.focus();
          }

          _this2._$textarea.scrollTop(oldScrollPos);
        }
      });
    },
    _replaceText: function _replaceText(oldVal, newVal) {
      var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var val = this.value;
      var needleStart = val.indexOf(oldVal);

      if (needleStart === -1) {
        // Nothing to replace.
        return;
      } // Determine post-replace selection.


      var newSelection = (0, _utilities.determinePostReplaceSelection)({
        selection: {
          start: this._textarea.selectionStart,
          end: this._textarea.selectionEnd
        },
        needle: {
          start: needleStart,
          end: needleStart + oldVal.length
        },
        replacement: {
          start: needleStart,
          end: needleStart + newVal.length
        }
      });

      if (opts.index && opts.regex) {
        var i = -1;
        var newValue = val.replace(opts.regex, function (match) {
          i++;
          return i === opts.index ? newVal : match;
        });
        this.set("value", newValue);
      } else {
        // Replace value (side effect: cursor at the end).
        this.set("value", val.replace(oldVal, newVal));
      }

      if ((opts.forceFocus || this._$textarea.is(":focus")) && !opts.skipNewSelection) {
        // Restore cursor.
        this._selectText(newSelection.start, newSelection.end - newSelection.start);
      }
    },
    _addBlock: function _addBlock(sel, text) {
      text = (text || "").trim();

      if (text.length === 0) {
        return;
      }

      var pre = sel.pre;
      var post = sel.value + sel.post;

      if (pre.length > 0) {
        pre = pre.replace(/\n*$/, "\n\n");
      }

      if (post.length > 0) {
        post = post.replace(/^\n*/, "\n\n");
      } else {
        post = "\n";
      }

      var value = pre + text + post;
      this.set("value", value);

      this._$textarea.val(value);

      this._$textarea.prop("selectionStart", (pre + text).length + 2);

      this._$textarea.prop("selectionEnd", (pre + text).length + 2);

      Ember.run.schedule("afterRender", this, this._focusTextArea);
    },
    _addText: function _addText(sel, text, options) {
      var _this3 = this;

      if (options && options.ensureSpace) {
        if ((sel.pre + "").length > 0) {
          if (!sel.pre.match(/\s$/)) {
            text = " " + text;
          }
        }

        if ((sel.post + "").length > 0) {
          if (!sel.post.match(/^\s/)) {
            text = text + " ";
          }
        }
      }

      var insert = "".concat(sel.pre).concat(text);
      var value = "".concat(insert).concat(sel.post);
      this.set("value", value);

      this._$textarea.val(value);

      this._$textarea.prop("selectionStart", insert.length);

      this._$textarea.prop("selectionEnd", insert.length);

      Ember.run.next(function () {
        return _this3._$textarea.trigger("change");
      });

      this._focusTextArea();
    },
    _extractTable: function _extractTable(text) {
      if (text.endsWith("\n")) {
        text = text.substring(0, text.length - 1);
      }

      text = text.split("");
      var cell = false;
      text.forEach(function (char, index) {
        if (char === "\n" && cell) {
          text[index] = "\r";
        }

        if (char === '"') {
          text[index] = "";
          cell = !cell;
        }
      });
      var rows = text.join("").replace(/\r/g, "<br>").split("\n");

      if (rows.length > 1) {
        var columns = rows.map(function (r) {
          return r.split("\t").length;
        });
        var isTable = columns.reduce(function (a, b) {
          return a && columns[0] === b && b > 1;
        }) && !(columns[0] === 2 && rows[0].split("\t")[0].match(/^•$|^\d+.$/)); // to skip tab delimited lists

        if (isTable) {
          var splitterRow = _toConsumableArray(Array(columns[0])).map(function () {
            return "---";
          }).join("\t");

          rows.splice(1, 0, splitterRow);
          return "|" + rows.map(function (r) {
            return r.split("\t").join("|");
          }).join("|\n|") + "|\n";
        }
      }

      return null;
    },
    _isInside: function _isInside(text, regex) {
      var matches = text.match(regex);
      return matches && matches.length % 2;
    },
    paste: function paste(e) {
      if (!this._$textarea.is(":focus") && !(0, _environment.isTesting)()) {
        return;
      }

      var isComposer = $(this.composerFocusSelector).is(":focus");

      var _clipboardHelpers = (0, _utilities.clipboardHelpers)(e, {
        siteSettings: this.siteSettings,
        canUpload: isComposer
      }),
          clipboard = _clipboardHelpers.clipboard,
          canPasteHtml = _clipboardHelpers.canPasteHtml,
          canUpload = _clipboardHelpers.canUpload;

      var plainText = clipboard.getData("text/plain");
      var html = clipboard.getData("text/html");
      var handled = false;

      var selected = this._getSelected(null, {
        lineVal: true
      });

      var pre = selected.pre,
          selectedValue = selected.value,
          lineVal = selected.lineVal;
      var isInlinePasting = pre.match(/[^\n]$/);

      var isCodeBlock = this._isInside(pre, /(^|\n)```/g);

      if (plainText && this.siteSettings.enable_rich_text_paste && !isInlinePasting && !isCodeBlock) {
        plainText = plainText.replace(/\r/g, "");

        var table = this._extractTable(plainText);

        if (table) {
          this.appEvents.trigger("composer:insert-text", table);
          handled = true;
        }
      }

      if (canPasteHtml && plainText) {
        if (isInlinePasting) {
          canPasteHtml = !(lineVal.match(/^```/) || this._isInside(pre, /`/g) || lineVal.match(/^    /));
        } else {
          canPasteHtml = !isCodeBlock;
        }
      }

      if (this._cachedLinkify && plainText && !handled && selected.end > selected.start && // text selection does not contain url
      !this._cachedLinkify.test(selectedValue) && // text selection does not contain a bbcode-like tag
      !selectedValue.match(/\[\/?[a-z =]+?\]/g)) {
        if (this._cachedLinkify.test(plainText)) {
          var match = this._cachedLinkify.match(plainText)[0];

          if (match && match.index === 0 && match.lastIndex === match.raw.length) {
            // When specified, linkify supports fuzzy links and emails. Prefer providing the protocol.
            // eg: pasting "example@discourse.org" may apply a link format of "mailto:example@discourse.org"
            this._addText(selected, "[".concat(selectedValue, "](").concat(match.url, ")"));

            handled = true;
          }
        }
      }

      if (canPasteHtml && !handled) {
        var markdown = (0, _toMarkdown.default)(html);

        if (!plainText || plainText.length < markdown.length) {
          if (isInlinePasting) {
            markdown = markdown.replace(/^#+/, "").trim();
            markdown = pre.match(/\S$/) ? " ".concat(markdown) : markdown;
          }

          if (isComposer) {
            this.appEvents.trigger("composer:insert-text", markdown);
            handled = true;
          }
        }
      }

      if (handled || canUpload && !plainText) {
        e.preventDefault();
      }
    },

    /**
     * Removes the provided char from the provided str up
     * until the limit, or until a character that is _not_
     * the provided one is encountered.
     */
    _deindentLine: function _deindentLine(str, char, limit) {
      var eaten = 0;

      for (var i = 0; i < str.length; i++) {
        if (eaten < limit && str[i] === char) {
          eaten += 1;
        } else {
          return str.slice(eaten);
        }
      }

      return str;
    },
    _indentSelection: function _indentSelection(direction) {
      var _value$match,
          _value$match2,
          _this4 = this;

      if (![INDENT_DIRECTION_LEFT, INDENT_DIRECTION_RIGHT].includes(direction)) {
        return;
      }

      var selected = this._getSelected(null, {
        lineVal: true
      });

      var lineVal = selected.lineVal;
      var value = selected.value; // Perhaps this is a bit simplistic, but it is a fairly reliable
      // guess to say whether we are indenting with tabs or spaces. for
      // example some programming languages prefer tabs, others prefer
      // spaces, and for the cases with no tabs it's safer to use spaces

      var indentationSteps, indentationChar;
      var linesStartingWithTabCount = ((_value$match = value.match(/^\t/gm)) === null || _value$match === void 0 ? void 0 : _value$match.length) || 0;
      var linesStartingWithSpaceCount = ((_value$match2 = value.match(/^ /gm)) === null || _value$match2 === void 0 ? void 0 : _value$match2.length) || 0;

      if (linesStartingWithTabCount > linesStartingWithSpaceCount) {
        indentationSteps = 1;
        indentationChar = "\t";
      } else {
        indentationChar = " ";
        indentationSteps = 2;
      } // We want to include all the spaces on the selected line as
      // well, no matter where the cursor begins on the first line,
      // because we want to indent those too. * is the cursor/selection
      // and . are spaces:
      //
      // BEFORE               AFTER
      //
      //     *                *
      // ....text here        ....text here
      // ....some more text   ....some more text
      //                  *                    *
      //
      // BEFORE               AFTER
      //
      //  *                   *
      // ....text here        ....text here
      // ....some more text   ....some more text
      //                  *                    *


      var indentationRegexp = new RegExp("^".concat(indentationChar, "+"));
      var lineStartsWithIndentationChar = lineVal.match(indentationRegexp);
      var intentationCharsBeforeSelection = value.match(indentationRegexp);

      if (lineStartsWithIndentationChar) {
        var charsToSubtract = intentationCharsBeforeSelection ? intentationCharsBeforeSelection[0] : "";
        value = lineStartsWithIndentationChar[0].replace(charsToSubtract, "") + value;
      }

      var splitSelection = value.split("\n");
      var newValue = splitSelection.map(function (line) {
        if (direction === INDENT_DIRECTION_LEFT) {
          return _this4._deindentLine(line, indentationChar, indentationSteps);
        } else {
          return "".concat(Array(indentationSteps + 1).join(indentationChar)).concat(line);
        }
      }).join("\n");

      if (newValue.trim() !== "") {
        this._replaceText(value, newValue, {
          skipNewSelection: true
        });

        this._selectText(this.value.indexOf(newValue), newValue.length);
      }
    },
    emojiSelected: function emojiSelected(code) {
      var selected = this._getSelected();

      var captures = selected.pre.match(/\B:(\w*)$/);

      if (Ember.isEmpty(captures)) {
        if (selected.pre.match(/\S$/)) {
          this._addText(selected, " :".concat(code, ":"));
        } else {
          this._addText(selected, ":".concat(code, ":"));
        }
      } else {
        var numOfRemovedChars = selected.pre.length - captures[1].length;
        selected.pre = selected.pre.slice(0, selected.pre.length - captures[1].length);
        selected.start -= numOfRemovedChars;
        selected.end -= numOfRemovedChars;

        this._addText(selected, "".concat(code, ":"));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "paste", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "paste"), _obj), _applyDecoratedDescriptor(_obj, "_indentSelection", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_indentSelection"), _obj), _applyDecoratedDescriptor(_obj, "emojiSelected", [_dec], Object.getOwnPropertyDescriptor(_obj, "emojiSelected"), _obj)), _obj)));

  _exports.default = _default;
});