define("discourse/routes/account-created-edit-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      var accountCreated = this.controllerFor("account-created").get("accountCreated");
      controller.set("accountCreated", accountCreated);
      controller.set("newEmail", accountCreated.email);
    }
  });

  _exports.default = _default;
});