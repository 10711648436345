define("discourse/components/group-manage-save-button", ["exports", "discourse/lib/url", "I18n", "discourse-common/utils/decorators", "discourse/lib/ajax-error", "discourse/controllers/groups-new", "discourse/lib/show-modal"], function (_exports, _url, _I18n, _decorators, _ajaxError, _groupsNew, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("saving"), (_obj = {
    saving: null,
    disabled: false,
    updateExistingUsers: null,
    savingText: function savingText(saving) {
      return saving ? _I18n.default.t("saving") : _I18n.default.t("save");
    },
    actions: {
      save: function save() {
        var _this = this;

        if (this.beforeSave) {
          this.beforeSave();
        }

        this.set("saving", true);
        var group = this.model;
        (0, _groupsNew.popupAutomaticMembershipAlert)(group.id, group.automatic_membership_email_domains);
        var opts = {};

        if (this.updateExistingUsers !== null) {
          opts.update_existing_users = this.updateExistingUsers;
        }

        return group.save(opts).then(function (data) {
          if (data.user_count) {
            var controller = (0, _showModal.default)("group-default-notifications", {
              model: {
                count: data.user_count
              }
            });
            controller.set("onClose", function () {
              _this.updateExistingUsers = controller.updateExistingUsers;

              _this.send("save");
            });
            return;
          }

          if (data.route_to) {
            _url.default.routeTo(data.route_to);
          }

          _this.setProperties({
            saved: true,
            updateExistingUsers: null
          });

          if (_this.afterSave) {
            _this.afterSave();
          }
        }).catch(_ajaxError.popupAjaxError).finally(function () {
          return _this.set("saving", false);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "savingText", [_dec], Object.getOwnPropertyDescriptor(_obj, "savingText"), _obj)), _obj)));

  _exports.default = _default;
});