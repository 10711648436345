define("discourse/components/create-topics-notice", ["exports", "discourse-common/utils/decorators", "I18n", "discourse/models/live-post-counts"], function (_exports, _decorators, _I18n, _livePostCounts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)(), _dec2 = (0, _decorators.default)("enabled", "shouldSee", "publicTopicCount", "publicPostCount"), _dec3 = (0, _decorators.default)("publicTopicCount", "publicPostCount", "topicTrackingState.incomingCount"), _dec4 = (0, _decorators.observes)("topicTrackingState.incomingCount"), (_obj = {
    classNameBindings: ["hidden:hidden", ":create-topics-notice"],
    enabled: false,
    publicTopicCount: null,
    publicPostCount: null,
    requiredTopics: 5,
    requiredPosts: Ember.computed.alias("siteSettings.tl1_requires_read_posts"),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.shouldSee) {
        var topicCount = 0,
            postCount = 0; // Use data we already have before fetching live stats

        this.site.get("categories").forEach(function (c) {
          if (!c.get("read_restricted")) {
            topicCount += c.get("topic_count");
            postCount += c.get("post_count");
          }
        });

        if (topicCount < this.requiredTopics || postCount < this.requiredPosts) {
          this.set("enabled", true);
          this.fetchLiveStats();
        }
      }
    },
    shouldSee: function shouldSee() {
      var user = this.currentUser;
      return user && user.get("admin") && this.siteSettings.show_create_topics_notice && !this.site.get("wizard_required");
    },
    hidden: function hidden() {
      return !this.enabled || !this.shouldSee || this.publicTopicCount == null || this.publicPostCount == null;
    },
    message: function message() {
      var msg = null;

      if (this.publicTopicCount < this.requiredTopics && this.publicPostCount < this.requiredPosts) {
        msg = "too_few_topics_and_posts_notice_MF";
      } else if (this.publicTopicCount < this.requiredTopics) {
        msg = "too_few_topics_notice_MF";
      } else {
        msg = "too_few_posts_notice_MF";
      }

      return Ember.String.htmlSafe(_I18n.default.messageFormat(msg, {
        requiredTopics: this.requiredTopics,
        requiredPosts: this.requiredPosts,
        currentTopics: this.publicTopicCount,
        currentPosts: this.publicPostCount
      }));
    },
    fetchLiveStats: function fetchLiveStats() {
      var _this = this;

      if (!this.enabled) {
        return;
      }

      _livePostCounts.default.find().then(function (stats) {
        if (stats) {
          _this.set("publicTopicCount", stats.get("public_topic_count"));

          _this.set("publicPostCount", stats.get("public_post_count"));

          if (_this.publicTopicCount >= _this.requiredTopics && _this.publicPostCount >= _this.requiredPosts) {
            _this.set("enabled", false); // No more checks

          }
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "shouldSee", [_dec], Object.getOwnPropertyDescriptor(_obj, "shouldSee"), _obj), _applyDecoratedDescriptor(_obj, "hidden", [_dec2], Object.getOwnPropertyDescriptor(_obj, "hidden"), _obj), _applyDecoratedDescriptor(_obj, "message", [_dec3], Object.getOwnPropertyDescriptor(_obj, "message"), _obj), _applyDecoratedDescriptor(_obj, "fetchLiveStats", [_dec4], Object.getOwnPropertyDescriptor(_obj, "fetchLiveStats"), _obj)), _obj)));

  _exports.default = _default;
});