define("discourse/initializers/inject-objects", ["exports", "discourse-common/lib/get-owner", "discourse-common/config/environment", "discourse/models/user", "discourse/models/site", "discourse-common/lib/deprecated"], function (_exports, _getOwner, _environment, _user, _site, _deprecated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "inject-objects",
    after: (0, _environment.isLegacyEmber)() ? null : "export-application-global",
    initialize: function initialize(container, app) {
      // This is required for Ember CLI tests to work
      (0, _getOwner.setDefaultOwner)(app.__container__); // Backwards compatibility for Discourse.SiteSettings and Discourse.User

      if (!(0, _environment.isLegacyEmber)()) {
        Object.defineProperty(app, "SiteSettings", {
          get: function get() {
            (0, _deprecated.default)("use injected siteSettings instead of Discourse.SiteSettings", {
              since: "2.8",
              dropFrom: "2.9"
            });
            return container.lookup("site-settings:main");
          }
        });
        Object.defineProperty(app, "User", {
          get: function get() {
            (0, _deprecated.default)("import discourse/models/user instead of using Discourse.User", {
              since: "2.8",
              dropFrom: "2.9"
            });
            return _user.default;
          }
        });
        Object.defineProperty(app, "Site", {
          get: function get() {
            (0, _deprecated.default)("import discourse/models/site instead of using Discourse.Site", {
              since: "2.8",
              dropFrom: "2.9"
            });
            return _site.default;
          }
        });
      }
    }
  };
  _exports.default = _default;
});