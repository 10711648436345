define("discourse/pre-initializers/theme-errors-handler", ["exports", "discourse-common/config/environment", "discourse/app", "discourse/lib/preload-store", "discourse-common/lib/get-url", "I18n", "discourse-common/utils/decorators"], function (_exports, _environment, _app, _preloadStore, _getUrl, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var showingErrors = new Set();

  var _default = (_obj = {
    name: "theme-errors-handler",
    after: "inject-discourse-objects",
    initialize: function initialize(container) {
      var _this = this;

      if ((0, _environment.isTesting)()) {
        return;
      }

      this.currentUser = container.lookup("current-user:main");
      (0, _app.getAndClearUnhandledThemeErrors)().forEach(function (e) {
        reportThemeError(_this.currentUser, e);
      });
      document.addEventListener("discourse-error", this.handleDiscourseError);
    },
    teardown: function teardown() {
      document.removeEventListener("discourse-error", this.handleDiscourseError);
      delete this.currentUser;
    },
    handleDiscourseError: function handleDiscourseError(e) {
      var _e$detail;

      if ((_e$detail = e.detail) !== null && _e$detail !== void 0 && _e$detail.themeId) {
        reportThemeError(this.currentUser, e);
      } else {
        reportGenericError(this.currentUser, e);
      }

      e.preventDefault(); // Mark as handled
    }
  }, (_applyDecoratedDescriptor(_obj, "handleDiscourseError", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "handleDiscourseError"), _obj)), _obj);

  _exports.default = _default;

  function reportToLogster(name, error) {
    var data = {
      message: "".concat(name, " theme/component is throwing errors"),
      stacktrace: error.stack
    };
    Ember.$.ajax((0, _getUrl.default)("/logs/report_js_error"), {
      data: data,
      type: "POST"
    });
  }

  function reportThemeError(currentUser, e) {
    var _e$detail2 = e.detail,
        themeId = _e$detail2.themeId,
        error = _e$detail2.error;
    var name = _preloadStore.default.get("activatedThemes")[themeId] || "(theme-id: ".concat(themeId, ")");
    /* eslint-disable-next-line no-console */

    console.error("An error occurred in the \"".concat(name, "\" theme/component:"), error);
    reportToLogster(name, error);
    var path = (0, _getUrl.default)("/admin/customize/themes");

    var message = _I18n.default.t("themes.broken_theme_alert", {
      theme: name,
      path: "<a href=\"".concat(path, "\">").concat(path, "</a>")
    });

    displayErrorNotice(currentUser, message);
  }

  function reportGenericError(currentUser, e) {
    var _e$detail3 = e.detail,
        messageKey = _e$detail3.messageKey,
        error = _e$detail3.error;
    /* eslint-disable-next-line no-console */

    console.error(error);

    if (messageKey && !showingErrors.has(messageKey)) {
      showingErrors.add(messageKey);
      displayErrorNotice(currentUser, _I18n.default.t(messageKey));
    }
  }

  function displayErrorNotice(currentUser, message) {
    if (!(currentUser !== null && currentUser !== void 0 && currentUser.admin)) {
      return;
    }

    var alertDiv = document.createElement("div");
    alertDiv.classList.add("broken-theme-alert");
    alertDiv.innerHTML = "\u26A0\uFE0F ".concat(message);
    document.body.prepend(alertDiv);
  }
});