define("discourse/templates/components/topic-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XiKKAepH",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"topicListItemContents\"],false],[0,\"\\n\\n\"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"args\"],[\"after-topic-list-item\",[28,\"hash\",null,[[\"topic\"],[[24,[\"topic\"]]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/topic-list-item.hbs"
    }
  });

  _exports.default = _default;
});