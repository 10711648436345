define("discourse/templates/components/directory-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WmYHMyhS",
    "block": "{\"symbols\":[\"item\",\"column\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"directory-table-top-scroll\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"directory-table-top-scroll-fake-content\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"directory-table-container\"],[8],[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"directory-table\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n      \"],[1,[28,\"table-header-toggle\",null,[[\"field\",\"order\",\"asc\"],[\"username\",[24,[\"order\"]],[24,[\"asc\"]]]]],false],[0,\"\\n\"],[4,\"each\",[[24,[\"columns\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"table-header-toggle\",null,[[\"field\",\"icon\",\"order\",\"asc\",\"automatic\",\"translated\",\"onActiveRender\"],[[23,2,[\"name\"]],[23,2,[\"icon\"]],[24,[\"order\"]],[24,[\"asc\"]],[28,\"directory-column-is-automatic\",null,[[\"column\"],[[23,2,[]]]]],[23,2,[\"user_field_id\"]],[24,[\"setActiveHeader\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showTimeRead\"]]],null,{\"statements\":[[0,\"        \"],[7,\"th\",true],[8],[1,[28,\"i18n\",[\"directory.time_read\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"directory-item\",null,[[\"item\",\"columns\",\"showTimeRead\"],[[23,1,[]],[24,[\"columns\"]],[24,[\"showTimeRead\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/directory-table.hbs"
    }
  });

  _exports.default = _default;
});