define("discourse/templates/components/security-key-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r2x6IDJA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"security-key\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"icon\",\"id\",\"label\",\"type\",\"class\"],[[24,[\"action\"]],\"key\",\"security-key-authenticate-button\",\"login.security_key_authenticate\",\"button\",\"btn btn-large btn-primary\"]]],false],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"otherMethodAllowed\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"toggle-second-factor-method\"],[3,\"action\",[[23,0,[]],\"useAnotherMethod\"]],[8],[1,[28,\"i18n\",[\"login.security_key_alternative\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/security-key-form.hbs"
    }
  });

  _exports.default = _default;
});