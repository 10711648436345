define("discourse/components/edit-topic-timer-form", ["exports", "discourse/controllers/edit-topic-timer", "select-kit/components/future-date-input-selector", "discourse-common/utils/decorators", "I18n", "discourse/lib/time-utils", "discourse/lib/keyboard-shortcuts", "discourse/lib/time-shortcut", "@discourse/itsatrap"], function (_exports, _editTopicTimer, _futureDateInputSelector, _decorators, _I18n, _timeUtils, _keyboardShortcuts, _timeShortcut, _itsatrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("autoDeleteReplies"), _dec2 = (0, _decorators.default)("topic.visible"), _dec3 = (0, _decorators.default)(), _dec4 = (0, _decorators.default)("topicTimer.updateTime", "topicTimer.duration_minutes", "useDuration"), _dec5 = (0, _decorators.default)("isBasedOnLastPost", "topicTimer.duration_minutes", "topic.last_posted_at"), _dec6 = (0, _decorators.default)("isBasedOnLastPost", "topic.last_posted_at"), _dec7 = (0, _decorators.default)("durationType"), _dec8 = (0, _decorators.default)("statusType", "isCustom", "topicTimer.updateTime", "willCloseImmediately", "topicTimer.category_id", "useDuration", "topicTimer.duration_minutes"), _dec9 = Ember._action, _dec10 = Ember._action, (_obj = {
    statusType: Ember.computed.readOnly("topicTimer.status_type"),
    autoOpen: Ember.computed.equal("statusType", _editTopicTimer.OPEN_STATUS_TYPE),
    autoClose: Ember.computed.equal("statusType", _editTopicTimer.CLOSE_STATUS_TYPE),
    autoCloseAfterLastPost: Ember.computed.equal("statusType", _editTopicTimer.CLOSE_AFTER_LAST_POST_STATUS_TYPE),
    autoDelete: Ember.computed.equal("statusType", _editTopicTimer.DELETE_STATUS_TYPE),
    autoBump: Ember.computed.equal("statusType", _editTopicTimer.BUMP_TYPE),
    publishToCategory: Ember.computed.equal("statusType", _editTopicTimer.PUBLISH_TO_CATEGORY_STATUS_TYPE),
    autoDeleteReplies: Ember.computed.equal("statusType", _editTopicTimer.DELETE_REPLIES_TYPE),
    showTimeOnly: Ember.computed.or("autoOpen", "autoDelete", "autoBump"),
    showFutureDateInput: Ember.computed.or("showTimeOnly", "publishToCategory", "autoClose"),
    useDuration: Ember.computed.or("isBasedOnLastPost", "autoDeleteReplies", "autoCloseAfterLastPost"),
    duration: null,
    _itsatrap: null,
    init: function init() {
      this._super.apply(this, arguments);

      _keyboardShortcuts.default.pause();

      this.set("_itsatrap", new _itsatrap.default());
      this.set("duration", this.initialDuration);
    },

    get initialDuration() {
      if (!this.useDuration || !this.topicTimer.duration_minutes) {
        return null;
      } else if (this.durationType === "days") {
        return this.topicTimer.duration_minutes / 60 / 24;
      } else {
        return this.topicTimer.duration_minutes / 60;
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._itsatrap.destroy();

      this.set("_itsatrap", null);

      _keyboardShortcuts.default.unpause();
    },
    durationType: function durationType(autoDeleteReplies) {
      return autoDeleteReplies ? "days" : "hours";
    },
    excludeCategoryId: function excludeCategoryId(visible) {
      if (visible) {
        return this.get("topic.category_id");
      }
    },
    customTimeShortcutOptions: function customTimeShortcutOptions() {
      var timezone = this.currentUser.resolvedTimezone(this.currentUser);
      return [{
        icon: "far-clock",
        id: "two_weeks",
        label: "time_shortcut.two_weeks",
        time: (0, _timeUtils.startOfDay)((0, _timeUtils.now)(timezone).add(2, "weeks").day(_timeUtils.MOMENT_MONDAY)),
        timeFormatKey: "dates.long_no_year"
      }, {
        icon: "far-calendar-plus",
        id: "six_months",
        label: "time_shortcut.six_months",
        time: (0, _timeUtils.startOfDay)((0, _timeUtils.now)(timezone).add(6, "months").startOf("month")),
        timeFormatKey: "dates.long_no_year"
      }];
    },
    hiddenTimeShortcutOptions: function hiddenTimeShortcutOptions() {
      return [_timeShortcut.TIME_SHORTCUT_TYPES.NONE, _timeShortcut.TIME_SHORTCUT_TYPES.LATER_TODAY, _timeShortcut.TIME_SHORTCUT_TYPES.LATER_THIS_WEEK];
    },
    isCustom: Ember.computed.equal("timerType", "custom"),
    isBasedOnLastPost: Ember.computed.equal("statusType", "close_after_last_post"),
    executeAt: function executeAt(updateTime, duration, useDuration) {
      if (useDuration) {
        return moment().add(parseFloat(duration), "minutes").format(_futureDateInputSelector.FORMAT);
      } else {
        return updateTime;
      }
    },
    willCloseImmediately: function willCloseImmediately(isBasedOnLastPost, duration, lastPostedAt) {
      if (isBasedOnLastPost && duration) {
        var closeDate = moment(lastPostedAt);
        closeDate = closeDate.add(duration, "minutes");
        return closeDate < moment();
      }
    },
    willCloseI18n: function willCloseI18n(isBasedOnLastPost, lastPostedAt) {
      if (isBasedOnLastPost) {
        var diff = Math.round((new Date() - new Date(lastPostedAt)) / (1000 * 60 * 60));
        return _I18n.default.t("topic.auto_close_momentarily", {
          count: diff
        });
      }
    },
    durationLabel: function durationLabel(durationType) {
      return _I18n.default.t("topic.topic_status_update.num_of_".concat(durationType));
    },
    showTopicTimerInfo: function showTopicTimerInfo(statusType, isCustom, updateTime, willCloseImmediately, categoryId, useDuration, duration) {
      if (!statusType || willCloseImmediately) {
        return false;
      }

      if (statusType === _editTopicTimer.PUBLISH_TO_CATEGORY_STATUS_TYPE && Ember.isEmpty(categoryId)) {
        return false;
      }

      if (isCustom && updateTime) {
        if (moment(updateTime) < moment()) {
          return false;
        }
      } else if (useDuration) {
        return duration;
      }

      return updateTime;
    },
    onTimeSelected: function onTimeSelected(type, time) {
      this.set("timerType", type);
      this.onChangeInput(type, time);
    },
    durationChanged: function durationChanged(newDurationMins) {
      this.set("topicTimer.duration_minutes", newDurationMins);
    }
  }, (_applyDecoratedDescriptor(_obj, "durationType", [_dec], Object.getOwnPropertyDescriptor(_obj, "durationType"), _obj), _applyDecoratedDescriptor(_obj, "excludeCategoryId", [_dec2], Object.getOwnPropertyDescriptor(_obj, "excludeCategoryId"), _obj), _applyDecoratedDescriptor(_obj, "customTimeShortcutOptions", [_dec3], Object.getOwnPropertyDescriptor(_obj, "customTimeShortcutOptions"), _obj), _applyDecoratedDescriptor(_obj, "hiddenTimeShortcutOptions", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "hiddenTimeShortcutOptions"), _obj), _applyDecoratedDescriptor(_obj, "executeAt", [_dec4], Object.getOwnPropertyDescriptor(_obj, "executeAt"), _obj), _applyDecoratedDescriptor(_obj, "willCloseImmediately", [_dec5], Object.getOwnPropertyDescriptor(_obj, "willCloseImmediately"), _obj), _applyDecoratedDescriptor(_obj, "willCloseI18n", [_dec6], Object.getOwnPropertyDescriptor(_obj, "willCloseI18n"), _obj), _applyDecoratedDescriptor(_obj, "durationLabel", [_dec7], Object.getOwnPropertyDescriptor(_obj, "durationLabel"), _obj), _applyDecoratedDescriptor(_obj, "showTopicTimerInfo", [_dec8], Object.getOwnPropertyDescriptor(_obj, "showTopicTimerInfo"), _obj), _applyDecoratedDescriptor(_obj, "onTimeSelected", [_dec9], Object.getOwnPropertyDescriptor(_obj, "onTimeSelected"), _obj), _applyDecoratedDescriptor(_obj, "durationChanged", [_dec10], Object.getOwnPropertyDescriptor(_obj, "durationChanged"), _obj)), _obj)));

  _exports.default = _default;
});