define("discourse/templates/mobile/components/directory-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XA2Us0Ht",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[1,[28,\"user-info\",null,[[\"user\"],[[24,[\"item\",\"user\"]]]]],false],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"columns\"]]],null,{\"statements\":[[4,\"if\",[[28,\"directory-column-is-user-field\",null,[[\"column\"],[[23,1,[]]]]]],null,{\"statements\":[[4,\"if\",[[28,\"get\",[[24,[\"item\",\"user\",\"user_fields\"]],[23,1,[\"user_field_id\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"user-stat\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"value user-field\"],[8],[0,\"\\n          \"],[1,[28,\"directory-item-user-field-value\",null,[[\"item\",\"column\"],[[24,[\"item\"]],[23,1,[]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"\\n          \"],[1,[23,1,[\"name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"user-stat\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"value\"],[8],[0,\"\\n        \"],[1,[28,\"directory-item-value\",null,[[\"item\",\"column\"],[[24,[\"item\"]],[23,1,[]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"icon\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"d-icon\",[[23,1,[\"icon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[1,[28,\"mobile-directory-item-label\",null,[[\"item\",\"column\"],[[24,[\"item\"]],[23,1,[]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showTimeRead\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"user-stat\",null,[[\"value\",\"label\",\"type\"],[[24,[\"item\",\"time_read\"]],\"directory.time_read\",\"duration\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/mobile/components/directory-item.hbs"
    }
  });

  _exports.default = _default;
});