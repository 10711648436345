define("discourse/components/suggested-topics", ["exports", "I18n", "discourse/models/site", "discourse/helpers/category-link", "discourse-common/utils/decorators", "discourse-common/lib/get-url", "discourse-common/lib/icon-library"], function (_exports, _I18n, _site, _categoryLink, _decorators, _getUrl, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("topic", "pmTopicTrackingState.isTracking", "pmTopicTrackingState.statesModificationCounter", "topicTrackingState.messageCount"), (_obj = {
    tagName: "",
    suggestedTitleLabel: Ember.computed("topic", function () {
      var href = this.currentUser && this.currentUser.pmPath(this.topic);

      if (this.topic.get("isPrivateMessage") && href) {
        return "suggested_topics.pm_title";
      } else {
        return "suggested_topics.title";
      }
    }),
    browseMoreMessage: function browseMoreMessage(topic) {
      return topic.isPrivateMessage ? this._privateMessageBrowseMoreMessage(topic) : this._topicBrowseMoreMessage(topic);
    },
    _privateMessageBrowseMoreMessage: function _privateMessageBrowseMoreMessage(topic) {
      var username = this.currentUser.username;
      var suggestedGroupName = topic.suggested_group_name;
      var inboxFilter = suggestedGroupName ? "group" : "user";
      var unreadCount = this.pmTopicTrackingState.lookupCount("unread", {
        inboxFilter: inboxFilter,
        groupName: suggestedGroupName
      });
      var newCount = this.pmTopicTrackingState.lookupCount("new", {
        inboxFilter: inboxFilter,
        groupName: suggestedGroupName
      });

      if (unreadCount + newCount > 0) {
        var hasBoth = unreadCount > 0 && newCount > 0;

        if (suggestedGroupName) {
          return _I18n.default.messageFormat("user.messages.read_more_group_pm_MF", {
            BOTH: hasBoth,
            UNREAD: unreadCount,
            NEW: newCount,
            username: username,
            groupName: suggestedGroupName,
            groupLink: this._groupLink(username, suggestedGroupName),
            basePath: (0, _getUrl.default)("")
          });
        } else {
          return _I18n.default.messageFormat("user.messages.read_more_personal_pm_MF", {
            BOTH: hasBoth,
            UNREAD: unreadCount,
            NEW: newCount,
            username: username,
            basePath: (0, _getUrl.default)("")
          });
        }
      } else if (suggestedGroupName) {
        return _I18n.default.t("user.messages.read_more_in_group", {
          groupLink: this._groupLink(username, suggestedGroupName)
        });
      } else {
        return _I18n.default.t("user.messages.read_more", {
          basePath: (0, _getUrl.default)(""),
          username: username
        });
      }
    },
    _topicBrowseMoreMessage: function _topicBrowseMoreMessage(topic) {
      var opts = {
        latestLink: "<a href=\"".concat((0, _getUrl.default)("/latest"), "\">").concat(_I18n.default.t("topic.view_latest_topics"), "</a>")
      };
      var category = topic.get("category");

      if (category && Ember.get(category, "id") === _site.default.currentProp("uncategorized_category_id")) {
        category = null;
      }

      if (category) {
        opts.catLink = (0, _categoryLink.categoryBadgeHTML)(category);
      } else {
        opts.catLink = '<a href="' + (0, _getUrl.default)("/categories") + '">' + _I18n.default.t("topic.browse_all_categories") + "</a>";
      }

      var unreadTopics = 0;
      var newTopics = 0;

      if (this.currentUser) {
        unreadTopics = this.topicTrackingState.countUnread();
        newTopics = this.topicTrackingState.countNew();
      }

      if (newTopics + unreadTopics > 0) {
        var hasBoth = unreadTopics > 0 && newTopics > 0;
        return _I18n.default.messageFormat("topic.read_more_MF", {
          BOTH: hasBoth,
          UNREAD: unreadTopics,
          NEW: newTopics,
          CATEGORY: category ? true : false,
          latestLink: opts.latestLink,
          catLink: opts.catLink,
          basePath: (0, _getUrl.default)("")
        });
      } else if (category) {
        return _I18n.default.t("topic.read_more_in_category", opts);
      } else {
        return _I18n.default.t("topic.read_more", opts);
      }
    },
    _groupLink: function _groupLink(username, groupName) {
      return "<a class=\"group-link\" href=\"".concat((0, _getUrl.default)("/u/".concat(username, "/messages/group/").concat(groupName)), "\">").concat((0, _iconLibrary.iconHTML)("users"), " ").concat(groupName, "</a>");
    }
  }, (_applyDecoratedDescriptor(_obj, "browseMoreMessage", [_dec], Object.getOwnPropertyDescriptor(_obj, "browseMoreMessage"), _obj)), _obj)));

  _exports.default = _default;
});