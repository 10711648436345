define("discourse/components/desktop-notification-config", ["exports", "discourse/lib/desktop-notifications", "discourse/lib/push-notifications", "discourse/lib/key-value-store", "discourse-common/utils/decorators"], function (_exports, _desktopNotifications, _pushNotifications, _keyValueStore, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj, _init, _init2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var keyValueStore = new _keyValueStore.default(_desktopNotifications.context);

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("isNotSupported"), _dec2 = (0, _decorators.default)("isNotSupported", "notificationsPermission"), _dec3 = (0, _decorators.default)("isNotSupported", "notificationsPermission"), _dec4 = (0, _decorators.default)("isGrantedPermission", "notificationsDisabled"), (_obj = {
    classNames: ["controls"],
    notificationsPermission: function notificationsPermission(isNotSupported) {
      return isNotSupported ? "" : Notification.permission;
    },
    notificationsDisabled: {
      set: function set(value) {
        keyValueStore.setItem("notifications-disabled", value);
        return keyValueStore.getItem("notifications-disabled");
      },
      get: function get() {
        return keyValueStore.getItem("notifications-disabled");
      }
    },
    isNotSupported: function isNotSupported() {
      return typeof window.Notification === "undefined";
    },
    isDeniedPermission: function isDeniedPermission(isNotSupported, notificationsPermission) {
      return isNotSupported ? false : notificationsPermission === "denied";
    },
    isGrantedPermission: function isGrantedPermission(isNotSupported, notificationsPermission) {
      return isNotSupported ? false : notificationsPermission === "granted";
    },
    isEnabledDesktop: function isEnabledDesktop(isGrantedPermission, notificationsDisabled) {
      return isGrantedPermission ? !notificationsDisabled : false;
    },
    isEnabledPush: {
      set: function set(value) {
        var user = this.currentUser;

        if (!user) {
          return false;
        }

        _pushNotifications.keyValueStore.setItem((0, _pushNotifications.userSubscriptionKey)(user), value);

        return _pushNotifications.keyValueStore.getItem((0, _pushNotifications.userSubscriptionKey)(user));
      },
      get: function get() {
        var user = this.currentUser;
        return user ? _pushNotifications.keyValueStore.getItem((0, _pushNotifications.userSubscriptionKey)(user)) : false;
      }
    },
    isEnabled: Ember.computed.or("isEnabledDesktop", "isEnabledPush"),
    isPushNotificationsPreferred: function isPushNotificationsPreferred() {
      if (!this.site.mobileView) {
        return false;
      }

      return (0, _pushNotifications.isPushNotificationsSupported)(this.site.mobileView);
    },
    actions: {
      recheckPermission: function recheckPermission() {
        this.notifyPropertyChange("notificationsPermission");
      },
      turnoff: function turnoff() {
        var _this = this;

        if (this.isEnabledDesktop) {
          this.set("notificationsDisabled", "disabled");
          this.notifyPropertyChange("notificationsPermission");
        }

        if (this.isEnabledPush) {
          (0, _pushNotifications.unsubscribe)(this.currentUser, function () {
            _this.set("isEnabledPush", "");
          });
        }
      },
      turnon: function turnon() {
        var _this2 = this;

        if (this.isPushNotificationsPreferred()) {
          (0, _pushNotifications.subscribe)(function () {
            _this2.set("isEnabledPush", "subscribed");
          }, this.siteSettings.vapid_public_key_bytes);
        } else {
          this.set("notificationsDisabled", "");
          Notification.requestPermission(function () {
            (0, _desktopNotifications.confirmNotification)(_this2.siteSettings);

            _this2.notifyPropertyChange("notificationsPermission");
          });
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "notificationsPermission", [_dec], Object.getOwnPropertyDescriptor(_obj, "notificationsPermission"), _obj), _applyDecoratedDescriptor(_obj, "notificationsDisabled", [_decorators.default], (_init = Object.getOwnPropertyDescriptor(_obj, "notificationsDisabled"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "isNotSupported", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "isNotSupported"), _obj), _applyDecoratedDescriptor(_obj, "isDeniedPermission", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isDeniedPermission"), _obj), _applyDecoratedDescriptor(_obj, "isGrantedPermission", [_dec3], Object.getOwnPropertyDescriptor(_obj, "isGrantedPermission"), _obj), _applyDecoratedDescriptor(_obj, "isEnabledDesktop", [_dec4], Object.getOwnPropertyDescriptor(_obj, "isEnabledDesktop"), _obj), _applyDecoratedDescriptor(_obj, "isEnabledPush", [_decorators.default], (_init2 = Object.getOwnPropertyDescriptor(_obj, "isEnabledPush"), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj)), _obj)));

  _exports.default = _default;
});