define("discourse/routes/user-activity-topics", ["exports", "discourse/models/user-action", "discourse/routes/user-topic-list", "I18n"], function (_exports, _userAction, _userTopicList, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _userTopicList.default.extend((_dec = Ember._action, (_obj = {
    userActionType: _userAction.default.TYPES.topics,
    model: function model() {
      var _this = this;

      return this.store.findFiltered("topicList", {
        filter: "topics/created-by/" + this.modelFor("user").get("username_lower")
      }).then(function (model) {
        // andrei: we agreed that this is an anti pattern,
        // it's better to avoid mutating a rest model like this
        // this place we'll be refactored later
        // see https://github.com/discourse/discourse/pull/14313#discussion_r708784704
        model.set("emptyState", _this.emptyState());
        return model;
      });
    },
    emptyState: function emptyState() {
      return {
        title: _I18n.default.t("user_activity.no_topics_title"),
        body: ""
      };
    },
    triggerRefresh: function triggerRefresh() {
      this.refresh();
    }
  }, (_applyDecoratedDescriptor(_obj, "triggerRefresh", [_dec], Object.getOwnPropertyDescriptor(_obj, "triggerRefresh"), _obj)), _obj)));

  _exports.default = _default;
});