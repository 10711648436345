define("discourse/templates/components/date-time-input-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M+Tz3D+R",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"date-time-input\",null,[[\"date\",\"onChange\",\"showTime\",\"class\",\"placeholder\"],[[24,[\"from\"]],[28,\"action\",[[23,0,[]],\"onChangeRanges\",[28,\"hash\",null,[[\"prop\"],[\"from\"]]]],null],[24,[\"showFromTime\"]],\"from\",[28,\"i18n\",[\"dates.from_placeholder\"],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"date-time-input\",null,[[\"date\",\"relativeDate\",\"onChange\",\"timeFirst\",\"showTime\",\"clearable\",\"class\",\"placeholder\"],[[24,[\"to\"]],[24,[\"from\"]],[28,\"action\",[[23,0,[]],\"onChangeRanges\",[28,\"hash\",null,[[\"prop\"],[\"to\"]]]],null],[24,[\"toTimeFirst\"]],[24,[\"showToTime\"]],[24,[\"clearable\"]],\"to\",[28,\"i18n\",[\"dates.to_placeholder\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/date-time-input-range.hbs"
    }
  });

  _exports.default = _default;
});