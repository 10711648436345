define("discourse/adapters/email-template", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    basePath: function basePath() {
      return "/admin/customize/";
    }
  });

  _exports.default = _default;
});