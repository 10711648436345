define("discourse/components/conditional-loading-section", ["exports", "I18n"], function (_exports, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["conditional-loading-section"],
    classNameBindings: ["isLoading"],
    isLoading: false,
    title: _I18n.default.t("conditional_loading_section.loading")
  });

  _exports.default = _default;
});