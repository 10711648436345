define("discourse/widgets/hbs-compiler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hbs;

  function hbs() {
    // eslint-disable-next-line no-console
    console.log("Templates should be precompiled server side");
  }
});