define("discourse/routes/forgot-password", ["exports", "discourse/routes/build-static-route", "discourse/lib/utilities"], function (_exports, _buildStaticRoute, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ForgotPasswordRoute = (0, _buildStaticRoute.default)("password-reset");
  ForgotPasswordRoute.reopen({
    beforeModel: function beforeModel() {
      var loginRequired = this.controllerFor("application").get("loginRequired");
      this.replaceWith(loginRequired ? "login" : "discovery.".concat((0, _utilities.defaultHomepage)())).then(function (e) {
        Ember.run.next(function () {
          return e.send("showForgotPassword");
        });
      });
    }
  });
  var _default = ForgotPasswordRoute;
  _exports.default = _default;
});