define("discourse/controllers/share-topic", ["exports", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/sharing", "discourse/lib/show-modal", "discourse/mixins/buffered-content", "discourse/mixins/modal-functionality", "I18n", "discourse/models/category"], function (_exports, _getUrl, _decorators, _ajax, _ajaxError, _sharing, _showModal, _bufferedContent, _modalFunctionality, _I18n, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, (0, _bufferedContent.bufferedProperty)("invite"), (_dec = (0, _decorators.default)("topic.shareUrl"), _dec2 = (0, _decorators.default)("topic.{isPrivateMessage,invisible,category.read_restricted}"), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_obj = {
    topic: null,
    restrictedGroups: null,
    onShow: function onShow() {
      this.set("showNotifyUsers", false);

      if (this.model && this.model.read_restricted) {
        this.restrictedGroupWarning();
      }
    },
    topicUrl: function topicUrl(url) {
      return url ? (0, _getUrl.getAbsoluteURL)(url) : null;
    },
    sources: function sources(topic) {
      var privateContext = this.siteSettings.login_required || topic && topic.isPrivateMessage || topic && topic.invisible || topic.category.read_restricted;
      return _sharing.default.activeSources(this.siteSettings.share_links, privateContext);
    },
    onChangeUsers: function onChangeUsers(usernames) {
      this.set("users", usernames.uniq());
    },
    share: function share(source) {
      this.set("showNotifyUsers", false);

      _sharing.default.shareSource(source, {
        title: this.topic.title,
        url: this.topicUrl
      });
    },
    toggleNotifyUsers: function toggleNotifyUsers() {
      if (this.showNotifyUsers) {
        this.set("showNotifyUsers", false);
      } else {
        this.setProperties({
          showNotifyUsers: true,
          users: []
        });
      }
    },
    notifyUsers: function notifyUsers() {
      var _this = this;

      if (this.users.length === 0) {
        return;
      }

      (0, _ajax.ajax)("/t/".concat(this.topic.id, "/invite-notify"), {
        type: "POST",
        data: {
          usernames: this.users
        }
      }).then(function () {
        _this.setProperties({
          showNotifyUsers: false
        });

        _this.appEvents.trigger("modal-body:flash", {
          text: _I18n.default.t("topic.share.notify_users.success", {
            count: _this.users.length,
            username: _this.users[0]
          }),
          messageClass: "success"
        });
      }).catch(function (error) {
        _this.appEvents.trigger("modal-body:flash", {
          text: (0, _ajaxError.extractError)(error),
          messageClass: "error"
        });
      });
    },
    inviteUsers: function inviteUsers() {
      this.set("showNotifyUsers", false);
      var controller = (0, _showModal.default)("create-invite");
      controller.set("inviteToTopic", true);
      controller.buffered.setProperties({
        topicId: this.topic.id,
        topicTitle: this.topic.title
      });
    },
    restrictedGroupWarning: function restrictedGroupWarning() {
      var _this2 = this;

      this.appEvents.on("modal:body-shown", function () {
        var restrictedGroups;

        _category.default.reloadBySlugPath(_this2.model.slug).then(function (result) {
          restrictedGroups = result.category.group_permissions.map(function (g) {
            return g.group_name;
          });

          if (restrictedGroups) {
            var message = _I18n.default.t("topic.share.restricted_groups", {
              count: restrictedGroups.length,
              groupNames: restrictedGroups.join(", ")
            });

            _this2.flash(message, "warning");
          }
        });
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "topicUrl", [_dec], Object.getOwnPropertyDescriptor(_obj, "topicUrl"), _obj), _applyDecoratedDescriptor(_obj, "sources", [_dec2], Object.getOwnPropertyDescriptor(_obj, "sources"), _obj), _applyDecoratedDescriptor(_obj, "onChangeUsers", [_dec3], Object.getOwnPropertyDescriptor(_obj, "onChangeUsers"), _obj), _applyDecoratedDescriptor(_obj, "share", [_dec4], Object.getOwnPropertyDescriptor(_obj, "share"), _obj), _applyDecoratedDescriptor(_obj, "toggleNotifyUsers", [_dec5], Object.getOwnPropertyDescriptor(_obj, "toggleNotifyUsers"), _obj), _applyDecoratedDescriptor(_obj, "notifyUsers", [_dec6], Object.getOwnPropertyDescriptor(_obj, "notifyUsers"), _obj), _applyDecoratedDescriptor(_obj, "inviteUsers", [_dec7], Object.getOwnPropertyDescriptor(_obj, "inviteUsers"), _obj)), _obj)));

  _exports.default = _default;
});