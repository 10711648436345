define("discourse/templates/components/user-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SO/84tqr",
    "block": "{\"symbols\":[\"@count\",\"@badge\"],\"statements\":[[7,\"a\",true],[10,\"class\",\"user-card-badge-link\"],[11,\"href\",[23,0,[\"badgeUrl\"]]],[8],[0,\"\\n\"],[4,\"badge-button\",null,[[\"badge\"],[[23,2,[]]]],{\"statements\":[[4,\"if\",[[23,0,[\"showGrantCount\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"count\"],[8],[0,\"(× \"],[1,[23,1,[]],false],[0,\")\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/user-badge.hbs"
    }
  });

  _exports.default = _default;
});