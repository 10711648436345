define("discourse/templates/components/uppy-backup-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tm/5oPpJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"btn\"],[11,\"disabled\",[22,\"uploading\"]],[11,\"title\",[28,\"i18n\",[\"admin.backups.upload.title\"],null]],[8],[0,\"\\n  \"],[1,[28,\"d-icon\",[\"upload\"],null],false],[1,[22,\"uploadButtonText\"],false],[0,\"\\n  \"],[7,\"input\",true],[10,\"class\",\"hidden-upload-field\"],[11,\"disabled\",[22,\"uploading\"]],[10,\"accept\",\".gz\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/uppy-backup-uploader.hbs"
    }
  });

  _exports.default = _default;
});