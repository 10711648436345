define("discourse/adapters/group", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    appendQueryParams: function appendQueryParams(path, findArgs) {
      return this._super(path, findArgs, ".json");
    }
  });

  _exports.default = _default;
});