define("discourse/templates/components/shared-draft-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sjIwG+KI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"shared-draft-controls\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"publishing\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"i18n\",[\"shared_drafts.publishing\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"i18n\",[\"shared_drafts.notice\"],null],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"publish-field\"],[8],[0,\"\\n      \"],[7,\"label\",true],[8],[1,[28,\"i18n\",[\"shared_drafts.destination_category\"],null],false],[9],[0,\"\\n      \"],[1,[28,\"category-chooser\",null,[[\"value\",\"onChange\"],[[24,[\"topic\",\"destination_category_id\"]],[28,\"action\",[[23,0,[]],\"updateDestinationCategory\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"publish-field\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"validCategory\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"d-button\",null,[[\"action\",\"label\",\"class\",\"icon\"],[[28,\"action\",[[23,0,[]],\"publish\"],null],\"shared_drafts.publish\",\"btn-primary publish-shared-draft\",\"far-clipboard\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/shared-draft-controls.hbs"
    }
  });

  _exports.default = _default;
});