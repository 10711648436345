define("discourse/lib/topic-fancy-title", ["exports", "discourse/models/site", "pretty-text/censored-words", "discourse/lib/text", "discourse/lib/text-direction"], function (_exports, _site, _censoredWords, _text, _textDirection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fancyTitle = fancyTitle;

  function fancyTitle(topicTitle, supportMixedTextDirection) {
    var title = (0, _censoredWords.censor)((0, _text.emojiUnescape)(topicTitle) || "", _site.default.currentProp("censored_regexp"));

    if (supportMixedTextDirection) {
      var titleDir = (0, _textDirection.isRTL)(title) ? "rtl" : "ltr";
      return "<span dir=\"".concat(titleDir, "\">").concat(title, "</span>");
    }

    return title;
  }
});