define("discourse/controllers/second-factor-edit", ["exports", "discourse/mixins/modal-functionality"], function (_exports, _modalFunctionality) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    actions: {
      disableSecondFactor: function disableSecondFactor() {
        var _this = this;

        this.user.updateSecondFactor(this.model.id, this.model.name, true, this.model.method).then(function (response) {
          if (response.error) {
            return;
          }

          _this.markDirty();
        }).catch(function (error) {
          _this.send("closeModal");

          _this.onError(error);
        }).finally(function () {
          _this.set("loading", false);

          _this.send("closeModal");
        });
      },
      editSecondFactor: function editSecondFactor() {
        var _this2 = this;

        this.user.updateSecondFactor(this.model.id, this.model.name, false, this.model.method).then(function (response) {
          if (response.error) {
            return;
          }

          _this2.markDirty();
        }).catch(function (error) {
          _this2.send("closeModal");

          _this2.onError(error);
        }).finally(function () {
          _this2.set("loading", false);

          _this2.send("closeModal");
        });
      }
    }
  });

  _exports.default = _default;
});