define("discourse/templates/components/composer-action-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JLTveESn",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"composer-actions\",null,[[\"composerModel\",\"replyOptions\",\"canWhisper\",\"openComposer\",\"closeComposer\",\"action\",\"tabindex\",\"topic\",\"post\",\"whisper\",\"noBump\",\"options\"],[[24,[\"model\"]],[24,[\"model\",\"replyOptions\"]],[24,[\"canWhisper\"]],[24,[\"openComposer\"]],[24,[\"closeComposer\"]],[24,[\"model\",\"action\"]],[24,[\"tabindex\"]],[24,[\"model\",\"topic\"]],[24,[\"model\",\"post\"]],[24,[\"model\",\"whisper\"]],[24,[\"model\",\"noBump\"]],[28,\"hash\",null,[[\"mobilePlacementStrategy\"],[\"fixed\"]]]]]],false],[0,\"\\n\\n\"],[7,\"span\",true],[10,\"class\",\"action-title\"],[8],[0,\"\\n  \"],[1,[22,\"actionTitle\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/composer-action-title.hbs"
    }
  });

  _exports.default = _default;
});