define("discourse/initializers/subscribe-user-notifications", ["exports", "discourse/lib/desktop-notifications", "discourse/lib/push-notifications", "discourse-common/config/environment"], function (_exports, _desktopNotifications, _pushNotifications, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Subscribes to user events on the message bus
  var _default = {
    name: "subscribe-user-notifications",
    after: "message-bus",
    initialize: function initialize(container) {
      var user = container.lookup("current-user:main");
      var bus = container.lookup("message-bus:main");
      var appEvents = container.lookup("service:app-events");

      if (user) {
        bus.subscribe("/reviewable_counts", function (data) {
          user.set("reviewable_count", data.reviewable_count);
        });
        bus.subscribe("/notification/".concat(user.get("id")), function (data) {
          var store = container.lookup("service:store");
          var oldUnread = user.get("unread_notifications");
          var oldHighPriority = user.get("unread_high_priority_notifications");
          user.setProperties({
            unread_notifications: data.unread_notifications,
            unread_high_priority_notifications: data.unread_high_priority_notifications,
            read_first_notification: data.read_first_notification
          });

          if (oldUnread !== data.unread_notifications || oldHighPriority !== data.unread_high_priority_notifications) {
            appEvents.trigger("notifications:changed");

            if (site.mobileView && (data.unread_notifications - oldUnread > 0 || data.unread_high_priority_notifications - oldHighPriority > 0)) {
              appEvents.trigger("header:update-topic", null, 5000);
            }
          }

          var stale = store.findStale("notification", {}, {
            cacheKey: "recent-notifications"
          });
          var lastNotification = data.last_notification && data.last_notification.notification;

          if (stale && stale.hasResults && lastNotification) {
            var oldNotifications = stale.results.get("content");
            var staleIndex = oldNotifications.findIndex(function (n) {
              return n.id === lastNotification.id;
            });

            if (staleIndex === -1) {
              // high priority and unread notifications are first
              var insertPosition = 0;

              if (!lastNotification.high_priority || lastNotification.read) {
                var nextPosition = oldNotifications.findIndex(function (n) {
                  return !n.high_priority || n.read;
                });

                if (nextPosition !== -1) {
                  insertPosition = nextPosition;
                }
              }

              oldNotifications.insertAt(insertPosition, Ember.Object.create(lastNotification));
            } // remove stale notifications and update existing ones


            var read = Object.fromEntries(data.recent);
            var newNotifications = oldNotifications.map(function (notification) {
              if (read[notification.id] !== undefined) {
                notification.set("read", read[notification.id]);
                return notification;
              }
            }).filter(Boolean);
            stale.results.set("content", newNotifications);
          }
        }, user.notification_channel_position);
        bus.subscribe("/do-not-disturb/".concat(user.get("id")), function (data) {
          user.updateDoNotDisturbStatus(data.ends_at);
        });
        var site = container.lookup("site:main");
        var siteSettings = container.lookup("site-settings:main");
        var router = container.lookup("router:main");
        bus.subscribe("/categories", function (data) {
          (data.categories || []).forEach(function (c) {
            return site.updateCategory(c);
          });
          (data.deleted_categories || []).forEach(function (id) {
            return site.removeCategory(id);
          });
        });
        bus.subscribe("/client_settings", function (data) {
          return Ember.set(siteSettings, data.name, data.value);
        });

        if (!(0, _environment.isTesting)()) {
          bus.subscribe((0, _desktopNotifications.alertChannel)(user), function (data) {
            return (0, _desktopNotifications.onNotification)(data, siteSettings, user);
          });
          (0, _desktopNotifications.init)(bus, appEvents);

          if ((0, _pushNotifications.isPushNotificationsEnabled)(user, site.mobileView)) {
            (0, _desktopNotifications.disable)();
            (0, _pushNotifications.register)(user, site.mobileView, router, appEvents);
          } else {
            (0, _pushNotifications.unsubscribe)(user);
          }
        }
      }
    }
  };
  _exports.default = _default;
});