define("discourse/models/published-page", ["exports", "discourse/models/rest", "discourse-common/lib/get-url"], function (_exports, _rest, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    url: Ember.computed("slug", function () {
      return (0, _getUrl.getAbsoluteURL)("/pub/".concat(this.slug));
    })
  });

  _exports.default = _default;
});