define("discourse/controllers/users", ["exports", "discourse/models/group", "discourse-common/lib/debounce", "discourse/lib/show-modal", "discourse/lib/formatter", "discourse-common/utils/decorators"], function (_exports, _group, _debounce, _showModal, _formatter, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = (0, _decorators.observes)("model.canLoadMore"), _dec5 = Ember._action, (_obj = {
    application: Ember.inject.controller(),
    queryParams: ["period", "order", "asc", "name", "group", "exclude_usernames"],
    period: "weekly",
    order: "",
    asc: null,
    name: "",
    group: null,
    nameInput: null,
    exclude_usernames: null,
    isLoading: false,
    columns: null,
    groupOptions: null,
    params: null,
    showGroupFilter: Ember.computed.and("currentUser", "groupOptions"),
    showTimeRead: Ember.computed.equal("period", "all"),
    loadUsers: function loadUsers() {
      var _this = this;

      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      if (params) {
        this.set("params", params);
      }

      this.setProperties({
        isLoading: true,
        nameInput: this.params.name,
        order: this.params.order
      });
      var userFieldIds = this.columns.filter(function (c) {
        return c.type === "user_field";
      }).map(function (c) {
        return c.user_field_id;
      }).join("|");
      var pluginColumnIds = this.columns.filter(function (c) {
        return c.type === "plugin";
      }).map(function (c) {
        return c.id;
      }).join("|");
      return this.store.find("directoryItem", Object.assign(this.params, {
        user_field_ids: userFieldIds,
        plugin_column_ids: pluginColumnIds
      })).then(function (model) {
        var lastUpdatedAt = model.get("resultSetMeta.last_updated_at");

        _this.setProperties({
          model: model,
          lastUpdatedAt: lastUpdatedAt ? (0, _formatter.longDate)(lastUpdatedAt) : null,
          period: _this.params.period
        });
      }).finally(function () {
        _this.set("isLoading", false);
      });
    },
    loadGroups: function loadGroups() {
      var _this2 = this;

      if (this.currentUser) {
        return _group.default.findAll({
          ignore_automatic: true
        }).then(function (groups) {
          var groupOptions = groups.map(function (group) {
            return {
              name: group.full_name || group.name,
              id: group.name
            };
          });

          _this2.set("groupOptions", groupOptions);
        });
      }
    },
    groupChanged: function groupChanged(_, groupAttrs) {
      // First param is the group name, which include none or 'all groups'. Ignore this and look at second param.
      this.set("group", groupAttrs === null || groupAttrs === void 0 ? void 0 : groupAttrs.id);
    },
    showEditColumnsModal: function showEditColumnsModal() {
      (0, _showModal.default)("edit-user-directory-columns");
    },
    onUsernameFilterChanged: function onUsernameFilterChanged(filter) {
      (0, _debounce.default)(this, this._setUsernameFilter, filter, 500);
    },
    _setUsernameFilter: function _setUsernameFilter(username) {
      this.setProperties({
        name: username,
        "params.name": username
      });
      this.loadUsers();
    },
    _showFooter: function _showFooter() {
      this.set("application.showFooter", !this.get("model.canLoadMore"));
    },
    loadMore: function loadMore() {
      this.model.loadMore();
    }
  }, (_applyDecoratedDescriptor(_obj, "groupChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "groupChanged"), _obj), _applyDecoratedDescriptor(_obj, "showEditColumnsModal", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showEditColumnsModal"), _obj), _applyDecoratedDescriptor(_obj, "onUsernameFilterChanged", [_dec3], Object.getOwnPropertyDescriptor(_obj, "onUsernameFilterChanged"), _obj), _applyDecoratedDescriptor(_obj, "_showFooter", [_dec4], Object.getOwnPropertyDescriptor(_obj, "_showFooter"), _obj), _applyDecoratedDescriptor(_obj, "loadMore", [_dec5], Object.getOwnPropertyDescriptor(_obj, "loadMore"), _obj)), _obj)));

  _exports.default = _default;
});