define("discourse/controllers/edit-user-directory-columns", ["exports", "discourse/mixins/modal-functionality", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/helpers/page-reloader"], function (_exports, _modalFunctionality, _ajax, _ajaxError, _pageReloader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var UP = "up";
  var DOWN = "down";

  var _default = Ember.Controller.extend(_modalFunctionality.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    loading: true,
    columns: null,
    labelKey: null,
    onShow: function onShow() {
      var _this = this;

      (0, _ajax.ajax)("edit-directory-columns.json").then(function (response) {
        _this.setProperties({
          loading: false,
          columns: response.directory_columns.sort(function (a, b) {
            return a.position > b.position ? 1 : -1;
          }).map(function (c) {
            return Ember.Object.create(c);
          })
        });
      }).catch(_ajaxError.extractError);
    },
    save: function save() {
      var _this2 = this;

      this.set("loading", true);
      var data = {
        directory_columns: this.columns.map(function (c) {
          return c.getProperties("id", "enabled", "position");
        })
      };
      (0, _ajax.ajax)("edit-directory-columns.json", {
        type: "PUT",
        data: data
      }).then(function () {
        (0, _pageReloader.reload)();
      }).catch(function (e) {
        _this2.set("loading", false);

        _this2.flash((0, _ajaxError.extractError)(e), "error");
      });
    },
    resetToDefault: function resetToDefault() {
      var resetColumns = this.columns;
      resetColumns.sort(function (a, b) {
        return (a.automatic_position || a.user_field.position + 1000) > (b.automatic_position || b.user_field.position + 1000) ? 1 : -1;
      }).forEach(function (column, index) {
        column.setProperties({
          position: column.automatic_position || index + 1,
          enabled: column.type === "automatic"
        });
      });
      this.set("columns", resetColumns);
      this.notifyPropertyChange("columns");
    },
    moveUp: function moveUp(column) {
      this._moveColumn(UP, column);
    },
    moveDown: function moveDown(column) {
      this._moveColumn(DOWN, column);
    },
    _moveColumn: function _moveColumn(direction, column) {
      if (direction === UP && column.position === 1 || direction === DOWN && column.position === this.columns.length) {
        return;
      }

      var positionOnClick = column.position;
      var newPosition = direction === UP ? positionOnClick - 1 : positionOnClick + 1;
      var previousColumn = this.columns.find(function (c) {
        return c.position === newPosition;
      });
      column.set("position", newPosition);
      previousColumn.set("position", positionOnClick);
      this.set("columns", this.columns.sort(function (a, b) {
        return a.position > b.position ? 1 : -1;
      }));
      this.notifyPropertyChange("columns");
    }
  }, (_applyDecoratedDescriptor(_obj, "save", [_dec], Object.getOwnPropertyDescriptor(_obj, "save"), _obj), _applyDecoratedDescriptor(_obj, "resetToDefault", [_dec2], Object.getOwnPropertyDescriptor(_obj, "resetToDefault"), _obj), _applyDecoratedDescriptor(_obj, "moveUp", [_dec3], Object.getOwnPropertyDescriptor(_obj, "moveUp"), _obj), _applyDecoratedDescriptor(_obj, "moveDown", [_dec4], Object.getOwnPropertyDescriptor(_obj, "moveDown"), _obj)), _obj)));

  _exports.default = _default;
});