define("discourse/helpers/loading-spinner", ["exports", "discourse-common/lib/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.renderSpinner = renderSpinner;
  _exports.spinnerHTML = _exports.default = void 0;

  function renderSpinner(cssClass) {
    var html = "<div class='spinner";

    if (cssClass) {
      html += " " + cssClass;
    }

    return html + "'></div>";
  }

  var spinnerHTML = renderSpinner();
  _exports.spinnerHTML = spinnerHTML;

  var _default = (0, _helpers.htmlHelper)(function (params) {
    var hash = params.hash;
    return renderSpinner(hash && hash.size ? hash.size : undefined);
  });

  _exports.default = _default;
});