define("discourse/components/create-invite-uploader", ["exports", "discourse/mixins/uppy-upload", "discourse-common/utils/decorators"], function (_exports, _uppyUpload, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_uppyUpload.default, (_dec = (0, _decorators.default)("filesAwaitingUpload", "uploading"), _dec2 = Ember._action, (_obj = {
    id: "create-invite-uploader",
    tagName: "div",
    type: "csv",
    autoStartUploads: false,
    uploadUrl: "/invites/upload_csv",
    preventDirectS3Uploads: true,
    fileInputSelector: "#csv-file",
    validateUploadedFilesOptions: function validateUploadedFilesOptions() {
      return {
        bypassNewUserRestriction: true,
        csvOnly: true
      };
    },
    submitDisabled: function submitDisabled(filesAwaitingUpload, uploading) {
      return !filesAwaitingUpload || uploading;
    },
    uploadDone: function uploadDone() {
      this.set("uploaded", true);
    },
    startUpload: function startUpload() {
      this._startUpload();
    }
  }, (_applyDecoratedDescriptor(_obj, "submitDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "submitDisabled"), _obj), _applyDecoratedDescriptor(_obj, "startUpload", [_dec2], Object.getOwnPropertyDescriptor(_obj, "startUpload"), _obj)), _obj)));

  _exports.default = _default;
});