define("discourse/templates/components/notification-consent-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7zX3iCci",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showNotificationPromptBanner\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"consent_banner alert alert-info\"],[8],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"\\n        \"],[1,[28,\"i18n\",[\"user.desktop_notifications.consent_prompt\"],null],false],[0,\"\\n        \"],[1,[28,\"d-button\",null,[[\"display\",\"action\",\"label\"],[\"link\",[28,\"action\",[[23,0,[]],\"turnon\"],null],\"user.desktop_notifications.enable\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[1,[28,\"d-button\",null,[[\"icon\",\"action\",\"class\",\"title\"],[\"times\",[28,\"action\",[[23,0,[]],\"dismiss\"],null],\"btn btn-flat close\",\"banner.close\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/notification-consent-banner.hbs"
    }
  });

  _exports.default = _default;
});