define("discourse/models/site", ["exports", "discourse/models/archetype", "discourse/models/post-action-type", "discourse/lib/preload-store", "discourse/models/rest", "discourse/mixins/singleton", "discourse/models/trust-level", "discourse-common/lib/deprecated", "discourse-common/utils/decorators", "discourse-common/lib/get-owner"], function (_exports, _archetype, _postActionType, _preloadStore, _rest, _singleton, _trustLevel, _deprecated, _decorators, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var Site = _rest.default.extend((_dec = (0, _decorators.default)("notification_types"), _dec2 = (0, _decorators.default)("post_action_types.[]"), _dec3 = (0, _decorators.default)("categoriesByCount", "categories.[]"), _dec4 = (0, _decorators.default)("categories.[]"), (_obj = {
    isReadOnly: Ember.computed.alias("is_readonly"),
    init: function init() {
      this._super.apply(this, arguments);

      this.topicCountDesc = ["topic_count:desc"];
    },
    notificationLookup: function notificationLookup(notificationTypes) {
      var result = [];
      Object.keys(notificationTypes).forEach(function (k) {
        return result[notificationTypes[k]] = k;
      });
      return result;
    },
    flagTypes: function flagTypes() {
      var postActionTypes = this.post_action_types;

      if (!postActionTypes) {
        return [];
      }

      return postActionTypes.filterBy("is_flag", true);
    },
    categoriesByCount: Ember.computed.sort("categories", "topicCountDesc"),
    collectUserFields: function collectUserFields(fields) {
      fields = fields || {};
      var siteFields = this.user_fields;

      if (!Ember.isEmpty(siteFields)) {
        return siteFields.map(function (f) {
          var value = fields ? fields[f.id.toString()] : null;
          value = value || "&mdash;".htmlSafe();
          return {
            name: f.name,
            value: value
          };
        });
      }

      return [];
    },
    sortedCategories: function sortedCategories(categories) {
      var children = new Map();
      categories.forEach(function (category) {
        var parentId = parseInt(category.parent_category_id, 10) || -1;
        var group = children.get(parentId) || [];
        group.pushObject(category);
        children.set(parentId, group);
      });

      var reduce = function reduce(values) {
        return values.flatMap(function (c) {
          return [c, reduce(children.get(c.id) || [])];
        }).flat();
      };

      return reduce(children.get(-1));
    },
    categoriesList: function categoriesList() {
      return this.siteSettings.fixed_category_positions ? this.categories : this.sortedCategories;
    },
    postActionTypeById: function postActionTypeById(id) {
      return this.get("postActionByIdLookup.action" + id);
    },
    topicFlagTypeById: function topicFlagTypeById(id) {
      return this.get("topicFlagByIdLookup.action" + id);
    },
    removeCategory: function removeCategory(id) {
      var categories = this.categories;
      var existingCategory = categories.findBy("id", id);

      if (existingCategory) {
        categories.removeObject(existingCategory);
        delete this.categoriesById.categoryId;
      }
    },
    updateCategory: function updateCategory(newCategory) {
      var categories = this.categories;
      var categoryId = Ember.get(newCategory, "id");
      var existingCategory = categories.findBy("id", categoryId); // Don't update null permissions

      if (newCategory.permission === null) {
        delete newCategory.permission;
      }

      if (existingCategory) {
        existingCategory.setProperties(newCategory);
        return existingCategory;
      } else {
        // TODO insert in right order?
        newCategory = this.store.createRecord("category", newCategory);
        categories.pushObject(newCategory);
        this.categoriesById[categoryId] = newCategory;
        return newCategory;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "notificationLookup", [_dec], Object.getOwnPropertyDescriptor(_obj, "notificationLookup"), _obj), _applyDecoratedDescriptor(_obj, "flagTypes", [_dec2], Object.getOwnPropertyDescriptor(_obj, "flagTypes"), _obj), _applyDecoratedDescriptor(_obj, "sortedCategories", [_dec3], Object.getOwnPropertyDescriptor(_obj, "sortedCategories"), _obj), _applyDecoratedDescriptor(_obj, "categoriesList", [_dec4], Object.getOwnPropertyDescriptor(_obj, "categoriesList"), _obj)), _obj)));

  Site.reopenClass(_singleton.default, {
    // The current singleton will retrieve its attributes from the `PreloadStore`.
    createCurrent: function createCurrent() {
      var store = (0, _getOwner.getOwner)(this).lookup("service:store");

      var siteAttributes = _preloadStore.default.get("site");

      siteAttributes["isReadOnly"] = _preloadStore.default.get("isReadOnly");
      return store.createRecord("site", siteAttributes);
    },
    create: function create() {
      var result = this._super.apply(this, arguments);

      var store = result.store;

      if (result.categories) {
        var subcatMap = {};
        result.categoriesById = {};
        result.categories = result.categories.map(function (c) {
          if (c.parent_category_id) {
            subcatMap[c.parent_category_id] = subcatMap[c.parent_category_id] || [];
            subcatMap[c.parent_category_id].push(c.id);
          }

          return result.categoriesById[c.id] = store.createRecord("category", c);
        }); // Associate the categories with their parents

        result.categories.forEach(function (c) {
          var subcategoryIds = subcatMap[c.get("id")];

          if (subcategoryIds) {
            c.set("subcategories", subcategoryIds.map(function (id) {
              return result.categoriesById[id];
            }));
          }

          if (c.get("parent_category_id")) {
            c.set("parentCategory", result.categoriesById[c.get("parent_category_id")]);
          }
        });
      }

      if (result.trust_levels) {
        result.trustLevels = Object.entries(result.trust_levels).map(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              key = _ref2[0],
              id = _ref2[1];

          return new _trustLevel.default(id, key);
        });
        delete result.trust_levels;
      }

      if (result.post_action_types) {
        result.postActionByIdLookup = Ember.Object.create();
        result.post_action_types = result.post_action_types.map(function (p) {
          var actionType = _postActionType.default.create(p);

          result.postActionByIdLookup.set("action" + p.id, actionType);
          return actionType;
        });
      }

      if (result.topic_flag_types) {
        result.topicFlagByIdLookup = Ember.Object.create();
        result.topic_flag_types = result.topic_flag_types.map(function (p) {
          var actionType = _postActionType.default.create(p);

          result.topicFlagByIdLookup.set("action" + p.id, actionType);
          return actionType;
        });
      }

      if (result.archetypes) {
        result.archetypes = result.archetypes.map(function (a) {
          a.site = result;
          return _archetype.default.create(a);
        });
      }

      if (result.user_fields) {
        result.user_fields = result.user_fields.map(function (uf) {
          return Ember.Object.create(uf);
        });
      }

      return result;
    }
  });

  if (typeof Discourse !== "undefined") {
    var warned = false; // eslint-disable-next-line no-undef

    Object.defineProperty(Discourse, "Site", {
      get: function get() {
        if (!warned) {
          (0, _deprecated.default)("Import the Site class instead of using Discourse.Site", {
            since: "2.4.0",
            dropFrom: "2.6.0"
          });
          warned = true;
        }

        return Site;
      }
    });
  }

  var _default = Site;
  _exports.default = _default;
});