define("discourse/controllers/tags-index", ["exports", "I18n", "discourse/lib/ajax", "bootbox", "discourse-common/utils/decorators", "discourse/lib/ajax-error", "discourse/lib/show-modal"], function (_exports, _I18n, _ajax, _bootbox, _decorators, _ajaxError, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("groupedByCategory", "groupedByTagGroup"), (_obj = {
    sortedByCount: true,
    sortedByName: false,
    canAdminTags: Ember.computed.alias("currentUser.staff"),
    groupedByCategory: Ember.computed.notEmpty("model.extras.categories"),
    groupedByTagGroup: Ember.computed.notEmpty("model.extras.tag_groups"),
    init: function init() {
      this._super.apply(this, arguments);

      this.sortProperties = ["totalCount:desc", "id"];
    },
    otherTagsTitleKey: function otherTagsTitleKey(groupedByCategory, groupedByTagGroup) {
      if (!groupedByCategory && !groupedByTagGroup) {
        return "tagging.all_tags";
      } else {
        return "tagging.other_tags";
      }
    },
    actionsMapping: function actionsMapping() {
      var _this = this;

      return {
        manageGroups: function manageGroups() {
          return _this.send("showTagGroups");
        },
        uploadTags: function uploadTags() {
          return _this.send("showUploader");
        },
        deleteUnusedTags: function deleteUnusedTags() {
          return _this.send("deleteUnused");
        }
      };
    },
    actions: {
      sortByCount: function sortByCount() {
        this.setProperties({
          sortProperties: ["totalCount:desc", "id"],
          sortedByCount: true,
          sortedByName: false
        });
      },
      sortById: function sortById() {
        this.setProperties({
          sortProperties: ["id"],
          sortedByCount: false,
          sortedByName: true
        });
      },
      showUploader: function showUploader() {
        (0, _showModal.default)("tag-upload");
      },
      deleteUnused: function deleteUnused() {
        var _this2 = this;

        (0, _ajax.ajax)("/tags/unused", {
          type: "GET"
        }).then(function (result) {
          var displayN = 20;
          var tags = result["tags"];

          if (tags.length === 0) {
            _bootbox.default.alert(_I18n.default.t("tagging.delete_no_unused_tags"));

            return;
          }

          var joinedTags = tags.slice(0, displayN).join(_I18n.default.t("tagging.tag_list_joiner"));
          var more = Math.max(0, tags.length - displayN);
          var tagsString = more === 0 ? joinedTags : _I18n.default.t("tagging.delete_unused_confirmation_more_tags", {
            count: more,
            tags: joinedTags
          });

          var string = _I18n.default.t("tagging.delete_unused_confirmation", {
            count: tags.length,
            tags: tagsString
          });

          _bootbox.default.confirm(string, _I18n.default.t("tagging.cancel_delete_unused"), _I18n.default.t("tagging.delete_unused"), function (proceed) {
            if (proceed) {
              (0, _ajax.ajax)("/tags/unused", {
                type: "DELETE"
              }).then(function () {
                return _this2.send("triggerRefresh");
              }).catch(_ajaxError.popupAjaxError);
            }
          });
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "otherTagsTitleKey", [_dec], Object.getOwnPropertyDescriptor(_obj, "otherTagsTitleKey"), _obj), _applyDecoratedDescriptor(_obj, "actionsMapping", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "actionsMapping"), _obj)), _obj)));

  _exports.default = _default;
});