define("discourse/templates/mobile/components/navigation-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c4RQ9+Yw",
    "block": "{\"symbols\":[\"navItem\"],\"statements\":[[7,\"li\",true],[10,\"class\",\"navigation-toggle\"],[8],[0,\"\\n  \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"toggle-link\"],[3,\"action\",[[23,0,[]],\"toggleDrop\"]],[8],[0,\"\\n    \"],[1,[24,[\"selectedNavItem\",\"displayName\"]],false],[0,\"\\n    \"],[1,[28,\"d-icon\",[\"caret-down\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"expanded\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[10,\"class\",\"drop\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"navItems\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"navigation-item\",null,[[\"content\",\"filterMode\",\"category\"],[[23,1,[]],[24,[\"filterMode\"]],[24,[\"category\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"connectorTagName\",\"args\"],[\"extra-nav-item\",\"li\",[28,\"hash\",null,[[\"category\",\"filterMode\"],[[24,[\"category\"]],[24,[\"filterMode\"]]]]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/mobile/components/navigation-bar.hbs"
    }
  });

  _exports.default = _default;
});