define("discourse/routes/preferences-email", ["exports", "discourse/routes/restricted-user"], function (_exports, _restrictedUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _restrictedUser.default.extend({
    showFooter: true,
    model: function model() {
      return this.modelFor("user");
    },
    renderTemplate: function renderTemplate() {
      this.render({
        into: "user"
      });
    },
    setupController: function setupController(controller, model) {
      controller.reset();
      controller.setProperties({
        model: model,
        oldEmail: controller.new ? "" : model.get("email"),
        newEmail: controller.new ? "" : model.get("email")
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("new", undefined);
      }
    },
    // A bit odd, but if we leave to /preferences we need to re-render that outlet
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.render("preferences", {
        into: "user",
        controller: "preferences"
      });
    }
  });

  _exports.default = _default;
});