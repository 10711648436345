define("discourse/templates/components/group-member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gyGsHk9M",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[11,\"href\",[24,[\"member\",\"adminPath\"]]],[8],[0,\"\\n  \"],[1,[28,\"avatar\",[[24,[\"member\"]]],[[\"imageSize\"],[\"small\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"span\",true],[8],[1,[24,[\"member\",\"username\"]],false],[9],[0,\"\\n\"],[4,\"unless\",[[24,[\"automatic\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"remove\"],[3,\"action\",[[23,0,[]],\"remove\"]],[8],[0,\"\\n    \"],[1,[28,\"d-icon\",[\"times\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/group-member.hbs"
    }
  });

  _exports.default = _default;
});