define("discourse/mixins/extendable-uploader", ["exports", "discourse/mixins/upload-debugging"], function (_exports, _uploadDebugging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Use this mixin with any component that needs to upload files or images
   * with Uppy. This mixin makes it easier to tell Uppy to use certain uppy plugins
   * as well as tracking all of the state of preprocessor plugins. For example,
   * you may have multiple preprocessors:
   *
   * - UppyMediaOptimization
   * - UppyChecksum
   *
   * Once installed with _useUploadPlugin(PluginClass, opts), we track the following
   * status for every preprocessor plugin:
   *
   * - needProcessing - The total number of files that have been added to uppy that
   *                    will need to be run through the preprocessor plugins.
   * - activeProcessing - The number of files that are currently being processed,
   *                      which is determined by the preprocess-progress event.
   * - completeProcessing - The number of files that have completed being processed,
   *                        which is determined by the preprocess-complete event.
   * - allComplete - Whether all files have completed the preprocessing for the plugin.
   *
   * There is a caveat - you must call _addNeedProcessing(data.fileIDs.length) when
   * handling the "upload" event with uppy, otherwise this mixin does not know how
   * many files need to be processed.
   *
   * If you need to do something else on progress or completion of preprocessors,
   * hook into the _onPreProcessProgress(callback) or _onPreProcessComplete(callback, allCompleteCallback)
   * functions. Note the _onPreProcessComplete function takes a second callback
   * that is fired only when _all_ of the files have been preprocessed for all
   * preprocessor plugins.
   *
   * A preprocessor is considered complete if the completeProcessing count is
   * equal to needProcessing, at which point the allComplete prop is set to true.
   * If all preprocessor plugins have allComplete set to true, then the allCompleteCallback
   * is called for _onPreProcessComplete.
   *
   * To completely reset the preprocessor state for all plugins, call _resetPreProcessors.
   *
   * See ComposerUploadUppy for an example of a component using this mixin.
   */
  var _default = Ember.Mixin.create(_uploadDebugging.default, {
    _useUploadPlugin: function _useUploadPlugin(pluginClass) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (!this._uppyInstance) {
        return;
      }

      if (!pluginClass.pluginId) {
        throw new Error("The uppy plugin should have a static pluginId that is used to uniquely identify it.");
      }

      if (!pluginClass.pluginType || !["preprocessor", "uploader"].includes(pluginClass.pluginType)) {
        throw new Error("The uppy plugin ".concat(pluginClass.pluginId, " should have a static pluginType that should be preprocessor or uploader"));
      }

      this._uppyInstance.use(pluginClass, Object.assign(opts, {
        id: pluginClass.pluginId,
        type: pluginClass.pluginType
      }));

      if (pluginClass.pluginType === "preprocessor") {
        this._trackPreProcessorStatus(pluginClass.pluginId);
      }
    },
    // TODO (martin) This and _onPreProcessComplete will need to be tweaked
    // if we ever add support for "determinate" preprocessors for uppy, which
    // means the progress will have a value rather than a started/complete
    // state ("indeterminate").
    //
    // See: https://uppy.io/docs/writing-plugins/#Progress-events
    _onPreProcessProgress: function _onPreProcessProgress(callback) {
      var _this = this;

      this._uppyInstance.on("preprocess-progress", function (file, progress, pluginId) {
        _this._consoleDebug("[".concat(pluginId, "] processing file ").concat(file.name, " (").concat(file.id, ")"));

        _this._preProcessorStatus[pluginId].activeProcessing++;
        callback(file);
      });
    },
    _onPreProcessComplete: function _onPreProcessComplete(callback, allCompleteCallback) {
      var _this2 = this;

      this._uppyInstance.on("preprocess-complete", function (file, skipped, pluginId) {
        _this2._consoleDebug("[".concat(pluginId, "] ").concat(skipped ? "skipped" : "completed", " processing file ").concat(file.name, " (").concat(file.id, ")"));

        callback(file);

        _this2._completePreProcessing(pluginId, function (allComplete) {
          if (allComplete) {
            _this2._consoleDebug("[uppy] All upload preprocessors complete!");

            allCompleteCallback();
          }
        });
      });
    },
    _resetPreProcessors: function _resetPreProcessors() {
      var _this3 = this;

      this._eachPreProcessor(function (pluginId) {
        _this3._preProcessorStatus[pluginId] = {
          needProcessing: 0,
          activeProcessing: 0,
          completeProcessing: 0,
          allComplete: false
        };
      });
    },
    _trackPreProcessorStatus: function _trackPreProcessorStatus(pluginId) {
      if (!this._preProcessorStatus) {
        this._preProcessorStatus = {};
      }

      this._preProcessorStatus[pluginId] = {
        needProcessing: 0,
        activeProcessing: 0,
        completeProcessing: 0,
        allComplete: false
      };
    },
    _addNeedProcessing: function _addNeedProcessing(fileCount) {
      this._eachPreProcessor(function (pluginName, status) {
        status.needProcessing += fileCount;
      });
    },
    _eachPreProcessor: function _eachPreProcessor(cb) {
      for (var _i = 0, _Object$entries = Object.entries(this._preProcessorStatus); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            pluginId = _Object$entries$_i[0],
            status = _Object$entries$_i[1];

        cb(pluginId, status);
      }
    },
    _allPreprocessorsComplete: function _allPreprocessorsComplete() {
      var completed = [];

      this._eachPreProcessor(function (pluginId, status) {
        completed.push(status.allComplete);
      });

      return completed.every(Boolean);
    },
    _completePreProcessing: function _completePreProcessing(pluginId, callback) {
      var preProcessorStatus = this._preProcessorStatus[pluginId];
      preProcessorStatus.activeProcessing--;
      preProcessorStatus.completeProcessing++;

      if (preProcessorStatus.completeProcessing === preProcessorStatus.needProcessing) {
        preProcessorStatus.allComplete = true;
        preProcessorStatus.needProcessing = 0;

        if (this._allPreprocessorsComplete()) {
          callback(true);
        } else {
          callback(false);
        }
      }
    }
  });

  _exports.default = _default;
});