define("discourse/initializers/show-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "show-footer",
    initialize: function initialize(container) {
      var router = container.lookup("router:main");
      var application = container.lookup("controller:application"); // only take care of hiding the footer here
      // controllers MUST take care of displaying it

      router.on("routeWillChange", function () {
        application.set("showFooter", false);
        return true;
      });
    }
  };
  _exports.default = _default;
});