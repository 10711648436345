define("discourse/lib/preload-store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    data: new Map(),
    store: function store(key, value) {
      this.data.set(key, value);
    },

    /**
      To retrieve a key, you provide the key you want, plus a finder to load
      it if the key cannot be found. Once the key is used once, it is removed
      from the store.
      So, for example, you can't load a preloaded topic more than once.
    **/
    getAndRemove: function getAndRemove(key, finder) {
      if (this.data.has(key)) {
        var promise = Ember.RSVP.Promise.resolve(this.data.get(key));
        this.data.delete(key);
        return promise;
      }

      if (finder) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          var result = finder(); // If the finder returns a promise, we support that too

          if (result && result.then) {
            result.then(function (toResolve) {
              return resolve(toResolve);
            }).catch(function (toReject) {
              return reject(toReject);
            });
          } else {
            resolve(result);
          }
        });
      }

      return Ember.RSVP.Promise.resolve(null);
    },
    get: function get(key) {
      return this.data.get(key);
    },
    remove: function remove(key) {
      this.data.delete(key);
    },
    reset: function reset() {
      this.data = new Map();
    }
  };
  _exports.default = _default;
});