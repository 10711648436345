define("discourse/controllers/edit-category-tabs", ["exports", "discourse-common/utils/decorators", "discourse/models/category", "discourse/lib/url", "I18n", "discourse/lib/notification-levels", "discourse/models/permission-type", "bootbox", "discourse/lib/ajax-error"], function (_exports, _decorators, _category, _url, _I18n, _notificationLevels, _permissionType, _bootbox, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.default)("saving", "model.name", "model.color", "deleting"), _dec3 = (0, _decorators.default)("saving", "deleting"), _dec4 = (0, _decorators.default)("name"), _dec5 = (0, _decorators.default)("saving", "model.id"), _dec6 = (0, _decorators.default)("model.id", "model.name"), _dec7 = (0, _decorators.default)("selectedTab"), (_obj = {
    selectedTab: "general",
    saving: false,
    deleting: false,
    panels: null,
    showTooltip: false,
    createdCategory: false,
    expandedMenu: false,
    mobileView: Ember.computed.readOnly("site.mobileView"),
    parentParams: null,
    showDeleteReason: Ember.computed.and("showTooltip", "model.cannot_delete_reason"),
    _initPanels: function _initPanels() {
      this.setProperties({
        panels: [],
        validators: []
      });
    },
    disabled: function disabled(saving, name, color, deleting) {
      if (saving || deleting) {
        return true;
      }

      if (!name) {
        return true;
      }

      if (!color) {
        return true;
      }

      return false;
    },
    deleteDisabled: function deleteDisabled(saving, deleting) {
      return deleting || saving || false;
    },
    categoryName: function categoryName(name) {
      name = name || "";
      return name.trim().length > 0 ? name : _I18n.default.t("preview");
    },
    saveLabel: function saveLabel(saving, id) {
      if (saving) {
        return "saving";
      }

      return id ? "category.save" : "category.create";
    },
    title: function title(id, name) {
      return id ? _I18n.default.t("category.edit_dialog_title", {
        categoryName: name
      }) : _I18n.default.t("category.create");
    },
    selectedTabTitle: function selectedTabTitle(tab) {
      return _I18n.default.t("category.".concat(Ember.String.underscore(tab)));
    },
    actions: {
      registerValidator: function registerValidator(validator) {
        this.validators.push(validator);
      },
      saveCategory: function saveCategory() {
        var _this = this;

        if (this.validators.some(function (validator) {
          return validator();
        })) {
          return;
        }

        var model = this.model;
        var parentCategory = this.site.categories.findBy("id", parseInt(model.parent_category_id, 10));
        this.set("saving", true);
        model.set("parentCategory", parentCategory);
        model.save().then(function (result) {
          _this.set("saving", false);

          if (!model.id) {
            model.setProperties({
              slug: result.category.slug,
              id: result.category.id,
              can_edit: result.category.can_edit,
              permission: _permissionType.default.FULL,
              notification_level: _notificationLevels.NotificationLevels.REGULAR
            });

            _this.site.updateCategory(model);

            _this.transitionToRoute("editCategory", _category.default.slugFor(model));
          }
        }).catch(function (error) {
          _bootbox.default.alert((0, _ajaxError.extractError)(error));

          _this.set("saving", false);
        });
      },
      deleteCategory: function deleteCategory() {
        var _this2 = this;

        this.set("deleting", true);

        _bootbox.default.confirm(_I18n.default.t("category.delete_confirm"), _I18n.default.t("no_value"), _I18n.default.t("yes_value"), function (result) {
          if (result) {
            _this2.model.destroy().then(function () {
              _this2.transitionToRoute("discovery.categories");
            }).catch(function () {
              _this2.displayErrors([_I18n.default.t("category.delete_error")]);
            }).finally(function () {
              _this2.set("deleting", false);
            });
          } else {
            _this2.set("deleting", false);
          }
        });
      },
      toggleDeleteTooltip: function toggleDeleteTooltip() {
        this.toggleProperty("showTooltip");
      },
      goBack: function goBack() {
        _url.default.routeTo(this.model.url);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "_initPanels", [_dec], Object.getOwnPropertyDescriptor(_obj, "_initPanels"), _obj), _applyDecoratedDescriptor(_obj, "disabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "disabled"), _obj), _applyDecoratedDescriptor(_obj, "deleteDisabled", [_dec3], Object.getOwnPropertyDescriptor(_obj, "deleteDisabled"), _obj), _applyDecoratedDescriptor(_obj, "categoryName", [_dec4], Object.getOwnPropertyDescriptor(_obj, "categoryName"), _obj), _applyDecoratedDescriptor(_obj, "saveLabel", [_dec5], Object.getOwnPropertyDescriptor(_obj, "saveLabel"), _obj), _applyDecoratedDescriptor(_obj, "title", [_dec6], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "selectedTabTitle", [_dec7], Object.getOwnPropertyDescriptor(_obj, "selectedTabTitle"), _obj)), _obj)));

  _exports.default = _default;
});