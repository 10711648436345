define("discourse/controllers/create-invite", ["exports", "discourse-common/utils/decorators", "discourse/lib/ajax-error", "discourse/lib/pwa-utils", "discourse/lib/utilities", "discourse/mixins/buffered-content", "discourse/mixins/modal-functionality", "discourse/models/group", "discourse/models/invite", "I18n", "select-kit/components/future-date-input-selector", "discourse/lib/text"], function (_exports, _decorators, _ajaxError, _pwaUtils, _utilities, _bufferedContent, _modalFunctionality, _group, _invite, _I18n, _futureDateInputSelector, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _obj;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, (0, _bufferedContent.bufferedProperty)("invite"), (_dec = (0, _decorators.default)("buffered.emailOrDomain"), _dec2 = (0, _decorators.default)("buffered.emailOrDomain"), _dec3 = (0, _decorators.default)("currentUser.staff", "siteSettings.invite_link_max_redemptions_limit", "siteSettings.invite_link_max_redemptions_limit_users"), _dec4 = (0, _decorators.default)("buffered.expires_at"), _dec5 = (0, _decorators.default)("currentUser.staff", "currentUser.groups"), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_obj = {
    allGroups: null,
    flashText: null,
    flashClass: null,
    flashLink: false,
    invite: null,
    invites: null,
    editing: false,
    inviteToTopic: false,
    limitToEmail: false,
    isEmail: function isEmail(emailOrDomain) {
      return (0, _utilities.emailValid)(emailOrDomain);
    },
    isDomain: function isDomain(emailOrDomain) {
      return (0, _utilities.hostnameValid)(emailOrDomain);
    },
    isLink: Ember.computed.not("isEmail"),
    onShow: function onShow() {
      var _this = this;

      _group.default.findAll().then(function (groups) {
        _this.set("allGroups", groups.filterBy("automatic", false));
      });

      this.setProperties({
        flashText: null,
        flashClass: null,
        flashLink: false,
        invite: null,
        invites: null,
        editing: false,
        inviteToTopic: false,
        limitToEmail: false
      });
      this.setInvite(_invite.default.create());
      this.buffered.setProperties({
        max_redemptions_allowed: 1,
        expires_at: moment().add(this.siteSettings.invite_expiry_days, "days").format(_futureDateInputSelector.FORMAT)
      });
    },
    onClose: function onClose() {
      this.appEvents.trigger("modal-body:clearFlash");
    },
    setInvite: function setInvite(invite) {
      this.set("invite", invite);
    },
    save: function save(opts) {
      var _this2 = this;

      var data = _objectSpread({}, this.buffered.buffer);

      if (data.emailOrDomain) {
        if ((0, _utilities.emailValid)(data.emailOrDomain)) {
          data.email = data.emailOrDomain;
        } else if ((0, _utilities.hostnameValid)(data.emailOrDomain)) {
          data.domain = data.emailOrDomain;
        }

        delete data.emailOrDomain;
      }

      if (data.groupIds !== undefined) {
        data.group_ids = data.groupIds.length > 0 ? data.groupIds : "";
        delete data.groupIds;
      }

      if (data.topicId !== undefined) {
        data.topic_id = data.topicId;
        delete data.topicId;
        delete data.topicTitle;
      }

      if (this.isLink) {
        if (this.invite.email) {
          data.email = data.custom_message = "";
        }
      } else if (this.isEmail) {
        if (this.invite.max_redemptions_allowed > 1) {
          data.max_redemptions_allowed = 1;
        }

        if (opts.sendEmail) {
          data.send_email = true;

          if (this.inviteToTopic) {
            data.invite_to_topic = true;
          }
        } else {
          data.skip_email = true;
        }
      }

      return this.invite.save(data).then(function (result) {
        _this2.rollbackBuffer();

        if (_this2.invites && !_this2.invites.any(function (i) {
          return i.id === _this2.invite.id;
        })) {
          _this2.invites.unshiftObject(_this2.invite);
        }

        if (result.warnings) {
          _this2.setProperties({
            flashText: (0, _text.sanitize)(result.warnings.join(",")),
            flashClass: "warning",
            flashLink: !_this2.editing
          });
        } else {
          if (_this2.isEmail && opts.sendEmail) {
            _this2.send("closeModal");
          } else {
            _this2.setProperties({
              flashText: (0, _text.sanitize)(_I18n.default.t("user.invited.invite.invite_saved")),
              flashClass: "success",
              flashLink: !_this2.editing
            });
          }
        }
      }).catch(function (e) {
        return _this2.setProperties({
          flashText: (0, _text.sanitize)((0, _ajaxError.extractError)(e)),
          flashClass: "error",
          flashLink: false
        });
      });
    },
    maxRedemptionsAllowedLimit: function maxRedemptionsAllowedLimit(staff, staffLimit, usersLimit) {
      return staff ? staffLimit : usersLimit;
    },
    expiresAtLabel: function expiresAtLabel(expires_at) {
      var expiresAt = moment(expires_at);
      return expiresAt.isBefore() ? _I18n.default.t("user.invited.invite.expired_at_time", {
        time: expiresAt.format("LLL")
      }) : _I18n.default.t("user.invited.invite.expires_in_time", {
        time: moment.duration(expiresAt - moment()).humanize()
      });
    },
    canInviteToGroup: function canInviteToGroup(staff, groups) {
      return staff || groups.any(function (g) {
        return g.owner;
      });
    },
    copied: function copied() {
      this.save({
        sendEmail: false,
        copy: true
      });
    },
    saveInvite: function saveInvite(sendEmail) {
      this.appEvents.trigger("modal-body:clearFlash");
      this.save({
        sendEmail: sendEmail
      });
    },
    searchContact: function searchContact() {
      var _this3 = this;

      (0, _pwaUtils.getNativeContact)(this.capabilities, ["email"], false).then(function (result) {
        _this3.set("buffered.email", result[0].email[0]);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "isEmail", [_dec], Object.getOwnPropertyDescriptor(_obj, "isEmail"), _obj), _applyDecoratedDescriptor(_obj, "isDomain", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isDomain"), _obj), _applyDecoratedDescriptor(_obj, "maxRedemptionsAllowedLimit", [_dec3], Object.getOwnPropertyDescriptor(_obj, "maxRedemptionsAllowedLimit"), _obj), _applyDecoratedDescriptor(_obj, "expiresAtLabel", [_dec4], Object.getOwnPropertyDescriptor(_obj, "expiresAtLabel"), _obj), _applyDecoratedDescriptor(_obj, "canInviteToGroup", [_dec5], Object.getOwnPropertyDescriptor(_obj, "canInviteToGroup"), _obj), _applyDecoratedDescriptor(_obj, "copied", [_dec6], Object.getOwnPropertyDescriptor(_obj, "copied"), _obj), _applyDecoratedDescriptor(_obj, "saveInvite", [_dec7], Object.getOwnPropertyDescriptor(_obj, "saveInvite"), _obj), _applyDecoratedDescriptor(_obj, "searchContact", [_dec8], Object.getOwnPropertyDescriptor(_obj, "searchContact"), _obj)), _obj)));

  _exports.default = _default;
});