define("discourse/widgets/user-menu", ["exports", "discourse/widgets/widget", "virtual-dom", "discourse/lib/show-modal"], function (_exports, _widget, _virtualDom, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addUserMenuGlyph = addUserMenuGlyph;
  _exports.default = void 0;
  var UserMenuAction = {
    QUICK_ACCESS: "quickAccess"
  };
  var QuickAccess = {
    BOOKMARKS: "bookmarks",
    MESSAGES: "messages",
    NOTIFICATIONS: "notifications",
    PROFILE: "profile"
  };
  var Titles = {
    bookmarks: "user.bookmarks",
    messages: "user.private_messages",
    notifications: "user.notifications",
    profile: "user.preferences"
  };
  var extraGlyphs;

  function addUserMenuGlyph(glyph) {
    extraGlyphs = extraGlyphs || [];
    extraGlyphs.push(glyph);
  }

  (0, _widget.createWidget)("user-menu-links", {
    tagName: "div.menu-links-header",
    _tabAttrs: function _tabAttrs(quickAccessType) {
      return {
        "aria-controls": "quick-access-".concat(quickAccessType),
        "aria-selected": "false",
        tabindex: "-1"
      };
    },
    // TODO: Remove when 2.7 gets released.
    _structureAsTab: function _structureAsTab(extraGlyph) {
      var glyph = extraGlyph; // Assume glyph is a button if it has a data-url field.

      if (!glyph.data || !glyph.data.url) {
        glyph.title = glyph.label;
        glyph.data = {
          url: glyph.href
        };
        glyph.label = null;
        glyph.href = null;
      }

      if (glyph.className) {
        glyph.className += " menu-link";
      } else {
        glyph.className = "menu-link";
      }

      glyph.role = "tab";
      glyph.tabAttrs = this._tabAttrs(glyph.actionParam);
      return glyph;
    },
    profileGlyph: function profileGlyph() {
      return {
        title: Titles["profile"],
        className: "user-preferences-link menu-link",
        id: QuickAccess.PROFILE,
        icon: "user",
        action: UserMenuAction.QUICK_ACCESS,
        actionParam: QuickAccess.PROFILE,
        data: {
          url: "".concat(this.attrs.path, "/summary")
        },
        role: "tab",
        tabAttrs: this._tabAttrs(QuickAccess.PROFILE)
      };
    },
    notificationsGlyph: function notificationsGlyph() {
      return {
        title: Titles["notifications"],
        className: "user-notifications-link menu-link",
        id: QuickAccess.NOTIFICATIONS,
        icon: "bell",
        action: UserMenuAction.QUICK_ACCESS,
        actionParam: QuickAccess.NOTIFICATIONS,
        data: {
          url: "".concat(this.attrs.path, "/notifications")
        },
        role: "tab",
        tabAttrs: this._tabAttrs(QuickAccess.NOTIFICATIONS)
      };
    },
    bookmarksGlyph: function bookmarksGlyph() {
      return {
        title: Titles["bookmarks"],
        action: UserMenuAction.QUICK_ACCESS,
        actionParam: QuickAccess.BOOKMARKS,
        className: "user-bookmarks-link menu-link",
        id: QuickAccess.BOOKMARKS,
        icon: "bookmark",
        data: {
          url: "".concat(this.attrs.path, "/activity/bookmarks")
        },
        "aria-label": "user.bookmarks",
        role: "tab",
        tabAttrs: this._tabAttrs(QuickAccess.BOOKMARKS)
      };
    },
    messagesGlyph: function messagesGlyph() {
      return {
        title: Titles["messages"],
        action: UserMenuAction.QUICK_ACCESS,
        actionParam: QuickAccess.MESSAGES,
        className: "user-pms-link menu-link",
        id: QuickAccess.MESSAGES,
        icon: "envelope",
        data: {
          url: "".concat(this.attrs.path, "/messages")
        },
        role: "tab",
        tabAttrs: this._tabAttrs(QuickAccess.MESSAGES)
      };
    },
    linkHtml: function linkHtml(link) {
      if (this.isActive(link)) {
        link = this.markAsActive(link);
      }

      return this.attach("link", link);
    },
    glyphHtml: function glyphHtml(glyph, idx) {
      if (this.isActive(glyph)) {
        glyph = this.markAsActive(glyph);
      }

      glyph.data["tab-number"] = "".concat(idx);
      return this.attach("flat-button", glyph);
    },
    html: function html() {
      var _this = this;

      var glyphs = [this.notificationsGlyph()];

      if (extraGlyphs) {
        extraGlyphs.forEach(function (g) {
          if (typeof g === "function") {
            g = g(_this);
          }

          if (g) {
            var structuredGlyph = _this._structureAsTab(g);

            Titles[structuredGlyph.actionParam] = structuredGlyph.title || structuredGlyph.label;
            glyphs.push(structuredGlyph);
          }
        });
      }

      glyphs.push(this.bookmarksGlyph());

      if (this.siteSettings.enable_personal_messages || this.currentUser.staff) {
        glyphs.push(this.messagesGlyph());
      }

      glyphs.push(this.profileGlyph());
      return (0, _virtualDom.h)("div.menu-links-row", [(0, _virtualDom.h)("div.glyphs", {
        attributes: {
          "aria-label": "Menu links",
          role: "tablist"
        }
      }, glyphs.map(function (l, index) {
        return _this.glyphHtml(l, index);
      }))]);
    },
    markAsActive: function markAsActive(definition) {
      // Clicking on an active quick access tab icon should redirect the user to
      // the full page.
      definition.action = null;
      definition.actionParam = null;
      definition.url = definition.data.url;

      if (definition.className) {
        definition.className += " active";
      } else {
        definition.className = "active";
      }

      definition.tabAttrs["tabindex"] = "0";
      definition.tabAttrs["aria-selected"] = "true";
      return definition;
    },
    isActive: function isActive(_ref) {
      var action = _ref.action,
          actionParam = _ref.actionParam;
      return action === UserMenuAction.QUICK_ACCESS && actionParam === this.attrs.currentQuickAccess;
    }
  });

  var _default = (0, _widget.createWidget)("user-menu", {
    tagName: "div.user-menu",
    buildKey: function buildKey() {
      return "user-menu";
    },
    settings: {
      maxWidth: 320,
      showLogoutButton: true
    },
    userMenuNavigation: function userMenuNavigation(nav) {
      var maxTabNumber = document.querySelectorAll(".glyphs button").length - 1;
      var isLeft = nav.key === "ArrowLeft";
      var nextTab = isLeft ? nav.tabNumber - 1 : nav.tabNumber + 1;

      if (isLeft && nextTab < 0) {
        nextTab = maxTabNumber;
      }

      if (!isLeft && nextTab > maxTabNumber) {
        nextTab = 0;
      }

      document.querySelector(".menu-link[role='tab'][data-tab-number='".concat(nextTab, "']")).focus();
    },
    defaultState: function defaultState() {
      return {
        currentQuickAccess: QuickAccess.NOTIFICATIONS,
        titleKey: Titles["notifications"],
        hasUnread: false,
        markUnread: null
      };
    },
    panelContents: function panelContents() {
      var path = this.currentUser.get("path");
      var _this$state = this.state,
          currentQuickAccess = _this$state.currentQuickAccess,
          titleKey = _this$state.titleKey;
      var result = [this.attach("user-menu-links", {
        path: path,
        currentQuickAccess: currentQuickAccess
      }), this.quickAccessPanel(path, titleKey, currentQuickAccess)];
      return result;
    },
    dismissNotifications: function dismissNotifications() {
      var _this2 = this;

      var unreadHighPriorityNotifications = this.currentUser.get("unread_high_priority_notifications");

      if (unreadHighPriorityNotifications > 0) {
        return (0, _showModal.default)("dismiss-notification-confirmation").setProperties({
          count: unreadHighPriorityNotifications,
          dismissNotifications: function dismissNotifications() {
            return _this2.state.markRead();
          }
        });
      } else {
        return this.state.markRead();
      }
    },
    itemsLoaded: function itemsLoaded(_ref2) {
      var hasUnread = _ref2.hasUnread,
          markRead = _ref2.markRead;
      this.state.hasUnread = hasUnread;
      this.state.markRead = markRead;
    },
    html: function html() {
      var _this3 = this;

      return this.attach("menu-panel", {
        maxWidth: this.settings.maxWidth,
        contents: function contents() {
          return _this3.panelContents();
        }
      });
    },
    clickOutsideMobile: function clickOutsideMobile(e) {
      var _this4 = this;

      var centeredElement = document.elementFromPoint(e.clientX, e.clientY);
      var parents = document.elementsFromPoint(e.clientX, e.clientY).some(function (ele) {
        return ele.classList.contains("panel");
      });

      if (!centeredElement.classList.contains("header-cloak") && parents) {
        this.sendWidgetAction("toggleUserMenu");
      } else {
        var windowWidth = document.body.offsetWidth;
        var panel = document.querySelector(".menu-panel");
        panel.classList.add("animate");
        var offsetDirection = document.querySelector("html").classList["direction"] === "rtl" ? -1 : 1;
        panel.style.setProperty("--offset", "".concat(offsetDirection * windowWidth, "px"));
        var headerCloak = document.querySelector(".header-cloak");
        headerCloak.classList.add("animate");
        headerCloak.style.setProperty("--opacity", 0);
        Ember.run.later(function () {
          return _this4.sendWidgetAction("toggleUserMenu");
        }, 200);
      }
    },
    clickOutside: function clickOutside(e) {
      if (this.site.mobileView) {
        this.clickOutsideMobile(e);
      } else {
        this.sendWidgetAction("toggleUserMenu");
      }
    },
    quickAccess: function quickAccess(type) {
      if (this.state.currentQuickAccess !== type) {
        this.state.currentQuickAccess = type;
        this.state.titleKey = Titles[type];
      }
    },
    quickAccessPanel: function quickAccessPanel(path, titleKey, currentQuickAccess) {
      var showLogoutButton = this.settings.showLogoutButton; // This deliberately does NOT fallback to a default quick access panel.

      return this.attach("quick-access-".concat(this.state.currentQuickAccess), {
        path: path,
        showLogoutButton: showLogoutButton,
        titleKey: titleKey,
        currentQuickAccess: currentQuickAccess
      });
    }
  });

  _exports.default = _default;
});