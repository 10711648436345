define("discourse/routes/group-permissions", ["exports", "discourse/routes/discourse", "I18n", "discourse/lib/ajax", "discourse/models/permission-type"], function (_exports, _discourse, _I18n, _ajax, _permissionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    showFooter: true,
    titleToken: function titleToken() {
      return _I18n.default.t("groups.permissions.title");
    },
    model: function model() {
      var _this = this;

      var group = this.modelFor("group");
      return (0, _ajax.ajax)("/g/".concat(group.name, "/permissions")).then(function (permissions) {
        permissions.forEach(function (permission) {
          permission.description = (0, _permissionType.buildPermissionDescription)(permission.permission_type);
        });
        return {
          permissions: permissions
        };
      }).catch(function () {
        _this.transitionTo("group.members", group);
      });
    },
    setupController: function setupController(controller, model) {
      this.controllerFor("group-permissions").setProperties({
        model: model
      });
      this.controllerFor("group").set("showing", "permissions");
    }
  });

  _exports.default = _default;
});