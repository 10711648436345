define("discourse/components/time-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function convertMinutes(num) {
    return {
      hours: Math.floor(num / 60),
      minutes: num % 60
    };
  }

  function convertMinutesToString(n) {
    var hoursAndMinutes = convertMinutes(n);
    return "".concat(hoursAndMinutes.hours.toString().padStart(2, "0"), ":").concat(hoursAndMinutes.minutes.toString().padStart(2, "0"));
  }

  function convertMinutesToDurationString(n) {
    var hoursAndMinutes = convertMinutes(n);
    var output = "";

    if (hoursAndMinutes.hours) {
      output = "".concat(hoursAndMinutes.hours, "h");

      if (hoursAndMinutes.minutes > 0) {
        output = "".concat(output, " ").concat(hoursAndMinutes.minutes, " min");
      }
    } else {
      output = "".concat(hoursAndMinutes.minutes, " min");
    }

    return output;
  }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    classNames: ["d-time-input"],
    hours: null,
    minutes: null,
    relativeDate: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (Ember.isPresent(this.date)) {
        this.setProperties({
          hours: this.date.hours(),
          minutes: this.date.minutes()
        });
      }

      if (!Ember.isPresent(this.date) && !Ember.isPresent(this.attrs.hours) && !Ember.isPresent(this.attrs.minutes)) {
        this.setProperties({
          hours: null,
          minutes: null
        });
      }
    },
    minimumTime: Ember.computed("relativeDate", "date", function () {
      if (this.relativeDate) {
        if (this.date) {
          if (this.date.diff(this.relativeDate, "minutes") > 1440) {
            return 0;
          } else {
            return this.relativeDate.hours() * 60 + this.relativeDate.minutes();
          }
        } else {
          return this.relativeDate.hours() * 60 + this.relativeDate.minutes();
        }
      }
    }),
    timeOptions: Ember.computed("minimumTime", "hours", "minutes", function () {
      var _this = this;

      var options = [];
      var start = this.minimumTime ? this.minimumTime > this.time ? this.time : this.minimumTime : 0; // theres 1440 minutes in a day
      // and 1440 / 15 = 96

      var i = 0;

      while (i < 96) {
        // while diff with minimumTime is less than one hour
        // use 15 minutes steps and then 30 minutes
        var minutes = this.minimumTime ? i <= 4 ? 15 : 30 : 15;
        var option = start + i * minutes; // when start is higher than 0 we will reach 1440 minutes
        // before the 96 iterations

        if (option > 1440) {
          break;
        }

        options.push(option);
        i++;
      }

      if (this.time && !options.includes(this.time)) {
        options = [this.time].concat(options);
      }

      options = options.sort(function (a, b) {
        return a - b;
      });
      return options.map(function (option) {
        var name = convertMinutesToString(option);
        var label;

        if (_this.minimumTime) {
          var diff = option - _this.minimumTime;
          label = "".concat(name, " <small>(").concat(convertMinutesToDurationString(diff), ")</small>").htmlSafe();
        }

        return {
          id: option,
          name: name,
          label: label,
          title: name
        };
      });
    }),
    time: Ember.computed("minimumTime", "hours", "minutes", function () {
      if (Ember.isPresent(this.hours) && Ember.isPresent(this.minutes)) {
        return parseInt(this.hours, 10) * 60 + parseInt(this.minutes, 10);
      }
    }),
    onFocusIn: function onFocusIn(value, event) {
      if (value && event.target) {
        event.target.select();
      }
    },
    onChangeTime: function onChangeTime(time) {
      if (Ember.isPresent(time) && this.onChange) {
        if (typeof time === "string" && time.length) {
          var _time$split = time.split(":"),
              _time$split2 = _slicedToArray(_time$split, 2),
              hours = _time$split2[0],
              minutes = _time$split2[1];

          if (hours && minutes) {
            if (hours < 0) {
              hours = 0;
            }

            if (hours > 23) {
              hours = 23;
            }

            if (minutes < 0) {
              minutes = 0;
            }

            if (minutes > 59) {
              minutes = 59;
            }

            this.onChange({
              hours: parseInt(hours, 10),
              minutes: parseInt(minutes, 10)
            });
          }
        } else {
          this.onChange({
            hours: convertMinutes(time).hours,
            minutes: convertMinutes(time).minutes
          });
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "onFocusIn", [_dec], Object.getOwnPropertyDescriptor(_obj, "onFocusIn"), _obj), _applyDecoratedDescriptor(_obj, "onChangeTime", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onChangeTime"), _obj)), _obj)));

  _exports.default = _default;
});