define("discourse/components/bread-crumbs", ["exports", "discourse-common/lib/deprecated", "discourse-common/utils/decorators"], function (_exports, _deprecated, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _obj;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  //  A breadcrumb including category drop downs
  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("categories"), _dec2 = (0, _decorators.default)("category.ancestors", "filteredCategories", "noSubcategories"), _dec3 = (0, _decorators.default)("siteSettings.tagging_enabled", "editingCategory"), _dec4 = (0, _decorators.default)("category"), _dec5 = (0, _decorators.default)("parentCategories"), _dec6 = (0, _decorators.default)("category"), _dec7 = (0, _decorators.default)("category", "parentCategory"), _dec8 = (0, _decorators.default)("category", "parentCategory"), _dec9 = (0, _decorators.default)("firstCategory", "hideSubcategories"), (_obj = {
    classNameBindings: ["hidden:hidden", ":category-breadcrumb"],
    tagName: "ol",
    editingCategory: false,
    editingCategoryTab: null,
    filteredCategories: function filteredCategories(categories) {
      var _this = this;

      return categories.filter(function (category) {
        return _this.siteSettings.allow_uncategorized_topics || category.id !== _this.site.uncategorized_category_id;
      });
    },
    categoryBreadcrumbs: function categoryBreadcrumbs(categoryAncestors, filteredCategories, noSubcategories) {
      categoryAncestors = categoryAncestors || [];
      var parentCategories = [undefined].concat(_toConsumableArray(categoryAncestors));
      var categories = [].concat(_toConsumableArray(categoryAncestors), [undefined]);
      var zipped = parentCategories.map(function (x, i) {
        return [x, categories[i]];
      });
      return zipped.map(function (record) {
        var _record = _slicedToArray(record, 2),
            parentCategory = _record[0],
            category = _record[1];

        var options = filteredCategories.filter(function (c) {
          return c.get("parentCategory.id") === (parentCategory && parentCategory.id);
        });
        return {
          category: category,
          parentCategory: parentCategory,
          options: options,
          isSubcategory: !!parentCategory,
          noSubcategories: !category && noSubcategories,
          hasOptions: options.length !== 0
        };
      });
    },
    showTagsSection: function showTagsSection(taggingEnabled, editingCategory) {
      return taggingEnabled && !editingCategory;
    },
    parentCategory: function parentCategory(category) {
      (0, _deprecated.default)("The parentCategory property of the bread-crumbs component is deprecated");
      return category && category.parentCategory;
    },
    parentCategories: Ember.computed.filter("categories", function (c) {
      (0, _deprecated.default)("The parentCategories property of the bread-crumbs component is deprecated");

      if (c.id === this.site.get("uncategorized_category_id") && !this.siteSettings.allow_uncategorized_topics) {
        // Don't show "uncategorized" if allow_uncategorized_topics setting is false.
        return false;
      }

      return !c.get("parentCategory");
    }),
    parentCategoriesSorted: function parentCategoriesSorted(parentCategories) {
      (0, _deprecated.default)("The parentCategoriesSorted property of the bread-crumbs component is deprecated");

      if (this.siteSettings.fixed_category_positions) {
        return parentCategories;
      }

      return parentCategories.sortBy("totalTopicCount").reverse();
    },
    hidden: function hidden(category) {
      return this.site.mobileView && !category;
    },
    firstCategory: function firstCategory(category, parentCategory) {
      (0, _deprecated.default)("The firstCategory property of the bread-crumbs component is deprecated");
      return parentCategory || category;
    },
    secondCategory: function secondCategory(category, parentCategory) {
      (0, _deprecated.default)("The secondCategory property of the bread-crumbs component is deprecated");
      return parentCategory && category;
    },
    childCategories: function childCategories(firstCategory, hideSubcategories) {
      (0, _deprecated.default)("The childCategories property of the bread-crumbs component is deprecated");

      if (hideSubcategories) {
        return [];
      }

      if (!firstCategory) {
        return [];
      }

      return this.categories.filter(function (c) {
        return c.get("parentCategory") === firstCategory;
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "filteredCategories", [_dec], Object.getOwnPropertyDescriptor(_obj, "filteredCategories"), _obj), _applyDecoratedDescriptor(_obj, "categoryBreadcrumbs", [_dec2], Object.getOwnPropertyDescriptor(_obj, "categoryBreadcrumbs"), _obj), _applyDecoratedDescriptor(_obj, "showTagsSection", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showTagsSection"), _obj), _applyDecoratedDescriptor(_obj, "parentCategory", [_dec4], Object.getOwnPropertyDescriptor(_obj, "parentCategory"), _obj), _applyDecoratedDescriptor(_obj, "parentCategoriesSorted", [_dec5], Object.getOwnPropertyDescriptor(_obj, "parentCategoriesSorted"), _obj), _applyDecoratedDescriptor(_obj, "hidden", [_dec6], Object.getOwnPropertyDescriptor(_obj, "hidden"), _obj), _applyDecoratedDescriptor(_obj, "firstCategory", [_dec7], Object.getOwnPropertyDescriptor(_obj, "firstCategory"), _obj), _applyDecoratedDescriptor(_obj, "secondCategory", [_dec8], Object.getOwnPropertyDescriptor(_obj, "secondCategory"), _obj), _applyDecoratedDescriptor(_obj, "childCategories", [_dec9], Object.getOwnPropertyDescriptor(_obj, "childCategories"), _obj)), _obj)));

  _exports.default = _default;
});