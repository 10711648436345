define("discourse/components/backup-codes", ["exports", "discourse/lib/utilities", "discourse-common/utils/decorators"], function (_exports, _utilities, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj, _init;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
  function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode("0x" + p1);
    }));
  }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("formattedBackupCodes"), _dec2 = (0, _decorators.default)("backupCodes"), _dec3 = (0, _decorators.default)(), (_obj = {
    classNames: ["backup-codes"],
    backupCodes: null,
    click: function click(event) {
      if (event.target.id === "backupCodes") {
        this._selectAllBackupCodes();
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var backupCodes = this.element.querySelector("#backupCodes");

      if (backupCodes) {
        backupCodes.style.height = backupCodes.scrollHeight;
      }
    },
    base64BackupCode: b64EncodeUnicode,
    formattedBackupCodes: function formattedBackupCodes(backupCodes) {
      if (!backupCodes) {
        return null;
      }

      return backupCodes.join("\n").trim();
    },
    siteTitleSlug: function siteTitleSlug() {
      var title = this.siteSettings.title;
      var convertedTitle = (0, _utilities.toAsciiPrintable)(title, "discourse");
      return (0, _utilities.slugify)(convertedTitle);
    },
    actions: {
      copyToClipboard: function copyToClipboard() {
        this._selectAllBackupCodes();

        this.copyBackupCode(document.execCommand("copy"));
      }
    },
    _selectAllBackupCodes: function _selectAllBackupCodes() {
      var textArea = this.element.querySelector("#backupCodes");
      textArea.focus();
      textArea.setSelectionRange(0, this.formattedBackupCodes.length);
    }
  }, (_applyDecoratedDescriptor(_obj, "base64BackupCode", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "base64BackupCode"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "formattedBackupCodes", [_dec2], Object.getOwnPropertyDescriptor(_obj, "formattedBackupCodes"), _obj), _applyDecoratedDescriptor(_obj, "siteTitleSlug", [_dec3], Object.getOwnPropertyDescriptor(_obj, "siteTitleSlug"), _obj)), _obj)));

  _exports.default = _default;
});