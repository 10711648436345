define("discourse/routes/user-private-messages", ["exports", "discourse/models/composer", "discourse/routes/discourse", "discourse/models/draft"], function (_exports, _composer, _discourse, _draft) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _discourse.default.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    renderTemplate: function renderTemplate() {
      this.render("user/messages");
    },
    model: function model() {
      return this.modelFor("user");
    },
    afterModel: function afterModel() {
      return this.pmTopicTrackingState.startTracking();
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model);

      if (this.currentUser) {
        var composerController = this.controllerFor("composer");

        _draft.default.get("new_private_message").then(function (data) {
          if (data.draft) {
            composerController.open({
              draft: data.draft,
              draftKey: _composer.default.NEW_PRIVATE_MESSAGE_KEY,
              ignoreIfChanged: true,
              draftSequence: data.draft_sequence
            });
          }
        });
      }
    },
    triggerRefresh: function triggerRefresh() {
      this.refresh();
    },
    willTransition: function willTransition() {
      this._super.apply(this, arguments);

      this.controllerFor("user").set("pmView", null);
      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, "triggerRefresh", [_dec], Object.getOwnPropertyDescriptor(_obj, "triggerRefresh"), _obj), _applyDecoratedDescriptor(_obj, "willTransition", [_dec2], Object.getOwnPropertyDescriptor(_obj, "willTransition"), _obj)), _obj)));

  _exports.default = _default;
});