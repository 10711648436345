define("discourse/initializers/logs-notice", ["exports", "discourse/services/logs-notice", "discourse/mixins/singleton"], function (_exports, _logsNotice, _singleton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var initializedOnce = false;
  var _default = {
    name: "logs-notice",
    after: "message-bus",
    initialize: function initialize(container) {
      if (initializedOnce) {
        return;
      }

      var siteSettings = container.lookup("site-settings:main");
      var messageBus = container.lookup("message-bus:main");
      var keyValueStore = container.lookup("key-value-store:main");
      var currentUser = container.lookup("current-user:main");

      _logsNotice.default.reopenClass(_singleton.default, {
        createCurrent: function createCurrent() {
          return this.create({
            messageBus: messageBus,
            keyValueStore: keyValueStore,
            siteSettings: siteSettings,
            currentUser: currentUser
          });
        }
      });

      initializedOnce = true;
    }
  };
  _exports.default = _default;
});