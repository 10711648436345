define("discourse/controllers/preferences/second-factor", ["exports", "discourse/lib/url", "discourse/mixins/can-check-emails", "I18n", "discourse/models/user", "bootbox", "discourse-common/utils/decorators", "discourse/models/login-method", "discourse-common/lib/icon-library", "discourse/lib/ajax-error", "discourse/lib/show-modal"], function (_exports, _url, _canCheckEmails, _I18n, _user, _bootbox, _decorators, _loginMethod, _iconLibrary, _ajaxError, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_canCheckEmails.default, (_dec = (0, _decorators.default)("currentUser"), (_obj = {
    loading: false,
    dirty: false,
    resetPasswordLoading: false,
    resetPasswordProgress: "",
    password: null,
    errorMessage: null,
    newUsername: null,
    backupEnabled: Ember.computed.alias("model.second_factor_backup_enabled"),
    secondFactorMethod: _user.SECOND_FACTOR_METHODS.TOTP,
    totps: null,
    loaded: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set("totps", []);
    },
    displayOAuthWarning: function displayOAuthWarning() {
      return (0, _loginMethod.findAll)().length > 0;
    },
    showEnforcedNotice: function showEnforcedNotice(user) {
      return user && user.enforcedSecondFactor;
    },
    handleError: function handleError(error) {
      if (error.jqXHR) {
        error = error.jqXHR;
      }

      var parsedJSON = error.responseJSON;

      if (parsedJSON.error_type === "invalid_access") {
        var usernameLower = this.model.username.toLowerCase();

        _url.default.redirectTo((0, _url.userPath)("".concat(usernameLower, "/preferences/second-factor")));
      } else {
        (0, _ajaxError.popupAjaxError)(error);
      }
    },
    loadSecondFactors: function loadSecondFactors() {
      var _this = this;

      if (this.dirty === false) {
        return;
      }

      this.set("loading", true);
      this.model.loadSecondFactorCodes(this.password).then(function (response) {
        if (response.error) {
          _this.set("errorMessage", response.error);

          return;
        }

        _this.setProperties({
          errorMessage: null,
          loaded: true,
          totps: response.totps,
          security_keys: response.security_keys,
          password: null,
          dirty: false
        });

        _this.set("model.second_factor_enabled", response.totps && response.totps.length > 0 || response.security_keys && response.security_keys.length > 0);
      }).catch(function (e) {
        return _this.handleError(e);
      }).finally(function () {
        return _this.set("loading", false);
      });
    },
    markDirty: function markDirty() {
      this.set("dirty", true);
    },
    actions: {
      confirmPassword: function confirmPassword() {
        if (!this.password) {
          return;
        }

        this.markDirty();
        this.loadSecondFactors();
        this.set("password", null);
      },
      resetPassword: function resetPassword() {
        var _this2 = this;

        this.setProperties({
          resetPasswordLoading: true,
          resetPasswordProgress: ""
        });
        return this.model.changePassword().then(function () {
          _this2.set("resetPasswordProgress", _I18n.default.t("user.change_password.success"));
        }).catch(_ajaxError.popupAjaxError).finally(function () {
          return _this2.set("resetPasswordLoading", false);
        });
      },
      disableAllSecondFactors: function disableAllSecondFactors() {
        var _this3 = this;

        if (this.loading) {
          return;
        }

        var message = _I18n.default.t("user.second_factor.disable_confirm");

        var buttons = [{
          label: _I18n.default.t("cancel"),
          class: "d-modal-cancel",
          link: true
        }, {
          icon: (0, _iconLibrary.iconHTML)("ban"),
          label: _I18n.default.t("user.second_factor.disable"),
          class: "btn-danger btn-icon-text",
          callback: function callback() {
            _this3.model.disableAllSecondFactors().then(function () {
              var usernameLower = _this3.model.username.toLowerCase();

              _url.default.redirectTo((0, _url.userPath)("".concat(usernameLower, "/preferences")));
            }).catch(function (e) {
              return _this3.handleError(e);
            }).finally(function () {
              return _this3.set("loading", false);
            });
          }
        }];

        _bootbox.default.dialog(message, buttons, {
          classes: "disable-second-factor-modal"
        });
      },
      createTotp: function createTotp() {
        var _this4 = this;

        var controller = (0, _showModal.default)("second-factor-add-totp", {
          model: this.model,
          title: "user.second_factor.totp.add"
        });
        controller.setProperties({
          onClose: function onClose() {
            return _this4.loadSecondFactors();
          },
          markDirty: function markDirty() {
            return _this4.markDirty();
          },
          onError: function onError(e) {
            return _this4.handleError(e);
          }
        });
      },
      createSecurityKey: function createSecurityKey() {
        var _this5 = this;

        var controller = (0, _showModal.default)("second-factor-add-security-key", {
          model: this.model,
          title: "user.second_factor.security_key.add"
        });
        controller.setProperties({
          onClose: function onClose() {
            return _this5.loadSecondFactors();
          },
          markDirty: function markDirty() {
            return _this5.markDirty();
          },
          onError: function onError(e) {
            return _this5.handleError(e);
          }
        });
      },
      editSecurityKey: function editSecurityKey(security_key) {
        var _this6 = this;

        var controller = (0, _showModal.default)("second-factor-edit-security-key", {
          model: security_key,
          title: "user.second_factor.security_key.edit"
        });
        controller.setProperties({
          user: this.model,
          onClose: function onClose() {
            return _this6.loadSecondFactors();
          },
          markDirty: function markDirty() {
            return _this6.markDirty();
          },
          onError: function onError(e) {
            return _this6.handleError(e);
          }
        });
      },
      editSecondFactor: function editSecondFactor(second_factor) {
        var _this7 = this;

        var controller = (0, _showModal.default)("second-factor-edit", {
          model: second_factor,
          title: "user.second_factor.edit_title"
        });
        controller.setProperties({
          user: this.model,
          onClose: function onClose() {
            return _this7.loadSecondFactors();
          },
          markDirty: function markDirty() {
            return _this7.markDirty();
          },
          onError: function onError(e) {
            return _this7.handleError(e);
          }
        });
      },
      editSecondFactorBackup: function editSecondFactorBackup() {
        var _this8 = this;

        var controller = (0, _showModal.default)("second-factor-backup-edit", {
          model: this.model,
          title: "user.second_factor_backup.title"
        });
        controller.setProperties({
          onClose: function onClose() {
            return _this8.loadSecondFactors();
          },
          markDirty: function markDirty() {
            return _this8.markDirty();
          },
          onError: function onError(e) {
            return _this8.handleError(e);
          }
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "displayOAuthWarning", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "displayOAuthWarning"), _obj), _applyDecoratedDescriptor(_obj, "showEnforcedNotice", [_dec], Object.getOwnPropertyDescriptor(_obj, "showEnforcedNotice"), _obj)), _obj)));

  _exports.default = _default;
});