define("discourse/widgets/do-not-disturb", ["exports", "I18n", "discourse/widgets/widget", "discourse/helpers/node", "virtual-dom", "discourse-common/lib/icon-library", "discourse/lib/show-modal"], function (_exports, _I18n, _widget, _node, _virtualDom, _iconLibrary, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _widget.createWidget)("do-not-disturb", {
    tagName: "li.do-not-disturb",
    saving: false,
    html: function html() {
      if (this.currentUser.isInDoNotDisturb()) {
        return [(0, _virtualDom.h)("button.btn-flat.do-not-disturb-inner-container", [(0, _iconLibrary.iconNode)("toggle-on"), (0, _virtualDom.h)("span.do-not-disturb-label", [(0, _virtualDom.h)("span", _I18n.default.t("do_not_disturb.label")), (0, _node.dateNode)(this.currentUser.do_not_disturb_until)])])];
      } else {
        return [(0, _virtualDom.h)("button.btn-flat.do-not-disturb-inner-container", [(0, _iconLibrary.iconNode)("toggle-off"), (0, _virtualDom.h)("span.do-not-disturb-label", _I18n.default.t("do_not_disturb.label"))])];
      }
    },
    click: function click() {
      var _this = this;

      if (this.saving) {
        return;
      }

      this.saving = true;

      if (this.currentUser.do_not_disturb_until) {
        return this.currentUser.leaveDoNotDisturb().then(function () {
          _this.saving = false;
        });
      } else {
        this.saving = false;
        return (0, _showModal.default)("do-not-disturb");
      }
    }
  });

  _exports.default = _default;
});