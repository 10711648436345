define("discourse/routes/user", ["exports", "discourse/routes/discourse", "I18n", "discourse/models/user"], function (_exports, _discourse, _I18n, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _discourse.default.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    titleToken: function titleToken() {
      var username = this.modelFor("user").username;

      if (username) {
        return [_I18n.default.t("user.profile"), username];
      }
    },
    undoRevokeApiKey: function undoRevokeApiKey(key) {
      key.undoRevoke();
    },
    revokeApiKey: function revokeApiKey(key) {
      key.revoke();
    },
    beforeModel: function beforeModel() {
      if (this.siteSettings.hide_user_profiles_from_public && !this.currentUser) {
        this.replaceWith("discovery");
      }
    },
    model: function model(params) {
      // If we're viewing the currently logged in user, return that object instead
      if (this.currentUser && params.username.toLowerCase() === this.currentUser.username_lower) {
        return this.currentUser;
      }

      return _user.default.create({
        username: encodeURIComponent(params.username)
      });
    },
    afterModel: function afterModel() {
      var _this = this;

      var user = this.modelFor("user");
      return user.findDetails().then(function () {
        return user.findStaffInfo();
      }).catch(function () {
        return _this.replaceWith("/404");
      });
    },
    serialize: function serialize(model) {
      if (!model) {
        return {};
      }

      return {
        username: (model.username || "").toLowerCase()
      };
    },
    setupController: function setupController(controller, user) {
      controller.set("model", user);
      this.searchService.set("searchContext", user.searchContext);
    },
    activate: function activate() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var user = this.modelFor("user");
      this.messageBus.subscribe("/u/".concat(user.username_lower), function (data) {
        return user.loadUserAction(data);
      });
      this.messageBus.subscribe("/u/".concat(user.username_lower, "/counters"), function (data) {
        user.setProperties(data);
        Object.entries(data).forEach(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              key = _ref2[0],
              value = _ref2[1];

          return _this2.appEvents.trigger("count-updated:".concat(user.username_lower, ":").concat(key), value);
        });
      });
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      var user = this.modelFor("user");
      this.messageBus.unsubscribe("/u/".concat(user.username_lower));
      this.messageBus.unsubscribe("/u/".concat(user.username_lower, "/counters")); // Remove the search context

      this.searchService.set("searchContext", null);
    }
  }, (_applyDecoratedDescriptor(_obj, "undoRevokeApiKey", [_dec], Object.getOwnPropertyDescriptor(_obj, "undoRevokeApiKey"), _obj), _applyDecoratedDescriptor(_obj, "revokeApiKey", [_dec2], Object.getOwnPropertyDescriptor(_obj, "revokeApiKey"), _obj)), _obj)));

  _exports.default = _default;
});