define("discourse/templates/modal/bulk-notification-level", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j61M+f2m",
    "block": "{\"symbols\":[\"level\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"bulk-notification-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"notificationLevels\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"radio notification-level-radio\"],[8],[0,\"\\n        \"],[1,[28,\"radio-button\",null,[[\"value\",\"name\",\"selection\"],[[23,1,[\"id\"]],\"notification_level\",[24,[\"notificationLevelId\"]]]]],false],[0,\" \"],[7,\"strong\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[1,[28,\"html-safe\",[[23,1,[\"description\"]]],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[1,[28,\"d-button\",null,[[\"disabled\",\"action\",\"label\"],[[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],\"changeNotificationLevel\"],null],\"topics.bulk.change_notification_level\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/bulk-notification-level.hbs"
    }
  });

  _exports.default = _default;
});