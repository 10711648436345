define("discourse/lib/user-search", ["exports", "discourse/lib/autocomplete", "discourse-common/lib/debounce", "discourse/lib/utilities", "discourse-common/config/environment", "discourse/lib/url"], function (_exports, _autocomplete, _debounce, _utilities, _environment, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.resetUserSearchCache = resetUserSearchCache;
  _exports.camelCaseToSnakeCase = camelCaseToSnakeCase;
  _exports.skipSearch = skipSearch;
  _exports.eagerCompleteSearch = eagerCompleteSearch;
  _exports.default = userSearch;
  var cache = {},
      cacheKey,
      cacheTime,
      currentTerm,
      oldSearch;

  function resetUserSearchCache() {
    cache = {};
    cacheKey = null;
    cacheTime = null;
    currentTerm = null;
    oldSearch = null;
  }

  function camelCaseToSnakeCase(text) {
    return text.replace(/([a-zA-Z])(?=[A-Z])/g, "$1_").toLowerCase();
  }

  function performSearch(term, topicId, categoryId, includeGroups, includeMentionableGroups, includeMessageableGroups, customUserSearchOptions, allowedUsers, groupMembersOf, includeStagedUsers, lastSeenUsers, limit, resultsFn) {
    var cached = cache[term];

    if (cached) {
      resultsFn(cached);
      return;
    }

    var eagerComplete = eagerCompleteSearch(term, topicId || categoryId);

    if (term === "" && !eagerComplete && !lastSeenUsers) {
      // The server returns no results in this case, so no point checking
      // do not return empty list, because autocomplete will get terminated
      resultsFn(_autocomplete.CANCELLED_STATUS);
      return;
    }

    var data = {
      term: term,
      topic_id: topicId,
      category_id: categoryId,
      include_groups: includeGroups,
      include_mentionable_groups: includeMentionableGroups,
      include_messageable_groups: includeMessageableGroups,
      groups: groupMembersOf,
      topic_allowed_users: allowedUsers,
      include_staged_users: includeStagedUsers,
      last_seen_users: lastSeenUsers,
      limit: limit
    };

    if (customUserSearchOptions) {
      Object.keys(customUserSearchOptions).forEach(function (key) {
        data[camelCaseToSnakeCase(key)] = customUserSearchOptions[key];
      });
    } // need to be able to cancel this


    oldSearch = $.ajax((0, _url.userPath)("search/users"), {
      data: data
    });
    var returnVal = _autocomplete.CANCELLED_STATUS;
    oldSearch.then(function (r) {
      var hasResults = !!(r.users && r.users.length || r.groups && r.groups.length || r.emails && r.emails.length);

      if (eagerComplete && !hasResults) {
        // we are trying to eager load, but received no results
        // do not return empty list, because autocomplete will get terminated
        r = _autocomplete.CANCELLED_STATUS;
      }

      cache[term] = r;
      cacheTime = new Date(); // If there is a newer search term, return null

      if (term === currentTerm) {
        returnVal = r;
      }
    }).always(function () {
      oldSearch = null;
      resultsFn(returnVal);
    });
  }

  var debouncedSearch = function debouncedSearch(term, topicId, categoryId, includeGroups, includeMentionableGroups, includeMessageableGroups, customUserSearchOptions, allowedUsers, groupMembersOf, includeStagedUsers, lastSeenUsers, limit, resultsFn) {
    (0, _debounce.default)(this, performSearch, term, topicId, categoryId, includeGroups, includeMentionableGroups, includeMessageableGroups, customUserSearchOptions, allowedUsers, groupMembersOf, includeStagedUsers, lastSeenUsers, limit, resultsFn, 300);
  };

  function organizeResults(r, options) {
    if (r === _autocomplete.CANCELLED_STATUS) {
      return r;
    }

    var exclude = options.exclude || [],
        limit = options.limit || 5,
        users = [],
        emails = [],
        groups = [],
        results = [];

    if (r.users) {
      r.users.every(function (u) {
        if (exclude.indexOf(u.username) === -1) {
          users.push(u);
          results.push(u);
        }

        return results.length <= limit;
      });
    }

    if (options.allowEmails && (0, _utilities.emailValid)(options.term)) {
      var e = {
        username: options.term
      };
      emails = [e];
      results.push(e);
    }

    if (r.groups) {
      r.groups.every(function (g) {
        if (options.term.toLowerCase() === g.name.toLowerCase() || results.length < limit) {
          if (exclude.indexOf(g.name) === -1) {
            groups.push(g);
            results.push(g);
          }
        }

        return true;
      });
    }

    results.users = users;
    results.emails = emails;
    results.groups = groups;
    return results;
  } // all punctuation except for -, _ and . which are allowed in usernames
  // note: these are valid in names, but will end up tripping search anyway so just skip
  // this means searching for `sam saffron` is OK but if my name is `sam$ saffron` autocomplete
  // will not find me, which is a reasonable compromise
  //
  // we also ignore if we notice a double space or a string that is only a space


  var ignoreRegex = /([\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*,\/:;<=>?\[\]^`{|}~])|\s\s|^\s$|^[^+]*\+[^@]*$/;

  function skipSearch(term, allowEmails) {
    var lastSeenUsers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    if (lastSeenUsers) {
      return false;
    }

    if (term.indexOf("@") > -1 && !allowEmails) {
      return true;
    }

    return !!term.match(ignoreRegex);
  }

  function eagerCompleteSearch(term, scopedId) {
    return term === "" && !!scopedId;
  }

  function userSearch(options) {
    if (options.term && options.term.length > 0 && options.term[0] === "@") {
      options.term = options.term.substring(1);
    }

    var term = options.term || "",
        includeGroups = options.includeGroups,
        includeMentionableGroups = options.includeMentionableGroups,
        includeMessageableGroups = options.includeMessageableGroups,
        customUserSearchOptions = options.customUserSearchOptions,
        allowedUsers = options.allowedUsers,
        topicId = options.topicId,
        categoryId = options.categoryId,
        groupMembersOf = options.groupMembersOf,
        includeStagedUsers = options.includeStagedUsers,
        lastSeenUsers = options.lastSeenUsers,
        limit = options.limit || 6;

    if (oldSearch) {
      oldSearch.abort();
      oldSearch = null;
    }

    currentTerm = term;
    return new Ember.RSVP.Promise(function (resolve) {
      var newCacheKey = "".concat(topicId, "-").concat(categoryId);

      if (new Date() - cacheTime > 30000 || cacheKey !== newCacheKey) {
        cache = {};
      }

      cacheKey = newCacheKey;
      var clearPromise;

      if (!(0, _environment.isTesting)()) {
        clearPromise = Ember.run.later(function () {
          return resolve(_autocomplete.CANCELLED_STATUS);
        }, 5000);
      }

      if (skipSearch(term, options.allowEmails, options.lastSeenUsers)) {
        resolve([]);
        return;
      }

      debouncedSearch(term, topicId, categoryId, includeGroups, includeMentionableGroups, includeMessageableGroups, customUserSearchOptions, allowedUsers, groupMembersOf, includeStagedUsers, lastSeenUsers, limit, function (r) {
        Ember.run.cancel(clearPromise);
        resolve(organizeResults(r, options));
      });
    });
  }
});