define("discourse/templates/components/reviewable-conversation-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HXXzXEz7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"post\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"reviewable-conversation-post\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showUsername\"]]],null,{\"statements\":[[0,\"      \"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"username\",\"user\",[24,[\"post\",\"user\"]]]],{\"statements\":[[0,\"@\"],[1,[24,[\"post\",\"user\",\"username\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[28,\"html-safe\",[[24,[\"post\",\"excerpt\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-conversation-post.hbs"
    }
  });

  _exports.default = _default;
});