define("discourse/templates/account-created/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2okA3nKR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ac-message\"],[8],[0,\"\\n  \"],[1,[28,\"html-safe\",[[24,[\"accountCreated\",\"message\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"accountCreated\",\"show_controls\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"activation-controls\",null,[[\"sendActivationEmail\",\"editActivationEmail\"],[[28,\"action\",[[23,0,[]],\"sendActivationEmail\"],null],[28,\"action\",[[23,0,[]],\"editActivationEmail\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/account-created/index.hbs"
    }
  });

  _exports.default = _default;
});