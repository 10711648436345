define("discourse/templates/components/topic-navigation-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NZgVPUHD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[24,[\"hidden\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"topic-navigation-popup\"],[8],[0,\"\\n    \"],[1,[28,\"d-button\",null,[[\"action\",\"class\",\"icon\"],[[28,\"action\",[[23,0,[]],\"close\"],null],\"close btn-flat\",\"times\"]]],false],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/topic-navigation-popup.hbs"
    }
  });

  _exports.default = _default;
});