define("discourse/templates/login-preferences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MkfZK1Sm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[8],[1,[28,\"i18n\",[\"login.to_continue\"],null],false],[9],[0,\"\\n\\n  \"],[7,\"p\",true],[10,\"style\",\"margin-top: 1em\"],[8],[1,[28,\"i18n\",[\"login.preferences\"],null],false],[9],[0,\"\\n\\n  \"],[1,[28,\"d-button\",null,[[\"class\",\"action\",\"label\"],[\"btn-primary\",[28,\"route-action\",[\"showLogin\"],null],\"log_in\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/login-preferences.hbs"
    }
  });

  _exports.default = _default;
});