define("discourse/components/mount-widget", ["exports", "virtual-dom", "discourse/widgets/widget", "discourse/lib/dirty-keys", "discourse/widgets/hooks", "discourse-common/lib/get-owner"], function (_exports, _virtualDom, _widget, _dirtyKeys, _hooks, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addWidgetCleanCallback = addWidgetCleanCallback;
  _exports.resetWidgetCleanCallbacks = resetWidgetCleanCallbacks;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _cleanCallbacks = {};

  function addWidgetCleanCallback(widgetName, fn) {
    _cleanCallbacks[widgetName] = _cleanCallbacks[widgetName] || [];

    _cleanCallbacks[widgetName].push(fn);
  }

  function resetWidgetCleanCallbacks() {
    _cleanCallbacks = {};
  }

  var _default = Ember.Component.extend({
    _tree: null,
    _rootNode: null,
    _timeout: null,
    _widgetClass: null,
    _renderCallback: null,
    _childEvents: null,
    _dispatched: null,
    dirtyKeys: null,
    init: function init() {
      this._super.apply(this, arguments);

      var name = this.widget;
      this.register = (0, _getOwner.getRegister)(this);
      this._widgetClass = (0, _widget.queryRegistry)(name) || this.register.lookupFactory("widget:".concat(name));

      if (!this._widgetClass) {
        // eslint-disable-next-line no-console
        console.error("Error: Could not find widget: ".concat(name));
      }

      this._childEvents = [];
      this._connected = [];
      this._dispatched = [];
      this.dirtyKeys = new _dirtyKeys.default(name);
    },
    didInsertElement: function didInsertElement() {
      _hooks.WidgetClickHook.setupDocumentCallback();

      this._rootNode = document.createElement("div");
      this.element.appendChild(this._rootNode);
      this._timeout = Ember.run.scheduleOnce("render", this, this.rerenderWidget);
    },
    willClearRender: function willClearRender() {
      var _this = this;

      var callbacks = _cleanCallbacks[this.widget];

      if (callbacks) {
        callbacks.forEach(function (cb) {
          return cb(_this._tree);
        });
      }

      this._connected.forEach(function (v) {
        return v.destroy();
      });

      this._connected.length = 0;
      this._rootNode = (0, _virtualDom.patch)(this._rootNode, (0, _virtualDom.diff)(this._tree, null));
      this._tree = null;
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      this._dispatched.forEach(function (evt) {
        var _evt = _slicedToArray(evt, 2),
            eventName = _evt[0],
            caller = _evt[1];

        _this2.appEvents.off(eventName, _this2, caller);
      });

      Ember.run.cancel(this._timeout);
    },
    afterRender: function afterRender() {},
    beforePatch: function beforePatch() {},
    afterPatch: function afterPatch() {},
    eventDispatched: function eventDispatched(eventName, key, refreshArg) {
      key = typeof key === "function" ? key(refreshArg) : key;
      var onRefresh = Ember.String.camelize(eventName.replace(/:/, "-"));
      this.dirtyKeys.keyDirty(key, {
        onRefresh: onRefresh,
        refreshArg: refreshArg
      });
      this.queueRerender();
    },
    dispatch: function dispatch(eventName, key) {
      var _this3 = this;

      this._childEvents.push(eventName);

      var caller = function caller(refreshArg) {
        return _this3.eventDispatched(eventName, key, refreshArg);
      };

      this._dispatched.push([eventName, caller]);

      this.appEvents.on(eventName, this, caller);
    },
    queueRerender: function queueRerender(callback) {
      if (callback && !this._renderCallback) {
        this._renderCallback = callback;
      }

      Ember.run.scheduleOnce("render", this, this.rerenderWidget);
    },
    buildArgs: function buildArgs() {},
    rerenderWidget: function rerenderWidget() {
      Ember.run.cancel(this._timeout);

      if (this._rootNode) {
        if (!this._widgetClass) {
          return;
        }

        var t0 = Date.now();
        var args = this.args || this.buildArgs();
        var opts = {
          model: this.model,
          dirtyKeys: this.dirtyKeys
        };
        var newTree = new this._widgetClass(args, this.register, opts);
        newTree._rerenderable = this;
        newTree._emberView = this;
        var patches = (0, _virtualDom.diff)(this._tree || this._rootNode, newTree);
        (0, _widget.traverseCustomWidgets)(this._tree, function (w) {
          return w.willRerenderWidget();
        });
        this.beforePatch();
        this._rootNode = (0, _virtualDom.patch)(this._rootNode, patches);
        this.afterPatch();
        this._tree = newTree;
        (0, _widget.traverseCustomWidgets)(newTree, function (w) {
          return w.didRenderWidget();
        });

        if (this._renderCallback) {
          this._renderCallback();

          this._renderCallback = null;
        }

        this.afterRender();
        this.dirtyKeys.renderedKey("*");

        if (this.profileWidget) {
          // eslint-disable-next-line no-console
          console.log(Date.now() - t0);
        }
      }
    }
  });

  _exports.default = _default;
});