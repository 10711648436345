define("discourse/components/topic-footer-buttons", ["exports", "discourse-common/utils/decorators", "discourse/lib/register-topic-footer-button", "discourse/lib/register-topic-footer-dropdown"], function (_exports, _decorators, _registerTopicFooterButton, _registerTopicFooterDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj, _init, _init2, _init3;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("topic.isPrivateMessage"), _dec2 = (0, _decorators.default)("inlineButtons.[]", "topic.assigned_to_user"), _dec3 = (0, _decorators.default)("topic.isPrivateMessage"), _dec4 = (0, _decorators.default)("topic.message_archived"), _dec5 = (0, _decorators.default)("topic.message_archived"), _dec6 = (0, _decorators.default)("topic.message_archived"), (_obj = {
    elementId: "topic-footer-buttons",
    attributeBindings: ["role"],
    role: "region",
    // Allow us to extend it
    layoutName: "components/topic-footer-buttons",
    canArchive: function canArchive(isPM) {
      return this.siteSettings.enable_personal_messages && isPM;
    },
    inlineButtons: (0, _registerTopicFooterButton.getTopicFooterButtons)(),
    inlineDropdowns: (0, _registerTopicFooterDropdown.getTopicFooterDropdowns)(),
    inlineActionables: Ember.computed("inlineButtons.[]", "inlineDropdowns.[]", function () {
      return this.inlineButtons.filterBy("dropdown", false).concat(this.inlineDropdowns).sortBy("priority").reverse();
    }),
    dropdownButtons: function dropdownButtons(inlineButtons) {
      return inlineButtons.filter(function (button) {
        return button.dropdown;
      });
    },
    showNotificationsButton: function showNotificationsButton(isPM) {
      return !isPM || this.siteSettings.enable_personal_messages;
    },
    canInviteTo: Ember.computed.alias("topic.details.can_invite_to"),
    canDefer: Ember.computed.alias("currentUser.enable_defer"),
    inviteDisabled: Ember.computed.or("topic.archived", "topic.closed", "topic.deleted"),
    showEditOnFooter: Ember.computed.and("topic.isPrivateMessage", "site.can_tag_pms"),
    archiveIcon: function archiveIcon(archived) {
      return archived ? "envelope" : "folder";
    },
    archiveTitle: function archiveTitle(archived) {
      return archived ? "topic.move_to_inbox.help" : "topic.archive_message.help";
    },
    archiveLabel: function archiveLabel(archived) {
      return archived ? "topic.move_to_inbox.title" : "topic.archive_message.title";
    }
  }, (_applyDecoratedDescriptor(_obj, "canArchive", [_dec], Object.getOwnPropertyDescriptor(_obj, "canArchive"), _obj), _applyDecoratedDescriptor(_obj, "dropdownButtons", [_dec2], Object.getOwnPropertyDescriptor(_obj, "dropdownButtons"), _obj), _applyDecoratedDescriptor(_obj, "showNotificationsButton", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showNotificationsButton"), _obj), _applyDecoratedDescriptor(_obj, "archiveIcon", [_dec4], (_init = Object.getOwnPropertyDescriptor(_obj, "archiveIcon"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "archiveTitle", [_dec5], (_init2 = Object.getOwnPropertyDescriptor(_obj, "archiveTitle"), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "archiveLabel", [_dec6], (_init3 = Object.getOwnPropertyDescriptor(_obj, "archiveLabel"), _init3 = _init3 ? _init3.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init3;
    }
  }), _obj)), _obj)));

  _exports.default = _default;
});