define("discourse/adapters/post", ["exports", "discourse/adapters/rest", "discourse/lib/ajax"], function (_exports, _rest, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    find: function find(store, type, findArgs) {
      return this._super(store, type, findArgs).then(function (result) {
        return {
          post: result
        };
      });
    },
    createRecord: function createRecord(store, type, args) {
      var typeField = Ember.String.underscore(type);
      args.nested_post = true;
      return (0, _ajax.ajax)(this.pathFor(store, type), {
        type: "POST",
        data: args
      }).then(function (json) {
        return new _rest.Result(json[typeField], json);
      });
    }
  });

  _exports.default = _default;
});