define("discourse/routes/build-group-messages-route", ["exports", "I18n", "discourse/routes/user-topic-list"], function (_exports, _I18n, _userTopicList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(type) {
    return _userTopicList.default.extend({
      titleToken: function titleToken() {
        return _I18n.default.t("user.messages.".concat(type));
      },
      model: function model() {
        var _this = this;

        var groupName = this.modelFor("group").get("name");
        var username = this.currentUser.get("username_lower");
        var filter = "topics/private-messages-group/".concat(username, "/").concat(groupName);

        if (this._isArchive()) {
          filter = "".concat(filter, "/archive");
        }

        return this.store.findFiltered("topicList", {
          filter: filter
        }).then(function (model) {
          // andrei: we agreed that this is an anti pattern,
          // it's better to avoid mutating a rest model like this
          // this place we'll be refactored later
          // see https://github.com/discourse/discourse/pull/14313#discussion_r708784704
          model.set("emptyState", _this.emptyState());
          return model;
        });
      },
      setupController: function setupController() {
        this._super.apply(this, arguments);

        var groupName = this.modelFor("group").get("name");
        var channel = "/private-messages/group/".concat(groupName);

        if (this._isArchive()) {
          channel = "".concat(channel, "/archive");
        }

        this.controllerFor("user-topics-list").subscribe(channel);
        this.controllerFor("user-topics-list").setProperties({
          hideCategory: true,
          showPosters: true
        });
        var currentUser = this.currentUser;
        this.searchService.set("searchContext", {
          type: "private_messages",
          id: currentUser.get("username_lower"),
          user: currentUser
        });
      },
      emptyState: function emptyState() {
        return {
          title: _I18n.default.t("no_group_messages_title"),
          body: ""
        };
      },
      _isArchive: function _isArchive() {
        return type === "archive";
      },
      deactivate: function deactivate() {
        this.searchService.set("searchContext", null);
      }
    });
  };

  _exports.default = _default;
});