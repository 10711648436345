define("discourse/components/reviewable-field-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onChange: function onChange(tags) {
        this.set("value", tags);
        this.valueChanged && this.valueChanged({
          target: {
            value: tags
          }
        });
      }
    }
  });

  _exports.default = _default;
});