define("discourse/controllers/discard-draft", ["exports", "discourse/mixins/modal-functionality"], function (_exports, _modalFunctionality) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    actions: {
      destroyDraft: function destroyDraft() {
        this.onDestroyDraft();
        this.send("closeModal");
      },
      saveDraftAndClose: function saveDraftAndClose() {
        this.onSaveDraft();
        this.send("closeModal");
      },
      dismissModal: function dismissModal() {
        this.onDismissModal();
        this.send("closeModal");
      }
    }
  });

  _exports.default = _default;
});