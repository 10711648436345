define("discourse/models/live-post-counts", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LivePostCounts = Ember.Object.extend({});
  LivePostCounts.reopenClass({
    find: function find() {
      return (0, _ajax.ajax)("/about/live_post_counts.json").then(function (result) {
        return LivePostCounts.create(result);
      });
    }
  });
  var _default = LivePostCounts;
  _exports.default = _default;
});