define("discourse/widgets/private-message-map", ["exports", "discourse/widgets/post", "I18n", "bootbox", "discourse/widgets/widget", "discourse-common/lib/get-url", "virtual-dom", "discourse-common/lib/helpers"], function (_exports, _post, _I18n, _bootbox, _widget, _getUrl, _virtualDom, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  (0, _widget.createWidget)("pm-remove-group-link", {
    tagName: "a.remove-invited.no-text.btn-icon.btn",
    template: function template(attrs, state) {
      var __h1 = __widget_helpers.iconNode;
      var _r = [];

      _r.push(__h1("times"));

      return _r;
    },
    click: function click() {
      var _this = this;

      _bootbox.default.confirm(_I18n.default.t("private_message_info.remove_allowed_group", {
        name: this.attrs.name
      }), function (confirmed) {
        if (confirmed) {
          _this.sendWidgetAction("removeAllowedGroup", _this.attrs);
        }
      });
    }
  });
  (0, _widget.createWidget)("pm-map-user-group", {
    tagName: "div.user.group",
    transform: function transform(attrs) {
      return {
        href: (0, _getUrl.default)("/g/".concat(attrs.group.name))
      };
    },
    template: function template(attrs, state) {
      var __h1 = __widget_helpers.iconNode;
      var _r = [];

      _r.push("\n    ");

      var _a0 = [];

      _a0.push("\n      ");

      _a0.push(__h1("users"));

      _a0.push("\n      ");

      var _a1 = [];

      _a1.push(attrs.group.name);

      _a0.push(virtualDom.h('span', {
        "className": "group-name",
        "attributes": {}
      }, _a1));

      _a0.push("\n    ");

      _r.push(virtualDom.h('a', {
        "className": "group-link",
        "attributes": {
          "href": this.transformed.href
        }
      }, _a0));

      _r.push("\n");

      if (attrs.isEditing) {
        if (attrs.canRemoveAllowedUsers) {
          _r.push("        ");

          _r.push(this.attach("pm-remove-group-link", attrs.group));

          _r.push("\n");
        }
      }

      _r.push("  ");

      return _r;
    }
  });
  (0, _widget.createWidget)("pm-remove-link", {
    tagName: "a.remove-invited.no-text.btn-icon.btn",
    template: function template(attrs, state) {
      var __h1 = __widget_helpers.iconNode;
      var _r = [];

      _r.push(__h1("times"));

      return _r;
    },
    click: function click() {
      var _this2 = this;

      var messageKey = this.attrs.isCurrentUser ? "leave_message" : "remove_allowed_user";

      _bootbox.default.confirm(_I18n.default.t("private_message_info.".concat(messageKey), {
        name: this.attrs.user.username
      }), function (confirmed) {
        if (confirmed) {
          _this2.sendWidgetAction("removeAllowedUser", _this2.attrs.user);
        }
      });
    }
  });
  (0, _widget.createWidget)("pm-map-user", {
    tagName: "div.user",
    html: function html(attrs) {
      var user = attrs.user;
      var username = (0, _virtualDom.h)("span.username", user.username);
      var link;

      if (this.site && this.site.mobileView) {
        var avatar = (0, _post.avatarImg)("tiny", {
          template: user.avatar_template,
          username: user.username
        });
        link = (0, _virtualDom.h)("a", {
          attributes: {
            href: user.get("path")
          }
        }, [avatar, username]);
      } else {
        var _avatar = (0, _post.avatarFor)("tiny", {
          template: user.avatar_template,
          username: user.username
        });

        link = (0, _virtualDom.h)("a", {
          attributes: {
            class: "user-link",
            href: user.get("path")
          }
        }, [_avatar, username]);
      }

      var result = [link];
      var isCurrentUser = attrs.canRemoveSelfId === user.get("id");

      if (attrs.isEditing && (attrs.canRemoveAllowedUsers || isCurrentUser)) {
        result.push(this.attach("pm-remove-link", {
          user: user,
          isCurrentUser: isCurrentUser
        }));
      }

      return result;
    }
  });

  var _default = (0, _widget.createWidget)("private-message-map", {
    tagName: "section.information.private-message-map",
    buildKey: function buildKey(attrs) {
      return "private-message-map-".concat(attrs.id);
    },
    defaultState: function defaultState() {
      return {
        isEditing: false
      };
    },
    html: function html(attrs) {
      var _this3 = this;

      var participants = [];

      if (attrs.allowedGroups.length) {
        participants.push(attrs.allowedGroups.map(function (group) {
          return _this3.attach("pm-map-user-group", {
            group: group,
            canRemoveAllowedUsers: attrs.canRemoveAllowedUsers,
            isEditing: _this3.state.isEditing
          });
        }));
      }

      if (attrs.allowedUsers.length) {
        participants.push(attrs.allowedUsers.map(function (au) {
          return _this3.attach("pm-map-user", {
            user: au,
            canRemoveAllowedUsers: attrs.canRemoveAllowedUsers,
            canRemoveSelfId: attrs.canRemoveSelfId,
            isEditing: _this3.state.isEditing
          });
        }));
      }

      var hideNamesClass = "";

      if (!this.state.isEditing && this.site.mobileView && (0, _helpers.makeArray)(participants[0]).length > 4) {
        hideNamesClass = ".hide-names";
      }

      var result = [(0, _virtualDom.h)("div.participants".concat(hideNamesClass), participants)];
      var controls = [];
      var canRemove = attrs.canRemoveAllowedUsers || attrs.canRemoveSelfId;

      if (attrs.canInvite || canRemove) {
        var key;
        var action = "toggleEditing";

        if (attrs.canInvite && canRemove) {
          key = "edit";
        } else if (!attrs.canInvite && canRemove) {
          key = "remove";
        } else {
          key = "add";
          action = "showInvite";
        }

        controls.push(this.attach("button", {
          action: action,
          label: "private_message_info.".concat(key),
          className: "btn btn-default add-remove-participant-btn"
        }));
      }

      if (attrs.canInvite && this.state.isEditing) {
        controls.push(this.attach("button", {
          action: "showInvite",
          icon: "plus",
          className: "btn btn-default no-text btn-icon"
        }));
      }

      if (controls.length) {
        result.push((0, _virtualDom.h)("div.controls", controls));
      }

      return result;
    },
    toggleEditing: function toggleEditing() {
      this.state.isEditing = !this.state.isEditing;
    }
  });

  _exports.default = _default;
});