define("discourse/models/topic-timer", ["exports", "discourse/models/rest", "discourse/lib/ajax"], function (_exports, _rest, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var TopicTimer = _rest.default.extend({});

  TopicTimer.reopenClass({
    update: function update(topicId, time, basedOnLastPost, statusType, categoryId, durationMinutes) {
      var data = {
        time: time,
        status_type: statusType
      };

      if (basedOnLastPost) {
        data.based_on_last_post = basedOnLastPost;
      }

      if (categoryId) {
        data.category_id = categoryId;
      }

      if (durationMinutes) {
        data.duration_minutes = durationMinutes;
      }

      return (0, _ajax.ajax)({
        url: "/t/".concat(topicId, "/timer"),
        type: "POST",
        data: data
      });
    }
  });
  var _default = TopicTimer;
  _exports.default = _default;
});