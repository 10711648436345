define("discourse/templates/components/choose-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LKBS3OEt",
    "block": "{\"symbols\":[\"m\"],\"statements\":[[7,\"label\",true],[10,\"for\",\"choose-message-title\"],[8],[1,[28,\"i18n\",[\"choose_message.title.search\"],null],false],[9],[0,\"\\n\\n\"],[1,[28,\"text-field\",null,[[\"value\",\"placeholderKey\",\"id\"],[[24,[\"messageTitle\"]],\"choose_message.title.placeholder\",\"choose-message-title\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"loading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[\"loading\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"noResults\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[\"choose_message.none_found\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"messages\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"controls existing-message\"],[8],[0,\"\\n        \"],[7,\"label\",true],[10,\"class\",\"radio\"],[8],[0,\"\\n          \"],[7,\"input\",false],[12,\"id\",[29,[\"choose-message-\",[23,1,[\"id\"]]]]],[12,\"name\",\"choose_message_id\"],[12,\"type\",\"radio\"],[3,\"action\",[[23,0,[]],\"chooseMessage\",[23,1,[]]]],[8],[9],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"message-title\"],[8],[0,\"\\n            \"],[1,[23,1,[\"title\"]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/choose-message.hbs"
    }
  });

  _exports.default = _default;
});