define("discourse/services/document-title", ["exports", "discourse-common/lib/get-url", "discourse/lib/update-tab-count"], function (_exports, _getUrl, _updateTabCount) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    appEvents: Ember.inject.service(),
    contextCount: null,
    notificationCount: null,
    _title: null,
    _backgroundNotify: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.reset();
    },
    reset: function reset() {
      this.contextCount = 0;
      this.notificationCount = 0;
      this._title = null;
      this._backgroundNotify = null;
    },
    getTitle: function getTitle() {
      return this._title;
    },
    setTitle: function setTitle(title) {
      this._title = title;

      this._renderTitle();
    },
    setFocus: function setFocus(focus) {
      var session = this.session;
      session.hasFocus = focus;

      if (session.hasFocus && this._backgroundNotify) {
        this.updateContextCount(0);
      }

      this._backgroundNotify = false;

      if (session.hasFocus) {
        this.notificationCount = 0;
      }

      this.appEvents.trigger("discourse:focus-changed", session.hasFocus);

      this._renderFavicon();

      this._renderTitle();
    },
    updateContextCount: function updateContextCount(count) {
      this.contextCount = count;

      this._renderTitle();
    },
    updateNotificationCount: function updateNotificationCount(count) {
      if (!this.session.hasFocus) {
        this.notificationCount = count;

        this._renderFavicon();

        this._renderTitle();
      }
    },
    incrementBackgroundContextCount: function incrementBackgroundContextCount() {
      if (!this.session.hasFocus) {
        this._backgroundNotify = true;
        this.contextCount += 1;

        this._renderFavicon();

        this._renderTitle();
      }
    },
    _displayCount: function _displayCount() {
      return this.currentUser && this.currentUser.title_count_mode === "notifications" ? this.notificationCount : this.contextCount;
    },
    _renderTitle: function _renderTitle() {
      var title = this._title || this.siteSettings.title;

      var displayCount = this._displayCount();

      var dynamicFavicon = this.currentUser && this.currentUser.dynamic_favicon;

      if (this.currentUser && this.currentUser.isInDoNotDisturb()) {
        document.title = title;
        return;
      }

      if (displayCount > 0 && !dynamicFavicon) {
        title = "(".concat(displayCount, ") ").concat(title);
      }

      document.title = title;
    },
    _renderFavicon: function _renderFavicon() {
      if (this.currentUser && this.currentUser.dynamic_favicon) {
        var url = this.siteSettings.site_favicon_url; // Since the favicon is cached on the browser for a really long time, we
        // append the favicon_url as query params to the path so that the cache
        // is not used when the favicon changes.

        if (/^http/.test(url)) {
          url = (0, _getUrl.default)("/favicon/proxied?" + encodeURIComponent(url));
        }

        (0, _updateTabCount.default)(url, this._displayCount());
      }
    }
  });

  _exports.default = _default;
});