define("discourse/templates/components/badge-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bJamzgsH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"badge-title\"],[8],[0,\"\\n  \"],[7,\"section\",true],[10,\"class\",\"user-content\"],[8],[0,\"\\n    \"],[7,\"form\",true],[10,\"class\",\"form-horizontal\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"control-group\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[0,\"\\n          \"],[7,\"h3\",true],[8],[1,[28,\"i18n\",[\"badges.select_badge_for_title\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"control-group\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[0,\"\\n          \"],[1,[28,\"combo-box\",null,[[\"value\",\"nameProperty\",\"content\",\"onChange\"],[[24,[\"_selectedUserBadgeId\"]],\"badge.name\",[24,[\"selectableUserBadges\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"_selectedUserBadgeId\"]]],null]],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"control-group\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[0,\"\\n          \"],[1,[28,\"d-button\",null,[[\"class\",\"action\",\"disabled\",\"label\"],[\"btn-primary\",[28,\"action\",[[23,0,[]],\"saveBadgeTitle\"],null],[24,[\"_isSaving\"]],[28,\"if\",[[24,[\"_isSaving\"]],\"saving\",\"save\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"_isSaved\"]]],null,{\"statements\":[[0,\"            \"],[7,\"span\",true],[8],[1,[28,\"i18n\",[\"saved\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/badge-title.hbs"
    }
  });

  _exports.default = _default;
});