define("discourse/models/user-action-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    push: function push(item) {
      if (!this.items) {
        this.items = [];
      }

      return this.items.push(item);
    }
  });

  _exports.default = _default;
});