define("discourse/templates/modal/reject-reason-reviewable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ECuWT/n+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"class\"],[\"explain-reviewable\"]],{\"statements\":[[0,\"  \"],[1,[28,\"textarea\",null,[[\"value\"],[[24,[\"rejectReason\"]]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"control-group\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"type\",\"class\",\"checked\"],[\"checkbox\",\"inline\",[24,[\"sendEmail\"]]]]],false],[0,\"\\n      \"],[1,[28,\"i18n\",[\"review.reject_reason.send_email\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"icon\",\"class\",\"action\",\"label\"],[\"trash-alt\",\"btn-danger\",[28,\"action\",[[23,0,[]],\"perform\"],null],\"admin.user.delete\"]]],false],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"label\",\"class\"],[[28,\"route-action\",[\"closeModal\"],null],\"cancel\",\"cancel\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/reject-reason-reviewable.hbs"
    }
  });

  _exports.default = _default;
});