define("discourse/routes/discourse", ["exports", "discourse/models/composer", "discourse/models/draft", "discourse/lib/user-presence"], function (_exports, _composer, _draft, _userPresence) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DiscourseRoute = Ember.Route.extend({
    showFooter: false,
    willTransition: function willTransition() {
      (0, _userPresence.seenUser)();
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      if (this.showFooter) {
        this.controllerFor("application").set("showFooter", true);
      }
    },
    _refreshTitleOnce: function _refreshTitleOnce() {
      this.send("_collectTitleTokens", []);
    },
    actions: {
      _collectTitleTokens: function _collectTitleTokens(tokens) {
        // If there's a title token method, call it and get the token
        if (this.titleToken) {
          var t = this.titleToken();

          if (t && t.length) {
            if (t instanceof Array) {
              t.forEach(function (ti) {
                tokens.push(ti);
              });
            } else {
              tokens.push(t);
            }
          }
        }

        return true;
      },
      refreshTitle: function refreshTitle() {
        Ember.run.once(this, this._refreshTitleOnce);
      }
    },
    redirectIfLoginRequired: function redirectIfLoginRequired() {
      var app = this.controllerFor("application");

      if (app.get("loginRequired")) {
        this.replaceWith("login");
      }
    },
    openTopicDraft: function openTopicDraft() {
      var composer = this.controllerFor("composer");

      if (composer.get("model.action") === _composer.default.CREATE_TOPIC && composer.get("model.draftKey") === _composer.default.NEW_TOPIC_KEY) {
        composer.set("model.composeState", _composer.default.OPEN);
      } else {
        _draft.default.get(_composer.default.NEW_TOPIC_KEY).then(function (data) {
          if (data.draft) {
            composer.open({
              action: _composer.default.CREATE_TOPIC,
              draft: data.draft,
              draftKey: _composer.default.NEW_TOPIC_KEY,
              draftSequence: data.draft_sequence
            });
          }
        });
      }
    },
    isAnotherUsersPage: function isAnotherUsersPage(user) {
      if (!this.currentUser) {
        return true;
      }

      return user.username !== this.currentUser.username;
    },
    isPoppedState: function isPoppedState(transition) {
      return !transition._discourse_intercepted && !!transition.intent.url;
    }
  });
  var _default = DiscourseRoute;
  _exports.default = _default;
});