define("discourse/widgets/hamburger-categories", ["exports", "discourse/models/category", "I18n", "discourse/widgets/widget", "discourse-common/lib/get-url", "virtual-dom", "discourse/lib/formatter"], function (_exports, _category, _I18n, _widget, _getUrl, _virtualDom, _formatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  (0, _widget.createWidget)("hamburger-category", {
    tagName: "li.category-link",
    html: function html(c) {
      if (c.parent_category_id) {
        this.tagName += ".subcategory";
      }

      this.tagName += ".category-" + _category.default.slugFor(c, "-");
      var results = [this.attach("category-link", {
        category: c,
        allowUncategorized: true
      })];
      var unreadTotal = parseInt(c.get("unreadTopics"), 10) + parseInt(c.get("newTopics"), 10);

      if (unreadTotal) {
        results.push((0, _virtualDom.h)("a.badge.badge-notification", {
          attributes: {
            href: c.get("url")
          }
        }, (0, _formatter.number)(unreadTotal)));
      }

      if (!this.currentUser) {
        var count;

        if (c.get("show_subcategory_list")) {
          count = c.get("totalTopicCount");
        } else {
          count = c.get("topic_count");
        }

        results.push((0, _virtualDom.h)("b.topics-count", (0, _formatter.number)(count)));
      }

      return results;
    }
  });

  var _default = (0, _widget.createWidget)("hamburger-categories", {
    tagName: "ul.category-links.clearfix",
    html: function html(attrs) {
      var _this = this;

      var href = (0, _getUrl.default)("/categories");

      var title = _I18n.default.t("filters.categories.title");

      if (attrs.moreCount > 0) {
        title = _I18n.default.t("categories.n_more", {
          count: attrs.moreCount
        });
      }

      var result = [(0, _virtualDom.h)("li.heading", (0, _virtualDom.h)("a.d-link.categories-link", {
        attributes: {
          href: href
        }
      }, title))];
      var categories = attrs.categories;

      if (categories.length === 0) {
        return;
      }

      result = result.concat(categories.map(function (c) {
        return _this.attach("hamburger-category", c);
      }));
      return result;
    }
  });

  _exports.default = _default;
});