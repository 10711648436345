define("discourse/initializers/copy-codeblocks", ["exports", "I18n", "discourse/lib/utilities", "discourse-common/lib/icon-library", "discourse/lib/plugin-api"], function (_exports, _I18n, _utilities, _iconLibrary, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _copyCodeblocksClickHandlers = {};
  var _fadeCopyCodeblocksRunners = {};
  var _default = {
    name: "copy-codeblocks",
    initialize: function initialize(container) {
      var siteSettings = container.lookup("site-settings:main");
      (0, _pluginApi.withPluginApi)("0.8.7", function (api) {
        function _cleanUp() {
          Object.values(_copyCodeblocksClickHandlers || {}).forEach(function (handler) {
            return handler.removeEventListener("click", _handleClick);
          });
          Object.values(_fadeCopyCodeblocksRunners || {}).forEach(function (runner) {
            return Ember.run.cancel(runner);
          });
          _copyCodeblocksClickHandlers = {};
          _fadeCopyCodeblocksRunners = {};
        }

        function _copyComplete(button) {
          button.classList.add("copied");
          var state = button.innerHTML;
          button.innerHTML = _I18n.default.t("copy_codeblock.copied");
          var commandId = Ember.guidFor(button);

          if (_fadeCopyCodeblocksRunners[commandId]) {
            Ember.run.cancel(_fadeCopyCodeblocksRunners[commandId]);
            delete _fadeCopyCodeblocksRunners[commandId];
          }

          _fadeCopyCodeblocksRunners[commandId] = Ember.run.later(function () {
            button.classList.remove("copied");
            button.innerHTML = state;
            delete _fadeCopyCodeblocksRunners[commandId];
          }, 3000);
        }

        function _handleClick(event) {
          if (!event.target.classList.contains("copy-cmd")) {
            return;
          }

          var button = event.target;
          var code = button.nextSibling;

          if (code) {
            // replace any weird whitespace characters with a proper '\u20' whitespace
            var text = code.innerText.replace(/[\f\v\u00a0\u1680\u2000-\u200a\u202f\u205f\u3000\ufeff]/g, " ").trim();
            var result = (0, _utilities.clipboardCopy)(text);

            if (result.then) {
              result.then(function () {
                _copyComplete(button);
              });
            } else if (result) {
              _copyComplete(button);
            }
          }
        }

        function _attachCommands(postElements, helper) {
          if (!helper) {
            return;
          }

          if (!siteSettings.show_copy_button_on_codeblocks) {
            return;
          }

          var commands = [];

          try {
            commands = postElements[0].querySelectorAll(":scope > pre > code, :scope :not(article):not(blockquote) > pre > code");
          } catch (e) {
            // :scope is probably not supported by this browser
            commands = [];
          }

          var post = helper.getModel();

          if (!commands.length || !post) {
            return;
          }

          var postElement = postElements[0];
          commands.forEach(function (command) {
            var button = document.createElement("button");
            button.classList.add("btn", "nohighlight", "copy-cmd");
            button.innerHTML = (0, _iconLibrary.iconHTML)("copy");
            command.before(button);
            command.parentElement.classList.add("copy-codeblocks");
          });

          if (_copyCodeblocksClickHandlers[post.id]) {
            _copyCodeblocksClickHandlers[post.id].removeEventListener("click", _handleClick);

            delete _copyCodeblocksClickHandlers[post.id];
          }

          _copyCodeblocksClickHandlers[post.id] = postElement;
          postElement.addEventListener("click", _handleClick, false);
        }

        api.decorateCooked(_attachCommands, {
          onlyStream: true,
          id: "copy-codeblocks"
        });
        api.cleanupStream(_cleanUp);
      });
    }
  };
  _exports.default = _default;
});