define("discourse/components/tag-info", ["exports", "I18n", "discourse/lib/ajax", "bootbox", "discourse-common/utils/decorators", "discourse/lib/ajax-error"], function (_exports, _I18n, _ajax, _bootbox, _decorators, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("tagInfo.tag_group_names"), _dec2 = (0, _decorators.default)("tagInfo.categories"), _dec3 = (0, _decorators.default)("tagInfo.tag_group_names", "tagInfo.categories", "tagInfo.synonyms"), _dec4 = (0, _decorators.default)("newTagName"), (_obj = {
    tagName: "",
    loading: false,
    tagInfo: null,
    newSynonyms: null,
    showEditControls: false,
    canAdminTag: Ember.computed.reads("currentUser.staff"),
    editSynonymsMode: Ember.computed.and("canAdminTag", "showEditControls"),
    editing: false,
    newTagName: null,
    newTagDescription: null,
    router: Ember.inject.service(),
    tagGroupsInfo: function tagGroupsInfo(tagGroupNames) {
      return _I18n.default.t("tagging.tag_groups_info", {
        count: tagGroupNames.length,
        tag_groups: tagGroupNames.join(", ")
      });
    },
    categoriesInfo: function categoriesInfo(categories) {
      return _I18n.default.t("tagging.category_restrictions", {
        count: categories.length
      });
    },
    nothingToShow: function nothingToShow(tagGroupNames, categories, synonyms) {
      return Ember.isEmpty(tagGroupNames) && Ember.isEmpty(categories) && Ember.isEmpty(synonyms);
    },
    updateDisabled: function updateDisabled(newTagName) {
      var filterRegexp = new RegExp(this.site.tags_filter_regexp, "g");
      newTagName = newTagName ? newTagName.replace(filterRegexp, "").trim() : "";
      return newTagName.length === 0;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.loadTagInfo();
    },
    loadTagInfo: function loadTagInfo() {
      var _this = this;

      if (this.loading) {
        return;
      }

      this.set("loading", true);
      return this.store.find("tag-info", this.tag.id).then(function (result) {
        _this.set("tagInfo", result);

        _this.set("tagInfo.synonyms", result.synonyms.map(function (s) {
          return _this.store.createRecord("tag", s);
        }));
      }).finally(function () {
        return _this.set("loading", false);
      }).catch(_ajaxError.popupAjaxError);
    },
    actions: {
      toggleEditControls: function toggleEditControls() {
        this.toggleProperty("showEditControls");
      },
      edit: function edit() {
        this.setProperties({
          editing: true,
          newTagName: this.tag.id,
          newTagDescription: this.tagInfo.description
        });
      },
      cancelEditing: function cancelEditing() {
        this.set("editing", false);
      },
      finishedEditing: function finishedEditing() {
        var _this2 = this;

        var oldTagName = this.tag.id;
        this.tag.update({
          id: this.newTagName,
          description: this.newTagDescription
        }).then(function (result) {
          _this2.set("editing", false);

          _this2.tagInfo.set("description", _this2.newTagDescription);

          if (result.responseJson.tag && oldTagName !== result.responseJson.tag.id) {
            _this2.router.transitionTo("tag.show", result.responseJson.tag.id);
          }
        }).catch(_ajaxError.popupAjaxError);
      },
      deleteTag: function deleteTag() {
        this.deleteAction(this.tagInfo);
      },
      unlinkSynonym: function unlinkSynonym(tag) {
        var _this3 = this;

        (0, _ajax.ajax)("/tag/".concat(this.tagInfo.name, "/synonyms/").concat(tag.id), {
          type: "DELETE"
        }).then(function () {
          return _this3.tagInfo.synonyms.removeObject(tag);
        }).catch(_ajaxError.popupAjaxError);
      },
      deleteSynonym: function deleteSynonym(tag) {
        var _this4 = this;

        _bootbox.default.confirm(_I18n.default.t("tagging.delete_synonym_confirm", {
          tag_name: tag.text
        }), function (result) {
          if (!result) {
            return;
          }

          tag.destroyRecord().then(function () {
            return _this4.tagInfo.synonyms.removeObject(tag);
          }).catch(_ajaxError.popupAjaxError);
        });
      },
      addSynonyms: function addSynonyms() {
        var _this5 = this;

        _bootbox.default.confirm(_I18n.default.t("tagging.add_synonyms_explanation", {
          count: this.newSynonyms.length,
          tag_name: this.tagInfo.name
        }), function (result) {
          if (!result) {
            return;
          }

          (0, _ajax.ajax)("/tag/".concat(_this5.tagInfo.name, "/synonyms"), {
            type: "POST",
            data: {
              synonyms: _this5.newSynonyms
            }
          }).then(function (response) {
            if (response.success) {
              _this5.set("newSynonyms", null);

              _this5.loadTagInfo();
            } else if (response.failed_tags) {
              _bootbox.default.alert(_I18n.default.t("tagging.add_synonyms_failed", {
                tag_names: Object.keys(response.failed_tags).join(", ")
              }));
            } else {
              _bootbox.default.alert(_I18n.default.t("generic_error"));
            }
          }).catch(_ajaxError.popupAjaxError);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "tagGroupsInfo", [_dec], Object.getOwnPropertyDescriptor(_obj, "tagGroupsInfo"), _obj), _applyDecoratedDescriptor(_obj, "categoriesInfo", [_dec2], Object.getOwnPropertyDescriptor(_obj, "categoriesInfo"), _obj), _applyDecoratedDescriptor(_obj, "nothingToShow", [_dec3], Object.getOwnPropertyDescriptor(_obj, "nothingToShow"), _obj), _applyDecoratedDescriptor(_obj, "updateDisabled", [_dec4], Object.getOwnPropertyDescriptor(_obj, "updateDisabled"), _obj)), _obj)));

  _exports.default = _default;
});