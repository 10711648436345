define("discourse/templates/components/create-topic-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GwLYpD7Z",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"canCreateTopic\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"d-button\",null,[[\"class\",\"id\",\"action\",\"icon\",\"disabled\",\"label\"],[[24,[\"btnClass\"]],\"create-topic\",[24,[\"action\"]],\"plus\",[24,[\"disabled\"]],[24,[\"label\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/create-topic-button.hbs"
    }
  });

  _exports.default = _default;
});