define("discourse/mixins/cleans-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Include this mixin if you want to be notified when the dom should be
  // cleaned (usually on route change.)
  var _default = Ember.Mixin.create({
    _initializeChooser: Ember.on("didInsertElement", function () {
      this.appEvents.on("dom:clean", this, "cleanUp");
    }),
    _clearChooser: Ember.on("willDestroyElement", function () {
      this.appEvents.off("dom:clean", this, "cleanUp");
    })
  });

  _exports.default = _default;
});