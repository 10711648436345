define("discourse/lib/highlight-search", ["exports", "discourse/lib/constants", "discourse/lib/highlight-html"], function (_exports, _constants, _highlightHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _exports.CLASS_NAME = void 0;
  var CLASS_NAME = "search-highlight";
  _exports.CLASS_NAME = CLASS_NAME;

  function _default(elem, term) {
    var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    if (!Ember.isEmpty(term)) {
      // special case ignore "l" which is used for magic sorting
      var words = term.match(new RegExp("".concat(_constants.SEARCH_PHRASE_REGEXP, "|[^\\s]+"), "g")).filter(function (t) {
        return t !== "l";
      }).map(function (w) {
        return w.replace(/^"(.*)"$/, "$1");
      });
      var highlightOpts = {};

      if (!opts.defaultClassName) {
        highlightOpts.className = CLASS_NAME;
      }

      (0, _highlightHtml.default)(elem, words, highlightOpts);
    }
  }
});