define("discourse/initializers/d-popover", ["exports", "discourse/lib/d-popover"], function (_exports, _dPopover) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "d-popover",
    initialize: function initialize(container) {
      var router = container.lookup("router:main");
      router.on("routeWillChange", _dPopover.hidePopover);
      $("#main").on("click.d-popover mouseenter.d-popover", _dPopover.POPOVER_SELECTORS, function (e) {
        return (0, _dPopover.showPopover)(e);
      }).on("mouseleave.d-popover", _dPopover.POPOVER_SELECTORS, function (e) {
        return (0, _dPopover.hidePopover)(e);
      });
    }
  };
  _exports.default = _default;
});