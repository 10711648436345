define("discourse/controllers/download-calendar", ["exports", "discourse/mixins/modal-functionality", "discourse/lib/download-calendar"], function (_exports, _modalFunctionality, _downloadCalendar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, (_dec = Ember._action, (_obj = {
    selectedCalendar: "ics",
    remember: false,
    downloadCalendar: function downloadCalendar() {
      if (this.remember) {
        this.currentUser.setProperties({
          default_calendar: this.selectedCalendar,
          user_option: {
            default_calendar: this.selectedCalendar
          }
        });
        this.currentUser.save(["default_calendar"]);
      }

      if (this.selectedCalendar === "ics") {
        (0, _downloadCalendar.downloadIcs)(this.model.title, this.model.dates);
      } else {
        (0, _downloadCalendar.downloadGoogle)(this.model.title, this.model.dates);
      }

      this.send("closeModal");
    }
  }, (_applyDecoratedDescriptor(_obj, "downloadCalendar", [_dec], Object.getOwnPropertyDescriptor(_obj, "downloadCalendar"), _obj)), _obj)));

  _exports.default = _default;
});