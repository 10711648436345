define("discourse/helpers/topic-link", ["discourse-common/lib/helpers"], function (_helpers) {
  "use strict";

  (0, _helpers.registerUnbound)("topic-link", function (topic, args) {
    var title = topic.get("fancyTitle");
    var url = topic.linked_post_number ? topic.urlForPostNumber(topic.linked_post_number) : topic.get("lastUnreadUrl");
    var classes = ["title"];

    if (args.class) {
      args.class.split(" ").forEach(function (c) {
        return classes.push(c);
      });
    }

    return Ember.String.htmlSafe("<a href='".concat(url, "'\n        role='heading'\n        aria-level='2'\n        class='").concat(classes.join(" "), "'\n        data-topic-id='").concat(topic.id, "'>").concat(title, "</a>"));
  });
});