define("discourse/components/avatar-uploader", ["exports", "discourse/mixins/uppy-upload", "discourse-common/utils/decorators"], function (_exports, _uppyUpload, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_uppyUpload.default, (_dec = (0, _decorators.default)("user_id"), (_obj = {
    type: "avatar",
    tagName: "span",
    imageIsNotASquare: false,
    validateUploadedFilesOptions: function validateUploadedFilesOptions() {
      return {
        imagesOnly: true
      };
    },
    uploadDone: function uploadDone(upload) {
      this.setProperties({
        imageIsNotASquare: upload.width !== upload.height,
        uploadedAvatarTemplate: upload.url,
        uploadedAvatarId: upload.id
      });
      this.done();
    },
    data: function data(user_id) {
      return {
        user_id: user_id
      };
    }
  }, (_applyDecoratedDescriptor(_obj, "data", [_dec], Object.getOwnPropertyDescriptor(_obj, "data"), _obj)), _obj)));

  _exports.default = _default;
});