define("discourse/controllers/user-private-messages-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sortProperties: ["count:desc", "id"],
    tagsForUser: null,
    sortedByCount: true,
    sortedByName: false,
    actions: {
      sortByCount: function sortByCount() {
        this.setProperties({
          sortProperties: ["count:desc", "id"],
          sortedByCount: true,
          sortedByName: false
        });
      },
      sortById: function sortById() {
        this.setProperties({
          sortProperties: ["id"],
          sortedByCount: false,
          sortedByName: true
        });
      }
    }
  });

  _exports.default = _default;
});