define("discourse/controllers/second-factor-add-totp", ["exports", "I18n", "discourse/mixins/modal-functionality"], function (_exports, _I18n, _modalFunctionality) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    loading: false,
    secondFactorImage: null,
    secondFactorKey: null,
    showSecondFactorKey: false,
    errorMessage: null,
    onShow: function onShow() {
      var _this = this;

      this.setProperties({
        errorMessage: null,
        secondFactorKey: null,
        secondFactorName: null,
        secondFactorToken: null,
        showSecondFactorKey: false,
        secondFactorImage: null,
        loading: true
      });
      this.model.createSecondFactorTotp().then(function (response) {
        if (response.error) {
          _this.set("errorMessage", response.error);

          return;
        }

        _this.setProperties({
          errorMessage: null,
          secondFactorKey: response.key,
          secondFactorImage: response.qr
        });
      }).catch(function (error) {
        _this.send("closeModal");

        _this.onError(error);
      }).finally(function () {
        return _this.set("loading", false);
      });
    },
    actions: {
      showSecondFactorKey: function showSecondFactorKey() {
        this.set("showSecondFactorKey", true);
      },
      enableSecondFactor: function enableSecondFactor() {
        var _this2 = this;

        if (!this.secondFactorToken || !this.secondFactorName) {
          this.set("errorMessage", _I18n.default.t("user.second_factor.totp.name_and_code_required_error"));
          return;
        }

        this.set("loading", true);
        this.model.enableSecondFactorTotp(this.secondFactorToken, this.secondFactorName).then(function (response) {
          if (response.error) {
            _this2.set("errorMessage", response.error);

            return;
          }

          _this2.markDirty();

          _this2.set("errorMessage", null);

          _this2.send("closeModal");
        }).catch(function (error) {
          return _this2.onError(error);
        }).finally(function () {
          return _this2.set("loading", false);
        });
      }
    }
  });

  _exports.default = _default;
});