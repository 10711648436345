define("discourse/templates/components/reviewable-field-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rkPrEh2d",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"mini-tag-chooser\",null,[[\"value\",\"onChange\"],[[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-field-tags.hbs"
    }
  });

  _exports.default = _default;
});