define("discourse/templates/components/reviewable-post-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h70YHwb0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"reviewable-post-header\"],[8],[0,\"\\n  \"],[1,[28,\"reviewable-created-by-name\",null,[[\"user\",\"tagName\"],[[24,[\"createdBy\"]],\"\"]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"reviewable\",\"reply_to_post_number\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[11,\"href\",[28,\"concat\",[[24,[\"reviewable\",\"topic_url\"]],\"/\",[24,[\"reviewable\",\"reply_to_post_number\"]]],null]],[10,\"class\",\"reviewable-reply-to\"],[8],[0,\"\\n      \"],[1,[28,\"d-icon\",[\"share\"],null],false],[0,\"\\n      \"],[7,\"span\",true],[8],[1,[28,\"i18n\",[\"review.in_reply_to\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-post-header.hbs"
    }
  });

  _exports.default = _default;
});