define("discourse/templates/components/color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ygwRKtTV",
    "block": "{\"symbols\":[\"c\"],\"statements\":[[4,\"each\",[[24,[\"colors\"]]],null,{\"statements\":[[4,\"color-picker-choice\",null,[[\"color\",\"usedColors\",\"selectColor\"],[[23,1,[]],[24,[\"usedColors\"]],[28,\"action\",[[23,0,[]],\"selectColor\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"d-icon\",[\"check\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/color-picker.hbs"
    }
  });

  _exports.default = _default;
});