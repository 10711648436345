define("discourse/components/user-notification-schedule-day", ["exports", "discourse/lib/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    dayLabel: (0, _computed.i18n)("day", "user.notification_schedule.%@")
  });

  _exports.default = _default;
});