define("discourse/templates/modal/second-factor-add-security-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cz6Y/Zmc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,null,{\"statements\":[[4,\"conditional-loading-spinner\",null,[[\"condition\"],[[24,[\"loading\"]]]],{\"statements\":[[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"control-group\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"alert alert-error\"],[8],[1,[22,\"errorMessage\"],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"control-group\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[0,\"\\n        \"],[1,[28,\"html-safe\",[[28,\"i18n\",[\"user.second_factor.enable_security_key_description\"],null]],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"control-group\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"value\",\"id\",\"placeholder\"],[[24,[\"securityKeyName\"]],\"security-key-name\",\"security key name\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"control-group\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"webauthnUnsupported\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"d-button\",null,[[\"class\",\"action\",\"label\"],[\"btn-primary add-security-key\",[28,\"action\",[[23,0,[]],\"registerSecurityKey\"],null],\"user.second_factor.security_key.register\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/second-factor-add-security-key.hbs"
    }
  });

  _exports.default = _default;
});