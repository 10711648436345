define("discourse/templates/components/group-manage-save-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7YNHFMDt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"control-group buttons group-manage-save-button\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"disabled\",\"class\",\"translatedLabel\"],[[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"or\",[[24,[\"disabled\"]],[24,[\"saving\"]]],null],\"btn btn-primary group-manage-save\",[24,[\"savingText\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"saved\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[1,[28,\"i18n\",[\"saved\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/group-manage-save-button.hbs"
    }
  });

  _exports.default = _default;
});