define("discourse/components/topic-status", ["exports", "I18n", "discourse-common/utils/decorators", "discourse-common/lib/icon-library"], function (_exports, _I18n, _decorators, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("disableActions"), _dec2 = (0, _decorators.default)("topic.closed", "topic.archived"), _dec3 = (0, _decorators.default)("topic.is_warning"), _dec4 = (0, _decorators.default)("showPrivateMessageIcon", "topic.isPrivateMessage", "topic.is_warning"), _dec5 = (0, _decorators.default)("topic.pinned"), _dec6 = (0, _decorators.default)("topic.unpinned"), _dec7 = (0, _decorators.default)("topic.invisible"), (_obj = {
    disableActions: false,
    classNames: ["topic-statuses"],
    click: function click(e) {
      // only pin unpin for now
      if (this.canAct && $(e.target).hasClass("d-icon-thumbtack")) {
        var topic = this.topic;
        topic.get("pinned") ? topic.clearPin() : topic.rePin();
        return false;
      }
    },
    canAct: function canAct(disableActions) {
      return this.currentUser && !disableActions;
    },
    topicClosedArchived: function topicClosedArchived(closed, archived) {
      if (closed && archived) {
        this._set("closedArchived", "lock", "locked_and_archived");

        this._reset("closed");

        this._reset("archived");

        return true;
      } else {
        this._reset("closedArchived");

        closed ? this._set("closed", "lock", "locked") : this._reset("closed");
        archived ? this._set("archived", "lock", "archived") : this._reset("archived");
        return false;
      }
    },
    topicWarning: function topicWarning(warning) {
      return warning ? this._set("warning", "envelope", "warning") : this._reset("warning");
    },
    topicPrivateMessage: function topicPrivateMessage(showPrivateMessageIcon, privateMessage, warning) {
      return showPrivateMessageIcon && privateMessage && !warning ? this._set("privateMessage", "envelope", "personal_message") : this._reset("privateMessage");
    },
    topicPinned: function topicPinned(pinned) {
      return pinned ? this._set("pinned", "thumbtack", "pinned") : this._reset("pinned");
    },
    topicUnpinned: function topicUnpinned(unpinned) {
      return unpinned ? this._set("unpinned", "thumbtack", "unpinned", {
        class: "unpinned"
      }) : this._reset("unpinned");
    },
    topicInvisible: function topicInvisible(invisible) {
      return invisible ? this._set("invisible", "far-eye-slash", "unlisted") : this._reset("invisible");
    },
    _set: function _set(name, icon, key) {
      var iconArgs = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      this.set("".concat(name, "Icon"), (0, _iconLibrary.iconHTML)("".concat(icon), iconArgs).htmlSafe());
      this.set("".concat(name, "Title"), _I18n.default.t("topic_statuses.".concat(key, ".help")));
      return true;
    },
    _reset: function _reset(name) {
      this.set("".concat(name, "Icon"), null);
      this.set("".concat(name, "Title"), null);
      return false;
    }
  }, (_applyDecoratedDescriptor(_obj, "canAct", [_dec], Object.getOwnPropertyDescriptor(_obj, "canAct"), _obj), _applyDecoratedDescriptor(_obj, "topicClosedArchived", [_dec2], Object.getOwnPropertyDescriptor(_obj, "topicClosedArchived"), _obj), _applyDecoratedDescriptor(_obj, "topicWarning", [_dec3], Object.getOwnPropertyDescriptor(_obj, "topicWarning"), _obj), _applyDecoratedDescriptor(_obj, "topicPrivateMessage", [_dec4], Object.getOwnPropertyDescriptor(_obj, "topicPrivateMessage"), _obj), _applyDecoratedDescriptor(_obj, "topicPinned", [_dec5], Object.getOwnPropertyDescriptor(_obj, "topicPinned"), _obj), _applyDecoratedDescriptor(_obj, "topicUnpinned", [_dec6], Object.getOwnPropertyDescriptor(_obj, "topicUnpinned"), _obj), _applyDecoratedDescriptor(_obj, "topicInvisible", [_dec7], Object.getOwnPropertyDescriptor(_obj, "topicInvisible"), _obj)), _obj)));

  _exports.default = _default;
});