define("discourse/initializers/edit-notification-clicks-tracker", ["exports", "discourse/models/post-stream"], function (_exports, _postStream) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "edit-notification-clicks-tracker",
    initialize: function initialize(container) {
      var appEvents = container.lookup("service:app-events");
      appEvents.on("edit-notification:clicked", this.handleClick);
    },
    handleClick: function handleClick(_ref) {
      var topicId = _ref.topicId,
          postNumber = _ref.postNumber,
          revisionNumber = _ref.revisionNumber;
      (0, _postStream.setLastEditNotificationClick)(topicId, postNumber, revisionNumber);
    }
  };
  _exports.default = _default;
});