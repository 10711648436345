define("discourse/helpers/category-badge", ["discourse/helpers/category-link", "discourse-common/lib/helpers"], function (_categoryLink, _helpers) {
  "use strict";

  (0, _helpers.registerUnbound)("category-badge", function (cat, options) {
    return (0, _categoryLink.categoryLinkHTML)(cat, {
      hideParent: options.hideParent,
      allowUncategorized: options.allowUncategorized,
      categoryStyle: options.categoryStyle,
      link: false
    });
  });
});