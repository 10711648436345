define("discourse/components/mobile-nav", ["exports", "discourse-common/utils/decorators", "discourse-common/lib/deprecated"], function (_exports, _decorators, _deprecated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.on)("init"), (_obj = {
    _init: function _init() {
      if (!this.get("site.mobileView")) {
        var classes = this.desktopClass;

        if (classes) {
          classes = classes.split(" ");
          this.set("classNames", classes);
        }
      }

      if (this.currentPath) {
        (0, _deprecated.default)("{{mobile-nav}} no longer requires the currentPath property", {
          since: "2.7.0.beta4",
          dropFrom: "2.9.0.beta1"
        });
      }
    },
    tagName: "ul",
    selectedHtml: null,
    classNames: ["mobile-nav"],
    router: Ember.inject.service(),
    currentRouteChanged: function currentRouteChanged() {
      var _this = this;

      this.set("expanded", false);
      Ember.run.next(function () {
        return _this._updateSelectedHtml();
      });
    },
    _updateSelectedHtml: function _updateSelectedHtml() {
      if (!this.element || this.isDestroying || this.isDestroyed) {
        return;
      }

      var active = this.element.querySelector(".active");

      if (active && active.innerHTML) {
        this.set("selectedHtml", active.innerHTML);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._updateSelectedHtml();

      this.router.on("routeDidChange", this, this.currentRouteChanged);
    },
    willDestroyElement: function willDestroyElement() {
      this.router.off("routeDidChange", this, this.currentRouteChanged);
    },
    actions: {
      toggleExpanded: function toggleExpanded() {
        var _this2 = this;

        this.toggleProperty("expanded");
        Ember.run.next(function () {
          if (_this2.expanded) {
            $(window).off("click.mobile-nav").on("click.mobile-nav", function (e) {
              if (!_this2.element || _this2.isDestroying || _this2.isDestroyed) {
                return;
              }

              var expander = _this2.element.querySelector(".expander");

              if (expander && e.target !== expander) {
                _this2.set("expanded", false);

                $(window).off("click.mobile-nav");
              }
            });
          }
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "_init", [_dec], Object.getOwnPropertyDescriptor(_obj, "_init"), _obj)), _obj)));

  _exports.default = _default;
});