define("discourse/templates/components/generated-invite-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qG+G0lbX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[1,[28,\"i18n\",[\"user.invited.link_generated\"],null],false],[9],[0,\"\\n\"],[7,\"p\",true],[8],[0,\"\\n  \"],[7,\"input\",true],[11,\"value\",[22,\"link\"]],[10,\"class\",\"invite-link-input\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"email\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[\"user.invited.valid_for\"],[[\"email\"],[[24,[\"email\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/generated-invite-link.hbs"
    }
  });

  _exports.default = _default;
});