define("discourse/templates/modal/feature-topic-on-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7biJg5/L",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"class\",\"id\"],[\"feature-topic-on-profile\",\"choosing-topic\"]],{\"statements\":[[0,\"  \"],[1,[28,\"choose-topic\",null,[[\"currentTopicId\",\"selectedTopicId\",\"additionalFilters\",\"label\",\"topicChangedCallback\",\"loadOnInit\"],[[24,[\"model\",\"featured_topic\",\"id\"]],[24,[\"newFeaturedTopicId\"]],\"status:public\",\"user.feature_topic_on_profile.search_label\",[28,\"action\",[[23,0,[]],\"newTopicSelected\"],null],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"class\",\"disabled\",\"label\"],[[28,\"action\",[[23,0,[]],\"save\"],null],\"btn-primary save-featured-topic-on-profile\",[24,[\"noTopicSelected\"]],\"user.feature_topic_on_profile.save\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/feature-topic-on-profile.hbs"
    }
  });

  _exports.default = _default;
});