define("discourse/initializers/clean-dom-on-route-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _clean(transition) {
    var _$$magnificPopup;

    if (window.MiniProfiler && transition.from) {
      window.MiniProfiler.pageTransition();
    } // Close some elements that may be open


    document.querySelectorAll("header ul.icons li").forEach(function (element) {
      element.classList.remove("active");
    });
    document.querySelectorAll("[data-toggle=\"dropdown\"]").forEach(function (element) {
      element.parentElement.classList.remove("open");
    }); // Close the lightbox

    if ((_$$magnificPopup = $.magnificPopup) !== null && _$$magnificPopup !== void 0 && _$$magnificPopup.instance) {
      $.magnificPopup.instance.close();
      document.body.classList.remove("mfp-zoom-out-cur");
    } // Remove any link focus


    var _document = document,
        activeElement = _document.activeElement;

    if (activeElement && !activeElement.classList.contains("no-blur")) {
      activeElement.blur();
    }

    this.lookup("route:application").send("closeModal");
    this.lookup("service:app-events").trigger("dom:clean");
    this.lookup("service:document-title").updateContextCount(0);
  }

  var _default = {
    name: "clean-dom-on-route-change",
    after: "inject-objects",
    initialize: function initialize(container) {
      var router = container.lookup("router:main");
      router.on("routeDidChange", function (transition) {
        Ember.run.scheduleOnce("afterRender", container, _clean, transition);
      });
    }
  };
  _exports.default = _default;
});