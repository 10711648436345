define("discourse/routes/user-private-messages-warnings", ["exports", "discourse/routes/build-private-messages-route"], function (_exports, _buildPrivateMessagesRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.VIEW_NAME_WARNINGS = void 0;
  var VIEW_NAME_WARNINGS = "warnings";
  _exports.VIEW_NAME_WARNINGS = VIEW_NAME_WARNINGS;

  var _default = (0, _buildPrivateMessagesRoute.default)(VIEW_NAME_WARNINGS, "private-messages-warnings", null
  /* no message bus notifications */
  );

  _exports.default = _default;
});