define("discourse/mixins/load-more", ["exports", "discourse/lib/eyeline", "discourse/mixins/scrolling", "discourse-common/utils/decorators"], function (_exports, _eyeline, _scrolling, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // Provides the ability to load more items for a view which is scrolled to the bottom.
  var _default = Ember.Mixin.create(_scrolling.default, (_dec = (0, _decorators.on)("didInsertElement"), _dec2 = (0, _decorators.on)("willDestroyElement"), (_obj = {
    scrolled: function scrolled() {
      var _this$eyeline;

      return (_this$eyeline = this.eyeline) === null || _this$eyeline === void 0 ? void 0 : _this$eyeline.update();
    },
    loadMoreUnlessFull: function loadMoreUnlessFull() {
      if (this.screenNotFull()) {
        this.send("loadMore");
      }
    },
    _bindEyeline: function _bindEyeline() {
      var _this = this;

      var eyeline = _eyeline.default.create({
        selector: "".concat(this.eyelineSelector, ":last")
      });

      this.set("eyeline", eyeline);
      eyeline.on("sawBottom", function () {
        return _this.send("loadMore");
      });
      eyeline.update(); // update once to consider current position

      this.bindScrolling();
    },
    _removeEyeline: function _removeEyeline() {
      this.unbindScrolling();
    }
  }, (_applyDecoratedDescriptor(_obj, "_bindEyeline", [_dec], Object.getOwnPropertyDescriptor(_obj, "_bindEyeline"), _obj), _applyDecoratedDescriptor(_obj, "_removeEyeline", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_removeEyeline"), _obj)), _obj)));

  _exports.default = _default;
});