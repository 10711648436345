define("discourse/routes/discovery", ["exports", "discourse/routes/discourse", "discourse/mixins/open-composer", "discourse/models/user", "discourse/mixins/scroll-top", "discourse/lib/topic-list-tracker", "discourse/models/site"], function (_exports, _discourse, _openComposer, _user, _scrollTop, _topicListTracker, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _discourse.default.extend(_openComposer.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_obj = {
    queryParams: {
      filter: {
        refreshModel: true
      }
    },
    redirect: function redirect() {
      return this.redirectIfLoginRequired();
    },
    beforeModel: function beforeModel(transition) {
      var url = transition.intent.url;
      var matches;

      if ((url === "/" || url === "/latest" || url === "/categories") && transition.targetName.indexOf("discovery.top") === -1 && _user.default.currentProp("should_be_redirected_to_top")) {
        _user.default.currentProp("should_be_redirected_to_top", false);

        var period = _user.default.currentProp("redirected_to_top.period") || "all";
        this.replaceWith("discovery.top", {
          queryParams: {
            period: period
          }
        });
      } else if (url && (matches = url.match(/top\/(.*)$/))) {
        if (_site.default.currentProp("periods").includes(matches[1])) {
          this.replaceWith("discovery.top", {
            queryParams: {
              period: matches[1]
            }
          });
        }
      }
    },
    loading: function loading() {
      this.controllerFor("discovery").loadingBegan(); // We don't want loading to bubble

      return true;
    },
    loadingComplete: function loadingComplete() {
      this.controllerFor("discovery").loadingComplete();

      if (!this.session.get("topicListScrollPosition")) {
        (0, _scrollTop.scrollTop)();
      }
    },
    didTransition: function didTransition() {
      this.send("loadingComplete");
      var model = this.controllerFor("discovery/topics").get("model");
      (0, _topicListTracker.setTopicList)(model);
    },
    clearPin: function clearPin(topic) {
      topic.clearPin();
    },
    createTopic: function createTopic() {
      if (this.get("currentUser.has_topic_draft")) {
        this.openTopicDraft();
      } else {
        this.openComposer(this.controllerFor("discovery/topics"));
      }
    },
    dismissReadTopics: function dismissReadTopics(dismissTopics) {
      var operationType = dismissTopics ? "topics" : "posts";
      this.send("dismissRead", operationType);
    },
    dismissRead: function dismissRead(operationType) {
      var controller = this.controllerFor("discovery/topics");
      controller.send("dismissRead", operationType, {
        categoryId: controller.get("category.id"),
        includeSubcategories: !controller.noSubcategories
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "loading", [_dec], Object.getOwnPropertyDescriptor(_obj, "loading"), _obj), _applyDecoratedDescriptor(_obj, "loadingComplete", [_dec2], Object.getOwnPropertyDescriptor(_obj, "loadingComplete"), _obj), _applyDecoratedDescriptor(_obj, "didTransition", [_dec3], Object.getOwnPropertyDescriptor(_obj, "didTransition"), _obj), _applyDecoratedDescriptor(_obj, "clearPin", [_dec4], Object.getOwnPropertyDescriptor(_obj, "clearPin"), _obj), _applyDecoratedDescriptor(_obj, "createTopic", [_dec5], Object.getOwnPropertyDescriptor(_obj, "createTopic"), _obj), _applyDecoratedDescriptor(_obj, "dismissReadTopics", [_dec6], Object.getOwnPropertyDescriptor(_obj, "dismissReadTopics"), _obj), _applyDecoratedDescriptor(_obj, "dismissRead", [_dec7], Object.getOwnPropertyDescriptor(_obj, "dismissRead"), _obj)), _obj)));

  _exports.default = _default;
});