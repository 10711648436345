define("discourse/controllers/user-notifications", ["exports", "discourse-common/utils/decorators", "discourse/lib/ajax", "discourse-common/lib/icon-library", "discourse-common/lib/get-url", "I18n"], function (_exports, _decorators, _ajax, _iconLibrary, _getUrl, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.observes)("model.canLoadMore"), _dec2 = (0, _decorators.default)("filter"), _dec3 = (0, _decorators.default)("model.content.@each.read"), _dec4 = (0, _decorators.default)("isFiltered", "model.content.length"), _dec5 = (0, _decorators.default)("isFiltered", "model.content.length"), _dec6 = (0, _decorators.default)(), (_obj = {
    application: Ember.inject.controller(),
    queryParams: ["filter"],
    filter: "all",
    _showFooter: function _showFooter() {
      this.set("application.showFooter", !this.get("model.canLoadMore"));
    },
    isFiltered: function isFiltered() {
      return this.filter && this.filter !== "all";
    },
    allNotificationsRead: function allNotificationsRead() {
      return !this.get("model.content").some(function (notification) {
        return !notification.get("read");
      });
    },
    doesNotHaveNotifications: function doesNotHaveNotifications(isFiltered, contentLength) {
      return !isFiltered && contentLength === 0;
    },
    nothingFound: function nothingFound(isFiltered, contentLength) {
      return isFiltered && contentLength === 0;
    },
    emptyStateBody: function emptyStateBody() {
      return _I18n.default.t("user.no_notifications_page_body", {
        preferencesUrl: (0, _getUrl.default)("/my/preferences/notifications"),
        icon: (0, _iconLibrary.iconHTML)("bell")
      }).htmlSafe();
    },
    actions: {
      resetNew: function resetNew() {
        var _this = this;

        (0, _ajax.ajax)("/notifications/mark-read", {
          type: "PUT"
        }).then(function () {
          _this.model.forEach(function (n) {
            return n.set("read", true);
          });
        });
      },
      loadMore: function loadMore() {
        this.model.loadMore();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "_showFooter", [_dec], Object.getOwnPropertyDescriptor(_obj, "_showFooter"), _obj), _applyDecoratedDescriptor(_obj, "isFiltered", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isFiltered"), _obj), _applyDecoratedDescriptor(_obj, "allNotificationsRead", [_dec3], Object.getOwnPropertyDescriptor(_obj, "allNotificationsRead"), _obj), _applyDecoratedDescriptor(_obj, "doesNotHaveNotifications", [_dec4], Object.getOwnPropertyDescriptor(_obj, "doesNotHaveNotifications"), _obj), _applyDecoratedDescriptor(_obj, "nothingFound", [_dec5], Object.getOwnPropertyDescriptor(_obj, "nothingFound"), _obj), _applyDecoratedDescriptor(_obj, "emptyStateBody", [_dec6], Object.getOwnPropertyDescriptor(_obj, "emptyStateBody"), _obj)), _obj)));

  _exports.default = _default;
});