define("discourse/templates/user-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vEYmMDQ4",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"site\",\"mobileView\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"card-cloak hidden\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"user-card-contents\",null,[[\"topic\",\"showUser\",\"filterPosts\",\"composePrivateMessage\",\"createNewMessageViaParams\"],[[24,[\"topic\",\"model\"]],[28,\"action\",[[23,0,[]],\"showUser\"],null],[28,\"action\",[[23,0,[]],\"filterPosts\"],null],[28,\"route-action\",[\"composePrivateMessage\"],null],[28,\"route-action\",[\"createNewMessageViaParams\"],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"group-card-contents\",null,[[\"topic\",\"showUser\",\"showGroup\",\"createNewMessageViaParams\"],[[24,[\"topic\",\"model\"]],[28,\"action\",[[23,0,[]],\"showUser\"],null],[28,\"action\",[[23,0,[]],\"showGroup\"],null],[28,\"route-action\",[\"createNewMessageViaParams\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/user-card.hbs"
    }
  });

  _exports.default = _default;
});