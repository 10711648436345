define("discourse/services/store", ["exports", "discourse/models/rest", "discourse/models/result-set", "discourse/lib/ajax", "discourse-common/lib/get-owner"], function (_exports, _rest, _resultSet2, _ajax, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.flushMap = flushMap;
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _identityMap; // You should only call this if you're a test scaffold


  function flushMap() {
    _identityMap = {};
  }

  function storeMap(type, id, obj) {
    if (!id) {
      return;
    }

    _identityMap[type] = _identityMap[type] || {};
    _identityMap[type][id] = obj;
  }

  function fromMap(type, id) {
    var byType = _identityMap[type];

    if (byType && byType.hasOwnProperty(id)) {
      return byType[id];
    }
  }

  function removeMap(type, id) {
    var byType = _identityMap[type];

    if (byType && byType.hasOwnProperty(id)) {
      delete byType[id];
    }
  }

  function findAndRemoveMap(type, id) {
    var byType = _identityMap[type];

    if (byType && byType.hasOwnProperty(id)) {
      var result = byType[id];
      delete byType[id];
      return result;
    }
  }

  flushMap();

  var _default = Ember.Service.extend({
    _plurals: {
      category: "categories",
      "post-reply": "post-replies",
      "post-reply-history": "post_reply_histories",
      reviewable_history: "reviewable_histories"
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.register = this.register || (0, _getOwner.getRegister)(this);
    },
    pluralize: function pluralize(thing) {
      return this._plurals[thing] || thing + "s";
    },
    addPluralization: function addPluralization(thing, plural) {
      this._plurals[thing] = plural;
    },
    findAll: function findAll(type, findArgs) {
      var _this = this;

      var adapter = this.adapterFor(type);
      var store = this;
      return adapter.findAll(this, type, findArgs).then(function (result) {
        var results = _this._resultSet(type, result);

        if (adapter.afterFindAll) {
          results = adapter.afterFindAll(results, {
            lookup: function lookup(subType, id) {
              return store._lookupSubType(subType, type, id, result);
            }
          });
        }

        return results;
      });
    },
    // Mostly for legacy, things like TopicList without ResultSets
    findFiltered: function findFiltered(type, findArgs) {
      var _this2 = this;

      return this.adapterFor(type).find(this, type, findArgs).then(function (result) {
        return _this2._build(type, result);
      });
    },
    _hydrateFindResults: function _hydrateFindResults(result, type, findArgs) {
      if (_typeof(findArgs) === "object") {
        return this._resultSet(type, result, findArgs);
      } else {
        var apiName = this.adapterFor(type).apiNameFor(type);
        return this._hydrate(type, result[Ember.String.underscore(apiName)], result);
      }
    },
    // See if the store can find stale data. We sometimes prefer to show stale data and
    // refresh it in the background.
    findStale: function findStale(type, findArgs, opts) {
      var _this3 = this;

      var stale = this.adapterFor(type).findStale(this, type, findArgs, opts);
      return {
        hasResults: stale !== undefined,
        results: stale,
        refresh: function refresh() {
          return _this3.find(type, findArgs, opts);
        }
      };
    },
    find: function find(type, findArgs, opts) {
      var _this4 = this;

      var adapter = this.adapterFor(type);
      return adapter.find(this, type, findArgs, opts).then(function (result) {
        var hydrated = _this4._hydrateFindResults(result, type, findArgs, opts);

        if (result.extras) {
          hydrated.set("extras", result.extras);
        }

        if (adapter.cache) {
          var stale = adapter.findStale(_this4, type, findArgs, opts);
          hydrated = _this4._updateStale(stale, hydrated, adapter.primaryKey);
          adapter.cacheFind(_this4, type, findArgs, opts, hydrated);
        }

        return hydrated;
      });
    },
    _updateStale: function _updateStale(stale, hydrated, primaryKey) {
      if (!stale) {
        return hydrated;
      }

      hydrated.set("content", hydrated.get("content").map(function (item) {
        var staleItem = stale.content.findBy(primaryKey, item.get(primaryKey));

        if (staleItem) {
          staleItem.setProperties(item);
        } else {
          staleItem = item;
        }

        return staleItem;
      }));
      return hydrated;
    },
    refreshResults: function refreshResults(resultSet, type, url) {
      var _this5 = this;

      var adapter = this.adapterFor(type);
      return (0, _ajax.ajax)(url).then(function (result) {
        var typeName = Ember.String.underscore(_this5.pluralize(adapter.apiNameFor(type)));
        var content = result[typeName].map(function (obj) {
          return _this5._hydrate(type, obj, result);
        });
        resultSet.set("content", content);
      });
    },
    appendResults: function appendResults(resultSet, type, url) {
      var _this6 = this;

      var adapter = this.adapterFor(type);
      return (0, _ajax.ajax)(url).then(function (result) {
        var typeName = Ember.String.underscore(_this6.pluralize(adapter.apiNameFor(type)));
        var pageTarget = result.meta || result;
        var totalRows = pageTarget["total_rows_" + typeName] || resultSet.get("totalRows");
        var loadMoreUrl = pageTarget["load_more_" + typeName];
        var content = result[typeName].map(function (obj) {
          return _this6._hydrate(type, obj, result);
        });
        resultSet.setProperties({
          totalRows: totalRows,
          loadMoreUrl: loadMoreUrl
        });
        resultSet.get("content").pushObjects(content); // If we've loaded them all, clear the load more URL

        if (resultSet.get("length") >= totalRows) {
          resultSet.set("loadMoreUrl", null);
        }
      });
    },
    update: function update(type, id, attrs) {
      var adapter = this.adapterFor(type);
      return adapter.update(this, type, id, attrs, function (result) {
        if (result && result[type] && result[type][adapter.primaryKey]) {
          var oldRecord = findAndRemoveMap(type, id);
          storeMap(type, result[type][adapter.primaryKey], oldRecord);
        }

        return result;
      });
    },
    createRecord: function createRecord(type, attrs) {
      attrs = attrs || {};
      var adapter = this.adapterFor(type);
      return !!attrs[adapter.primaryKey] ? this._hydrate(type, attrs) : this._build(type, attrs);
    },
    destroyRecord: function destroyRecord(type, record) {
      var adapter = this.adapterFor(type); // If the record is new, don't perform an Ajax call

      if (record.get("isNew")) {
        removeMap(type, record.get(adapter.primaryKey));
        return Ember.RSVP.Promise.resolve(true);
      }

      return adapter.destroyRecord(this, type, record).then(function (result) {
        removeMap(type, record.get(adapter.primaryKey));
        return result;
      });
    },
    _resultSet: function _resultSet(type, result, findArgs) {
      var _this7 = this;

      var adapter = this.adapterFor(type);
      var typeName = Ember.String.underscore(this.pluralize(adapter.apiNameFor(type)));

      if (!result[typeName]) {
        // eslint-disable-next-line no-console
        console.error("JSON response is missing `".concat(typeName, "` key"), result);
        return;
      }

      var content = result[typeName].map(function (obj) {
        return _this7._hydrate(type, obj, result);
      });
      var pageTarget = result.meta || result;
      var createArgs = {
        content: content,
        findArgs: findArgs,
        totalRows: pageTarget["total_rows_" + typeName] || content.length,
        loadMoreUrl: pageTarget["load_more_" + typeName],
        refreshUrl: pageTarget["refresh_" + typeName],
        resultSetMeta: result.meta,
        store: this,
        __type: type
      };

      if (result.extras) {
        createArgs.extras = result.extras;
      }

      return _resultSet2.default.create(createArgs);
    },
    _build: function _build(type, obj) {
      var adapter = this.adapterFor(type);
      obj.store = this;
      obj.__type = type;
      obj.__state = obj[adapter.primaryKey] ? "created" : "new"; // TODO: Have injections be automatic

      obj.topicTrackingState = this.register.lookup("topic-tracking-state:main");
      obj.keyValueStore = this.register.lookup("key-value-store:main");

      var klass = this.register.lookupFactory("model:" + type) || _rest.default;

      var model = klass.create(obj);
      storeMap(type, obj[adapter.primaryKey], model);
      return model;
    },
    adapterFor: function adapterFor(type) {
      return this.register.lookup("adapter:" + type) || this.register.lookup("adapter:rest");
    },
    _lookupSubType: function _lookupSubType(subType, type, id, root) {
      if (root.meta && root.meta.types) {
        subType = root.meta.types[subType] || subType;
      }

      var subTypeAdapter = this.adapterFor(subType);
      var pluralType = this.pluralize(subType);
      var collection = root[this.pluralize(subType)];

      if (collection) {
        var hashedProp = "__hashed_" + pluralType;
        var hashedCollection = root[hashedProp];

        if (!hashedCollection) {
          hashedCollection = {};
          collection.forEach(function (it) {
            hashedCollection[it[subTypeAdapter.primaryKey]] = it;
          });
          root[hashedProp] = hashedCollection;
        }

        var found = hashedCollection[id];

        if (found) {
          var hydrated = this._hydrate(subType, found, root);

          hashedCollection[id] = hydrated;
          return hydrated;
        }
      }
    },
    _hydrateEmbedded: function _hydrateEmbedded(type, obj, root) {
      var _this8 = this;

      var adapter = this.adapterFor(type);
      Object.keys(obj).forEach(function (k) {
        if (k === adapter.primaryKey) {
          return;
        }

        var m = /(.+)\_id(s?)$/.exec(k);

        if (m) {
          var subType = m[1];

          if (m[2]) {
            var hydrated = obj[k].map(function (id) {
              return _this8._lookupSubType(subType, type, id, root);
            });
            obj[_this8.pluralize(subType)] = hydrated || [];
            delete obj[k];
          } else {
            var _hydrated = _this8._lookupSubType(subType, type, obj[k], root);

            if (_hydrated) {
              obj[subType] = _hydrated;
              delete obj[k];
            } else {
              Ember.set(obj, subType, null);
            }
          }
        }
      });
    },
    _hydrate: function _hydrate(type, obj, root) {
      if (!obj) {
        throw new Error("Can't hydrate " + type + " of `null`");
      }

      var adapter = this.adapterFor(type);
      var id = obj[adapter.primaryKey];

      if (!id) {
        throw new Error("Can't hydrate ".concat(type, " without primaryKey: `").concat(adapter.primaryKey, "`"));
      }

      root = root || obj;

      if (root.__rest_serializer === "1") {
        this._hydrateEmbedded(type, obj, root);
      }

      var existing = fromMap(type, id);

      if (existing === obj) {
        return existing;
      }

      if (existing) {
        delete obj[adapter.primaryKey];
        var klass = this.register.lookupFactory("model:" + type);

        if (klass && klass.class) {
          klass = klass.class;
        }

        if (!klass) {
          klass = _rest.default;
        }

        existing.setProperties(klass.munge(obj));
        obj[adapter.primaryKey] = id;
        return existing;
      }

      return this._build(type, obj);
    }
  });

  _exports.default = _default;
});