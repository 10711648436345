define("discourse/lib/public-js-versions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PUBLIC_JS_VERSIONS = void 0;
  // DO NOT EDIT THIS FILE!!!
  // Update it by running `rake javascript:update`
  var PUBLIC_JS_VERSIONS = {
    "ace/ace.js": "ace.js/1.4.13/ace.js",
    "jsoneditor.js": "@json-editor/json-editor/2.6.1/jsoneditor.js",
    "Chart.min.js": "chart.js/3.5.1/Chart.min.js",
    "chartjs-plugin-datalabels.min.js": "chartjs-plugin-datalabels/2.0.0/chartjs-plugin-datalabels.min.js",
    "diffhtml.min.js": "diffhtml/1.0.0-beta.20/diffhtml.min.js",
    "jquery.magnific-popup.min.js": "magnific-popup/1.1.0/jquery.magnific-popup.min.js",
    "pikaday.js": "pikaday/1.8.0/pikaday.js"
  };
  _exports.PUBLIC_JS_VERSIONS = PUBLIC_JS_VERSIONS;
});