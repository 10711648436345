define("discourse/widgets/header-topic-info", ["exports", "discourse/widgets/widget", "discourse/lib/url", "I18n", "discourse/widgets/raw-html", "discourse/widgets/post", "discourse-common/lib/get-url", "virtual-dom", "discourse-common/lib/icon-library", "discourse/lib/render-tags", "discourse/lib/render-topic-featured-link"], function (_exports, _widget, _url, _I18n, _rawHtml, _post, _getUrl, _virtualDom, _iconLibrary, _renderTags, _renderTopicFeaturedLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  (0, _widget.createWidget)("topic-header-participant", {
    tagName: "span",
    buildClasses: function buildClasses(attrs) {
      return "trigger-".concat(attrs.type, "-card");
    },
    html: function html(attrs) {
      var user = attrs.user,
          group = attrs.group;
      var content, url;

      if (attrs.type === "user") {
        content = (0, _post.avatarImg)("tiny", {
          template: user.avatar_template,
          username: user.username
        });
        url = user.get("path");
      } else {
        content = [(0, _iconLibrary.iconNode)("users")];
        url = (0, _getUrl.default)("/g/".concat(group.name));
        content.push((0, _virtualDom.h)("span", group.name));
      }

      return (0, _virtualDom.h)("a.icon", {
        attributes: {
          href: url,
          "data-auto-route": true,
          title: attrs.username
        }
      }, content);
    },
    click: function click(e) {
      this.appEvents.trigger("topic-header:trigger-".concat(this.attrs.type, "-card"), this.attrs.username, e.target);
      e.preventDefault();
    }
  });

  var _default = (0, _widget.createWidget)("header-topic-info", {
    tagName: "div.extra-info-wrapper",
    contents: null,
    title: null,
    buildClasses: function buildClasses(attrs, state) {
      this.buildAttributes(attrs, state);
      return this.containerClassName();
    },
    buildFancyTitleClass: function buildFancyTitleClass() {
      var baseClass = ["topic-link"];

      var flatten = function flatten(array) {
        return [].concat.apply([], array);
      };

      var extraClass = flatten((0, _widget.applyDecorators)(this, "fancyTitleClass", this.attrs, this.state));
      return baseClass.concat(extraClass).filter(Boolean).join(" ");
    },
    buildAttributes: function buildAttributes(attrs, state) {
      var _this = this;

      var topic = attrs.topic;
      var heading = [];
      var showPM = !topic.get("is_warning") && topic.get("isPrivateMessage");

      if (showPM) {
        var _href = this.currentUser && this.currentUser.pmPath(topic);

        if (_href) {
          heading.push((0, _virtualDom.h)("a.private-message-glyph-wrapper", {
            attributes: {
              href: _href,
              "aria-label": _I18n.default.t("user.messages.inbox")
            }
          }, (0, _iconLibrary.iconNode)("envelope", {
            class: "private-message-glyph"
          })));
        }
      }

      var loaded = topic.get("details.loaded");
      var fancyTitle = topic.get("fancyTitle");
      var href = topic.get("url");

      if (fancyTitle && href) {
        heading.push(this.attach("topic-status", attrs));
        var titleHTML = new _rawHtml.default({
          html: "<span>".concat(fancyTitle, "</span>")
        });
        heading.push(this.attach("link", {
          className: this.buildFancyTitleClass(),
          action: "jumpToTopPost",
          href: href,
          attributes: {
            "data-topic-id": topic.get("id")
          },
          contents: function contents() {
            return titleHTML;
          }
        }));
      }

      this.headerElements = [(0, _virtualDom.h)("h1.header-title", heading)];
      var category = topic.get("category");

      if (loaded || category) {
        if (category && (!category.isUncategorizedCategory || !this.siteSettings.suppress_uncategorized_badge)) {
          var parentCategory = category.get("parentCategory");
          var categories = [];

          if (parentCategory) {
            if (this.siteSettings.max_category_nesting > 2 && !this.site.mobileView) {
              var grandParentCategory = parentCategory.get("parentCategory");

              if (grandParentCategory) {
                categories.push(this.attach("category-link", {
                  category: grandParentCategory
                }));
              }
            }

            categories.push(this.attach("category-link", {
              category: parentCategory
            }));
          }

          categories.push(this.attach("category-link", {
            category: category
          }));
          this.headerElements.push((0, _virtualDom.h)("div.categories-wrapper", categories));
        }

        var extra = [];
        var tags = (0, _renderTags.default)(topic);

        if (tags && tags.length > 0) {
          extra.push(new _rawHtml.default({
            html: tags
          }));
        }

        if (showPM) {
          var maxHeaderParticipants = extra.length > 0 ? 5 : 10;
          var participants = [];
          var topicDetails = topic.get("details");
          var totalParticipants = topicDetails.allowed_users.length + topicDetails.allowed_groups.length;
          topicDetails.allowed_users.some(function (user) {
            if (participants.length >= maxHeaderParticipants) {
              return true;
            }

            participants.push(_this.attach("topic-header-participant", {
              type: "user",
              user: user,
              username: user.username
            }));
          });
          topicDetails.allowed_groups.some(function (group) {
            if (participants.length >= maxHeaderParticipants) {
              return true;
            }

            participants.push(_this.attach("topic-header-participant", {
              type: "group",
              group: group,
              username: group.name
            }));
          });

          if (totalParticipants > maxHeaderParticipants) {
            var remaining = totalParticipants - maxHeaderParticipants;
            participants.push(this.attach("link", {
              className: "more-participants",
              action: "jumpToTopPost",
              href: href,
              attributes: {
                "data-topic-id": topic.get("id")
              },
              contents: function contents() {
                return "+".concat(remaining);
              }
            }));
          }

          extra.push((0, _virtualDom.h)("div.topic-header-participants", participants));
        }

        extra = extra.concat((0, _widget.applyDecorators)(this, "after-tags", attrs, state));

        if (this.siteSettings.topic_featured_link_enabled) {
          var featured = (0, _renderTopicFeaturedLink.topicFeaturedLinkNode)(attrs.topic);

          if (featured) {
            extra.push(featured);
          }
        }

        if (extra.length) {
          this.headerElements.push((0, _virtualDom.h)("div.topic-header-extra", extra));
        }
      }

      this.contents = (0, _virtualDom.h)("div.title-wrapper", this.headerElements);
    },
    html: function html() {
      return (0, _virtualDom.h)("div.extra-info", {
        className: this.containerClassName()
      }, this.contents);
    },
    containerClassName: function containerClassName() {
      return this.headerElements.length > 1 ? "two-rows" : "";
    },
    jumpToTopPost: function jumpToTopPost() {
      var topic = this.attrs.topic;

      if (topic) {
        _url.default.routeTo(topic.get("firstPostUrl"), {
          keepFilter: true
        });
      }
    }
  });

  _exports.default = _default;
});