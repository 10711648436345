define("discourse/controllers/user-activity-bookmarks", ["exports", "discourse-common/lib/icon-library", "discourse-common/utils/decorators", "discourse/lib/ajax", "discourse/models/bookmark", "I18n"], function (_exports, _iconLibrary, _decorators, _ajax, _bookmark, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)(), _dec2 = (0, _decorators.default)("inSearchMode", "noContent"), _dec3 = (0, _decorators.default)("inSearchMode", "noContent"), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_obj = {
    queryParams: ["q"],
    q: null,
    application: Ember.inject.controller(),
    user: Ember.inject.controller(),
    loading: false,
    loadingMore: false,
    permissionDenied: false,
    inSearchMode: Ember.computed.notEmpty("q"),
    noContent: Ember.computed.equal("model.bookmarks.length", 0),
    searchTerm: Ember.computed("q", {
      get: function get() {
        return this.q;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    emptyStateBody: function emptyStateBody() {
      return _I18n.default.t("user.no_bookmarks_body", {
        icon: (0, _iconLibrary.iconHTML)("bookmark")
      }).htmlSafe();
    },
    userDoesNotHaveBookmarks: function userDoesNotHaveBookmarks(inSearchMode, noContent) {
      return !inSearchMode && noContent;
    },
    nothingFound: function nothingFound(inSearchMode, noContent) {
      return inSearchMode && noContent;
    },
    search: function search() {
      this.transitionToRoute({
        queryParams: {
          q: this.searchTerm
        }
      });
    },
    reload: function reload() {
      this.send("triggerRefresh");
    },
    loadMore: function loadMore() {
      var _this = this;

      if (this.loadingMore) {
        return Ember.RSVP.Promise.resolve();
      }

      this.set("loadingMore", true);
      return this._loadMoreBookmarks(this.q).then(function (response) {
        return _this._processLoadResponse(response);
      }).catch(function () {
        return _this._bookmarksListDenied();
      }).finally(function () {
        return _this.set("loadingMore", false);
      });
    },
    _loadMoreBookmarks: function _loadMoreBookmarks(searchQuery) {
      if (!this.model.loadMoreUrl) {
        return Ember.RSVP.Promise.resolve();
      }

      var moreUrl = this.model.loadMoreUrl;

      if (searchQuery) {
        var delimiter = moreUrl.includes("?") ? "&" : "?";
        var q = encodeURIComponent(searchQuery);
        moreUrl += "".concat(delimiter, "q=").concat(q);
      }

      return (0, _ajax.ajax)({
        url: moreUrl
      });
    },
    _bookmarksListDenied: function _bookmarksListDenied() {
      this.set("permissionDenied", true);
    },
    _processLoadResponse: function _processLoadResponse(response) {
      if (!response || !response.user_bookmark_list) {
        return;
      }

      response = response.user_bookmark_list;
      this.model.loadMoreUrl = response.more_bookmarks_url;

      if (response.bookmarks) {
        var bookmarkModels = response.bookmarks.map(this.transform);
        this.model.bookmarks.pushObjects(bookmarkModels);
        this.session.set("bookmarksModel", this.model);
      }
    },
    transform: function transform(bookmark) {
      var bookmarkModel = _bookmark.default.create(bookmark);

      bookmarkModel.topicStatus = Ember.Object.create({
        closed: bookmark.closed,
        archived: bookmark.archived,
        is_warning: bookmark.is_warning,
        pinned: false,
        unpinned: false,
        invisible: bookmark.invisible
      });
      return bookmarkModel;
    }
  }, (_applyDecoratedDescriptor(_obj, "emptyStateBody", [_dec], Object.getOwnPropertyDescriptor(_obj, "emptyStateBody"), _obj), _applyDecoratedDescriptor(_obj, "userDoesNotHaveBookmarks", [_dec2], Object.getOwnPropertyDescriptor(_obj, "userDoesNotHaveBookmarks"), _obj), _applyDecoratedDescriptor(_obj, "nothingFound", [_dec3], Object.getOwnPropertyDescriptor(_obj, "nothingFound"), _obj), _applyDecoratedDescriptor(_obj, "search", [_dec4], Object.getOwnPropertyDescriptor(_obj, "search"), _obj), _applyDecoratedDescriptor(_obj, "reload", [_dec5], Object.getOwnPropertyDescriptor(_obj, "reload"), _obj), _applyDecoratedDescriptor(_obj, "loadMore", [_dec6], Object.getOwnPropertyDescriptor(_obj, "loadMore"), _obj)), _obj)));

  _exports.default = _default;
});