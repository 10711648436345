define("discourse/components/reviewable-field-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onChange: function onChange(category) {
        this.set("value", category);
        this.categoryChanged && this.categoryChanged(category);
      }
    }
  });

  _exports.default = _default;
});