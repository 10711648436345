define("discourse/lib/desktop-notifications", ["exports", "discourse/lib/url", "I18n", "discourse/lib/key-value-store", "discourse/models/site", "discourse/models/user", "discourse/lib/utilities"], function (_exports, _url, _I18n, _keyValueStore, _site, _user, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerDesktopNotificationHandler = registerDesktopNotificationHandler;
  _exports.clearDesktopNotificationHandlers = clearDesktopNotificationHandlers;
  _exports.resetIdle = resetIdle;
  _exports.init = init;
  _exports.onNotification = onNotification;
  _exports.unsubscribe = unsubscribe;
  _exports.alertChannel = alertChannel;
  _exports.confirmNotification = confirmNotification;
  _exports.disable = disable;
  _exports.context = void 0;
  var primaryTab = false;
  var liveEnabled = false;
  var havePermission = null;
  var mbClientId = "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx";
  var lastAction = -1;
  var focusTrackerKey = "focus-tracker";
  var idleThresholdTime = 1000 * 10; // 10 seconds

  var context = "discourse_desktop_notifications_";
  _exports.context = context;
  var keyValueStore = new _keyValueStore.default(context);
  var desktopNotificationHandlers = [];

  function registerDesktopNotificationHandler(handler) {
    desktopNotificationHandlers.push(handler);
  }

  function clearDesktopNotificationHandlers() {
    desktopNotificationHandlers = [];
  } // Called from an initializer


  function init(messageBus, appEvents) {
    liveEnabled = false;
    mbClientId = messageBus.clientId;

    if (!_user.default.current()) {
      return;
    }

    try {
      keyValueStore.getItem(focusTrackerKey);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info("Discourse desktop notifications are disabled - localStorage denied.");
      return;
    }

    if (!("Notification" in window)) {
      // eslint-disable-next-line no-console
      console.info("Discourse desktop notifications are disabled - not supported by browser");
      return;
    }

    try {
      if (Notification.permission === "granted") {
        havePermission = true;
      } else if (Notification.permission === "denied") {
        havePermission = false;
        return;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn("Unexpected error, Notification is defined on window but not a responding correctly " + e);
    }

    liveEnabled = true;

    try {
      // Preliminary checks passed, continue with setup
      setupNotifications(appEvents);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  function confirmNotification(siteSettings) {
    var notification = new Notification(_I18n.default.t("notifications.popup.confirm_title", {
      site_title: siteSettings.title
    }), {
      body: _I18n.default.t("notifications.popup.confirm_body"),
      icon: siteSettings.site_logo_small_url || siteSettings.site_logo_url,
      tag: "confirm-subscription"
    });

    var clickEventHandler = function clickEventHandler() {
      return notification.close();
    };

    notification.addEventListener("click", clickEventHandler);
    Ember.run.later(function () {
      notification.close();
      notification.removeEventListener("click", clickEventHandler);
    }, 10 * 1000);
  } // This function is only called if permission was granted


  function setupNotifications(appEvents) {
    window.addEventListener("storage", function (e) {
      // note: This event only fires when other tabs setItem()
      var key = e.key;

      if (key !== "".concat(context).concat(focusTrackerKey)) {
        return true;
      }

      primaryTab = false;
    });
    window.addEventListener("focus", function () {
      if (!primaryTab) {
        primaryTab = true;
        keyValueStore.setItem(focusTrackerKey, mbClientId);
      }
    });

    if (document && typeof document.hidden !== "undefined" && document["hidden"]) {
      primaryTab = false;
    } else {
      primaryTab = true;
      keyValueStore.setItem(focusTrackerKey, mbClientId);
    }

    if (document) {
      document.addEventListener("scroll", resetIdle);
    }

    appEvents.on("page:changed", resetIdle);
  }

  function resetIdle() {
    lastAction = Date.now();
  }

  function isIdle() {
    return lastAction + idleThresholdTime < Date.now();
  } // Call-in point from message bus


  function onNotification(data, siteSettings, user) {
    if (!liveEnabled) {
      return;
    }

    if (!primaryTab) {
      return;
    }

    if (!isIdle()) {
      return;
    }

    if (user.isInDoNotDisturb()) {
      return;
    }

    if (keyValueStore.getItem("notifications-disabled")) {
      return;
    }

    var notificationTitle = data.translated_title || _I18n.default.t(i18nKey(data.notification_type), {
      site_title: siteSettings.title,
      topic: data.topic_title,
      username: (0, _utilities.formatUsername)(data.username)
    });

    var notificationBody = data.excerpt;
    var notificationIcon = siteSettings.site_logo_small_url || siteSettings.site_logo_url;
    var notificationTag = "discourse-notification-" + siteSettings.title + "-" + data.topic_id;
    requestPermission().then(function () {
      // This shows the notification!
      var notification = new Notification(notificationTitle, {
        body: notificationBody,
        icon: notificationIcon,
        tag: notificationTag
      });

      notification.onclick = function () {
        _url.default.routeTo(data.post_url);

        notification.close();
      };

      desktopNotificationHandlers.forEach(function (handler) {
        return handler(data, siteSettings, user);
      });
    });
  } // Utility function
  // Wraps Notification.requestPermission in a Promise


  function requestPermission() {
    if (havePermission === true) {
      return Ember.RSVP.Promise.resolve();
    } else if (havePermission === false) {
      return Ember.RSVP.Promise.reject();
    } else {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Notification.requestPermission(function (status) {
          if (status === "granted") {
            resolve();
          } else {
            reject();
          }
        });
      });
    }
  }

  function i18nKey(notification_type) {
    return "notifications.popup." + _site.default.current().get("notificationLookup")[notification_type];
  }

  function alertChannel(user) {
    return "/notification-alert/".concat(user.get("id"));
  }

  function unsubscribe(bus, user) {
    bus.unsubscribe(alertChannel(user));
  }

  function disable() {
    keyValueStore.setItem("notifications-disabled", "disabled");
  }
});