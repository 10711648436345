define("discourse/components/edit-category-images", ["exports", "discourse/components/edit-category-panel", "discourse-common/utils/decorators"], function (_exports, _editCategoryPanel, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = (0, _editCategoryPanel.buildCategoryPanel)("images").extend((_dec = (0, _decorators.default)("category.uploaded_background.url"), _dec2 = (0, _decorators.default)("category.uploaded_logo.url"), (_obj = {
    backgroundImageUrl: function backgroundImageUrl(uploadedBackgroundUrl) {
      return uploadedBackgroundUrl || "";
    },
    logoImageUrl: function logoImageUrl(uploadedLogoUrl) {
      return uploadedLogoUrl || "";
    },
    actions: {
      logoUploadDone: function logoUploadDone(upload) {
        this._setFromUpload("category.uploaded_logo", upload);
      },
      logoUploadDeleted: function logoUploadDeleted() {
        this._deleteUpload("category.uploaded_logo");
      },
      backgroundUploadDone: function backgroundUploadDone(upload) {
        this._setFromUpload("category.uploaded_background", upload);
      },
      backgroundUploadDeleted: function backgroundUploadDeleted() {
        this._deleteUpload("category.uploaded_background");
      }
    },
    _deleteUpload: function _deleteUpload(path) {
      this.set(path, Ember.Object.create({
        id: null,
        url: null
      }));
    },
    _setFromUpload: function _setFromUpload(path, upload) {
      this.set(path, Ember.Object.create({
        url: upload.url,
        id: upload.id
      }));
    }
  }, (_applyDecoratedDescriptor(_obj, "backgroundImageUrl", [_dec], Object.getOwnPropertyDescriptor(_obj, "backgroundImageUrl"), _obj), _applyDecoratedDescriptor(_obj, "logoImageUrl", [_dec2], Object.getOwnPropertyDescriptor(_obj, "logoImageUrl"), _obj)), _obj)));

  _exports.default = _default;
});