define("discourse/templates/components/user-fields/confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/QVEipdI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"field\",\"name\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[0,\"\\n    \"],[1,[28,\"html-safe\",[[23,0,[\"field\",\"name\"]]],null],false],[0,\" \"],[4,\"if\",[[23,0,[\"field\",\"required\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"class\",\"required\"],[8],[0,\"*\"],[9]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"control-label checkbox-label\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"id\",\"checked\",\"type\"],[[28,\"concat\",[\"user-\",[23,0,[\"elementId\"]]],null],[23,0,[\"value\"]],\"checkbox\"]]],false],[0,\"\\n    \"],[7,\"span\",true],[8],[0,\"\\n      \"],[1,[28,\"html-safe\",[[23,0,[\"field\",\"description\"]]],null],false],[0,\" \"],[4,\"unless\",[[23,0,[\"field\",\"name\"]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"field\",\"required\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"class\",\"required\"],[8],[0,\"*\"],[9]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/user-fields/confirm.hbs"
    }
  });

  _exports.default = _default;
});