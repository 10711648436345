define("discourse/initializers/url-redirects", ["exports", "discourse/lib/url", "discourse/lib/utilities"], function (_exports, _url, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "url-redirects",
    after: "inject-objects",
    initialize: function initialize(container) {
      var currentUser = container.lookup("current-user:main");

      if (currentUser) {
        var username = currentUser.get("username");
        var escapedUsername = username.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

        _url.default.rewrite(new RegExp("^/u/".concat(escapedUsername, "/?$"), "i"), "/u/".concat(username, "/activity"));
      } // We are still using these for now


      _url.default.rewrite(/^\/group\//, "/groups/");

      _url.default.rewrite(/^\/groups$/, "/g");

      _url.default.rewrite(/^\/groups\//, "/g/"); // Initialize default homepage


      var siteSettings = container.lookup("site-settings:main");
      (0, _utilities.initializeDefaultHomepage)(siteSettings);

      _url.default.rewrite(/^\/u\/([^\/]+)\/?$/, "/u/$1/summary", {
        exceptions: ["/u/account-created", "/users/account-created", "/u/password-reset", "/users/password-reset"]
      });
    }
  };
  _exports.default = _default;
});