define("discourse/controllers/email-login", ["exports", "discourse/lib/url", "discourse/models/user", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse-common/lib/get-url", "discourse/lib/webauthn", "discourse/lib/ajax-error"], function (_exports, _url, _user, _ajax, _decorators, _getUrl, _webauthn, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("model"), _dec2 = (0, _decorators.default)("model"), (_obj = {
    lockImageUrl: (0, _getUrl.default)("/images/lock.svg"),
    secondFactorRequired: function secondFactorRequired(model) {
      return model.security_key_required || model.second_factor_required;
    },
    secondFactorMethod: function secondFactorMethod(model) {
      return model.security_key_required ? _user.SECOND_FACTOR_METHODS.SECURITY_KEY : _user.SECOND_FACTOR_METHODS.TOTP;
    },
    actions: {
      finishLogin: function finishLogin() {
        var _this = this;

        var data = {
          second_factor_method: this.secondFactorMethod,
          timezone: moment.tz.guess()
        };

        if (this.securityKeyCredential) {
          data.second_factor_token = this.securityKeyCredential;
        } else {
          data.second_factor_token = this.secondFactorToken;
        }

        (0, _ajax.ajax)({
          url: "/session/email-login/".concat(this.model.token),
          type: "POST",
          data: data
        }).then(function (result) {
          if (result.success) {
            _url.default.redirectTo("/");
          } else {
            _this.set("model.error", result.error);
          }
        }).catch(_ajaxError.popupAjaxError);
      },
      authenticateSecurityKey: function authenticateSecurityKey() {
        var _this2 = this;

        (0, _webauthn.getWebauthnCredential)(this.model.challenge, this.model.allowed_credential_ids, function (credentialData) {
          _this2.set("securityKeyCredential", credentialData);

          _this2.send("finishLogin");
        }, function (errorMessage) {
          _this2.set("model.error", errorMessage);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "secondFactorRequired", [_dec], Object.getOwnPropertyDescriptor(_obj, "secondFactorRequired"), _obj), _applyDecoratedDescriptor(_obj, "secondFactorMethod", [_dec2], Object.getOwnPropertyDescriptor(_obj, "secondFactorMethod"), _obj)), _obj)));

  _exports.default = _default;
});