define("discourse/components/navigation-item", ["exports", "discourse/mixins/filter-mode", "discourse-common/utils/decorators"], function (_exports, _filterMode, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_filterMode.default, (_dec = (0, _decorators.default)("content.filterType", "filterType", "content.active"), _dec2 = (0, _decorators.default)("content.count"), (_obj = {
    tagName: "li",
    classNameBindings: ["active", "content.hasIcon:has-icon", "content.classNames", "isHidden:hidden"],
    attributeBindings: ["content.title:title"],
    hidden: false,
    rerenderTriggers: ["content.count"],
    activeClass: "",
    hrefLink: null,
    active: function active(contentFilterType, filterType, _active) {
      if (_active !== undefined) {
        return _active;
      }

      return contentFilterType === filterType;
    },
    isHidden: function isHidden(count) {
      return !this.active && this.currentUser && this.currentUser.trust_level > 0 && (this.content.get("name") === "new" || this.content.get("name") === "unread") && count < 1;
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var content = this.content;
      var href = content.get("href");
      var queryParams = []; // Include the category id if the option is present

      if (content.get("includeCategoryId")) {
        var categoryId = this.get("content.category.id");

        if (categoryId) {
          queryParams.push("category_id=".concat(categoryId));
        }
      } // To reset the "filter" sticky param, at least one query param is needed.
      // If no query param is present, add an empty one to ensure a ? is
      // appended to the URL.


      if (content.currentRouteQueryParams) {
        if (content.currentRouteQueryParams.filter) {
          if (queryParams.length === 0) {
            queryParams.push("");
          }
        }
      }

      if (queryParams.length) {
        href += "?".concat(queryParams.join("&"));
      }

      this.set("hrefLink", href);
      this.set("activeClass", this.active ? "active" : "");
    }
  }, (_applyDecoratedDescriptor(_obj, "active", [_dec], Object.getOwnPropertyDescriptor(_obj, "active"), _obj), _applyDecoratedDescriptor(_obj, "isHidden", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isHidden"), _obj)), _obj)));

  _exports.default = _default;
});