define("discourse/templates/modal/create-invite-bulk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g50R4cfN",
    "block": "{\"symbols\":[\"status\"],\"statements\":[[4,\"create-invite-uploader\",null,null,{\"statements\":[[4,\"d-modal-body\",null,[[\"title\"],[\"user.invited.bulk_invite.text\"]],{\"statements\":[[4,\"if\",[[23,1,[\"uploaded\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"i18n\",[\"user.invited.bulk_invite.success\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"html-safe\",[[28,\"i18n\",[\"user.invited.bulk_invite.instructions\"],null]],null],false],[0,\"\\n\\n      \"],[7,\"input\",true],[10,\"id\",\"csv-file\"],[11,\"disabled\",[23,1,[\"uploading\"]]],[10,\"accept\",\".csv\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n\"],[4,\"unless\",[[23,1,[\"uploaded\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"d-button\",null,[[\"icon\",\"translatedLabel\",\"class\",\"action\",\"disabled\"],[[28,\"if\",[[24,[\"isEmail\"]],\"envelope\",\"link\"],null],[28,\"if\",[[23,1,[\"uploading\"]],[28,\"i18n\",[\"user.invited.bulk_invite.progress\"],[[\"progress\"],[[23,1,[\"uploadProgress\"]]]]],[28,\"i18n\",[\"user.invited.bulk_invite.text\"],null]],null],\"btn-primary\",[23,1,[\"startUpload\"]],[23,1,[\"submitDisabled\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[28,\"d-button\",null,[[\"label\",\"class\",\"action\"],[\"close\",\"btn-primary\",[28,\"route-action\",[\"closeModal\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/create-invite-bulk.hbs"
    }
  });

  _exports.default = _default;
});