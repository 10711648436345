define("discourse/components/global-notice", ["exports", "discourse/lib/cookie", "I18n", "discourse-common/utils/decorators", "discourse-common/lib/get-url"], function (_exports, _cookie, _I18n, _decorators, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addGlobalNotice = addGlobalNotice;
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _pluginNotices = [];

  function addGlobalNotice(text, id) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    _pluginNotices.push(Notice.create({
      text: text,
      id: id,
      options: options
    }));
  }

  var GLOBAL_NOTICE_DISMISSED_PROMPT_KEY = "dismissed-global-notice-v2";
  var Notice = Ember.Object.extend({
    logsNoticeService: Ember.inject.service("logsNotice"),
    text: null,
    id: null,
    options: null,
    init: function init() {
      this._super.apply(this, arguments);

      var defaults = {
        // can this banner be hidden
        dismissable: false,
        // prepend html content
        html: null,
        // will define the style of the banner, follows alerts styling
        level: "info",
        // should the banner be permanently hidden?
        persistentDismiss: true,
        // callback function when dismissing a banner
        onDismiss: null,
        // show/hide banner function, will take precedence over everything
        visibility: null,
        // how long before banner should show again, eg: moment.duration(1, "week")
        dismissDuration: null
      };
      this.options = this.set("options", Object.assign(defaults, this.options || {}));
    }
  });

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("site.isReadOnly", "site.wizard_required", "siteSettings.login_required", "siteSettings.disable_emails", "siteSettings.global_notice", "siteSettings.bootstrap_mode_enabled", "siteSettings.bootstrap_mode_min_users", "session.safe_mode", "logNotice.{id,text,hidden}"), _dec2 = Ember._action, (_obj = {
    logsNoticeService: Ember.inject.service("logsNotice"),
    logNotice: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.logsNoticeService.addObserver("hidden", this._handleLogsNoticeUpdate);
      this.logsNoticeService.addObserver("text", this._handleLogsNoticeUpdate);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.logsNoticeService.removeObserver("text", this._handleLogsNoticeUpdate);
      this.logsNoticeService.removeObserver("hidden", this._handleLogsNoticeUpdate);
    },
    notices: function notices(isReadOnly, wizardRequired, loginRequired, disableEmails, globalNotice, bootstrapModeEnabled, bootstrapModeMinUsers, safeMode, logNotice) {
      var _this$currentUser,
          _this = this;

      var notices = [];

      if ((0, _cookie.default)("dosp") === "1") {
        (0, _cookie.removeCookie)("dosp", {
          path: "/"
        });
        notices.push(Notice.create({
          text: loginRequired ? _I18n.default.t("forced_anonymous_login_required") : _I18n.default.t("forced_anonymous"),
          id: "forced-anonymous"
        }));
      }

      if (safeMode) {
        notices.push(Notice.create({
          text: _I18n.default.t("safe_mode.enabled"),
          id: "safe-mode"
        }));
      }

      if (isReadOnly) {
        notices.push(Notice.create({
          text: _I18n.default.t("read_only_mode.enabled"),
          id: "alert-read-only"
        }));
      }

      if (disableEmails === "yes" || disableEmails === "non-staff") {
        notices.push(Notice.create({
          text: _I18n.default.t("emails_are_disabled"),
          id: "alert-emails-disabled"
        }));
      }

      if (wizardRequired) {
        var requiredText = _I18n.default.t("wizard_required", {
          url: (0, _getUrl.default)("/wizard")
        });

        notices.push(Notice.create({
          text: Ember.String.htmlSafe(requiredText),
          id: "alert-wizard"
        }));
      }

      if ((_this$currentUser = this.currentUser) !== null && _this$currentUser !== void 0 && _this$currentUser.staff && bootstrapModeEnabled) {
        if (bootstrapModeMinUsers > 0) {
          notices.push(Notice.create({
            text: _I18n.default.t("bootstrap_mode_enabled", {
              count: bootstrapModeMinUsers
            }),
            id: "alert-bootstrap-mode"
          }));
        } else {
          notices.push(Notice.create({
            text: _I18n.default.t("bootstrap_mode_disabled"),
            id: "alert-bootstrap-mode"
          }));
        }
      }

      if ((globalNotice === null || globalNotice === void 0 ? void 0 : globalNotice.length) > 0) {
        notices.push(Notice.create({
          text: globalNotice,
          id: "alert-global-notice"
        }));
      }

      if (logNotice) {
        notices.push(logNotice);
      }

      return notices.concat(_pluginNotices).filter(function (notice) {
        if (notice.options.visibility) {
          return notice.options.visibility(notice);
        }

        var key = "".concat(GLOBAL_NOTICE_DISMISSED_PROMPT_KEY, "-").concat(notice.id);

        var value = _this.keyValueStore.get(key); // banner has never been dismissed


        if (!value) {
          return true;
        } // banner has no persistent dismiss and should always show on load


        if (!notice.options.persistentDismiss) {
          return true;
        }

        if (notice.options.dismissDuration) {
          var resetAt = moment(value).add(notice.options.dismissDuration);
          return moment().isAfter(resetAt);
        } else {
          return false;
        }
      });
    },
    dismissNotice: function dismissNotice(notice) {
      if (notice.options.onDismiss) {
        notice.options.onDismiss(notice);
      }

      if (notice.options.persistentDismiss) {
        this.keyValueStore.set({
          key: "".concat(GLOBAL_NOTICE_DISMISSED_PROMPT_KEY, "-").concat(notice.id),
          value: moment().toISOString(true)
        });
      }

      var alert = document.getElementById("global-notice-".concat(notice.id));

      if (alert) {
        alert.style.display = "none";
      }
    },
    _handleLogsNoticeUpdate: function _handleLogsNoticeUpdate() {
      var logsNoticeService = this.logsNoticeService;
      var logNotice = Notice.create({
        text: Ember.String.htmlSafe(this.logsNoticeService.message),
        id: "alert-logs-notice",
        options: {
          dismissable: true,
          persistentDismiss: false,
          visibility: function visibility() {
            return !logsNoticeService.hidden;
          },
          onDismiss: function onDismiss() {
            logsNoticeService.set("text", "");
          }
        }
      });
      this.set("logNotice", logNotice);
    }
  }, (_applyDecoratedDescriptor(_obj, "notices", [_dec], Object.getOwnPropertyDescriptor(_obj, "notices"), _obj), _applyDecoratedDescriptor(_obj, "dismissNotice", [_dec2], Object.getOwnPropertyDescriptor(_obj, "dismissNotice"), _obj), _applyDecoratedDescriptor(_obj, "_handleLogsNoticeUpdate", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_handleLogsNoticeUpdate"), _obj)), _obj)));

  _exports.default = _default;
});