define("discourse/mixins/modal-update-existing-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    onShow: function onShow() {
      this.set("updateExistingUsers", null);
    },
    actions: {
      updateExistingUsers: function updateExistingUsers() {
        this.set("updateExistingUsers", true);
        this.send("closeModal");
      },
      cancel: function cancel() {
        this.set("updateExistingUsers", false);
        this.send("closeModal");
      }
    }
  });

  _exports.default = _default;
});