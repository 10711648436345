define("discourse/templates/components/categories-and-latest-topics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9xANn2SK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"column categories\"],[8],[0,\"\\n  \"],[1,[28,\"categories-only\",null,[[\"categories\"],[[24,[\"categories\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n  \"],[1,[28,\"categories-topic-list\",null,[[\"topics\",\"filter\",\"class\"],[[24,[\"topics\"]],\"latest\",\"latest-topic-list\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"connectorTagName\"],[\"extra-categories-column\",\"div\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/categories-and-latest-topics.hbs"
    }
  });

  _exports.default = _default;
});