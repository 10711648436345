define("discourse/controllers/change-owner", ["exports", "discourse/lib/url", "I18n", "discourse/mixins/modal-functionality", "discourse/models/topic", "discourse-common/utils/decorators"], function (_exports, _url, _I18n, _modalFunctionality, _topic, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("saving", "newOwner"), (_obj = {
    topicController: Ember.inject.controller("topic"),
    saving: false,
    newOwner: null,
    selectedPostsCount: Ember.computed.alias("topicController.selectedPostsCount"),
    selectedPostsUsername: Ember.computed.alias("topicController.selectedPostsUsername"),
    buttonDisabled: function buttonDisabled(saving, newUser) {
      return saving || Ember.isEmpty(newUser);
    },
    onShow: function onShow() {
      this.setProperties({
        saving: false,
        newOwner: null
      });
    },
    actions: {
      changeOwnershipOfPosts: function changeOwnershipOfPosts() {
        var _this = this;

        this.set("saving", true);
        var options = {
          post_ids: this.get("topicController.selectedPostIds"),
          username: this.newOwner
        };

        _topic.default.changeOwners(this.get("topicController.model.id"), options).then(function () {
          _this.send("closeModal");

          _this.topicController.send("deselectAll");

          if (_this.get("topicController.multiSelect")) {
            _this.topicController.send("toggleMultiSelect");
          }

          Ember.run.next(function () {
            return _url.default.routeTo(_this.get("topicController.model.url"));
          });
        }, function () {
          _this.flash(_I18n.default.t("topic.change_owner.error"), "error");

          _this.set("saving", false);
        });

        return false;
      },
      updateNewOwner: function updateNewOwner(selected) {
        this.set("newOwner", selected.firstObject);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "buttonDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "buttonDisabled"), _obj)), _obj)));

  _exports.default = _default;
});