define("discourse/components/scrolling-post-stream", ["exports", "discourse/widgets/post-stream", "discourse/lib/url", "discourse/components/mount-widget", "discourse-common/lib/debounce", "discourse/lib/safari-hacks", "discourse/lib/offset-calculator", "discourse-common/utils/decorators", "discourse-common/utils/dom-utils"], function (_exports, _postStream, _url, _mountWidget, _debounce, _safariHacks, _offsetCalculator, _decorators, _domUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var DEBOUNCE_DELAY = 50;

  function findTopView(posts, viewportTop, postsWrapperTop, min, max) {
    if (max < min) {
      return min;
    }

    while (max > min) {
      var mid = Math.floor((min + max) / 2);
      var post = posts.item(mid);
      var viewBottom = _domUtils.default.offset(post).top - postsWrapperTop + post.clientHeight;

      if (viewBottom > viewportTop) {
        max = mid - 1;
      } else {
        min = mid + 1;
      }
    }

    return min;
  }

  var _default = _mountWidget.default.extend((_obj = {
    screenTrack: Ember.inject.service(),
    widget: "post-stream",
    _topVisible: null,
    _bottomVisible: null,
    _currentPost: null,
    _currentVisible: null,
    _currentPercent: null,
    buildArgs: function buildArgs() {
      return this.getProperties("posts", "canCreatePost", "filteredPostsCount", "multiSelect", "gaps", "selectedQuery", "selectedPostsCount", "searchService", "showReadIndicator", "streamFilters", "lastReadPostNumber", "highestPostNumber");
    },
    beforePatch: function beforePatch() {
      this.prevHeight = document.body.clientHeight;
      this.prevScrollTop = document.body.scrollTop;
    },
    afterPatch: function afterPatch() {
      var height = document.body.clientHeight; // This hack is for when swapping out many cloaked views at once
      // when using keyboard navigation. It could suddenly move the scroll

      if (this.prevHeight === height && document.body.scrollTop !== this.prevScrollTop) {
        document.body.scroll({
          left: 0,
          top: this.prevScrollTop
        });
      }
    },
    scrolled: function scrolled() {
      var _this = this;

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if ((0, _safariHacks.isWorkaroundActive)() || document.webkitFullscreenElement || document.fullscreenElement) {
        return;
      } // We use this because watching videos fullscreen in Chrome was super buggy
      // otherwise. Thanks to arrendek from q23 for the technique.


      var topLeftCornerElement = document.elementFromPoint(0, 0);

      if (topLeftCornerElement && topLeftCornerElement.tagName.toUpperCase() === "IFRAME") {
        return;
      }

      var windowHeight = window.innerHeight;
      var slack = Math.round(windowHeight * 5);
      var onscreen = [];
      var nearby = [];
      var windowTop = document.documentElement.scrollTop;

      var postsWrapperTop = _domUtils.default.offset(document.querySelector(".posts-wrapper")).top;

      var postsNodes = this.element.querySelectorAll(".onscreen-post, .cloaked-post");
      var viewportTop = windowTop - slack;
      var topView = findTopView(postsNodes, viewportTop, postsWrapperTop, 0, postsNodes.length - 1);
      var windowBottom = windowTop + windowHeight;
      var viewportBottom = windowBottom + slack;
      var bodyHeight = document.body.clientHeight;

      if (windowBottom > bodyHeight) {
        windowBottom = bodyHeight;
      }

      if (viewportBottom > bodyHeight) {
        viewportBottom = bodyHeight;
      }

      var currentPost = null;
      var percent = null;
      var offset = (0, _offsetCalculator.default)();
      var topCheck = Math.ceil(windowTop + offset + 5); // uncomment to debug the eyeline

      /*
      let $eyeline = $('.debug-eyeline');
      if ($eyeline.length === 0) {
        $('body').prepend('<div class="debug-eyeline"></div>');
        $eyeline = $('.debug-eyeline');
      }
      $eyeline.css({ height: '5px', width: '100%', backgroundColor: 'blue', position: 'absolute', top: `${topCheck}px`, zIndex: 999999 });
      */

      var allAbove = true;
      var bottomView = topView;
      var lastBottom = 0;

      while (bottomView < postsNodes.length) {
        var post = postsNodes.item(bottomView);

        if (!post) {
          break;
        }

        var viewTop = _domUtils.default.offset(post).top;

        var postHeight = post.clientHeight;
        var viewBottom = Math.ceil(viewTop + postHeight);
        allAbove = allAbove && viewTop < topCheck;

        if (viewTop > viewportBottom) {
          break;
        }

        if (viewBottom >= windowTop && viewTop <= windowBottom) {
          onscreen.push(bottomView);
        }

        if (currentPost === null && (viewTop <= topCheck && viewBottom >= topCheck || lastBottom <= topCheck && viewTop >= topCheck)) {
          percent = (topCheck - viewTop) / postHeight;
          currentPost = bottomView;
        }

        lastBottom = viewBottom;
        nearby.push(bottomView);
        bottomView++;
      }

      if (allAbove) {
        if (percent === null) {
          percent = 1.0;
        }

        if (currentPost === null) {
          currentPost = bottomView - 1;
        }
      }

      var posts = this.posts;

      var refresh = function refresh(cb) {
        return _this.queueRerender(cb);
      };

      if (onscreen.length) {
        var first = posts.objectAt(onscreen[0]);

        if (this._topVisible !== first) {
          this._topVisible = first;
          var elem = postsNodes.item(onscreen[0]);
          var elemId = elem.id;

          var elemPos = _domUtils.default.position(elem);

          var distToElement = elemPos ? document.body.scrollTop - elemPos.top : 0;

          var topRefresh = function topRefresh() {
            refresh(function () {
              var refreshedElem = document.getElementById(elemId); // Quickly going back might mean the element is destroyed

              var position = _domUtils.default.position(refreshedElem);

              if (position && position.top) {
                var whereY = position.top + distToElement;
                document.documentElement.scroll({
                  top: whereY,
                  left: 0
                }); // This seems weird, but somewhat infrequently a rerender
                // will cause the browser to scroll to the top of the document
                // in Chrome. This makes sure the scroll works correctly if that
                // happens.

                Ember.run.schedule("afterRender", function () {
                  document.documentElement.scroll({
                    top: whereY,
                    left: 0
                  });
                });
              }
            });
          };

          this.topVisibleChanged({
            post: first,
            refresh: topRefresh
          });
        }

        var last = posts.objectAt(onscreen[onscreen.length - 1]);

        if (this._bottomVisible !== last) {
          this._bottomVisible = last;
          this.bottomVisibleChanged({
            post: last,
            refresh: refresh
          });
        }

        var changedPost = this._currentPost !== currentPost;

        if (changedPost) {
          this._currentPost = currentPost;

          var _post = posts.objectAt(currentPost);

          this.currentPostChanged({
            post: _post
          });
        }

        if (percent !== null) {
          percent = Math.max(0.0, Math.min(1.0, percent));

          if (changedPost || this._currentPercent !== percent) {
            this._currentPercent = percent;
            this.currentPostScrolled({
              percent: percent
            });
          }
        }
      } else {
        this._topVisible = null;
        this._bottomVisible = null;
        this._currentPost = null;
        this._currentPercent = null;
      }

      var onscreenPostNumbers = [];
      var readPostNumbers = [];
      var prev = this._previouslyNearby;
      var newPrev = {};
      nearby.forEach(function (idx) {
        var post = posts.objectAt(idx);
        var postNumber = post.post_number;
        delete prev[postNumber];

        if (onscreen.indexOf(idx) !== -1) {
          onscreenPostNumbers.push(postNumber);

          if (post.read) {
            readPostNumbers.push(postNumber);
          }
        }

        newPrev[postNumber] = post;
        (0, _postStream.uncloak)(post, _this);
      });
      Object.values(prev).forEach(function (node) {
        return (0, _postStream.cloak)(node, _this);
      });
      this._previouslyNearby = newPrev;
      this.screenTrack.setOnscreen(onscreenPostNumbers, readPostNumbers);
    },
    _scrollTriggered: function _scrollTriggered() {
      Ember.run.scheduleOnce("afterRender", this, this.scrolled);
    },
    _posted: function _posted(staged) {
      this.queueRerender(function () {
        if (staged) {
          var postNumber = staged.post_number;

          _url.default.jumpToPost(postNumber, {
            skipIfOnScreen: true
          });
        }
      });
    },
    _refresh: function _refresh(args) {
      if (args) {
        if (args.id) {
          this.dirtyKeys.keyDirty("post-".concat(args.id));

          if (args.refreshLikes) {
            this.dirtyKeys.keyDirty("post-menu-".concat(args.id), {
              onRefresh: "refreshLikes"
            });
          }

          if (args.refreshReaders) {
            this.dirtyKeys.keyDirty("post-menu-".concat(args.id), {
              onRefresh: "refreshReaders"
            });
          }
        } else if (args.force) {
          this.dirtyKeys.forceAll();
        }
      }

      this.queueRerender();
    },
    _debouncedScroll: function _debouncedScroll() {
      (0, _debounce.default)(this, this._scrollTriggered, DEBOUNCE_DELAY);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._previouslyNearby = {};
      this.appEvents.on("post-stream:refresh", this, "_debouncedScroll");
      var opts = {
        passive: true
      };
      document.addEventListener("touchmove", this._debouncedScroll, opts);
      window.addEventListener("scroll", this._debouncedScroll, opts);

      this._scrollTriggered();

      this.appEvents.on("post-stream:posted", this, "_posted");
      this.element.addEventListener("mouseenter", this._handleWidgetButtonHoverState, true);
      this.element.addEventListener("mouseleave", this._removeWidgetButtonHoverState, true);
      this.appEvents.on("post-stream:refresh", this, "_refresh"); // restore scroll position on browsers with aggressive BFCaches (like Safari)

      window.onpageshow = function (event) {
        if (event.persisted) {
          _url.default.routeTo(this.location.pathname);
        }
      };
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      document.removeEventListener("touchmove", this._debouncedScroll);
      window.removeEventListener("scroll", this._debouncedScroll);
      this.appEvents.off("post-stream:refresh", this, "_debouncedScroll");
      this.element.removeEventListener("mouseenter", this._handleWidgetButtonHoverState);
      this.element.removeEventListener("mouseleave", this._removeWidgetButtonHoverState);
      this.appEvents.off("post-stream:refresh", this, "_refresh");
      this.appEvents.off("post-stream:posted", this, "_posted");
    },
    _handleWidgetButtonHoverState: function _handleWidgetButtonHoverState(event) {
      if (event.target.classList.contains("widget-button")) {
        document.querySelectorAll("button.widget-button").forEach(function (widgetButton) {
          widgetButton.classList.remove("d-hover");
        });
        event.target.classList.add("d-hover");
      }
    },
    _removeWidgetButtonHoverState: function _removeWidgetButtonHoverState() {
      document.querySelectorAll("button.widget-button").forEach(function (button) {
        button.classList.remove("d-hover");
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "_debouncedScroll", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_debouncedScroll"), _obj)), _obj));

  _exports.default = _default;
});