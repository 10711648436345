define("discourse/widgets/topic-timeline", ["exports", "discourse/widgets/component-connector", "I18n", "discourse/widgets/raw-html", "discourse/widgets/widget", "discourse/widgets/post-small-action", "virtual-dom", "discourse-common/lib/icon-library", "discourse/lib/formatter", "discourse/lib/render-tags", "discourse/lib/render-topic-featured-link"], function (_exports, _componentConnector, _I18n, _rawHtml, _widget, _postSmallAction, _virtualDom, _iconLibrary, _formatter, _renderTags, _renderTopicFeaturedLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SCROLLER_HEIGHT = 50;
  var LAST_READ_HEIGHT = 20;
  var MIN_SCROLLAREA_HEIGHT = 170;
  var MAX_SCROLLAREA_HEIGHT = 300;

  function scrollareaHeight() {
    var composerHeight = document.getElementById("reply-control").offsetHeight || 0,
        headerHeight = document.querySelectorAll(".d-header")[0].offsetHeight || 0; // scrollarea takes up about half of the timeline's height

    var availableHeight = (window.innerHeight - composerHeight - headerHeight) / 2;
    return Math.max(MIN_SCROLLAREA_HEIGHT, Math.min(availableHeight, MAX_SCROLLAREA_HEIGHT));
  }

  function scrollareaRemaining() {
    return scrollareaHeight() - SCROLLER_HEIGHT;
  }

  function clamp(p) {
    var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.0;
    var max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1.0;
    return Math.max(Math.min(p, max), min);
  }

  function attachBackButton(widget) {
    return widget.attach("button", {
      className: "btn-primary btn-small back-button",
      label: "topic.timeline.back",
      title: "topic.timeline.back_description",
      action: "goBack"
    });
  }

  (0, _widget.createWidget)("timeline-last-read", {
    tagName: "div.timeline-last-read",
    buildAttributes: function buildAttributes(attrs) {
      var bottom = scrollareaHeight() - LAST_READ_HEIGHT / 2;
      var top = attrs.top > bottom ? bottom : attrs.top;
      return {
        style: "height: ".concat(LAST_READ_HEIGHT, "px; top: ").concat(top, "px")
      };
    },
    html: function html(attrs) {
      var result = [(0, _iconLibrary.iconNode)("minus", {
        class: "progress"
      })];

      if (attrs.showButton) {
        result.push(attachBackButton(this));
      }

      return result;
    }
  });

  function timelineDate(date) {
    var fmt = date.getFullYear() === new Date().getFullYear() ? "long_no_year_no_time" : "timeline_date";
    return moment(date).format(_I18n.default.t("dates.".concat(fmt)));
  }

  (0, _widget.createWidget)("timeline-scroller", {
    tagName: "div.timeline-scroller",
    buildKey: function buildKey(attrs) {
      return "timeline-scroller-".concat(attrs.topicId);
    },
    defaultState: function defaultState() {
      return {
        dragging: false
      };
    },
    buildAttributes: function buildAttributes() {
      return {
        style: "height: ".concat(SCROLLER_HEIGHT, "px")
      };
    },
    html: function html(attrs, state) {
      var current = attrs.current,
          total = attrs.total,
          date = attrs.date;
      var contents = [(0, _virtualDom.h)("div.timeline-replies", _I18n.default.t("topic.timeline.replies_short", {
        current: current,
        total: total
      }))];

      if (date) {
        contents.push((0, _virtualDom.h)("div.timeline-ago", timelineDate(date)));
      }

      if (attrs.showDockedButton && !state.dragging) {
        contents.push(attachBackButton(this));
      }

      var result = [(0, _virtualDom.h)("div.timeline-handle"), (0, _virtualDom.h)("div.timeline-scroller-content", contents)];

      if (attrs.fullScreen) {
        result = [result[1], result[0]];
      }

      return result;
    },
    drag: function drag(e) {
      this.state.dragging = true;
      this.sendWidgetAction("updatePercentage", e.pageY);
    },
    dragEnd: function dragEnd(e) {
      this.state.dragging = false;

      if ($(e.target).is("button")) {
        this.sendWidgetAction("goBack");
      } else {
        this.sendWidgetAction("commit");
      }
    }
  });
  (0, _widget.createWidget)("timeline-padding", {
    tagName: "div.timeline-padding",
    buildAttributes: function buildAttributes(attrs) {
      return {
        style: "height: ".concat(attrs.height, "px")
      };
    },
    click: function click(e) {
      this.sendWidgetAction("updatePercentage", e.pageY);
      this.sendWidgetAction("commit");
    }
  });
  (0, _widget.createWidget)("timeline-scrollarea", {
    tagName: "div.timeline-scrollarea",
    buildKey: function buildKey(attrs) {
      return "timeline-scrollarea-".concat(attrs.topic.id);
    },
    buildAttributes: function buildAttributes() {
      return {
        style: "height: ".concat(scrollareaHeight(), "px")
      };
    },
    defaultState: function defaultState(attrs) {
      return {
        percentage: this._percentFor(attrs.topic, attrs.enteredIndex + 1),
        scrolledPost: 1
      };
    },
    position: function position() {
      var attrs = this.attrs;
      var percentage = this.state.percentage;
      var topic = attrs.topic;
      var postStream = topic.get("postStream");
      var total = postStream.get("filteredPostsCount");
      var scrollPosition = clamp(Math.floor(total * percentage), 0, total) + 1;
      var current = clamp(scrollPosition, 1, total);
      var daysAgo = postStream.closestDaysAgoFor(current);
      var date;

      if (daysAgo === undefined) {
        var post = postStream.get("posts").findBy("id", postStream.get("stream")[current]);

        if (post) {
          date = new Date(post.get("created_at"));
        }
      } else if (daysAgo !== null) {
        date = new Date();
        date.setDate(date.getDate() - daysAgo || 0);
      } else {
        date = null;
      }

      var result = {
        current: current,
        scrollPosition: scrollPosition,
        total: total,
        date: date,
        lastRead: null,
        lastReadPercentage: null
      };
      var lastReadId = topic.last_read_post_id;
      var lastReadNumber = topic.last_read_post_number;

      if (lastReadId && lastReadNumber) {
        var idx = postStream.get("stream").indexOf(lastReadId) + 1;
        result.lastRead = idx;
        result.lastReadPercentage = this._percentFor(topic, idx);
      }

      if (this.state.position !== result.scrollPosition) {
        this.state.position = result.scrollPosition;
        this.sendWidgetAction("updatePosition", current);
      }

      return result;
    },
    html: function html(attrs, state) {
      var position = this.position();
      state.scrolledPost = position.current;
      var percentage = state.percentage;

      if (percentage === null) {
        return;
      }

      var before = scrollareaRemaining() * percentage;
      var after = scrollareaHeight() - before - SCROLLER_HEIGHT;
      var showButton = false;
      var hasBackPosition = position.lastRead && position.lastRead > 3 && position.lastRead > position.current && Math.abs(position.lastRead - position.current) > 3 && Math.abs(position.lastRead - position.total) > 1 && position.lastRead !== position.total;

      if (hasBackPosition) {
        var lastReadTop = Math.round(position.lastReadPercentage * scrollareaHeight());
        showButton = before + SCROLLER_HEIGHT - 5 < lastReadTop || before > lastReadTop + 25;
      }

      var scrollerAttrs = position;
      scrollerAttrs.showDockedButton = !attrs.mobileView && hasBackPosition && !showButton;
      scrollerAttrs.fullScreen = attrs.fullScreen;
      scrollerAttrs.topicId = attrs.topic.id;
      var result = [this.attach("timeline-padding", {
        height: before
      }), this.attach("timeline-scroller", scrollerAttrs), this.attach("timeline-padding", {
        height: after
      })];

      if (hasBackPosition) {
        var _lastReadTop = Math.round(position.lastReadPercentage * scrollareaHeight());

        result.push(this.attach("timeline-last-read", {
          top: _lastReadTop,
          lastRead: position.lastRead,
          showButton: showButton
        }));
      }

      return result;
    },
    updatePercentage: function updatePercentage(y) {
      var $area = $(".timeline-scrollarea");
      var areaTop = $area.offset().top;
      var percentage = clamp(parseFloat(y - areaTop) / $area.height());
      this.state.percentage = percentage;
    },
    commit: function commit() {
      var position = this.position();
      this.state.scrolledPost = position.current;

      if (position.current === position.scrollPosition) {
        this.sendWidgetAction("jumpToIndex", position.current);
      } else {
        this.sendWidgetAction("jumpEnd");
      }
    },
    topicCurrentPostScrolled: function topicCurrentPostScrolled(event) {
      this.state.percentage = event.percent;
    },
    _percentFor: function _percentFor(topic, postIndex) {
      var total = topic.get("postStream.filteredPostsCount");
      return clamp(parseFloat(postIndex - 1.0) / total);
    },
    goBack: function goBack() {
      this.sendWidgetAction("jumpToIndex", this.position().lastRead);
    }
  });
  (0, _widget.createWidget)("topic-timeline-container", {
    tagName: "div.timeline-container",
    buildClasses: function buildClasses(attrs) {
      if (attrs.fullScreen) {
        if (attrs.addShowClass) {
          return "timeline-fullscreen show";
        } else {
          return "timeline-fullscreen";
        }
      }

      if (attrs.dockAt) {
        var result = ["timeline-docked"];

        if (attrs.dockBottom) {
          result.push("timeline-docked-bottom");
        }

        return result.join(" ");
      }
    },
    html: function html(attrs) {
      return this.attach("topic-timeline", attrs);
    }
  });
  (0, _widget.createWidget)("timeline-controls", {
    tagName: "div.timeline-controls",
    html: function html(attrs) {
      var controls = [];
      var fullScreen = attrs.fullScreen,
          currentUser = attrs.currentUser,
          topic = attrs.topic;

      if (!fullScreen && currentUser) {
        controls.push(this.attach("topic-admin-menu-button", {
          topic: topic,
          addKeyboardTargetClass: true
        }));
      }

      return controls;
    }
  });
  (0, _widget.createWidget)("timeline-footer-controls", {
    tagName: "div.timeline-footer-controls",
    html: function html(attrs) {
      var controls = [];
      var currentUser = attrs.currentUser,
          fullScreen = attrs.fullScreen,
          topic = attrs.topic,
          notificationLevel = attrs.notificationLevel;

      if (this.siteSettings.summary_timeline_button && !fullScreen && topic.has_summary && !topic.postStream.summary) {
        controls.push(this.attach("button", {
          className: "show-summary btn-small",
          icon: "layer-group",
          label: "summary.short_label",
          title: "summary.short_title",
          action: "showSummary"
        }));
      }

      if (currentUser && !fullScreen) {
        if (topic.get("details.can_create_post")) {
          controls.push(this.attach("button", {
            className: "btn-default create reply-to-post",
            icon: "reply",
            title: "topic.reply.help",
            action: "replyToPost"
          }));
        }
      }

      if (fullScreen) {
        controls.push(this.attach("button", {
          className: "jump-to-post",
          title: "topic.progress.jump_prompt_long",
          label: "topic.progress.jump_prompt",
          action: "jumpToPostPrompt"
        }));
      }

      if (currentUser) {
        controls.push(new _componentConnector.default(this, "topic-notifications-button", {
          notificationLevel: notificationLevel,
          topic: topic,
          showFullTitle: false,
          appendReason: false,
          placement: "bottom-end",
          mountedAsWidget: true,
          showCaret: false
        }, ["notificationLevel"]));

        if (this.site.mobileView) {
          controls.push(this.attach("topic-admin-menu-button", {
            topic: topic,
            addKeyboardTargetClass: true,
            openUpwards: true
          }));
        }
      }

      return controls;
    }
  });

  var _default = (0, _widget.createWidget)("topic-timeline", {
    tagName: "div.topic-timeline",
    buildKey: function buildKey(attrs) {
      return "topic-timeline-area-".concat(attrs.topic.id);
    },
    defaultState: function defaultState() {
      return {
        position: null,
        excerpt: null
      };
    },
    updatePosition: function updatePosition(scrollPosition) {
      var _this = this;

      if (!this.attrs.fullScreen) {
        return;
      }

      this.state.position = scrollPosition;
      this.state.excerpt = "";
      var stream = this.attrs.topic.get("postStream"); // a little debounce to avoid flashing

      Ember.run.later(function () {
        if (!_this.state.position === scrollPosition) {
          return;
        } // we have an off by one, stream is zero based,


        stream.excerpt(scrollPosition - 1).then(function (info) {
          if (info && _this.state.position === scrollPosition) {
            var excerpt = "";

            if (info.username) {
              excerpt = "<span class='username'>" + info.username + ":</span> ";
            }

            if (info.excerpt) {
              _this.state.excerpt = excerpt + info.excerpt;
            } else if (info.action_code) {
              _this.state.excerpt = "".concat(excerpt, " ").concat((0, _postSmallAction.actionDescriptionHtml)(info.action_code, info.created_at, info.username));
            }

            _this.scheduleRerender();
          }
        });
      }, 50);
    },
    html: function html(attrs) {
      var topic = attrs.topic;
      var createdAt = new Date(topic.created_at);
      var currentUser = this.currentUser;
      var _this$siteSettings = this.siteSettings,
          tagging_enabled = _this$siteSettings.tagging_enabled,
          topic_featured_link_enabled = _this$siteSettings.topic_featured_link_enabled;
      attrs["currentUser"] = currentUser;
      var result = [];

      if (attrs.fullScreen) {
        var titleHTML = "";

        if (attrs.mobileView) {
          titleHTML = new _rawHtml.default({
            html: "<span>".concat(topic.get("fancyTitle"), "</span>")
          });
        }

        var elems = [(0, _virtualDom.h)("h2", this.attach("link", {
          contents: function contents() {
            return titleHTML;
          },
          className: "fancy-title",
          action: "jumpTop"
        }))]; // duplicate of the {{topic-category}} component

        var category = [];

        if (!topic.get("isPrivateMessage")) {
          if (topic.category.parentCategory) {
            category.push(this.attach("category-link", {
              category: topic.category.parentCategory
            }));
          }

          category.push(this.attach("category-link", {
            category: topic.category
          }));
        }

        var showTags = tagging_enabled && topic.tags && topic.tags.length > 0;

        if (showTags || topic_featured_link_enabled) {
          var extras = [];

          if (showTags) {
            var tagsHtml = new _rawHtml.default({
              html: (0, _renderTags.default)(topic, {
                mode: "list"
              })
            });
            extras.push((0, _virtualDom.h)("div.list-tags", tagsHtml));
          }

          if (topic_featured_link_enabled) {
            extras.push(new _rawHtml.default({
              html: (0, _renderTopicFeaturedLink.default)(topic)
            }));
          }

          category.push((0, _virtualDom.h)("div.topic-header-extra", extras));
        }

        if (category.length > 0) {
          elems.push((0, _virtualDom.h)("div.topic-category", category));
        }

        if (this.state.excerpt) {
          elems.push(new _rawHtml.default({
            html: "<div class='post-excerpt'>".concat(this.state.excerpt, "</div>")
          }));
        }

        result.push((0, _virtualDom.h)("div.title", elems));
      }

      result.push(this.attach("timeline-controls", attrs));
      var displayTimeLineScrollArea = true;

      if (!attrs.mobileView) {
        var streamLength = attrs.topic.get("postStream.stream.length");

        if (streamLength === 1) {
          var postsWrapper = document.querySelector(".posts-wrapper");

          if (postsWrapper && postsWrapper.offsetHeight < 1000) {
            displayTimeLineScrollArea = false;
          }
        }
      }

      if (displayTimeLineScrollArea) {
        var bottomAge = (0, _formatter.relativeAge)(new Date(topic.last_posted_at || topic.created_at), {
          addAgo: true,
          defaultFormat: timelineDate
        });
        var scroller = [(0, _virtualDom.h)("div.timeline-date-wrapper", this.attach("link", {
          className: "start-date",
          rawLabel: timelineDate(createdAt),
          action: "jumpTop"
        })), this.attach("timeline-scrollarea", attrs), (0, _virtualDom.h)("div.timeline-date-wrapper", this.attach("link", {
          className: "now-date",
          rawLabel: bottomAge,
          action: "jumpBottom"
        }))];
        result.push((0, _virtualDom.h)("div.timeline-scrollarea-wrapper", scroller));
        result.push(this.attach("timeline-footer-controls", attrs));
      }

      return result;
    }
  });

  _exports.default = _default;
});