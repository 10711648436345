define("discourse/templates/components/second-factor-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6/cctYJm",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"text-field\",null,[[\"value\",\"type\",\"pattern\",\"maxlength\",\"class\",\"id\",\"autocapitalize\",\"autocomplete\",\"autocorrect\",\"autofocus\",\"placeholder\"],[[24,[\"value\"]],[24,[\"type\"]],[24,[\"pattern\"]],[24,[\"maxlength\"]],\"second-factor-token-input\",[24,[\"inputId\"]],\"off\",\"one-time-code\",\"off\",\"autofocus\",[24,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/second-factor-input.hbs"
    }
  });

  _exports.default = _default;
});