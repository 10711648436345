define("discourse/routes/discovery-categories", ["exports", "discourse/models/category-list", "discourse/routes/discourse", "I18n", "discourse/mixins/open-composer", "discourse/lib/preload-store", "discourse/models/site", "discourse/models/topic-list", "discourse/lib/ajax", "discourse/lib/utilities", "discourse/lib/show-modal", "discourse-common/lib/get-url", "discourse/models/session"], function (_exports, _categoryList, _discourse, _I18n, _openComposer, _preloadStore, _site, _topicList, _ajax, _utilities, _showModal, _getUrl, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var DiscoveryCategoriesRoute = _discourse.default.extend(_openComposer.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_obj = {
    renderTemplate: function renderTemplate() {
      this.render("navigation/categories", {
        outlet: "navigation-bar"
      });
      this.render("discovery/categories", {
        outlet: "list-container"
      });
    },
    findCategories: function findCategories() {
      var style = !this.site.mobileView && this.siteSettings.desktop_category_page_style;

      if (style === "categories_and_latest_topics") {
        return this._findCategoriesAndTopics("latest");
      } else if (style === "categories_and_top_topics") {
        return this._findCategoriesAndTopics("top");
      }

      return _categoryList.default.list(this.store);
    },
    model: function model() {
      var _this = this;

      return this.findCategories().then(function (model) {
        var tracking = _this.topicTrackingState;

        if (tracking) {
          tracking.sync(model, "categories");
          tracking.trackIncoming("categories");
        }

        return model;
      });
    },
    _loadBefore: function _loadBefore(store) {
      return function (topic_ids, storeInSession) {
        var _this2 = this;

        // refresh dupes
        this.topics.removeObjects(this.topics.filter(function (topic) {
          return topic_ids.indexOf(topic.id) >= 0;
        }));
        var url = "".concat((0, _getUrl.default)("/"), "latest.json?topic_ids=").concat(topic_ids.join(","));
        return (0, _ajax.ajax)({
          url: url,
          data: this.params
        }).then(function (result) {
          var topicIds = new Set();

          _this2.topics.forEach(function (topic) {
            return topicIds.add(topic.id);
          });

          var i = 0;

          _topicList.default.topicsFrom(store, result).forEach(function (topic) {
            if (!topicIds.has(topic.id)) {
              topic.set("highlight", true);

              _this2.topics.insertAt(i, topic);

              i++;
            }
          });

          if (storeInSession) {
            _session.default.currentProp("topicList", _this2);
          }
        });
      };
    },
    _findCategoriesAndTopics: function _findCategoriesAndTopics(filter) {
      var _this3 = this;

      return Ember.RSVP.hash({
        wrappedCategoriesList: _preloadStore.default.getAndRemove("categories_list"),
        topicsList: _preloadStore.default.getAndRemove("topic_list_".concat(filter))
      }).then(function (response) {
        var wrappedCategoriesList = response.wrappedCategoriesList,
            topicsList = response.topicsList;
        var categoriesList = wrappedCategoriesList && wrappedCategoriesList.category_list;
        var store = _this3.store;

        if (categoriesList && topicsList) {
          if (topicsList.topic_list && topicsList.topic_list.top_tags) {
            _site.default.currentProp("top_tags", topicsList.topic_list.top_tags);
          }

          return Ember.Object.create({
            categories: _categoryList.default.categoriesFrom(_this3.store, wrappedCategoriesList),
            topics: _topicList.default.topicsFrom(_this3.store, topicsList),
            can_create_category: categoriesList.can_create_category,
            can_create_topic: categoriesList.can_create_topic,
            loadBefore: _this3._loadBefore(store)
          });
        } // Otherwise, return the ajax result


        return (0, _ajax.ajax)("/categories_and_".concat(filter)).then(function (result) {
          if (result.topic_list && result.topic_list.top_tags) {
            _site.default.currentProp("top_tags", result.topic_list.top_tags);
          }

          return Ember.Object.create({
            categories: _categoryList.default.categoriesFrom(_this3.store, result),
            topics: _topicList.default.topicsFrom(_this3.store, result),
            can_create_category: result.category_list.can_create_category,
            can_create_topic: result.category_list.can_create_topic,
            loadBefore: _this3._loadBefore(store)
          });
        });
      });
    },
    titleToken: function titleToken() {
      if ((0, _utilities.defaultHomepage)() === "categories") {
        return;
      }

      return _I18n.default.t("filters.categories.title");
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model);
      this.controllerFor("navigation/categories").setProperties({
        showCategoryAdmin: model.get("can_create_category"),
        canCreateTopic: model.get("can_create_topic")
      });
    },
    triggerRefresh: function triggerRefresh() {
      this.refresh();
    },
    createCategory: function createCategory() {
      this.transitionTo("newCategory");
    },
    reorderCategories: function reorderCategories() {
      (0, _showModal.default)("reorderCategories");
    },
    createTopic: function createTopic() {
      if (this.get("currentUser.has_topic_draft")) {
        this.openTopicDraft();
      } else {
        this.openComposer(this.controllerFor("discovery/categories"));
      }
    },
    didTransition: function didTransition() {
      var _this4 = this;

      Ember.run.next(function () {
        return _this4.controllerFor("application").set("showFooter", true);
      });
      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, "triggerRefresh", [_dec], Object.getOwnPropertyDescriptor(_obj, "triggerRefresh"), _obj), _applyDecoratedDescriptor(_obj, "createCategory", [_dec2], Object.getOwnPropertyDescriptor(_obj, "createCategory"), _obj), _applyDecoratedDescriptor(_obj, "reorderCategories", [_dec3], Object.getOwnPropertyDescriptor(_obj, "reorderCategories"), _obj), _applyDecoratedDescriptor(_obj, "createTopic", [_dec4], Object.getOwnPropertyDescriptor(_obj, "createTopic"), _obj), _applyDecoratedDescriptor(_obj, "didTransition", [_dec5], Object.getOwnPropertyDescriptor(_obj, "didTransition"), _obj)), _obj)));

  var _default = DiscoveryCategoriesRoute;
  _exports.default = _default;
});