define("discourse/templates/modal/not-activated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MG+FotJh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,null,{\"statements\":[[0,\"  \"],[1,[28,\"html-safe\",[[28,\"i18n\",[\"login.not_activated\"],[[\"sentTo\"],[[24,[\"sentTo\"]]]]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"activation-controls\",null,[[\"sendActivationEmail\",\"editActivationEmail\"],[[28,\"action\",[[23,0,[]],\"sendActivationEmail\"],null],[28,\"action\",[[23,0,[]],\"editActivationEmail\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/not-activated.hbs"
    }
  });

  _exports.default = _default;
});