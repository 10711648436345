define("discourse/lib/category-tag-search", ["exports", "discourse/lib/autocomplete", "discourse/models/category", "discourse/lib/category-hashtags", "discourse/lib/tag-hashtags", "discourse-common/lib/debounce", "discourse-common/lib/get-url", "discourse-common/config/environment"], function (_exports, _autocomplete, _category, _categoryHashtags, _tagHashtags, _debounce, _getUrl, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.search = search;
  var cache = {};
  var cacheTime;
  var oldSearch;

  function updateCache(term, results) {
    cache[term] = results;
    cacheTime = new Date();
    return results;
  }

  function searchTags(term, categories, limit) {
    var _this = this;

    return new Ember.RSVP.Promise(function (resolve) {
      var clearPromise = (0, _environment.isTesting)() ? null : Ember.run.later(function () {
        resolve(_autocomplete.CANCELLED_STATUS);
      }, 5000);

      var debouncedSearch = function debouncedSearch(q, cats, resultFunc) {
        (0, _debounce.default)(_this, function () {
          oldSearch = $.ajax((0, _getUrl.default)("/tags/filter/search"), {
            type: "GET",
            data: {
              limit: limit,
              q: q
            }
          });
          var returnVal = _autocomplete.CANCELLED_STATUS;
          oldSearch.then(function (r) {
            var categoryNames = cats.map(function (c) {
              return c.model.get("name");
            });
            var tags = r.results.map(function (tag) {
              tag.text = categoryNames.includes(tag.text) ? "".concat(tag.text).concat(_tagHashtags.TAG_HASHTAG_POSTFIX) : tag.text;
              return tag;
            });
            returnVal = cats.concat(tags);
          }).always(function () {
            oldSearch = null;
            resultFunc(returnVal);
          });
        }, q, cats, resultFunc, 300);
      };

      debouncedSearch(term, categories, function (result) {
        Ember.run.cancel(clearPromise);
        resolve(updateCache(term, result));
      });
    });
  }

  function search(term, siteSettings) {
    if (oldSearch) {
      oldSearch.abort();
      oldSearch = null;
    }

    if (new Date() - cacheTime > 30000) {
      cache = {};
    }

    var cached = cache[term];

    if (cached) {
      return cached;
    }

    var limit = 5;

    var categories = _category.default.search(term, {
      limit: limit
    });

    var numOfCategories = categories.length;
    categories = categories.map(function (category) {
      return {
        model: category,
        text: _category.default.slugFor(category, _categoryHashtags.SEPARATOR, 2)
      };
    });

    if (numOfCategories !== limit && siteSettings.tagging_enabled) {
      return searchTags(term, categories, limit - numOfCategories);
    } else {
      return updateCache(term, categories);
    }
  }
});