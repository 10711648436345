define("discourse/lib/user-presence", ["exports", "discourse-common/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = userPresent;
  _exports.onPresenceChange = onPresenceChange;
  _exports.removeOnPresenceChange = removeOnPresenceChange;
  _exports.seenUser = seenUser;
  _exports.visibilityChanged = visibilityChanged;
  _exports.setTestPresence = setTestPresence;
  _exports.clearPresenceCallbacks = clearPresenceCallbacks;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var callbacks = [];
  var DEFAULT_USER_UNSEEN_MS = 60000;
  var DEFAULT_BROWSER_HIDDEN_MS = 0;
  var browserHiddenAt = null;
  var lastUserActivity = Date.now();
  var userSeenJustNow = false;
  var callbackWaitingForPresence = false;
  var testPresence = true; // Check whether the document is currently visible, and the user is actively using the site
  // Will return false if the browser went into the background more than `browserHiddenTime` milliseconds ago
  // Will also return false if there has been no user activty for more than `userUnseenTime` milliseconds
  // Otherwise, will return true

  function userPresent() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$browserHiddenTim = _ref.browserHiddenTime,
        browserHiddenTime = _ref$browserHiddenTim === void 0 ? DEFAULT_BROWSER_HIDDEN_MS : _ref$browserHiddenTim,
        _ref$userUnseenTime = _ref.userUnseenTime,
        userUnseenTime = _ref$userUnseenTime === void 0 ? DEFAULT_USER_UNSEEN_MS : _ref$userUnseenTime;

    if ((0, _environment.isTesting)()) {
      return testPresence;
    }

    if (browserHiddenAt) {
      var timeSinceBrowserHidden = Date.now() - browserHiddenAt;

      if (timeSinceBrowserHidden >= browserHiddenTime) {
        return false;
      }
    }

    var timeSinceUserActivity = Date.now() - lastUserActivity;

    if (timeSinceUserActivity >= userUnseenTime) {
      return false;
    }

    return true;
  } // Register a callback to be triggered when the value of `userPresent()` changes.
  // userUnseenTime and browserHiddenTime work the same as for `userPresent()`
  // 'not present' callbacks may lag by up to 10s, depending on the reason
  // 'now present' callbacks should be almost instantaneous


  function onPresenceChange() {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref2$userUnseenTime = _ref2.userUnseenTime,
        userUnseenTime = _ref2$userUnseenTime === void 0 ? DEFAULT_USER_UNSEEN_MS : _ref2$userUnseenTime,
        _ref2$browserHiddenTi = _ref2.browserHiddenTime,
        browserHiddenTime = _ref2$browserHiddenTi === void 0 ? DEFAULT_BROWSER_HIDDEN_MS : _ref2$browserHiddenTi,
        callback = _ref2.callback;

    if (userUnseenTime < DEFAULT_USER_UNSEEN_MS) {
      throw "userUnseenTime must be at least ".concat(DEFAULT_USER_UNSEEN_MS);
    }

    callbacks.push({
      userUnseenTime: userUnseenTime,
      browserHiddenTime: browserHiddenTime,
      lastState: true,
      callback: callback
    });
  }

  function removeOnPresenceChange(callback) {
    var i = callbacks.findIndex(function (c) {
      return c.callback === callback;
    });
    callbacks.splice(i, 1);
  }

  function processChanges() {
    var browserHidden = document.hidden;

    if (!!browserHiddenAt !== browserHidden) {
      browserHiddenAt = browserHidden ? Date.now() : null;
    }

    if (userSeenJustNow) {
      lastUserActivity = Date.now();
      userSeenJustNow = false;
    }

    callbackWaitingForPresence = false;

    var _iterator = _createForOfIteratorHelper(callbacks),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var callback = _step.value;
        var currentState = userPresent({
          userUnseenTime: callback.userUnseenTime,
          browserHiddenTime: callback.browserHiddenTime
        });

        if (callback.lastState !== currentState) {
          try {
            callback.callback(currentState);
          } finally {
            callback.lastState = currentState;
          }
        }

        if (!currentState) {
          callbackWaitingForPresence = true;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }

  function seenUser() {
    userSeenJustNow = true;

    if (callbackWaitingForPresence) {
      processChanges();
    }
  }

  function visibilityChanged() {
    if (document.hidden) {
      processChanges();
    } else {
      seenUser();
    }
  }

  function setTestPresence(value) {
    if (!(0, _environment.isTesting)()) {
      throw "Only available in test mode";
    }

    testPresence = value;
  }

  function clearPresenceCallbacks() {
    callbacks.splice(0, callbacks.length);
  }

  if (!(0, _environment.isTesting)()) {
    // Some of these events occur very frequently. Therefore seenUser() is as fast as possible.
    document.addEventListener("touchmove", seenUser, {
      passive: true
    });
    document.addEventListener("click", seenUser, {
      passive: true
    });
    window.addEventListener("scroll", seenUser, {
      passive: true
    });
    window.addEventListener("focus", seenUser, {
      passive: true
    });
    document.addEventListener("visibilitychange", visibilityChanged, {
      passive: true
    });
    setInterval(processChanges, 10000);
  }
});