define("discourse/templates/components/software-update-prompt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fTmjQXRQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showPrompt\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"software-update-prompt\",[28,\"if\",[[24,[\"animatePrompt\"]],\" require-software-refresh\"],null]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"wrap\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"update-prompt-main-content\"],[10,\"aria-live\",\"polite\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"refreshPage\"],null]],[10,\"class\",\"update-prompt-message\"],[8],[1,[28,\"d-icon\",[\"redo\"],null],false],[0,\" \"],[1,[28,\"html-safe\",[[28,\"i18n\",[\"software_update_prompt.message\"],null]],null],false],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"update-prompt-dismiss\"],[8],[7,\"span\",true],[11,\"aria-label\",[28,\"i18n\",[\"software_update_prompt.dismiss\"],null]],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"dismiss\"],null]],[8],[1,[28,\"d-icon\",[\"times\"],null],false],[9],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/software-update-prompt.hbs"
    }
  });

  _exports.default = _default;
});