define("discourse/lib/color-scheme-picker", ["exports", "discourse/lib/cookie", "I18n", "discourse/models/session", "discourse/lib/ajax"], function (_exports, _cookie, _I18n, _session, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.listColorSchemes = listColorSchemes;
  _exports.loadColorSchemeStylesheet = loadColorSchemeStylesheet;
  _exports.updateColorSchemeCookie = updateColorSchemeCookie;

  function listColorSchemes(site) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var schemes = site.get("user_color_schemes");

    if (!schemes || !Array.isArray(schemes)) {
      return null;
    }

    var results = [];

    if (!options.darkOnly) {
      schemes = schemes.sort(function (a, b) {
        return Number(a.is_dark) - Number(b.is_dark);
      });
    }

    schemes.forEach(function (s) {
      if (options.darkOnly && s.is_dark || !options.darkOnly) {
        results.push({
          name: s.name,
          id: s.id
        });
      }
    });

    if (options.darkOnly) {
      var defaultDarkColorScheme = site.get("default_dark_color_scheme");

      if (defaultDarkColorScheme) {
        var existing = schemes.findBy("id", defaultDarkColorScheme.id);

        if (!existing) {
          results.unshift({
            id: defaultDarkColorScheme.id,
            name: "".concat(defaultDarkColorScheme.name, " ").concat(_I18n.default.t("user.color_schemes.default_dark_scheme"))
          });
        }
      }

      results.unshift({
        id: -1,
        name: _I18n.default.t("user.color_schemes.disable_dark_scheme")
      });
    }

    return results.length === 0 ? null : results;
  }

  function loadColorSchemeStylesheet(colorSchemeId, theme_id) {
    var darkMode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var themeId = theme_id ? "/".concat(theme_id) : "";
    (0, _ajax.ajax)("/color-scheme-stylesheet/".concat(colorSchemeId).concat(themeId, ".json")).then(function (result) {
      if (result && result.new_href) {
        var elementId = darkMode ? "cs-preview-dark" : "cs-preview-light";
        var existingElement = document.querySelector("link#".concat(elementId));

        if (existingElement) {
          existingElement.href = result.new_href;
        } else {
          var link = document.createElement("link");
          link.href = result.new_href;
          link.media = darkMode ? "(prefers-color-scheme: dark)" : "(prefers-color-scheme: light)";
          link.rel = "stylesheet";
          link.id = elementId;
          document.body.appendChild(link);
        }

        if (!darkMode) {
          Ember.run.later(function () {
            var schemeType = getComputedStyle(document.body).getPropertyValue("--scheme-type");

            _session.default.currentProp("defaultColorSchemeIsDark", schemeType.trim() === "dark");
          }, 500);
        }
      }
    });
  }

  function updateColorSchemeCookie(id) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var cookieName = options.dark ? "dark_scheme_id" : "color_scheme_id";

    if (id) {
      (0, _cookie.default)(cookieName, id, {
        path: "/",
        expires: 9999
      });
    } else {
      (0, _cookie.removeCookie)(cookieName, {
        path: "/",
        expires: 1
      });
    }
  }
});