define("discourse/routes/new-message", ["exports", "discourse/routes/discourse", "discourse/models/group", "I18n", "bootbox", "discourse/lib/cookie"], function (_exports, _discourse, _group, _I18n, _bootbox, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    beforeModel: function beforeModel(transition) {
      var params = transition.to.queryParams;
      var groupName = params.groupname || params.group_name;

      if (this.currentUser) {
        this.replaceWith("discovery.latest").then(function (e) {
          if (params.username) {
            e.send("createNewMessageViaParams", {
              recipients: params.username,
              topicTitle: params.title,
              topicBody: params.body
            });
          } else if (groupName) {
            // send a message to a group
            _group.default.messageable(groupName).then(function (result) {
              if (result.messageable) {
                Ember.run.next(function () {
                  return e.send("createNewMessageViaParams", {
                    recipients: groupName,
                    topicTitle: params.title,
                    topicBody: params.body
                  });
                });
              } else {
                _bootbox.default.alert(_I18n.default.t("composer.cant_send_pm", {
                  username: groupName
                }));
              }
            }).catch(function () {
              return _bootbox.default.alert(_I18n.default.t("generic_error"));
            });
          } else {
            e.send("createNewMessageViaParams", {
              topicTitle: params.title,
              topicBody: params.body
            });
          }
        });
      } else {
        (0, _cookie.default)("destination_url", window.location.href);
        this.replaceWith("login");
      }
    }
  });

  _exports.default = _default;
});