define("discourse/templates/components/json-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JyrNjgrs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"rawTitle\"],[[28,\"i18n\",[\"admin.site_settings.json_schema.modal_title\"],[[\"name\"],[[24,[\"settingName\"]]]]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"id\",\"json-editor-holder\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"class\",\"action\",\"label\"],[\"btn-primary\",[28,\"action\",[[23,0,[]],\"saveChanges\"],null],\"save\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/json-editor.hbs"
    }
  });

  _exports.default = _default;
});