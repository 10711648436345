define("discourse/templates/components/user-summary-category-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PzvWJGk1",
    "block": "{\"symbols\":[\"@count\"],\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[4,\"link-to\",null,[[\"query\",\"route\"],[[28,\"hash\",null,[[\"q\"],[[23,0,[\"searchParams\"]]]]],\"full-page-search\"]],{\"statements\":[[0,\"    \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  –\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/user-summary-category-search.hbs"
    }
  });

  _exports.default = _default;
});