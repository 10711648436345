define("discourse/widgets/quick-access-bookmarks", ["discourse/widgets/raw-html", "discourse-common/lib/icon-library", "discourse/widgets/quick-access-panel", "discourse/lib/ajax", "discourse/widgets/widget", "virtual-dom", "discourse/lib/utilities", "I18n"], function (_rawHtml, _iconLibrary, _quickAccessPanel, _ajax, _widget, _virtualDom, _utilities, _I18n) {
  "use strict";

  var ICON = "bookmark";
  (0, _widget.createWidget)("no-quick-access-bookmarks", {
    html: function html() {
      return (0, _virtualDom.h)("div.empty-state", [(0, _virtualDom.h)("span.empty-state-title", _I18n.default.t("user.no_bookmarks_title")), (0, _virtualDom.h)("div.empty-state-body", new _rawHtml.default({
        html: "<p>" + _I18n.default.t("user.no_bookmarks_body", {
          icon: (0, _iconLibrary.iconHTML)(ICON)
        }).htmlSafe() + "</p>"
      }))]);
    }
  });
  (0, _widget.createWidgetFrom)(_quickAccessPanel.default, "quick-access-bookmarks", {
    buildKey: function buildKey() {
      return "quick-access-bookmarks";
    },
    emptyStateWidget: "no-quick-access-bookmarks",
    showAllHref: function showAllHref() {
      return "".concat(this.attrs.path, "/activity/bookmarks");
    },
    findNewItems: function findNewItems() {
      return this.loadBookmarksWithReminders();
    },
    itemHtml: function itemHtml(bookmark) {
      // for topic level bookmarks we want to jump to the last unread post
      // instead of the OP
      var postNumber;

      if (bookmark.for_topic) {
        postNumber = bookmark.last_read_post_number + 1;
      } else {
        postNumber = bookmark.linked_post_number;
      }

      return this.attach("quick-access-item", {
        icon: this.icon(bookmark),
        href: (0, _utilities.postUrl)(bookmark.slug, bookmark.topic_id, postNumber),
        title: bookmark.name,
        content: bookmark.title,
        username: bookmark.post_user_username
      });
    },
    icon: function icon(bookmark) {
      if (bookmark.reminder_at) {
        return "discourse-bookmark-clock";
      }

      return ICON;
    },
    loadBookmarksWithReminders: function loadBookmarksWithReminders() {
      return (0, _ajax.ajax)("/u/".concat(this.currentUser.username, "/bookmarks.json")).then(function (_ref) {
        var user_bookmark_list = _ref.user_bookmark_list;
        return user_bookmark_list.bookmarks;
      });
    }
  });
});