define("discourse/lib/render-tag", ["exports", "discourse/models/user", "discourse/lib/utilities", "discourse-common/lib/get-url", "discourse-common/lib/helpers"], function (_exports, _user, _utilities, _getUrl, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.replaceTagRenderer = replaceTagRenderer;
  _exports.defaultRenderTag = defaultRenderTag;
  _exports.default = renderTag;
  var _renderer = defaultRenderTag;

  function replaceTagRenderer(fn) {
    _renderer = fn;
  }

  function defaultRenderTag(tag, params) {
    // This file is in lib but it's used as a helper
    var siteSettings = (0, _helpers.helperContext)().siteSettings;
    params = params || {};
    var visibleName = (0, _utilities.escapeExpression)(tag);
    tag = visibleName.toLowerCase();
    var classes = ["discourse-tag"];
    var tagName = params.tagName || "a";
    var path;

    if (tagName === "a" && !params.noHref) {
      if ((params.isPrivateMessage || params.pmOnly) && _user.default.current()) {
        var username = params.tagsForUser ? params.tagsForUser : _user.default.current().username;
        path = "/u/".concat(username, "/messages/tags/").concat(tag);
      } else {
        path = "/tag/".concat(tag);
      }
    }

    var href = path ? " href='".concat((0, _getUrl.default)(path), "' ") : "";

    if (siteSettings.tag_style || params.style) {
      classes.push(params.style || siteSettings.tag_style);
    }

    if (params.size) {
      classes.push(params.size);
    }

    var val = "<" + tagName + href + " data-tag-name=" + tag + (params.description ? ' title="' + params.description + '" ' : "") + " class='" + classes.join(" ") + "'>" + visibleName + "</" + tagName + ">";

    if (params.count) {
      val += " <span class='discourse-tag-count'>x" + params.count + "</span>";
    }

    return val;
  }

  function renderTag(tag, params) {
    return _renderer(tag, params);
  }
});