define("discourse/widgets/topic-status", ["exports", "I18n", "discourse/helpers/topic-status-icons", "discourse/widgets/widget", "discourse/lib/utilities", "virtual-dom", "discourse-common/lib/icon-library"], function (_exports, _I18n, _topicStatusIcons, _widget, _utilities, _virtualDom, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _widget.createWidget)("topic-status", {
    tagName: "span.topic-statuses",
    html: function html(attrs) {
      var topic = attrs.topic;
      var canAct = this.currentUser && !attrs.disableActions;
      var result = [];

      _topicStatusIcons.default.render(topic, function (name, key) {
        var iconArgs = key === "unpinned" ? {
          class: "unpinned"
        } : null;
        var icon = (0, _iconLibrary.iconNode)(name, iconArgs);
        var attributes = {
          title: (0, _utilities.escapeExpression)(_I18n.default.t("topic_statuses.".concat(key, ".help")))
        };
        var klass = "topic-status";

        if (key === "unpinned" || key === "pinned") {
          klass += ".pin-toggle-button.".concat(key);
        }

        result.push((0, _virtualDom.h)("".concat(canAct ? "a" : "span", ".").concat(klass), attributes, icon));
      });

      return result;
    },
    click: function click(e) {
      var _parent$querySelector;

      var parent = e.target.closest(".topic-statuses");

      if (parent !== null && parent !== void 0 && (_parent$querySelector = parent.querySelector(".pin-toggle-button")) !== null && _parent$querySelector !== void 0 && _parent$querySelector.contains(e.target)) {
        this.attrs.topic.togglePinnedForUser();
      }
    }
  });

  _exports.default = _default;
});