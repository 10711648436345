define("discourse/controllers/not-activated", ["exports", "discourse/mixins/modal-functionality", "discourse/lib/user-activation"], function (_exports, _modalFunctionality, _userActivation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    actions: {
      sendActivationEmail: function sendActivationEmail() {
        var _this = this;

        (0, _userActivation.resendActivationEmail)(this.username).then(function () {
          var modal = _this.showModal("activation-resent", {
            title: "log_in"
          });

          modal.set("currentEmail", _this.currentEmail);
        });
      },
      editActivationEmail: function editActivationEmail() {
        var modal = this.showModal("activation-edit", {
          title: "login.change_email"
        });
        var currentEmail = this.currentEmail;
        modal.set("currentEmail", currentEmail);
        modal.set("newEmail", currentEmail);
      }
    }
  });

  _exports.default = _default;
});