define("discourse/templates/selected-posts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sOsye6SA",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"selected-posts\",null,[[\"selectedPostsCount\",\"canSelectAll\",\"canDeselectAll\",\"canDeleteSelected\",\"canMergeTopic\",\"canChangeOwner\",\"canMergePosts\",\"toggleMultiSelect\",\"mergePosts\",\"deleteSelected\",\"deselectAll\",\"selectAll\"],[[24,[\"selectedPostsCount\"]],[24,[\"canSelectAll\"]],[24,[\"canDeselectAll\"]],[24,[\"canDeleteSelected\"]],[24,[\"canMergeTopic\"]],[24,[\"canChangeOwner\"]],[24,[\"canMergePosts\"]],[28,\"action\",[[23,0,[]],\"toggleMultiSelect\"],null],[28,\"action\",[[23,0,[]],\"mergePosts\"],null],[28,\"action\",[[23,0,[]],\"deleteSelected\"],null],[28,\"action\",[[23,0,[]],\"deselectAll\"],null],[28,\"action\",[[23,0,[]],\"selectAll\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/selected-posts.hbs"
    }
  });

  _exports.default = _default;
});