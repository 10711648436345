define("discourse/routes/post", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    router: Ember.inject.service(),
    model: function model(params) {
      return this.store.find("post", params.id);
    },
    afterModel: function afterModel(post) {
      this.router.transitionTo(post.url);
    }
  });

  _exports.default = _default;
});