define("discourse/lib/utilities", ["exports", "discourse-common/lib/get-url", "handlebars", "I18n", "discourse-common/lib/object", "pretty-text/sanitizer", "discourse-common/lib/helpers", "discourse/lib/to-markdown", "discourse-common/lib/deprecated"], function (_exports, _getUrl, _handlebars, _I18n, _object, _sanitizer, _helpers, _toMarkdown, _deprecated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.splitString = splitString;
  _exports.translateSize = translateSize;
  _exports.escapeExpression = escapeExpression;
  _exports.formatUsername = formatUsername;
  _exports.replaceFormatter = replaceFormatter;
  _exports.avatarUrl = avatarUrl;
  _exports.getRawSize = getRawSize;
  _exports.avatarImg = avatarImg;
  _exports.tinyAvatar = tinyAvatar;
  _exports.postUrl = postUrl;
  _exports.highlightPost = highlightPost;
  _exports.emailValid = emailValid;
  _exports.hostnameValid = hostnameValid;
  _exports.extractDomainFromUrl = extractDomainFromUrl;
  _exports.selectedText = selectedText;
  _exports.selectedElement = selectedElement;
  _exports.selectedRange = selectedRange;
  _exports.caretRowCol = caretRowCol;
  _exports.caretPosition = caretPosition;
  _exports.setCaretPosition = setCaretPosition;
  _exports.initializeDefaultHomepage = initializeDefaultHomepage;
  _exports.defaultHomepage = defaultHomepage;
  _exports.setDefaultHomepage = setDefaultHomepage;
  _exports.determinePostReplaceSelection = determinePostReplaceSelection;
  _exports.isAppleDevice = isAppleDevice;
  _exports.isiPad = isiPad;
  _exports.safariHacksDisabled = safariHacksDisabled;
  _exports.clipboardHelpers = clipboardHelpers;
  _exports.toAsciiPrintable = toAsciiPrintable;
  _exports.slugify = slugify;
  _exports.toNumber = toNumber;
  _exports.isNumeric = isNumeric;
  _exports.fillMissingDates = fillMissingDates;
  _exports.areCookiesEnabled = areCookiesEnabled;
  _exports.isiOSPWA = isiOSPWA;
  _exports.prefersReducedMotion = prefersReducedMotion;
  _exports.isAppWebview = isAppWebview;
  _exports.postRNWebviewMessage = postRNWebviewMessage;
  _exports.inCodeBlock = inCodeBlock;
  _exports.translateModKey = translateModKey;
  _exports.clipboardCopy = clipboardCopy;
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _defaultHomepage;

  function splitString(str) {
    var separator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ",";

    if (typeof str === "string") {
      return str.split(separator).filter(Boolean);
    } else {
      return [];
    }
  }

  function translateSize(size) {
    switch (size) {
      case "tiny":
        return 20;

      case "small":
        return 25;

      case "medium":
        return 32;

      case "large":
        return 45;

      case "extra_large":
        return 60;

      case "huge":
        return 120;
    }

    return size;
  }

  function escapeExpression(string) {
    if (!string) {
      return "";
    } // don't escape SafeStrings, since they're already safe


    if (string instanceof _handlebars.default.SafeString) {
      return string.toString();
    }

    return (0, _sanitizer.escape)(string);
  }

  var _usernameFormatDelegate = function _usernameFormatDelegate(username) {
    return username;
  };

  function formatUsername(username) {
    return _usernameFormatDelegate(username || "");
  }

  function replaceFormatter(fn) {
    _usernameFormatDelegate = fn;
  }

  function avatarUrl(template, size) {
    if (!template) {
      return "";
    }

    var rawSize = getRawSize(translateSize(size));
    return template.replace(/\{size\}/g, rawSize);
  }

  function getRawSize(size) {
    var pixelRatio = window.devicePixelRatio || 1;
    var rawSize = 1;

    if (pixelRatio > 1.1 && pixelRatio < 2.1) {
      rawSize = 2;
    } else if (pixelRatio >= 2.1) {
      rawSize = 3;
    }

    return size * rawSize;
  }

  function avatarImg(options, customGetURL) {
    var size = translateSize(options.size);
    var path = avatarUrl(options.avatarTemplate, size); // We won't render an invalid url

    if (!path || path.length === 0) {
      return "";
    }

    path = (customGetURL || _getUrl.getURLWithCDN)(path);
    var classes = "avatar" + (options.extraClasses ? " " + options.extraClasses : "");
    var title = "";

    if (options.title) {
      var escaped = escapeExpression(options.title || "");
      title = " title='".concat(escaped, "' aria-label='").concat(escaped, "'");
    }

    return "<img loading='lazy' alt='' width='".concat(size, "' height='").concat(size, "' src='").concat(path, "' class='").concat(classes, "'").concat(title, ">");
  }

  function tinyAvatar(avatarTemplate, options) {
    return avatarImg((0, _object.deepMerge)({
      avatarTemplate: avatarTemplate,
      size: "tiny"
    }, options));
  }

  function postUrl(slug, topicId, postNumber) {
    var url = (0, _getUrl.default)("/t/");

    if (slug) {
      url += slug + "/";
    } else {
      url += "topic/";
    }

    url += topicId;

    if (postNumber > 1) {
      url += "/" + postNumber;
    }

    return url;
  }

  function highlightPost(postNumber) {
    var container = document.querySelector("#post_".concat(postNumber));

    if (!container) {
      return;
    }

    var element = container.querySelector(".topic-body");

    if (!element || element.classList.contains("highlighted")) {
      return;
    }

    element.classList.add("highlighted");

    var removeHighlighted = function removeHighlighted() {
      element.classList.remove("highlighted");
      element.removeEventListener("animationend", removeHighlighted);
    };

    element.addEventListener("animationend", removeHighlighted);
    container.querySelector(".tabLoc").focus();
  }

  function emailValid(email) {
    // see:  http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
    var re = /^[a-zA-Z0-9!#$%&'*+\/=?\^_`{|}~\-]+(?:\.[a-zA-Z0-9!#$%&'\*+\/=?\^_`{|}~\-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-]*[a-zA-Z0-9])?$/;
    return re.test(email);
  }

  function hostnameValid(hostname) {
    // see:  https://stackoverflow.com/questions/106179/regular-expression-to-match-dns-hostname-or-ip-address
    var re = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
    return hostname && re.test(hostname);
  }

  function extractDomainFromUrl(url) {
    if (url.indexOf("://") > -1) {
      url = url.split("/")[2];
    } else {
      url = url.split("/")[0];
    }

    return url.split(":")[0];
  }

  function selectedText() {
    var selection = window.getSelection();

    if (selection.isCollapsed) {
      return "";
    }

    var $div = $("<div>");

    for (var r = 0; r < selection.rangeCount; r++) {
      var range = selection.getRangeAt(r);
      var $ancestor = $(range.commonAncestorContainer); // ensure we never quote text in the post menu area

      var $postMenuArea = $ancestor.find(".post-menu-area")[0];

      if ($postMenuArea) {
        range.setEndBefore($postMenuArea);
      }

      var $oneboxTest = $ancestor.closest("aside.onebox[data-onebox-src]");
      var $codeBlockTest = $ancestor.parents("pre");

      if ($codeBlockTest.length) {
        var $code = $("<code>");
        $code.append(range.cloneContents()); // Even though this was a code block, produce a non-block quote if it's a single line.

        if (/\n/.test($code.text())) {
          var $pre = $("<pre>");
          $pre.append($code);
          $div.append($pre);
        } else {
          $div.append($code);
        }
      } else if ($oneboxTest.length) {
        // This is a partial quote from a onebox.
        // Treat it as though the entire onebox was quoted.
        var oneboxUrl = $($oneboxTest).data("onebox-src");
        $div.append(oneboxUrl);
      } else {
        $div.append(range.cloneContents());
      }
    }

    $div.find("aside.onebox[data-onebox-src]").each(function () {
      var oneboxUrl = $(this).data("onebox-src");
      $(this).replaceWith(oneboxUrl);
    });
    return (0, _toMarkdown.default)($div.html());
  }

  function selectedElement() {
    var _selectedRange;

    return (_selectedRange = selectedRange()) === null || _selectedRange === void 0 ? void 0 : _selectedRange.commonAncestorContainer;
  }

  function selectedRange() {
    var selection = window.getSelection();

    if (selection.rangeCount > 0) {
      return selection.getRangeAt(0);
    }
  } // Determine the row and col of the caret in an element


  function caretRowCol(el) {
    var cp = caretPosition(el);
    var rows = el.value.slice(0, cp).split("\n");
    var rowNum = rows.length;
    var colNum = cp - rows.splice(0, rowNum - 1).reduce(function (sum, row) {
      return sum + row.length + 1;
    }, 0);
    return {
      rowNum: rowNum,
      colNum: colNum
    };
  } // Determine the position of the caret in an element


  function caretPosition(el) {
    return (el === null || el === void 0 ? void 0 : el.selectionStart) || 0;
  } // Set the caret's position


  function setCaretPosition(ctrl, pos) {
    var range;

    if (ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(pos, pos);
      return;
    }

    if (ctrl.createTextRange) {
      range = ctrl.createTextRange();
      range.collapse(true);
      range.moveEnd("character", pos);
      range.moveStart("character", pos);
      return range.select();
    }
  }

  function initializeDefaultHomepage(siteSettings) {
    var homepage;
    var sel = document.querySelector("meta[name='discourse_current_homepage']");

    if (sel) {
      homepage = sel.getAttribute("content");
    }

    if (!homepage) {
      homepage = siteSettings.top_menu.split("|")[0].split(",")[0];
    }

    setDefaultHomepage(homepage);
  }

  function defaultHomepage() {
    return _defaultHomepage;
  }

  function setDefaultHomepage(homepage) {
    _defaultHomepage = homepage;
  }

  function determinePostReplaceSelection(_ref) {
    var selection = _ref.selection,
        needle = _ref.needle,
        replacement = _ref.replacement;
    var diff = replacement.end - replacement.start - (needle.end - needle.start);

    if (selection.end <= needle.start) {
      // Selection ends (and starts) before needle.
      return {
        start: selection.start,
        end: selection.end
      };
    } else if (selection.start <= needle.start) {
      // Selection starts before needle...
      if (selection.end < needle.end) {
        // ... and ends inside needle.
        return {
          start: selection.start,
          end: needle.start
        };
      } else {
        // ... and spans needle completely.
        return {
          start: selection.start,
          end: selection.end + diff
        };
      }
    } else if (selection.start < needle.end) {
      // Selection starts inside needle...
      if (selection.end <= needle.end) {
        // ... and ends inside needle.
        return {
          start: replacement.end,
          end: replacement.end
        };
      } else {
        // ... and spans end of needle.
        return {
          start: replacement.end,
          end: selection.end + diff
        };
      }
    } else {
      // Selection starts (and ends) behind needle.
      return {
        start: selection.start + diff,
        end: selection.end + diff
      };
    }
  }

  function isAppleDevice() {
    // IE has no DOMNodeInserted so can not get this hack despite saying it is like iPhone
    // This will apply hack on all iDevices
    var caps = (0, _helpers.helperContext)().capabilities;
    return caps.isIOS && !navigator.userAgent.match(/Trident/g);
  }

  var iPadDetected = undefined;

  function isiPad() {
    if (iPadDetected === undefined) {
      iPadDetected = navigator.userAgent.match(/iPad/g) && !navigator.userAgent.match(/Trident/g);
    }

    return iPadDetected;
  }

  function safariHacksDisabled() {
    (0, _deprecated.default)("`safariHacksDisabled()` is deprecated, it now always returns `false`", {
      since: "2.8.0.beta8",
      dropFrom: "2.9.0.beta1"
    });
    return false;
  }

  var toArray = function toArray(items) {
    items = items || [];

    if (!Array.isArray(items)) {
      return Array.from(items);
    }

    return items;
  };

  function clipboardHelpers(e, opts) {
    var clipboard = e.clipboardData || e.originalEvent.clipboardData || e.delegatedEvent.originalEvent.clipboardData;
    var types = toArray(clipboard.types);
    var files = toArray(clipboard.files);

    if (types.includes("Files") && files.length === 0) {
      // for IE
      files = toArray(clipboard.items).filter(function (i) {
        return i.kind === "file";
      });
    }

    var canUpload = files && opts.canUpload && types.includes("Files");
    var canUploadImage = canUpload && files.filter(function (f) {
      return f.type.match("^image/");
    })[0];
    var canPasteHtml = opts.siteSettings.enable_rich_text_paste && types.includes("text/html") && !canUploadImage;
    return {
      clipboard: clipboard,
      types: types,
      canUpload: canUpload,
      canPasteHtml: canPasteHtml
    };
  } // Replace any accented characters with their ASCII equivalent
  // Return the string if it only contains ASCII printable characters,
  // otherwise use the fallback


  function toAsciiPrintable(string, fallback) {
    if (typeof string.normalize === "function") {
      string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    return /^[\040-\176]*$/.test(string) ? string : fallback;
  }

  function slugify(string) {
    return string.trim().toLowerCase().replace(/\s|_+/g, "-") // Replace spaces and underscores with dashes
    .replace(/[^\w\-]+/g, "") // Remove non-word characters except for dashes
    .replace(/\-\-+/g, "-") // Replace multiple dashes with a single dash
    .replace(/^-+/, "") // Remove leading dashes
    .replace(/-+$/, ""); // Remove trailing dashes
  }

  function toNumber(input) {
    return typeof input === "number" ? input : parseFloat(input);
  }

  function isNumeric(input) {
    return !isNaN(toNumber(input)) && isFinite(input);
  }

  function fillMissingDates(data, startDate, endDate) {
    var startMoment = moment(startDate, "YYYY-MM-DD");
    var endMoment = moment(endDate, "YYYY-MM-DD");
    var countDays = endMoment.diff(startMoment, "days");
    var currentMoment = startMoment;

    for (var i = 0; i <= countDays; i++) {
      var date = data[i] ? moment(data[i].x, "YYYY-MM-DD") : null;

      if (i === 0 && (!date || date.isAfter(startMoment))) {
        data.splice(i, 0, {
          x: startMoment.format("YYYY-MM-DD"),
          y: 0
        });
      } else {
        if (!date || date.isAfter(moment(currentMoment))) {
          data.splice(i, 0, {
            x: currentMoment,
            y: 0
          });
        }
      }

      currentMoment = moment(currentMoment).add(1, "day").format("YYYY-MM-DD");
    }

    return data;
  }

  function areCookiesEnabled() {
    // see: https://github.com/Modernizr/Modernizr/blob/400db4043c22af98d46e1d2b9cbc5cb062791192/feature-detects/cookies.js
    try {
      document.cookie = "cookietest=1";
      var ret = document.cookie.indexOf("cookietest=") !== -1;
      document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
      return ret;
    } catch (e) {
      return false;
    }
  }

  function isiOSPWA() {
    var caps = (0, _helpers.helperContext)().capabilities;
    return window.matchMedia("(display-mode: standalone)").matches && caps.isIOS;
  }

  function prefersReducedMotion() {
    return window.matchMedia("(prefers-reduced-motion: reduce)").matches;
  }

  function isAppWebview() {
    return window.ReactNativeWebView !== undefined;
  }

  function postRNWebviewMessage(prop, value) {
    if (window.ReactNativeWebView !== undefined) {
      window.ReactNativeWebView.postMessage(JSON.stringify(_defineProperty({}, prop, value)));
    }
  }

  var CODE_BLOCKS_REGEX = /^(    |\t).*|`[^`]+`|^```[^]*?^```|\[code\][^]*?\[\/code\]/gm; //                        |      ^     |   ^   |      ^      |           ^           |
  //                               |         |          |                  |
  //                               |         |          |       code blocks between [code]
  //                               |         |          |
  //                               |         |          +--- code blocks between three backticks
  //                               |         |
  //                               |         +----- inline code between backticks
  //                               |
  //                               +------- paragraphs starting with 4 spaces or tab

  var OPEN_CODE_BLOCKS_REGEX = /`[^`]+|^```[^]*?|\[code\][^]*?/gm;

  function inCodeBlock(text, pos) {
    var end = 0;

    var _iterator = _createForOfIteratorHelper(text.matchAll(CODE_BLOCKS_REGEX)),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var match = _step.value;
        end = match.index + match[0].length;

        if (match.index <= pos && pos <= end) {
          return true;
        }
      } // Character at position `pos` can be in a code block that is unfinished.
      // To check this case, we look for any open code blocks after the last closed
      // code block.

    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    var lastOpenBlock = text.substr(end).search(OPEN_CODE_BLOCKS_REGEX);
    return lastOpenBlock !== -1 && pos >= end + lastOpenBlock;
  }

  function translateModKey(string) {
    var isApple = (0, _helpers.helperContext)().capabilities.isApple; // Apple device users are used to glyphs for shortcut keys

    if (isApple) {
      string = string.replace("Shift", "\u21E7").replace("Meta", "\u2318").replace("Alt", "\u2325").replace(/\+/g, "");
    } else {
      string = string.replace("Shift", _I18n.default.t("shortcut_modifier_key.shift")).replace("Ctrl", _I18n.default.t("shortcut_modifier_key.ctrl")).replace("Meta", _I18n.default.t("shortcut_modifier_key.ctrl")).replace("Alt", _I18n.default.t("shortcut_modifier_key.alt"));
    }

    return string;
  } // http://github.com/feross/clipboard-copy


  function clipboardCopy(text) {
    // Use the Async Clipboard API when available.
    // Requires a secure browsing context (i.e. HTTPS)
    if (navigator.clipboard) {
      return navigator.clipboard.writeText(text).catch(function (err) {
        throw err !== undefined ? err : new DOMException("The request is not allowed", "NotAllowedError");
      });
    } // ...Otherwise, use document.execCommand() fallback
    // Put the text to copy into a <span>


    var span = document.createElement("span");
    span.textContent = text; // Preserve consecutive spaces and newlines

    span.style.whiteSpace = "pre"; // Add the <span> to the page

    document.body.appendChild(span); // Make a selection object representing the range of text selected by the user

    var selection = window.getSelection();
    var range = window.document.createRange();
    selection.removeAllRanges();
    range.selectNode(span);
    selection.addRange(range); // Copy text to the clipboard

    var success = false;

    try {
      success = window.document.execCommand("copy");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("error", err);
    } // Cleanup


    selection.removeAllRanges();
    window.document.body.removeChild(span);
    return success;
  } // This prevents a mini racer crash


  var _default = {};
  _exports.default = _default;
});