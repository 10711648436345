define("discourse/widgets/custom-notification-item", ["discourse/widgets/default-notification-item", "I18n", "discourse/widgets/widget", "discourse/lib/utilities", "discourse-common/lib/icon-library"], function (_defaultNotificationItem, _I18n, _widget, _utilities, _iconLibrary) {
  "use strict";

  (0, _widget.createWidgetFrom)(_defaultNotificationItem.DefaultNotificationItem, "custom-notification-item", {
    notificationTitle: function notificationTitle(notificationName, data) {
      return data.title ? _I18n.default.t(data.title) : "";
    },
    text: function text(notificationName, data) {
      var username = (0, _utilities.formatUsername)(data.display_username);
      var description = this.description(data);
      return _I18n.default.t(data.message, {
        description: description,
        username: username
      });
    },
    icon: function icon(notificationName, data) {
      return (0, _iconLibrary.iconNode)("notification.".concat(data.message));
    }
  });
});