define("discourse/mixins/scrolling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ScrollingDOMMethods = void 0;

  /**
    This object provides the DOM methods we need for our Mixin to bind to scrolling
    methods in the browser. By removing them from the Mixin we can test them
    easier.
  **/
  var ScrollingDOMMethods = {
    bindOnScroll: function bindOnScroll(onScrollMethod) {
      document.addEventListener("touchmove", onScrollMethod, {
        passive: true
      });
      window.addEventListener("scroll", onScrollMethod, {
        passive: true
      });
    },
    unbindOnScroll: function unbindOnScroll(onScrollMethod) {
      document.removeEventListener("touchmove", onScrollMethod);
      window.removeEventListener("scroll", onScrollMethod);
    },
    screenNotFull: function screenNotFull() {
      return window.height > document.querySelector("#main").offsetHeight;
    }
  };
  _exports.ScrollingDOMMethods = ScrollingDOMMethods;
  var Scrolling = Ember.Mixin.create({
    router: Ember.inject.service(),
    // Begin watching for scroll events. By default they will be called at max every 100ms.
    // call with {throttle: N} to change the throttle spacing
    bindScrolling: function bindScrolling() {
      var _this = this;

      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (!opts.throttle) {
        opts.throttle = 100;
      } // So we can not call the scrolled event while transitioning. There is no public API for this :'(


      var microLib = this.router._router._routerMicrolib;

      var scheduleScrolled = function scheduleScrolled() {
        if (microLib.activeTransition) {
          return;
        }

        return Ember.run.scheduleOnce("afterRender", _this, "scrolled");
      };

      var onScrollMethod;

      if (opts.throttle) {
        onScrollMethod = function onScrollMethod() {
          return Ember.run.throttle(_this, scheduleScrolled, opts.throttle, false);
        };
      } else {
        onScrollMethod = scheduleScrolled;
      }

      this._scrollingMixinOnScrollMethod = onScrollMethod;
      ScrollingDOMMethods.bindOnScroll(onScrollMethod);
    },
    screenNotFull: function screenNotFull() {
      return ScrollingDOMMethods.screenNotFull();
    },
    unbindScrolling: function unbindScrolling() {
      ScrollingDOMMethods.unbindOnScroll(this._scrollingMixinOnScrollMethod);
    }
  });
  var _default = Scrolling;
  _exports.default = _default;
});