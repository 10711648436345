define("discourse/templates/user/profile-hidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MgMPpaRZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[10,\"class\",\"user-profile-hidden\"],[8],[1,[28,\"i18n\",[\"user.profile_hidden\"],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/user/profile-hidden.hbs"
    }
  });

  _exports.default = _default;
});