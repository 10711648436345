define("discourse/templates/components/topic-post-badges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NRLHbEfs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"displayUnreadPosts\"]]],null,{\"statements\":[[0,\"   \"],[7,\"a\",true],[11,\"href\",[22,\"url\"]],[11,\"title\",[28,\"i18n\",[\"topic.unread_posts\"],[[\"count\"],[[24,[\"displayUnreadPosts\"]]]]]],[10,\"class\",\"badge badge-notification unread-posts\"],[8],[1,[22,\"displayUnreadPosts\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"unseen\"]]],null,{\"statements\":[[0,\"   \"],[7,\"a\",true],[11,\"href\",[22,\"url\"]],[11,\"title\",[28,\"i18n\",[\"topic.new\"],null]],[10,\"class\",\"badge badge-notification new-topic\"],[8],[1,[22,\"newDotText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/topic-post-badges.hbs"
    }
  });

  _exports.default = _default;
});