define("discourse/templates/modal/grant-admin-second-factor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cOwxGxIw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"title\"],[\"admin.user.grant_admin\"]],{\"statements\":[[4,\"second-factor-form\",null,[[\"secondFactorMethod\",\"secondFactorToken\",\"class\",\"backupEnabled\"],[[24,[\"secondFactorMethod\"]],[24,[\"secondFactorToken\"]],[24,[\"secondFactorClass\"]],[24,[\"backupEnabled\"]]]],{\"statements\":[[4,\"if\",[[24,[\"showSecurityKey\"]]],null,{\"statements\":[[4,\"security-key-form\",null,[[\"allowedCredentialIds\",\"challenge\",\"showSecurityKey\",\"showSecondFactor\",\"secondFactorMethod\",\"otherMethodAllowed\",\"action\"],[[24,[\"securityKeyAllowedCredentialIds\"]],[24,[\"securityKeyChallenge\"]],[24,[\"showSecurityKey\"]],[24,[\"showSecondFactor\"]],[24,[\"secondFactorMethod\"]],[24,[\"otherMethodAllowed\"]],[28,\"action\",[[23,0,[]],\"authenticateSecurityKey\"],null]]],{\"statements\":[],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"second-factor-input\",null,[[\"value\",\"inputId\",\"secondFactorMethod\",\"backupEnabled\"],[[24,[\"secondFactorToken\"]],\"second-factor-confirmation\",[24,[\"secondFactorMethod\"]],[24,[\"backupEnabled\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[24,[\"showSecurityKey\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n      \"],[1,[28,\"d-button\",null,[[\"action\",\"icon\",\"label\",\"disabled\",\"class\"],[[28,\"action\",[[23,0,[]],\"authenticate\"],null],\"shield-alt\",\"admin.user.grant_admin\",[24,[\"disabled\"]],\"btn btn-primary\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/grant-admin-second-factor.hbs"
    }
  });

  _exports.default = _default;
});