define("discourse/helpers/format-username", ["exports", "discourse/lib/utilities", "discourse-common/lib/helpers"], function (_exports, _utilities, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helpers.registerUnbound)("format-username", _utilities.formatUsername);

  _exports.default = _default;
});