define("discourse/templates/components/user-summary-topics-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pSAdI133",
    "block": "{\"symbols\":[\"item\",\"@type\",\"@user\",\"&default\",\"@items\"],\"statements\":[[4,\"if\",[[23,5,[]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,5,[]]],null,{\"statements\":[[0,\"      \"],[14,4,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"hasMore\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"more\",[28,\"concat\",[\"userActivity.\",[23,2,[]]],null],[23,3,[]]]],{\"statements\":[[0,\"        \"],[1,[28,\"i18n\",[[28,\"concat\",[\"user.summary.more_\",[23,2,[]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[[28,\"concat\",[\"user.summary.no_\",[23,2,[]]],null]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/user-summary-topics-list.hbs"
    }
  });

  _exports.default = _default;
});