define("discourse/routes/email-login", ["exports", "discourse/routes/discourse", "I18n", "discourse/lib/ajax"], function (_exports, _discourse, _I18n, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    titleToken: function titleToken() {
      return _I18n.default.t("login.title");
    },
    model: function model(params) {
      return (0, _ajax.ajax)("/session/email-login/".concat(params.token, ".json"));
    }
  });

  _exports.default = _default;
});