define("discourse/components/discovery-topics-list", ["exports", "discourse-common/utils/decorators", "discourse/mixins/load-more", "discourse/mixins/url-refresh"], function (_exports, _decorators, _loadMore, _urlRefresh) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_urlRefresh.default, _loadMore.default, (_dec = (0, _decorators.on)("didInsertElement"), _dec2 = (0, _decorators.observes)("model"), _dec3 = (0, _decorators.on)("didInsertElement"), _dec4 = (0, _decorators.on)("willDestroyElement"), _dec5 = (0, _decorators.observes)("incomingCount"), (_obj = {
    classNames: ["contents"],
    eyelineSelector: ".topic-list-item",
    documentTitle: Ember.inject.service(),
    _readjustScrollPosition: function _readjustScrollPosition() {
      var scrollTo = this.session.get("topicListScrollPosition");

      if (scrollTo && scrollTo >= 0) {
        Ember.run.schedule("afterRender", function () {
          return $(window).scrollTop(scrollTo + 1);
        });
      } else {
        Ember.run.scheduleOnce("afterRender", this, this.loadMoreUnlessFull);
      }
    },
    _monitorTrackingState: function _monitorTrackingState() {
      var _this = this;

      this.topicTrackingState.onStateChange(function () {
        return _this._updateTrackingTopics();
      });
    },
    _removeTrackingStateChangeMonitor: function _removeTrackingStateChangeMonitor() {
      this.topicTrackingState.offStateChange(this.stateChangeCallbackId);
    },
    _updateTrackingTopics: function _updateTrackingTopics() {
      this.topicTrackingState.updateTopics(this.model.topics);
    },
    _updateTitle: function _updateTitle() {
      this.documentTitle.updateContextCount(this.incomingCount);
    },
    saveScrollPosition: function saveScrollPosition() {
      this.session.set("topicListScrollPosition", $(window).scrollTop());
    },
    actions: {
      loadMore: function loadMore() {
        var _this2 = this;

        this.documentTitle.updateContextCount(0);
        this.model.loadMore().then(function () {
          var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
              moreTopicsUrl = _ref.moreTopicsUrl,
              newTopics = _ref.newTopics;

          if (newTopics && newTopics.length && _this2.autoAddTopicsToBulkSelect && _this2.bulkSelectEnabled) {
            _this2.addTopicsToBulkSelect(newTopics);
          }

          Ember.run.schedule("afterRender", function () {
            return _this2.saveScrollPosition();
          });

          if (moreTopicsUrl && $(window).height() >= $(document).height()) {
            _this2.send("loadMore");
          }

          if (_this2.loadingComplete) {
            _this2.loadingComplete();
          }
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "_readjustScrollPosition", [_dec, _dec2], Object.getOwnPropertyDescriptor(_obj, "_readjustScrollPosition"), _obj), _applyDecoratedDescriptor(_obj, "_monitorTrackingState", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_monitorTrackingState"), _obj), _applyDecoratedDescriptor(_obj, "_removeTrackingStateChangeMonitor", [_dec4], Object.getOwnPropertyDescriptor(_obj, "_removeTrackingStateChangeMonitor"), _obj), _applyDecoratedDescriptor(_obj, "_updateTitle", [_dec5], Object.getOwnPropertyDescriptor(_obj, "_updateTitle"), _obj)), _obj)));

  _exports.default = _default;
});