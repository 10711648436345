define("discourse/components/groups-form-profile-fields", ["exports", "discourse-common/utils/decorators", "discourse/models/group", "I18n", "discourse-common/lib/debounce", "discourse/lib/ajax-error"], function (_exports, _decorators, _group, _I18n, _debounce, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("basicNameValidation", "uniqueNameValidation"), _dec2 = (0, _decorators.observes)("nameInput"), (_obj = {
    disableSave: null,
    nameInput: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var name = this.get("model.name");

      if (name) {
        this.set("nameInput", name);
      } else {
        this.set("disableSave", true);
      }
    },
    canEdit: Ember.computed.not("model.automatic"),
    nameValidation: function nameValidation(basicNameValidation, uniqueNameValidation) {
      return uniqueNameValidation ? uniqueNameValidation : basicNameValidation;
    },
    _validateName: function _validateName() {
      if (this.nameInput === this.get("model.name")) {
        return;
      }

      if (this.nameInput === undefined) {
        return this._failedInputValidation();
      }

      if (this.nameInput === "") {
        this.set("uniqueNameValidation", null);
        return this._failedInputValidation(_I18n.default.t("admin.groups.new.name.blank"));
      }

      if (this.nameInput.length < this.siteSettings.min_username_length) {
        return this._failedInputValidation(_I18n.default.t("admin.groups.new.name.too_short"));
      }

      if (this.nameInput.length > this.siteSettings.max_username_length) {
        return this._failedInputValidation(_I18n.default.t("admin.groups.new.name.too_long"));
      }

      this.checkGroupNameDebounced();
      return this._failedInputValidation(_I18n.default.t("admin.groups.new.name.checking"));
    },
    checkGroupNameDebounced: function checkGroupNameDebounced() {
      (0, _debounce.default)(this, this._checkGroupName, 500);
    },
    _checkGroupName: function _checkGroupName() {
      var _this = this;

      if (Ember.isEmpty(this.nameInput)) {
        return;
      }

      _group.default.checkName(this.nameInput).then(function (response) {
        var validationName = "uniqueNameValidation";

        if (response.available) {
          _this.set(validationName, Ember.Object.create({
            ok: true,
            reason: _I18n.default.t("admin.groups.new.name.available")
          }));

          _this.set("disableSave", false);

          _this.set("model.name", _this.nameInput);
        } else {
          var reason;

          if (response.errors) {
            reason = response.errors.join(" ");
          } else {
            reason = _I18n.default.t("admin.groups.new.name.not_available");
          }

          _this.set(validationName, _this._failedInputValidation(reason));
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    _failedInputValidation: function _failedInputValidation(reason) {
      this.set("disableSave", true);
      var options = {
        failed: true
      };

      if (reason) {
        options.reason = reason;
      }

      this.set("basicNameValidation", Ember.Object.create(options));
    }
  }, (_applyDecoratedDescriptor(_obj, "nameValidation", [_dec], Object.getOwnPropertyDescriptor(_obj, "nameValidation"), _obj), _applyDecoratedDescriptor(_obj, "_validateName", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_validateName"), _obj)), _obj)));

  _exports.default = _default;
});