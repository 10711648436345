define("discourse/controllers/topic-bulk-actions", ["exports", "I18n", "discourse/mixins/modal-functionality", "discourse/models/topic", "bootbox"], function (_exports, _I18n, _modalFunctionality, _topic, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addBulkButton = addBulkButton;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _buttons = [];

  var alwaysTrue = function alwaysTrue() {
    return true;
  };

  function identity() {}

  function addBulkButton(action, key, opts) {
    opts = opts || {};
    var btn = {
      action: action,
      label: "topics.bulk.".concat(key),
      icon: opts.icon,
      buttonVisible: opts.buttonVisible || alwaysTrue,
      enabledSetting: opts.enabledSetting,
      class: opts.class
    };

    _buttons.push(btn);
  } // Default buttons


  addBulkButton("showChangeCategory", "change_category", {
    icon: "pencil-alt",
    class: "btn-default",
    buttonVisible: function buttonVisible(topics) {
      return !topics.some(function (t) {
        return t.isPrivateMessage;
      });
    }
  });
  addBulkButton("closeTopics", "close_topics", {
    icon: "lock",
    class: "btn-default",
    buttonVisible: function buttonVisible(topics) {
      return !topics.some(function (t) {
        return t.isPrivateMessage;
      });
    }
  });
  addBulkButton("archiveTopics", "archive_topics", {
    icon: "folder",
    class: "btn-default",
    buttonVisible: function buttonVisible(topics) {
      return !topics.some(function (t) {
        return t.isPrivateMessage;
      });
    }
  });
  addBulkButton("archiveMessages", "archive_topics", {
    icon: "folder",
    class: "btn-default",
    buttonVisible: function buttonVisible(topics) {
      return topics.some(function (t) {
        return t.isPrivateMessage;
      });
    }
  });
  addBulkButton("moveMessagesToInbox", "move_messages_to_inbox", {
    icon: "folder",
    class: "btn-default",
    buttonVisible: function buttonVisible(topics) {
      return topics.some(function (t) {
        return t.isPrivateMessage;
      });
    }
  });
  addBulkButton("showNotificationLevel", "notification_level", {
    icon: "d-regular",
    class: "btn-default"
  });
  addBulkButton("resetRead", "reset_read", {
    icon: "backward",
    class: "btn-default"
  });
  addBulkButton("unlistTopics", "unlist_topics", {
    icon: "far-eye-slash",
    class: "btn-default",
    buttonVisible: function buttonVisible(topics) {
      return topics.some(function (t) {
        return t.visible;
      }) && !topics.some(function (t) {
        return t.isPrivateMessage;
      });
    }
  });
  addBulkButton("relistTopics", "relist_topics", {
    icon: "far-eye",
    class: "btn-default",
    buttonVisible: function buttonVisible(topics) {
      return topics.some(function (t) {
        return !t.visible;
      }) && !topics.some(function (t) {
        return t.isPrivateMessage;
      });
    }
  });
  addBulkButton("showTagTopics", "change_tags", {
    icon: "tag",
    class: "btn-default",
    enabledSetting: "tagging_enabled",
    buttonVisible: function buttonVisible() {
      return this.currentUser.staff;
    }
  });
  addBulkButton("showAppendTagTopics", "append_tags", {
    icon: "tag",
    class: "btn-default",
    enabledSetting: "tagging_enabled",
    buttonVisible: function buttonVisible() {
      return this.currentUser.staff;
    }
  });
  addBulkButton("removeTags", "remove_tags", {
    icon: "tag",
    class: "btn-default",
    enabledSetting: "tagging_enabled",
    buttonVisible: function buttonVisible() {
      return this.currentUser.staff;
    }
  });
  addBulkButton("deleteTopics", "delete", {
    icon: "trash-alt",
    class: "btn-danger delete-topics",
    buttonVisible: function buttonVisible() {
      return this.currentUser.staff;
    }
  }); // Modal for performing bulk actions on topics

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    userPrivateMessages: Ember.inject.controller("user-private-messages"),
    tags: null,
    emptyTags: Ember.computed.empty("tags"),
    categoryId: Ember.computed.alias("model.category.id"),
    processedTopicCount: 0,
    isGroup: Ember.computed.alias("userPrivateMessages.isGroup"),
    groupFilter: Ember.computed.alias("userPrivateMessages.groupFilter"),
    onShow: function onShow() {
      var _this = this;

      var topics = this.get("model.topics");
      this.set("buttons", _buttons.filter(function (b) {
        if (b.enabledSetting && !_this.siteSettings[b.enabledSetting]) {
          return false;
        }

        return b.buttonVisible.call(_this, topics);
      }));
      this.set("modal.modalClass", "topic-bulk-actions-modal small");
      this.send("changeBulkTemplate", "modal/bulk-actions-buttons");
    },
    perform: function perform(operation) {
      var _this2 = this;

      this.set("processedTopicCount", 0);

      if (this.get("model.topics").length > 20) {
        this.send("changeBulkTemplate", "modal/bulk-progress");
      }

      this.set("loading", true);
      return this._processChunks(operation).catch(function () {
        _bootbox.default.alert(_I18n.default.t("generic_error"));
      }).finally(function () {
        _this2.set("loading", false);
      });
    },
    _generateTopicChunks: function _generateTopicChunks(allTopics) {
      var startIndex = 0;
      var chunkSize = 30;
      var chunks = [];

      while (startIndex < allTopics.length) {
        var topics = allTopics.slice(startIndex, startIndex + chunkSize);
        chunks.push(topics);
        startIndex += chunkSize;
      }

      return chunks;
    },
    _processChunks: function _processChunks(operation) {
      var _this3 = this;

      var allTopics = this.get("model.topics");

      var topicChunks = this._generateTopicChunks(allTopics);

      var topicIds = [];
      var tasks = topicChunks.map(function (topics) {
        return function () {
          return _topic.default.bulkOperation(topics, operation).then(function (result) {
            _this3.set("processedTopicCount", _this3.get("processedTopicCount") + topics.length);

            return result;
          });
        };
      });
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var resolveNextTask = function resolveNextTask() {
          if (tasks.length === 0) {
            var topics = topicIds.map(function (id) {
              return allTopics.findBy("id", id);
            });
            return resolve(topics);
          }

          tasks.shift()().then(function (result) {
            if (result && result.topic_ids) {
              topicIds.push.apply(topicIds, _toConsumableArray(result.topic_ids));
            }

            resolveNextTask();
          }).catch(reject);
        };

        resolveNextTask();
      });
    },
    forEachPerformed: function forEachPerformed(operation, cb) {
      var _this4 = this;

      this.perform(operation).then(function (topics) {
        if (topics) {
          topics.forEach(cb);
          (_this4.refreshClosure || identity)();

          _this4.send("closeModal");
        }
      });
    },
    performAndRefresh: function performAndRefresh(operation) {
      var _this5 = this;

      return this.perform(operation).then(function () {
        (_this5.refreshClosure || identity)();

        _this5.send("closeModal");
      });
    },
    actions: {
      showTagTopics: function showTagTopics() {
        this.set("tags", "");
        this.set("action", "changeTags");
        this.set("label", "change_tags");
        this.set("title", "choose_new_tags");
        this.send("changeBulkTemplate", "bulk-tag");
      },
      changeTags: function changeTags() {
        this.performAndRefresh({
          type: "change_tags",
          tags: this.tags
        });
      },
      showAppendTagTopics: function showAppendTagTopics() {
        this.set("tags", null);
        this.set("action", "appendTags");
        this.set("label", "append_tags");
        this.set("title", "choose_append_tags");
        this.send("changeBulkTemplate", "bulk-tag");
      },
      appendTags: function appendTags() {
        this.performAndRefresh({
          type: "append_tags",
          tags: this.tags
        });
      },
      showChangeCategory: function showChangeCategory() {
        this.send("changeBulkTemplate", "modal/bulk-change-category");
      },
      showNotificationLevel: function showNotificationLevel() {
        this.send("changeBulkTemplate", "modal/bulk-notification-level");
      },
      deleteTopics: function deleteTopics() {
        this.performAndRefresh({
          type: "delete"
        });
      },
      closeTopics: function closeTopics() {
        this.forEachPerformed({
          type: "close"
        }, function (t) {
          return t.set("closed", true);
        });
      },
      archiveTopics: function archiveTopics() {
        this.forEachPerformed({
          type: "archive"
        }, function (t) {
          return t.set("archived", true);
        });
      },
      archiveMessages: function archiveMessages() {
        var params = {
          type: "archive_messages"
        };

        if (this.isGroup) {
          params.group = this.groupFilter;
        }

        this.performAndRefresh(params);
      },
      moveMessagesToInbox: function moveMessagesToInbox() {
        var params = {
          type: "move_messages_to_inbox"
        };

        if (this.isGroup) {
          params.group = this.groupFilter;
        }

        this.performAndRefresh(params);
      },
      unlistTopics: function unlistTopics() {
        this.forEachPerformed({
          type: "unlist"
        }, function (t) {
          return t.set("visible", false);
        });
      },
      relistTopics: function relistTopics() {
        this.forEachPerformed({
          type: "relist"
        }, function (t) {
          return t.set("visible", true);
        });
      },
      changeCategory: function changeCategory() {
        var _this6 = this;

        var categoryId = parseInt(this.newCategoryId, 10) || 0;
        this.perform({
          type: "change_category",
          category_id: categoryId
        }).then(function (topics) {
          topics.forEach(function (t) {
            return t.set("category_id", categoryId);
          });
          (_this6.refreshClosure || identity)();

          _this6.send("closeModal");
        });
      },
      resetRead: function resetRead() {
        this.performAndRefresh({
          type: "reset_read"
        });
      },
      removeTags: function removeTags() {
        var _this7 = this;

        _bootbox.default.confirm(_I18n.default.t("topics.bulk.confirm_remove_tags", {
          count: this.get("model.topics").length
        }), function (result) {
          if (result) {
            _this7.performAndRefresh({
              type: "remove_tags"
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});