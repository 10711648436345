define("discourse/components/private-message-glyph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: null,
    href: null,
    title: null,
    ariaLabel: null,
    shouldShow: null
  });

  _exports.default = _default;
});