define("discourse/controllers/application", ["exports", "discourse/lib/utilities", "discourse-common/utils/decorators"], function (_exports, _utilities, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_obj = {
    showTop: true,
    showFooter: false,
    router: Ember.inject.service(),
    canSignUp: function canSignUp() {
      return !this.siteSettings.invite_only && this.siteSettings.allow_new_registrations && !this.siteSettings.enable_discourse_connect;
    },
    loginRequired: function loginRequired() {
      return this.siteSettings.login_required && !this.currentUser;
    },
    showFooterNav: function showFooterNav() {
      return (0, _utilities.isAppWebview)() || (0, _utilities.isiOSPWA)();
    }
  }, (_applyDecoratedDescriptor(_obj, "canSignUp", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "canSignUp"), _obj), _applyDecoratedDescriptor(_obj, "loginRequired", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "loginRequired"), _obj), _applyDecoratedDescriptor(_obj, "showFooterNav", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "showFooterNav"), _obj)), _obj));

  _exports.default = _default;
});