define("discourse/mixins/card-contents-base", ["exports", "discourse/lib/url", "discourse/lib/after-transition", "discourse/lib/utilities", "discourse/lib/intercept-click", "discourse-common/utils/decorators"], function (_exports, _url, _afterTransition, _utilities, _interceptClick, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addCardClickListenerSelector = addCardClickListenerSelector;
  _exports.resetCardClickListenerSelector = resetCardClickListenerSelector;
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var DEFAULT_SELECTOR = "#main-outlet";
  var _cardClickListenerSelectors = [DEFAULT_SELECTOR];

  function addCardClickListenerSelector(selector) {
    _cardClickListenerSelectors.push(selector);
  }

  function resetCardClickListenerSelector() {
    _cardClickListenerSelectors = [DEFAULT_SELECTOR];
  }

  var _default = Ember.Mixin.create((_obj = {
    router: Ember.inject.service(),
    elementId: null,
    //click detection added for data-{elementId}
    triggeringLinkClass: null,
    //the <a> classname where this card should appear
    _showCallback: null,
    //username, $target - load up data for when show is called, should call this._positionCard($target) when it's done.
    postStream: Ember.computed.alias("topic.postStream"),
    viewingTopic: Ember.computed.match("router.currentRouteName", /^topic\./),
    visible: false,
    username: null,
    loading: null,
    cardTarget: null,
    post: null,
    isFixed: false,
    isDocked: false,
    _show: function _show(username, target, event) {
      var _closestArticle$datas,
          _this = this;

      // No user card for anon
      if (this.siteSettings.hide_user_profiles_from_public && !this.currentUser) {
        return false;
      }

      username = (0, _utilities.escapeExpression)(username.toString()); // Don't show if nested

      if (target.closest(".card-content")) {
        this._close();

        _url.default.routeTo(target.href);

        return false;
      }

      this.appEvents.trigger("card:show", username, target, event);
      var currentUsername = this.username;

      if (username === currentUsername || this.loading === username) {
        this._positionCard($(target));

        return;
      }

      var closestArticle = target.closest("article");
      var postId = (closestArticle === null || closestArticle === void 0 ? void 0 : (_closestArticle$datas = closestArticle.dataset) === null || _closestArticle$datas === void 0 ? void 0 : _closestArticle$datas.postId) || null;
      var wasVisible = this.visible;
      var previousTarget = this.cardTarget;

      if (wasVisible) {
        this._close();

        if (target === previousTarget) {
          return;
        }
      }

      var post = this.viewingTopic && postId ? this.postStream.findLoadedPost(postId) : null;
      this.setProperties({
        username: username,
        loading: username,
        cardTarget: target,
        post: post
      });
      this.appEvents.trigger("user-card:show", {
        username: username
      });

      this._showCallback(username, $(target)).then(function (user) {
        _this.appEvents.trigger("user-card:after-show", {
          user: user
        });
      }); // We bind scrolling on mobile after cards are shown to hide them if user scrolls


      if (this.site.mobileView) {
        this._bindMobileScroll();
      }

      return false;
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      (0, _afterTransition.default)($(this.element), this._hide);
      var id = this.elementId;
      var triggeringLinkClass = this.triggeringLinkClass;
      var previewClickEvent = "click.discourse-preview-".concat(id, "-").concat(triggeringLinkClass);
      var mobileScrollEvent = "scroll.mobile-card-cloak";
      this.setProperties({
        boundCardClickHandler: this._cardClickHandler,
        previewClickEvent: previewClickEvent,
        mobileScrollEvent: mobileScrollEvent
      });
      document.addEventListener("mousedown", this._clickOutsideHandler);

      _cardClickListenerSelectors.forEach(function (selector) {
        document.querySelector(selector).addEventListener("click", _this2.boundCardClickHandler);
      });

      this.appEvents.on(previewClickEvent, this, "_previewClick");
      this.appEvents.on("topic-header:trigger-".concat(id), this, "_topicHeaderTrigger");
    },
    _cardClickHandler: function _cardClickHandler(event) {
      var _this3 = this;

      if (this.avatarSelector) {
        var matched = this._showCardOnClick(event, this.avatarSelector, function (el) {
          return el.dataset[_this3.avatarDataAttrKey];
        });

        if (matched) {
          return; // Don't need to check for mention click; it's an avatar click
        }
      } // Mention click


      this._showCardOnClick(event, this.mentionSelector, function (el) {
        return el.innerText.replace(/^@/, "");
      });
    },
    _showCardOnClick: function _showCardOnClick(event, selector, transformText) {
      var matchingEl = event.target.closest(selector);

      if (matchingEl) {
        if ((0, _interceptClick.wantsNewWindow)(event)) {
          return true;
        }

        event.preventDefault();
        event.stopPropagation();
        return this._show(transformText(matchingEl), matchingEl, event);
      }

      return false;
    },
    _topicHeaderTrigger: function _topicHeaderTrigger(username, target) {
      this.setProperties({
        isFixed: true,
        isDocked: true
      });
      return this._show(username, target);
    },
    _bindMobileScroll: function _bindMobileScroll() {
      var _this4 = this;

      var mobileScrollEvent = this.mobileScrollEvent;

      var onScroll = function onScroll() {
        Ember.run.throttle(_this4, _this4._close, 1000);
      };

      $(window).on(mobileScrollEvent, onScroll);
    },
    _unbindMobileScroll: function _unbindMobileScroll() {
      var mobileScrollEvent = this.mobileScrollEvent;
      $(window).off(mobileScrollEvent);
    },
    _previewClick: function _previewClick($target) {
      this.set("isFixed", true);
      return this._show($target.text().replace(/^@/, ""), $target);
    },
    _positionCard: function _positionCard(target) {
      var _this5 = this;

      var rtl = $("html").css("direction") === "rtl";

      if (!target) {
        return;
      }

      var width = $(this.element).width();
      var height = 175;
      var isFixed = this.isFixed;
      var isDocked = this.isDocked;
      var verticalAdjustments = 0;
      Ember.run.schedule("afterRender", function () {
        if (target) {
          if (!_this5.site.mobileView) {
            var position = target.offset();

            if (target.parents(".d-header").length > 0) {
              position.top = target.position().top;
            }

            if (position) {
              position.bottom = "unset";

              if (rtl) {
                // The site direction is rtl
                position.right = $(window).width() - position.left + 10;
                position.left = "auto";
                var overage = $(window).width() - 50 - (position.right + width);

                if (overage < 0) {
                  position.right += overage;
                  position.top += target.height() + 48;
                  verticalAdjustments += target.height() + 48;
                }
              } else {
                // The site direction is ltr
                position.left += target.width() + 10;

                var _overage = $(window).width() - 50 - (position.left + width);

                if (_overage < 0) {
                  position.left += _overage;
                  position.top += target.height() + 48;
                  verticalAdjustments += target.height() + 48;
                }
              } // It looks better to have the card aligned slightly higher


              position.top -= 24;

              if (isFixed) {
                position.top -= $("html").scrollTop(); //if content is fixed and will be cut off on the bottom, display it above...

                if (position.top + height + verticalAdjustments > $(window).height() - 50) {
                  position.bottom = $(window).height() - (target.offset().top - $("html").scrollTop());

                  if (verticalAdjustments > 0) {
                    position.bottom += 48;
                  }

                  position.top = "unset";
                }
              }

              var avatarOverflowSize = 44;

              if (isDocked && position.top < avatarOverflowSize) {
                position.top = avatarOverflowSize;
              }

              $(_this5.element).css(position);
            }
          }

          if (_this5.site.mobileView) {
            $(".card-cloak").removeClass("hidden");

            var _position = target.offset();

            _position.top = "10%"; // match modal behaviour

            _position.left = 0;
            $(_this5.element).css(_position);
          }

          $(_this5.element).toggleClass("docked-card", isDocked); // After the card is shown, focus on the first link
          //
          // note: we DO NOT use afterRender here cause _positionCard may
          // run afterwards, if we allowed this to happen the usercard
          // may be offscreen and we may scroll all the way to it on focus

          Ember.run.next(null, function () {
            var firstLink = _this5.element.querySelector("a");

            firstLink && firstLink.focus();
          });
        }
      });
    },
    _hide: function _hide() {
      if (!this.visible) {
        $(this.element).css({
          left: -9999,
          top: -9999
        });

        if (this.site.mobileView) {
          $(".card-cloak").addClass("hidden");
        }
      }
    },
    _close: function _close() {
      this.setProperties({
        visible: false,
        username: null,
        loading: null,
        cardTarget: null,
        post: null,
        isFixed: false,
        isDocked: false
      }); // Card will be removed, so we unbind mobile scrolling

      if (this.site.mobileView) {
        this._unbindMobileScroll();
      }

      this._hide();

      this.appEvents.trigger("card:hide");
    },
    willDestroyElement: function willDestroyElement() {
      var _this6 = this;

      this._super.apply(this, arguments);

      document.removeEventListener("mousedown", this._clickOutsideHandler);

      _cardClickListenerSelectors.forEach(function (selector) {
        document.querySelector(selector).removeEventListener("click", _this6.boundCardClickHandler);
      });

      var previewClickEvent = this.previewClickEvent;
      this.appEvents.off(previewClickEvent, this, "_previewClick");
      this.appEvents.off("topic-header:trigger-".concat(this.elementId), this, "_topicHeaderTrigger");

      this._hide();
    },
    keyUp: function keyUp(e) {
      if (e.key === "Escape") {
        var target = this.cardTarget;

        this._close();

        target.focus();
      }
    },
    _clickOutsideHandler: function _clickOutsideHandler(event) {
      if (this.visible) {
        var $target = $(event.target);

        if ($target.closest("[data-".concat(this.elementId, "]")).data(this.elementId) || $target.closest("a.".concat(this.triggeringLinkClass)).length > 0 || $target.closest("#".concat(this.elementId)).length > 0) {
          return;
        }

        this._close();
      }

      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, "_cardClickHandler", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_cardClickHandler"), _obj), _applyDecoratedDescriptor(_obj, "_hide", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_hide"), _obj), _applyDecoratedDescriptor(_obj, "_clickOutsideHandler", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_clickOutsideHandler"), _obj)), _obj));

  _exports.default = _default;
});