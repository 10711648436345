define("discourse/components/software-update-prompt", ["exports", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse-common/config/environment"], function (_exports, _getUrl, _decorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.on)("init"), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = (0, _decorators.on)("willDestroyElement"), (_obj = {
    tagName: "",
    showPrompt: false,
    animatePrompt: false,
    _timeoutHandler: null,
    rootUrl: function rootUrl() {
      return (0, _getUrl.default)("/");
    },
    initSubscribtions: function initSubscribtions() {
      var _this = this;

      this.messageBus.subscribe("/refresh_client", function () {
        _this.session.requiresRefresh = true;
      });
      this.messageBus.subscribe("/global/asset-version", function (version) {
        if (_this.session.assetVersion !== version) {
          _this.session.requiresRefresh = true;
        }

        if (!_this._timeoutHandler && _this.session.requiresRefresh) {
          if ((0, _environment.isTesting)()) {
            _this.updatePromptState(true);
          } else {
            // Since we can do this transparently for people browsing the forum
            // hold back the message 24 hours.
            _this._timeoutHandler = Ember.run.later(function () {
              _this.updatePromptState(true);
            }, 1000 * 60 * 24 * 60);
          }
        }
      });
    },
    updatePromptState: function updatePromptState(value) {
      var _this2 = this;

      // when adding the message, we inject the HTML then add the animation
      // when dismissing, things need to happen in the opposite order
      var firstProp = value ? "showPrompt" : "animatePrompt",
          secondProp = value ? "animatePrompt" : "showPrompt";
      this.set(firstProp, value);

      if ((0, _environment.isTesting)()) {
        this.set(secondProp, value);
      } else {
        Ember.run.later(function () {
          _this2.set(secondProp, value);
        }, 500);
      }
    },
    refreshPage: function refreshPage() {
      document.location.reload();
    },
    dismiss: function dismiss() {
      this.updatePromptState(false);
    },
    _resetTimeoutHandler: function _resetTimeoutHandler() {
      this._timeoutHandler && Ember.run.cancel(this._timeoutHandler);
      this._timeoutHandler = null;
    }
  }, (_applyDecoratedDescriptor(_obj, "rootUrl", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "rootUrl"), _obj), _applyDecoratedDescriptor(_obj, "initSubscribtions", [_dec], Object.getOwnPropertyDescriptor(_obj, "initSubscribtions"), _obj), _applyDecoratedDescriptor(_obj, "refreshPage", [_dec2], Object.getOwnPropertyDescriptor(_obj, "refreshPage"), _obj), _applyDecoratedDescriptor(_obj, "dismiss", [_dec3], Object.getOwnPropertyDescriptor(_obj, "dismiss"), _obj), _applyDecoratedDescriptor(_obj, "_resetTimeoutHandler", [_dec4], Object.getOwnPropertyDescriptor(_obj, "_resetTimeoutHandler"), _obj)), _obj)));

  _exports.default = _default;
});