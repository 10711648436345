define("discourse/widgets/membership-request-accepted-notification-item", ["discourse/widgets/default-notification-item", "I18n", "discourse/widgets/widget", "discourse/lib/url"], function (_defaultNotificationItem, _I18n, _widget, _url) {
  "use strict";

  (0, _widget.createWidgetFrom)(_defaultNotificationItem.DefaultNotificationItem, "membership-request-accepted-notification-item", {
    url: function url(data) {
      return (0, _url.groupPath)(data.group_name);
    },
    text: function text(notificationName, data) {
      return _I18n.default.t("notifications.".concat(notificationName), {
        group_name: data.group_name
      });
    }
  });
});