define("discourse/routes/build-private-messages-route", ["exports", "I18n", "discourse/models/user-action", "discourse/routes/user-topic-list", "discourse/lib/cached-topic-list", "discourse-common/lib/icon-library", "discourse-common/lib/get-url"], function (_exports, _I18n, _userAction, _userTopicList, _cachedTopicList, _iconLibrary, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ARCHIVE_FILTER = _exports.INBOX_FILTER = _exports.UNREAD_FILTER = _exports.NEW_FILTER = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var NEW_FILTER = "new";
  _exports.NEW_FILTER = NEW_FILTER;
  var UNREAD_FILTER = "unread";
  _exports.UNREAD_FILTER = UNREAD_FILTER;
  var INBOX_FILTER = "inbox";
  _exports.INBOX_FILTER = INBOX_FILTER;
  var ARCHIVE_FILTER = "archive"; // A helper to build a user topic list route

  _exports.ARCHIVE_FILTER = ARCHIVE_FILTER;

  var _default = function _default(inboxType, path, filter) {
    var _dec, _dec2, _obj;

    return _userTopicList.default.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
      userActionType: _userAction.default.TYPES.messages_received,
      titleToken: function titleToken() {
        return [_I18n.default.t("user.messages.".concat(filter)), _I18n.default.t("user.private_messages")];
      },
      didTransition: function didTransition() {
        this.controllerFor("user-topics-list")._showFooter();

        return true;
      },
      model: function model() {
        var _this = this;

        var topicListFilter = "topics/" + path + "/" + this.modelFor("user").get("username_lower");
        var lastTopicList = (0, _cachedTopicList.findOrResetCachedTopicList)(this.session, topicListFilter);
        return lastTopicList ? lastTopicList : this.store.findFiltered("topicList", {
          filter: topicListFilter
        }).then(function (model) {
          // andrei: we agreed that this is an anti pattern,
          // it's better to avoid mutating a rest model like this
          // this place we'll be refactored later
          // see https://github.com/discourse/discourse/pull/14313#discussion_r708784704
          model.set("emptyState", _this.emptyState());
          return model;
        });
      },
      setupController: function setupController() {
        this._super.apply(this, arguments);

        var userPrivateMessagesController = this.controllerFor("user-private-messages");
        var userTopicsListController = this.controllerFor("user-topics-list");
        userTopicsListController.setProperties({
          hideCategory: true,
          showPosters: true,
          tagsForUser: this.modelFor("user").get("username_lower"),
          selected: [],
          showToggleBulkSelect: true,
          filter: filter,
          group: null,
          inbox: inboxType
        });
        userTopicsListController.subscribe();
        userPrivateMessagesController.setProperties({
          archive: false,
          pmView: inboxType,
          group: null
        });
        this.searchService.set("contextType", "private_messages");
      },
      emptyState: function emptyState() {
        var title = _I18n.default.t("user.no_messages_title");

        var body = _I18n.default.t("user.no_messages_body", {
          aboutUrl: (0, _getUrl.default)("/about"),
          icon: (0, _iconLibrary.iconHTML)("envelope")
        }).htmlSafe();

        return {
          title: title,
          body: body
        };
      },
      deactivate: function deactivate() {
        this.controllerFor("user-topics-list").unsubscribe();
        this.searchService.set("searchContext", this.controllerFor("user").get("model.searchContext"));
      },
      dismissReadOptions: function dismissReadOptions() {
        return {};
      },
      dismissReadTopics: function dismissReadTopics(dismissTopics) {
        var operationType = dismissTopics ? "topics" : "posts";
        var controller = this.controllerFor("user-topics-list");
        controller.send("dismissRead", operationType, _objectSpread({
          private_message_inbox: inboxType
        }, this.dismissReadOptions()));
      }
    }, (_applyDecoratedDescriptor(_obj, "didTransition", [_dec], Object.getOwnPropertyDescriptor(_obj, "didTransition"), _obj), _applyDecoratedDescriptor(_obj, "dismissReadTopics", [_dec2], Object.getOwnPropertyDescriptor(_obj, "dismissReadTopics"), _obj)), _obj)));
  };

  _exports.default = _default;
});