define("discourse/initializers/mobile", ["exports", "discourse/lib/mobile", "discourse-common/resolver"], function (_exports, _mobile, _resolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Initializes the `Mobile` helper object.
  var _default = {
    name: "mobile",
    after: "inject-objects",
    initialize: function initialize(container) {
      _mobile.default.init();

      var site = container.lookup("site:main");
      site.set("mobileView", _mobile.default.mobileView);
      site.set("isMobileDevice", _mobile.default.isMobileDevice);
      (0, _resolver.setResolverOption)("mobileView", _mobile.default.mobileView);
    }
  };
  _exports.default = _default;
});