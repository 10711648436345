define("discourse/controllers/user-topics-list", ["exports", "discourse-common/utils/decorators", "discourse/mixins/bulk-topic-selection", "discourse/models/topic", "discourse/routes/build-private-messages-route"], function (_exports, _decorators, _bulkTopicSelection, _topic, _buildPrivateMessagesRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // Lists of topics on a user's page.
  var _default = Ember.Controller.extend(_bulkTopicSelection.default, (_dec = (0, _decorators.default)("model.topics.length", "incomingCount"), _dec2 = (0, _decorators.observes)("model.canLoadMore"), _dec3 = (0, _decorators.default)("filter", "model.topics.length"), _dec4 = (0, _decorators.default)("filter", "model.topics.length"), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_obj = {
    application: Ember.inject.controller(),
    hideCategory: false,
    showPosters: false,
    channel: null,
    tagsForUser: null,
    incomingCount: Ember.computed.reads("pmTopicTrackingState.newIncoming.length"),
    noContent: function noContent(topicsLength, incomingCount) {
      return topicsLength === 0 && incomingCount === 0;
    },
    saveScrollPosition: function saveScrollPosition() {
      this.session.set("topicListScrollPosition", $(window).scrollTop());
    },
    _showFooter: function _showFooter() {
      this.set("application.showFooter", !this.get("model.canLoadMore"));
    },
    showResetNew: function showResetNew(filter, hasTopics) {
      return filter === _buildPrivateMessagesRoute.NEW_FILTER && hasTopics;
    },
    showDismissRead: function showDismissRead(filter, hasTopics) {
      return filter === _buildPrivateMessagesRoute.UNREAD_FILTER && hasTopics;
    },
    subscribe: function subscribe() {
      this.pmTopicTrackingState.trackIncoming(this.inbox, this.filter);
    },
    unsubscribe: function unsubscribe() {
      this.pmTopicTrackingState.stopIncomingTracking();
    },
    resetNew: function resetNew() {
      var _this = this;

      var topicIds = this.selected ? this.selected.map(function (topic) {
        return topic.id;
      }) : null;
      var opts = {
        inbox: this.inbox,
        topicIds: topicIds
      };

      if (this.group) {
        opts.groupName = this.group.name;
      }

      _topic.default.pmResetNew(opts).then(function (result) {
        if (result && result.topic_ids.length > 0) {
          _this.pmTopicTrackingState.removeTopics(result.topic_ids);

          _this.send("refresh");
        }
      });
    },
    loadMore: function loadMore() {
      this.model.loadMore();
    },
    showInserted: function showInserted() {
      this.model.loadBefore(this.pmTopicTrackingState.newIncoming);
      this.pmTopicTrackingState.resetIncomingTracking();
      return false;
    },
    refresh: function refresh() {
      this.send("triggerRefresh");
    }
  }, (_applyDecoratedDescriptor(_obj, "noContent", [_dec], Object.getOwnPropertyDescriptor(_obj, "noContent"), _obj), _applyDecoratedDescriptor(_obj, "_showFooter", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_showFooter"), _obj), _applyDecoratedDescriptor(_obj, "showResetNew", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showResetNew"), _obj), _applyDecoratedDescriptor(_obj, "showDismissRead", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showDismissRead"), _obj), _applyDecoratedDescriptor(_obj, "resetNew", [_dec5], Object.getOwnPropertyDescriptor(_obj, "resetNew"), _obj), _applyDecoratedDescriptor(_obj, "loadMore", [_dec6], Object.getOwnPropertyDescriptor(_obj, "loadMore"), _obj), _applyDecoratedDescriptor(_obj, "showInserted", [_dec7], Object.getOwnPropertyDescriptor(_obj, "showInserted"), _obj), _applyDecoratedDescriptor(_obj, "refresh", [_dec8], Object.getOwnPropertyDescriptor(_obj, "refresh"), _obj)), _obj)));

  _exports.default = _default;
});