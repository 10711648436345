define("discourse/initializers/live-development", ["exports", "discourse/lib/url", "handlebars", "discourse-common/config/environment"], function (_exports, _url, _handlebars, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //  Use the message bus for live reloading of components for faster development.
  var _default = {
    name: "live-development",
    initialize: function initialize(container) {
      var _this = this;

      var messageBus = container.lookup("message-bus:main");
      var session = container.lookup("session:main"); // Preserve preview_theme_id=## and pp=async-flamegraph parameters across pages

      var params = new URLSearchParams(window.location.search);
      var previewThemeId = params.get("preview_theme_id");
      var flamegraph = params.get("pp") === "async-flamegraph";

      if (flamegraph || previewThemeId !== null) {
        ["replaceState", "pushState"].forEach(function (funcName) {
          var originalFunc = window.history[funcName];

          window.history[funcName] = function (stateObj, name, rawUrl) {
            var url = new URL(rawUrl, window.location);

            if (previewThemeId !== null) {
              url.searchParams.set("preview_theme_id", previewThemeId);
            }

            if (flamegraph) {
              url.searchParams.set("pp", "async-flamegraph");
            }

            return originalFunc.call(window.history, stateObj, name, url.href);
          };
        });
      } // Custom header changes


      $("header.custom").each(function () {
        var header = $(this);
        return messageBus.subscribe("/header-change/" + $(this).data("id"), function (data) {
          return header.html(data);
        });
      }); // Useful to export this for debugging purposes

      if ((0, _environment.isDevelopment)()) {
        window.DiscourseURL = _url.default;
      } // Observe file changes


      messageBus.subscribe("/file-change", function (data) {
        if (_handlebars.default.compile && !Ember.TEMPLATES.empty) {
          // hbs notifications only happen in dev
          Ember.TEMPLATES.empty = _handlebars.default.compile("<div></div>");
        }

        data.forEach(function (me) {
          if (me === "refresh") {
            // Refresh if necessary
            document.location.reload(true);
          } else if (me.new_href && me.target) {
            var link_target = !!me.theme_id ? "[data-target='".concat(me.target, "'][data-theme-id='").concat(me.theme_id, "']") : "[data-target='".concat(me.target, "']");
            var links = document.querySelectorAll("link".concat(link_target));

            if (links.length > 0) {
              var lastLink = links[links.length - 1]; // this check is useful when message-bus has multiple file updates
              // it avoids the browser doing a lot of work for nothing
              // should the filenames be unchanged

              if (lastLink.href.split("/").pop() !== me.new_href.split("/").pop()) {
                _this.refreshCSS(lastLink, me.new_href);
              }
            }
          }
        });
      }, session.mbLastFileChangeId);
    },
    refreshCSS: function refreshCSS(node, newHref) {
      var reloaded = node.cloneNode(true);
      reloaded.href = newHref;
      node.insertAdjacentElement("afterend", reloaded);
      setTimeout(function () {
        if (node && node.parentNode) {
          node.parentNode.removeChild(node);
        }
      }, 500);
    }
  };
  _exports.default = _default;
});