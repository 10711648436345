define("discourse/components/group-manage-logs-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    expandDetails: false,
    actions: {
      toggleDetails: function toggleDetails() {
        this.toggleProperty("expandDetails");
      },
      filter: function filter(params) {
        this.set("filters.".concat(params.key), params.value);
      }
    }
  });

  _exports.default = _default;
});