define("discourse/components/tags-admin-dropdown", ["exports", "select-kit/components/dropdown-select-box", "I18n"], function (_exports, _dropdownSelectBox, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dropdownSelectBox.default.extend({
    pluginApiIdentifiers: ["tags-admin-dropdown"],
    classNames: ["tags-admin-dropdown"],
    actionsMapping: null,
    selectKitOptions: {
      icons: ["wrench", "caret-down"],
      showFullTitle: false
    },
    content: Ember.computed(function () {
      return [{
        id: "manageGroups",
        name: _I18n.default.t("tagging.manage_groups"),
        description: _I18n.default.t("tagging.manage_groups_description"),
        icon: "tags"
      }, {
        id: "uploadTags",
        name: _I18n.default.t("tagging.upload"),
        description: _I18n.default.t("tagging.upload_description"),
        icon: "upload"
      }, {
        id: "deleteUnusedTags",
        name: _I18n.default.t("tagging.delete_unused"),
        description: _I18n.default.t("tagging.delete_unused_description"),
        icon: "trash-alt"
      }];
    }),
    actions: {
      onChange: function onChange(id) {
        var action = this.actionsMapping[id];

        if (action) {
          action();
        }
      }
    }
  });

  _exports.default = _default;
});