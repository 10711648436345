define("discourse/components/composer-toggles", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("composeState"), _dec2 = (0, _decorators.default)("showToolbar"), _dec3 = (0, _decorators.default)("composeState"), _dec4 = (0, _decorators.default)("composeState"), _dec5 = (0, _decorators.default)("composeState"), _dec6 = (0, _decorators.default)("disableTextarea"), (_obj = {
    tagName: "",
    toggleTitle: function toggleTitle(composeState) {
      return composeState === "draft" || composeState === "saving" ? "composer.abandon" : "composer.collapse";
    },
    toggleToolbarTitle: function toggleToolbarTitle(showToolbar) {
      return showToolbar ? "composer.hide_toolbar" : "composer.show_toolbar";
    },
    fullscreenTitle: function fullscreenTitle(composeState) {
      return composeState === "draft" ? "composer.open" : composeState === "fullscreen" ? "composer.exit_fullscreen" : "composer.enter_fullscreen";
    },
    toggleIcon: function toggleIcon(composeState) {
      return composeState === "draft" || composeState === "saving" ? "times" : "chevron-down";
    },
    fullscreenIcon: function fullscreenIcon(composeState) {
      return composeState === "draft" ? "chevron-up" : composeState === "fullscreen" ? "discourse-compress" : "discourse-expand";
    },
    showFullScreenButton: function showFullScreenButton(disableTextarea) {
      if (this.site.mobileView) {
        return false;
      }

      return !disableTextarea;
    }
  }, (_applyDecoratedDescriptor(_obj, "toggleTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleTitle"), _obj), _applyDecoratedDescriptor(_obj, "toggleToolbarTitle", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleToolbarTitle"), _obj), _applyDecoratedDescriptor(_obj, "fullscreenTitle", [_dec3], Object.getOwnPropertyDescriptor(_obj, "fullscreenTitle"), _obj), _applyDecoratedDescriptor(_obj, "toggleIcon", [_dec4], Object.getOwnPropertyDescriptor(_obj, "toggleIcon"), _obj), _applyDecoratedDescriptor(_obj, "fullscreenIcon", [_dec5], Object.getOwnPropertyDescriptor(_obj, "fullscreenIcon"), _obj), _applyDecoratedDescriptor(_obj, "showFullScreenButton", [_dec6], Object.getOwnPropertyDescriptor(_obj, "showFullScreenButton"), _obj)), _obj)));

  _exports.default = _default;
});