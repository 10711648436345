define("discourse/components/cook-text", ["exports", "discourse/lib/ajax", "discourse/lib/text", "discourse/lib/load-oneboxes", "pretty-text/upload-short-url"], function (_exports, _ajax, _text, _loadOneboxes, _uploadShortUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CookText = Ember.Component.extend({
    cooked: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _text.cookAsync)(this.rawText).then(function (cooked) {
        _this.set("cooked", cooked);
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (this.paintOneboxes) {
        (0, _loadOneboxes.loadOneboxes)(this.element, _ajax.ajax, this.topicId, this.categoryId, this.siteSettings.max_oneboxes_per_post, false // refresh
        );
      }

      (0, _uploadShortUrl.resolveAllShortUrls)(_ajax.ajax, this.siteSettings, this.element, this.opts);
    }
  });
  CookText.reopenClass({
    positionalParams: ["rawText"]
  });
  var _default = CookText;
  _exports.default = _default;
});