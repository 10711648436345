define("discourse/templates/components/bread-crumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GndbpqIU",
    "block": "{\"symbols\":[\"breadcrumb\"],\"statements\":[[4,\"each\",[[24,[\"categoryBreadcrumbs\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"hasOptions\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[1,[28,\"category-drop\",null,[[\"category\",\"categories\",\"tagId\",\"editingCategory\",\"editingCategoryTab\",\"options\"],[[23,1,[\"category\"]],[23,1,[\"options\"]],[24,[\"tag\",\"id\"]],[24,[\"editingCategory\"]],[24,[\"editingCategoryTab\"]],[28,\"hash\",null,[[\"parentCategory\",\"subCategory\",\"noSubcategories\",\"autoFilterable\"],[[23,1,[\"parentCategory\"]],[23,1,[\"isSubcategory\"]],[23,1,[\"noSubcategories\"]],true]]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showTagsSection\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"additionalTags\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[1,[28,\"tags-intersection-chooser\",null,[[\"currentCategory\",\"mainTag\",\"additionalTags\",\"options\"],[[24,[\"category\"]],[24,[\"tag\",\"id\"]],[24,[\"additionalTags\"]],[28,\"hash\",null,[[\"categoryId\"],[[24,[\"category\",\"id\"]]]]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[1,[28,\"tag-drop\",null,[[\"currentCategory\",\"noSubcategories\",\"tagId\"],[[24,[\"category\"]],[24,[\"noSubcategories\"]],[24,[\"tag\",\"id\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"connectorTagName\"],[\"bread-crumbs-right\",\"li\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/bread-crumbs.hbs"
    }
  });

  _exports.default = _default;
});