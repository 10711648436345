define("discourse/helpers/icon-or-image", ["exports", "discourse-common/lib/icon-library", "discourse-common/lib/helpers"], function (_exports, _iconLibrary, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helpers.htmlHelper)(function (_ref) {
    var icon = _ref.icon,
        image = _ref.image;

    if (!Ember.isEmpty(image)) {
      return "<img src='".concat(image, "'>");
    }

    if (Ember.isEmpty(icon)) {
      return "";
    }

    icon = (0, _iconLibrary.convertIconClass)(icon);
    return (0, _iconLibrary.iconHTML)(icon);
  });

  _exports.default = _default;
});