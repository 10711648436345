define("discourse/lib/url", ["exports", "discourse-common/lib/get-url", "discourse/models/category", "discourse/lib/lock-on", "discourse/models/session", "discourse/models/user", "discourse/lib/utilities", "discourse/lib/offset-calculator", "discourse-common/config/environment"], function (_exports, _getUrl, _category, _lockOn, _session, _user, _utilities, _offsetCalculator, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rewritePath = rewritePath;
  _exports.clearRewrites = clearRewrites;
  _exports.userPath = userPath;
  _exports.groupPath = groupPath;
  _exports.jumpToElement = jumpToElement;
  _exports.setURLContainer = setURLContainer;
  _exports.prefixProtocol = prefixProtocol;
  _exports.getCategoryAndTagUrl = getCategoryAndTagUrl;
  _exports.getEditCategoryUrl = getEditCategoryUrl;
  _exports.default = _exports.TOPIC_URL_REGEXP = void 0;
  var rewrites = [];
  var TOPIC_URL_REGEXP = /\/t\/([^\/]*[^\d\/][^\/]*)\/(\d+)\/?(\d+)?/; // We can add links here that have server side responses but not client side.

  _exports.TOPIC_URL_REGEXP = TOPIC_URL_REGEXP;
  var SERVER_SIDE_ONLY = [/^\/assets\//, /^\/uploads\//, /^\/secure-media-uploads\//, /^\/stylesheets\//, /^\/site_customizations\//, /^\/raw\//, /^\/posts\/\d+\/raw/, /^\/raw\/\d+/, /^\/wizard/, /\.rss$/, /\.json$/, /^\/admin\/upgrade$/, /^\/logs($|\/)/, /^\/admin\/customize\/watched_words\/action\/[^\/]+\/download$/, /^\/pub\//, /^\/invites\//, /^\/styleguide/]; // The amount of height (in pixels) that we factor in when jumpEnd is called so
  // that we show a little bit of the post text even on mobile devices instead of
  // scrolling to "suggested topics".

  var JUMP_END_BUFFER = 250;

  function rewritePath(path) {
    var params = path.split("?");
    var result = params[0];
    rewrites.forEach(function (rw) {
      if ((rw.opts.exceptions || []).some(function (ex) {
        return path.indexOf(ex) === 0;
      })) {
        return;
      }

      result = result.replace(rw.regexp, rw.replacement);
    });

    if (params.length > 1) {
      result += "?".concat(params[1]);
    }

    return result;
  }

  function clearRewrites() {
    rewrites.length = 0;
  }

  function userPath(subPath) {
    return (0, _getUrl.default)(subPath ? "/u/".concat(subPath) : "/u");
  }

  function groupPath(subPath) {
    return (0, _getUrl.default)(subPath ? "/g/".concat(subPath) : "/g");
  }

  var _jumpScheduled = false;
  var _transitioning = false;
  var lockon = null;

  function jumpToElement(elementId) {
    if (_jumpScheduled || Ember.isEmpty(elementId)) {
      return;
    }

    var selector = "#main #".concat(elementId, ", a[name=").concat(elementId, "]");
    _jumpScheduled = true;
    Ember.run.schedule("afterRender", function () {
      if (lockon) {
        lockon.clearLock();
      }

      lockon = new _lockOn.default(selector, {
        finished: function finished() {
          _jumpScheduled = false;
          lockon = null;
        }
      });
      lockon.lock();
    });
  }

  var DiscourseURL = Ember.Object.extend({
    isJumpScheduled: function isJumpScheduled() {
      return _transitioning || _jumpScheduled;
    },
    // Jumps to a particular post in the stream
    jumpToPost: function jumpToPost(postNumber, opts) {
      opts = opts || {};
      var holderId = "#post_".concat(postNumber);
      _transitioning = postNumber > 1;
      Ember.run.schedule("afterRender", function () {
        if (opts.jumpEnd) {
          var $holder = $(holderId);
          var holderHeight = $holder.height();
          var windowHeight = $(window).height() - (0, _offsetCalculator.default)();

          if (holderHeight > windowHeight) {
            $(window).scrollTop($holder.offset().top + (holderHeight - JUMP_END_BUFFER));
            _transitioning = false;
            return;
          }
        }

        if (postNumber === 1 && !opts.anchor) {
          $(window).scrollTop(0);
          _transitioning = false;
          return;
        }

        var selector;
        var holder;

        if (opts.anchor) {
          selector = "#main #".concat(opts.anchor, ", a[name=").concat(opts.anchor, "]");
          holder = document.querySelector(selector);
        }

        if (!holder) {
          selector = holderId;
          holder = document.querySelector(selector);
        }

        if (lockon) {
          lockon.clearLock();
        }

        lockon = new _lockOn.default(selector, {
          originalTopOffset: opts.originalTopOffset,
          finished: function finished() {
            _transitioning = false;
            lockon = null;
          }
        });

        if (holder && opts.skipIfOnScreen) {
          var elementTop = lockon.elementTop();
          var scrollTop = $(window).scrollTop();

          var _windowHeight = $(window).height() - (0, _offsetCalculator.default)();

          var height = $(holder).height();

          if (elementTop > scrollTop && elementTop + height < scrollTop + _windowHeight) {
            _transitioning = false;
            return;
          }
        }

        lockon.lock();

        if (lockon.elementTop() < 1) {
          _transitioning = false;
          return;
        }
      });
    },
    replaceState: function replaceState(path) {
      var _this = this;

      if (this.router.currentURL !== path) {
        // Always use replaceState in the next runloop to prevent weird routes changing
        // while URLs are loading. For example, while a topic loads it sets `currentPost`
        // which triggers a replaceState even though the topic hasn't fully loaded yet!
        Ember.run.next(function () {
          // Using the private `_routerMicrolib` is not ideal, but Ember doesn't provide
          // any other way for us to do `history.replaceState` without a full transition
          _this.router._routerMicrolib.replaceURL(path);
        });
      }
    },
    routeToTag: function routeToTag(a) {
      // skip when we are provided nowhere to route to
      if (!a || !a.href) {
        return false;
      }

      if (a.host && a.host !== document.location.host) {
        document.location = a.href;
        return false;
      }

      return this.routeTo(a.href);
    },

    /**
      Our custom routeTo method is used to intelligently overwrite default routing
      behavior.
       It contains the logic necessary to route within a topic using replaceState to
      keep the history intact.
    **/
    routeTo: function routeTo(path, opts) {
      opts = opts || {};

      if (Ember.isEmpty(path)) {
        return;
      }

      if (_session.default.currentProp("requiresRefresh")) {
        return this.redirectTo(path);
      }

      var pathname = path.replace(/(https?\:)?\/\/[^\/]+/, "");

      if (!this.isInternal(path)) {
        return this.redirectTo(path);
      }

      var serverSide = SERVER_SIDE_ONLY.some(function (r) {
        return pathname.match(r);
      });

      if (serverSide) {
        this.redirectTo(path);
        return;
      } // Scroll to the same page, different anchor


      var m = /^#(.+)$/.exec(path);

      if (m) {
        jumpToElement(m[1]);
        return this.replaceState(path);
      }

      var oldPath = this.router.currentURL;
      path = path.replace(/(https?\:)?\/\/[^\/]+/, ""); // Rewrite /my/* urls

      var myPath = (0, _getUrl.default)("/my");
      var fullPath = (0, _getUrl.default)(path);

      if (fullPath.indexOf(myPath) === 0) {
        var currentUser = _user.default.current();

        if (currentUser) {
          path = fullPath.replace(myPath, userPath(currentUser.get("username_lower")));
        } else {
          return this.redirectTo("/login-preferences");
        }
      } // handle prefixes


      if (path.indexOf("/") === 0) {
        path = (0, _getUrl.withoutPrefix)(path);
      }

      path = rewritePath(path);

      if (typeof opts.afterRouteComplete === "function") {
        Ember.run.schedule("afterRender", opts.afterRouteComplete);
      }

      if (this.navigatedToPost(oldPath, path, opts)) {
        return;
      }

      if (oldPath === path) {
        // If navigating to the same path send an app event.
        // Views can watch it and tell their controllers to refresh
        this.appEvents.trigger("url:refresh");
      } // TODO: Extract into rules we can inject into the URL handler


      if (this.navigatedToHome(oldPath, path, opts)) {
        return;
      } // Navigating to empty string is the same as root


      if (path === "") {
        path = "/";
      }

      return this.handleURL(path, opts);
    },
    routeToUrl: function routeToUrl(url) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.routeTo((0, _getUrl.default)(url), opts);
    },
    rewrite: function rewrite(regexp, replacement, opts) {
      rewrites.push({
        regexp: regexp,
        replacement: replacement,
        opts: opts || {}
      });
    },
    redirectAbsolute: function redirectAbsolute(url) {
      // Redirects will kill a test runner
      if ((0, _environment.isTesting)()) {
        return true;
      }

      window.location = url;
      return true;
    },
    redirectTo: function redirectTo(url) {
      return this.redirectAbsolute((0, _getUrl.default)(url));
    },
    // Determines whether a URL is internal or not
    isInternal: function isInternal(url) {
      if (url && url.length) {
        if (url.indexOf("//") === 0) {
          url = "http:" + url;
        }

        if (url.indexOf("#") === 0) {
          return true;
        }

        if (url.indexOf("/") === 0) {
          return true;
        }

        if (url.indexOf(this.origin()) === 0) {
          return true;
        }

        if (url.replace(/^http/, "https").indexOf(this.origin()) === 0) {
          return true;
        }

        if (url.replace(/^https/, "http").indexOf(this.origin()) === 0) {
          return true;
        }
      }

      return false;
    },

    /**
      If the URL is in the topic form, /t/something/:topic_id/:post_number
      then we want to apply some special logic. If the post_number changes within the
      same topic, use replaceState and instruct our controller to load more posts.
    **/
    navigatedToPost: function navigatedToPost(oldPath, path, routeOpts) {
      var _this2 = this;

      var newMatches = TOPIC_URL_REGEXP.exec(path);
      var newTopicId = newMatches ? newMatches[2] : null;

      if (newTopicId) {
        var oldMatches = TOPIC_URL_REGEXP.exec(oldPath);
        var oldTopicId = oldMatches ? oldMatches[2] : null; // If the topic_id is the same

        if (oldTopicId === newTopicId) {
          this.replaceState(path);
          var topicController = this.container.lookup("controller:topic");
          var opts = {};
          var postStream = topicController.get("model.postStream");

          if (newMatches[3]) {
            opts.nearPost = newMatches[3];
          }

          if (path.match(/last$/)) {
            opts.nearPost = topicController.get("model.highest_post_number");
          }

          if (!routeOpts.keepFilter) {
            opts.cancelFilter = true;
          }

          postStream.refresh(opts).then(function () {
            var closest = postStream.closestPostNumberFor(opts.nearPost || 1);
            topicController.setProperties({
              "model.currentPost": closest,
              enteredAt: Date.now().toString()
            });

            _this2.appEvents.trigger("post:highlight", closest);

            var jumpOpts = {
              skipIfOnScreen: routeOpts.skipIfOnScreen,
              jumpEnd: routeOpts.jumpEnd
            };
            var anchorMatch = /#(.+)$/.exec(path);

            if (anchorMatch) {
              jumpOpts.anchor = anchorMatch[1];
            }

            _this2.jumpToPost(closest, jumpOpts);
          }); // Abort routing, we have replaced our state.

          return true;
        }
      }

      return false;
    },

    /**
      @private
       Handle the custom case of routing to the root path from itself.
       @param {String} oldPath the previous path we were on
      @param {String} path the path we're navigating to
    **/
    navigatedToHome: function navigatedToHome(oldPath, path) {
      var homepage = (0, _utilities.defaultHomepage)();

      if (window.history && window.history.pushState && (path === "/" || path === "/" + homepage) && (oldPath === "/" || oldPath === "/" + homepage)) {
        this.appEvents.trigger("url:refresh");
        return true;
      }

      return false;
    },
    // This has been extracted so it can be tested.
    origin: function origin() {
      var prefix = (0, _getUrl.default)("/");
      return window.location.origin + (prefix === "/" ? "" : prefix);
    },

    get router() {
      return this.container.lookup("router:main");
    },

    get appEvents() {
      return this.container.lookup("service:app-events");
    },

    controllerFor: function controllerFor(name) {
      return this.container.lookup("controller:" + name);
    },

    /**
      Be wary of looking up the router. In this case, we have links in our
      HTML, say form compiled markdown posts, that need to be routed.
    **/
    handleURL: function handleURL(path, opts) {
      opts = opts || {};
      var router = this.router;

      if (opts.replaceURL) {
        this.replaceState(path);
      }

      var split = path.split("#");
      var elementId;

      if (split.length === 2) {
        path = split[0];
        elementId = split[1];
      } // The default path has a hack to allow `/` to default to defaultHomepage
      // via BareRouter.handleUrl


      var transition;

      if (path === "/" || path.substring(0, 2) === "/?") {
        router._routerMicrolib.updateURL(path);

        transition = router.handleURL(path);
      } else {
        transition = router.transitionTo(path);
      }

      transition._discourse_intercepted = true;
      transition._discourse_anchor = elementId;
      transition._discourse_original_url = path;
      var promise = transition.promise || transition;
      promise.then(function () {
        return jumpToElement(elementId);
      });
    }
  });

  var _urlInstance = DiscourseURL.create();

  function setURLContainer(container) {
    _urlInstance.container = container;
    Ember.setOwner(_urlInstance, container);
  }

  function prefixProtocol(url) {
    return url.indexOf("://") === -1 && url.indexOf("mailto:") !== 0 ? "https://" + url : url;
  }

  function getCategoryAndTagUrl(category, subcategories, tag) {
    var url;

    if (category) {
      url = category.path;

      if (subcategories && category.default_list_filter === "none") {
        url += "/all";
      } else if (!subcategories && category.default_list_filter === "all") {
        url += "/none";
      }
    }

    if (tag) {
      url = url ? "/tags" + url + "/" + tag.toLowerCase() : "/tag/" + tag.toLowerCase();
    }

    return (0, _getUrl.default)(url || "/");
  }

  function getEditCategoryUrl(category, subcategories, tab) {
    var url = "/c/".concat(_category.default.slugFor(category), "/edit");

    if (tab) {
      url += "/".concat(tab);
    }

    return (0, _getUrl.default)(url);
  }

  var _default = _urlInstance;
  _exports.default = _default;
});