define("discourse/helpers/capitalize-string", ["exports", "discourse-common/lib/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helpers.htmlHelper)(function (str) {
    return str[0].toUpperCase() + str.slice(1);
  });

  _exports.default = _default;
});