define("discourse/controllers/user-invited-show", ["exports", "bootbox", "discourse-common/config/environment", "discourse-common/lib/debounce", "discourse-common/utils/decorators", "discourse/lib/ajax-error", "discourse/lib/show-modal", "discourse/models/invite", "I18n"], function (_exports, _bootbox, _environment, _debounce, _decorators, _ajaxError, _showModal, _invite, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.observes)("searchTerm"), _dec2 = (0, _decorators.default)("model"), _dec3 = (0, _decorators.default)("filter"), _dec4 = (0, _decorators.default)("invitesCount", "filter"), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_obj = {
    user: null,
    model: null,
    filter: null,
    invitesCount: null,
    canLoadMore: true,
    invitesLoading: false,
    reinvitedAll: false,
    removedAll: false,
    searchTerm: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set("searchTerm", "");
    },
    _searchTermChanged: function _searchTermChanged() {
      (0, _debounce.default)(this, function () {
        var _this = this;

        _invite.default.findInvitedBy(this.user, this.filter, this.searchTerm).then(function (invites) {
          return _this.set("model", invites);
        });
      }, _environment.INPUT_DELAY);
    },
    inviteRedeemed: Ember.computed.equal("filter", "redeemed"),
    inviteExpired: Ember.computed.equal("filter", "expired"),
    invitePending: Ember.computed.equal("filter", "pending"),
    hasEmailInvites: function hasEmailInvites(model) {
      return model.invites.some(function (invite) {
        return invite.email;
      });
    },
    showBulkActionButtons: function showBulkActionButtons(filter) {
      return filter === "pending" && this.model.invites.length > 0 && this.currentUser.staff;
    },
    canInviteToForum: Ember.computed.reads("currentUser.can_invite_to_forum"),
    canBulkInvite: Ember.computed.reads("currentUser.admin"),
    showSearch: function showSearch(invitesCount, filter) {
      return invitesCount[filter] > 5;
    },
    createInvite: function createInvite() {
      var controller = (0, _showModal.default)("create-invite");
      controller.set("invites", this.model.invites);
    },
    createInviteCsv: function createInviteCsv() {
      (0, _showModal.default)("create-invite-bulk");
    },
    editInvite: function editInvite(invite) {
      var controller = (0, _showModal.default)("create-invite");
      controller.set("editing", true);
      controller.setInvite(invite);
    },
    destroyInvite: function destroyInvite(invite) {
      invite.destroy();
      this.model.invites.removeObject(invite);
    },
    destroyAllExpired: function destroyAllExpired() {
      var _this2 = this;

      _bootbox.default.confirm(_I18n.default.t("user.invited.remove_all_confirm"), function (confirm) {
        if (confirm) {
          _invite.default.destroyAllExpired().then(function () {
            _this2.set("removedAll", true);

            _this2.send("triggerRefresh");
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    },
    reinvite: function reinvite(invite) {
      invite.reinvite();
      return false;
    },
    reinviteAll: function reinviteAll() {
      var _this3 = this;

      _bootbox.default.confirm(_I18n.default.t("user.invited.reinvite_all_confirm"), function (confirm) {
        if (confirm) {
          _invite.default.reinviteAll().then(function () {
            return _this3.set("reinvitedAll", true);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    },
    loadMore: function loadMore() {
      var _this4 = this;

      var model = this.model;

      if (this.canLoadMore && !this.invitesLoading) {
        this.set("invitesLoading", true);

        _invite.default.findInvitedBy(this.user, this.filter, this.searchTerm, model.invites.length).then(function (invite_model) {
          _this4.set("invitesLoading", false);

          model.invites.pushObjects(invite_model.invites);

          if (invite_model.invites.length === 0 || invite_model.invites.length < _this4.siteSettings.invites_per_page) {
            _this4.set("canLoadMore", false);
          }
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "_searchTermChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "_searchTermChanged"), _obj), _applyDecoratedDescriptor(_obj, "hasEmailInvites", [_dec2], Object.getOwnPropertyDescriptor(_obj, "hasEmailInvites"), _obj), _applyDecoratedDescriptor(_obj, "showBulkActionButtons", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showBulkActionButtons"), _obj), _applyDecoratedDescriptor(_obj, "showSearch", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showSearch"), _obj), _applyDecoratedDescriptor(_obj, "createInvite", [_dec5], Object.getOwnPropertyDescriptor(_obj, "createInvite"), _obj), _applyDecoratedDescriptor(_obj, "createInviteCsv", [_dec6], Object.getOwnPropertyDescriptor(_obj, "createInviteCsv"), _obj), _applyDecoratedDescriptor(_obj, "editInvite", [_dec7], Object.getOwnPropertyDescriptor(_obj, "editInvite"), _obj), _applyDecoratedDescriptor(_obj, "destroyInvite", [_dec8], Object.getOwnPropertyDescriptor(_obj, "destroyInvite"), _obj), _applyDecoratedDescriptor(_obj, "destroyAllExpired", [_dec9], Object.getOwnPropertyDescriptor(_obj, "destroyAllExpired"), _obj), _applyDecoratedDescriptor(_obj, "reinvite", [_dec10], Object.getOwnPropertyDescriptor(_obj, "reinvite"), _obj), _applyDecoratedDescriptor(_obj, "reinviteAll", [_dec11], Object.getOwnPropertyDescriptor(_obj, "reinviteAll"), _obj), _applyDecoratedDescriptor(_obj, "loadMore", [_dec12], Object.getOwnPropertyDescriptor(_obj, "loadMore"), _obj)), _obj)));

  _exports.default = _default;
});