define("discourse/components/user-flag-percentage", ["exports", "I18n", "discourse-common/utils/decorators"], function (_exports, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("percentage"), _dec2 = (0, _decorators.default)("agreed", "disagreed", "ignored"), (_obj = {
    tagName: "",
    showPercentage: function showPercentage(percentage) {
      return percentage.total >= 3;
    },
    percentage: function percentage(agreed, disagreed, ignored) {
      var total = agreed + disagreed + ignored;
      var result = {
        total: total
      };

      if (total > 0) {
        result.agreed = Math.round(agreed / total * 100);
        result.disagreed = Math.round(disagreed / total * 100);
        result.ignored = Math.round(ignored / total * 100);
      }

      var highest = Math.max(agreed, disagreed, ignored);

      if (highest === agreed) {
        result.icon = "thumbs-up";
        result.className = "agreed";
        result.label = "".concat(result.agreed, "%");
      } else if (highest === disagreed) {
        result.icon = "thumbs-down";
        result.className = "disagreed";
        result.label = "".concat(result.disagreed, "%");
      } else {
        result.icon = "external-link-alt";
        result.className = "ignored";
        result.label = "".concat(result.ignored, "%");
      }

      result.title = _I18n.default.t("review.user_percentage.summary", {
        agreed: _I18n.default.t("review.user_percentage.agreed", {
          count: result.agreed
        }),
        disagreed: _I18n.default.t("review.user_percentage.disagreed", {
          count: result.disagreed
        }),
        ignored: _I18n.default.t("review.user_percentage.ignored", {
          count: result.ignored
        }),
        count: total
      });
      return result;
    }
  }, (_applyDecoratedDescriptor(_obj, "showPercentage", [_dec], Object.getOwnPropertyDescriptor(_obj, "showPercentage"), _obj), _applyDecoratedDescriptor(_obj, "percentage", [_dec2], Object.getOwnPropertyDescriptor(_obj, "percentage"), _obj)), _obj)));

  _exports.default = _default;
});