define("discourse/components/d-button", ["exports", "discourse/lib/url", "I18n", "discourse-common/utils/decorators"], function (_exports, _url, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("icon", "computedLabel"), _dec2 = (0, _decorators.default)("title", "translatedTitle"), _dec3 = (0, _decorators.default)("label", "translatedLabel"), _dec4 = (0, _decorators.default)("ariaLabel", "translatedAriaLabel"), _dec5 = (0, _decorators.default)("ariaExpanded"), (_obj = {
    tagName: "button",
    // subclasses need this
    layoutName: "components/d-button",
    form: null,
    type: "button",
    title: null,
    translatedTitle: null,
    label: null,
    translatedLabel: null,
    ariaLabel: null,
    ariaExpanded: null,
    ariaControls: null,
    translatedAriaLabel: null,
    forwardEvent: false,
    preventFocus: false,
    onKeyDown: null,
    isLoading: Ember.computed({
      set: function set(key, value) {
        this.set("forceDisabled", value);
        return value;
      }
    }),
    classNameBindings: ["isLoading:is-loading", "btnLink::btn", "btnLink", "noText", "btnType"],
    attributeBindings: ["form", "isDisabled:disabled", "computedTitle:title", "computedAriaLabel:aria-label", "computedAriaExpanded:aria-expanded", "ariaControls:aria-controls", "tabindex", "type"],
    isDisabled: Ember.computed("disabled", "forceDisabled", function () {
      return this.forceDisabled || this.disabled;
    }),
    forceDisabled: false,
    btnIcon: Ember.computed.notEmpty("icon"),
    btnLink: Ember.computed.equal("display", "link"),
    btnType: function btnType(icon, translatedLabel) {
      if (icon) {
        return translatedLabel ? "btn-icon-text" : "btn-icon";
      } else if (translatedLabel) {
        return "btn-text";
      }
    },
    noText: Ember.computed.empty("computedLabel"),
    computedTitle: function computedTitle(title, translatedTitle) {
      if (this.title) {
        return _I18n.default.t(title);
      }

      return translatedTitle;
    },
    computedLabel: function computedLabel(label, translatedLabel) {
      if (this.label) {
        return _I18n.default.t(label);
      }

      return translatedLabel;
    },
    computedAriaLabel: function computedAriaLabel(ariaLabel, translatedAriaLabel) {
      if (ariaLabel) {
        return _I18n.default.t(ariaLabel);
      }

      if (translatedAriaLabel) {
        return translatedAriaLabel;
      }
    },
    computedAriaExpanded: function computedAriaExpanded(ariaExpanded) {
      if (ariaExpanded === true) {
        return "true";
      }

      if (ariaExpanded === false) {
        return "false";
      }
    },
    keyDown: function keyDown(e) {
      if (this.onKeyDown) {
        e.stopPropagation();
        this.onKeyDown(e);
      } else if (e.key === "Enter") {
        this._triggerAction(e);

        return false;
      }
    },
    click: function click(event) {
      this._triggerAction(event);

      return false;
    },
    mouseDown: function mouseDown(event) {
      if (this.preventFocus) {
        event.preventDefault();
      }
    },
    _triggerAction: function _triggerAction(event) {
      var action = this.action;

      if (action) {
        if (typeof action === "string") {
          // Note: This is deprecated in new Embers and needs to be removed in the future.
          // There is already a warning in the console.
          this.sendAction("action", this.actionParam);
        } else if (_typeof(action) === "object" && action.value) {
          if (this.forwardEvent) {
            action.value(this.actionParam, event);
          } else {
            action.value(this.actionParam);
          }
        } else if (typeof this.action === "function") {
          if (this.forwardEvent) {
            action(this.actionParam, event);
          } else {
            action(this.actionParam);
          }
        }
      }

      if (this.href && this.href.length) {
        _url.default.routeTo(this.href);
      }

      event.preventDefault();
      event.stopPropagation();
    }
  }, (_applyDecoratedDescriptor(_obj, "btnType", [_dec], Object.getOwnPropertyDescriptor(_obj, "btnType"), _obj), _applyDecoratedDescriptor(_obj, "computedTitle", [_dec2], Object.getOwnPropertyDescriptor(_obj, "computedTitle"), _obj), _applyDecoratedDescriptor(_obj, "computedLabel", [_dec3], Object.getOwnPropertyDescriptor(_obj, "computedLabel"), _obj), _applyDecoratedDescriptor(_obj, "computedAriaLabel", [_dec4], Object.getOwnPropertyDescriptor(_obj, "computedAriaLabel"), _obj), _applyDecoratedDescriptor(_obj, "computedAriaExpanded", [_dec5], Object.getOwnPropertyDescriptor(_obj, "computedAriaExpanded"), _obj)), _obj)));

  _exports.default = _default;
});