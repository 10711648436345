define("discourse/components/user-stream-item", ["exports", "discourse/widgets/post-small-action", "discourse/lib/computed"], function (_exports, _postSmallAction, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    classNameBindings: [":user-stream-item", ":item", // DEPRECATED: 'item' class
    "hidden", "item.deleted:deleted", "moderatorAction"],
    hidden: Ember.computed("item.hidden", function () {
      return this.get("item.hidden") && !(this.currentUser && this.currentUser.staff);
    }),
    moderatorAction: (0, _computed.propertyEqual)("item.post_type", "site.post_types.moderator_action"),
    actionDescription: (0, _postSmallAction.actionDescription)("item.action_code", "item.created_at", "item.action_code_who")
  });

  _exports.default = _default;
});