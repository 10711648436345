define("discourse/controllers/preferences/account", ["exports", "discourse/lib/computed", "discourse/mixins/can-check-emails", "I18n", "bootbox", "discourse-common/utils/decorators", "discourse/models/login-method", "discourse-common/lib/get-url", "discourse-common/lib/icon-library", "discourse/lib/ajax-error"], function (_exports, _computed, _canCheckEmails, _I18n, _bootbox, _decorators, _loginMethod, _getUrl, _iconLibrary, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_canCheckEmails.default, (_dec = (0, _decorators.default)(), _dec2 = (0, _decorators.default)("model.filteredGroups"), _dec3 = (0, _decorators.default)("model.associated_accounts"), _dec4 = (0, _decorators.default)("model.associated_accounts.[]"), _dec5 = (0, _decorators.default)("model.email", "model.secondary_emails.[]", "model.unconfirmed_emails.[]"), _dec6 = (0, _decorators.default)("model.second_factor_enabled", "canCheckEmails", "model.is_anonymous"), (_obj = {
    init: function init() {
      this._super.apply(this, arguments);

      this.saveAttrNames = ["name", "title", "primary_group_id", "flair_group_id"];
      this.set("revoking", {});
    },
    canEditName: (0, _computed.setting)("enable_names"),
    canSaveUser: true,
    newNameInput: null,
    newTitleInput: null,
    newPrimaryGroupInput: null,
    revoking: null,
    cannotDeleteAccount: Ember.computed.not("currentUser.can_delete_account"),
    deleteDisabled: Ember.computed.or("model.isSaving", "deleting", "cannotDeleteAccount"),
    reset: function reset() {
      this.set("passwordProgress", null);
    },
    nameInstructions: function nameInstructions() {
      return _I18n.default.t(this.siteSettings.full_name_required ? "user.name.instructions_required" : "user.name.instructions");
    },
    canSelectTitle: Ember.computed.gt("model.availableTitles.length", 0),
    canSelectFlair: Ember.computed.gt("model.availableFlairs.length", 0),
    canSelectPrimaryGroup: function canSelectPrimaryGroup(primaryGroupOptions) {
      return primaryGroupOptions.length > 0 && this.siteSettings.user_selected_primary_groups;
    },
    associatedAccountsLoaded: function associatedAccountsLoaded(associatedAccounts) {
      return typeof associatedAccounts !== "undefined";
    },
    authProviders: function authProviders(accounts) {
      var allMethods = (0, _loginMethod.findAll)();
      var result = allMethods.map(function (method) {
        return {
          method: method,
          account: accounts.find(function (account) {
            return account.name === method.name;
          }) // Will be undefined if no account

        };
      });
      return result.filter(function (value) {
        return value.account || value.method.can_connect;
      });
    },
    disableConnectButtons: (0, _computed.propertyNotEqual)("model.id", "currentUser.id"),
    emails: function emails(primaryEmail, secondaryEmails, unconfirmedEmails) {
      var emails = [];

      if (primaryEmail) {
        emails.push(Ember.Object.create({
          email: primaryEmail,
          primary: true,
          confirmed: true
        }));
      }

      if (secondaryEmails) {
        secondaryEmails.forEach(function (email) {
          emails.push(Ember.Object.create({
            email: email,
            confirmed: true
          }));
        });
      }

      if (unconfirmedEmails) {
        unconfirmedEmails.forEach(function (email) {
          emails.push(Ember.Object.create({
            email: email
          }));
        });
      }

      return emails.sort(function (a, b) {
        return a.email.localeCompare(b.email);
      });
    },
    canUpdateAssociatedAccounts: function canUpdateAssociatedAccounts(secondFactorEnabled, canCheckEmails, isAnonymous) {
      if (secondFactorEnabled || !canCheckEmails || isAnonymous) {
        return false;
      }

      return (0, _loginMethod.findAll)().length > 0;
    },
    actions: {
      save: function save() {
        var _this = this;

        this.set("saved", false);
        this.model.setProperties({
          name: this.newNameInput,
          title: this.newTitleInput,
          primary_group_id: this.newPrimaryGroupInput,
          flair_group_id: this.newFlairGroupId
        });
        return this.model.save(this.saveAttrNames).then(function () {
          return _this.set("saved", true);
        }).catch(_ajaxError.popupAjaxError);
      },
      setPrimaryEmail: function setPrimaryEmail(email) {
        this.model.setPrimaryEmail(email).catch(_ajaxError.popupAjaxError);
      },
      destroyEmail: function destroyEmail(email) {
        this.model.destroyEmail(email);
      },
      resendConfirmationEmail: function resendConfirmationEmail(email) {
        email.set("resending", true);
        this.model.addEmail(email.email).then(function () {
          email.set("resent", true);
        }).finally(function () {
          email.set("resending", false);
        });
      },
      delete: function _delete() {
        var _this2 = this;

        this.set("deleting", true);

        var message = _I18n.default.t("user.delete_account_confirm"),
            model = this.model,
            buttons = [{
          label: _I18n.default.t("cancel"),
          class: "d-modal-cancel",
          link: true,
          callback: function callback() {
            _this2.set("deleting", false);
          }
        }, {
          label: (0, _iconLibrary.iconHTML)("exclamation-triangle") + _I18n.default.t("user.delete_account"),
          class: "btn btn-danger",
          callback: function callback() {
            var _this3 = this;

            model.delete().then(function () {
              _bootbox.default.alert(_I18n.default.t("user.deleted_yourself"), function () {
                return window.location = (0, _getUrl.default)("/");
              });
            }, function () {
              _bootbox.default.alert(_I18n.default.t("user.delete_yourself_not_allowed"));

              _this3.set("deleting", false);
            });
          }
        }];

        _bootbox.default.dialog(message, buttons, {
          classes: "delete-account"
        });
      },
      revokeAccount: function revokeAccount(account) {
        var _this4 = this;

        this.set("revoking.".concat(account.name), true);
        this.model.revokeAssociatedAccount(account.name).then(function (result) {
          if (result.success) {
            _this4.model.associated_accounts.removeObject(account);
          } else {
            _bootbox.default.alert(result.message);
          }
        }).catch(_ajaxError.popupAjaxError).finally(function () {
          return _this4.set("revoking.".concat(account.name), false);
        });
      },
      connectAccount: function connectAccount(method) {
        method.doLogin({
          reconnect: true
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "nameInstructions", [_dec], Object.getOwnPropertyDescriptor(_obj, "nameInstructions"), _obj), _applyDecoratedDescriptor(_obj, "canSelectPrimaryGroup", [_dec2], Object.getOwnPropertyDescriptor(_obj, "canSelectPrimaryGroup"), _obj), _applyDecoratedDescriptor(_obj, "associatedAccountsLoaded", [_dec3], Object.getOwnPropertyDescriptor(_obj, "associatedAccountsLoaded"), _obj), _applyDecoratedDescriptor(_obj, "authProviders", [_dec4], Object.getOwnPropertyDescriptor(_obj, "authProviders"), _obj), _applyDecoratedDescriptor(_obj, "emails", [_dec5], Object.getOwnPropertyDescriptor(_obj, "emails"), _obj), _applyDecoratedDescriptor(_obj, "canUpdateAssociatedAccounts", [_dec6], Object.getOwnPropertyDescriptor(_obj, "canUpdateAssociatedAccounts"), _obj)), _obj)));

  _exports.default = _default;
});