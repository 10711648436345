define("discourse/templates/modal/grant-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sE0aNuOa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"class\"],[\"grant-badge\"]],{\"statements\":[[4,\"conditional-loading-spinner\",null,[[\"condition\"],[[24,[\"loading\"]]]],{\"statements\":[[4,\"if\",[[24,[\"noGrantableBadges\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[\"admin.badges.no_badges\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[0,\"\\n        \"],[1,[28,\"combo-box\",null,[[\"filterable\",\"value\",\"content\",\"none\",\"onChange\"],[true,[24,[\"selectedBadgeId\"]],[24,[\"grantableBadges\"]],\"badges.none\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selectedBadgeId\"]]],null]],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"class\",\"disabled\",\"action\",\"label\"],[\"btn-primary\",[24,[\"buttonDisabled\"]],[28,\"action\",[[23,0,[]],\"grantBadge\"],null],\"admin.badges.grant\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/grant-badge.hbs"
    }
  });

  _exports.default = _default;
});