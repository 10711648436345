define("discourse/routes/groups-new", ["exports", "discourse/routes/discourse", "discourse/models/group", "I18n"], function (_exports, _discourse, _group, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    showFooter: true,
    titleToken: function titleToken() {
      return _I18n.default.t("admin.groups.new.title");
    },
    model: function model() {
      return _group.default.create({
        automatic: false,
        visibility_level: 0,
        can_admin_group: true
      });
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model);
    },
    afterModel: function afterModel() {
      if (!this.get("currentUser.can_create_group")) {
        this.transitionTo("groups");
      }
    }
  });

  _exports.default = _default;
});