define("discourse/controllers/group-activity-posts", ["exports", "discourse/lib/computed", "discourse-common/utils/decorators"], function (_exports, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.observes)("canLoadMore"), (_obj = {
    group: Ember.inject.controller(),
    groupActivity: Ember.inject.controller(),
    application: Ember.inject.controller(),
    canLoadMore: true,
    loading: false,
    emptyText: (0, _computed.fmt)("type", "groups.empty.%@"),
    actions: {
      loadMore: function loadMore() {
        var _this = this;

        if (!this.canLoadMore) {
          return;
        }

        if (this.loading) {
          return;
        }

        this.set("loading", true);
        var posts = this.model;

        if (posts && posts.length) {
          var beforePostId = posts[posts.length - 1].get("id");
          var group = this.get("group.model");
          var categoryId = this.get("groupActivity.category_id");
          var opts = {
            beforePostId: beforePostId,
            type: this.type,
            categoryId: categoryId
          };
          group.findPosts(opts).then(function (newPosts) {
            posts.addObjects(newPosts);

            if (newPosts.length === 0) {
              _this.set("canLoadMore", false);
            }
          }).finally(function () {
            _this.set("loading", false);
          });
        }
      }
    },
    _showFooter: function _showFooter() {
      this.set("application.showFooter", !this.canLoadMore);
    }
  }, (_applyDecoratedDescriptor(_obj, "_showFooter", [_dec], Object.getOwnPropertyDescriptor(_obj, "_showFooter"), _obj)), _obj)));

  _exports.default = _default;
});