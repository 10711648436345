define("discourse/components/group-manage-email-settings", ["exports", "discourse-common/utils/decorators", "I18n", "bootbox"], function (_exports, _decorators, _I18n, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.default)("emailSettingsValid", "group.smtp_enabled", "group.imap_enabled"), _dec3 = (0, _decorators.default)("smtpSettingsValid", "imapSettingsValid", "group.smtp_enabled", "group.imap_enabled"), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_obj = {
    tagName: "",
    imapSettingsValid: false,
    smtpSettingsValid: false,
    _determineSettingsValid: function _determineSettingsValid() {
      this.set("imapSettingsValid", this.group.imap_enabled && this.group.imap_server);
      this.set("smtpSettingsValid", this.group.smtp_enabled && this.group.smtp_server);
    },
    enableImapSettings: function enableImapSettings(emailSettingsValid, smtpEnabled, imapEnabled) {
      return smtpEnabled && (emailSettingsValid || imapEnabled);
    },
    emailSettingsValid: function emailSettingsValid(smtpSettingsValid, imapSettingsValid, smtpEnabled, imapEnabled) {
      return (!smtpEnabled || smtpSettingsValid) && (!imapEnabled || imapSettingsValid);
    },
    _anySmtpFieldsFilled: function _anySmtpFieldsFilled() {
      return [this.group.smtp_server, this.group.smtp_port, this.group.email_username, this.group.email_password].some(function (value) {
        return !Ember.isEmpty(value);
      });
    },
    _anyImapFieldsFilled: function _anyImapFieldsFilled() {
      return [this.group.imap_server, this.group.imap_port].some(function (value) {
        return !Ember.isEmpty(value);
      });
    },
    smtpEnabledChange: function smtpEnabledChange(event) {
      var _this = this;

      if (!event.target.checked && this.group.smtp_enabled && this._anySmtpFieldsFilled()) {
        _bootbox.default.confirm(_I18n.default.t("groups.manage.email.smtp_disable_confirm"), function (result) {
          if (!result) {
            _this.group.set("smtp_enabled", true);
          } else {
            _this.group.set("imap_enabled", false);
          }
        });
      }

      this.group.set("smtp_enabled", event.target.checked);
    },
    imapEnabledChange: function imapEnabledChange(event) {
      var _this2 = this;

      if (!event.target.checked && this.group.imap_enabled && this._anyImapFieldsFilled()) {
        _bootbox.default.confirm(_I18n.default.t("groups.manage.email.imap_disable_confirm"), function (result) {
          if (!result) {
            _this2.group.set("imap_enabled", true);
          }
        });
      }

      this.group.set("imap_enabled", event.target.checked);
    },
    afterSave: function afterSave() {
      var _this3 = this;

      // reload the group to get the updated imap_mailboxes
      this.store.find("group", this.group.name).then(function () {
        _this3._determineSettingsValid();
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "_determineSettingsValid", [_dec], Object.getOwnPropertyDescriptor(_obj, "_determineSettingsValid"), _obj), _applyDecoratedDescriptor(_obj, "enableImapSettings", [_dec2], Object.getOwnPropertyDescriptor(_obj, "enableImapSettings"), _obj), _applyDecoratedDescriptor(_obj, "emailSettingsValid", [_dec3], Object.getOwnPropertyDescriptor(_obj, "emailSettingsValid"), _obj), _applyDecoratedDescriptor(_obj, "smtpEnabledChange", [_dec4], Object.getOwnPropertyDescriptor(_obj, "smtpEnabledChange"), _obj), _applyDecoratedDescriptor(_obj, "imapEnabledChange", [_dec5], Object.getOwnPropertyDescriptor(_obj, "imapEnabledChange"), _obj), _applyDecoratedDescriptor(_obj, "afterSave", [_dec6], Object.getOwnPropertyDescriptor(_obj, "afterSave"), _obj)), _obj)));

  _exports.default = _default;
});