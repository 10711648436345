define("discourse/templates/components/top-period-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "toRfojhn",
    "block": "{\"symbols\":[\"p\"],\"statements\":[[4,\"each\",[[24,[\"periods\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"d-button\",null,[[\"action\",\"class\",\"actionParam\",\"translatedLabel\"],[[28,\"action\",[[23,0,[]],\"changePeriod\"],null],\"btn-default\",[23,1,[]],[28,\"period-title\",[[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/top-period-buttons.hbs"
    }
  });

  _exports.default = _default;
});