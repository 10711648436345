define("discourse/components/pick-files-button", ["exports", "discourse-common/utils/decorators", "I18n", "bootbox"], function (_exports, _decorators, _I18n, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    classNames: ["pick-files-button"],
    acceptedFileTypes: null,
    acceptAnyFile: Ember.computed.empty("acceptedFileTypes"),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var fileInput = this.element.querySelector("input");
      this.set("fileInput", fileInput);
      fileInput.addEventListener("change", this.onChange, false);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.fileInput.removeEventListener("change", this.onChange);
    },
    onChange: function onChange() {
      var files = this.fileInput.files;

      this._filesPicked(files);
    },
    acceptedFileTypesString: function acceptedFileTypesString() {
      if (!this.acceptedFileTypes) {
        return null;
      }

      return this.acceptedFileTypes.join(",");
    },
    acceptedExtensions: function acceptedExtensions() {
      if (!this.acceptedFileTypes) {
        return null;
      }

      return this.acceptedFileTypes.filter(function (type) {
        return type.startsWith(".");
      }).map(function (type) {
        return type.substring(1);
      });
    },
    acceptedMimeTypes: function acceptedMimeTypes() {
      if (!this.acceptedFileTypes) {
        return null;
      }

      return this.acceptedFileTypes.filter(function (type) {
        return !type.startsWith(".");
      });
    },
    openSystemFilePicker: function openSystemFilePicker() {
      this.fileInput.click();
    },
    _filesPicked: function _filesPicked(files) {
      if (!files || !files.length) {
        return;
      }

      if (!this._haveAcceptedTypes(files)) {
        var message = _I18n.default.t("pick_files_button.unsupported_file_picked", {
          types: this.acceptedFileTypesString
        });

        _bootbox.default.alert(message);

        return;
      }

      this.onFilesPicked(files);
    },
    _haveAcceptedTypes: function _haveAcceptedTypes(files) {
      var _iterator = _createForOfIteratorHelper(files),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var file = _step.value;

          if (!(this._hasAcceptedExtension(file) || this._hasAcceptedMimeType(file))) {
            return false;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return true;
    },
    _hasAcceptedExtension: function _hasAcceptedExtension(file) {
      var extension = this._fileExtension(file.name);

      return !this.acceptedExtensions || this.acceptedExtensions.includes(extension);
    },
    _hasAcceptedMimeType: function _hasAcceptedMimeType(file) {
      return !this.acceptedMimeTypes || this.acceptedMimeTypes.includes(file.type);
    },
    _fileExtension: function _fileExtension(fileName) {
      return fileName.split(".").pop();
    }
  }, (_applyDecoratedDescriptor(_obj, "onChange", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "onChange"), _obj), _applyDecoratedDescriptor(_obj, "acceptedFileTypesString", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "acceptedFileTypesString"), _obj), _applyDecoratedDescriptor(_obj, "acceptedExtensions", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "acceptedExtensions"), _obj), _applyDecoratedDescriptor(_obj, "acceptedMimeTypes", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "acceptedMimeTypes"), _obj), _applyDecoratedDescriptor(_obj, "openSystemFilePicker", [_dec], Object.getOwnPropertyDescriptor(_obj, "openSystemFilePicker"), _obj)), _obj)));

  _exports.default = _default;
});