define("discourse/templates/components/user-summary-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mQtHRwIW",
    "block": "{\"symbols\":[\"@icon\",\"@countClass\",\"@user\"],\"statements\":[[4,\"user-info\",null,[[\"user\"],[[23,3,[]]]],{\"statements\":[[0,\"  \"],[1,[28,\"d-icon\",[[23,1,[]]],null],false],[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[23,2,[]]],[8],[1,[28,\"number\",[[23,3,[\"count\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/user-summary-user.hbs"
    }
  });

  _exports.default = _default;
});