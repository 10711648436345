define("discourse/routes/edit-category-index", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    afterModel: function afterModel() {
      var params = this.paramsFor("editCategory");
      this.replaceWith("/c/".concat(params.slug, "/edit/general"));
    }
  });

  _exports.default = _default;
});