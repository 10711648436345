define("discourse/components/about-page-users", ["exports", "discourse/lib/utilities", "discourse/components/user-info", "discourse/lib/settings", "discourse/helpers/user-avatar", "discourse/lib/url"], function (_exports, _utilities, _userInfo, _settings, _userAvatar, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    usersTemplates: Ember.computed("users.[]", function () {
      var _this = this;

      return (this.users || []).map(function (user) {
        var name = user.name;
        var username = user.username;
        var prioritizeName = (0, _settings.prioritizeNameInUx)(name);
        var hideName = false;

        if (name && (0, _userInfo.normalize)(username) === (0, _userInfo.normalize)(name)) {
          hideName = true;
        }

        return {
          name: name,
          username: username,
          userPath: (0, _url.userPath)(username),
          avatar: (0, _userAvatar.renderAvatar)(user, {
            imageSize: "large",
            siteSettings: _this.siteSettings
          }),
          title: user.title || "",
          formatedUsername: (0, _utilities.formatUsername)(username),
          prioritizeName: prioritizeName,
          hideName: hideName
        };
      });
    })
  });

  _exports.default = _default;
});