define("discourse/templates/components/mobile-category-topic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "plydwuM2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"class\",\"main-link\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"topic-inset\"],[8],[0,\"\\n    \"],[1,[28,\"raw\",[\"topic-status\"],[[\"topic\"],[[24,[\"topic\"]]]]],false],[0,\"\\n    \"],[1,[28,\"topic-link\",[[24,[\"topic\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[24,[\"topic\",\"unseen\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"badge-notification new-topic\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"span\",true],[11,\"class\",[28,\"cold-age-class\",[[24,[\"topic\",\"last_posted_at\"]]],null]],[11,\"title\",[28,\"raw-date\",[[24,[\"topic\",\"last_posted_at\"]]],null]],[8],[1,[28,\"format-age\",[[24,[\"topic\",\"last_posted_at\"]]],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"num posts\"],[8],[1,[28,\"raw\",[\"list/post-count-or-badges\"],[[\"topic\",\"postBadgesEnabled\"],[[24,[\"topic\"]],\"true\"]]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/mobile-category-topic.hbs"
    }
  });

  _exports.default = _default;
});