define("discourse/components/login-buttons", ["exports", "discourse-common/utils/decorators", "discourse/models/login-method"], function (_exports, _decorators, _loginMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("buttons.length", "showLoginWithEmailLink"), (_obj = {
    elementId: "login-buttons",
    classNameBindings: ["hidden"],
    hidden: function hidden(buttonsCount, showLoginWithEmailLink) {
      return buttonsCount === 0 && !showLoginWithEmailLink;
    },
    buttons: function buttons() {
      return (0, _loginMethod.findAll)();
    },
    actions: {
      externalLogin: function externalLogin(provider) {
        this.externalLogin(provider);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "hidden", [_dec], Object.getOwnPropertyDescriptor(_obj, "hidden"), _obj), _applyDecoratedDescriptor(_obj, "buttons", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "buttons"), _obj)), _obj)));

  _exports.default = _default;
});