define("discourse/components/d-navigation-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    route: null,
    router: Ember.inject.service(),
    attributeBindings: ["ariaCurrent:aria-current", "title"],
    ariaCurrent: Ember.computed("router.currentRouteName", "route", function () {
      return this.router.currentRouteName === this.route ? "page" : null;
    })
  });

  _exports.default = _default;
});