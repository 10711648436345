define("discourse/initializers/register-service-worker", ["exports", "discourse/lib/register-service-worker"], function (_exports, _registerServiceWorker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "register-service-worker",
    initialize: function initialize(container) {
      var _container$lookup = container.lookup("session:main"),
          serviceWorkerURL = _container$lookup.serviceWorkerURL;

      (0, _registerServiceWorker.registerServiceWorker)(container, serviceWorkerURL);
    }
  };
  _exports.default = _default;
});