define("discourse/templates/user-selector-autocomplete", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var template = (0, _rawHandlebars.template)({
    "1": function _(container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
          alias2 = container.escapeExpression,
          alias3 = container.hooks.helperMissing,
          lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }

        return undefined;
      };

      return "      <li>\n        <a href title=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "user.name", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 5,
            "column": 23
          },
          "end": {
            "line": 5,
            "column": 36
          }
        }
      })) + "\">\n          " + alias2((lookupProperty(helpers, "avatar") || depth0 && lookupProperty(depth0, "avatar") || alias3).call(alias1, "user", {
        "name": "avatar",
        "hash": {
          "imageSize": "tiny"
        },
        "hashTypes": {
          "imageSize": "StringLiteral"
        },
        "hashContexts": {
          "imageSize": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 6,
            "column": 10
          },
          "end": {
            "line": 6,
            "column": 42
          }
        }
      })) + "\n          <span class='username'>" + alias2((lookupProperty(helpers, "format-username") || depth0 && lookupProperty(depth0, "format-username") || alias3).call(alias1, "user.username", {
        "name": "format-username",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 7,
            "column": 33
          },
          "end": {
            "line": 7,
            "column": 66
          }
        }
      })) + "</span>\n          <span class='name'>" + alias2(lookupProperty(helpers, "get").call(alias1, "user.name", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 8,
            "column": 29
          },
          "end": {
            "line": 8,
            "column": 42
          }
        }
      })) + "</span>\n          " + alias2((lookupProperty(helpers, "decorate-username-selector") || depth0 && lookupProperty(depth0, "decorate-username-selector") || alias3).call(alias1, "user.username", {
        "name": "decorate-username-selector",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 9,
            "column": 10
          },
          "end": {
            "line": 9,
            "column": 54
          }
        }
      })) + "\n        </a>\n      </li>\n";
    },
    "3": function _(container, depth0, helpers, partials, data) {
      var stack1,
          lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }

        return undefined;
      };

      return (stack1 = lookupProperty(helpers, "each").call(depth0 != null ? depth0 : container.nullContext || {}, "email", "in", "options.emails", {
        "name": "each",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(4, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression", "CommentStatement", "PathExpression"],
        "contexts": [depth0, depth0, depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 15,
            "column": 6
          },
          "end": {
            "line": 22,
            "column": 15
          }
        }
      })) != null ? stack1 : "";
    },
    "4": function _(container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
          alias2 = container.escapeExpression,
          alias3 = container.hooks.helperMissing,
          lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }

        return undefined;
      };

      return "        <li>\n          <a href title=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "email.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 17,
            "column": 25
          },
          "end": {
            "line": 17,
            "column": 43
          }
        }
      })) + "\">\n            " + alias2((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias3).call(alias1, "envelope", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 18,
            "column": 12
          },
          "end": {
            "line": 18,
            "column": 33
          }
        }
      })) + "\n            <span class='username'>" + alias2((lookupProperty(helpers, "format-username") || depth0 && lookupProperty(depth0, "format-username") || alias3).call(alias1, "email.username", {
        "name": "format-username",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 19,
            "column": 35
          },
          "end": {
            "line": 19,
            "column": 69
          }
        }
      })) + "</span>\n          </a>\n        </li>\n";
    },
    "6": function _(container, depth0, helpers, partials, data) {
      var stack1,
          lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }

        return undefined;
      };

      return (stack1 = lookupProperty(helpers, "each").call(depth0 != null ? depth0 : container.nullContext || {}, "group", "in", "options.groups", {
        "name": "each",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(7, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression", "CommentStatement", "PathExpression"],
        "contexts": [depth0, depth0, depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 26,
            "column": 6
          },
          "end": {
            "line": 34,
            "column": 15
          }
        }
      })) != null ? stack1 : "";
    },
    "7": function _(container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
          alias2 = container.escapeExpression,
          lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }

        return undefined;
      };

      return "        <li>\n          <a href title=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "group.full_name", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 28,
            "column": 25
          },
          "end": {
            "line": 28,
            "column": 44
          }
        }
      })) + "\">\n            " + alias2((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || container.hooks.helperMissing).call(alias1, "users", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 29,
            "column": 12
          },
          "end": {
            "line": 29,
            "column": 30
          }
        }
      })) + "\n            <span class='username'>" + alias2(lookupProperty(helpers, "get").call(alias1, "group.name", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 30,
            "column": 35
          },
          "end": {
            "line": 30,
            "column": 49
          }
        }
      })) + "</span>\n            <span class='name'>" + alias2(lookupProperty(helpers, "get").call(alias1, "group.full_name", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 31,
            "column": 31
          },
          "end": {
            "line": 31,
            "column": 50
          }
        }
      })) + "</span>\n          </a>\n        </li>\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function main(container, depth0, helpers, partials, data) {
      var stack1,
          alias1 = depth0 != null ? depth0 : container.nullContext || {},
          lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }

        return undefined;
      };

      return "<div class='autocomplete ac-user'>\n  <ul>\n" + ((stack1 = lookupProperty(helpers, "each").call(alias1, "user", "in", "options.users", {
        "name": "each",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression", "CommentStatement", "PathExpression"],
        "contexts": [depth0, depth0, depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 4
          },
          "end": {
            "line": 12,
            "column": 13
          }
        }
      })) != null ? stack1 : "") + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "options.emails", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(3, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 14,
            "column": 4
          },
          "end": {
            "line": 23,
            "column": 11
          }
        }
      })) != null ? stack1 : "") + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "options.groups", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(6, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 25,
            "column": 4
          },
          "end": {
            "line": 35,
            "column": 11
          }
        }
      })) != null ? stack1 : "") + "  </ul>\n</div>\n";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("user-selector-autocomplete", template, {
    core: true
  });
  var _default = template;
  _exports.default = _default;
});