define("discourse/controllers/ignore-duration-with-username", ["exports", "I18n", "discourse/mixins/modal-functionality", "discourse/models/user", "discourse/lib/ajax-error"], function (_exports, _I18n, _modalFunctionality, _user, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    loading: false,
    ignoredUntil: null,
    ignoredUsername: null,
    actions: {
      ignore: function ignore() {
        var _this = this;

        if (!this.ignoredUntil || !this.ignoredUsername) {
          this.flash(_I18n.default.t("user.user_notifications.ignore_duration_time_frame_required"), "error");
          return;
        }

        this.set("loading", true);

        _user.default.findByUsername(this.ignoredUsername).then(function (user) {
          user.updateNotificationLevel("ignore", _this.ignoredUntil).then(function () {
            _this.onUserIgnored(_this.ignoredUsername);

            _this.send("closeModal");
          }).catch(_ajaxError.popupAjaxError).finally(function () {
            return _this.set("loading", false);
          });
        });
      },
      updateIgnoredUsername: function updateIgnoredUsername(selected) {
        this.set("ignoredUsername", selected.firstObject);
      }
    }
  });

  _exports.default = _default;
});