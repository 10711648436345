define("discourse/templates/modal/second-factor-edit-security-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x1aiL+jL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,null,{\"statements\":[[0,\"  \"],[7,\"form\",true],[10,\"class\",\"form-horizontal\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"input-group\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"for\",\"security-key-name\"],[8],[1,[28,\"i18n\",[\"user.second_factor.security_key.edit_description\"],null],false],[9],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"name\",\"type\",\"value\"],[\"security-key-name\",\"text\",[24,[\"model\",\"name\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"class\",\"label\"],[[28,\"action\",[[23,0,[]],\"editSecurityKey\"],null],\"btn-primary\",\"user.second_factor.security_key.save\"]]],false],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"class\",\"icon\",\"aria-label\",\"title\"],[[28,\"action\",[[23,0,[]],\"disableSecurityKey\"],null],\"btn-danger no-text\",\"trash-alt\",\"user.second_factor.disable\",\"user.second_factor.disable\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/second-factor-edit-security-key.hbs"
    }
  });

  _exports.default = _default;
});