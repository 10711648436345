define("discourse/controllers/grant-badge", ["exports", "discourse/models/badge", "discourse/mixins/grant-badge-controller", "I18n", "discourse/mixins/modal-functionality", "discourse/models/user-badge", "discourse-common/utils/decorators", "discourse/lib/ajax-error"], function (_exports, _badge, _grantBadgeController, _I18n, _modalFunctionality, _userBadge, _decorators, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, _grantBadgeController.default, (_dec = (0, _decorators.default)("topicController.selectedPosts"), _dec2 = (0, _decorators.default)("post"), _dec3 = (0, _decorators.default)("saving", "selectedBadgeGrantable"), (_obj = {
    topicController: Ember.inject.controller("topic"),
    loading: true,
    saving: false,
    selectedBadgeId: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.allBadges = [];
      this.userBadges = [];
    },
    post: function post() {
      return this.get("topicController.selectedPosts")[0];
    },
    badgeReason: function badgeReason(post) {
      var url = post.get("url");
      var protocolAndHost = window.location.protocol + "//" + window.location.host;
      return url.indexOf("/") === 0 ? protocolAndHost + url : url;
    },
    buttonDisabled: function buttonDisabled(saving, selectedBadgeGrantable) {
      return saving || !selectedBadgeGrantable;
    },
    onShow: function onShow() {
      var _this = this;

      this.set("loading", true);
      Ember.RSVP.all([_badge.default.findAll(), _userBadge.default.findByUsername(this.get("post.username"))]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            allBadges = _ref2[0],
            userBadges = _ref2[1];

        _this.setProperties({
          allBadges: allBadges,
          userBadges: userBadges,
          loading: false
        });
      });
    },
    actions: {
      grantBadge: function grantBadge() {
        var _this2 = this;

        this.set("saving", true);
        this.grantBadge(this.selectedBadgeId, this.get("post.username"), this.badgeReason).then(function (newBadge) {
          _this2.set("selectedBadgeId", null);

          _this2.flash(_I18n.default.t("badges.successfully_granted", {
            username: _this2.get("post.username"),
            badge: newBadge.get("badge.name")
          }), "success");
        }, function (error) {
          _this2.flash((0, _ajaxError.extractError)(error), "error");
        }).finally(function () {
          return _this2.set("saving", false);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "post", [_dec], Object.getOwnPropertyDescriptor(_obj, "post"), _obj), _applyDecoratedDescriptor(_obj, "badgeReason", [_dec2], Object.getOwnPropertyDescriptor(_obj, "badgeReason"), _obj), _applyDecoratedDescriptor(_obj, "buttonDisabled", [_dec3], Object.getOwnPropertyDescriptor(_obj, "buttonDisabled"), _obj)), _obj)));

  _exports.default = _default;
});