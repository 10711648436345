define("discourse/templates/components/desktop-notification-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XP4iuws7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isNotSupported\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"d-button\",null,[[\"icon\",\"class\",\"label\",\"disabled\"],[\"bell-slash\",\"btn-default\",\"user.desktop_notifications.not_supported\",\"true\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"isDeniedPermission\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"d-button\",null,[[\"icon\",\"class\",\"label\",\"action\",\"disabled\"],[\"bell-slash\",\"btn-default\",\"user.desktop_notifications.perm_denied_btn\",[28,\"action\",[[23,0,[]],\"recheckPermission\"],null],\"true\"]]],false],[0,\"\\n  \"],[1,[28,\"i18n\",[\"user.desktop_notifications.perm_denied_expl\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isEnabled\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"d-button\",null,[[\"icon\",\"class\",\"label\",\"action\"],[\"far-bell-slash\",\"btn-default\",\"user.desktop_notifications.disable\",[28,\"action\",[[23,0,[]],\"turnoff\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"d-button\",null,[[\"icon\",\"class\",\"label\",\"action\"],[\"far-bell\",\"btn-default\",\"user.desktop_notifications.enable\",[28,\"action\",[[23,0,[]],\"turnon\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/desktop-notification-config.hbs"
    }
  });

  _exports.default = _default;
});