define("discourse/lib/keyboard-shortcuts", ["exports", "discourse-common/utils/decorators", "discourse-common/lib/debounce", "discourse/lib/utilities", "discourse/lib/topic-list-tracker", "discourse/models/composer", "discourse/lib/url", "discourse-common/utils/dom-utils", "discourse-common/config/environment", "discourse/lib/ajax", "discourse/lib/offset-calculator"], function (_exports, _decorators, _debounce, _utilities, _topicListTracker, _composer, _url, _domUtils, _environment, _ajax, _offsetCalculator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var DEFAULT_BINDINGS = {
    "!": {
      postAction: "showFlags"
    },
    "#": {
      handler: "goToPost",
      anonymous: true
    },
    "/": {
      handler: "toggleSearch",
      anonymous: true
    },
    "ctrl+alt+f": {
      handler: "toggleSearch",
      anonymous: true,
      global: true
    },
    "=": {
      handler: "toggleHamburgerMenu",
      anonymous: true
    },
    "?": {
      handler: "showHelpModal",
      anonymous: true
    },
    ".": {
      click: ".alert.alert-info.clickable",
      anonymous: true
    },
    // show incoming/updated topics
    b: {
      handler: "toggleBookmark"
    },
    c: {
      handler: "createTopic"
    },
    "shift+c": {
      handler: "focusComposer"
    },
    "ctrl+f": {
      handler: "showPageSearch",
      anonymous: true
    },
    "command+f": {
      handler: "showPageSearch",
      anonymous: true
    },
    "command+left": {
      handler: "webviewKeyboardBack",
      anonymous: true
    },
    "command+[": {
      handler: "webviewKeyboardBack",
      anonymous: true
    },
    "command+right": {
      handler: "webviewKeyboardForward",
      anonymous: true
    },
    "command+]": {
      handler: "webviewKeyboardForward",
      anonymous: true
    },
    "mod+p": {
      handler: "printTopic",
      anonymous: true
    },
    d: {
      postAction: "deletePost"
    },
    e: {
      handler: "editPost"
    },
    end: {
      handler: "goToLastPost",
      anonymous: true
    },
    "command+down": {
      handler: "goToLastPost",
      anonymous: true
    },
    f: {
      handler: "toggleBookmarkTopic"
    },
    "g h": {
      path: "/",
      anonymous: true
    },
    "g l": {
      path: "/latest",
      anonymous: true
    },
    "g n": {
      path: "/new"
    },
    "g u": {
      path: "/unread"
    },
    "g c": {
      path: "/categories",
      anonymous: true
    },
    "g t": {
      path: "/top",
      anonymous: true
    },
    "g b": {
      path: "/my/activity/bookmarks"
    },
    "g p": {
      path: "/my/activity"
    },
    "g m": {
      path: "/my/messages"
    },
    "g d": {
      path: "/my/activity/drafts"
    },
    "g s": {
      handler: "goToFirstSuggestedTopic",
      anonymous: true
    },
    "g j": {
      handler: "goToNextTopic",
      anonymous: true
    },
    "g k": {
      handler: "goToPreviousTopic",
      anonymous: true
    },
    home: {
      handler: "goToFirstPost",
      anonymous: true
    },
    "command+up": {
      handler: "goToFirstPost",
      anonymous: true
    },
    j: {
      handler: "selectDown",
      anonymous: true
    },
    k: {
      handler: "selectUp",
      anonymous: true
    },
    // we use this odd routing here vs a postAction: cause like
    // has an animation so the widget handles that
    // TODO: teach controller how to trigger the widget animation
    l: {
      click: ".topic-post.selected button.toggle-like"
    },
    "m m": {
      handler: "setTrackingToMuted"
    },
    // mark topic as muted
    "m r": {
      handler: "setTrackingToRegular"
    },
    // mark topic as regular
    "m t": {
      handler: "setTrackingToTracking"
    },
    // mark topic as tracking
    "m w": {
      handler: "setTrackingToWatching"
    },
    // mark topic as watching
    "o,enter": {
      click: [".topic-list tr.selected a.title", ".latest-topic-list .latest-topic-list-item.selected div.main-link a.title", ".top-topic-list .latest-topic-list-item.selected div.main-link a.title", ".latest .featured-topic.selected a.title", ".search-results .fps-result.selected .search-link"].join(", "),
      anonymous: true
    },
    // open selected topic on latest or categories page
    tab: {
      handler: "switchFocusCategoriesPage",
      anonymous: true
    },
    p: {
      handler: "showCurrentUser"
    },
    q: {
      handler: "quoteReply"
    },
    r: {
      postAction: "replyToPost"
    },
    s: {
      click: ".topic-post.selected a.post-date",
      anonymous: true
    },
    // share post
    "shift+j": {
      handler: "nextSection",
      anonymous: true
    },
    "shift+k": {
      handler: "prevSection",
      anonymous: true
    },
    "shift+p": {
      handler: "pinUnpinTopic"
    },
    "shift+r": {
      handler: "replyToTopic"
    },
    "shift+s": {
      click: "#topic-footer-buttons button.share",
      anonymous: true
    },
    // share topic
    "shift+l": {
      handler: "goToUnreadPost"
    },
    "shift+z shift+z": {
      handler: "logout"
    },
    "shift+f11": {
      handler: "fullscreenComposer",
      global: true
    },
    "shift+u": {
      handler: "deferTopic"
    },
    "shift+a": {
      handler: "toggleAdminActions"
    },
    t: {
      postAction: "replyAsNewTopic"
    },
    u: {
      handler: "goBack",
      anonymous: true
    },
    "x r": {
      click: "#dismiss-new-bottom,#dismiss-new-top"
    },
    // dismiss new
    "x t": {
      click: "#dismiss-topics-bottom,#dismiss-topics-top"
    } // dismiss topics

  };
  var animationDuration = 100;

  function preventKeyboardEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  var _default = (_obj = {
    init: function init(keyTrapper, container) {
      // Sometimes the keyboard shortcut initializer is not torn down. This makes sure
      // we clear any previous test state.
      if (this.keyTrapper) {
        this.keyTrapper.destroy();
        this.keyTrapper = null;
      }

      this.keyTrapper = new keyTrapper();
      this.container = container;

      this._stopCallback();

      this.searchService = this.container.lookup("service:search");
      this.appEvents = this.container.lookup("service:app-events");
      this.currentUser = this.container.lookup("current-user:main");
      this.siteSettings = this.container.lookup("site-settings:main"); // Disable the shortcut if private messages are disabled

      if (!this.siteSettings.enable_personal_messages) {
        delete DEFAULT_BINDINGS["g m"];
      }
    },
    bindEvents: function bindEvents() {
      var _this = this;

      Object.keys(DEFAULT_BINDINGS).forEach(function (key) {
        _this.bindKey(key);
      });
    },
    teardown: function teardown() {
      var _this$keyTrapper;

      (_this$keyTrapper = this.keyTrapper) === null || _this$keyTrapper === void 0 ? void 0 : _this$keyTrapper.destroy();
      this.keyTrapper = null;
      this.container = null;
    },
    isTornDown: function isTornDown() {
      return this.keyTrapper == null || this.container == null;
    },
    bindKey: function bindKey(key) {
      var binding = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (this.isTornDown()) {
        return;
      }

      if (!binding) {
        binding = DEFAULT_BINDINGS[key];
      }

      if (!binding.anonymous && !this.currentUser) {
        return;
      }

      if (binding.path) {
        this._bindToPath(binding.path, key);
      } else if (binding.handler) {
        if (binding.global) {
          // global shortcuts will trigger even while focusing on input/textarea
          this._globalBindToFunction(binding.handler, key);
        } else {
          this._bindToFunction(binding.handler, key);
        }
      } else if (binding.postAction) {
        this._bindToSelectedPost(binding.postAction, key);
      } else if (binding.click) {
        this._bindToClick(binding.click, key);
      }
    },
    // for cases when you want to disable global keyboard shortcuts
    // so that you can override them (e.g. inside a modal)
    pause: function pause(combinations) {
      var _this2 = this;

      if (this.isTornDown()) {
        return;
      }

      if (!combinations) {
        this.keyTrapper.paused = true;
        return;
      }

      combinations.forEach(function (combo) {
        return _this2.keyTrapper.unbind(combo);
      });
    },
    // restore global shortcuts that you have paused
    unpause: function unpause(combinations) {
      var _this3 = this;

      if (this.isTornDown()) {
        return;
      }

      if (!combinations) {
        this.keyTrapper.paused = false;
        return;
      }

      combinations.forEach(function (combo) {
        return _this3.bindKey(combo);
      });
    },

    /**
     * addShortcut(shortcut, callback, opts)
     *
     * Used to bind a keyboard shortcut, which will fire the provided
     * callback when pressed. Valid options are:
     *
     * - global     - makes the shortcut work anywhere, including when an input is focused
     * - anonymous  - makes the shortcut work even if a user is not logged in
     * - path       - a specific path to limit the shortcut to .e.g /latest
     * - postAction - binds the shortcut to fire the specified post action when a
     *                post is selected
     * - click      - allows to provide a selector on which a click event
     *                will be triggered, eg: { click: ".topic.last .title" }
     **/
    addShortcut: function addShortcut(shortcut, callback) {
      var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      // we trim but leave whitespace between characters, as shortcuts
      // like `z z` are valid for ItsATrap
      shortcut = shortcut.trim();
      var newBinding = Object.assign({
        handler: callback
      }, opts);
      this.bindKey(shortcut, newBinding);
    },
    // unbinds all the shortcuts in a key binding object e.g.
    // {
    //   'c': createTopic
    // }
    unbind: function unbind(combinations) {
      var _this4 = this;

      Object.keys(combinations).forEach(function (combo) {
        return _this4.keyTrapper.unbind(combo);
      });
    },
    toggleBookmark: function toggleBookmark(event) {
      var selectedPost = this._getSelectedPost();

      if (selectedPost) {
        preventKeyboardEvent(event);
        this.sendToSelectedPost("toggleBookmark", selectedPost);
        return;
      }

      var selectedTopicListItem = this._getSelectedTopicListItem();

      if (selectedTopicListItem) {
        preventKeyboardEvent(event);
        this.sendToTopicListItemView("toggleBookmark", selectedTopicListItem);
        return;
      }

      this._bookmarkCurrentTopic(event);
    },
    toggleBookmarkTopic: function toggleBookmarkTopic(event) {
      var selectedTopicListItem = this._getSelectedTopicListItem();

      if (selectedTopicListItem) {
        preventKeyboardEvent(event);
        this.sendToTopicListItemView("toggleBookmark", selectedTopicListItem);
        return;
      }

      this._bookmarkCurrentTopic(event);
    },
    _bookmarkCurrentTopic: function _bookmarkCurrentTopic(event) {
      var topic = this.currentTopic();

      if (topic && document.querySelectorAll(".posts-wrapper").length) {
        preventKeyboardEvent(event);
        this.container.lookup("controller:topic").send("toggleBookmark");
      }
    },
    logout: function logout() {
      this.container.lookup("route:application").send("logout");
    },
    quoteReply: function quoteReply() {
      if (this.isPostTextSelected()) {
        this.appEvents.trigger("quote-button:quote");
        return false;
      }

      this.sendToSelectedPost("replyToPost"); // lazy but should work for now

      Ember.run.later(function () {
        return $(".d-editor .quote").click();
      }, 500);
      return false;
    },
    editPost: function editPost() {
      if (this.siteSettings.enable_fast_edit && this.isPostTextSelected()) {
        this.appEvents.trigger("quote-button:edit");
        return false;
      } else {
        this.sendToSelectedPost("editPost");
      }

      return false;
    },
    goToNextTopic: function goToNextTopic() {
      (0, _topicListTracker.nextTopicUrl)().then(function (url) {
        if (url) {
          _url.default.routeTo(url);
        }
      });
    },
    goToPreviousTopic: function goToPreviousTopic() {
      (0, _topicListTracker.previousTopicUrl)().then(function (url) {
        if (url) {
          _url.default.routeTo(url);
        }
      });
    },
    goToFirstSuggestedTopic: function goToFirstSuggestedTopic() {
      var $el = $(".suggested-topics a.raw-topic-link:first");

      if ($el.length) {
        $el.click();
      } else {
        var controller = this.container.lookup("controller:topic"); // Only the last page contains list of suggested topics.

        var url = "/t/".concat(controller.get("model.id"), "/last.json");
        (0, _ajax.ajax)(url).then(function (result) {
          if (result.suggested_topics && result.suggested_topics.length > 0) {
            var topic = controller.store.createRecord("topic", result.suggested_topics[0]);

            _url.default.routeTo(topic.get("url"));
          }
        });
      }
    },
    goToFirstPost: function goToFirstPost() {
      this._jumpTo("jumpTop");
    },
    goToLastPost: function goToLastPost() {
      this._jumpTo("jumpBottom");
    },
    goToUnreadPost: function goToUnreadPost() {
      this._jumpTo("jumpUnread");
    },
    _jumpTo: function _jumpTo(direction) {
      if ($(".container.posts").length) {
        this.container.lookup("controller:topic").send(direction);
      }
    },
    replyToTopic: function replyToTopic() {
      this._replyToPost();

      return false;
    },
    selectDown: function selectDown() {
      this._moveSelection(1);
    },
    selectUp: function selectUp() {
      this._moveSelection(-1);
    },
    goBack: function goBack() {
      history.back();
    },
    nextSection: function nextSection() {
      this._changeSection(1);
    },
    prevSection: function prevSection() {
      this._changeSection(-1);
    },
    showPageSearch: function showPageSearch(event) {
      var _this5 = this;

      Ember.run(function () {
        _this5.appEvents.trigger("header:keyboard-trigger", {
          type: "page-search",
          event: event
        });
      });
    },
    printTopic: function printTopic(event) {
      var _this6 = this;

      Ember.run(function () {
        if ($(".container.posts").length) {
          event.preventDefault(); // We need to stop printing the current page in Firefox

          _this6.container.lookup("controller:topic").print();
        }
      });
    },
    createTopic: function createTopic(event) {
      if (!(this.currentUser && this.currentUser.can_create_topic)) {
        return;
      }

      event.preventDefault(); // If the page has a create-topic button, use it for context sensitive attributes like category

      var $createTopicButton = $("#create-topic");

      if ($createTopicButton.length) {
        $createTopicButton.click();
        return;
      }

      this.container.lookup("controller:composer").open({
        action: _composer.default.CREATE_TOPIC,
        draftKey: _composer.default.NEW_TOPIC_KEY
      });
    },
    focusComposer: function focusComposer(event) {
      var composer = this.container.lookup("controller:composer");

      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      composer.focusComposer(event);
    },
    fullscreenComposer: function fullscreenComposer() {
      var composer = this.container.lookup("controller:composer");

      if (composer.get("model")) {
        composer.toggleFullscreen();
      }
    },
    pinUnpinTopic: function pinUnpinTopic() {
      this.container.lookup("controller:topic").togglePinnedState();
    },
    goToPost: function goToPost(event) {
      preventKeyboardEvent(event);
      this.appEvents.trigger("topic:keyboard-trigger", {
        type: "jump"
      });
    },
    toggleSearch: function toggleSearch(event) {
      this.appEvents.trigger("header:keyboard-trigger", {
        type: "search",
        event: event
      });
      return false;
    },
    toggleHamburgerMenu: function toggleHamburgerMenu(event) {
      this.appEvents.trigger("header:keyboard-trigger", {
        type: "hamburger",
        event: event
      });
    },
    showCurrentUser: function showCurrentUser(event) {
      this.appEvents.trigger("header:keyboard-trigger", {
        type: "user",
        event: event
      });
    },
    showHelpModal: function showHelpModal() {
      this.container.lookup("controller:application").send("showKeyboardShortcutsHelp");
    },
    setTrackingToMuted: function setTrackingToMuted() {
      Ember.run.throttle(this, "_setTracking", 0, _environment.INPUT_DELAY, true);
    },
    setTrackingToRegular: function setTrackingToRegular() {
      Ember.run.throttle(this, "_setTracking", 1, _environment.INPUT_DELAY, true);
    },
    setTrackingToTracking: function setTrackingToTracking() {
      Ember.run.throttle(this, "_setTracking", 2, _environment.INPUT_DELAY, true);
    },
    setTrackingToWatching: function setTrackingToWatching() {
      Ember.run.throttle(this, "_setTracking", 3, _environment.INPUT_DELAY, true);
    },
    _setTracking: function _setTracking(levelId) {
      var topic = this.currentTopic();

      if (!topic) {
        return;
      }

      topic.details.updateNotifications(levelId);
    },
    sendToTopicListItemView: function sendToTopicListItemView(action, elem) {
      elem = elem || document.querySelector("tr.selected.topic-list-item");

      if (elem) {
        var registry = this.container.lookup("-view-registry:main");

        if (registry) {
          var view = registry[elem.id];
          view.send(action);
        }
      }
    },
    currentTopic: function currentTopic() {
      var topicController = this.container.lookup("controller:topic");

      if (topicController) {
        var topic = topicController.get("model");

        if (topic) {
          return topic;
        }
      }
    },
    isPostTextSelected: function isPostTextSelected() {
      var _topicController$get;

      var topicController = this.container.lookup("controller:topic");
      return !!(topicController !== null && topicController !== void 0 && (_topicController$get = topicController.get("quoteState")) !== null && _topicController$get !== void 0 && _topicController$get.postId);
    },
    sendToSelectedPost: function sendToSelectedPost(action, elem) {
      // TODO: We should keep track of the post without a CSS class
      var selectedPost = elem || document.querySelector(".topic-post.selected article.boxed");
      var selectedPostId;

      if (selectedPost) {
        selectedPostId = parseInt(selectedPost.dataset.postId, 10);
      }

      if (selectedPostId) {
        var topicController = this.container.lookup("controller:topic");
        var post = topicController.get("model.postStream.posts").findBy("id", selectedPostId);

        if (post) {
          // TODO: Use ember closure actions
          var actionMethod = topicController.actions[action];

          if (!actionMethod) {
            var topicRoute = this.container.lookup("route:topic");
            actionMethod = topicRoute.actions[action];
          }

          var result = actionMethod.call(topicController, post);

          if (result && result.then) {
            this.appEvents.trigger("post-stream:refresh", {
              id: selectedPostId
            });
          }
        }
      }

      return false;
    },
    _bindToSelectedPost: function _bindToSelectedPost(action, binding) {
      var _this7 = this;

      this.keyTrapper.bind(binding, function () {
        return _this7.sendToSelectedPost(action);
      });
    },
    _bindToPath: function _bindToPath(path, key) {
      this.keyTrapper.bind(key, function () {
        return _url.default.routeTo(path);
      });
    },
    _bindToClick: function _bindToClick(selector, binding) {
      binding = binding.split(",");
      this.keyTrapper.bind(binding, function (e) {
        var selection = document.querySelector(selector); // Special case: We're binding to enter.

        if (e && e.key === "Enter") {
          // Binding to enter should only be effective when there is something
          // to select.
          if (!selection) {
            return;
          } // If effective, prevent default.


          e.preventDefault();
        }

        selection === null || selection === void 0 ? void 0 : selection.click();
      });
    },
    _globalBindToFunction: function _globalBindToFunction(func, binding) {
      var funcToBind = typeof func === "function" ? func : this[func];

      if (typeof funcToBind === "function") {
        this.keyTrapper.bindGlobal(binding, funcToBind.bind(this));
      }
    },
    _bindToFunction: function _bindToFunction(func, binding) {
      var funcToBind = typeof func === "function" ? func : this[func];

      if (typeof funcToBind === "function") {
        this.keyTrapper.bind(binding, funcToBind.bind(this));
      }
    },
    _moveSelection: function _moveSelection(direction) {
      // Pressing a move key (J/K) very quick (i.e. keeping J or K pressed) will
      // move fast by disabling smooth page scrolling.
      var now = +new Date();
      var fast = this._lastMoveTime && now - this._lastMoveTime < 1.5 * animationDuration;
      this._lastMoveTime = now;

      var $articles = this._findArticles();

      if ($articles === undefined) {
        return;
      }

      var $selected = $articles.filter(".selected");

      if ($selected.length === 0) {
        $selected = $articles.filter("[data-islastviewedtopic=true]");
      } // Discard selection if it is not in viewport, so users can combine
      // keyboard shortcuts with mouse scrolling.


      if ($selected.length !== 0 && !fast) {
        var offset = (0, _offsetCalculator.headerOffset)();
        var beginScreen = $(window).scrollTop() - offset;
        var endScreen = beginScreen + window.innerHeight + offset;
        var beginArticle = $selected.offset().top;
        var endArticle = $selected.offset().top + $selected.height();

        if (beginScreen > endArticle || beginArticle > endScreen) {
          $selected = null;
        }
      } // If still nothing is selected, select the first post that is
      // visible and cancel move operation.


      if (!$selected || $selected.length === 0) {
        var _offset = (0, _offsetCalculator.headerOffset)();

        $selected = $articles.toArray().find(function (article) {
          return direction > 0 ? article.getBoundingClientRect().top > _offset : article.getBoundingClientRect().bottom > _offset;
        });

        if (!$selected) {
          $selected = $articles[$articles.length - 1];
        }

        direction = 0;
      }

      var index = $articles.index($selected);
      var article = $articles.eq(index)[0]; // Try doing a page scroll in the context of current post.

      if (!fast && direction !== 0 && article) {
        // The beginning of first article is the beginning of the page.
        var _beginArticle = article.classList.contains("topic-post") && article.querySelector("#post_1") ? 0 : _domUtils.default.offset(article).top;

        var _endArticle = _domUtils.default.offset(article).top + article.offsetHeight;

        var _beginScreen = window.scrollY;

        var _endScreen = _beginScreen + window.innerHeight;

        if (direction < 0 && _beginScreen > _beginArticle) {
          return this._scrollTo(Math.max(_beginScreen - window.innerHeight + 3 * (0, _offsetCalculator.headerOffset)(), // page up
          _beginArticle - (0, _offsetCalculator.headerOffset)() // beginning of article
          ));
        } else if (direction > 0 && _endScreen < _endArticle - (0, _offsetCalculator.headerOffset)()) {
          return this._scrollTo(Math.min(_endScreen - 3 * (0, _offsetCalculator.headerOffset)(), // page down
          _endArticle - window.innerHeight // end of article
          ));
        }
      } // Try scrolling to post above or below.


      if ($selected.length !== 0) {
        if (direction === -1 && index === 0) {
          return;
        }

        if (direction === 1 && index === $articles.length - 1) {
          return;
        }
      }

      article = $articles.eq(index + direction)[0];

      if (!article) {
        return;
      }

      $articles.removeClass("selected");
      article.classList.add("selected");
      this.appEvents.trigger("keyboard:move-selection", {
        articles: $articles.get(),
        selectedArticle: article
      });

      var articleTop = _domUtils.default.offset(article).top,
          articleTopPosition = articleTop - (0, _offsetCalculator.headerOffset)();

      if (!fast && direction < 0 && article.offsetHeight > window.innerHeight) {
        // Scrolling to the last "page" of the previous post if post has multiple
        // "pages" (if its height does not fit in the screen).
        return this._scrollTo(articleTop + article.offsetHeight - window.innerHeight);
      } else if (article.classList.contains("topic-post")) {
        return this._scrollTo(article.querySelector("#post_1") ? 0 : articleTopPosition, {
          focusTabLoc: true
        });
      } // Otherwise scroll through the topic list.


      if (articleTopPosition > window.pageYOffset && articleTop + article.offsetHeight < window.pageYOffset + window.innerHeight) {
        return;
      }

      var scrollRatio = direction > 0 ? 0.2 : 0.7;

      this._scrollTo(articleTopPosition - window.innerHeight * scrollRatio);
    },
    _scrollTo: function _scrollTo(scrollTop) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      window.scrollTo({
        top: scrollTop,
        behavior: "smooth"
      });

      if (opts.focusTabLoc) {
        window.addEventListener("scroll", this._onScrollEnds, {
          passive: true
        });
      }
    },
    _onScrollEnds: function _onScrollEnds() {
      window.removeEventListener("scroll", this._onScrollEnds, {
        passive: true
      });
      (0, _debounce.default)(this, this._onScrollEndsCallback, animationDuration);
    },
    _onScrollEndsCallback: function _onScrollEndsCallback() {
      var _document$querySelect;

      (_document$querySelect = document.querySelector(".topic-post.selected a.tabLoc")) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.focus();
    },
    categoriesTopicsList: function categoriesTopicsList() {
      switch (this.siteSettings.desktop_category_page_style) {
        case "categories_with_featured_topics":
          return $(".latest .featured-topic");

        case "categories_and_latest_topics":
          return $(".latest-topic-list .latest-topic-list-item");

        case "categories_and_top_topics":
          return $(".top-topic-list .latest-topic-list-item");

        default:
          return $();
      }
    },
    _findArticles: function _findArticles() {
      var $topicList = $(".topic-list");
      var $postsWrapper = $(".posts-wrapper");
      var $categoriesTopicsList = this.categoriesTopicsList();
      var $searchResults = $(".search-results");

      if ($postsWrapper.length > 0) {
        return $(".posts-wrapper .topic-post, .topic-list tbody tr");
      } else if ($topicList.length > 0) {
        return $topicList.find(".topic-list-item");
      } else if ($categoriesTopicsList.length > 0) {
        return $categoriesTopicsList;
      } else if ($searchResults.length > 0) {
        return $searchResults.find(".fps-result");
      }
    },
    _changeSection: function _changeSection(direction) {
      var $sections = $(".nav.nav-pills li"),
          active = $(".nav.nav-pills li.active"),
          index = $sections.index(active) + direction;

      if (index >= 0 && index < $sections.length) {
        $sections.eq(index).find("a").click();
      }
    },
    _stopCallback: function _stopCallback() {
      var prototype = Object.getPrototypeOf(this.keyTrapper);
      var oldStopCallback = prototype.stopCallback;

      prototype.stopCallback = function (e, element, combo, sequence) {
        if (this.paused) {
          return true;
        }

        if ((combo === "ctrl+f" || combo === "command+f") && element.id === "search-term") {
          return false;
        }

        return oldStopCallback.call(this, e, element, combo, sequence);
      };
    },
    _replyToPost: function _replyToPost() {
      this.container.lookup("controller:topic").send("replyToPost");
    },
    _getSelectedPost: function _getSelectedPost() {
      return document.querySelector(".topic-post.selected article[data-post-id]");
    },
    _getSelectedTopicListItem: function _getSelectedTopicListItem() {
      return document.querySelector("tr.selected.topic-list-item");
    },
    deferTopic: function deferTopic() {
      this.container.lookup("controller:topic").send("deferTopic");
    },
    toggleAdminActions: function toggleAdminActions() {
      this.appEvents.trigger("topic:toggle-actions");
    },
    webviewKeyboardBack: function webviewKeyboardBack() {
      if ((0, _utilities.isAppWebview)()) {
        window.history.back();
      }
    },
    webviewKeyboardForward: function webviewKeyboardForward() {
      if ((0, _utilities.isAppWebview)()) {
        window.history.forward();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "_onScrollEnds", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_onScrollEnds"), _obj)), _obj);

  _exports.default = _default;
});