define("discourse/models/invite", ["exports", "discourse-common/utils/decorators", "discourse/models/topic", "discourse/models/user", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/url"], function (_exports, _decorators, _topic, _user, _ajax, _ajaxError, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var Invite = Ember.Object.extend((_dec = (0, _decorators.default)("invite_key"), _dec2 = (0, _decorators.default)("groups"), _dec3 = (0, _decorators.default)("topics.firstObject"), _dec4 = (0, _decorators.default)("email", "domain"), (_obj = {
    save: function save(data) {
      var _this = this;

      var promise = this.id ? (0, _ajax.ajax)("/invites/".concat(this.id), {
        type: "PUT",
        data: data
      }) : (0, _ajax.ajax)("/invites", {
        type: "POST",
        data: data
      });
      return promise.then(function (result) {
        return _this.setProperties(result);
      });
    },
    destroy: function destroy() {
      var _this2 = this;

      return (0, _ajax.ajax)("/invites", {
        type: "DELETE",
        data: {
          id: this.id
        }
      }).then(function () {
        return _this2.set("destroyed", true);
      });
    },
    reinvite: function reinvite() {
      var _this3 = this;

      return (0, _ajax.ajax)("/invites/reinvite", {
        type: "POST",
        data: {
          email: this.email
        }
      }).then(function () {
        return _this3.set("reinvited", true);
      }).catch(_ajaxError.popupAjaxError);
    },
    shortKey: function shortKey(key) {
      return key.substr(0, 4) + "...";
    },
    groupIds: function groupIds(groups) {
      return groups ? groups.map(function (group) {
        return group.id;
      }) : [];
    },
    topic: function topic(topicData) {
      return topicData ? _topic.default.create(topicData) : null;
    },
    emailOrDomain: function emailOrDomain(email, domain) {
      return email || domain;
    },
    topicId: Ember.computed.alias("topics.firstObject.id"),
    topicTitle: Ember.computed.alias("topics.firstObject.title")
  }, (_applyDecoratedDescriptor(_obj, "shortKey", [_dec], Object.getOwnPropertyDescriptor(_obj, "shortKey"), _obj), _applyDecoratedDescriptor(_obj, "groupIds", [_dec2], Object.getOwnPropertyDescriptor(_obj, "groupIds"), _obj), _applyDecoratedDescriptor(_obj, "topic", [_dec3], Object.getOwnPropertyDescriptor(_obj, "topic"), _obj), _applyDecoratedDescriptor(_obj, "emailOrDomain", [_dec4], Object.getOwnPropertyDescriptor(_obj, "emailOrDomain"), _obj)), _obj)));
  Invite.reopenClass({
    create: function create() {
      var result = this._super.apply(this, arguments);

      if (result.user) {
        result.user = _user.default.create(result.user);
      }

      return result;
    },
    findInvitedBy: function findInvitedBy(user, filter, search, offset) {
      if (!user) {
        Ember.RSVP.Promise.resolve();
      }

      var data = {};

      if (!Ember.isNone(filter)) {
        data.filter = filter;
      }

      if (!Ember.isNone(search)) {
        data.search = search;
      }

      data.offset = offset || 0;
      return (0, _ajax.ajax)((0, _url.userPath)("".concat(user.username_lower, "/invited.json")), {
        data: data
      }).then(function (result) {
        result.invites = result.invites.map(function (i) {
          return Invite.create(i);
        });
        return Ember.Object.create(result);
      });
    },
    reinviteAll: function reinviteAll() {
      return (0, _ajax.ajax)("/invites/reinvite-all", {
        type: "POST"
      });
    },
    destroyAllExpired: function destroyAllExpired() {
      return (0, _ajax.ajax)("/invites/destroy-all-expired", {
        type: "POST"
      });
    }
  });
  var _default = Invite;
  _exports.default = _default;
});