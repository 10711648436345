define("discourse/initializers/jquery-plugins", ["exports", "discourse/lib/autocomplete", "bootbox"], function (_exports, _autocomplete, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "jquery-plugins",
    initialize: function initialize() {
      // Settings for bootbox
      _bootbox.default.animate(false);

      _bootbox.default.backdrop(true); // Initialize the autocomplete tool


      $.fn.autocomplete = _autocomplete.default;
    }
  };
  _exports.default = _default;
});