define("discourse/templates/components/reviewable-field-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nYRD3weM",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"d-editor\",null,[[\"value\",\"change\"],[[24,[\"value\"]],[24,[\"valueChanged\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-field-editor.hbs"
    }
  });

  _exports.default = _default;
});