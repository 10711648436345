define("discourse/templates/modal/discard-draft", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3gtigaSy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"headerClass\"],[\"hidden\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"instructions\"],[8],[0,\"\\n    \"],[1,[28,\"i18n\",[\"post.cancel_composer.confirm\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"icon\",\"label\",\"class\",\"action\"],[\"far-trash-alt\",\"post.cancel_composer.discard\",\"btn-danger discard-draft\",[28,\"action\",[[23,0,[]],\"destroyDraft\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"canSaveDraft\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"d-button\",null,[[\"label\",\"class\",\"action\"],[\"post.cancel_composer.save_draft\",\"save-draft\",[28,\"action\",[[23,0,[]],\"saveDraftAndClose\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[28,\"d-button\",null,[[\"label\",\"class\",\"action\"],[\"post.cancel_composer.keep_editing\",\"keep-editing\",[28,\"action\",[[23,0,[]],\"dismissModal\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/discard-draft.hbs"
    }
  });

  _exports.default = _default;
});