define("discourse/controllers/navigation/categories", ["exports", "discourse/controllers/navigation/default"], function (_exports, _default2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _default2.default.extend({
    discoveryCategories: Ember.inject.controller("discovery/categories")
  });

  _exports.default = _default;
});