define("discourse/templates/components/composer-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7kLpjPS/",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[4,\"each\",[[24,[\"messages\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"composer-message\",null,[[\"message\",\"closeMessage\",\"shareModal\",\"switchPM\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"closeMessage\"],null],[28,\"action\",[[23,0,[]],\"shareModal\"],null],[28,\"action\",[[23,0,[]],\"switchPM\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/composer-messages.hbs"
    }
  });

  _exports.default = _default;
});