define("discourse/helpers/format-age", ["discourse/lib/formatter", "discourse-common/lib/helpers"], function (_formatter, _helpers) {
  "use strict";

  (0, _helpers.registerUnbound)("format-age", function (dt) {
    dt = new Date(dt);
    return Ember.String.htmlSafe((0, _formatter.autoUpdatingRelativeAge)(dt));
  });
  (0, _helpers.registerUnbound)("format-duration", function (seconds) {
    return Ember.String.htmlSafe((0, _formatter.durationTiny)(seconds));
  });
});