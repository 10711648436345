define("discourse/helpers/bound-avatar-template", ["exports", "discourse/lib/utilities", "discourse-common/lib/helpers"], function (_exports, _utilities, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helpers.htmlHelper)(function (avatarTemplate, size) {
    if (Ember.isEmpty(avatarTemplate)) {
      return "<div class='avatar-placeholder'></div>";
    } else {
      return (0, _utilities.avatarImg)({
        size: size,
        avatarTemplate: avatarTemplate
      });
    }
  });

  _exports.default = _default;
});