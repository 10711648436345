define("discourse/widgets/menu-panel", ["discourse/widgets/widget", "virtual-dom"], function (_widget, _virtualDom) {
  "use strict";

  (0, _widget.createWidget)("menu-links", {
    buildClasses: function buildClasses(attrs) {
      if (attrs.name && attrs.name.length) {
        return "menu-container-".concat(attrs.name);
      }
    },
    html: function html(attrs) {
      var links = [].concat(attrs.contents());
      var liOpts = {};

      if (attrs.heading) {
        liOpts.className = "header";
      }

      var result = [];
      result.push((0, _virtualDom.h)("ul.menu-links.columned", links.map(function (l) {
        return (0, _virtualDom.h)("li", liOpts, l);
      })));
      result.push((0, _virtualDom.h)("div.clearfix"));

      if (!attrs.omitRule) {
        result.push((0, _virtualDom.h)("hr"));
      }

      return result;
    }
  });
  (0, _widget.createWidget)("menu-panel", {
    tagName: "div.menu-panel",
    template: function template(attrs, state) {
      var _r = [];

      _r.push("\n    ");

      var _a0 = [];

      _a0.push("\n      ");

      var _a1 = [];

      _a1.push("\n        ");

      _a1.push(this.attrs.contents());

      _a1.push("\n      ");

      _a0.push(virtualDom.h('div', {
        "className": "panel-body-contents",
        "attributes": {}
      }, _a1));

      _a0.push("\n    ");

      _r.push(virtualDom.h('div', {
        "className": "panel-body",
        "attributes": {}
      }, _a0));

      _r.push("\n  ");

      return _r;
    },
    buildAttributes: function buildAttributes(attrs) {
      if (attrs.maxWidth) {
        return {
          "data-max-width": attrs.maxWidth
        };
      }
    }
  });
});