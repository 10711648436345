define("discourse/templates/modal/convert-to-public-topic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yiVTJNse",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"title\"],[\"topic.make_public.title\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"instructions\"],[8],[0,\"\\n    \"],[1,[28,\"i18n\",[\"topic.make_public.choose_category\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"category-chooser\",null,[[\"value\",\"onChange\"],[[24,[\"publicCategoryId\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"publicCategoryId\"]]],null]],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"class\",\"action\",\"label\",\"disabled\"],[\"btn-primary\",[28,\"action\",[[23,0,[]],\"makePublic\"],null],\"composer.modal_ok\",[24,[\"saving\"]]]]],false],[0,\"\\n  \"],[1,[28,\"d-modal-cancel\",null,[[\"close\"],[[28,\"route-action\",[\"closeModal\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/convert-to-public-topic.hbs"
    }
  });

  _exports.default = _default;
});