define("discourse/templates/components/cdn-img", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7rfZp+uR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"src\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"class\"],\" aspect-image\"]]],[11,\"style\",[22,\"style\"]],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[22,\"cdnSrc\"]],[11,\"width\",[22,\"width\"]],[11,\"height\",[22,\"height\"]],[11,\"alt\",[22,\"alt\"]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/cdn-img.hbs"
    }
  });

  _exports.default = _default;
});