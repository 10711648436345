define("discourse/templates/components/composer-user-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "stIyQltO",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"email-group-user-chooser\",null,[[\"id\",\"value\",\"onChange\",\"options\"],[\"private-message-users\",[24,[\"splitRecipients\"]],[28,\"action\",[[23,0,[]],\"updateRecipients\"],null],[28,\"hash\",null,[[\"topicId\",\"none\",\"includeMessageableGroups\",\"allowEmails\",\"autoWrap\"],[[24,[\"topicId\"]],\"composer.users_placeholder\",true,[24,[\"currentUser\",\"can_send_private_email_messages\"]],true]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/composer-user-selector.hbs"
    }
  });

  _exports.default = _default;
});