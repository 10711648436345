define("discourse/controllers/group-manage-logs", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("filters.action", "filters.acting_user", "filters.target_user", "filters.subject"), _dec2 = (0, _decorators.observes)("filters.action", "filters.acting_user", "filters.target_user", "filters.subject"), _dec3 = (0, _decorators.observes)("model.all_loaded"), _dec4 = Ember._action, _dec5 = Ember._action, (_obj = {
    group: Ember.inject.controller(),
    application: Ember.inject.controller(),
    loading: false,
    offset: 0,
    init: function init() {
      this._super.apply(this, arguments);

      this.set("filters", Ember.Object.create());
    },
    filterParams: function filterParams(filtersAction, acting_user, target_user, subject) {
      return {
        action: filtersAction,
        acting_user: acting_user,
        target_user: target_user,
        subject: subject
      };
    },
    _refreshModel: function _refreshModel() {
      var _this = this;

      this.get("group.model").findLogs(0, this.filterParams).then(function (results) {
        _this.set("offset", 0);

        _this.model.setProperties({
          logs: results.logs,
          all_loaded: results.all_loaded
        });
      });
    },
    _showFooter: function _showFooter() {
      this.set("application.showFooter", this.get("model.all_loaded"));
    },
    reset: function reset() {
      this.setProperties({
        offset: 0,
        filters: Ember.Object.create()
      });
    },
    loadMore: function loadMore() {
      var _this2 = this;

      if (this.get("model.all_loaded")) {
        return;
      }

      this.set("loading", true);
      this.get("group.model").findLogs(this.offset + 1, this.filterParams).then(function (results) {
        results.logs.forEach(function (result) {
          return _this2.get("model.logs").addObject(result);
        });

        _this2.incrementProperty("offset");

        _this2.set("model.all_loaded", results.all_loaded);
      }).finally(function () {
        return _this2.set("loading", false);
      });
    },
    clearFilter: function clearFilter(key) {
      this.set("filters.".concat(key), "");
    }
  }, (_applyDecoratedDescriptor(_obj, "filterParams", [_dec], Object.getOwnPropertyDescriptor(_obj, "filterParams"), _obj), _applyDecoratedDescriptor(_obj, "_refreshModel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_refreshModel"), _obj), _applyDecoratedDescriptor(_obj, "_showFooter", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_showFooter"), _obj), _applyDecoratedDescriptor(_obj, "loadMore", [_dec4], Object.getOwnPropertyDescriptor(_obj, "loadMore"), _obj), _applyDecoratedDescriptor(_obj, "clearFilter", [_dec5], Object.getOwnPropertyDescriptor(_obj, "clearFilter"), _obj)), _obj)));

  _exports.default = _default;
});