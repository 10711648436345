define("discourse/templates/components/flag-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oS57z6cl",
    "block": "{\"symbols\":[\"f\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"flags\"]]],null,{\"statements\":[[0,\"  \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[1,[28,\"i18n\",[\"flagging.cant\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/flag-selection.hbs"
    }
  });

  _exports.default = _default;
});