define("discourse/components/header-extra-info", ["exports", "discourse-common/lib/deprecated"], function (_exports, _deprecated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.needsSecondRowIf = needsSecondRowIf;

  function needsSecondRowIf() {
    (0, _deprecated.default)("`needsSecondRowIf` is deprecated. Use widget hooks on `header-second-row`");
  }
});