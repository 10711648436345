define("discourse/components/composer-body", ["exports", "discourse-common/utils/decorators", "discourse/models/composer", "discourse/mixins/key-enter-escape", "discourse/lib/after-transition", "discourse-common/lib/debounce", "discourse/lib/offset-calculator", "discourse/lib/safari-hacks"], function (_exports, _decorators, _composer, _keyEnterEscape, _afterTransition, _debounce, _offsetCalculator, _safariHacks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var START_DRAG_EVENTS = ["touchstart", "mousedown"];
  var DRAG_EVENTS = ["touchmove", "mousemove"];
  var END_DRAG_EVENTS = ["touchend", "mouseup"];
  var THROTTLE_RATE = 20;

  function mouseYPos(e) {
    return e.clientY || e.touches && e.touches[0] && e.touches[0].clientY;
  }

  var _default = Ember.Component.extend(_keyEnterEscape.default, (_dec = (0, _decorators.default)("composer.action"), _dec2 = (0, _decorators.default)("currentUser.primary_group_name"), _dec3 = (0, _decorators.default)("composer.composeState"), _dec4 = (0, _decorators.observes)("composeState", "composer.{action,canEditTopicFeaturedLink}"), _dec5 = (0, _decorators.observes)("composeState"), (_obj = {
    elementId: "reply-control",
    classNameBindings: ["composer.creatingPrivateMessage:private-message", "composeState", "composer.loading", "prefixedComposerAction", "composer.canEditTitle:edit-title", "composer.createdPost:created-post", "composer.creatingTopic:topic", "composer.whisper:composing-whisper", "composer.sharedDraft:composing-shared-draft", "showPreview:show-preview:hide-preview", "currentUserPrimaryGroupClass"],
    prefixedComposerAction: function prefixedComposerAction(action) {
      return action ? "composer-action-".concat(action) : "";
    },
    currentUserPrimaryGroupClass: function currentUserPrimaryGroupClass(primaryGroupName) {
      return primaryGroupName && "group-".concat(primaryGroupName);
    },
    composeState: function composeState(_composeState) {
      return _composeState || _composer.default.CLOSED;
    },
    movePanels: function movePanels(size) {
      document.querySelector("#main-outlet").style.paddingBottom = size ? "".concat(size, "px") : ""; // signal the progress bar it should move!

      this.appEvents.trigger("composer:resized");
    },
    resize: function resize() {
      var _this = this;

      Ember.run.schedule("afterRender", function () {
        if (!_this.element || _this.isDestroying || _this.isDestroyed) {
          return;
        }

        (0, _debounce.default)(_this, _this.debounceMove, 300);
      });
    },
    debounceMove: function debounceMove() {
      var height = 0;

      if (!this.element.classList.contains("saving")) {
        height = this.element.offsetHeight;
      }

      this.movePanels(height);
    },
    keyUp: function keyUp() {
      var _this2 = this;

      this.typed();
      var lastKeyUp = new Date();
      this._lastKeyUp = lastKeyUp; // One second from now, check to see if the last key was hit when
      // we recorded it. If it was, the user paused typing.

      Ember.run.cancel(this._lastKeyTimeout);
      this._lastKeyTimeout = Ember.run.later(function () {
        if (lastKeyUp !== _this2._lastKeyUp) {
          return;
        }

        _this2.appEvents.trigger("composer:find-similar");
      }, 1000);
    },
    disableFullscreen: function disableFullscreen() {
      if (this.composeState !== _composer.default.OPEN && _safariHacks.default.blur) {
        _safariHacks.default.blur();
      }
    },
    setupComposerResizeEvents: function setupComposerResizeEvents() {
      var _this3 = this;

      this.origComposerSize = 0;
      this.lastMousePos = 0;
      START_DRAG_EVENTS.forEach(function (startDragEvent) {
        var _this3$element$queryS;

        (_this3$element$queryS = _this3.element.querySelector(".grippie")) === null || _this3$element$queryS === void 0 ? void 0 : _this3$element$queryS.addEventListener(startDragEvent, _this3.startDragHandler, {
          passive: false
        });
      });

      if (this._visualViewportResizing()) {
        this.viewportResize();
        window.visualViewport.addEventListener("resize", this.viewportResize);
      }
    },
    performDragHandler: function performDragHandler() {
      this.appEvents.trigger("composer:div-resizing");
      this.element.classList.add("clear-transitions");
      var currentMousePos = mouseYPos(event);
      var size = this.origComposerSize + (this.lastMousePos - currentMousePos);
      size = Math.min(size, window.innerHeight - (0, _offsetCalculator.headerOffset)());
      this.movePanels(size);
      this.element.style.height = size ? "".concat(size, "px") : "";
    },
    startDragHandler: function startDragHandler(event) {
      var _this4 = this;

      event.preventDefault();
      this.origComposerSize = this.element.offsetHeight;
      this.lastMousePos = mouseYPos(event);
      DRAG_EVENTS.forEach(function (dragEvent) {
        document.addEventListener(dragEvent, _this4.throttledPerformDrag);
      });
      END_DRAG_EVENTS.forEach(function (endDragEvent) {
        document.addEventListener(endDragEvent, _this4.endDragHandler);
      });
      this.appEvents.trigger("composer:resize-started");
    },
    endDragHandler: function endDragHandler() {
      var _this5 = this;

      this.appEvents.trigger("composer:resize-ended");
      DRAG_EVENTS.forEach(function (dragEvent) {
        document.removeEventListener(dragEvent, _this5.throttledPerformDrag);
      });
      END_DRAG_EVENTS.forEach(function (endDragEvent) {
        document.removeEventListener(endDragEvent, _this5.endDragHandler);
      });
      this.element.classList.remove("clear-transitions");
      this.element.focus();
    },
    throttledPerformDrag: function throttledPerformDrag(event) {
      event.preventDefault();
      Ember.run.throttle(this, this.performDragHandler, event, THROTTLE_RATE);
    },
    viewportResize: function viewportResize() {
      var composerVH = window.visualViewport.height * 0.01,
          doc = document.documentElement;
      doc.style.setProperty("--composer-vh", "".concat(composerVH, "px"));
      var viewportWindowDiff = this.windowInnerHeight - window.visualViewport.height;
      viewportWindowDiff > 0 ? doc.classList.add("keyboard-visible") : doc.classList.remove("keyboard-visible"); // adds bottom padding when using a hardware keyboard and the accessory bar is visible
      // accessory bar height is 55px, using 75 allows a small buffer

      doc.style.setProperty("--composer-ipad-padding", "".concat(viewportWindowDiff < 75 ? viewportWindowDiff : 0, "px"));
    },
    _visualViewportResizing: function _visualViewportResizing() {
      return (this.capabilities.isIpadOS || this.site.mobileView) && window.visualViewport !== undefined;
    },
    didInsertElement: function didInsertElement() {
      var _this6 = this;

      this._super.apply(this, arguments);

      if (this._visualViewportResizing()) {
        this.set("windowInnerHeight", window.innerHeight);
      }

      this.setupComposerResizeEvents();

      var resize = function resize() {
        return Ember.run(function () {
          return _this6.resize();
        });
      };

      var triggerOpen = function triggerOpen() {
        if (_this6.get("composer.composeState") === _composer.default.OPEN) {
          _this6.appEvents.trigger("composer:opened");
        }
      };

      triggerOpen();
      (0, _afterTransition.default)($(this.element), function () {
        resize();
        triggerOpen();
      });
      (0, _safariHacks.default)($(this.element));
    },
    willDestroyElement: function willDestroyElement() {
      var _this7 = this;

      this._super.apply(this, arguments);

      if (this._visualViewportResizing()) {
        window.visualViewport.removeEventListener("resize", this.viewportResize);
      }

      START_DRAG_EVENTS.forEach(function (startDragEvent) {
        var _this7$element$queryS;

        (_this7$element$queryS = _this7.element.querySelector(".grippie")) === null || _this7$element$queryS === void 0 ? void 0 : _this7$element$queryS.removeEventListener(startDragEvent, _this7.startDragHandler);
      });
      Ember.run.cancel(this._lastKeyTimeout);
    },
    click: function click() {
      this.openIfDraft();
    }
  }, (_applyDecoratedDescriptor(_obj, "prefixedComposerAction", [_dec], Object.getOwnPropertyDescriptor(_obj, "prefixedComposerAction"), _obj), _applyDecoratedDescriptor(_obj, "currentUserPrimaryGroupClass", [_dec2], Object.getOwnPropertyDescriptor(_obj, "currentUserPrimaryGroupClass"), _obj), _applyDecoratedDescriptor(_obj, "composeState", [_dec3], Object.getOwnPropertyDescriptor(_obj, "composeState"), _obj), _applyDecoratedDescriptor(_obj, "resize", [_dec4], Object.getOwnPropertyDescriptor(_obj, "resize"), _obj), _applyDecoratedDescriptor(_obj, "disableFullscreen", [_dec5], Object.getOwnPropertyDescriptor(_obj, "disableFullscreen"), _obj), _applyDecoratedDescriptor(_obj, "performDragHandler", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "performDragHandler"), _obj), _applyDecoratedDescriptor(_obj, "startDragHandler", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "startDragHandler"), _obj), _applyDecoratedDescriptor(_obj, "endDragHandler", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "endDragHandler"), _obj), _applyDecoratedDescriptor(_obj, "throttledPerformDrag", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "throttledPerformDrag"), _obj), _applyDecoratedDescriptor(_obj, "viewportResize", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "viewportResize"), _obj)), _obj)));

  _exports.default = _default;
});