define("discourse/components/login-reply-button", ["exports", "discourse/components/d-button"], function (_exports, _dButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dButton.default.extend({
    label: "topic.reply.title",
    icon: "reply",
    action: "showLogin"
  });

  _exports.default = _default;
});