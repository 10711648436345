define("discourse/routes/user-deleted-posts", ["exports", "discourse/routes/build-admin-user-posts-route"], function (_exports, _buildAdminUserPostsRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _buildAdminUserPostsRoute.default)("deleted");

  _exports.default = _default;
});