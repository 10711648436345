define("discourse/controllers/avatar-selector", ["exports", "discourse/mixins/modal-functionality", "discourse/lib/ajax", "discourse/lib/uploads", "discourse-common/utils/decorators", "discourse/lib/ajax-error", "discourse/lib/computed"], function (_exports, _modalFunctionality, _ajax, _uploads, _decorators, _ajaxError, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("selected", "uploading"), _dec2 = (0, _decorators.default)("siteSettings.selectable_avatars_enabled", "siteSettings.selectable_avatars"), _dec3 = (0, _decorators.default)("user.use_logo_small_as_avatar", "user.avatar_template", "user.system_avatar_template", "user.gravatar_avatar_template"), _dec4 = (0, _decorators.default)("selected", "user.system_avatar_upload_id", "user.gravatar_avatar_upload_id", "user.custom_avatar_upload_id"), _dec5 = (0, _decorators.default)("selected", "user.system_avatar_template", "user.gravatar_avatar_template", "user.custom_avatar_template"), _dec6 = (0, _decorators.default)("siteSettings.allow_uploaded_avatars"), (_obj = {
    gravatarName: (0, _computed.setting)("gravatar_name"),
    gravatarBaseUrl: (0, _computed.setting)("gravatar_base_url"),
    gravatarLoginUrl: (0, _computed.setting)("gravatar_login_url"),
    submitDisabled: function submitDisabled(selected, uploading) {
      return selected === "logo" || uploading;
    },
    selectableAvatars: function selectableAvatars(enabled, list) {
      if (enabled) {
        return list ? list.split("|") : [];
      }
    },
    selected: function selected(useLogo, avatarTemplate, systemAvatarTemplate, gravatarAvatarTemplate) {
      if (useLogo) {
        return "logo";
      } else if (avatarTemplate === systemAvatarTemplate) {
        return "system";
      } else if (avatarTemplate === gravatarAvatarTemplate) {
        return "gravatar";
      } else {
        return "custom";
      }
    },
    selectedUploadId: function selectedUploadId(selected, system, gravatar, custom) {
      switch (selected) {
        case "system":
          return system;

        case "gravatar":
          return gravatar;

        default:
          return custom;
      }
    },
    selectedAvatarTemplate: function selectedAvatarTemplate(selected, system, gravatar, custom) {
      switch (selected) {
        case "system":
          return system;

        case "gravatar":
          return gravatar;

        default:
          return custom;
      }
    },
    siteSettingMatches: function siteSettingMatches(value, user) {
      switch (value) {
        case "disabled":
          return false;

        case "staff":
          return user.staff;

        case "admin":
          return user.admin;

        default:
          return user.trust_level >= parseInt(value, 10) || user.staff;
      }
    },
    allowAvatarUpload: function allowAvatarUpload(allowUploadedAvatars) {
      return this.siteSettingMatches(allowUploadedAvatars, this.currentUser) && (0, _uploads.allowsImages)(this.currentUser.staff, this.siteSettings);
    },
    actions: {
      uploadComplete: function uploadComplete() {
        this.set("selected", "custom");
      },
      refreshGravatar: function refreshGravatar() {
        var _this = this;

        this.set("gravatarRefreshDisabled", true);
        return (0, _ajax.ajax)("/user_avatar/".concat(this.get("user.username"), "/refresh_gravatar.json"), {
          type: "POST"
        }).then(function (result) {
          if (!result.gravatar_upload_id) {
            _this.set("gravatarFailed", true);
          } else {
            _this.set("gravatarFailed", false);

            _this.user.setProperties({
              gravatar_avatar_upload_id: result.gravatar_upload_id,
              gravatar_avatar_template: result.gravatar_avatar_template
            });
          }
        }).finally(function () {
          return _this.set("gravatarRefreshDisabled", false);
        });
      },
      selectAvatar: function selectAvatar(url) {
        this.user.selectAvatar(url).then(function () {
          return window.location.reload();
        }).catch(_ajaxError.popupAjaxError);
      },
      saveAvatarSelection: function saveAvatarSelection() {
        var selectedUploadId = this.selectedUploadId;
        var type = this.selected;
        this.user.pickAvatar(selectedUploadId, type).then(function () {
          return window.location.reload();
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "submitDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "submitDisabled"), _obj), _applyDecoratedDescriptor(_obj, "selectableAvatars", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectableAvatars"), _obj), _applyDecoratedDescriptor(_obj, "selected", [_dec3], Object.getOwnPropertyDescriptor(_obj, "selected"), _obj), _applyDecoratedDescriptor(_obj, "selectedUploadId", [_dec4], Object.getOwnPropertyDescriptor(_obj, "selectedUploadId"), _obj), _applyDecoratedDescriptor(_obj, "selectedAvatarTemplate", [_dec5], Object.getOwnPropertyDescriptor(_obj, "selectedAvatarTemplate"), _obj), _applyDecoratedDescriptor(_obj, "allowAvatarUpload", [_dec6], Object.getOwnPropertyDescriptor(_obj, "allowAvatarUpload"), _obj)), _obj)));

  _exports.default = _default;
});