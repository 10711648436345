define("discourse/templates/components/table-header-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WDzERLds",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"header-contents\"],[11,\"id\",[22,\"id\"]],[8],[0,\"\\n  \"],[1,[28,\"directory-table-header-title\",null,[[\"field\",\"labelKey\",\"icon\",\"translated\"],[[24,[\"field\"]],[24,[\"labelKey\"]],[24,[\"icon\"]],[24,[\"translated\"]]]]],false],[0,\"\\n  \"],[1,[22,\"chevronIcon\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/table-header-toggle.hbs"
    }
  });

  _exports.default = _default;
});