define("discourse/routes/user-notifications-index", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    controllerName: "user-notifications",
    renderTemplate: function renderTemplate() {
      this.render("user/notifications-index");
    },
    afterModel: function afterModel(model) {
      if (!model) {
        this.transitionTo("userNotifications.responses");
      }
    }
  });

  _exports.default = _default;
});