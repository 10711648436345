define("discourse/templates/components/reviewable-scores", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kNUb4Gp1",
    "block": "{\"symbols\":[\"rs\"],\"statements\":[[4,\"if\",[[24,[\"reviewable\",\"reviewable_scores\"]]],null,{\"statements\":[[0,\"  \"],[7,\"table\",true],[10,\"class\",\"reviewable-scores\"],[8],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"reviewable\",\"reviewable_scores\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"reviewable-score\",null,[[\"rs\",\"reviewable\"],[[23,1,[]],[24,[\"reviewable\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-scores.hbs"
    }
  });

  _exports.default = _default;
});