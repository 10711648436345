define("discourse/components/edit-category-topic-template", ["exports", "discourse/components/edit-category-panel", "discourse-common/utils/decorators"], function (_exports, _editCategoryPanel, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = (0, _editCategoryPanel.buildCategoryPanel)("topic-template", (_dec = (0, _decorators.observes)("activeTab"), (_obj = {
    // Modals are defined using the singleton pattern.
    // Opening the insert link modal will destroy the edit category modal.
    showInsertLinkButton: false,
    _activeTabChanged: function _activeTabChanged() {
      var _this = this;

      if (this.activeTab) {
        Ember.run.schedule("afterRender", function () {
          return _this.element.querySelector(".d-editor-input").focus();
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "_activeTabChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "_activeTabChanged"), _obj)), _obj)));

  _exports.default = _default;
});