define("discourse/lib/after-transition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
    CSS transitions are a PITA, often we need to queue some js after a transition, this helper ensures
    it happens after the transition.
  
    SO: http://stackoverflow.com/questions/9943435/css3-animation-end-techniques
  **/
  var dummy = document.createElement("div"),
      eventNameHash = {
    webkit: "webkitTransitionEnd",
    Moz: "transitionend",
    O: "oTransitionEnd",
    ms: "MSTransitionEnd"
  };

  var transitionEnd = function () {
    var retValue;
    retValue = "transitionend";
    Object.keys(eventNameHash).some(function (vendor) {
      if (vendor + "TransitionProperty" in dummy.style) {
        retValue = eventNameHash[vendor];
        return true;
      }
    });
    return retValue;
  }();

  function _default(element, callback) {
    return $(element).on(transitionEnd, function (event) {
      if (event.target !== event.currentTarget) {
        return;
      }

      return callback(event);
    });
  }
});