define("discourse/controllers/associate-account-confirm", ["exports", "discourse/mixins/modal-functionality", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _modalFunctionality, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    actions: {
      finishConnect: function finishConnect() {
        var _this = this;

        (0, _ajax.ajax)({
          url: "/associate/".concat(encodeURIComponent(this.model.token)),
          type: "POST"
        }).then(function (result) {
          if (result.success) {
            _this.transitionToRoute("preferences.account", _this.currentUser.findDetails());

            _this.send("closeModal");
          } else {
            _this.set("model.error", result.error);
          }
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });

  _exports.default = _default;
});