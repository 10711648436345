define("discourse/lib/logout", ["exports", "discourse-common/lib/get-url", "discourse-common/lib/helpers"], function (_exports, _getUrl, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = logout;

  function logout() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        redirect = _ref.redirect;

    var ctx = (0, _helpers.helperContext)();
    var keyValueStore = ctx.keyValueStore;
    keyValueStore.abandonLocal();

    if (!Ember.isEmpty(redirect)) {
      window.location.href = redirect;
      return;
    }

    window.location.href = (0, _getUrl.default)("/");
  }
});