define("discourse/lib/reports-loader", ["exports", "discourse/lib/ajax", "discourse-common/lib/debounce"], function (_exports, _ajax, _debounce) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _queue = [];
  var _processing = 0; // max number of reports which will be requested in one bulk request

  var MAX_JOB_SIZE = 4; // max number of concurrent bulk requests

  var MAX_CONCURRENCY = 3; // max number of jobs stored, first entered jobs will be evicted first

  var MAX_QUEUE_SIZE = 20;
  var BULK_REPORTS_ENDPOINT = "/admin/reports/bulk";
  var DEBOUNCING_DELAY = 50;
  var _default = {
    enqueue: function enqueue(type, params, callback) {
      // makes sures the queue is not filling indefinitely
      if (_queue.length >= MAX_QUEUE_SIZE) {
        var removedJobs = _queue.splice(0, 1)[0];

        removedJobs.forEach(function (job) {
          // this is technically not a 429, but it's the result
          // of client doing too many requests so we want the same
          // behavior
          job.runnable()(429);
        });
      }

      _queue.push({
        runnable: function runnable() {
          return callback;
        },
        type: type,
        params: params
      });

      (0, _debounce.default)(this, this._processQueue, DEBOUNCING_DELAY);
    },
    _processQueue: function _processQueue() {
      var _this = this;

      if (_queue.length === 0) {
        return;
      }

      if (_processing >= MAX_CONCURRENCY) {
        return;
      }

      _processing++;

      var jobs = _queue.splice(0, MAX_JOB_SIZE); // if queue has still jobs after splice, we request a future processing


      if (_queue.length > 0) {
        (0, _debounce.default)(this, this._processQueue, DEBOUNCING_DELAY);
      }

      var reports = {};
      jobs.forEach(function (job) {
        reports[job.type] = job.params;
      });
      (0, _ajax.ajax)(BULK_REPORTS_ENDPOINT, {
        data: {
          reports: reports
        }
      }).then(function (response) {
        jobs.forEach(function (job) {
          var report = response.reports.findBy("type", job.type);
          job.runnable()(report);
        });
      }).catch(function (data) {
        jobs.forEach(function (job) {
          if (data.jqXHR && data.jqXHR.status === 429) {
            job.runnable()(429);
          } else if (data.jqXHR && data.jqXHR.status === 500) {
            job.runnable()(500);
          } else {
            job.runnable()();
          }
        });
      }).finally(function () {
        _processing--;
        (0, _debounce.default)(_this, _this._processQueue, DEBOUNCING_DELAY);
      });
    },
    _reset: function _reset() {
      _queue = [];
      _processing = 0;
    }
  };
  _exports.default = _default;
});