define("discourse/routes/build-static-route", ["exports", "discourse/routes/discourse", "discourse/models/static-page"], function (_exports, _discourse, _staticPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(pageName) {
    var route = {
      model: function model() {
        return _staticPage.default.find(pageName);
      },
      renderTemplate: function renderTemplate() {
        this.render("static");
      },
      setupController: function setupController(controller, model) {
        this.controllerFor("static").set("model", model);
      }
    };
    return _discourse.default.extend(route);
  }
});