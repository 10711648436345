define("discourse/routes/group-messages-inbox", ["exports", "discourse/routes/build-group-messages-route"], function (_exports, _buildGroupMessagesRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _buildGroupMessagesRoute.default)("inbox");

  _exports.default = _default;
});