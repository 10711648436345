define("discourse/controllers/discovery-sortable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeSort = changeSort;
  _exports.resetParams = resetParams;
  _exports.default = _exports.addDiscoveryQueryParam = _exports.queryParams = void 0;
  // Just add query params here to have them automatically passed to topic list filters.
  var queryParams = {
    order: {
      replace: true,
      refreshModel: true
    },
    ascending: {
      replace: true,
      refreshModel: true,
      default: false
    },
    status: {
      replace: true,
      refreshModel: true
    },
    state: {
      replace: true,
      refreshModel: true
    },
    search: {
      replace: true,
      refreshModel: true
    },
    max_posts: {
      replace: true,
      refreshModel: true
    },
    q: {
      replace: true,
      refreshModel: true
    },
    before: {
      replace: true,
      refreshModel: true
    },
    bumped_before: {
      replace: true,
      refreshModel: true
    },
    f: {
      replace: true,
      refreshModel: true
    },
    period: {
      replace: true,
      refreshModel: true
    }
  }; // Basic controller options

  _exports.queryParams = queryParams;
  var controllerOpts = {
    discoveryTopics: Ember.inject.controller("discovery/topics"),
    queryParams: Object.keys(queryParams)
  }; // Default to `null`

  controllerOpts.queryParams.forEach(function (p) {
    controllerOpts[p] = queryParams[p].default;
  });

  function changeSort(sortBy) {
    var model = this.controllerFor("discovery.topics").model;

    if (sortBy === this.controller.order) {
      this.controller.toggleProperty("ascending");
      model.updateSortParams(sortBy, this.controller.ascending);
    } else {
      this.controller.setProperties({
        order: sortBy,
        ascending: false
      });
      model.updateSortParams(sortBy, false);
    }
  }

  function resetParams() {
    var _this = this;

    var skipParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    controllerOpts.queryParams.forEach(function (p) {
      if (!skipParams.includes(p)) {
        _this.controller.set(p, queryParams[p].default);
      }
    });
  }

  var SortableController = Ember.Controller.extend(controllerOpts);

  var addDiscoveryQueryParam = function addDiscoveryQueryParam(p, opts) {
    queryParams[p] = opts;
    var cOpts = {};
    cOpts[p] = null;
    cOpts["queryParams"] = Object.keys(queryParams);
    SortableController.reopen(cOpts);
  };

  _exports.addDiscoveryQueryParam = addDiscoveryQueryParam;
  var _default = SortableController;
  _exports.default = _default;
});