define("discourse/controllers/preferences/profile", ["exports", "I18n", "discourse/lib/ajax", "bootbox", "discourse/lib/text", "discourse-common/utils/decorators", "discourse/lib/ajax-error", "discourse/lib/show-modal"], function (_exports, _I18n, _ajax, _bootbox, _text, _decorators, _ajaxError, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("model.user_fields.@each.value"), _dec2 = (0, _decorators.default)("model.default_calendar"), (_obj = {
    init: function init() {
      this._super.apply(this, arguments);

      this.saveAttrNames = ["bio_raw", "website", "location", "custom_fields", "user_fields", "profile_background_upload_url", "card_background_upload_url", "date_of_birth", "timezone", "default_calendar"];
      this.calendarOptions = [{
        name: _I18n.default.t("download_calendar.google"),
        value: "google"
      }, {
        name: _I18n.default.t("download_calendar.ics"),
        value: "ics"
      }];
    },
    userFields: function userFields() {
      var _this = this;

      var siteUserFields = this.site.user_fields;

      if (Ember.isEmpty(siteUserFields)) {
        return;
      } // Staff can edit fields that are not `editable`


      if (!this.currentUser.staff) {
        siteUserFields = siteUserFields.filterBy("editable", true);
      }

      return siteUserFields.sortBy("position").map(function (field) {
        var _this$model$user_fiel;

        var value = (_this$model$user_fiel = _this.model.user_fields) === null || _this$model$user_fiel === void 0 ? void 0 : _this$model$user_fiel[field.id.toString()];
        return Ember.Object.create({
          field: field,
          value: value
        });
      });
    },
    canChangeDefaultCalendar: function canChangeDefaultCalendar(defaultCalendar) {
      return defaultCalendar !== "none_selected";
    },
    canChangeBio: Ember.computed.readOnly("model.can_change_bio"),
    canChangeLocation: Ember.computed.readOnly("model.can_change_location"),
    canChangeWebsite: Ember.computed.readOnly("model.can_change_website"),
    canUploadProfileHeader: Ember.computed.readOnly("model.can_upload_profile_header"),
    canUploadUserCardBackground: Ember.computed.readOnly("model.can_upload_user_card_background"),
    actions: {
      showFeaturedTopicModal: function showFeaturedTopicModal() {
        (0, _showModal.default)("feature-topic-on-profile", {
          model: this.model,
          title: "user.feature_topic_on_profile.title"
        });
      },
      clearFeaturedTopicFromProfile: function clearFeaturedTopicFromProfile() {
        var _this2 = this;

        _bootbox.default.confirm(_I18n.default.t("user.feature_topic_on_profile.clear.warning"), function (result) {
          if (result) {
            (0, _ajax.ajax)("/u/".concat(_this2.model.username, "/clear-featured-topic"), {
              type: "PUT"
            }).then(function () {
              _this2.model.set("featured_topic", null);
            }).catch(_ajaxError.popupAjaxError);
          }
        });
      },
      useCurrentTimezone: function useCurrentTimezone() {
        this.model.set("user_option.timezone", moment.tz.guess());
      },
      _updateUserFields: function _updateUserFields() {
        var model = this.model,
            userFields = this.userFields;

        if (!Ember.isEmpty(userFields)) {
          var modelFields = model.get("user_fields");

          if (!Ember.isEmpty(modelFields)) {
            userFields.forEach(function (uf) {
              var value = uf.get("value");
              modelFields[uf.get("field.id").toString()] = Ember.isEmpty(value) ? null : value;
            });
          }
        }
      },
      save: function save() {
        var _this3 = this;

        this.set("saved", false);
        var model = this.model; // Update the user fields

        this.send("_updateUserFields");
        return model.save(this.saveAttrNames).then(function () {
          // update the timezone in memory so we can use the new
          // one if we change routes without reloading the user
          if (_this3.currentUser.id === _this3.model.id) {
            _this3.currentUser.changeTimezone(_this3.model.user_option.timezone);
          }

          (0, _text.cookAsync)(model.get("bio_raw")).then(function () {
            model.set("bio_cooked");

            _this3.set("saved", true);
          }).catch(_ajaxError.popupAjaxError);
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "userFields", [_dec], Object.getOwnPropertyDescriptor(_obj, "userFields"), _obj), _applyDecoratedDescriptor(_obj, "canChangeDefaultCalendar", [_dec2], Object.getOwnPropertyDescriptor(_obj, "canChangeDefaultCalendar"), _obj)), _obj)));

  _exports.default = _default;
});