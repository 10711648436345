define("discourse/templates/user/notifications-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eYroTopB",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"error\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"item error\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"forbidden\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"i18n\",[\"errors.reasons.forbidden\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"i18n\",[\"errors.desc.unknown\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"doesNotHaveNotifications\"]]],null,{\"statements\":[[0,\"  \"],[5,\"empty-state\",[],[[\"@title\",\"@body\"],[[28,\"i18n\",[\"user.no_notifications_page_title\"],null],[22,\"emptyStateBody\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"user-notifications-filter\"],[8],[0,\"\\n    \"],[1,[28,\"notifications-filter\",null,[[\"value\",\"onChange\"],[[24,[\"filter\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"filter\"]]],null]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"nothingFound\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"alert alert-info\"],[8],[1,[28,\"i18n\",[\"notifications.empty\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"user-notifications-large\",null,[[\"notifications\"],[[24,[\"model\"]]]]],false],[0,\"\\n    \"],[1,[28,\"conditional-loading-spinner\",null,[[\"condition\"],[[24,[\"loading\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/user/notifications-index.hbs"
    }
  });

  _exports.default = _default;
});