define("discourse/components/plugin-outlet", ["exports", "discourse/lib/plugin-connectors"], function (_exports, _pluginConnectors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    connectorTagName: "",
    connectors: null,
    init: function init() {
      this._super.apply(this, arguments);

      var name = this.name;

      if (name) {
        var args = (0, _pluginConnectors.buildArgsWithDeprecations)(this.args || {}, this.deprecatedArgs || {});
        this.set("connectors", (0, _pluginConnectors.renderedConnectorsFor)(name, args, this));
      }
    }
  });

  _exports.default = _default;
});