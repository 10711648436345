define("discourse/initializers/webview-background", ["exports", "discourse/lib/utilities"], function (_exports, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Send bg color to webview so iOS status bar matches site theme
  var _default = {
    name: "webview-background",
    after: "inject-objects",
    initialize: function initialize() {
      if ((0, _utilities.isAppWebview)()) {
        window.matchMedia("(prefers-color-scheme: dark)").addListener(this.updateAppBackground);
        this.updateAppBackground();
      }
    },
    updateAppBackground: function updateAppBackground() {
      Ember.run.later(function () {
        var header = document.querySelector(".d-header-wrap .d-header");

        if (header) {
          var styles = window.getComputedStyle(header);
          (0, _utilities.postRNWebviewMessage)("headerBg", styles.backgroundColor);
        }
      }, 500);
    }
  };
  _exports.default = _default;
});