define("discourse/templates/components/reviewable-topic-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7e+B8xvX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"post-topic\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"reviewable\",\"topic\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"topic-status\",null,[[\"topic\",\"showPrivateMessageIcon\"],[[24,[\"reviewable\",\"topic\"]],true]]],false],[0,\"\\n    \"],[7,\"a\",true],[11,\"href\",[24,[\"reviewable\",\"target_url\"]]],[10,\"class\",\"title-text\"],[8],[1,[28,\"html-safe\",[[24,[\"reviewable\",\"topic\",\"fancyTitle\"]]],null],false],[9],[0,\"\\n    \"],[1,[28,\"category-badge\",[[24,[\"reviewable\",\"category\"]]],null],false],[0,\"\\n    \"],[1,[28,\"reviewable-tags\",null,[[\"tags\",\"tagName\"],[[24,[\"reviewable\",\"topic_tags\"]],\"\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"title-text\"],[8],[0,\"\\n      \"],[1,[28,\"i18n\",[\"review.topics.deleted\"],null],false],[0,\"\\n      \"],[4,\"link-to\",null,[[\"route\",\"models\"],[\"topic\",[28,\"array\",[\"-\",[24,[\"reviewable\",\"removed_topic_id\"]]],null]]],{\"statements\":[[1,[28,\"i18n\",[\"review.topics.original\"],null],false]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-topic-link.hbs"
    }
  });

  _exports.default = _default;
});