define("discourse/widgets/post-menu", ["exports", "discourse/widgets/widget", "discourse/lib/bookmark", "virtual-dom", "discourse/lib/show-modal", "discourse/widgets/actions-summary"], function (_exports, _widget, _bookmark, _virtualDom, _showModal, _actionsSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addButton = addButton;
  _exports.resetPostMenuExtraButtons = resetPostMenuExtraButtons;
  _exports.removeButton = removeButton;
  _exports.buildButton = buildButton;
  _exports.default = _exports.apiExtraButtons = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var LIKE_ACTION = 2;
  var VIBRATE_DURATION = 5;
  var _builders = {};
  var _extraButtons = {};
  var apiExtraButtons = {};
  _exports.apiExtraButtons = apiExtraButtons;
  var _buttonsToRemove = {};

  function addButton(name, builder) {
    _extraButtons[name] = builder;
  }

  function resetPostMenuExtraButtons() {
    _buttonsToRemove = {};
    _exports.apiExtraButtons = apiExtraButtons = {};
    _extraButtons = {};
  }

  function removeButton(name, callback) {
    if (callback) {
      _buttonsToRemove[name] = callback;
    } else {
      _buttonsToRemove[name] = function () {
        return true;
      };
    }
  }

  function registerButton(name, builder) {
    _builders[name] = builder;
  }

  function buildButton(name, widget) {
    var attrs = widget.attrs,
        state = widget.state,
        siteSettings = widget.siteSettings,
        settings = widget.settings,
        currentUser = widget.currentUser;
    var shouldAddButton = true;

    if (_buttonsToRemove[name]) {
      shouldAddButton = !_buttonsToRemove[name](attrs, state, siteSettings, settings, currentUser);
    }

    var builder = _builders[name];

    if (shouldAddButton && builder) {
      var button = builder(attrs, state, siteSettings, settings, currentUser);

      if (button && !button.id) {
        button.id = name;
      }

      return button;
    }
  }

  registerButton("read-count", function (attrs) {
    if (attrs.showReadIndicator) {
      var count = attrs.readCount;

      if (count > 0) {
        return {
          action: "toggleWhoRead",
          title: "post.controls.read_indicator",
          className: "button-count read-indicator",
          contents: count,
          iconRight: true,
          addContainer: false
        };
      }
    }
  });
  registerButton("read", function (attrs) {
    var readBySomeone = attrs.readCount > 0;

    if (attrs.showReadIndicator && readBySomeone) {
      return {
        action: "toggleWhoRead",
        title: "post.controls.read_indicator",
        icon: "book-reader",
        before: "read-count",
        addContainer: false
      };
    }
  });

  function likeCount(attrs) {
    var count = attrs.likeCount;

    if (count > 0) {
      var title = attrs.liked ? count === 1 ? "post.has_likes_title_only_you" : "post.has_likes_title_you" : "post.has_likes_title";
      var icon = attrs.yours ? "d-liked" : "";
      var addContainer = attrs.yours;
      var additionalClass = attrs.yours ? "my-likes" : "regular-likes";

      if (!attrs.showLike) {
        icon = attrs.yours ? "d-liked" : "d-unliked";
        addContainer = true;
      }

      return {
        action: "toggleWhoLiked",
        title: title,
        className: "button-count like-count highlight-action ".concat(additionalClass),
        contents: count,
        icon: icon,
        iconRight: true,
        addContainer: addContainer,
        titleOptions: {
          count: attrs.liked ? count - 1 : count
        }
      };
    }
  }

  registerButton("like-count", likeCount);
  registerButton("like", function (attrs) {
    if (!attrs.showLike) {
      return likeCount(attrs);
    }

    var className = attrs.liked ? "toggle-like has-like fade-out" : "toggle-like like";
    var button = {
      action: "like",
      icon: attrs.liked ? "d-liked" : "d-unliked",
      className: className,
      before: "like-count",
      data: {
        "post-id": attrs.id
      }
    }; // If the user has already liked the post and doesn't have permission
    // to undo that operation, then indicate via the title that they've liked it
    // and disable the button. Otherwise, set the title even if the user
    // is anonymous (meaning they don't currently have permission to like);
    // this is important for accessibility.

    if (attrs.liked && !attrs.canToggleLike) {
      button.title = "post.controls.has_liked";
      button.disabled = true;
    } else {
      button.title = attrs.liked ? "post.controls.undo_like" : "post.controls.like";
    }

    return button;
  });
  registerButton("flag-count", function (attrs) {
    var className = "button-count";

    if (attrs.reviewableScorePendingCount > 0) {
      className += " has-pending";
    }

    return {
      className: className,
      contents: (0, _virtualDom.h)("span", attrs.reviewableScoreCount.toString()),
      url: "/review/".concat(attrs.reviewableId)
    };
  });
  registerButton("flag", function (attrs) {
    if (attrs.reviewableId || attrs.canFlag && !attrs.hidden) {
      var button = {
        action: "showFlags",
        title: "post.controls.flag",
        icon: "flag",
        className: "create-flag"
      };

      if (attrs.reviewableId) {
        button.before = "flag-count";
      }

      return button;
    }
  });
  registerButton("edit", function (attrs) {
    if (attrs.canEdit) {
      return {
        action: "editPost",
        className: "edit",
        title: "post.controls.edit",
        icon: "pencil-alt",
        alwaysShowYours: true
      };
    }
  });
  registerButton("reply-small", function (attrs) {
    if (!attrs.canCreatePost) {
      return;
    }

    var args = {
      action: "replyToPost",
      title: "post.controls.reply",
      icon: "reply",
      className: "reply"
    };
    return args;
  });
  registerButton("wiki-edit", function (attrs) {
    if (attrs.canEdit) {
      var args = {
        action: "editPost",
        className: "edit create",
        title: "post.controls.edit",
        icon: "far-edit",
        alwaysShowYours: true
      };

      if (!attrs.mobileView) {
        args.label = "post.controls.edit_action";
      }

      return args;
    }
  });
  registerButton("replies", function (attrs, state, siteSettings) {
    var replyCount = attrs.replyCount;

    if (!replyCount) {
      return;
    }

    var action = "toggleRepliesBelow",
        icon = state.repliesShown ? "chevron-up" : "chevron-down";

    if (siteSettings.enable_filtered_replies_view) {
      action = "toggleFilteredRepliesView";
      icon = state.filteredRepliesShown ? "chevron-up" : "chevron-down";
    } // Omit replies if the setting `suppress_reply_directly_below` is enabled


    if (replyCount === 1 && attrs.replyDirectlyBelow && siteSettings.suppress_reply_directly_below) {
      return;
    }

    return {
      action: action,
      icon: icon,
      className: "show-replies",
      titleOptions: {
        count: replyCount
      },
      title: siteSettings.enable_filtered_replies_view ? state.filteredRepliesShown ? "post.view_all_posts" : "post.filtered_replies_hint" : "post.has_replies",
      labelOptions: {
        count: replyCount
      },
      label: attrs.mobileView ? "post.has_replies_count" : "post.has_replies",
      iconRight: !siteSettings.enable_filtered_replies_view || attrs.mobileView
    };
  });
  registerButton("share", function (attrs) {
    return {
      action: "share",
      className: "share",
      title: "post.controls.share",
      icon: "d-post-share",
      data: {
        "share-url": attrs.shareUrl,
        "post-number": attrs.post_number
      }
    };
  });
  registerButton("reply", function (attrs, state, siteSettings, postMenuSettings) {
    var args = {
      action: "replyToPost",
      title: "post.controls.reply",
      icon: "reply",
      className: "reply create fade-out"
    };

    if (!attrs.canCreatePost) {
      return;
    }

    if (postMenuSettings.showReplyTitleOnMobile || !attrs.mobileView) {
      args.label = "topic.reply.title";
    }

    return args;
  });
  registerButton("bookmark", function (attrs, _state, _siteSettings, _settings, currentUser) {
    if (!attrs.canBookmark) {
      return;
    }

    var classNames = ["bookmark", "with-reminder"];
    var title = "bookmarks.not_bookmarked";
    var titleOptions = {
      name: ""
    };

    if (attrs.bookmarked) {
      classNames.push("bookmarked");

      if (attrs.bookmarkReminderAt) {
        var formattedReminder = (0, _bookmark.formattedReminderTime)(attrs.bookmarkReminderAt, currentUser.resolvedTimezone(currentUser));
        title = "bookmarks.created_with_reminder";
        titleOptions.date = formattedReminder;
      } else {
        title = "bookmarks.created";
      }

      if (attrs.bookmarkName) {
        titleOptions.name = attrs.bookmarkName;
      }
    }

    return {
      id: attrs.bookmarked ? "unbookmark" : "bookmark",
      action: "toggleBookmark",
      title: title,
      titleOptions: titleOptions,
      className: classNames.join(" "),
      icon: attrs.bookmarkReminderAt ? "discourse-bookmark-clock" : "bookmark"
    };
  });
  registerButton("admin", function (attrs) {
    if (!attrs.canManage && !attrs.canWiki && !attrs.canEditStaffNotes) {
      return;
    }

    return {
      action: "openAdminMenu",
      title: "post.controls.admin",
      className: "show-post-admin-menu",
      icon: "wrench"
    };
  });
  registerButton("delete", function (attrs) {
    if (attrs.canRecoverTopic) {
      return {
        id: "recover_topic",
        action: "recoverPost",
        title: "topic.actions.recover",
        icon: "undo",
        className: "recover"
      };
    } else if (attrs.canDeleteTopic) {
      return {
        id: "delete_topic",
        action: "deletePost",
        title: "post.controls.delete_topic",
        icon: "far-trash-alt",
        className: "delete"
      };
    } else if (attrs.canRecover) {
      return {
        id: "recover",
        action: "recoverPost",
        title: "post.controls.undelete",
        icon: "undo",
        className: "recover"
      };
    } else if (attrs.canDelete) {
      return {
        id: "delete",
        action: "deletePost",
        title: "post.controls.delete",
        icon: "far-trash-alt",
        className: "delete"
      };
    } else if (attrs.showFlagDelete) {
      return {
        id: "delete_topic",
        action: "showDeleteTopicModal",
        title: "post.controls.delete_topic_disallowed",
        icon: "far-trash-alt",
        className: "delete"
      };
    }
  });

  function replaceButton(buttons, find, replace) {
    var idx = buttons.indexOf(find);

    if (idx !== -1) {
      buttons[idx] = replace;
    }
  }

  var _default = (0, _widget.createWidget)("post-menu", {
    tagName: "section.post-menu-area.clearfix",
    settings: {
      collapseButtons: true,
      buttonType: "flat-button",
      showReplyTitleOnMobile: false
    },
    defaultState: function defaultState() {
      return {
        collapsed: true,
        likedUsers: [],
        readers: [],
        adminVisible: false
      };
    },
    buildKey: function buildKey(attrs) {
      return "post-menu-".concat(attrs.id);
    },
    attachButton: function attachButton(name) {
      var buttonAtts = buildButton(name, this);

      if (buttonAtts) {
        var button = this.attach(this.settings.buttonType, buttonAtts);

        if (buttonAtts.before) {
          var before = this.attachButton(buttonAtts.before);
          return (0, _virtualDom.h)("div.double-button", [before, button]);
        } else if (buttonAtts.addContainer) {
          return (0, _virtualDom.h)("div.double-button", [button]);
        }

        return button;
      }
    },
    menuItems: function menuItems() {
      return this.siteSettings.post_menu.split("|").filter(Boolean);
    },
    html: function html(attrs, state) {
      var _this = this;

      var currentUser = this.currentUser,
          keyValueStore = this.keyValueStore,
          siteSettings = this.siteSettings;
      var hiddenSetting = siteSettings.post_menu_hidden_items || "";
      var hiddenButtons = hiddenSetting.split("|").filter(function (s) {
        return !attrs.bookmarked || s !== "bookmark";
      });

      if (currentUser && keyValueStore) {
        var likedPostId = keyValueStore.getInt("likedPostId");

        if (likedPostId === attrs.id) {
          keyValueStore.remove("likedPostId");
          Ember.run.next(function () {
            return _this.sendWidgetAction("toggleLike");
          });
        }
      }

      var allButtons = [];
      var visibleButtons = []; // filter menu items based on site settings

      var orderedButtons = this.menuItems(); // If the post is a wiki, make Edit more prominent

      if (attrs.wiki && attrs.canEdit) {
        replaceButton(orderedButtons, "edit", "reply-small");
        replaceButton(orderedButtons, "reply", "wiki-edit");
      }

      orderedButtons.forEach(function (i) {
        var button = _this.attachButton(i, attrs);

        if (button) {
          allButtons.push(button);

          if (attrs.yours && button.attrs && button.attrs.alwaysShowYours || attrs.reviewableId && i === "flag" || hiddenButtons.indexOf(i) === -1) {
            visibleButtons.push(button);
          }
        }
      });

      if (!this.settings.collapseButtons) {
        visibleButtons = allButtons;
      } // Only show ellipsis if there is more than one button hidden
      // if there are no more buttons, we are not collapsed


      if (!state.collapsed || allButtons.length <= visibleButtons.length + 1) {
        visibleButtons = allButtons;

        if (state.collapsed) {
          state.collapsed = false;
        }
      } else {
        var showMore = this.attach("flat-button", {
          action: "showMoreActions",
          title: "show_more",
          className: "show-more-actions",
          icon: "ellipsis-h"
        });
        visibleButtons.splice(visibleButtons.length - 1, 0, showMore);
      }

      Object.values(_extraButtons).forEach(function (builder) {
        var shouldAddButton = true;

        if (_buttonsToRemove[name]) {
          shouldAddButton = !_buttonsToRemove[name](attrs, _this.state, _this.siteSettings, _this.settings, _this.currentUser);
        }

        if (shouldAddButton && builder) {
          var buttonAtts = builder(attrs, _this.state, _this.siteSettings, _this.settings, _this.currentUser);

          if (buttonAtts) {
            var position = buttonAtts.position,
                beforeButton = buttonAtts.beforeButton,
                afterButton = buttonAtts.afterButton;
            delete buttonAtts.position;

            var button = _this.attach(_this.settings.buttonType, buttonAtts);

            var content = [];

            if (beforeButton) {
              content.push(beforeButton(_virtualDom.h));
            }

            content.push(button);

            if (afterButton) {
              content.push(afterButton(_virtualDom.h));
            }

            button = (0, _virtualDom.h)("span.extra-buttons", content);

            if (button) {
              switch (position) {
                case "first":
                  visibleButtons.unshift(button);
                  break;

                case "second":
                  visibleButtons.splice(1, 0, button);
                  break;

                case "second-last-hidden":
                  if (!state.collapsed) {
                    visibleButtons.splice(visibleButtons.length - 2, 0, button);
                  }

                  break;

                default:
                  visibleButtons.push(button);
                  break;
              }
            }
          }
        }
      });
      var postControls = [];
      var repliesButton = this.attachButton("replies", attrs);

      if (repliesButton) {
        postControls.push(repliesButton);
      }

      var extraPostControls = (0, _widget.applyDecorators)(this, "extra-post-controls", attrs, state);
      postControls.push(extraPostControls);
      var extraControls = (0, _widget.applyDecorators)(this, "extra-controls", attrs, state);
      var beforeExtraControls = (0, _widget.applyDecorators)(this, "before-extra-controls", attrs, state);
      var controlsButtons = [].concat(_toConsumableArray(beforeExtraControls), _toConsumableArray(visibleButtons), _toConsumableArray(extraControls));
      postControls.push((0, _virtualDom.h)("div.actions", controlsButtons));

      if (state.adminVisible) {
        postControls.push(this.attach("post-admin-menu", attrs));
      }

      var contents = [(0, _virtualDom.h)("nav.post-controls" + (this.state.collapsed ? ".collapsed" : ".expanded") + (siteSettings.enable_filtered_replies_view ? ".replies-button-visible" : ""), postControls)];

      if (state.readers.length) {
        var remaining = state.totalReaders - state.readers.length;
        var description = remaining > 0 ? "post.actions.people.read_capped" : "post.actions.people.read";
        var count = remaining > 0 ? remaining : state.totalReaders;
        contents.push(this.attach("small-user-list", {
          users: state.readers,
          addSelf: false,
          listClassName: "who-read",
          description: description,
          count: count
        }));
      }

      if (state.likedUsers.length) {
        var _remaining = state.total - state.likedUsers.length;

        var _description = _remaining > 0 ? "post.actions.people.like_capped" : "post.actions.people.like";

        var _count = _remaining > 0 ? _remaining : state.total;

        contents.push(this.attach("small-user-list", {
          users: state.likedUsers,
          addSelf: attrs.liked && _remaining === 0,
          listClassName: "who-liked",
          description: _description,
          count: _count
        }));
      }

      return contents;
    },
    openAdminMenu: function openAdminMenu() {
      this.state.adminVisible = true;
    },
    closeAdminMenu: function closeAdminMenu() {
      this.state.adminVisible = false;
    },
    showDeleteTopicModal: function showDeleteTopicModal() {
      (0, _showModal.default)("delete-topic-disallowed");
    },
    showMoreActions: function showMoreActions() {
      var _this2 = this;

      this.state.collapsed = false;
      var likesPromise = !this.state.likedUsers.length ? this.getWhoLiked() : Ember.RSVP.Promise.resolve();
      return likesPromise.then(function () {
        if (!_this2.state.readers.length && _this2.attrs.showReadIndicator) {
          return _this2.getWhoRead();
        }
      });
    },
    like: function like() {
      var _this3 = this;

      var attrs = this.attrs,
          currentUser = this.currentUser,
          keyValueStore = this.keyValueStore;

      if (!currentUser) {
        keyValueStore && keyValueStore.set({
          key: "likedPostId",
          value: attrs.id
        });
        return this.sendWidgetAction("showLogin");
      }

      if (this.capabilities.canVibrate) {
        navigator.vibrate(VIBRATE_DURATION);
      }

      if (attrs.liked) {
        return this.sendWidgetAction("toggleLike");
      }

      var heart = document.querySelector(".toggle-like[data-post-id=\"".concat(attrs.id, "\"] .d-icon"));
      heart.closest(".toggle-like").classList.add("has-like");
      heart.classList.add("heart-animation");
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(function () {
          _this3.sendWidgetAction("toggleLike").then(function () {
            return resolve();
          });
        }, 400);
      });
    },
    refreshLikes: function refreshLikes() {
      if (this.state.likedUsers.length) {
        return this.getWhoLiked();
      }
    },
    refreshReaders: function refreshReaders() {
      if (this.state.readers.length) {
        return this.getWhoRead();
      }
    },
    getWhoLiked: function getWhoLiked() {
      var attrs = this.attrs,
          state = this.state;
      return this.store.find("post-action-user", {
        id: attrs.id,
        post_action_type_id: LIKE_ACTION
      }).then(function (users) {
        state.likedUsers = users.map(_actionsSummary.smallUserAtts);
        state.total = users.totalRows;
      });
    },
    getWhoRead: function getWhoRead() {
      var attrs = this.attrs,
          state = this.state;
      return this.store.find("post-reader", {
        id: attrs.id
      }).then(function (users) {
        state.readers = users.map(_actionsSummary.smallUserAtts);
        state.totalReaders = users.totalRows;
      });
    },
    toggleWhoLiked: function toggleWhoLiked() {
      var state = this.state;

      if (state.likedUsers.length) {
        state.likedUsers = [];
      } else {
        return this.getWhoLiked();
      }
    },
    toggleWhoRead: function toggleWhoRead() {
      var state = this.state;

      if (this.state.readers.length) {
        state.readers = [];
      } else {
        return this.getWhoRead();
      }
    }
  });

  _exports.default = _default;
});