define("discourse/components/topic-dismiss-buttons", ["exports", "discourse/lib/show-modal", "discourse/lib/is-element-in-viewport", "discourse-common/utils/decorators", "I18n"], function (_exports, _showModal, _isElementInViewport, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("position"), _dec2 = (0, _decorators.default)("position"), _dec3 = (0, _decorators.default)("position"), _dec4 = (0, _decorators.default)("position", "isOtherDismissUnreadButtonVisible", "isOtherDismissNewButtonVisible"), _dec5 = (0, _decorators.default)("selectedTopics.length"), _dec6 = (0, _decorators.default)("selectedTopics.length"), _dec7 = (0, _decorators.on)("didInsertElement"), _dec8 = Ember._action, (_obj = {
    tagName: "",
    classNames: ["topic-dismiss-buttons"],
    position: null,
    selectedTopics: null,
    model: null,
    containerClass: function containerClass(position) {
      return "dismiss-container-".concat(position);
    },
    dismissReadId: function dismissReadId(position) {
      return "dismiss-topics-".concat(position);
    },
    dismissNewId: function dismissNewId(position) {
      return "dismiss-new-".concat(position);
    },
    showBasedOnPosition: function showBasedOnPosition(position, isOtherDismissUnreadButtonVisible, isOtherDismissNewButtonVisible) {
      if (position !== "top") {
        return true;
      }

      return !(isOtherDismissUnreadButtonVisible || isOtherDismissNewButtonVisible);
    },
    dismissLabel: function dismissLabel(selectedTopicCount) {
      if (selectedTopicCount === 0) {
        return _I18n.default.t("topics.bulk.dismiss_button");
      }

      return _I18n.default.t("topics.bulk.dismiss_button_with_selected", {
        count: selectedTopicCount
      });
    },
    dismissNewLabel: function dismissNewLabel(selectedTopicCount) {
      if (selectedTopicCount === 0) {
        return _I18n.default.t("topics.bulk.dismiss_new");
      }

      return _I18n.default.t("topics.bulk.dismiss_new_with_selected", {
        count: selectedTopicCount
      });
    },
    _determineOtherDismissVisibility: function _determineOtherDismissVisibility() {
      var _this = this;

      Ember.run.later(function () {
        if (_this.position === "top") {
          _this.set("isOtherDismissUnreadButtonVisible", (0, _isElementInViewport.default)(document.getElementById("dismiss-topics-bottom")));

          _this.set("isOtherDismissNewButtonVisible", (0, _isElementInViewport.default)(document.getElementById("dismiss-new-bottom")));
        } else {
          _this.set("isOtherDismissUnreadButtonVisible", true);

          _this.set("isOtherDismissNewButtonVisible", true);
        }
      });
    },
    dismissReadPosts: function dismissReadPosts() {
      var dismissTitle = "topics.bulk.dismiss_read";

      if (this.selectedTopics.length > 0) {
        dismissTitle = "topics.bulk.dismiss_read_with_selected";
      }

      (0, _showModal.default)("dismiss-read", {
        titleTranslated: _I18n.default.t(dismissTitle, {
          count: this.selectedTopics.length
        })
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "containerClass", [_dec], Object.getOwnPropertyDescriptor(_obj, "containerClass"), _obj), _applyDecoratedDescriptor(_obj, "dismissReadId", [_dec2], Object.getOwnPropertyDescriptor(_obj, "dismissReadId"), _obj), _applyDecoratedDescriptor(_obj, "dismissNewId", [_dec3], Object.getOwnPropertyDescriptor(_obj, "dismissNewId"), _obj), _applyDecoratedDescriptor(_obj, "showBasedOnPosition", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showBasedOnPosition"), _obj), _applyDecoratedDescriptor(_obj, "dismissLabel", [_dec5], Object.getOwnPropertyDescriptor(_obj, "dismissLabel"), _obj), _applyDecoratedDescriptor(_obj, "dismissNewLabel", [_dec6], Object.getOwnPropertyDescriptor(_obj, "dismissNewLabel"), _obj), _applyDecoratedDescriptor(_obj, "_determineOtherDismissVisibility", [_dec7], Object.getOwnPropertyDescriptor(_obj, "_determineOtherDismissVisibility"), _obj), _applyDecoratedDescriptor(_obj, "dismissReadPosts", [_dec8], Object.getOwnPropertyDescriptor(_obj, "dismissReadPosts"), _obj)), _obj)));

  _exports.default = _default;
});