define("discourse/models/pending-post", ["exports", "discourse-common/utils/decorators", "discourse/models/rest", "discourse-common/utils/category-macro", "discourse/lib/url", "discourse/lib/text"], function (_exports, _decorators, _rest, _categoryMacro, _url, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var PendingPost = _rest.default.extend((_dec = (0, _decorators.default)("username"), (_obj = {
    expandedExcerpt: null,
    postUrl: Ember.computed.reads("topic_url"),
    truncated: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _text.cookAsync)(this.raw_text).then(function (cooked) {
        _this.set("expandedExcerpt", cooked);
      });
    },
    userUrl: function userUrl(username) {
      return (0, _url.userPath)(username.toLowerCase());
    },
    category: (0, _categoryMacro.default)("category_id")
  }, (_applyDecoratedDescriptor(_obj, "userUrl", [_dec], Object.getOwnPropertyDescriptor(_obj, "userUrl"), _obj)), _obj)));

  var _default = PendingPost;
  _exports.default = _default;
});