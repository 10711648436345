define("discourse/controllers/account-created-index", ["exports", "discourse/lib/user-activation"], function (_exports, _userActivation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      sendActivationEmail: function sendActivationEmail() {
        var _this = this;

        (0, _userActivation.resendActivationEmail)(this.get("accountCreated.username")).then(function () {
          _this.transitionToRoute("account-created.resent");
        });
      },
      editActivationEmail: function editActivationEmail() {
        this.transitionToRoute("account-created.edit-email");
      }
    }
  });

  _exports.default = _default;
});