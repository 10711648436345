define("discourse/templates/components/link-to-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RJkBd+zv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"showInput\"]]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"a\",true],[10,\"href\",\"\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"key\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"i18n\",[[24,[\"key\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"d-icon\",[[24,[\"icon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/link-to-input.hbs"
    }
  });

  _exports.default = _default;
});