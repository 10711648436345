define("discourse/mixins/docking", ["exports", "discourse-common/lib/debounce", "discourse-common/config/environment"], function (_exports, _debounce, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INITIAL_DELAY_MS = (0, _environment.isTesting)() ? 0 : 50;
  var DEBOUNCE_MS = (0, _environment.isTesting)() ? 0 : 5;

  var _default = Ember.Mixin.create({
    queueDockCheck: null,
    _initialTimer: null,
    _queuedTimer: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.queueDockCheck = function () {
        _this._queuedTimer = (0, _debounce.default)(_this, _this.safeDockCheck, DEBOUNCE_MS);
      };
    },
    safeDockCheck: function safeDockCheck() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      this.dockCheck();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      window.addEventListener("scroll", this.queueDockCheck, {
        passive: true
      });
      document.addEventListener("touchmove", this.queueDockCheck, {
        passive: true
      }); // dockCheck might happen too early on full page refresh

      this._initialTimer = Ember.run.later(this, this.safeDockCheck, INITIAL_DELAY_MS);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this._queuedTimer) {
        Ember.run.cancel(this._queuedTimer);
      }

      Ember.run.cancel(this._initialTimer);
      window.removeEventListener("scroll", this.queueDockCheck);
      document.removeEventListener("touchmove", this.queueDockCheck);
    }
  });

  _exports.default = _default;
});