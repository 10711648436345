define("discourse/templates/components/save-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qs5V2aXQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"d-button\",null,[[\"action\",\"disabled\",\"label\",\"class\"],[[24,[\"action\"]],[24,[\"buttonDisabled\"]],[24,[\"savingText\"]],\"btn-primary save-changes\"]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"saved\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"saved\"],[8],[1,[28,\"i18n\",[\"saved\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/save-controls.hbs"
    }
  });

  _exports.default = _default;
});