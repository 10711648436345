define("discourse/lib/svg-sprite-loader", ["exports", "discourse/lib/load-script"], function (_exports, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadSprites = loadSprites;
  var SVG_CONTAINER_ID = "svg-sprites";

  function loadSprites(spritePath, spriteName) {
    var spriteContainer = document.getElementById(SVG_CONTAINER_ID);

    if (!spriteContainer) {
      spriteContainer = document.createElement("div");
      spriteContainer.id = SVG_CONTAINER_ID;
      document.body.appendChild(spriteContainer);
    }

    var sprites = spriteContainer.querySelector(".".concat(spriteName));

    if (!sprites) {
      sprites = document.createElement("div");
      sprites.className = spriteName;
      spriteContainer.appendChild(sprites);
    }

    (0, _loadScript.default)(spritePath).then(function () {
      sprites.innerHTML = window.__svg_sprite; // we got to clean up here... this is one giant string

      delete window.__svg_sprite;
    });
  }
});