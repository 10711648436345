define("discourse/lib/category-hashtags", ["exports", "discourse/lib/utilities"], function (_exports, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.replaceSpan = replaceSpan;
  _exports.categoryHashtagTriggerRule = categoryHashtagTriggerRule;
  _exports.SEPARATOR = void 0;
  var SEPARATOR = ":";
  _exports.SEPARATOR = SEPARATOR;

  function replaceSpan($elem, categorySlug, categoryLink, type) {
    type = type ? " data-type=\"".concat(type, "\"") : "";
    $elem.replaceWith("<a href=\"".concat(categoryLink, "\" class=\"hashtag\"").concat(type, ">#<span>").concat(categorySlug, "</span></a>"));
  }

  function categoryHashtagTriggerRule(textarea, opts) {
    var result = (0, _utilities.caretRowCol)(textarea);
    var row = result.rowNum;
    var col = result.colNum;
    var line = textarea.value.split("\n")[row - 1];

    if (opts && opts.backSpace) {
      col = col - 1;
      line = line.slice(0, line.length - 1); // Don't trigger autocomplete when backspacing into a `#category |` => `#category|`

      if (/^#{1}\w+/.test(line)) {
        return false;
      }
    } // Don't trigger autocomplete when ATX-style headers are used


    if (col < 6 && line.slice(0, col) === "#".repeat(col)) {
      return false;
    }

    if ((0, _utilities.inCodeBlock)(textarea.value, (0, _utilities.caretPosition)(textarea))) {
      return false;
    }

    return true;
  }
});