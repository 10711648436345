define("discourse/components/d-modal", ["exports", "I18n", "discourse-common/utils/decorators"], function (_exports, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("title", "titleAriaElementId"), _dec2 = (0, _decorators.on)("didInsertElement"), _dec3 = (0, _decorators.on)("willDestroyElement"), (_obj = {
    classNameBindings: [":modal", ":d-modal", "modalClass", "modalStyle", "hasPanels"],
    attributeBindings: ["data-keyboard", "aria-modal", "role", "ariaLabelledby:aria-labelledby"],
    submitOnEnter: true,
    dismissable: true,
    title: null,
    titleAriaElementId: null,
    subtitle: null,
    role: "dialog",
    headerClass: null,
    init: function init() {
      this._super.apply(this, arguments); // If we need to render a second modal for any reason, we can't
      // use `elementId`


      if (this.modalStyle !== "inline-modal") {
        this.set("elementId", "discourse-modal");
        this.set("modalStyle", "fixed-modal");
      }
    },
    // We handle ESC ourselves
    "data-keyboard": "false",
    // Inform screenreaders of the modal
    "aria-modal": "true",
    ariaLabelledby: function ariaLabelledby(title, titleAriaElementId) {
      if (titleAriaElementId) {
        return titleAriaElementId;
      }

      if (title) {
        return "discourse-modal-title";
      }

      return;
    },
    setUp: function setUp() {
      this.appEvents.on("modal:body-shown", this, "_modalBodyShown");
      document.documentElement.addEventListener("keydown", this._handleModalEvents);
    },
    cleanUp: function cleanUp() {
      this.appEvents.off("modal:body-shown", this, "_modalBodyShown");
      document.documentElement.removeEventListener("keydown", this._handleModalEvents);
    },
    triggerClickOnEnter: function triggerClickOnEnter(e) {
      if (!this.submitOnEnter) {
        return false;
      } // skip when in a form or a textarea element


      if (e.target.closest("form") || document.activeElement && document.activeElement.nodeName === "TEXTAREA") {
        return false;
      }

      return true;
    },
    mouseDown: function mouseDown(e) {
      if (!this.dismissable) {
        return;
      }

      var $target = $(e.target);

      if ($target.hasClass("modal-middle-container") || $target.hasClass("modal-outer-container")) {
        // Send modal close (which bubbles to ApplicationRoute) if clicked outside.
        // We do this because some CSS of ours seems to cover the backdrop and makes
        // it unclickable.
        return this.attrs.closeModal && this.attrs.closeModal("initiatedByClickOut");
      }
    },
    _modalBodyShown: function _modalBodyShown(data) {
      var _this = this;

      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      if (data.fixed) {
        this.element.classList.remove("hidden");
      }

      if (data.title) {
        this.set("title", _I18n.default.t(data.title));
      } else if (data.rawTitle) {
        this.set("title", data.rawTitle);
      }

      if (data.subtitle) {
        this.set("subtitle", _I18n.default.t(data.subtitle));
      } else if (data.rawSubtitle) {
        this.set("subtitle", data.rawSubtitle);
      } else {
        // if no subtitle provided, makes sure the previous subtitle
        // of another modal is not used
        this.set("subtitle", null);
      }

      if ("submitOnEnter" in data) {
        this.set("submitOnEnter", data.submitOnEnter);
      }

      if ("dismissable" in data) {
        this.set("dismissable", data.dismissable);
      } else {
        this.set("dismissable", true);
      }

      this.set("headerClass", data.headerClass || null);
      Ember.run.schedule("afterRender", function () {
        _this._trapTab();
      });
    },
    _handleModalEvents: function _handleModalEvents(event) {
      var _this2 = this;

      if (this.element.classList.contains("hidden")) {
        return;
      }

      if (event.key === "Escape" && this.dismissable) {
        Ember.run.next(function () {
          return _this2.attrs.closeModal("initiatedByESC");
        });
      }

      if (event.key === "Enter" && this.triggerClickOnEnter(event)) {
        var _this$element, _this$element$querySe;

        (_this$element = this.element) === null || _this$element === void 0 ? void 0 : (_this$element$querySe = _this$element.querySelector(".modal-footer .btn-primary")) === null || _this$element$querySe === void 0 ? void 0 : _this$element$querySe.click();
      }

      if (event.key === "Tab") {
        this._trapTab(event);
      }
    },
    _trapTab: function _trapTab(event) {
      var _innerContainer$query2;

      if (this.element.classList.contains("hidden")) {
        return true;
      }

      var innerContainer = this.element.querySelector(".modal-inner-container");

      if (!innerContainer) {
        return;
      }

      var focusableElements = '[autofocus], a, input, select, textarea, summary, [tabindex]:not([tabindex="-1"])';

      if (!event) {
        // on first trap we don't allow to focus modal-close
        // and apply manual focus only if we don't have any autofocus element
        var autofocusedElement = innerContainer.querySelector("[autofocus]");

        if (!autofocusedElement || document.activeElement !== autofocusedElement) {
          var _innerContainer$query;

          (_innerContainer$query = innerContainer.querySelectorAll(focusableElements + ", button:not(.modal-close)")[0]) === null || _innerContainer$query === void 0 ? void 0 : _innerContainer$query.focus();
        }

        return;
      }

      focusableElements = focusableElements + ", button:enabled";
      var firstFocusableElement = (_innerContainer$query2 = innerContainer.querySelectorAll(focusableElements)) === null || _innerContainer$query2 === void 0 ? void 0 : _innerContainer$query2[0];
      var focusableContent = innerContainer.querySelectorAll(focusableElements);
      var lastFocusableElement = focusableContent[focusableContent.length - 1];

      if (event.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement === null || lastFocusableElement === void 0 ? void 0 : lastFocusableElement.focus();
          event.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          var _ref;

          (_ref = innerContainer.querySelector(".modal-close") || firstFocusableElement) === null || _ref === void 0 ? void 0 : _ref.focus();
          event.preventDefault();
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "ariaLabelledby", [_dec], Object.getOwnPropertyDescriptor(_obj, "ariaLabelledby"), _obj), _applyDecoratedDescriptor(_obj, "setUp", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setUp"), _obj), _applyDecoratedDescriptor(_obj, "cleanUp", [_dec3], Object.getOwnPropertyDescriptor(_obj, "cleanUp"), _obj), _applyDecoratedDescriptor(_obj, "_handleModalEvents", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_handleModalEvents"), _obj)), _obj)));

  _exports.default = _default;
});