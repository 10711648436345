define("discourse/templates/components/expand-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JSn9vM0V",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"item\",\"truncated\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"expanded\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[10,\"href\",\"\"],[10,\"class\",\"collapse-item\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toggleItem\"],null]],[11,\"title\",[28,\"i18n\",[\"post.expand_collapse\"],null]],[8],[0,\"\\n      \"],[1,[28,\"d-icon\",[\"chevron-up\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\",true],[10,\"href\",\"\"],[10,\"class\",\"expand-item\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toggleItem\"],null]],[11,\"title\",[28,\"i18n\",[\"post.expand_collapse\"],null]],[8],[0,\"\\n      \"],[1,[28,\"d-icon\",[\"chevron-down\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/expand-post.hbs"
    }
  });

  _exports.default = _default;
});