define("discourse/routes/topic-from-params", ["exports", "discourse/routes/discourse", "discourse/lib/url", "discourse/models/draft", "discourse-common/config/environment"], function (_exports, _discourse, _url, _draft, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // This route is used for retrieving a topic based on params
  var _default = _discourse.default.extend((_dec = Ember._action, (_obj = {
    // Avoid default model hook
    model: function model(params) {
      params = params || {};
      params.track_visit = true;
      var topic = this.modelFor("topic");
      var postStream = topic.postStream; // I sincerely hope no topic gets this many posts

      if (params.nearPost === "last") {
        params.nearPost = 999999999;
      }

      params.forceLoad = true;
      return postStream.refresh(params).then(function () {
        return params;
      }).catch(function (e) {
        if (!(0, _environment.isTesting)()) {
          // eslint-disable-next-line no-console
          console.log("Could not view topic", e);
        }

        params._loading_error = true;
        return params;
      });
    },
    afterModel: function afterModel() {
      var topic = this.modelFor("topic");

      if (topic.isPrivateMessage && topic.suggested_topics) {
        this.pmTopicTrackingState.startTracking();
      }
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.controllerFor("topic").unsubscribe();
    },
    setupController: function setupController(controller, params, _ref) {
      var _this = this;

      var _discourse_anchor = _ref._discourse_anchor;

      // Don't do anything else if we couldn't load
      // TODO: Tests require this but it seems bad
      if (params._loading_error) {
        return;
      }

      var topicController = this.controllerFor("topic");
      var composerController = this.controllerFor("composer");
      var topic = this.modelFor("topic");
      var postStream = topic.postStream; // TODO we are seeing errors where closest post is null and this is exploding
      // we need better handling and logging for this condition.
      // there are no closestPost for hidden topics

      if (topic.view_hidden) {
        return;
      } // The post we requested might not exist. Let's find the closest post


      var closestPost = postStream.closestPostForPostNumber(params.nearPost || 1);
      var closest = closestPost.post_number;
      topicController.setProperties({
        "model.currentPost": closest,
        enteredIndex: topic.postStream.progressIndexOfPost(closestPost),
        enteredAt: Date.now().toString(),
        userLastReadPostNumber: topic.last_read_post_number,
        highestPostNumber: topic.highest_post_number
      });
      this.appEvents.trigger("page:topic-loaded", topic);
      topicController.subscribe(); // Highlight our post after the next render

      Ember.run.schedule("afterRender", function () {
        return _this.appEvents.trigger("post:highlight", closest);
      });
      var opts = {};

      if (document.location.hash) {
        opts.anchor = document.location.hash.substr(1);
      } else if (_discourse_anchor) {
        opts.anchor = _discourse_anchor;
      }

      _url.default.jumpToPost(closest, opts); // completely clear out all the bookmark related attributes
      // because they are not in the response if bookmarked == false


      if (closestPost && !closestPost.bookmarked) {
        closestPost.clearBookmark();
      }

      if (!Ember.isEmpty(topic.draft)) {
        composerController.open({
          draft: _draft.default.getLocal(topic.draft_key, topic.draft),
          draftKey: topic.draft_key,
          draftSequence: topic.draft_sequence,
          ignoreIfChanged: true,
          topic: topic
        });
      }
    },
    willTransition: function willTransition() {
      this.controllerFor("topic").set("previousURL", document.location.pathname); // NOTE: omitting this return can break the back button when transitioning quickly between
      // topics and the latest page.

      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, "willTransition", [_dec], Object.getOwnPropertyDescriptor(_obj, "willTransition"), _obj)), _obj)));

  _exports.default = _default;
});