define("discourse/components/composer-action-title", ["exports", "discourse/models/composer", "I18n", "discourse-common/utils/decorators", "discourse-common/lib/icon-library"], function (_exports, _composer, _I18n, _decorators, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _TITLES, _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var TITLES = (_TITLES = {}, _defineProperty(_TITLES, _composer.PRIVATE_MESSAGE, "topic.private_message"), _defineProperty(_TITLES, _composer.CREATE_TOPIC, "topic.create_long"), _defineProperty(_TITLES, _composer.CREATE_SHARED_DRAFT, "composer.create_shared_draft"), _defineProperty(_TITLES, _composer.EDIT_SHARED_DRAFT, "composer.edit_shared_draft"), _TITLES);

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("options", "action", "model.tags", "model.category"), (_obj = {
    classNames: ["composer-action-title"],
    options: Ember.computed.alias("model.replyOptions"),
    action: Ember.computed.alias("model.action"),
    actionTitle: function actionTitle(opts, action) {
      var result = this.model.customizationFor("actionTitle");

      if (result) {
        return result;
      }

      if (TITLES[action]) {
        return _I18n.default.t(TITLES[action]);
      }

      switch (action) {
        case _composer.REPLY:
          if (opts.userAvatar && opts.userLink) {
            return this._formatReplyToUserPost(opts.userAvatar, opts.userLink);
          } else if (opts.topicLink) {
            return this._formatReplyToTopic(opts.topicLink);
          }

        case _composer.EDIT:
          if (opts.userAvatar && opts.userLink && opts.postLink) {
            return this._formatEditUserPost(opts.userAvatar, opts.userLink, opts.postLink, opts.originalUser);
          }

      }
    },
    _formatEditUserPost: function _formatEditUserPost(userAvatar, userLink, postLink, originalUser) {
      var editTitle = "\n      <a class=\"post-link\" href=\"".concat(postLink.href, "\">").concat(postLink.anchor, "</a>\n      ").concat(userAvatar, "\n      <span class=\"username\">").concat(userLink.anchor, "</span>\n    ");

      if (originalUser) {
        editTitle += "\n        ".concat((0, _iconLibrary.iconHTML)("share", {
          class: "reply-to-glyph"
        }), "\n        ").concat(originalUser.avatar, "\n        <span class=\"original-username\">").concat(originalUser.username, "</span>\n      ");
      }

      return editTitle.htmlSafe();
    },
    _formatReplyToTopic: function _formatReplyToTopic(link) {
      return "<a class=\"topic-link\" href=\"".concat(link.href, "\" data-topic-id=\"").concat(this.get("model.topic.id"), "\">").concat(link.anchor, "</a>").htmlSafe();
    },
    _formatReplyToUserPost: function _formatReplyToUserPost(avatar, link) {
      var htmlLink = "<a class=\"user-link\" href=\"".concat(link.href, "\">").concat(link.anchor, "</a>");
      return "".concat(avatar).concat(htmlLink).htmlSafe();
    }
  }, (_applyDecoratedDescriptor(_obj, "actionTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "actionTitle"), _obj)), _obj)));

  _exports.default = _default;
});