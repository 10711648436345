define("discourse/lib/topic-list-tracker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setTopicList = setTopicList;
  _exports.nextTopicUrl = nextTopicUrl;
  _exports.previousTopicUrl = previousTopicUrl;
  _exports.setHighestReadCache = setHighestReadCache;
  _exports.getHighestReadCache = getHighestReadCache;
  _exports.resetHighestReadCache = resetHighestReadCache;
  _exports.setTopicId = setTopicId;
  var model, currentTopicId;
  var lastTopicId, lastHighestRead;

  function setTopicList(incomingModel) {
    var _model, _model$topics;

    model = incomingModel;
    (_model = model) === null || _model === void 0 ? void 0 : (_model$topics = _model.topics) === null || _model$topics === void 0 ? void 0 : _model$topics.forEach(function (topic) {
      var highestRead = getHighestReadCache(topic.id);

      if (highestRead && highestRead >= topic.last_read_post_number) {
        var count = Math.max(topic.highest_post_number - highestRead, 0);
        topic.setProperties({
          unread_posts: count,
          new_posts: count
        });
        resetHighestReadCache();
      }
    });
    currentTopicId = null;
  }

  function nextTopicUrl() {
    return urlAt(1);
  }

  function previousTopicUrl() {
    return urlAt(-1);
  }

  function setHighestReadCache(topicId, postNumber) {
    lastTopicId = topicId;
    lastHighestRead = postNumber;
  }

  function getHighestReadCache(topicId) {
    if (topicId === lastTopicId) {
      return lastHighestRead;
    }
  }

  function resetHighestReadCache() {
    lastTopicId = undefined;
    lastHighestRead = undefined;
  }

  function urlAt(delta) {
    if (!model || !model.topics) {
      return Ember.RSVP.Promise.resolve(null);
    }

    var index = currentIndex();

    if (index === -1) {
      index = 0;
    } else {
      index += delta;
    }

    var topic = model.topics[index];

    if (!topic && index > 0 && model.more_topics_url && model.loadMore) {
      return model.loadMore().then(function () {
        return urlAt(delta);
      });
    }

    if (topic) {
      currentTopicId = topic.id;
      return Ember.RSVP.Promise.resolve(topic.lastUnreadUrl);
    }

    return Ember.RSVP.Promise.resolve(null);
  }

  function setTopicId(topicId) {
    currentTopicId = topicId;
  }

  function currentIndex() {
    if (currentTopicId && model && model.topics) {
      var idx = model.topics.findIndex(function (t) {
        return t.id === currentTopicId;
      });

      if (idx > -1) {
        return idx;
      }
    }

    return -1;
  }
});