define("discourse/components/composer-save-button", ["exports", "discourse/components/d-button", "I18n", "discourse/lib/utilities"], function (_exports, _dButton, _I18n, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dButton.default.extend({
    classNameBindings: [":btn-primary", ":create", "disableSubmit:disabled"],
    translatedTitle: _I18n.default.t("composer.title", {
      modifier: (0, _utilities.translateModKey)("Meta+")
    })
  });

  _exports.default = _default;
});