define("discourse/templates/user/posts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dxw7vyG8",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"user-stream\",null,[[\"stream\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/user/posts.hbs"
    }
  });

  _exports.default = _default;
});