define("discourse/lib/quote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildQuote = buildQuote;
  _exports.QUOTE_REGEXP = void 0;
  var QUOTE_REGEXP = /\[quote=([^\]]*)\]((?:[\s\S](?!\[quote=[^\]]*\]))*?)\[\/quote\]/im; // Build the BBCode quote around the selected text

  _exports.QUOTE_REGEXP = QUOTE_REGEXP;

  function buildQuote(post, contents) {
    var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    if (!post || !contents) {
      return "";
    }

    var params = [opts.username || post.username, "post:".concat(opts.post || post.post_number), "topic:".concat(opts.topic || post.topic_id)];

    if (opts.full) {
      params.push("full:true");
    }

    return "[quote=\"".concat(params.join(", "), "\"]\n").concat(contents.trim(), "\n[/quote]\n\n");
  }
});