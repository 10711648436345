define("discourse/templates/components/ignored-user-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "biwmxroz",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ignored-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"ignored-user-list-item\",null,[[\"item\",\"onRemoveIgnoredUser\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"removeIgnoredUser\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[1,[28,\"i18n\",[\"user.user_notifications.ignore_no_users\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"instructions\"],[8],[1,[28,\"i18n\",[\"user.ignored_users_instructions\"],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[8],[1,[28,\"d-button\",null,[[\"action\",\"class\",\"icon\",\"label\"],[[28,\"action\",[[23,0,[]],\"newIgnoredUser\"],null],\"btn-default\",\"plus\",\"user.user_notifications.add_ignored_user\"]]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/ignored-user-list.hbs"
    }
  });

  _exports.default = _default;
});