define("discourse/controllers/keyboard-shortcuts-help", ["exports", "I18n", "discourse/lib/utilities", "discourse/mixins/modal-functionality"], function (_exports, _I18n, _utilities, _modalFunctionality) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var KEY = "keyboard_shortcuts_help";

  var SHIFT = _I18n.default.t("shortcut_modifier_key.shift");

  var ALT = (0, _utilities.translateModKey)("Alt");

  var CTRL = _I18n.default.t("shortcut_modifier_key.ctrl");

  var ENTER = _I18n.default.t("shortcut_modifier_key.enter");

  var COMMA = _I18n.default.t("".concat(KEY, ".shortcut_key_delimiter_comma"));

  var PLUS = _I18n.default.t("".concat(KEY, ".shortcut_key_delimiter_plus"));

  function buildHTML(keys1, keys2, keysDelimiter, shortcutsDelimiter) {
    var allKeys = [keys1, keys2].reject(function (keys) {
      return keys.length === 0;
    }).map(function (keys) {
      return keys.map(function (k) {
        return "<kbd>".concat(k, "</kbd>");
      }).join(keysDelimiter);
    }).map(function (keys) {
      return shortcutsDelimiter !== "space" ? wrapInSpan(keys) : keys;
    });

    var _allKeys = _slicedToArray(allKeys, 2),
        shortcut1 = _allKeys[0],
        shortcut2 = _allKeys[1];

    if (allKeys.length === 1) {
      return shortcut1;
    } else if (shortcutsDelimiter === "or") {
      return _I18n.default.t("".concat(KEY, ".shortcut_delimiter_or"), {
        shortcut1: shortcut1,
        shortcut2: shortcut2
      });
    } else if (shortcutsDelimiter === "slash") {
      return _I18n.default.t("".concat(KEY, ".shortcut_delimiter_slash"), {
        shortcut1: shortcut1,
        shortcut2: shortcut2
      });
    } else if (shortcutsDelimiter === "space") {
      return wrapInSpan(_I18n.default.t("".concat(KEY, ".shortcut_delimiter_space"), {
        shortcut1: shortcut1,
        shortcut2: shortcut2
      }));
    }
  }

  function wrapInSpan(shortcut) {
    return "<span dir=\"ltr\">".concat(shortcut, "</span>");
  }

  function buildShortcut(key, _ref) {
    var _ref$keys = _ref.keys1,
        keys1 = _ref$keys === void 0 ? [] : _ref$keys,
        _ref$keys2 = _ref.keys2,
        keys2 = _ref$keys2 === void 0 ? [] : _ref$keys2,
        _ref$keysDelimiter = _ref.keysDelimiter,
        keysDelimiter = _ref$keysDelimiter === void 0 ? COMMA : _ref$keysDelimiter,
        _ref$shortcutsDelimit = _ref.shortcutsDelimiter,
        shortcutsDelimiter = _ref$shortcutsDelimit === void 0 ? "or" : _ref$shortcutsDelimit;
    var context = {
      shortcut: buildHTML(keys1, keys2, keysDelimiter, shortcutsDelimiter)
    };
    return _I18n.default.t("".concat(KEY, ".").concat(key), context);
  }

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    onShow: function onShow() {
      this.set("modal.modalClass", "keyboard-shortcuts-modal");

      this._defineShortcuts();
    },
    onClose: function onClose() {
      this.set("shortcuts", null);
    },
    _defineShortcuts: function _defineShortcuts() {
      this.set("shortcuts", {
        jump_to: {
          home: buildShortcut("jump_to.home", {
            keys1: ["g", "h"]
          }),
          latest: buildShortcut("jump_to.latest", {
            keys1: ["g", "l"]
          }),
          new: buildShortcut("jump_to.new", {
            keys1: ["g", "n"]
          }),
          unread: buildShortcut("jump_to.unread", {
            keys1: ["g", "u"]
          }),
          categories: buildShortcut("jump_to.categories", {
            keys1: ["g", "c"]
          }),
          top: buildShortcut("jump_to.top", {
            keys1: ["g", "t"]
          }),
          bookmarks: buildShortcut("jump_to.bookmarks", {
            keys1: ["g", "b"]
          }),
          profile: buildShortcut("jump_to.profile", {
            keys1: ["g", "p"]
          }),
          messages: buildShortcut("jump_to.messages", {
            keys1: ["g", "m"]
          }),
          drafts: buildShortcut("jump_to.drafts", {
            keys1: ["g", "d"]
          }),
          next: buildShortcut("jump_to.next", {
            keys1: ["g", "j"]
          }),
          previous: buildShortcut("jump_to.previous", {
            keys1: ["g", "k"]
          })
        },
        navigation: {
          back: buildShortcut("navigation.back", {
            keys1: ["u"]
          }),
          jump: buildShortcut("navigation.jump", {
            keys1: ["#"]
          }),
          up_down: buildShortcut("navigation.up_down", {
            keys1: ["k"],
            keys2: ["j"],
            shortcutsDelimiter: "slash"
          }),
          open: buildShortcut("navigation.open", {
            keys1: ["o"],
            keys2: [ENTER]
          }),
          next_prev: buildShortcut("navigation.next_prev", {
            keys1: [SHIFT, "j"],
            keys2: [SHIFT, "k"],
            keysDelimiter: PLUS,
            shortcutsDelimiter: "slash"
          }),
          go_to_unread_post: buildShortcut("navigation.go_to_unread_post", {
            keys1: [SHIFT, "l"],
            keysDelimiter: PLUS
          })
        },
        application: {
          hamburger_menu: buildShortcut("application.hamburger_menu", {
            keys1: ["="]
          }),
          user_profile_menu: buildShortcut("application.user_profile_menu", {
            keys1: ["p"]
          }),
          create: buildShortcut("application.create", {
            keys1: ["c"]
          }),
          show_incoming_updated_topics: buildShortcut("application.show_incoming_updated_topics", {
            keys1: ["."]
          }),
          search: buildShortcut("application.search", {
            keys1: ["/"],
            keys2: [CTRL, ALT, "f"],
            keysDelimiter: PLUS
          }),
          help: buildShortcut("application.help", {
            keys1: ["?"]
          }),
          dismiss_new: buildShortcut("application.dismiss_new", {
            keys1: ["x", "r"]
          }),
          dismiss_topics: buildShortcut("application.dismiss_topics", {
            keys1: ["x", "t"]
          }),
          log_out: buildShortcut("application.log_out", {
            keys1: [SHIFT, "z"],
            keys2: [SHIFT, "z"],
            keysDelimiter: PLUS,
            shortcutsDelimiter: "space"
          })
        },
        composing: {
          return: buildShortcut("composing.return", {
            keys1: [SHIFT, "c"],
            keysDelimiter: PLUS
          }),
          fullscreen: buildShortcut("composing.fullscreen", {
            keys1: [SHIFT, "F11"],
            keysDelimiter: PLUS
          })
        },
        bookmarks: {
          enter: buildShortcut("bookmarks.enter", {
            keys1: [ENTER]
          }),
          later_today: buildShortcut("bookmarks.later_today", {
            keys1: ["l", "t"],
            shortcutsDelimiter: "space"
          }),
          later_this_week: buildShortcut("bookmarks.later_this_week", {
            keys1: ["l", "w"],
            shortcutsDelimiter: "space"
          }),
          tomorrow: buildShortcut("bookmarks.tomorrow", {
            keys1: ["n", "d"],
            shortcutsDelimiter: "space"
          }),
          next_business_week: buildShortcut("bookmarks.next_business_week", {
            keys1: ["n", "b", "w"],
            shortcutsDelimiter: "space"
          }),
          next_business_day: buildShortcut("bookmarks.next_business_day", {
            keys1: ["n", "b", "d"],
            shortcutsDelimiter: "space"
          }),
          custom: buildShortcut("bookmarks.custom", {
            keys1: ["c", "r"],
            shortcutsDelimiter: "space"
          }),
          none: buildShortcut("bookmarks.none", {
            keys1: ["n", "r"],
            shortcutsDelimiter: "space"
          }),
          delete: buildShortcut("bookmarks.delete", {
            keys1: ["d", "d"],
            shortcutsDelimiter: "space"
          })
        },
        actions: {
          bookmark_topic: buildShortcut("actions.bookmark_topic", {
            keys1: ["f"]
          }),
          reply_as_new_topic: buildShortcut("actions.reply_as_new_topic", {
            keys1: ["t"]
          }),
          reply_topic: buildShortcut("actions.reply_topic", {
            keys1: [SHIFT, "r"],
            keysDelimiter: PLUS
          }),
          reply_post: buildShortcut("actions.reply_post", {
            keys1: ["r"]
          }),
          quote_post: buildShortcut("actions.quote_post", {
            keys1: ["q"]
          }),
          pin_unpin_topic: buildShortcut("actions.pin_unpin_topic", {
            keys1: [SHIFT, "p"],
            keysDelimiter: PLUS
          }),
          share_topic: buildShortcut("actions.share_topic", {
            keys1: [SHIFT, "s"],
            keysDelimiter: PLUS
          }),
          share_post: buildShortcut("actions.share_post", {
            keys1: ["s"]
          }),
          like: buildShortcut("actions.like", {
            keys1: ["l"]
          }),
          flag: buildShortcut("actions.flag", {
            keys1: ["!"]
          }),
          bookmark: buildShortcut("actions.bookmark", {
            keys1: ["b"]
          }),
          edit: buildShortcut("actions.edit", {
            keys1: ["e"]
          }),
          delete: buildShortcut("actions.delete", {
            keys1: ["d"]
          }),
          mark_muted: buildShortcut("actions.mark_muted", {
            keys1: ["m", "m"]
          }),
          mark_regular: buildShortcut("actions.mark_regular", {
            keys1: ["m", "r"]
          }),
          mark_tracking: buildShortcut("actions.mark_tracking", {
            keys1: ["m", "t"]
          }),
          mark_watching: buildShortcut("actions.mark_watching", {
            keys1: ["m", "w"]
          }),
          print: buildShortcut("actions.print", {
            keys1: [(0, _utilities.translateModKey)("Meta"), "p"],
            keysDelimiter: PLUS
          }),
          defer: buildShortcut("actions.defer", {
            keys1: [SHIFT, "u"],
            keysDelimiter: PLUS
          }),
          topic_admin_actions: buildShortcut("actions.topic_admin_actions", {
            keys1: [SHIFT, "a"],
            keysDelimiter: PLUS
          })
        },
        search_menu: {
          prev_next: buildShortcut("search_menu.prev_next", {
            keys1: ["&uarr;"],
            keys2: ["&darr;"],
            shortcutsDelimiter: "slash"
          }),
          insert_url: buildShortcut("search_menu.insert_url", {
            keys1: ["a"]
          }),
          full_page_search: buildShortcut("search_menu.full_page_search", {
            keys1: [(0, _utilities.translateModKey)("Meta"), "Enter"],
            keysDelimiter: PLUS
          })
        }
      });
    }
  });

  _exports.default = _default;
});