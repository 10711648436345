define("discourse/components/reviewable-queued-post", ["exports", "discourse/lib/show-modal"], function (_exports, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      showRawEmail: function showRawEmail() {
        (0, _showModal.default)("raw-email").set("rawEmail", this.reviewable.payload.raw_email);
      }
    }
  });

  _exports.default = _default;
});