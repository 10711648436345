define("discourse/components/bulk-select-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: Remove in December 2021
  var _default = Ember.Component.extend({});

  _exports.default = _default;
});