define("discourse/components/topic-post-badges", ["exports", "I18n"], function (_exports, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    classNameBindings: [":topic-post-badges"],
    rerenderTriggers: ["url", "unread", "newPosts", "unreadPosts", "unseen"],
    newDotText: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set("newDotText", this.currentUser && this.currentUser.trust_level > 0 ? " " : _I18n.default.t("filters.new.lower_title"));
    },
    displayUnreadPosts: Ember.computed.or("newPosts", "unreadPosts")
  });

  _exports.default = _default;
});