define("discourse/templates/tag-groups-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A42JgtGd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"tag-group-content\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[8],[1,[28,\"i18n\",[\"tagging.groups.new_title\"],null],false],[9],[0,\"\\n  \"],[1,[28,\"tag-groups-form\",null,[[\"model\",\"onSave\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"onSave\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/tag-groups-new.hbs"
    }
  });

  _exports.default = _default;
});