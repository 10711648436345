define("discourse/components/date-time-input-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    classNames: ["d-date-time-input-range"],
    from: null,
    to: null,
    onChangeTo: null,
    onChangeFrom: null,
    toTimeFirst: false,
    showToTime: true,
    showFromTime: true,
    clearable: false,
    onChangeRanges: function onChangeRanges(options, value) {
      if (this.onChange) {
        var state = {
          from: this.from,
          to: this.to
        };
        var diff = {};

        if (options.prop === "from") {
          if (value && value.isAfter(this.to)) {
            diff[options.prop] = value;
            diff["to"] = value.clone().add(1, "hour");
          } else {
            diff[options.prop] = value;
          }
        }

        if (options.prop === "to") {
          if (value && value.isBefore(this.from)) {
            diff[options.prop] = this.from.clone().add(1, "hour");
          } else {
            diff[options.prop] = value;
          }
        }

        var newState = Object.assign({}, state, diff);
        this.onChange(newState);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "onChangeRanges", [_dec], Object.getOwnPropertyDescriptor(_obj, "onChangeRanges"), _obj)), _obj)));

  _exports.default = _default;
});