define("discourse/initializers/post-decorations", ["exports", "I18n", "discourse/lib/highlight-syntax", "discourse/lib/lightbox", "discourse-common/lib/icon-library", "discourse/lib/text-direction", "discourse/lib/lazy-load-images", "discourse/lib/plugin-api", "virtual-dom", "discourse/lib/show-modal"], function (_exports, _I18n, _highlightSyntax, _lightbox, _iconLibrary, _textDirection, _lazyLoadImages, _pluginApi, _virtualDom, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = {
    name: "post-decorations",
    initialize: function initialize(container) {
      (0, _pluginApi.withPluginApi)("0.1", function (api) {
        var siteSettings = container.lookup("site-settings:main");
        var session = container.lookup("session:main");
        api.decorateCookedElement(function (elem) {
          return (0, _highlightSyntax.default)(elem, siteSettings, session);
        }, {
          id: "discourse-syntax-highlighting"
        });
        api.decorateCookedElement(function (elem) {
          return (0, _lightbox.default)(elem, siteSettings);
        }, {
          id: "discourse-lightbox"
        });

        if (siteSettings.support_mixed_text_direction) {
          api.decorateCookedElement(_textDirection.setTextDirections, {
            id: "discourse-text-direction"
          });
        }

        (0, _lazyLoadImages.nativeLazyLoading)(api);
        api.decorateCookedElement(function (elem) {
          elem.querySelectorAll("audio").forEach(function (player) {
            player.addEventListener("play", function () {
              var _elem$closest;

              var postId = parseInt((_elem$closest = elem.closest("article")) === null || _elem$closest === void 0 ? void 0 : _elem$closest.dataset.postId, 10);

              if (postId) {
                api.preventCloak(postId);
              }
            });
          });
        }, {
          id: "discourse-audio"
        });
        var caps = container.lookup("capabilities:main");

        if (caps.isSafari || caps.isIOS) {
          api.decorateCookedElement(function (elem) {
            elem.querySelectorAll("video").forEach(function (video) {
              if (video.poster && video.poster !== "" && !video.autoplay) {
                return;
              }

              var source = video.querySelector("source");

              if (source) {
                // In post-cooked.js, we create the video element in a detached DOM
                // then adopt it into to the real DOM.
                // This confuses safari, and preloading/autoplay do not happen.
                // Calling `.load()` tricks Safari into loading the video element correctly
                source.parentElement.load();
              }
            });
          }, {
            id: "safari-video-poster",
            afterAdopt: true,
            onlyStream: true
          });
        }

        var oneboxTypes = {
          amazon: "discourse-amazon",
          githubactions: "fab-github",
          githubblob: "fab-github",
          githubcommit: "fab-github",
          githubpullrequest: "fab-github",
          githubissue: "fab-github",
          githubfile: "fab-github",
          githubgist: "fab-github",
          twitterstatus: "fab-twitter",
          wikipedia: "fab-wikipedia-w"
        };
        api.decorateCookedElement(function (elem) {
          elem.querySelectorAll(".onebox").forEach(function (onebox) {
            Object.entries(oneboxTypes).forEach(function (_ref) {
              var _ref2 = _slicedToArray(_ref, 2),
                  key = _ref2[0],
                  value = _ref2[1];

              if (onebox.classList.contains(key)) {
                onebox.querySelector(".source").insertAdjacentHTML("afterbegin", (0, _iconLibrary.iconHTML)(value));
              }
            });
          });
        }, {
          id: "onebox-source-icons"
        });
        api.decorateCookedElement(function (element) {
          element.querySelectorAll(".video-container").forEach(function (videoContainer) {
            var video = videoContainer.getElementsByTagName("video")[0];
            video.addEventListener("loadeddata", function () {
              Ember.run.later(function () {
                if (video.videoWidth === 0 || video.videoHeight === 0) {
                  var notice = document.createElement("div");
                  notice.className = "notice";
                  notice.innerHTML = (0, _iconLibrary.iconHTML)("exclamation-triangle") + " " + _I18n.default.t("cannot_render_video");
                  videoContainer.appendChild(notice);
                }
              }, 500);
            });
          });
        }, {
          id: "discourse-video-codecs"
        });

        function _createButton() {
          var openPopupBtn = document.createElement("button");
          openPopupBtn.classList.add("open-popup-link", "btn-default", "btn", "btn-icon-text");
          var expandIcon = (0, _virtualDom.create)((0, _iconLibrary.iconNode)("discourse-expand", {
            class: "expand-table-icon"
          }));
          var openPopupText = document.createTextNode(_I18n.default.t("fullscreen_table.expand_btn"));
          openPopupBtn.append(expandIcon, openPopupText);
          return openPopupBtn;
        }

        function isOverflown(_ref3) {
          var clientWidth = _ref3.clientWidth,
              scrollWidth = _ref3.scrollWidth;
          return scrollWidth > clientWidth;
        }

        function generateModal(event) {
          var table = event.target.parentNode.querySelector("table");
          var tempTable = table.cloneNode(true);
          (0, _showModal.default)("fullscreen-table").set("tableHtml", tempTable);
        }

        function generatePopups(tables) {
          tables.forEach(function (table) {
            if (!isOverflown(table.parentNode)) {
              return;
            }

            var popupBtn = _createButton();

            table.parentNode.classList.add("fullscreen-table-wrapper");
            table.parentNode.insertBefore(popupBtn, table);
            popupBtn.addEventListener("click", generateModal, false);
          });
        }

        api.decorateCookedElement(function (post) {
          Ember.run.schedule("afterRender", function () {
            var tables = post.querySelectorAll("table");
            generatePopups(tables);
          });
        }, {
          onlyStream: true,
          id: "fullscreen-table"
        });
      });
    }
  };
  _exports.default = _default;
});