define("discourse/lib/search", ["exports", "discourse/models/category", "I18n", "discourse/models/post", "discourse/models/topic", "discourse/models/user", "discourse/lib/ajax", "discourse-common/lib/object", "discourse/lib/text", "discourse/lib/utilities", "discourse-common/lib/raw-templates", "discourse-common/lib/get-url", "discourse/lib/category-tag-search", "discourse/lib/url", "discourse/lib/user-search"], function (_exports, _category, _I18n, _post, _topic, _user, _ajax, _object, _text, _utilities, _rawTemplates, _getUrl, _categoryTagSearch, _url, _userSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addSearchResultsCallback = addSearchResultsCallback;
  _exports.translateResults = translateResults;
  _exports.searchForTerm = searchForTerm;
  _exports.searchContextDescription = searchContextDescription;
  _exports.getSearchKey = getSearchKey;
  _exports.isValidSearchTerm = isValidSearchTerm;
  _exports.applySearchAutocomplete = applySearchAutocomplete;
  _exports.updateRecentSearches = updateRecentSearches;
  var translateResultsCallbacks = [];
  var MAX_RECENT_SEARCHES = 5; // should match backend constant with the same name

  function addSearchResultsCallback(callback) {
    translateResultsCallbacks.push(callback);
  }

  function translateResults(results, opts) {
    opts = opts || {};
    results.topics = results.topics || [];
    results.users = results.users || [];
    results.posts = results.posts || [];
    results.categories = results.categories || [];
    results.tags = results.tags || [];
    results.groups = results.groups || [];
    var topicMap = {};
    results.topics = results.topics.map(function (topic) {
      topic = _topic.default.create(topic);
      topicMap[topic.id] = topic;
      return topic;
    });
    results.posts = results.posts.map(function (post) {
      if (post.username) {
        post.userPath = (0, _url.userPath)(post.username.toLowerCase());
      }

      post = _post.default.create(post);
      post.set("topic", topicMap[post.topic_id]);
      post.blurb = (0, _text.emojiUnescape)(post.blurb);
      return post;
    });
    results.users = results.users.map(function (user) {
      return _user.default.create(user);
    });
    results.categories = results.categories.map(function (category) {
      return _category.default.list().findBy("id", category.id || category.model.id);
    }).compact();
    results.groups = results.groups.map(function (group) {
      var name = (0, _utilities.escapeExpression)(group.name);
      var fullName = (0, _utilities.escapeExpression)(group.full_name || group.display_name);
      var flairUrl = Ember.isEmpty(group.flair_url) ? null : (0, _utilities.escapeExpression)(group.flair_url);
      var flairColor = (0, _utilities.escapeExpression)(group.flair_color);
      var flairBgColor = (0, _utilities.escapeExpression)(group.flair_bg_color);
      return {
        id: group.id,
        flairUrl: flairUrl,
        flairColor: flairColor,
        flairBgColor: flairBgColor,
        fullName: fullName,
        name: name,
        url: (0, _getUrl.default)("/g/".concat(name))
      };
    }).compact();
    results.tags = results.tags.map(function (tag) {
      var tagName = (0, _utilities.escapeExpression)(tag.name);
      return Ember.Object.create({
        id: tagName,
        url: (0, _getUrl.default)("/tag/" + tagName)
      });
    }).compact();
    return translateResultsCallbacks.reduce(function (promise, callback) {
      return promise.then(function (r) {
        return callback(r);
      });
    }, Ember.RSVP.Promise.resolve(results)).then(function (results_) {
      translateGroupedSearchResults(results_, opts);
      return Ember.Object.create(results_);
    });
  }

  function translateGroupedSearchResults(results, opts) {
    results.resultTypes = [];
    var groupedSearchResult = results.grouped_search_result;

    if (groupedSearchResult) {
      [["topic", "posts"], ["user", "users"], ["group", "groups"], ["category", "categories"], ["tag", "tags"]].forEach(function (pair) {
        var type = pair[0];
        var name = pair[1];

        if (results[name].length > 0) {
          var componentName = opts.searchContext && type === "topic" ? "post" : type;
          var result = {
            results: results[name],
            componentName: "search-result-".concat(componentName),
            type: type,
            more: groupedSearchResult["more_".concat(name)]
          };

          if (result.more && componentName === "topic" && opts.fullSearchUrl) {
            result.more = false;
            result.moreUrl = opts.fullSearchUrl;
          }

          results.resultTypes.push(result);
        }
      });
    }
  }

  function searchForTerm(term, opts) {
    if (!opts) {
      opts = {};
    } // Only include the data we have


    var data = {
      term: term
    };

    if (opts.typeFilter) {
      data.type_filter = opts.typeFilter;
    }

    if (opts.searchForId) {
      data.search_for_id = true;
    }

    if (opts.restrictToArchetype) {
      data.restrict_to_archetype = opts.restrictToArchetype;
    }

    if (opts.searchContext) {
      data.search_context = {
        type: opts.searchContext.type,
        id: opts.searchContext.id,
        name: opts.searchContext.name
      };
    }

    var ajaxPromise = (0, _ajax.ajax)("/search/query", {
      data: data
    });
    var promise = ajaxPromise.then(function (res) {
      return translateResults(res, opts);
    });
    promise.abort = ajaxPromise.abort;
    return promise;
  }

  function searchContextDescription(type, name) {
    if (type) {
      switch (type) {
        case "topic":
          return _I18n.default.t("search.context.topic");

        case "user":
          return _I18n.default.t("search.context.user", {
            username: name
          });

        case "category":
          return _I18n.default.t("search.context.category", {
            category: name
          });

        case "tag":
          return _I18n.default.t("search.context.tag", {
            tag: name
          });

        case "private_messages":
          return _I18n.default.t("search.context.private_messages");
      }
    }
  }

  function getSearchKey(args) {
    return args.q + "|" + (args.searchContext && args.searchContext.type || "") + "|" + (args.searchContext && args.searchContext.id || "");
  }

  function isValidSearchTerm(searchTerm, siteSettings) {
    if (searchTerm) {
      return searchTerm.trim().length >= siteSettings.min_search_term_length;
    } else {
      return false;
    }
  }

  function applySearchAutocomplete($input, siteSettings) {
    $input.autocomplete((0, _object.deepMerge)({
      template: (0, _rawTemplates.findRawTemplate)("category-tag-autocomplete"),
      key: "#",
      width: "100%",
      treatAsTextarea: true,
      autoSelectFirstSuggestion: false,
      transformComplete: function transformComplete(obj) {
        return obj.text;
      },
      dataSource: function dataSource(term) {
        return (0, _categoryTagSearch.search)(term, siteSettings);
      }
    }));

    if (siteSettings.enable_mentions) {
      $input.autocomplete((0, _object.deepMerge)({
        template: (0, _rawTemplates.findRawTemplate)("user-selector-autocomplete"),
        key: "@",
        width: "100%",
        treatAsTextarea: true,
        autoSelectFirstSuggestion: false,
        transformComplete: function transformComplete(v) {
          return v.username || v.name;
        },
        dataSource: function dataSource(term) {
          return (0, _userSearch.default)({
            term: term,
            includeGroups: true
          });
        }
      }));
    }
  }

  function updateRecentSearches(currentUser, term) {
    var recentSearches = Object.assign(currentUser.recent_searches || []);

    if (recentSearches.includes(term)) {
      recentSearches = recentSearches.without(term);
    } else if (recentSearches.length === MAX_RECENT_SEARCHES) {
      recentSearches.popObject();
    }

    recentSearches.unshiftObject(term);
    currentUser.set("recent_searches", recentSearches);
  }
});