define("discourse/mixins/singleton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Singleton = Ember.Mixin.create({
    current: function current() {
      if (!this._current) {
        this._current = this.createCurrent();
      }

      return this._current;
    },

    /**
      How the singleton instance is created. This can be overridden
      with logic for creating (or even returning null) your instance.
       By default it just calls `create` with an empty object.
    **/
    createCurrent: function createCurrent() {
      return this.create({});
    },
    // Returns OR sets a property on the singleton instance.
    currentProp: function currentProp(property, value) {
      var instance = this.current();

      if (!instance) {
        return;
      }

      if (typeof value !== "undefined") {
        instance.set(property, value);
        return value;
      } else {
        return instance.get(property);
      }
    },
    resetCurrent: function resetCurrent(val) {
      this._current = val;
      return val;
    }
  });
  var _default = Singleton;
  _exports.default = _default;
});