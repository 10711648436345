define("discourse/adapters/post-reply-history", ["exports", "discourse/adapters/rest", "discourse/lib/ajax"], function (_exports, _rest, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    find: function find(store, type, findArgs) {
      var maxReplies = this.siteSettings.max_reply_history;
      return (0, _ajax.ajax)("/posts/".concat(findArgs.postId, "/reply-history?max_replies=").concat(maxReplies)).then(function (replies) {
        return {
          post_reply_histories: replies
        };
      });
    }
  });

  _exports.default = _default;
});