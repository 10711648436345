define("discourse/templates/components/backup-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "28o3F2vO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"wrapper\"],[8],[0,\"\\n  \"],[7,\"textarea\",true],[10,\"id\",\"backupCodes\"],[10,\"class\",\"backup-codes-area\"],[10,\"rows\",\"10\"],[10,\"readonly\",\"\"],[8],[1,[22,\"formattedBackupCodes\"],false],[9],[0,\"\\n\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"class\",\"icon\",\"aria-label\",\"title\"],[[28,\"action\",[[23,0,[]],\"copyToClipboard\"],null],\"backup-codes-copy-btn\",\"copy\",\"user.second_factor_backup.copy_to_clipboard\",\"user.second_factor_backup.copy_to_clipboard\"]]],false],[0,\"\\n\\n  \"],[7,\"a\",true],[11,\"download\",[29,[[22,\"siteTitleSlug\"],\"-backup-codes.txt\"]]],[10,\"class\",\"btn no-text btn-icon backup-codes-download-btn\"],[11,\"aria-label\",[28,\"i18n\",[\"user.second_factor_backup.download_backup_codes\"],null]],[11,\"title\",[28,\"i18n\",[\"user.second_factor_backup.download_backup_codes\"],null]],[10,\"rel\",\"noopener noreferrer\"],[10,\"target\",\"_blank\"],[11,\"href\",[29,[\"data:application/octet-stream;charset=utf-8;base64,\",[22,\"base64BackupCode\"]]]],[8],[0,\"\\n    \"],[1,[28,\"d-icon\",[\"download\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/backup-codes.hbs"
    }
  });

  _exports.default = _default;
});