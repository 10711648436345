define("discourse/templates/components/group-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9l6mGWQK",
    "block": "{\"symbols\":[\"tab\"],\"statements\":[[4,\"mobile-nav\",null,[[\"class\",\"desktopClass\"],[\"group-nav\",\"nav nav-pills\"]],{\"statements\":[[4,\"if\",[[24,[\"site\",\"mobileView\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"groups.index\"]],{\"statements\":[[0,\"        \"],[1,[28,\"i18n\",[\"groups.index.all\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n      \"],[1,[28,\"group-dropdown\",null,[[\"groups\",\"value\"],[[24,[\"group\",\"extras\",\"visible_group_names\"]],[24,[\"group\",\"name\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"each\",[[24,[\"tabs\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"title\",\"class\",\"route\",\"model\"],[[23,1,[\"message\"]],[23,1,[\"name\"]],[23,1,[\"route\"]],[24,[\"group\"]]]],{\"statements\":[[0,\"        \"],[4,\"if\",[[23,1,[\"icon\"]]],null,{\"statements\":[[1,[28,\"d-icon\",[[23,1,[\"icon\"]]],null],false]],\"parameters\":[]},null],[0,\"\\n        \"],[1,[23,1,[\"message\"]],false],[0,\"\\n        \"],[4,\"if\",[[23,1,[\"count\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"class\",\"count\"],[8],[0,\"(\"],[1,[23,1,[\"count\"]],false],[0,\")\"],[9]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"args\",\"connectorTagName\"],[\"group-reports-nav-item\",[28,\"hash\",null,[[\"group\"],[[24,[\"group\"]]]]],\"li\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/group-navigation.hbs"
    }
  });

  _exports.default = _default;
});