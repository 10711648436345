define("discourse/components/topic-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Injections don't occur without a class
  var _default = Ember.Component.extend();

  _exports.default = _default;
});