define("discourse/lib/register-topic-footer-button", ["exports", "I18n"], function (_exports, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerTopicFooterButton = registerTopicFooterButton;
  _exports.getTopicFooterButtons = getTopicFooterButtons;
  _exports.clearTopicFooterButtons = clearTopicFooterButtons;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _topicFooterButtons = {};

  function registerTopicFooterButton(button) {
    if (!button.id) {
      throw new Error("Attempted to register a topic button with no id.");
    }

    if (_topicFooterButtons[button.id]) {
      return;
    }

    var defaultButton = {
      type: "inline-button",
      // id of the button, required
      id: null,
      // icon displayed on the button
      icon: null,
      // local key path for title attribute
      title: null,
      translatedTitle: null,
      // local key path for label
      label: null,
      translatedLabel: null,
      // local key path for aria label
      ariaLabel: null,
      translatedAriaLabel: null,
      // is this button displayed in the mobile dropdown or as an inline button ?
      dropdown: false,
      // css class appended to the button
      classNames: [],
      // discourseComputed properties which should force a button state refresh
      // eg: ["topic.bookmarked", "topic.category_id"]
      dependentKeys: [],
      // should we display this button ?
      displayed: true,
      // is this button disabled ?
      disabled: false,
      // display order, higher comes first
      priority: 0
    };
    var normalizedButton = Object.assign(defaultButton, button);

    if (!normalizedButton.icon && !normalizedButton.title && !normalizedButton.translatedTitle) {
      throw new Error("Attempted to register a topic button: ".concat(button.id, " with no icon or title."));
    }

    _topicFooterButtons[normalizedButton.id] = normalizedButton;
  }

  function getTopicFooterButtons() {
    var _ref, _Ember;

    var dependentKeys = (_ref = []).concat.apply(_ref, _toConsumableArray(Object.values(_topicFooterButtons).map(function (tfb) {
      return tfb.dependentKeys;
    }).filter(function (x) {
      return x;
    })));

    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(dependentKeys).concat([{
      get: function get() {
        var _this = this;

        var _isFunction = function _isFunction(descriptor) {
          return descriptor && typeof descriptor === "function";
        };

        var _compute = function _compute(button, property) {
          var field = button[property];

          if (_isFunction(field)) {
            return field.apply(_this);
          }

          return field;
        };

        return Object.values(_topicFooterButtons).filter(function (button) {
          return _compute(button, "displayed");
        }).map(function (button) {
          var discourseComputedButton = {};
          discourseComputedButton.id = button.id;
          discourseComputedButton.type = button.type;

          var label = _compute(button, "label");

          discourseComputedButton.label = label ? _I18n.default.t(label) : _compute(button, "translatedLabel");

          var ariaLabel = _compute(button, "ariaLabel");

          if (ariaLabel) {
            discourseComputedButton.ariaLabel = _I18n.default.t(ariaLabel);
          } else {
            var translatedAriaLabel = _compute(button, "translatedAriaLabel");

            discourseComputedButton.ariaLabel = translatedAriaLabel || discourseComputedButton.label;
          }

          var title = _compute(button, "title");

          discourseComputedButton.title = title ? _I18n.default.t(title) : _compute(button, "translatedTitle");
          discourseComputedButton.classNames = (_compute(button, "classNames") || []).join(" ");
          discourseComputedButton.icon = _compute(button, "icon");
          discourseComputedButton.disabled = _compute(button, "disabled");
          discourseComputedButton.dropdown = _compute(button, "dropdown");
          discourseComputedButton.priority = _compute(button, "priority");

          if (_isFunction(button.action)) {
            discourseComputedButton.action = function () {
              return button.action.apply(_this);
            };
          } else {
            var actionName = button.action;

            discourseComputedButton.action = function () {
              return _this[actionName]();
            };
          }

          return discourseComputedButton;
        });
      }
    }]));
  }

  function clearTopicFooterButtons() {
    _topicFooterButtons = [];
  }
});