define("discourse/templates/group/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j9Qew5Bz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"user-secondary-navigation\"],[8],[0,\"\\n\"],[4,\"mobile-nav\",null,[[\"class\",\"desktopClass\"],[\"messages-nav\",\"nav-stacked action-list\"]],{\"statements\":[[0,\"    \"],[7,\"li\",true],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"group.messages.inbox\",[24,[\"model\",\"name\"]]]],{\"statements\":[[0,\"        \"],[1,[28,\"i18n\",[\"user.messages.inbox\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"group.messages.archive\",[24,[\"model\",\"name\"]]]],{\"statements\":[[0,\"        \"],[1,[28,\"i18n\",[\"user.messages.archive\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"user-content\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/group/messages.hbs"
    }
  });

  _exports.default = _default;
});