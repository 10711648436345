define("discourse/templates/components/user-stat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lC3Y03jP",
    "block": "{\"symbols\":[\"@icon\",\"@value\",\"@label\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"value\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isNumber\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"number\",[[23,2,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"isDuration\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"format-duration\",[[23,2,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,2,[]],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"label\"],[8],[0,\"\\n  \"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[1,[28,\"d-icon\",[[23,1,[]]],null],false]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"html-safe\",[[28,\"i18n\",[[23,3,[]]],[[\"count\"],[[23,2,[]]]]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/user-stat.hbs"
    }
  });

  _exports.default = _default;
});