define("discourse/initializers/sharing-sources", ["exports", "I18n", "discourse/lib/sharing"], function (_exports, _I18n, _sharing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "sharing-sources",
    initialize: function initialize(container) {
      var siteSettings = container.lookup("site-settings:main");

      _sharing.default.addSource({
        id: "twitter",
        icon: "fab-twitter",
        generateUrl: function generateUrl(link, title) {
          var quote = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
          var text = quote ? "\"".concat(quote, "\" -- ") : title;
          return "http://twitter.com/intent/tweet?url=".concat(encodeURIComponent(link), "&text=").concat(encodeURIComponent(text));
        },
        shouldOpenInPopup: true,
        title: _I18n.default.t("share.twitter"),
        popupHeight: 265
      });

      _sharing.default.addSource({
        id: "facebook",
        icon: "fab-facebook",
        title: _I18n.default.t("share.facebook"),
        generateUrl: function generateUrl(link, title) {
          var quote = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
          var fb_url = siteSettings.facebook_app_id ? "https://www.facebook.com/dialog/share?app_id=".concat(siteSettings.facebook_app_id, "&quote=").concat(encodeURIComponent(quote), "&href=") : "https://www.facebook.com/sharer.php?u=";
          return "".concat(fb_url).concat(encodeURIComponent(link));
        },
        shouldOpenInPopup: true
      });

      _sharing.default.addSource({
        id: "email",
        icon: "envelope",
        title: _I18n.default.t("share.email"),
        generateUrl: function generateUrl(link, title) {
          var quote = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
          var body = quote ? "".concat(quote, " \n\n ").concat(link) : link;
          return "mailto:?to=&subject=" + encodeURIComponent("[" + siteSettings.title + "] " + title) + "&body=" + encodeURIComponent(body);
        },
        showInPrivateContext: true
      });
    }
  };
  _exports.default = _default;
});