define("discourse/templates/modal/ignore-duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R0MOwEPO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"title\",\"autoFocus\"],[\"user.user_notifications.ignore_duration_title\",\"false\"]],{\"statements\":[[0,\"  \"],[1,[28,\"future-date-input\",null,[[\"label\",\"input\",\"includeWeekend\",\"includeDateTime\",\"includeFarFuture\",\"onChangeInput\"],[\"user.user_notifications.ignore_duration_when\",[24,[\"ignoredUntil\"]],true,false,true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"ignoredUntil\"]]],null]],null]]]],false],[0,\"\\n  \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[\"user.user_notifications.ignore_duration_note\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"class\",\"disabled\",\"label\",\"action\"],[\"btn-primary\",[24,[\"saveDisabled\"]],\"user.user_notifications.ignore_duration_save\",[28,\"action\",[[23,0,[]],\"ignore\"],null]]]],false],[0,\"\\n\\n  \"],[1,[28,\"conditional-loading-spinner\",null,[[\"size\",\"condition\"],[\"small\",[24,[\"loading\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/ignore-duration.hbs"
    }
  });

  _exports.default = _default;
});