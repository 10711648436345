define("discourse/widgets/post-gap", ["exports", "I18n", "discourse/widgets/widget"], function (_exports, _I18n, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _widget.createWidget)("post-gap", {
    tagName: "div.gap",
    buildKey: function buildKey(attrs) {
      return "post-gap-".concat(attrs.pos, "-").concat(attrs.postId);
    },
    defaultState: function defaultState() {
      return {
        loading: false
      };
    },
    html: function html(attrs, state) {
      return state.loading ? _I18n.default.t("loading") : _I18n.default.t("post.gap", {
        count: attrs.gap.length
      });
    },
    click: function click() {
      var _this = this;

      var attrs = this.attrs,
          state = this.state;

      if (state.loading) {
        return;
      }

      state.loading = true;
      var args = {
        gap: attrs.gap,
        post: this.model
      };
      return this.sendWidgetAction(attrs.pos === "before" ? "fillGapBefore" : "fillGapAfter", args).then(function () {
        state.loading = false;

        _this.appEvents.trigger("post-stream:gap-expanded", {
          post_id: _this.model.id
        });
      });
    }
  });

  _exports.default = _default;
});