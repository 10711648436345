define("discourse/templates/components/nav-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LTBZPKEQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"routeParam\"]]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",null,[[\"route\",\"model\"],[[24,[\"route\"]],[24,[\"routeParam\"]]]],{\"statements\":[[1,[22,\"contents\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"route\"]]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",null,[[\"route\"],[[24,[\"route\"]]]],{\"statements\":[[1,[22,\"contents\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"a\",true],[11,\"href\",[28,\"get-url\",[[24,[\"path\"]]],null]],[10,\"data-auto-route\",\"true\"],[8],[1,[22,\"contents\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/nav-item.hbs"
    }
  });

  _exports.default = _default;
});