define("discourse/components/composer-user-selector", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("recipients"), (_obj = {
    init: function init() {
      this._super.apply(this, arguments);

      this.set("_groups", []);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.focusTarget === "usernames") {
        this.element.querySelector(".select-kit .select-kit-header").focus();
      }
    },
    splitRecipients: function splitRecipients(recipients) {
      if (Array.isArray(recipients)) {
        return recipients;
      }

      return recipients ? recipients.split(",").filter(Boolean) : [];
    },
    _updateGroups: function _updateGroups(selected, newGroups) {
      var groups = [];

      this._groups.forEach(function (existing) {
        if (selected.includes(existing)) {
          groups.addObject(existing);
        }
      });

      newGroups.forEach(function (newGroup) {
        if (!groups.includes(newGroup)) {
          groups.addObject(newGroup);
        }
      });
      this.setProperties({
        _groups: groups,
        hasGroups: groups.length > 0
      });
    },
    actions: {
      updateRecipients: function updateRecipients(selected, content) {
        var newGroups = content.filterBy("isGroup").mapBy("id");

        this._updateGroups(selected, newGroups);

        this.set("recipients", selected.join(","));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "splitRecipients", [_dec], Object.getOwnPropertyDescriptor(_obj, "splitRecipients"), _obj)), _obj)));

  _exports.default = _default;
});