define("discourse/mixins/grant-badge-controller", ["exports", "discourse/models/user-badge", "discourse-common/lib/icon-library", "discourse-common/utils/decorators"], function (_exports, _userBadge, _iconLibrary, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Mixin.create((_dec = (0, _decorators.default)("allBadges.[]", "userBadges.[]"), _dec2 = (0, _decorators.default)("selectedBadgeId", "grantableBadges"), (_obj = {
    grantableBadges: function grantableBadges(allBadges, userBadges) {
      var granted = userBadges.reduce(function (map, badge) {
        map[badge.get("badge_id")] = true;
        return map;
      }, {});
      return allBadges.filter(function (badge) {
        return badge.get("enabled") && badge.get("manually_grantable") && (!granted[badge.get("id")] || badge.get("multiple_grant"));
      }).map(function (badge) {
        if (badge.get("icon")) {
          badge.set("icon", (0, _iconLibrary.convertIconClass)(badge.icon));
        }

        return badge;
      }).sort(function (a, b) {
        return a.get("name").localeCompare(b.get("name"));
      });
    },
    noGrantableBadges: Ember.computed.empty("grantableBadges"),
    selectedBadgeGrantable: function selectedBadgeGrantable(selectedBadgeId, grantableBadges) {
      return grantableBadges && grantableBadges.find(function (badge) {
        return badge.get("id") === selectedBadgeId;
      });
    },
    grantBadge: function grantBadge(selectedBadgeId, username, badgeReason) {
      var _this = this;

      return _userBadge.default.grant(selectedBadgeId, username, badgeReason).then(function (newBadge) {
        _this.userBadges.pushObject(newBadge);

        return newBadge;
      }, function (error) {
        throw error;
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "grantableBadges", [_dec], Object.getOwnPropertyDescriptor(_obj, "grantableBadges"), _obj), _applyDecoratedDescriptor(_obj, "selectedBadgeGrantable", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectedBadgeGrantable"), _obj)), _obj)));

  _exports.default = _default;
});