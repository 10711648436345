define("discourse/pre-initializers/svg-sprite-fontawesome", ["exports", "discourse/lib/svg-sprite-loader"], function (_exports, _svgSpriteLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "svg-sprite-fontawesome",
    after: "discourse-bootstrap",
    initialize: function initialize(container) {
      var session = container.lookup("session:main");

      if (session.svgSpritePath) {
        (0, _svgSpriteLoader.loadSprites)(session.svgSpritePath, "fontawesome");
      }
    }
  };
  _exports.default = _default;
});