define("discourse/templates/components/color-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M86O70iQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"onlyHex\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"class\",\"add-on\"],[8],[0,\"#\"],[9]],\"parameters\":[]},null],[1,[28,\"text-field\",null,[[\"class\",\"value\",\"maxlength\",\"input\"],[\"hex-input\",[24,[\"hexValue\"]],[24,[\"maxlength\"]],[28,\"action\",[[23,0,[]],\"onHexInput\"],[[\"value\"],[\"target.value\"]]]]]],false],[0,\"\\n\"],[7,\"input\",false],[12,\"class\",\"picker\"],[12,\"value\",[22,\"normalizedHexValue\"]],[12,\"type\",\"color\"],[3,\"on\",[\"input\",[23,0,[\"onPickerInput\"]]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/color-input.hbs"
    }
  });

  _exports.default = _default;
});