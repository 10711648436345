define("discourse/components/emoji-uploader", ["exports", "I18n", "discourse/mixins/uppy-upload", "discourse-common/utils/decorators"], function (_exports, _I18n, _uppyUpload, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var DEFAULT_GROUP = "default";

  var _default = Ember.Component.extend(_uppyUpload.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = (0, _decorators.default)("uploading", "uploadProgress"), _dec4 = (0, _decorators.default)("uploading"), (_obj = {
    type: "emoji",
    uploadUrl: "/admin/customize/emojis",
    hasName: Ember.computed.notEmpty("name"),
    hasGroup: Ember.computed.notEmpty("group"),
    group: "default",
    emojiGroups: null,
    newEmojiGroups: null,
    tagName: null,
    preventDirectS3Uploads: true,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set("newEmojiGroups", this.emojiGroups);
    },
    createEmojiGroup: function createEmojiGroup(group) {
      var newEmojiGroups = this.newEmojiGroups;

      if (group !== DEFAULT_GROUP) {
        newEmojiGroups = this.emojiGroups.concat([group]).uniq();
      }

      this.setProperties({
        newEmojiGroups: newEmojiGroups,
        group: group
      });
    },
    _perFileData: function _perFileData() {
      var payload = {};

      if (!Ember.isEmpty(this.name)) {
        payload.name = this.name; // if uploading multiple files, we can't use the name for every emoji

        this.set("name", null);
      }

      if (!Ember.isEmpty(this.group) && this.group !== DEFAULT_GROUP) {
        payload.group = this.group;
      }

      return payload;
    },
    validateUploadedFilesOptions: function validateUploadedFilesOptions() {
      return {
        imagesOnly: true
      };
    },
    uploadDone: function uploadDone(upload) {
      this.done(upload, this.group);
      this.set("name", null);
    },
    chooseFiles: function chooseFiles() {
      this.fileInputEl.click();
    },
    buttonLabel: function buttonLabel(uploading, uploadProgress) {
      if (uploading) {
        return "".concat(_I18n.default.t("admin.emoji.uploading"), " ").concat(uploadProgress, "%");
      } else {
        return _I18n.default.t("admin.emoji.add");
      }
    },
    buttonIcon: function buttonIcon(uploading) {
      if (uploading) {
        return "spinner";
      } else {
        return "plus";
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "createEmojiGroup", [_dec], Object.getOwnPropertyDescriptor(_obj, "createEmojiGroup"), _obj), _applyDecoratedDescriptor(_obj, "chooseFiles", [_dec2], Object.getOwnPropertyDescriptor(_obj, "chooseFiles"), _obj), _applyDecoratedDescriptor(_obj, "buttonLabel", [_dec3], Object.getOwnPropertyDescriptor(_obj, "buttonLabel"), _obj), _applyDecoratedDescriptor(_obj, "buttonIcon", [_dec4], Object.getOwnPropertyDescriptor(_obj, "buttonIcon"), _obj)), _obj)));

  _exports.default = _default;
});