define("discourse/components/future-date-input", ["exports", "discourse/controllers/edit-topic-timer", "discourse/lib/timeframes-builder", "I18n", "select-kit/components/future-date-input-selector"], function (_exports, _editTopicTimer, _timeframesBuilder, _I18n, _futureDateInputSelector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    selection: null,
    includeDateTime: true,
    isCustom: Ember.computed.equal("selection", "pick_date_and_time"),
    displayDateAndTimePicker: Ember.computed.and("includeDateTime", "isCustom"),
    displayLabel: null,
    labelClasses: null,
    timeInputDisabled: Ember.computed.empty("_date"),
    _date: null,
    _time: null,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.input) {
        var dateTime = moment(this.input);
        var closestTimeframe = this.findClosestTimeframe(dateTime);

        if (closestTimeframe) {
          this.set("selection", closestTimeframe.id);
        } else {
          this.setProperties({
            selection: "pick_date_and_time",
            _date: dateTime.format("YYYY-MM-DD"),
            _time: dateTime.format("HH:mm")
          });
        }
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.label) {
        this.set("displayLabel", _I18n.default.t(this.label));
      }
    },
    onChangeDate: function onChangeDate(date) {
      if (!date) {
        this.set("time", null);
      }

      this._dateTimeChanged(date, this.time);
    },
    onChangeTime: function onChangeTime(time) {
      if (this._date) {
        this._dateTimeChanged(this._date, time);
      }
    },
    _dateTimeChanged: function _dateTimeChanged(date, time) {
      time = time ? " ".concat(time) : "";
      var dateTime = moment("".concat(date).concat(time));

      if (dateTime.isValid()) {
        this.attrs.onChangeInput && this.attrs.onChangeInput(dateTime.format(_futureDateInputSelector.FORMAT));
      } else {
        this.attrs.onChangeInput && this.attrs.onChangeInput(null);
      }
    },
    findClosestTimeframe: function findClosestTimeframe(dateTime) {
      var _this = this;

      var now = moment();
      var futureDateInputSelectorOptions = {
        now: now,
        day: now.day(),
        includeWeekend: this.includeWeekend,
        includeFarFuture: this.includeFarFuture,
        includeDateTime: this.includeDateTime,
        canScheduleNow: this.includeNow || false,
        canScheduleToday: 24 - now.hour() > 6
      };
      return (0, _timeframesBuilder.default)(futureDateInputSelectorOptions).find(function (tf) {
        var tfDateTime = tf.when(moment(), _this.statusType !== _editTopicTimer.CLOSE_STATUS_TYPE ? 8 : 18);

        if (tfDateTime) {
          var diff = tfDateTime.diff(dateTime);
          return 0 <= diff && diff < 60 * 1000;
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "onChangeDate", [_dec], Object.getOwnPropertyDescriptor(_obj, "onChangeDate"), _obj), _applyDecoratedDescriptor(_obj, "onChangeTime", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onChangeTime"), _obj)), _obj)));

  _exports.default = _default;
});