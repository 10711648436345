define("discourse/templates/discovery/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ic1dIxn/",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"plugin-outlet\",null,[[\"name\",\"connectorTagName\",\"args\"],[\"above-discovery-categories\",\"div\",[28,\"hash\",null,[[\"categories\",\"categoryPageStyle\",\"topics\"],[[24,[\"model\",\"categories\"]],[24,[\"categoryPageStyle\"]],[24,[\"model\",\"topics\"]]]]]]]],false],[0,\"\\n\\n\"],[4,\"discovery-categories\",null,[[\"refresh\"],[[28,\"action\",[[23,0,[]],\"refresh\"],null]]],{\"statements\":[[4,\"if\",[[24,[\"topicTrackingState\",\"hasIncoming\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"show-more \",[28,\"if\",[[24,[\"hasTopics\"]],\"has-topics\"],null]]]],[8],[0,\"\\n      \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"alert alert-info clickable\"],[3,\"action\",[[23,0,[]],\"showInserted\"]],[8],[0,\"\\n        \"],[1,[28,\"count-i18n\",null,[[\"key\",\"suffix\",\"count\"],[\"topic_count_\",[24,[\"topicTrackingState\",\"filter\"]],[24,[\"topicTrackingState\",\"incomingCount\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"component\",[[24,[\"categoryPageStyle\"]]],[[\"categories\",\"topics\"],[[24,[\"model\",\"categories\"]],[24,[\"model\",\"topics\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"connectorTagName\",\"args\"],[\"below-discovery-categories\",\"div\",[28,\"hash\",null,[[\"categories\",\"categoryPageStyle\",\"topics\"],[[24,[\"model\",\"categories\"]],[24,[\"categoryPageStyle\"]],[24,[\"model\",\"topics\"]]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/discovery/categories.hbs"
    }
  });

  _exports.default = _default;
});