define("discourse/models/badge", ["exports", "discourse/models/badge-grouping", "discourse/models/rest", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse-common/lib/get-url"], function (_exports, _badgeGrouping, _rest, _ajax, _decorators, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var Badge = _rest.default.extend((_dec = (0, _decorators.default)("badge_type.name"), (_obj = {
    newBadge: Ember.computed.none("id"),
    image: Ember.computed.alias("image_url"),
    url: function url() {
      return (0, _getUrl.default)("/badges/".concat(this.id, "/").concat(this.slug));
    },
    updateFromJson: function updateFromJson(json) {
      var _this = this;

      if (json.badge) {
        Object.keys(json.badge).forEach(function (key) {
          return _this.set(key, json.badge[key]);
        });
      }

      if (json.badge_types) {
        json.badge_types.forEach(function (badgeType) {
          if (badgeType.id === _this.badge_type_id) {
            _this.set("badge_type", Object.create(badgeType));
          }
        });
      }
    },
    badgeTypeClassName: function badgeTypeClassName(type) {
      type = type || "";
      return "badge-type-".concat(type.toLowerCase());
    },
    save: function save(data) {
      var _this2 = this;

      var url = "/admin/badges",
          type = "POST";

      if (this.id) {
        // We are updating an existing badge.
        url += "/".concat(this.id);
        type = "PUT";
      }

      return (0, _ajax.ajax)(url, {
        type: type,
        data: data
      }).then(function (json) {
        _this2.updateFromJson(json);

        return _this2;
      }).catch(function (error) {
        throw new Error(error);
      });
    },
    destroy: function destroy() {
      if (this.newBadge) {
        return Ember.RSVP.Promise.resolve();
      }

      return (0, _ajax.ajax)("/admin/badges/".concat(this.id), {
        type: "DELETE"
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "url", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "url"), _obj), _applyDecoratedDescriptor(_obj, "badgeTypeClassName", [_dec], Object.getOwnPropertyDescriptor(_obj, "badgeTypeClassName"), _obj)), _obj)));

  Badge.reopenClass({
    createFromJson: function createFromJson(json) {
      // Create BadgeType objects.
      var badgeTypes = {};

      if ("badge_types" in json) {
        json.badge_types.forEach(function (badgeTypeJson) {
          return badgeTypes[badgeTypeJson.id] = Ember.Object.create(badgeTypeJson);
        });
      }

      var badgeGroupings = {};

      if ("badge_groupings" in json) {
        json.badge_groupings.forEach(function (badgeGroupingJson) {
          return badgeGroupings[badgeGroupingJson.id] = _badgeGrouping.default.create(badgeGroupingJson);
        });
      } // Create Badge objects.


      var badges = [];

      if ("badge" in json) {
        badges = [json.badge];
      } else if (json.badges) {
        badges = json.badges;
      }

      badges = badges.map(function (badgeJson) {
        var badge = Badge.create(badgeJson);
        badge.setProperties({
          badge_type: badgeTypes[badge.badge_type_id],
          badge_grouping: badgeGroupings[badge.badge_grouping_id]
        });
        return badge;
      });

      if ("badge" in json) {
        return badges[0];
      } else {
        return badges;
      }
    },
    findAll: function findAll(opts) {
      var listable = "";

      if (opts && opts.onlyListable) {
        listable = "?only_listable=true";
      }

      return (0, _ajax.ajax)("/badges.json".concat(listable), {
        data: opts
      }).then(function (badgesJson) {
        return Badge.createFromJson(badgesJson);
      });
    },
    findById: function findById(id) {
      return (0, _ajax.ajax)("/badges/".concat(id)).then(function (badgeJson) {
        return Badge.createFromJson(badgeJson);
      });
    }
  });
  var _default = Badge;
  _exports.default = _default;
});