define("discourse/templates/components/slow-mode-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t6uaBWuZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showSlowModeNotice\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"topic-status-info\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"slow-mode-heading\"],[8],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"\\n        \"],[1,[28,\"d-icon\",[\"hourglass-start\"],null],false],[0,\"\\n        \"],[1,[28,\"i18n\",[\"topic.slow_mode_notice.duration\"],[[\"duration\"],[[24,[\"durationText\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"user\",\"canManageTopic\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"d-button\",null,[[\"class\",\"action\",\"icon\"],[\"slow-mode-remove\",[28,\"action\",[[23,0,[]],\"disableSlowMode\"],null],\"trash-alt\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/slow-mode-info.hbs"
    }
  });

  _exports.default = _default;
});