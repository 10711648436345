define("discourse/initializers/strip-mobile-app-url-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "strip-mobile-app-url-params",
    initialize: function initialize() {
      var queryStrings = window.location.search;

      if (queryStrings.indexOf("user_api_public_key") !== -1) {
        var params = queryStrings.startsWith("?") ? queryStrings.substr(1).split("&") : [];
        params = params.filter(function (param) {
          return !param.startsWith("user_api_public_key=") && !param.startsWith("auth_redirect=");
        });
        queryStrings = params.length > 0 ? "?".concat(params.join("&")) : "";

        if (window.history && window.history.replaceState) {
          window.history.replaceState(null, null, "".concat(location.pathname).concat(queryStrings).concat(location.hash));
        }
      }
    }
  };
  _exports.default = _default;
});