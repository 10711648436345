define("discourse/routes/user-notifications-likes-received", ["exports", "discourse/models/user-action", "discourse/routes/user-activity-stream"], function (_exports, _userAction, _userActivityStream) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userActivityStream.default.extend({
    userActionType: _userAction.default.TYPES["likes_received"]
  });

  _exports.default = _default;
});