define("discourse/widgets/post-links", ["exports", "discourse/widgets/widget", "virtual-dom", "discourse-common/lib/icon-library", "discourse/widgets/emoji"], function (_exports, _widget, _virtualDom, _iconLibrary, _emoji) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _widget.createWidget)("post-links", {
    tagName: "div.post-links-container",
    buildKey: function buildKey(attrs) {
      return "post-links-".concat(attrs.id);
    },
    defaultState: function defaultState() {
      return {
        collapsed: true
      };
    },
    linkHtml: function linkHtml(link) {
      var linkBody = (0, _emoji.replaceEmoji)(link.title);

      if (link.clicks) {
        linkBody.push((0, _virtualDom.h)("span.badge.badge-notification.clicks", link.clicks.toString()));
      }

      return (0, _virtualDom.h)("li", (0, _virtualDom.h)("a.track-link", {
        className: "inbound",
        attributes: {
          href: link.url
        }
      }, [(0, _iconLibrary.iconNode)("link"), linkBody]));
    },
    html: function html(attrs, state) {
      var _this = this;

      if (!this.attrs.links || this.attrs.links.length === 0) {
        // shortcut all work
        return;
      } // only show incoming


      var links = this.attrs.links.filter(function (l) {
        return l.reflection;
      }).uniqBy("title");

      if (links.length === 0) {
        return;
      }

      var result = []; // show all links

      if (links.length <= 5 || !state.collapsed) {
        links.forEach(function (l) {
          return result.push(_this.linkHtml(l));
        });
      } else {
        var max = Math.min(5, links.length);

        for (var i = 0; i < max; i++) {
          result.push(this.linkHtml(links[i]));
        } // 'show more' link


        if (links.length > max) {
          result.push((0, _virtualDom.h)("li", this.attach("link", {
            labelCount: "post_links.title",
            title: "post_links.about",
            count: links.length - max,
            action: "expandLinks",
            className: "expand-links"
          })));
        }
      }

      if (result.length) {
        return (0, _virtualDom.h)("ul.post-links", result);
      }
    },
    expandLinks: function expandLinks() {
      this.state.collapsed = false;
    }
  });

  _exports.default = _default;
});