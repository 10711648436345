define("discourse/lib/text-direction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isRTL = isRTL;
  _exports.isLTR = isLTR;
  _exports.setTextDirections = setTextDirections;
  _exports.siteDir = siteDir;
  _exports.isDocumentRTL = isDocumentRTL;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var ltrChars = "A-Za-z\xC0-\xD6\xD8-\xF6\xF8-\u02B8\u0300-\u0590\u0800-\u1FFF\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF";
  var rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC";
  var rtlDirCheck = new RegExp("^[^" + ltrChars + "]*[" + rtlChars + "]");
  var ltrDirCheck = new RegExp("^[^" + rtlChars + "]*[" + ltrChars + "]");

  var _siteDir;

  function isRTL(text) {
    return rtlDirCheck.test(text);
  }

  function isLTR(text) {
    return ltrDirCheck.test(text);
  }

  function setTextDirections(elem) {
    var _iterator = _createForOfIteratorHelper(elem.children),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var e = _step.value;

        if (e.textContent) {
          e.setAttribute("dir", isRTL(e.textContent) ? "rtl" : "ltr");
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }

  function siteDir() {
    if (!_siteDir) {
      _siteDir = document.documentElement.classList.contains("rtl") ? "rtl" : "ltr";
    }

    return _siteDir;
  }

  function isDocumentRTL() {
    return siteDir() === "rtl";
  }
});