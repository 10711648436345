define("discourse/templates/components/discourse-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UySKv44b",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"visible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"id\",\"banner\"],[11,\"class\",[22,\"overlay\"]],[8],[0,\"\\n      \"],[1,[28,\"d-button\",null,[[\"icon\",\"action\",\"class\",\"title\"],[\"times\",[28,\"action\",[[23,0,[]],\"dismiss\"],null],\"btn btn-flat close\",\"banner.close\"]]],false],[0,\"\\n      \"],[7,\"div\",true],[10,\"id\",\"banner-content\"],[8],[0,\"\\n        \"],[1,[28,\"html-safe\",[[24,[\"content\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[24,[\"currentUser\",\"staff\"]]],null,{\"statements\":[[0,\"          \"],[7,\"p\",true],[8],[7,\"a\",true],[11,\"href\",[24,[\"banner\",\"url\"]]],[8],[1,[28,\"html-safe\",[[28,\"i18n\",[\"banner.edit\"],null]],null],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/discourse-banner.hbs"
    }
  });

  _exports.default = _default;
});