define("discourse/components/html-with-links", ["exports", "discourse/lib/click-track"], function (_exports, _clickTrack) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    click: function click(event) {
      var _event$target;

      if ((event === null || event === void 0 ? void 0 : (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.tagName) === "A") {
        if ((0, _clickTrack.shouldOpenInNewTab)(event.target.href)) {
          (0, _clickTrack.openLinkInNewTab)(event.target);
        }
      }
    }
  });

  _exports.default = _default;
});