define("discourse/templates/user/bookmarks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XIQNkTbP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"conditional-loading-spinner\",null,[[\"condition\"],[[24,[\"loading\"]]]],{\"statements\":[[4,\"if\",[[24,[\"permissionDenied\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"alert alert-info\"],[8],[1,[28,\"i18n\",[\"bookmarks.list_permission_denied\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"userDoesNotHaveBookmarks\"]]],null,{\"statements\":[[0,\"    \"],[5,\"empty-state\",[],[[\"@title\",\"@body\"],[[28,\"i18n\",[\"user.no_bookmarks_title\"],null],[22,\"emptyStateBody\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"inline-form full-width bookmark-search-form\"],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"placeholder\",\"enter\",\"id\",\"autocomplete\"],[\"text\",[24,[\"searchTerm\"]],[28,\"i18n\",[\"bookmarks.search_placeholder\"],null],[28,\"action\",[[23,0,[]],\"search\"],null],\"bookmark-search\",\"off\"]]],false],[0,\"\\n      \"],[1,[28,\"d-button\",null,[[\"class\",\"action\",\"type\",\"icon\"],[\"btn-primary\",[28,\"action\",[[23,0,[]],\"search\"],null],\"button\",\"search\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"nothingFound\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"alert alert-info\"],[8],[1,[28,\"i18n\",[\"user.no_bookmarks_search\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"bookmark-list\",null,[[\"loadMore\",\"reload\",\"loadingMore\",\"content\"],[[28,\"action\",[[23,0,[]],\"loadMore\"],null],[28,\"action\",[[23,0,[]],\"reload\"],null],[24,[\"loadingMore\"]],[24,[\"model\",\"bookmarks\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/user/bookmarks.hbs"
    }
  });

  _exports.default = _default;
});