define("discourse/templates/account-created", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TPqcic+l",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"simple-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"account-created\"],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/account-created.hbs"
    }
  });

  _exports.default = _default;
});