define("discourse/templates/components/reviewable-created-by-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OW8smXTN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"names\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"username\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"user\"]]],null,{\"statements\":[[0,\"      \"],[4,\"user-link\",null,[[\"user\"],[[24,[\"user\"]]]],{\"statements\":[[1,[24,[\"user\",\"username\"]],false]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"user\",\"silenced\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"d-icon\",[\"ban\"],[[\"title\"],[\"user.silenced_tooltip\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"i18n\",[\"review.deleted_user\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"connectorTagName\",\"args\"],[\"after-reviewable-post-user\",\"div\",[28,\"hash\",null,[[\"user\"],[[24,[\"user\"]]]]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-created-by-name.hbs"
    }
  });

  _exports.default = _default;
});