define("discourse/templates/components/preference-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "noW0hD3S",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"checkbox-label\"],[8],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"type\",\"disabled\",\"checked\"],[\"checkbox\",[24,[\"disabled\"]],[24,[\"checked\"]]]]],false],[0,\"\\n  \"],[1,[22,\"label\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/preference-checkbox.hbs"
    }
  });

  _exports.default = _default;
});