define("discourse/controllers/navigation/category", ["exports", "discourse/mixins/filter-mode", "discourse/controllers/navigation/default"], function (_exports, _filterMode, _default2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _default2.default.extend(_filterMode.default, {
    showingParentCategory: Ember.computed.none("category.parentCategory"),
    showingSubcategoryList: Ember.computed.and("category.show_subcategory_list", "showingParentCategory")
  });

  _exports.default = _default;
});