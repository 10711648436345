define("discourse/lib/click-track", ["exports", "discourse/lib/url", "I18n", "discourse/models/user", "discourse/lib/ajax", "bootbox", "discourse-common/lib/get-url", "discourse-common/config/environment", "discourse/lib/utilities", "discourse/lib/intercept-click"], function (_exports, _url, _I18n, _user, _ajax, _bootbox, _getUrl, _environment, _utilities, _interceptClick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isValidLink = isValidLink;
  _exports.shouldOpenInNewTab = shouldOpenInNewTab;
  _exports.openLinkInNewTab = openLinkInNewTab;
  _exports.default = void 0;

  function isValidLink($link) {
    // .hashtag == category/tag link
    // .back == quote back ^ button
    if ($link.is(".lightbox, .no-track-link, .hashtag, .back")) {
      return false;
    }

    if ($link.parents("aside.quote, .elided, .expanded-embed").length !== 0) {
      return false;
    }

    if ($link.closest(".onebox-result, .onebox-body").length) {
      var $a = $link.closest(".onebox").find("header a");

      if ($a[0] && $a[0].href === $link[0].href) {
        return true;
      }
    }

    return $link.hasClass("track-link") || $link.closest(".hashtag, .badge-category, .onebox-result, .onebox-body").length === 0;
  }

  function shouldOpenInNewTab(href) {
    var isInternal = _url.default.isInternal(href);

    var openExternalInNewTab = _user.default.currentProp("external_links_in_new_tab");

    return !isInternal && openExternalInNewTab;
  }

  function openLinkInNewTab(link) {
    var href = (link.href || link.dataset.href || "").trim();

    if (href === "") {
      return;
    }

    var newWindow = window.open(href, "_blank");
    newWindow.opener = null;
    newWindow.focus(); // Hack to prevent changing current window.location.
    // e.preventDefault() does not work.

    if (!link.dataset.href) {
      link.classList.add("no-href");
      link.dataset.href = link.href;
      link.dataset.autoRoute = true;
      link.removeAttribute("href");
      Ember.run.later(function () {
        if (link) {
          link.classList.remove("no-href");
          link.setAttribute("href", link.dataset.href);
          delete link.dataset.href;
          delete link.dataset.autoRoute;
        }
      }, 50);
    }
  }

  var _default = {
    trackClick: function trackClick(e, siteSettings) {
      var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
          _ref$returnPromise = _ref.returnPromise,
          returnPromise = _ref$returnPromise === void 0 ? false : _ref$returnPromise;

      // right clicks are not tracked
      if (e.which === 3) {
        return true;
      } // Cancel click if triggered as part of selection.


      var selection = window.getSelection();

      if (selection.type === "Range" || selection.rangeCount > 0) {
        if ((0, _utilities.selectedText)() !== "") {
          return true;
        }
      }

      var $link = $(e.currentTarget);
      var tracking = isValidLink($link); // Return early for mentions and group mentions

      if ($link.is(".mention, .mention-group")) {
        return true;
      }

      var href = ($link.attr("href") || $link.data("href") || "").trim();

      if (!href || href.indexOf("mailto:") === 0) {
        return true;
      }

      if ($link.hasClass("attachment")) {
        // Warn the user if they cannot download the file.
        if (siteSettings && siteSettings.prevent_anons_from_downloading_files && !_user.default.current()) {
          _bootbox.default.alert(_I18n.default.t("post.errors.attachment_download_requires_login"));
        } else if ((0, _interceptClick.wantsNewWindow)(e)) {
          var newWindow = window.open(href, "_blank");
          newWindow.opener = null;
          newWindow.focus();
        } else {
          _url.default.redirectTo(href);
        }

        return false;
      }

      var $article = $link.closest("article:not(.onebox-body), .excerpt, #revisions");
      var postId = $article.data("post-id");
      var topicId = $("#topic").data("topic-id") || $article.data("topic-id");
      var userId = $link.data("user-id") || $article.data("user-id");

      var ownLink = userId && userId === _user.default.currentProp("id"); // Update badge clicks unless it's our own.


      if (tracking && !ownLink) {
        var $badge = $("span.badge", $link);

        if ($badge.length === 1) {
          var html = $badge.html();
          var key = "".concat(new Date().toLocaleDateString(), "-").concat(postId, "-").concat(href);

          if (/^\d+$/.test(html) && !sessionStorage.getItem(key)) {
            sessionStorage.setItem(key, true);
            $badge.html(parseInt(html, 10) + 1);
          }
        }
      }

      var trackPromise = Ember.RSVP.Promise.resolve();

      if (tracking) {
        if (!(0, _environment.isTesting)() && navigator.sendBeacon) {
          var data = new FormData();
          data.append("url", href);
          data.append("post_id", postId);
          data.append("topic_id", topicId);
          navigator.sendBeacon((0, _getUrl.default)("/clicks/track"), data);
        } else {
          trackPromise = (0, _ajax.ajax)((0, _getUrl.default)("/clicks/track"), {
            type: "POST",
            data: {
              url: href,
              post_id: postId,
              topic_id: topicId
            }
          });
        }
      }

      if (!(0, _interceptClick.wantsNewWindow)(e)) {
        if (shouldOpenInNewTab(href)) {
          openLinkInNewTab($link[0]);
        } else {
          trackPromise.finally(function () {
            if (_url.default.isInternal(href) && (0, _getUrl.samePrefix)(href)) {
              _url.default.routeTo(href);
            } else {
              _url.default.redirectAbsolute(href);
            }
          });
        }

        return returnPromise ? trackPromise : false;
      }

      return returnPromise ? trackPromise : true;
    }
  };
  _exports.default = _default;
});