define("discourse/templates/components/signup-cta", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w9oHtQ6a",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"signup-cta alert alert-info\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"session\",\"hideSignupCta\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\",true],[8],[0,\"\\n      \"],[1,[28,\"i18n\",[\"signup_cta.hidden_for_session\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"h3\",true],[8],[1,[28,\"replace-emoji\",[[28,\"i18n\",[\"signup_cta.intro\"],null]],null],false],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"replace-emoji\",[[28,\"i18n\",[\"signup_cta.value_prop\"],null]],null],false],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"buttons\"],[8],[0,\"\\n      \"],[1,[28,\"d-button\",null,[[\"action\",\"label\",\"icon\",\"class\"],[[28,\"route-action\",[\"showCreateAccount\"],null],\"signup_cta.sign_up\",\"check\",\"btn-primary\"]]],false],[0,\"\\n      \"],[1,[28,\"d-button\",null,[[\"action\",\"label\",\"class\"],[[28,\"action\",[[23,0,[]],\"hideForSession\"],null],\"signup_cta.hide_session\",\"no-icon\"]]],false],[0,\"\\n      \"],[7,\"a\",false],[12,\"href\",\"\"],[3,\"action\",[[23,0,[]],\"neverShow\"]],[8],[1,[28,\"i18n\",[\"signup_cta.hide_forever\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/signup-cta.hbs"
    }
  });

  _exports.default = _default;
});