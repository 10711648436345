define("discourse/components/topic-timeline", ["exports", "discourse/mixins/docking", "discourse/components/mount-widget", "discourse/lib/offset-calculator", "discourse-common/utils/decorators", "discourse/lib/optional-service"], function (_exports, _docking, _mountWidget, _offsetCalculator, _decorators, _optionalService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _mountWidget.default.extend(_docking.default, (_dec = (0, _decorators.observes)("topic.highest_post_number", "loading"), _dec2 = (0, _decorators.observes)("topic.details.notification_level"), (_obj = {
    adminTools: (0, _optionalService.default)(),
    widget: "topic-timeline-container",
    dockBottom: null,
    dockAt: null,
    intersectionObserver: null,
    buildArgs: function buildArgs() {
      var attrs = {
        topic: this.topic,
        notificationLevel: this.notificationLevel,
        topicTrackingState: this.topicTrackingState,
        enteredIndex: this.enteredIndex,
        dockAt: this.dockAt,
        dockBottom: this.dockBottom,
        mobileView: this.get("site.mobileView")
      };
      var event = this.prevEvent;

      if (event) {
        attrs.enteredIndex = event.postIndex - 1;
      }

      if (this.fullscreen) {
        attrs.fullScreen = true;
        attrs.addShowClass = this.addShowClass;
      }

      return attrs;
    },
    newPostAdded: function newPostAdded() {
      var _this = this;

      this.queueRerender(function () {
        return _this.queueDockCheck();
      });
    },
    _queueRerender: function _queueRerender() {
      this.queueRerender();
    },
    dockCheck: function dockCheck() {
      var timeline = this.element.querySelector(".timeline-container");
      var timelineHeight = timeline && timeline.offsetHeight || 400;
      var prev = this.dockAt;
      var posTop = (0, _offsetCalculator.headerOffset)() + window.pageYOffset;
      var pos = posTop + timelineHeight;
      this.dockBottom = false;

      if (posTop < this.topicTop) {
        this.dockAt = parseInt(this.topicTop, 10);
      } else if (pos > this.topicBottom) {
        this.dockAt = parseInt(this.topicBottom - timelineHeight, 10);
        this.dockBottom = true;

        if (this.dockAt < 0) {
          this.dockAt = 0;
        }
      } else {
        this.dockAt = null;
        this.fastDockAt = parseInt(this.topicBottom - timelineHeight, 10);
      }

      if (this.dockAt !== prev) {
        this.queueRerender();
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (this.fullscreen && !this.addShowClass) {
        Ember.run.next(function () {
          _this2.set("addShowClass", true);

          _this2.queueRerender();
        });
      }

      this.dispatch("topic:current-post-scrolled", function () {
        return "timeline-scrollarea-".concat(_this2.topic.id);
      });
      this.dispatch("topic:toggle-actions", "topic-admin-menu-button");

      if (!this.site.mobileView) {
        this.appEvents.on("composer:opened", this, this.queueRerender);
        this.appEvents.on("composer:resized", this, this.queueRerender);
        this.appEvents.on("composer:closed", this, this.queueRerender);

        if ("IntersectionObserver" in window) {
          this.intersectionObserver = new IntersectionObserver(function (entries) {
            var _iterator = _createForOfIteratorHelper(entries),
                _step;

            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var entry = _step.value;
                var bounds = entry.boundingClientRect;

                if (entry.target.id === "topic-bottom") {
                  _this2.set("topicBottom", bounds.y + window.scrollY);
                } else {
                  _this2.set("topicTop", bounds.y + window.scrollY);
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
          });
          var elements = [document.querySelector(".container.posts"), document.querySelector("#topic-bottom")];

          for (var i = 0; i < elements.length; i++) {
            this.intersectionObserver.observe(elements[i]);
          }
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (!this.site.mobileView) {
        this.appEvents.off("composer:opened", this, this.queueRerender);
        this.appEvents.off("composer:resized", this, this.queueRerender);
        this.appEvents.off("composer:closed", this, this.queueRerender);

        if ("IntersectionObserver" in window) {
          var _this$intersectionObs;

          (_this$intersectionObs = this.intersectionObserver) === null || _this$intersectionObs === void 0 ? void 0 : _this$intersectionObs.disconnect();
          this.intersectionObserver = null;
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "newPostAdded", [_dec], Object.getOwnPropertyDescriptor(_obj, "newPostAdded"), _obj), _applyDecoratedDescriptor(_obj, "_queueRerender", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_queueRerender"), _obj)), _obj)));

  _exports.default = _default;
});