define("discourse/templates/navigation/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A0+4E8US",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-section\",null,[[\"bodyClass\",\"class\"],[\"navigation-categories\",\"navigation-container\"]],{\"statements\":[[0,\"  \"],[1,[28,\"d-navigation\",null,[[\"filterType\",\"showCategoryAdmin\",\"createCategory\",\"reorderCategories\",\"canCreateTopic\",\"hasDraft\",\"createTopic\"],[\"categories\",[24,[\"showCategoryAdmin\"]],[28,\"route-action\",[\"createCategory\"],null],[28,\"route-action\",[\"reorderCategories\"],null],[24,[\"canCreateTopic\"]],[24,[\"currentUser\",\"has_topic_draft\"]],[28,\"route-action\",[\"createTopic\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/navigation/categories.hbs"
    }
  });

  _exports.default = _default;
});