define("discourse/templates/user-invited", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vyxi3tJL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"can_see_invite_details\"]]],null,{\"statements\":[[4,\"d-section\",null,[[\"class\",\"pageClass\"],[\"user-secondary-navigation\",\"user-invites\"]],{\"statements\":[[4,\"mobile-nav\",null,[[\"class\",\"desktopClass\"],[\"invites-nav\",\"nav-stacked action-list\"]],{\"statements\":[[0,\"      \"],[1,[28,\"nav-item\",null,[[\"route\",\"routeParam\",\"i18nLabel\"],[\"userInvited.show\",\"pending\",[24,[\"pendingLabel\"]]]]],false],[0,\"\\n      \"],[1,[28,\"nav-item\",null,[[\"route\",\"routeParam\",\"i18nLabel\"],[\"userInvited.show\",\"expired\",[24,[\"expiredLabel\"]]]]],false],[0,\"\\n      \"],[1,[28,\"nav-item\",null,[[\"route\",\"routeParam\",\"i18nLabel\"],[\"userInvited.show\",\"redeemed\",[24,[\"redeemedLabel\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/user-invited.hbs"
    }
  });

  _exports.default = _default;
});