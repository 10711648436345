define("discourse/templates/components/categories-topic-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xaOU/UEO",
    "block": "{\"symbols\":[\"t\"],\"statements\":[[7,\"div\",true],[10,\"role\",\"heading\"],[10,\"aria-level\",\"2\"],[10,\"class\",\"table-heading\"],[8],[0,\"\\n  \"],[1,[28,\"i18n\",[[28,\"concat\",[\"filters.\",[24,[\"filter\"]],\".title\"],null]],null],false],[0,\"\\n  \"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"connectorTagName\"],[\"categories-topics-table-heading\",\"div\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"topics\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"topics\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"latest-topic-list-item\",null,[[\"topic\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"more-topics\"],[8],[0,\"\\n    \"],[7,\"a\",true],[11,\"href\",[28,\"get-url\",[[28,\"concat\",[\"/\",[24,[\"filter\"]]],null]],null]],[10,\"class\",\"btn btn-default pull-right\"],[8],[1,[28,\"i18n\",[\"more\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"no-topics\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[8],[1,[28,\"i18n\",[[28,\"concat\",[\"topics.none.\",[24,[\"filter\"]]],null]],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/categories-topic-list.hbs"
    }
  });

  _exports.default = _default;
});