define("discourse/routes/build-topic-route", ["exports", "discourse/controllers/discovery-sortable", "discourse/routes/discourse", "I18n", "discourse/models/session", "discourse/models/site", "discourse-common/lib/object", "discourse/lib/utilities"], function (_exports, _discoverySortable, _discourse, _I18n, _session, _site, _object, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _exports.filterQueryParams = filterQueryParams;
  _exports.findTopicList = findTopicList;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // A helper to build a topic route for a filter
  function filterQueryParams(params, defaultParams) {
    var findOpts = Object.assign({}, defaultParams || {});

    if (params) {
      Object.keys(_discoverySortable.queryParams).forEach(function (opt) {
        if (!Ember.isEmpty(params[opt])) {
          findOpts[opt] = params[opt];
        }
      });
    }

    return findOpts;
  }

  function findTopicList(store, tracking, filter, filterParams, extras) {
    extras = extras || {};
    return new Ember.RSVP.Promise(function (resolve) {
      var session = _session.default.current();

      if (extras.cached) {
        var cachedList = session.get("topicList"); // Try to use the cached version if it exists and is greater than the topics per page

        if (cachedList && cachedList.get("filter") === filter && (cachedList.get("topics.length") || 0) > cachedList.get("per_page") && (0, _object.deepEqual)(cachedList.get("listParams"), filterParams)) {
          cachedList.set("loaded", true);

          if (tracking) {
            tracking.updateTopics(cachedList.get("topics"));
          }

          return resolve(cachedList);
        }

        session.set("topicList", null);
      } else {
        // Clear the cache
        session.setProperties({
          topicList: null,
          topicListScrollPosition: null
        });
      } // Clean up any string parameters that might slip through


      filterParams = filterParams || {};
      Object.keys(filterParams).forEach(function (k) {
        var val = filterParams[k];

        if (val === "undefined" || val === "null") {
          filterParams[k] = null;
        }
      });
      return resolve(store.findFiltered("topicList", {
        filter: filter,
        params: filterParams || {}
      }));
    }).then(function (list) {
      list.set("listParams", filterParams);

      if (tracking) {
        tracking.sync(list, list.filter, filterParams);
        tracking.trackIncoming(list.filter);
      }

      _session.default.currentProp("topicList", list);

      if (list.topic_list && list.topic_list.top_tags) {
        if (list.filter.startsWith("c/") || list.filter.startsWith("tags/c/")) {
          _site.default.currentProp("category_top_tags", list.topic_list.top_tags);
        } else {
          _site.default.currentProp("top_tags", list.topic_list.top_tags);
        }
      }

      return list;
    });
  }

  function _default(filter, extras) {
    var _dec, _dec2, _obj;

    extras = extras || {};
    return _discourse.default.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
      screenTrack: Ember.inject.service(),
      queryParams: _discoverySortable.queryParams,
      beforeModel: function beforeModel() {
        this.controllerFor("navigation/default").set("filterType", filter.split("/")[0]);
      },
      model: function model(data, transition) {
        // attempt to stop early cause we need this to be called before .sync
        this.screenTrack.stop();
        var findOpts = filterQueryParams(data),
            findExtras = {
          cached: this.isPoppedState(transition)
        };
        return findTopicList(this.store, this.topicTrackingState, filter, findOpts, findExtras);
      },
      titleToken: function titleToken() {
        if (filter === (0, _utilities.defaultHomepage)()) {
          return;
        }

        var filterText = _I18n.default.t("filters." + filter.replace("/", ".") + ".title");

        return _I18n.default.t("filters.with_topics", {
          filter: filterText
        });
      },
      setupController: function setupController(controller, model) {
        var topicOpts = {
          model: model,
          category: null,
          period: model.get("for_period") || model.get("params.period"),
          selected: [],
          expandAllPinned: false,
          expandGloballyPinned: true
        };
        this.controllerFor("discovery/topics").setProperties(topicOpts);
        this.controllerFor("navigation/default").set("canCreateTopic", model.get("can_create_topic"));
      },
      renderTemplate: function renderTemplate() {
        this.render("navigation/default", {
          outlet: "navigation-bar"
        });
        this.render("discovery/topics", {
          controller: "discovery/topics",
          outlet: "list-container"
        });
      },
      changeSort: function changeSort(sortBy) {
        _discoverySortable.changeSort.call(this, sortBy);
      },
      resetParams: function resetParams() {
        var skipParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        _discoverySortable.resetParams.call(this, skipParams);
      }
    }, (_applyDecoratedDescriptor(_obj, "changeSort", [_dec], Object.getOwnPropertyDescriptor(_obj, "changeSort"), _obj), _applyDecoratedDescriptor(_obj, "resetParams", [_dec2], Object.getOwnPropertyDescriptor(_obj, "resetParams"), _obj)), _obj)), extras);
  }
});