define("discourse/initializers/localization", ["exports", "I18n", "bootbox"], function (_exports, _I18n, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = {
    name: "localization",
    after: "inject-objects",
    isVerboseLocalizationEnabled: function isVerboseLocalizationEnabled(container) {
      var siteSettings = container.lookup("site-settings:main");

      if (siteSettings.verbose_localization) {
        return true;
      }

      try {
        return sessionStorage && sessionStorage.getItem("verbose_localization");
      } catch (e) {
        return false;
      }
    },
    initialize: function initialize(container) {
      if (this.isVerboseLocalizationEnabled(container)) {
        _I18n.default.enableVerboseLocalization();
      } // Merge any overrides into our object


      for (var _i = 0, _Object$entries = Object.entries(_I18n.default._overrides || {}); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            locale = _Object$entries$_i[0],
            overrides = _Object$entries$_i[1];

        for (var _i2 = 0, _Object$entries2 = Object.entries(overrides); _i2 < _Object$entries2.length; _i2++) {
          var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 2),
              key = _Object$entries2$_i[0],
              value = _Object$entries2$_i[1];

          var segs = key.replace(/^admin_js\./, "js.admin.").split(".");
          var node = _I18n.default.translations[locale] || {};

          for (var i = 0; i < segs.length - 1; i++) {
            if (!(segs[i] in node)) {
              node[segs[i]] = {};
            }

            node = node[segs[i]];
          }

          if (_typeof(node) === "object") {
            node[segs[segs.length - 1]] = value;
          }
        }
      }

      for (var _i3 = 0, _Object$entries3 = Object.entries(_I18n.default._mfOverrides || {}); _i3 < _Object$entries3.length; _i3++) {
        var _Object$entries3$_i = _slicedToArray(_Object$entries3[_i3], 2),
            _key = _Object$entries3$_i[0],
            _value = _Object$entries3$_i[1];

        _key = _key.replace(/^[a-z_]*js\./, "");
        _I18n.default._compiledMFs[_key] = _value;
      }

      _bootbox.default.addLocale(_I18n.default.currentLocale(), {
        OK: _I18n.default.t("composer.modal_ok"),
        CANCEL: _I18n.default.t("composer.modal_cancel"),
        CONFIRM: _I18n.default.t("composer.modal_ok")
      });

      _bootbox.default.setLocale(_I18n.default.currentLocale());
    }
  };
  _exports.default = _default;
});