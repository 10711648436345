define("discourse/mixins/composer-upload-uppy", ["exports", "discourse/mixins/extendable-uploader", "discourse/mixins/uppy-s3-multipart", "discourse-common/lib/object", "discourse/lib/uppy-checksum-plugin", "@uppy/core", "@uppy/drop-target", "@uppy/xhr-upload", "I18n", "discourse-common/lib/get-url", "discourse/lib/utilities", "discourse-common/utils/decorators", "discourse/lib/uploads", "pretty-text/upload-short-url", "bootbox"], function (_exports, _extendableUploader, _uppyS3Multipart, _object, _uppyChecksumPlugin, _core, _dropTarget, _xhrUpload, _I18n, _getUrl, _utilities, _decorators, _uploads, _uploadShortUrl, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // Note: This mixin is used _in addition_ to the ComposerUpload mixin
  // on the composer-editor component. It overrides some, but not all,
  // functions created by ComposerUpload. Eventually this will supplant
  // ComposerUpload, but until then only the functions that need to be
  // overridden to use uppy will be overridden, so as to not go out of
  // sync with the main ComposerUpload functionality by copying unchanging
  // functions.
  //
  // Some examples are uploadPlaceholder, the main properties e.g. uploadProgress,
  // and the most important _bindUploadTarget which handles all the main upload
  // functionality and event binding.
  //
  var _default = Ember.Mixin.create(_extendableUploader.default, _uppyS3Multipart.default, (_dec = (0, _decorators.observes)("composerModel.uploadCancelled"), _dec2 = (0, _decorators.on)("willDestroyElement"), (_obj = {
    uploadRootPath: "/uploads",
    uploadTargetBound: false,
    _cancelSingleUpload: function _cancelSingleUpload(data) {
      this._uppyInstance.removeFile(data.fileId);
    },
    _cancelUpload: function _cancelUpload() {
      if (!this.get("composerModel.uploadCancelled")) {
        return;
      }

      this.set("composerModel.uploadCancelled", false);
      this.set("userCancelled", true);

      this._uppyInstance.cancelAll();
    },
    _unbindUploadTarget: function _unbindUploadTarget() {
      var _this$fileInputEl, _this$editorEl;

      if (!this.uploadTargetBound) {
        return;
      }

      (_this$fileInputEl = this.fileInputEl) === null || _this$fileInputEl === void 0 ? void 0 : _this$fileInputEl.removeEventListener("change", this.fileInputEventListener);
      (_this$editorEl = this.editorEl) === null || _this$editorEl === void 0 ? void 0 : _this$editorEl.removeEventListener("paste", this.pasteEventListener);
      this.appEvents.off("".concat(this.eventPrefix, ":add-files"), this._addFiles);
      this.appEvents.off("".concat(this.eventPrefix, ":cancel-upload"), this._cancelSingleUpload);

      this._reset();

      if (this._uppyInstance) {
        this._uppyInstance.close();

        this._uppyInstance = null;
      }

      this.uploadTargetBound = false;
    },
    _abortAndReset: function _abortAndReset() {
      this.appEvents.trigger("".concat(this.eventPrefix, ":uploads-aborted"));

      this._reset();

      return false;
    },
    _bindUploadTarget: function _bindUploadTarget() {
      var _this = this;

      this.set("inProgressUploads", []);
      this.placeholders = {};
      this._preProcessorStatus = {};
      this.editorEl = this.element.querySelector(this.editorClass);
      this.fileInputEl = document.getElementById(this.fileUploadElementId);
      var isPrivateMessage = this.get("composerModel.privateMessage");
      this.appEvents.on("".concat(this.eventPrefix, ":add-files"), this._addFiles);
      this.appEvents.on("".concat(this.eventPrefix, ":cancel-upload"), this._cancelSingleUpload);

      this._unbindUploadTarget();

      this.fileInputEventListener = (0, _uploads.bindFileInputChangeListener)(this.fileInputEl, this._addFiles);
      this.editorEl.addEventListener("paste", this.pasteEventListener);
      this._uppyInstance = new _core.default({
        id: this.uppyId,
        autoProceed: true,
        // need to use upload_type because uppy overrides type with the
        // actual file type
        meta: (0, _object.deepMerge)({
          upload_type: this.uploadType
        }, this.data || {}),
        onBeforeFileAdded: function onBeforeFileAdded(currentFile) {
          var validationOpts = {
            user: _this.currentUser,
            siteSettings: _this.siteSettings,
            isPrivateMessage: isPrivateMessage,
            allowStaffToUploadAnyFileInPm: _this.siteSettings.allow_staff_to_upload_any_file_in_pm
          };
          var isUploading = (0, _uploads.validateUploadedFile)(currentFile, validationOpts);

          _this.setProperties({
            uploadProgress: 0,
            isUploading: isUploading,
            isCancellable: isUploading
          });

          if (!isUploading) {
            _this.appEvents.trigger("".concat(_this.eventPrefix, ":uploads-aborted"));
          }

          return isUploading;
        },
        onBeforeUpload: function onBeforeUpload(files) {
          var maxFiles = _this.siteSettings.simultaneous_uploads; // Look for a matching file upload handler contributed from a plugin.
          // In future we may want to devise a nicer way of doing this.
          // Uppy plugins are out of the question because there is no way to
          // define which uploader plugin handles which file extensions at this time.

          var unhandledFiles = {};
          var handlerBuckets = {};

          for (var _i = 0, _Object$entries = Object.entries(files); _i < _Object$entries.length; _i++) {
            var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
                fileId = _Object$entries$_i[0],
                file = _Object$entries$_i[1];

            var matchingHandler = _this._findMatchingUploadHandler(file.name);

            if (matchingHandler) {
              // the function signature will be converted to a string for the
              // object key, so we can send multiple files at once to each handler
              if (handlerBuckets[matchingHandler.method]) {
                handlerBuckets[matchingHandler.method].files.push(file);
              } else {
                handlerBuckets[matchingHandler.method] = {
                  fn: matchingHandler.method,
                  // file.data is the native File object, which is all the plugins
                  // should need, not the uppy wrapper
                  files: [file.data]
                };
              }
            } else {
              unhandledFiles[fileId] = _objectSpread({}, files[fileId]);
            }
          } // Send the collected array of files to each matching handler,
          // rather than the old jQuery file uploader method of sending
          // a single file at a time through to the handler.


          for (var _i2 = 0, _Object$values = Object.values(handlerBuckets); _i2 < _Object$values.length; _i2++) {
            var bucket = _Object$values[_i2];

            if (!bucket.fn(bucket.files, _this)) {
              return _this._abortAndReset();
            }
          } // Limit the number of simultaneous uploads, for files which have
          // _not_ been handled by an upload handler.


          var fileCount = Object.keys(unhandledFiles).length;

          if (maxFiles > 0 && fileCount > maxFiles) {
            _bootbox.default.alert(_I18n.default.t("post.errors.too_many_dragged_and_dropped_files", {
              count: maxFiles
            }));

            return _this._abortAndReset();
          } // uppy uses this new object to track progress of remaining files


          return unhandledFiles;
        }
      });

      if (this.siteSettings.enable_upload_debug_mode) {
        this._instrumentUploadTimings();
      }

      if (this.siteSettings.enable_direct_s3_uploads) {
        this._useS3MultipartUploads();
      } else {
        this._useXHRUploads();
      }

      this._uppyInstance.on("file-added", function (file) {
        Ember.run(function () {
          if (isPrivateMessage) {
            file.meta.for_private_message = true;
          }
        });
      });

      this._uppyInstance.on("progress", function (progress) {
        Ember.run(function () {
          if (_this.isDestroying || _this.isDestroyed) {
            return;
          }

          _this.set("uploadProgress", progress);
        });
      });

      this._uppyInstance.on("file-removed", function (file, reason) {
        Ember.run(function () {
          // we handle the cancel-all event specifically, so no need
          // to do anything here. this event is also fired when some files
          // are handled by an upload handler
          if (reason === "cancel-all") {
            return;
          }

          file.meta.cancelled = true;

          _this._removeInProgressUpload(file.id);

          _this._resetUpload(file, {
            removePlaceholder: true
          });

          if (_this.inProgressUploads.length === 0) {
            _this.set("userCancelled", true);

            _this._uppyInstance.cancelAll();
          }
        });
      });

      this._uppyInstance.on("upload-progress", function (file, progress) {
        Ember.run(function () {
          if (_this.isDestroying || _this.isDestroyed) {
            return;
          }

          var upload = _this.inProgressUploads.find(function (upl) {
            return upl.id === file.id;
          });

          if (upload) {
            var percentage = Math.round(progress.bytesUploaded / progress.bytesTotal * 100);
            upload.set("progress", percentage);
          }
        });
      });

      this._uppyInstance.on("upload", function (data) {
        Ember.run(function () {
          _this._addNeedProcessing(data.fileIDs.length);

          var files = data.fileIDs.map(function (fileId) {
            return _this._uppyInstance.getFile(fileId);
          });

          _this.setProperties({
            isProcessingUpload: true,
            isCancellable: false
          });

          files.forEach(function (file) {
            // The inProgressUploads is meant to be used to display these uploads
            // in a UI, and Ember will only update the array in the UI if pushObject
            // is used to notify it.
            _this.inProgressUploads.pushObject(Ember.Object.create({
              fileName: file.name,
              id: file.id,
              progress: 0,
              extension: file.extension
            }));

            var placeholder = _this._uploadPlaceholder(file);

            _this.placeholders[file.id] = {
              uploadPlaceholder: placeholder
            };

            _this.appEvents.trigger("".concat(_this.eventPrefix, ":insert-text"), placeholder);

            _this.appEvents.trigger("".concat(_this.eventPrefix, ":upload-started"), file.name);
          });
        });
      });

      this._uppyInstance.on("upload-success", function (file, response) {
        Ember.run(function () {
          if (!_this._uppyInstance) {
            return;
          }

          _this._removeInProgressUpload(file.id);

          var upload = response.body;

          var markdown = _this.uploadMarkdownResolvers.reduce(function (md, resolver) {
            return resolver(upload) || md;
          }, (0, _uploads.getUploadMarkdown)(upload));

          (0, _uploadShortUrl.cacheShortUploadUrl)(upload.short_url, upload);

          _this.appEvents.trigger("".concat(_this.eventPrefix, ":replace-text"), _this.placeholders[file.id].uploadPlaceholder.trim(), markdown);

          _this._resetUpload(file, {
            removePlaceholder: false
          });

          _this.appEvents.trigger("".concat(_this.eventPrefix, ":upload-success"), file.name, upload);
        });
      });

      this._uppyInstance.on("upload-error", this._handleUploadError);

      this._uppyInstance.on("complete", function () {
        Ember.run(function () {
          _this.appEvents.trigger("".concat(_this.eventPrefix, ":all-uploads-complete"));

          _this._reset();
        });
      });

      this._uppyInstance.on("cancel-all", function () {
        // uppyInstance.reset() also fires cancel-all, so we want to
        // only do the manual cancelling work if the user clicked cancel
        if (_this.userCancelled) {
          Object.values(_this.placeholders).forEach(function (data) {
            Ember.run(function () {
              _this.appEvents.trigger("".concat(_this.eventPrefix, ":replace-text"), data.uploadPlaceholder, "");
            });
          });

          _this.set("userCancelled", false);

          _this._reset();

          _this.appEvents.trigger("".concat(_this.eventPrefix, ":uploads-cancelled"));
        }
      });

      this._setupPreProcessors();

      this._setupUIPlugins();

      this.uploadTargetBound = true;

      this._uppyReady();
    },
    // This should be overridden in a child component if you need to
    // hook into uppy events and be sure that everything is already
    // set up for _uppyInstance.
    _uppyReady: function _uppyReady() {},
    _handleUploadError: function _handleUploadError(file, error, response) {
      this._removeInProgressUpload(file.id);

      this._resetUpload(file, {
        removePlaceholder: true
      });

      file.meta.error = error;

      if (!this.userCancelled) {
        (0, _uploads.displayErrorForUpload)(response || error, this.siteSettings, file.name);
        this.appEvents.trigger("".concat(this.eventPrefix, ":upload-error"), file);
      }

      if (this.inProgressUploads.length === 0) {
        this._reset();
      }
    },
    _removeInProgressUpload: function _removeInProgressUpload(fileId) {
      this.set("inProgressUploads", this.inProgressUploads.filter(function (upl) {
        return upl.id !== fileId;
      }));
    },
    _setupPreProcessors: function _setupPreProcessors() {
      var _this2 = this;

      var checksumPreProcessor = {
        pluginClass: _uppyChecksumPlugin.default,
        optionsResolverFn: function optionsResolverFn(_ref) {
          var capabilities = _ref.capabilities;
          return {
            capabilities: capabilities
          };
        }
      }; // It is important that the UppyChecksum preprocessor is the last one to
      // be added; the preprocessors are run in order and since other preprocessors
      // may modify the file (e.g. the UppyMediaOptimization one), we need to
      // checksum once we are sure the file data has "settled".

      [this.uploadPreProcessors, checksumPreProcessor].flat().forEach(function (_ref2) {
        var pluginClass = _ref2.pluginClass,
            optionsResolverFn = _ref2.optionsResolverFn;

        _this2._useUploadPlugin(pluginClass, optionsResolverFn({
          composerModel: _this2.composerModel,
          composerElement: _this2.composerElement,
          capabilities: _this2.capabilities,
          isMobileDevice: _this2.site.isMobileDevice
        }));
      });

      this._onPreProcessProgress(function (file) {
        var placeholderData = _this2.placeholders[file.id];
        placeholderData.processingPlaceholder = "[".concat(_I18n.default.t("processing_filename", {
          filename: file.name
        }), "]()\n");

        _this2.appEvents.trigger("".concat(_this2.eventPrefix, ":replace-text"), placeholderData.uploadPlaceholder, placeholderData.processingPlaceholder);
      });

      this._onPreProcessComplete(function (file) {
        Ember.run(function () {
          var placeholderData = _this2.placeholders[file.id];

          _this2.appEvents.trigger("".concat(_this2.eventPrefix, ":replace-text"), placeholderData.processingPlaceholder, placeholderData.uploadPlaceholder);
        });
      }, function () {
        Ember.run(function () {
          _this2.setProperties({
            isProcessingUpload: false,
            isCancellable: true
          });

          _this2.appEvents.trigger("".concat(_this2.eventPrefix, ":uploads-preprocessing-complete"));
        });
      });
    },
    _setupUIPlugins: function _setupUIPlugins() {
      this._uppyInstance.use(_dropTarget.default, this._uploadDropTargetOptions());
    },
    _uploadFilenamePlaceholder: function _uploadFilenamePlaceholder(file) {
      var filename = this._filenamePlaceholder(file); // when adding two separate files with the same filename search for matching
      // placeholder already existing in the editor ie [Uploading: test.png...]
      // and add order nr to the next one: [Uploading: test.png(1)...]


      var escapedFilename = filename.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      var regexString = "\\[".concat(_I18n.default.t("uploading_filename", {
        filename: escapedFilename + "(?:\\()?([0-9])?(?:\\))?"
      }), "\\]\\(\\)");
      var globalRegex = new RegExp(regexString, "g");
      var matchingPlaceholder = this.get("composerModel.".concat(this.composerModelContentKey)).match(globalRegex);

      if (matchingPlaceholder) {
        // get last matching placeholder and its consecutive nr in regex
        // capturing group and apply +1 to the placeholder
        var lastMatch = matchingPlaceholder[matchingPlaceholder.length - 1];
        var regex = new RegExp(regexString);
        var orderNr = regex.exec(lastMatch)[1] ? parseInt(regex.exec(lastMatch)[1], 10) + 1 : 1;
        return "".concat(filename, "(").concat(orderNr, ")");
      }

      return filename;
    },
    _uploadPlaceholder: function _uploadPlaceholder(file) {
      var clipboard = _I18n.default.t("clipboard");

      var uploadFilenamePlaceholder = this._uploadFilenamePlaceholder(file);

      var filename = uploadFilenamePlaceholder ? uploadFilenamePlaceholder : clipboard;
      var placeholder = "[".concat(_I18n.default.t("uploading_filename", {
        filename: filename
      }), "]()\n");

      if (!this._cursorIsOnEmptyLine()) {
        placeholder = "\n".concat(placeholder);
      }

      return placeholder;
    },
    _useXHRUploads: function _useXHRUploads() {
      this._uppyInstance.use(_xhrUpload.default, {
        endpoint: (0, _getUrl.default)("/uploads.json?client_id=".concat(this.messageBus.clientId)),
        headers: {
          "X-CSRF-Token": this.session.csrfToken
        }
      });
    },
    _reset: function _reset() {
      var _this$_uppyInstance;

      (_this$_uppyInstance = this._uppyInstance) === null || _this$_uppyInstance === void 0 ? void 0 : _this$_uppyInstance.reset();
      this.setProperties({
        uploadProgress: 0,
        isUploading: false,
        isProcessingUpload: false,
        isCancellable: false
      });

      this._resetPreProcessors();

      this.fileInputEl.value = "";
    },
    _resetUpload: function _resetUpload(file, opts) {
      if (opts.removePlaceholder) {
        this.appEvents.trigger("".concat(this.eventPrefix, ":replace-text"), this.placeholders[file.id].uploadPlaceholder, "");
      }
    },
    pasteEventListener: function pasteEventListener(event) {
      if (document.activeElement !== document.querySelector(this.editorInputClass)) {
        return;
      }

      var _clipboardHelpers = (0, _utilities.clipboardHelpers)(event, {
        siteSettings: this.siteSettings,
        canUpload: true
      }),
          canUpload = _clipboardHelpers.canUpload,
          canPasteHtml = _clipboardHelpers.canPasteHtml,
          types = _clipboardHelpers.types;

      if (!canUpload || canPasteHtml || types.includes("text/plain")) {
        return;
      }

      if (event && event.clipboardData && event.clipboardData.files) {
        this._addFiles(_toConsumableArray(event.clipboardData.files), {
          pasted: true
        });
      }
    },
    _addFiles: function _addFiles(files) {
      var _this3 = this;

      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      files = Array.isArray(files) ? files : [files];

      try {
        this._uppyInstance.addFiles(files.map(function (file) {
          return {
            source: _this3.uppyId,
            name: file.name,
            type: file.type,
            data: file,
            meta: {
              pasted: opts.pasted
            }
          };
        }));
      } catch (err) {
        (false && Ember.warn("error adding files to uppy: ".concat(err), {
          id: "discourse.upload.uppy-add-files-error"
        }));
      }
    },
    showUploadSelector: function showUploadSelector(toolbarEvent) {
      this.send("showUploadSelector", toolbarEvent);
    },
    _bindMobileUploadButton: function _bindMobileUploadButton() {
      var _this4 = this;

      if (this.site.mobileView) {
        this.mobileUploadButton = document.getElementById(this.mobileFileUploaderId);

        this.mobileUploadButtonEventListener = function () {
          document.getElementById(_this4.fileUploadElementId).click();
        };

        this.mobileUploadButton.addEventListener("click", this.mobileUploadButtonEventListener, false);
      }
    },
    _unbindMobileUploadButton: function _unbindMobileUploadButton() {
      var _this$mobileUploadBut;

      (_this$mobileUploadBut = this.mobileUploadButton) === null || _this$mobileUploadBut === void 0 ? void 0 : _this$mobileUploadBut.removeEventListener("click", this.mobileUploadButtonEventListener);
    },
    _filenamePlaceholder: function _filenamePlaceholder(data) {
      return data.name.replace(/\u200B-\u200D\uFEFF]/g, "");
    },
    _resetUploadFilenamePlaceholder: function _resetUploadFilenamePlaceholder() {
      this.set("uploadFilenamePlaceholder", null);
    },
    // target must be provided as a DOM element, however the
    // onDragOver and onDragLeave callbacks can also be provided.
    // it is advisable to debounce/add a setTimeout timer when
    // doing anything in these callbacks to avoid jumping. uppy
    // also adds a .uppy-is-drag-over class to the target element by
    // default onDragOver and removes it onDragLeave
    _uploadDropTargetOptions: function _uploadDropTargetOptions() {
      return {
        target: this.element
      };
    }
  }, (_applyDecoratedDescriptor(_obj, "_cancelSingleUpload", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_cancelSingleUpload"), _obj), _applyDecoratedDescriptor(_obj, "_cancelUpload", [_dec], Object.getOwnPropertyDescriptor(_obj, "_cancelUpload"), _obj), _applyDecoratedDescriptor(_obj, "_unbindUploadTarget", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_unbindUploadTarget"), _obj), _applyDecoratedDescriptor(_obj, "_handleUploadError", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_handleUploadError"), _obj), _applyDecoratedDescriptor(_obj, "pasteEventListener", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "pasteEventListener"), _obj), _applyDecoratedDescriptor(_obj, "_addFiles", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_addFiles"), _obj)), _obj)));

  _exports.default = _default;
});