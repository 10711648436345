define("discourse/components/add-topic-status-classes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.refreshClass();
    },
    _updateClass: function _updateClass() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      var body = document.getElementsByTagName("body")[0];

      this._removeClass();

      if (this.topic.invisible) {
        body.classList.add("topic-status-unlisted");
      }

      if (this.topic.pinned) {
        body.classList.add("topic-status-pinned");
      }

      if (this.topic.unpinned) {
        body.classList.add("topic-status-unpinned");
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.refreshClass();
    },
    refreshClass: function refreshClass() {
      Ember.run.scheduleOnce("afterRender", this, this._updateClass);
    },
    _removeClass: function _removeClass() {
      var regx = new RegExp(/\btopic-status-\S+/, "g");
      var body = document.getElementsByTagName("body")[0];
      body.className = body.className.replace(regx, "");
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._removeClass();
    }
  });

  _exports.default = _default;
});