define("discourse/templates/components/reviewable-bundled-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Fzq44iA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"multiple\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"dropdown-select-box\",null,[[\"class\",\"nameProperty\",\"title\",\"content\",\"onChange\",\"options\"],[\"reviewable-action-dropdown\",\"label\",[24,[\"bundle\",\"label\"]],[24,[\"bundle\",\"actions\"]],[28,\"action\",[[23,0,[]],\"performById\"],null],[28,\"hash\",null,[[\"icon\",\"disabled\",\"placement\"],[[24,[\"bundle\",\"icon\"]],[24,[\"reviewableUpdating\"]],[24,[\"placement\"]]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"d-button\",null,[[\"class\",\"icon\",\"action\",\"translatedLabel\",\"disabled\"],[[28,\"concat\",[\"reviewable-action \",[28,\"dasherize\",[[24,[\"first\",\"id\"]]],null],\" \",[24,[\"first\",\"button_class\"]]],null],[24,[\"first\",\"icon\"]],[28,\"action\",[[23,0,[]],\"perform\",[24,[\"first\"]]],null],[24,[\"first\",\"label\"]],[24,[\"reviewableUpdating\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-bundled-action.hbs"
    }
  });

  _exports.default = _default;
});