define("discourse/components/email-dropdown", ["exports", "select-kit/components/dropdown-select-box", "I18n"], function (_exports, _dropdownSelectBox, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _dropdownSelectBox.default.extend((_dec = Ember._action, (_obj = {
    router: Ember.inject.service(),
    classNames: ["email-dropdown"],
    selectKitOptions: {
      icon: "wrench",
      showFullTitle: false
    },
    content: Ember.computed("email", function () {
      var content = [];

      if (this.email.primary) {
        content.push({
          id: "updateEmail",
          icon: "pencil-alt",
          name: _I18n.default.t("user.email.update_email"),
          description: ""
        });
      }

      if (!this.email.primary && this.email.confirmed) {
        content.push({
          id: "setPrimaryEmail",
          icon: "star",
          name: _I18n.default.t("user.email.set_primary"),
          description: ""
        });
      }

      if (!this.email.primary) {
        content.push({
          id: "destroyEmail",
          icon: "times",
          name: _I18n.default.t("user.email.destroy"),
          description: ""
        });
      }

      return content;
    }),
    onChange: function onChange(id) {
      switch (id) {
        case "updateEmail":
          this.router.transitionTo("preferences.email");
          break;

        case "setPrimaryEmail":
          this.setPrimaryEmail(this.email.email);
          break;

        case "destroyEmail":
          this.destroyEmail(this.email.email);
          break;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "onChange", [_dec], Object.getOwnPropertyDescriptor(_obj, "onChange"), _obj)), _obj)));

  _exports.default = _default;
});