define("discourse/templates/components/global-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5u+gnc/4",
    "block": "{\"symbols\":[\"notice\"],\"statements\":[[4,\"each\",[[24,[\"notices\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"id\",[29,[\"global-notice-\",[23,1,[\"id\"]]]]],[11,\"class\",[29,[\"alert alert-\",[23,1,[\"options\",\"level\"]],\" \",[23,1,[\"id\"]]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"options\",\"html\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"html-safe\",[[23,1,[\"options\",\"html\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"span\",true],[10,\"class\",\"text\"],[8],[1,[28,\"html-safe\",[[23,1,[\"text\"]]],null],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"options\",\"dismissable\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"d-button\",null,[[\"class\",\"icon\",\"action\",\"actionParam\"],[\"btn-flat close\",\"times\",[28,\"action\",[[23,0,[]],\"dismissNotice\"],null],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/global-notice.hbs"
    }
  });

  _exports.default = _default;
});