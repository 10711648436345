define("discourse/controllers/dismiss-notification-confirmation", ["exports", "discourse/mixins/modal-functionality"], function (_exports, _modalFunctionality) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    actions: {
      dismiss: function dismiss() {
        this.send("closeModal");
        this.dismissNotifications();
      }
    }
  });

  _exports.default = _default;
});