define("discourse/controllers/tag-show", ["exports", "discourse-common/utils/decorators", "discourse/mixins/bulk-topic-selection", "discourse/mixins/filter-mode", "I18n", "discourse/models/nav-item", "discourse/models/topic", "bootbox", "discourse/controllers/discovery-sortable", "discourse/lib/computed"], function (_exports, _decorators, _bulkTopicSelection, _filterMode, _I18n, _navItem, _topic, _bootbox, _discoverySortable, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_bulkTopicSelection.default, _filterMode.default, (_dec = (0, _decorators.default)("canCreateTopic", "category", "canCreateTopicOnCategory", "tag", "canCreateTopicOnTag"), _dec2 = (0, _decorators.default)("category", "tag.id", "filterType", "noSubcategories"), _dec3 = (0, _decorators.default)("category"), _dec4 = (0, _decorators.observes)("list.canLoadMore"), _dec5 = (0, _decorators.default)("navMode", "list.topics.length", "loading"), _dec6 = (0, _decorators.default)("list.filter", "list.topics.length"), _dec7 = (0, _decorators.default)("list.filter", "list.topics.length"), (_obj = {
    application: Ember.inject.controller(),
    tag: null,
    additionalTags: null,
    list: null,
    canAdminTag: Ember.computed.alias("currentUser.staff"),
    navMode: "latest",
    loading: false,
    canCreateTopic: false,
    order: "default",
    ascending: false,
    status: null,
    state: null,
    search: null,
    max_posts: null,
    q: null,
    showInfo: false,
    top: (0, _computed.endWith)("list.filter", "top"),
    period: Ember.computed.alias("list.for_period"),
    createTopicDisabled: function createTopicDisabled(canCreateTopic, category, canCreateTopicOnCategory, tag, canCreateTopicOnTag) {
      return !canCreateTopic || category && !canCreateTopicOnCategory || tag && !canCreateTopicOnTag;
    },
    queryParams: Object.keys(_discoverySortable.queryParams),
    navItems: function navItems(category, tagId, filterType, noSubcategories) {
      return _navItem.default.buildList(category, {
        tagId: tagId,
        filterType: filterType,
        noSubcategories: noSubcategories,
        siteSettings: this.siteSettings
      });
    },
    showTagFilter: function showTagFilter() {
      return true;
    },
    loadMoreTopics: function loadMoreTopics() {
      return this.list.loadMore();
    },
    _showFooter: function _showFooter() {
      this.set("application.showFooter", !this.get("list.canLoadMore"));
    },
    footerMessage: function footerMessage(navMode, listTopicsLength, loading) {
      if (loading) {
        return;
      }

      if (listTopicsLength === 0) {
        return _I18n.default.t("tagging.topics.none.".concat(navMode), {
          tag: this.get("tag.id")
        });
      } else {
        return _I18n.default.t("topics.bottom.tag", {
          tag: this.get("tag.id")
        });
      }
    },
    showDismissRead: function showDismissRead(filter, topicsLength) {
      return this._isFilterPage(filter, "unread") && topicsLength > 0;
    },
    showResetNew: function showResetNew(filter, topicsLength) {
      return this._isFilterPage(filter, "new") && topicsLength > 0;
    },
    actions: {
      resetNew: function resetNew() {
        var _this = this;

        var tracked = (this.router.currentRoute.queryParams["f"] || this.router.currentRoute.queryParams["filter"]) === "tracked";
        var topicIds = this.selected ? this.selected.map(function (topic) {
          return topic.id;
        }) : null;

        _topic.default.resetNew(this.category, !this.noSubcategories, {
          tracked: tracked,
          tag: this.tag,
          topicIds: topicIds
        }).then(function () {
          return _this.send("refresh", tracked ? {
            skipResettingParams: ["filter", "f"]
          } : {});
        });
      },
      showInserted: function showInserted() {
        var tracker = this.topicTrackingState;
        this.list.loadBefore(tracker.get("newIncoming"), true);
        tracker.resetTracking();
        return false;
      },
      changeSort: function changeSort(order) {
        if (order === this.order) {
          this.toggleProperty("ascending");
        } else {
          this.setProperties({
            order: order,
            ascending: false
          });
        }

        var params = {
          order: order,
          ascending: this.ascending
        };

        if (this.period) {
          params.period = this.period;
        }

        this.transitionToRoute({
          queryParams: params
        });
      },
      changePeriod: function changePeriod(p) {
        this.set("period", p);
        var params = {
          period: this.period
        };

        if (!(this.order === "default" && this.ascending === false)) {
          params = Object.assign(params, {
            order: this.order,
            ascending: this.ascending
          });
        }

        this.transitionToRoute({
          queryParams: params
        });
      },
      toggleInfo: function toggleInfo() {
        this.toggleProperty("showInfo");
      },
      refresh: function refresh() {
        var _this2 = this;

        return this.store.findFiltered("topicList", {
          filter: this.get("list.filter")
        }).then(function (list) {
          _this2.set("list", list);

          _this2.resetSelected();
        });
      },
      deleteTag: function deleteTag(tagInfo) {
        var _this3 = this;

        var numTopics = this.get("list.topic_list.tags.firstObject.topic_count") || 0;
        var confirmText = numTopics === 0 ? _I18n.default.t("tagging.delete_confirm_no_topics") : _I18n.default.t("tagging.delete_confirm", {
          count: numTopics
        });

        if (tagInfo.synonyms.length > 0) {
          confirmText += " " + _I18n.default.t("tagging.delete_confirm_synonyms", {
            count: tagInfo.synonyms.length
          });
        }

        _bootbox.default.confirm(confirmText, function (result) {
          if (!result) {
            return;
          }

          _this3.tag.destroyRecord().then(function () {
            return _this3.transitionToRoute("tags.index");
          }).catch(function () {
            return _bootbox.default.alert(_I18n.default.t("generic_error"));
          });
        });
      },
      changeTagNotificationLevel: function changeTagNotificationLevel(notificationLevel) {
        var _this4 = this;

        this.tagNotification.update({
          notification_level: notificationLevel
        }).then(function (response) {
          _this4.currentUser.set("muted_tag_ids", _this4.currentUser.calculateMutedIds(notificationLevel, response.responseJson.tag_id, "muted_tag_ids"));
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "createTopicDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "createTopicDisabled"), _obj), _applyDecoratedDescriptor(_obj, "navItems", [_dec2], Object.getOwnPropertyDescriptor(_obj, "navItems"), _obj), _applyDecoratedDescriptor(_obj, "showTagFilter", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showTagFilter"), _obj), _applyDecoratedDescriptor(_obj, "_showFooter", [_dec4], Object.getOwnPropertyDescriptor(_obj, "_showFooter"), _obj), _applyDecoratedDescriptor(_obj, "footerMessage", [_dec5], Object.getOwnPropertyDescriptor(_obj, "footerMessage"), _obj), _applyDecoratedDescriptor(_obj, "showDismissRead", [_dec6], Object.getOwnPropertyDescriptor(_obj, "showDismissRead"), _obj), _applyDecoratedDescriptor(_obj, "showResetNew", [_dec7], Object.getOwnPropertyDescriptor(_obj, "showResetNew"), _obj)), _obj)));

  _exports.default = _default;
});