define("discourse/components/flat-button", ["exports", "I18n", "discourse-common/utils/decorators"], function (_exports, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("title"), (_obj = {
    tagName: "button",
    classNames: ["btn-flat"],
    attributeBindings: ["disabled", "translatedTitle:title"],
    translatedTitle: function translatedTitle(title) {
      if (title) {
        return _I18n.default.t(title);
      }
    },
    keyDown: function keyDown(event) {
      if (event.key === "Enter") {
        var _this$action;

        (_this$action = this.action) === null || _this$action === void 0 ? void 0 : _this$action.call(this);
        return false;
      }
    },
    click: function click() {
      var _this$action2;

      (_this$action2 = this.action) === null || _this$action2 === void 0 ? void 0 : _this$action2.call(this);
      return false;
    }
  }, (_applyDecoratedDescriptor(_obj, "translatedTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "translatedTitle"), _obj)), _obj)));

  _exports.default = _default;
});