define("discourse/components/tap-tile", ["exports", "discourse/lib/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set("elementId", "tap_tile_".concat(this.tileId));
    },
    classNames: ["tap-tile"],
    classNameBindings: ["active"],
    attributeBindings: ["role", "ariaPressed", "tabIndex"],
    role: "button",
    tabIndex: 0,
    ariaPressed: Ember.computed.reads("active"),
    click: function click() {
      this.onChange(this.tileId);
    },
    active: (0, _computed.propertyEqual)("activeTile", "tileId")
  });

  _exports.default = _default;
});