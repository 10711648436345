define("discourse/components/user-notification-schedule", ["exports", "I18n", "discourse-common/utils/decorators"], function (_exports, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  var Day = Ember.Object.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = (0, _decorators.default)("model.user_notification_schedule.day_{0,1,2,3,4,5,6}_start_time"), _dec4 = (0, _decorators.default)("model.user_notification_schedule.day_{0,1,2,3,4,5,6}_start_time"), _dec5 = (0, _decorators.default)("model.user_notification_schedule.day_{0,1,2,3,4,5,6}_end_time"), (_obj = {
    id: null,
    startTimeOptions: null,
    model: null,
    onChangeStartTime: function onChangeStartTime(val) {
      this.startingTimeChangedForDay(val);
    },
    onChangeEndTime: function onChangeEndTime(val) {
      this.set("model.user_notification_schedule.day_".concat(this.id, "_end_time"), val);
    },
    startTimeValue: function startTimeValue(schedule) {
      return schedule["day_".concat(this.id, "_start_time")];
    },
    endTimeOptions: function endTimeOptions(schedule) {
      return this.buildEndTimeOptionsFor(schedule["day_".concat(this.id, "_start_time")]);
    },
    endTimeValue: function endTimeValue(schedule) {
      return schedule["day_".concat(this.id, "_end_time")];
    },
    startingTimeChangedForDay: function startingTimeChangedForDay(val) {
      val = parseInt(val, 10);
      this.model.set("user_notification_schedule.day_".concat(this.id, "_start_time"), val);

      if (val !== "-1" && this.model.user_notification_schedule["day_".concat(this.id, "_end_time")] <= val) {
        this.model.set("user_notification_schedule.day_".concat(this.id, "_end_time"), val + 30);
      }
    },
    buildEndTimeOptionsFor: function buildEndTimeOptionsFor(startTime) {
      startTime = parseInt(startTime, 10);

      if (startTime === -1) {
        return null;
      }

      return this.buildTimeOptions(startTime + 30, {
        includeNone: false,
        showMidnight: true
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "onChangeStartTime", [_dec], Object.getOwnPropertyDescriptor(_obj, "onChangeStartTime"), _obj), _applyDecoratedDescriptor(_obj, "onChangeEndTime", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onChangeEndTime"), _obj), _applyDecoratedDescriptor(_obj, "startTimeValue", [_dec3], Object.getOwnPropertyDescriptor(_obj, "startTimeValue"), _obj), _applyDecoratedDescriptor(_obj, "endTimeOptions", [_dec4], Object.getOwnPropertyDescriptor(_obj, "endTimeOptions"), _obj), _applyDecoratedDescriptor(_obj, "endTimeValue", [_dec5], Object.getOwnPropertyDescriptor(_obj, "endTimeValue"), _obj)), _obj)));

  var _default = Ember.Component.extend({
    days: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set("startTimeOptions", this.buildTimeOptions(0, {
        includeNone: true,
        showMidnight: false
      }));
      this.set("days", []);
      DAYS.forEach(function (day, index) {
        _this.days.pushObject(Day.create({
          id: index,
          day: day,
          model: _this.model,
          buildTimeOptions: _this.buildTimeOptions,
          startTimeOptions: _this.startTimeOptions
        }));
      });
    },
    buildTimeOptions: function buildTimeOptions(startAt) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        includeNone: false,
        showMidnight: true
      };
      var timeOptions = [];

      if (opts.includeNone) {
        timeOptions.push({
          name: _I18n.default.t("user.notification_schedule.none"),
          value: -1
        });
      }

      for (var timeInMin = startAt; timeInMin <= 1440; timeInMin += 30) {
        var hours = Math.floor(timeInMin / 60);
        var minutes = timeInMin % 60;

        if (minutes === 0) {
          minutes = "00";
        }

        if (hours === 24) {
          if (opts.showMidnight) {
            timeOptions.push({
              name: _I18n.default.t("user.notification_schedule.midnight"),
              value: 1440
            });
          }

          break;
        }

        timeOptions.push({
          name: moment().set("hour", hours).set("minute", minutes).format("LT"),
          value: timeInMin
        });
      }

      return timeOptions;
    }
  });

  _exports.default = _default;
});