define("discourse/templates/components/about-page-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fdjEMF40",
    "block": "{\"symbols\":[\"userTemplate\"],\"statements\":[[4,\"each\",[[24,[\"usersTemplates\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"data-username\",[23,1,[\"username\"]]],[10,\"class\",\"user-info small\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"user-image\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"user-image-inner\"],[8],[0,\"\\n        \"],[7,\"a\",true],[11,\"href\",[23,1,[\"userPath\"]]],[11,\"data-user-card\",[23,1,[\"username\"]]],[8],[0,\"\\n          \"],[1,[28,\"html-safe\",[[23,1,[\"avatar\"]]],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"user-detail\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"name-line\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"username\"],[8],[0,\"\\n          \"],[7,\"a\",true],[11,\"href\",[23,1,[\"userPath\"]]],[11,\"data-user-card\",[23,1,[\"username\"]]],[8],[0,\"\\n            \"],[4,\"if\",[[23,1,[\"prioritizeName\"]]],null,{\"statements\":[[1,[23,1,[\"name\"]],false]],\"parameters\":[]},{\"statements\":[[1,[23,1,[\"username\"]],false]],\"parameters\":[]}],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"name\"],[8],[0,\"\\n\"],[4,\"unless\",[[23,1,[\"hideName\"]]],null,{\"statements\":[[0,\"            \"],[4,\"if\",[[23,1,[\"prioritizeName\"]]],null,{\"statements\":[[1,[23,1,[\"username\"]],false]],\"parameters\":[]},{\"statements\":[[1,[23,1,[\"name\"]],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/about-page-users.hbs"
    }
  });

  _exports.default = _default;
});