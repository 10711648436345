define("discourse/components/color-picker-choice", ["exports", "I18n", "discourse-common/utils/decorators"], function (_exports, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("color", "usedColors"), _dec2 = (0, _decorators.default)("isUsed"), _dec3 = (0, _decorators.default)("color"), (_obj = {
    tagName: "button",
    attributeBindings: ["style", "title"],
    classNameBindings: [":colorpicker", "isUsed:used-color:unused-color"],
    isUsed: function isUsed(color, usedColors) {
      return (usedColors || []).indexOf(color.toUpperCase()) >= 0;
    },
    title: function title(isUsed) {
      return isUsed ? _I18n.default.t("category.already_used") : null;
    },
    style: function style(color) {
      return "background-color: #".concat(color, ";").htmlSafe();
    },
    click: function click(e) {
      e.preventDefault();
      this.selectColor(this.color);
    }
  }, (_applyDecoratedDescriptor(_obj, "isUsed", [_dec], Object.getOwnPropertyDescriptor(_obj, "isUsed"), _obj), _applyDecoratedDescriptor(_obj, "title", [_dec2], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "style", [_dec3], Object.getOwnPropertyDescriptor(_obj, "style"), _obj)), _obj)));

  _exports.default = _default;
});