define("discourse/models/rest", ["exports", "discourse-common/lib/get-owner"], function (_exports, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RestModel = Ember.Object.extend({
    isNew: Ember.computed.equal("__state", "new"),
    isCreated: Ember.computed.equal("__state", "created"),
    isSaving: false,
    beforeCreate: function beforeCreate() {},
    afterCreate: function afterCreate() {},
    beforeUpdate: function beforeUpdate() {},
    afterUpdate: function afterUpdate() {},
    update: function update(props) {
      var _this = this;

      if (this.isSaving) {
        return Ember.RSVP.Promise.reject();
      }

      props = props || this.updateProperties();
      this.beforeUpdate(props);
      this.set("isSaving", true);
      return this.store.update(this.__type, this.id, props).then(function (res) {
        var payload = _this.__munge(res.payload || res.responseJson);

        if (payload.success === "OK") {
          (false && Ember.warn("An update call should return the updated attributes", {
            id: "discourse.rest-model.update-attributes"
          }));
          res = props;
        }

        _this.setProperties(payload);

        _this.afterUpdate(res);

        res.target = _this;
        return res;
      }).finally(function () {
        return _this.set("isSaving", false);
      });
    },
    _saveNew: function _saveNew(props) {
      var _this2 = this;

      if (this.isSaving) {
        return Ember.RSVP.Promise.reject();
      }

      props = props || this.createProperties();
      this.beforeCreate(props);
      var adapter = this.store.adapterFor(this.__type);
      this.set("isSaving", true);
      return adapter.createRecord(this.store, this.__type, props).then(function (res) {
        if (!res) {
          throw new Error("Received no data back from createRecord");
        } // We can get a response back without properties, for example
        // when a post is queued.


        if (res.payload) {
          _this2.setProperties(_this2.__munge(res.payload));

          _this2.set("__state", "created");
        }

        _this2.afterCreate(res);

        res.target = _this2;
        return res;
      }).finally(function () {
        return _this2.set("isSaving", false);
      });
    },
    createProperties: function createProperties() {
      throw new Error("You must overwrite `createProperties()` before saving a record");
    },
    save: function save(props) {
      return this.isNew ? this._saveNew(props) : this.update(props);
    },
    destroyRecord: function destroyRecord() {
      return this.store.destroyRecord(this.__type, this);
    }
  });
  RestModel.reopenClass({
    // Overwrite and JSON will be passed through here before `create` and `update`
    munge: function munge(json) {
      return json;
    },
    create: function create(args) {
      args = args || {};
      var owner = (0, _getOwner.getOwner)(this); // Some Discourse code calls `model.create()` directly without going through the
      // store. In that case the injections are not made, so we do them here. Eventually
      // we should use the store for everything to fix this.

      if (!args.store) {
        args.store = owner.lookup("service:store");
      }

      if (!args.siteSettings) {
        args.siteSettings = owner.lookup("site-settings:main");
      }

      args.__munge = this.munge;
      return this._super(this.munge(args, args.store));
    }
  });
  var _default = RestModel;
  _exports.default = _default;
});