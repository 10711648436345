define("discourse/templates/components/time-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ObK1hlGv",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"combo-box\",null,[[\"value\",\"content\",\"onChange\",\"options\"],[[24,[\"time\"]],[24,[\"timeOptions\"]],[28,\"action\",[[23,0,[]],\"onChangeTime\"],null],[28,\"hash\",null,[[\"translatedNone\",\"allowAny\",\"filterable\",\"autoInsertNoneItem\",\"translatedFilterPlaceholder\"],[\"--:--\",true,false,false,\"--:--\"]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/time-input.hbs"
    }
  });

  _exports.default = _default;
});