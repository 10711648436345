define("discourse/lib/notification-levels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buttonDetails = buttonDetails;
  _exports.topicLevels = _exports.allLevels = _exports.NotificationLevels = void 0;
  var MUTED = 0;
  var REGULAR = 1;
  var TRACKING = 2;
  var WATCHING = 3;
  var WATCHING_FIRST_POST = 4;
  var NotificationLevels = {
    WATCHING_FIRST_POST: WATCHING_FIRST_POST,
    WATCHING: WATCHING,
    TRACKING: TRACKING,
    REGULAR: REGULAR,
    MUTED: MUTED
  };
  _exports.NotificationLevels = NotificationLevels;

  function buttonDetails(level) {
    switch (level) {
      case WATCHING_FIRST_POST:
        return {
          id: WATCHING_FIRST_POST,
          key: "watching_first_post",
          icon: "d-watching-first"
        };

      case WATCHING:
        return {
          id: WATCHING,
          key: "watching",
          icon: "d-watching"
        };

      case TRACKING:
        return {
          id: TRACKING,
          key: "tracking",
          icon: "d-tracking"
        };

      case MUTED:
        return {
          id: MUTED,
          key: "muted",
          icon: "d-muted"
        };

      default:
        return {
          id: REGULAR,
          key: "regular",
          icon: "d-regular"
        };
    }
  }

  var allLevels = [WATCHING, TRACKING, WATCHING_FIRST_POST, REGULAR, MUTED].map(buttonDetails);
  _exports.allLevels = allLevels;
  var topicLevels = allLevels.filter(function (l) {
    return l.id !== WATCHING_FIRST_POST;
  });
  _exports.topicLevels = topicLevels;
});