define("discourse/components/discourse-banner", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("banner.html"), _dec2 = (0, _decorators.default)("user.dismissed_banner_key", "banner.key", "hide"), (_obj = {
    hide: false,
    content: function content(bannerHtml) {
      var $div = $("<div>");
      $div.append(bannerHtml);
      $div.find("[id^='heading--']").removeAttr("id");
      return $div.html();
    },
    visible: function visible(dismissedBannerKey, bannerKey, hide) {
      dismissedBannerKey = dismissedBannerKey || this.keyValueStore.get("dismissed_banner_key");

      if (bannerKey) {
        bannerKey = parseInt(bannerKey, 10);
      }

      if (dismissedBannerKey) {
        dismissedBannerKey = parseInt(dismissedBannerKey, 10);
      }

      return !hide && bannerKey && dismissedBannerKey !== bannerKey;
    },
    actions: {
      dismiss: function dismiss() {
        if (this.user) {
          this.user.dismissBanner(this.get("banner.key"));
        } else {
          this.set("hide", true);
          this.keyValueStore.set({
            key: "dismissed_banner_key",
            value: this.get("banner.key")
          });
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "content", [_dec], Object.getOwnPropertyDescriptor(_obj, "content"), _obj), _applyDecoratedDescriptor(_obj, "visible", [_dec2], Object.getOwnPropertyDescriptor(_obj, "visible"), _obj)), _obj)));

  _exports.default = _default;
});