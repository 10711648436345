define("discourse/templates/modal/change-owner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XqTpgK/y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"class\"],[\"change-ownership\"]],{\"statements\":[[0,\"  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[1,[28,\"html-safe\",[[28,\"i18n\",[[28,\"if\",[[24,[\"selectedPostsUsername\"]],\"topic.change_owner.instructions\",\"topic.change_owner.instructions_without_old_user\"],null]],[[\"count\",\"old_user\"],[[24,[\"selectedPostsCount\"]],[24,[\"selectedPostsUsername\"]]]]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"form\",true],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[9],[0,\"\\n    \"],[1,[28,\"email-group-user-chooser\",null,[[\"value\",\"autofocus\",\"onChange\",\"options\"],[[24,[\"newOwner\"]],true,[28,\"action\",[[23,0,[]],\"updateNewOwner\"],null],[28,\"hash\",null,[[\"maximum\",\"filterPlaceholder\"],[1,\"topic.change_owner.placeholder\"]]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"class\",\"action\",\"disabled\",\"label\"],[\"btn-primary\",[28,\"action\",[[23,0,[]],\"changeOwnershipOfPosts\"],null],[24,[\"buttonDisabled\"]],[28,\"if\",[[24,[\"saving\"]],\"saving\",\"topic.change_owner.action\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/change-owner.hbs"
    }
  });

  _exports.default = _default;
});