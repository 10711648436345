define("discourse/components/custom-html", ["exports", "discourse/helpers/custom-html", "discourse-common/lib/get-owner"], function (_exports, _customHtml, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    triggerAppEvent: null,
    init: function init() {
      this._super.apply(this, arguments);

      var name = this.name;
      var html = (0, _customHtml.getCustomHTML)(name);

      if (html) {
        this.set("html", html);
        this.set("layoutName", "components/custom-html-container");
      } else {
        var template = (0, _getOwner.getOwner)(this).lookup("template:".concat(name));

        if (template) {
          this.set("layoutName", name);
        }
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.triggerAppEvent === "true") {
        this.appEvents.trigger("inserted-custom-html:".concat(this.name));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.triggerAppEvent === "true") {
        this.appEvents.trigger("destroyed-custom-html:".concat(this.name));
      }
    }
  });

  _exports.default = _default;
});