define("discourse/components/notification-consent-banner", ["exports", "discourse/components/desktop-notification-config", "discourse-common/utils/decorators", "discourse/lib/push-notifications"], function (_exports, _desktopNotificationConfig, _decorators, _pushNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj, _init;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var userDismissedPromptKey = "dismissed-prompt";

  var _default = _desktopNotificationConfig.default.extend((_dec = (0, _decorators.default)("isNotSupported", "isEnabled", "bannerDismissed", "currentUser.any_posts"), (_obj = {
    bannerDismissed: {
      set: function set(value) {
        _pushNotifications.keyValueStore.setItem(userDismissedPromptKey, value);

        return _pushNotifications.keyValueStore.getItem(userDismissedPromptKey);
      },
      get: function get() {
        return _pushNotifications.keyValueStore.getItem(userDismissedPromptKey);
      }
    },
    showNotificationPromptBanner: function showNotificationPromptBanner(isNotSupported, isEnabled, bannerDismissed, anyPosts) {
      return this.siteSettings.push_notifications_prompt && !isNotSupported && this.currentUser && anyPosts && Notification.permission !== "denied" && Notification.permission !== "granted" && !isEnabled && !bannerDismissed;
    },
    actions: {
      turnon: function turnon() {
        this._super.apply(this, arguments);

        this.set("bannerDismissed", true);
      },
      dismiss: function dismiss() {
        this.set("bannerDismissed", true);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "bannerDismissed", [_decorators.default], (_init = Object.getOwnPropertyDescriptor(_obj, "bannerDismissed"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "showNotificationPromptBanner", [_dec], Object.getOwnPropertyDescriptor(_obj, "showNotificationPromptBanner"), _obj)), _obj)));

  _exports.default = _default;
});