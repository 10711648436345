define("discourse/widgets/embedded-post", ["exports", "discourse/widgets/decorator-helper", "discourse/widgets/post-cooked", "discourse/widgets/widget", "virtual-dom"], function (_exports, _decoratorHelper, _postCooked, _widget, _virtualDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  (0, _widget.createWidget)("post-link-arrow", {
    tagName: "div.post-link-arrow",
    template: function template(attrs, state) {
      var __h1 = __widget_helpers.iconNode;
      var _r = [];

      _r.push("\n");

      if (attrs.above) {
        _r.push("      ");

        var _a0 = [];

        _a0.push("\n        ");

        _a0.push(__h1("arrow-up"));

        _a0.push("\n      ");

        _r.push(virtualDom.h('a', {
          "className": "post-info arrow",
          "attributes": {
            "href": attrs.shareUrl,
            "title": I18n.t("topic.jump_reply_up")
          }
        }, _a0));

        _r.push("\n");
      } else {
        _r.push("      ");

        var _a1 = [];

        _a1.push("\n        ");

        _a1.push(__h1("arrow-down"));

        _a1.push("\n      ");

        _r.push(virtualDom.h('a', {
          "className": "post-info arrow",
          "attributes": {
            "href": attrs.shareUrl,
            "title": I18n.t("topic.jump_reply_down")
          }
        }, _a1));

        _r.push("\n");
      }

      _r.push("  ");

      return _r;
    }
  });

  var _default = (0, _widget.createWidget)("embedded-post", {
    buildKey: function buildKey(attrs) {
      return "embedded-post-".concat(attrs.id);
    },
    html: function html(attrs, state) {
      attrs.embeddedPost = true;
      return [(0, _virtualDom.h)("div.reply", {
        attributes: {
          "data-post-id": attrs.id
        }
      }, [(0, _virtualDom.h)("div.row", [this.attach("post-avatar", attrs), (0, _virtualDom.h)("div.topic-body", [(0, _virtualDom.h)("div.topic-meta-data.embedded-reply", [this.attach("poster-name", attrs), this.attach("post-link-arrow", {
        above: state.above,
        shareUrl: attrs.customShare
      })]), new _postCooked.default(attrs, new _decoratorHelper.default(this), this.currentUser)])])])];
    }
  });

  _exports.default = _default;
});