define("discourse/components/categories-boxes-topic", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("topic.pinned", "topic.closed", "topic.archived"), (_obj = {
    tagName: "li",
    attributeBindings: ["topic.id:data-topic-id"],
    topicStatusIcon: function topicStatusIcon(pinned, closed, archived) {
      if (pinned) {
        return "thumbtack";
      }

      if (closed || archived) {
        return "lock";
      }

      return "far-file-alt";
    }
  }, (_applyDecoratedDescriptor(_obj, "topicStatusIcon", [_dec], Object.getOwnPropertyDescriptor(_obj, "topicStatusIcon"), _obj)), _obj)));

  _exports.default = _default;
});