define("discourse/components/d-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextArea.extend({
    attributeBindings: ["aria-label"]
  });

  _exports.default = _default;
});