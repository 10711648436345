define("discourse/components/count-i18n", ["exports", "I18n"], function (_exports, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    rerenderTriggers: ["count", "suffix"],
    i18nCount: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set("i18nCount", _I18n.default.t(this.key + (this.suffix || ""), {
        count: this.count
      }).htmlSafe());
    }
  });

  _exports.default = _default;
});