define("discourse/templates/modal/dismiss-notification-confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cIgWd4PO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"headerClass\",\"class\"],[\"hidden\",\"dismiss-notification-confirmation\"]],{\"statements\":[[0,\"  \"],[1,[28,\"i18n\",[\"notifications.dismiss_confirmation.body\"],[[\"count\"],[[24,[\"count\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"icon\",\"class\",\"action\",\"label\"],[\"check\",\"btn-primary\",[28,\"action\",[[23,0,[]],\"dismiss\"],null],\"notifications.dismiss_confirmation.dismiss\"]]],false],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"label\",\"class\"],[[28,\"route-action\",[\"closeModal\"],null],\"notifications.dismiss_confirmation.cancel\",\"btn-default\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/dismiss-notification-confirmation.hbs"
    }
  });

  _exports.default = _default;
});