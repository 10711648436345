define("discourse/helpers/topic-featured-link", ["exports", "discourse-common/lib/helpers", "discourse/lib/render-topic-featured-link"], function (_exports, _helpers, _renderTopicFeaturedLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helpers.registerUnbound)("topic-featured-link", function (topic, params) {
    return Ember.String.htmlSafe((0, _renderTopicFeaturedLink.default)(topic, params));
  });

  _exports.default = _default;
});