define("discourse/lib/render-topic-featured-link", ["exports", "discourse/models/user", "virtual-dom", "discourse-common/lib/icon-library"], function (_exports, _user, _virtualDom, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addFeaturedLinkMetaDecorator = addFeaturedLinkMetaDecorator;
  _exports.extractLinkMeta = extractLinkMeta;
  _exports.default = renderTopicFeaturedLink;
  _exports.topicFeaturedLinkNode = topicFeaturedLinkNode;
  var _decorators = [];

  function addFeaturedLinkMetaDecorator(decorator) {
    _decorators.push(decorator);
  }

  function extractLinkMeta(topic) {
    var href = topic.get("featured_link");
    var target = _user.default.currentProp("external_links_in_new_tab") ? "_blank" : "";
    var domain = topic.get("featured_link_root_domain");
    var allowList = topic.siteSettings.exclude_rel_nofollow_domains;
    var rel = "nofollow ugc";

    if (allowList) {
      allowList = allowList.split("|");

      if (allowList.includes(domain)) {
        rel = rel.replace("nofollow ", "");
      }
    }

    if (!href) {
      return;
    }

    var meta = {
      target: target,
      href: href,
      domain: domain,
      rel: rel
    };

    if (_decorators.length) {
      _decorators.forEach(function (cb) {
        return cb(meta);
      });
    }

    return meta;
  }

  function renderTopicFeaturedLink(topic) {
    var meta = extractLinkMeta(topic);

    if (meta) {
      return "<a class=\"topic-featured-link\" rel=\"".concat(meta.rel, "\" target=\"").concat(meta.target, "\" href=\"").concat(meta.href, "\">").concat((0, _iconLibrary.renderIcon)("string", "external-link-alt"), " ").concat(meta.domain, "</a>");
    } else {
      return "";
    }
  }

  function topicFeaturedLinkNode(topic) {
    var meta = extractLinkMeta(topic);

    if (meta) {
      return (0, _virtualDom.h)("a.topic-featured-link", {
        attributes: {
          href: meta.href,
          rel: meta.rel,
          target: meta.target
        }
      }, [(0, _iconLibrary.renderIcon)("node", "external-link-alt"), meta.domain]);
    }
  }
});