define("discourse/widgets/footer-nav", ["discourse/lib/utilities", "discourse/widgets/widget"], function (_utilities, _widget) {
  "use strict";

  (0, _widget.createWidget)("footer-nav", {
    tagName: "div.footer-nav-widget",
    html: function html(attrs) {
      var buttons = [];
      buttons.push(this.attach("flat-button", {
        action: "goBack",
        icon: "chevron-left",
        className: "btn-large",
        disabled: !attrs.canGoBack,
        title: "footer_nav.back"
      }));
      buttons.push(this.attach("flat-button", {
        action: "goForward",
        icon: "chevron-right",
        className: "btn-large",
        disabled: !attrs.canGoForward,
        title: "footer_nav.forward"
      }));

      if ((0, _utilities.isAppWebview)()) {
        buttons.push(this.attach("flat-button", {
          action: "share",
          icon: "link",
          className: "btn-large",
          title: "footer_nav.share"
        }));
        buttons.push(this.attach("flat-button", {
          action: "dismiss",
          icon: "chevron-down",
          className: "btn-large",
          title: "footer_nav.dismiss"
        }));
      }

      return buttons;
    },
    dismiss: function dismiss() {
      (0, _utilities.postRNWebviewMessage)("dismiss", true);
    },
    share: function share() {
      (0, _utilities.postRNWebviewMessage)("shareUrl", window.location.href);
    }
  });
});