define("discourse/templates/components/topic-entrance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LSJ6VAuq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-button\",null,[[\"action\",\"class\"],[[28,\"action\",[[23,0,[]],\"enterTop\"],null],\"btn-default full jump-top\"]],{\"statements\":[[0,\"  \"],[1,[28,\"d-icon\",[\"step-backward\"],null],false],[0,\" \"],[1,[28,\"html-safe\",[[24,[\"topDate\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"d-button\",null,[[\"action\",\"class\"],[[28,\"action\",[[23,0,[]],\"enterBottom\"],null],\"btn-default full jump-bottom\"]],{\"statements\":[[0,\"  \"],[1,[28,\"html-safe\",[[24,[\"bottomDate\"]]],null],false],[0,\" \"],[1,[28,\"d-icon\",[\"step-forward\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/topic-entrance.hbs"
    }
  });

  _exports.default = _default;
});