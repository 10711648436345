define("discourse/templates/modal/tag-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yBmykEwC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"title\"],[\"tagging.upload\"]],{\"statements\":[[0,\"  \"],[1,[28,\"tags-uploader\",null,[[\"refresh\",\"closeModal\",\"id\"],[[28,\"route-action\",[\"triggerRefresh\"],null],[28,\"route-action\",[\"closeModal\"],null],\"tags-uploader\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/tag-upload.hbs"
    }
  });

  _exports.default = _default;
});