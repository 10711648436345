define("discourse/components/reviewable-post-edits", ["exports", "discourse-common/utils/decorators", "discourse/widgets/post-edits-indicator", "discourse/lib/formatter", "discourse/lib/show-modal"], function (_exports, _decorators, _postEditsIndicator, _formatter, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("reviewable.post_updated_at"), _dec2 = (0, _decorators.default)("reviewable.post_updated_at"), (_obj = {
    hasEdits: Ember.computed.gt("reviewable.post_version", 1),
    historyClass: function historyClass(updatedAt) {
      return (0, _postEditsIndicator.historyHeat)(this.siteSettings, new Date(updatedAt));
    },
    editedDate: function editedDate(updatedAt) {
      return (0, _formatter.longDate)(updatedAt);
    },
    actions: {
      showEditHistory: function showEditHistory() {
        var postId = this.get("reviewable.post_id");
        this.store.find("post", postId).then(function (post) {
          var historyController = (0, _showModal.default)("history", {
            model: post,
            modalClass: "history-modal"
          });
          historyController.refresh(postId, "latest");
          historyController.set("post", post);
          historyController.set("topicController", null);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "historyClass", [_dec], Object.getOwnPropertyDescriptor(_obj, "historyClass"), _obj), _applyDecoratedDescriptor(_obj, "editedDate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "editedDate"), _obj)), _obj)));

  _exports.default = _default;
});