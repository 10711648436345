define("discourse/components/topic-timer-info", ["exports", "discourse/models/category", "discourse/controllers/edit-topic-timer", "I18n", "discourse-common/utils/decorators", "discourse-common/lib/icon-library", "discourse-common/config/environment"], function (_exports, _category, _editTopicTimer, _I18n, _decorators, _iconLibrary, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.on)("didReceiveAttrs"), _dec2 = (0, _decorators.on)("willDestroyElement"), _dec3 = (0, _decorators.default)("canModifyTimer", "removeTopicTimer"), _dec4 = (0, _decorators.default)("canModifyTimer", "showTopicTimerModal"), (_obj = {
    classNames: ["topic-timer-info"],
    _delayedRerender: null,
    clockIcon: "".concat((0, _iconLibrary.iconHTML)("far-clock")).htmlSafe(),
    trashLabel: _I18n.default.t("post.controls.remove_timer"),
    title: null,
    notice: null,
    showTopicTimer: null,
    showTopicTimerModal: null,
    removeTopicTimer: null,
    setupRenderer: function setupRenderer() {
      this.renderTopicTimer();
    },
    cancelDelayedRenderer: function cancelDelayedRenderer() {
      if (this._delayedRerender) {
        Ember.run.cancel(this._delayedRerender);
      }
    },
    canModifyTimer: function canModifyTimer() {
      return this.currentUser && this.currentUser.get("canManageTopic");
    },
    showTrashCan: function showTrashCan(canModifyTimer, removeTopicTimer) {
      return canModifyTimer && removeTopicTimer;
    },
    showEdit: function showEdit(canModifyTimer, showTopicTimerModal) {
      return canModifyTimer && showTopicTimerModal;
    },
    additionalOpts: function additionalOpts() {
      return {};
    },
    renderTopicTimer: function renderTopicTimer() {
      var _this = this;

      var isDeleteRepliesType = this.statusType === _editTopicTimer.DELETE_REPLIES_TYPE;

      if (!isDeleteRepliesType && !this.basedOnLastPost && (!this.executeAt || this.executeAt < moment())) {
        this.set("showTopicTimer", null);
        return;
      }

      if (this.isDestroyed) {
        return;
      }

      var topicStatus = this.topicClosed ? "close" : "open";
      var topicStatusKnown = this.topicClosed !== undefined;

      if (topicStatusKnown && topicStatus === this.statusType) {
        return;
      }

      var statusUpdateAt = moment(this.executeAt);
      var duration = moment.duration(statusUpdateAt - moment());
      var minutesLeft = duration.asMinutes();

      if (minutesLeft > 0 || isDeleteRepliesType || this.basedOnLastPost) {
        var durationMinutes = parseInt(this.durationMinutes, 10) || 0;
        var options = {
          timeLeft: duration.humanize(true),
          duration: moment.duration(durationMinutes, "minutes").humanize({
            s: 60,
            m: 60,
            h: 24
          })
        };
        var categoryId = this.categoryId;

        if (categoryId) {
          var category = _category.default.findById(categoryId);

          options = Object.assign({
            categoryName: category.get("slug"),
            categoryUrl: category.get("url")
          }, options);
        }

        options = Object.assign(options, this.additionalOpts());
        this.setProperties({
          title: "".concat(moment(this.executeAt).format("LLLL")).htmlSafe(),
          notice: "".concat(_I18n.default.t(this._noticeKey(), options)).htmlSafe(),
          showTopicTimer: true
        }); // TODO Sam: concerned this can cause a heavy rerender loop

        if (!(0, _environment.isTesting)()) {
          this._delayedRerender = Ember.run.later(function () {
            _this.renderTopicTimer();
          }, this.rerenderDelay(minutesLeft));
        }
      } else {
        this.set("showTopicTimer", null);
      }
    },
    rerenderDelay: function rerenderDelay(minutesLeft) {
      if (minutesLeft > 2160) {
        return 12 * 60 * 60000;
      } else if (minutesLeft > 1410) {
        return 60 * 60000;
      } else if (minutesLeft > 90) {
        return 30 * 60000;
      } else if (minutesLeft > 2) {
        return 60000;
      }

      return 1000;
    },
    _noticeKey: function _noticeKey() {
      var statusType = this.statusType;

      if (statusType === "silent_close") {
        statusType = "close";
      }

      if (this.basedOnLastPost && statusType === "close") {
        statusType = "close_after_last_post";
      }

      return "topic.status_update_notice.auto_".concat(statusType);
    }
  }, (_applyDecoratedDescriptor(_obj, "setupRenderer", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupRenderer"), _obj), _applyDecoratedDescriptor(_obj, "cancelDelayedRenderer", [_dec2], Object.getOwnPropertyDescriptor(_obj, "cancelDelayedRenderer"), _obj), _applyDecoratedDescriptor(_obj, "canModifyTimer", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "canModifyTimer"), _obj), _applyDecoratedDescriptor(_obj, "showTrashCan", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showTrashCan"), _obj), _applyDecoratedDescriptor(_obj, "showEdit", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showEdit"), _obj)), _obj)));

  _exports.default = _default;
});