define("discourse/mixins/name-validation", ["exports", "I18n", "discourse-common/utils/decorators"], function (_exports, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Mixin.create((_dec = (0, _decorators.default)(), _dec2 = (0, _decorators.default)("accountName", "forceValidationReason"), (_obj = {
    nameInstructions: function nameInstructions() {
      return _I18n.default.t(this.siteSettings.full_name_required ? "user.name.instructions_required" : "user.name.instructions");
    },
    nameValidation: function nameValidation(accountName, forceValidationReason) {
      if (this.siteSettings.full_name_required && Ember.isEmpty(accountName)) {
        return Ember.Object.create({
          failed: true,
          ok: false,
          message: _I18n.default.t("user.name.required"),
          reason: forceValidationReason ? _I18n.default.t("user.name.required") : null,
          element: document.querySelector("#new-account-name")
        });
      }

      return Ember.Object.create({
        ok: true
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "nameInstructions", [_dec], Object.getOwnPropertyDescriptor(_obj, "nameInstructions"), _obj), _applyDecoratedDescriptor(_obj, "nameValidation", [_dec2], Object.getOwnPropertyDescriptor(_obj, "nameValidation"), _obj)), _obj)));

  _exports.default = _default;
});