define("discourse/initializers/auto-load-modules", ["exports", "discourse-common/lib/helpers", "handlebars", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-handlebars-helpers"], function (_exports, _helpers, _handlebars, _rawHandlebars, _rawHandlebarsHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.autoLoadModules = autoLoadModules;
  _exports.default = void 0;

  function autoLoadModules(container, registry) {
    Object.keys(requirejs.entries).forEach(function (entry) {
      if (/\/helpers\//.test(entry) && !/-test/.test(entry)) {
        requirejs(entry, null, null, true);
      }

      if (/\/widgets\//.test(entry) && !/-test/.test(entry)) {
        requirejs(entry, null, null, true);
      }
    });
    var context = {
      siteSettings: container.lookup("site-settings:main"),
      keyValueStore: container.lookup("key-value-store:main"),
      capabilities: container.lookup("capabilities:main"),
      currentUser: container.lookup("current-user:main"),
      site: container.lookup("site:main"),
      session: container.lookup("session:main"),
      topicTrackingState: container.lookup("topic-tracking-state:main"),
      registry: registry
    };
    Ember.setOwner(context, container);
    (0, _helpers.createHelperContext)(context);
    (0, _helpers.registerHelpers)(registry);
    (0, _rawHandlebarsHelpers.registerRawHelpers)(_rawHandlebars.default, _handlebars.default);
  }

  var _default = {
    name: "auto-load-modules",
    after: "inject-objects",
    initialize: function initialize(container) {
      return autoLoadModules(container, container.registry);
    }
  };
  _exports.default = _default;
});