define("discourse/templates/components/group-activity-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jv8b9uVA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"query\",\"route\"],[[28,\"hash\",null,[[\"category_id\"],[[24,[\"categoryId\"]]]]],[28,\"concat\",[\"group.activity.\",[24,[\"filter\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"i18n\",[[28,\"concat\",[\"groups.\",[24,[\"filter\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/group-activity-filter.hbs"
    }
  });

  _exports.default = _default;
});