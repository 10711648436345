define("discourse/mixins/uppy-upload", ["exports", "discourse/lib/ajax", "discourse/lib/uploads", "discourse-common/lib/object", "discourse-common/lib/get-url", "I18n", "@uppy/core", "@uppy/drop-target", "@uppy/xhr-upload", "@uppy/aws-s3", "discourse/lib/uppy-checksum-plugin", "discourse/mixins/uppy-s3-multipart", "discourse/lib/uppy-chunked-uploader-plugin", "discourse-common/utils/decorators", "bootbox"], function (_exports, _ajax, _uploads, _object, _getUrl, _I18n, _core, _dropTarget, _xhrUpload, _awsS, _uppyChecksumPlugin, _uppyS3Multipart, _uppyChunkedUploaderPlugin, _decorators, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.HUGE_FILE_THRESHOLD_BYTES = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var HUGE_FILE_THRESHOLD_BYTES = 104857600; // 100MB

  _exports.HUGE_FILE_THRESHOLD_BYTES = HUGE_FILE_THRESHOLD_BYTES;

  var _default = Ember.Mixin.create(_uppyS3Multipart.default, (_dec = (0, _decorators.on)("willDestroyElement"), _dec2 = (0, _decorators.on)("didInsertElement"), (_obj = {
    uploading: false,
    uploadProgress: 0,
    _uppyInstance: null,
    autoStartUploads: true,
    inProgressUploads: null,
    id: null,
    uploadRootPath: "/uploads",
    fileInputSelector: ".hidden-upload-field",
    uploadDone: function uploadDone() {
      (false && Ember.warn("You should implement `uploadDone`", {
        id: "discourse.upload.missing-upload-done"
      }));
    },
    validateUploadedFilesOptions: function validateUploadedFilesOptions() {
      return {};
    },
    uploadingOrProcessing: Ember.computed.or("uploading", "processing"),
    _destroy: function _destroy() {
      var _this$fileInputEl, _this$_uppyInstance;

      if (this.messageBus) {
        this.messageBus.unsubscribe("/uploads/".concat(this.type));
      }

      (_this$fileInputEl = this.fileInputEl) === null || _this$fileInputEl === void 0 ? void 0 : _this$fileInputEl.removeEventListener("change", this.fileInputEventListener);
      this.appEvents.off("upload-mixin:".concat(this.id, ":add-files"), this._addFiles);
      (_this$_uppyInstance = this._uppyInstance) === null || _this$_uppyInstance === void 0 ? void 0 : _this$_uppyInstance.close();
      this._uppyInstance = null;
    },
    _initialize: function _initialize() {
      var _this = this;

      this.setProperties({
        fileInputEl: this.element.querySelector(this.fileInputSelector)
      });
      this.set("allowMultipleFiles", this.fileInputEl.multiple);
      this.set("inProgressUploads", []);

      this._bindFileInputChange();

      if (!this.id) {
        (false && Ember.warn("uppy needs a unique id, pass one in to the component implementing this mixin", {
          id: "discourse.upload.missing-id"
        }));
      }

      this._uppyInstance = new _core.default({
        id: this.id,
        autoProceed: this.autoStartUploads,
        // need to use upload_type because uppy overrides type with the
        // actual file type
        meta: (0, _object.deepMerge)({
          upload_type: this.type
        }, this.additionalParams || {}, this.data || {}),
        onBeforeFileAdded: function onBeforeFileAdded(currentFile) {
          var validationOpts = (0, _object.deepMerge)({
            bypassNewUserRestriction: true,
            user: _this.currentUser,
            siteSettings: _this.siteSettings,
            validateSize: true
          }, _this.validateUploadedFilesOptions());
          var isValid = (0, _uploads.validateUploadedFile)(currentFile, validationOpts);

          _this.setProperties({
            uploadProgress: 0,
            uploading: isValid && _this.autoStartUploads,
            filesAwaitingUpload: !_this.autoStartUploads
          });

          return isValid;
        },
        onBeforeUpload: function onBeforeUpload(files) {
          var tooMany = false;
          var fileCount = Object.keys(files).length;

          var maxFiles = _this.getWithDefault("maxFiles", _this.siteSettings.simultaneous_uploads);

          if (_this.allowMultipleFiles) {
            tooMany = maxFiles > 0 && fileCount > maxFiles;
          } else {
            tooMany = fileCount > 1;
          }

          if (tooMany) {
            _bootbox.default.alert(_I18n.default.t("post.errors.too_many_dragged_and_dropped_files", {
              count: _this.allowMultipleFiles ? maxFiles : 1
            }));

            _this._reset();

            return false;
          }

          if (_this._perFileData) {
            Object.values(files).forEach(function (file) {
              (0, _object.deepMerge)(file.meta, _this._perFileData());
            });
          }
        }
      });

      this._uppyInstance.use(_dropTarget.default, this._uploadDropTargetOptions());

      this._uppyInstance.use(_uppyChecksumPlugin.default, {
        capabilities: this.capabilities
      });

      this._uppyInstance.on("progress", function (progress) {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }

        _this.set("uploadProgress", progress);
      });

      this._uppyInstance.on("upload", function (data) {
        var files = data.fileIDs.map(function (fileId) {
          return _this._uppyInstance.getFile(fileId);
        });
        files.forEach(function (file) {
          _this.inProgressUploads.push(Ember.Object.create({
            fileName: file.name,
            id: file.id,
            progress: 0
          }));
        });
      });

      this._uppyInstance.on("upload-success", function (file, response) {
        _this._removeInProgressUpload(file.id);

        if (_this.usingS3Uploads) {
          _this.setProperties({
            uploading: false,
            processing: true
          });

          _this._completeExternalUpload(file).then(function (completeResponse) {
            _this.uploadDone((0, _object.deepMerge)(completeResponse, {
              file_name: file.name
            }));

            if (_this.inProgressUploads.length === 0) {
              _this._reset();
            }
          }).catch(function (errResponse) {
            (0, _uploads.displayErrorForUpload)(errResponse, _this.siteSettings, file.name);

            if (_this.inProgressUploads.length === 0) {
              _this._reset();
            }
          });
        } else {
          _this.uploadDone((0, _object.deepMerge)((response === null || response === void 0 ? void 0 : response.body) || {}, {
            file_name: file.name
          }));

          if (_this.inProgressUploads.length === 0) {
            _this._reset();
          }
        }
      });

      this._uppyInstance.on("upload-error", function (file, error, response) {
        _this._removeInProgressUpload(file.id);

        (0, _uploads.displayErrorForUpload)(response || error, _this.siteSettings, file.name);

        _this._reset();
      }); // TODO (martin) preventDirectS3Uploads is necessary because some of
      // the current upload mixin components, for example the emoji uploader,
      // send the upload to custom endpoints that do fancy things in the rails
      // controller with the upload or create additional data or records. we
      // need a nice way to do this on complete-external-upload before we can
      // allow these other uploaders to go direct to S3.


      if (this.siteSettings.enable_direct_s3_uploads && !this.preventDirectS3Uploads && !this.useChunkedUploads) {
        if (this.useMultipartUploadsIfAvailable) {
          this._useS3MultipartUploads();
        } else {
          this._useS3Uploads();
        }
      } else {
        if (this.useChunkedUploads) {
          this._useChunkedUploads();
        } else {
          this._useXHRUploads();
        }
      }

      this.appEvents.on("upload-mixin:".concat(this.id, ":add-files"), this._addFiles);

      this._uppyReady();
    },
    // This should be overridden in a child component if you need to
    // hook into uppy events and be sure that everything is already
    // set up for _uppyInstance.
    _uppyReady: function _uppyReady() {},
    _startUpload: function _startUpload() {
      var _this$_uppyInstance2, _this$_uppyInstance3;

      if (!this.filesAwaitingUpload) {
        return;
      }

      if (!((_this$_uppyInstance2 = this._uppyInstance) !== null && _this$_uppyInstance2 !== void 0 && _this$_uppyInstance2.getFiles().length)) {
        return;
      }

      this.set("uploading", true);
      return (_this$_uppyInstance3 = this._uppyInstance) === null || _this$_uppyInstance3 === void 0 ? void 0 : _this$_uppyInstance3.upload();
    },
    _useXHRUploads: function _useXHRUploads() {
      this._uppyInstance.use(_xhrUpload.default, {
        endpoint: this._xhrUploadUrl(),
        headers: {
          "X-CSRF-Token": this.session.csrfToken
        }
      });
    },
    _useChunkedUploads: function _useChunkedUploads() {
      this.set("usingChunkedUploads", true);

      this._uppyInstance.use(_uppyChunkedUploaderPlugin.default, {
        url: this._xhrUploadUrl(),
        headers: {
          "X-CSRF-Token": this.session.csrfToken
        }
      });
    },
    _useS3Uploads: function _useS3Uploads() {
      var _this2 = this;

      this.set("usingS3Uploads", true);

      this._uppyInstance.use(_awsS.default, {
        getUploadParameters: function getUploadParameters(file) {
          var data = {
            file_name: file.name,
            file_size: file.size,
            type: _this2.type
          }; // the sha1 checksum is set by the UppyChecksum plugin, except
          // for in cases where the browser does not support the required
          // crypto mechanisms or an error occurs. it is an additional layer
          // of security, and not required.

          if (file.meta.sha1_checksum) {
            data.metadata = {
              "sha1-checksum": file.meta.sha1_checksum
            };
          }

          return (0, _ajax.ajax)((0, _getUrl.default)("".concat(_this2.uploadRootPath, "/generate-presigned-put")), {
            type: "POST",
            data: data
          }).then(function (response) {
            _this2._uppyInstance.setFileMeta(file.id, {
              uniqueUploadIdentifier: response.unique_identifier
            });

            return {
              method: "put",
              url: response.url,
              headers: {
                "Content-Type": file.type
              }
            };
          }).catch(function (errResponse) {
            (0, _uploads.displayErrorForUpload)(errResponse, _this2.siteSettings, file.name);

            _this2._reset();
          });
        }
      });
    },
    _xhrUploadUrl: function _xhrUploadUrl() {
      var _this$messageBus;

      return (0, _getUrl.default)(this.getWithDefault("uploadUrl", this.uploadRootPath)) + ".json?client_id=" + ((_this$messageBus = this.messageBus) === null || _this$messageBus === void 0 ? void 0 : _this$messageBus.clientId);
    },
    _bindFileInputChange: function _bindFileInputChange() {
      this.fileInputEventListener = (0, _uploads.bindFileInputChangeListener)(this.fileInputEl, this._addFiles);
    },
    _addFiles: function _addFiles(files) {
      var _this3 = this;

      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      files = Array.isArray(files) ? files : [files];

      try {
        this._uppyInstance.addFiles(files.map(function (file) {
          return {
            source: _this3.id,
            name: file.name,
            type: file.type,
            data: file,
            meta: {
              pasted: opts.pasted
            }
          };
        }));
      } catch (err) {
        (false && Ember.warn("error adding files to uppy: ".concat(err), {
          id: "discourse.upload.uppy-add-files-error"
        }));
      }
    },
    _completeExternalUpload: function _completeExternalUpload(file) {
      return (0, _ajax.ajax)((0, _getUrl.default)("".concat(this.uploadRootPath, "/complete-external-upload")), {
        type: "POST",
        data: (0, _object.deepMerge)({
          unique_identifier: file.meta.uniqueUploadIdentifier
        }, this.additionalParams || {})
      });
    },
    _reset: function _reset() {
      var _this$_uppyInstance4;

      (_this$_uppyInstance4 = this._uppyInstance) === null || _this$_uppyInstance4 === void 0 ? void 0 : _this$_uppyInstance4.reset();
      this.setProperties({
        uploading: false,
        processing: false,
        uploadProgress: 0,
        filesAwaitingUpload: false
      });
      this.fileInputEl.value = "";
    },
    _removeInProgressUpload: function _removeInProgressUpload(fileId) {
      this.set("inProgressUploads", this.inProgressUploads.filter(function (upl) {
        return upl.id !== fileId;
      }));
    },
    // target must be provided as a DOM element, however the
    // onDragOver and onDragLeave callbacks can also be provided.
    // it is advisable to debounce/add a setTimeout timer when
    // doing anything in these callbacks to avoid jumping. uppy
    // also adds a .uppy-is-drag-over class to the target element by
    // default onDragOver and removes it onDragLeave
    _uploadDropTargetOptions: function _uploadDropTargetOptions() {
      return {
        target: this.element
      };
    }
  }, (_applyDecoratedDescriptor(_obj, "_destroy", [_dec], Object.getOwnPropertyDescriptor(_obj, "_destroy"), _obj), _applyDecoratedDescriptor(_obj, "_initialize", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_initialize"), _obj), _applyDecoratedDescriptor(_obj, "_addFiles", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_addFiles"), _obj)), _obj)));

  _exports.default = _default;
});