define("discourse/routes/user-activity-stream", ["exports", "discourse/routes/discourse", "discourse/mixins/viewing-action-type", "I18n"], function (_exports, _discourse, _viewingActionType, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _discourse.default.extend(_viewingActionType.default, (_dec = Ember._action, (_obj = {
    queryParams: {
      acting_username: {
        refreshModel: true
      }
    },
    emptyStateOthers: _I18n.default.t("user_activity.no_activity_others"),
    model: function model() {
      var user = this.modelFor("user");
      var stream = user.get("stream");
      return {
        stream: stream,
        isAnotherUsersPage: this.isAnotherUsersPage(user),
        emptyState: this.emptyState(),
        emptyStateOthers: this.emptyStateOthers
      };
    },
    afterModel: function afterModel(model, transition) {
      return model.stream.filterBy({
        filter: this.userActionType,
        actingUsername: transition.to.queryParams.acting_username
      });
    },
    renderTemplate: function renderTemplate() {
      this.render("user_stream");
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model);
      this.viewingActionType(this.userActionType);
    },
    emptyState: function emptyState() {
      var title = _I18n.default.t("user_activity.no_activity_title");

      var body = "";
      return {
        title: title,
        body: body
      };
    },
    didTransition: function didTransition() {
      this.controllerFor("user-activity")._showFooter();

      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, "didTransition", [_dec], Object.getOwnPropertyDescriptor(_obj, "didTransition"), _obj)), _obj)));

  _exports.default = _default;
});