define("discourse/templates/review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ObDGRaAb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"reviewable\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"nav nav-pills reviewable-title\"],[8],[0,\"\\n    \"],[1,[28,\"nav-item\",null,[[\"route\",\"label\"],[\"review.index\",\"review.view_all\"]]],false],[0,\"\\n    \"],[1,[28,\"nav-item\",null,[[\"route\",\"label\"],[\"review.topics\",\"review.grouped_by_topic\"]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"currentUser\",\"admin\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"nav-item\",null,[[\"route\",\"label\",\"icon\"],[\"review.settings\",\"review.settings.title\",\"wrench\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/review.hbs"
    }
  });

  _exports.default = _default;
});