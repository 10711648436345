define("discourse/templates/modal/request-group-membership-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EOi0x+lf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",true],[10,\"class\",\"request-group-membership-form\"],[8],[0,\"\\n\"],[4,\"d-modal-body\",null,[[\"rawTitle\"],[[24,[\"title\"]]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"control-group\"],[8],[0,\"\\n      \"],[7,\"label\",true],[8],[0,\"\\n        \"],[1,[28,\"i18n\",[\"groups.membership_request.reason\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[1,[28,\"expanding-text-area\",null,[[\"value\"],[[24,[\"reason\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n    \"],[1,[28,\"d-button\",null,[[\"class\",\"disabled\",\"label\",\"action\"],[\"btn-primary\",[24,[\"disableSubmit\"]],\"groups.membership_request.submit\",[28,\"action\",[[23,0,[]],\"requestMember\"],null]]]],false],[0,\"\\n\\n    \"],[1,[28,\"d-modal-cancel\",null,[[\"close\"],[[28,\"route-action\",[\"closeModal\"],null]]]],false],[0,\"\\n    \"],[1,[28,\"conditional-loading-spinner\",null,[[\"size\",\"condition\"],[\"small\",[24,[\"loading\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/request-group-membership-form.hbs"
    }
  });

  _exports.default = _default;
});