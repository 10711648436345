define("discourse/templates/components/d-modal-cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vF8yacu1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"d-modal-cancel\"],[3,\"action\",[[23,0,[]],[24,[\"close\"]]]],[8],[1,[28,\"i18n\",[\"cancel\"],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/d-modal-cancel.hbs"
    }
  });

  _exports.default = _default;
});