define("discourse/components/topic-navigation-popup", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("popupId"), _dec2 = Ember._action, (_obj = {
    tagName: "",
    popupId: null,
    hidden: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.popupKey) {
        var value = this.keyValueStore.getItem(this.popupKey);

        if (value === true || value > +new Date()) {
          this.set("hidden", true);
        } else {
          this.keyValueStore.removeItem(this.popupKey);
        }
      }
    },
    popupKey: function popupKey(popupId) {
      if (popupId) {
        return "dismiss_topic_nav_popup_".concat(popupId);
      }
    },
    close: function close() {
      this.set("hidden", true);

      if (this.popupKey) {
        if (this.dismissDuration) {
          var expiry = +new Date() + this.dismissDuration;
          this.keyValueStore.setItem(this.popupKey, expiry);
        } else {
          this.keyValueStore.setItem(this.popupKey, true);
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "popupKey", [_dec], Object.getOwnPropertyDescriptor(_obj, "popupKey"), _obj), _applyDecoratedDescriptor(_obj, "close", [_dec2], Object.getOwnPropertyDescriptor(_obj, "close"), _obj)), _obj)));

  _exports.default = _default;
});