define("discourse/controllers/bulk-notification-level", ["exports", "I18n", "discourse-common/utils/decorators", "discourse/lib/notification-levels"], function (_exports, _I18n, _decorators, _notificationLevels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // Support for changing the notification level of various topics
  var _default = Ember.Controller.extend((_obj = {
    topicBulkActions: Ember.inject.controller(),
    notificationLevelId: null,
    notificationLevels: function notificationLevels() {
      return _notificationLevels.topicLevels.map(function (level) {
        return {
          id: level.id.toString(),
          name: _I18n.default.t("topic.notifications.".concat(level.key, ".title")),
          description: _I18n.default.t("topic.notifications.".concat(level.key, ".description"))
        };
      });
    },
    disabled: Ember.computed.empty("notificationLevelId"),
    actions: {
      changeNotificationLevel: function changeNotificationLevel() {
        this.topicBulkActions.performAndRefresh({
          type: "change_notification_level",
          notification_level_id: this.notificationLevelId
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "notificationLevels", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "notificationLevels"), _obj)), _obj));

  _exports.default = _default;
});