define("discourse/components/tag-list", ["exports", "discourse/models/category", "I18n", "discourse-common/utils/decorators"], function (_exports, _category, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("titleKey"), _dec2 = (0, _decorators.default)("categoryId"), _dec3 = (0, _decorators.default)("category.fullSlug"), _dec4 = (0, _decorators.default)("tagGroupName"), (_obj = {
    classNameBindings: [":tags-list", ":tag-list", "categoryClass", "tagGroupNameClass"],
    isPrivateMessage: false,
    sortedTags: Ember.computed.sort("tags", "sortProperties"),
    title: function title(titleKey) {
      return titleKey && _I18n.default.t(titleKey);
    },
    category: function category(categoryId) {
      return categoryId && _category.default.findById(categoryId);
    },
    categoryClass: function categoryClass(slug) {
      return slug && "tag-list-".concat(slug);
    },
    tagGroupNameClass: function tagGroupNameClass(groupName) {
      if (groupName) {
        groupName = groupName.replace(/\s+/g, "-").replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, "").toLowerCase();
        return groupName && "tag-group-".concat(groupName);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "title", [_dec], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "category", [_dec2], Object.getOwnPropertyDescriptor(_obj, "category"), _obj), _applyDecoratedDescriptor(_obj, "categoryClass", [_dec3], Object.getOwnPropertyDescriptor(_obj, "categoryClass"), _obj), _applyDecoratedDescriptor(_obj, "tagGroupNameClass", [_dec4], Object.getOwnPropertyDescriptor(_obj, "tagGroupNameClass"), _obj)), _obj)));

  _exports.default = _default;
});