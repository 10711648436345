define("discourse/templates/components/composer-toggles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P+5VKcqb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"composer-controls\"],[8],[0,\"\\n  \"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"tagName\",\"connectorTagName\"],[\"before-composer-toggles\",\"span\",\"div\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"site\",\"mobileView\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"d-button\",null,[[\"class\",\"icon\",\"action\",\"title\",\"preventFocus\",\"tabindex\"],[\"btn-flat toggle-toolbar btn-mini-toggle\",\"bars\",[24,[\"toggleToolbar\"]],[24,[\"toggleToolbarTitle\"]],true,-1]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"class\",\"icon\",\"action\",\"title\",\"tabindex\"],[\"btn-flat toggler toggle-minimize btn-mini-toggle\",[24,[\"toggleIcon\"]],[24,[\"toggleComposer\"]],[24,[\"toggleTitle\"]],-1]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showFullScreenButton\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"d-button\",null,[[\"class\",\"icon\",\"action\",\"title\",\"tabindex\"],[\"btn-flat toggle-fullscreen btn-mini-toggle\",[24,[\"fullscreenIcon\"]],[24,[\"toggleFullscreen\"]],[24,[\"fullscreenTitle\"]],-1]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/composer-toggles.hbs"
    }
  });

  _exports.default = _default;
});