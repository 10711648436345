define("discourse/templates/components/suggested-topics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WvPNwIrb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"suggested-topics\"],[10,\"class\",\"suggested-topics\"],[10,\"role\",\"complementary\"],[10,\"aria-labelledby\",\"suggested-topics-title\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"id\",\"suggested-topics-title\"],[10,\"class\",\"suggested-topics-title\"],[8],[0,\"\\n    \"],[1,[28,\"i18n\",[[24,[\"suggestedTitleLabel\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"topics\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"topic\",\"isPrivateMessage\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"basic-topic-list\",null,[[\"hideCategory\",\"showPosters\",\"topics\"],[\"true\",\"true\",[24,[\"topic\",\"suggestedTopics\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"basic-topic-list\",null,[[\"topics\"],[[24,[\"topic\",\"suggestedTopics\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"h3\",true],[10,\"class\",\"suggested-topics-message\"],[8],[0,\"\\n    \"],[1,[28,\"html-safe\",[[24,[\"browseMoreMessage\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"tagName\",\"connectorTagName\",\"args\"],[\"below-suggested-topics\",\"span\",\"div\",[28,\"hash\",null,[[\"topic\"],[[24,[\"topic\"]]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/suggested-topics.hbs"
    }
  });

  _exports.default = _default;
});