define("discourse/components/group-card-contents", ["exports", "discourse/mixins/card-contents-base", "discourse/mixins/cleans-up", "discourse-common/utils/decorators", "discourse/lib/url", "discourse/lib/computed"], function (_exports, _cardContentsBase, _cleansUp, _decorators, _url, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj, _init, _init2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var maxMembersToDisplay = 10;

  var _default = Ember.Component.extend(_cardContentsBase.default, _cleansUp.default, (_dec = (0, _decorators.default)("group.user_count", "group.members.length"), _dec2 = (0, _decorators.default)("group.name"), _dec3 = (0, _decorators.default)("group"), (_obj = {
    elementId: "group-card",
    mentionSelector: "a.mention-group",
    classNames: ["no-bg", "group-card"],
    classNameBindings: ["visible:show", "showBadges", "hasCardBadgeImage", "isFixed:fixed", "groupClass"],
    allowBackgrounds: (0, _computed.setting)("allow_profile_backgrounds"),
    showBadges: (0, _computed.setting)("enable_badges"),
    postStream: Ember.computed.alias("topic.postStream"),
    showMoreMembers: Ember.computed.gt("moreMembersCount", 0),
    group: null,
    moreMembersCount: function moreMembersCount(memberCount, maxMemberDisplay) {
      return memberCount - maxMemberDisplay;
    },
    groupClass: function groupClass(name) {
      return name ? "group-card-".concat(name) : "";
    },
    groupPath: function groupPath(group) {
      return (0, _url.groupPath)(group.name);
    },
    _showCallback: function _showCallback(username, $target) {
      var _this = this;

      this._positionCard($target);

      this.setProperties({
        visible: true,
        loading: true
      });
      return this.store.find("group", username).then(function (group) {
        _this.setProperties({
          group: group
        });

        if (!group.flair_url && !group.flair_bg_color) {
          group.set("flair_url", "fa-users");
        }

        return group.can_see_members && group.members.length < maxMembersToDisplay ? group.reloadMembers({
          limit: maxMembersToDisplay
        }, true) : Ember.RSVP.Promise.resolve();
      }).catch(function () {
        return _this._close();
      }).finally(function () {
        return _this.set("loading", null);
      });
    },
    _close: function _close() {
      this.set("group", null);

      this._super.apply(this, arguments);
    },
    cleanUp: function cleanUp() {
      this._close();
    },
    actions: {
      close: function close() {
        this._close();
      },
      cancelFilter: function cancelFilter() {
        var postStream = this.postStream;
        postStream.cancelFilter();
        postStream.refresh();

        this._close();
      },
      messageGroup: function messageGroup() {
        this.createNewMessageViaParams({
          recipients: this.get("group.name"),
          hasGroups: true
        });
      },
      showGroup: function showGroup(group) {
        this.showGroup(group);

        this._close();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "moreMembersCount", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "moreMembersCount"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "groupClass", [_dec2], (_init2 = Object.getOwnPropertyDescriptor(_obj, "groupClass"), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "groupPath", [_dec3], Object.getOwnPropertyDescriptor(_obj, "groupPath"), _obj)), _obj)));

  _exports.default = _default;
});