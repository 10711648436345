define("discourse/initializers/topic-footer-buttons", ["exports", "I18n", "discourse/lib/register-topic-footer-button", "discourse/lib/show-modal"], function (_exports, _I18n, _registerTopicFooterButton, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SHARE_PRIORITY = 1000;
  var BOOKMARK_PRIORITY = 900;
  var ARCHIVE_PRIORITY = 800;
  var FLAG_PRIORITY = 700;
  var DEFER_PRIORITY = 500;
  var _default = {
    name: "topic-footer-buttons",
    initialize: function initialize() {
      (0, _registerTopicFooterButton.registerTopicFooterButton)({
        id: "share-and-invite",
        icon: "d-topic-share",
        priority: SHARE_PRIORITY,
        label: function label() {
          if (!this.get("topic.isPrivateMessage") || this.site.mobileView) {
            return "topic.share.title";
          }
        },
        title: "topic.share.help",
        action: function action() {
          var controller = (0, _showModal.default)("share-topic", {
            model: this.topic.category
          });
          controller.setProperties({
            allowInvites: this.canInviteTo && !this.inviteDisabled,
            topic: this.topic
          });
        },
        dropdown: function dropdown() {
          return this.site.mobileView;
        },
        classNames: ["share-and-invite"],
        dependentKeys: ["topic.shareUrl", "topic.isPrivateMessage", "canInviteTo", "inviteDisabled", "isPM", "invitingToTopic"]
      });
      (0, _registerTopicFooterButton.registerTopicFooterButton)({
        id: "flag",
        icon: "flag",
        priority: FLAG_PRIORITY,
        label: "topic.flag_topic.title",
        title: "topic.flag_topic.help",
        action: "showFlagTopic",
        dropdown: function dropdown() {
          return this.site.mobileView;
        },
        classNames: ["flag-topic"],
        dependentKeys: ["topic.details.can_flag_topic", "topic.isPrivateMessage"],
        displayed: function displayed() {
          return this.get("topic.details.can_flag_topic") && !this.get("topic.isPrivateMessage");
        }
      });
      (0, _registerTopicFooterButton.registerTopicFooterButton)({
        dependentKeys: ["topic.bookmarked", "topic.bookmarksWereChanged"],
        id: "bookmark",
        icon: function icon() {
          if (this.topic.bookmarks.some(function (bookmark) {
            return bookmark.reminder_at;
          })) {
            return "discourse-bookmark-clock";
          }

          return "bookmark";
        },
        priority: BOOKMARK_PRIORITY,
        classNames: function classNames() {
          return this.topic.bookmarked ? ["bookmark", "bookmarked"] : ["bookmark"];
        },
        label: function label() {
          if (!this.topic.isPrivateMessage || this.site.mobileView) {
            if (this.topic.bookmarkCount === 0) {
              return "bookmarked.title";
            } else if (this.topic.bookmarkCount === 1) {
              return "bookmarked.edit_bookmark";
            } else {
              return "bookmarked.clear_bookmarks";
            }
          }
        },
        translatedTitle: function translatedTitle() {
          if (this.topic.bookmarkCount === 0) {
            return _I18n.default.t("bookmarked.help.bookmark");
          } else if (this.topic.bookmarkCount === 1) {
            if (this.topic.bookmarks.filter(function (bookmark) {
              return bookmark.for_topic;
            }).length) {
              return _I18n.default.t("bookmarked.help.edit_bookmark_for_topic");
            } else {
              return _I18n.default.t("bookmarked.help.edit_bookmark");
            }
          } else if (this.topic.bookmarks.some(function (bookmark) {
            return bookmark.reminder_at;
          })) {
            return _I18n.default.t("bookmarked.help.unbookmark_with_reminder");
          } else {
            return _I18n.default.t("bookmarked.help.unbookmark");
          }
        },
        action: "toggleBookmark",
        dropdown: function dropdown() {
          return this.site.mobileView;
        }
      });
      (0, _registerTopicFooterButton.registerTopicFooterButton)({
        id: "archive",
        priority: ARCHIVE_PRIORITY,
        icon: function icon() {
          return this.archiveIcon;
        },
        label: function label() {
          return this.archiveLabel;
        },
        title: function title() {
          return this.archiveTitle;
        },
        action: "toggleArchiveMessage",
        classNames: ["standard", "archive-topic"],
        dependentKeys: ["canArchive", "archiveIcon", "archiveLabel", "archiveTitle", "toggleArchiveMessage"],
        dropdown: function dropdown() {
          return this.site.mobileView;
        },
        displayed: function displayed() {
          return this.canArchive;
        }
      });
      (0, _registerTopicFooterButton.registerTopicFooterButton)({
        id: "defer",
        icon: "circle",
        priority: DEFER_PRIORITY,
        label: "topic.defer.title",
        title: "topic.defer.help",
        action: "deferTopic",
        displayed: function displayed() {
          return this.canDefer;
        },
        dropdown: function dropdown() {
          return this.site.mobileView;
        }
      });
    }
  };
  _exports.default = _default;
});