define("discourse/templates/user/stream", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WXI1nFVD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"stream\",\"noContent\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"model\",\"isAnotherUsersPage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"alert alert-info\"],[8],[1,[24,[\"model\",\"emptyStateOthers\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"empty-state\",[],[[\"@title\",\"@body\"],[[24,[\"model\",\"emptyState\",\"title\"]],[24,[\"model\",\"emptyState\",\"body\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[1,[28,\"user-stream\",null,[[\"stream\"],[[24,[\"model\",\"stream\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/user/stream.hbs"
    }
  });

  _exports.default = _default;
});