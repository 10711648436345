define("discourse/templates/components/cancel-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g2B0YKtg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"cancel\",[24,[\"route\"]],[24,[\"args\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"i18n\",[\"cancel\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/cancel-link.hbs"
    }
  });

  _exports.default = _default;
});