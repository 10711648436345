define("discourse/components/preference-checkbox", ["exports", "I18n", "discourse-common/utils/decorators"], function (_exports, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("labelKey"), (_obj = {
    classNames: ["controls"],
    label: function label(labelKey) {
      return _I18n.default.t(labelKey);
    },
    change: function change() {
      var warning = this.warning;

      if (warning && this.checked) {
        this.warning();
        return false;
      }

      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, "label", [_dec], Object.getOwnPropertyDescriptor(_obj, "label"), _obj)), _obj)));

  _exports.default = _default;
});