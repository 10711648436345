define("discourse/components/date-input", ["exports", "discourse-common/utils/decorators", "I18n", "discourse/lib/load-script"], function (_exports, _decorators, _I18n, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj, _init;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function isInputDateSupported() {
    var input = document.createElement("input");
    var value = "a";
    input.setAttribute("type", "date");
    input.setAttribute("value", value);
    return input.value !== value;
  }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("site.mobileView"), _dec2 = (0, _decorators.on)("willDestroyElement"), _dec3 = (0, _decorators.default)("_placeholder"), _dec4 = Ember._action, (_obj = {
    classNames: ["d-date-input"],
    date: null,
    _picker: null,
    inputType: function inputType() {
      return this.useNativePicker ? "date" : "text";
    },
    useNativePicker: isInputDateSupported(),
    click: function click(event) {
      event.stopPropagation();
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.schedule("afterRender", function () {
        if (!_this.element || _this.isDestroying || _this.isDestroying) {
          return;
        }

        var promise;
        var container = document.getElementById(_this.containerId);

        if (_this.useNativePicker) {
          promise = _this._loadNativePicker(container);
        } else {
          promise = _this._loadPikadayPicker(container);
        }

        promise.then(function (picker) {
          _this._picker = picker;

          if (_this._picker && _this.date) {
            _this._picker.setDate(moment(_this.date).toDate(), true);
          }
        });
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      if (this._picker && this.date) {
        this._picker.setDate(moment(this.date).toDate(), true);
      }

      if (this._picker && this.relativeDate) {
        this._picker.setMinDate(moment(this.relativeDate).toDate(), true);
      }

      if (this._picker && !this.date) {
        this._picker.setDate(null);
      }
    },
    _loadPikadayPicker: function _loadPikadayPicker(container) {
      var _this2 = this;

      return (0, _loadScript.default)("/javascripts/pikaday.js").then(function () {
        var defaultOptions = {
          field: _this2.element.querySelector(".date-picker"),
          container: container || _this2.element.querySelector(".picker-container"),
          bound: container === null,
          format: "LL",
          firstDay: 1,
          i18n: {
            previousMonth: _I18n.default.t("dates.previous_month"),
            nextMonth: _I18n.default.t("dates.next_month"),
            months: moment.months(),
            weekdays: moment.weekdays(),
            weekdaysShort: moment.weekdaysShort()
          },
          onSelect: function onSelect(date) {
            return _this2._handleSelection(date);
          }
        };

        if (_this2.relativeDate) {
          defaultOptions = Object.assign({}, defaultOptions, {
            minDate: moment(_this2.relativeDate).toDate()
          });
        }

        return new Pikaday(Object.assign({}, defaultOptions, _this2._opts()));
      });
    },
    _loadNativePicker: function _loadNativePicker(container) {
      var _this3 = this;

      var wrapper = container || this.element;
      var picker = wrapper.querySelector("input.date-picker");

      picker.onchange = function () {
        return _this3._handleSelection(picker.value);
      };

      picker.hide = function () {
        /* do nothing for native */
      };

      picker.destroy = function () {
        /* do nothing for native */
      };

      picker.setDate = function (date) {
        picker.value = date ? moment(date).format("YYYY-MM-DD") : null;
      };

      picker.setMinDate = function (date) {
        picker.min = date;
      };

      if (this.date) {
        picker.setDate(this.date);
      }

      return Ember.RSVP.Promise.resolve(picker);
    },
    _handleSelection: function _handleSelection(value) {
      if (!this.element || this.isDestroying || this.isDestroyed) {
        return;
      }

      if (this.onChange) {
        this.onChange(value ? moment(value) : null);
      }
    },
    _destroy: function _destroy() {
      if (this._picker) {
        this._picker.destroy();

        this._picker = null;
      }
    },
    placeholder: {
      get: function get(_placeholder) {
        return _placeholder || _I18n.default.t("dates.placeholder");
      },
      set: function set(value) {
        this.set("_placeholder", value);
        return value;
      }
    },
    _opts: function _opts() {
      return null;
    },
    onChangeDate: function onChangeDate(event) {
      this._handleSelection(event.target.value);
    }
  }, (_applyDecoratedDescriptor(_obj, "inputType", [_dec], Object.getOwnPropertyDescriptor(_obj, "inputType"), _obj), _applyDecoratedDescriptor(_obj, "_destroy", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_destroy"), _obj), _applyDecoratedDescriptor(_obj, "placeholder", [_dec3], (_init = Object.getOwnPropertyDescriptor(_obj, "placeholder"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "onChangeDate", [_dec4], Object.getOwnPropertyDescriptor(_obj, "onChangeDate"), _obj)), _obj)));

  _exports.default = _default;
});