define("discourse/widgets/emoji", ["exports", "discourse/lib/text", "discourse/widgets/raw-html", "discourse/widgets/widget", "discourse/lib/utilities"], function (_exports, _text, _rawHtml, _widget, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.replaceEmoji = replaceEmoji;
  _exports.default = void 0;

  function replaceEmoji(str) {
    var escaped = (0, _text.emojiUnescape)((0, _utilities.escapeExpression)(str));
    return [new _rawHtml.default({
      html: "<span>".concat(escaped, "</span>")
    })];
  }

  var _default = (0, _widget.createWidget)("emoji", {
    tagName: "img.emoji",
    buildAttributes: function buildAttributes(attrs) {
      var result = {
        src: (0, _text.emojiUrlFor)(attrs.name),
        alt: ":".concat(attrs.alt || attrs.name, ":")
      };

      if (attrs.title) {
        result.title = typeof attrs.title === "string" ? attrs.title : attrs.name;
      }

      return result;
    }
  });

  _exports.default = _default;
});