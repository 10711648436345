define("discourse/routes/tag-groups-edit", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    showFooter: true,
    model: function model(params) {
      return this.store.find("tagGroup", params.id);
    },
    afterModel: function afterModel(tagGroup) {
      tagGroup.set("savingStatus", null);
    }
  });

  _exports.default = _default;
});