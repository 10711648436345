define("discourse/components/edit-category-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildCategoryPanel = buildCategoryPanel;
  _exports.default = void 0;
  var EditCategoryPanel = Ember.Component.extend({});
  var _default = EditCategoryPanel;
  _exports.default = _default;

  function buildCategoryPanel(tab, extras) {
    return EditCategoryPanel.extend({
      activeTab: Ember.computed.equal("selectedTab", tab),
      classNameBindings: [":edit-category-tab", "activeTab::hide", ":edit-category-tab-".concat(tab)]
    }, extras || {});
  }
});