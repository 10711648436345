define("discourse/components/d-document", ["exports", "I18n", "discourse-common/utils/decorators", "bootbox", "discourse/lib/logout", "discourse/lib/ajax"], function (_exports, _I18n, _decorators, _bootbox, _logout, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addPluginDocumentTitleCounter = addPluginDocumentTitleCounter;
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var pluginCounterFunctions = [];

  function addPluginDocumentTitleCounter(counterFunction) {
    pluginCounterFunctions.push(counterFunction);
  }

  var _default = Ember.Component.extend((_obj = {
    tagName: "",
    documentTitle: Ember.inject.service(),
    _showingLogout: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.documentTitle.setTitle(document.title);
      document.addEventListener("visibilitychange", this._focusChanged);
      document.addEventListener("resume", this._focusChanged);
      document.addEventListener("freeze", this._focusChanged);
      this.session.hasFocus = true;
      this.appEvents.on("notifications:changed", this, this._updateNotifications);
      (0, _ajax.setLogoffCallback)(function () {
        return _this.displayLogoff();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _ajax.setLogoffCallback)(null);
      document.removeEventListener("visibilitychange", this._focusChanged);
      document.removeEventListener("resume", this._focusChanged);
      document.removeEventListener("freeze", this._focusChanged);
      this.appEvents.off("notifications:changed", this, this._updateNotifications);
    },
    _updateNotifications: function _updateNotifications() {
      if (!this.currentUser) {
        return;
      }

      var count = pluginCounterFunctions.reduce(function (sum, fn) {
        return sum + fn();
      }, 0) + this.currentUser.unread_notifications + this.currentUser.unread_high_priority_notifications;
      this.documentTitle.updateNotificationCount(count);
    },
    _focusChanged: function _focusChanged() {
      if (document.visibilityState === "hidden") {
        if (this.session.hasFocus) {
          this.documentTitle.setFocus(false);
        }
      } else if (!this.hasFocus) {
        this.documentTitle.setFocus(true);
      }
    },
    displayLogoff: function displayLogoff() {
      if (this._showingLogout) {
        return;
      }

      this._showingLogout = true;
      this.messageBus.stop();

      _bootbox.default.dialog(_I18n.default.t("logout"), {
        label: _I18n.default.t("refresh"),
        callback: _logout.default
      }, {
        onEscape: function onEscape() {
          return (0, _logout.default)();
        },
        backdrop: "static"
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "_focusChanged", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_focusChanged"), _obj)), _obj));

  _exports.default = _default;
});