define("discourse/components/user-stat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["user-stat"],
    type: "number",
    isNumber: Ember.computed.equal("type", "number"),
    isDuration: Ember.computed.equal("type", "duration")
  });

  _exports.default = _default;
});