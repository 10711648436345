define("discourse/helpers/custom-html", ["exports", "discourse/lib/preload-store"], function (_exports, _preloadStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getCustomHTML = getCustomHTML;
  _exports.clearHTMLCache = clearHTMLCache;
  _exports.setCustomHTML = setCustomHTML;
  var _customizations = {};

  function getCustomHTML(key) {
    var c = _customizations[key];

    if (c) {
      return Ember.String.htmlSafe(c);
    }

    var html = _preloadStore.default.get("customHTML");

    if (html && html[key] && html[key].length) {
      return Ember.String.htmlSafe(html[key]);
    }
  }

  function clearHTMLCache() {
    _customizations = {};
  } // Set a fragment of HTML by key. It can then be looked up with `getCustomHTML(key)`.


  function setCustomHTML(key, html) {
    _customizations[key] = html;
  }
});