define("discourse/components/group-member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["item"],
    actions: {
      remove: function remove() {
        this.removeAction(this.member);
      }
    }
  });

  _exports.default = _default;
});