define("discourse/components/edit-category-security", ["exports", "discourse/models/permission-type", "discourse/components/edit-category-panel", "discourse-common/utils/decorators"], function (_exports, _permissionType, _editCategoryPanel, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = (0, _editCategoryPanel.buildCategoryPanel)("security", (_dec = (0, _decorators.default)("category.permissions.@each.permission_type"), _dec2 = (0, _decorators.default)("category.permissions.@each.permission_type"), _dec3 = (0, _decorators.default)("everyonePermission"), (_obj = {
    selectedGroup: null,
    noGroupSelected: Ember.computed.not("selectedGroup"),
    everyonePermission: function everyonePermission(permissions) {
      return permissions.findBy("group_name", "everyone");
    },
    everyoneGrantedFull: function everyoneGrantedFull() {
      return this.everyonePermission && this.everyonePermission.permission_type === _permissionType.default.FULL;
    },
    minimumPermission: function minimumPermission(everyonePermission) {
      return everyonePermission ? everyonePermission.permission_type : _permissionType.default.READONLY;
    },
    actions: {
      onSelectGroup: function onSelectGroup(group_name) {
        this.category.addPermission({
          group_name: group_name,
          permission_type: this.minimumPermission
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "everyonePermission", [_dec], Object.getOwnPropertyDescriptor(_obj, "everyonePermission"), _obj), _applyDecoratedDescriptor(_obj, "everyoneGrantedFull", [_dec2], Object.getOwnPropertyDescriptor(_obj, "everyoneGrantedFull"), _obj), _applyDecoratedDescriptor(_obj, "minimumPermission", [_dec3], Object.getOwnPropertyDescriptor(_obj, "minimumPermission"), _obj)), _obj)));

  _exports.default = _default;
});