define("discourse/templates/review-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A53ZkNGv",
    "block": "{\"symbols\":[\"rst\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"reviewable-settings\"],[8],[0,\"\\n  \"],[7,\"h4\",true],[8],[1,[28,\"i18n\",[\"review.settings.priorities.title\"],null],false],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"scoreTypes\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"reviewable-score-type\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[23,1,[\"title\"]],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n        \"],[1,[28,\"combo-box\",null,[[\"value\",\"content\",\"onChange\"],[[23,1,[\"reviewable_priority\"]],[24,[\"settings\",\"reviewable_priorities\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,1,[\"reviewable_priority\"]]],null]],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"reviewable-score-type\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n      \"],[1,[28,\"d-button\",null,[[\"icon\",\"label\",\"class\",\"action\",\"disabled\"],[\"check\",\"review.settings.save_changes\",\"btn-primary save-settings\",[28,\"action\",[[23,0,[]],\"save\"],null],[24,[\"saving\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"saved\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"saved\"],[8],[1,[28,\"i18n\",[\"review.settings.saved\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/review-settings.hbs"
    }
  });

  _exports.default = _default;
});