define("discourse/components/tag-groups-form", ["exports", "discourse/models/group", "I18n", "discourse/models/permission-type", "bootbox", "discourse/mixins/buffered-content", "discourse-common/utils/decorators"], function (_exports, _group, _I18n, _permissionType, _bootbox, _bufferedContent, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((0, _bufferedContent.bufferedProperty)("model"), (_dec = (0, _decorators.default)("buffered.name", "buffered.tag_names", "buffered.permissions"), _dec2 = (0, _decorators.default)("buffered.permissions", "allGroups"), (_obj = {
    router: Ember.inject.service(),
    tagName: "",
    allGroups: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.setGroupOptions();
    },
    setGroupOptions: function setGroupOptions() {
      var _this = this;

      _group.default.findAll().then(function (groups) {
        _this.set("allGroups", groups);
      });
    },
    cannotSave: function cannotSave(name, tagNames, permissions) {
      return Ember.isEmpty(name) || Ember.isEmpty(tagNames) || !this.everyoneSelected(permissions) && Ember.isEmpty(this.selectedGroupNames(permissions));
    },
    selectedGroupIds: function selectedGroupIds(permissions, allGroups) {
      if (!permissions || !allGroups) {
        return [];
      }

      var selectedGroupNames = Object.keys(permissions);
      var groupIds = [];
      allGroups.forEach(function (group) {
        if (selectedGroupNames.includes(group.name)) {
          groupIds.push(group.id);
        }
      });
      return groupIds;
    },
    everyoneSelected: function everyoneSelected(permissions) {
      if (!permissions) {
        return true;
      }

      return permissions.everyone === _permissionType.default.FULL;
    },
    selectedGroupNames: function selectedGroupNames(permissions) {
      if (!permissions) {
        return [];
      }

      return Object.keys(permissions).filter(function (name) {
        return name !== "everyone";
      });
    },
    actions: {
      setPermissionsType: function setPermissionsType(permissionName) {
        var updatedPermissions = Object.assign({}, this.buffered.get("permissions"));

        if (permissionName === "private") {
          delete updatedPermissions.everyone;
        } else if (permissionName === "visible") {
          updatedPermissions.everyone = _permissionType.default.READONLY;
        } else {
          updatedPermissions.everyone = _permissionType.default.FULL;
        }

        this.buffered.set("permissions", updatedPermissions);
      },
      setPermissionsGroups: function setPermissionsGroups(groupIds) {
        var updatedPermissions = Object.assign({}, this.buffered.get("permissions"));
        this.allGroups.forEach(function (group) {
          if (groupIds.includes(group.id)) {
            updatedPermissions[group.name] = _permissionType.default.FULL;
          } else {
            delete updatedPermissions[group.name];
          }
        });
        this.buffered.set("permissions", updatedPermissions);
      },
      save: function save() {
        var _this2 = this;

        if (this.cannotSave) {
          _bootbox.default.alert(_I18n.default.t("tagging.groups.cannot_save"));

          return false;
        }

        var attrs = this.buffered.getProperties("name", "tag_names", "parent_tag_name", "one_per_topic", "permissions"); // If 'everyone' is set to full, we can remove any groups.

        if (!attrs.permissions || attrs.permissions.everyone === _permissionType.default.FULL) {
          attrs.permissions = {
            everyone: _permissionType.default.FULL
          };
        }

        this.model.save(attrs).then(function () {
          _this2.commitBuffer();

          if (_this2.onSave) {
            _this2.onSave();
          } else {
            _this2.router.transitionTo("tagGroups.index");
          }
        });
      },
      destroy: function destroy() {
        var _this3 = this;

        return _bootbox.default.confirm(_I18n.default.t("tagging.groups.confirm_delete"), _I18n.default.t("no_value"), _I18n.default.t("yes_value"), function (destroy) {
          if (!destroy) {
            return;
          }

          _this3.model.destroyRecord().then(function () {
            if (_this3.onDestroy) {
              _this3.onDestroy();
            }
          });
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "cannotSave", [_dec], Object.getOwnPropertyDescriptor(_obj, "cannotSave"), _obj), _applyDecoratedDescriptor(_obj, "selectedGroupIds", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectedGroupIds"), _obj)), _obj)));

  _exports.default = _default;
});