define("discourse/routes/exception-unknown", ["exports", "discourse/routes/unknown"], function (_exports, _unknown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _unknown.default.extend({
    renderTemplate: function renderTemplate() {
      this.render("unknown");
    }
  });

  _exports.default = _default;
});