define("discourse/routes/preferences-account", ["exports", "discourse/routes/restricted-user", "discourse/models/user-badge", "discourse/lib/show-modal"], function (_exports, _restrictedUser, _userBadge, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _restrictedUser.default.extend((_dec = Ember._action, (_obj = {
    showFooter: true,
    model: function model() {
      var user = this.modelFor("user");

      if (this.siteSettings.enable_badges) {
        return _userBadge.default.findByUsername(user.get("username")).then(function (userBadges) {
          user.set("badges", userBadges.map(function (ub) {
            return ub.badge;
          }));
          return user;
        });
      } else {
        return user;
      }
    },
    setupController: function setupController(controller, user) {
      controller.reset();
      controller.setProperties({
        model: user,
        newNameInput: user.get("name"),
        newTitleInput: user.get("title"),
        newPrimaryGroupInput: user.get("primary_group_id"),
        newFlairGroupId: user.get("flair_group_id")
      });
    },
    showAvatarSelector: function showAvatarSelector(user) {
      (0, _showModal.default)("avatar-selector").setProperties({
        user: user
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "showAvatarSelector", [_dec], Object.getOwnPropertyDescriptor(_obj, "showAvatarSelector"), _obj)), _obj)));

  _exports.default = _default;
});