define("discourse/templates/components/private-message-glyph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+u6wAdG+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"shouldShow\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"href\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[11,\"href\",[22,\"href\"]],[11,\"title\",[28,\"i18n\",[[24,[\"title\"]]],null]],[11,\"aria-label\",[28,\"i18n\",[[24,[\"ariaLabel\"]]],null]],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"private-message-glyph-wrapper\"],[8],[0,\"\\n        \"],[1,[28,\"d-icon\",[\"envelope\"],[[\"class\"],[\"private-message-glyph\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"private-message-glyph-wrapper\"],[8],[0,\"\\n      \"],[1,[28,\"d-icon\",[\"envelope\"],[[\"class\"],[\"private-message-glyph\"]]],false],[0,\"\\n    \"],[9]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/private-message-glyph.hbs"
    }
  });

  _exports.default = _default;
});