define("discourse/components/group-member-dropdown", ["exports", "select-kit/components/dropdown-select-box", "I18n"], function (_exports, _dropdownSelectBox, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dropdownSelectBox.default.extend({
    pluginApiIdentifiers: ["group-member-dropdown"],
    classNames: ["group-member-dropdown"],
    selectKitOptions: {
      icon: "wrench",
      showFullTitle: false
    },
    contentBulk: function contentBulk() {
      var items = [];
      items.push({
        id: "removeMembers",
        name: _I18n.default.t("groups.members.remove_members"),
        description: _I18n.default.t("groups.members.remove_members_description"),
        icon: "user-times"
      });

      if (this.bulkSelection.some(function (m) {
        return !m.owner;
      })) {
        items.push({
          id: "makeOwners",
          name: _I18n.default.t("groups.members.make_owners"),
          description: _I18n.default.t("groups.members.make_owners_description"),
          icon: "shield-alt"
        });
      }

      if (this.bulkSelection.some(function (m) {
        return m.owner;
      })) {
        items.push({
          id: "removeOwners",
          name: _I18n.default.t("groups.members.remove_owners"),
          description: _I18n.default.t("groups.members.remove_owners_description"),
          icon: "shield-alt"
        });
      }

      if (this.bulkSelection.some(function (m) {
        return !m.primary;
      })) {
        items.push({
          id: "setPrimary",
          name: _I18n.default.t("groups.members.make_all_primary"),
          description: _I18n.default.t("groups.members.make_all_primary_description"),
          icon: "id-card"
        });
      }

      if (this.bulkSelection.some(function (m) {
        return m.primary;
      })) {
        items.push({
          id: "unsetPrimary",
          name: _I18n.default.t("groups.members.remove_all_primary"),
          description: _I18n.default.t("groups.members.remove_all_primary_description"),
          icon: "id-card"
        });
      }

      return items;
    },
    contentSingle: function contentSingle() {
      var items = [{
        id: "removeMember",
        name: _I18n.default.t("groups.members.remove_member"),
        description: _I18n.default.t("groups.members.remove_member_description", {
          username: this.get("member.username")
        }),
        icon: "user-times"
      }];

      if (this.canAdminGroup) {
        if (this.member.owner) {
          items.push({
            id: "removeOwner",
            name: _I18n.default.t("groups.members.remove_owner"),
            description: _I18n.default.t("groups.members.remove_owner_description", {
              username: this.get("member.username")
            }),
            icon: "shield-alt"
          });
        } else {
          items.push({
            id: "makeOwner",
            name: _I18n.default.t("groups.members.make_owner"),
            description: _I18n.default.t("groups.members.make_owner_description", {
              username: this.get("member.username")
            }),
            icon: "shield-alt"
          });
        }
      }

      if (this.currentUser.staff) {
        if (this.member.primary) {
          items.push({
            id: "removePrimary",
            name: _I18n.default.t("groups.members.remove_primary"),
            description: _I18n.default.t("groups.members.remove_primary_description", {
              username: this.get("member.username")
            }),
            icon: "id-card"
          });
        } else {
          items.push({
            id: "makePrimary",
            name: _I18n.default.t("groups.members.make_primary"),
            description: _I18n.default.t("groups.members.make_primary_description", {
              username: this.get("member.username")
            }),
            icon: "id-card"
          });
        }
      }

      return items;
    },
    content: Ember.computed("bulkSelection.[]", "member.owner", "member.primary", function () {
      return this.bulkSelection !== undefined ? this.contentBulk() : this.contentSingle();
    })
  });

  _exports.default = _default;
});