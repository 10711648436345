define("discourse/templates/components/pick-files-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EkwGOz/y",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"d-button\",null,[[\"action\",\"label\",\"icon\"],[[28,\"action\",[[23,0,[]],\"openSystemFilePicker\"],null],[24,[\"label\"]],[24,[\"icon\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"acceptAnyFile\"]]],null,{\"statements\":[[0,\"  \"],[7,\"input\",true],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"input\",true],[11,\"accept\",[22,\"acceptedFileTypesString\"]],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/pick-files-button.hbs"
    }
  });

  _exports.default = _default;
});