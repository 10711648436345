define("discourse/templates/components/reviewable-flagged-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iRg48DS7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flagged-post-header\"],[8],[0,\"\\n  \"],[1,[28,\"reviewable-topic-link\",null,[[\"reviewable\",\"tagName\"],[[24,[\"reviewable\"]],\"\"]]],false],[0,\"\\n  \"],[1,[28,\"reviewable-post-edits\",null,[[\"reviewable\",\"tagName\"],[[24,[\"reviewable\"]],\"\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"post-contents-wrapper\"],[8],[0,\"\\n  \"],[1,[28,\"reviewable-created-by\",null,[[\"user\",\"tagName\"],[[24,[\"reviewable\",\"target_created_by\"]],\"\"]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"post-contents\"],[8],[0,\"\\n    \"],[1,[28,\"reviewable-post-header\",null,[[\"reviewable\",\"createdBy\",\"tagName\"],[[24,[\"reviewable\"]],[24,[\"reviewable\",\"target_created_by\"]],\"\"]]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"post-body\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"reviewable\",\"blank_post\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[8],[1,[28,\"i18n\",[\"review.deleted_post\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"html-safe\",[[24,[\"reviewable\",\"cooked\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n    \"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"tagName\",\"connectorTagName\",\"args\"],[\"after-reviewable-flagged-post-body\",\"span\",\"div\",[28,\"hash\",null,[[\"model\"],[[24,[\"reviewable\"]]]]]]]],false],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-flagged-post.hbs"
    }
  });

  _exports.default = _default;
});