define("discourse/components/reviewable-claimed-topic", ["exports", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/ajax-error"], function (_exports, _ajax, _decorators, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_obj = {
    tagName: "",
    enabled: function enabled() {
      return this.siteSettings.reviewable_claiming !== "disabled";
    },
    actions: {
      unclaim: function unclaim() {
        var _this = this;

        (0, _ajax.ajax)("/reviewable_claimed_topics/".concat(this.topicId), {
          type: "DELETE"
        }).then(function () {
          _this.set("claimedBy", null);
        });
      },
      claim: function claim() {
        var _this2 = this;

        var claim = this.store.createRecord("reviewable-claimed-topic");
        claim.save({
          topic_id: this.topicId
        }).then(function () {
          _this2.set("claimedBy", _this2.currentUser);
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "enabled", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "enabled"), _obj)), _obj));

  _exports.default = _default;
});