define("discourse/initializers/opengraph-tag-updater", ["exports", "discourse-common/lib/get-url"], function (_exports, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "opengraph-tag-updater",
    initialize: function initialize(container) {
      // workaround for Safari on iOS 14.3
      // seems it has started using opengraph tags when sharing
      var ogTitle = document.querySelector("meta[property='og:title']");
      var ogUrl = document.querySelector("meta[property='og:url']");

      if (!ogTitle || !ogUrl) {
        return;
      }

      var appEvents = container.lookup("service:app-events");
      appEvents.on("page:changed", function (_ref) {
        var title = _ref.title,
            url = _ref.url;
        ogTitle.setAttribute("content", title);
        ogUrl.setAttribute("content", (0, _getUrl.getAbsoluteURL)(url));
      });
    }
  };
  _exports.default = _default;
});