define("discourse/components/site-header", ["exports", "discourse/mixins/pan-events", "discourse/mixins/docking", "discourse/components/mount-widget", "@discourse/itsatrap", "discourse/mixins/rerender-on-do-not-disturb-change", "discourse/lib/offset-calculator", "discourse-common/utils/decorators", "discourse/components/topic-title"], function (_exports, _panEvents, _docking, _mountWidget, _itsatrap, _rerenderOnDoNotDisturbChange, _offsetCalculator, _decorators, _topicTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.headerTop = headerTop;
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var SiteHeaderComponent = _mountWidget.default.extend(_docking.default, _panEvents.default, _rerenderOnDoNotDisturbChange.default, (_dec = (0, _decorators.observes)("currentUser.unread_notifications", "currentUser.unread_high_priority_notifications", "currentUser.reviewable_count", "session.defaultColorSchemeIsDark", "session.darkModeAvailable"), (_obj = {
    widget: "header",
    docAt: null,
    dockedHeader: null,
    _animate: false,
    _isPanning: false,
    _panMenuOrigin: "right",
    _panMenuOffset: 0,
    _scheduledRemoveAnimate: null,
    _topic: null,
    _itsatrap: null,
    notificationsChanged: function notificationsChanged() {
      this.queueRerender();
    },
    _animateOpening: function _animateOpening(panel) {
      var headerCloak = document.querySelector(".header-cloak");
      panel.classList.add("animate");
      headerCloak.classList.add("animate");
      this._scheduledRemoveAnimate = Ember.run.later(function () {
        panel.classList.remove("animate");
        headerCloak.classList.remove("animate");
      }, 200);
      panel.style.setProperty("--offset", 0);
      headerCloak.style.setProperty("--opacity", 0.5);
      this._panMenuOffset = 0;
    },
    _animateClosing: function _animateClosing(panel, menuOrigin) {
      var _this = this;

      var windowWidth = document.body.offsetWidth;
      this._animate = true;
      var headerCloak = document.querySelector(".header-cloak");
      panel.classList.add("animate");
      headerCloak.classList.add("animate");
      var offsetDirection = menuOrigin === "left" ? -1 : 1;
      panel.style.setProperty("--offset", "".concat(offsetDirection * windowWidth, "px"));
      headerCloak.style.setProperty("--opacity", 0);
      this._scheduledRemoveAnimate = Ember.run.later(function () {
        panel.classList.remove("animate");
        headerCloak.classList.remove("animate");
        Ember.run.schedule("afterRender", function () {
          _this.eventDispatched("dom:clean", "header");

          _this._panMenuOffset = 0;
        });
      }, 200);
    },
    _isRTL: function _isRTL() {
      return document.querySelector("html").classList["direction"] === "rtl";
    },
    _leftMenuClass: function _leftMenuClass() {
      return this._isRTL() ? "user-menu" : "hamburger-panel";
    },
    _leftMenuAction: function _leftMenuAction() {
      return this._isRTL() ? "toggleUserMenu" : "toggleHamburger";
    },
    _rightMenuAction: function _rightMenuAction() {
      return this._isRTL() ? "toggleHamburger" : "toggleUserMenu";
    },
    _handlePanDone: function _handlePanDone(event) {
      var _this2 = this;

      var menuPanels = document.querySelectorAll(".menu-panel");
      var menuOrigin = this._panMenuOrigin;
      menuPanels.forEach(function (panel) {
        panel.classList.remove("moving");

        if (_this2._shouldMenuClose(event, menuOrigin)) {
          _this2._animateClosing(panel, menuOrigin);
        } else {
          _this2._animateOpening(panel);
        }
      });
    },
    _shouldMenuClose: function _shouldMenuClose(e, menuOrigin) {
      // menu should close after a pan either:
      // if a user moved the panel closed past a threshold and away and is NOT swiping back open
      // if a user swiped to close fast enough regardless of distance
      if (menuOrigin === "right") {
        return e.deltaX > _panEvents.SWIPE_DISTANCE_THRESHOLD && e.velocityX > -_panEvents.SWIPE_VELOCITY_THRESHOLD || e.velocityX > 0;
      } else {
        return e.deltaX < -_panEvents.SWIPE_DISTANCE_THRESHOLD && e.velocityX < _panEvents.SWIPE_VELOCITY_THRESHOLD || e.velocityX < 0;
      }
    },
    panStart: function panStart(e) {
      var center = e.center;
      var panOverValidElement = document.elementsFromPoint(center.x, center.y).some(function (ele) {
        return ele.classList.contains("panel-body") || ele.classList.contains("header-cloak");
      });

      if (panOverValidElement && (e.direction === "left" || e.direction === "right")) {
        e.originalEvent.preventDefault();
        this._isPanning = true;
        var panel = document.querySelector(".menu-panel");

        if (panel) {
          panel.classList.add("moving");
        }
      } else {
        this._isPanning = false;
      }
    },
    panEnd: function panEnd(e) {
      if (!this._isPanning) {
        return;
      }

      this._isPanning = false;

      this._handlePanDone(e);
    },
    panMove: function panMove(e) {
      if (!this._isPanning) {
        return;
      }

      var panel = document.querySelector(".menu-panel");
      var headerCloak = document.querySelector(".header-cloak");

      if (this._panMenuOrigin === "right") {
        var pxClosed = Math.min(0, -e.deltaX + this._panMenuOffset);
        panel.style.setProperty("--offset", "".concat(-pxClosed, "px"));
        headerCloak.style.setProperty("--opacity", Math.min(0.5, (300 + pxClosed) / 600));
      } else {
        var _pxClosed = Math.min(0, e.deltaX + this._panMenuOffset);

        panel.style.setProperty("--offset", "".concat(_pxClosed, "px"));
        headerCloak.style.setProperty("--opacity", Math.min(0.5, (300 + _pxClosed) / 600));
      }
    },
    dockCheck: function dockCheck() {
      var header = document.querySelector("header.d-header");

      if (this.docAt === null) {
        if (!header) {
          return;
        }

        this.docAt = header.offsetTop;
      }

      var headerRect = header.getBoundingClientRect();
      var headerOffsetCalc = headerRect.top + headerRect.height;

      if (window.scrollY < 0) {
        headerOffsetCalc += window.scrollY;
      }

      var newValue = "".concat(headerOffsetCalc, "px");

      if (newValue !== this.currentHeaderOffsetValue) {
        this.currentHeaderOffsetValue = newValue;
        document.documentElement.style.setProperty("--header-offset", newValue);
      }

      if (window.pageYOffset >= this.docAt) {
        if (!this.dockedHeader) {
          document.body.classList.add("docked");
          this.dockedHeader = true;
        }
      } else {
        if (this.dockedHeader) {
          document.body.classList.remove("docked");
          this.dockedHeader = false;
        }
      }
    },
    setTopic: function setTopic(topic) {
      this.eventDispatched("dom:clean", "header");
      this._topic = topic;
      this.queueRerender();
    },
    willRender: function willRender() {
      if (this.get("currentUser.staff")) {
        document.body.classList.add("staff");
      }
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this._super.apply(this, arguments);

      this._resizeDiscourseMenuPanel = function () {
        return _this3.afterRender();
      };

      window.addEventListener("resize", this._resizeDiscourseMenuPanel);
      this.appEvents.on("header:show-topic", this, "setTopic");
      this.appEvents.on("header:hide-topic", this, "setTopic");
      this.dispatch("notifications:changed", "user-notifications");
      this.dispatch("header:keyboard-trigger", "header");
      this.dispatch("user-menu:navigation", "user-menu");
      this.appEvents.on("dom:clean", this, "_cleanDom");

      if (this.currentUser && !this.get("currentUser.read_first_notification")) {
        document.body.classList.add("unread-first-notification");
      } // Allow first notification to be dismissed on a click anywhere


      if (this.currentUser && !this.get("currentUser.read_first_notification") && !this.get("currentUser.enforcedSecondFactor")) {
        this._dismissFirstNotification = function (e) {
          if (document.body.classList.contains("unread-first-notification")) {
            document.body.classList.remove("unread-first-notification");
          }

          if (!e.target.closest("#current-user") && !e.target.closest(".ring-backdrop") && _this3.currentUser && !_this3.get("currentUser.read_first_notification") && !_this3.get("currentUser.enforcedSecondFactor")) {
            _this3.eventDispatched("header:dismiss-first-notification-mask", "header");
          }
        };

        document.addEventListener("click", this._dismissFirstNotification, {
          once: true
        });
      }

      var header = document.querySelector("header.d-header");
      this._itsatrap = new _itsatrap.default(header);

      this._itsatrap.bind(["right", "left"], function (e) {
        var activeTab = document.querySelector(".glyphs .menu-link.active");

        if (activeTab) {
          var focusedTab = document.activeElement;

          if (!focusedTab.dataset.tabNumber) {
            focusedTab = activeTab;
          }

          _this3.appEvents.trigger("user-menu:navigation", {
            key: e.key,
            tabNumber: Number(focusedTab.dataset.tabNumber)
          });
        }
      });
    },
    _cleanDom: function _cleanDom() {
      // For performance, only trigger a re-render if any menu panels are visible
      if (this.element.querySelector(".menu-panel")) {
        this.eventDispatched("dom:clean", "header");
      }
    },
    willDestroyElement: function willDestroyElement() {
      var _this$_itsatrap;

      this._super.apply(this, arguments);

      window.removeEventListener("resize", this._resizeDiscourseMenuPanel);
      this.appEvents.off("header:show-topic", this, "setTopic");
      this.appEvents.off("header:hide-topic", this, "setTopic");
      this.appEvents.off("dom:clean", this, "_cleanDom");
      Ember.run.cancel(this._scheduledRemoveAnimate);
      (_this$_itsatrap = this._itsatrap) === null || _this$_itsatrap === void 0 ? void 0 : _this$_itsatrap.destroy();
      this._itsatrap = null;
      document.removeEventListener("click", this._dismissFirstNotification);
    },
    buildArgs: function buildArgs() {
      return {
        topic: this._topic,
        canSignUp: this.canSignUp
      };
    },
    afterRender: function afterRender() {
      var _this4 = this;

      var headerTitle = document.querySelector(".header-title .topic-link");

      if (headerTitle && this._topic) {
        _topicTitle.topicTitleDecorators.forEach(function (cb) {
          return cb(_this4._topic, headerTitle, "header-title");
        });
      }

      var menuPanels = document.querySelectorAll(".menu-panel");

      if (menuPanels.length === 0) {
        if (this.site.mobileView) {
          this._animate = true;
        }

        return;
      }

      var windowWidth = document.body.offsetWidth;
      var viewMode = this.site.mobileView ? "slide-in" : "drop-down";
      menuPanels.forEach(function (panel) {
        var headerCloak = document.querySelector(".header-cloak");
        var width = parseInt(panel.getAttribute("data-max-width"), 10) || 300;

        if (windowWidth - width < 50) {
          width = windowWidth - 50;
        }

        if (_this4._panMenuOffset) {
          _this4._panMenuOffset = -width;
        }

        panel.classList.remove("drop-down");
        panel.classList.remove("slide-in");
        panel.classList.add(viewMode);

        if (_this4._animate || _this4._panMenuOffset !== 0) {
          if (_this4.site.mobileView && panel.parentElement.classList.contains(_this4._leftMenuClass())) {
            _this4._panMenuOrigin = "left";
            panel.style.setProperty("--offset", "".concat(-windowWidth, "px"));
          } else {
            _this4._panMenuOrigin = "right";
            panel.style.setProperty("--offset", "".concat(windowWidth, "px"));
          }

          headerCloak.style.setProperty("--opacity", 0);
        }

        var panelBody = panel.querySelector(".panel-body"); // We use a mutationObserver to check for style changes, so it's important
        // we don't set it if it doesn't change. Same goes for the panelBody!

        if (viewMode === "drop-down") {
          var buttonPanel = document.querySelectorAll("header ul.icons");

          if (buttonPanel.length === 0) {
            return;
          } // These values need to be set here, not in the css file - this is to deal with the
          // possibility of the window being resized and the menu changing from .slide-in to .drop-down.


          if (panel.style.top !== "100%" || panel.style.height !== "auto") {
            panel.style.setProperty("top", "100%");
            panel.style.setProperty("height", "auto");
          }

          document.body.classList.add("drop-down-mode");
        } else {
          if (_this4.site.mobileView) {
            headerCloak.style.display = "block";
          }

          var menuTop = _this4.site.mobileView ? headerTop() : (0, _offsetCalculator.headerOffset)();
          var winHeightOffset = 16;
          var initialWinHeight = window.innerHeight;
          var winHeight = initialWinHeight - winHeightOffset;
          var height;

          if (_this4.site.mobileView) {
            height = winHeight - menuTop;
          }

          var isIPadApp = document.body.classList.contains("footer-nav-ipad"),
              heightProp = isIPadApp ? "max-height" : "height",
              iPadOffset = 10;

          if (isIPadApp) {
            height = winHeight - menuTop - iPadOffset;
          }

          if (panelBody.style.height !== "100%") {
            panelBody.style.setProperty("height", "100%");
          }

          if (panel.style.top !== "".concat(menuTop, "px") || panel.style[heightProp] !== "".concat(height, "px")) {
            panel.style.top = "".concat(menuTop, "px");
            panel.style.setProperty(heightProp, "".concat(height, "px"));

            if (headerCloak) {
              headerCloak.style.top = "".concat(menuTop, "px");
            }
          }

          document.body.classList.remove("drop-down-mode");
        }

        panel.style.setProperty("width", "".concat(width, "px"));

        if (_this4._animate) {
          _this4._animateOpening(panel);
        }

        _this4._animate = false;
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "notificationsChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "notificationsChanged"), _obj)), _obj)));

  var _default = SiteHeaderComponent.extend({
    classNames: ["d-header-wrap"]
  });

  _exports.default = _default;

  function headerTop() {
    var header = document.querySelector("header.d-header");
    var headerOffsetTop = header.offsetTop ? header.offsetTop : 0;
    return headerOffsetTop - document.body.scrollTop;
  }
});