define("discourse/components/bookmark-list", ["exports", "bootbox", "discourse-common/lib/debounce", "discourse/controllers/bookmark", "discourse/lib/click-track", "discourse/mixins/scrolling", "I18n"], function (_exports, _bootbox, _debounce, _bookmark, _clickTrack, _scrolling, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_scrolling.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    classNames: ["bookmark-list-wrapper"],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.bindScrolling();
      this.scrollToLastPosition();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.unbindScrolling();
    },
    scrollToLastPosition: function scrollToLastPosition() {
      var _this = this;

      var scrollTo = this.session.bookmarkListScrollPosition;

      if (scrollTo && scrollTo >= 0) {
        Ember.run.schedule("afterRender", function () {
          (0, _debounce.default)(_this, function () {
            if (this.element && !this.isDestroying && !this.isDestroyed) {
              window.scrollTo(0, scrollTo + 1);
            }
          }, 0);
        });
      }
    },
    scrolled: function scrolled() {
      this._super.apply(this, arguments);

      this.session.set("bookmarkListScrollPosition", window.scrollY);
    },
    removeBookmark: function removeBookmark(bookmark) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var deleteBookmark = function deleteBookmark() {
          bookmark.destroy().then(function () {
            _this2.appEvents.trigger("bookmarks:changed", null, bookmark.attachedTo());

            _this2._removeBookmarkFromList(bookmark);

            resolve(true);
          }).catch(function (error) {
            reject(error);
          });
        };

        if (!bookmark.reminder_at) {
          return deleteBookmark();
        }

        _bootbox.default.confirm(_I18n.default.t("bookmarks.confirm_delete"), function (result) {
          if (result) {
            deleteBookmark();
          } else {
            resolve(false);
          }
        });
      });
    },
    screenExcerptForExternalLink: function screenExcerptForExternalLink(event) {
      if (event.target && event.target.tagName === "A") {
        var link = event.target;

        if ((0, _clickTrack.shouldOpenInNewTab)(link.href)) {
          (0, _clickTrack.openLinkInNewTab)(link);
        }
      }
    },
    editBookmark: function editBookmark(bookmark) {
      var _this3 = this;

      (0, _bookmark.openBookmarkModal)(bookmark, {
        onAfterSave: function onAfterSave(savedData) {
          _this3.appEvents.trigger("bookmarks:changed", savedData, bookmark.attachedTo());

          _this3.reload();
        },
        onAfterDelete: function onAfterDelete() {
          _this3.reload();
        }
      });
    },
    togglePinBookmark: function togglePinBookmark(bookmark) {
      bookmark.togglePin().then(this.reload);
    },
    _removeBookmarkFromList: function _removeBookmarkFromList(bookmark) {
      this.content.removeObject(bookmark);
    }
  }, (_applyDecoratedDescriptor(_obj, "removeBookmark", [_dec], Object.getOwnPropertyDescriptor(_obj, "removeBookmark"), _obj), _applyDecoratedDescriptor(_obj, "screenExcerptForExternalLink", [_dec2], Object.getOwnPropertyDescriptor(_obj, "screenExcerptForExternalLink"), _obj), _applyDecoratedDescriptor(_obj, "editBookmark", [_dec3], Object.getOwnPropertyDescriptor(_obj, "editBookmark"), _obj), _applyDecoratedDescriptor(_obj, "togglePinBookmark", [_dec4], Object.getOwnPropertyDescriptor(_obj, "togglePinBookmark"), _obj)), _obj)));

  _exports.default = _default;
});