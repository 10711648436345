define("discourse/models/topic-tracking-state", ["exports", "discourse-common/utils/decorators", "discourse/models/category", "discourse-common/lib/object", "discourse/lib/url", "discourse/lib/notification-levels", "discourse/lib/preload-store", "discourse/models/user", "discourse/models/site"], function (_exports, _decorators, _category, _object, _url, _notificationLevels, _preloadStore, _user, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startTracking = startTracking;
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function isNew(topic) {
    return topic.last_read_post_number === null && (topic.notification_level !== 0 && !topic.notification_level || topic.notification_level >= _notificationLevels.NotificationLevels.TRACKING) && topic.created_in_new_period && isUnseen(topic);
  }

  function isUnread(topic) {
    return topic.last_read_post_number !== null && topic.last_read_post_number < topic.highest_post_number && topic.notification_level >= _notificationLevels.NotificationLevels.TRACKING && topic.unread_not_too_old;
  }

  function isUnseen(topic) {
    return !topic.is_seen;
  }

  function hasMutedTags(topicTagIds, mutedTagIds, siteSettings) {
    if (!mutedTagIds || !topicTagIds) {
      return false;
    }

    return siteSettings.remove_muted_tags_from_latest === "always" && topicTagIds.any(function (tagId) {
      return mutedTagIds.includes(tagId);
    }) || siteSettings.remove_muted_tags_from_latest === "only_muted" && topicTagIds.every(function (tagId) {
      return mutedTagIds.includes(tagId);
    });
  }

  var TopicTrackingState = Ember.Object.extend((_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.default)("incomingCount"), (_obj = {
    messageCount: 0,
    _setup: function _setup() {
      this.states = new Map();
      this.stateChangeCallbacks = {};
      this._trackedTopicLimit = 4000;
    },

    /**
     * Subscribe to MessageBus channels which are used for publishing changes
     * to the tracking state. Each message received will modify state for
     * a particular topic.
     *
     * See app/models/topic_tracking_state.rb for the data payloads published
     * to each of the channels.
     *
     * @method establishChannels
     */
    establishChannels: function establishChannels() {
      var _this = this;

      this.messageBus.subscribe("/new", this._processChannelPayload);
      this.messageBus.subscribe("/latest", this._processChannelPayload);

      if (this.currentUser) {
        this.messageBus.subscribe("/unread/".concat(this.currentUser.id), this._processChannelPayload);
      }

      this.messageBus.subscribe("/delete", function (msg) {
        _this.modifyStateProp(msg, "deleted", true);

        _this.incrementMessageCount();
      });
      this.messageBus.subscribe("/recover", function (msg) {
        _this.modifyStateProp(msg, "deleted", false);

        _this.incrementMessageCount();
      });
      this.messageBus.subscribe("/destroy", function (msg) {
        _this.incrementMessageCount();

        var currentRoute = _url.default.router.currentRoute.parent;

        if (currentRoute.name === "topic" && parseInt(currentRoute.params.id, 10) === msg.topic_id) {
          _url.default.redirectTo("/");
        }
      });
    },
    mutedTopics: function mutedTopics() {
      return this.currentUser && this.currentUser.muted_topics || [];
    },
    unmutedTopics: function unmutedTopics() {
      return this.currentUser && this.currentUser.unmuted_topics || [];
    },
    trackMutedOrUnmutedTopic: function trackMutedOrUnmutedTopic(data) {
      var topics, key;

      if (data.message_type === "muted") {
        key = "muted_topics";
        topics = this.mutedTopics();
      } else {
        key = "unmuted_topics";
        topics = this.unmutedTopics();
      }

      topics = topics.concat({
        topicId: data.topic_id,
        createdAt: Date.now()
      });
      this.currentUser && this.currentUser.set(key, topics);
    },
    pruneOldMutedAndUnmutedTopics: function pruneOldMutedAndUnmutedTopics() {
      var now = Date.now();
      var mutedTopics = this.mutedTopics().filter(function (mutedTopic) {
        return now - mutedTopic.createdAt < 60000;
      });
      var unmutedTopics = this.unmutedTopics().filter(function (unmutedTopic) {
        return now - unmutedTopic.createdAt < 60000;
      });
      this.currentUser && this.currentUser.set("muted_topics", mutedTopics) && this.currentUser.set("unmuted_topics", unmutedTopics);
    },
    isMutedTopic: function isMutedTopic(topicId) {
      return !!this.mutedTopics().findBy("topicId", topicId);
    },
    isUnmutedTopic: function isUnmutedTopic(topicId) {
      return !!this.unmutedTopics().findBy("topicId", topicId);
    },

    /**
     * Updates the topic's last_read_post_number to the highestSeen post
     * number, as long as the topic is being tracked.
     *
     * Calls onStateChange callbacks.
     *
     * @params {Number|String} topicId - The ID of the topic to set last_read_post_number for.
     * @params {Number} highestSeen - The post number of the topic that should be
     *                                used for last_read_post_number.
     * @method updateSeen
     */
    updateSeen: function updateSeen(topicId, highestSeen) {
      if (!topicId || !highestSeen) {
        return;
      }

      var state = this.findState(topicId);

      if (!state) {
        return;
      }

      if (!state.last_read_post_number || state.last_read_post_number < highestSeen) {
        this.modifyStateProp(topicId, "last_read_post_number", highestSeen);
        this.incrementMessageCount();
      }
    },

    /**
     * Used to count incoming topics which will be displayed in a message
     * at the top of the topic list, if hasIncoming is true (which is if
     * incomingCount > 0).
     *
     * This will do nothing unless resetTracking or trackIncoming has been
     * called; newIncoming will be null instead of an array. trackIncoming
     * is called by various topic routes, as is resetTracking.
     *
     * @method notifyIncoming
     * @param {Object} data - The data sent by TopicTrackingState to MessageBus
     *                        which includes the message_type, payload of the topic,
     *                        and the topic_id.
     */
    notifyIncoming: function notifyIncoming(data) {
      if (!this.newIncoming) {
        return;
      }

      if (data.payload && data.payload.archetype === "private_message") {
        return;
      }

      var filter = this.filter;
      var filterCategory = this.filterCategory;
      var filterTag = this.filterTag;
      var categoryId = data.payload && data.payload.category_id; // if we have a filter category currently and it is not the
      // same as the topic category from the payload, then do nothing
      // because it doesn't need to be counted as incoming

      if (filterCategory && filterCategory.get("id") !== categoryId) {
        var category = categoryId && _category.default.findById(categoryId);

        if (!category || category.get("parentCategory.id") !== filterCategory.get("id")) {
          return;
        }
      }

      if (filterTag && !data.payload.tags.includes(filterTag)) {
        return;
      } // always count a new_topic as incoming


      if (["all", "latest", "new", "unseen"].includes(filter) && data.message_type === "new_topic") {
        this._addIncoming(data.topic_id);
      } // count an unread topic as incoming


      if (["all", "unread", "unseen"].includes(filter) && data.message_type === "unread") {
        var old = this.findState(data); // the highest post number is equal to last read post number here
        // because the state has already been modified based on the /unread
        // messageBus message

        if (!old || old.highest_post_number === old.last_read_post_number) {
          this._addIncoming(data.topic_id);
        }
      } // always add incoming if looking at the latest list and a latest channel
      // message comes through


      if (filter === "latest" && data.message_type === "latest") {
        this._addIncoming(data.topic_id);
      } // Add incoming to the 'categories and latest topics' desktop view


      if (filter === "categories" && data.message_type === "latest" && !_site.default.current().mobileView && this.siteSettings.desktop_category_page_style === "categories_and_latest_topics") {
        this._addIncoming(data.topic_id);
      } // hasIncoming relies on this count


      this.set("incomingCount", this.newIncoming.length);
    },

    /**
     * Resets the number of incoming topics to 0 and flushes the new topics
     * from the array. Without calling this or trackIncoming the notifyIncoming
     * method will do nothing.
     *
     * @method resetTracking
     */
    resetTracking: function resetTracking() {
      this.newIncoming = [];
      this.set("incomingCount", 0);
    },

    /**
     * Track how many new topics came for the specified filter.
     *
     * Related/intertwined with notifyIncoming; the filter and filterCategory
     * set here is used to determine whether or not to add incoming counts
     * based on message types of incoming MessageBus messages (via establishChannels)
     *
     * @method trackIncoming
     * @param {String} filter - Valid values are all, categories, and any topic list
     *                          filters e.g. latest, unread, new. As well as this
     *                          specific category and tag URLs like tag/test/l/latest,
     *                          c/cat/subcat/6/l/latest or tags/c/cat/subcat/6/test/l/latest.
     */
    trackIncoming: function trackIncoming(filter) {
      this.newIncoming = [];
      var category, tag;

      if (filter.startsWith("c/") || filter.startsWith("tags/c/")) {
        var categoryId = filter.match(/\/(\d*)\//);
        category = _category.default.findById(parseInt(categoryId[1], 10));
        var split = filter.split("/");

        if (filter.startsWith("tags/c/")) {
          tag = split[split.indexOf(categoryId[1]) + 1];
        }

        if (split.length >= 4) {
          filter = split[split.length - 1];
        }
      } else if (filter.startsWith("tag/")) {
        var _split = filter.split("/");

        filter = _split[_split.length - 1];
        tag = _split[1];
      }

      this.set("filterCategory", category);
      this.set("filterTag", tag);
      this.set("filter", filter);
      this.set("incomingCount", 0);
    },
    hasIncoming: function hasIncoming(incomingCount) {
      return incomingCount && incomingCount > 0;
    },

    /**
     * Removes the topic ID provided from the tracker state.
     *
     * Calls onStateChange callbacks.
     *
     * @param {Number|String} topicId - The ID of the topic to remove from state.
     * @method removeTopic
     */
    removeTopic: function removeTopic(topicId) {
      this.states.delete(this._stateKey(topicId));

      this._afterStateChange();
    },

    /**
     * Removes multiple topics from the state at once, and increments
     * the message count.
     *
     * Calls onStateChange callbacks.
     *
     * @param {Array} topicIds - The IDs of the topic to removes from state.
     * @method removeTopics
     */
    removeTopics: function removeTopics(topicIds) {
      var _this2 = this;

      topicIds.forEach(function (topicId) {
        return _this2.removeTopic(topicId);
      });
      this.incrementMessageCount();

      this._afterStateChange();
    },

    /**
     * If we have a cached topic list, we can update it from our tracking information
     * if the last_read_post_number or is_seen property does not match what the
     * cached topic has.
     *
     * @method updateTopics
     * @param {Array} topics - An array of Topic models.
     */
    updateTopics: function updateTopics(topics) {
      var _this3 = this;

      if (Ember.isEmpty(topics)) {
        return;
      }

      topics.forEach(function (topic) {
        var state = _this3.findState(topic.get("id"));

        if (!state) {
          return;
        }

        var lastRead = topic.get("last_read_post_number");
        var isSeen = topic.get("is_seen");

        if (lastRead !== state.last_read_post_number || isSeen !== state.is_seen) {
          var postsCount = topic.get("posts_count");
          var unread;

          if (state.last_read_post_number) {
            unread = postsCount - state.last_read_post_number;
          } else {
            unread = 0;
          }

          if (unread < 0) {
            unread = 0;
          }

          topic.setProperties({
            highest_post_number: state.highest_post_number,
            last_read_post_number: state.last_read_post_number,
            unread_posts: unread,
            is_seen: state.is_seen,
            unseen: !state.last_read_post_number && isUnseen(state)
          });
        }
      });
    },

    /**
     * Uses the provided topic list to apply changes to the in-memory topic
     * tracking state, remove state as required, and also compensate for missing
     * in-memory state.
     *
     * Any state changes will make a callback to all state change callbacks defined
     * via onStateChange.
     *
     * @method sync
     * @param {TopicList} list
     * @param {String} filter - The filter used for the list e.g. new/unread
     * @param {Object} queryParams - The query parameters for the list e.g. page
     */
    sync: function sync(list, filter, queryParams) {
      if (!list || !list.topics) {
        return;
      } // make sure any server-side state matches reality in the client side


      this._fixDelayedServerState(list, filter); // make sure all the state is up to date with what is accurate
      // from the server


      list.topics.forEach(this._syncStateFromListTopic); // correct missing states, safeguard in case message bus is corrupt

      if (this._shouldCompensateState(list, filter, queryParams)) {
        this._correctMissingState(list, filter);
      }

      this.incrementMessageCount();
    },
    incrementMessageCount: function incrementMessageCount() {
      this.incrementProperty("messageCount");
    },
    _generateCallbackId: function _generateCallbackId() {
      return Math.random().toString(12).substr(2, 9);
    },
    onStateChange: function onStateChange(cb) {
      var callbackId = this._generateCallbackId();

      this.stateChangeCallbacks[callbackId] = cb;
      return callbackId;
    },
    offStateChange: function offStateChange(callbackId) {
      delete this.stateChangeCallbacks[callbackId];
    },
    getSubCategoryIds: function getSubCategoryIds(categoryId) {
      var result = [categoryId];

      var categories = _category.default.list();

      for (var i = 0; i < result.length; ++i) {
        for (var j = 0; j < categories.length; ++j) {
          if (result[i] === categories[j].parent_category_id) {
            result[result.length] = categories[j].id;
          }
        }
      }

      return new Set(result);
    },
    countCategoryByState: function countCategoryByState(type, categoryId, tagId, noSubcategories) {
      var subcategoryIds = noSubcategories ? new Set([categoryId]) : this.getSubCategoryIds(categoryId);
      var mutedCategoryIds = this.currentUser && this.currentUser.muted_category_ids;
      var filterFn = type === "new" ? isNew : isUnread;
      return Array.from(this.states.values()).filter(function (topic) {
        return filterFn(topic) && topic.archetype !== "private_message" && !topic.deleted && (!categoryId || subcategoryIds.has(topic.category_id)) && (!tagId || topic.tags && topic.tags.indexOf(tagId) > -1) && (type !== "new" || !mutedCategoryIds || mutedCategoryIds.indexOf(topic.category_id) === -1);
      }).length;
    },
    countNew: function countNew(categoryId, tagId, noSubcategories) {
      return this.countCategoryByState("new", categoryId, tagId, noSubcategories);
    },
    countUnread: function countUnread(categoryId, tagId, noSubcategories) {
      return this.countCategoryByState("unread", categoryId, tagId, noSubcategories);
    },

    /**
     * Calls the provided callback for each of the currenty tracked topics
     * we have in state.
     *
     * @method forEachTracked
     * @param {Function} fn - The callback function to call with the topic,
     *                        newTopic which is a boolean result of isNew,
     *                        and unreadTopic which is a boolean result of
     *                        isUnread.
     */
    forEachTracked: function forEachTracked(fn) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this._trackedTopics(opts).forEach(function (trackedTopic) {
        fn(trackedTopic.topic, trackedTopic.newTopic, trackedTopic.unreadTopic);
      });
    },

    /**
     * Using the array of tags provided, tallys up all topics via forEachTracked
     * that we are tracking, separated into new/unread/total.
     *
     * Total is only counted if opts.includeTotal is specified.
     *
     * Output (from input ["pending", "bug"]):
     *
     * {
     *   pending: { unreadCount: 6, newCount: 1, totalCount: 10 },
     *   bug: { unreadCount: 0, newCount: 4, totalCount: 20 }
     * }
     *
     * @method countTags
     * @param opts - Valid options:
     *                 * includeTotal - When true, a totalCount is incremented for
     *                                all topics matching a tag.
     */
    countTags: function countTags(tags) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var counts = {};
      tags.forEach(function (tag) {
        counts[tag] = {
          unreadCount: 0,
          newCount: 0
        };

        if (opts.includeTotal) {
          counts[tag].totalCount = 0;
        }
      });
      this.forEachTracked(function (topic, newTopic, unreadTopic) {
        if (topic.tags && topic.tags.length > 0) {
          tags.forEach(function (tag) {
            if (topic.tags.indexOf(tag) > -1) {
              if (unreadTopic) {
                counts[tag].unreadCount++;
              }

              if (newTopic) {
                counts[tag].newCount++;
              }

              if (opts.includeTotal) {
                counts[tag].totalCount++;
              }
            }
          });
        }
      }, {
        includeAll: opts.includeTotal
      });
      return counts;
    },
    countCategory: function countCategory(category_id, tagId) {
      var sum = 0;

      var _iterator = _createForOfIteratorHelper(this.states.values()),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var topic = _step.value;

          if (topic.category_id === category_id && !topic.deleted && (!tagId || topic.tags && topic.tags.indexOf(tagId) > -1)) {
            sum += topic.last_read_post_number === null || topic.last_read_post_number < topic.highest_post_number ? 1 : 0;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return sum;
    },
    lookupCount: function lookupCount(name, category, tagId, noSubcategories) {
      if (name === "latest") {
        return this.lookupCount("new", category, tagId, noSubcategories) + this.lookupCount("unread", category, tagId, noSubcategories);
      }

      var categoryId = category ? Ember.get(category, "id") : null;

      if (name === "new") {
        return this.countNew(categoryId, tagId, noSubcategories);
      } else if (name === "unread") {
        return this.countUnread(categoryId, tagId, noSubcategories);
      } else {
        var categoryName = name.split("/")[1];

        if (categoryName) {
          return this.countCategory(categoryId, tagId);
        }
      }
    },
    loadStates: function loadStates(data) {
      var _this4 = this;

      (data || []).forEach(function (topic) {
        _this4.modifyState(topic, topic);
      });
    },
    modifyState: function modifyState(topic, data) {
      this.states.set(this._stateKey(topic), data);

      this._afterStateChange();
    },
    modifyStateProp: function modifyStateProp(topic, prop, data) {
      var state = this.findState(topic);

      if (state) {
        state[prop] = data;

        this._afterStateChange();
      }
    },
    findState: function findState(topicOrId) {
      return this.states.get(this._stateKey(topicOrId));
    },

    /*
     * private
     */
    // fix delayed "new" topics by removing the now seen
    // topic from the list (for the "new" list) or setting the topic
    // to "seen" for other lists.
    //
    // client side we know they are not new, server side we think they are.
    // this can happen if the list is cached or the update to the state
    // for a particular seen topic has not yet reached the server.
    _fixDelayedServerState: function _fixDelayedServerState(list, filter) {
      for (var index = list.topics.length - 1; index >= 0; index--) {
        var state = this.findState(list.topics[index].id);

        if (state && state.last_read_post_number > 0) {
          if (filter === "new") {
            list.topics.splice(index, 1);
          } else {
            list.topics[index].set("unseen", false);
            list.topics[index].set("prevent_sync", true);
          }
        }
      }
    },
    _syncStateFromListTopic: function _syncStateFromListTopic(topic) {
      var state = this.findState(topic.id) || {}; // make a new copy so we aren't modifying the state object directly while
      // we make changes

      var newState = _objectSpread({}, state);

      newState.topic_id = topic.id;
      newState.notification_level = topic.notification_level; // see ListableTopicSerializer for unread_posts/unseen and other
      // topic property logic

      if (topic.unseen) {
        newState.last_read_post_number = null;
      } else if (topic.unread_posts) {
        newState.last_read_post_number = topic.highest_post_number - (topic.unread_posts || 0);
      } else {
        // remove the topic if it is no longer unread/new (it has been seen)
        // and if there are too many topics in memory
        if (!topic.prevent_sync && this._maxStateSizeReached()) {
          this.removeTopic(topic.id);
        }

        return;
      }

      newState.highest_post_number = topic.highest_post_number;

      if (topic.category) {
        newState.category_id = topic.category.id;
      }

      if (topic.tags) {
        newState.tags = topic.tags;
      }

      this.modifyState(topic.id, newState);
    },
    // this stops sync of tracking state when list is filtered, in the past this
    // would cause the tracking state to become inconsistent.
    _shouldCompensateState: function _shouldCompensateState(list, filter, queryParams) {
      var shouldCompensate = (filter === "new" || filter === "unread") && !list.more_topics_url;

      if (shouldCompensate && queryParams) {
        Object.keys(queryParams).forEach(function (k) {
          if (k !== "ascending" && k !== "order") {
            shouldCompensate = false;
          }
        });
      }

      return shouldCompensate;
    },
    // any state that is not in the provided list must be updated
    // based on the filter selected so we do not have any incorrect
    // state in the list
    _correctMissingState: function _correctMissingState(list, filter) {
      var _this5 = this;

      var ids = {};
      list.topics.forEach(function (topic) {
        return ids[_this5._stateKey(topic.id)] = true;
      });

      var _iterator2 = _createForOfIteratorHelper(this.states.keys()),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var topicKey = _step2.value;

          // if the topic is already in the list then there is
          // no compensation needed; we already have latest state
          // from the backend
          if (ids[topicKey]) {
            return;
          }

          var newState = _objectSpread({}, this.findState(topicKey));

          if (filter === "unread" && isUnread(newState)) {
            // pretend read. if unread, the highest_post_number will be greater
            // than the last_read_post_number
            newState.last_read_post_number = newState.highest_post_number;
          }

          if (filter === "new" && isNew(newState)) {
            // pretend not new. if the topic is new, then last_read_post_number
            // will be null.
            newState.last_read_post_number = 1;
          }

          this.modifyState(topicKey, newState);
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    },
    _processChannelPayload: function _processChannelPayload(data) {
      if (["muted", "unmuted"].includes(data.message_type)) {
        this.trackMutedOrUnmutedTopic(data);
        return;
      }

      this.pruneOldMutedAndUnmutedTopics();

      if (this.isMutedTopic(data.topic_id)) {
        return;
      }

      if (this.siteSettings.mute_all_categories_by_default && !this.isUnmutedTopic(data.topic_id)) {
        return;
      }

      if (["new_topic", "latest"].includes(data.message_type)) {
        var muted_category_ids = _user.default.currentProp("muted_category_ids");

        if (muted_category_ids && muted_category_ids.includes(data.payload.category_id)) {
          return;
        }
      }

      if (["new_topic", "latest"].includes(data.message_type)) {
        var mutedTagIds = _user.default.currentProp("muted_tag_ids");

        if (hasMutedTags(data.payload.topic_tag_ids, mutedTagIds, this.siteSettings)) {
          return;
        }
      }

      var old = this.findState(data);

      if (data.message_type === "latest") {
        this.notifyIncoming(data);

        if ((old && old.tags) !== data.payload.tags) {
          this.modifyStateProp(data, "tags", data.payload.tags);
          this.incrementMessageCount();
        }
      }

      if (data.message_type === "dismiss_new") {
        this._dismissNewTopics(data.payload.topic_ids);
      }

      if (["new_topic", "unread", "read"].includes(data.message_type)) {
        this.notifyIncoming(data);

        if (!(0, _object.deepEqual)(old, data.payload)) {
          if (data.message_type === "read") {
            var mergeData = {}; // we have to do this because the "read" event does not
            // include tags; we don't want them to be overridden

            if (old) {
              mergeData = {
                tags: old.tags,
                topic_tag_ids: old.topic_tag_ids
              };
            }

            this.modifyState(data, (0, _object.deepMerge)(data.payload, mergeData));
          } else {
            this.modifyState(data, data.payload);
          }

          this.incrementMessageCount();
        }
      }
    },
    _dismissNewTopics: function _dismissNewTopics(topicIds) {
      var _this6 = this;

      topicIds.forEach(function (topicId) {
        _this6.modifyStateProp(topicId, "is_seen", true);
      });
      this.incrementMessageCount();
    },
    _addIncoming: function _addIncoming(topicId) {
      if (this.newIncoming.indexOf(topicId) === -1) {
        this.newIncoming.push(topicId);
      }
    },
    _trackedTopics: function _trackedTopics() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return Array.from(this.states.values()).map(function (topic) {
        if (topic.archetype !== "private_message" && !topic.deleted) {
          var newTopic = isNew(topic);
          var unreadTopic = isUnread(topic);

          if (newTopic || unreadTopic || opts.includeAll) {
            return {
              topic: topic,
              newTopic: newTopic,
              unreadTopic: unreadTopic
            };
          }
        }
      }).compact();
    },
    _stateKey: function _stateKey(topicOrId) {
      if (typeof topicOrId === "number") {
        return "t".concat(topicOrId);
      } else if (typeof topicOrId === "string" && topicOrId.indexOf("t") > -1) {
        return topicOrId;
      } else {
        return "t".concat(topicOrId.topic_id);
      }
    },
    _afterStateChange: function _afterStateChange() {
      this.notifyPropertyChange("states");
      Object.values(this.stateChangeCallbacks).forEach(function (cb) {
        return cb();
      });
    },
    _maxStateSizeReached: function _maxStateSizeReached() {
      return this.states.size >= this._trackedTopicLimit;
    }
  }, (_applyDecoratedDescriptor(_obj, "_setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "_setup"), _obj), _applyDecoratedDescriptor(_obj, "hasIncoming", [_dec2], Object.getOwnPropertyDescriptor(_obj, "hasIncoming"), _obj), _applyDecoratedDescriptor(_obj, "_syncStateFromListTopic", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_syncStateFromListTopic"), _obj), _applyDecoratedDescriptor(_obj, "_processChannelPayload", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_processChannelPayload"), _obj)), _obj)));

  function startTracking(tracking) {
    var data = _preloadStore.default.get("topicTrackingStates");

    tracking.loadStates(data);
    tracking.establishChannels();

    _preloadStore.default.remove("topicTrackingStates");
  }

  var _default = TopicTrackingState;
  _exports.default = _default;
});