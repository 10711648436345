define("discourse/initializers/ember-link-component-extension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var initializedOnce = false;
  var _default = {
    name: "ember-link-component-extensions",
    initialize: function initialize() {
      if (initializedOnce) {
        return;
      }

      Ember.LinkComponent.reopen({
        attributeBindings: ["name"]
      });
      initializedOnce = true;
    }
  };
  _exports.default = _default;
});