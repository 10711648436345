define("discourse/lib/link-mentions", ["exports", "discourse-common/lib/deprecated", "discourse/lib/ajax", "discourse/lib/utilities", "discourse-common/lib/get-url", "discourse/lib/url"], function (_exports, _deprecated, _ajax, _utilities, _getUrl, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkSeenMentions = linkSeenMentions;
  _exports.fetchUnseenMentions = fetchUnseenMentions;
  _exports.cannotSee = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var maxGroupMention;

  function replaceSpan(element, username, opts) {
    var extra = {};
    var extraClass = [];
    var a = document.createElement("a");

    if (opts && opts.group) {
      var _a$classList;

      if (opts.mentionable) {
        extra = {
          name: username,
          mentionableUserCount: opts.mentionable.user_count,
          maxMentions: maxGroupMention
        };
        extraClass.push("notify");
      }

      a.setAttribute("href", (0, _getUrl.default)("/g/") + username);

      (_a$classList = a.classList).add.apply(_a$classList, ["mention-group"].concat(extraClass));

      a.innerText = "@".concat(username);
    } else {
      var _a$classList2;

      if (opts && opts.cannot_see) {
        extra = {
          name: username
        };
        extraClass.push("cannot-see");
      }

      a.href = (0, _url.userPath)(username.toLowerCase());

      (_a$classList2 = a.classList).add.apply(_a$classList2, ["mention"].concat(extraClass));

      a.innerText = "@".concat((0, _utilities.formatUsername)(username));
    }

    Object.keys(extra).forEach(function (key) {
      a.dataset[key] = extra[key];
    });
    element.replaceWith(a);
  }

  var found = {};
  var foundGroups = {};
  var mentionableGroups = {};
  var checked = {};
  var cannotSee = {};
  _exports.cannotSee = cannotSee;

  function updateFound(mentions, usernames) {
    mentions.forEach(function (mention, index) {
      var username = usernames[index];

      if (found[username.toLowerCase()]) {
        replaceSpan(mention, username, {
          cannot_see: cannotSee[username]
        });
      } else if (mentionableGroups[username]) {
        replaceSpan(mention, username, {
          group: true,
          mentionable: mentionableGroups[username]
        });
      } else if (foundGroups[username]) {
        replaceSpan(mention, username, {
          group: true
        });
      } else if (checked[username]) {
        mention.classList.add("mention-tested");
      }
    });
  }

  function linkSeenMentions(elem, siteSettings) {
    // eslint-disable-next-line no-undef
    if (elem instanceof jQuery) {
      elem = elem[0];
      (0, _deprecated.default)("linkSeenMentions now expects a DOM node as first parameter", {
        since: "2.8.0.beta7",
        dropFrom: "2.9.0.beta1"
      });
    }

    var mentions = _toConsumableArray(elem.querySelectorAll("span.mention:not(.mention-tested)"));

    if (mentions.length) {
      var usernames = mentions.map(function (m) {
        return m.innerText.substr(1);
      });
      updateFound(mentions, usernames);
      return usernames.uniq().filter(function (u) {
        return !checked[u] && u.length >= siteSettings.min_username_length;
      });
    }

    return [];
  } // 'Create a New Topic' scenario is not supported (per conversation with codinghorror)
  // https://meta.discourse.org/t/taking-another-1-7-release-task/51986/7


  function fetchUnseenMentions(usernames, topic_id) {
    return (0, _ajax.ajax)((0, _url.userPath)("is_local_username"), {
      data: {
        usernames: usernames,
        topic_id: topic_id
      }
    }).then(function (r) {
      r.valid.forEach(function (v) {
        return found[v] = true;
      });
      r.valid_groups.forEach(function (vg) {
        return foundGroups[vg] = true;
      });
      r.mentionable_groups.forEach(function (mg) {
        return mentionableGroups[mg.name] = mg;
      });
      Object.entries(r.cannot_see).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            username = _ref2[0],
            reason = _ref2[1];

        return cannotSee[username] = reason;
      });
      maxGroupMention = r.max_users_notified_per_group_mention;
      usernames.forEach(function (u) {
        return checked[u] = true;
      });
      return r;
    });
  }
});