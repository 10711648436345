define("discourse/templates/components/hidden-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LQQhtomd",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"expanded\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"d-button\",null,[[\"action\",\"class\",\"label\"],[[28,\"action\",[[23,0,[]],\"expand\"],null],\"btn-link\",[24,[\"label\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"expanded\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"description\"],[10,\"aria-live\",\"assertive\"],[8],[1,[22,\"details\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/hidden-details.hbs"
    }
  });

  _exports.default = _default;
});