define("discourse/templates/components/topic-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PJB0lzZj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[24,[\"hideProgress\"]]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showBackButton\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"progress-back-container\"],[8],[0,\"\\n    \"],[1,[28,\"d-button\",null,[[\"label\",\"class\",\"action\",\"icon\"],[\"topic.timeline.back\",\"btn-primary progress-back\",[28,\"action\",[[23,0,[]],\"goBack\"],null],\"arrow-down\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"nav\",true],[11,\"title\",[28,\"i18n\",[\"topic.progress.title\"],null]],[11,\"class\",[28,\"if\",[[24,[\"hideProgress\"]],\"hidden\"],null]],[10,\"id\",\"topic-progress\"],[11,\"style\",[28,\"html-safe\",[[24,[\"progressStyle\"]]],null]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"nums\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[8],[1,[22,\"progressPosition\"],false],[9],[0,\"\\n    \"],[7,\"span\",true],[11,\"class\",[28,\"if\",[[24,[\"hugeNumberOfPosts\"]],\"hidden\"],null]],[8],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"/\"],[9],[0,\"\\n      \"],[7,\"h4\",true],[8],[1,[24,[\"postStream\",\"filteredPostsCount\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"bg\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"tagName\",\"connectorTagName\"],[\"after-topic-progress\",\"\",\"div\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/topic-progress.hbs"
    }
  });

  _exports.default = _default;
});