define("discourse/adapters/rest", ["exports", "discourse/lib/ajax", "discourse/lib/hash"], function (_exports, _ajax, _hash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Result = Result;
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var ADMIN_MODELS = ["plugin", "theme", "embeddable-host", "web-hook", "web-hook-event", "flagged-topic"];

  function Result(payload, responseJson) {
    this.payload = payload;
    this.responseJson = responseJson;
    this.target = null;
  } // We use this to make sure 404s are caught


  function rethrow(error) {
    if (error.status === 404) {
      throw new Error("404: " + error.responseText);
    }

    throw error;
  }

  var _default = Ember.Object.extend({
    primaryKey: "id",
    storageKey: function storageKey(type, findArgs, options) {
      if (options && options.cacheKey) {
        return options.cacheKey;
      }

      var hashedArgs = Math.abs((0, _hash.hashString)(JSON.stringify(findArgs)));
      return "".concat(type, "_").concat(hashedArgs);
    },
    basePath: function basePath(store, type) {
      if (ADMIN_MODELS.indexOf(type.replace("_", "-")) !== -1) {
        return "/admin/";
      }

      return "/";
    },
    appendQueryParams: function appendQueryParams(path, findArgs, extension) {
      if (findArgs) {
        if (_typeof(findArgs) === "object") {
          var queryString = Object.keys(findArgs).reject(function (k) {
            return !findArgs[k];
          }).map(function (k) {
            return k + "=" + encodeURIComponent(findArgs[k]);
          });

          if (queryString.length) {
            return "".concat(path).concat(extension ? extension : "", "?").concat(queryString.join("&"));
          }
        } else {
          // It's serializable as a string if not an object
          return "".concat(path, "/").concat(encodeURIComponent(findArgs)).concat(extension ? extension : "");
        }
      }

      return path;
    },
    pathFor: function pathFor(store, type, findArgs) {
      var path = this.basePath(store, type, findArgs) + Ember.String.underscore(store.pluralize(this.apiNameFor(type)));
      return this.appendQueryParams(path, findArgs);
    },
    apiNameFor: function apiNameFor(type) {
      return type;
    },
    findAll: function findAll(store, type, findArgs) {
      return (0, _ajax.ajax)(this.pathFor(store, type, findArgs)).catch(rethrow);
    },
    find: function find(store, type, findArgs) {
      return (0, _ajax.ajax)(this.pathFor(store, type, findArgs)).catch(rethrow);
    },
    findStale: function findStale(store, type, findArgs, options) {
      if (this.cached) {
        return this.cached[this.storageKey(type, findArgs, options)];
      }
    },
    cacheFind: function cacheFind(store, type, findArgs, opts, hydrated) {
      this.cached = this.cached || {};
      this.cached[this.storageKey(type, findArgs, opts)] = hydrated;
    },
    jsonMode: false,
    getPayload: function getPayload(method, data) {
      var payload = {
        method: method,
        data: data
      };

      if (this.jsonMode) {
        payload.contentType = "application/json";
        payload.data = JSON.stringify(data);
      }

      return payload;
    },
    update: function update(store, type, id, attrs) {
      var data = {};
      var typeField = Ember.String.underscore(this.apiNameFor(type));
      data[typeField] = attrs;
      return (0, _ajax.ajax)(this.pathFor(store, type, id), this.getPayload("PUT", data)).then(function (json) {
        return new Result(json[typeField], json);
      });
    },
    createRecord: function createRecord(store, type, attrs) {
      var data = {};
      var typeField = Ember.String.underscore(this.apiNameFor(type));
      data[typeField] = attrs;
      return (0, _ajax.ajax)(this.pathFor(store, type), this.getPayload("POST", data)).then(function (json) {
        return new Result(json[typeField], json);
      });
    },
    destroyRecord: function destroyRecord(store, type, record) {
      return (0, _ajax.ajax)(this.pathFor(store, type, record.get(this.primaryKey)), {
        type: "DELETE"
      });
    }
  });

  _exports.default = _default;
});