define("discourse/components/choose-message", ["exports", "discourse-common/lib/debounce", "discourse-common/utils/decorators", "discourse/lib/search"], function (_exports, _debounce, _decorators, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.observes)("messageTitle"), _dec2 = (0, _decorators.observes)("messages"), (_obj = {
    loading: null,
    noResults: null,
    messages: null,
    messageTitleChanged: function messageTitleChanged() {
      this.setProperties({
        loading: true,
        noResults: true,
        selectedTopicId: null
      });
      this.search(this.messageTitle);
    },
    messagesChanged: function messagesChanged() {
      var messages = this.messages;

      if (messages) {
        this.set("noResults", messages.length === 0);
      }

      this.set("loading", false);
    },
    search: function search(title) {
      (0, _debounce.default)(this, function () {
        var _this = this;

        var currentTopicId = this.currentTopicId;

        if (Ember.isEmpty(title)) {
          this.setProperties({
            messages: null,
            loading: false
          });
          return;
        }

        (0, _search.searchForTerm)(title, {
          typeFilter: "private_messages",
          searchForId: true,
          restrictToArchetype: "private_message"
        }).then(function (results) {
          if (results && results.posts && results.posts.length > 0) {
            _this.set("messages", results.posts.mapBy("topic").filter(function (t) {
              return t.get("id") !== currentTopicId;
            }));
          } else {
            _this.setProperties({
              messages: null,
              loading: false
            });
          }
        });
      }, title, 300);
    },
    actions: {
      chooseMessage: function chooseMessage(message) {
        var messageId = Ember.get(message, "id");
        this.set("selectedTopicId", messageId);
        Ember.run.next(function () {
          return $("#choose-message-".concat(messageId)).prop("checked", "true");
        });
        return false;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "messageTitleChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "messageTitleChanged"), _obj), _applyDecoratedDescriptor(_obj, "messagesChanged", [_dec2], Object.getOwnPropertyDescriptor(_obj, "messagesChanged"), _obj)), _obj)));

  _exports.default = _default;
});