define("discourse/controllers/group-default-notifications", ["exports", "discourse/mixins/modal-functionality", "discourse/mixins/modal-update-existing-users"], function (_exports, _modalFunctionality, _modalUpdateExistingUsers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalFunctionality.default, _modalUpdateExistingUsers.default);

  _exports.default = _default;
});