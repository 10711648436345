define("discourse/controllers/insert-hyperlink", ["exports", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators", "discourse-common/lib/debounce", "discourse/lib/url", "discourse/lib/search"], function (_exports, _modalFunctionality, _decorators, _debounce, _url, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, (_obj = {
    _debounced: null,
    _activeSearch: null,
    onShow: function onShow() {
      var _this = this;

      this.setProperties({
        linkUrl: "",
        linkText: "",
        searchResults: [],
        searchLoading: false,
        selectedRow: -1
      });
      Ember.run.schedule("afterRender", function () {
        var element = document.querySelector(".insert-link");
        element.addEventListener("keydown", _this.keyDown);
        element.closest(".modal-inner-container").addEventListener("mousedown", _this.mouseDown);
      });
    },
    keyDown: function keyDown(event) {
      switch (event.which) {
        case 40:
          this.highlightRow(event, "down");
          break;

        case 38:
          this.highlightRow(event, "up");
          break;

        case 13:
          // override Enter behaviour when a row is selected
          if (this.selectedRow > -1) {
            var selected = document.querySelectorAll(".internal-link-results .search-link")[this.selectedRow];
            this.selectLink(selected);
            event.preventDefault();
            event.stopPropagation();
          }

          break;

        case 27:
          // Esc should cancel dropdown first
          if (this.searchResults.length) {
            this.set("searchResults", []);
            event.preventDefault();
            event.stopPropagation();
          } else {
            this.send("closeModal");
          }

          break;
      }
    },
    mouseDown: function mouseDown(event) {
      if (!event.target.closest(".inputs")) {
        this.set("searchResults", []);
      }
    },
    highlightRow: function highlightRow(e, direction) {
      var index = direction === "down" ? this.selectedRow + 1 : this.selectedRow - 1;

      if (index > -1 && index < this.searchResults.length) {
        document.querySelectorAll(".internal-link-results .search-link")[index].focus();
        this.set("selectedRow", index);
      } else {
        this.set("selectedRow", -1);
        document.querySelector("input.link-url").focus();
      }

      e.preventDefault();
    },
    selectLink: function selectLink(el) {
      this.setProperties({
        linkUrl: el.href,
        searchResults: [],
        selectedRow: -1
      });

      if (!this.linkText && el.dataset.title) {
        this.set("linkText", el.dataset.title);
      }

      document.querySelector("input.link-text").focus();
    },
    triggerSearch: function triggerSearch() {
      var _this2 = this;

      if (this.linkUrl.length > 3 && this.linkUrl.indexOf("http") === -1) {
        this.set("searchLoading", true);
        this._activeSearch = (0, _search.searchForTerm)(this.linkUrl, {
          typeFilter: "topic"
        });

        this._activeSearch.then(function (results) {
          if (results && results.topics && results.topics.length > 0) {
            _this2.set("searchResults", results.topics);
          } else {
            _this2.set("searchResults", []);
          }
        }).finally(function () {
          _this2.set("searchLoading", false);

          _this2._activeSearch = null;
        });
      } else {
        this.abortSearch();
      }
    },
    abortSearch: function abortSearch() {
      if (this._activeSearch) {
        this._activeSearch.abort();
      }

      this.setProperties({
        searchResults: [],
        searchLoading: false
      });
    },
    onClose: function onClose() {
      var element = document.querySelector(".insert-link");
      element.removeEventListener("keydown", this.keyDown);
      element.closest(".modal-inner-container").removeEventListener("mousedown", this.mouseDown);
      Ember.run.cancel(this._debounced);
    },
    actions: {
      ok: function ok() {
        var origLink = this.linkUrl;
        var linkUrl = (0, _url.prefixProtocol)(origLink);
        var sel = this.toolbarEvent.selected;

        if (Ember.isEmpty(linkUrl)) {
          return;
        }

        var linkText = this.linkText || "";

        if (linkText.length) {
          this.toolbarEvent.addText("[".concat(linkText, "](").concat(linkUrl, ")"));
        } else {
          if (sel.value) {
            this.toolbarEvent.addText("[".concat(sel.value, "](").concat(linkUrl, ")"));
          } else {
            this.toolbarEvent.addText("[".concat(origLink, "](").concat(linkUrl, ")"));
            this.toolbarEvent.selectText(sel.start + 1, origLink.length);
          }
        }

        this.send("closeModal");
      },
      cancel: function cancel() {
        this.send("closeModal");
      },
      linkClick: function linkClick(e) {
        if (!e.metaKey && !e.ctrlKey) {
          e.preventDefault();
          e.stopPropagation();
          this.selectLink(e.target.closest(".search-link"));
        }
      },
      search: function search() {
        this._debounced = (0, _debounce.default)(this, this.triggerSearch, 400);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "keyDown", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "keyDown"), _obj), _applyDecoratedDescriptor(_obj, "mouseDown", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "mouseDown"), _obj)), _obj));

  _exports.default = _default;
});