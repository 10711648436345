define("discourse/models/category-list", ["exports", "discourse/models/category", "I18n", "discourse/lib/preload-store", "discourse/models/site", "discourse/models/topic", "discourse/lib/ajax", "discourse/lib/formatter"], function (_exports, _category, _I18n, _preloadStore, _site, _topic, _ajax, _formatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CategoryList = Ember.ArrayProxy.extend({
    init: function init() {
      this.set("content", []);

      this._super.apply(this, arguments);
    }
  });
  CategoryList.reopenClass({
    categoriesFrom: function categoriesFrom(store, result) {
      var categories = CategoryList.create();

      var list = _category.default.list();

      var statPeriod = "all";
      var minCategories = result.category_list.categories.length * 0.66;
      ["week", "month"].some(function (period) {
        var filteredCategories = result.category_list.categories.filter(function (c) {
          return c["topics_".concat(period)] > 0;
        });

        if (filteredCategories.length >= minCategories) {
          statPeriod = period;
          return true;
        }
      });
      result.category_list.categories.forEach(function (c) {
        if (c.parent_category_id) {
          c.parentCategory = list.findBy("id", c.parent_category_id);
        }

        if (c.subcategory_ids) {
          c.subcategories = c.subcategory_ids.map(function (scid) {
            return list.findBy("id", parseInt(scid, 10));
          });
        }

        if (c.topics) {
          c.topics = c.topics.map(function (t) {
            return _topic.default.create(t);
          });
        }

        switch (statPeriod) {
          case "week":
          case "month":
            var stat = c["topics_".concat(statPeriod)];

            if (stat > 0) {
              var unit = _I18n.default.t("categories.topic_stat_unit.".concat(statPeriod));

              c.stat = _I18n.default.t("categories.topic_stat", {
                count: stat,
                // only used to correctly pluralize the string
                number: "<span class=\"value\">".concat((0, _formatter.number)(stat), "</span>"),
                unit: "<span class=\"unit\">".concat(unit, "</span>")
              });
              c.statTitle = _I18n.default.t("categories.topic_stat_sentence_".concat(statPeriod), {
                count: stat
              });
              c.pickAll = false;
              break;
            }

          default:
            c.stat = "<span class=\"value\">".concat((0, _formatter.number)(c.topics_all_time), "</span>");
            c.statTitle = _I18n.default.t("categories.topic_sentence", {
              count: c.topics_all_time
            });
            c.pickAll = true;
            break;
        }

        if (_site.default.currentProp("mobileView")) {
          c.statTotal = _I18n.default.t("categories.topic_stat_all_time", {
            count: c.topics_all_time,
            number: "<span class=\"value\">".concat((0, _formatter.number)(c.topics_all_time), "</span>")
          });
        }

        var record = _site.default.current().updateCategory(c);

        record.setupGroupsAndPermissions();
        categories.pushObject(record);
      });
      return categories;
    },
    listForParent: function listForParent(store, category) {
      var _this = this;

      return (0, _ajax.ajax)("/categories.json?parent_category_id=".concat(category.get("id"))).then(function (result) {
        return CategoryList.create({
          categories: _this.categoriesFrom(store, result),
          parentCategory: category
        });
      });
    },
    list: function list(store) {
      var _this2 = this;

      var getCategories = function getCategories() {
        return (0, _ajax.ajax)("/categories.json");
      };

      return _preloadStore.default.getAndRemove("categories_list", getCategories).then(function (result) {
        return CategoryList.create({
          categories: _this2.categoriesFrom(store, result),
          can_create_category: result.category_list.can_create_category,
          can_create_topic: result.category_list.can_create_topic
        });
      });
    }
  });
  var _default = CategoryList;
  _exports.default = _default;
});