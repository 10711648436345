define("discourse/mixins/open-composer", ["exports", "discourse/models/composer"], function (_exports, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This mixin allows a route to open the composer
  var _default = Ember.Mixin.create({
    openComposer: function openComposer(controller) {
      var categoryId = controller.get("category.id");

      if (categoryId && controller.category.isUncategorizedCategory && !this.siteSettings.allow_uncategorized_topics) {
        categoryId = null;
      }

      this.controllerFor("composer").open({
        prioritizedCategoryId: categoryId,
        topicCategoryId: categoryId,
        action: _composer.default.CREATE_TOPIC,
        draftKey: controller.get("model.draft_key") || _composer.default.NEW_TOPIC_KEY,
        draftSequence: controller.get("model.draft_sequence") || 0
      });
    },
    openComposerWithTopicParams: function openComposerWithTopicParams(controller, topicTitle, topicBody, topicCategoryId, topicTags) {
      this.controllerFor("composer").open({
        action: _composer.default.CREATE_TOPIC,
        topicTitle: topicTitle,
        topicBody: topicBody,
        topicCategoryId: topicCategoryId,
        topicTags: topicTags,
        draftKey: controller.get("model.draft_key") || _composer.default.NEW_TOPIC_KEY,
        draftSequence: controller.get("model.draft_sequence")
      });
    },
    openComposerWithMessageParams: function openComposerWithMessageParams() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$recipients = _ref.recipients,
          recipients = _ref$recipients === void 0 ? [] : _ref$recipients,
          _ref$topicTitle = _ref.topicTitle,
          topicTitle = _ref$topicTitle === void 0 ? "" : _ref$topicTitle,
          _ref$topicBody = _ref.topicBody,
          topicBody = _ref$topicBody === void 0 ? "" : _ref$topicBody,
          _ref$hasGroups = _ref.hasGroups,
          hasGroups = _ref$hasGroups === void 0 ? false : _ref$hasGroups;

      this.controllerFor("composer").open({
        action: _composer.default.PRIVATE_MESSAGE,
        recipients: recipients,
        topicTitle: topicTitle,
        topicBody: topicBody,
        archetypeId: "private_message",
        draftKey: _composer.default.NEW_PRIVATE_MESSAGE_KEY,
        hasGroups: hasGroups
      });
    }
  });

  _exports.default = _default;
});