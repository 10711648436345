define("discourse/lib/download-calendar", ["exports", "discourse/models/user", "discourse/lib/show-modal", "discourse-common/lib/get-url"], function (_exports, _user, _showModal, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.downloadCalendar = downloadCalendar;
  _exports.downloadIcs = downloadIcs;
  _exports.downloadGoogle = downloadGoogle;
  _exports.formatDates = formatDates;
  _exports.generateIcsData = generateIcsData;

  function downloadCalendar(title, dates) {
    var currentUser = _user.default.current();

    var formattedDates = formatDates(dates);
    title = title.trim();

    switch (currentUser.default_calendar) {
      case "none_selected":
        _displayModal(title, formattedDates);

        break;

      case "ics":
        downloadIcs(title, formattedDates);
        break;

      case "google":
        downloadGoogle(title, formattedDates);
        break;
    }
  }

  function downloadIcs(title, dates) {
    var REMOVE_FILE_AFTER = 20000;
    var file = new File([generateIcsData(title, dates)], {
      type: "text/plain"
    });
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = window.URL.createObjectURL(file);
    a.download = "".concat(title.toLowerCase().replace(/[^\w]/g, "-"), ".ics");
    a.click();
    setTimeout(function () {
      return window.URL.revokeObjectURL(file);
    }, REMOVE_FILE_AFTER); //remove file to avoid memory leaks
  }

  function downloadGoogle(title, dates) {
    dates.forEach(function (date) {
      var encodedTitle = encodeURIComponent(title);
      var link = (0, _getUrl.default)("\n      https://www.google.com/calendar/event?action=TEMPLATE&text=".concat(encodedTitle, "&dates=").concat(_formatDateForGoogleApi(date.startsAt), "/").concat(_formatDateForGoogleApi(date.endsAt), "\n    ")).trim();
      window.open(link, "_blank", "noopener", "noreferrer");
    });
  }

  function formatDates(dates) {
    return dates.map(function (date) {
      return {
        startsAt: date.startsAt,
        endsAt: date.endsAt ? date.endsAt : moment.utc(date.startsAt).add(1, "hours").format()
      };
    });
  }

  function generateIcsData(title, dates) {
    var data = "BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//Discourse//EN\n";
    dates.forEach(function (date) {
      var startDate = moment(date.startsAt);
      var endDate = moment(date.endsAt);
      data = data.concat("BEGIN:VEVENT\n" + "UID:".concat(startDate.utc().format("x"), "_").concat(endDate.format("x"), "\n") + "DTSTAMP:".concat(moment().utc().format("YMMDDTHHmmss"), "Z\n") + "DTSTART:".concat(startDate.utc().format("YMMDDTHHmmss"), "Z\n") + "DTEND:".concat(endDate.utc().format("YMMDDTHHmmss"), "Z\n") + "SUMMARY:".concat(title, "\n") + "END:VEVENT\n");
    });
    data = data.concat("END:VCALENDAR");
    return data;
  }

  function _displayModal(title, dates) {
    (0, _showModal.default)("download-calendar", {
      model: {
        title: title,
        dates: dates
      }
    });
  }

  function _formatDateForGoogleApi(date) {
    return moment(date).toISOString().replace(/-|:|\.\d\d\d/g, "");
  }
});