define("discourse/templates/components/tag-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Z2u+vvs",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h3\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"category\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"category-title-link\",null,[[\"category\"],[[24,[\"category\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"tagGroupName\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h3\",true],[8],[1,[22,\"tagGroupName\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[24,[\"sortedTags\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"tag-box\"],[8],[0,\"\\n    \"],[1,[28,\"discourse-tag\",[[23,1,[\"id\"]]],[[\"description\",\"isPrivateMessage\",\"pmOnly\",\"tagsForUser\"],[[23,1,[\"description\"]],[24,[\"isPrivateMessage\"]],[23,1,[\"pmOnly\"]],[24,[\"tagsForUser\"]]]]],false],[0,\" \"],[4,\"if\",[[23,1,[\"pmOnly\"]]],null,{\"statements\":[[1,[28,\"d-icon\",[\"far-envelope\"],null],false]],\"parameters\":[]},null],[4,\"if\",[[23,1,[\"totalCount\"]]],null,{\"statements\":[[0,\" \"],[7,\"span\",true],[10,\"class\",\"tag-count\"],[8],[0,\"x \"],[1,[23,1,[\"totalCount\"]],false],[9]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/tag-list.hbs"
    }
  });

  _exports.default = _default;
});