define("discourse/pre-initializers/dynamic-route-builders", ["exports", "discourse/controllers/discovery-sortable", "discourse/models/site", "discourse/routes/tag-show", "discourse/models/user", "discourse/routes/build-category-route", "discourse/routes/build-topic-route"], function (_exports, _discoverySortable, _site, _tagShow, _user, _buildCategoryRoute, _buildTopicRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    after: "inject-discourse-objects",
    name: "dynamic-route-builders",
    initialize: function initialize(registry, app) {
      app.DiscoveryCategoryController = _discoverySortable.default.extend();
      app.DiscoveryCategoryNoneController = _discoverySortable.default.extend();
      app.DiscoveryCategoryAllController = _discoverySortable.default.extend();
      app.DiscoveryCategoryRoute = (0, _buildCategoryRoute.default)("default");
      app.DiscoveryCategoryNoneRoute = (0, _buildCategoryRoute.default)("default", {
        no_subcategories: true
      });
      app.DiscoveryCategoryAllRoute = (0, _buildCategoryRoute.default)("default", {
        no_subcategories: false
      });

      var site = _site.default.current();

      site.get("filters").forEach(function (filter) {
        var filterCapitalized = filter.capitalize();
        app["Discovery".concat(filterCapitalized, "Controller")] = _discoverySortable.default.extend();
        app["Discovery".concat(filterCapitalized, "CategoryController")] = _discoverySortable.default.extend();
        app["Discovery".concat(filterCapitalized, "CategoryNoneController")] = _discoverySortable.default.extend();

        if (filter === "top") {
          app.DiscoveryTopRoute = (0, _buildTopicRoute.default)("top", {
            actions: {
              willTransition: function willTransition() {
                _user.default.currentProp("should_be_redirected_to_top", false);

                _user.default.currentProp("redirected_to_top.reason", null);

                return this._super.apply(this, arguments);
              }
            }
          });
        } else {
          app["Discovery".concat(filterCapitalized, "Route")] = (0, _buildTopicRoute.default)(filter);
        }

        app["Discovery".concat(filterCapitalized, "CategoryRoute")] = (0, _buildCategoryRoute.default)(filter);
        app["Discovery".concat(filterCapitalized, "CategoryNoneRoute")] = (0, _buildCategoryRoute.default)(filter, {
          no_subcategories: true
        });
      });
      app["TagsShowCategoryRoute"] = _tagShow.default.extend();
      app["TagsShowCategoryNoneRoute"] = _tagShow.default.extend({
        noSubcategories: true
      });
      site.get("filters").forEach(function (filter) {
        app["TagShow" + filter.capitalize() + "Route"] = _tagShow.default.extend({
          navMode: filter
        });
        app["TagsShowCategory" + filter.capitalize() + "Route"] = _tagShow.default.extend({
          navMode: filter
        });
        app["TagsShowNoneCategory" + filter.capitalize() + "Route"] = _tagShow.default.extend({
          navMode: filter,
          noSubcategories: true
        });
      });
    }
  };
  _exports.default = _default;
});