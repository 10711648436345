define("discourse/templates/modal/edit-topic-timer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bZEBImDo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"title\",\"autoFocus\",\"id\"],[\"topic.topic_status_update.title\",\"false\",\"topic-timer-modal\"]],{\"statements\":[[0,\"  \"],[1,[28,\"edit-topic-timer-form\",null,[[\"topic\",\"topicTimer\",\"timerTypes\",\"onChangeStatusType\",\"onChangeInput\"],[[24,[\"model\"]],[24,[\"topicTimer\"]],[24,[\"publicTimerTypes\"]],[28,\"action\",[[23,0,[]],\"onChangeStatusType\"],null],[28,\"action\",[[23,0,[]],\"onChangeInput\"],null]]]],false],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"modal-footer control-group edit-topic-timer-buttons\"],[8],[0,\"\\n    \"],[1,[28,\"d-button\",null,[[\"class\",\"disabled\",\"label\",\"action\"],[\"btn-primary\",[24,[\"saveDisabled\"]],\"topic.topic_status_update.save\",[28,\"action\",[[23,0,[]],\"saveTimer\"],null]]]],false],[0,\"\\n\\n    \"],[1,[28,\"conditional-loading-spinner\",null,[[\"size\",\"condition\"],[\"small\",[24,[\"loading\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"topicTimer\",\"execute_at\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"d-button\",null,[[\"class\",\"action\",\"label\"],[\"pull-right btn-danger\",[28,\"action\",[[23,0,[]],\"removeTimer\"],null],\"topic.topic_status_update.remove\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/edit-topic-timer.hbs"
    }
  });

  _exports.default = _default;
});