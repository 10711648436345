define("discourse/controllers/navigation/default", ["exports", "discourse/mixins/filter-mode"], function (_exports, _filterMode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_filterMode.default, {
    discovery: Ember.inject.controller()
  });

  _exports.default = _default;
});