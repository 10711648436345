define("discourse/templates/modal/activation-resent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vhA8HXGw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,null,{\"statements\":[[0,\"  \"],[1,[28,\"html-safe\",[[28,\"i18n\",[\"login.sent_activation_email_again\"],[[\"currentEmail\"],[[24,[\"currentEmail\"]]]]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"modal-footer-close\",null,[[\"closeModal\"],[[28,\"route-action\",[\"closeModal\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/activation-resent.hbs"
    }
  });

  _exports.default = _default;
});