define("discourse/templates/group/manage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3sCfvRh5",
    "block": "{\"symbols\":[\"tab\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"user-secondary-navigation\"],[8],[0,\"\\n\"],[4,\"mobile-nav\",null,[[\"class\",\"desktopClass\"],[\"activity-nav\",\"action-list activity-list nav-stacked\"]],{\"statements\":[[4,\"each\",[[24,[\"tabs\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[[23,1,[\"route\"]],[24,[\"model\",\"name\"]]]],{\"statements\":[[0,\"          \"],[1,[28,\"i18n\",[[23,1,[\"title\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"user-content\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/group/manage.hbs"
    }
  });

  _exports.default = _default;
});