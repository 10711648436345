define("discourse/widgets/topic-map", ["exports", "discourse/widgets/post", "discourse/helpers/node", "I18n", "discourse/widgets/widget", "virtual-dom", "discourse/widgets/emoji", "discourse/lib/avatar-flair"], function (_exports, _post, _node, _I18n, _widget, _virtualDom, _emoji, _avatarFlair) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addTopicParticipantClassesCallback = addTopicParticipantClassesCallback;
  _exports.default = void 0;
  var LINKS_SHOWN = 5;

  function renderParticipants(userFilters, participants) {
    var _this = this;

    if (!participants) {
      return;
    }

    userFilters = userFilters || [];
    return participants.map(function (p) {
      return _this.attach("topic-participant", p, {
        state: {
          toggled: userFilters.includes(p.username)
        }
      });
    });
  }

  (0, _widget.createWidget)("topic-map-show-links", {
    tagName: "div.link-summary",
    html: function html() {
      return (0, _virtualDom.h)("span", this.attach("button", {
        title: "topic_map.links_shown",
        icon: "chevron-down",
        action: "showLinks",
        className: "btn"
      }));
    },
    showLinks: function showLinks() {
      this.sendWidgetAction("showAllLinks");
    }
  });
  var addTopicParticipantClassesCallbacks = null;

  function addTopicParticipantClassesCallback(callback) {
    addTopicParticipantClassesCallbacks = addTopicParticipantClassesCallbacks || [];
    addTopicParticipantClassesCallbacks.push(callback);
  }

  (0, _widget.createWidget)("topic-participant", {
    buildClasses: function buildClasses(attrs) {
      var classNames = [];

      if (attrs.primary_group_name) {
        classNames.push("group-".concat(attrs.primary_group_name));
      }

      if (addTopicParticipantClassesCallbacks) {
        for (var i = 0; i < addTopicParticipantClassesCallbacks.length; i++) {
          var pluginClasses = addTopicParticipantClassesCallbacks[i].call(this, attrs);

          if (pluginClasses) {
            classNames.push.apply(classNames, pluginClasses);
          }
        }
      }

      return classNames;
    },
    html: function html(attrs, state) {
      var linkContents = [(0, _post.avatarImg)("medium", {
        username: attrs.username,
        template: attrs.avatar_template,
        name: attrs.name
      })];

      if (attrs.post_count > 2) {
        linkContents.push((0, _virtualDom.h)("span.post-count", attrs.post_count.toString()));
      }

      if (attrs.flair_url || attrs.flair_bg_color) {
        linkContents.push(this.attach("avatar-flair", attrs));
      } else {
        var autoFlairAttrs = (0, _avatarFlair.default)(this.site, attrs);

        if (autoFlairAttrs) {
          linkContents.push(this.attach("avatar-flair", autoFlairAttrs));
        }
      }

      return (0, _virtualDom.h)("a.poster.trigger-user-card", {
        className: state.toggled ? "toggled" : null,
        attributes: {
          title: attrs.username,
          "data-user-card": attrs.username
        }
      }, linkContents);
    }
  });
  (0, _widget.createWidget)("topic-map-summary", {
    tagName: "section.map",
    buildClasses: function buildClasses(attrs, state) {
      if (state.collapsed) {
        return "map-collapsed";
      }
    },
    html: function html(attrs, state) {
      var contents = [];
      contents.push((0, _virtualDom.h)("li.created-at", [(0, _virtualDom.h)("h4", {
        attributes: {
          role: "presentation"
        }
      }, _I18n.default.t("created_lowercase")), (0, _virtualDom.h)("div.topic-map-post.created-at", [(0, _post.avatarFor)("tiny", {
        username: attrs.createdByUsername,
        template: attrs.createdByAvatarTemplate,
        name: attrs.createdByName
      }), (0, _node.dateNode)(attrs.topicCreatedAt)])]));
      contents.push((0, _virtualDom.h)("li.last-reply", (0, _virtualDom.h)("a", {
        attributes: {
          href: attrs.lastPostUrl
        }
      }, [(0, _virtualDom.h)("h4", {
        attributes: {
          role: "presentation"
        }
      }, _I18n.default.t("last_reply_lowercase")), (0, _virtualDom.h)("div.topic-map-post.last-reply", [(0, _post.avatarFor)("tiny", {
        username: attrs.lastPostUsername,
        template: attrs.lastPostAvatarTemplate,
        name: attrs.lastPostName
      }), (0, _node.dateNode)(attrs.lastPostAt)])])));
      contents.push((0, _virtualDom.h)("li.replies", [(0, _node.numberNode)(attrs.topicReplyCount), (0, _virtualDom.h)("h4", {
        attributes: {
          role: "presentation"
        }
      }, _I18n.default.t("replies_lowercase", {
        count: attrs.topicReplyCount
      }).toString())]));
      contents.push((0, _virtualDom.h)("li.secondary.views", [(0, _node.numberNode)(attrs.topicViews, {
        className: attrs.topicViewsHeat
      }), (0, _virtualDom.h)("h4", {
        attributes: {
          role: "presentation"
        }
      }, _I18n.default.t("views_lowercase", {
        count: attrs.topicViews
      }).toString())]));

      if (attrs.participantCount > 0) {
        contents.push((0, _virtualDom.h)("li.secondary.users", [(0, _node.numberNode)(attrs.participantCount), (0, _virtualDom.h)("h4", {
          attributes: {
            role: "presentation"
          }
        }, _I18n.default.t("users_lowercase", {
          count: attrs.participantCount
        }).toString())]));
      }

      if (attrs.topicLikeCount) {
        contents.push((0, _virtualDom.h)("li.secondary.likes", [(0, _node.numberNode)(attrs.topicLikeCount), (0, _virtualDom.h)("h4", {
          attributes: {
            role: "presentation"
          }
        }, _I18n.default.t("likes_lowercase", {
          count: attrs.topicLikeCount
        }).toString())]));
      }

      if (attrs.topicLinkLength > 0) {
        contents.push((0, _virtualDom.h)("li.secondary.links", [(0, _node.numberNode)(attrs.topicLinkLength), (0, _virtualDom.h)("h4", {
          attributes: {
            role: "presentation"
          }
        }, _I18n.default.t("links_lowercase", {
          count: attrs.topicLinkLength
        }).toString())]));
      }

      if (state.collapsed && attrs.topicPostsCount > 2 && attrs.participants && attrs.participants.length > 0) {
        var participants = renderParticipants.call(this, attrs.userFilters, attrs.participants.slice(0, 3));
        contents.push((0, _virtualDom.h)("li.avatars", participants));
      }

      var nav = (0, _virtualDom.h)("nav.buttons", this.attach("button", {
        title: state.collapsed ? "topic.expand_details" : "topic.collapse_details",
        icon: state.collapsed ? "chevron-down" : "chevron-up",
        action: "toggleMap",
        className: "btn"
      }));
      return [nav, (0, _virtualDom.h)("ul.clearfix", contents)];
    }
  });
  (0, _widget.createWidget)("topic-map-link", {
    tagName: "a.topic-link.track-link",
    buildClasses: function buildClasses(attrs) {
      if (attrs.attachment) {
        return "attachment";
      }
    },
    buildAttributes: function buildAttributes(attrs) {
      return {
        href: attrs.url,
        target: "_blank",
        "data-user-id": attrs.user_id,
        "data-ignore-post-id": "true",
        title: attrs.url,
        rel: "nofollow ugc noopener"
      };
    },
    html: function html(attrs) {
      var content = attrs.title || attrs.url;
      var truncateLength = 85;

      if (content.length > truncateLength) {
        content = "".concat(content.substr(0, truncateLength).trim(), "...");
      }

      return attrs.title ? (0, _emoji.replaceEmoji)(content) : content;
    }
  });
  (0, _widget.createWidget)("topic-map-expanded", {
    tagName: "section.topic-map-expanded",
    buildKey: function buildKey(attrs) {
      return "topic-map-expanded-".concat(attrs.id);
    },
    defaultState: function defaultState() {
      return {
        allLinksShown: false
      };
    },
    html: function html(attrs, state) {
      var _this2 = this;

      var avatars;

      if (attrs.participants && attrs.participants.length > 0) {
        avatars = (0, _virtualDom.h)("section.avatars.clearfix", [(0, _virtualDom.h)("h3", _I18n.default.t("topic_map.participants_title")), renderParticipants.call(this, attrs.userFilters, attrs.participants)]);
      }

      var result = [avatars];

      if (attrs.topicLinks) {
        var toShow = state.allLinksShown ? attrs.topicLinks : attrs.topicLinks.slice(0, LINKS_SHOWN);
        var links = toShow.map(function (l) {
          var host = "";

          if (l.title && l.title.length) {
            var rootDomain = l.root_domain;

            if (rootDomain && rootDomain.length) {
              host = (0, _virtualDom.h)("span.domain", rootDomain);
            }
          }

          return (0, _virtualDom.h)("tr", [(0, _virtualDom.h)("td", (0, _virtualDom.h)("span.badge.badge-notification.clicks", {
            attributes: {
              title: _I18n.default.t("topic_map.clicks", {
                count: l.clicks
              })
            }
          }, l.clicks.toString())), (0, _virtualDom.h)("td", [_this2.attach("topic-map-link", l), " ", host])]);
        });
        var showAllLinksContent = [(0, _virtualDom.h)("h3", _I18n.default.t("topic_map.links_title")), (0, _virtualDom.h)("table.topic-links", links)];

        if (!state.allLinksShown && links.length < attrs.topicLinks.length) {
          showAllLinksContent.push(this.attach("topic-map-show-links"));
        }

        var section = (0, _virtualDom.h)("section.links", showAllLinksContent);
        result.push(section);
      }

      return result;
    },
    showAllLinks: function showAllLinks() {
      this.state.allLinksShown = true;
    }
  });

  var _default = (0, _widget.createWidget)("topic-map", {
    tagName: "div.topic-map",
    buildKey: function buildKey(attrs) {
      return "topic-map-".concat(attrs.id);
    },
    defaultState: function defaultState(attrs) {
      return {
        collapsed: !attrs.hasTopicSummary
      };
    },
    html: function html(attrs, state) {
      var contents = [this.attach("topic-map-summary", attrs, {
        state: state
      })];

      if (!state.collapsed) {
        contents.push(this.attach("topic-map-expanded", attrs));
      }

      if (attrs.hasTopicSummary) {
        contents.push(this.attach("toggle-topic-summary", attrs));
      }

      if (attrs.showPMMap) {
        contents.push(this.attach("private-message-map", attrs));
      }

      return contents;
    },
    toggleMap: function toggleMap() {
      this.state.collapsed = !this.state.collapsed;
    }
  });

  _exports.default = _default;
});