define("discourse/templates/components/user-notification-schedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P6fviEOu",
    "block": "{\"symbols\":[\"day\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"control-group notification-schedule\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[1,[28,\"i18n\",[\"user.notification_schedule.title\"],null],false],[9],[0,\"\\n  \"],[1,[28,\"preference-checkbox\",null,[[\"labelKey\",\"checked\"],[\"user.notification_schedule.label\",[24,[\"model\",\"user_notification_schedule\",\"enabled\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"user_notification_schedule\",\"enabled\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"instruction\"],[8],[1,[28,\"i18n\",[\"user.notification_schedule.tip\"],null],false],[9],[0,\"\\n\\n    \"],[7,\"table\",true],[10,\"class\",\"notification-schedule-table\"],[8],[0,\"\\n      \"],[7,\"tbody\",true],[10,\"class\",\"notification-schedule-tbody\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"days\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"user-notification-schedule-day\",null,[[\"day\",\"startTimeOptions\",\"startTimeValue\",\"onChangeStartTime\",\"endTimeOptions\",\"endTimeValue\",\"onChangeEndTime\"],[[23,1,[\"day\"]],[23,1,[\"startTimeOptions\"]],[23,1,[\"startTimeValue\"]],[23,1,[\"onChangeStartTime\"]],[23,1,[\"endTimeOptions\"]],[23,1,[\"endTimeValue\"]],[23,1,[\"onChangeEndTime\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/user-notification-schedule.hbs"
    }
  });

  _exports.default = _default;
});