define("discourse/components/load-more", ["exports", "discourse/mixins/load-more"], function (_exports, _loadMore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_loadMore.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.set("eyelineSelector", this.selector);
    },
    actions: {
      loadMore: function loadMore() {
        this.action();
      }
    }
  });

  _exports.default = _default;
});