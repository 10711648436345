define("discourse/routes/application", ["exports", "discourse/lib/url", "discourse/models/category", "discourse/models/composer", "discourse/routes/discourse", "I18n", "discourse/mixins/open-composer", "discourse/lib/ajax", "bootbox", "discourse/models/login-method", "discourse-common/lib/get-owner", "discourse-common/lib/get-url", "discourse/lib/logout", "discourse/lib/mobile", "discourse/lib/computed", "discourse/lib/show-modal"], function (_exports, _url, _category, _composer, _discourse, _I18n, _openComposer, _ajax, _bootbox, _loginMethod, _getOwner, _getUrl, _logout, _mobile, _computed, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function unlessReadOnly(method, message) {
    return function () {
      if (this.site.get("isReadOnly")) {
        _bootbox.default.alert(message);
      } else {
        this[method]();
      }
    };
  }

  var ApplicationRoute = _discourse.default.extend(_openComposer.default, {
    siteTitle: (0, _computed.setting)("title"),
    shortSiteDescription: (0, _computed.setting)("short_site_description"),
    documentTitle: Ember.inject.service(),
    actions: {
      toggleAnonymous: function toggleAnonymous() {
        (0, _ajax.ajax)((0, _url.userPath)("toggle-anon"), {
          type: "POST"
        }).then(function () {
          window.location.reload();
        });
      },
      toggleMobileView: function toggleMobileView() {
        _mobile.default.toggleMobileView();
      },
      logout: unlessReadOnly("_handleLogout", _I18n.default.t("read_only_mode.logout_disabled")),
      _collectTitleTokens: function _collectTitleTokens(tokens) {
        tokens.push(this.siteTitle);

        if ((window.location.pathname === (0, _getUrl.default)("/") || window.location.pathname === (0, _getUrl.default)("/login")) && this.shortSiteDescription !== "") {
          tokens.push(this.shortSiteDescription);
        }

        this.documentTitle.setTitle(tokens.join(" - "));
      },
      postWasEnqueued: function postWasEnqueued(details) {
        (0, _showModal.default)("post-enqueued", {
          model: details,
          title: "review.approval.title"
        });
      },
      composePrivateMessage: function composePrivateMessage(user, post) {
        var recipients = user ? user.get("username") : "";
        var reply = post ? "".concat(window.location.protocol, "//").concat(window.location.host).concat(post.url) : null;
        var title = post ? _I18n.default.t("composer.reference_topic_title", {
          title: post.topic.title
        }) : null; // used only once, one less dependency

        return this.controllerFor("composer").open({
          action: _composer.default.PRIVATE_MESSAGE,
          recipients: recipients,
          archetypeId: "private_message",
          draftKey: _composer.default.NEW_PRIVATE_MESSAGE_KEY,
          draftSequence: 0,
          reply: reply,
          title: title
        });
      },
      error: function error(err, transition) {
        var xhrOrErr = err.jqXHR ? err.jqXHR : err;
        var exceptionController = this.controllerFor("exception");
        var c = window.console;

        if (c && c.error) {
          c.error(xhrOrErr);
        }

        if (xhrOrErr && xhrOrErr.status === 404) {
          return this.transitionTo("exception-unknown");
        }

        exceptionController.setProperties({
          lastTransition: transition,
          thrown: xhrOrErr
        });
        this.intermediateTransitionTo("exception");
        return true;
      },
      showLogin: unlessReadOnly("handleShowLogin", _I18n.default.t("read_only_mode.login_disabled")),
      showCreateAccount: unlessReadOnly("handleShowCreateAccount", _I18n.default.t("read_only_mode.login_disabled")),
      showForgotPassword: function showForgotPassword() {
        this.controllerFor("forgot-password").setProperties({
          offerHelp: null,
          helpSeen: false
        });
        (0, _showModal.default)("forgotPassword", {
          title: "forgot_password.title"
        });
      },
      showNotActivated: function showNotActivated(props) {
        (0, _showModal.default)("not-activated", {
          title: "log_in"
        }).setProperties(props);
      },
      showUploadSelector: function showUploadSelector() {
        document.getElementById("file-uploader").click();
      },
      showKeyboardShortcutsHelp: function showKeyboardShortcutsHelp() {
        (0, _showModal.default)("keyboard-shortcuts-help", {
          title: "keyboard_shortcuts_help.title"
        });
      },
      // Close the current modal, and destroy its state.
      closeModal: function closeModal(initiatedBy) {
        var route = (0, _getOwner.getOwner)(this).lookup("route:application");
        var modalController = route.controllerFor("modal");
        var controllerName = modalController.get("name");

        if (controllerName) {
          var controller = (0, _getOwner.getOwner)(this).lookup("controller:".concat(controllerName));

          if (controller && controller.beforeClose) {
            if (false === controller.beforeClose()) {
              return;
            }
          }
        }

        this.render("hide-modal", {
          into: "modal",
          outlet: "modalBody"
        });

        if (controllerName) {
          var _controller = (0, _getOwner.getOwner)(this).lookup("controller:".concat(controllerName));

          if (_controller) {
            this.appEvents.trigger("modal:closed", {
              name: controllerName,
              controller: _controller
            });

            if (_controller.onClose) {
              _controller.onClose({
                initiatedByCloseButton: initiatedBy === "initiatedByCloseButton",
                initiatedByClickOut: initiatedBy === "initiatedByClickOut",
                initiatedByESC: initiatedBy === "initiatedByESC"
              });
            }
          }

          modalController.set("name", null);
        }
      },

      /**
        Hide the modal, but keep it with all its state so that it can be shown again later.
        This is useful if you want to prompt for confirmation. hideModal, ask "Are you sure?",
        user clicks "No", reopenModal. If user clicks "Yes", be sure to call closeModal.
      **/
      hideModal: function hideModal() {
        $(".d-modal.fixed-modal").modal("hide");
      },
      reopenModal: function reopenModal() {
        $(".d-modal.fixed-modal").modal("show");
      },
      editCategory: function editCategory(category) {
        _url.default.routeTo("/c/".concat(_category.default.slugFor(category), "/edit"));
      },
      checkEmail: function checkEmail(user) {
        user.checkEmail();
      },
      changeBulkTemplate: function changeBulkTemplate(w) {
        var controllerName = w.replace("modal/", "");
        var controller = (0, _getOwner.getOwner)(this).lookup("controller:" + controllerName);
        this.render(w, {
          into: "modal/topic-bulk-actions",
          outlet: "bulkOutlet",
          controller: controller ? controllerName : "topic-bulk-actions"
        });
      },
      createNewTopicViaParams: function createNewTopicViaParams(title, body, category_id, tags) {
        this.openComposerWithTopicParams(this.controllerFor("discovery/topics"), title, body, category_id, tags);
      },
      createNewMessageViaParams: function createNewMessageViaParams() {
        var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
            _ref$recipients = _ref.recipients,
            recipients = _ref$recipients === void 0 ? [] : _ref$recipients,
            _ref$topicTitle = _ref.topicTitle,
            topicTitle = _ref$topicTitle === void 0 ? "" : _ref$topicTitle,
            _ref$topicBody = _ref.topicBody,
            topicBody = _ref$topicBody === void 0 ? "" : _ref$topicBody,
            _ref$hasGroups = _ref.hasGroups,
            hasGroups = _ref$hasGroups === void 0 ? false : _ref$hasGroups;

        this.openComposerWithMessageParams({
          recipients: recipients,
          topicTitle: topicTitle,
          topicBody: topicBody,
          hasGroups: hasGroups
        });
      }
    },
    renderTemplate: function renderTemplate() {
      this.render("application");
      this.render("user-card", {
        into: "application",
        outlet: "user-card"
      });
      this.render("modal", {
        into: "application",
        outlet: "modal"
      });
      this.render("composer", {
        into: "application",
        outlet: "composer"
      });
    },
    handleShowLogin: function handleShowLogin() {
      var _this = this;

      if (this.siteSettings.enable_discourse_connect) {
        var returnPath = encodeURIComponent(window.location.pathname);
        window.location = (0, _getUrl.default)("/session/sso?return_path=" + returnPath);
      } else {
        this._autoLogin("login", "login-modal", {
          notAuto: function notAuto() {
            return _this.controllerFor("login").resetForm();
          }
        });
      }
    },
    handleShowCreateAccount: function handleShowCreateAccount() {
      if (this.siteSettings.enable_discourse_connect) {
        var returnPath = encodeURIComponent(window.location.pathname);
        window.location = (0, _getUrl.default)("/session/sso?return_path=" + returnPath);
      } else {
        this._autoLogin("createAccount", "create-account", {
          signup: true,
          titleAriaElementId: "create-account-title"
        });
      }
    },
    _autoLogin: function _autoLogin(modal, modalClass) {
      var _ref2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
          _ref2$notAuto = _ref2.notAuto,
          notAuto = _ref2$notAuto === void 0 ? null : _ref2$notAuto,
          _ref2$signup = _ref2.signup,
          signup = _ref2$signup === void 0 ? false : _ref2$signup,
          _ref2$titleAriaElemen = _ref2.titleAriaElementId,
          titleAriaElementId = _ref2$titleAriaElemen === void 0 ? null : _ref2$titleAriaElemen;

      var methods = (0, _loginMethod.findAll)();

      if (!this.siteSettings.enable_local_logins && methods.length === 1) {
        this.controllerFor("login").send("externalLogin", methods[0], {
          signup: signup
        });
      } else {
        (0, _showModal.default)(modal, {
          titleAriaElementId: titleAriaElementId
        });
        this.controllerFor("modal").set("modalClass", modalClass);

        if (notAuto) {
          notAuto();
        }
      }
    },
    _handleLogout: function _handleLogout() {
      if (this.currentUser) {
        this.currentUser.destroySession().then(function (response) {
          return (0, _logout.default)({
            redirect: response["redirect_url"]
          });
        });
      }
    }
  });

  var _default = ApplicationRoute;
  _exports.default = _default;
});