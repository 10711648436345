define("discourse/templates/components/category-permission-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "870KKCQb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"group-name\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"group-name-label\"],[8],[1,[22,\"group_name\"],false],[9],[0,\"\\n  \"],[7,\"a\",false],[12,\"class\",\"remove-permission\"],[12,\"href\",\"\"],[3,\"action\",[[23,0,[]],\"removeRow\"]],[8],[0,\"\\n    \"],[1,[28,\"d-icon\",[\"far-trash-alt\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"options actionable\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"icon\",\"class\",\"disabled\"],[\"check-square\",\"btn btn-flat see\",true]]],false],[0,\"\\n\\n  \"],[1,[28,\"d-button\",null,[[\"icon\",\"action\",\"translatedTitle\",\"class\",\"disabled\"],[[24,[\"canReplyIcon\"]],[28,\"action\",[[23,0,[]],\"setPermissionReply\"],null],[24,[\"replyTooltip\"]],[28,\"concat\",[\"btn btn-flat reply-toggle \",[24,[\"replyGranted\"]]],null],[24,[\"replyDisabled\"]]]]],false],[0,\"\\n\\n  \"],[1,[28,\"d-button\",null,[[\"icon\",\"action\",\"translatedTitle\",\"class\",\"disabled\"],[[24,[\"canCreateIcon\"]],[28,\"action\",[[23,0,[]],\"setPermissionFull\"],null],[24,[\"createTooltip\"]],[28,\"concat\",[\"btn btn-flat create-toggle \",[24,[\"createGranted\"]]],null],[24,[\"createDisabled\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/category-permission-row.hbs"
    }
  });

  _exports.default = _default;
});