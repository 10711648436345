define("discourse/components/mobile-category-topic", ["exports", "discourse/components/topic-list-item"], function (_exports, _topicListItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "tr",
    classNameBindings: [":category-topic-link", "topic.archived", "topic.visited"],
    click: _topicListItem.showEntrance
  });

  _exports.default = _default;
});