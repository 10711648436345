define("discourse/components/category-permission-row", ["exports", "discourse-common/utils/decorators", "I18n", "discourse/models/permission-type"], function (_exports, _decorators, _I18n, _permissionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var EVERYONE = "everyone";

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("type"), _dec2 = (0, _decorators.default)("type"), _dec3 = (0, _decorators.default)("type"), _dec4 = (0, _decorators.default)("type"), _dec5 = (0, _decorators.default)("type"), _dec6 = (0, _decorators.observes)("everyonePermissionType"), _dec7 = (0, _decorators.default)("everyonePermissionType", "type"), _dec8 = (0, _decorators.default)("replyDisabled"), _dec9 = (0, _decorators.default)("everyonePermissionType", "type"), _dec10 = (0, _decorators.default)("createDisabled"), (_obj = {
    classNames: ["permission-row", "row-body"],
    canCreate: Ember.computed.equal("type", _permissionType.default.FULL),
    everyonePermissionType: Ember.computed.alias("everyonePermission.permission_type"),
    canReply: function canReply(value) {
      return value === _permissionType.default.CREATE_POST || value === _permissionType.default.FULL;
    },
    canReplyIcon: function canReplyIcon() {
      return this.canReply ? "check-square" : "far-square";
    },
    canCreateIcon: function canCreateIcon() {
      return this.canCreate ? "check-square" : "far-square";
    },
    replyGranted: function replyGranted() {
      return this.type <= _permissionType.default.CREATE_POST ? "reply-granted" : "";
    },
    createGranted: function createGranted() {
      return this.type === _permissionType.default.FULL ? "create-granted" : "";
    },
    inheritFromEveryone: function inheritFromEveryone() {
      if (this.group_name === EVERYONE) {
        return;
      } // groups cannot have a lesser permission than "everyone"


      if (this.everyonePermissionType < this.type) {
        this.updatePermission(this.everyonePermissionType);
      }
    },
    replyDisabled: function replyDisabled(everyonePermissionType) {
      if (this.group_name !== EVERYONE && everyonePermissionType && everyonePermissionType <= _permissionType.default.CREATE_POST) {
        return true;
      }

      return false;
    },
    replyTooltip: function replyTooltip() {
      return this.replyDisabled ? _I18n.default.t("category.permissions.inherited") : _I18n.default.t("category.permissions.toggle_reply");
    },
    createDisabled: function createDisabled(everyonePermissionType) {
      if (this.group_name !== EVERYONE && everyonePermissionType && everyonePermissionType === _permissionType.default.FULL) {
        return true;
      }

      return false;
    },
    createTooltip: function createTooltip() {
      return this.createDisabled ? _I18n.default.t("category.permissions.inherited") : _I18n.default.t("category.permissions.toggle_full");
    },
    updatePermission: function updatePermission(type) {
      this.category.updatePermission(this.group_name, type);
    },
    actions: {
      removeRow: function removeRow() {
        this.category.removePermission(this.group_name);
      },
      setPermissionReply: function setPermissionReply() {
        if (this.type <= _permissionType.default.CREATE_POST) {
          this.updatePermission(_permissionType.default.READONLY);
        } else {
          this.updatePermission(_permissionType.default.CREATE_POST);
        }
      },
      setPermissionFull: function setPermissionFull() {
        if (this.group_name !== EVERYONE && this.everyonePermissionType === _permissionType.default.FULL) {
          return;
        }

        if (this.type === _permissionType.default.FULL) {
          this.updatePermission(_permissionType.default.CREATE_POST);
        } else {
          this.updatePermission(_permissionType.default.FULL);
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "canReply", [_dec], Object.getOwnPropertyDescriptor(_obj, "canReply"), _obj), _applyDecoratedDescriptor(_obj, "canReplyIcon", [_dec2], Object.getOwnPropertyDescriptor(_obj, "canReplyIcon"), _obj), _applyDecoratedDescriptor(_obj, "canCreateIcon", [_dec3], Object.getOwnPropertyDescriptor(_obj, "canCreateIcon"), _obj), _applyDecoratedDescriptor(_obj, "replyGranted", [_dec4], Object.getOwnPropertyDescriptor(_obj, "replyGranted"), _obj), _applyDecoratedDescriptor(_obj, "createGranted", [_dec5], Object.getOwnPropertyDescriptor(_obj, "createGranted"), _obj), _applyDecoratedDescriptor(_obj, "inheritFromEveryone", [_dec6], Object.getOwnPropertyDescriptor(_obj, "inheritFromEveryone"), _obj), _applyDecoratedDescriptor(_obj, "replyDisabled", [_dec7], Object.getOwnPropertyDescriptor(_obj, "replyDisabled"), _obj), _applyDecoratedDescriptor(_obj, "replyTooltip", [_dec8], Object.getOwnPropertyDescriptor(_obj, "replyTooltip"), _obj), _applyDecoratedDescriptor(_obj, "createDisabled", [_dec9], Object.getOwnPropertyDescriptor(_obj, "createDisabled"), _obj), _applyDecoratedDescriptor(_obj, "createTooltip", [_dec10], Object.getOwnPropertyDescriptor(_obj, "createTooltip"), _obj)), _obj)));

  _exports.default = _default;
});