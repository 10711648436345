define("discourse/components/user-badge", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("count"), _dec2 = (0, _decorators.default)("badge", "user"), (_obj = {
    tagName: "",
    showGrantCount: function showGrantCount(count) {
      return count && count > 1;
    },
    badgeUrl: function badgeUrl() {
      // NOTE: I tried using a link-to helper here but the queryParams mean it fails
      var username = this.get("user.username_lower") || "";
      username = username !== "" ? "?username=" + username : "";
      return this.get("badge.url") + username;
    }
  }, (_applyDecoratedDescriptor(_obj, "showGrantCount", [_dec], Object.getOwnPropertyDescriptor(_obj, "showGrantCount"), _obj), _applyDecoratedDescriptor(_obj, "badgeUrl", [_dec2], Object.getOwnPropertyDescriptor(_obj, "badgeUrl"), _obj)), _obj)));

  _exports.default = _default;
});