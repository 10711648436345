define("discourse/components/share-popup", ["exports", "discourse-common/utils/decorators", "I18n", "discourse/lib/sharing", "discourse/lib/formatter", "discourse/lib/pwa-utils", "discourse/lib/intercept-click"], function (_exports, _decorators, _I18n, _sharing, _formatter, _pwaUtils, _interceptClick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("topic.{isPrivateMessage,invisible,category}"), _dec2 = (0, _decorators.default)("type", "postNumber"), _dec3 = (0, _decorators.default)("date"), (_obj = {
    elementId: "share-link",
    classNameBindings: ["visible"],
    link: null,
    visible: null,
    privateCategory: Ember.computed.alias("topic.category.read_restricted"),
    sources: function sources(topic) {
      var privateContext = this.siteSettings.login_required || topic && topic.isPrivateMessage || topic && topic.invisible || this.privateCategory;
      return _sharing.default.activeSources(this.siteSettings.share_links, privateContext);
    },
    shareTitle: function shareTitle(type, postNumber) {
      if (type === "topic") {
        return _I18n.default.t("share.topic");
      }

      if (postNumber) {
        return _I18n.default.t("share.post", {
          postNumber: postNumber
        });
      }

      return _I18n.default.t("share.topic");
    },
    displayDate: function displayDate(date) {
      return (0, _formatter.longDateNoYear)(new Date(date));
    },
    _focusUrl: function _focusUrl() {
      var _this = this;

      // Wait for the fade-in transition to finish before selecting the link:
      Ember.run.later(function () {
        if (_this.element) {
          var linkInput = _this.element.querySelector("#share-link input");

          linkInput.value = _this.link;

          if (!_this.site.mobileView) {
            // if the input is auto-focused on mobile, iOS requires two taps of the copy button
            linkInput.setSelectionRange(0, _this.link.length);
            linkInput.focus();
          }
        }
      }, 200);
    },
    _showUrl: function _showUrl($target, url) {
      var currentTargetOffset = $target.offset();
      var $this = $(this.element);

      if (Ember.isEmpty(url)) {
        return;
      } // Relative urls


      if (url.indexOf("/") === 0) {
        url = window.location.protocol + "//" + window.location.host + url;
      }

      var shareLinkWidth = $this.width();
      var x = currentTargetOffset.left - shareLinkWidth / 2;

      if (x < 25) {
        x = 25;
      }

      if (x + shareLinkWidth > $(window).width()) {
        x -= shareLinkWidth / 2;
      }

      var header = $(".d-header");
      var y = currentTargetOffset.top - ($this.height() + 20);

      if (y < header.offset().top + header.height()) {
        y = currentTargetOffset.top + 10;
      }

      this.element.style.top = "".concat(y, "px");

      if (!this.site.mobileView) {
        this.element.style.left = "".concat(x, "px");

        if (document.documentElement.classList.contains("rtl")) {
          this.element.style.right = "unset";
        }
      }

      this.set("link", url);
      this.set("visible", true);
      Ember.run.scheduleOnce("afterRender", this, this._focusUrl);
    },
    _mouseDownHandler: function _mouseDownHandler(event) {
      if (!this.element || this.isDestroying || this.isDestroyed) {
        return;
      } // Use mousedown instead of click so this event is handled before routing occurs when a
      // link is clicked (which is a click event) while the share dialog is showing.


      if ($(this.element).has(event.target).length !== 0) {
        return;
      }

      this.send("close");
      return true;
    },
    _clickHandler: function _clickHandler(event) {
      var _this2 = this;

      if (!this.element || this.isDestroying || this.isDestroyed) {
        return;
      } // if they want to open in a new tab, let it so


      if ((0, _interceptClick.wantsNewWindow)(event)) {
        return true;
      }

      event.preventDefault();
      var $currentTarget = $(event.currentTarget);
      var url = $currentTarget.data("share-url");
      var postNumber = $currentTarget.data("post-number");
      var postId = $currentTarget.closest("article").data("post-id");
      var date = $currentTarget.children().data("time");
      this.setProperties({
        postNumber: postNumber,
        date: date,
        postId: postId
      }); // use native webshare only when the user clicks on the "chain" icon

      if (!$currentTarget.hasClass("post-date")) {
        (0, _pwaUtils.nativeShare)(this.capabilities, {
          url: url
        }).then(null, function () {
          return _this2._showUrl($currentTarget, url);
        });
      } else {
        this._showUrl($currentTarget, url);
      }

      return false;
    },
    _keydownHandler: function _keydownHandler(event) {
      if (!this.element || this.isDestroying || this.isDestroyed) {
        return;
      }

      if (event.key === "Escape") {
        this.send("close");
      }
    },
    _shareUrlHandler: function _shareUrlHandler(url, $target) {
      this._showUrl($target, url);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      $("html").on("mousedown.outside-share-link", this._mouseDownHandler).on("click.discourse-share-link", "button[data-share-url], .post-info .post-date[data-share-url]", this._clickHandler).on("keydown.share-view", this._keydownHandler);
      this.appEvents.on("share:url", this, "_shareUrlHandler");
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      $("html").off("click.discourse-share-link", this._clickHandler).off("mousedown.outside-share-link", this._mouseDownHandler).off("keydown.share-view", this._keydownHandler);
      this.appEvents.off("share:url", this, "_shareUrlHandler");
    },
    actions: {
      replyAsNewTopic: function replyAsNewTopic() {
        var postStream = this.get("topic.postStream");
        var postId = this.postId || postStream.findPostIdForPostNumber(1);
        var post = postStream.findLoadedPost(postId);
        this.replyAsNewTopic(post);
        this.send("close");
      },
      close: function close() {
        this.setProperties({
          link: null,
          postNumber: null,
          postId: null,
          visible: false
        });
      },
      share: function share(source) {
        _sharing.default.shareSource(source, {
          url: this.link,
          title: this.get("topic.title")
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "sources", [_dec], Object.getOwnPropertyDescriptor(_obj, "sources"), _obj), _applyDecoratedDescriptor(_obj, "shareTitle", [_dec2], Object.getOwnPropertyDescriptor(_obj, "shareTitle"), _obj), _applyDecoratedDescriptor(_obj, "displayDate", [_dec3], Object.getOwnPropertyDescriptor(_obj, "displayDate"), _obj), _applyDecoratedDescriptor(_obj, "_mouseDownHandler", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_mouseDownHandler"), _obj), _applyDecoratedDescriptor(_obj, "_clickHandler", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_clickHandler"), _obj), _applyDecoratedDescriptor(_obj, "_keydownHandler", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_keydownHandler"), _obj)), _obj)));

  _exports.default = _default;
});