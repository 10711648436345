define("discourse/controllers/convert-to-public-topic", ["exports", "discourse/mixins/modal-functionality", "discourse/lib/ajax-error"], function (_exports, _modalFunctionality, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    publicCategoryId: null,
    saving: true,
    onShow: function onShow() {
      this.setProperties({
        publicCategoryId: null,
        saving: false
      });
    },
    actions: {
      makePublic: function makePublic() {
        var _this = this;

        var topic = this.model;
        topic.convertTopic("public", {
          categoryId: this.publicCategoryId
        }).then(function () {
          topic.set("archetype", "regular");
          topic.set("category_id", _this.publicCategoryId);

          _this.appEvents.trigger("header:show-topic", topic);

          _this.send("closeModal");
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });

  _exports.default = _default;
});