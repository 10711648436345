define("discourse/controllers/auth-token", ["exports", "discourse/mixins/modal-functionality", "discourse/lib/ajax", "discourse/lib/url"], function (_exports, _modalFunctionality, _ajax, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    expanded: false,
    onShow: function onShow() {
      var _this = this;

      (0, _ajax.ajax)((0, _url.userPath)("".concat(this.get("currentUser.username_lower"), "/activity.json"))).then(function (posts) {
        if (posts.length > 0) {
          _this.set("latest_post", posts[0]);
        }
      });
    },
    actions: {
      toggleExpanded: function toggleExpanded() {
        this.set("expanded", !this.expanded);
      },
      highlightSecure: function highlightSecure() {
        this.send("closeModal");
        Ember.run.next(function () {
          var $prefPasswordDiv = $(".pref-password");
          $prefPasswordDiv.addClass("highlighted");
          $prefPasswordDiv.on("animationend", function () {
            return $prefPasswordDiv.removeClass("highlighted");
          });
          window.scrollTo({
            top: $prefPasswordDiv.offset().top,
            behavior: "smooth"
          });
        });
      }
    }
  });

  _exports.default = _default;
});