define("discourse/templates/components/categories-boxes-topic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TRsh+TXp",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"d-icon\",[[24,[\"topicStatusIcon\"]]],null],false],[0,\"\\n\\n\"],[7,\"a\",true],[11,\"href\",[24,[\"topic\",\"lastUnreadUrl\"]]],[10,\"class\",\"title\"],[8],[0,\"\\n  \"],[1,[28,\"html-safe\",[[24,[\"topic\",\"fancyTitle\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/categories-boxes-topic.hbs"
    }
  });

  _exports.default = _default;
});