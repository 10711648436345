define("discourse/templates/components/share-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xCvqmt/n",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"d-button\",null,[[\"action\",\"class\",\"translatedTitle\",\"icon\"],[[28,\"action\",[[23,0,[]],\"share\",[24,[\"source\"]]],null],[28,\"concat\",[\"btn-default share-\",[24,[\"source\",\"id\"]]],null],[24,[\"source\",\"title\"]],[28,\"if\",[[24,[\"source\",\"icon\"]],[24,[\"source\",\"icon\"]],[24,[\"source\",\"htmlIcon\"]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/share-source.hbs"
    }
  });

  _exports.default = _default;
});