define("discourse/components/parent-category-row", ["exports", "discourse/components/category-list-item"], function (_exports, _categoryListItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _categoryListItem.default.extend({});

  _exports.default = _default;
});