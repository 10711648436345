define("discourse/components/group-smtp-email-settings", ["exports", "discourse/lib/email-provider-default-settings", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "discourse/lib/ajax"], function (_exports, _emailProviderDefaultSettings, _ajaxError, _decorators, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("form.email_username", "form.email_password", "form.smtp_server", "form.smtp_port"), _dec2 = Ember._action, _dec3 = (0, _decorators.on)("init"), _dec4 = Ember._action, _dec5 = Ember._action, (_obj = {
    tagName: "",
    form: null,
    missingSettings: function missingSettings(email_username, email_password, smtp_server, smtp_port) {
      return [email_username, email_password, smtp_server, smtp_port].some(function (value) {
        return Ember.isEmpty(value);
      });
    },
    resetSettingsValid: function resetSettingsValid() {
      this.set("smtpSettingsValid", false);
    },
    _fillForm: function _fillForm() {
      this.set("form", Ember.Object.create({
        email_username: this.group.email_username,
        email_password: this.group.email_password,
        email_from_alias: this.group.email_from_alias,
        smtp_server: this.group.smtp_server,
        smtp_port: (this.group.smtp_port || "").toString(),
        smtp_ssl: this.group.smtp_ssl
      }));
    },
    prefillSettings: function prefillSettings(provider) {
      this.form.setProperties((0, _emailProviderDefaultSettings.default)(provider, "smtp"));
    },
    testSmtpSettings: function testSmtpSettings() {
      var _this = this;

      var settings = {
        host: this.form.smtp_server,
        port: this.form.smtp_port,
        ssl: this.form.smtp_ssl,
        username: this.form.email_username,
        password: this.form.email_password
      };
      this.set("testingSettings", true);
      this.set("smtpSettingsValid", false);
      return (0, _ajax.ajax)("/groups/".concat(this.group.id, "/test_email_settings"), {
        type: "POST",
        data: Object.assign(settings, {
          protocol: "smtp"
        })
      }).then(function () {
        _this.set("smtpSettingsValid", true);

        _this.group.setProperties({
          smtp_server: _this.form.smtp_server,
          smtp_port: _this.form.smtp_port,
          smtp_ssl: _this.form.smtp_ssl,
          email_username: _this.form.email_username,
          email_from_alias: _this.form.email_from_alias,
          email_password: _this.form.email_password
        });
      }).catch(_ajaxError.popupAjaxError).finally(function () {
        return _this.set("testingSettings", false);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "missingSettings", [_dec], Object.getOwnPropertyDescriptor(_obj, "missingSettings"), _obj), _applyDecoratedDescriptor(_obj, "resetSettingsValid", [_dec2], Object.getOwnPropertyDescriptor(_obj, "resetSettingsValid"), _obj), _applyDecoratedDescriptor(_obj, "_fillForm", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_fillForm"), _obj), _applyDecoratedDescriptor(_obj, "prefillSettings", [_dec4], Object.getOwnPropertyDescriptor(_obj, "prefillSettings"), _obj), _applyDecoratedDescriptor(_obj, "testSmtpSettings", [_dec5], Object.getOwnPropertyDescriptor(_obj, "testSmtpSettings"), _obj)), _obj)));

  _exports.default = _default;
});