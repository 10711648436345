define("discourse/templates/components/user-summary-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zjlMk5g4",
    "block": "{\"symbols\":[\"@title\",\"&default\"],\"statements\":[[7,\"h3\",true],[10,\"class\",\"stats-title\"],[8],[1,[28,\"i18n\",[[28,\"concat\",[\"user.summary.\",[23,1,[]]],null]],null],false],[9],[0,\"\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/user-summary-section.hbs"
    }
  });

  _exports.default = _default;
});