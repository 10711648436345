define("discourse/widgets/quick-access-panel", ["exports", "I18n", "discourse/models/session", "discourse/widgets/widget", "virtual-dom", "discourse/lib/utilities"], function (_exports, _I18n, _session, _widget, _virtualDom, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This tries to enforce a consistent flow of fetching, caching, refreshing,
   * and rendering for "quick access items".
   *
   * There are parts to introducing a new quick access panel:
   * 1. A user menu link that sends a `quickAccess` action, with a unique `type`.
   * 2. A `quick-access-${type}` widget, extended from `quick-access-panel`.
   */
  var _default = (0, _widget.createWidget)("quick-access-panel", {
    tagName: "div.quick-access-panel",
    emptyStatePlaceholderItemKey: null,
    emptyStateWidget: null,
    buildKey: function buildKey() {
      throw Error('Cannot attach abstract widget "quick-access-panel".');
    },
    markReadRequest: function markReadRequest() {
      return Ember.RSVP.Promise.resolve();
    },
    hideBottomItems: function hideBottomItems() {
      return false;
    },
    hasUnread: function hasUnread() {
      return false;
    },
    showAllHref: function showAllHref() {
      return "";
    },
    findNewItems: function findNewItems() {
      return Ember.RSVP.Promise.resolve([]);
    },
    buildId: function buildId() {
      return this.key;
    },
    buildAttributes: function buildAttributes() {
      var attributes = this.attrs;
      attributes["aria-labelledby"] = attributes.currentQuickAccess;
      attributes["tabindex"] = "0";
      attributes["role"] = "tabpanel";
      return attributes;
    },
    newItemsLoaded: function newItemsLoaded() {},
    itemHtml: function itemHtml(item) {},
    // eslint-disable-line no-unused-vars
    emptyStatePlaceholderItem: function emptyStatePlaceholderItem() {
      if (this.emptyStatePlaceholderItemKey) {
        return (0, _virtualDom.h)("li.read", _I18n.default.t(this.emptyStatePlaceholderItemKey));
      } else if (this.emptyStateWidget) {
        return this.attach(this.emptyStateWidget);
      } else {
        return "";
      }
    },
    defaultState: function defaultState() {
      return {
        items: [],
        loading: false,
        loaded: false
      };
    },
    markRead: function markRead() {
      var _this = this;

      return this.markReadRequest().then(function () {
        _this.refreshNotifications(_this.state);

        (0, _utilities.postRNWebviewMessage)("markRead", "1");
      });
    },
    refreshNotifications: function refreshNotifications(state) {
      var _this2 = this;

      if (state.loading) {
        return;
      }

      if (this.getItems().length === 0) {
        state.loading = true;
      }

      this.findNewItems().then(function (newItems) {
        return _this2.setItems(newItems);
      }).catch(function () {
        return _this2.setItems([]);
      }).finally(function () {
        state.loading = false;
        state.loaded = true;

        _this2.newItemsLoaded();

        _this2.sendWidgetAction("itemsLoaded", {
          hasUnread: _this2.hasUnread(),
          markRead: function markRead() {
            return _this2.markRead();
          }
        });

        _this2.scheduleRerender();
      });
    },
    html: function html(attrs, state) {
      var _this3 = this;

      if (!state.loaded) {
        this.refreshNotifications(state);
      }

      if (state.loading) {
        return [(0, _virtualDom.h)("div.spinner-container", (0, _virtualDom.h)("div.spinner"))];
      }

      var items = this.getItems().length ? this.getItems().map(function (item) {
        return _this3.itemHtml(item);
      }) : [this.emptyStatePlaceholderItem()];
      var bottomItems = [];

      if (!this.hideBottomItems()) {
        var tab = _I18n.default.t(this.attrs.titleKey).toLowerCase();

        bottomItems.push( // intentionally a link so it can be ctrl clicked
        this.attach("link", {
          title: "view_all",
          titleOptions: {
            tab: tab
          },
          icon: "chevron-down",
          className: "btn btn-default btn-icon no-text show-all",
          "aria-label": "view_all",
          ariaLabelOptions: {
            tab: tab
          },
          href: this.showAllHref()
        }));
      }

      if (this.hasUnread()) {
        bottomItems.push(this.attach("button", {
          title: "user.dismiss_notifications_tooltip",
          icon: "check",
          label: "user.dismiss",
          className: "btn btn-default notifications-dismiss",
          action: "dismissNotifications"
        }));
      }

      return [(0, _virtualDom.h)("ul", items), (0, _virtualDom.h)("div.panel-body-bottom", bottomItems)];
    },
    getItems: function getItems() {
      return _session.default.currentProp("".concat(this.key, "-items")) || [];
    },
    setItems: function setItems(newItems) {
      _session.default.currentProp("".concat(this.key, "-items"), newItems);
    }
  });

  _exports.default = _default;
});