define("discourse/components/latest-topic-list-item", ["exports", "discourse/components/topic-list-item", "discourse-common/utils/decorators"], function (_exports, _topicListItem, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("topic"), (_obj = {
    attributeBindings: ["topic.id:data-topic-id"],
    classNameBindings: [":latest-topic-list-item", "unboundClassNames"],
    showEntrance: _topicListItem.showEntrance,
    navigateToTopic: _topicListItem.navigateToTopic,
    click: function click(e) {
      // for events undefined has a different meaning than false
      if (this.showEntrance(e) === false) {
        return false;
      }

      return this.unhandledRowClick(e, this.topic);
    },
    // Can be overwritten by plugins to handle clicks on other parts of the row
    unhandledRowClick: function unhandledRowClick() {},
    unboundClassNames: function unboundClassNames(topic) {
      var classes = [];

      if (topic.get("category")) {
        classes.push("category-" + topic.get("category.fullSlug"));
      }

      if (topic.get("tags")) {
        topic.get("tags").forEach(function (tagName) {
          return classes.push("tag-" + tagName);
        });
      }

      ["liked", "archived", "bookmarked", "pinned", "closed", "visited"].forEach(function (name) {
        if (topic.get(name)) {
          classes.push(name);
        }
      });
      return classes.join(" ");
    }
  }, (_applyDecoratedDescriptor(_obj, "unboundClassNames", [_dec], Object.getOwnPropertyDescriptor(_obj, "unboundClassNames"), _obj)), _obj)));

  _exports.default = _default;
});