define("discourse/components/color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: "colors-container",
    actions: {
      selectColor: function selectColor(color) {
        this.set("value", color);
      }
    }
  });

  _exports.default = _default;
});