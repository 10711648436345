define("discourse/initializers/ember-input-component-extension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var initializedOnce = false;
  var _default = {
    name: "ember-input-component-extensions",
    initialize: function initialize() {
      if (initializedOnce) {
        return;
      }

      Ember.TextField.reopen({
        attributeBindings: ["aria-describedby", "aria-invalid"]
      });
      Ember.TextArea.reopen({
        attributeBindings: ["aria-describedby", "aria-invalid"]
      });
      initializedOnce = true;
    }
  };
  _exports.default = _default;
});