define("discourse/components/topic-progress", ["exports", "discourse-common/utils/decorators", "discourse-common/config/environment"], function (_exports, _decorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var CSS_TRANSITION_DELAY = (0, _environment.isTesting)() ? 0 : 500;

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("postStream.loaded", "topic.currentPost", "postStream.filteredPostsCount"), _dec2 = (0, _decorators.default)("postStream.filteredPostsCount"), _dec3 = (0, _decorators.default)("progressPosition", "topic.last_read_post_id"), _dec4 = (0, _decorators.default)("_streamPercentage"), _dec5 = Ember._action, (_obj = {
    elementId: "topic-progress-wrapper",
    classNameBindings: ["docked", "withTransitions"],
    docked: false,
    withTransitions: null,
    progressPosition: null,
    postStream: Ember.computed.alias("topic.postStream"),
    _streamPercentage: null,
    hideProgress: function hideProgress(loaded, currentPost, filteredPostsCount) {
      var hideOnShortStream = !this.site.mobileView && filteredPostsCount < 2;
      return !loaded || !currentPost || hideOnShortStream;
    },
    hugeNumberOfPosts: function hugeNumberOfPosts(filteredPostsCount) {
      return filteredPostsCount >= this.siteSettings.short_progress_text_threshold;
    },
    showBackButton: function showBackButton(position, lastReadId) {
      if (!lastReadId) {
        return;
      }

      var stream = this.get("postStream.stream");
      var readPos = stream.indexOf(lastReadId) || 0;
      return readPos < stream.length - 1 && readPos > position;
    },
    _topicScrolled: function _topicScrolled(event) {
      if (this.docked) {
        this.setProperties({
          progressPosition: this.get("postStream.filteredPostsCount"),
          _streamPercentage: 100
        });
      } else {
        this.setProperties({
          progressPosition: event.postIndex,
          _streamPercentage: (event.percent * 100).toFixed(2)
        });
      }
    },
    progressStyle: function progressStyle(_streamPercentage) {
      return "--progress-bg-width: ".concat(_streamPercentage || 0, "%");
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.appEvents.on("composer:resized", this, this._composerEvent).on("topic:current-post-scrolled", this, this._topicScrolled);

      if (this.prevEvent) {
        Ember.run.scheduleOnce("afterRender", this, this._topicScrolled, this.prevEvent);
      }

      Ember.run.scheduleOnce("afterRender", this, this._startObserver); // start CSS transitions a tiny bit later
      // to avoid jumpiness on initial topic load

      Ember.run.later(this._addCssTransitions, CSS_TRANSITION_DELAY);
    },
    willDestroyElement: function willDestroyElement() {
      var _this$_topicBottomObs;

      this._super.apply(this, arguments);

      (_this$_topicBottomObs = this._topicBottomObserver) === null || _this$_topicBottomObs === void 0 ? void 0 : _this$_topicBottomObs.disconnect();
      this.appEvents.off("composer:resized", this, this._composerEvent).off("topic:current-post-scrolled", this, this._topicScrolled);
    },
    _addCssTransitions: function _addCssTransitions() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      this.set("withTransitions", true);
    },
    _startObserver: function _startObserver() {
      if ("IntersectionObserver" in window) {
        this._topicBottomObserver = this._setupObserver();

        this._topicBottomObserver.observe(document.querySelector("#topic-bottom"));
      }
    },
    _setupObserver: function _setupObserver() {
      var _document$querySelect;

      // minimum 50px here ensures element is not docked when
      // scrolling down quickly, it causes post stream refresh loop
      // on Android
      var bottomIntersectionMargin = ((_document$querySelect = document.querySelector("#reply-control")) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.clientHeight) || 50;
      return new IntersectionObserver(this._intersectionHandler, {
        threshold: 1,
        rootMargin: "0px 0px -".concat(bottomIntersectionMargin, "px 0px")
      });
    },
    _composerEvent: function _composerEvent() {
      // reinitializing needed to account for composer height
      // might be no longer necessary if IntersectionObserver API supports dynamic rootMargin
      // see https://github.com/w3c/IntersectionObserver/issues/428
      if ("IntersectionObserver" in window) {
        var _this$_topicBottomObs2;

        (_this$_topicBottomObs2 = this._topicBottomObserver) === null || _this$_topicBottomObs2 === void 0 ? void 0 : _this$_topicBottomObs2.disconnect();

        this._startObserver();
      }
    },
    _intersectionHandler: function _intersectionHandler(entries) {
      var _document$querySelect2;

      if (!this.element || this.isDestroying || this.isDestroyed) {
        return;
      }

      var composerH = ((_document$querySelect2 = document.querySelector("#reply-control")) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.clientHeight) || 0; // on desktop, pin this element to the composer
      // otherwise the grid layout will change too much when toggling the composer
      // and jitter when the viewport is near the topic bottom

      if (!this.site.mobileView && composerH) {
        this.set("docked", false);
        this.element.style.setProperty("bottom", "".concat(composerH, "px"));
        return;
      }

      if (entries[0].isIntersecting === true) {
        this.set("docked", true);
        this.element.style.removeProperty("bottom");
      } else {
        if (entries[0].boundingClientRect.top > 0) {
          this.set("docked", false);

          if (composerH === 0) {
            var _document$querySelect3;

            var filteredPostsHeight = ((_document$querySelect3 = document.querySelector(".posts-filtered-notice")) === null || _document$querySelect3 === void 0 ? void 0 : _document$querySelect3.clientHeight) || 0;
            filteredPostsHeight === 0 ? this.element.style.removeProperty("bottom") : this.element.style.setProperty("bottom", "".concat(filteredPostsHeight, "px"));
          } else {
            this.element.style.setProperty("bottom", "".concat(composerH, "px"));
          }
        }
      }
    },
    click: function click(e) {
      if (e.target.closest("#topic-progress")) {
        this.toggleProperty("expanded");
      }
    },
    goBack: function goBack() {
      this.attrs.jumpToPost(this.get("topic.last_read_post_number"));
    }
  }, (_applyDecoratedDescriptor(_obj, "hideProgress", [_dec], Object.getOwnPropertyDescriptor(_obj, "hideProgress"), _obj), _applyDecoratedDescriptor(_obj, "hugeNumberOfPosts", [_dec2], Object.getOwnPropertyDescriptor(_obj, "hugeNumberOfPosts"), _obj), _applyDecoratedDescriptor(_obj, "showBackButton", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showBackButton"), _obj), _applyDecoratedDescriptor(_obj, "progressStyle", [_dec4], Object.getOwnPropertyDescriptor(_obj, "progressStyle"), _obj), _applyDecoratedDescriptor(_obj, "_addCssTransitions", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_addCssTransitions"), _obj), _applyDecoratedDescriptor(_obj, "_intersectionHandler", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_intersectionHandler"), _obj), _applyDecoratedDescriptor(_obj, "goBack", [_dec5], Object.getOwnPropertyDescriptor(_obj, "goBack"), _obj)), _obj)));

  _exports.default = _default;
});