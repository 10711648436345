define("discourse/templates/components/edit-category-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L+mbaxMQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",[28,\"if\",[[24,[\"active\"]],\"active\"],null]],[3,\"action\",[[23,0,[]],\"select\"]],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/edit-category-tab.hbs"
    }
  });

  _exports.default = _default;
});