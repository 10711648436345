define("discourse/lib/link-hashtags", ["exports", "discourse-common/lib/deprecated", "discourse/lib/tag-hashtags", "discourse/lib/ajax", "discourse/lib/category-hashtags"], function (_exports, _deprecated, _tagHashtags, _ajax, _categoryHashtags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkSeenHashtags = linkSeenHashtags;
  _exports.fetchUnseenHashtags = fetchUnseenHashtags;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var categoryHashtags = {};
  var tagHashtags = {};
  var checkedHashtags = new Set();

  function linkSeenHashtags(elem) {
    var _elem;

    // eslint-disable-next-line no-undef
    if (elem instanceof jQuery) {
      elem = elem[0];
      (0, _deprecated.default)("linkSeenHashtags now expects a DOM node as first parameter", {
        since: "2.8.0.beta7",
        dropFrom: "2.9.0.beta1"
      });
    }

    var hashtags = _toConsumableArray(((_elem = elem) === null || _elem === void 0 ? void 0 : _elem.querySelectorAll("span.hashtag")) || []);

    if (hashtags.length === 0) {
      return [];
    }

    var slugs = _toConsumableArray(hashtags.map(function (hashtag) {
      return hashtag.innerText.substr(1);
    }));

    hashtags.forEach(function (hashtag, index) {
      var slug = slugs[index];
      var hasTagSuffix = slug.endsWith(_tagHashtags.TAG_HASHTAG_POSTFIX);

      if (hasTagSuffix) {
        slug = slug.substr(0, slug.length - _tagHashtags.TAG_HASHTAG_POSTFIX.length);
      }

      var lowerSlug = slug.toLowerCase();

      if (categoryHashtags[lowerSlug] && !hasTagSuffix) {
        (0, _categoryHashtags.replaceSpan)($(hashtag), slug, categoryHashtags[lowerSlug]);
      } else if (tagHashtags[lowerSlug]) {
        (0, _categoryHashtags.replaceSpan)($(hashtag), slug, tagHashtags[lowerSlug]);
      }
    });
    return slugs.map(function (slug) {
      return slug.toLowerCase();
    }).uniq().filter(function (slug) {
      return !checkedHashtags.has(slug);
    });
  }

  function fetchUnseenHashtags(slugs) {
    return (0, _ajax.ajax)("/hashtags", {
      data: {
        slugs: slugs
      }
    }).then(function (response) {
      Object.keys(response.categories).forEach(function (slug) {
        categoryHashtags[slug] = response.categories[slug];
      });
      Object.keys(response.tags).forEach(function (slug) {
        tagHashtags[slug] = response.tags[slug];
      });
      slugs.forEach(checkedHashtags.add, checkedHashtags);
    });
  }
});