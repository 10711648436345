define("discourse/templates/components/reviewable-post-edits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T/g4Fg8w",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"hasEdits\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",[29,[\"has-edits \",[22,\"historyClass\"]]]],[12,\"title\",[28,\"i18n\",[\"post.last_edited_on\"],[[\"dateTime\"],[[24,[\"editedDate\"]]]]]],[3,\"action\",[[23,0,[]],\"showEditHistory\"]],[8],[0,\"\\n    \"],[1,[28,\"d-icon\",[\"pencil-alt\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-post-edits.hbs"
    }
  });

  _exports.default = _default;
});