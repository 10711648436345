define("discourse/initializers/auth-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "auth-complete",
    after: "inject-objects",
    initialize: function initialize(container) {
      var lastAuthResult;

      if (document.getElementById("data-authentication")) {
        // Happens for full screen logins
        lastAuthResult = document.getElementById("data-authentication").dataset.authenticationData;
      }

      if (lastAuthResult) {
        var router = container.lookup("router:main");
        router.one("didTransition", function () {
          var controllerName = router.currentPath === "invites.show" ? "invites-show" : "login";
          Ember.run.next(function () {
            var controller = container.lookup("controller:".concat(controllerName));
            controller.authenticationComplete(JSON.parse(lastAuthResult));
          });
        });
      }
    }
  };
  _exports.default = _default;
});