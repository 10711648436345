define("discourse/initializers/register-media-optimization-upload-processor", ["exports", "discourse/components/composer-editor", "discourse/lib/uppy-media-optimization-plugin"], function (_exports, _composerEditor, _uppyMediaOptimizationPlugin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "register-media-optimization-upload-processor",
    initialize: function initialize(container) {
      var siteSettings = container.lookup("site-settings:main");

      if (siteSettings.composer_media_optimization_image_enabled) {
        (0, _composerEditor.addComposerUploadPreProcessor)(_uppyMediaOptimizationPlugin.default, function (_ref) {
          var isMobileDevice = _ref.isMobileDevice;
          return {
            optimizeFn: function optimizeFn(data, opts) {
              return container.lookup("service:media-optimization-worker").optimizeImage(data, opts);
            },
            runParallel: !isMobileDevice
          };
        });
      }
    }
  };
  _exports.default = _default;
});