define("discourse/initializers/logout", ["exports", "I18n", "bootbox", "discourse/lib/logout"], function (_exports, _I18n, _bootbox, _logout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _showingLogout = false; //  Subscribe to "logout" change events via the Message Bus

  var _default = {
    name: "logout",
    after: "message-bus",
    initialize: function initialize(container) {
      var messageBus = container.lookup("message-bus:main");

      if (!messageBus) {
        return;
      }

      messageBus.subscribe("/logout", function () {
        if (!_showingLogout) {
          _showingLogout = true;

          _bootbox.default.dialog(_I18n.default.t("logout"), {
            label: _I18n.default.t("home"),
            callback: _logout.default
          }, {
            onEscape: _logout.default,
            backdrop: "static"
          });
        }
      });
    }
  };
  _exports.default = _default;
});