define("discourse/components/reviewable-bundled-action", ["exports", "discourse-common/utils/decorators", "discourse/lib/text-direction"], function (_exports, _decorators, _textDirection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)(), (_obj = {
    tagName: "",
    multiple: Ember.computed.gt("bundle.actions.length", 1),
    first: Ember.computed.alias("bundle.actions.firstObject"),
    placement: function placement() {
      var vertical = this.site.mobileView ? "top" : "bottom",
          horizontal = (0, _textDirection.isRTL)() ? "end" : "start";
      return "".concat(vertical, "-").concat(horizontal);
    },
    actions: {
      performById: function performById(id) {
        this.attrs.performAction(this.get("bundle.actions").findBy("id", id));
      },
      perform: function perform(action) {
        this.attrs.performAction(action);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "placement", [_dec], Object.getOwnPropertyDescriptor(_obj, "placement"), _obj)), _obj)));

  _exports.default = _default;
});