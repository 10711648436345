define("discourse/components/pwa-install-banner", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj, _init;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var USER_DISMISSED_PROMPT_KEY = "dismissed-pwa-install-banner";

  var _default = Ember.Component.extend((_dec = (0, _decorators.on)("didInsertElement"), _dec2 = (0, _decorators.on)("willDestroyElement"), _dec3 = (0, _decorators.default)("deferredInstallPromptEvent", "bannerDismissed"), (_obj = {
    deferredInstallPromptEvent: null,
    _onInstallPrompt: function _onInstallPrompt(event) {
      // Prevent Chrome 76+ from automatically showing the prompt
      event.preventDefault(); // Stash the event so it can be triggered later

      this.set("deferredInstallPromptEvent", event);
    },
    _registerListener: function _registerListener() {
      window.addEventListener("beforeinstallprompt", this._onInstallPrompt);
    },
    _unregisterListener: function _unregisterListener() {
      window.removeEventListener("beforeinstallprompt", this._onInstallPrompt);
    },
    bannerDismissed: {
      set: function set(value) {
        this.keyValueStore.set({
          key: USER_DISMISSED_PROMPT_KEY,
          value: value
        });
        return this.keyValueStore.get(USER_DISMISSED_PROMPT_KEY);
      },
      get: function get() {
        return this.keyValueStore.get(USER_DISMISSED_PROMPT_KEY);
      }
    },
    showPWAInstallBanner: function showPWAInstallBanner() {
      var launchedFromDiscourseHub = window.location.search.indexOf("discourse_app=1") !== -1;
      return this.capabilities.isAndroid && this.get("currentUser.trust_level") > 0 && this.deferredInstallPromptEvent && // Pass the browser engagement checks
      !window.matchMedia("(display-mode: standalone)").matches && // Not be in the installed PWA already
      !launchedFromDiscourseHub && // not launched via official app
      !this.bannerDismissed // Have not a previously dismissed install banner
      ;
    },
    actions: {
      turnOn: function turnOn() {
        this.set("bannerDismissed", true);
        this.deferredInstallPromptEvent.prompt();
      },
      dismiss: function dismiss() {
        this.set("bannerDismissed", true);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "_onInstallPrompt", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_onInstallPrompt"), _obj), _applyDecoratedDescriptor(_obj, "_registerListener", [_dec], Object.getOwnPropertyDescriptor(_obj, "_registerListener"), _obj), _applyDecoratedDescriptor(_obj, "_unregisterListener", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_unregisterListener"), _obj), _applyDecoratedDescriptor(_obj, "bannerDismissed", [_decorators.default], (_init = Object.getOwnPropertyDescriptor(_obj, "bannerDismissed"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "showPWAInstallBanner", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showPWAInstallBanner"), _obj)), _obj)));

  _exports.default = _default;
});