define("discourse/components/slow-mode-info", ["exports", "discourse/models/topic", "discourse-common/utils/decorators", "discourse/helpers/slow-mode", "discourse/lib/ajax-error"], function (_exports, _topic, _decorators, _slowMode, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("topic.slow_mode_seconds"), _dec2 = (0, _decorators.default)("topic.slow_mode_seconds", "topic.closed"), _dec3 = Ember._action, (_obj = {
    durationText: function durationText(seconds) {
      return (0, _slowMode.durationTextFromSeconds)(seconds);
    },
    showSlowModeNotice: function showSlowModeNotice(seconds, closed) {
      return seconds > 0 && !closed;
    },
    disableSlowMode: function disableSlowMode() {
      var _this = this;

      _topic.default.setSlowMode(this.topic.id, 0).catch(_ajaxError.popupAjaxError).then(function () {
        return _this.set("topic.slow_mode_seconds", 0);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "durationText", [_dec], Object.getOwnPropertyDescriptor(_obj, "durationText"), _obj), _applyDecoratedDescriptor(_obj, "showSlowModeNotice", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showSlowModeNotice"), _obj), _applyDecoratedDescriptor(_obj, "disableSlowMode", [_dec3], Object.getOwnPropertyDescriptor(_obj, "disableSlowMode"), _obj)), _obj)));

  _exports.default = _default;
});