define("discourse/templates/d-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "81zmIyDQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"section\",true],[8],[14,1],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/d-section.hbs"
    }
  });

  _exports.default = _default;
});