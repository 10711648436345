define("discourse/components/link-to-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showInput: false,
    click: function click() {
      var _this = this;

      this.onClick();
      Ember.run.schedule("afterRender", function () {
        $(_this.element).find("input").focus();
      });
      return false;
    }
  });

  _exports.default = _default;
});