define("discourse/components/watch-read", ["exports", "discourse/lib/is-element-in-viewport"], function (_exports, _isElementInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var currentUser = this.currentUser;

      if (!currentUser) {
        return;
      }

      var path = this.path;

      if (path === "faq" || path === "guidelines") {
        $(window).on("load.faq resize.faq scroll.faq", function () {
          var faqUnread = !currentUser.get("read_faq");

          if (faqUnread && (0, _isElementInViewport.default)($(".contents p").last()[0])) {
            _this.action();
          }
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      $(window).off("load.faq resize.faq scroll.faq");
    }
  });

  _exports.default = _default;
});