define("discourse/templates/components/user-profile-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z6ONVW46",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"user-profile-avatar\"],[8],[0,\"\\n  \"],[1,[28,\"bound-avatar\",[[23,1,[]],\"huge\"],null],false],[0,\"\\n  \"],[1,[28,\"user-avatar-flair\",null,[[\"user\"],[[23,1,[]]]]],false],[0,\"\\n  \"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"connectorTagName\",\"args\",\"tagName\"],[\"user-profile-avatar-flair\",\"div\",[28,\"hash\",null,[[\"model\"],[[23,1,[]]]]],\"div\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/user-profile-avatar.hbs"
    }
  });

  _exports.default = _default;
});