define("discourse/components/uppy-image-uploader", ["exports", "discourse/mixins/uppy-upload", "discourse-common/utils/decorators", "discourse-common/lib/get-url", "discourse/lib/lightbox"], function (_exports, _uppyUpload, _decorators, _getUrl, _lightbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_uppyUpload.default, (_dec = (0, _decorators.default)("imageUrl", "placeholderUrl"), _dec2 = (0, _decorators.default)("placeholderUrl"), _dec3 = (0, _decorators.default)("imageUrl"), _dec4 = (0, _decorators.default)("imageCDNURL"), _dec5 = (0, _decorators.default)("imageUrl"), _dec6 = (0, _decorators.on)("didRender"), _dec7 = (0, _decorators.on)("willDestroyElement"), (_obj = {
    classNames: ["image-uploader"],
    uploadingOrProcessing: Ember.computed.or("uploading", "processing"),
    showingPlaceholder: function showingPlaceholder(imageUrl, placeholderUrl) {
      return !imageUrl && placeholderUrl;
    },
    placeholderStyle: function placeholderStyle(url) {
      if (Ember.isEmpty(url)) {
        return "".htmlSafe();
      }

      return "background-image: url(".concat(url, ")").htmlSafe();
    },
    imageCDNURL: function imageCDNURL(url) {
      if (Ember.isEmpty(url)) {
        return "".htmlSafe();
      }

      return (0, _getUrl.getURLWithCDN)(url);
    },
    backgroundStyle: function backgroundStyle(url) {
      return "background-image: url(".concat(url, ")").htmlSafe();
    },
    imageBaseName: function imageBaseName(imageUrl) {
      if (Ember.isEmpty(imageUrl)) {
        return;
      }

      return imageUrl.split("/").slice(-1)[0];
    },
    validateUploadedFilesOptions: function validateUploadedFilesOptions() {
      return {
        imagesOnly: true
      };
    },
    uploadDone: function uploadDone(upload) {
      this.setProperties({
        imageFilesize: upload.human_filesize,
        imageFilename: upload.original_filename,
        imageWidth: upload.width,
        imageHeight: upload.height
      }); // the value of the property used for imageUrl should be set
      // in this callback. this should be done in cases where imageUrl
      // is bound to a computed property of the parent component.

      if (this.onUploadDone) {
        this.onUploadDone(upload);
      } else {
        this.set("imageUrl", upload.url);
      }
    },
    _applyLightbox: function _applyLightbox() {
      var _this = this;

      Ember.run.next(function () {
        return (0, _lightbox.default)(_this.element, _this.siteSettings);
      });
    },
    _closeOnRemoval: function _closeOnRemoval() {
      var _$$magnificPopup;

      if ((_$$magnificPopup = $.magnificPopup) !== null && _$$magnificPopup !== void 0 && _$$magnificPopup.instance) {
        $.magnificPopup.instance.close();
      }
    },
    actions: {
      toggleLightbox: function toggleLightbox() {
        var _$;

        (_$ = $(this.element.querySelector("a.lightbox"))) === null || _$ === void 0 ? void 0 : _$.magnificPopup("open");
      },
      trash: function trash() {
        // uppy needs to be reset to allow for more uploads
        this._reset(); // the value of the property used for imageUrl should be cleared
        // in this callback. this should be done in cases where imageUrl
        // is bound to a computed property of the parent component.


        if (this.onUploadDeleted) {
          this.onUploadDeleted();
        } else {
          this.setProperties({
            imageUrl: null
          });
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "showingPlaceholder", [_dec], Object.getOwnPropertyDescriptor(_obj, "showingPlaceholder"), _obj), _applyDecoratedDescriptor(_obj, "placeholderStyle", [_dec2], Object.getOwnPropertyDescriptor(_obj, "placeholderStyle"), _obj), _applyDecoratedDescriptor(_obj, "imageCDNURL", [_dec3], Object.getOwnPropertyDescriptor(_obj, "imageCDNURL"), _obj), _applyDecoratedDescriptor(_obj, "backgroundStyle", [_dec4], Object.getOwnPropertyDescriptor(_obj, "backgroundStyle"), _obj), _applyDecoratedDescriptor(_obj, "imageBaseName", [_dec5], Object.getOwnPropertyDescriptor(_obj, "imageBaseName"), _obj), _applyDecoratedDescriptor(_obj, "_applyLightbox", [_dec6], Object.getOwnPropertyDescriptor(_obj, "_applyLightbox"), _obj), _applyDecoratedDescriptor(_obj, "_closeOnRemoval", [_dec7], Object.getOwnPropertyDescriptor(_obj, "_closeOnRemoval"), _obj)), _obj)));

  _exports.default = _default;
});