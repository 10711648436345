define("discourse/templates/modal/group-default-notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LGzxKodv",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"title\"],[\"groups.default_notifications.modal_title\"]],{\"statements\":[[0,\"  \"],[1,[28,\"i18n\",[\"groups.default_notifications.modal_description\"],[[\"count\"],[[24,[\"model\",\"count\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"class\",\"label\"],[[28,\"action\",[[23,0,[]],\"updateExistingUsers\"],null],\"btn-primary\",\"groups.default_notifications.modal_yes\"]]],false],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"action\",\"label\"],[[28,\"action\",[[23,0,[]],\"cancel\"],null],\"groups.default_notifications.modal_no\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/group-default-notifications.hbs"
    }
  });

  _exports.default = _default;
});