define("discourse/controllers/publish-page", ["exports", "discourse/mixins/modal-functionality", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _modalFunctionality, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var States = {
    initializing: "initializing",
    checking: "checking",
    valid: "valid",
    invalid: "invalid",
    saving: "saving",
    new: "new",
    existing: "existing",
    unpublishing: "unpublishing",
    unpublished: "unpublished"
  };
  var StateHelpers = {};
  Object.keys(States).forEach(function (name) {
    StateHelpers[name] = Ember.computed.equal("state", name);
  });

  var _default = Ember.Controller.extend(_modalFunctionality.default, StateHelpers, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_obj = {
    state: null,
    reason: null,
    publishedPage: null,
    disabled: Ember.computed.not("valid"),
    showUrl: Ember.computed("state", function () {
      return this.state === States.valid || this.state === States.saving || this.state === States.existing;
    }),
    showUnpublish: Ember.computed("state", function () {
      return this.state === States.existing || this.state === States.unpublishing;
    }),
    onShow: function onShow() {
      var _this = this;

      this.set("state", States.initializing);
      this.store.find("published_page", this.model.id).then(function (page) {
        _this.setProperties({
          state: States.existing,
          publishedPage: page
        });
      }).catch(this.startNew);
    },
    startCheckSlug: function startCheckSlug() {
      if (this.state === States.existing) {
        return;
      }

      this.set("state", States.checking);
    },
    checkSlug: function checkSlug() {
      var _this2 = this;

      if (this.state === States.existing) {
        return;
      }

      return (0, _ajax.ajax)("/pub/check-slug", {
        data: {
          slug: this.publishedPage.slug
        }
      }).then(function (result) {
        if (result.valid_slug) {
          _this2.set("state", States.valid);
        } else {
          _this2.setProperties({
            state: States.invalid,
            reason: result.reason
          });
        }
      });
    },
    unpublish: function unpublish() {
      var _this3 = this;

      this.set("state", States.unpublishing);
      return this.publishedPage.destroyRecord().then(function () {
        _this3.set("state", States.unpublished);

        _this3.model.set("publishedPage", null);
      }).catch(function (result) {
        _this3.set("state", States.existing);

        (0, _ajaxError.popupAjaxError)(result);
      });
    },
    publish: function publish() {
      var _this4 = this;

      this.set("state", States.saving);
      return this.publishedPage.update(this.publishedPage.getProperties("slug", "public")).then(function () {
        _this4.set("state", States.existing);

        _this4.model.set("publishedPage", _this4.publishedPage);
      }).catch(function (errResult) {
        (0, _ajaxError.popupAjaxError)(errResult);

        _this4.set("state", States.existing);
      });
    },
    startNew: function startNew() {
      this.setProperties({
        state: States.new,
        publishedPage: this.store.createRecord("published_page", this.model.getProperties("id", "slug", "public"))
      });
      this.checkSlug();
    },
    onChangePublic: function onChangePublic(isPublic) {
      this.publishedPage.set("public", isPublic);

      if (this.showUnpublish) {
        this.publish();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "startCheckSlug", [_dec], Object.getOwnPropertyDescriptor(_obj, "startCheckSlug"), _obj), _applyDecoratedDescriptor(_obj, "checkSlug", [_dec2], Object.getOwnPropertyDescriptor(_obj, "checkSlug"), _obj), _applyDecoratedDescriptor(_obj, "unpublish", [_dec3], Object.getOwnPropertyDescriptor(_obj, "unpublish"), _obj), _applyDecoratedDescriptor(_obj, "publish", [_dec4], Object.getOwnPropertyDescriptor(_obj, "publish"), _obj), _applyDecoratedDescriptor(_obj, "startNew", [_dec5], Object.getOwnPropertyDescriptor(_obj, "startNew"), _obj), _applyDecoratedDescriptor(_obj, "onChangePublic", [_dec6], Object.getOwnPropertyDescriptor(_obj, "onChangePublic"), _obj)), _obj)));

  _exports.default = _default;
});