define("discourse/templates/components/d-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hcvF13nG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[1,[28,\"d-icon\",[\"spinner\"],[[\"class\"],[\"loading-icon\"]]],false]],\"parameters\":[]},{\"statements\":[[1,[28,\"d-icon\",[[24,[\"icon\"]]],null],false]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[1,[28,\"d-icon\",[\"spinner\"],[[\"class\"],[\"loading-icon\"]]],false]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"if\",[[24,[\"computedLabel\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"class\",\"d-button-label\"],[8],[1,[28,\"html-safe\",[[24,[\"computedLabel\"]]],null],false],[4,\"if\",[[24,[\"ellipsis\"]]],null,{\"statements\":[[0,\"…\"]],\"parameters\":[]},null],[9]],\"parameters\":[]},{\"statements\":[[0,\"​\\n\"]],\"parameters\":[]}],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/d-button.hbs"
    }
  });

  _exports.default = _default;
});