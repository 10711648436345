define("discourse/helpers/directory-table-header-title", ["exports", "discourse-common/lib/helpers", "I18n", "discourse-common/lib/icon-library"], function (_exports, _helpers, _I18n, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helpers.registerUnbound)("directory-table-header-title", function (args) {
    // Args should include key/values { field, labelKey, icon, translated }
    var html = "";

    if (args.icon) {
      html += (0, _iconLibrary.iconHTML)(args.icon);
    }

    var labelKey = args.labelKey || "directory.".concat(args.field);
    html += args.translated ? args.field : _I18n.default.t(labelKey + "_long", {
      defaultValue: _I18n.default.t(labelKey)
    });
    return Ember.String.htmlSafe(html);
  });

  _exports.default = _default;
});