define("discourse/controllers/invites-show", ["exports", "discourse/lib/url", "I18n", "discourse/mixins/name-validation", "discourse/mixins/password-validation", "discourse/mixins/user-fields-validation", "discourse/mixins/username-validation", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "discourse/lib/utilities", "discourse/models/login-method", "discourse-common/lib/get-url", "discourse/lib/waving-hand-url"], function (_exports, _url, _I18n, _nameValidation, _passwordValidation, _userFieldsValidation, _usernameValidation, _ajax, _ajaxError, _decorators, _utilities, _loginMethod, _getUrl, _wavingHandUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj, _init, _init2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_passwordValidation.default, _usernameValidation.default, _nameValidation.default, _userFieldsValidation.default, (_dec = (0, _decorators.default)("email"), _dec2 = (0, _decorators.default)("externalAuthsEnabled", "externalAuthsOnly", "discourseConnectEnabled"), _dec3 = (0, _decorators.default)("externalAuthsOnly", "authOptions", "emailValidation.failed"), _dec4 = (0, _decorators.default)("email", "rejectedEmails.[]", "authOptions.email", "authOptions.email_valid", "hiddenEmail", "emailVerifiedByLink", "differentExternalEmail"), _dec5 = (0, _decorators.default)("authOptions.associate_url", "authOptions.auth_provider"), (_obj = {
    queryParams: ["t"],
    createAccount: Ember.inject.controller(),
    invitedBy: Ember.computed.readOnly("model.invited_by"),
    email: Ember.computed.alias("model.email"),
    accountEmail: Ember.computed.alias("email"),
    hiddenEmail: Ember.computed.alias("model.hidden_email"),
    emailVerifiedByLink: Ember.computed.alias("model.email_verified_by_link"),
    differentExternalEmail: Ember.computed.alias("model.different_external_email"),
    accountUsername: Ember.computed.alias("model.username"),
    passwordRequired: Ember.computed.notEmpty("accountPassword"),
    successMessage: null,
    errorMessage: null,
    userFields: null,
    authOptions: null,
    inviteImageUrl: (0, _getUrl.default)("/images/envelope.svg"),
    isInviteLink: Ember.computed.readOnly("model.is_invite_link"),
    submitDisabled: Ember.computed.or("emailValidation.failed", "usernameValidation.failed", "passwordValidation.failed", "nameValidation.failed", "userFieldsValidation.failed"),
    rejectedEmails: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.rejectedEmails = [];
    },
    authenticationComplete: function authenticationComplete(options) {
      var props = {
        accountUsername: options.username,
        accountName: options.name,
        authOptions: Ember.Object.create(options)
      };

      if (this.isInviteLink) {
        props.email = options.email;
      }

      this.setProperties(props);
    },
    discourseConnectEnabled: function discourseConnectEnabled() {
      return this.siteSettings.enable_discourse_connect;
    },
    welcomeTitle: function welcomeTitle() {
      return _I18n.default.t("invites.welcome_to", {
        site_name: this.siteSettings.title
      });
    },
    yourEmailMessage: function yourEmailMessage(email) {
      return _I18n.default.t("invites.your_email", {
        email: email
      });
    },
    externalAuthsEnabled: function externalAuthsEnabled() {
      return (0, _loginMethod.findAll)().length > 0;
    },
    externalAuthsOnly: function externalAuthsOnly() {
      return !this.siteSettings.enable_local_logins && this.externalAuthsEnabled && !this.siteSettings.enable_discourse_connect;
    },
    showSocialLoginAvailable: function showSocialLoginAvailable(externalAuthsEnabled, externalAuthsOnly, discourseConnectEnabled) {
      return externalAuthsEnabled && !externalAuthsOnly && !discourseConnectEnabled;
    },
    shouldDisplayForm: function shouldDisplayForm(externalAuthsOnly, authOptions, emailValidationFailed) {
      return (this.siteSettings.enable_local_logins || externalAuthsOnly && authOptions && !emailValidationFailed) && !this.siteSettings.enable_discourse_connect;
    },
    fullnameRequired: function fullnameRequired() {
      return this.siteSettings.full_name_required || this.siteSettings.enable_names;
    },
    emailValidation: function emailValidation(email, rejectedEmails, externalAuthEmail, externalAuthEmailValid, hiddenEmail, emailVerifiedByLink, differentExternalEmail) {
      if (hiddenEmail && !differentExternalEmail) {
        return Ember.Object.create({
          ok: true,
          reason: _I18n.default.t("user.email.ok")
        });
      } // If blank, fail without a reason


      if (Ember.isEmpty(email)) {
        return Ember.Object.create({
          failed: true
        });
      }

      if (rejectedEmails.includes(email)) {
        return Ember.Object.create({
          failed: true,
          reason: _I18n.default.t("user.email.invalid")
        });
      }

      if (externalAuthEmail && externalAuthEmailValid) {
        var provider = this.createAccount.authProviderDisplayName(this.get("authOptions.auth_provider"));

        if (externalAuthEmail === email) {
          return Ember.Object.create({
            ok: true,
            reason: _I18n.default.t("user.email.authenticated", {
              provider: provider
            })
          });
        } else {
          return Ember.Object.create({
            failed: true,
            reason: _I18n.default.t("user.email.invite_auth_email_invalid", {
              provider: provider
            })
          });
        }
      }

      if (emailVerifiedByLink) {
        return Ember.Object.create({
          ok: true,
          reason: _I18n.default.t("user.email.authenticated_by_invite")
        });
      }

      if ((0, _utilities.emailValid)(email)) {
        return Ember.Object.create({
          ok: true,
          reason: _I18n.default.t("user.email.ok")
        });
      }

      return Ember.Object.create({
        failed: true,
        reason: _I18n.default.t("user.email.invalid")
      });
    },
    wavingHandURL: function wavingHandURL() {
      return (0, _wavingHandUrl.wavingHandURL)();
    },
    ssoPath: function ssoPath() {
      return (0, _getUrl.default)("/session/sso");
    },
    associateHtml: function associateHtml(url, provider) {
      if (!url) {
        return;
      }

      return _I18n.default.t("create_account.associate", {
        associate_link: url,
        provider: _I18n.default.t("login.".concat(provider, ".name"))
      });
    },
    actions: {
      submit: function submit() {
        var _this = this;

        var userFields = this.userFields;
        var userCustomFields = {};

        if (!Ember.isEmpty(userFields)) {
          userFields.forEach(function (f) {
            userCustomFields[f.get("field.id")] = f.get("value");
          });
        }

        var data = {
          username: this.accountUsername,
          name: this.accountName,
          password: this.accountPassword,
          user_custom_fields: userCustomFields,
          timezone: moment.tz.guess()
        };

        if (this.isInviteLink) {
          data.email = this.email;
        } else {
          data.email_token = this.t;
        }

        (0, _ajax.ajax)({
          url: "/invites/show/".concat(this.get("model.token"), ".json"),
          type: "PUT",
          data: data
        }).then(function (result) {
          if (result.success) {
            _this.set("successMessage", result.message || _I18n.default.t("invites.success"));

            if (result.redirect_to) {
              _url.default.redirectTo(result.redirect_to);
            }
          } else {
            if (result.errors && result.errors.email && result.errors.email.length > 0 && result.values) {
              _this.rejectedEmails.pushObject(result.values.email);
            }

            if (result.errors && result.errors.password && result.errors.password.length > 0) {
              _this.rejectedPasswords.pushObject(_this.accountPassword);

              _this.rejectedPasswordsMessages.set(_this.accountPassword, result.errors.password[0]);
            }

            if (result.message) {
              _this.set("errorMessage", result.message);
            }
          }
        }).catch(function (error) {
          _this.set("errorMessage", (0, _ajaxError.extractError)(error));
        });
      },
      externalLogin: function externalLogin(provider) {
        provider.doLogin({
          signup: true,
          params: {
            origin: window.location.href
          }
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "discourseConnectEnabled", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "discourseConnectEnabled"), _obj), _applyDecoratedDescriptor(_obj, "welcomeTitle", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "welcomeTitle"), _obj), _applyDecoratedDescriptor(_obj, "yourEmailMessage", [_dec], Object.getOwnPropertyDescriptor(_obj, "yourEmailMessage"), _obj), _applyDecoratedDescriptor(_obj, "externalAuthsEnabled", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "externalAuthsEnabled"), _obj), _applyDecoratedDescriptor(_obj, "externalAuthsOnly", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "externalAuthsOnly"), _obj), _applyDecoratedDescriptor(_obj, "showSocialLoginAvailable", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showSocialLoginAvailable"), _obj), _applyDecoratedDescriptor(_obj, "shouldDisplayForm", [_dec3], Object.getOwnPropertyDescriptor(_obj, "shouldDisplayForm"), _obj), _applyDecoratedDescriptor(_obj, "fullnameRequired", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "fullnameRequired"), _obj), _applyDecoratedDescriptor(_obj, "emailValidation", [_dec4], Object.getOwnPropertyDescriptor(_obj, "emailValidation"), _obj), _applyDecoratedDescriptor(_obj, "wavingHandURL", [_decorators.default], (_init = Object.getOwnPropertyDescriptor(_obj, "wavingHandURL"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "ssoPath", [_decorators.default], (_init2 = Object.getOwnPropertyDescriptor(_obj, "ssoPath"), _init2 = _init2 ? _init2.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init2;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "associateHtml", [_dec5], Object.getOwnPropertyDescriptor(_obj, "associateHtml"), _obj)), _obj)));

  _exports.default = _default;
});