define("discourse/lib/settings", ["exports", "discourse-common/lib/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prioritizeNameInUx = prioritizeNameInUx;
  _exports.emojiBasePath = emojiBasePath;

  function prioritizeNameInUx(name) {
    var siteSettings = (0, _helpers.helperContext)().siteSettings;
    return !siteSettings.prioritize_username_in_ux && name && name.trim().length > 0;
  }

  function emojiBasePath() {
    var siteSettings = (0, _helpers.helperContext)().siteSettings;
    return siteSettings.external_emoji_url === "" ? "/images/emoji" : siteSettings.external_emoji_url;
  }
});