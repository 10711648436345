define("discourse/routes/preferences-username", ["exports", "discourse/routes/restricted-user"], function (_exports, _restrictedUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _restrictedUser.default.extend({
    showFooter: true,
    model: function model() {
      return this.modelFor("user");
    },
    renderTemplate: function renderTemplate() {
      return this.render({
        into: "user"
      });
    },
    // A bit odd, but if we leave to /preferences we need to re-render that outlet
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.render("preferences", {
        into: "user",
        controller: "preferences"
      });
    },
    setupController: function setupController(controller, user) {
      controller.setProperties({
        model: user,
        newUsername: user.get("username")
      });
    }
  });

  _exports.default = _default;
});