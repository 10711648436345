define("discourse/initializers/ember-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var initializedOnce = false;
  var _default = {
    name: "ember-events",
    initialize: function initialize() {
      // By default Ember listens to too many events. This tells it the only events
      // we're interested in. (it removes mousemove, touchstart and touchmove)
      if (initializedOnce) {
        return;
      }

      Ember.EventDispatcher.reopen({
        events: {
          touchend: "touchEnd",
          touchcancel: "touchCancel",
          keydown: "keyDown",
          keyup: "keyUp",
          keypress: "keyPress",
          mousedown: "mouseDown",
          mouseup: "mouseUp",
          contextmenu: "contextMenu",
          click: "click",
          dblclick: "doubleClick",
          focusin: "focusIn",
          focusout: "focusOut",
          mouseenter: "mouseEnter",
          mouseleave: "mouseLeave",
          submit: "submit",
          input: "input",
          change: "change",
          dragstart: "dragStart",
          drag: "drag",
          dragenter: "dragEnter",
          dragleave: "dragLeave",
          dragover: "dragOver",
          drop: "drop",
          dragend: "dragEnd"
        }
      });
      initializedOnce = true;
    }
  };
  _exports.default = _default;
});