define("discourse/controllers/preferences/username", ["exports", "discourse/lib/url", "discourse-common/utils/decorators", "discourse/lib/computed", "I18n", "discourse/models/user", "bootbox", "discourse/lib/ajax-error"], function (_exports, _url, _decorators, _computed, _I18n, _user, _bootbox, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.observes)("newUsername"), _dec2 = (0, _decorators.default)("saving"), (_obj = {
    taken: false,
    saving: false,
    errorMessage: null,
    newUsername: null,
    maxLength: (0, _computed.setting)("max_username_length"),
    minLength: (0, _computed.setting)("min_username_length"),
    newUsernameEmpty: Ember.computed.empty("newUsername"),
    saveDisabled: Ember.computed.or("saving", "newUsernameEmpty", "taken", "unchanged", "errorMessage"),
    unchanged: (0, _computed.propertyEqual)("newUsername", "username"),
    checkTaken: function checkTaken() {
      var _this = this;

      var newUsername = this.newUsername;

      if (newUsername && newUsername.length < this.minLength) {
        this.set("errorMessage", _I18n.default.t("user.name.too_short"));
      } else {
        this.set("taken", false);
        this.set("errorMessage", null);

        if (Ember.isEmpty(this.newUsername)) {
          return;
        }

        if (this.unchanged) {
          return;
        }

        _user.default.checkUsername(newUsername, undefined, this.get("model.id")).then(function (result) {
          if (result.errors) {
            _this.set("errorMessage", result.errors.join(" "));
          } else if (result.available === false) {
            _this.set("taken", true);
          }
        });
      }
    },
    saveButtonText: function saveButtonText(saving) {
      if (saving) {
        return _I18n.default.t("saving");
      }

      return _I18n.default.t("user.change");
    },
    actions: {
      changeUsername: function changeUsername() {
        var _this2 = this;

        if (this.saveDisabled) {
          return;
        }

        return _bootbox.default.confirm(_I18n.default.t("user.change_username.confirm"), _I18n.default.t("no_value"), _I18n.default.t("yes_value"), function (result) {
          if (result) {
            _this2.set("saving", true);

            _this2.model.changeUsername(_this2.newUsername).then(function () {
              _url.default.redirectTo((0, _url.userPath)(_this2.newUsername.toLowerCase() + "/preferences"));
            }).catch(_ajaxError.popupAjaxError).finally(function () {
              return _this2.set("saving", false);
            });
          }
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "checkTaken", [_dec], Object.getOwnPropertyDescriptor(_obj, "checkTaken"), _obj), _applyDecoratedDescriptor(_obj, "saveButtonText", [_dec2], Object.getOwnPropertyDescriptor(_obj, "saveButtonText"), _obj)), _obj)));

  _exports.default = _default;
});