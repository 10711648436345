define("discourse/controllers/discovery/topics", ["exports", "discourse/mixins/bulk-topic-selection", "discourse/controllers/discovery", "I18n", "discourse/models/topic", "discourse/models/topic-list", "discourse-common/lib/deprecated", "discourse-common/utils/decorators", "discourse/lib/computed", "discourse/helpers/route-action", "discourse/lib/url"], function (_exports, _bulkTopicSelection, _discovery, _I18n, _topic, _topicList, _deprecated, _decorators, _computed, _routeAction, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var controllerOpts = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = (0, _decorators.default)("model.filter", "model.topics.length"), _dec4 = (0, _decorators.default)("model.filter", "model.topics.length"), _dec5 = (0, _decorators.default)("allLoaded", "model.topics.length"), _dec6 = (0, _decorators.default)("allLoaded", "model.topics.length"), (_obj = {
    discovery: Ember.inject.controller(),
    discoveryTopics: Ember.inject.controller("discovery/topics"),
    router: Ember.inject.service(),
    period: null,
    canCreateTopicOnCategory: null,
    canStar: Ember.computed.alias("currentUser.id"),
    showTopicPostBadges: Ember.computed.not("discoveryTopics.new"),
    redirectedReason: Ember.computed.alias("currentUser.redirected_to_top.reason"),
    expandGloballyPinned: false,
    expandAllPinned: false,
    order: Ember.computed.readOnly("model.params.order"),
    ascending: Ember.computed.readOnly("model.params.ascending"),
    selected: null,
    loadingBegan: function loadingBegan() {
      return true;
    },
    loadingComplete: function loadingComplete() {
      return true;
    },
    showDismissRead: function showDismissRead(filter, topicsLength) {
      return this._isFilterPage(filter, "unread") && topicsLength > 0;
    },
    showResetNew: function showResetNew(filter, topicsLength) {
      return this._isFilterPage(filter, "new") && topicsLength > 0;
    },
    actions: {
      changeSort: function changeSort() {
        (0, _deprecated.default)("changeSort has been changed from an (action) to a (route-action)", {
          since: "2.6.0",
          dropFrom: "2.7.0"
        });
        return _routeAction.routeAction.apply(void 0, ["changeSort", this.router._router].concat(Array.prototype.slice.call(arguments)))();
      },
      // Show newly inserted topics
      showInserted: function showInserted() {
        var tracker = this.topicTrackingState; // Move inserted into topics

        this.model.loadBefore(tracker.get("newIncoming"), true);
        tracker.resetTracking();
        return false;
      },
      refresh: function refresh() {
        var _this = this;

        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
          skipResettingParams: []
        };
        var filter = this.get("model.filter");
        this.send("resetParams", options.skipResettingParams); // Don't refresh if we're still loading

        if (this.discovery.loading) {
          return;
        } // If we `send('loading')` here, due to returning true it bubbles up to the
        // router and ember throws an error due to missing `handlerInfos`.
        // Lesson learned: Don't call `loading` yourself.


        this.discovery.loadingBegan();
        this.topicTrackingState.resetTracking();
        this.store.findFiltered("topicList", {
          filter: filter
        }).then(function (list) {
          _topicList.default.hideUniformCategory(list, _this.category); // If query params are present in the current route, we need still need to sync topic
          // tracking with the topicList without any query params. Then we set the topic
          // list to the list filtered with query params in the afterRefresh.


          var params = _this.router.currentRoute.queryParams;

          if (Object.keys(params).length) {
            _this.store.findFiltered("topicList", {
              filter: filter,
              params: params
            }).then(function (listWithParams) {
              _this.afterRefresh(filter, list, listWithParams);
            });
          } else {
            _this.afterRefresh(filter, list);
          }
        });
      },
      resetNew: function resetNew() {
        var _this2 = this;

        var tracked = (this.router.currentRoute.queryParams["f"] || this.router.currentRoute.queryParams["filter"]) === "tracked";
        var topicIds = this.selected ? this.selected.map(function (topic) {
          return topic.id;
        }) : null;

        _topic.default.resetNew(this.category, !this.noSubcategories, {
          tracked: tracked,
          topicIds: topicIds
        }).then(function () {
          return _this2.send("refresh", tracked ? {
            skipResettingParams: ["filter", "f"]
          } : {});
        });
      }
    },
    afterRefresh: function afterRefresh(filter, list) {
      var listModel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : list;
      this.setProperties({
        model: listModel
      });
      this.resetSelected();

      if (this.topicTrackingState) {
        this.topicTrackingState.sync(list, filter);
      }

      this.send("loadingComplete");
    },
    hasTopics: Ember.computed.gt("model.topics.length", 0),
    allLoaded: Ember.computed.empty("model.more_topics_url"),
    latest: (0, _computed.endWith)("model.filter", "latest"),
    new: (0, _computed.endWith)("model.filter", "new"),
    top: (0, _computed.endWith)("model.filter", "top"),
    yearly: Ember.computed.equal("period", "yearly"),
    quarterly: Ember.computed.equal("period", "quarterly"),
    monthly: Ember.computed.equal("period", "monthly"),
    weekly: Ember.computed.equal("period", "weekly"),
    daily: Ember.computed.equal("period", "daily"),
    footerMessage: function footerMessage(allLoaded, topicsLength) {
      if (!allLoaded) {
        return;
      }

      var category = this.category;

      if (category) {
        return _I18n.default.t("topics.bottom.category", {
          category: category.get("name")
        });
      } else {
        var split = (this.get("model.filter") || "").split("/");

        if (topicsLength === 0) {
          return _I18n.default.t("topics.none." + split[0], {
            category: split[1]
          });
        } else {
          return _I18n.default.t("topics.bottom." + split[0], {
            category: split[1]
          });
        }
      }
    },
    footerEducation: function footerEducation(allLoaded, topicsLength) {
      if (!allLoaded || topicsLength > 0 || !this.currentUser) {
        return;
      }

      var segments = (this.get("model.filter") || "").split("/");
      var tab = segments[segments.length - 1];

      if (tab !== "new" && tab !== "unread") {
        return;
      }

      return _I18n.default.t("topics.none.educate." + tab, {
        userPrefsUrl: (0, _url.userPath)("".concat(this.currentUser.get("username_lower"), "/preferences/notifications"))
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "loadingBegan", [_dec], Object.getOwnPropertyDescriptor(_obj, "loadingBegan"), _obj), _applyDecoratedDescriptor(_obj, "loadingComplete", [_dec2], Object.getOwnPropertyDescriptor(_obj, "loadingComplete"), _obj), _applyDecoratedDescriptor(_obj, "showDismissRead", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showDismissRead"), _obj), _applyDecoratedDescriptor(_obj, "showResetNew", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showResetNew"), _obj), _applyDecoratedDescriptor(_obj, "footerMessage", [_dec5], Object.getOwnPropertyDescriptor(_obj, "footerMessage"), _obj), _applyDecoratedDescriptor(_obj, "footerEducation", [_dec6], Object.getOwnPropertyDescriptor(_obj, "footerEducation"), _obj)), _obj));

  var _default = _discovery.default.extend(controllerOpts, _bulkTopicSelection.default);

  _exports.default = _default;
});