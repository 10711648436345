define("discourse/mixins/modal-functionality", ["exports", "discourse/lib/show-modal"], function (_exports, _showModal2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    flash: function flash(text, messageClass) {
      this.appEvents.trigger("modal-body:flash", {
        text: text,
        messageClass: messageClass
      });
    },
    clearFlash: function clearFlash() {
      this.appEvents.trigger("modal-body:clearFlash");
    },
    showModal: function showModal() {
      return _showModal2.default.apply(void 0, arguments);
    },
    actions: {
      closeModal: function closeModal() {
        this.modal.send("closeModal");
        this.set("panels", []);
      }
    }
  });

  _exports.default = _default;
});