define("discourse/routes/login", ["exports", "discourse/routes/build-static-route", "discourse/lib/utilities"], function (_exports, _buildStaticRoute, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LoginRoute = (0, _buildStaticRoute.default)("login");
  LoginRoute.reopen({
    beforeModel: function beforeModel() {
      if (!this.siteSettings.login_required) {
        this.replaceWith("/".concat((0, _utilities.defaultHomepage)())).then(function (e) {
          Ember.run.next(function () {
            return e.send("showLogin");
          });
        });
      }
    }
  });
  var _default = LoginRoute;
  _exports.default = _default;
});