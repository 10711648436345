define("discourse/widgets/quick-access-item", ["exports", "discourse/widgets/raw-html", "discourse/widgets/widget", "discourse/lib/text", "discourse/lib/utilities", "virtual-dom", "discourse-common/lib/icon-library"], function (_exports, _rawHtml, _widget, _text, _utilities, _virtualDom, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper widget tries to enforce a consistent look and behavior for any
   * item under any quick access panels.
   *
   * It accepts the following attributes:
   *   action
   *   actionParam
   *   content
   *   escapedContent
   *   href
   *   icon
   *   read
   *   username
   */
  var _default = (0, _widget.createWidget)("quick-access-item", {
    tagName: "li",
    buildClasses: function buildClasses(attrs) {
      var result = [];

      if (attrs.className) {
        result.push(attrs.className);
      }

      if (attrs.read === undefined || attrs.read) {
        result.push("read");
      }

      return result;
    },
    html: function html(_ref) {
      var href = _ref.href,
          title = _ref.title,
          icon = _ref.icon;

      var content = this._contentHtml();

      if (href) {
        var topicId = href.match(/\/t\/.*?\/(\d+)/);

        if (topicId && topicId[1]) {
          topicId = (0, _utilities.escapeExpression)(topicId[1]);
          content = "<span data-topic-id=\"".concat(topicId, "\">").concat(content, "</span>");
        }
      }

      return (0, _virtualDom.h)("a", {
        attributes: this._linkAttributes(href, title)
      }, [(0, _iconLibrary.iconNode)(icon), new _rawHtml.default({
        html: "<div>".concat(this._usernameHtml()).concat(content, "</div>")
      })]);
    },
    click: function click(e) {
      this.attrs.read = true;

      if (this.attrs.action) {
        e.preventDefault();
        return this.sendWidgetAction(this.attrs.action, this.attrs.actionParam);
      }
    },
    _linkAttributes: function _linkAttributes(href, title) {
      return {
        href: href,
        title: title
      };
    },
    _contentHtml: function _contentHtml() {
      var content = this.attrs.escapedContent || (0, _utilities.escapeExpression)(this.attrs.content);
      return (0, _text.emojiUnescape)(content);
    },
    _usernameHtml: function _usernameHtml() {
      // Generate an empty `<span>` even if there is no username, because the
      // first `<span>` is styled differently.
      return this.attrs.username ? "<span>".concat(this.attrs.username, "</span> ") : "<span></span>";
    }
  });

  _exports.default = _default;
});