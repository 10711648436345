define("discourse/widgets/poster-name", ["exports", "discourse/widgets/widget", "I18n", "discourse/lib/utilities", "discourse-common/lib/get-url", "virtual-dom", "discourse-common/lib/icon-library", "discourse/lib/settings"], function (_exports, _widget, _I18n, _utilities, _getUrl, _virtualDom, _iconLibrary, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.disableNameSuppression = disableNameSuppression;
  _exports.default = void 0;

  var sanitizeName = function sanitizeName(name) {
    return name.toLowerCase().replace(/[\s\._-]/g, "");
  };

  function disableNameSuppression() {
    sanitizeName = function sanitizeName(name) {
      return name;
    };
  }

  (0, _widget.createWidget)("poster-name-title", {
    tagName: "span.user-title",
    html: function html(attrs) {
      var titleContents = attrs.title;

      if (attrs.primaryGroupName && attrs.titleIsGroup) {
        var href = (0, _getUrl.default)("/g/".concat(attrs.primaryGroupName));
        titleContents = (0, _virtualDom.h)("a.user-group", {
          className: attrs.extraClasses,
          attributes: {
            href: href,
            "data-group-card": attrs.primaryGroupName
          }
        }, attrs.title);
      }

      return titleContents;
    }
  });

  var _default = (0, _widget.createWidget)("poster-name", {
    tagName: "div.names.trigger-user-card",
    settings: {
      showNameAndGroup: true,
      showGlyph: true
    },
    // TODO: Allow extensibility
    posterGlyph: function posterGlyph(attrs) {
      if (attrs.moderator || attrs.groupModerator) {
        return (0, _iconLibrary.iconNode)("shield-alt", {
          title: _I18n.default.t("user.moderator_tooltip")
        });
      }
    },
    userLink: function userLink(attrs, text) {
      return (0, _virtualDom.h)("a", {
        attributes: {
          href: attrs.usernameUrl,
          "data-user-card": attrs.username
        }
      }, (0, _utilities.formatUsername)(text));
    },
    html: function html(attrs) {
      var username = attrs.username;
      var name = attrs.name;
      var nameFirst = this.siteSettings.display_name_on_posts && (0, _settings.prioritizeNameInUx)(name);
      var classNames = nameFirst ? ["first", "full-name"] : ["first", "username"];

      if (attrs.staff) {
        classNames.push("staff");
      }

      if (attrs.admin) {
        classNames.push("admin");
      }

      if (attrs.moderator) {
        classNames.push("moderator");
      }

      if (attrs.groupModerator) {
        classNames.push("category-moderator");
      }

      if (attrs.new_user) {
        classNames.push("new-user");
      }

      var afterNameContents = (0, _widget.applyDecorators)(this, "after-name", attrs, this.state) || [];
      var primaryGroupName = attrs.primary_group_name;

      if (primaryGroupName && primaryGroupName.length) {
        classNames.push(primaryGroupName);
      }

      var nameContents = [this.userLink(attrs, nameFirst ? name : username)];

      if (this.settings.showGlyph) {
        var glyph = this.posterGlyph(attrs);

        if (glyph) {
          nameContents.push(glyph);
        }
      }

      nameContents = nameContents.concat(afterNameContents);
      var contents = [(0, _virtualDom.h)("span", {
        className: classNames.join(" ")
      }, nameContents)];

      if (!this.settings.showNameAndGroup) {
        return contents;
      }

      if (name && this.siteSettings.display_name_on_posts && sanitizeName(name) !== sanitizeName(username)) {
        contents.push((0, _virtualDom.h)("span.second." + (nameFirst ? "username" : "full-name"), [this.userLink(attrs, nameFirst ? username : name)].concat(afterNameContents)));
      }

      var title = attrs.user_title,
          titleIsGroup = attrs.title_is_group;

      if (title && title.length) {
        contents.push(this.attach("poster-name-title", {
          title: title,
          primaryGroupName: primaryGroupName,
          titleIsGroup: titleIsGroup
        }));
      }

      return contents;
    }
  });

  _exports.default = _default;
});