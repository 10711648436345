define("discourse/lib/lazy-load-images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nativeLazyLoading = nativeLazyLoading;
  // Min size in pixels for consideration for lazy loading
  var MINIMUM_SIZE = 150;

  function forEachImage(post, callback) {
    post.querySelectorAll("img").forEach(function (img) {
      if (img.width >= MINIMUM_SIZE && img.height >= MINIMUM_SIZE) {
        callback(img);
      }
    });
  }

  function nativeLazyLoading(api) {
    api.decorateCookedElement(function (post) {
      return forEachImage(post, function (img) {
        img.loading = "lazy";

        if (img.dataset.smallUpload) {
          if (!img.complete) {
            if (!img.onload) {
              img.onload = function () {
                img.removeAttribute("style");
              };
            }

            img.style = "background-image: url(".concat(img.dataset.smallUpload, "); background-size: cover;");
          }
        }
      });
    }, {
      onlyStream: true,
      id: "discourse-lazy-load-after-adopt",
      afterAdopt: true
    });
  }
});