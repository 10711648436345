define("discourse/components/add-category-tag-classes", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.observes)("category.fullSlug", "tags"), (_obj = {
    _slug: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.refreshClass();
    },
    _updateClass: function _updateClass() {
      var _this$tags, _document$body$classL;

      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      var slug = this.get("category.fullSlug");

      this._removeClass();

      var classes = [];

      if (slug) {
        classes.push("category");
        classes.push("category-".concat(slug));
      }

      (_this$tags = this.tags) === null || _this$tags === void 0 ? void 0 : _this$tags.forEach(function (t) {
        return classes.push("tag-".concat(t));
      });

      (_document$body$classL = document.body.classList).add.apply(_document$body$classL, classes);
    },
    refreshClass: function refreshClass() {
      Ember.run.scheduleOnce("afterRender", this, this._updateClass);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._removeClass();
    },
    _removeClass: function _removeClass() {
      var invalidClasses = [];
      var regex = /\b(?:category|tag)-\S+|( category )/g;
      document.body.classList.forEach(function (name) {
        if (regex.test(name)) {
          invalidClasses.push(name);
        }
      });

      if (invalidClasses.length) {
        var _document$body$classL2;

        (_document$body$classL2 = document.body.classList).remove.apply(_document$body$classL2, invalidClasses);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "refreshClass", [_dec], Object.getOwnPropertyDescriptor(_obj, "refreshClass"), _obj)), _obj)));

  _exports.default = _default;
});