define("discourse/templates/components/score-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Ma8wXHI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"score-value\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"score-number\"],[8],[1,[28,\"float\",[[24,[\"value\"]]],null],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[11,\"title\",[28,\"i18n\",[[28,\"concat\",[\"review.explain.\",[24,[\"label\"]],\".title\"],null]],null]],[10,\"class\",\"score-value-type\"],[8],[0,\"\\n        \"],[1,[28,\"i18n\",[[28,\"concat\",[\"review.explain.\",[24,[\"label\"]],\".name\"],null]],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"op\"],[8],[0,\"+\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/score-value.hbs"
    }
  });

  _exports.default = _default;
});