define("discourse/templates/modal/do-not-disturb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wAZEhFbX",
    "block": "{\"symbols\":[\"grid\"],\"statements\":[[4,\"d-modal-body\",null,[[\"title\"],[\"do_not_disturb.title\"]],{\"statements\":[[4,\"tap-tile-grid\",null,[[\"activeTile\"],[[24,[\"duration\"]]]],{\"statements\":[[4,\"tap-tile\",null,[[\"class\",\"tileId\",\"activeTile\",\"onChange\"],[\"do-not-disturb-tile\",\"30\",[23,1,[\"activeTile\"]],[28,\"action\",[[23,0,[]],\"setDuration\"],null]]],{\"statements\":[[0,\"      \"],[1,[28,\"i18n\",[\"do_not_disturb.options.half_hour\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"tap-tile\",null,[[\"class\",\"tileId\",\"activeTile\",\"onChange\"],[\"do-not-disturb-tile\",\"60\",[23,1,[\"activeTile\"]],[28,\"action\",[[23,0,[]],\"setDuration\"],null]]],{\"statements\":[[0,\"      \"],[1,[28,\"i18n\",[\"do_not_disturb.options.one_hour\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"tap-tile\",null,[[\"class\",\"tileId\",\"activeTile\",\"onChange\"],[\"do-not-disturb-tile\",\"120\",[23,1,[\"activeTile\"]],[28,\"action\",[[23,0,[]],\"setDuration\"],null]]],{\"statements\":[[0,\"      \"],[1,[28,\"i18n\",[\"do_not_disturb.options.two_hours\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"tap-tile\",null,[[\"class\",\"tileId\",\"activeTile\",\"onChange\"],[\"do-not-disturb-tile\",\"tomorrow\",[23,1,[\"activeTile\"]],[28,\"action\",[[23,0,[]],\"setDuration\"],null]]],{\"statements\":[[0,\"      \"],[1,[28,\"i18n\",[\"do_not_disturb.options.tomorrow\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n  \"],[7,\"a\",false],[12,\"href\",\"\"],[3,\"action\",[[23,0,[]],[24,[\"navigateToNotificationSchedule\"]]]],[8],[0,\"\\n    \"],[1,[28,\"i18n\",[\"do_not_disturb.set_schedule\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/do-not-disturb.hbs"
    }
  });

  _exports.default = _default;
});