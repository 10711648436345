define("discourse/initializers/sticky-avatars", ["exports", "discourse/lib/sticky-avatars"], function (_exports, _stickyAvatars) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "sticky-avatars",
    after: "inject-objects",
    initialize: function initialize(container) {
      this._stickyAvatars = _stickyAvatars.default.init(container);
    },
    teardown: function teardown() {
      var _this$_stickyAvatars;

      (_this$_stickyAvatars = this._stickyAvatars) === null || _this$_stickyAvatars === void 0 ? void 0 : _this$_stickyAvatars.destroy();
    }
  };
  _exports.default = _default;
});