define("discourse/controllers/tag-groups-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tagGroups: Ember.inject.controller(),
    actions: {
      onSave: function onSave() {
        var tagGroups = this.tagGroups.model;
        tagGroups.pushObject(this.model);
        this.transitionToRoute("tagGroups.index");
      }
    }
  });

  _exports.default = _default;
});