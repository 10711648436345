define("discourse/components/composer-messages", ["exports", "I18n", "discourse/lib/link-lookup", "discourse/lib/show-modal"], function (_exports, _I18n, _linkLookup, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _messagesCache = {};

  var _default = Ember.Component.extend({
    classNameBindings: [":composer-popup-container", "hidden"],
    checkedMessages: false,
    messages: null,
    messagesByTemplate: null,
    queuedForTyping: null,
    _lastSimilaritySearch: null,
    _similarTopicsMessage: null,
    _yourselfConfirm: null,
    similarTopics: null,
    hidden: Ember.computed.not("composer.viewOpenOrFullscreen"),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.appEvents.on("composer:typed-reply", this, this._typedReply);
      this.appEvents.on("composer:opened", this, this._findMessages);
      this.appEvents.on("composer:find-similar", this, this._findSimilar);
      this.appEvents.on("composer-messages:close", this, this._closeTop);
      this.appEvents.on("composer-messages:create", this, this._create);
      Ember.run.scheduleOnce("afterRender", this, this.reset);
    },
    willDestroyElement: function willDestroyElement() {
      this.appEvents.off("composer:typed-reply", this, this._typedReply);
      this.appEvents.off("composer:opened", this, this._findMessages);
      this.appEvents.off("composer:find-similar", this, this._findSimilar);
      this.appEvents.off("composer-messages:close", this, this._closeTop);
      this.appEvents.off("composer-messages:create", this, this._create);
    },
    _closeTop: function _closeTop() {
      var messages = this.messages;
      messages.popObject();
      this.set("messageCount", messages.get("length"));
    },
    _removeMessage: function _removeMessage(message) {
      var messages = this.messages;
      messages.removeObject(message);
      this.set("messageCount", messages.get("length"));
    },
    actions: {
      closeMessage: function closeMessage(message) {
        this._removeMessage(message);
      },
      hideMessage: function hideMessage(message) {
        this._removeMessage(message); // kind of hacky but the visibility depends on this


        this.messagesByTemplate[message.get("templateName")] = undefined;
      },
      popup: function popup(message) {
        var messagesByTemplate = this.messagesByTemplate;
        var templateName = message.get("templateName");

        if (!messagesByTemplate[templateName]) {
          var messages = this.messages;
          messages.pushObject(message);
          this.set("messageCount", messages.get("length"));
          messagesByTemplate[templateName] = message;
        }
      },
      shareModal: function shareModal() {
        var topic = this.composer.topic;
        var controller = (0, _showModal.default)("share-topic", {
          model: topic.category
        });
        controller.setProperties({
          allowInvites: topic.details.can_invite_to && !topic.archived && !topic.closed && !topic.deleted,
          topic: topic
        });
      },
      switchPM: function switchPM(message) {
        this.composer.set("action", "privateMessage");
        this.composer.set("targetRecipients", message.reply_username);

        this._removeMessage(message);
      }
    },
    // Resets all active messages.
    // For example if composing a new post.
    reset: function reset() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      this.setProperties({
        messages: [],
        messagesByTemplate: {},
        queuedForTyping: [],
        checkedMessages: false,
        similarTopics: []
      });
    },
    // Called after the user has typed a reply.
    // Some messages only get shown after being typed.
    _typedReply: function _typedReply() {
      var _this = this;

      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      var composer = this.composer;

      if (composer.get("privateMessage")) {
        var recipients = composer.targetRecipientsArray;

        if (recipients.length > 0 && recipients.every(function (r) {
          return r.name === _this.currentUser.get("username");
        })) {
          var message = this._yourselfConfirm || composer.store.createRecord("composer-message", {
            id: "yourself_confirm",
            templateName: "custom-body",
            title: _I18n.default.t("composer.yourself_confirm.title"),
            body: _I18n.default.t("composer.yourself_confirm.body")
          });
          this.send("popup", message);
        }
      }

      this.queuedForTyping.forEach(function (msg) {
        if (composer.whisper && msg.hide_if_whisper) {
          return;
        }

        _this.send("popup", msg);
      });
    },
    _create: function _create(info) {
      this.reset();
      this.send("popup", Ember.Object.create(info));
    },
    _findSimilar: function _findSimilar() {
      var _this2 = this;

      var composer = this.composer; // We don't care about similar topics unless creating a topic

      if (!composer.get("creatingTopic")) {
        return;
      } // TODO pass the 200 in from somewhere


      var raw = (composer.get("reply") || "").substr(0, 200);
      var title = composer.get("title") || ""; // Ensure we have at least a title

      if (title.length < this.siteSettings.min_title_similar_length) {
        return;
      } // Don't search over and over


      var concat = title + raw;

      if (concat === this._lastSimilaritySearch) {
        return;
      }

      this._lastSimilaritySearch = concat;
      var similarTopics = this.similarTopics;
      var message = this._similarTopicsMessage || composer.store.createRecord("composer-message", {
        id: "similar_topics",
        templateName: "similar-topics",
        extraClass: "similar-topics"
      });
      this._similarTopicsMessage = message;
      composer.store.find("similar-topic", {
        title: title,
        raw: raw
      }).then(function (topics) {
        similarTopics.clear();
        similarTopics.pushObjects(topics.get("content"));

        if (similarTopics.get("length") > 0) {
          message.set("similarTopics", similarTopics);

          _this2.send("popup", message);
        } else if (message && !(_this2.isDestroyed || _this2.isDestroying)) {
          _this2.send("hideMessage", message);
        }
      });
    },
    // Figure out if there are any messages that should be displayed above the composer.
    _findMessages: function _findMessages() {
      var _this3 = this;

      if (this.checkedMessages) {
        return;
      }

      var composer = this.composer;
      var args = {
        composer_action: composer.get("action")
      };
      var topicId = composer.get("topic.id");
      var postId = composer.get("post.id");

      if (topicId) {
        args.topic_id = topicId;
      }

      if (postId) {
        args.post_id = postId;
      }

      var cacheKey = "".concat(args.composer_action).concat(args.topic_id).concat(args.post_id);

      var processMessages = function processMessages(messages) {
        if (_this3.isDestroying || _this3.isDestroyed) {
          return;
        } // Checking composer messages on replies can give us a list of links to check for
        // duplicates


        if (messages.extras && messages.extras.duplicate_lookup) {
          _this3.addLinkLookup(new _linkLookup.default(messages.extras.duplicate_lookup));
        }

        _this3.set("checkedMessages", true);

        var queuedForTyping = _this3.queuedForTyping;
        messages.forEach(function (msg) {
          return msg.wait_for_typing ? queuedForTyping.addObject(msg) : _this3.send("popup", msg);
        });
      };

      if (_messagesCache.cacheKey === cacheKey) {
        processMessages(_messagesCache.messages);
      } else {
        composer.store.find("composer-message", args).then(function (messages) {
          _messagesCache = {
            messages: messages,
            cacheKey: cacheKey
          };
          processMessages(messages);
        });
      }
    }
  });

  _exports.default = _default;
});