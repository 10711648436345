define("discourse/routes/user-topic-list", ["exports", "discourse/routes/discourse", "discourse/mixins/viewing-action-type"], function (_exports, _discourse, _viewingActionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend(_viewingActionType.default, {
    renderTemplate: function renderTemplate() {
      this.render("user-topics-list");
    },
    setupController: function setupController(controller, model) {
      var userActionType = this.userActionType;
      this.controllerFor("user").set("userActionType", userActionType);
      this.controllerFor("user-activity").set("userActionType", userActionType);
      this.controllerFor("user-topics-list").setProperties({
        model: model,
        hideCategory: false
      });
    }
  });

  _exports.default = _default;
});