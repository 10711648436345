define("discourse/templates/components/share-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fAChlTfj",
    "block": "{\"symbols\":[\"source\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"title\"],[8],[1,[28,\"html-safe\",[[24,[\"shareTitle\"]]],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"body\"],[8],[0,\"\\n  \"],[1,[28,\"d-textarea\",null,[[\"value\",\"class\",\"aria-label\"],[[24,[\"shareUrl\"]],\"topic-share-url\",[28,\"I18n\",[\"share.url\"],null]]]],false],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"sources\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"sources\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"share-source\",null,[[\"source\",\"title\",\"action\"],[[23,1,[]],[24,[\"topic\",\"title\"]],[28,\"action\",[[23,0,[]],\"share\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/share-panel.hbs"
    }
  });

  _exports.default = _default;
});