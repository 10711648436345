define("discourse/components/popup-input-tip", ["exports", "discourse-common/utils/decorators", "discourse-common/lib/get-owner"], function (_exports, _decorators, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("bad"), _dec2 = (0, _decorators.default)("validation.reason"), (_obj = {
    classNameBindings: [":popup-tip", "good", "bad", "lastShownAt::hide"],
    attributeBindings: ["role", "ariaLabel"],
    rerenderTriggers: ["validation.reason"],
    tipReason: null,
    lastShownAt: Ember.computed.or("shownAt", "validation.lastShownAt"),
    bad: Ember.computed.reads("validation.failed"),
    good: Ember.computed.not("bad"),
    role: function role(bad) {
      if (bad) {
        return "alert";
      }
    },
    ariaLabel: function ariaLabel(reason) {
      return reason === null || reason === void 0 ? void 0 : reason.replace(/(<([^>]+)>)/gi, "");
    },
    click: function click() {
      this.set("shownAt", null);
      var composer = (0, _getOwner.getOwner)(this).lookup("controller:composer");
      composer.clearLastValidatedAt();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var reason = this.get("validation.reason");

      if (reason) {
        this.set("tipReason", "".concat(reason).htmlSafe());
      } else {
        this.set("tipReason", null);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "role", [_dec], Object.getOwnPropertyDescriptor(_obj, "role"), _obj), _applyDecoratedDescriptor(_obj, "ariaLabel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "ariaLabel"), _obj)), _obj)));

  _exports.default = _default;
});