define("discourse/controllers/groups-new", ["exports", "I18n", "discourse/lib/ajax", "bootbox", "discourse/lib/ajax-error", "discourse-common/utils/decorators"], function (_exports, _I18n, _ajax, _bootbox, _ajaxError, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.popupAutomaticMembershipAlert = popupAutomaticMembershipAlert;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function popupAutomaticMembershipAlert(group_id, email_domains) {
    if (!email_domains) {
      return;
    }

    var data = {};
    data.automatic_membership_email_domains = email_domains;

    if (group_id) {
      data.id = group_id;
    }

    (0, _ajax.ajax)("/admin/groups/automatic_membership_count.json", {
      type: "PUT",
      data: data
    }).then(function (result) {
      var count = result.user_count;

      if (count > 0) {
        _bootbox.default.alert(_I18n.default.t("admin.groups.manage.membership.automatic_membership_user_count", {
          count: count
        }));
      }
    });
  }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("model.ownerUsernames"), _dec2 = (0, _decorators.default)("model.usernames"), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_obj = {
    saving: null,
    splitOwnerUsernames: function splitOwnerUsernames(owners) {
      return owners && owners.length ? owners.split(",") : [];
    },
    splitUsernames: function splitUsernames(usernames) {
      return usernames && usernames.length ? usernames.split(",") : [];
    },
    save: function save() {
      var _this = this;

      this.set("saving", true);
      var group = this.model;
      popupAutomaticMembershipAlert(group.id, group.automatic_membership_email_domains);
      group.create().then(function () {
        _this.transitionToRoute("group.members", group.name);
      }).catch(_ajaxError.popupAjaxError).finally(function () {
        return _this.set("saving", false);
      });
    },
    updateOwnerUsernames: function updateOwnerUsernames(selected) {
      this.set("model.ownerUsernames", selected.join(","));
    },
    updateUsernames: function updateUsernames(selected) {
      this.set("model.usernames", selected.join(","));
    }
  }, (_applyDecoratedDescriptor(_obj, "splitOwnerUsernames", [_dec], Object.getOwnPropertyDescriptor(_obj, "splitOwnerUsernames"), _obj), _applyDecoratedDescriptor(_obj, "splitUsernames", [_dec2], Object.getOwnPropertyDescriptor(_obj, "splitUsernames"), _obj), _applyDecoratedDescriptor(_obj, "save", [_dec3], Object.getOwnPropertyDescriptor(_obj, "save"), _obj), _applyDecoratedDescriptor(_obj, "updateOwnerUsernames", [_dec4], Object.getOwnPropertyDescriptor(_obj, "updateOwnerUsernames"), _obj), _applyDecoratedDescriptor(_obj, "updateUsernames", [_dec5], Object.getOwnPropertyDescriptor(_obj, "updateUsernames"), _obj)), _obj)));

  _exports.default = _default;
});