define("discourse/templates/account-created/resent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7WY7Ia3n",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ac-message\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"email\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"html-safe\",[[28,\"i18n\",[\"login.sent_activation_email_again\"],[[\"currentEmail\"],[[24,[\"email\"]]]]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"i18n\",[\"login.sent_activation_email_again_generic\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/account-created/resent.hbs"
    }
  });

  _exports.default = _default;
});