define("discourse/lib/theme-selector", ["exports", "discourse/lib/cookie", "I18n", "discourse-common/lib/deprecated"], function (_exports, _cookie, _I18n, _deprecated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currentThemeKey = currentThemeKey;
  _exports.currentThemeIds = currentThemeIds;
  _exports.currentThemeId = currentThemeId;
  _exports.setLocalTheme = setLocalTheme;
  _exports.listThemes = listThemes;
  var keySelector = "meta[name=discourse_theme_id]";

  function currentThemeKey() {
    // eslint-disable-next-line no-console
    if (console && console.warn && console.trace) {
      // TODO: Remove this code Jan 2019
      (0, _deprecated.default)("'currentThemeKey' is is deprecated use 'currentThemeId' instead. A theme component may require updating.");
    }
  }

  function currentThemeIds() {
    var themeIds = [];
    var elem = document.querySelector(keySelector);

    if (elem) {
      elem.content.split(",").forEach(function (num) {
        num = parseInt(num, 10);

        if (!isNaN(num)) {
          themeIds.push(num);
        }
      });
    }

    return themeIds;
  }

  function currentThemeId() {
    return currentThemeIds()[0];
  }

  function setLocalTheme(ids, themeSeq) {
    ids = ids.reject(function (id) {
      return !id;
    });

    if (ids && ids.length > 0) {
      (0, _cookie.default)("theme_ids", "".concat(ids.join(","), "|").concat(themeSeq), {
        path: "/",
        expires: 9999
      });
    } else {
      (0, _cookie.removeCookie)("theme_ids", {
        path: "/",
        expires: 1
      });
    }
  }

  function listThemes(site) {
    var themes = site.get("user_themes");

    if (!themes) {
      return null;
    }

    var hasDefault = !!themes.findBy("default", true);
    var results = [];

    if (!hasDefault) {
      results.push({
        name: _I18n.default.t("themes.default_description"),
        id: null
      });
    }

    themes.forEach(function (t) {
      results.push({
        name: t.name,
        id: t.theme_id,
        color_scheme_id: t.color_scheme_id
      });
    });
    return results.length === 0 ? null : results;
  }
});