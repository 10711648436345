define("discourse/templates/bulk-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QZbVwYQL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[1,[28,\"i18n\",[[28,\"concat\",[\"topics.bulk.\",[24,[\"title\"]]],null]],null],false],[9],[0,\"\\n\\n\"],[7,\"p\",true],[8],[1,[28,\"tag-chooser\",null,[[\"tags\",\"categoryId\"],[[24,[\"tags\"]],[24,[\"categoryId\"]]]]],false],[9],[0,\"\\n\\n\"],[1,[28,\"d-button\",null,[[\"action\",\"disabled\",\"label\"],[[24,[\"action\"]],[24,[\"emptyTags\"]],[28,\"concat\",[\"topics.bulk.\",[24,[\"label\"]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/bulk-tag.hbs"
    }
  });

  _exports.default = _default;
});