define("discourse/templates/components/disabled-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ITPFRGMk",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"d-icon\",[[24,[\"icon\"]]],[[\"class\"],[\"fa-stack-1x\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"disabled\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"d-icon\",[\"ban\"],[[\"class\"],[\"fa-stack-2x\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/disabled-icon.hbs"
    }
  });

  _exports.default = _default;
});