define("discourse/components/group-membership-button", ["exports", "I18n", "bootbox", "discourse/lib/cookie", "discourse-common/utils/decorators", "discourse/lib/ajax-error", "discourse/lib/show-modal"], function (_exports, _I18n, _bootbox, _cookie, _decorators, _ajaxError, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("model.public_admission", "userIsGroupUser"), _dec2 = (0, _decorators.default)("model.public_exit", "userIsGroupUser"), _dec3 = (0, _decorators.default)("model.allow_membership_requests", "userIsGroupUser"), _dec4 = (0, _decorators.default)("model.is_group_user"), (_obj = {
    classNames: ["group-membership-button"],
    canJoinGroup: function canJoinGroup(publicAdmission, userIsGroupUser) {
      return publicAdmission && !userIsGroupUser;
    },
    canLeaveGroup: function canLeaveGroup(publicExit, userIsGroupUser) {
      return publicExit && userIsGroupUser;
    },
    canRequestMembership: function canRequestMembership(allowMembershipRequests, userIsGroupUser) {
      return allowMembershipRequests && !userIsGroupUser;
    },
    userIsGroupUser: function userIsGroupUser(isGroupUser) {
      return !!isGroupUser;
    },
    _showLoginModal: function _showLoginModal() {
      this.showLogin();
      (0, _cookie.default)("destination_url", window.location.href);
    },
    removeFromGroup: function removeFromGroup() {
      var _this = this;

      var model = this.model;
      model.leave().then(function () {
        model.set("is_group_user", false);

        _this.appEvents.trigger("group:leave", model);
      }).catch(_ajaxError.popupAjaxError).finally(function () {
        return _this.set("updatingMembership", false);
      });
    },
    actions: {
      joinGroup: function joinGroup() {
        var _this2 = this;

        if (this.currentUser) {
          this.set("updatingMembership", true);
          var group = this.model;
          group.join().then(function () {
            group.set("is_group_user", true);

            _this2.appEvents.trigger("group:join", group);
          }).catch(_ajaxError.popupAjaxError).finally(function () {
            _this2.set("updatingMembership", false);
          });
        } else {
          this._showLoginModal();
        }
      },
      leaveGroup: function leaveGroup() {
        var _this3 = this;

        this.set("updatingMembership", true);

        if (this.model.public_admission) {
          this.removeFromGroup();
        } else {
          return _bootbox.default.confirm(_I18n.default.t("groups.confirm_leave"), _I18n.default.t("no_value"), _I18n.default.t("yes_value"), function (result) {
            result ? _this3.removeFromGroup() : _this3.set("updatingMembership", false);
          });
        }
      },
      showRequestMembershipForm: function showRequestMembershipForm() {
        if (this.currentUser) {
          (0, _showModal.default)("request-group-membership-form", {
            model: this.model
          });
        } else {
          this._showLoginModal();
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "canJoinGroup", [_dec], Object.getOwnPropertyDescriptor(_obj, "canJoinGroup"), _obj), _applyDecoratedDescriptor(_obj, "canLeaveGroup", [_dec2], Object.getOwnPropertyDescriptor(_obj, "canLeaveGroup"), _obj), _applyDecoratedDescriptor(_obj, "canRequestMembership", [_dec3], Object.getOwnPropertyDescriptor(_obj, "canRequestMembership"), _obj), _applyDecoratedDescriptor(_obj, "userIsGroupUser", [_dec4], Object.getOwnPropertyDescriptor(_obj, "userIsGroupUser"), _obj)), _obj)));

  _exports.default = _default;
});