define("discourse/controllers/preferences/notifications", ["exports", "I18n", "discourse/lib/notification-levels", "discourse/lib/ajax-error"], function (_exports, _I18n, _notificationLevels, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.saveAttrNames = ["muted_usernames", "new_topic_duration_minutes", "auto_track_topics_after_msecs", "notification_level_when_replying", "like_notification_frequency", "allow_private_messages", "enable_allowed_pm_users", "user_notification_schedule"];
      this.likeNotificationFrequencies = [{
        name: _I18n.default.t("user.like_notification_frequency.always"),
        value: 0
      }, {
        name: _I18n.default.t("user.like_notification_frequency.first_time_and_daily"),
        value: 1
      }, {
        name: _I18n.default.t("user.like_notification_frequency.first_time"),
        value: 2
      }, {
        name: _I18n.default.t("user.like_notification_frequency.never"),
        value: 3
      }];
      this.autoTrackDurations = [{
        name: _I18n.default.t("user.auto_track_options.never"),
        value: -1
      }, {
        name: _I18n.default.t("user.auto_track_options.immediately"),
        value: 0
      }, {
        name: _I18n.default.t("user.auto_track_options.after_30_seconds"),
        value: 30000
      }, {
        name: _I18n.default.t("user.auto_track_options.after_1_minute"),
        value: 60000
      }, {
        name: _I18n.default.t("user.auto_track_options.after_2_minutes"),
        value: 120000
      }, {
        name: _I18n.default.t("user.auto_track_options.after_3_minutes"),
        value: 180000
      }, {
        name: _I18n.default.t("user.auto_track_options.after_4_minutes"),
        value: 240000
      }, {
        name: _I18n.default.t("user.auto_track_options.after_5_minutes"),
        value: 300000
      }, {
        name: _I18n.default.t("user.auto_track_options.after_10_minutes"),
        value: 600000
      }];
      this.notificationLevelsForReplying = [{
        name: _I18n.default.t("topic.notifications.watching.title"),
        value: _notificationLevels.NotificationLevels.WATCHING
      }, {
        name: _I18n.default.t("topic.notifications.tracking.title"),
        value: _notificationLevels.NotificationLevels.TRACKING
      }, {
        name: _I18n.default.t("topic.notifications.regular.title"),
        value: _notificationLevels.NotificationLevels.REGULAR
      }];
      this.considerNewTopicOptions = [{
        name: _I18n.default.t("user.new_topic_duration.not_viewed"),
        value: -1
      }, {
        name: _I18n.default.t("user.new_topic_duration.after_1_day"),
        value: 60 * 24
      }, {
        name: _I18n.default.t("user.new_topic_duration.after_2_days"),
        value: 60 * 48
      }, {
        name: _I18n.default.t("user.new_topic_duration.after_1_week"),
        value: 7 * 60 * 24
      }, {
        name: _I18n.default.t("user.new_topic_duration.after_2_weeks"),
        value: 2 * 7 * 60 * 24
      }, {
        name: _I18n.default.t("user.new_topic_duration.last_here"),
        value: -2
      }];
    },
    actions: {
      save: function save() {
        var _this = this;

        this.set("saved", false);
        return this.model.save(this.saveAttrNames).then(function () {
          _this.set("saved", true);
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });

  _exports.default = _default;
});