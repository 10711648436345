define("discourse/routes/account-created", ["exports", "discourse/lib/preload-store"], function (_exports, _preloadStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set("accountCreated", _preloadStore.default.get("accountCreated"));
    }
  });

  _exports.default = _default;
});