define("discourse/lib/put-cursor-at-end", ["exports", "discourse/lib/utilities", "discourse/lib/safari-hacks"], function (_exports, _utilities, _safariHacks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(element) {
    if ((0, _utilities.isAppleDevice)() && _safariHacks.default.touchstartEvent) {
      _safariHacks.default.touchstartEvent(element);
    } else {
      element.focus();
    }

    var len = element.value.length;
    element.setSelectionRange(len, len); // Scroll to the bottom, in case we're in a tall textarea

    element.scrollTop = 999999;
  }
});