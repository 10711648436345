define("discourse/routes/tags-index", ["exports", "discourse/routes/discourse", "I18n", "discourse/models/tag"], function (_exports, _discourse, _I18n, _tag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _discourse.default.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    model: function model() {
      return this.store.findAll("tag").then(function (result) {
        if (result.extras) {
          if (result.extras.categories) {
            result.extras.categories.forEach(function (category) {
              category.tags = category.tags.map(function (t) {
                return _tag.default.create(t);
              });
            });
          }

          if (result.extras.tag_groups) {
            result.extras.tag_groups.forEach(function (tagGroup) {
              tagGroup.tags = tagGroup.tags.map(function (t) {
                return _tag.default.create(t);
              });
            });
          }
        }

        return result;
      });
    },
    titleToken: function titleToken() {
      return _I18n.default.t("tagging.tags");
    },
    setupController: function setupController(controller, model) {
      this.controllerFor("tags.index").setProperties({
        model: model,
        sortProperties: this.siteSettings.tags_sort_alphabetically ? ["id"] : ["totalCount:desc", "id"]
      });
    },
    didTransition: function didTransition() {
      this.controllerFor("application").set("showFooter", true);
      return true;
    },
    showTagGroups: function showTagGroups() {
      this.transitionTo("tagGroups");
      return true;
    },
    triggerRefresh: function triggerRefresh() {
      this.refresh();
    }
  }, (_applyDecoratedDescriptor(_obj, "didTransition", [_dec], Object.getOwnPropertyDescriptor(_obj, "didTransition"), _obj), _applyDecoratedDescriptor(_obj, "showTagGroups", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showTagGroups"), _obj), _applyDecoratedDescriptor(_obj, "triggerRefresh", [_dec3], Object.getOwnPropertyDescriptor(_obj, "triggerRefresh"), _obj)), _obj)));

  _exports.default = _default;
});