define("discourse/templates/components/second-factor-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+B2jy975",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"id\",\"second-factor\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[8],[1,[22,\"secondFactorTitle\"],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"optionalText\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[1,[28,\"html-safe\",[[24,[\"optionalText\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"p\",true],[8],[1,[22,\"secondFactorDescription\"],false],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[4,\"if\",[[24,[\"showToggleMethodLink\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[0,\"\\n      \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"toggle-second-factor-method\"],[3,\"action\",[[23,0,[]],\"toggleSecondFactorMethod\"]],[8],[1,[28,\"i18n\",[[24,[\"linkText\"]]],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/second-factor-form.hbs"
    }
  });

  _exports.default = _default;
});