define("discourse/lib/static-route-builder", ["exports", "discourse/lib/url", "discourse/routes/discourse", "I18n", "discourse/models/static-page"], function (_exports, _url, _discourse, _I18n, _staticPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var configs = {
    faq: "faq_url",
    tos: "tos_url",
    privacy: "privacy_policy_url"
  };

  function _default(page) {
    return _discourse.default.extend({
      renderTemplate: function renderTemplate() {
        this.render("static");
      },
      beforeModel: function beforeModel(transition) {
        var configKey = configs[page];

        if (configKey && this.siteSettings[configKey].length > 0) {
          transition.abort();

          _url.default.redirectTo(this.siteSettings[configKey]);
        }
      },
      activate: function activate() {
        this._super.apply(this, arguments);

        (0, _url.jumpToElement)(document.location.hash.substr(1));
      },
      model: function model() {
        return _staticPage.default.find(page);
      },
      setupController: function setupController(controller, model) {
        this.controllerFor("static").set("model", model);
      },
      titleToken: function titleToken() {
        return _I18n.default.t(page);
      },
      actions: {
        didTransition: function didTransition() {
          this.controllerFor("application").set("showFooter", true);
          return true;
        }
      }
    });
  }
});