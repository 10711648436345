define("discourse/components/topic-navigation", ["exports", "discourse/mixins/pan-events", "discourse-common/lib/debounce", "discourse/lib/offset-calculator", "discourse-common/utils/decorators", "discourse/lib/show-modal"], function (_exports, _panEvents, _debounce, _offsetCalculator, _decorators, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var MIN_WIDTH_TIMELINE = 924,
      MIN_HEIGHT_TIMELINE = 325;

  var _default = Ember.Component.extend(_panEvents.default, (_dec = (0, _decorators.observes)("info.topicProgressExpanded"), (_obj = {
    classNameBindings: ["info.topicProgressExpanded:topic-progress-expanded", "info.renderTimeline:with-timeline:with-topic-progress"],
    composerOpen: null,
    info: null,
    isPanning: false,
    canRender: true,
    _lastTopicId: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set("info", Ember.Object.create());
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this._lastTopicId !== this.topic.id) {
        this._lastTopicId = this.topic.id;
        this.set("canRender", false);
        Ember.run.next(function () {
          return _this.set("canRender", true);
        });
      }
    },
    _performCheckSize: function _performCheckSize() {
      if (!this.element || this.isDestroying || this.isDestroyed) {
        return;
      }

      var info = this.info;

      if (info.get("topicProgressExpanded")) {
        info.set("renderTimeline", true);
      } else {
        var renderTimeline = !this.site.mobileView;

        if (renderTimeline) {
          var composer = document.getElementById("reply-control");

          if (composer) {
            renderTimeline = window.innerWidth > MIN_WIDTH_TIMELINE && window.innerHeight - composer.offsetHeight - (0, _offsetCalculator.headerOffset)() > MIN_HEIGHT_TIMELINE;
          }
        }

        info.set("renderTimeline", renderTimeline);
      }
    },
    _checkSize: function _checkSize() {
      (0, _debounce.default)(this, this._performCheckSize, 300, true);
    },
    // we need to store this so topic progress has something to init with
    _topicScrolled: function _topicScrolled(event) {
      this.set("info.prevEvent", event);
    },
    _expanded: function _expanded() {
      var _this2 = this;

      if (this.get("info.topicProgressExpanded")) {
        $(window).on("click.hide-fullscreen", function (e) {
          var $target = $(e.target);
          var $parents = $target.parents();

          if (!$target.is(".widget-button") && !$parents.is(".widget-button") && !$parents.is("#discourse-modal") && !$target.is("#discourse-modal") && !$parents.is(".modal-footer") && ($target.is(".topic-timeline") || !$parents.is("#topic-progress-wrapper"))) {
            _this2._collapseFullscreen();
          }
        });
      } else {
        $(window).off("click.hide-fullscreen");
      }

      this._checkSize();
    },
    composerOpened: function composerOpened() {
      this.set("composerOpen", true);

      this._checkSize();
    },
    composerClosed: function composerClosed() {
      this.set("composerOpen", false);

      this._checkSize();
    },
    _collapseFullscreen: function _collapseFullscreen() {
      var _this3 = this;

      if (this.get("info.topicProgressExpanded")) {
        $(".timeline-fullscreen").removeClass("show");
        Ember.run.later(function () {
          if (!_this3.element || _this3.isDestroying || _this3.isDestroyed) {
            return;
          }

          _this3.set("info.topicProgressExpanded", false);

          _this3._checkSize();
        }, 500);
      }
    },
    keyboardTrigger: function keyboardTrigger(e) {
      if (e.type === "jump") {
        var controller = (0, _showModal.default)("jump-to-post", {
          modalClass: "jump-to-post-modal"
        });
        controller.setProperties({
          topic: this.topic,
          jumpToIndex: this.attrs.jumpToIndex,
          jumpToDate: this.attrs.jumpToDate
        });
      }
    },
    _handlePanDone: function _handlePanDone(offset, event) {
      var _this4 = this;

      var $timelineContainer = $(".timeline-container");
      var maxOffset = parseInt($timelineContainer.css("height"), 10);
      $timelineContainer.addClass("animate");

      if (this._shouldPanClose(event)) {
        $timelineContainer.css("--offset", "".concat(maxOffset, "px"));
        Ember.run.later(function () {
          _this4._collapseFullscreen();

          $timelineContainer.removeClass("animate");
        }, 200);
      } else {
        $timelineContainer.css("--offset", 0);
        Ember.run.later(function () {
          $timelineContainer.removeClass("animate");
        }, 200);
      }
    },
    _shouldPanClose: function _shouldPanClose(e) {
      return e.deltaY > _panEvents.SWIPE_DISTANCE_THRESHOLD && e.velocityY > -_panEvents.SWIPE_VELOCITY_THRESHOLD || e.velocityY > _panEvents.SWIPE_VELOCITY_THRESHOLD;
    },
    panStart: function panStart(e) {
      if (e.originalEvent.target.classList.contains("docked")) {
        return;
      }

      e.originalEvent.preventDefault();
      var center = e.center;
      var $centeredElement = $(document.elementFromPoint(center.x, center.y));

      if ($centeredElement.parents(".timeline-scrollarea-wrapper").length) {
        this.isPanning = false;
      } else if (e.direction === "up" || e.direction === "down") {
        this.isPanning = true;
      }
    },
    panEnd: function panEnd(e) {
      if (!this.isPanning) {
        return;
      }

      e.originalEvent.preventDefault();
      this.isPanning = false;

      this._handlePanDone(e.deltaY, e);
    },
    panMove: function panMove(e) {
      if (!this.isPanning) {
        return;
      }

      e.originalEvent.preventDefault();
      $(".timeline-container").css("--offset", "".concat(Math.max(0, e.deltaY), "px"));
    },
    didInsertElement: function didInsertElement() {
      var _this5 = this;

      this._super.apply(this, arguments);

      this._lastTopicId = this.topic.id;
      this.appEvents.on("topic:current-post-scrolled", this, this._topicScrolled).on("topic:jump-to-post", this, this._collapseFullscreen).on("topic:keyboard-trigger", this, this.keyboardTrigger);

      if (!this.site.mobileView) {
        $(window).on("resize.discourse-topic-navigation", function () {
          return _this5._checkSize();
        });
        this.appEvents.on("composer:opened", this, this.composerOpened);
        this.appEvents.on("composer:resize-ended", this, this.composerOpened);
        this.appEvents.on("composer:closed", this, this.composerClosed);
        $("#reply-control").on("div-resized.discourse-topic-navigation", function () {
          return _this5._checkSize();
        });
      }

      this._checkSize();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.appEvents.off("topic:current-post-scrolled", this, this._topicScrolled).off("topic:jump-to-post", this, this._collapseFullscreen).off("topic:keyboard-trigger", this, this.keyboardTrigger);
      $(window).off("click.hide-fullscreen");

      if (!this.site.mobileView) {
        $(window).off("resize.discourse-topic-navigation");
        this.appEvents.off("composer:opened", this, this.composerOpened);
        this.appEvents.off("composer:resize-ended", this, this.composerOpened);
        this.appEvents.off("composer:closed", this, this.composerClosed);
        $("#reply-control").off("div-resized.discourse-topic-navigation");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "_expanded", [_dec], Object.getOwnPropertyDescriptor(_obj, "_expanded"), _obj)), _obj)));

  _exports.default = _default;
});