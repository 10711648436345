define("discourse/widgets/post-edits-indicator", ["exports", "I18n", "discourse/widgets/widget", "discourse/lib/formatter"], function (_exports, _I18n, _widget, _formatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.historyHeat = historyHeat;
  _exports.default = void 0;

  function mult(val) {
    return 60 * 50 * 1000 * val;
  }

  function historyHeat(siteSettings, updatedAt) {
    if (!updatedAt) {
      return;
    } // Show heat on age


    var rightNow = Date.now();
    var updatedAtTime = updatedAt.getTime();

    if (updatedAtTime > rightNow - mult(siteSettings.history_hours_low)) {
      return "heatmap-high";
    }

    if (updatedAtTime > rightNow - mult(siteSettings.history_hours_medium)) {
      return "heatmap-med";
    }

    if (updatedAtTime > rightNow - mult(siteSettings.history_hours_high)) {
      return "heatmap-low";
    }
  }

  var _default = (0, _widget.createWidget)("post-edits-indicator", {
    tagName: "div.post-info.edits",
    html: function html(attrs) {
      var icon = "pencil-alt";
      var updatedAt = new Date(attrs.updated_at);
      var className = historyHeat(this.siteSettings, updatedAt);
      var date = (0, _formatter.longDate)(updatedAt);
      var title;

      if (attrs.wiki) {
        icon = "far-edit";
        className = "".concat(className || "", " wiki").trim();

        if (attrs.version > 1) {
          title = _I18n.default.t("post.wiki_last_edited_on", {
            dateTime: date
          });
        } else {
          title = _I18n.default.t("post.wiki.about");
        }
      } else {
        title = _I18n.default.t("post.last_edited_on", {
          dateTime: date
        });
      }

      return this.attach("flat-button", {
        icon: icon,
        translatedTitle: title,
        className: className,
        action: "onPostEditsIndicatorClick",
        translatedLabel: attrs.version > 1 ? attrs.version - 1 : ""
      });
    },
    onPostEditsIndicatorClick: function onPostEditsIndicatorClick() {
      if (this.attrs.wiki && this.attrs.version === 1) {
        this.sendWidgetAction("editPost");
      } else if (this.attrs.canViewEditHistory) {
        this.sendWidgetAction("showHistory");
      }
    }
  });

  _exports.default = _default;
});