define("discourse/components/d-section", ["exports", "discourse-common/lib/deprecated", "discourse/mixins/scroll-top"], function (_exports, _deprecated, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  // Can add a body class from within a component, also will scroll to the top automatically.
  var _default = Ember.Component.extend({
    tagName: null,
    pageClass: null,
    bodyClass: null,
    scrollTop: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.pageClass) {
        document.body.classList.add("".concat(this.pageClass, "-page"));
      }

      if (this.bodyClass) {
        var _document$body$classL;

        (_document$body$classL = document.body.classList).add.apply(_document$body$classL, _toConsumableArray(this.bodyClass.split(" ")));
      }

      if (this.scrollTop === "false") {
        (0, _deprecated.default)("Uses boolean instead of string for scrollTop.", {
          since: "2.8.0.beta9",
          dropFrom: "2.9.0.beta1"
        });
        return;
      }

      if (!this.scrollTop) {
        return;
      }

      (0, _scrollTop.scrollTop)();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.pageClass) {
        document.body.classList.remove("".concat(this.pageClass, "-page"));
      }

      if (this.bodyClass) {
        var _document$body$classL2;

        (_document$body$classL2 = document.body.classList).remove.apply(_document$body$classL2, _toConsumableArray(this.bodyClass.split(" ")));
      }
    }
  });

  _exports.default = _default;
});