define("discourse/helpers/bound-date", ["exports", "discourse/lib/formatter", "discourse-common/lib/helpers"], function (_exports, _formatter, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helpers.htmlHelper)(function (dt) {
    return (0, _formatter.autoUpdatingRelativeAge)(new Date(dt), {
      format: "medium",
      title: true
    });
  });

  _exports.default = _default;
});