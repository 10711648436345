define("discourse/templates/components/featured-topic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RnwdfDMC",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"raw\",[\"topic-status\"],[[\"topic\"],[[24,[\"topic\"]]]]],false],[0,\"\\n\"],[7,\"a\",true],[11,\"href\",[24,[\"topic\",\"lastUnreadUrl\"]]],[10,\"class\",\"title\"],[8],[1,[28,\"html-safe\",[[24,[\"topic\",\"fancyTitle\"]]],null],false],[9],[0,\"\\n\"],[1,[28,\"topic-post-badges\",null,[[\"unreadPosts\",\"unseen\",\"url\"],[[24,[\"topic\",\"unread_posts\"]],[24,[\"topic\",\"unseen\"]],[24,[\"topic\",\"lastUnreadUrl\"]]]]],false],[0,\"\\n\\n\"],[7,\"a\",true],[11,\"href\",[24,[\"topic\",\"lastPostUrl\"]]],[10,\"class\",\"last-posted-at\"],[8],[1,[28,\"format-age\",[[24,[\"topic\",\"last_posted_at\"]]],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/featured-topic.hbs"
    }
  });

  _exports.default = _default;
});