define("discourse/templates/components/group-manage-logs-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EpyQW9xJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"d-button\",null,[[\"class\",\"action\",\"actionParam\",\"icon\",\"translatedLabel\"],[\"btn-default group-manage-logs-filter\",[28,\"action\",[[23,0,[]],\"clearFilter\"],null],[24,[\"type\"]],\"times-circle\",[28,\"concat\",[[24,[\"label\"]],\": \",[24,[\"filterText\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/group-manage-logs-filter.hbs"
    }
  });

  _exports.default = _default;
});