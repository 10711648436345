define("discourse/models/post-action-type", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MAX_MESSAGE_LENGTH = void 0;
  var MAX_MESSAGE_LENGTH = 500;
  _exports.MAX_MESSAGE_LENGTH = MAX_MESSAGE_LENGTH;

  var _default = _rest.default.extend({
    notCustomFlag: Ember.computed.not("is_custom_flag")
  });

  _exports.default = _default;
});