define("discourse/components/expand-post", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    expanded: null,
    _loading: false,
    actions: {
      toggleItem: function toggleItem() {
        var _this = this;

        if (this._loading) {
          return false;
        }

        var item = this.item;

        if (this.expanded) {
          this.set("expanded", false);
          item.set("expandedExcerpt", null);
          return false;
        }

        var topicId = item.get("topic_id");
        var postNumber = item.get("post_number");
        this._loading = true;
        (0, _ajax.ajax)("/posts/by_number/".concat(topicId, "/").concat(postNumber, ".json")).then(function (result) {
          _this.set("expanded", true);

          item.set("expandedExcerpt", result.cooked);
        }).finally(function () {
          return _this._loading = false;
        });
        return false;
      }
    }
  });

  _exports.default = _default;
});