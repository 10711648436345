define("discourse/templates/review-show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5R+5odEt",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"reviewable-item\",null,[[\"reviewable\"],[[24,[\"reviewable\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/review-show.hbs"
    }
  });

  _exports.default = _default;
});