define("discourse/routes/user-activity-drafts", ["exports", "discourse/routes/discourse", "I18n"], function (_exports, _discourse, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _discourse.default.extend((_dec = Ember._action, (_obj = {
    model: function model() {
      var _this = this;

      var user = this.modelFor("user");
      var draftsStream = user.get("userDraftsStream");
      draftsStream.reset();
      return draftsStream.findItems(this.site).then(function () {
        return {
          stream: draftsStream,
          isAnotherUsersPage: _this.isAnotherUsersPage(user),
          emptyState: _this.emptyState()
        };
      });
    },
    renderTemplate: function renderTemplate() {
      this.render("user_stream");
    },
    setupController: function setupController(controller, model) {
      controller.set("model", model);
    },
    emptyState: function emptyState() {
      var title = _I18n.default.t("user_activity.no_drafts_title");

      var body = _I18n.default.t("user_activity.no_drafts_body");

      return {
        title: title,
        body: body
      };
    },
    activate: function activate() {
      this.appEvents.on("draft:destroyed", this, this.refresh);
    },
    deactivate: function deactivate() {
      this.appEvents.off("draft:destroyed", this, this.refresh);
    },
    didTransition: function didTransition() {
      this.controllerFor("user-activity")._showFooter();

      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, "didTransition", [_dec], Object.getOwnPropertyDescriptor(_obj, "didTransition"), _obj)), _obj)));

  _exports.default = _default;
});