define("discourse/controllers/change-post-notice", ["exports", "discourse/mixins/modal-functionality", "discourse/lib/text", "discourse-common/utils/decorators"], function (_exports, _modalFunctionality, _text, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("saving", "notice"), _dec2 = Ember._action, (_obj = {
    post: null,
    resolve: null,
    reject: null,
    notice: null,
    saving: false,
    disabled: function disabled(saving, notice) {
      return saving || Ember.isEmpty(notice);
    },
    onShow: function onShow() {
      this.setProperties({
        notice: "",
        saving: false
      });
    },
    onClose: function onClose() {
      if (this.reject) {
        this.reject();
      }
    },
    setNotice: function setNotice(notice) {
      var _this = this;

      var resolve = this.resolve,
          reject = this.reject;
      this.setProperties({
        saving: true,
        resolve: null,
        reject: null
      });
      this.model.updatePostField("notice", notice).then(function () {
        if (notice) {
          return (0, _text.cookAsync)(notice, {
            features: {
              onebox: false
            }
          });
        }
      }).then(function (cooked) {
        return _this.model.set("notice", cooked ? {
          type: "custom",
          raw: notice,
          cooked: cooked.string
        } : null);
      }).then(resolve, reject).finally(function () {
        return _this.send("closeModal");
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "disabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "disabled"), _obj), _applyDecoratedDescriptor(_obj, "setNotice", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setNotice"), _obj)), _obj)));

  _exports.default = _default;
});