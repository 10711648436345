define("discourse/templates/components/reviewable-queued-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6q6Ajitb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"reviewable-topic-link\",null,[[\"reviewable\",\"tagName\"],[[24,[\"reviewable\"]],\"\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"title-text\"],[8],[0,\"\\n    \"],[1,[28,\"d-icon\",[\"plus-square\"],[[\"title\"],[\"review.new_topic\"]]],false],[0,\"\\n    \"],[1,[24,[\"reviewable\",\"payload\",\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"category-badge\",[[24,[\"reviewable\",\"category\"]]],null],false],[0,\"\\n  \"],[1,[28,\"reviewable-tags\",null,[[\"tags\",\"tagName\"],[[24,[\"reviewable\",\"payload\",\"tags\"]],\"\"]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"reviewable\",\"payload\",\"via_email\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"show-raw-email\"],[3,\"action\",[[23,0,[]],\"showRawEmail\"]],[8],[0,\"\\n      \"],[1,[28,\"d-icon\",[\"far-envelope\"],[[\"title\"],[\"post.via_email\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"post-contents-wrapper\"],[8],[0,\"\\n  \"],[1,[28,\"reviewable-created-by\",null,[[\"user\",\"tagName\"],[[24,[\"reviewable\",\"created_by\"]],\"\"]]],false],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"post-contents\"],[8],[0,\"\\n    \"],[1,[28,\"reviewable-post-header\",null,[[\"reviewable\",\"createdBy\",\"tagName\"],[[24,[\"reviewable\"]],[24,[\"reviewable\",\"created_by\"]],\"\"]]],false],[0,\"\\n\\n    \"],[1,[28,\"cook-text\",[[24,[\"reviewable\",\"payload\",\"raw\"]]],[[\"class\",\"categoryId\",\"topicId\",\"paintOneboxes\",\"opts\"],[\"post-body\",[24,[\"reviewable\",\"category_id\"]],[24,[\"reviewable\",\"topic_id\"]],true,[28,\"hash\",null,[[\"removeMissing\"],[true]]]]]],false],[0,\"\\n\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-queued-post.hbs"
    }
  });

  _exports.default = _default;
});