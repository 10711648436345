define("discourse/models/reviewable", ["exports", "discourse-common/utils/category-macro", "I18n", "discourse/models/rest", "discourse/lib/ajax", "discourse-common/utils/decorators"], function (_exports, _categoryMacro, _I18n, _rest, _ajax, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DELETED = _exports.IGNORED = _exports.REJECTED = _exports.APPROVED = _exports.PENDING = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var PENDING = 0;
  _exports.PENDING = PENDING;
  var APPROVED = 1;
  _exports.APPROVED = APPROVED;
  var REJECTED = 2;
  _exports.REJECTED = REJECTED;
  var IGNORED = 3;
  _exports.IGNORED = IGNORED;
  var DELETED = 4;
  _exports.DELETED = DELETED;

  var Reviewable = _rest.default.extend((_dec = (0, _decorators.default)("type", "topic"), (_obj = {
    humanType: function humanType(type, topic) {
      // Display "Queued Topic" if the post will create a topic
      if (type === "ReviewableQueuedPost" && !topic) {
        type = "ReviewableQueuedTopic";
      }

      return _I18n.default.t("review.types.".concat(type.underscore(), ".title"), {
        defaultValue: ""
      });
    },
    category: (0, _categoryMacro.default)("category_id"),
    update: function update(updates) {
      var _this = this;

      // If no changes, do nothing
      if (Object.keys(updates).length === 0) {
        return Ember.RSVP.Promise.resolve();
      }

      var adapter = this.store.adapterFor("reviewable");
      return (0, _ajax.ajax)("/review/".concat(this.id, "?version=").concat(this.version), adapter.getPayload("PUT", {
        reviewable: updates
      })).then(function (updated) {
        updated.payload = Object.assign({}, _this.payload || {}, updated.payload || {});

        _this.setProperties(updated);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "humanType", [_dec], Object.getOwnPropertyDescriptor(_obj, "humanType"), _obj)), _obj)));

  Reviewable.reopenClass({
    munge: function munge(json) {
      // ensure we are not overriding category computed property
      delete json.category;
      return json;
    }
  });
  var _default = Reviewable;
  _exports.default = _default;
});