define("discourse/templates/components/category-unread", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QzNkJ2qC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"category\",\"unreadTopics\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\",true],[11,\"href\",[24,[\"category\",\"unreadUrl\"]]],[11,\"title\",[28,\"i18n\",[\"topic.unread_topics\"],[[\"count\"],[[24,[\"category\",\"unreadTopics\"]]]]]],[10,\"class\",\"badge new-posts badge-notification\"],[8],[1,[28,\"i18n\",[\"filters.unread.lower_title_with_count\"],[[\"count\"],[[24,[\"category\",\"unreadTopics\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"category\",\"newTopics\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\",true],[11,\"href\",[24,[\"category\",\"newUrl\"]]],[11,\"title\",[28,\"i18n\",[\"topic.new_topics\"],[[\"count\"],[[24,[\"category\",\"newTopics\"]]]]]],[10,\"class\",\"badge new-posts badge-notification\"],[8],[1,[28,\"i18n\",[\"filters.new.lower_title_with_count\"],[[\"count\"],[[24,[\"category\",\"newTopics\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/category-unread.hbs"
    }
  });

  _exports.default = _default;
});