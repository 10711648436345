define("discourse/models/post-stream", ["exports", "discourse/lib/url", "I18n", "discourse/lib/posts-with-placeholders", "discourse/models/rest", "discourse/models/user", "discourse/lib/ajax", "discourse-common/lib/object", "discourse-common/lib/deprecated", "discourse-common/utils/decorators", "discourse/lib/utilities", "discourse/models/topic"], function (_exports, _url, _I18n, _postsWithPlaceholders, _rest, _user, _ajax, _object, _deprecated, _decorators, _utilities, _topic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setLastEditNotificationClick = setLastEditNotificationClick;
  _exports.resetLastEditNotificationClick = resetLastEditNotificationClick;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _obj;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _lastEditNotificationClick = null;

  function setLastEditNotificationClick(topicId, postNumber, revisionNumber) {
    _lastEditNotificationClick = {
      topicId: topicId,
      postNumber: postNumber,
      revisionNumber: revisionNumber
    };
  }

  function resetLastEditNotificationClick() {
    _lastEditNotificationClick = null;
  }

  var _default = _rest.default.extend((_dec = (0, _decorators.default)("isMegaTopic", "stream.length", "topic.highest_post_number"), _dec2 = (0, _decorators.default)("posts.[]"), _dec3 = (0, _decorators.default)("hasPosts", "filteredPostsCount"), _dec4 = (0, _decorators.default)("hasLoadedData", "posts.[]"), _dec5 = (0, _decorators.default)("isMegaTopic", "stream.lastObject", "lastId"), _dec6 = (0, _decorators.default)("hasLoadedData", "lastPostId", "posts.@each.id"), _dec7 = (0, _decorators.default)("summary", "userFilters.[]", "filterRepliesToPostNumber", "filterUpwardsPostID"), _dec8 = (0, _decorators.default)("streamFilters.[]", "topic.posts_count", "posts.length"), _dec9 = (0, _decorators.default)("posts.[]", "stream.[]"), _dec10 = (0, _decorators.default)("posts.lastObject", "stream.[]"), (_obj = {
    _identityMap: null,
    posts: null,
    stream: null,
    userFilters: null,
    summary: null,
    loaded: null,
    loadingAbove: null,
    loadingBelow: null,
    loadingFilter: null,
    loadingNearPost: null,
    stagingPost: null,
    postsWithPlaceholders: null,
    timelineLookup: null,
    filterRepliesToPostNumber: null,
    filterUpwardsPostID: null,
    init: function init() {
      this._identityMap = {};
      var posts = [];

      var postsWithPlaceholders = _postsWithPlaceholders.default.create({
        posts: posts,
        store: this.store
      });

      this.setProperties({
        posts: posts,
        postsWithPlaceholders: postsWithPlaceholders,
        stream: [],
        userFilters: [],
        summary: false,
        filterRepliesToPostNumber: parseInt(this.get("topic.replies_to_post_number"), 10) || false,
        filterUpwardsPostID: false,
        loaded: false,
        loadingAbove: false,
        loadingBelow: false,
        loadingFilter: false,
        stagingPost: false,
        timelineLookup: []
      });
    },
    loading: Ember.computed.or("loadingAbove", "loadingBelow", "loadingFilter", "stagingPost"),
    notLoading: Ember.computed.not("loading"),
    filteredPostsCount: function filteredPostsCount(isMegaTopic, streamLength, topicHighestPostNumber) {
      return isMegaTopic ? topicHighestPostNumber : streamLength;
    },
    hasPosts: function hasPosts() {
      return this.get("posts.length") > 0;
    },
    hasLoadedData: function hasLoadedData(hasPosts, filteredPostsCount) {
      return hasPosts && filteredPostsCount > 0;
    },
    canAppendMore: Ember.computed.and("notLoading", "hasPosts", "lastPostNotLoaded"),
    canPrependMore: Ember.computed.and("notLoading", "hasPosts", "firstPostNotLoaded"),
    firstPostPresent: function firstPostPresent(hasLoadedData) {
      if (!hasLoadedData) {
        return false;
      }

      return !!this.posts.findBy("post_number", 1);
    },
    firstPostNotLoaded: Ember.computed.not("firstPostPresent"),
    lastId: null,
    lastPostId: function lastPostId(isMegaTopic, streamLastId, lastId) {
      return isMegaTopic ? lastId : streamLastId;
    },
    loadedAllPosts: function loadedAllPosts(hasLoadedData, lastPostId) {
      if (!hasLoadedData) {
        return false;
      }

      if (lastPostId === -1) {
        return true;
      }

      return !!this.posts.findBy("id", lastPostId);
    },
    lastPostNotLoaded: Ember.computed.not("loadedAllPosts"),
    streamFilters: function streamFilters() {
      var result = {};

      if (this.summary) {
        result.filter = "summary";
      }

      var userFilters = this.userFilters;

      if (!Ember.isEmpty(userFilters)) {
        result.username_filters = userFilters.join(",");
      }

      if (this.filterRepliesToPostNumber) {
        result.replies_to_post_number = this.filterRepliesToPostNumber;
      }

      if (this.filterUpwardsPostID) {
        result.filter_upwards_post_id = this.filterUpwardsPostID;
      }

      return result;
    },
    hasNoFilters: function hasNoFilters() {
      var streamFilters = this.streamFilters;
      return !(streamFilters && (streamFilters.filter === "summary" || streamFilters.username_filters));
    },
    previousWindow: function previousWindow() {
      if (!this.posts) {
        return [];
      } // If we can't find the last post loaded, bail


      var firstPost = this.posts[0];

      if (!firstPost) {
        return [];
      } // Find the index of the last post loaded, if not found, bail


      var stream = this.stream;
      var firstIndex = this.indexOf(firstPost);

      if (firstIndex === -1) {
        return [];
      }

      var startIndex = firstIndex - this.get("topic.chunk_size");

      if (startIndex < 0) {
        startIndex = 0;
      }

      return stream.slice(startIndex, firstIndex);
    },
    nextWindow: function nextWindow(lastLoadedPost) {
      // If we can't find the last post loaded, bail
      if (!lastLoadedPost) {
        return [];
      } // Find the index of the last post loaded, if not found, bail


      var stream = this.stream;
      var lastIndex = this.indexOf(lastLoadedPost);

      if (lastIndex === -1) {
        return [];
      }

      if (lastIndex + 1 >= this.highest_post_number) {
        return [];
      } // find our window of posts


      return stream.slice(lastIndex + 1, lastIndex + this.get("topic.chunk_size") + 1);
    },
    cancelFilter: function cancelFilter() {
      this.setProperties({
        userFilters: [],
        summary: false,
        filterRepliesToPostNumber: false,
        filterUpwardsPostID: false,
        mixedHiddenPosts: false
      });
    },
    refreshAndJumptoSecondVisible: function refreshAndJumptoSecondVisible() {
      var _this = this;

      return this.refresh({}).then(function () {
        if (_this.posts && _this.posts.length > 1) {
          _url.default.jumpToPost(_this.posts[1].get("post_number"));
        }
      });
    },
    showSummary: function showSummary() {
      this.cancelFilter();
      this.set("summary", true);
      return this.refreshAndJumptoSecondVisible();
    },
    // Filter the stream to a particular user.
    filterParticipant: function filterParticipant(username) {
      this.cancelFilter();
      this.userFilters.addObject(username);
      return this.refreshAndJumptoSecondVisible();
    },
    filterReplies: function filterReplies(postNumber, postId) {
      var _this2 = this;

      this.cancelFilter();
      this.set("filterRepliesToPostNumber", postNumber);
      this.appEvents.trigger("post-stream:filter-replies", {
        topic_id: this.get("topic.id"),
        post_number: postNumber,
        post_id: postId
      });
      return this.refresh({
        refreshInPlace: true
      }).then(function () {
        var element = document.querySelector("#post_".concat(postNumber)); // order is important, we need to get the offset before triggering a refresh

        var originalTopOffset = element ? element.getBoundingClientRect().top : null;

        _this2.appEvents.trigger("post-stream:refresh");

        _url.default.jumpToPost(postNumber, {
          originalTopOffset: originalTopOffset
        });

        Ember.run.schedule("afterRender", function () {
          (0, _utilities.highlightPost)(postNumber);
        });
      });
    },
    filterUpwards: function filterUpwards(postID) {
      var _this3 = this;

      this.cancelFilter();
      this.set("filterUpwardsPostID", postID);
      this.appEvents.trigger("post-stream:filter-upwards", {
        topic_id: this.get("topic.id"),
        post_id: postID
      });
      return this.refresh({
        refreshInPlace: true
      }).then(function () {
        _this3.appEvents.trigger("post-stream:refresh");

        if (_this3.posts && _this3.posts.length > 1) {
          var postNumber = _this3.posts[1].get("post_number");

          _url.default.jumpToPost(postNumber, {
            skipIfOnScreen: true
          });

          Ember.run.schedule("afterRender", function () {
            (0, _utilities.highlightPost)(postNumber);
          });
        }
      });
    },

    /**
      Loads a new set of posts into the stream. If you provide a `nearPost` option and the post
      is already loaded, it will simply scroll there and load nothing.
    **/
    refresh: function refresh(opts) {
      var _this4 = this;

      opts = opts || {};
      opts.nearPost = parseInt(opts.nearPost, 10);

      if (opts.cancelFilter) {
        this.cancelFilter();
        delete opts.cancelFilter;
      }

      var topic = this.topic; // Do we already have the post in our list of posts? Jump there.

      if (opts.forceLoad) {
        this.set("loaded", false);
      } else {
        var postWeWant = this.posts.findBy("post_number", opts.nearPost);

        if (postWeWant) {
          return Ember.RSVP.Promise.resolve().then(function () {
            return _this4._checkIfShouldShowRevisions();
          });
        }
      } // TODO: if we have all the posts in the filter, don't go to the server for them.


      if (!opts.refreshInPlace) {
        this.set("loadingFilter", true);
      }

      this.set("loadingNearPost", opts.nearPost);
      opts = (0, _object.deepMerge)(opts, this.streamFilters); // Request a topicView

      return (0, _topic.loadTopicView)(topic, opts).then(function (json) {
        _this4.updateFromJson(json.post_stream);

        _this4.setProperties({
          loadingFilter: false,
          timelineLookup: json.timeline_lookup,
          loaded: true
        });

        _this4._checkIfShouldShowRevisions();
      }).catch(function (result) {
        _this4.errorLoading(result);

        throw new Error(result);
      }).finally(function () {
        _this4.set("loadingNearPost", null);
      });
    },
    // Fill in a gap of posts before a particular post
    fillGapBefore: function fillGapBefore(post, gap) {
      var _this5 = this;

      var postId = post.get("id"),
          stream = this.stream,
          idx = stream.indexOf(postId),
          currentPosts = this.posts;

      if (idx !== -1) {
        // Insert the gap at the appropriate place
        var postIdx = currentPosts.indexOf(post);
        var origIdx = postIdx;
        var headGap = gap.slice(0, this.topic.chunk_size);
        var tailGap = gap.slice(this.topic.chunk_size);
        stream.splice.apply(stream, [idx, 0].concat(headGap));

        if (postIdx !== -1) {
          return this.findPostsByIds(headGap).then(function (posts) {
            posts.forEach(function (p) {
              var stored = _this5.storePost(p);

              if (!currentPosts.includes(stored)) {
                currentPosts.insertAt(postIdx++, stored);
              }
            });

            if (tailGap.length > 0) {
              _this5.get("gaps.before")[postId] = tailGap;
            } else {
              delete _this5.get("gaps.before")[postId];
            }

            _this5.postsWithPlaceholders.arrayContentDidChange(origIdx, 0, posts.length);

            post.set("hasGap", false);

            _this5.gapExpanded();
          });
        }
      }

      return Ember.RSVP.Promise.resolve();
    },
    // Fill in a gap of posts after a particular post
    fillGapAfter: function fillGapAfter(post, gap) {
      var _this6 = this;

      var postId = post.get("id"),
          stream = this.stream,
          idx = stream.indexOf(postId);

      if (idx !== -1) {
        stream.pushObjects(gap);
        return this.appendMore().then(function () {
          delete _this6.get("gaps.after")[postId];

          _this6.gapExpanded();
        });
      }

      return Ember.RSVP.Promise.resolve();
    },
    gapExpanded: function gapExpanded() {
      this.appEvents.trigger("post-stream:refresh"); // resets the reply count in posts-filtered-notice
      // because once a gap has been expanded that count is no longer exact

      if (this.streamFilters && this.streamFilters.replies_to_post_number) {
        this.set("streamFilters.mixedHiddenPosts", true);
      }
    },
    // Appends the next window of posts to the stream. Call it when scrolling downwards.
    appendMore: function appendMore() {
      var _this7 = this;

      // Make sure we can append more posts
      if (!this.canAppendMore) {
        return Ember.RSVP.Promise.resolve();
      }

      var postsWithPlaceholders = this.postsWithPlaceholders;

      if (this.isMegaTopic) {
        this.set("loadingBelow", true);

        var fakePostIds = _toConsumableArray(Array(this.get("topic.chunk_size") - 1).keys()).map(function (i) {
          return -i - 1;
        });

        postsWithPlaceholders.appending(fakePostIds);
        return this.fetchNextWindow(this.get("posts.lastObject.post_number"), true, function (p) {
          _this7.appendPost(p);
        }).finally(function () {
          postsWithPlaceholders.finishedAppending(fakePostIds);

          _this7.set("loadingBelow", false);
        });
      } else {
        var postIds = this.nextWindow;

        if (Ember.isEmpty(postIds)) {
          return Ember.RSVP.Promise.resolve();
        }

        this.set("loadingBelow", true);
        postsWithPlaceholders.appending(postIds);
        return this.findPostsByIds(postIds).then(function (posts) {
          posts.forEach(function (p) {
            return _this7.appendPost(p);
          });
          return posts;
        }).finally(function () {
          postsWithPlaceholders.finishedAppending(postIds);

          _this7.set("loadingBelow", false);
        });
      }
    },
    // Prepend the previous window of posts to the stream. Call it when scrolling upwards.
    prependMore: function prependMore() {
      var _this8 = this;

      // Make sure we can append more posts
      if (!this.canPrependMore) {
        return Ember.RSVP.Promise.resolve();
      }

      if (this.isMegaTopic) {
        this.set("loadingAbove", true);
        var prependedIds = [];
        return this.fetchNextWindow(this.get("posts.firstObject.post_number"), false, function (p) {
          _this8.prependPost(p);

          prependedIds.push(p.get("id"));
        }).finally(function () {
          var postsWithPlaceholders = _this8.postsWithPlaceholders;
          postsWithPlaceholders.finishedPrepending(prependedIds);

          _this8.set("loadingAbove", false);
        });
      } else {
        var postIds = this.previousWindow;

        if (Ember.isEmpty(postIds)) {
          return Ember.RSVP.Promise.resolve();
        }

        this.set("loadingAbove", true);
        return this.findPostsByIds(postIds.reverse()).then(function (posts) {
          posts.forEach(function (p) {
            return _this8.prependPost(p);
          });
        }).finally(function () {
          var postsWithPlaceholders = _this8.postsWithPlaceholders;
          postsWithPlaceholders.finishedPrepending(postIds);

          _this8.set("loadingAbove", false);
        });
      }
    },

    /**
      Stage a post for insertion in the stream. It should be rendered right away under the
      assumption that the post will succeed. We can then `commitPost` when it succeeds or
      `undoPost` when it fails.
    **/
    stagePost: function stagePost(post, user) {
      // We can't stage two posts simultaneously
      if (this.stagingPost) {
        return "alreadyStaging";
      }

      this.set("stagingPost", true);
      var topic = this.topic;
      topic.setProperties({
        posts_count: (topic.get("posts_count") || 0) + 1,
        last_posted_at: new Date(),
        "details.last_poster": user,
        highest_post_number: (topic.get("highest_post_number") || 0) + 1
      });
      post.setProperties({
        post_number: topic.get("highest_post_number"),
        topic: topic,
        created_at: new Date(),
        id: -1
      }); // If we're at the end of the stream, add the post

      if (this.loadedAllPosts) {
        this.appendPost(post);
        this.stream.addObject(post.get("id"));
        return "staged";
      }

      return "offScreen";
    },
    // Commit the post we staged. Call this after a save succeeds.
    commitPost: function commitPost(post) {
      if (this.get("topic.id") === post.get("topic_id")) {
        if (this.loadedAllPosts) {
          this.appendPost(post);
          this.stream.addObject(post.get("id"));
        }
      }

      this.stream.removeObject(-1);
      this._identityMap[-1] = null;
      this.set("stagingPost", false);
    },

    /**
      Undo a post we've staged in the stream. Remove it from being rendered and revert the
      state we changed.
    **/
    undoPost: function undoPost(post) {
      var _this9 = this;

      this.stream.removeObject(-1);
      this.postsWithPlaceholders.removePost(function () {
        return _this9.posts.removeObject(post);
      });
      this._identityMap[-1] = null;
      var topic = this.topic;
      this.set("stagingPost", false);
      topic.setProperties({
        highest_post_number: (topic.get("highest_post_number") || 0) - 1,
        posts_count: (topic.get("posts_count") || 0) - 1
      }); // TODO unfudge reply count on parent post
    },
    prependPost: function prependPost(post) {
      var stored = this.storePost(post);

      if (stored) {
        var posts = this.posts;
        posts.unshiftObject(stored);
      }

      return post;
    },
    appendPost: function appendPost(post) {
      var stored = this.storePost(post);

      if (stored) {
        var posts = this.posts;

        if (!posts.includes(stored)) {
          if (!this.loadingBelow) {
            this.postsWithPlaceholders.appendPost(function () {
              return posts.pushObject(stored);
            });
          } else {
            posts.pushObject(stored);
          }
        }

        if (stored.get("id") !== -1) {
          this.set("lastAppended", stored);
        }
      }

      return post;
    },
    removePosts: function removePosts(posts) {
      var _this10 = this;

      if (Ember.isEmpty(posts)) {
        return;
      }

      this.postsWithPlaceholders.refreshAll(function () {
        var allPosts = _this10.posts;
        var postIds = posts.map(function (p) {
          return p.get("id");
        });
        var identityMap = _this10._identityMap;

        _this10.stream.removeObjects(postIds);

        allPosts.removeObjects(posts);
        postIds.forEach(function (id) {
          return delete identityMap[id];
        });
      });
    },
    // Returns a post from the identity map if it's been inserted.
    findLoadedPost: function findLoadedPost(id) {
      return this._identityMap[id];
    },
    loadPostByPostNumber: function loadPostByPostNumber(postNumber) {
      var _this11 = this;

      var url = "/posts/by_number/".concat(this.get("topic.id"), "/").concat(postNumber);
      var store = this.store;
      return (0, _ajax.ajax)(url).then(function (post) {
        return _this11.storePost(store.createRecord("post", post));
      });
    },
    loadNearestPostToDate: function loadNearestPostToDate(date) {
      var _this12 = this;

      var url = "/posts/by-date/".concat(this.get("topic.id"), "/").concat(date);
      var store = this.store;
      return (0, _ajax.ajax)(url).then(function (post) {
        return _this12.storePost(store.createRecord("post", post));
      });
    },
    loadPost: function loadPost(postId) {
      var _this13 = this;

      var url = "/posts/" + postId;
      var store = this.store;
      var existing = this._identityMap[postId];
      return (0, _ajax.ajax)(url).then(function (p) {
        if (existing) {
          p.cooked = existing.cooked;
        }

        return _this13.storePost(store.createRecord("post", p));
      });
    },

    /* mainly for backwards compatability with plugins, used in quick messages plugin
     * TODO: remove July 2021
     * */
    triggerNewPostInStream: function triggerNewPostInStream(postId, opts) {
      (0, _deprecated.default)("Please use triggerNewPostsInStream, this method will be removed July 2021");
      return this.triggerNewPostsInStream([postId], opts);
    },

    /**
      Finds and adds posts to the stream by id. Typically this would happen if we receive a message
      from the message bus indicating there's a new post. We'll only insert it if we currently
      have no filters.
    **/
    triggerNewPostsInStream: function triggerNewPostsInStream(postIds, opts) {
      var _this14 = this;

      var resolved = Ember.RSVP.Promise.resolve();

      if (!postIds || postIds.length === 0) {
        return resolved;
      } // We only trigger if there are no filters active


      if (!this.hasNoFilters) {
        return resolved;
      }

      var loadedAllPosts = this.loadedAllPosts;
      this._loadingPostIds = this._loadingPostIds || [];
      var missingIds = [];
      postIds.forEach(function (postId) {
        if (postId && _this14.stream.indexOf(postId) === -1) {
          missingIds.push(postId);
        }
      });

      if (missingIds.length === 0) {
        return resolved;
      }

      if (loadedAllPosts) {
        missingIds.forEach(function (postId) {
          if (_this14._loadingPostIds.indexOf(postId) === -1) {
            _this14._loadingPostIds.push(postId);
          }
        });
        this.set("loadingLastPost", true);
        return this.findPostsByIds(this._loadingPostIds, opts).then(function (posts) {
          _this14._loadingPostIds = null;

          var ignoredUsers = _user.default.current() && _user.default.current().get("ignored_users");

          posts.forEach(function (p) {
            if (ignoredUsers && ignoredUsers.includes(p.username)) {
              _this14.stream.removeObject(p.id);

              return;
            }

            _this14.stream.addObject(p.id);

            _this14.appendPost(p);
          });
        }).finally(function () {
          _this14.set("loadingLastPost", false);
        });
      } else {
        missingIds.forEach(function (postId) {
          return _this14.stream.addObject(postId);
        });
      }

      return resolved;
    },
    triggerRecoveredPost: function triggerRecoveredPost(postId) {
      var _this15 = this;

      var existing = this._identityMap[postId];

      if (existing) {
        return this.triggerChangedPost(postId, new Date());
      } else {
        // need to insert into stream
        var url = "/posts/".concat(postId);
        var store = this.store;
        return (0, _ajax.ajax)(url).then(function (p) {
          var post = store.createRecord("post", p);
          var stream = _this15.stream;
          var posts = _this15.posts;

          _this15.storePost(post); // we need to zip this into the stream


          var index = 0;
          stream.forEach(function (pid) {
            if (pid < p.id) {
              index += 1;
            }
          });
          stream.insertAt(index, p.id);
          index = 0;
          posts.forEach(function (_post) {
            if (_post.id < p.id) {
              index += 1;
            }
          });

          if (index < posts.length) {
            _this15.postsWithPlaceholders.refreshAll(function () {
              posts.insertAt(index, post);
            });
          } else {
            if (post.post_number < posts[posts.length - 1].post_number + 5) {
              _this15.appendMore();
            }
          }
        });
      }
    },
    triggerDeletedPost: function triggerDeletedPost(postId) {
      var _this16 = this;

      var existing = this._identityMap[postId];

      if (existing && !existing.deleted_at) {
        var url = "/posts/" + postId;
        var store = this.store;
        return (0, _ajax.ajax)(url).then(function (p) {
          _this16.storePost(store.createRecord("post", p));
        }).catch(function () {
          _this16.removePosts([existing]);
        });
      }

      return Ember.RSVP.Promise.resolve();
    },
    triggerDestroyedPost: function triggerDestroyedPost(postId) {
      var existing = this._identityMap[postId];
      this.removePosts([existing]);
      return Ember.RSVP.Promise.resolve();
    },
    triggerChangedPost: function triggerChangedPost(postId, updatedAt, opts) {
      var _this17 = this;

      opts = opts || {};
      var resolved = Ember.RSVP.Promise.resolve();

      if (!postId) {
        return resolved;
      }

      var existing = this._identityMap[postId];

      if (existing && existing.updated_at !== updatedAt) {
        var url = "/posts/" + postId;
        var store = this.store;
        return (0, _ajax.ajax)(url).then(function (p) {
          if (opts.preserveCooked) {
            p.cooked = existing.get("cooked");
          }

          _this17.storePost(store.createRecord("post", p));
        });
      }

      return resolved;
    },
    triggerLikedPost: function triggerLikedPost(postId, likesCount) {
      var resolved = Ember.RSVP.Promise.resolve();
      var post = this.findLoadedPost(postId);

      if (post) {
        post.updateLikeCount(likesCount);
        this.storePost(post);
      }

      return resolved;
    },
    triggerReadPost: function triggerReadPost(postId, readersCount) {
      var _this18 = this;

      var resolved = Ember.RSVP.Promise.resolve();
      resolved.then(function () {
        var post = _this18.findLoadedPost(postId);

        if (post && readersCount > post.readers_count) {
          post.set("readers_count", readersCount);

          _this18.storePost(post);
        }
      });
      return resolved;
    },
    postForPostNumber: function postForPostNumber(postNumber) {
      if (!this.hasPosts) {
        return;
      }

      return this.posts.find(function (p) {
        return p.get("post_number") === postNumber;
      });
    },

    /**
      Returns the closest post given a postNumber that may not exist in the stream.
      For example, if the user asks for a post that's deleted or otherwise outside the range.
      This allows us to set the progress bar with the correct number.
    **/
    closestPostForPostNumber: function closestPostForPostNumber(postNumber) {
      if (!this.hasPosts) {
        return;
      }

      var closest = null;
      this.posts.forEach(function (p) {
        if (!closest) {
          closest = p;
          return;
        }

        if (Math.abs(postNumber - p.get("post_number")) < Math.abs(closest.get("post_number") - postNumber)) {
          closest = p;
        }
      });
      return closest;
    },
    // Get the index of a post in the stream. (Use this for the topic progress bar.)
    progressIndexOfPost: function progressIndexOfPost(post) {
      return this.progressIndexOfPostId(post);
    },
    // Get the index in the stream of a post id. (Use this for the topic progress bar.)
    progressIndexOfPostId: function progressIndexOfPostId(post) {
      var postId = post.get("id");

      if (this.isMegaTopic) {
        return post.get("post_number");
      } else {
        var index = this.stream.indexOf(postId);
        return index + 1;
      }
    },

    /**
      Returns the closest post number given a postNumber that may not exist in the stream.
      For example, if the user asks for a post that's deleted or otherwise outside the range.
      This allows us to set the progress bar with the correct number.
    **/
    closestPostNumberFor: function closestPostNumberFor(postNumber) {
      if (!this.hasPosts) {
        return;
      }

      var closest = null;
      this.posts.forEach(function (p) {
        if (closest === postNumber) {
          return;
        }

        if (!closest) {
          closest = p.get("post_number");
        }

        if (Math.abs(postNumber - p.get("post_number")) < Math.abs(closest - postNumber)) {
          closest = p.get("post_number");
        }
      });
      return closest;
    },
    closestDaysAgoFor: function closestDaysAgoFor(postNumber) {
      var timelineLookup = this.timelineLookup || [];
      var low = 0;
      var high = timelineLookup.length - 1;

      while (low <= high) {
        var mid = Math.floor(low + (high - low) / 2);
        var midValue = timelineLookup[mid][0];

        if (midValue > postNumber) {
          high = mid - 1;
        } else if (midValue < postNumber) {
          low = mid + 1;
        } else {
          return timelineLookup[mid][1];
        }
      }

      var val = timelineLookup[high] || timelineLookup[low];

      if (val) {
        return val[1];
      }
    },
    // Find a postId for a postNumber, respecting gaps
    findPostIdForPostNumber: function findPostIdForPostNumber(postNumber) {
      var stream = this.stream,
          beforeLookup = this.get("gaps.before"),
          streamLength = stream.length;
      var sum = 1;

      for (var i = 0; i < streamLength; i++) {
        var pid = stream[i]; // See if there are posts before this post

        if (beforeLookup) {
          var before = beforeLookup[pid];

          if (before) {
            for (var j = 0; j < before.length; j++) {
              if (sum === postNumber) {
                return pid;
              }

              sum++;
            }
          }
        }

        if (sum === postNumber) {
          return pid;
        }

        sum++;
      }
    },
    updateFromJson: function updateFromJson(postStreamData) {
      var _this19 = this;

      var posts = this.posts;
      var postsWithPlaceholders = this.postsWithPlaceholders;
      postsWithPlaceholders.clear(function () {
        return posts.clear();
      });
      this.set("gaps", null);

      if (postStreamData) {
        // Load posts if present
        var store = this.store;
        postStreamData.posts.forEach(function (p) {
          return _this19.appendPost(store.createRecord("post", p));
        });
        delete postStreamData.posts; // Update our attributes

        this.setProperties(postStreamData);
      }
    },

    /**
      Stores a post in our identity map, and sets up the references it needs to
      find associated objects like the topic. It might return a different reference
      than you supplied if the post has already been loaded.
    **/
    storePost: function storePost(post) {
      // Calling `get(undefined)` raises an error
      if (!post) {
        return;
      }

      var postId = Ember.get(post, "id");

      if (postId) {
        var existing = this._identityMap[post.get("id")]; // Update the `highest_post_number` if this post is higher.


        var postNumber = post.get("post_number");

        if (postNumber && postNumber > (this.get("topic.highest_post_number") || 0)) {
          this.set("topic.highest_post_number", postNumber);
          this.set("topic.last_posted_at", post.get("created_at"));
        }

        if (existing) {
          // If the post is in the identity map, update it and return the old reference.
          existing.updateFromPost(post);
          return existing;
        }

        post.set("topic", this.topic);
        this._identityMap[post.get("id")] = post;
      }

      return post;
    },
    fetchNextWindow: function fetchNextWindow(postNumber, asc, callback) {
      var _this20 = this;

      var includeSuggested = !this.get("topic.suggested_topics");
      var url = "/t/".concat(this.get("topic.id"), "/posts.json");
      var data = {
        post_number: postNumber,
        asc: asc,
        include_suggested: includeSuggested
      };
      data = (0, _object.deepMerge)(data, this.streamFilters);
      var store = this.store;
      return (0, _ajax.ajax)(url, {
        data: data
      }).then(function (result) {
        _this20._setSuggestedTopics(result);

        var posts = Ember.get(result, "post_stream.posts");

        if (posts) {
          posts.forEach(function (p) {
            p = _this20.storePost(store.createRecord("post", p));

            if (callback) {
              callback.call(_this20, p);
            }
          });
        }
      });
    },
    findPostsByIds: function findPostsByIds(postIds, opts) {
      var identityMap = this._identityMap;
      var unloaded = postIds.filter(function (p) {
        return !identityMap[p];
      }); // Load our unloaded posts by id

      return this.loadIntoIdentityMap(unloaded, opts).then(function () {
        return postIds.map(function (p) {
          return identityMap[p];
        }).compact();
      });
    },
    loadIntoIdentityMap: function loadIntoIdentityMap(postIds, opts) {
      var _this21 = this;

      if (Ember.isEmpty(postIds)) {
        return Ember.RSVP.Promise.resolve([]);
      }

      var includeSuggested = !this.get("topic.suggested_topics");
      var url = "/t/" + this.get("topic.id") + "/posts.json";
      var data = {
        post_ids: postIds,
        include_suggested: includeSuggested
      };
      var store = this.store;
      var headers = {};

      if (opts && opts.background) {
        headers["Discourse-Background"] = "true";
      }

      return (0, _ajax.ajax)(url, {
        data: data,
        headers: headers
      }).then(function (result) {
        _this21._setSuggestedTopics(result);

        var posts = Ember.get(result, "post_stream.posts");

        if (posts) {
          posts.forEach(function (p) {
            return _this21.storePost(store.createRecord("post", p));
          });
        }
      });
    },
    backfillExcerpts: function backfillExcerpts(streamPosition) {
      var _this22 = this;

      this._excerpts = this._excerpts || [];
      var stream = this.stream;
      this._excerpts.loadNext = streamPosition;

      if (this._excerpts.loading) {
        return this._excerpts.loading.then(function () {
          if (!_this22._excerpts[stream[streamPosition]]) {
            if (_this22._excerpts.loadNext === streamPosition) {
              return _this22.backfillExcerpts(streamPosition);
            }
          }
        });
      }

      var postIds = stream.slice(Math.max(streamPosition - 20, 0), streamPosition + 20);

      for (var i = postIds.length - 1; i >= 0; i--) {
        if (this._excerpts[postIds[i]]) {
          postIds.splice(i, 1);
        }
      }

      var data = {
        post_ids: postIds
      };
      this._excerpts.loading = (0, _ajax.ajax)("/t/" + this.get("topic.id") + "/excerpts.json", {
        data: data
      }).then(function (excerpts) {
        excerpts.forEach(function (obj) {
          _this22._excerpts[obj.post_id] = obj;
        });
      }).finally(function () {
        _this22._excerpts.loading = null;
      });
      return this._excerpts.loading;
    },
    excerpt: function excerpt(streamPosition) {
      var _this23 = this;

      if (this.isMegaTopic) {
        return new Ember.RSVP.Promise(function (resolve) {
          return resolve("");
        });
      }

      var stream = this.stream;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var excerpt = _this23._excerpts && _this23._excerpts[stream[streamPosition]];

        if (excerpt) {
          resolve(excerpt);
          return;
        }

        _this23.backfillExcerpts(streamPosition).then(function () {
          resolve(_this23._excerpts[stream[streamPosition]]);
        }).catch(function (e) {
          return reject(e);
        });
      });
    },
    indexOf: function indexOf(post) {
      return this.stream.indexOf(post.get("id"));
    },
    // Handles an error loading a topic based on a HTTP status code. Updates
    // the text to the correct values.
    errorLoading: function errorLoading(error) {
      var topic = this.topic;
      this.set("loadingFilter", false);
      topic.set("errorLoading", true);

      if (!error.jqXHR) {
        throw error;
      }

      var json = error.jqXHR.responseJSON;

      if (json && json.extras && json.extras.html) {
        topic.set("errorHtml", json.extras.html);
      } else {
        topic.set("errorMessage", _I18n.default.t("topic.server_error.description"));
        topic.set("noRetry", error.jqXHR.status === 403);
      }
    },
    _checkIfShouldShowRevisions: function _checkIfShouldShowRevisions() {
      var _this24 = this;

      if (_lastEditNotificationClick) {
        var copy = _lastEditNotificationClick;
        resetLastEditNotificationClick();
        var postsNumbers = this.posts.mapBy("post_number");

        if (copy.topicId === this.topic.id && postsNumbers.includes(copy.postNumber)) {
          Ember.run.schedule("afterRender", function () {
            _this24.appEvents.trigger("post:show-revision", copy.postNumber, copy.revisionNumber);
          });
        }
      }
    },
    _setSuggestedTopics: function _setSuggestedTopics(result) {
      if (!result.suggested_topics) {
        return;
      }

      this.topic.setProperties({
        suggested_topics: result.suggested_topics,
        suggested_group_name: result.suggested_group_name
      });

      if (this.topic.isPrivateMessage) {
        this.pmTopicTrackingState.startTracking();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "filteredPostsCount", [_dec], Object.getOwnPropertyDescriptor(_obj, "filteredPostsCount"), _obj), _applyDecoratedDescriptor(_obj, "hasPosts", [_dec2], Object.getOwnPropertyDescriptor(_obj, "hasPosts"), _obj), _applyDecoratedDescriptor(_obj, "hasLoadedData", [_dec3], Object.getOwnPropertyDescriptor(_obj, "hasLoadedData"), _obj), _applyDecoratedDescriptor(_obj, "firstPostPresent", [_dec4], Object.getOwnPropertyDescriptor(_obj, "firstPostPresent"), _obj), _applyDecoratedDescriptor(_obj, "lastPostId", [_dec5], Object.getOwnPropertyDescriptor(_obj, "lastPostId"), _obj), _applyDecoratedDescriptor(_obj, "loadedAllPosts", [_dec6], Object.getOwnPropertyDescriptor(_obj, "loadedAllPosts"), _obj), _applyDecoratedDescriptor(_obj, "streamFilters", [_dec7], Object.getOwnPropertyDescriptor(_obj, "streamFilters"), _obj), _applyDecoratedDescriptor(_obj, "hasNoFilters", [_dec8], Object.getOwnPropertyDescriptor(_obj, "hasNoFilters"), _obj), _applyDecoratedDescriptor(_obj, "previousWindow", [_dec9], Object.getOwnPropertyDescriptor(_obj, "previousWindow"), _obj), _applyDecoratedDescriptor(_obj, "nextWindow", [_dec10], Object.getOwnPropertyDescriptor(_obj, "nextWindow"), _obj)), _obj)));

  _exports.default = _default;
});