define("discourse/widgets/group-message-summary-notification-item", ["discourse/widgets/default-notification-item", "I18n", "discourse/widgets/widget"], function (_defaultNotificationItem, _I18n, _widget) {
  "use strict";

  (0, _widget.createWidgetFrom)(_defaultNotificationItem.DefaultNotificationItem, "group-message-summary-notification-item", {
    text: function text(notificationName, data) {
      var count = data.inbox_count;
      var group_name = data.group_name;
      return _I18n.default.t("notifications.group_message_summary", {
        count: count,
        group_name: group_name
      });
    }
  });
});