define("discourse/models/draft", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Draft = Ember.Object.extend();
  Draft.reopenClass({
    clear: function clear(key, sequence) {
      return (0, _ajax.ajax)("/drafts/".concat(key, ".json"), {
        type: "DELETE",
        data: {
          draft_key: key,
          sequence: sequence
        }
      });
    },
    get: function get(key) {
      return (0, _ajax.ajax)("/drafts/".concat(key, ".json"));
    },
    getLocal: function getLocal(key, current) {
      // TODO: implement this
      return current;
    },
    save: function save(key, sequence, data, clientId) {
      var _ref = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {},
          _ref$forceSave = _ref.forceSave,
          forceSave = _ref$forceSave === void 0 ? false : _ref$forceSave;

      data = typeof data === "string" ? data : JSON.stringify(data);
      return (0, _ajax.ajax)("/drafts.json", {
        type: "POST",
        data: {
          draft_key: key,
          sequence: sequence,
          data: data,
          owner: clientId,
          force_save: forceSave
        },
        ignoreUnsent: false
      });
    }
  });
  var _default = Draft;
  _exports.default = _default;
});