define("discourse/components/time-shortcut-picker", ["exports", "discourse/lib/time-utils", "discourse/lib/time-shortcut", "discourse-common/utils/decorators", "I18n"], function (_exports, _timeUtils, _timeShortcut, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var BINDINGS = {
    "l t": {
      handler: "selectShortcut",
      args: [_timeShortcut.TIME_SHORTCUT_TYPES.LATER_TODAY]
    },
    "l w": {
      handler: "selectShortcut",
      args: [_timeShortcut.TIME_SHORTCUT_TYPES.LATER_THIS_WEEK]
    },
    "n d": {
      handler: "selectShortcut",
      args: [_timeShortcut.TIME_SHORTCUT_TYPES.TOMORROW]
    },
    "n b w": {
      handler: "selectShortcut",
      args: [_timeShortcut.TIME_SHORTCUT_TYPES.START_OF_NEXT_BUSINESS_WEEK]
    },
    "n m": {
      handler: "selectShortcut",
      args: [_timeShortcut.TIME_SHORTCUT_TYPES.NEXT_MONTH]
    },
    "c r": {
      handler: "selectShortcut",
      args: [_timeShortcut.TIME_SHORTCUT_TYPES.CUSTOM]
    },
    "n r": {
      handler: "selectShortcut",
      args: [_timeShortcut.TIME_SHORTCUT_TYPES.NONE]
    }
  };

  var _default = Ember.Component.extend((_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.observes)("prefilledDatetime"), _dec3 = (0, _decorators.observes)("customDate", "customTime"), _dec4 = (0, _decorators.default)("hiddenOptions", "customOptions", "customLabels", "userTimezone"), _dec5 = Ember._action, _dec6 = Ember._action, (_obj = {
    tagName: "",
    userTimezone: null,
    onTimeSelected: null,
    selectedShortcut: null,
    selectedTime: null,
    selectedDate: null,
    selectedDatetime: null,
    prefilledDatetime: null,
    hiddenOptions: null,
    customOptions: null,
    lastCustomDate: null,
    lastCustomTime: null,
    parsedLastCustomDatetime: null,
    customDate: null,
    customTime: null,
    _itsatrap: null,
    defaultCustomReminderTime: "0".concat(_timeUtils.START_OF_DAY_HOUR, ":00"),
    _setupPicker: function _setupPicker() {
      this.setProperties({
        customTime: this.defaultCustomReminderTime,
        userTimezone: this.currentUser.resolvedTimezone(this.currentUser),
        hiddenOptions: this.hiddenOptions || [],
        customOptions: this.customOptions || [],
        customLabels: this.customLabels || {}
      });

      if (this.prefilledDatetime) {
        this.parsePrefilledDatetime();
      }

      this._bindKeyboardShortcuts();
    },
    prefilledDatetimeChanged: function prefilledDatetimeChanged() {
      if (this.prefilledDatetime) {
        this.parsePrefilledDatetime();
      } else {
        this.setProperties({
          customDate: null,
          customTime: null,
          selectedShortcut: null
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._itsatrap.unbind(Object.keys(BINDINGS));
    },
    parsePrefilledDatetime: function parsePrefilledDatetime() {
      var parsedDatetime = (0, _timeUtils.parseCustomDatetime)(this.prefilledDatetime, null, this.userTimezone);

      if (parsedDatetime.isSame((0, _timeUtils.laterToday)())) {
        return this.set("selectedShortcut", _timeShortcut.TIME_SHORTCUT_TYPES.LATER_TODAY);
      }

      this.setProperties({
        customDate: parsedDatetime.format("YYYY-MM-DD"),
        customTime: parsedDatetime.format("HH:mm"),
        selectedShortcut: _timeShortcut.TIME_SHORTCUT_TYPES.CUSTOM
      });
    },
    _loadLastUsedCustomDatetime: function _loadLastUsedCustomDatetime() {
      var lastTime = localStorage.lastCustomTime;
      var lastDate = localStorage.lastCustomDate;

      if (lastTime && lastDate) {
        var parsed = (0, _timeUtils.parseCustomDatetime)(lastDate, lastTime, this.userTimezone);

        if (!parsed.isValid() || parsed < (0, _timeUtils.now)(this.userTimezone)) {
          return;
        }

        this.setProperties({
          lastCustomDate: lastDate,
          lastCustomTime: lastTime,
          parsedLastCustomDatetime: parsed
        });
      }
    },
    _bindKeyboardShortcuts: function _bindKeyboardShortcuts() {
      var _this = this;

      Object.keys(BINDINGS).forEach(function (shortcut) {
        _this._itsatrap.bind(shortcut, function () {
          var binding = BINDINGS[shortcut];

          _this.send.apply(_this, [binding.handler].concat(_toConsumableArray(binding.args)));

          return false;
        });
      });
    },
    customDatetimeSelected: Ember.computed.equal("selectedShortcut", _timeShortcut.TIME_SHORTCUT_TYPES.CUSTOM),
    relativeTimeSelected: Ember.computed.equal("selectedShortcut", _timeShortcut.TIME_SHORTCUT_TYPES.RELATIVE),
    customDatetimeFilled: Ember.computed.and("customDate", "customTime"),
    customDatetimeChanged: function customDatetimeChanged() {
      if (!this.customDatetimeFilled) {
        return;
      }

      this.selectShortcut(_timeShortcut.TIME_SHORTCUT_TYPES.CUSTOM);
    },
    options: function options(hiddenOptions, customOptions, customLabels, userTimezone) {
      this._loadLastUsedCustomDatetime();

      var options = (0, _timeShortcut.defaultShortcutOptions)(userTimezone);

      this._hideDynamicOptions(options);

      options = options.concat(customOptions);
      options.sort(function (a, b) {
        if (a.time < b.time) {
          return -1;
        }

        if (a.time > b.time) {
          return 1;
        }

        return 0;
      });
      var specialOptions = (0, _timeShortcut.specialShortcutOptions)();

      if (this.lastCustomDate && this.lastCustomTime) {
        var lastCustom = specialOptions.findBy("id", _timeShortcut.TIME_SHORTCUT_TYPES.LAST_CUSTOM);
        lastCustom.time = this.parsedLastCustomDatetime;
        lastCustom.timeFormatKey = "dates.long_no_year";
        lastCustom.hidden = false;
      }

      options = options.concat(specialOptions);

      if (hiddenOptions.length > 0) {
        options.forEach(function (opt) {
          if (hiddenOptions.includes(opt.id)) {
            opt.hidden = true;
          }
        });
      }

      this._applyCustomLabels(options, customLabels);

      this._formatTime(options);

      return options;
    },
    relativeTimeChanged: function relativeTimeChanged(relativeTimeMins) {
      var dateTime = (0, _timeUtils.now)(this.userTimezone).add(relativeTimeMins, "minutes");
      this.set("selectedDatetime", dateTime);

      if (this.onTimeSelected) {
        this.onTimeSelected(_timeShortcut.TIME_SHORTCUT_TYPES.RELATIVE, dateTime);
      }
    },
    selectShortcut: function selectShortcut(type) {
      if (this.options.filterBy("hidden").mapBy("id").includes(type)) {
        return;
      }

      var dateTime = null;

      if (type === _timeShortcut.TIME_SHORTCUT_TYPES.CUSTOM) {
        this.set("customTime", this.customTime || this.defaultCustomReminderTime);
        var customDatetime = (0, _timeUtils.parseCustomDatetime)(this.customDate, this.customTime, this.userTimezone);

        if (customDatetime.isValid() && this.customDate) {
          dateTime = customDatetime;
          localStorage.lastCustomTime = this.customTime;
          localStorage.lastCustomDate = this.customDate;
        }
      } else {
        dateTime = this.options.findBy("id", type).time;
      }

      this.setProperties({
        selectedShortcut: type,
        selectedDatetime: dateTime
      });

      if (this.onTimeSelected) {
        this.onTimeSelected(type, dateTime);
      }
    },
    _applyCustomLabels: function _applyCustomLabels(options, customLabels) {
      options.forEach(function (option) {
        if (customLabels[option.id]) {
          option.label = customLabels[option.id];
        }
      });
    },
    _formatTime: function _formatTime(options) {
      options.forEach(function (option) {
        if (option.time && option.timeFormatKey) {
          option.timeFormatted = option.time.format(_I18n.default.t(option.timeFormatKey));
        }
      });
    },
    _hideDynamicOptions: function _hideDynamicOptions(options) {
      if ((0, _timeUtils.now)(this.userTimezone).hour() >= _timeUtils.LATER_TODAY_CUTOFF_HOUR) {
        this._hideOption(options, _timeShortcut.TIME_SHORTCUT_TYPES.LATER_TODAY);
      }

      if ((0, _timeUtils.now)(this.userTimezone).day() >= _timeUtils.MOMENT_THURSDAY) {
        this._hideOption(options, _timeShortcut.TIME_SHORTCUT_TYPES.LATER_THIS_WEEK);
      }

      if ((0, _timeUtils.now)(this.userTimezone).day() >= _timeUtils.MOMENT_FRIDAY) {
        this._hideOption(options, _timeShortcut.TIME_SHORTCUT_TYPES.THIS_WEEKEND);
      }
    },
    _hideOption: function _hideOption(options, optionId) {
      var option = options.findBy("id", optionId);
      option.hidden = true;
    }
  }, (_applyDecoratedDescriptor(_obj, "_setupPicker", [_dec], Object.getOwnPropertyDescriptor(_obj, "_setupPicker"), _obj), _applyDecoratedDescriptor(_obj, "prefilledDatetimeChanged", [_dec2], Object.getOwnPropertyDescriptor(_obj, "prefilledDatetimeChanged"), _obj), _applyDecoratedDescriptor(_obj, "customDatetimeChanged", [_dec3], Object.getOwnPropertyDescriptor(_obj, "customDatetimeChanged"), _obj), _applyDecoratedDescriptor(_obj, "options", [_dec4], Object.getOwnPropertyDescriptor(_obj, "options"), _obj), _applyDecoratedDescriptor(_obj, "relativeTimeChanged", [_dec5], Object.getOwnPropertyDescriptor(_obj, "relativeTimeChanged"), _obj), _applyDecoratedDescriptor(_obj, "selectShortcut", [_dec6], Object.getOwnPropertyDescriptor(_obj, "selectShortcut"), _obj)), _obj)));

  _exports.default = _default;
});