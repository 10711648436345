define("discourse/helpers/decorate-username-selector", ["exports", "discourse-common/lib/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addUsernameSelectorDecorator = addUsernameSelectorDecorator;
  _exports.resetUsernameDecorators = resetUsernameDecorators;
  _exports.default = void 0;
  var usernameDecorators = [];

  function addUsernameSelectorDecorator(decorator) {
    usernameDecorators.push(decorator);
  }

  function resetUsernameDecorators() {
    usernameDecorators = [];
  }

  var _default = (0, _helpers.registerUnbound)("decorate-username-selector", function (username) {
    var decorations = [];
    usernameDecorators.forEach(function (decorator) {
      decorations.push(decorator(username));
    });
    return decorations.length ? Ember.String.htmlSafe(decorations.join("")) : "";
  });

  _exports.default = _default;
});