define("discourse/templates/components/activation-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/rTvzJJp",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"siteSettings\",\"must_approve_users\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"d-button\",null,[[\"action\",\"label\",\"icon\",\"class\"],[[24,[\"sendActivationEmail\"]],\"login.resend_title\",\"envelope\",\"btn-primary resend\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"canEditEmail\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"d-button\",null,[[\"action\",\"label\",\"icon\",\"class\"],[[24,[\"editActivationEmail\"]],\"login.change_email\",\"pencil-alt\",\"edit-email\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/activation-controls.hbs"
    }
  });

  _exports.default = _default;
});