define("discourse/models/login-method", ["exports", "I18n", "discourse/models/session", "discourse/models/site", "discourse-common/utils/decorators", "discourse-common/lib/get-url", "discourse/lib/ajax"], function (_exports, _I18n, _session, _site, _decorators, _getUrl, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findAll = findAll;
  _exports.clearAuthMethods = clearAuthMethods;
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var LoginMethod = Ember.Object.extend((_obj = {
    title: function title() {
      return this.title_override || _I18n.default.t("login.".concat(this.name, ".title"));
    },
    screenReaderTitle: function screenReaderTitle() {
      return this.title_override || _I18n.default.t("login.".concat(this.name, ".sr_title"), {
        defaultValue: this.title
      });
    },
    prettyName: function prettyName() {
      return this.pretty_name_override || _I18n.default.t("login.".concat(this.name, ".name"));
    },
    doLogin: function doLogin() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$reconnect = _ref.reconnect,
          reconnect = _ref$reconnect === void 0 ? false : _ref$reconnect,
          _ref$signup = _ref.signup,
          signup = _ref$signup === void 0 ? false : _ref$signup,
          _ref$params = _ref.params,
          params = _ref$params === void 0 ? {} : _ref$params;

      if (this.customLogin) {
        this.customLogin();
        return Ember.RSVP.Promise.resolve();
      }

      if (this.custom_url) {
        window.location = this.custom_url;
        return Ember.RSVP.Promise.resolve();
      }

      var authUrl = (0, _getUrl.default)("/auth/".concat(this.name));

      if (reconnect) {
        params["reconnect"] = true;
      }

      if (signup) {
        params["signup"] = true;
      }

      var paramKeys = Object.keys(params);

      if (paramKeys.length > 0) {
        authUrl += "?";
        authUrl += paramKeys.map(function (k) {
          return "".concat(encodeURIComponent(k), "=").concat(encodeURIComponent(params[k]));
        }).join("&");
      }

      return LoginMethod.buildPostForm(authUrl).then(function (form) {
        return form.submit();
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "title", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "screenReaderTitle", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "screenReaderTitle"), _obj), _applyDecoratedDescriptor(_obj, "prettyName", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "prettyName"), _obj)), _obj));
  LoginMethod.reopenClass({
    buildPostForm: function buildPostForm(url) {
      // Login always happens in an anonymous context, with no CSRF token
      // So we need to fetch it before sending a POST request
      return (0, _ajax.updateCsrfToken)().then(function () {
        var form = document.createElement("form");
        form.setAttribute("style", "display:none;");
        form.setAttribute("method", "post");
        form.setAttribute("action", url);
        var input = document.createElement("input");
        input.setAttribute("name", "authenticity_token");
        input.setAttribute("value", _session.default.currentProp("csrfToken"));
        form.appendChild(input);
        document.body.appendChild(form);
        return form;
      });
    }
  });
  var methods;

  function findAll() {
    if (methods) {
      return methods;
    }

    methods = _site.default.currentProp("auth_providers").map(function (provider) {
      return LoginMethod.create(provider);
    }); // exclude FA icon for Google, uses custom SVG

    methods.forEach(function (m) {
      return m.set("isGoogle", m.name === "google_oauth2");
    });
    return methods;
  }

  function clearAuthMethods() {
    methods = undefined;
  }

  var _default = LoginMethod;
  _exports.default = _default;
});