define("discourse/templates/user/badges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o7FpBT9c",
    "block": "{\"symbols\":[\"ub\"],\"statements\":[[4,\"d-section\",null,[[\"pageClass\",\"class\"],[\"user-badges\",\"user-content\"]],{\"statements\":[[0,\"  \"],[7,\"p\",true],[10,\"class\",\"favorite-count\"],[8],[0,\"\\n    \"],[1,[28,\"i18n\",[\"badges.favorite_count\"],[[\"count\",\"max\"],[[23,0,[\"favoriteBadges\",\"length\"]],[24,[\"siteSettings\",\"max_favorite_badges\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"badge-group-list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"sortedBadges\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"badge-card\",null,[[\"badge\",\"count\",\"canFavorite\",\"isFavorite\",\"username\",\"canFavoriteMoreBadges\",\"onFavoriteClick\",\"filterUser\"],[[23,1,[\"badge\"]],[23,1,[\"count\"]],[23,1,[\"can_favorite\"]],[23,1,[\"is_favorite\"]],[24,[\"username\"]],[24,[\"canFavoriteMoreBadges\"]],[28,\"action\",[[23,0,[]],\"favorite\",[23,1,[]]],null],\"true\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/user/badges.hbs"
    }
  });

  _exports.default = _default;
});