define("discourse/templates/components/edit-category-images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "368YThpi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"field category-logo\"],[8],[0,\"\\n  \"],[7,\"label\",true],[8],[1,[28,\"i18n\",[\"category.logo\"],null],false],[9],[0,\"\\n  \"],[1,[28,\"uppy-image-uploader\",null,[[\"imageUrl\",\"onUploadDone\",\"onUploadDeleted\",\"type\",\"class\",\"id\"],[[24,[\"logoImageUrl\"]],[28,\"action\",[[23,0,[]],\"logoUploadDone\"],null],[28,\"action\",[[23,0,[]],\"logoUploadDeleted\"],null],\"category_logo\",\"no-repeat contain-image\",\"category-logo-uploader\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"section\",true],[10,\"class\",\"field category-background-image\"],[8],[0,\"\\n  \"],[7,\"label\",true],[8],[1,[28,\"i18n\",[\"category.background_image\"],null],false],[9],[0,\"\\n  \"],[1,[28,\"uppy-image-uploader\",null,[[\"imageUrl\",\"onUploadDone\",\"onUploadDeleted\",\"type\",\"id\"],[[24,[\"backgroundImageUrl\"]],[28,\"action\",[[23,0,[]],\"backgroundUploadDone\"],null],[28,\"action\",[[23,0,[]],\"backgroundUploadDeleted\"],null],\"category_background\",\"category-background-uploader\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/edit-category-images.hbs"
    }
  });

  _exports.default = _default;
});