define("discourse/templates/components/search-result-entries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Qgb5O30",
    "block": "{\"symbols\":[\"post\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"fps-result-entries\"],[10,\"role\",\"list\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"posts\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"search-result-entry\",null,[[\"post\",\"bulkSelectEnabled\",\"selected\",\"highlightQuery\"],[[23,1,[]],[24,[\"bulkSelectEnabled\"]],[24,[\"selected\"]],[24,[\"highlightQuery\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/search-result-entries.hbs"
    }
  });

  _exports.default = _default;
});