define("discourse/controllers/reorder-categories", ["exports", "discourse-common/utils/decorators", "ember-buffered-proxy/proxy", "discourse/mixins/modal-functionality", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _decorators, _proxy, _modalFunctionality, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, Ember.Evented, (_dec = (0, _decorators.default)("site.categories.[]"), _dec2 = (0, _decorators.on)("init"), (_obj = {
    init: function init() {
      this._super.apply(this, arguments);

      this.categoriesSorting = ["position"];
    },
    categoriesBuffered: function categoriesBuffered(categories) {
      return (categories || []).map(function (c) {
        return _proxy.default.create({
          content: c
        });
      });
    },
    categoriesOrdered: Ember.computed.sort("categoriesBuffered", "categoriesSorting"),
    reorder: function reorder() {
      var _this = this;

      var reorderChildren = function reorderChildren(categoryId, depth, index) {
        _this.categoriesOrdered.forEach(function (category) {
          if (categoryId === null && !category.get("parent_category_id") || category.get("parent_category_id") === categoryId) {
            category.setProperties({
              depth: depth,
              position: index++
            });
            index = reorderChildren(category.get("id"), depth + 1, index);
          }
        });

        return index;
      };

      reorderChildren(null, 0, 0);
      this.categoriesBuffered.forEach(function (bc) {
        if (bc.get("hasBufferedChanges")) {
          bc.applyBufferedChanges();
        }
      });
      this.notifyPropertyChange("categoriesBuffered");
    },
    countDescendants: function countDescendants(category) {
      var _this2 = this;

      return category.get("subcategories") ? category.get("subcategories").reduce(function (count, subcategory) {
        return count + _this2.countDescendants(subcategory);
      }, category.get("subcategories").length) : 0;
    },
    move: function move(category, direction) {
      var targetPosition = category.get("position") + direction; // Adjust target position for sub-categories

      if (direction > 0) {
        // Moving down (position gets larger)
        if (category.get("isParent")) {
          // This category has subcategories, adjust targetPosition to account for them
          var offset = this.countDescendants(category);

          if (direction <= offset) {
            // Only apply offset if target position is occupied by a subcategory
            // Seems weird but fixes a UX quirk
            targetPosition += offset;
          }
        }
      } else {
        // Moving up (position gets smaller)
        var otherCategory = this.categoriesOrdered.find(function (c) {
          return (// find category currently at targetPosition
            c.get("position") === targetPosition
          );
        });

        if (otherCategory && otherCategory.get("ancestors")) {
          // Target category is a subcategory, adjust targetPosition to account for ancestors
          var highestAncestor = otherCategory.get("ancestors").reduce(function (current, min) {
            return current.get("position") < min.get("position") ? current : min;
          });
          targetPosition = highestAncestor.get("position");
        }
      } // Adjust target position for range bounds


      if (targetPosition >= this.categoriesOrdered.length) {
        // Set to max
        targetPosition = this.categoriesOrdered.length - 1;
      } else if (targetPosition < 0) {
        // Set to min
        targetPosition = 0;
      } // Update other categories between current and target position


      this.categoriesOrdered.map(function (c) {
        if (direction < 0) {
          // Moving up (position gets smaller)
          if (c.get("position") < category.get("position") && c.get("position") >= targetPosition) {
            var newPosition = c.get("position") + 1;
            c.set("position", newPosition);
          }
        } else {
          // Moving down (position gets larger)
          if (c.get("position") > category.get("position") && c.get("position") <= targetPosition) {
            var _newPosition = c.get("position") - 1;

            c.set("position", _newPosition);
          }
        }
      }); // Update this category's position to target position

      category.set("position", targetPosition);
      this.reorder();
    },
    actions: {
      change: function change(category, event) {
        var newPosition = parseFloat(event.target.value);
        newPosition = newPosition < category.get("position") ? Math.ceil(newPosition) : Math.floor(newPosition);
        var direction = newPosition - category.get("position");
        this.move(category, direction);
      },
      moveUp: function moveUp(category) {
        this.move(category, -1);
      },
      moveDown: function moveDown(category) {
        this.move(category, 1);
      },
      save: function save() {
        this.reorder();
        var data = {};
        this.categoriesBuffered.forEach(function (cat) {
          data[cat.get("id")] = cat.get("position");
        });
        (0, _ajax.ajax)("/categories/reorder", {
          type: "POST",
          data: {
            mapping: JSON.stringify(data)
          }
        }).then(function () {
          return window.location.reload();
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "categoriesBuffered", [_dec], Object.getOwnPropertyDescriptor(_obj, "categoriesBuffered"), _obj), _applyDecoratedDescriptor(_obj, "reorder", [_dec2], Object.getOwnPropertyDescriptor(_obj, "reorder"), _obj)), _obj)));

  _exports.default = _default;
});