define("discourse/app", ["exports", "discourse-common/resolver", "discourse-common/config/environment"], function (_exports, _resolver, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAndClearUnhandledThemeErrors = getAndClearUnhandledThemeErrors;
  _exports.default = void 0;
  var _pluginCallbacks = [];
  var _unhandledThemeErrors = [];
  var Discourse = Ember.Application.extend({
    rootElement: "#main",
    customEvents: {
      paste: "paste"
    },
    Resolver: (0, _resolver.buildResolver)("discourse"),
    _prepareInitializer: function _prepareInitializer(moduleName) {
      var themeId = moduleThemeId(moduleName);
      var module = null;

      try {
        module = requirejs(moduleName, null, null, true);

        if (!module) {
          throw new Error(moduleName + " must export an initializer.");
        }
      } catch (error) {
        if (!themeId || (0, _environment.isTesting)()) {
          throw error;
        }

        fireThemeErrorEvent({
          themeId: themeId,
          error: error
        });
        return;
      }

      var init = module.default;
      var oldInitialize = init.initialize;

      init.initialize = function (app) {
        try {
          return oldInitialize.call(init, app.__container__, app);
        } catch (error) {
          if (!themeId || (0, _environment.isTesting)()) {
            throw error;
          }

          fireThemeErrorEvent({
            themeId: themeId,
            error: error
          });
        }
      };

      return init;
    },
    // Start up the Discourse application by running all the initializers we've defined.
    start: function start() {
      var _document$querySelect,
          _this = this;

      (_document$querySelect = document.querySelector("noscript")) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.remove();
      Object.keys(requirejs._eak_seen).forEach(function (key) {
        if (/\/pre\-initializers\//.test(key)) {
          var initializer = _this._prepareInitializer(key);

          if (initializer) {
            _this.initializer(initializer);
          }
        } else if (/\/(api\-)?initializers\//.test(key)) {
          var _initializer = _this._prepareInitializer(key);

          if (_initializer) {
            _this.instanceInitializer(_initializer);
          }
        }
      }); // Plugins that are registered via `<script>` tags.

      var withPluginApi = requirejs("discourse/lib/plugin-api").withPluginApi;
      var initCount = 0;

      _pluginCallbacks.forEach(function (cb) {
        _this.instanceInitializer({
          name: "_discourse_plugin_".concat(++initCount),
          after: "inject-objects",
          initialize: function initialize() {
            return withPluginApi(cb.version, cb.code);
          }
        });
      });
    },
    _registerPluginCode: function _registerPluginCode(version, code) {
      _pluginCallbacks.push({
        version: version,
        code: code
      });
    }
  });

  function moduleThemeId(moduleName) {
    var match = moduleName.match(/^discourse\/theme\-(\d+)\//);

    if (match) {
      return parseInt(match[1], 10);
    }
  }

  function fireThemeErrorEvent(_ref) {
    var themeId = _ref.themeId,
        error = _ref.error;
    var event = new CustomEvent("discourse-error", {
      cancelable: true,
      detail: {
        themeId: themeId,
        error: error
      }
    });
    var unhandled = document.dispatchEvent(event);

    if (unhandled) {
      _unhandledThemeErrors.push(event);
    }
  }

  function getAndClearUnhandledThemeErrors() {
    var copy = _unhandledThemeErrors;
    _unhandledThemeErrors = [];
    return copy;
  }

  var _default = Discourse;
  _exports.default = _default;
});