define("discourse/pre-initializers/sniff-capabilities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Initializes an object that lets us know about browser's capabilities
  var APPLE_NAVIGATOR_PLATFORMS = /iPhone|iPod|iPad|Macintosh|MacIntel/;
  var APPLE_USERAGENTDATA_PLATFORM = /macOS/;
  var _default = {
    name: "sniff-capabilities",
    initialize: function initialize(_, app) {
      var _window$safari;

      var html = document.querySelector("html");
      var touch = navigator.maxTouchPoints > 1 || "ontouchstart" in window;
      var ua = navigator.userAgent;
      var caps = {
        touch: touch
      };

      if (touch) {
        html.classList.add("touch", "discourse-touch");
      } else {
        html.classList.add("no-touch", "discourse-no-touch");
      }

      caps.isAndroid = ua.includes("Android");
      caps.isWinphone = ua.includes("Windows Phone");
      caps.isOpera = !!window.opera || ua.includes(" OPR/");
      caps.isFirefox = typeof InstallTrigger !== "undefined";
      caps.isChrome = !!window.chrome && !caps.isOpera;
      caps.isSafari = /Constructor/.test(window.HTMLElement) || ((_window$safari = window.safari) === null || _window$safari === void 0 ? void 0 : _window$safari.pushNotification.toString()) === "[object SafariRemoteNotification]";
      caps.isIpadOS = ua.includes("Mac OS") && !/iPhone|iPod/.test(ua) && touch;
      caps.isIOS = (/iPhone|iPod/.test(navigator.userAgent) || caps.isIpadOS) && !window.MSStream;
      caps.isApple = APPLE_NAVIGATOR_PLATFORMS.test(navigator.platform) || navigator.userAgentData && APPLE_USERAGENTDATA_PLATFORM.test(navigator.userAgentData.platform);
      caps.hasContactPicker = "contacts" in navigator && "ContactsManager" in window;
      caps.canVibrate = "vibrate" in navigator;
      caps.isPwa = window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone || document.referrer.includes("android-app://"); // Inject it

      app.register("capabilities:main", caps, {
        instantiate: false
      });
      app.inject("view", "capabilities", "capabilities:main");
      app.inject("controller", "capabilities", "capabilities:main");
      app.inject("component", "capabilities", "capabilities:main");
    }
  };
  _exports.default = _default;
});