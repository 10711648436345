define("discourse/components/discourse-root", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var componentArgs = {
    tagName: "div"
  }; // TODO: Once we've moved to Ember CLI completely we can remove this.

  if (!Ember.FEATURES.EMBER_GLIMMER_SET_COMPONENT_TEMPLATE) {
    componentArgs = {
      tagName: ""
    };
  }

  var _default = Ember.Component.extend(componentArgs);

  _exports.default = _default;
});