define("discourse/templates/composer/get-a-room", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FXfLgO3a",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"close\"],[3,\"action\",[[23,0,[]],[24,[\"closeMessage\"]],[24,[\"message\"]]]],[8],[1,[28,\"d-icon\",[\"times\"],null],false],[9],[0,\"\\n\\n\"],[1,[28,\"html-safe\",[[24,[\"message\",\"body\"]]],null],false],[0,\"\\n\\n\"],[1,[28,\"d-button\",null,[[\"class\",\"label\",\"icon\",\"action\"],[\"btn-primary\",\"user.private_message\",\"envelope\",[28,\"action\",[[23,0,[]],[24,[\"switchPM\"]],[24,[\"message\"]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/composer/get-a-room.hbs"
    }
  });

  _exports.default = _default;
});