define("discourse/helpers/category-link", ["exports", "discourse-common/lib/helpers", "discourse/models/category", "I18n", "discourse/models/site", "discourse/lib/utilities", "discourse-common/lib/get-url", "discourse-common/lib/icon-library", "discourse/lib/text-direction"], function (_exports, _helpers, _category, _I18n, _site, _utilities, _getUrl, _iconLibrary, _textDirection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.replaceCategoryLinkRenderer = replaceCategoryLinkRenderer;
  _exports.addExtraIconRenderer = addExtraIconRenderer;
  _exports.categoryBadgeHTML = categoryBadgeHTML;
  _exports.categoryLinkHTML = categoryLinkHTML;
  var _renderer = defaultCategoryLinkRenderer;

  function replaceCategoryLinkRenderer(fn) {
    _renderer = fn;
  }

  function categoryStripe(color, classes) {
    var style = color ? "style='background-color: #" + color + ";'" : "";
    return "<span class='" + classes + "' " + style + "></span>";
  }

  var _extraIconRenderers = [];

  function addExtraIconRenderer(renderer) {
    _extraIconRenderers.push(renderer);
  }
  /**
    Generates category badge HTML
  
    @param {Object} category The category to generate the badge for.
    @param {Object} opts
      @param {String}  [opts.url] The url that we want the category badge to link to.
      @param {Boolean} [opts.allowUncategorized] If false, returns an empty string for the uncategorized category.
      @param {Boolean} [opts.link] If false, the category badge will not be a link.
      @param {Boolean} [opts.hideParent] If true, parent category will be hidden in the badge.
      @param {Boolean} [opts.recursive] If true, the function will be called recursively for all parent categories
      @param {Number}  [opts.depth] Current category depth, used for limiting recursive calls
  **/


  function categoryBadgeHTML(category, opts) {
    var siteSettings = (0, _helpers.helperContext)().siteSettings;
    opts = opts || {};

    if (!category || !opts.allowUncategorized && Ember.get(category, "id") === _site.default.currentProp("uncategorized_category_id") && siteSettings.suppress_uncategorized_badge) {
      return "";
    }

    var depth = (opts.depth || 1) + 1;

    if (opts.recursive && depth <= siteSettings.max_category_nesting) {
      var parentCategory = _category.default.findById(category.parent_category_id);

      var lastSubcategory = !opts.depth;
      opts.depth = depth;
      var parentBadges = categoryBadgeHTML(parentCategory, opts);
      opts.lastSubcategory = lastSubcategory;
      return parentBadges + _renderer(category, opts);
    }

    return _renderer(category, opts);
  }

  function categoryLinkHTML(category, options) {
    var categoryOptions = {}; // TODO: This is a compatibility layer with the old helper structure.
    // Can be removed once we migrate to `registerUnbound` fully

    if (options && options.hash) {
      options = options.hash;
    }

    if (options) {
      if (options.allowUncategorized) {
        categoryOptions.allowUncategorized = true;
      }

      if (options.link !== undefined) {
        categoryOptions.link = options.link;
      }

      if (options.extraClasses) {
        categoryOptions.extraClasses = options.extraClasses;
      }

      if (options.hideParent) {
        categoryOptions.hideParent = true;
      }

      if (options.categoryStyle) {
        categoryOptions.categoryStyle = options.categoryStyle;
      }

      if (options.recursive) {
        categoryOptions.recursive = true;
      }
    }

    return Ember.String.htmlSafe(categoryBadgeHTML(category, categoryOptions));
  }

  (0, _helpers.registerUnbound)("category-link", categoryLinkHTML);

  function buildTopicCount(count) {
    return "<span class=\"topic-count\" aria-label=\"".concat(_I18n.default.t("category_row.topic_count", {
      count: count
    }), "\">&times; ").concat(count, "</span>");
  }

  function defaultCategoryLinkRenderer(category, opts) {
    var descriptionText = Ember.get(category, "description_text");
    var restricted = Ember.get(category, "read_restricted");
    var url = opts.url ? opts.url : (0, _getUrl.default)("/c/".concat(_category.default.slugFor(category), "/").concat(Ember.get(category, "id")));
    var href = opts.link === false ? "" : url;
    var tagName = opts.link === false || opts.link === "false" ? "span" : "a";
    var extraClasses = opts.extraClasses ? " " + opts.extraClasses : "";
    var color = Ember.get(category, "color");
    var html = "";
    var parentCat = null;
    var categoryDir = "";

    if (!opts.hideParent) {
      parentCat = _category.default.findById(Ember.get(category, "parent_category_id"));
    }

    var siteSettings = (0, _helpers.helperContext)().siteSettings;
    var categoryStyle = opts.categoryStyle || siteSettings.category_style;

    if (categoryStyle !== "none") {
      if (parentCat && parentCat !== category) {
        html += categoryStripe(Ember.get(parentCat, "color"), "badge-category-parent-bg");
      }

      html += categoryStripe(color, "badge-category-bg");
    }

    var classNames = "badge-category clear-badge";

    if (restricted) {
      classNames += " restricted";
    }

    var style = "";

    if (categoryStyle === "box") {
      style = "style=\"color: #".concat(Ember.get(category, "text_color"), ";\"");
    }

    html += "<span ".concat(style, " ") + 'data-drop-close="true" class="' + classNames + '"' + (descriptionText ? 'title="' + descriptionText + '" ' : "") + ">";
    var categoryName = (0, _utilities.escapeExpression)(Ember.get(category, "name"));

    if (siteSettings.support_mixed_text_direction) {
      categoryDir = (0, _textDirection.isRTL)(categoryName) ? 'dir="rtl"' : 'dir="ltr"';
    }

    if (restricted) {
      html += (0, _iconLibrary.iconHTML)("lock");
    }

    _extraIconRenderers.forEach(function (renderer) {
      var iconName = renderer(category);

      if (iconName) {
        html += (0, _iconLibrary.iconHTML)(iconName);
      }
    });

    html += "<span class=\"category-name\" ".concat(categoryDir, ">").concat(categoryName, "</span>");
    html += "</span>";

    if (opts.topicCount && categoryStyle !== "box") {
      html += buildTopicCount(opts.topicCount);
    }

    if (href) {
      href = " href=\"".concat(href, "\" ");
    }

    extraClasses = categoryStyle ? categoryStyle + extraClasses : extraClasses;
    var afterBadgeWrapper = "";

    if (opts.topicCount && categoryStyle === "box") {
      afterBadgeWrapper += buildTopicCount(opts.topicCount);
    }

    if (opts.plusSubcategories && opts.lastSubcategory) {
      afterBadgeWrapper += "<span class=\"plus-subcategories\">".concat(_I18n.default.t("category_row.plus_subcategories", {
        count: opts.plusSubcategories
      }), "</span>");
    }

    return "<".concat(tagName, " class=\"badge-wrapper ").concat(extraClasses, "\" ").concat(href, ">").concat(html, "</").concat(tagName, ">").concat(afterBadgeWrapper);
  }
});