define("discourse/components/ignored-user-list", ["exports", "discourse/models/user", "discourse/lib/ajax-error", "discourse/lib/show-modal"], function (_exports, _user, _ajaxError, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    item: null,
    actions: {
      removeIgnoredUser: function removeIgnoredUser(item) {
        var _this = this;

        this.set("saved", false);
        this.items.removeObject(item);

        _user.default.findByUsername(item).then(function (user) {
          user.updateNotificationLevel("normal").catch(_ajaxError.popupAjaxError).finally(function () {
            return _this.set("saved", true);
          });
        });
      },
      newIgnoredUser: function newIgnoredUser() {
        var _this2 = this;

        var modal = (0, _showModal.default)("ignore-duration-with-username", {
          model: this.model
        });
        modal.setProperties({
          ignoredUsername: null,
          onUserIgnored: function onUserIgnored(username) {
            _this2.items.addObject(username);
          }
        });
      }
    }
  });

  _exports.default = _default;
});