define("discourse/models/associated-group", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AssociatedGroup = Ember.Object.extend();
  AssociatedGroup.reopenClass({
    list: function list() {
      return (0, _ajax.ajax)("/associated_groups").then(function (result) {
        return result.associated_groups.map(function (ag) {
          return AssociatedGroup.create(ag);
        });
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = AssociatedGroup;
  _exports.default = _default;
});