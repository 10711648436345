define("discourse/templates/components/images-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mHCLr9kH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"btn\"],[11,\"disabled\",[22,\"uploadingOrProcessing\"]],[11,\"title\",[28,\"i18n\",[\"admin.site_settings.uploaded_image_list.upload.title\"],null]],[8],[0,\"\\n  \"],[1,[28,\"d-icon\",[\"far-image\"],null],false],[0,\" \"],[1,[22,\"uploadButtonText\"],false],[0,\"\\n  \"],[7,\"input\",true],[10,\"class\",\"hidden-upload-field\"],[11,\"disabled\",[22,\"uploading\"]],[10,\"accept\",\"image/*\"],[10,\"multiple\",\"\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"uploadingOrProcessing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[8],[1,[28,\"i18n\",[\"upload_selector.uploading\"],null],false],[0,\" \"],[1,[22,\"uploadProgress\"],false],[0,\"%\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/images-uploader.hbs"
    }
  });

  _exports.default = _default;
});