define("discourse/components/iframed-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "iframe",
    html: null,
    className: "",
    classNameBindings: ["html:iframed-html", "className"],
    sandbox: "allow-same-origin",
    attributeBindings: ["sandbox:sandbox"],
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var iframeDoc = this.element.contentWindow.document;
      iframeDoc.open("text/html", "replace");
      iframeDoc.write(this.html);
      iframeDoc.close();
    }
  });

  _exports.default = _default;
});