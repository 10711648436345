define("discourse/lib/push-notifications", ["exports", "discourse/lib/key-value-store", "discourse/lib/ajax"], function (_exports, _keyValueStore, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.userSubscriptionKey = userSubscriptionKey;
  _exports.isPushNotificationsSupported = isPushNotificationsSupported;
  _exports.isPushNotificationsEnabled = isPushNotificationsEnabled;
  _exports.register = register;
  _exports.subscribe = subscribe;
  _exports.unsubscribe = unsubscribe;
  _exports.keyValueStore = void 0;
  var keyValueStore = new _keyValueStore.default("discourse_push_notifications_");
  _exports.keyValueStore = keyValueStore;

  function userSubscriptionKey(user) {
    return "subscribed-".concat(user.get("id"));
  }

  function sendSubscriptionToServer(subscription, sendConfirmation) {
    (0, _ajax.ajax)("/push_notifications/subscribe", {
      type: "POST",
      data: {
        subscription: subscription.toJSON(),
        send_confirmation: sendConfirmation
      }
    });
  }

  function userAgentVersionChecker(agent, version, mobileView) {
    var uaMatch = navigator.userAgent.match(new RegExp("".concat(agent, "/(\\d+)\\.\\d")));

    if (uaMatch && mobileView) {
      return false;
    }

    if (!uaMatch || parseInt(uaMatch[1], 10) < version) {
      return false;
    }

    return true;
  }

  function resetIdle() {
    if ("controller" in navigator.serviceWorker && navigator.serviceWorker.controller != null) {
      navigator.serviceWorker.controller.postMessage({
        lastAction: Date.now()
      });
    }
  }

  function setupActivityListeners(appEvents) {
    window.addEventListener("focus", resetIdle);

    if (document) {
      document.addEventListener("scroll", resetIdle);
    }

    appEvents.on("page:changed", resetIdle);
  }

  function isPushNotificationsSupported(mobileView) {
    if (!("serviceWorker" in navigator && typeof ServiceWorkerRegistration !== "undefined" && typeof Notification !== "undefined" && "showNotification" in ServiceWorkerRegistration.prototype && "PushManager" in window)) {
      return false;
    }

    if (!userAgentVersionChecker("Firefox", 44, mobileView) && !userAgentVersionChecker("Chrome", 50)) {
      return false;
    }

    return true;
  }

  function isPushNotificationsEnabled(user, mobileView) {
    return user && !user.isInDoNotDisturb() && isPushNotificationsSupported(mobileView) && keyValueStore.getItem(userSubscriptionKey(user));
  }

  function register(user, mobileView, router, appEvents) {
    if (!isPushNotificationsSupported(mobileView)) {
      return;
    }

    if (Notification.permission === "denied" || !user) {
      return;
    }

    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
      serviceWorkerRegistration.pushManager.getSubscription().then(function (subscription) {
        if (subscription) {
          sendSubscriptionToServer(subscription, false); // Resync localStorage

          keyValueStore.setItem(userSubscriptionKey(user), "subscribed");
        }

        setupActivityListeners(appEvents);
      }).catch(function (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    });
    navigator.serviceWorker.addEventListener("message", function (event) {
      if ("url" in event.data) {
        var url = event.data.url;
        router.handleURL(url);
      }
    });
  }

  function subscribe(callback, applicationServerKey, mobileView) {
    if (!isPushNotificationsSupported(mobileView)) {
      return;
    }

    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
      serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: new Uint8Array(applicationServerKey.split("|")) // eslint-disable-line no-undef

      }).then(function (subscription) {
        sendSubscriptionToServer(subscription, true);

        if (callback) {
          callback();
        }
      }).catch(function (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    });
  }

  function unsubscribe(user, callback, mobileView) {
    if (!isPushNotificationsSupported(mobileView)) {
      return;
    }

    keyValueStore.setItem(userSubscriptionKey(user), "");
    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
      serviceWorkerRegistration.pushManager.getSubscription().then(function (subscription) {
        if (subscription) {
          subscription.unsubscribe().then(function (successful) {
            if (successful) {
              (0, _ajax.ajax)("/push_notifications/unsubscribe", {
                type: "POST",
                data: {
                  subscription: subscription.toJSON()
                }
              });
            }
          });
        }
      }).catch(function (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      });

      if (callback) {
        callback();
      }
    });
  }
});