define("discourse/components/navigation-bar", ["exports", "discourse-common/utils/decorators", "discourse/lib/url", "discourse/mixins/filter-mode"], function (_exports, _decorators, _url, _filterMode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_filterMode.default, (_dec = (0, _decorators.default)("filterType", "navItems"), _dec2 = (0, _decorators.observes)("expanded"), (_obj = {
    tagName: "ul",
    classNameBindings: [":nav", ":nav-pills"],
    elementId: "navigation-bar",
    init: function init() {
      this._super.apply(this, arguments);
    },
    selectedNavItem: function selectedNavItem(filterType, navItems) {
      var item = navItems.find(function (i) {
        return i.active === true;
      });
      item = item || navItems.find(function (i) {
        return i.get("filterType") === filterType;
      });

      if (!item) {
        var connectors = this.connectors;
        var category = this.category;

        if (connectors && category) {
          connectors.forEach(function (c) {
            if (c.connectorClass && typeof c.connectorClass.path === "function" && typeof c.connectorClass.displayName === "function") {
              var path = c.connectorClass.path(category);

              if (path.indexOf(filterType) > 0) {
                item = {
                  displayName: c.connectorClass.displayName()
                };
              }
            }
          });
        }
      }

      return item || navItems[0];
    },
    closedNav: function closedNav() {
      if (!this.expanded) {
        this.ensureDropClosed();
      }
    },
    ensureDropClosed: function ensureDropClosed() {
      if (!this.element || this.isDestroying || this.isDestroyed) {
        return;
      }

      if (this.expanded) {
        this.set("expanded", false);
      }

      $(window).off("click.navigation-bar");

      _url.default.appEvents.off("dom:clean", this, this.ensureDropClosed);
    },
    actions: {
      toggleDrop: function toggleDrop() {
        var _this = this;

        this.set("expanded", !this.expanded);

        if (this.expanded) {
          _url.default.appEvents.on("dom:clean", this, this.ensureDropClosed);

          Ember.run.next(function () {
            if (!_this.expanded) {
              return;
            }

            $(_this.element.querySelector(".drop a")).on("click", function () {
              _this.element.querySelector(".drop").style.display = "none";
              Ember.run.next(function () {
                _this.ensureDropClosed();
              });
              return true;
            });
            $(window).on("click.navigation-bar", function () {
              _this.ensureDropClosed();

              return true;
            });
          });
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "selectedNavItem", [_dec], Object.getOwnPropertyDescriptor(_obj, "selectedNavItem"), _obj), _applyDecoratedDescriptor(_obj, "closedNav", [_dec2], Object.getOwnPropertyDescriptor(_obj, "closedNav"), _obj)), _obj)));

  _exports.default = _default;
});