define("discourse/lib/register-topic-footer-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerTopicFooterDropdown = registerTopicFooterDropdown;
  _exports.getTopicFooterDropdowns = getTopicFooterDropdowns;
  _exports.clearTopicFooterDropdowns = clearTopicFooterDropdowns;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _topicFooterDropdowns = {};

  function registerTopicFooterDropdown(dropdown) {
    if (!dropdown.id) {
      throw new Error("Attempted to register a topic dropdown with no id.");
    }

    if (_topicFooterDropdowns[dropdown.id]) {
      return;
    }

    var defaultDropdown = {
      type: "inline-dropdown",
      // id of the dropdown, required
      id: null,
      // icon displayed on the dropdown
      icon: null,
      // dropdown’s content
      content: null,
      // css class appended to the button
      classNames: [],
      // discourseComputed properties which should force a button state refresh
      // eg: ["topic.bookmarked", "topic.category_id"]
      dependentKeys: [],
      // should we display this dropdown ?
      displayed: true,
      // is this button disabled ?
      disabled: false,
      // display order, higher comes first
      priority: 0,
      // an object used to display the state of the dropdown
      // when no value is currectnly set, eg: { id: 1, name: "foo" }
      noneItem: null
    };
    var normalizedDropdown = Object.assign(defaultDropdown, dropdown);

    if (!normalizedDropdown.content) {
      throw new Error("Attempted to register a topic dropdown: ".concat(dropdown.id, " with no content."));
    }

    _topicFooterDropdowns[normalizedDropdown.id] = normalizedDropdown;
  }

  function getTopicFooterDropdowns() {
    var _ref, _Ember;

    var dependentKeys = (_ref = []).concat.apply(_ref, _toConsumableArray(Object.values(_topicFooterDropdowns).mapBy("dependentKeys").filter(Boolean)));

    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(dependentKeys).concat([{
      get: function get() {
        var _this = this;

        var _isFunction = function _isFunction(descriptor) {
          return descriptor && typeof descriptor === "function";
        };

        var _compute = function _compute(dropdown, property) {
          var field = dropdown[property];

          if (_isFunction(field)) {
            return field.apply(_this);
          }

          return field;
        };

        return Object.values(_topicFooterDropdowns).filter(function (dropdown) {
          return _compute(dropdown, "displayed");
        }).map(function (dropdown) {
          var discourseComputedDropdown = {};
          discourseComputedDropdown.id = dropdown.id;
          discourseComputedDropdown.type = dropdown.type;
          discourseComputedDropdown.classNames = (_compute(dropdown, "classNames") || []).join(" ");
          discourseComputedDropdown.icon = _compute(dropdown, "icon");
          discourseComputedDropdown.disabled = _compute(dropdown, "disabled");
          discourseComputedDropdown.priority = _compute(dropdown, "priority");
          discourseComputedDropdown.content = _compute(dropdown, "content");
          discourseComputedDropdown.value = _compute(dropdown, "value");
          discourseComputedDropdown.action = dropdown.action;
          discourseComputedDropdown.noneItem = _compute(dropdown, "noneItem");
          return discourseComputedDropdown;
        });
      }
    }]));
  }

  function clearTopicFooterDropdowns() {
    _topicFooterDropdowns = {};
  }
});