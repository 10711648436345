define("discourse/services/emoji-store", ["exports", "discourse/lib/key-value-store"], function (_exports, _keyValueStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EMOJI_USAGE = "emojiUsage";
  var EMOJI_SELECTED_DIVERSITY = "emojiSelectedDiversity";
  var TRACKED_EMOJIS = 15;
  var STORE_NAMESPACE = "discourse_emojis_";

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.store = new _keyValueStore.default(STORE_NAMESPACE);

      if (!this.store.getObject(EMOJI_USAGE)) {
        this.favorites = [];
      }
    },

    get diversity() {
      return this.store.getObject(EMOJI_SELECTED_DIVERSITY) || 1;
    },

    set diversity(value) {
      this.store.setObject({
        key: EMOJI_SELECTED_DIVERSITY,
        value: value || 1
      });
    },

    get favorites() {
      return this.store.getObject(EMOJI_USAGE) || [];
    },

    set favorites(value) {
      this.store.setObject({
        key: EMOJI_USAGE,
        value: value || []
      });
      this.notifyPropertyChange("favorites");
    },

    track: function track(code) {
      var normalizedCode = code.replace(/(^:)|(:$)/g, "");
      var recent = this.favorites.filter(function (r) {
        return r !== normalizedCode;
      });
      recent.unshift(normalizedCode);
      recent.length = Math.min(recent.length, TRACKED_EMOJIS);
      this.favorites = recent;
    },
    reset: function reset() {
      var store = new _keyValueStore.default(STORE_NAMESPACE);
      store.setObject({
        key: EMOJI_USAGE,
        value: []
      });
      store.setObject({
        key: EMOJI_SELECTED_DIVERSITY,
        value: 1
      });
    }
  });

  _exports.default = _default;
});