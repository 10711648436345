define("discourse/models/reviewable-history", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EDITED = _exports.TRANSITIONED_TO = _exports.CREATED = void 0;
  var CREATED = 0;
  _exports.CREATED = CREATED;
  var TRANSITIONED_TO = 1;
  _exports.TRANSITIONED_TO = TRANSITIONED_TO;
  var EDITED = 2;
  _exports.EDITED = EDITED;

  var _default = _rest.default.extend({
    created: Ember.computed.equal("reviewable_history_type", CREATED)
  });

  _exports.default = _default;
});