define("discourse/components/discovery-categories", ["exports", "discourse/mixins/url-refresh"], function (_exports, _urlRefresh) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CATEGORIES_LIST_BODY_CLASS = "categories-list";

  var _default = Ember.Component.extend(_urlRefresh.default, {
    classNames: ["contents"],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      document.body.classList.add(CATEGORIES_LIST_BODY_CLASS);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      document.body.classList.remove(CATEGORIES_LIST_BODY_CLASS);
    }
  });

  _exports.default = _default;
});