define("discourse/templates/components/topic-dismiss-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vj9jTTID",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showBasedOnPosition\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"row \",[22,\"containerClass\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showDismissRead\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"d-button\",null,[[\"class\",\"id\",\"action\",\"translatedLabel\",\"title\"],[\"btn-default dismiss-read\",[24,[\"dismissReadId\"]],[28,\"action\",[[23,0,[]],\"dismissReadPosts\"],null],[24,[\"dismissLabel\"]],\"topics.bulk.dismiss_tooltip\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"showResetNew\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"d-button\",null,[[\"class\",\"id\",\"action\",\"icon\",\"translatedLabel\"],[\"btn-default dismiss-read\",[24,[\"dismissNewId\"]],[24,[\"resetNew\"]],\"check\",[24,[\"dismissNewLabel\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/topic-dismiss-buttons.hbs"
    }
  });

  _exports.default = _default;
});