define("discourse/templates/badges/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eBWbyvqT",
    "block": "{\"symbols\":[\"bg\",\"b\"],\"statements\":[[4,\"d-section\",null,[[\"pageClass\"],[\"badges\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"container badges\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[1,[28,\"i18n\",[\"badges.title\"],null],false],[9],[0,\"\\n\\n    \"],[1,[28,\"plugin-outlet\",null,[[\"name\",\"tagName\",\"connectorTagName\"],[\"below-badges-title\",\"span\",\"div\"]]],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"badge-groups\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"badgeGroups\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"badge-grouping\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n            \"],[7,\"h3\",true],[8],[1,[23,1,[\"badgeGrouping\",\"displayName\"]],false],[9],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"badge-group-list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"badges\"]]],null,{\"statements\":[[0,\"              \"],[1,[28,\"badge-card\",null,[[\"badge\",\"username\"],[[23,2,[]],[24,[\"currentUser\",\"username\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/badges/index.hbs"
    }
  });

  _exports.default = _default;
});