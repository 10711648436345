define("discourse/components/edit-category-tab", ["exports", "discourse/lib/url", "I18n", "discourse-common/utils/decorators", "discourse-common/lib/get-url", "discourse/lib/computed"], function (_exports, _url, _I18n, _decorators, _getUrl, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("tab"), _dec2 = (0, _decorators.default)("tab"), _dec3 = (0, _decorators.default)("params.slug", "params.parentSlug"), (_obj = {
    tagName: "li",
    classNameBindings: ["active", "tabClassName"],
    newCategory: Ember.computed.empty("params.slug"),
    tabClassName: function tabClassName(tab) {
      return "edit-category-" + tab;
    },
    active: (0, _computed.propertyEqual)("selectedTab", "tab"),
    title: function title(tab) {
      return _I18n.default.t("category.".concat(Ember.String.underscore(tab)));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce("afterRender", this, this._addToCollection);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.setProperties({
        selectedTab: "general",
        params: {}
      });
    },
    _addToCollection: function _addToCollection() {
      this.panels.addObject(this.tabClassName);
    },
    fullSlug: function fullSlug(slug, parentSlug) {
      var slugPart = parentSlug && slug ? "".concat(parentSlug, "/").concat(slug) : slug;
      return (0, _getUrl.default)("/c/".concat(slugPart, "/edit/").concat(this.tab));
    },
    actions: {
      select: function select() {
        this.set("selectedTab", this.tab);

        if (!this.newCategory) {
          _url.default.routeTo(this.fullSlug);
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "tabClassName", [_dec], Object.getOwnPropertyDescriptor(_obj, "tabClassName"), _obj), _applyDecoratedDescriptor(_obj, "title", [_dec2], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "fullSlug", [_dec3], Object.getOwnPropertyDescriptor(_obj, "fullSlug"), _obj)), _obj)));

  _exports.default = _default;
});