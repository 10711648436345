define("discourse/helpers/bound-avatar", ["exports", "discourse/helpers/user-avatar", "discourse/lib/utilities", "discourse-common/lib/helpers"], function (_exports, _userAvatar, _utilities, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helpers.htmlHelper)(function (user, size) {
    if (Ember.isEmpty(user)) {
      return "<div class='avatar-placeholder'></div>";
    }

    var avatarTemplate = Ember.get(user, "avatar_template");
    return (0, _utilities.avatarImg)((0, _userAvatar.addExtraUserClasses)(user, {
      size: size,
      avatarTemplate: avatarTemplate
    }));
  });

  _exports.default = _default;
});