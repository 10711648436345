define("discourse/templates/components/user-stream", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GuSfZNs+",
    "block": "{\"symbols\":[\"item\",\"@stream\"],\"statements\":[[4,\"each\",[[23,2,[\"content\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"user-stream-item\",null,[[\"item\",\"removeBookmark\",\"resumeDraft\",\"removeDraft\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"removeBookmark\"],null],[28,\"action\",[[23,0,[]],\"resumeDraft\"],null],[28,\"action\",[[23,0,[]],\"removeDraft\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/user-stream.hbs"
    }
  });

  _exports.default = _default;
});