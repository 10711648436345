define("discourse/lib/load-script", ["exports", "discourse-common/lib/get-url", "discourse/lib/public-js-versions", "discourse/lib/ajax"], function (_exports, _getUrl, _publicJsVersions, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadCSS = loadCSS;
  _exports.default = loadScript;
  _exports.cacheBuster = cacheBuster;

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _loaded = {};
  var _loading = {};

  function loadWithTag(path, cb) {
    var head = document.getElementsByTagName("head")[0];
    var finished = false;
    var s = document.createElement("script");
    s.src = path;

    if (Ember.Test) {
      Ember.Test.registerWaiter(function () {
        return finished;
      });
    }

    s.onload = s.onreadystatechange = function (_, abort) {
      finished = true;

      if (abort || !s.readyState || s.readyState === "loaded" || s.readyState === "complete") {
        s = s.onload = s.onreadystatechange = null;

        if (!abort) {
          Ember.run(null, cb);
        }
      }
    };

    head.appendChild(s);
  }

  function loadCSS(url) {
    return loadScript(url, {
      css: true
    });
  }

  function loadScript(url, opts) {
    // TODO: Remove this once plugins have been updated not to use it:
    if (url === "defer/html-sanitizer-bundle") {
      return Ember.RSVP.Promise.resolve();
    }

    opts = opts || {};

    if (_loaded[url]) {
      return Ember.RSVP.Promise.resolve();
    }

    if (_publicJsVersions.PUBLIC_JS_VERSIONS) {
      url = cacheBuster(url);
    } // Scripts should always load from CDN
    // CSS is type text, to accept it from a CDN we would need to handle CORS


    var fullUrl = opts.css ? (0, _getUrl.default)(url) : (0, _getUrl.getURLWithCDN)(url);
    $("script").each(function (i, tag) {
      var src = tag.getAttribute("src");

      if (src && src !== fullUrl && !_loading[src]) {
        _loaded[src] = true;
      }
    });
    return new Ember.RSVP.Promise(function (resolve) {
      // If we already loaded this url
      if (_loaded[fullUrl]) {
        return resolve();
      }

      if (_loading[fullUrl]) {
        return _loading[fullUrl].then(resolve);
      }

      var done;
      _loading[fullUrl] = new Ember.RSVP.Promise(function (_done) {
        done = _done;
      });

      _loading[fullUrl].then(function () {
        delete _loading[fullUrl];
      });

      var cb = function cb(data) {
        if (opts && opts.css) {
          $("head").append("<style>" + data + "</style>");
        }

        done();
        resolve();
        _loaded[url] = true;
        _loaded[fullUrl] = true;
      };

      if (opts.css) {
        (0, _ajax.ajax)({
          url: fullUrl,
          dataType: "text"
        }).then(cb);
      } else {
        // Always load JavaScript with script tag to avoid Content Security Policy inline violations
        loadWithTag(fullUrl, cb);
      }
    });
  }

  function cacheBuster(url) {
    if (_publicJsVersions.PUBLIC_JS_VERSIONS) {
      var _url$split$filter = url.split("/").filter(Boolean),
          _url$split$filter2 = _toArray(_url$split$filter),
          folder = _url$split$filter2[0],
          lib = _url$split$filter2.slice(1);

      if (folder === "javascripts") {
        lib = lib.join("/");
        var versionedPath = _publicJsVersions.PUBLIC_JS_VERSIONS[lib];

        if (versionedPath) {
          return "/javascripts/".concat(versionedPath);
        }
      }
    }

    return url;
  }
});