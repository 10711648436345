define("discourse/components/emoji-picker", ["exports", "discourse-common/utils/decorators", "pretty-text/emoji", "discourse/lib/text", "discourse/lib/utilities", "@popperjs/core"], function (_exports, _decorators, _emoji, _text, _utilities, _core) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _obj;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function customEmojis() {
    var list = (0, _emoji.extendedEmojiList)();
    var groups = [];
    Object.keys(list).forEach(function (code) {
      var emoji = list[code];
      groups[emoji.group] = groups[emoji.group] || [];
      groups[emoji.group].push({
        code: code,
        src: (0, _text.emojiUrlFor)(code)
      });
    });
    return groups;
  }

  var _default = Ember.Component.extend((_dec = (0, _decorators.observes)("isActive"), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_obj = {
    emojiStore: Ember.inject.service("emoji-store"),
    tagName: "",
    customEmojis: null,
    selectedDiversity: null,
    recentEmojis: null,
    hoveredEmoji: null,
    isActive: false,
    isLoading: true,
    usePopper: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.set("customEmojis", customEmojis());
      this.set("selectedDiversity", this.emojiStore.diversity);

      if ("IntersectionObserver" in window) {
        this._sectionObserver = this._setupSectionObserver();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.appEvents.on("emoji-picker:close", this, "onClose");
    },
    _setup: function _setup() {
      if (this.isActive) {
        this.onShow();
      } else {
        this.onClose();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._sectionObserver && this._sectionObserver.disconnect();
      this.appEvents.off("emoji-picker:close", this, "onClose");
    },
    onShow: function onShow() {
      var _this = this;

      this.set("isLoading", true);
      this.set("recentEmojis", this.emojiStore.favorites);
      Ember.run.schedule("afterRender", function () {
        document.addEventListener("click", _this.handleOutsideClick);
        var emojiPicker = document.querySelector(".emoji-picker");

        if (!emojiPicker) {
          return;
        }

        var textareaWrapper = document.querySelector(".d-editor-textarea-wrapper");

        if (!_this.site.isMobileDevice && _this.usePopper && textareaWrapper) {
          var modifiers = [{
            name: "preventOverflow"
          }, {
            name: "offset",
            options: {
              offset: [5, 5]
            }
          }];

          if (window.innerWidth < textareaWrapper.clientWidth * 2) {
            modifiers.push({
              name: "computeStyles",
              enabled: true,
              fn: function fn(_ref) {
                var state = _ref.state;
                state.styles.popper = _objectSpread(_objectSpread({}, state.styles.popper), {}, {
                  position: "fixed",
                  left: "".concat((window.innerWidth - state.rects.popper.width) / 2, "px"),
                  top: "50%",
                  transform: "translateY(-50%)"
                });
                return state;
              }
            });
          }

          _this._popper = (0, _core.createPopper)(textareaWrapper, emojiPicker, {
            placement: "auto",
            modifiers: modifiers
          });
        } // this is a low-tech trick to prevent appending hundreds of emojis
        // of blocking the rendering of the picker


        Ember.run.later(function () {
          _this.set("isLoading", false);

          Ember.run.schedule("afterRender", function () {
            if (!_this.site.isMobileDevice || _this.isEditorFocused) {
              var filter = emojiPicker.querySelector("input.filter");
              filter && filter.focus();

              if (_this._sectionObserver) {
                emojiPicker.querySelectorAll(".emojis-container .section .section-header").forEach(function (p) {
                  return _this._sectionObserver.observe(p);
                });
              }
            }

            if (_this.selectedDiversity !== 0) {
              _this._applyDiversity(_this.selectedDiversity);
            }
          });
        }, 50);
      });
    },
    onClose: function onClose() {
      document.removeEventListener("click", this.handleOutsideClick);
      this.onEmojiPickerClose && this.onEmojiPickerClose();
    },
    diversityScales: Ember.computed("selectedDiversity", function () {
      var _this2 = this;

      return ["default", "light", "medium-light", "medium", "medium-dark", "dark"].map(function (name, index) {
        return {
          name: name,
          title: "emoji_picker.".concat(Ember.String.underscore(name), "_tone"),
          icon: index + 1 === _this2.selectedDiversity ? "check" : ""
        };
      });
    }),
    onClearRecents: function onClearRecents() {
      this.emojiStore.favorites = [];
      this.set("recentEmojis", []);
    },
    onDiversitySelection: function onDiversitySelection(index) {
      var scale = index + 1;
      this.emojiStore.diversity = scale;
      this.set("selectedDiversity", scale);

      this._applyDiversity(scale);
    },
    onEmojiHover: function onEmojiHover(event) {
      var img = event.target;

      if (!img.classList.contains("emoji") || img.tagName !== "IMG") {
        return false;
      }

      this.set("hoveredEmoji", this._codeWithDiversity(event.target.title, this.selectedDiversity));
    },
    onEmojiSelection: function onEmojiSelection(event) {
      var img = event.target;

      if (!img.classList.contains("emoji") || img.tagName !== "IMG") {
        return false;
      }

      var code = event.target.title;
      code = this._codeWithDiversity(code, this.selectedDiversity);
      this.emojiSelected(code);

      this._trackEmojiUsage(code, {
        refresh: !img.parentNode.parentNode.classList.contains("recent")
      });

      if (this.site.isMobileDevice) {
        this.onClose();
      }
    },
    onCategorySelection: function onCategorySelection(sectionName) {
      var section = document.querySelector(".emoji-picker-emoji-area .section[data-section=\"".concat(sectionName, "\"]"));
      section && section.scrollIntoView();
    },
    keydown: function keydown(event) {
      if (event.code === "Escape") {
        this.onClose();
        return false;
      }
    },
    onFilter: function onFilter(event) {
      var emojiPicker = document.querySelector(".emoji-picker");
      var results = document.querySelector(".emoji-picker-emoji-area .results");
      results.innerHTML = "";

      if (event.target.value) {
        results.innerHTML = (0, _emoji.emojiSearch)(event.target.value.toLowerCase(), {
          maxResults: 20,
          diversity: this.emojiStore.diversity
        }).map(this._replaceEmoji).join("");
        emojiPicker.classList.add("has-filter");
        results.scrollIntoView();
      } else {
        emojiPicker.classList.remove("has-filter");
      }
    },
    _trackEmojiUsage: function _trackEmojiUsage(code) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.emojiStore.track(code);

      if (options.refresh) {
        this.set("recentEmojis", _toConsumableArray(this.emojiStore.favorites));
      }
    },
    _replaceEmoji: function _replaceEmoji(code) {
      var escaped = (0, _text.emojiUnescape)(":".concat((0, _utilities.escapeExpression)(code), ":"), {
        lazy: true
      });
      return Ember.String.htmlSafe("<span>".concat(escaped, "</span>"));
    },
    _codeWithDiversity: function _codeWithDiversity(code, selectedDiversity) {
      if (/:t\d/.test(code)) {
        return code;
      } else if (selectedDiversity > 1 && (0, _emoji.isSkinTonableEmoji)(code)) {
        return "".concat(code, ":t").concat(selectedDiversity);
      } else {
        return code;
      }
    },
    _applyDiversity: function _applyDiversity(diversity) {
      var _this3 = this;

      var emojiPickerArea = document.querySelector(".emoji-picker-emoji-area");
      emojiPickerArea && emojiPickerArea.querySelectorAll(".emoji.diversity").forEach(function (img) {
        var code = _this3._codeWithDiversity(img.title, diversity);

        img.src = (0, _text.emojiUrlFor)(code);
      });
    },
    _setupSectionObserver: function _setupSectionObserver() {
      return new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            var sectionName = entry.target.parentNode.dataset.section;
            var categoryButtons = document.querySelector(".emoji-picker .emoji-picker-category-buttons");

            if (!categoryButtons) {
              return;
            }

            var button = categoryButtons.querySelector(".category-button[data-section=\"".concat(sectionName, "\"]"));
            categoryButtons.querySelectorAll(".category-button").forEach(function (b) {
              return b.classList.remove("current");
            });
            button && button.classList.add("current");
          }
        });
      }, {
        threshold: 1
      });
    },
    handleOutsideClick: function handleOutsideClick(event) {
      var emojiPicker = document.querySelector(".emoji-picker");

      if (emojiPicker && !emojiPicker.contains(event.target)) {
        this.onClose();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "_setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "_setup"), _obj), _applyDecoratedDescriptor(_obj, "onShow", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onShow"), _obj), _applyDecoratedDescriptor(_obj, "onClose", [_dec3], Object.getOwnPropertyDescriptor(_obj, "onClose"), _obj), _applyDecoratedDescriptor(_obj, "onClearRecents", [_dec4], Object.getOwnPropertyDescriptor(_obj, "onClearRecents"), _obj), _applyDecoratedDescriptor(_obj, "onDiversitySelection", [_dec5], Object.getOwnPropertyDescriptor(_obj, "onDiversitySelection"), _obj), _applyDecoratedDescriptor(_obj, "onEmojiHover", [_dec6], Object.getOwnPropertyDescriptor(_obj, "onEmojiHover"), _obj), _applyDecoratedDescriptor(_obj, "onEmojiSelection", [_dec7], Object.getOwnPropertyDescriptor(_obj, "onEmojiSelection"), _obj), _applyDecoratedDescriptor(_obj, "onCategorySelection", [_dec8], Object.getOwnPropertyDescriptor(_obj, "onCategorySelection"), _obj), _applyDecoratedDescriptor(_obj, "keydown", [_dec9], Object.getOwnPropertyDescriptor(_obj, "keydown"), _obj), _applyDecoratedDescriptor(_obj, "onFilter", [_dec10], Object.getOwnPropertyDescriptor(_obj, "onFilter"), _obj), _applyDecoratedDescriptor(_obj, "handleOutsideClick", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "handleOutsideClick"), _obj)), _obj)));

  _exports.default = _default;
});