define("discourse/templates/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V/Nyg0vv",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal\",null,[[\"modalClass\",\"title\",\"titleAriaElementId\",\"subtitle\",\"panels\",\"selectedPanel\",\"onSelectPanel\",\"class\",\"errors\",\"closeModal\"],[[24,[\"modalClass\"]],[24,[\"title\"]],[24,[\"titleAriaElementId\"]],[24,[\"subtitle\"]],[24,[\"panels\"]],[24,[\"selectedPanel\"]],[24,[\"onSelectPanel\"]],\"hidden\",[24,[\"errors\"]],[28,\"route-action\",[\"closeModal\"],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"outlet\",[\"modalBody\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal.hbs"
    }
  });

  _exports.default = _default;
});