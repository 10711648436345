define("discourse/templates/components/pwa-install-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h00vy6FJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showPWAInstallBanner\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"pwa-install-banner alert alert-info\"],[8],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"\\n        \"],[1,[28,\"discourse-linked-text\",null,[[\"action\",\"text\",\"textParams\"],[[28,\"action\",[[23,0,[]],\"turnOn\"],null],\"pwa.install_banner\",[28,\"hash\",null,[[\"title\"],[[24,[\"siteSettings\",\"title\"]]]]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[1,[28,\"d-button\",null,[[\"icon\",\"action\",\"class\",\"title\"],[\"times\",[28,\"action\",[[23,0,[]],\"dismiss\"],null],\"btn btn-flat close\",\"banner.close\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/pwa-install-banner.hbs"
    }
  });

  _exports.default = _default;
});