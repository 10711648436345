define("discourse/components/user-avatar-flair", ["exports", "discourse/lib/avatar-flair", "discourse-common/utils/decorators"], function (_exports, _avatarFlair, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("user"), (_obj = {
    tagName: "",
    flair: function flair(user) {
      if (!user) {
        return;
      }

      if (user.flair_url || user.flair_bg_color) {
        return {
          flairName: user.flair_name,
          flairUrl: user.flair_url,
          flairBgColor: user.flair_bg_color,
          flairColor: user.flair_color
        };
      }

      var autoFlairAttrs = (0, _avatarFlair.default)(this.site, user);

      if (autoFlairAttrs) {
        return {
          flairName: autoFlairAttrs.flair_name,
          flairUrl: autoFlairAttrs.flair_url,
          flairBgColor: autoFlairAttrs.flair_bg_color,
          flairColor: autoFlairAttrs.flair_color
        };
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "flair", [_dec], Object.getOwnPropertyDescriptor(_obj, "flair"), _obj)), _obj)));

  _exports.default = _default;
});