define("discourse/components/flag-action-type", ["exports", "I18n", "discourse/models/post-action-type", "discourse-common/utils/decorators"], function (_exports, _I18n, _postActionType, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("flag.name_key"), _dec2 = (0, _decorators.default)("flag.name", "flag.name_key", "flag.is_custom_flag", "username"), _dec3 = (0, _decorators.default)("flag", "selectedFlag"), _dec4 = (0, _decorators.default)("flag.description", "flag.short_description"), _dec5 = (0, _decorators.default)("message.length"), _dec6 = (0, _decorators.default)("message.length"), (_obj = {
    classNames: ["flag-action-type"],
    customPlaceholder: function customPlaceholder(nameKey) {
      return _I18n.default.t("flagging.custom_placeholder_" + nameKey);
    },
    formattedName: function formattedName(name, nameKey, isCustomFlag, username) {
      if (isCustomFlag) {
        return name.replace(/{{username}}|%{username}/, username);
      } else {
        return _I18n.default.t("flagging.formatted_name." + nameKey);
      }
    },
    selected: function selected(flag, selectedFlag) {
      return flag === selectedFlag;
    },
    showMessageInput: Ember.computed.and("flag.is_custom_flag", "selected"),
    showDescription: Ember.computed.not("showMessageInput"),
    isNotifyUser: Ember.computed.equal("flag.name_key", "notify_user"),
    description: function description(long_description, short_description) {
      return this.site.mobileView ? short_description : long_description;
    },
    customMessageLengthClasses: function customMessageLengthClasses(messageLength) {
      return messageLength < this.siteSettings.min_personal_message_post_length ? "too-short" : "ok";
    },
    customMessageLength: function customMessageLength(messageLength) {
      var len = messageLength || 0;
      var minLen = this.siteSettings.min_personal_message_post_length;

      if (len === 0) {
        return _I18n.default.t("flagging.custom_message.at_least", {
          count: minLen
        });
      } else if (len < minLen) {
        return _I18n.default.t("flagging.custom_message.more", {
          count: minLen - len
        });
      } else {
        return _I18n.default.t("flagging.custom_message.left", {
          count: _postActionType.MAX_MESSAGE_LENGTH - len
        });
      }
    },
    actions: {
      changePostActionType: function changePostActionType(at) {
        this.changePostActionType(at);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "customPlaceholder", [_dec], Object.getOwnPropertyDescriptor(_obj, "customPlaceholder"), _obj), _applyDecoratedDescriptor(_obj, "formattedName", [_dec2], Object.getOwnPropertyDescriptor(_obj, "formattedName"), _obj), _applyDecoratedDescriptor(_obj, "selected", [_dec3], Object.getOwnPropertyDescriptor(_obj, "selected"), _obj), _applyDecoratedDescriptor(_obj, "description", [_dec4], Object.getOwnPropertyDescriptor(_obj, "description"), _obj), _applyDecoratedDescriptor(_obj, "customMessageLengthClasses", [_dec5], Object.getOwnPropertyDescriptor(_obj, "customMessageLengthClasses"), _obj), _applyDecoratedDescriptor(_obj, "customMessageLength", [_dec6], Object.getOwnPropertyDescriptor(_obj, "customMessageLength"), _obj)), _obj)));

  _exports.default = _default;
});