define("discourse/components/ignored-user-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "div",
    items: null,
    actions: {
      removeIgnoredUser: function removeIgnoredUser(item) {
        this.onRemoveIgnoredUser(item);
      }
    }
  });

  _exports.default = _default;
});