define("discourse/models/archetype", ["exports", "discourse/models/rest", "discourse/lib/computed"], function (_exports, _rest, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    hasOptions: Ember.computed.gt("options.length", 0),
    isDefault: (0, _computed.propertyEqual)("id", "site.default_archetype"),
    notDefault: Ember.computed.not("isDefault")
  });

  _exports.default = _default;
});