define("discourse/lib/theme-settings-store", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerSettings = registerSettings;
  _exports.getSetting = getSetting;
  _exports.getObjectForTheme = getObjectForTheme;
  _exports.resetSettings = resetSettings;
  var originalSettings = {};
  var settings = {};

  function registerSettings(themeId, settingsObject) {
    var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
        _ref$force = _ref.force,
        force = _ref$force === void 0 ? false : _ref$force;

    if (settings[themeId] && !force) {
      return;
    }

    originalSettings[themeId] = Object.assign({}, settingsObject);
    var s = {};
    Object.keys(settingsObject).forEach(function (key) {
      Object.defineProperty(s, key, {
        enumerable: true,
        get: function get() {
          return settingsObject[key];
        },
        set: function set(newVal) {
          settingsObject[key] = newVal;
        }
      });
    });
    settings[themeId] = s;
  }

  function getSetting(themeId, settingKey) {
    if (settings[themeId]) {
      return Ember.get(settings[themeId], settingKey);
    }

    return null;
  }

  function getObjectForTheme(themeId) {
    return settings[themeId];
  }

  function resetSettings() {
    Object.keys(originalSettings).forEach(function (themeId) {
      Object.keys(originalSettings[themeId]).forEach(function (key) {
        settings[themeId][key] = originalSettings[themeId][key];
      });
    });
  }
});