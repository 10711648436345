define("discourse/components/tag-drop-link", ["exports", "discourse/lib/url", "discourse-common/utils/decorators", "discourse-common/lib/get-url"], function (_exports, _url, _decorators, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("tagId", "category"), _dec2 = (0, _decorators.default)("tagId"), (_obj = {
    tagName: "a",
    classNameBindings: [":tag-badge-wrapper", ":badge-wrapper", ":bullet", "tagClass"],
    attributeBindings: ["href"],
    href: function href(tagId, category) {
      var path;

      if (category) {
        path = "/tags" + category.path + "/" + tagId;
      } else {
        path = "/tag/" + tagId;
      }

      return (0, _getUrl.default)(path);
    },
    tagClass: function tagClass(tagId) {
      return "tag-" + tagId;
    },
    click: function click(e) {
      e.preventDefault();

      _url.default.routeTo(this.href);

      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, "href", [_dec], Object.getOwnPropertyDescriptor(_obj, "href"), _obj), _applyDecoratedDescriptor(_obj, "tagClass", [_dec2], Object.getOwnPropertyDescriptor(_obj, "tagClass"), _obj)), _obj)));

  _exports.default = _default;
});