define("discourse/helpers/discourse-tags", ["exports", "discourse-common/lib/helpers", "discourse/lib/render-tags"], function (_exports, _helpers, _renderTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helpers.registerUnbound)("discourse-tags", function (topic, params) {
    return Ember.String.htmlSafe((0, _renderTags.default)(topic, params));
  });

  _exports.default = _default;
});