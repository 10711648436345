define("discourse/mixins/username-validation", ["exports", "I18n", "discourse/models/user", "discourse-common/lib/debounce", "discourse-common/utils/decorators", "discourse/lib/computed"], function (_exports, _I18n, _user, _debounce, _decorators, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function failedResult(attrs) {
    var result = Ember.Object.create({
      shouldCheck: false,
      failed: true,
      ok: false,
      element: document.querySelector("#new-account-username")
    });
    result.setProperties(attrs);
    return result;
  }

  function validResult(attrs) {
    var result = Ember.Object.create({
      ok: true
    });
    result.setProperties(attrs);
    return result;
  }

  var _default = Ember.Mixin.create((_dec = (0, _decorators.observes)("accountUsername"), (_obj = {
    uniqueUsernameValidation: null,
    maxUsernameLength: (0, _computed.setting)("max_username_length"),
    minUsernameLength: (0, _computed.setting)("min_username_length"),
    fetchExistingUsername: function fetchExistingUsername() {
      var _this = this;

      _user.default.checkUsername(null, this.accountEmail).then(function (result) {
        if (result.suggestion && (Ember.isEmpty(_this.accountUsername) || _this.accountUsername === _this.get("authOptions.username"))) {
          _this.setProperties({
            accountUsername: result.suggestion,
            prefilledUsername: result.suggestion
          });
        }
      });
    },
    triggerValidation: function triggerValidation() {
      var accountUsername = this.accountUsername;
      var result = this.basicUsernameValidation(accountUsername);

      if (result.shouldCheck) {
        (0, _debounce.default)(this, this.checkUsernameAvailability, 500);
      }

      this.set("usernameValidation", result);
    },
    basicUsernameValidation: function basicUsernameValidation(username) {
      if (username && username === this.prefilledUsername) {
        return validResult({
          reason: _I18n.default.t("user.username.prefilled")
        });
      }

      if (Ember.isEmpty(username)) {
        return failedResult({
          message: _I18n.default.t("user.username.required"),
          reason: this.forceValidationReason ? _I18n.default.t("user.username.required") : null
        });
      }

      if (username.length < this.siteSettings.min_username_length) {
        return failedResult({
          reason: _I18n.default.t("user.username.too_short")
        });
      }

      if (username.length > this.maxUsernameLength) {
        return failedResult({
          reason: _I18n.default.t("user.username.too_long")
        });
      }

      return failedResult({
        shouldCheck: true,
        reason: _I18n.default.t("user.username.checking")
      });
    },
    checkUsernameAvailability: function checkUsernameAvailability() {
      var _this2 = this;

      return _user.default.checkUsername(this.accountUsername, this.accountEmail).then(function (result) {
        if (_this2.isDestroying || _this2.isDestroyed) {
          return;
        }

        _this2.set("isDeveloper", false);

        if (result.available) {
          if (result.is_developer) {
            _this2.set("isDeveloper", true);
          }

          return _this2.set("usernameValidation", validResult({
            reason: _I18n.default.t("user.username.available")
          }));
        } else {
          if (result.suggestion) {
            return _this2.set("usernameValidation", failedResult({
              reason: _I18n.default.t("user.username.not_available", result)
            }));
          } else {
            return _this2.set("usernameValidation", failedResult({
              reason: result.errors ? result.errors.join(" ") : _I18n.default.t("user.username.not_available_no_suggestion")
            }));
          }
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "triggerValidation", [_dec], Object.getOwnPropertyDescriptor(_obj, "triggerValidation"), _obj)), _obj)));

  _exports.default = _default;
});