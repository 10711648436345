define("discourse/routes/signup", ["exports", "discourse/routes/build-static-route"], function (_exports, _buildStaticRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SignupRoute = (0, _buildStaticRoute.default)("signup");
  SignupRoute.reopen({
    beforeModel: function beforeModel() {
      var canSignUp = this.controllerFor("application").get("canSignUp");

      if (!this.siteSettings.login_required) {
        this.replaceWith("discovery.latest").then(function (e) {
          if (canSignUp) {
            Ember.run.next(function () {
              return e.send("showCreateAccount");
            });
          }
        });
      } else {
        this.replaceWith("login").then(function (e) {
          if (canSignUp) {
            Ember.run.next(function () {
              return e.send("showCreateAccount");
            });
          }
        });
      }
    }
  });
  var _default = SignupRoute;
  _exports.default = _default;
});