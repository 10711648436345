define("discourse/routes/new-topic", ["exports", "discourse/models/category", "discourse/routes/discourse", "discourse/lib/cookie"], function (_exports, _category, _discourse, _cookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    beforeModel: function beforeModel(transition) {
      var _this = this;

      if (this.currentUser) {
        var category, categoryId;

        if (transition.to.queryParams.category_id) {
          categoryId = transition.to.queryParams.category_id;
          category = _category.default.findById(categoryId);
        } else if (transition.to.queryParams.category) {
          var splitCategory = transition.to.queryParams.category.split("/");
          category = this._getCategory(splitCategory[0], splitCategory[1], "nameLower");

          if (!category) {
            category = this._getCategory(splitCategory[0], splitCategory[1], "slug");
          }

          if (category) {
            categoryId = category.id;
          }
        }

        if (category) {
          var route = "discovery.category";
          var params = {
            category: category,
            id: category.id
          };
          this.replaceWith(route, params).then(function (e) {
            if (_this.controllerFor("navigation/category").canCreateTopic) {
              _this._sendTransition(e, transition, categoryId);
            }
          });
        } else {
          this.replaceWith("discovery.latest").then(function (e) {
            if (_this.controllerFor("navigation/default").canCreateTopic) {
              _this._sendTransition(e, transition);
            }
          });
        }
      } else {
        // User is not logged in
        (0, _cookie.default)("destination_url", window.location.href);
        this.replaceWith("login");
      }
    },
    _sendTransition: function _sendTransition(event, transition, categoryId) {
      Ember.run.next(function () {
        event.send("createNewTopicViaParams", transition.to.queryParams.title, transition.to.queryParams.body, categoryId, transition.to.queryParams.tags);
      });
    },
    _getCategory: function _getCategory(mainCategory, subCategory, type) {
      var category;

      if (!subCategory) {
        category = this.site.categories.findBy(type, mainCategory.toLowerCase());
      } else {
        var categories = this.site.categories;
        var main = categories.findBy(type, mainCategory.toLowerCase());

        if (main) {
          category = categories.find(function (item) {
            return item && item[type] === subCategory.toLowerCase() && item.parent_category_id === main.id;
          });
        }
      }

      return category;
    }
  });

  _exports.default = _default;
});