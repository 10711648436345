define("discourse/helpers/user-avatar", ["exports", "discourse/lib/utilities", "I18n", "discourse/lib/settings", "discourse-common/lib/helpers"], function (_exports, _utilities, _I18n, _settings, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerCustomAvatarHelper = registerCustomAvatarHelper;
  _exports.addExtraUserClasses = addExtraUserClasses;
  _exports.classesForUser = classesForUser;
  _exports.renderAvatar = renderAvatar;

  var _customAvatarHelpers;

  function registerCustomAvatarHelper(fn) {
    _customAvatarHelpers = _customAvatarHelpers || [];

    _customAvatarHelpers.push(fn);
  }

  function addExtraUserClasses(u, args) {
    var extraClasses = classesForUser(u).join(" ");

    if (extraClasses && extraClasses.length) {
      args.extraClasses = extraClasses;
    }

    return args;
  }

  function classesForUser(u) {
    var result = [];

    if (_customAvatarHelpers) {
      for (var i = 0; i < _customAvatarHelpers.length; i++) {
        result = result.concat(_customAvatarHelpers[i](u));
      }
    }

    return result;
  }

  function renderAvatar(user, options) {
    options = options || {};

    if (user) {
      var name = Ember.get(user, options.namePath || "name");
      var username = Ember.get(user, options.usernamePath || "username");
      var avatarTemplate = Ember.get(user, options.avatarTemplatePath || "avatar_template");

      if (!username || !avatarTemplate) {
        return "";
      }

      var displayName = (0, _settings.prioritizeNameInUx)(name) ? name : (0, _utilities.formatUsername)(username);
      var title = options.title;

      if (!title && !options.ignoreTitle) {
        // first try to get a title
        title = Ember.get(user, "title"); // if there was no title provided

        if (!title) {
          // try to retrieve a description
          var description = Ember.get(user, "description"); // if a description has been provided

          if (description && description.length > 0) {
            // prepend the username before the description
            title = _I18n.default.t("user.avatar.name_and_description", {
              name: displayName,
              description: description
            });
          }
        }
      }

      return (0, _utilities.avatarImg)({
        size: options.imageSize,
        extraClasses: Ember.get(user, "extras") || options.extraClasses,
        title: title || displayName,
        avatarTemplate: avatarTemplate
      });
    } else {
      return "";
    }
  }

  (0, _helpers.registerUnbound)("avatar", function (user, params) {
    return Ember.String.htmlSafe(renderAvatar.call(this, user, params));
  });
});