define("discourse/components/d-modal-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["modal-body"],
    fixed: false,
    submitOnEnter: true,
    dismissable: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._modalAlertElement = document.getElementById("modal-alert");

      if (this._modalAlertElement) {
        this._clearFlash();
      }

      var fixedParent = $(this.element).closest(".d-modal.fixed-modal");

      if (fixedParent.length) {
        this.set("fixed", true);
        fixedParent.modal("show");
      }

      Ember.run.scheduleOnce("afterRender", this, this._afterFirstRender);
      this.appEvents.on("modal-body:flash", this, "_flash");
      this.appEvents.on("modal-body:clearFlash", this, "_clearFlash");
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.appEvents.off("modal-body:flash", this, "_flash");
      this.appEvents.off("modal-body:clearFlash", this, "_clearFlash");
      this.appEvents.trigger("modal:body-dismissed");
    },
    _afterFirstRender: function _afterFirstRender() {
      var maxHeight = this.maxHeight;

      if (maxHeight) {
        var maxHeightFloat = parseFloat(maxHeight) / 100.0;

        if (maxHeightFloat > 0) {
          var viewPortHeight = $(window).height();
          this.element.style.maxHeight = Math.floor(maxHeightFloat * viewPortHeight) + "px";
        }
      }

      this.appEvents.trigger("modal:body-shown", this.getProperties("title", "rawTitle", "fixed", "subtitle", "rawSubtitle", "submitOnEnter", "dismissable", "headerClass"));
    },
    _clearFlash: function _clearFlash() {
      if (this._modalAlertElement) {
        this._modalAlertElement.innerHTML = "";

        this._modalAlertElement.classList.remove("alert", "alert-error", "alert-info", "alert-success", "alert-warning");
      }
    },
    _flash: function _flash(msg) {
      this._clearFlash();

      if (!this._modalAlertElement) {
        return;
      }

      this._modalAlertElement.classList.add("alert", "alert-".concat(msg.messageClass || "success"));

      this._modalAlertElement.innerHTML = msg.text || "";
    }
  });

  _exports.default = _default;
});