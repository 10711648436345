define("discourse/components/reviewable-item", ["exports", "discourse/models/category", "I18n", "discourse/lib/ajax", "bootbox", "discourse-common/utils/decorators", "discourse/lib/optional-service", "discourse/lib/ajax-error", "discourse/lib/show-modal"], function (_exports, _category, _I18n, _ajax, _bootbox, _decorators, _optionalService, _ajaxError, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addPluginReviewableParam = addPluginReviewableParam;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _components = {};
  var pluginReviewableParams = {};

  function addPluginReviewableParam(reviewableType, param) {
    pluginReviewableParams[reviewableType] ? pluginReviewableParams[reviewableType].push(param) : pluginReviewableParams[reviewableType] = [param];
  }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("reviewable.type", "reviewable.last_performing_username", "siteSettings.blur_tl0_flagged_posts_media", "reviewable.target_created_by_trust_level"), _dec2 = (0, _decorators.default)("reviewable.topic", "reviewable.topic_id", "reviewable.removed_topic_id"), _dec3 = (0, _decorators.default)("siteSettings.reviewable_claiming", "topicId"), _dec4 = (0, _decorators.default)("claimEnabled", "siteSettings.reviewable_claiming", "reviewable.claimed_by"), _dec5 = (0, _decorators.default)("siteSettings.reviewable_claiming", "reviewable.claimed_by"), _dec6 = (0, _decorators.default)("reviewable.type"), (_obj = {
    adminTools: (0, _optionalService.default)(),
    tagName: "",
    updating: null,
    editing: false,
    _updates: null,
    customClasses: function customClasses(type, lastPerformingUsername, blurEnabled, trustLevel) {
      var classes = type.dasherize();

      if (lastPerformingUsername) {
        classes = "".concat(classes, " reviewable-stale");
      }

      if (blurEnabled && trustLevel === 0) {
        classes = "".concat(classes, " blur-images");
      }

      return classes;
    },
    topicId: function topicId(topic, _topicId, removedTopicId) {
      return topic && topic.id || _topicId || removedTopicId;
    },
    claimEnabled: function claimEnabled(claimMode, topicId) {
      return claimMode !== "disabled" && !!topicId;
    },
    canPerform: function canPerform(claimEnabled, claimMode, claimedBy) {
      if (!claimEnabled) {
        return true;
      }

      if (claimedBy) {
        return claimedBy.id === this.currentUser.id;
      }

      return claimMode !== "required";
    },
    claimHelp: function claimHelp(claimMode, claimedBy) {
      if (claimedBy) {
        return claimedBy.id === this.currentUser.id ? _I18n.default.t("review.claim_help.claimed_by_you") : _I18n.default.t("review.claim_help.claimed_by_other", {
          username: claimedBy.username
        });
      }

      return claimMode === "optional" ? _I18n.default.t("review.claim_help.optional") : _I18n.default.t("review.claim_help.required");
    },
    reviewableComponent: function reviewableComponent(type) {
      if (_components[type] !== undefined) {
        return _components[type];
      }

      var dasherized = Ember.String.dasherize(type);
      var templatePath = "components/".concat(dasherized);
      var template = Ember.TEMPLATES["".concat(templatePath)] || Ember.TEMPLATES["javascripts/".concat(templatePath)];
      _components[type] = template ? dasherized : null;
      return _components[type];
    },
    _performConfirmed: function _performConfirmed(action) {
      var _this = this;

      var reviewable = this.reviewable;

      var performAction = function performAction() {
        var version = reviewable.get("version");

        _this.set("updating", true);

        var data = {
          send_email: reviewable.sendEmail,
          reject_reason: reviewable.rejectReason
        };
        (pluginReviewableParams[reviewable.type] || []).forEach(function (param) {
          if (reviewable[param]) {
            data[param] = reviewable[param];
          }
        });
        return (0, _ajax.ajax)("/review/".concat(reviewable.id, "/perform/").concat(action.id, "?version=").concat(version), {
          type: "PUT",
          data: data
        }).then(function (result) {
          var performResult = result.reviewable_perform_result; // "fast track" to update the current user's reviewable count before the message bus finds out.

          if (performResult.reviewable_count !== undefined) {
            _this.currentUser.set("reviewable_count", performResult.reviewable_count);
          }

          if (_this.attrs.remove) {
            _this.attrs.remove(performResult.remove_reviewable_ids);
          } else {
            return _this.store.find("reviewable", reviewable.id);
          }
        }).catch(_ajaxError.popupAjaxError).finally(function () {
          return _this.set("updating", false);
        });
      };

      if (action.client_action) {
        var actionMethod = this["client".concat(action.client_action.classify())];

        if (actionMethod) {
          return actionMethod.call(this, reviewable, performAction);
        } else {
          // eslint-disable-next-line no-console
          console.error("No handler for ".concat(action.client_action, " found"));
          return;
        }
      } else {
        return performAction();
      }
    },
    clientSuspend: function clientSuspend(reviewable, performAction) {
      this._penalize("showSuspendModal", reviewable, performAction);
    },
    clientSilence: function clientSilence(reviewable, performAction) {
      this._penalize("showSilenceModal", reviewable, performAction);
    },
    _penalize: function _penalize(adminToolMethod, reviewable, performAction) {
      var adminTools = this.adminTools;

      if (adminTools) {
        var createdBy = reviewable.get("target_created_by");
        var postId = reviewable.get("post_id");
        var postEdit = reviewable.get("raw");
        return adminTools[adminToolMethod](createdBy, {
          postId: postId,
          postEdit: postEdit,
          before: performAction
        });
      }
    },
    actions: {
      explainReviewable: function explainReviewable(reviewable) {
        (0, _showModal.default)("explain-reviewable", {
          title: "review.explain.title",
          model: reviewable
        });
      },
      edit: function edit() {
        this.set("editing", true);
        this._updates = {
          payload: {}
        };
      },
      cancelEdit: function cancelEdit() {
        this.set("editing", false);
      },
      saveEdit: function saveEdit() {
        var _this2 = this;

        var updates = this._updates; // Remove empty objects

        Object.keys(updates).forEach(function (name) {
          var attr = updates[name];

          if (_typeof(attr) === "object" && Object.keys(attr).length === 0) {
            delete updates[name];
          }
        });
        this.set("updating", true);
        return this.reviewable.update(updates).then(function () {
          return _this2.set("editing", false);
        }).catch(_ajaxError.popupAjaxError).finally(function () {
          return _this2.set("updating", false);
        });
      },
      categoryChanged: function categoryChanged(categoryId) {
        var category = _category.default.findById(categoryId);

        if (!category) {
          category = _category.default.findUncategorized();
        }

        this._updates.category_id = category.id;
      },
      valueChanged: function valueChanged(fieldId, event) {
        Ember.set(this._updates, fieldId, event.target.value);
      },
      perform: function perform(action) {
        var _this3 = this;

        if (this.updating) {
          return;
        }

        var msg = action.get("confirm_message");
        var requireRejectReason = action.get("require_reject_reason");
        var customModal = action.get("custom_modal");

        if (msg) {
          _bootbox.default.confirm(msg, function (answer) {
            if (answer) {
              return _this3._performConfirmed(action);
            }
          });
        } else if (requireRejectReason) {
          (0, _showModal.default)("reject-reason-reviewable", {
            title: "review.reject_reason.title",
            model: this.reviewable
          }).setProperties({
            performConfirmed: this._performConfirmed,
            action: action
          });
        } else if (customModal) {
          (0, _showModal.default)(customModal, {
            title: "review.".concat(customModal, ".title"),
            model: this.reviewable
          }).setProperties({
            performConfirmed: this._performConfirmed,
            action: action
          });
        } else {
          return this._performConfirmed(action);
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "customClasses", [_dec], Object.getOwnPropertyDescriptor(_obj, "customClasses"), _obj), _applyDecoratedDescriptor(_obj, "topicId", [_dec2], Object.getOwnPropertyDescriptor(_obj, "topicId"), _obj), _applyDecoratedDescriptor(_obj, "claimEnabled", [_dec3], Object.getOwnPropertyDescriptor(_obj, "claimEnabled"), _obj), _applyDecoratedDescriptor(_obj, "canPerform", [_dec4], Object.getOwnPropertyDescriptor(_obj, "canPerform"), _obj), _applyDecoratedDescriptor(_obj, "claimHelp", [_dec5], Object.getOwnPropertyDescriptor(_obj, "claimHelp"), _obj), _applyDecoratedDescriptor(_obj, "reviewableComponent", [_dec6], Object.getOwnPropertyDescriptor(_obj, "reviewableComponent"), _obj), _applyDecoratedDescriptor(_obj, "_performConfirmed", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_performConfirmed"), _obj)), _obj)));

  _exports.default = _default;
});