define("discourse/routes/invites-show", ["exports", "discourse/routes/discourse", "I18n", "discourse/lib/preload-store", "discourse-common/lib/object"], function (_exports, _discourse, _I18n, _preloadStore, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    titleToken: function titleToken() {
      return _I18n.default.t("invites.accept_title");
    },
    model: function model(params) {
      if (_preloadStore.default.get("invite_info")) {
        return _preloadStore.default.getAndRemove("invite_info").then(function (json) {
          return (0, _object.deepMerge)(params, json);
        });
      } else {
        return {};
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (model.user_fields) {
        controller.userFields.forEach(function (userField) {
          if (model.user_fields[userField.field.id]) {
            userField.value = model.user_fields[userField.field.id];
          }
        });
      }
    }
  });

  _exports.default = _default;
});