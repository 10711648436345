define("discourse/routes/review-show", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    setupController: function setupController(controller, model) {
      controller.set("reviewable", model);
    }
  });

  _exports.default = _default;
});