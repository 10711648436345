define("discourse/templates/components/google-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yDWN37pz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",true],[10,\"action\",\"//google.com/search\"],[10,\"id\",\"google-search\"],[10,\"class\",\"inline-form\"],[8],[0,\"\\n  \"],[7,\"input\",true],[10,\"name\",\"q\"],[11,\"aria-label\",[28,\"i18n\",[\"search.search_google\"],null]],[11,\"value\",[22,\"searchTerm\"]],[10,\"type\",\"text\"],[8],[9],[0,\"\\n  \"],[7,\"input\",true],[10,\"name\",\"as_sitesearch\"],[11,\"value\",[22,\"siteUrl\"]],[10,\"type\",\"hidden\"],[8],[9],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"btn btn-primary\"],[10,\"type\",\"submit\"],[8],[1,[28,\"i18n\",[\"search.search_google_button\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/google-search.hbs"
    }
  });

  _exports.default = _default;
});