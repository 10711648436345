define("discourse/components/group-flair-inputs", ["exports", "discourse-common/utils/decorators", "I18n", "discourse/lib/ajax", "discourse-common/lib/icon-library", "discourse-common/lib/debounce", "discourse-common/lib/get-url"], function (_exports, _decorators, _I18n, _ajax, _iconLibrary, _debounce, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("model.flair_type"), _dec2 = (0, _decorators.default)("model.flair_icon"), _dec3 = (0, _decorators.on)("didInsertElement"), _dec4 = (0, _decorators.observes)("model.flair_icon"), _dec5 = (0, _decorators.default)("model.flair_type"), _dec6 = (0, _decorators.default)("model.flair_url"), _dec7 = (0, _decorators.default)("flairPreviewImage"), _dec8 = Ember._action, _dec9 = Ember._action, (_obj = {
    classNames: ["group-flair-inputs"],
    demoAvatarUrl: function demoAvatarUrl() {
      return (0, _getUrl.default)("/images/avatar.png");
    },
    flairPreviewIcon: function flairPreviewIcon(flairType) {
      return flairType && flairType === "icon";
    },
    flairPreviewIconUrl: function flairPreviewIconUrl(flairIcon) {
      return flairIcon ? (0, _iconLibrary.convertIconClass)(flairIcon) : "";
    },
    _loadSVGIcon: function _loadSVGIcon(flairIcon) {
      if (flairIcon) {
        (0, _debounce.default)(this, this._loadIcon, 1000);
      }
    },
    _loadIcon: function _loadIcon() {
      if (!this.model.flair_icon) {
        return;
      }

      var icon = (0, _iconLibrary.convertIconClass)(this.model.flair_icon),
          c = "#svg-sprites",
          h = "ajax-icon-holder",
          singleIconEl = "".concat(c, " .").concat(h);

      if (!icon) {
        return;
      }

      if (!$("".concat(c, " symbol#").concat(icon)).length) {
        (0, _ajax.ajax)("/svg-sprite/search/".concat(icon)).then(function (data) {
          if ($(singleIconEl).length === 0) {
            $(c).append("<div class=\"".concat(h, "\">"));
          }

          $(singleIconEl).html("<svg xmlns='http://www.w3.org/2000/svg' style='display: none;'>".concat(data, "</svg>"));
        });
      }
    },
    flairPreviewImage: function flairPreviewImage(flairType) {
      return flairType && flairType === "image";
    },
    flairImageUrl: function flairImageUrl(flairUrl) {
      return flairUrl && flairUrl.includes("/") ? flairUrl : null;
    },
    flairPreviewLabel: function flairPreviewLabel(flairPreviewImage) {
      var key = flairPreviewImage ? "image" : "icon";
      return _I18n.default.t("groups.flair_preview_".concat(key));
    },
    setFlairImage: function setFlairImage(upload) {
      this.model.setProperties({
        flair_url: (0, _getUrl.default)(upload.url),
        flair_upload_id: upload.id
      });
    },
    removeFlairImage: function removeFlairImage() {
      this.model.setProperties({
        flair_url: null,
        flair_upload_id: null
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "demoAvatarUrl", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "demoAvatarUrl"), _obj), _applyDecoratedDescriptor(_obj, "flairPreviewIcon", [_dec], Object.getOwnPropertyDescriptor(_obj, "flairPreviewIcon"), _obj), _applyDecoratedDescriptor(_obj, "flairPreviewIconUrl", [_dec2], Object.getOwnPropertyDescriptor(_obj, "flairPreviewIconUrl"), _obj), _applyDecoratedDescriptor(_obj, "_loadSVGIcon", [_dec3, _dec4], Object.getOwnPropertyDescriptor(_obj, "_loadSVGIcon"), _obj), _applyDecoratedDescriptor(_obj, "flairPreviewImage", [_dec5], Object.getOwnPropertyDescriptor(_obj, "flairPreviewImage"), _obj), _applyDecoratedDescriptor(_obj, "flairImageUrl", [_dec6], Object.getOwnPropertyDescriptor(_obj, "flairImageUrl"), _obj), _applyDecoratedDescriptor(_obj, "flairPreviewLabel", [_dec7], Object.getOwnPropertyDescriptor(_obj, "flairPreviewLabel"), _obj), _applyDecoratedDescriptor(_obj, "setFlairImage", [_dec8], Object.getOwnPropertyDescriptor(_obj, "setFlairImage"), _obj), _applyDecoratedDescriptor(_obj, "removeFlairImage", [_dec9], Object.getOwnPropertyDescriptor(_obj, "removeFlairImage"), _obj)), _obj)));

  _exports.default = _default;
});