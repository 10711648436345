define("discourse/components/related-messages", ["exports", "discourse-common/utils/decorators", "discourse-common/lib/get-url"], function (_exports, _decorators, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("topic"), (_obj = {
    tagName: "",
    targetUser: function targetUser(topic) {
      var _this = this;

      if (!topic || !topic.isPrivateMessage) {
        return;
      }

      var allowedUsers = topic.details.allowed_users;

      if (topic.relatedMessages && topic.relatedMessages.length >= 5 && allowedUsers.length === 2 && topic.details.allowed_groups.length === 0 && allowedUsers.find(function (u) {
        return u.username === _this.currentUser.username;
      })) {
        return allowedUsers.find(function (u) {
          return u.username !== _this.currentUser.username;
        });
      }
    },
    searchLink: function searchLink() {
      return (0, _getUrl.default)("/search?expanded=true&q=%40".concat(this.targetUser.username, "%20in%3Apersonal-direct"));
    }
  }, (_applyDecoratedDescriptor(_obj, "targetUser", [_dec], Object.getOwnPropertyDescriptor(_obj, "targetUser"), _obj), _applyDecoratedDescriptor(_obj, "searchLink", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "searchLink"), _obj)), _obj)));

  _exports.default = _default;
});