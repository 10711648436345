define("discourse/lib/cached-topic-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findOrResetCachedTopicList = findOrResetCachedTopicList;

  function findOrResetCachedTopicList(session, filter) {
    var lastTopicList = session.get("topicList");

    if (lastTopicList && lastTopicList.filter === filter) {
      return lastTopicList;
    } else {
      session.setProperties({
        topicList: null,
        topicListScrollPosition: null
      });
      return false;
    }
  }
});