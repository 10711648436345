define("discourse/helpers/raw", ["discourse-common/lib/helpers", "discourse-common/lib/raw-templates", "discourse-common/lib/raw-handlebars-helpers"], function (_helpers, _rawTemplates, _rawHandlebarsHelpers) {
  "use strict";

  function renderRaw(ctx, template, templateName, params) {
    params = Object.assign({}, params);
    params.parent = params.parent || ctx;
    var context = (0, _helpers.helperContext)();

    if (!params.view) {
      var viewClass = context.registry.resolve("raw-view:".concat(templateName));

      if (viewClass) {
        params.view = viewClass.create(params, context);
      }

      if (!params.view) {
        params = Object.assign({}, params, context);
      }
    }

    return Ember.String.htmlSafe(template(params, _rawHandlebarsHelpers.RUNTIME_OPTIONS));
  }

  (0, _helpers.registerUnbound)("raw", function (templateName, params) {
    templateName = templateName.replace(".", "/");
    var template = (0, _rawTemplates.findRawTemplate)(templateName);

    if (!template) {
      // eslint-disable-next-line no-console
      console.warn("Could not find raw template: " + templateName);
      return;
    }

    return renderRaw(this, template, templateName, params);
  });
});