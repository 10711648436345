define("discourse/helpers/raw-plugin-outlet", ["discourse-common/lib/raw-handlebars", "discourse/lib/plugin-connectors"], function (_rawHandlebars, _pluginConnectors) {
  "use strict";

  _rawHandlebars.default.registerHelper("raw-plugin-outlet", function (args) {
    var _this = this;

    var connectors = (0, _pluginConnectors.rawConnectorsFor)(args.hash.name);

    if (connectors.length) {
      var output = connectors.map(function (c) {
        return c.template({
          context: _this
        });
      });
      return Ember.String.htmlSafe(output.join(""));
    }
  });
});