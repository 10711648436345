define("discourse/initializers/subscribe-user-changes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "subscribe-user-changes",
    after: "message-bus",
    initialize: function initialize(container) {
      var user = container.lookup("current-user:main");

      if (user) {
        var bus = container.lookup("message-bus:main");
        bus.subscribe("/user", function (data) {
          user.setProperties(data);
        });
      }
    }
  };
  _exports.default = _default;
});