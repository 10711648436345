define("discourse/initializers/enable-emoji", ["exports", "discourse/lib/preload-store", "pretty-text/emoji", "discourse/lib/plugin-api"], function (_exports, _preloadStore, _emoji, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "enable-emoji",
    initialize: function initialize(container) {
      var siteSettings = container.lookup("site-settings:main");

      if (!siteSettings.enable_emoji) {
        return;
      }

      (0, _pluginApi.withPluginApi)("0.1", function (api) {
        api.onToolbarCreate(function (toolbar) {
          toolbar.addButton({
            id: "emoji",
            group: "extras",
            icon: "far-smile",
            action: function action() {
              return toolbar.context.send("emoji");
            },
            title: "composer.emoji",
            className: "emoji insert-emoji"
          });
        });
      });
      (_preloadStore.default.get("customEmoji") || []).forEach(function (emoji) {
        return (0, _emoji.registerEmoji)(emoji.name, emoji.url, emoji.group);
      });
    }
  };
  _exports.default = _default;
});