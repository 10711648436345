define("discourse/controllers/preferences/email", ["exports", "I18n", "discourse-common/utils/decorators", "discourse/lib/utilities", "discourse/lib/computed"], function (_exports, _I18n, _decorators, _utilities, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_dec = (0, _decorators.default)("newEmail"), _dec2 = (0, _decorators.default)("saving", "new"), _dec3 = (0, _decorators.default)("newEmail"), _dec4 = (0, _decorators.default)("invalidEmail", "oldEmail", "newEmail"), (_obj = {
    queryParams: ["new"],
    taken: false,
    saving: false,
    error: false,
    success: false,
    oldEmail: null,
    newEmail: null,
    successMessage: null,
    newEmailEmpty: Ember.computed.empty("newEmail"),
    saveDisabled: Ember.computed.or("saving", "newEmailEmpty", "taken", "unchanged", "invalidEmail"),
    unchanged: (0, _computed.propertyEqual)("newEmailLower", "oldEmail"),
    newEmailLower: function newEmailLower(newEmail) {
      return newEmail.toLowerCase().trim();
    },
    saveButtonText: function saveButtonText(saving, isNew) {
      if (saving) {
        return _I18n.default.t("saving");
      }

      if (isNew) {
        return _I18n.default.t("user.add_email.add");
      }

      return _I18n.default.t("user.change");
    },
    invalidEmail: function invalidEmail(newEmail) {
      return !(0, _utilities.emailValid)(newEmail);
    },
    emailValidation: function emailValidation(invalidEmail, oldEmail, newEmail) {
      if (invalidEmail && (oldEmail || newEmail)) {
        return Ember.Object.create({
          failed: true,
          reason: _I18n.default.t("user.email.invalid")
        });
      }
    },
    reset: function reset() {
      this.setProperties({
        taken: false,
        saving: false,
        error: false,
        success: false,
        newEmail: null
      });
    },
    actions: {
      saveEmail: function saveEmail() {
        var _this = this;

        this.set("saving", true);
        return (this.new ? this.model.addEmail(this.newEmail) : this.model.changeEmail(this.newEmail)).then(function () {
          _this.set("success", true);

          if (_this.model.staff) {
            _this.set("successMessage", _I18n.default.t("user.change_email.success_staff"));
          } else {
            if (_this.currentUser.admin) {
              _this.set("successMessage", _I18n.default.t("user.change_email.success_via_admin"));
            } else {
              _this.set("successMessage", _I18n.default.t("user.change_email.success"));
            }
          }
        }, function (e) {
          _this.setProperties({
            error: true,
            saving: false
          });

          if (e.jqXHR.responseJSON && e.jqXHR.responseJSON.errors && e.jqXHR.responseJSON.errors[0]) {
            _this.set("errorMessage", e.jqXHR.responseJSON.errors[0]);
          } else {
            _this.set("errorMessage", _I18n.default.t("user.change_email.error"));
          }
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "newEmailLower", [_dec], Object.getOwnPropertyDescriptor(_obj, "newEmailLower"), _obj), _applyDecoratedDescriptor(_obj, "saveButtonText", [_dec2], Object.getOwnPropertyDescriptor(_obj, "saveButtonText"), _obj), _applyDecoratedDescriptor(_obj, "invalidEmail", [_dec3], Object.getOwnPropertyDescriptor(_obj, "invalidEmail"), _obj), _applyDecoratedDescriptor(_obj, "emailValidation", [_dec4], Object.getOwnPropertyDescriptor(_obj, "emailValidation"), _obj)), _obj)));

  _exports.default = _default;
});