define("discourse/templates/components/login-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z3la6sN3",
    "block": "{\"symbols\":[\"b\"],\"statements\":[[4,\"each\",[[24,[\"buttons\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\",false],[12,\"class\",[29,[\"btn btn-social \",[23,1,[\"name\"]]]]],[12,\"aria-label\",[23,1,[\"screenReaderTitle\"]]],[12,\"tabindex\",\"3\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"externalLogin\"]],[23,1,[]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"isGoogle\"]]],null,{\"statements\":[[0,\"      \"],[7,\"svg\",true],[10,\"class\",\"fa d-icon d-icon-custom-google-oauth2 svg-icon\"],[10,\"viewBox\",\"0 0 48 48\"],[8],[7,\"defs\",true],[8],[7,\"path\",true],[10,\"id\",\"a\"],[10,\"d\",\"M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z\"],[8],[9],[9],[7,\"clipPath\",true],[10,\"id\",\"b\"],[8],[7,\"use\",true],[10,\"href\",\"#a\"],[10,\"overflow\",\"visible\"],[8],[9],[9],[7,\"path\",true],[10,\"clip-path\",\"url(#b)\"],[10,\"fill\",\"#FBBC05\"],[10,\"d\",\"M0 37V11l17 13z\"],[8],[9],[7,\"path\",true],[10,\"clip-path\",\"url(#b)\"],[10,\"fill\",\"#EA4335\"],[10,\"d\",\"M0 11l17 13 7-6.1L48 14V0H0z\"],[8],[9],[7,\"path\",true],[10,\"clip-path\",\"url(#b)\"],[10,\"fill\",\"#34A853\"],[10,\"d\",\"M0 37l30-23 7.9 1L48 0v48H0z\"],[8],[9],[7,\"path\",true],[10,\"clip-path\",\"url(#b)\"],[10,\"fill\",\"#4285F4\"],[10,\"d\",\"M48 48L17 24l-4-3 35-10z\"],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,1,[\"icon\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"d-icon\",[[23,1,[\"icon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"d-icon\",[\"sign-in-alt\"],null],false],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"    \"],[1,[23,1,[\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/login-buttons.hbs"
    }
  });

  _exports.default = _default;
});