define("discourse/components/create-topic-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    label: "topic.create",
    btnClass: "btn-default"
  });

  _exports.default = _default;
});