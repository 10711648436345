define("discourse/components/badge-selector", ["exports", "discourse-common/utils/decorators", "I18n", "discourse-common/lib/raw-templates", "discourse-common/lib/helpers"], function (_exports, _decorators, _I18n, _rawTemplates, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("placeholderKey"), _dec2 = (0, _decorators.observes)("badgeNames"), _dec3 = (0, _decorators.on)("didInsertElement"), (_obj = {
    placeholder: function placeholder(placeholderKey) {
      return placeholderKey ? _I18n.default.t(placeholderKey) : "";
    },
    _update: function _update() {
      if (this.canReceiveUpdates === "true") {
        this._initializeAutocomplete({
          updateData: true
        });
      }
    },
    _initializeAutocomplete: function _initializeAutocomplete(opts) {
      var selectedBadges;
      $(this.element.querySelector("input")).autocomplete({
        allowAny: false,
        items: (0, _helpers.makeArray)(this.badgeNames),
        single: this.single,
        updateData: opts && opts.updateData ? opts.updateData : false,
        template: (0, _rawTemplates.findRawTemplate)("badge-selector-autocomplete"),
        onChangeItems: function onChangeItems(items) {
          selectedBadges = items;
          this.set("badgeNames", items.join(","));
        },
        transformComplete: function transformComplete(g) {
          return g.name;
        },
        dataSource: function dataSource(term) {
          return this.badgeFinder(term).then(function (badges) {
            if (!selectedBadges) {
              return badges;
            }

            return badges.filter(function (badge) {
              return !selectedBadges.any(function (s) {
                return s === badge.name;
              });
            });
          });
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "placeholder", [_dec], Object.getOwnPropertyDescriptor(_obj, "placeholder"), _obj), _applyDecoratedDescriptor(_obj, "_update", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_update"), _obj), _applyDecoratedDescriptor(_obj, "_initializeAutocomplete", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_initializeAutocomplete"), _obj)), _obj)));

  _exports.default = _default;
});