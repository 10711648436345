define("discourse/routes/group-activity-mentions", ["exports", "discourse/routes/group-activity-posts"], function (_exports, _groupActivityPosts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _groupActivityPosts.buildGroupPage)("mentions");

  _exports.default = _default;
});