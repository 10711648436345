define("discourse/lib/render-tags", ["exports", "discourse/lib/render-tag"], function (_exports, _renderTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addTagsHtmlCallback = addTagsHtmlCallback;
  _exports.default = _default;
  var callbacks = null;
  var priorities = null;

  function addTagsHtmlCallback(callback, options) {
    callbacks = callbacks || [];
    priorities = priorities || [];
    var priority = options && options.priority || 0;
    var i = 0;

    while (i < priorities.length && priorities[i] > priority) {
      i += 1;
    }

    priorities.splice(i, 0, priority);
    callbacks.splice(i, 0, callback);
  }

  function _default(topic, params) {
    var tags = topic.tags;
    var buffer = "";
    var tagsForUser = null;
    var tagName;
    var isPrivateMessage = topic.get("isPrivateMessage");

    if (params) {
      if (params.mode === "list") {
        tags = topic.get("visibleListTags");
      }

      if (params.tagsForUser) {
        tagsForUser = params.tagsForUser;
      }

      if (params.tagName) {
        tagName = params.tagName;
      }
    }

    var customHtml = null;

    if (callbacks) {
      callbacks.forEach(function (c) {
        var html = c(topic, params);

        if (html) {
          if (customHtml) {
            customHtml += html;
          } else {
            customHtml = html;
          }
        }
      });
    }

    if (customHtml || tags && tags.length > 0) {
      buffer = "<div class='discourse-tags'>";

      if (tags) {
        for (var i = 0; i < tags.length; i++) {
          buffer += (0, _renderTag.default)(tags[i], {
            description: topic.tags_descriptions && topic.tags_descriptions[tags[i]],
            isPrivateMessage: isPrivateMessage,
            tagsForUser: tagsForUser,
            tagName: tagName
          }) + " ";
        }
      }

      if (customHtml) {
        buffer += customHtml;
      }

      buffer += "</div>";
    }

    return buffer;
  }
});