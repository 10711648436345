define("discourse/controllers/flag", ["exports", "discourse/models/action-summary", "I18n", "discourse/models/post-action-type", "discourse/mixins/modal-functionality", "discourse/models/user", "discourse-common/utils/decorators", "discourse/lib/optional-service", "discourse/lib/ajax-error"], function (_exports, _actionSummary, _I18n, _postActionType, _modalFunctionality, _user, _decorators, _optionalService, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("spammerDetails.canDelete", "selected.name_key"), _dec2 = (0, _decorators.default)("flagTopic"), _dec3 = (0, _decorators.default)("post", "flagTopic", "model.actions_summary.@each.can_act"), _dec4 = (0, _decorators.default)("post", "flagTopic", "model.actions_summary.@each.can_act"), _dec5 = (0, _decorators.default)("selected.is_custom_flag", "message.length"), _dec6 = (0, _decorators.default)("flagsAvailable"), _dec7 = (0, _decorators.default)("flagTopic", "selected.is_custom_flag"), _dec8 = (0, _decorators.default)("selected.is_custom_flag"), _dec9 = (0, _decorators.default)("selected.is_custom_flag", "flagTopic"), _dec10 = (0, _decorators.default)("flagTopic", "selected.name_key"), (_obj = {
    adminTools: (0, _optionalService.default)(),
    userDetails: null,
    selected: null,
    flagTopic: null,
    message: null,
    isWarning: false,
    topicActionByName: null,
    spammerDetails: null,
    flagActions: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.flagActions = {
        icon: "gavel",
        label: _I18n.default.t("flagging.take_action"),
        actions: [{
          id: "agree_and_keep",
          icon: "thumbs-up",
          label: _I18n.default.t("flagging.take_action_options.default.title"),
          description: _I18n.default.t("flagging.take_action_options.default.details")
        }, {
          id: "agree_and_suspend",
          icon: "ban",
          label: _I18n.default.t("flagging.take_action_options.suspend.title"),
          description: _I18n.default.t("flagging.take_action_options.suspend.details"),
          client_action: "suspend"
        }, {
          id: "agree_and_silence",
          icon: "microphone-slash",
          label: _I18n.default.t("flagging.take_action_options.silence.title"),
          description: _I18n.default.t("flagging.take_action_options.silence.details"),
          client_action: "silence"
        }]
      };
    },
    keyDown: function keyDown(event) {
      // CTRL+ENTER or CMD+ENTER
      if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
        if (this.submitEnabled) {
          this.send("createFlag");
          return false;
        }
      }
    },
    clientSuspend: function clientSuspend(performAction) {
      this._penalize("showSuspendModal", performAction);
    },
    clientSilence: function clientSilence(performAction) {
      this._penalize("showSilenceModal", performAction);
    },
    _penalize: function _penalize(adminToolMethod, performAction) {
      var _this = this;

      if (this.adminTools) {
        return _user.default.findByUsername(this.model.username).then(function (createdBy) {
          var postId = _this.model.id;
          var postEdit = _this.model.cooked;
          return _this.adminTools[adminToolMethod](createdBy, {
            postId: postId,
            postEdit: postEdit,
            before: performAction
          });
        });
      }
    },
    onShow: function onShow() {
      var _this2 = this;

      this.setProperties({
        selected: null,
        spammerDetails: null
      });

      if (this.adminTools) {
        this.adminTools.checkSpammer(this.get("model.user_id")).then(function (result) {
          _this2.set("spammerDetails", result);
        });
      }

      Ember.run.schedule("afterRender", function () {
        var element = document.querySelector(".flag-modal");
        element.addEventListener("keydown", _this2.keyDown);
      });
    },
    onClose: function onClose() {
      var element = document.querySelector(".flag-modal");
      element.removeEventListener("keydown", this.keyDown);
    },
    showDeleteSpammer: function showDeleteSpammer(canDeleteSpammer, nameKey) {
      return canDeleteSpammer && nameKey === "spam";
    },
    title: function title(flagTopic) {
      return flagTopic ? "flagging_topic.title" : "flagging.title";
    },
    flagsAvailable: function flagsAvailable() {
      var _this3 = this;

      if (!this.flagTopic) {
        // flagging post
        var flagsAvailable = this.get("model.flagsAvailable"); // "message user" option should be at the top

        var notifyUserIndex = flagsAvailable.indexOf(flagsAvailable.filterBy("name_key", "notify_user")[0]);

        if (notifyUserIndex !== -1) {
          var notifyUser = flagsAvailable[notifyUserIndex];
          flagsAvailable.splice(notifyUserIndex, 1);
          flagsAvailable.splice(0, 0, notifyUser);
        }

        return flagsAvailable;
      } else {
        // flagging topic
        var lookup = Ember.Object.create();
        var model = this.model;
        model.get("actions_summary").forEach(function (a) {
          a.flagTopic = model;
          a.actionType = _this3.site.topicFlagTypeById(a.id);
          lookup.set(a.actionType.get("name_key"), _actionSummary.default.create(a));
        });
        this.set("topicActionByName", lookup);
        return this.site.get("topic_flag_types").filter(function (item) {
          return _this3.get("model.actions_summary").some(function (a) {
            return a.id === item.get("id") && a.can_act;
          });
        });
      }
    },
    staffFlagsAvailable: function staffFlagsAvailable() {
      return this.get("model.flagsAvailable") && this.get("model.flagsAvailable").length > 1;
    },
    submitEnabled: function submitEnabled() {
      var selected = this.selected;

      if (!selected) {
        return false;
      }

      if (selected.get("is_custom_flag")) {
        var len = this.get("message.length") || 0;
        return len >= this.siteSettings.min_personal_message_post_length && len <= _postActionType.MAX_MESSAGE_LENGTH;
      }

      return true;
    },
    submitDisabled: Ember.computed.not("submitEnabled"),
    cantFlagForReview: Ember.computed.not("notifyModeratorsFlag"),
    notifyModeratorsFlag: function notifyModeratorsFlag(flagsAvailable) {
      var notifyModeratorsID = 7;
      return flagsAvailable.find(function (f) {
        return f.id === notifyModeratorsID;
      });
    },
    canTakeAction: function canTakeAction(flagTopic, isCustomFlag) {
      return !flagTopic && !isCustomFlag && this.currentUser.get("staff");
    },
    submitIcon: function submitIcon(isCustomFlag) {
      return isCustomFlag ? "envelope" : "flag";
    },
    submitLabel: function submitLabel(isCustomFlag, flagTopic) {
      if (isCustomFlag) {
        return flagTopic ? "flagging_topic.notify_action" : "flagging.notify_action";
      }

      return flagTopic ? "flagging_topic.action" : "flagging.action";
    },
    actions: {
      deleteSpammer: function deleteSpammer() {
        var details = this.spammerDetails;

        if (details) {
          details.deleteUser().then(function () {
            return window.location.reload();
          });
        }
      },
      takeAction: function takeAction(action) {
        var _this4 = this;

        var performAction = function performAction() {
          var o = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          o.takeAction = true;

          _this4.send("createFlag", o);

          return Ember.RSVP.Promise.resolve();
        };

        if (action.client_action) {
          var actionMethod = this["client".concat(action.client_action.classify())];

          if (actionMethod) {
            return actionMethod.call(this, function () {
              return performAction({
                skipClose: true
              });
            });
          } else {
            // eslint-disable-next-line no-console
            console.error("No handler for ".concat(action.client_action, " found"));
            return;
          }
        } else {
          this.set("model.hidden", true);
          return performAction();
        }
      },
      createFlag: function createFlag(opts) {
        var _this5 = this;

        var postAction; // an instance of ActionSummary

        if (!this.flagTopic) {
          postAction = this.get("model.actions_summary").findBy("id", this.get("selected.id"));
        } else {
          postAction = this.get("topicActionByName." + this.get("selected.name_key"));
        }

        var params = this.get("selected.is_custom_flag") ? {
          message: this.message
        } : {};

        if (opts) {
          params = Object.assign(params, opts);
        }

        this.appEvents.trigger(this.flagTopic ? "topic:flag-created" : "post:flag-created", this.model, postAction, params);
        this.send("hideModal");
        postAction.act(this.model, params).then(function () {
          if (_this5.isDestroying || _this5.isDestroyed) {
            return;
          }

          if (!params.skipClose) {
            _this5.send("closeModal");
          }

          if (params.message) {
            _this5.set("message", "");
          }

          _this5.appEvents.trigger("post-stream:refresh", {
            id: _this5.get("model.id")
          });
        }).catch(function (error) {
          if (!_this5.isDestroying && !_this5.isDestroyed) {
            _this5.send("closeModal");
          }

          (0, _ajaxError.popupAjaxError)(error);
        });
      },
      createFlagAsWarning: function createFlagAsWarning() {
        this.send("createFlag", {
          isWarning: true
        });
        this.set("model.hidden", true);
      },
      flagForReview: function flagForReview() {
        this.set("selected", this.get("notifyModeratorsFlag"));
        this.send("createFlag", {
          queue_for_review: true
        });
        this.set("model.hidden", true);
      },
      changePostActionType: function changePostActionType(action) {
        this.set("selected", action);
      }
    },
    canSendWarning: function canSendWarning(flagTopic, nameKey) {
      return !flagTopic && this.currentUser.get("staff") && nameKey === "notify_user";
    }
  }, (_applyDecoratedDescriptor(_obj, "keyDown", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "keyDown"), _obj), _applyDecoratedDescriptor(_obj, "showDeleteSpammer", [_dec], Object.getOwnPropertyDescriptor(_obj, "showDeleteSpammer"), _obj), _applyDecoratedDescriptor(_obj, "title", [_dec2], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "flagsAvailable", [_dec3], Object.getOwnPropertyDescriptor(_obj, "flagsAvailable"), _obj), _applyDecoratedDescriptor(_obj, "staffFlagsAvailable", [_dec4], Object.getOwnPropertyDescriptor(_obj, "staffFlagsAvailable"), _obj), _applyDecoratedDescriptor(_obj, "submitEnabled", [_dec5], Object.getOwnPropertyDescriptor(_obj, "submitEnabled"), _obj), _applyDecoratedDescriptor(_obj, "notifyModeratorsFlag", [_dec6], Object.getOwnPropertyDescriptor(_obj, "notifyModeratorsFlag"), _obj), _applyDecoratedDescriptor(_obj, "canTakeAction", [_dec7], Object.getOwnPropertyDescriptor(_obj, "canTakeAction"), _obj), _applyDecoratedDescriptor(_obj, "submitIcon", [_dec8], Object.getOwnPropertyDescriptor(_obj, "submitIcon"), _obj), _applyDecoratedDescriptor(_obj, "submitLabel", [_dec9], Object.getOwnPropertyDescriptor(_obj, "submitLabel"), _obj), _applyDecoratedDescriptor(_obj, "canSendWarning", [_dec10], Object.getOwnPropertyDescriptor(_obj, "canSendWarning"), _obj)), _obj)));

  _exports.default = _default;
});