define("discourse/mixins/url-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // A Mixin that a view can use to listen for 'url:refresh' when
  // it is on screen, and will send an action to refresh its data.
  //
  // This is useful if you want to get around Ember's default
  // behavior of not refreshing when navigating to the same place.
  var _default = {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.appEvents.on("url:refresh", this, "refresh");
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.appEvents.off("url:refresh", this, "refresh");
    }
  };
  _exports.default = _default;
});