define("discourse/templates/modal/dismiss-read", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2FnFrHjr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,null,{\"statements\":[[0,\"  \"],[7,\"p\",true],[8],[0,\"\\n    \"],[1,[28,\"preference-checkbox\",null,[[\"labelKey\",\"checked\"],[\"topics.bulk.also_dismiss_topics\",[24,[\"dismissTopics\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n  \"],[1,[28,\"d-button\",null,[[\"class\",\"action\",\"icon\",\"id\",\"label\"],[\"btn-primary\",[28,\"route-action\",[\"dismissReadTopics\",[24,[\"dismissTopics\"]]],null],\"check\",\"dismiss-read-confirm\",\"topics.bulk.dismiss\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/dismiss-read.hbs"
    }
  });

  _exports.default = _default;
});