define("discourse/templates/components/plugin-outlet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YdCSzG6N",
    "block": "{\"symbols\":[\"c\"],\"statements\":[[4,\"each\",[[24,[\"connectors\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"plugin-connector\",null,[[\"connector\",\"args\",\"deprecatedArgs\",\"class\",\"tagName\"],[[23,1,[]],[24,[\"args\"]],[24,[\"deprecatedArgs\"]],[23,1,[\"classNames\"]],[24,[\"connectorTagName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/plugin-outlet.hbs"
    }
  });

  _exports.default = _default;
});