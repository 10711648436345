define("discourse/mixins/buffered-content", ["exports", "ember-buffered-proxy/proxy"], function (_exports, _proxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bufferedProperty = bufferedProperty;
  _exports.default = void 0;

  function bufferedProperty(property) {
    var mixin = {
      buffered: Ember.computed(property, function () {
        return _proxy.default.create({
          content: this.get(property)
        });
      }),
      rollbackBuffer: function rollbackBuffer() {
        this.buffered.discardBufferedChanges();
      },
      commitBuffer: function commitBuffer() {
        this.buffered.applyBufferedChanges();
      }
    }; // It's a good idea to null out fields when declaring objects

    mixin.property = null;
    return Ember.Mixin.create(mixin);
  }

  var _default = bufferedProperty("content");

  _exports.default = _default;
});