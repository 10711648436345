define("discourse/lib/timeframes-builder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeframeDetails = timeframeDetails;
  _exports.default = buildTimeframes;
  var TIMEFRAME_BASE = {
    enabled: function enabled() {
      return true;
    },
    when: function when() {
      return null;
    },
    icon: "briefcase",
    displayWhen: true
  };

  function buildTimeframe(opts) {
    return Object.assign({}, TIMEFRAME_BASE, opts);
  }

  var TIMEFRAMES = [buildTimeframe({
    id: "now",
    format: "h:mm a",
    enabled: function enabled(opts) {
      return opts.canScheduleNow;
    },
    when: function when(time) {
      return time.add(1, "minute");
    },
    icon: "magic"
  }), buildTimeframe({
    id: "later_today",
    format: "h a",
    enabled: function enabled(opts) {
      return opts.canScheduleToday;
    },
    when: function when(time) {
      return time.hour(18).minute(0);
    },
    icon: "far-moon"
  }), buildTimeframe({
    id: "tomorrow",
    format: "ddd, h a",
    when: function when(time, timeOfDay) {
      return time.add(1, "day").hour(timeOfDay).minute(0);
    },
    icon: "far-sun"
  }), buildTimeframe({
    id: "later_this_week",
    format: "ddd, h a",
    enabled: function enabled(opts) {
      return !opts.canScheduleToday && opts.day > 0 && opts.day < 4;
    },
    when: function when(time, timeOfDay) {
      return time.add(2, "day").hour(timeOfDay).minute(0);
    }
  }), buildTimeframe({
    id: "this_weekend",
    format: "ddd, h a",
    enabled: function enabled(opts) {
      return opts.day > 0 && opts.day < 5 && opts.includeWeekend;
    },
    when: function when(time, timeOfDay) {
      return time.day(6).hour(timeOfDay).minute(0);
    },
    icon: "bed"
  }), buildTimeframe({
    id: "next_week",
    format: "ddd, h a",
    enabled: function enabled(opts) {
      return opts.day !== 0;
    },
    when: function when(time, timeOfDay) {
      return time.add(1, "week").day(1).hour(timeOfDay).minute(0);
    },
    icon: "briefcase"
  }), buildTimeframe({
    id: "two_weeks",
    format: "MMM D",
    when: function when(time, timeOfDay) {
      return time.add(2, "week").hour(timeOfDay).minute(0);
    },
    icon: "briefcase"
  }), buildTimeframe({
    id: "next_month",
    format: "MMM D",
    enabled: function enabled(opts) {
      return opts.now.date() !== moment().endOf("month").date();
    },
    when: function when(time, timeOfDay) {
      return time.add(1, "month").startOf("month").hour(timeOfDay).minute(0);
    },
    icon: "briefcase"
  }), buildTimeframe({
    id: "two_months",
    format: "MMM D",
    enabled: function enabled() {
      return true;
    },
    when: function when(time, timeOfDay) {
      return time.add(2, "month").startOf("month").hour(timeOfDay).minute(0);
    },
    icon: "briefcase"
  }), buildTimeframe({
    id: "three_months",
    format: "MMM D",
    enabled: function enabled() {
      return true;
    },
    when: function when(time, timeOfDay) {
      return time.add(3, "month").startOf("month").hour(timeOfDay).minute(0);
    },
    icon: "briefcase"
  }), buildTimeframe({
    id: "four_months",
    format: "MMM D",
    enabled: function enabled() {
      return true;
    },
    when: function when(time, timeOfDay) {
      return time.add(4, "month").startOf("month").hour(timeOfDay).minute(0);
    },
    icon: "briefcase"
  }), buildTimeframe({
    id: "six_months",
    format: "MMM D",
    enabled: function enabled() {
      return true;
    },
    when: function when(time, timeOfDay) {
      return time.add(6, "month").startOf("month").hour(timeOfDay).minute(0);
    },
    icon: "briefcase"
  }), buildTimeframe({
    id: "one_year",
    format: "MMM D",
    enabled: function enabled(opts) {
      return opts.includeFarFuture;
    },
    when: function when(time, timeOfDay) {
      return time.add(1, "year").startOf("day").hour(timeOfDay).minute(0);
    },
    icon: "briefcase"
  }), buildTimeframe({
    id: "forever",
    enabled: function enabled(opts) {
      return opts.includeFarFuture;
    },
    when: function when(time, timeOfDay) {
      return time.add(1000, "year").hour(timeOfDay).minute(0);
    },
    icon: "gavel",
    displayWhen: false
  }), buildTimeframe({
    id: "pick_date_and_time",
    enabled: function enabled(opts) {
      return opts.includeDateTime;
    },
    icon: "far-calendar-plus"
  })];
  var _timeframeById = null;

  function timeframeDetails(id) {
    if (!_timeframeById) {
      _timeframeById = {};
      TIMEFRAMES.forEach(function (t) {
        return _timeframeById[t.id] = t;
      });
    }

    return _timeframeById[id];
  }

  function buildTimeframes() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return TIMEFRAMES.filter(function (tf) {
      return tf.enabled(options);
    });
  }
});