define("discourse/pre-initializers/discourse-bootstrap", ["exports", "discourse-common/config/environment", "discourse-common/lib/get-url", "I18n", "discourse/lib/preload-store", "discourse/models/session", "discourse-common/lib/deprecated", "discourse-common/lib/get-owner", "discourse-common/lib/icon-library", "discourse/lib/url"], function (_exports, _environment, _getUrl, _I18n, _preloadStore, _session, _deprecated, _getOwner, _iconLibrary, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "discourse-bootstrap",
    // The very first initializer to run
    initialize: function initialize(container, app) {
      (0, _url.setURLContainer)(container);
      (0, _getOwner.setDefaultOwner)(container); // Our test environment has its own bootstrap code

      if ((0, _environment.isTesting)()) {
        return;
      }

      var setupData;
      var setupDataElement = document.getElementById("data-discourse-setup");

      if (setupDataElement) {
        setupData = setupDataElement.dataset;
      }

      var preloaded;
      var preloadedDataElement = document.getElementById("data-preloaded");

      if (preloadedDataElement) {
        preloaded = JSON.parse(preloadedDataElement.dataset.preloaded);
      }

      Object.keys(preloaded).forEach(function (key) {
        _preloadStore.default.store(key, JSON.parse(preloaded[key]));

        if (setupData.debugPreloadedAppData === "true") {
          /* eslint-disable no-console */
          console.log(key, _preloadStore.default.get(key));
          /* eslint-enable no-console */
        }
      });
      var baseUrl = setupData.baseUrl;
      Object.defineProperty(app, "BaseUrl", {
        get: function get() {
          (0, _deprecated.default)("use \"get-url\" helpers instead of Discourse.BaseUrl", {
            since: "2.5",
            dropFrom: "2.6"
          });
          return baseUrl;
        }
      });
      var baseUri = setupData.baseUri;
      Object.defineProperty(app, "BaseUri", {
        get: function get() {
          (0, _deprecated.default)("use \"get-url\" helpers instead of Discourse.BaseUri", {
            since: "2.5",
            dropFrom: "2.6"
          });
          return baseUri;
        }
      });
      (0, _getUrl.setupURL)(setupData.cdn, baseUrl, setupData.baseUri);
      (0, _environment.setEnvironment)(setupData.environment);
      app.SiteSettings = _preloadStore.default.get("siteSettings");
      _I18n.default.defaultLocale = setupData.defaultLocale;
      window.Logster = window.Logster || {};
      window.Logster.enabled = setupData.enableJsErrorReporting === "true";

      var session = _session.default.current();

      session.serviceWorkerURL = setupData.serviceWorkerUrl;
      session.assetVersion = setupData.assetVersion;
      session.disableCustomCSS = setupData.disableCustomCss === "true";
      session.markdownItURL = setupData.markdownItUrl;

      if (setupData.mbLastFileChangeId) {
        session.mbLastFileChangeId = parseInt(setupData.mbLastFileChangeId, 10);
      }

      if (setupData.safeMode) {
        session.safe_mode = setupData.safeMode;
      }

      session.darkModeAvailable = document.head.querySelectorAll('link[media="(prefers-color-scheme: dark)"]').length > 0;
      session.defaultColorSchemeIsDark = setupData.colorSchemeIsDark === "true";
      session.highlightJsPath = setupData.highlightJsPath;
      session.svgSpritePath = setupData.svgSpritePath;
      session.userColorSchemeId = parseInt(setupData.userColorSchemeId, 10) || null;
      session.userDarkSchemeId = parseInt(setupData.userDarkSchemeId, 10) || -1;
      var iconList = setupData.svgIconList;

      if ((0, _environment.isDevelopment)() && iconList) {
        (0, _iconLibrary.setIconList)(typeof iconList === "string" ? JSON.parse(iconList) : iconList);
      }

      if (setupData.s3BaseUrl) {
        (0, _getUrl.setupS3CDN)(setupData.s3BaseUrl, setupData.s3Cdn);
      }

      Ember.RSVP.configure("onerror", function (e) {
        // Ignore TransitionAborted exceptions that bubble up
        if (e && e.message === "TransitionAborted") {
          return;
        }

        if (!(0, _environment.isProduction)()) {
          /* eslint-disable no-console  */
          if (e) {
            if (e.message || e.stack) {
              console.log(e.message);
              console.log(e.stack);
            } else {
              console.log("Uncaught promise: ", e);
            }
          } else {
            console.log("A promise failed but was not caught.");
          }
          /* eslint-enable no-console  */

        }

        window.onerror(e && e.message, null, null, null, e);
      }); // Deprecate lodash usage

      var lo = window._;

      if (lo) {
        Object.keys(lo).forEach(function (m) {
          var old = lo[m];

          lo[m] = function () {
            (0, _deprecated.default)("lodash is deprecated and will be removed from Discourse.", {
              since: "2.6",
              dropFrom: "2.7"
            });
            return old.apply(void 0, arguments);
          };
        });
      }
    }
  };
  _exports.default = _default;
});