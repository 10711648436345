define("discourse/helpers/reviewable-status", ["exports", "discourse/models/reviewable", "I18n", "discourse-common/lib/helpers", "discourse-common/lib/icon-library"], function (_exports, _reviewable, _I18n, _helpers, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.htmlStatus = htmlStatus;
  _exports.default = void 0;

  function dataFor(status) {
    switch (status) {
      case _reviewable.PENDING:
        return {
          name: "pending"
        };

      case _reviewable.APPROVED:
        return {
          icon: "check",
          name: "approved"
        };

      case _reviewable.REJECTED:
        return {
          icon: "times",
          name: "rejected"
        };

      case _reviewable.IGNORED:
        return {
          icon: "external-link-alt",
          name: "ignored"
        };

      case _reviewable.DELETED:
        return {
          icon: "trash-alt",
          name: "deleted"
        };
    }
  }

  function htmlStatus(status) {
    var data = dataFor(status);

    if (!data) {
      return;
    }

    var icon = data.icon ? (0, _iconLibrary.iconHTML)(data.icon) : "";
    return "\n    <span class='status'>\n      <span class=\"".concat(data.name, "\">\n        ").concat(icon, "\n        ").concat(_I18n.default.t("review.statuses." + data.name + ".title"), "\n      </span>\n    </span>\n  ");
  }

  var _default = (0, _helpers.htmlHelper)(function (status) {
    return htmlStatus(status);
  });

  _exports.default = _default;
});