define("discourse/widgets/avatar-flair", ["discourse-common/lib/icon-library", "discourse/widgets/widget", "discourse/lib/utilities"], function (_iconLibrary, _widget, _utilities) {
  "use strict";

  (0, _widget.createWidget)("avatar-flair", {
    tagName: "div.avatar-flair",
    isIcon: function isIcon(attrs) {
      return attrs.flair_url && !attrs.flair_url.includes("/");
    },
    title: function title(attrs) {
      return attrs.flair_name;
    },
    buildClasses: function buildClasses(attrs) {
      var defaultClass = "avatar-flair-".concat(attrs.flair_name, " ").concat(attrs.flair_bg_color ? "rounded" : "");

      if (!this.isIcon(attrs)) {
        defaultClass += " avatar-flair-image";
      }

      return defaultClass;
    },
    buildAttributes: function buildAttributes(attrs) {
      var style = "";

      if (!this.isIcon(attrs)) {
        style += "background-image: url(" + (0, _utilities.escapeExpression)(attrs.flair_url) + "); ";
      }

      if (attrs.flair_bg_color) {
        style += "background-color: #" + (0, _utilities.escapeExpression)(attrs.flair_bg_color) + "; ";
      }

      if (attrs.flair_color) {
        style += "color: #" + (0, _utilities.escapeExpression)(attrs.flair_color) + "; ";
      }

      return {
        style: style
      };
    },
    html: function html(attrs) {
      if (this.isIcon(attrs)) {
        var icon = (0, _iconLibrary.convertIconClass)(attrs.flair_url);
        return [(0, _iconLibrary.iconNode)(icon)];
      } else {
        return [];
      }
    }
  });
});