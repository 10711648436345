define("discourse/components/category-list-item", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var LIST_TYPE = {
    NORMAL: "normal",
    MUTED: "muted"
  };

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("category.isHidden", "category.hasMuted", "listType"), _dec2 = (0, _decorators.default)("category.isMuted", "listType"), (_obj = {
    tagName: "",
    category: null,
    listType: LIST_TYPE.NORMAL,
    isHidden: function isHidden(isHiddenCategory, hasMuted, listType) {
      return isHiddenCategory && listType === LIST_TYPE.NORMAL || !hasMuted && listType === LIST_TYPE.MUTED;
    },
    isMuted: function isMuted(isMutedCategory, listType) {
      return isMutedCategory && listType === LIST_TYPE.NORMAL || !isMutedCategory && listType === LIST_TYPE.MUTED;
    }
  }, (_applyDecoratedDescriptor(_obj, "isHidden", [_dec], Object.getOwnPropertyDescriptor(_obj, "isHidden"), _obj), _applyDecoratedDescriptor(_obj, "isMuted", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isMuted"), _obj)), _obj)));

  _exports.default = _default;
});