define("discourse/components/activation-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: "activation-controls",
    canEditEmail: Ember.computed.or("siteSettings.enable_local_logins", "siteSettings.email_editable")
  });

  _exports.default = _default;
});