define("discourse/widgets/button", ["exports", "discourse/lib/url", "I18n", "discourse/widgets/widget", "virtual-dom", "discourse-common/lib/icon-library"], function (_exports, _url, _I18n, _widget, _virtualDom, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ButtonClass = void 0;
  var ButtonClass = {
    tagName: "button.widget-button.btn",
    buildClasses: function buildClasses(attrs) {
      var className = this.attrs.className || "";
      var hasText = attrs.label || attrs.contents;

      if (!hasText) {
        className += " no-text";
      }

      if (attrs.icon) {
        className += " btn-icon";

        if (hasText) {
          className += "-text";
        }
      } else if (hasText) {
        className += " btn-text";
      }

      return className;
    },
    buildAttributes: function buildAttributes() {
      var attrs = this.attrs;
      var attributes = {};
      var title = attrs.translatedTitle;

      if (!title && attrs.title) {
        title = _I18n.default.t(attrs.title, attrs.titleOptions);
      }

      if (title) {
        attributes.title = title;
      }

      if (attrs.role) {
        attributes["role"] = attrs.role;
      }

      if (attrs.tabAttrs) {
        var tab = attrs.tabAttrs;
        attributes["aria-selected"] = tab["aria-selected"];
        attributes["tabindex"] = tab["tabindex"];
        attributes["aria-controls"] = tab["aria-controls"];
        attributes["id"] = attrs.id;
      }

      if (attrs.disabled) {
        attributes.disabled = "true";
      }

      if (attrs.data) {
        Object.keys(attrs.data).forEach(function (k) {
          return attributes["data-".concat(k)] = attrs.data[k];
        });
      }

      return attributes;
    },
    _buildIcon: function _buildIcon(attrs) {
      var icon = (0, _iconLibrary.iconNode)(attrs.icon, {
        class: attrs.iconClass
      });

      if (attrs["aria-label"]) {
        icon.properties.attributes["role"] = "img";
        icon.properties.attributes["aria-hidden"] = false;
      }

      return icon;
    },
    html: function html(attrs) {
      var contents = [];
      var left = !attrs.iconRight;

      if (attrs.icon && left) {
        contents.push(this._buildIcon(attrs));
      }

      if (attrs.label) {
        contents.push((0, _virtualDom.h)("span.d-button-label", _I18n.default.t(attrs.label, attrs.labelOptions)));
      }

      if (attrs.translatedLabel) {
        contents.push((0, _virtualDom.h)("span.d-button-label", attrs.translatedLabel.toString(), attrs.translatedLabelOptions));
      }

      if (attrs.contents) {
        contents.push(attrs.contents);
      }

      if (attrs.icon && !left) {
        contents.push(this._buildIcon(attrs));
      }

      return contents;
    },
    click: function click(e) {
      var attrs = this.attrs;

      if (attrs.disabled) {
        return;
      }

      $("button.widget-button").removeClass("d-hover").blur();

      if (attrs.secondaryAction) {
        this.sendWidgetAction(attrs.secondaryAction);
      }

      if (attrs.url) {
        return _url.default.routeTo(attrs.url);
      }

      if (attrs.sendActionEvent) {
        return this.sendWidgetAction(attrs.action, e);
      }

      return this.sendWidgetAction(attrs.action, attrs.actionParam);
    }
  };
  _exports.ButtonClass = ButtonClass;

  var _default = (0, _widget.createWidget)("button", ButtonClass);

  _exports.default = _default;
  (0, _widget.createWidget)("flat-button", Object.assign(ButtonClass, {
    tagName: "button.widget-button.btn-flat"
  }));
});