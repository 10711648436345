define("discourse/lib/posts-with-placeholders", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Placeholder = Placeholder;
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function Placeholder(viewName) {
    this.viewName = viewName;
  }

  var _default = Ember.Object.extend(Ember.Array, (_obj = {
    posts: null,
    _appendingIds: null,
    init: function init() {
      this._appendingIds = {};
    },
    length: function length() {
      return this.get("posts.length") + Object.keys(this._appendingIds || {}).length;
    },
    nextObject: function nextObject(index) {
      return this.objectAt(index);
    },
    _changeArray: function _changeArray(cb, offset, removed, inserted) {
      this.arrayContentWillChange(offset, removed, inserted);
      cb();
      this.arrayContentDidChange(offset, removed, inserted);
      this.notifyPropertyChange("length");
    },
    clear: function clear(cb) {
      this._changeArray(cb, 0, this.get("posts.length"), 0);
    },
    appendPost: function appendPost(cb) {
      this._changeArray(cb, this.get("posts.length"), 0, 1);
    },
    removePost: function removePost(cb) {
      this._changeArray(cb, this.get("posts.length") - 1, 1, 0);
    },
    refreshAll: function refreshAll(cb) {
      var length = this.get("posts.length");

      this._changeArray(cb, 0, length, length);
    },
    appending: function appending(postIds) {
      var _this = this;

      this._changeArray(function () {
        var appendingIds = _this._appendingIds;
        postIds.forEach(function (pid) {
          return appendingIds[pid] = true;
        });
      }, this.length, 0, postIds.length);
    },
    finishedAppending: function finishedAppending(postIds) {
      var _this2 = this;

      this._changeArray(function () {
        var appendingIds = _this2._appendingIds;
        postIds.forEach(function (pid) {
          return delete appendingIds[pid];
        });
      }, this.get("posts.length") - postIds.length, postIds.length, postIds.length);
    },
    finishedPrepending: function finishedPrepending(postIds) {
      this._changeArray(function () {}, 0, 0, postIds.length);
    },
    objectAt: function objectAt(index) {
      var posts = this.posts;
      return index < posts.length ? posts[index] : new Placeholder("post-placeholder");
    }
  }, (_applyDecoratedDescriptor(_obj, "length", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "length"), _obj)), _obj));

  _exports.default = _default;
});