define("discourse/routes/user-invited", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    setupController: function setupController(controller) {
      var _this$currentUser;

      var can_see_invite_details = this.currentUser.staff || this.controllerFor("user").id === ((_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id);
      controller.setProperties({
        can_see_invite_details: can_see_invite_details
      });
    }
  });

  _exports.default = _default;
});