define("discourse/adapters/published-page", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    jsonMode: true,
    pathFor: function pathFor(store, type, id) {
      return "/pub/by-topic/".concat(id);
    }
  });

  _exports.default = _default;
});