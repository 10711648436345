define("discourse/widgets/user-notifications-large", ["exports", "discourse/widgets/widget", "discourse/helpers/node", "virtual-dom"], function (_exports, _widget, _node, _virtualDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  (0, _widget.createWidget)("large-notification-item", {
    tagName: "li",
    buildClasses: function buildClasses(attrs) {
      var result = ["item", "notification", "large-notification"];

      if (!attrs.get("read")) {
        result.push("unread");
      }

      return result;
    },
    html: function html(attrs) {
      var notificationName = this.site.notificationLookup[attrs.notification_type];
      return [this.attach("".concat(notificationName.dasherize(), "-notification-item"), attrs, {}, {
        fallbackWidgetName: "default-notification-item",
        tagName: "div"
      }), (0, _virtualDom.h)("span.time", (0, _node.dateNode)(attrs.created_at))];
    }
  });

  var _default = (0, _widget.createWidget)("user-notifications-large", {
    tagName: "ul.notifications.large-notifications",
    html: function html(attrs) {
      var _this = this;

      var notifications = attrs.notifications;
      var username = notifications.findArgs.username;
      return notifications.map(function (n) {
        n.username = username;
        return _this.attach("large-notification-item", n);
      });
    }
  });

  _exports.default = _default;
});