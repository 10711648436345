define("discourse/controllers/raw-email", ["exports", "admin/models/incoming-email", "discourse/mixins/modal-functionality", "discourse/models/post"], function (_exports, _incomingEmail, _modalFunctionality, _post) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This controller handles displaying of raw email
  var _default = Ember.Controller.extend(_modalFunctionality.default, {
    rawEmail: "",
    textPart: "",
    htmlPart: "",
    tab: "raw",
    showRawEmail: Ember.computed.equal("tab", "raw"),
    showTextPart: Ember.computed.equal("tab", "text_part"),
    showHtmlPart: Ember.computed.equal("tab", "html_part"),
    onShow: function onShow() {
      this.send("displayRaw");
    },
    loadRawEmail: function loadRawEmail(postId) {
      var _this = this;

      return _post.default.loadRawEmail(postId).then(function (result) {
        return _this.setProperties({
          rawEmail: result.raw_email,
          textPart: result.text_part,
          htmlPart: result.html_part
        });
      });
    },
    loadIncomingRawEmail: function loadIncomingRawEmail(incomingEmailId) {
      var _this2 = this;

      return _incomingEmail.default.loadRawEmail(incomingEmailId).then(function (result) {
        return _this2.setProperties({
          rawEmail: result.raw_email,
          textPart: result.text_part,
          htmlPart: result.html_part
        });
      });
    },
    actions: {
      displayRaw: function displayRaw() {
        this.set("tab", "raw");
      },
      displayTextPart: function displayTextPart() {
        this.set("tab", "text_part");
      },
      displayHtmlPart: function displayHtmlPart() {
        this.set("tab", "html_part");
      }
    }
  });

  _exports.default = _default;
});