define("discourse/templates/composer/dominating-topic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yuFmkCa8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"href\",\"\"],[12,\"class\",\"close\"],[3,\"action\",[[23,0,[]],[24,[\"closeMessage\"]],[24,[\"message\"]]]],[8],[1,[28,\"d-icon\",[\"times\"],null],false],[9],[0,\"\\n\\n\"],[1,[28,\"html-safe\",[[24,[\"message\",\"body\"]]],null],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"currentUser\",\"can_invite_to_forum\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"d-button\",null,[[\"class\",\"label\",\"icon\",\"action\"],[\"btn-primary\",\"footer_nav.share\",\"link\",[28,\"action\",[[23,0,[]],[24,[\"shareModal\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/composer/dominating-topic.hbs"
    }
  });

  _exports.default = _default;
});