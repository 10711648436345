define("discourse/routes/group-members", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    beforeModel: function beforeModel() {
      this.transitionTo("group.index");
    }
  });

  _exports.default = _default;
});