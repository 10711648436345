define("discourse/templates/group-activity-posts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6g61Egpw",
    "block": "{\"symbols\":[\"post\"],\"statements\":[[4,\"load-more\",null,[[\"selector\",\"action\"],[\".user-stream-item\",[28,\"action\",[[23,0,[]],\"loadMore\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"user-stream\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"group-post\",null,[[\"post\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[1,[28,\"i18n\",[[24,[\"emptyText\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[1,[28,\"conditional-loading-spinner\",null,[[\"condition\"],[[24,[\"loading\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/group-activity-posts.hbs"
    }
  });

  _exports.default = _default;
});