define("discourse/templates/modal/bulk-actions-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bDmDl7Ki",
    "block": "{\"symbols\":[\"button\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"bulk-buttons\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"buttons\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"d-button\",null,[[\"action\",\"label\",\"icon\",\"class\"],[[28,\"action\",[[23,0,[]],[23,1,[\"action\"]]],null],[23,1,[\"label\"]],[23,1,[\"icon\"]],[23,1,[\"class\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/modal/bulk-actions-buttons.hbs"
    }
  });

  _exports.default = _default;
});