define("discourse/models/post", ["exports", "discourse/models/action-summary", "discourse/models/composer", "I18n", "discourse/models/rest", "discourse/models/site", "discourse/models/user", "discourse/lib/ajax", "discourse/lib/text", "discourse-common/utils/decorators", "discourse/lib/topic-fancy-title", "discourse/lib/ajax-error", "discourse/lib/utilities", "discourse/lib/computed", "discourse/helpers/share-url", "discourse/lib/url"], function (_exports, _actionSummary, _composer, _I18n, _rest, _site, _user, _ajax, _text, _decorators, _topicFancyTitle, _ajaxError, _utilities, _computed, _shareUrl, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _obj, _init;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var Post = _rest.default.extend((_dec = (0, _decorators.default)("url", "customShare"), _dec2 = (0, _decorators.default)("name", "username"), _dec3 = (0, _decorators.default)("firstPost", "deleted_by", "topic.deleted_by"), _dec4 = (0, _decorators.default)("firstPost", "deleted_at", "topic.deleted_at"), _dec5 = (0, _decorators.default)("post_number", "topic_id", "topic.slug"), _dec6 = (0, _decorators.default)("post_number", "url"), _dec7 = (0, _decorators.default)("username"), _dec8 = (0, _decorators.default)("link_counts.@each.internal"), _dec9 = (0, _decorators.default)("actions_summary.@each.can_act"), _dec10 = (0, _decorators.default)("siteSettings.use_pg_headlines_for_excerpt", "topic_title_headline"), _dec11 = (0, _decorators.default)("topic_title_headline"), (_obj = {
    customShare: null,
    shareUrl: function shareUrl(url) {
      if (this.customShare) {
        return this.customShare;
      }

      var user = _user.default.current();

      return (0, _shareUrl.resolveShareUrl)(url, user);
    },
    new_user: Ember.computed.equal("trust_level", 0),
    firstPost: Ember.computed.equal("post_number", 1),
    // Posts can show up as deleted if the topic is deleted
    deletedViaTopic: Ember.computed.and("firstPost", "topic.deleted_at"),
    deleted: Ember.computed.or("deleted_at", "deletedViaTopic"),
    notDeleted: Ember.computed.not("deleted"),
    showName: function showName(name, username) {
      return name && name !== username && this.siteSettings.display_name_on_posts;
    },
    postDeletedBy: function postDeletedBy(firstPost, deletedBy, topicDeletedBy) {
      return firstPost ? topicDeletedBy : deletedBy;
    },
    postDeletedAt: function postDeletedAt(firstPost, deletedAt, topicDeletedAt) {
      return firstPost ? topicDeletedAt : deletedAt;
    },
    url: function url(post_number, topic_id, topicSlug) {
      return (0, _utilities.postUrl)(topicSlug || this.topic_slug, topic_id || this.get("topic.id"), post_number);
    },
    urlWithNumber: function urlWithNumber(postNumber, baseUrl) {
      return postNumber === 1 ? "".concat(baseUrl, "/1") : baseUrl;
    },
    usernameUrl: _url.userPath,
    topicOwner: (0, _computed.propertyEqual)("topic.details.created_by.id", "user_id"),
    updatePostField: function updatePostField(field, value) {
      var _this = this;

      var data = {};
      data[field] = value;
      return (0, _ajax.ajax)("/posts/".concat(this.id, "/").concat(field), {
        type: "PUT",
        data: data
      }).then(function () {
        return _this.set(field, value);
      }).catch(_ajaxError.popupAjaxError);
    },
    internalLinks: function internalLinks() {
      if (Ember.isEmpty(this.link_counts)) {
        return null;
      }

      return this.link_counts.filterBy("internal").filterBy("title");
    },
    flagsAvailable: function flagsAvailable() {
      var _this2 = this;

      // TODO: Investigate why `this.site` is sometimes null when running
      // Search - Search with context
      if (!this.site) {
        return [];
      }

      return this.site.flagTypes.filter(function (item) {
        return _this2.get("actionByName.".concat(item.name_key, ".can_act"));
      });
    },
    useTopicTitleHeadline: function useTopicTitleHeadline(enabled, title) {
      return enabled && title;
    },
    topicTitleHeadline: function topicTitleHeadline(title) {
      return (0, _topicFancyTitle.fancyTitle)(title, this.siteSettings.support_mixed_text_direction);
    },
    afterUpdate: function afterUpdate(res) {
      if (res.category) {
        this.site.updateCategory(res.category);
      }
    },
    updateProperties: function updateProperties() {
      return {
        post: {
          raw: this.raw,
          edit_reason: this.editReason
        },
        image_sizes: this.imageSizes
      };
    },
    createProperties: function createProperties() {
      // composer only used once, defer the dependency
      var data = this.getProperties(_composer.default.serializedFieldsForCreate());
      data.reply_to_post_number = this.reply_to_post_number;
      data.image_sizes = this.imageSizes;
      var metaData = this.metaData; // Put the metaData into the request

      if (metaData) {
        data.meta_data = {};
        Object.keys(metaData).forEach(function (key) {
          return data.meta_data[key] = metaData[key];
        });
      }

      return data;
    },
    // Expands the first post's content, if embedded and shortened.
    expand: function expand() {
      var _this3 = this;

      return (0, _ajax.ajax)("/posts/".concat(this.id, "/expand-embed")).then(function (post) {
        _this3.set("cooked", "<section class=\"expanded-embed\">".concat(post.cooked, "</section>"));
      });
    },
    // Recover a deleted post
    recover: function recover() {
      var _this4 = this;

      var initProperties = this.getProperties("deleted_at", "deleted_by", "user_deleted", "can_delete");
      this.setProperties({
        deleted_at: null,
        deleted_by: null,
        user_deleted: false,
        can_delete: false
      });
      return (0, _ajax.ajax)("/posts/".concat(this.id, "/recover"), {
        type: "PUT"
      }).then(function (data) {
        _this4.setProperties({
          cooked: data.cooked,
          raw: data.raw,
          user_deleted: false,
          can_delete: true,
          version: data.version
        });
      }).catch(function (error) {
        (0, _ajaxError.popupAjaxError)(error);

        _this4.setProperties(initProperties);
      });
    },

    /**
      Changes the state of the post to be deleted. Does not call the server, that should be
      done elsewhere.
    **/
    setDeletedState: function setDeletedState(deletedBy) {
      var _this5 = this;

      var promise;
      this.set("oldCooked", this.cooked); // Moderators can delete posts. Users can only trigger a deleted at message, unless delete_removed_posts_after is 0.

      if (deletedBy.staff || this.siteSettings.delete_removed_posts_after === 0) {
        this.setProperties({
          deleted_at: new Date(),
          deleted_by: deletedBy,
          can_delete: false,
          can_permanently_delete: this.siteSettings.can_permanently_delete && deletedBy.admin,
          can_recover: true
        });
      } else {
        var key = this.post_number === 1 ? "topic.deleted_by_author_simple" : "post.deleted_by_author_simple";
        promise = (0, _text.cookAsync)(_I18n.default.t(key)).then(function (cooked) {
          _this5.setProperties({
            cooked: cooked,
            can_delete: false,
            can_permanently_delete: false,
            version: _this5.version + 1,
            can_recover: true,
            can_edit: false,
            user_deleted: true
          });
        });
      }

      return promise || Ember.RSVP.Promise.resolve();
    },

    /**
      Changes the state of the post to NOT be deleted. Does not call the server.
      This can only be called after setDeletedState was called, but the delete
      failed on the server.
    **/
    undoDeleteState: function undoDeleteState() {
      if (this.oldCooked) {
        this.setProperties({
          deleted_at: null,
          deleted_by: null,
          cooked: this.oldCooked,
          version: this.version - 1,
          can_recover: false,
          can_delete: true,
          user_deleted: false
        });
      }
    },
    destroy: function destroy(deletedBy, opts) {
      var _this6 = this;

      return this.setDeletedState(deletedBy).then(function () {
        return (0, _ajax.ajax)("/posts/" + _this6.id, {
          data: _objectSpread({
            context: window.location.pathname
          }, opts),
          type: "DELETE"
        });
      });
    },

    /**
      Updates a post from another's attributes. This will normally happen when a post is loading but
      is already found in an identity map.
    **/
    updateFromPost: function updateFromPost(otherPost) {
      var _this7 = this;

      Object.keys(otherPost).forEach(function (key) {
        var value = otherPost[key],
            oldValue = _this7[key];

        if (!value) {
          value = null;
        }

        if (!oldValue) {
          oldValue = null;
        }

        var skip = false;

        if (typeof value !== "function" && oldValue !== value) {
          // wishing for an identity map
          if (key === "reply_to_user" && value && oldValue) {
            skip = value.username === oldValue.username || Ember.get(value, "username") === Ember.get(oldValue, "username");
          }

          if (!skip) {
            _this7.set(key, value);
          }
        }
      });
    },
    expandHidden: function expandHidden() {
      var _this8 = this;

      return (0, _ajax.ajax)("/posts/".concat(this.id, "/cooked.json")).then(function (result) {
        _this8.setProperties({
          cooked: result.cooked,
          cooked_hidden: false
        });
      });
    },
    rebake: function rebake() {
      return (0, _ajax.ajax)("/posts/".concat(this.id, "/rebake"), {
        type: "PUT"
      });
    },
    unhide: function unhide() {
      return (0, _ajax.ajax)("/posts/".concat(this.id, "/unhide"), {
        type: "PUT"
      });
    },
    createBookmark: function createBookmark(data) {
      this.setProperties({
        "topic.bookmarked": true,
        bookmarked: true,
        bookmark_reminder_at: data.reminder_at,
        bookmark_auto_delete_preference: data.auto_delete_preference,
        bookmark_name: data.name,
        bookmark_id: data.id
      });
      this.topic.incrementProperty("bookmarksWereChanged");
      this.appEvents.trigger("bookmarks:changed", data, {
        target: "post",
        targetId: this.id
      }); // TODO (martin) (2022-02-01) Remove these old bookmark events, replaced by bookmarks:changed.

      this.appEvents.trigger("page:bookmark-post-toggled", this);
      this.appEvents.trigger("post-stream:refresh", {
        id: this.id
      });
    },
    deleteBookmark: function deleteBookmark(bookmarked) {
      this.set("topic.bookmarked", bookmarked);
      this.clearBookmark();
    },
    clearBookmark: function clearBookmark() {
      this.setProperties({
        bookmark_reminder_at: null,
        bookmark_name: null,
        bookmark_id: null,
        bookmarked: false,
        bookmark_auto_delete_preference: null
      });
      this.topic.incrementProperty("bookmarksWereChanged");
      this.appEvents.trigger("bookmarks:changed", null, {
        target: "post",
        targetId: this.id
      }); // TODO (martin) (2022-02-01) Remove these old bookmark events, replaced by bookmarks:changed.

      this.appEvents.trigger("page:bookmark-post-toggled", this);
    },
    updateActionsSummary: function updateActionsSummary(json) {
      if (json && json.id === this.id) {
        json = Post.munge(json);
        this.set("actions_summary", json.actions_summary);
      }
    },
    updateLikeCount: function updateLikeCount(count) {
      var current_actions_summary = this.get("actions_summary");

      var likeActionID = _site.default.current().post_action_types.find(function (a) {
        return a.name_key === "like";
      }).id;

      if (!this.actions_summary.find(function (entry) {
        return entry.id === likeActionID;
      })) {
        var json = Post.munge({
          id: this.id,
          actions_summary: [{
            id: likeActionID,
            count: count
          }]
        });
        this.set("actions_summary", Object.assign(current_actions_summary, json.actions_summary));
        this.set("actionByName", json.actionByName);
        this.set("likeAction", json.likeAction);
      } else {
        this.actions_summary.find(function (entry) {
          return entry.id === likeActionID;
        }).count = count;
        this.actionByName["like"] = count;
        this.likeAction.count = count;
      }
    },
    revertToRevision: function revertToRevision(version) {
      return (0, _ajax.ajax)("/posts/".concat(this.id, "/revisions/").concat(version, "/revert"), {
        type: "PUT"
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "shareUrl", [_dec], Object.getOwnPropertyDescriptor(_obj, "shareUrl"), _obj), _applyDecoratedDescriptor(_obj, "showName", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showName"), _obj), _applyDecoratedDescriptor(_obj, "postDeletedBy", [_dec3], Object.getOwnPropertyDescriptor(_obj, "postDeletedBy"), _obj), _applyDecoratedDescriptor(_obj, "postDeletedAt", [_dec4], Object.getOwnPropertyDescriptor(_obj, "postDeletedAt"), _obj), _applyDecoratedDescriptor(_obj, "url", [_dec5], Object.getOwnPropertyDescriptor(_obj, "url"), _obj), _applyDecoratedDescriptor(_obj, "urlWithNumber", [_dec6], Object.getOwnPropertyDescriptor(_obj, "urlWithNumber"), _obj), _applyDecoratedDescriptor(_obj, "usernameUrl", [_dec7], (_init = Object.getOwnPropertyDescriptor(_obj, "usernameUrl"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "internalLinks", [_dec8], Object.getOwnPropertyDescriptor(_obj, "internalLinks"), _obj), _applyDecoratedDescriptor(_obj, "flagsAvailable", [_dec9], Object.getOwnPropertyDescriptor(_obj, "flagsAvailable"), _obj), _applyDecoratedDescriptor(_obj, "useTopicTitleHeadline", [_dec10], Object.getOwnPropertyDescriptor(_obj, "useTopicTitleHeadline"), _obj), _applyDecoratedDescriptor(_obj, "topicTitleHeadline", [_dec11], Object.getOwnPropertyDescriptor(_obj, "topicTitleHeadline"), _obj)), _obj)));

  Post.reopenClass({
    munge: function munge(json) {
      if (json.actions_summary) {
        var lookup = Ember.Object.create(); // this area should be optimized, it is creating way too many objects per post

        json.actions_summary = json.actions_summary.map(function (a) {
          a.actionType = _site.default.current().postActionTypeById(a.id);
          a.count = a.count || 0;

          var actionSummary = _actionSummary.default.create(a);

          lookup[a.actionType.name_key] = actionSummary;

          if (a.actionType.name_key === "like") {
            json.likeAction = actionSummary;
          }

          return actionSummary;
        });
        json.actionByName = lookup;
      }

      if (json && json.reply_to_user) {
        json.reply_to_user = _user.default.create(json.reply_to_user);
      }

      return json;
    },
    updateBookmark: function updateBookmark(postId, bookmarked) {
      return (0, _ajax.ajax)("/posts/".concat(postId, "/bookmark"), {
        type: "PUT",
        data: {
          bookmarked: bookmarked
        }
      });
    },
    destroyBookmark: function destroyBookmark(postId) {
      return (0, _ajax.ajax)("/posts/".concat(postId, "/bookmark"), {
        type: "DELETE"
      });
    },
    deleteMany: function deleteMany(post_ids) {
      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref$agreeWithFirstRe = _ref.agreeWithFirstReplyFlag,
          agreeWithFirstReplyFlag = _ref$agreeWithFirstRe === void 0 ? true : _ref$agreeWithFirstRe;

      return (0, _ajax.ajax)("/posts/destroy_many", {
        type: "DELETE",
        data: {
          post_ids: post_ids,
          agree_with_first_reply_flag: agreeWithFirstReplyFlag
        }
      });
    },
    mergePosts: function mergePosts(post_ids) {
      return (0, _ajax.ajax)("/posts/merge_posts", {
        type: "PUT",
        data: {
          post_ids: post_ids
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    loadRevision: function loadRevision(postId, version) {
      return (0, _ajax.ajax)("/posts/".concat(postId, "/revisions/").concat(version, ".json")).then(function (result) {
        return Ember.Object.create(result);
      });
    },
    hideRevision: function hideRevision(postId, version) {
      return (0, _ajax.ajax)("/posts/".concat(postId, "/revisions/").concat(version, "/hide"), {
        type: "PUT"
      });
    },
    showRevision: function showRevision(postId, version) {
      return (0, _ajax.ajax)("/posts/".concat(postId, "/revisions/").concat(version, "/show"), {
        type: "PUT"
      });
    },
    loadRawEmail: function loadRawEmail(postId) {
      return (0, _ajax.ajax)("/posts/".concat(postId, "/raw-email.json"));
    }
  });
  var _default = Post;
  _exports.default = _default;
});