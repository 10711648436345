define("discourse/routes/unknown", ["exports", "discourse/routes/discourse", "discourse/lib/url", "discourse/lib/ajax"], function (_exports, _discourse, _url, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    model: function model(_, transition) {
      var path = transition.intent.url;

      if (!this.currentUser && this.siteSettings.login_required) {
        return;
      }

      return (0, _ajax.ajax)("/permalink-check.json", {
        data: {
          path: path
        }
      }).then(function (results) {
        if (results.found) {
          // Avoid polluting the history stack for external links
          transition.abort();
          var url = results.target_url;

          if (transition._discourse_anchor) {
            // Remove the anchor from the permalink if present
            url = url.split("#")[0]; // Add the anchor from the transition

            url += "#".concat(transition._discourse_anchor);
          }

          _url.default.routeTo(url);

          return "";
        } else {
          // 404 body HTML
          return results.html;
        }
      });
    }
  });

  _exports.default = _default;
});