define("discourse/templates/components/empty-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JzwKbluD",
    "block": "{\"symbols\":[\"@title\",\"@body\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"empty-state-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"empty-state\"],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"data-test-title\",\"\"],[10,\"class\",\"empty-state-title\"],[8],[1,[23,1,[]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"empty-state-body\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"data-test-body\",\"\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/empty-state.hbs"
    }
  });

  _exports.default = _default;
});