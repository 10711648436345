define("discourse/pre-initializers/map-routes", ["exports", "discourse-common/config/environment", "discourse/mapping-router"], function (_exports, _environment, _mappingRouter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var originalBuildInstance;
  var _default = {
    name: "map-routes",
    after: "inject-discourse-objects",
    initialize: function initialize(container, app) {
      var routerClass = (0, _mappingRouter.registerRouter)(app);
      container.registry.register("router:main", routerClass);
      this.routerClass = routerClass;

      if ((0, _environment.isLegacyEmber)()) {
        // HACK to fix: https://github.com/emberjs/ember.js/issues/10310
        originalBuildInstance = originalBuildInstance || Ember.Application.prototype.buildInstance;

        Ember.Application.prototype.buildInstance = function () {
          this.buildRegistry();
          return originalBuildInstance.apply(this);
        };
      }
    },
    teardown: function teardown() {
      (0, _mappingRouter.teardownRouter)(this.routerClass);
    }
  };
  _exports.default = _default;
});