define("discourse/models/private-message-topic-tracking-state", ["exports", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/ajax-error", "discourse-common/lib/object", "discourse/routes/build-private-messages-route", "discourse/lib/notification-levels"], function (_exports, _ajax, _decorators, _ajaxError, _object, _buildPrivateMessagesRoute, _notificationLevels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // See private_message_topic_tracking_state.rb for documentation
  var PrivateMessageTopicTrackingState = Ember.Object.extend((_dec = (0, _decorators.on)("init"), (_obj = {
    CHANNEL_PREFIX: "/private-message-topic-tracking-state",
    inbox: null,
    filter: null,
    activeGroup: null,
    _setup: function _setup() {
      this.states = new Map();
      this.statesModificationCounter = 0;
      this.isTracking = false;
      this.newIncoming = [];
      this.stateChangeCallbacks = {};
    },
    onStateChange: function onStateChange(name, callback) {
      this.stateChangeCallbacks[name] = callback;
    },
    startTracking: function startTracking() {
      var _this = this;

      if (this.isTracking) {
        return;
      }

      this._establishChannels();

      return this._loadInitialState().finally(function () {
        _this.set("isTracking", true);
      });
    },
    _establishChannels: function _establishChannels() {
      var _this$currentUser$gro,
          _this2 = this;

      this.messageBus.subscribe(this._userChannel(), this._processMessage.bind(this));
      (_this$currentUser$gro = this.currentUser.groupsWithMessages) === null || _this$currentUser$gro === void 0 ? void 0 : _this$currentUser$gro.forEach(function (group) {
        _this2.messageBus.subscribe(_this2._groupChannel(group.id), _this2._processMessage.bind(_this2));
      });
    },
    lookupCount: function lookupCount(type) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var typeFilterFn = type === "new" ? this._isNew : this._isUnread;
      var inbox = opts.inboxFilter || this.inbox;
      var filterFn;

      if (inbox === "user") {
        filterFn = this._isPersonal;
      } else if (inbox === "group") {
        filterFn = this._isGroup;
      }

      return Array.from(this.states.values()).filter(function (topic) {
        var _filterFn;

        return typeFilterFn(topic) && ((_filterFn = filterFn) === null || _filterFn === void 0 ? void 0 : _filterFn(topic, opts.groupName));
      }).length;
    },
    trackIncoming: function trackIncoming(inbox, filter, group) {
      this.setProperties({
        inbox: inbox,
        filter: filter,
        activeGroup: group,
        isTrackingIncoming: true
      });
    },
    resetIncomingTracking: function resetIncomingTracking() {
      if (this.isTrackingIncoming) {
        this.set("newIncoming", []);
      }
    },
    stopIncomingTracking: function stopIncomingTracking() {
      if (this.isTrackingIncoming) {
        this.setProperties({
          isTrackingIncoming: false,
          newIncoming: []
        });
      }
    },
    removeTopics: function removeTopics(topicIds) {
      var _this3 = this;

      if (!this.isTracking) {
        return;
      }

      topicIds.forEach(function (topicId) {
        return _this3.states.delete(topicId);
      });

      this._afterStateChange();
    },
    _userChannel: function _userChannel() {
      return "".concat(this.CHANNEL_PREFIX, "/user/").concat(this.currentUser.id);
    },
    _groupChannel: function _groupChannel(groupId) {
      return "".concat(this.CHANNEL_PREFIX, "/group/").concat(groupId);
    },
    _isNew: function _isNew(topic) {
      return !topic.last_read_post_number && (topic.notification_level !== 0 && !topic.notification_level || topic.notification_level >= _notificationLevels.NotificationLevels.TRACKING) && !topic.is_seen;
    },
    _isUnread: function _isUnread(topic) {
      return topic.last_read_post_number && topic.last_read_post_number < topic.highest_post_number && topic.notification_level >= _notificationLevels.NotificationLevels.TRACKING;
    },
    _isPersonal: function _isPersonal(topic) {
      var _this$currentUser;

      var groups = (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.groups;

      if (!groups || groups.length === 0) {
        return true;
      }

      return !groups.some(function (group) {
        var _topic$group_ids;

        return (_topic$group_ids = topic.group_ids) === null || _topic$group_ids === void 0 ? void 0 : _topic$group_ids.includes(group.id);
      });
    },
    _isGroup: function _isGroup(topic, activeGroupName) {
      var _this4 = this;

      return this.currentUser.groups.some(function (group) {
        var _topic$group_ids2;

        return group.name === (activeGroupName || _this4.activeGroup.name) && ((_topic$group_ids2 = topic.group_ids) === null || _topic$group_ids2 === void 0 ? void 0 : _topic$group_ids2.includes(group.id));
      });
    },
    _processMessage: function _processMessage(message) {
      switch (message.message_type) {
        case "new_topic":
          this._modifyState(message.topic_id, message.payload);

          if ([_buildPrivateMessagesRoute.NEW_FILTER, _buildPrivateMessagesRoute.INBOX_FILTER].includes(this.filter) && this._shouldDisplayMessageForInbox(message)) {
            this._notifyIncoming(message.topic_id);
          }

          break;

        case "read":
          this._modifyState(message.topic_id, message.payload);

          break;

        case "unread":
          this._modifyState(message.topic_id, message.payload);

          if ([_buildPrivateMessagesRoute.UNREAD_FILTER, _buildPrivateMessagesRoute.INBOX_FILTER].includes(this.filter) && this._shouldDisplayMessageForInbox(message)) {
            this._notifyIncoming(message.topic_id);
          }

          break;

        case "group_archive":
          if ([_buildPrivateMessagesRoute.INBOX_FILTER, _buildPrivateMessagesRoute.ARCHIVE_FILTER].includes(this.filter) && (!message.payload.acting_user_id || message.payload.acting_user_id !== this.currentUser.id) && this._displayMessageForGroupInbox(message)) {
            this._notifyIncoming(message.topic_id);
          }

          break;
      }
    },
    _displayMessageForGroupInbox: function _displayMessageForGroupInbox(message) {
      return this.inbox === "group" && message.payload.group_ids.includes(this.activeGroup.id);
    },
    _shouldDisplayMessageForInbox: function _shouldDisplayMessageForInbox(message) {
      return this._displayMessageForGroupInbox(message) || this.inbox === "user" && (message.payload.group_ids.length === 0 || this.currentUser.groups.filter(function (group) {
        return message.payload.group_ids.includes(group.id);
      }).length === 0);
    },
    _notifyIncoming: function _notifyIncoming(topicId) {
      if (this.isTrackingIncoming && !this.newIncoming.includes(topicId)) {
        this.newIncoming.pushObject(topicId);
      }
    },
    _loadInitialState: function _loadInitialState() {
      var _this5 = this;

      return (0, _ajax.ajax)("/u/".concat(this.currentUser.username, "/private-message-topic-tracking-state")).then(function (pmTopicTrackingStateData) {
        pmTopicTrackingStateData.forEach(function (topic) {
          _this5._modifyState(topic.topic_id, topic, {
            skipIncrement: true
          });
        });
      }).catch(_ajaxError.popupAjaxError);
    },
    _modifyState: function _modifyState(topicId, data) {
      var opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var oldState = this.states.get(topicId);
      var newState = data;

      if (oldState && !(0, _object.deepEqual)(oldState, newState)) {
        newState = (0, _object.deepMerge)(oldState, newState);
      }

      this.states.set(topicId, newState);

      if (!opts.skipIncrement) {
        this._afterStateChange();
      }
    },
    _afterStateChange: function _afterStateChange() {
      this.incrementProperty("statesModificationCounter");
      Object.values(this.stateChangeCallbacks).forEach(function (callback) {
        return callback();
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "_setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "_setup"), _obj), _applyDecoratedDescriptor(_obj, "_isPersonal", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_isPersonal"), _obj), _applyDecoratedDescriptor(_obj, "_isGroup", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_isGroup"), _obj), _applyDecoratedDescriptor(_obj, "_processMessage", [_decorators.bind], Object.getOwnPropertyDescriptor(_obj, "_processMessage"), _obj)), _obj)));
  var _default = PrivateMessageTopicTrackingState;
  _exports.default = _default;
});