define("discourse/components/badge-card", ["exports", "discourse/lib/text", "discourse-common/utils/decorators"], function (_exports, _text, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("badge.url", "filterUser", "username"), _dec2 = (0, _decorators.default)("count", "badge.grant_count"), _dec3 = (0, _decorators.default)("size", "badge.long_description", "badge.description"), _dec4 = (0, _decorators.default)("badge.id"), (_obj = {
    size: "medium",
    classNameBindings: [":badge-card", "size", "badge.slug"],
    url: function url(badgeUrl, filterUser, username) {
      return filterUser ? "".concat(badgeUrl, "?username=").concat(username) : badgeUrl;
    },
    displayCount: function displayCount(count, grantCount) {
      if (count == null) {
        return grantCount;
      }

      if (count > 1) {
        return count;
      }
    },
    summary: function summary(size, longDescription, description) {
      if (size === "large") {
        if (!Ember.isEmpty(longDescription)) {
          return (0, _text.emojiUnescape)((0, _text.sanitize)(longDescription));
        }
      }

      return (0, _text.sanitize)(description);
    },
    showFavorite: function showFavorite(badgeId) {
      return ![1, 2, 3, 4].includes(badgeId);
    }
  }, (_applyDecoratedDescriptor(_obj, "url", [_dec], Object.getOwnPropertyDescriptor(_obj, "url"), _obj), _applyDecoratedDescriptor(_obj, "displayCount", [_dec2], Object.getOwnPropertyDescriptor(_obj, "displayCount"), _obj), _applyDecoratedDescriptor(_obj, "summary", [_dec3], Object.getOwnPropertyDescriptor(_obj, "summary"), _obj), _applyDecoratedDescriptor(_obj, "showFavorite", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showFavorite"), _obj)), _obj)));

  _exports.default = _default;
});