define("discourse/components/user-field", ["exports", "discourse-common/utils/decorators", "discourse/lib/computed"], function (_exports, _decorators, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("field.name"), (_obj = {
    classNameBindings: [":user-field", "field.field_type", "customFieldClass"],
    layoutName: (0, _computed.fmt)("field.field_type", "components/user-fields/%@"),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var element = this.element.querySelector(".user-field.dropdown .select-kit-header");
      element = element || this.element.querySelector("input");
      this.field.element = element;
    },
    noneLabel: function noneLabel() {
      return "user_fields.none";
    },
    customFieldClass: function customFieldClass(fieldName) {
      if (fieldName) {
        fieldName = fieldName.replace(/\s+/g, "-").replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, "").toLowerCase();
        return fieldName && "user-field-".concat(fieldName);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "noneLabel", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "noneLabel"), _obj), _applyDecoratedDescriptor(_obj, "customFieldClass", [_dec], Object.getOwnPropertyDescriptor(_obj, "customFieldClass"), _obj)), _obj)));

  _exports.default = _default;
});