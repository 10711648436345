define("discourse/components/choose-topic", ["exports", "discourse-common/utils/decorators", "discourse-common/lib/debounce", "discourse/lib/search", "discourse-common/config/environment"], function (_exports, _decorators, _debounce, _search, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.observes)("topicTitle"), _dec2 = (0, _decorators.default)("label"), _dec3 = (0, _decorators.observes)("topics"), _dec4 = Ember._action, (_obj = {
    loading: null,
    noResults: null,
    topics: null,
    selectedTopicId: null,
    currentTopicId: null,
    additionalFilters: null,
    topicTitle: null,
    label: null,
    loadOnInit: false,
    topicChangedCallback: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.additionalFilters = this.additionalFilters || "";
      this.topicTitle = this.topicTitle || "";

      if (this.loadOnInit && !Ember.isEmpty(this.additionalFilters)) {
        (0, _search.searchForTerm)(this.additionalFilters, {}).then(function (results) {
          var _results$posts;

          if ((results === null || results === void 0 ? void 0 : (_results$posts = results.posts) === null || _results$posts === void 0 ? void 0 : _results$posts.length) > 0) {
            _this.set("topics", results.posts.mapBy("topic").filter(function (t) {
              return t.id !== _this.currentTopicId;
            }));
          } else {
            _this.setProperties({
              topics: null,
              loading: false
            });
          }
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      document.getElementById("choose-topic-title").addEventListener("keydown", this._handleEnter);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      document.getElementById("choose-topic-title").removeEventListener("keydown", this._handleEnter);
    },
    topicTitleChanged: function topicTitleChanged() {
      if (this.oldTopicTitle === this.topicTitle) {
        return;
      }

      this.setProperties({
        loading: true,
        noResults: true,
        selectedTopicId: null,
        oldTopicTitle: this.topicTitle
      });
      this.searchDebounced(this.topicTitle);
    },
    labelText: function labelText(label) {
      return label || "choose_topic.title.search";
    },
    topicsChanged: function topicsChanged() {
      if (this.topics) {
        this.set("noResults", this.topics.length === 0);
      }

      this.set("loading", false);
    },
    searchDebounced: function searchDebounced(title) {
      (0, _debounce.default)(this, this.search, title, _environment.INPUT_DELAY);
    },
    search: function search(title) {
      var _this2 = this;

      if (!this.element || this.isDestroying || this.isDestroyed) {
        return;
      }

      if (Ember.isEmpty(title) && Ember.isEmpty(this.additionalFilters)) {
        var _this$onSearchEmptied;

        this.setProperties({
          topics: null,
          loading: false
        });
        (_this$onSearchEmptied = this.onSearchEmptied) === null || _this$onSearchEmptied === void 0 ? void 0 : _this$onSearchEmptied.call(this);
        return;
      }

      var currentTopicId = this.currentTopicId;
      var titleWithFilters = "".concat(title, " ").concat(this.additionalFilters);
      var searchParams = {};

      if (!Ember.isEmpty(title)) {
        searchParams.typeFilter = "topic";
        searchParams.restrictToArchetype = "regular";
        searchParams.searchForId = true;
      }

      (0, _search.searchForTerm)(titleWithFilters, searchParams).then(function (results) {
        var _results$posts2;

        // search term changed after the request was fired but before we
        // got a response, ignore results.
        if (title !== _this2.topicTitle) {
          return;
        }

        if ((results === null || results === void 0 ? void 0 : (_results$posts2 = results.posts) === null || _results$posts2 === void 0 ? void 0 : _results$posts2.length) > 0) {
          _this2.set("topics", results.posts.mapBy("topic").filter(function (t) {
            return t.id !== currentTopicId;
          }));

          if (_this2.topics.length === 1) {
            _this2.send("chooseTopic", _this2.topics[0]);
          }
        } else {
          _this2.setProperties({
            topics: null,
            loading: false
          });
        }
      });
    },
    chooseTopic: function chooseTopic(topic) {
      this.set("selectedTopicId", topic.id);

      if (this.topicChangedCallback) {
        this.topicChangedCallback(topic);
      }
    },
    _handleEnter: function _handleEnter(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "topicTitleChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "topicTitleChanged"), _obj), _applyDecoratedDescriptor(_obj, "labelText", [_dec2], Object.getOwnPropertyDescriptor(_obj, "labelText"), _obj), _applyDecoratedDescriptor(_obj, "topicsChanged", [_dec3], Object.getOwnPropertyDescriptor(_obj, "topicsChanged"), _obj), _applyDecoratedDescriptor(_obj, "chooseTopic", [_dec4], Object.getOwnPropertyDescriptor(_obj, "chooseTopic"), _obj)), _obj)));

  _exports.default = _default;
});