define("discourse/models/user-posts-stream", ["exports", "discourse/models/user-action", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/computed"], function (_exports, _userAction, _ajax, _decorators, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Object.extend((_dec = (0, _decorators.on)("init"), (_obj = {
    loaded: false,
    _initialize: function _initialize() {
      this.setProperties({
        itemsLoaded: 0,
        canLoadMore: true,
        content: []
      });
    },
    url: (0, _computed.url)("user.username_lower", "filter", "itemsLoaded", "/posts/%@/%@?offset=%@"),
    filterBy: function filterBy(opts) {
      if (this.loaded && this.filter === opts.filter) {
        return Ember.RSVP.Promise.resolve();
      }

      this.setProperties(Object.assign({
        itemsLoaded: 0,
        content: [],
        lastLoadedUrl: null
      }, opts));
      return this.findItems();
    },
    findItems: function findItems() {
      var _this = this;

      if (this.loading || !this.canLoadMore) {
        return Ember.RSVP.Promise.reject();
      }

      this.set("loading", true);
      return (0, _ajax.ajax)(this.url).then(function (result) {
        if (result) {
          var posts = result.map(function (post) {
            return _userAction.default.create(post);
          });

          _this.content.pushObjects(posts);

          _this.setProperties({
            loaded: true,
            itemsLoaded: _this.itemsLoaded + posts.length,
            canLoadMore: posts.length > 0
          });
        }
      }).finally(function () {
        return _this.set("loading", false);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "_initialize", [_dec], Object.getOwnPropertyDescriptor(_obj, "_initialize"), _obj)), _obj)));

  _exports.default = _default;
});