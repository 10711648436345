define("discourse/routes/user-activity-likes-given", ["exports", "discourse/models/user-action", "discourse/routes/user-activity-stream", "discourse-common/lib/icon-library", "I18n"], function (_exports, _userAction, _userActivityStream, _iconLibrary, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _userActivityStream.default.extend((_dec = Ember._action, (_obj = {
    userActionType: _userAction.default.TYPES["likes_given"],
    emptyStateOthers: _I18n.default.t("user_activity.no_likes_others"),
    emptyState: function emptyState() {
      var title = _I18n.default.t("user_activity.no_likes_title");

      var body = _I18n.default.t("user_activity.no_likes_body", {
        heartIcon: (0, _iconLibrary.iconHTML)("heart")
      }).htmlSafe();

      return {
        title: title,
        body: body
      };
    },
    didTransition: function didTransition() {
      this.controllerFor("application").set("showFooter", true);
      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, "didTransition", [_dec], Object.getOwnPropertyDescriptor(_obj, "didTransition"), _obj)), _obj)));

  _exports.default = _default;
});