define("discourse/components/d-navigation", ["exports", "discourse/mixins/filter-mode", "discourse/models/nav-item", "bootbox", "discourse-common/utils/decorators"], function (_exports, _filterMode, _navItem, _bootbox, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _obj, _init;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_filterMode.default, (_dec = (0, _decorators.default)("site.categoriesList"), _dec2 = (0, _decorators.default)("category"), _dec3 = (0, _decorators.default)("tagNotification", "additionalTags"), _dec4 = (0, _decorators.default)("category", "createTopicDisabled"), _dec5 = (0, _decorators.default)("createTopicDisabled", "hasDraft", "categoryReadOnlyBanner", "canCreateTopicOnTag", "tag.id"), _dec6 = (0, _decorators.default)("categoryReadOnlyBanner", "hasDraft"), _dec7 = (0, _decorators.default)("hasDraft"), _dec8 = (0, _decorators.default)("category.can_edit"), _dec9 = (0, _decorators.default)("additionalTags", "category", "tag.id"), _dec10 = (0, _decorators.default)("filterType", "category", "noSubcategories", "tag.id", "router.currentRoute.queryParams"), (_obj = {
    router: Ember.inject.service(),
    tagName: "",
    categories: function categories(categoriesList) {
      return categoriesList;
    },
    showCategoryNotifications: function showCategoryNotifications(category) {
      return category && this.currentUser;
    },
    showTagNotifications: function showTagNotifications(tagNotification, additionalTags) {
      return tagNotification && !additionalTags;
    },
    categoryReadOnlyBanner: function categoryReadOnlyBanner(category, createTopicDisabled) {
      if (category && this.currentUser && createTopicDisabled) {
        return category.read_only_banner;
      }
    },
    createTopicButtonDisabled: function createTopicButtonDisabled(createTopicDisabled, hasDraft, categoryReadOnlyBanner, canCreateTopicOnTag, tagId) {
      if (tagId && !canCreateTopicOnTag) {
        return true;
      } else if (categoryReadOnlyBanner && !hasDraft) {
        return false;
      }

      return createTopicDisabled;
    },
    createTopicClass: function createTopicClass(categoryReadOnlyBanner, hasDraft) {
      var classNames = ["btn-default"];

      if (hasDraft) {
        classNames.push("open-draft");
      } else if (categoryReadOnlyBanner) {
        classNames.push("disabled");
      }

      return classNames.join(" ");
    },
    createTopicLabel: function createTopicLabel(hasDraft) {
      return hasDraft ? "topic.open_draft" : "topic.create";
    },
    showCategoryEdit: function showCategoryEdit(canEdit) {
      return canEdit;
    },
    showToggleInfo: function showToggleInfo(additionalTags, category, tagId) {
      return !additionalTags && !category && tagId !== "none";
    },
    navItems: function navItems(filterType, category, noSubcategories, tagId, currentRouteQueryParams) {
      return _navItem.default.buildList(category, {
        filterType: filterType,
        noSubcategories: noSubcategories,
        currentRouteQueryParams: currentRouteQueryParams,
        tagId: tagId,
        siteSettings: this.siteSettings
      });
    },
    actions: {
      changeCategoryNotificationLevel: function changeCategoryNotificationLevel(notificationLevel) {
        this.category.setNotification(notificationLevel);
      },
      selectCategoryAdminDropdownAction: function selectCategoryAdminDropdownAction(actionId) {
        switch (actionId) {
          case "create":
            this.createCategory();
            break;

          case "reorder":
            this.reorderCategories();
            break;
        }
      },
      clickCreateTopicButton: function clickCreateTopicButton() {
        if (this.categoryReadOnlyBanner && !this.hasDraft) {
          _bootbox.default.alert(this.categoryReadOnlyBanner);
        } else {
          this.createTopic();
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "categories", [_dec], Object.getOwnPropertyDescriptor(_obj, "categories"), _obj), _applyDecoratedDescriptor(_obj, "showCategoryNotifications", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showCategoryNotifications"), _obj), _applyDecoratedDescriptor(_obj, "showTagNotifications", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showTagNotifications"), _obj), _applyDecoratedDescriptor(_obj, "categoryReadOnlyBanner", [_dec4], Object.getOwnPropertyDescriptor(_obj, "categoryReadOnlyBanner"), _obj), _applyDecoratedDescriptor(_obj, "createTopicButtonDisabled", [_dec5], Object.getOwnPropertyDescriptor(_obj, "createTopicButtonDisabled"), _obj), _applyDecoratedDescriptor(_obj, "createTopicClass", [_dec6], Object.getOwnPropertyDescriptor(_obj, "createTopicClass"), _obj), _applyDecoratedDescriptor(_obj, "createTopicLabel", [_dec7], Object.getOwnPropertyDescriptor(_obj, "createTopicLabel"), _obj), _applyDecoratedDescriptor(_obj, "showCategoryEdit", [_dec8], (_init = Object.getOwnPropertyDescriptor(_obj, "showCategoryEdit"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, "showToggleInfo", [_dec9], Object.getOwnPropertyDescriptor(_obj, "showToggleInfo"), _obj), _applyDecoratedDescriptor(_obj, "navItems", [_dec10], Object.getOwnPropertyDescriptor(_obj, "navItems"), _obj)), _obj)));

  _exports.default = _default;
});