define("discourse/widgets/search-menu", ["exports", "discourse/lib/search", "discourse/lib/url", "discourse/widgets/widget", "discourse-common/lib/debounce", "discourse-common/lib/get-url", "virtual-dom", "discourse-common/lib/icon-library", "discourse/lib/utilities", "discourse/lib/ajax-error", "discourse/lib/category-tag-search", "discourse/lib/user-search", "discourse/lib/autocomplete", "I18n"], function (_exports, _search, _url, _widget, _debounce, _getUrl, _virtualDom, _iconLibrary, _utilities, _ajaxError, _categoryTagSearch, _userSearch, _autocomplete, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initSearchData = initSearchData;
  _exports.default = _exports.DEFAULT_TYPE_FILTER = _exports.MODIFIER_REGEXP = void 0;
  var CATEGORY_SLUG_REGEXP = /(\#[a-zA-Z0-9\-:]*)$/gi;
  var USERNAME_REGEXP = /(\@[a-zA-Z0-9\-\_]*)$/gi;
  var SUGGESTIONS_REGEXP = /(in:|status:|order:|:)([a-zA-Z]*)$/gi;
  var SECOND_ENTER_MAX_DELAY = 15000;
  var MODIFIER_REGEXP = /.*(\#|\@|:).*$/gi;
  _exports.MODIFIER_REGEXP = MODIFIER_REGEXP;
  var DEFAULT_TYPE_FILTER = "exclude_topics";
  _exports.DEFAULT_TYPE_FILTER = DEFAULT_TYPE_FILTER;
  var searchData = {};

  function initSearchData() {
    searchData.loading = false;
    searchData.results = {};
    searchData.noResults = false;
    searchData.term = undefined;
    searchData.typeFilter = DEFAULT_TYPE_FILTER;
    searchData.invalidTerm = false;
    searchData.suggestionResults = [];
    searchData.suggestionKeyword = false;
  }

  initSearchData(); // Helps with debouncing and cancelling promises

  var SearchHelper = {
    _activeSearch: null,
    // for cancelling debounced search
    cancel: function cancel() {
      if (this._activeSearch) {
        this._activeSearch.abort();

        this._activeSearch = null;
      }
    },
    perform: function perform(widget) {
      this.cancel();
      var term = searchData.term,
          typeFilter = searchData.typeFilter;
      var searchContext = widget.searchContext();
      var fullSearchUrl = widget.fullSearchUrl();
      var matchSuggestions = this.matchesSuggestions();

      if (matchSuggestions) {
        searchData.noResults = true;
        searchData.results = {};
        searchData.loading = false;
        searchData.suggestionResults = [];

        if (matchSuggestions.type === "category") {
          var categorySearchTerm = matchSuggestions.categoriesMatch[0].replace("#", "");
          var categoryTagSearch = (0, _categoryTagSearch.search)(categorySearchTerm, widget.siteSettings);
          Ember.RSVP.Promise.resolve(categoryTagSearch).then(function (results) {
            if (results !== _autocomplete.CANCELLED_STATUS) {
              searchData.suggestionResults = results;
              searchData.suggestionKeyword = "#";
            }

            widget.scheduleRerender();
          });
        } else if (matchSuggestions.type === "username") {
          var userSearchTerm = matchSuggestions.usernamesMatch[0].replace("@", "");
          var opts = {
            includeGroups: true,
            limit: 6
          };

          if (userSearchTerm.length > 0) {
            opts.term = userSearchTerm;
          } else {
            opts.lastSeenUsers = true;
          }

          (0, _userSearch.default)(opts).then(function (result) {
            var _result$users;

            if ((result === null || result === void 0 ? void 0 : (_result$users = result.users) === null || _result$users === void 0 ? void 0 : _result$users.length) > 0) {
              searchData.suggestionResults = result.users;
              searchData.suggestionKeyword = "@";
            } else {
              searchData.noResults = true;
              searchData.suggestionKeyword = false;
            }

            widget.scheduleRerender();
          });
        } else {
          searchData.suggestionKeyword = matchSuggestions[0];
          widget.scheduleRerender();
        }

        return;
      }

      searchData.suggestionKeyword = false;

      if (!term) {
        searchData.noResults = false;
        searchData.results = {};
        searchData.loading = false;
        searchData.invalidTerm = false;
        widget.scheduleRerender();
      } else if (!(0, _search.isValidSearchTerm)(term, widget.siteSettings)) {
        searchData.noResults = true;
        searchData.results = {};
        searchData.loading = false;
        searchData.invalidTerm = true;
        widget.scheduleRerender();
      } else {
        searchData.invalidTerm = false;
        this._activeSearch = (0, _search.searchForTerm)(term, {
          typeFilter: typeFilter,
          fullSearchUrl: fullSearchUrl,
          searchContext: searchContext
        });

        this._activeSearch.then(function (results) {
          // we ensure the current search term is the one used
          // when starting the query
          if (results && term === searchData.term) {
            if (searchContext) {
              widget.appEvents.trigger("post-stream:refresh", {
                force: true
              });
            }

            searchData.noResults = results.resultTypes.length === 0;
            searchData.results = results;
          }
        }).catch(_ajaxError.popupAjaxError).finally(function () {
          searchData.loading = false;
          widget.scheduleRerender();
        });
      }
    },
    matchesSuggestions: function matchesSuggestions() {
      if (searchData.term === undefined || this.includesTopics()) {
        return false;
      }

      var term = searchData.term.trim();
      var categoriesMatch = term.match(CATEGORY_SLUG_REGEXP);

      if (categoriesMatch) {
        return {
          type: "category",
          categoriesMatch: categoriesMatch
        };
      }

      var usernamesMatch = term.match(USERNAME_REGEXP);

      if (usernamesMatch) {
        return {
          type: "username",
          usernamesMatch: usernamesMatch
        };
      }

      var suggestionsMatch = term.match(SUGGESTIONS_REGEXP);

      if (suggestionsMatch) {
        return suggestionsMatch;
      }

      return false;
    },
    includesTopics: function includesTopics() {
      return searchData.typeFilter !== DEFAULT_TYPE_FILTER;
    }
  };

  var _default = (0, _widget.createWidget)("search-menu", {
    tagName: "div.search-menu",
    services: ["search"],
    searchData: searchData,
    buildKey: function buildKey() {
      return "search-menu";
    },
    defaultState: function defaultState(attrs) {
      return {
        inTopicContext: attrs.inTopicContext,
        _lastEnterTimestamp: null,
        _debouncer: null
      };
    },
    fullSearchUrl: function fullSearchUrl(opts) {
      var url = "/search";
      var params = [];

      if (searchData.term) {
        var query = "";
        query += "q=".concat(encodeURIComponent(searchData.term));
        var searchContext = this.searchContext();

        if ((searchContext === null || searchContext === void 0 ? void 0 : searchContext.type) === "topic") {
          query += encodeURIComponent(" topic:".concat(searchContext.id));
        }

        if (query) {
          params.push(query);
        }
      }

      if (opts && opts.expanded) {
        params.push("expanded=true");
      }

      if (params.length > 0) {
        url = "".concat(url, "?").concat(params.join("&"));
      }

      return (0, _getUrl.default)(url);
    },
    panelContents: function panelContents() {
      var searchInput = [];

      if (this.state.inTopicContext) {
        searchInput.push(this.attach("button", {
          icon: "times",
          label: "search.in_this_topic",
          title: "search.in_this_topic_tooltip",
          className: "btn btn-small search-context",
          action: "clearTopicContext",
          iconRight: true
        }));
      }

      searchInput.push(this.attach("search-term", {
        value: searchData.term
      }));

      if (searchData.loading) {
        searchInput.push((0, _virtualDom.h)("div.searching", (0, _virtualDom.h)("div.spinner")));
      } else {
        var clearButton = this.attach("link", {
          title: "search.clear_search",
          action: "clearSearch",
          className: "clear-search",
          contents: function contents() {
            return (0, _iconLibrary.iconNode)("times");
          }
        });
        var advancedSearchButton = this.attach("link", {
          href: this.fullSearchUrl({
            expanded: true
          }),
          contents: function contents() {
            return (0, _iconLibrary.iconNode)("sliders-h");
          },
          className: "show-advanced-search",
          title: "search.open_advanced"
        });

        if (searchData.term) {
          searchInput.push((0, _virtualDom.h)("div.searching", [clearButton, advancedSearchButton]));
        } else {
          searchInput.push((0, _virtualDom.h)("div.searching", advancedSearchButton));
        }
      }

      var results = [(0, _virtualDom.h)("div.search-input", searchInput)];

      if (this.state.inTopicContext && (!SearchHelper.includesTopics() || !searchData.term)) {
        var isMobileDevice = this.site.isMobileDevice;

        if (!isMobileDevice) {
          results.push(this.attach("browser-search-tip"));
        }

        return results;
      }

      if (!searchData.loading) {
        results.push(this.attach("search-menu-results", {
          term: searchData.term,
          noResults: searchData.noResults,
          results: searchData.results,
          invalidTerm: searchData.invalidTerm,
          suggestionKeyword: searchData.suggestionKeyword,
          suggestionResults: searchData.suggestionResults,
          searchTopics: SearchHelper.includesTopics()
        }));
      }

      return results;
    },
    clearSearch: function clearSearch() {
      searchData.term = "";
      var searchInput = document.getElementById("search-term");
      searchInput.value = "";
      searchInput.focus();
      this.triggerSearch();
    },
    html: function html(attrs, state) {
      var _this = this;

      if (attrs.inTopicContext === false) {
        state.inTopicContext = false;
      }

      return this.attach("menu-panel", {
        maxWidth: 500,
        contents: function contents() {
          return _this.panelContents();
        }
      });
    },
    mouseDownOutside: function mouseDownOutside() {
      this.sendWidgetAction("toggleSearchMenu");
    },
    clearTopicContext: function clearTopicContext() {
      this.sendWidgetAction("clearContext");
    },
    keyDown: function keyDown(e) {
      if (e.which === 27
      /* escape */
      ) {
          this.sendWidgetAction("toggleSearchMenu");
          e.preventDefault();
          return false;
        }

      if (searchData.loading) {
        return;
      }

      if (e.which === 65
      /* a */
      ) {
          var _document$activeEleme;

          if ((_document$activeEleme = document.activeElement) !== null && _document$activeEleme !== void 0 && _document$activeEleme.classList.contains("search-link")) {
            if (document.querySelector("#reply-control.open")) {
              // add a link and focus composer
              this.appEvents.trigger("composer:insert-text", document.activeElement.href, {
                ensureSpace: true
              });
              this.appEvents.trigger("header:keyboard-trigger", {
                type: "search"
              });
              e.preventDefault();
              document.querySelector("#reply-control.open textarea").focus();
              return false;
            }
          }
        }

      var up = e.which === 38;
      var down = e.which === 40;

      if (up || down) {
        var focused = document.activeElement.closest(".search-menu") ? document.activeElement : null;

        if (!focused) {
          return;
        }

        var links = document.querySelectorAll(".search-menu .results a");
        var results = document.querySelectorAll(".search-menu .results .search-link");

        if (!results.length) {
          return;
        }

        var prevResult;
        var result;
        links.forEach(function (item) {
          if (item.classList.contains("search-link")) {
            prevResult = item;
          }

          if (item === focused) {
            result = prevResult;
          }
        });
        var index = -1;

        if (result) {
          index = Array.prototype.indexOf.call(results, result);
        }

        if (index === -1 && down) {
          document.querySelector(".search-menu .results .search-link").focus();
        } else if (index === 0 && up) {
          document.querySelector(".search-menu input#search-term").focus();
        } else if (index > -1) {
          index += down ? 1 : -1;

          if (index >= 0 && index < results.length) {
            results[index].focus();
          }
        }

        e.preventDefault();
        return false;
      }

      var searchInput = document.querySelector("#search-term");

      if (e.which === 13 && e.target === searchInput) {
        var recentEnterHit = this.state._lastEnterTimestamp && Date.now() - this.state._lastEnterTimestamp < SECOND_ENTER_MAX_DELAY; // same combination as key-enter-escape mixin

        if (e.ctrlKey || e.metaKey || (0, _utilities.isiPad)() && e.altKey || searchData.typeFilter !== DEFAULT_TYPE_FILTER && recentEnterHit) {
          this.fullSearch();
        } else {
          searchData.typeFilter = null;
          this.triggerSearch();
        }

        this.state._lastEnterTimestamp = Date.now();
      }

      if (e.target === searchInput && e.which === 8
      /* backspace */
      ) {
          if (!searchInput.value) {
            this.clearTopicContext();
          }
        }
    },
    triggerSearch: function triggerSearch() {
      searchData.noResults = false;

      if (SearchHelper.includesTopics()) {
        if (this.state.inTopicContext) {
          this.search.set("highlightTerm", searchData.term);
        }

        searchData.loading = true;
        Ember.run.cancel(this.state._debouncer);
        SearchHelper.perform(this);

        if (this.currentUser) {
          (0, _search.updateRecentSearches)(this.currentUser, searchData.term);
        }
      } else {
        searchData.loading = false;

        if (!this.state.inTopicContext) {
          this.state._debouncer = (0, _debounce.default)(SearchHelper, SearchHelper.perform, this, 400);
        }
      }
    },
    moreOfType: function moreOfType(type) {
      searchData.typeFilter = type;
      this.triggerSearch();
    },
    searchTermChanged: function searchTermChanged(term) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      searchData.typeFilter = opts.searchTopics ? null : DEFAULT_TYPE_FILTER;
      searchData.term = term;
      this.triggerSearch();
    },
    triggerAutocomplete: function triggerAutocomplete() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (opts.setTopicContext) {
        this.sendWidgetAction("setTopicContext");
        this.state.inTopicContext = true;
      }

      this.searchTermChanged(opts.value, {
        searchTopics: opts.searchTopics
      });
    },
    fullSearch: function fullSearch() {
      searchData.loading = false;
      SearchHelper.cancel();
      var url = this.fullSearchUrl();

      if (url) {
        this.sendWidgetEvent("linkClicked");

        _url.default.routeTo(url);
      }
    },
    searchContext: function searchContext() {
      if (this.state.inTopicContext) {
        return this.search.searchContext;
      }

      return false;
    }
  });

  _exports.default = _default;
  (0, _widget.createWidget)("browser-search-tip", {
    buildKey: function buildKey() {
      return "browser-search-tip";
    },
    tagName: "div.browser-search-tip",
    html: function html() {
      return [(0, _virtualDom.h)("span.tip-label", _I18n.default.t("search.browser_tip", {
        modifier: (0, _utilities.translateModKey)("Meta")
      })), (0, _virtualDom.h)("span.tip-description", _I18n.default.t("search.browser_tip_description"))];
    }
  });
});