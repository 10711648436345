define("discourse/components/topic-title", ["exports", "discourse/mixins/key-enter-escape"], function (_exports, _keyEnterEscape) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addTopicTitleDecorator = addTopicTitleDecorator;
  _exports.resetTopicTitleDecorators = resetTopicTitleDecorators;
  _exports.default = _exports.topicTitleDecorators = void 0;
  var topicTitleDecorators = [];
  _exports.topicTitleDecorators = topicTitleDecorators;

  function addTopicTitleDecorator(decorator) {
    topicTitleDecorators.push(decorator);
  }

  function resetTopicTitleDecorators() {
    _exports.topicTitleDecorators = topicTitleDecorators = [];
  }

  var _default = Ember.Component.extend(_keyEnterEscape.default, {
    elementId: "topic-title",
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.schedule("afterRender", function () {
        if (_this.element && !_this.isDestroying && !_this.isDestroyed) {
          var fancyTitle = _this.element.querySelector(".fancy-title");

          fancyTitle && topicTitleDecorators && topicTitleDecorators.forEach(function (cb) {
            return cb(_this.model, fancyTitle, "topic-title");
          });
        }
      });
    }
  });

  _exports.default = _default;
});