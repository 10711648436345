define("discourse/components/edit-category-settings", ["exports", "discourse/models/group", "I18n", "discourse/lib/constants", "discourse/components/edit-category-panel", "discourse-common/utils/decorators", "discourse/lib/computed"], function (_exports, _group, _I18n, _constants, _editCategoryPanel, _decorators, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addCategorySortCriteria = addCategorySortCriteria;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var categorySortCriteria = [];

  function addCategorySortCriteria(criteria) {
    categorySortCriteria.push(criteria);
  }

  var _default = (0, _editCategoryPanel.buildCategoryPanel)("settings", (_dec = (0, _decorators.default)("category.isParent", "category.parent_category_id"), _dec2 = (0, _decorators.default)("category.sort_ascending"), _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    emailInEnabled: (0, _computed.setting)("email_in"),
    showPositionInput: (0, _computed.setting)("fixed_category_positions"),
    isParentCategory: function isParentCategory(isParent, parentCategoryId) {
      return isParent || !parentCategoryId;
    },
    showSubcategoryListStyle: Ember.computed.and("category.show_subcategory_list", "isParentCategory"),
    isDefaultSortOrder: Ember.computed.empty("category.sort_order"),
    availableSubcategoryListStyles: function availableSubcategoryListStyles() {
      return [{
        name: _I18n.default.t("category.subcategory_list_styles.rows"),
        value: "rows"
      }, {
        name: _I18n.default.t("category.subcategory_list_styles.rows_with_featured_topics"),
        value: "rows_with_featured_topics"
      }, {
        name: _I18n.default.t("category.subcategory_list_styles.boxes"),
        value: "boxes"
      }, {
        name: _I18n.default.t("category.subcategory_list_styles.boxes_with_featured_topics"),
        value: "boxes_with_featured_topics"
      }];
    },
    groupFinder: function groupFinder(term) {
      return _group.default.findAll({
        term: term,
        ignore_automatic: true
      });
    },
    availableViews: function availableViews() {
      return [{
        name: _I18n.default.t("filters.latest.title"),
        value: "latest"
      }, {
        name: _I18n.default.t("filters.top.title"),
        value: "top"
      }];
    },
    availableTopPeriods: function availableTopPeriods() {
      return ["all", "yearly", "quarterly", "monthly", "weekly", "daily"].map(function (p) {
        return {
          name: _I18n.default.t("filters.top.".concat(p, ".title")),
          value: p
        };
      });
    },
    availableListFilters: function availableListFilters() {
      return ["all", "none"].map(function (p) {
        return {
          name: _I18n.default.t("category.list_filters.".concat(p)),
          value: p
        };
      });
    },
    searchPrioritiesOptions: function searchPrioritiesOptions() {
      var options = [];
      Object.entries(_constants.SEARCH_PRIORITIES).forEach(function (entry) {
        var _entry = _slicedToArray(entry, 2),
            name = _entry[0],
            value = _entry[1];

        options.push({
          name: _I18n.default.t("category.search_priority.options.".concat(name)),
          value: value
        });
      });
      return options;
    },
    availableSorts: function availableSorts() {
      return ["likes", "op_likes", "views", "posts", "activity", "posters", "category", "created"].concat(categorySortCriteria).map(function (s) {
        return {
          name: _I18n.default.t("category.sort_options." + s),
          value: s
        };
      }).sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    },
    sortAscendingOption: function sortAscendingOption(sortAscending) {
      if (sortAscending === "false") {
        return false;
      }

      if (sortAscending === "true") {
        return true;
      }

      return sortAscending;
    },
    sortAscendingOptions: function sortAscendingOptions() {
      return [{
        name: _I18n.default.t("category.sort_ascending"),
        value: true
      }, {
        name: _I18n.default.t("category.sort_descending"),
        value: false
      }];
    },
    hiddenRelativeIntervals: function hiddenRelativeIntervals() {
      return ["mins"];
    },
    onAutoCloseDurationChange: function onAutoCloseDurationChange(minutes) {
      var hours = minutes ? minutes / 60 : null;
      this.set("category.auto_close_hours", hours);
    },
    onDefaultSlowModeDurationChange: function onDefaultSlowModeDurationChange(minutes) {
      var seconds = minutes ? minutes * 60 : null;
      this.set("category.default_slow_mode_seconds", seconds);
    }
  }, (_applyDecoratedDescriptor(_obj, "isParentCategory", [_dec], Object.getOwnPropertyDescriptor(_obj, "isParentCategory"), _obj), _applyDecoratedDescriptor(_obj, "availableSubcategoryListStyles", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "availableSubcategoryListStyles"), _obj), _applyDecoratedDescriptor(_obj, "availableViews", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "availableViews"), _obj), _applyDecoratedDescriptor(_obj, "availableTopPeriods", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "availableTopPeriods"), _obj), _applyDecoratedDescriptor(_obj, "availableListFilters", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "availableListFilters"), _obj), _applyDecoratedDescriptor(_obj, "searchPrioritiesOptions", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "searchPrioritiesOptions"), _obj), _applyDecoratedDescriptor(_obj, "availableSorts", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "availableSorts"), _obj), _applyDecoratedDescriptor(_obj, "sortAscendingOption", [_dec2], Object.getOwnPropertyDescriptor(_obj, "sortAscendingOption"), _obj), _applyDecoratedDescriptor(_obj, "sortAscendingOptions", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "sortAscendingOptions"), _obj), _applyDecoratedDescriptor(_obj, "hiddenRelativeIntervals", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "hiddenRelativeIntervals"), _obj), _applyDecoratedDescriptor(_obj, "onAutoCloseDurationChange", [_dec3], Object.getOwnPropertyDescriptor(_obj, "onAutoCloseDurationChange"), _obj), _applyDecoratedDescriptor(_obj, "onDefaultSlowModeDurationChange", [_dec4], Object.getOwnPropertyDescriptor(_obj, "onDefaultSlowModeDurationChange"), _obj)), _obj)));

  _exports.default = _default;
});