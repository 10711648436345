define("discourse/routes/user-invited-index", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    beforeModel: function beforeModel() {
      this.replaceWith("userInvited.show", "pending");
    }
  });

  _exports.default = _default;
});