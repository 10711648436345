define("discourse/components/directory-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_obj = {
    lastScrollPosition: 0,
    ticking: false,
    _topHorizontalScrollBar: null,
    _tableContainer: null,
    _table: null,
    _fakeScrollContent: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setProperties({
        _tableContainer: this.element.querySelector(".directory-table-container"),
        _topHorizontalScrollBar: this.element.querySelector(".directory-table-top-scroll"),
        _fakeScrollContent: this.element.querySelector(".directory-table-top-scroll-fake-content"),
        _table: this.element.querySelector(".directory-table")
      });

      this._tableContainer.addEventListener("scroll", this.onBottomScroll);

      this._topHorizontalScrollBar.addEventListener("scroll", this.onTopScroll); // Set active header might have already scrolled the _tableContainer.
      // Call onHorizontalScroll manually to scroll the _topHorizontalScrollBar


      this.onResize();
      this.onHorizontalScroll(this._tableContainer, this._topHorizontalScrollBar);
      window.addEventListener("resize", this.onResize);
    },
    onResize: function onResize() {
      if (this._tableContainer.getBoundingClientRect().bottom < window.innerHeight) {
        // Bottom of the table is visible. Hide the scrollbar
        this._fakeScrollContent.style.height = 0;
      } else {
        this._fakeScrollContent.style.width = "".concat(this._table.offsetWidth, "px");
        this._fakeScrollContent.style.height = "1px";
      }
    },
    onTopScroll: function onTopScroll() {
      this.onHorizontalScroll(this._topHorizontalScrollBar, this._tableContainer);
    },
    onBottomScroll: function onBottomScroll() {
      this.onHorizontalScroll(this._tableContainer, this._topHorizontalScrollBar);
    },
    onHorizontalScroll: function onHorizontalScroll(primary, replica) {
      var _this = this;

      if (this.isDestroying || this.isDestroyed || this.lastScrollPosition === primary.scrollLeft) {
        return;
      }

      this.set("lastScrollPosition", primary.scrollLeft);

      if (!this.ticking) {
        window.requestAnimationFrame(function () {
          if (!_this.isDestroying && !_this.isDestroyed) {
            replica.scrollLeft = _this.lastScrollPosition;

            _this.set("ticking", false);
          }
        });
        this.set("ticking", true);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._tableContainer.removeEventListener("scroll", this.onBottomScroll);

      this._topHorizontalScrollBar.removeEventListener("scroll", this.onTopScroll);

      window.removeEventListener("resize", this.onResize);
    },
    setActiveHeader: function setActiveHeader(header) {
      // After render, scroll table left to ensure the order by column is visible
      if (!this._tableContainer) {
        this.set("_tableContainer", document.querySelector(".directory-table-container"));
      }

      var scrollPixels = header.offsetLeft + header.offsetWidth + 10 - this._tableContainer.offsetWidth;

      if (scrollPixels > 0) {
        this._tableContainer.scrollLeft = scrollPixels;
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "onResize", [_dec], Object.getOwnPropertyDescriptor(_obj, "onResize"), _obj), _applyDecoratedDescriptor(_obj, "onTopScroll", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onTopScroll"), _obj), _applyDecoratedDescriptor(_obj, "onBottomScroll", [_dec3], Object.getOwnPropertyDescriptor(_obj, "onBottomScroll"), _obj), _applyDecoratedDescriptor(_obj, "onHorizontalScroll", [_dec4], Object.getOwnPropertyDescriptor(_obj, "onHorizontalScroll"), _obj), _applyDecoratedDescriptor(_obj, "setActiveHeader", [_dec5], Object.getOwnPropertyDescriptor(_obj, "setActiveHeader"), _obj)), _obj)));

  _exports.default = _default;
});