define("discourse/templates/components/reviewable-field-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lj79CrTr",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"textarea\",null,[[\"value\",\"change\",\"class\"],[[24,[\"value\"]],[24,[\"valueChanged\"]],\"reviewable-input-textarea\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/reviewable-field-textarea.hbs"
    }
  });

  _exports.default = _default;
});