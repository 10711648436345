define("discourse/templates/components/sub-category-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Axx+uFj6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"isMuted\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"subcategory\"],[8],[0,\"\\n    \"],[1,[28,\"category-title-before\",null,[[\"category\"],[[24,[\"category\"]]]]],false],[0,\"\\n    \"],[1,[28,\"category-link\",[[24,[\"category\"]]],[[\"hideParent\"],[\"true\"]]],false],[0,\"\\n\"],[4,\"unless\",[[24,[\"hideUnread\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"category-unread\",null,[[\"category\"],[[24,[\"category\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "discourse/templates/components/sub-category-item.hbs"
    }
  });

  _exports.default = _default;
});